import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Option } from '@unifii/sdk';

import { ColumnGap, ProgressComponent, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

enum ContentValues {
	Default = 'default',
	Scrollable = 'scrollable',
	ScrollableContent = 'scrollableContent'
}

@Component({
	selector: 'sc-show-form-cards',
	templateUrl: './show-form-cards.html',

})
export class ShowFormCardsComponent implements OnInit, OnDestroy {

	@ViewChild(ProgressComponent) progress?: ProgressComponent;

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	// Table
	readonly colInfo: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly rows = [
		{
			name: 'uf-form-card',
			type: 'base',
			description: 'Provides drop shadow and indicator add the top of container',
		},
	];

	readonly contentOptions: Option[] = [
		{ identifier: ContentValues.Default, name: 'Default' },
		{ identifier: ContentValues.Scrollable, name: 'Scrollable' },
		{ identifier: ContentValues.ScrollableContent, name: 'Scrollable Content' },
	];
	readonly contentValues = ContentValues;

	template: string;
	content = ContentValues.Default;

	private progressTimeout?: NodeJS.Timeout;
	private readonly templateSource = `
		<form class="uf-form-card">
			<uf-progress class="large"></uf-progress>
			<div class="uf-app-bar">
				<button type="button" class="uf-action tertiary">
					<uf-icon name="contextual"></uf-icon>
				</button>
				<h3>CardTitle</h3>
			</div>
			<div class="uf-grid pad gaps">
				<uf-text class="col-12" label="Entry field"></uf-text>
			</div>
			<div class="uf-form-actions">
				<button type="button" class="uf-button tertiary">
					Cancel
				</button>
				<button type="button" class="uf-button primary">
					Submit
				</button>
			</div>
		</form>
	`;

	ngOnInit() {
		this.modifierChange();
	}

	ngOnDestroy() {
		clearTimeout(this.progressTimeout);
	}

	updateTemplate() {
		this.template = this.templateSource;
	}

	startProgress() {

		if (!this.progress || this.progress.inProgress) {
			return;
		}

		const valorizedProgress = this.progress;

		clearTimeout(this.progressTimeout);
		this.progress.start();
		setTimeout(() => { valorizedProgress.complete(); }, 800);
	}

	modifierChange() {
		this.template = this.getTemplate();
	}

	private getTemplate(): string {

		if (this.content === ContentValues.ScrollableContent) {
			const template = this.insert(this.templateSource, '<uf-panel class="grow">', this.templateSource.indexOf('<div class="uf-grid'));

			return this.insert(template, '</uf-panel>', template.indexOf('<div class="uf-form-actions'));
		}

		if (this.content === ContentValues.Scrollable) {
			const template = this.insert(this.templateSource, '<uf-panel class="grow">', this.templateSource.indexOf('<div class="uf-app-bar'));

			return this.insert(template, '</uf-panel>', template.lastIndexOf('</div>'));
		}

		return this.templateSource;
	}

	private insert(source: string, value: string, index: number): string {
		return [source.slice(0, index), value, source.slice(index)].join('');
	}

}
