<!-- todo: is this required? could label be displayed outside? -->
<label *ngIf="label">
	{{label}}
	<ng-content />
</label>

<uf-skeleton *ngIf="!initialized" class="gap-none" />

<!-- Hierarchy -->
<div *ngIf="initialized" class="uf-grid">

	<ng-template [ngIf]="showSearch">
		<uf-autocomplete [label]="commonTK.SearchUnitLabel | translate" [options]="searchOptions" [value]="searchValue"
			[nameProperty]="displaySearchResult" (searchChange)="search($event)"
			(valueChange)="changeSearchValue($event)" class="col-12" />

		<h3 class="col-12">Select a Unit</h3>
	</ng-template>

	<uf-autocomplete *ngFor="let input of inputs" [label]="input.label" [value]="input.selection"
		[disabled]="disabled || input.disabled" [options]="optionsLookup[input.id]"
		(searchChange)="searchChildren(input.id, $event)" (valueChange)="select(input.id, $event)" nameProperty="label"
		class="col-12" />

	<!-- Configuration error -->
	<uf-message *ngIf="error" [content]="error.message" icon="warningSolid" class="col-12 warning">
		<button *ngIf="!disabled && error.data.showRetry" (click)="retry()" type="button" class="uf-button small right">
			{{ sharedTK.ActionRetry | translate }}
		</button>
		<button *ngIf="!disabled && error.data.showClear" (click)="reset()" type="button" class="uf-button small right">
			{{ sharedTK.ActionClear | translate }}
		</button>
	</uf-message>

	<!-- Control error -->
	<uf-message *ngIf="control.showError" [content]="control.errors?.message" icon="error" class="col-12 error" />

</div>