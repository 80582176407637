import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { UfControl } from '../controls';

@Pipe({
	name: 'asUfControl',
})
export class AsUfControlPipe implements PipeTransform {

	transform(value: AbstractControl): UfControl {
		return value as UfControl;
	}

}
