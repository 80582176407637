<ng-template [ngIf]="mode === modes.Input">
	<uf-chips [allowCustom]="true" [(value)]="value" (valueChange)="valueChanged()" />
</ng-template>

<ng-template [ngIf]="mode === modes.Display && !isEmpty">
	<div *ngFor="let v of value; let i = index" class="uf-chip x-small">
		{{ label }}: {{ v }}
		<button (click)="remove(i)" type="button" class="uf-action">
			<uf-icon name="delete" />
		</button>
	</div>
</ng-template>