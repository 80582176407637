<div class="input-wrap">
	<input *ngIf="nativeControl && !disabled" [formControl]="nativeControl" [attr.type]="nativeInputType"
		(focus)="focused = true;" (blur)="onBlur();">
	<input #input [formControl]="inputControl" [readonly]="disabled" [attr.placeholder]="placeholder"
		(focus)="focused = true; onFocus($event)" (blur)="onBlur(); setControlValue();" type="text">
	<div class="input-icon">
		<uf-icon [name]="inputIcon" />
	</div>
	<!-- Do not show control error when inputControl is invalid -->
	<uf-error [control]="inputControl" />
	<uf-error *ngIf="inputControl.valid" [control]="control" />
	<uf-underline *ngIf="!disabled" [class.active]="focused" />
</div>