import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataSeed } from '@unifii/sdk';

import { FilterEntry, FilterViewMode, IFilterComponent } from '../../../models';

@Component({
	selector: 'uf-filter-chips',
	templateUrl: './filter-chips.html',
	styleUrls: ['./field-filter.less'],
})
export class FilterChipsComponent implements IFilterComponent, OnInit {

	@Input() mode: FilterViewMode = FilterViewMode.Input;
	@Input() entry?: FilterEntry;
	@Input() value: DataSeed[] = [];
	@Output() valueChange = new EventEmitter<DataSeed[]>();

	protected readonly modes = FilterViewMode;
	protected results: DataSeed[] = [];
	protected searchMinLength: number;

	private searchAbortController: AbortController | null = null;

	ngOnInit() {
		this.searchMinLength = this.entry?.searchMinLength ?? 0;
	}

	get isEmpty() {
		return !this.value.length;
	}

	get label() {
		return this.entry?.label ?? '';
	}

	protected async search(q?: string) {
		if (!this.entry?.loader) {
			console.error('FilterChipsComponent: Loader not provided');

			return;
		}

		if (this.searchAbortController) {
			this.searchAbortController.abort();
			await new Promise((resolve) => setTimeout(resolve, 0)); // To guaranteed previous abort is digested
		}

		this.searchAbortController = new AbortController();
		this.results = await this.entry.loader.search(q, undefined, undefined, this.searchAbortController.signal);
	}

	protected remove(index: number) {
		this.value.splice(index, 1);
		this.valueChanged();
	}

	protected valueChanged() {
		this.valueChange.emit(this.isEmpty ? undefined : [...this.value]);
	}

}
