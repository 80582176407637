import { AstNode, Query, SortDirections, mergeAstNodes } from '@unifii/sdk';

import { SortStatus } from '../../directives';
import { Context, Scope, SourceConfig } from '../../models';
import { normalizeAstNode } from '../../utils';
import { ExpressionParser } from '../expression-parser';

import { hasSourceConfigFilter, hasSourceConfigSort } from './source-config-functions';

/**
 *  Simple helper class for creating Queries from a SourceConfig
 *  Responsible for:
 *  - Applying $q
 *  - Find item by identifier
 *  - Sort
 *  - filters
 *  - Resolving expressions
 */
export class SourceConfigQueryBuilder {

	static readonly queryLimit = 50;

	constructor(
		private source: SourceConfig,
		private expressionParser: ExpressionParser,
	) { }

	create(q?: string, context?: Context, scope?: Scope, findByIdentifier?: string, filters?: AstNode): Query {

		let query = new Query();
		let astNode = filters;

		// Sort
		if (hasSourceConfigSort(this.source) && !findByIdentifier) {

			const sortStatus = SortStatus.fromString(this.source.sort);

			if (sortStatus) {
				query = query.sort(sortStatus.name, sortStatus.direction ?? SortDirections.Ascending);
			}
		}

		// Filter
		if (hasSourceConfigFilter(this.source)) {
			astNode = mergeAstNodes('and', this.source.filter, filters);
		}

		query = query.fromAst(normalizeAstNode(
			astNode,
			this.expressionParser,
			context,
			scope,
		));

		if (q) {
			query = findByIdentifier ? query.eq(findByIdentifier, q) : query.q(q);
		}

		query = query.limit(SourceConfigQueryBuilder.queryLimit);

		return query;
	}

}
