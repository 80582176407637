import { Component, OnInit } from '@angular/core';

import { ColumnGap, Size, StepperTemplate, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';

@Component({
	selector: 'sc-show-steppers',
	templateUrl: './show-steppers.html',
})
export class ShowSteppersComponent implements OnInit {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly staticTemplate = `
		<uf-stepper#orientation>
			<uf-step label="Step 1"#control>
				Step 1
			</uf-step>
			<uf-step label="Step 2"#control>
				Step 2
			</uf-step>
			<uf-step label="Step 3"#control>
				Step 3
			</uf-step>
		</uf-stepper>
	`;

	readonly orientationOptions = [
		{ name: 'Horizontal', value: StepperTemplate.Horizontal },
		{ name: 'Vertical', value: StepperTemplate.Vertical },
	];

	readonly validationOptions = [
		{ name: 'Off', value: '' },
		{ name: 'On', value: 'validate' },
	];

	readonly labelOptions = [
		{ name: 'On (Default)', value: '' },
		{ name: 'Off', value: 'off' },
	];

	control = new UfControlGroup({
		personalDetails: new UfControlGroup({
			firstName: new UfControl(),
			surname: new UfControl(),
		}),
		contactInfo: new UfControlGroup({
			phone: new UfControl(),
			email: new UfControl(),
		}),
		confirmation: new UfControlGroup({
			confirm: new UfControl(),
		}),
	});

	showDemo = true;
	orientation = StepperTemplate.Horizontal;
	validation = '';
	showLabels = '';
	template: string;
	labelsInputDisabled: boolean;

	ngOnInit() {
		this.valueChanges();
	}

	toggleValidation() {

		this.updateValidators(this.control.get('personalDetails') as UfControlGroup, this.validation !== '');
		this.updateValidators(this.control.get('contactInfo') as UfControlGroup, this.validation !== '');
		this.updateValidators(this.control.get('confirmation') as UfControlGroup, this.validation !== '');
	}

	valueChanges() {
		this.showDemo = false;
		this.template = this.getTemplate();
		setTimeout(() => { this.showDemo = true; }, 1);
	}

	orientationChange() {
		this.showLabels = '';
		this.labelsInputDisabled = this.orientation === StepperTemplate.Vertical;
	}

	get personDetailsControl(): UfControlGroup {
		return this.control.get('personalDetails') as UfControlGroup;
	}

	get contactInfoControl(): UfControlGroup {
		return this.control.get('contactInfo') as UfControlGroup;
	}

	get confirmationControl(): UfControlGroup {
		return this.control.get('confirmation') as UfControlGroup;
	}

	private getTemplate(): string {

		const orientationBinding = this.orientation === StepperTemplate.Vertical ? '' : ' orientation="Vertical"';
		const controlBinding = this.validation === '' ? '' : ' [control]="yourControl"';

		return this.staticTemplate.replace('#orientation', orientationBinding).replace(/#control/g, controlBinding);
	}

	private updateValidators(controlGroup: UfControlGroup, isRequired: boolean) {

		Object.keys(controlGroup.controls).forEach((key) => {

			const control = controlGroup.get(key) as UfControl;

			if (isRequired) {
				control.setValidators(ValidatorFunctions.required('This field is mandatory'));
				control.updateValueAndValidity();
			} else {
				control.clearValidators();
				control.updateValueAndValidity();
			}
		});

	}

}
