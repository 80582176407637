<div *ngIf="label" class="label">
	<span>{{label}}</span>
	<ng-content></ng-content>
</div>
<div [direction]="direction ?? 'Row'" [columns]="columns" [rows]="rows" [cellTotal]="labels.length"
	[ngClass]="{'half-screen': columns === 1 && hasContent}" [columnGap]="columnRowGap" ufGrid class="checkboxes">
	<ng-container *ngFor="let label of labels; let i = index;">
		<div [ngClass]="{'uf-box flat': hasContent, 'selected-border': hasContent && checkboxValues[i]}">
			<uf-checkbox *ngIf="options[i] let option" [name]="name" [label]="getOptionLabel(option)"
				[ngClass]="{'fill': hasContent}" [disabled]="disabled || (!checkboxValues[i] && maxLength == selected)"
				[content]="getContent(option)" [(value)]="checkboxValues[i]" (valueChange)="valueChanged()"
				(touchedChange)="control.markAsTouched()" />
		</div>
	</ng-container>
</div>

<uf-error [control]="control" />