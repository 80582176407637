import { Directive, ElementRef, Input, OnChanges, SimpleChanges, inject } from '@angular/core';
import { FormData, isStringNotEmpty } from '@unifii/sdk';

import { WorkflowStartState } from '../constants';

@Directive({
	selector: '[formDataLabel]',
})
export class FormDataLabelDirective implements OnChanges {

	@Input({ required: true }) formData: FormData;

	private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

	ngOnChanges(changes: SimpleChanges) {

		if (changes.formData?.previousValue === changes.formData?.currentValue) {
			return;
		}
		
		this.setLabel();
	}

	private setLabel() {
		const state = this.formData._state === WorkflowStartState ? undefined : this.formData._state;

		const label = [this.formData._seqId, state].filter(isStringNotEmpty).join(' - ');

		if (label) {
			this.elementRef.nativeElement.innerHTML = `(${label})`;
		}
	}

}
