import { NgModule } from '@angular/core';

import { CommonModule } from '@unifii/library/common';

import { FieldComponent } from './components';
import { SMART_FORM_DIRECTIVES } from './directives';

@NgModule({
	imports: [CommonModule],
	declarations: [FieldComponent, SMART_FORM_DIRECTIVES],
	exports: [CommonModule, FieldComponent, SMART_FORM_DIRECTIVES],
})
export class SmartFormsModule { }
