import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { toHexString } from '@unifii/sdk';
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en';

import { WindowWrapper } from '../native';
import { CommonTranslationKey } from '../translations';

export interface PasswordStrengthResult {
	score: number;
	label: string;
}

@Injectable({ providedIn: 'root' })
export class PasswordService {

	readonly characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

	constructor(
		@Inject(WindowWrapper) private window: Window,
		private translate: TranslateService,
	) {

		zxcvbnOptions.setOptions({
			translations: zxcvbnEnPackage.translations,
			dictionary: {
				...zxcvbnCommonPackage.dictionary,
				...zxcvbnEnPackage.dictionary,
			},
			// The next line is now recommended to get a good scoring.
			graphs: zxcvbnCommonPackage.adjacencyGraphs,
		});
	}

	/** Generate a string of 21 random characters */
	async generate(): Promise<string> {

		const createPassword = () => {
			const values = new Uint8Array(21);

			// values elements are replaced in place
			this.window.crypto.getRandomValues(values);

			return new Array(...values)
				.map((v) => this.characters[v % this.characters.length])
				.join('');
		};

		let password: string;
		let pwned: boolean;

		do {
			password = createPassword();
			pwned = await this.hasBeenPwned(password);
		} while (pwned);

		return password;
	}

	/**
	 * Use haveibeenpwnd public api
	 * documentation https://haveibeenpwned.com/API/v2#PwnedPasswords
	 */
	async hasBeenPwned(password: string): Promise<boolean> {

		try {
			const hexString = await toHexString(password);
			const url = `https://api.pwnedpasswords.com/range/${hexString.slice(0, 5)}`;
			const response = await fetch(url);
			const body = await response.text();

			return body.includes(hexString.toUpperCase().slice(5, hexString.length));
		} catch (e) {
			console.warn('Password pwned check failed', e);

			return false;
		}
	}

	getStrength(password?: string | null): PasswordStrengthResult | undefined {

		if (!password?.length) {
			return;
		}

		const score = zxcvbn(password).score;

		return {
			score,
			label: this.getStrengthLabel(score),
		};
	}

	private getStrengthLabel(score: number): string {
		switch (score) {
			case 0:
			case 1:
				return this.translate.instant(CommonTranslationKey.PasswordStrengthWeak) as string;
			case 2:
				return this.translate.instant(CommonTranslationKey.PasswordStrengthAverage) as string;
			case 3:
				return this.translate.instant(CommonTranslationKey.PasswordStrengthModerate) as string;
			default:
				return this.translate.instant(CommonTranslationKey.PasswordStrengthStrong) as string;
		}
	}

}
