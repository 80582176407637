<ng-template [ngIf]="mode === modes.Input">
	<uf-autocomplete [options]="results" [minSearchLength]="searchMinLength" [(value)]="value"
		(searchChange)="search($event)" (valueChange)="valueChanged()" nameProperty="_display" />
</ng-template>

<div *ngIf="mode === modes.Display && value" class="uf-chip x-small">
	{{ label }}: {{ value._display }}
	<button (click)="remove()" type="button" class="uf-action">
		<uf-icon name="delete" />
	</button>
</div>