import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { UfControlGroup } from '../controls';

@Pipe({
	name: 'asUfControlGroup',
})
export class AsUfControlGroupPipe implements PipeTransform {

	transform(value: AbstractControl): UfControlGroup {
		return value as UfControlGroup;
	}

}
