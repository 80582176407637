<uf-expander *ngIf="field" [actionBtn]="true">

	<div [ngClass]="cssClass" expanderHeader class="uf-app-bar medium">
		<div class="title">
			{{field.label}}
			<uf-help *ngIf="field.help" [content]="field.help" icon="infoSolid" class="grow" />
		</div>
		<button *ngIf="!control.disabled" [ngClass]="cssClass" [title]="sharedTermsTK.ActionClear | translate"
			(click)="updateContent()" type="button" class="uf-action">
			<uf-icon name="backspace" />
		</button>
		<button *ngIf="!control.disabled" [ngClass]="cssClass" [title]="sharedTermsTK.ActionGeolocate | translate"
			(click)="locate()" class="uf-action" type="button">
			<uf-icon name="myLocation" />
		</button>
	</div>

	<div expanderBody class="content grid">
		<uf-progress />
		<div *ngIf="control.disabled && isSummary" class="col-1of1">
			{{ content | dataDisplay: { type: 'GeoLocation' } }}
		</div>
		<ng-template [ngForOf]="fields" let-field ngFor>
			<!-- Standard fields -->
			<uf-number
				*ngIf="field.identifier && field.identifier !== 'map' && !(control.disabled && isSummary) && control.controls[field.identifier] let safeControl"
				[label]="field.label" [ngClass]="field.cssClass" [control]="safeControl | asUfControl"
				[disabled]="control.disabled" [(value)]="$any(content)[field.identifier]" step="0.000001" precision="6">
				<span *ngIf="!control.disabled" class="suffix">
					{{ field.suffix }}
				</span>
			</uf-number>
			<!-- Map -->
			<uf-map-input *ngIf="field.identifier === 'map' && control.controls[field.identifier] let safeControl"
				[disableClear]="true" [control]="safeControl | asUfControl" [value]="content"
				[disabled]="control.disabled" class="col-1of1" />
		</ng-template>
		<uf-error *ngIf="control.controls.preventDisabledBubble"
			[control]="control.controls.preventDisabledBubble | asUfControl" class="readonly-error" />
	</div>

</uf-expander>