<sc-page-content width="lg" identifier="app-bar">

	<ng-container editorInputs>
		<uf-radio [options]="sizeOptions" [(value)]="size" (valueChange)="modifierChange()" class="col-6 col-2-md"
			label="Sizes" columns="1" valueProperty="value" />
		<uf-radio [options]="colourOptions" [(value)]="colour" (valueChange)="modifierChange()" class="col-6 col-2-md"
			label="Colours" columns="1" valueProperty="value" />
		<uf-radio [options]="depthOptions" [(value)]="depth" (valueChange)="modifierChange()" class="col-6 col-2-md"
			label="Depth" columns="1" valueProperty="value" />
		<uf-multi-choice [options]="titleModifierOptions" [(value)]="titleModifiers" (valueChange)="modifierChange()"
			class="col-6 col-2-md" label="Title Modifiers" columns="1" valueProperty="value" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander>
			<div [ngClass]="[size, colour, depth]" class="uf-app-bar grow">
				<button type="button" class="uf-action">
					<uf-icon name="hamburger" />
				</button>
				<div [ngClass]="titleModifiers" class="title">
					Latest News
				</div>
				<uf-search />
				<button type="button" class="uf-action">
					<uf-icon name="address" />
				</button>
				<button type="button" class="uf-action">
					<uf-icon name="contextual" />
				</button>
			</div>
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>