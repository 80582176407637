<div class="grid">

	<div class="col-1of1 row space-children">
		<button (click)="clear()" class="uf-button" type="button">Clear</button>
	</div>

	<uf-search-result #addressSearch [options]="options" (searchChange)="search($event)"
		(valueChange)="results.push($event)" label="Find address" class="col-1of1" nameProperty="formatted_address" />

	<ng-template [ngForOf]="results" let-item ngFor target="_blank">
		<div class="col-1of3">
			<div class="uf-content-card">
				<div class="grid">
					<div class="col-1of1">
						<h3>{{item._display}}</h3>
					</div>
				</div>
			</div>
		</div>
	</ng-template>

	<uf-search-result #fruitSearch [options]="fruitOptions" [blurOnSelect]="false" [minSearchLength]="3"
		[nameProperty]="fruitNameProperty" (searchChange)="findFruits($event)"
		(valueChange)="selectedFruits.push($event)" label="Find some fruit" class="col-1of1" />

	<div *ngFor="let fruit of selectedFruits" class="col-1of3">
		<div class="uf-content-card">
			<div class="grid">
				<div class="col-1of1">
					<h3>{{fruit}}</h3>
				</div>
			</div>
		</div>
	</div>

</div>