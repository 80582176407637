import { EventEmitter, Injectable, OnDestroy, inject } from '@angular/core';
import { FieldType, FormData, isNotNull } from '@unifii/sdk';
import { Subject, Subscription, merge } from 'rxjs';

import { ContextProvider, ExpressionFunction, ExpressionParser, RuntimeDefinition, RuntimeField, RuntimeTransition,	UfControlGroup, UfExpressionFunctionsSet } from '@unifii/library/common';

import { DebugValidation } from '../constants';
import { FormSettings, StateTransitionInfo, WorkflowAction } from '../models';
import { FieldHelperFunctions, FormFunctions } from '../utils';

import { FormDebugger } from './form-debugger';
import { FormService } from './form.service';
import { ScopeManager } from './scope-manager';

/** Transition wrapper to cache the showIf expression function and the current showIf isVisible status */
interface StaticTransition {
	transition: RuntimeTransition;
	isVisible: boolean;
	showIfFunction?: ExpressionFunction;
}

/** UfFormComponent private instance */
@Injectable()
export class WorkflowService implements OnDestroy {

	definition: RuntimeDefinition | undefined;
	/** 1 to 1 to the workflow buttons */
	actions: WorkflowAction[] = [];
	/** Notify a transition has been request, indifferently of its validation success or failure */
	transitionRequested = new Subject<void>();
	stateChange = new EventEmitter<StateTransitionInfo>();
	updated = new EventEmitter<FormData>();

	private formData?: FormData;
	private subscriptions = new Subscription();
	private staticTransitions: StaticTransition[] = [];
	/** Both active and historical */
	private visibleSections = new Set<RuntimeField>();
	private scopeManager = inject(ScopeManager);
	private expressionParser = inject(ExpressionParser);
	private formService = inject(FormService);
	private contextProvider = inject(ContextProvider);
	private formSettings = inject(FormSettings);
	private formDebugger = inject(FormDebugger, { optional: true });
	private _actionsPosition: 'default' | 'floating' = 'default';
	private _workflowMode: 'stepper' | 'default' = 'default';

	get actionPosition(): 'default' | 'floating' {
		return this._actionsPosition;
	}

	set actionPosition(v: 'default' | 'floating') {
		if (this._actionsPosition === v) {
			return;
		}

		this._actionsPosition = v;

		this.updateVisibleActions();
	}

	get workflowMode(): 'stepper' | 'default' {
		return this._workflowMode;
	}

	set workflowMode(v: 'stepper' | 'default') {
		if (this._workflowMode === v) {
			return;
		}

		this._workflowMode = v;

		this.updateVisibleActions();
	}

	/** Can be disabled by FormDebugger */
	get isWorkflowEnabled(): boolean {
		return !this.formDebugger || this.formDebugger.enableWorkflow;
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/** Need to be invoked each time definition or formData change */
	init(definition: RuntimeDefinition, formData: FormData) {
		this.definition = definition;
		this.formData = formData;
		this.visibleSections = new Set();

		for (const section of this.definition.fields.filter((f) => f.type === FieldType.Section)) {
			if (this.isSectionActive(section, this.formData)) {
				this.visibleSections.add(section);
				continue;
			}

			if (this.isSectionHistorical(section, this.formData)) {
				this.visibleSections.add(section);
			}

			if (this.isWorkflowEnabled) {
				// Disable all controls which are historical or have not been rendered
				this.scopeManager.getControl(section)?.disable();
			}
		}

		this.updateStaticTransitions(this.definition, this.formData);
		this.updateVisibleActions();

		this.updated.emit(this.formData);
	}

	/**
	 * Validate the whole form or a list of sections (active sections that are editable and need validation)
	 * @param transition drive with/without required fields validation, see Transition.validate flag
	 * @param sections to be validated or Form root control if no sections are provided
	 * @returns true when all validations pass
	 */
	validate(transition?: RuntimeTransition, sections?: RuntimeField[]): boolean {

		if (transition?.source !== this.formData?._state) {
			console.warn(`WorkflowService.validate: transition source '${transition?.source}' doesn't match FormData._state ${this.formData?._state}`);

			return false;
		}
		
		const ignoreRequired = transition?.validate === false && !this.useDebuggerValidators;

		let isValid = true;

		// Whole form validation (special Submit action when no workflow is present)
		if (!sections?.length) {
			isValid = ignoreRequired ?
				this.scopeManager.validateExcludingRequired(this.scopeManager.control) :
				this.scopeManager.control.valid;
			
			if (!isValid) {
				this.scopeManager.control.setSubmitted();
			}
			
			this.transitionRequested.next();
			
			return isValid;
		}

		// Sections validation, the outcome is TRUE when every section is valid
		// All Sections are anyway validated in order to flag Submitted the control of the invalid ones
		for (const section of sections) {
			const sectionControl = this.scopeManager.getControl(section) as UfControlGroup | null;

			if (!sectionControl) {
				console.warn('Control not found for Section', section.label);

				// TODO Why not do this.transitionRequested.emit() in this scenario?
				return false;
			}

			const isSectionValid = ignoreRequired ?
				this.scopeManager.validateExcludingRequired(sectionControl) :
				sectionControl.valid;
			
			if (!isSectionValid) {
				sectionControl.setSubmitted();
			}

			isValid = isValid && isSectionValid;
		}

		this.transitionRequested.next();

		return isValid;
	}

	/**
	 * To request the execution of the workflowAction
	 * @param workflowAction to be executed
	 * @returns true if the execution is possible
	 */
	trigger(workflowAction: WorkflowAction): boolean {
		const formData = this.formData;

		if (!formData) {
			throw Error('WorkflowService: action triggered without service initialized');
		}

		if (this.formService.disabled || this.formService.inProgress) {
			return false;
		}

		const transition = workflowAction.transition;
		
		const sections = this.definition?.fields.filter((field) =>
			field.type === FieldType.Section
			&& this.isSectionActive(field, formData),
		);

		if (!this.validate(transition, sections)) {
			return false;
		}

		this.stateChange.emit(Object.assign({}, workflowAction, {
			formData: this.createNextFormData(formData, transition),
		}));

		return true;
	}

	isVisible(field: RuntimeField): boolean {

		// Static visibility (Section can ignore if when already executed)
		if (!this.formService.isGranted(field) && field.type !== FieldType.Section) {
			return false;
		}

		// No Workflow, apply static visibility
		if (!this.isWorkflowEnabled) {
			return this.formService.isGranted(field);
		}

		// Workflow enabled
		if (field.type === FieldType.Section) {
			return this.visibleSections.has(field);
		}

		return this.formService.isGranted(field);
	}

	isActive(field: RuntimeField): boolean {
		if (this.formSettings.print || this.formService.disabled) {
			return false;
		}

		if (!this.isWorkflowEnabled) {
			return true;
		}

		const section = this.getParentSection(field);

		return section ? section.transitions.some((transition) => this.formData?._state === transition.source) : true;
	}

	/** Pick the first actionable ActionGroup within the transition's Section */
	private getFirstActionableActionGroup(transition: RuntimeTransition): RuntimeField | undefined {
		return transition.parent.fields.find((field) =>
			field.type === FieldType.ActionGroup &&
			field.showOn === transition.action &&
			this.formService.isGranted(field),
		);
	}

	/** Regenerate the list of WorkflowAction based on the StaticTransitions status */
	private updateVisibleActions() {
		if (!this.isWorkflowEnabled || this.formSettings.print) {
			this.actions = [];
			
			return;
		}

		this.actions = this.staticTransitions
			// Only staticActions that are visible (based on their showIf expression current value)
			.filter((staticTransition) => staticTransition.isVisible)
			// Extract the WorkflowAction
			.map((staticTransition) => staticTransition.transition)
			// Apply workflow and position visibility
			.filter((transition) =>
				transition.hasPersistentVisibility
				|| (this.workflowMode === 'default' && this.actionPosition === 'default'),
			)
			// A single WorkflowAction is create as "merge" of all the equivalent Transitions
			.reduce<WorkflowAction[]>((workflowActions, transition) => {
				/*
				* The winning Transition
				* 	the first Section's Transition is kept, then eventually overridden by the last Transition's Section with an actionable ActionGroup
				* The winning ActionGroup
				* 	the first actionable ActionGroup of the last Transition's Section with an actionable ActionGroup
				*/
				const existingWorkflowAction = workflowActions.find((u) =>
					u.transition.action === transition.action
					&& u.transition.target === transition.target,
				);

				if (!existingWorkflowAction) {
					workflowActions.push({
						transition,
						actionGroup: this.getFirstActionableActionGroup(transition),
					});
				} else {
					const actionGroup = this.getFirstActionableActionGroup(transition);

					if (actionGroup) {
						existingWorkflowAction.actionGroup = actionGroup;
						existingWorkflowAction.transition = transition;
					}
				}

				return workflowActions;
			}, []);
	}

	private isSectionActive(section: RuntimeField, formData: FormData): boolean {
		if (!this.formService.isGranted(section)) {
			return false;
		}

		return section.transitions.filter((transition) =>
			// filter transitions by matching role to user
			FieldHelperFunctions.areRolesMatching(this.userRoles, transition.roles),
		).some((transition) =>
			// Check source state matches current state
			transition.source === formData._state,
		);
	}

	private isSectionHistorical(section: RuntimeField, formData: FormData): boolean {
		return section.transitions.some((transition) =>
			(formData._history ?? []).some((historyEntry) =>
				historyEntry.state === transition.source && historyEntry.action === transition.action,
			),
		);
	}

	// TODO refactor to simpler implementation
	private getParentSection(field: RuntimeField): RuntimeField | undefined {

		if (field.type === FieldType.Section) {
			return field;
		}

		const parent = field.parent;

		if (!parent) {
			return;
		}

		return parent.type === FieldType.Section ?
			parent :
			this.getParentSection(parent);
	}

	private get userRoles(): string[] {
		return this.contextProvider.get().user?.roles ?? [];
	}

	private get useDebuggerValidators(): boolean {
		return !!this.formDebugger &&
			[DebugValidation.Off, DebugValidation.ExcludeRequired].includes(this.formDebugger.validation);
	}

	/** Update the list of StaticTransitions based on the FormData _state */
	private updateStaticTransitions(definition: RuntimeDefinition, formData: FormData) {
		this.subscriptions.unsubscribe();
		this.subscriptions = new Subscription();

		// Clean previous actions
		this.staticTransitions = [];

		const currentWorkflowTransitions = this.getWorkflowStateTransitions(definition, formData);
		
		// Wrap actions with showIf function/listener
		this.staticTransitions = currentWorkflowTransitions.map(this.toStaticTransition.bind(this));
	}

	private onDependenciesChangeUpdateStaticTransitionVisibility(staticTransition: StaticTransition) {
		const result = this.evaluateTransitionShowIfExpression(staticTransition);

		if (staticTransition.isVisible !== result) {
			staticTransition.isVisible = result;
			this.updateVisibleActions();
		}
	}

	private toStaticTransition(transition: RuntimeTransition): StaticTransition {

		const staticTransition: StaticTransition = {
			transition,
			isVisible: true,
		};

		try {
			staticTransition.showIfFunction = this.expressionParser.getFunc(transition.showIf) ?? undefined;
		} catch (e) {
			console.warn(`Parse transition '${transition.source} > ${transition.target} [${transition.action}]' showIf '${transition.showIf}'`, e);

			return staticTransition;
		}

		// Evaluate the transition visibility
		staticTransition.isVisible = this.evaluateTransitionShowIfExpression(staticTransition);

		// Register visibility update on transition dependencies changes
		const transitionDependencyControls = transition.dependencies
			.map((field) => this.scopeManager.getControl(field))
			.filter(isNotNull);

		for (const control of transitionDependencyControls) {
			/**
			 * Control status change listeners used instead of registering OnValueUpdate because we require
			 * bindings to run before evaluating expression // for readonly case?
			 */
			this.subscriptions.add(merge(control.statusChanges, control.valueChanges).subscribe(() => {
				this.onDependenciesChangeUpdateStaticTransitionVisibility(staticTransition);
			}));
		}

		return staticTransition;
	}

	private evaluateTransitionShowIfExpression(staticTransition: StaticTransition): boolean {

		if (!staticTransition.showIfFunction) {
			return true;
		}

		try {
			const context = this.scopeManager.createContext();
			
			return !!staticTransition.showIfFunction(this.scopeManager.scope, context, UfExpressionFunctionsSet);
		} catch (e) {
			console.warn(`Execute '${staticTransition.transition.source} > ${staticTransition.transition.target} [${staticTransition.transition.action}]' showIf '${staticTransition.transition.showIf}'`, e);

			return true;
		}
	}
	
	/**
	 * Retrieve all the transitions accessible and matching the FormData._state
	 */
	private getWorkflowStateTransitions(definition: RuntimeDefinition, formData: FormData): RuntimeTransition[] {
		const workflowCurrentTransitions: RuntimeTransition[] = [];
		const allowedSections = definition.fields.filter((field) =>
			// Sections accessible by User's roles
			field.type === FieldType.Section
			&& FieldHelperFunctions.areRolesMatching(this.userRoles, field.roles),
		);

		for (const section of allowedSections) {
			// Transitions matching the FormData._state and accessible by User's roles
			const sectionActiveTransitions = section.transitions.filter((transition) =>
				transition.source === formData._state
				&& FieldHelperFunctions.areRolesMatching(this.userRoles, transition.roles),
			);

			workflowCurrentTransitions.push(...sectionActiveTransitions);
		}

		return workflowCurrentTransitions;
	}

	private createNextFormData(formData: FormData, transition: RuntimeTransition): FormData {
		// Return a copy of the formData to avoid changes before transition is completed by done() callback
		const next = structuredClone(formData);
		const user = this.contextProvider.get().user;

		// Metadata managed by client side
		next._history = next._history ?? [];
		next._history.unshift({ state: formData._state!, action: transition.action });
		next._action = transition.action;
		next._state = transition.target;
		next._result = transition.result;
		next._completedAt = FormFunctions.getUTCTime();

		// Metadata managed by server side
		// Those get updated by client side as temporary values before server side save occurs
		// To cover missing server side save when data is saved only in offline queue
		if (user) {
			next._lastModifiedBy = user.username;
			next._createdBy = next._createdBy ?? user.username;
		}

		return next;
	}

}
