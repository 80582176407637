import { DataSeed, DataSourceType, FieldType } from '@unifii/sdk';

import { CollectionItemMetadataIdentifiers, CompanyIdentifiers, DataSourceDisplayLabel, DataSourceDisplayTo,
	DataSourceIdLabel, DataSourceIdTo, DataSourceUserFullNameExpression, FormDefinitionMetadataIdentifiers,
	UserInfoIdentifiers } from '../../constants';
import { SourceConfigBucket, SourceConfigCollection, SourceConfigCompany, SourceConfigMapping, SourceConfigUser } from '../../models';

import { sourceConfigMappingsToSourceConfigMappingInfo } from './data-source-functions';

export const sortByDisplay = (a: DataSeed, b: DataSeed): number => a._display > b._display ? 1 : -1;

export const getUserDefaultSourceConfig = (): SourceConfigUser => ({
	type: DataSourceType.Users,
	sort: UserInfoIdentifiers.Username,
	...sourceConfigMappingsToSourceConfigMappingInfo([
		getIdDefaultMapping(UserInfoIdentifiers.Id),
		getDisplayDefaultMapping(DataSourceUserFullNameExpression),
	]),
});

export const getUserByUsernameSourceConfig = (): SourceConfigUser => {
	const sourceConfig = getUserDefaultSourceConfig();
	const idMapping = sourceConfig.mappingsTo[DataSourceIdTo];

	if (idMapping) {
		idMapping.from = UserInfoIdentifiers.Username;
	}

	return sourceConfig;
};

export const getCompanyDefaultSourceConfig = (): SourceConfigCompany => ({
	type: DataSourceType.Company,
	sort: CompanyIdentifiers.Name,
	...sourceConfigMappingsToSourceConfigMappingInfo([
		getIdDefaultMapping(CompanyIdentifiers.Id),
		getDisplayDefaultMapping(CompanyIdentifiers.Name),
	]),
});

export const getBucketDefaultSourceConfig = (id: string): SourceConfigBucket => ({
	type: DataSourceType.Bucket,
	id,
	...sourceConfigMappingsToSourceConfigMappingInfo([
		getIdDefaultMapping(FormDefinitionMetadataIdentifiers.Id),
		getDisplayDefaultMapping(FormDefinitionMetadataIdentifiers.Id),
	]),
});

export const getCollectionDefaultSourceConfig = (id: string): SourceConfigCollection => ({
	type: DataSourceType.Collection,
	id,
	...sourceConfigMappingsToSourceConfigMappingInfo([
		getIdDefaultMapping(CollectionItemMetadataIdentifiers.Id),
		getDisplayDefaultMapping(CollectionItemMetadataIdentifiers.Title),
	]),
});

const getIdDefaultMapping = (from: string): SourceConfigMapping => ({
	from,
	to: DataSourceIdTo,
	type: FieldType.Text,
	label: DataSourceIdLabel,
});

const getDisplayDefaultMapping = (from: string): SourceConfigMapping => ({
	from,
	to: DataSourceDisplayTo,
	type: FieldType.Text,
	label: DataSourceDisplayLabel,
});
