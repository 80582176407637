import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { SortDirection, SortDirections } from '@unifii/sdk';

export class SortStatus {

	constructor(public name: string, public direction?: SortDirection) { }

	static fromString(value: string | null | undefined): SortStatus | null {
		// Normalize
		value = (value ?? '').trim();

		// Check for empty
		if (value.length === 0 || value === '-') {
			return null;
		}

		// Parse literal value
		return new SortStatus(
			value.startsWith('-') ? value.substring(1) : value,
			value.startsWith('-') ? SortDirections.Descending : SortDirections.Ascending,
		);
	}

	static same(a: SortStatus | null | undefined, b: SortStatus | null | undefined): boolean {
		return (a ? a.toString() : null) === (b ? b.toString() : null);
	}

	toString = (): string =>
		(this.direction === SortDirections.Ascending ? '' : '-') + this.name;

}

export interface Sortable {
	/** The id of the item being sorted. */
	id: string;
	/** Update internal render based on master sort status */
	refresh(): void;
}

/** Master reference for Sortable items, orchestrate the sort status. */
@Directive({
	selector: '[ufSort]',
})
export class SortDirective {

	@Input('ufSort') set sort(v: SortStatus | undefined) {
		this._sort = v;
		this.refreshChildren();
	}

	get sort(): SortStatus | undefined {
		return this._sort;
	}

	/** Event emitted when the user changes either the active sort or sort direction. */
	@Output() readonly sortChange: EventEmitter<SortStatus> = new EventEmitter<SortStatus>();

	/** Collection of all registered sortables that this directive manages. */
	sortables = new Map<string, Sortable>();

	private _sort?: SortStatus;

	/** Register function to be used by the contained TableSortHeaderDirectives. Adds the MatSortable to the
	 * collection of TableSortHeaderDirectives.
	 */
	register(sortable: Sortable) {
		if (!sortable.id) {
			throw new Error('SortDirective - Sortable missing id');
		}

		if (this.sortables.has(sortable.id)) {
			throw new Error(`SortDirective - Sortable id duplicated ${sortable.id}`);
		}

		this.sortables.set(sortable.id, sortable);
	}

	/**
	 * Unregister function to be used by the contained TableSortHeaderDirectives.
	 * Removes the TableSortHeaderDirective from the collection of contained Sortables.
	 */
	deregister(sortable: Sortable) {
		if (this.sort?.name === sortable.id) {
			this.updateSort();
		}
		this.sortables.delete(sortable.id);
	}

	/** Sets the active sort id and determines the new sort direction
	 *
	 * @input force - sortables is based on registered sort element that are connected to
	 * existing Component, when a component is removed  (ex. hidden column) this result
	 * not sortable anymore even when the original configuration mark it as sortable (see TableColumnConfig)
	 * The force parameters bypass the check on sortables list
	 */
	sortBy(id?: string, force = false) {

		if (id == null || (!this.sortables.has(id) && !force)) {
			// Guard invalid sort request
			this.updateSort();
		} else if (this.sort?.name === id) {
			// Continue to sort on the active id
			const next = this.nextDirection(this.sort.direction);

			if (!next) {
				this.updateSort();
			} else {
				this.updateSort(new SortStatus(this.sort.name, next));
			}
		} else {
			// Start on a different sortable id
			this.updateSort(new SortStatus(id, this.nextDirection()));
		}
	}

	/** Notify children that sort status has been updated */
	private refreshChildren() {
		for (const sortable of this.sortables.values()) {
			sortable.refresh();
		}
	}

	/** Returns the next direction in the sort cycle */
	private nextDirection(direction?: SortDirection): SortDirection | undefined {
		switch (direction) {
			case SortDirections.Ascending: return SortDirections.Descending;
			case SortDirections.Descending: return undefined;
			default: return SortDirections.Ascending;
		}
	}

	private updateSort(value?: SortStatus) {
		if (SortStatus.same(this.sort, value)) {
			return;
		}

		this.sort = value;
		this.sortChange.emit(this.sort);
	}

}
