<sc-page-content width="xl" identifier="filters">
	<ng-container editor>
		<uf-tabs (selectedChange)="configChange()" class="col-12">
			<uf-tab label="Manual">
				<div class="uf-form-card col-12">
					<div class="uf-grid pad gaps">
						<uf-select [options]="entityTypes" [(value)]="entityType" (valueChange)="configChange()"
							label="Entity Type" nameProperty="name" valueProperty="identifier"
							placeholder="Select an entity type" class="col-4" />
						<uf-autocomplete *ngIf="entityType === 'Bucket' && buckets" [options]="bucketOptions"
							[(value)]="bucketSelected" (valueChange)="configChange()"
							(searchChange)="searchBucket($event)" label="Select bucket" class="col-8" />
						<uf-autocomplete *ngIf="entityType === 'Collection' && collections"
							[options]="collectionOptions" [(value)]="collectionSelected" (valueChange)="configChange()"
							(searchChange)="searchCollection($event)" label="Select collection" nameProperty="name"
							class="col-8" />
					</div>
					<div class="uf-form-actions">
						<button *ngIf="isManualConfigured" (click)="manualFilters()" type="button"
							class="uf-button primary">
							Init Filters
						</button>
					</div>
				</div>
			</uf-tab>
			<uf-tab label="Table">
				<div class="uf-form-card col-12">
					<div class="uf-grid pad gaps">
						<uf-autocomplete *ngIf="tables" [options]="tableOptions" [(value)]="tableSelected"
							(searchChange)="searchTable($event)" (valueChange)="configChange()" label="Select table"
							nameProperty="title" class="col-12" />
					</div>
					<div class="uf-form-actions">
						<button *ngIf="tableSelected" (click)="tableFilters()" type="button" class="uf-button primary">
							Init Filters
						</button>
					</div>
				</div>
			</uf-tab>
			<uf-tab label="Field DS">
				<div class="uf-form-card col-12">
					<div class="uf-grid pad gaps">
						<uf-autocomplete *ngIf="forms" [options]="formOptions" [(value)]="formSelected"
							(searchChange)="searchForm($event)" (valueChange)="formChange()" label="Select form"
							nameProperty="name" class="col-12" />
						<uf-message *ngIf="!fields.length && formSelected"
							heading="No DataSource fields with visible filters in this form"
							class="warning col-12 gap-top" />
						<uf-autocomplete *ngIf="fields.length" [options]="fieldOptions" [(value)]="fieldSelected"
							(searchChange)="searchField($event)" label="Select field" nameProperty="label"
							class="col-12" />
					</div>
					<div class="uf-form-actions">
						<button *ngIf="fieldSelected" (click)="fieldFilters()" type="button" class="uf-button primary">
							Init Filters
						</button>
					</div>
				</div>
			</uf-tab>
		</uf-tabs>

		<uf-message *ngIf="configError" class="error col-12 gap-top">
			<h3>{{ configError }}</h3>
		</uf-message>

		<ng-template [ngIf]="filterManager">
			<div class="uf-form-card col-12 gap-top">
				<div class="uf-app-bar">
					<button *ngIf="hasFilters" (click)="filterValues = {}; onFiltersChange()" type="button"
						title="Clear" class="uf-action tertiary">
						<uf-icon name="delete" />
					</button>
					<h3>Chips</h3>
				</div>
				<div class="uf-grid pad gaps">
					<uf-filter-display *ngIf="filterManager" [manager]="filterManager" [value]="filterValues"
						(valueChange)="onFiltersChange()" class="col-12" />
				</div>
			</div>

			<div class="uf-form-card col-12 gap-top">
				<div class="uf-app-bar">
					<uf-checkbox *ngIf="hiddenFilter" [value]="combineHiddenFilter"
						(valueChange)="combineHiddenFilter = !combineHiddenFilter; onFiltersChange()"
						label="Combine hidden filters" />
					<h3>Query</h3>
				</div>
				<div class="uf-grid pad gaps">
					<uf-textarea [disabled]="true" [value]="query" class="col-12" />
				</div>
			</div>

			<div class="uf-form-card col-12 gap-top">
				<div class="uf-app-bar">
					<h3>Data Seeds</h3>
				</div>
				<div class="uf-grid pad gaps">
					<span *ngIf="!loaderError">{{dataSeeds.length}} seed(s)</span>
					<uf-message *ngIf="loaderError" class="error col-12 gap-top">
						<h3>{{ loaderError }}</h3>
					</uf-message>
				</div>
			</div>

			<div class="uf-form-card col-12 gap-top">
				<div class="uf-app-bar">
					<div class="uf-grid condensed column-gap gap-top">
						<button *ngIf="serializedFilterValues" (click)="showFiltersValues()" type="button"
							class="uf-button secondary">
							Filters Values
						</button>
						<button *ngIf="deserializedFilterValues" (click)="showFiltersURL()" type="button"
							class="uf-button secondary">
							Filters URL
						</button>
						<button *ngIf="astNode" (click)="showAstNode()" type="button" class="uf-button secondary">
							AstNode
						</button>
					</div>
					<h3>Filters</h3>
				</div>
				<div class="uf-grid pad gaps">
					<uf-filter-inputs *ngIf="filterManager" [manager]="filterManager" [value]="filterValues"
						(valueChange)="onFiltersChange()" class="col-12" />
				</div>
			</div>
		</ng-template>

	</ng-container>
</sc-page-content>