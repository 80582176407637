<label *ngIf="label">
	{{label}}
	<ng-content></ng-content>
</label>
<div class="input-wrap">
	<select [attr.id]="id" [attr.name]="name" [formControl]="inputControl" (focus)="focused = true"
		(blur)="focused = false; control.markAsTouched();" type="select">
		<!-- An unmatched value is a value that doesn't exist in the options list -->
		<option *ngIf="unmatchedValue" [ngValue]="unmatchedValue" disabled>
			{{ unmatchedValue | nameProperty: nameProperty }}
		</option>
		<option *ngIf="placeholder" [ngValue]="null">
			{{ placeholder }}
		</option>
		<!-- hack for safari to ensure that behavior is the same if placeholder is not provided -->
		<option *ngIf="!placeholder && !unmatchedValue" class="safari-only" disabled></option>
		<option *ngFor="let option of options" [ngValue]="option">
			{{option | nameProperty: nameProperty}}
		</option>
	</select>
	<uf-error [control]="control" />
	<uf-underline *ngIf="!disabled" [class.active]="focused" />
	<div class="input-icon picker-icon">
		<uf-icon name="arrowDown" />
	</div>
</div>