import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { DataType, HierarchyUnitFormData } from '@unifii/sdk';

import { FilterEntry, FilterViewMode, IFilterComponent } from '../../../models';
import { DataDisplayService } from '../../../services';
import { CommonTranslationKey, SharedTermsTranslationKey } from '../../../translations';
import { getValueAsArray } from '../../../utils';

@Component({
	selector: 'uf-filter-hierarchies',
	templateUrl: './filter-hierarchies.html',
	styleUrls: ['./field-filter.less'],
})
export class FilterHierarchiesComponent implements IFilterComponent<HierarchyUnitFormData[]> {

	@Input() mode = FilterViewMode.Input;
	@Input({ required: true }) entry: FilterEntry;
	@Input({ required: true }) value: HierarchyUnitFormData[] | null;

	@Output() valueChange = new EventEmitter<HierarchyUnitFormData[] | null>();

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;
	protected readonly modes = FilterViewMode;

	private dataDisplayService = inject(DataDisplayService);

	get label(): string {
		return this.entry.label;
	}

	get isEmpty(): boolean {
		return !this.value?.length;
	}

	protected get displayValue(): string | null {
		return this.dataDisplayService.displayAsString(this.value, { type: DataType.HierarchyUnit, format: this.entry.format });
	}

	protected remove(index: number) {
		if (!this.value) {
			return;
		}

		this.value.splice(index, 1);
		this.onValueChange(this.value);
	}

	protected onValueChange(value: HierarchyUnitFormData | HierarchyUnitFormData[] | null) {
		const arrayValue = getValueAsArray(value);

		this.valueChange.emit(arrayValue.length ? [...arrayValue] : null);
	}

}
