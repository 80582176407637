import { DataSourceType, FieldType, isBoolean, isDictionary, isFileData, isOptionalType, isString, isStringNotEmpty, isValueOfStringEnumType } from '@unifii/sdk';

import { DataDisplayListItem, FileDataExtended, SourceConfig, SourceConfigMapping } from '../models';

/** Value satisfies FileDataExtended */
export const isFileDataExtended = (value: unknown): value is FileDataExtended =>
	isDictionary(value) &&
	isOptionalType(value.title, isString) &&
	isOptionalType(value.filename, isString) &&
	isOptionalType(value.url, isString) &&
	isFileData(value);

export const isDataDisplayListItem = (value: unknown): value is DataDisplayListItem =>
	isDictionary(value) &&
	isOptionalType(value.term, isString) &&
	isOptionalType(value.help, isString) &&
	Object.hasOwn(value, 'data');

export const isSourceConfigMapping = (value: unknown): value is SourceConfigMapping =>
	isDictionary(value) &&
	isStringNotEmpty(value.from) &&
	isStringNotEmpty(value.to) &&
	isStringNotEmpty(value.label) &&
	isValueOfStringEnumType(FieldType)(value.type) &&
	isOptionalType(value.itemTemplate, isStringNotEmpty) &&
	isOptionalType(value.isExpression, isBoolean) &&
	isOptionalType(value.isVisible, isBoolean) &&
	isOptionalType(value.hideEmpty, isBoolean) &&
	isOptionalType(value.isReportable, isBoolean) &&
	isOptionalType(value.isExpression, isBoolean);

/** // TODO improve this loose implementation */
export const isSourceConfig = (value: unknown): value is SourceConfig =>
	isDictionary(value) &&
	isValueOfStringEnumType(DataSourceType)(value.type);
