<label *ngIf="label">
	{{label}}
</label>
<div class="input-wrap">
	<input #input [formControl]="control" [attr.name]="name"
		[placeholder]="placeholder ? placeholder : (commonTK.SearchInputPlaceholder | translate)" [readonly]="disabled"
		[hasSearchConfigured]="hasSearchConfigured" [minSearchLength]="minSearchLength"
		[minSearchLengthMsg]="minSearchLengthMsg" [minSearchClass]="minSearchClass" type="search" listBoxResults />
	<div [class.input-icon--clickable]="showClear" (click)="clear()" class="input-icon">
		<uf-icon *ngIf="!showClear" name="search" />
		<uf-icon *ngIf="showClear" [title]="sharedTermsTK.ActionClear | translate" name="backspace" />
	</div>
	<uf-underline *ngIf="!disabled" [class.active]="focused" />
</div>