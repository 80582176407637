<ng-template [ngIf]="mode === modes.Input">
	<uf-chips [options]="results" [minSearchLength]="searchMinLength" [(value)]="value" (searchChange)="search($event)"
		(valueChange)="valueChanged()" nameProperty="_display" />
</ng-template>

<ng-template [ngIf]="mode === modes.Display && !isEmpty">
	<div *ngFor="let v of value; let i = index" class="uf-chip x-small">
		{{ label }}: {{ v._display }}
		<button (click)="remove(i)" type="button" class="uf-action">
			<uf-icon name="delete" />
		</button>
	</div>
</ng-template>