export * from './array-filter.pipe';
export * from './as-safe-abstract-control.pipe';
export * from './as-uf-control-array.pipe';
export * from './as-uf-control-group.pipe';
export * from './as-uf-control.pipe';
export * from './as-uf-controls-groups.pipe';
export * from './as-uf-controls.pipe';
export * from './data-display.pipe';
export * from './flatten.pipe';
export * from './name-property.pipe';
export * from './source-config-mappings.pipe';

import { ArrayFilterPipe } from './array-filter.pipe';
import { AsUfSafeAbstractControlPipe } from './as-safe-abstract-control.pipe';
import { AsUfControlArrayPipe } from './as-uf-control-array.pipe';
import { AsUfControlGroupPipe } from './as-uf-control-group.pipe';
import { AsUfControlPipe } from './as-uf-control.pipe';
import { AsUfControlsGroupsPipe } from './as-uf-controls-groups.pipe';
import { AsUfControlsPipe } from './as-uf-controls.pipe';
import { DataDisplayPipe } from './data-display.pipe';
import { FlattenPipe } from './flatten.pipe';
import { NamePropertyPipe } from './name-property.pipe';
import { UfSourceConfigMappingsPipe } from './source-config-mappings.pipe';

export const COMMON_PIPES = [
	AsUfSafeAbstractControlPipe, AsUfControlArrayPipe, AsUfControlGroupPipe, AsUfControlPipe, AsUfControlsGroupsPipe, AsUfControlsPipe,
	DataDisplayPipe, ArrayFilterPipe, FlattenPipe, NamePropertyPipe, UfSourceConfigMappingsPipe,
];
