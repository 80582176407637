import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

import { ClipboardService } from '@unifii/library/common';

@Component({
	selector: 'sc-code-editor-expander',
	templateUrl: './show-code-editor-expander.html',
	styleUrls: ['./show-code-editor-expander.less'],
})
export class ShowCodeEditorExpanderComponent implements AfterViewInit {

	@ViewChild('content') content: ElementRef;
	@Input() expanded: boolean;
	@Input() showCopy = true;
	@Input() showCode = true;

	innerHTML: string;
	visible: boolean;

	@Input() set code(v: string) {
		this._code = v;
	}

	get code() {
		return this._code;
	}

	private _code: string;

	constructor(
		private clipboard: ClipboardService,
	) { }

	ngAfterViewInit() {

		if (this.code == null) {
			this.updateInnerHTML();
		}
	}

	copy() {
		this.clipboard.setText(this.code || this.innerHTML);
	}

	updateInnerHTML() {

		if (this.content == null) {
			return;
		}

		this.innerHTML = this.replaceIcons(this.content.nativeElement.innerHTML)
		// Remove comments, angular classes, double white spaces, new lines
			.replace(/<!--[\s\S]*?-->|_ng[^ ]*?=\"\"|ng[^ ]*?=\"[^ ]\"| {2}|\n/g, '')
		// Add new lines between tags
			.replace(/></g, '> \n<');

	}

	private replaceIcons(innerHTML: string): string {

		const icons = innerHTML.match(/<uf-icon([\s\S]*?)<\/uf-icon>/g) || [];

		for (const icon of icons) {

			const href = icon.match(/href=\"#.*?"/) || [];

			if (href.length > 0) {
				const name = href[0]?.replace('href="#', '').replace('">', '');

				innerHTML = innerHTML.replace(icon, `<uf-icon name="${name}></uf-icon>`);
			}
		}

		return innerHTML;
	}

}
