import { Component, HostBinding, inject } from '@angular/core';

import { CommonTranslationKey, SharedTermsTranslationKey } from '../../../translations';
import { Modal, ModalRuntime } from '../../modal';

import { BarcodeScannerModalProvider } from './barcode-scanner-modal-provider';

export const UfBarcodeScannerModalTextProvider: BarcodeScannerModalProvider = () => BarcodeScannerModalTextComponent;

@Component({
	selector: 'uf-barcode-scanner-modal-text',
	templateUrl: './barcode-scanner-modal-text.html',
})
export class BarcodeScannerModalTextComponent implements Modal<void, string> {

	@HostBinding('class.uf-form-card') formCardClass = true;

	runtime = inject<ModalRuntime<void, string | undefined>>(ModalRuntime);

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;
	protected barcode: string | undefined;

	close() {
		this.runtime.close();
	}

	protected submit() {
		this.runtime.close(this.barcode);
	}

}
