import { Component, HostBinding, inject } from '@angular/core';

import { SharedTermsTranslationKey } from '../../../translations';
import { Modal } from '../modal';
import { ModalRuntime } from '../modal-runtime';
import { ModalData } from '../modal-types';

export interface ContentModalData {
	markdown?: string;
	imageUrl?: string;
	imageAlt?: string;
}

/**
 * Generic way of displaying markdown and images in a modal
 */
@Component({
	selector: 'uf-content-modal',
	templateUrl: './content-modal.html',
	styleUrls: ['./content-modal.less'],
})
export class ContentModalComponent implements Modal<ContentModalData, null> {

	@HostBinding('class.uf-form-card') cardClass = true;

	runtime = inject<ModalRuntime<ContentModalData, null>>(ModalRuntime);
	data = inject<ContentModalData>(ModalData);

	protected readonly sharedTermsTK = SharedTermsTranslationKey;

	close() {
		this.runtime.close();
	}

}
