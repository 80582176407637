<label *ngIf="label">
	{{label}}
	<ng-content></ng-content>
</label>
<!-- Box -->
<div *ngIf="template === 'box'">
	<p *ngIf="value" class="small gap-sm-top gap-none-bottom">{{ value | dataDisplay: { type: 'HierarchyUnit' } }}</p>
	<p *ngIf="!value && placeholder" class="small gap-sm-top gap-none-bottom light">{{ placeholder }}</p>
	<div class="row space-children--small">
		<button *ngIf="value" (click)="applyValue(undefined)" class="uf-button x-small right" type="button">
			{{ sharedTK.ActionClear | translate }}
		</button>
		<button #showPickerButton [class.right]="!value" (click)="showPicker()" class="uf-button x-small" type="button">
			{{ (!value ? sharedTK.ActionAdd : sharedTK.ActionEdit ) | translate }}
		</button>
	</div>

</div>
<!-- Input -->
<div *ngIf="template === 'input'" class="input-wrap">
	<input [value]="value | dataDisplay: { type: 'HierarchyUnit' }" [readonly]=" true" [attr.placeholder]="placeholder"
		(click)="showPicker();" type="text">
	<div class="input-icon">
		<uf-icon class="clear-btn" name="hierarchy" />
	</div>
</div>

<uf-error [control]="control" />