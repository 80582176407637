import { Component, OnInit } from '@angular/core';
import { Address, CompoundType, Field, FieldType, GeoLocation, generateUUID } from '@unifii/sdk';

import { RuntimeDefinitionAdapter, RuntimeField, UfControlGroup } from '@unifii/library/common';
import { ComponentRegistry } from '@unifii/library/smart-forms';
import { UfFormComponentRegistry } from '@unifii/library/smart-forms/input';

@Component({
	selector: 'sc-show-fields',
	templateUrl: './show-fields.html',
	providers: [{ provide: ComponentRegistry, useClass: UfFormComponentRegistry }],
})
export class ShowFieldsComponentsComponent implements OnInit {

	ready: boolean;
	controls: UfControlGroup;
	entries: { field: RuntimeField; id: string }[] = [];
	values: any[] = [];

	constructor(private runtimeAdapter: RuntimeDefinitionAdapter) { }

	changed(value: any, index: number) {
		console.log('FIELD-COMPONENT', index, 'changed', value);
		this.values[index] = value;
	}

	submit() {
		console.log('submit!');
		this.controls.setSubmitted();
	}

	async ngOnInit() {

		const fields: Field[] = [{
			type: FieldType.GeoLocation,
			label: 'Geo Location',
			identifier: 'geoLocation',
			visibleTo: '',
			help: 'GeoLocation test field',
			isRequired: true,
			autoDetect: false,
			isSearchable: false,
			validators: [],
			variations: [],
			tags: [],
			visibleFields: [
				'map',
				'lng',
			],
			requiredFields: [
				'map', 'lng',
			],
		}, {
			type: FieldType.Address,
			label: 'Address',
			identifier: 'address',
			isRequired: false,
			isSearchable: false,
			validators: [],
			variations: [],
			visibleFields: [
				'address1',
				'city',
				'state',
				'country',
				'map',
			],
			requiredFields: [
				'map',
				'city',
				'country',
			],
		}];

		const runtimeDefinition = await this.runtimeAdapter.transform({
			fields,
			compoundType: CompoundType.Form,
			identifier: 'identifier',
			label: 'label',
		});

		for (const field of runtimeDefinition.fields) {
			this.entries.push({
				id: generateUUID(),
				field,
			});
		}

		this.values = [
			{ lat: -33.90917503325776, lng: 151.1773681640625, zoom: 10 } as GeoLocation,
			{ lat: -35.3963574, lng: 149.20398790000002, address1: 'Bicentennial Drive', city: 'Auburn', state: 'New South Wales', country: 'Australia', zoom: 9 } as Address,
		];

		this.controls = new UfControlGroup({});

		for (const entry of this.entries) {
			const c = new UfControlGroup({});

			c.disable();
			this.controls.addControl(entry.id, c);
		}

		this.ready = true;
	}

}
