import { AbstractControl, AsyncValidatorFn, FormControl, ValidatorFn } from '@angular/forms';

import { ControlDependencyManager } from './control-dependency-manager';
import { UfControlOptions, UfFormControl, UpdateValueValidityArgs } from './uf-control-types';

export class UfControl extends FormControl {

	dependents: UfFormControl[] = [];

	private onValueUpdate = new Map<unknown,(updatedControls: Set<AbstractControl>) => unknown>();
	private _submitted: boolean;
	private _component?: { disabled: boolean };
	private dependencyManager: ControlDependencyManager<UfFormControl>;

	constructor(
		validator?: ValidatorFn | ValidatorFn[],
		options?: UfControlOptions,
		asyncValidator?: AsyncValidatorFn,
		formState: any = null,
	) {
		super(formState, validator, asyncValidator);
		this.dependencyManager = new ControlDependencyManager<UfFormControl>(this, options?.deps);
	}

	override enable(opts: { onlySelf?: boolean; emitEvent?: boolean } = {}) {
		if (this.component?.disabled === true) {
			return;
		}
		super.enable(opts);
	}

	// TODO override identical to super, why is it even defined??
	override disable(opts: { onlySelf?: boolean; emitEvent?: boolean } = {}) {
		super.disable(opts);
	}

	addDependencies(controls: UfFormControl[]) {
		this.dependencyManager.addDependencies(controls);
	}

	removeDependencies() {
		this.dependencyManager.removeDependencies();
	}

	updateDependencies() {
		this.dependencyManager.updateDependencies();
	}

	registerOnValueUpdate(key: unknown, func: (updatedControls: Set<AbstractControl>) => unknown) {
		this.onValueUpdate.set(key, func);
	}

	removeOnValueUpdate(key: unknown) {
		this.onValueUpdate.delete(key);
	}

	override updateValueAndValidity({ onlySelf, emitEvent, updatedControls }: UpdateValueValidityArgs = {}): void {
		super.updateValueAndValidity({ onlySelf, emitEvent });

		if (onlySelf || updatedControls?.has(this)) {
			return;
		}

		if (!updatedControls) {
			updatedControls = new Set();
		}
		updatedControls.add(this);

		for (const callback of this.onValueUpdate.values()) {
			callback(updatedControls);
		}

		for (const dependant of this.dependents) {
			if (!updatedControls.has(dependant)) {
				dependant.updateValueAndValidity({ onlySelf, emitEvent, updatedControls });
			}
		}
	}

	setSubmitted(v = true) {
		this._submitted = v;
	}

	set component(v: { disabled: boolean }) {
		this._component = v;
	}

	get component(): { disabled: boolean } | undefined {
		return this._component;
	}

	get submitted(): boolean {
		return this._submitted;
	}

	get showError(): boolean {
		return !this.valid && this.enabled && (this.submitted || this.touched);
	}

	get dependencies() {
		return this.dependencyManager.dependencies;
	}

}
