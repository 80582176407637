import { Component, inject } from '@angular/core';
import { Company, FormData, TableIdentifierFieldDescriptor, TableSourceType, UserInfo, fieldTypeToDataType } from '@unifii/sdk';

import { DataDisplayGroup, DataDisplayInfo, DataDisplayListItem, DataDisplayService, DataDisplaySpecificEntitiesService, DataLookupService } from '@unifii/library/common';
import { EntityPickerOutput, EntityPickerTableOutput } from 'showcase/src/app/components';

@Component({
	selector: 'sc-show-table-item-detail',
	templateUrl: './show-table-item-detail.html',
})
export class ShowTableItemDetailComponent {

	protected info: Required<EntityPickerTableOutput> | undefined;
	protected error: Error | undefined;
	protected groups: DataDisplayGroup[] = [];

	private dataDisplayEntityService = inject(DataDisplaySpecificEntitiesService);
	private dataDisplayService = inject(DataDisplayService);
	private dataLookupService = inject(DataLookupService);
	
	protected onPickedInfo(info: EntityPickerOutput | undefined) {
		if (info?.type === 'table' && info.tableDetail && info.row) {
			this.info = info as unknown as Required<EntityPickerTableOutput>;
		} else {
			this.info = undefined;
		}

		this.render();
	}

	private render() {
		
		const info = this.info;

		if (!info) {
			this.groups = [];

			return;
		}

		const groups: DataDisplayGroup[] = [{ items: [] }];

		for (const field of info.tableDetail.fields) {
			if (field.type === 'Heading') {
				groups.push({ heading: field.value, items: [] });
			} else {
				const item = this.getDescriptionListItem(field, info);
				
				if (item) {
					(groups[groups.length - 1]?.items as DataDisplayListItem[] | undefined)?.push(item);
				}
			}
		}

		this.groups = groups.filter((i) => Array.isArray(i.items) && i.items.length);
	}

	private getDescriptionListItem(field: TableIdentifierFieldDescriptor, info: Required<EntityPickerTableOutput>): DataDisplayListItem | undefined | null {
		const propertyDescriptor = info.dataDescriptor.propertyDescriptorsMap.get(field.identifier);
			
		if (!propertyDescriptor) {
			console.warn(`ShowTableDetailComponent.render: descriptor not found for identifier ${field.identifier}`);
				
			return;
		}

		switch (info.table.sourceType) {
			case TableSourceType.Users: {
				const user = info.row as UserInfo;
					
				return this.dataDisplayEntityService.displayUserPropertyAsDescriptionItem(user, propertyDescriptor);
			}
			case TableSourceType.Company: {
				const company = info.row as Company;

				return this.dataDisplayEntityService.displayCompanyPropertyAsDescriptionItem(company, propertyDescriptor);
			}
			case TableSourceType.Bucket: {
				const formData = info.row as FormData;
				const displayInfo = {
					type: fieldTypeToDataType(propertyDescriptor.type),
					template: field.itemTemplate,
					options: propertyDescriptor.options,
				} as DataDisplayInfo;
					
				const value = this.dataLookupService.lookupData(formData, field.identifier);
				const description = this.dataDisplayService.displayAsDataDisplayValue(value, displayInfo);
					
				return {
					term: field.label ?? propertyDescriptor.label,
					data: description,
				} satisfies DataDisplayListItem;
			}
		}
	}

}
