import { Injectable } from '@angular/core';
import { FieldOption } from '@unifii/sdk';

import { DataDisplayComponentRegistry, SEPARATOR_COMMA, UfDataDisplayTableComponentRegistry, isDataDisplayAttachmentValue, isDataDisplayHrefValue, isDataDisplayImageValue, isDataDisplayMapMarkerValue, isDataDisplayOptionsValue } from '@unifii/library/common';

export const dataDisplayRepeatTableComponentRegistryFactory = (registry: DataDisplayComponentRegistry) => new UfDataDisplayRepeatTableComponentRegistry(registry);

@Injectable()
export class UfDataDisplayRepeatTableComponentRegistry extends UfDataDisplayTableComponentRegistry implements DataDisplayComponentRegistry {

	override preDataTransform(data: unknown): unknown {

		// Website, Email, Phone, FileList, ImageList, VideoList, SoundList (but not those from ContentLink)
		if ((isDataDisplayHrefValue(data) || isDataDisplayAttachmentValue(data)) && data.displayAsLink == null) {
			data.displayAsLink = false;

			return data;
		}

		// Address, GeoLocation
		if (isDataDisplayMapMarkerValue(data)) {
			return data.label;
		}
		// SmartForm (via FieldDescriptionService) split Address, GeoLocation in 2 data values, `label` and `map image url`, in Repeat we skip the image part
		if (isDataDisplayImageValue(data)) {
			return null;
		}

		// Choice, MultiChoice, from options expander style to CSV
		if (isDataDisplayOptionsValue(data)) {
			const values = data.selected;
			const options = data.options;
			const valuesOptionLabel = values.map((selected) =>
				options.find((option: FieldOption) => option.identifier === selected)?.name ?? selected,
			);

			return valuesOptionLabel.join(SEPARATOR_COMMA);
		}

		return super.preDataTransform(data);
	}

}
