<sc-page-content width="xl" identifier="grid">
	<ng-container editorInputs>
		<uf-select [options]="spacingOptions" [(value)]="spacing" (valueChange)="modifierChange()"
			class="col-6 col-2-md" label="Spacing" columns="1" valueProperty="identifier" />
		<uf-select [options]="columnGapOptions" [(value)]="columnGap" (valueChange)="modifierChange()"
			class="col-6 col-2-md" label="Column Gap" columns="1" valueProperty="identifier" />
		<uf-select [options]="rowGapOptions" [(value)]="rowGap" (valueChange)="modifierChange()" class="col-6 col-2-md"
			label="Row Gap" columns="1" valueProperty="identifier" />
		<uf-select [options]="paddingOptions" [(value)]="padding" (valueChange)="modifierChange()"
			class="col-6 col-3-md" label="Padding" columns="1" valueProperty="identifier" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template">
			<sc-column-demo [ngClass]="[spacing, columnGap, rowGap, padding]" class="uf-grid grow" />
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">Breakpoints</h3>
		<sc-info-table [columnConfig]="breakPointTableConfig" [rows]="breakPointTableInfo" />
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="tableConfig" [rows]="tableInfo" />
	</ng-container>

</sc-page-content>