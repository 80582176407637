import { Component, Inject, ViewChild } from '@angular/core';
import { DataSeed } from '@unifii/sdk';

import { LocationProvider, UfSearchResultComponent } from '@unifii/library/common';

import { SCDataService } from '../../services';

@Component({
	selector: 'sc-show-search-result',
	templateUrl: './show-search-result.html',
})
export class ShowSearchResultComponent {

	@ViewChild('addressSearch') addressSearch: UfSearchResultComponent;
	@ViewChild('fruitSearch') fruitSearch: UfSearchResultComponent;

	results: DataSeed[] = [];
	query: string;
	options: DataSeed[];
	value: string;

	fruitOptions: string[];
	selectedFruits: string[] = [];

	constructor(
		@Inject(LocationProvider) private location: LocationProvider,
		private data: SCDataService,
	) { }

	async search(q: string) {
		if (this.location.search) {
			const places = await this.location.search(q);

			this.options = places;
		}
	}

	async findFruits(q: string) {
		this.fruitOptions = await this.data.asyncSearchFruitNames(q, 1000);
	}

	fruitNameProperty = (fruitName: string): string => `Custom display for ${fruitName}`;

	clear() {
		this.addressSearch.clear();
		this.fruitSearch.clear();
	}

}
