import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { DataSeed } from '@unifii/sdk';

import { FilterEntry, FilterValue } from '../../../models';
import { ModalService } from '../../../services';
import { CommonTranslationKey } from '../../../translations';

import { DataSourceAdvancedSearchModalComponent } from './data-source-advanced-search-modal.component';
import { DataSourceAdvancedSearchConfig } from './data-source-advanced-search-model';

@Component({
	selector: 'uf-data-source-advanced-search',
	templateUrl: './uf-data-source-advanced-search.html',
})
export class UfDataSourceAdvancedSearchComponent<V extends FilterValue, E extends FilterEntry> {

	@Input() disabled: boolean;
	@Input() config: DataSourceAdvancedSearchConfig<V, E>;

	@Output() valueChange = new EventEmitter<DataSeed>();

	protected readonly commonTK = CommonTranslationKey;

	private modalService = inject(ModalService);

	protected async open() {

		const value = await this.modalService.openMedium(
			DataSourceAdvancedSearchModalComponent,
			this.config,
			undefined,
		);

		if (!value) {
			return;
		}

		this.valueChange.emit(value);
	}

}
