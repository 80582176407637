import { Injectable, inject } from '@angular/core';
import { PublishedContent, Query, Schema, TenantClient } from '@unifii/sdk';

import { BucketDataDescriptorAdapterLoader } from './bucket-data-descriptor-adapter';

type FormInfo = {
	identifier: string;
	label: string;
	bucket?: string;
};

@Injectable()
export class UfBucketDataDescriptorAdapterLoader implements BucketDataDescriptorAdapterLoader {

	private tenantClient = inject(TenantClient);
	private content = inject(PublishedContent, { optional: true });

	loadSchema(identifier: string): Promise<Schema> {
		if (!this.content) {
			throw new Error('UfBucketDataDescriptorAdapterLoader.loadSchema: PublishedContent not provided!');
		}

		return this.content.getBucket(identifier);
	}

	async loadForms(): Promise<FormInfo[]> {

		if (!this.content) {
			throw new Error('UfBucketDataDescriptorAdapterLoader.loadForms: PublishedContent not provided!');
		}

		const results: FormInfo[] = [];
		const pageSize = 50;

		let batch: FormInfo[];
		let page = 0;
		let query = new Query();

		do {
			query = query.limit(pageSize, page * pageSize);
			batch = await this.content.queryForms(query);
			if (batch.length > 0) {
				results.push(...batch);
			}
			page = page + 1;
		} while (batch.length === pageSize);

		return results;
	}

	loadUserClaims() {
		return this.tenantClient.getUserClaims();
	}

}
