<ng-template [ngIf]="mode === modes.Input">
	<uf-hierarchy-cascade-selection [ceiling]="entry.hierarchyConfig?.ceiling" [value]="value"
		(valueChange)="onValueChange($event)" mode="siblings" />
</ng-template>

<ng-template [ngIf]="mode === modes.Display && !isEmpty">
	<div *ngFor="let unit of value; let i = index" class="uf-chip x-small">
		{{label}}: {{unit | dataDisplay}}
		<button (click)="remove(i)" type="button" class="uf-action">
			<uf-icon name="delete" />
		</button>
	</div>

</ng-template>