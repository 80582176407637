import { Component, Input, OnInit } from '@angular/core';
import { FieldOption } from '@unifii/sdk';

import { DataDisplayComponentType } from '../../../models';
import { CommonTranslationKey } from '../../../translations';

import { DataDisplayOptionsValue, isDataDisplayOptionsValue } from './data-display-components-model';

@Component({
	selector: 'uf-data-display-options',
	templateUrl: './data-display-options.html',
	styleUrls: ['./data-display-options.less', '../data-display.less'],
})
export class DataDisplayOptionsComponent implements DataDisplayComponentType, OnInit {
	
	@Input({ required: true }) data: DataDisplayOptionsValue;

	static isDataAccepted = isDataDisplayOptionsValue;
	
	protected readonly commonTK = CommonTranslationKey;
	protected selectedOptions: FieldOption[];
	protected unselectedOptions: FieldOption[];

	ngOnInit() {
		// Selected options are obtained from the available options or built using just their value
		this.selectedOptions = this.data.selected.map((identifier) => this.data.options.find((option) => option.identifier === identifier) ?? { identifier, name: identifier });

		// Unselected options are obtained from the available options that are not matching the selected identifiers
		this.unselectedOptions = this.data.options.filter((option) => !this.data.selected.includes(option.identifier));
	}
	
}
