import { Component, EventEmitter, Input, Output } from '@angular/core';

import { NavItem } from '../../models';

@Component({
	selector: 'uf-nav, uf-navigation',
	templateUrl: './navigation.html',
	styleUrls: ['./navigation.less'],
})
export class NavigationComponent {

	@Input({ required: true }) navItems: NavItem[];
	@Output() selected = new EventEmitter<NavItem>();

	protected onSelect(node: NavItem) {
		if (node.isDisabled || node.isHeading) {
			return;
		}
		this.selected.emit(node);
	}

}
