import { Directive, ElementRef, Input, OnInit, Renderer2, inject } from '@angular/core';

import { AssetInterpolator, MarkdownProvider } from '../services';

export enum MarkdownAssetMetaPosition {
	FloatRight = 'floatRight',
	FloatLeft = 'floatLeft',
	Left = 'left',
	Center = 'center',
	Right = 'right',
	Stretch = 'stretch'
}

@Directive({
	selector: '[markdownRenderer]',
})
export class MarkdownDirective implements OnInit {

	private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
	private renderer = inject(Renderer2);
	private assetInterpolator = inject(AssetInterpolator);
	private markdown = inject(MarkdownProvider, { optional: true });
	private container?: HTMLElement;

	@Input() set markdownRenderer(v: string | null | undefined) {
		void this.render(v ?? undefined);
	}

	ngOnInit() {
		if (!this.markdown) {
			console.warn('Markdown service not provided, render skipped');
		}

		this.container = this.getBodyCopyContainer(this.elementRef.nativeElement);
	}

	private async render(v?: string) {
		const markdown = !v ? '' : this.markdown ? this.markdown.render(v) : v;
		const domString = await this.assetInterpolator.interpolate(markdown, this.container?.clientWidth);

		this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', domString);
	}

	private getBodyCopyContainer(element: HTMLElement): HTMLElement | undefined {
		if (element.classList.contains('body-copy')) {
			return element;
		}

		if (element.parentElement) {
			return this.getBodyCopyContainer(element.parentElement);
		}

		return undefined;
	}

}
