import { Component, HostBinding, Input } from '@angular/core';

import { RuntimeField } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-website-display',
	templateUrl: './website.html',
})
export class WebsiteComponent implements ContentField {

	@HostBinding('class.body-copy') bodyclass = true;

	@Input() content: any;
	@Input() field: RuntimeField;

}
