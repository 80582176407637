import { Component, Inject, OnInit, Optional, ViewChild, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AstNode, Client, DataSeed, DataSourceType, Definition, Dictionary, Option, ProjectContentOptions, ProjectContentOptionsInterface, PublishedContent, Query, RequestAnalyticsOrigin, TableSourceType, VisibleFilterDescriptor, mergeAstNodes } from '@unifii/sdk';

import { ColumnGap, DataDescriptor, DataDescriptorBucketType, DataDescriptorCollectionType, DataDescriptorCompanyType, DataDescriptorService, DataDescriptorType, DataDescriptorUsersType, DataLoaderFactory, DataSourceLoader, FilterEntry, FilterEntryDataDescriptorAdapter, FilterManager, FilterSerializer, FilterValue, HierarchyUnitProvider, ModalService, RuntimeDefinitionAdapter, RuntimeField, Size, SourceConfig, SourceConfigBucket, SourceConfigCollection, SourceConfigCompany, SourceConfigUser, TabsComponent, ValidatorFunctions, dataSourceTypeToDataDescriptorType, fieldIterator, getBucketDefaultSourceConfig, getCollectionDefaultSourceConfig, getCompanyDefaultSourceConfig, getUserDefaultSourceConfig } from '@unifii/library/common';

import { FormInfo, TableInfo, UsAPIContentClient } from '../../../services';

@Component({
	selector: 'sc-filters',
	templateUrl: './show-filters.html',
})
export class ShowFiltersComponent implements OnInit {

	@ViewChild(TabsComponent) tabsComponent: TabsComponent;

	protected readonly sizes = Size;
	protected readonly columnGaps = ColumnGap;
	protected readonly entityTypes: Option[] = [
		{ identifier: DataDescriptorUsersType, name: 'Users' },
		{ identifier: DataDescriptorCompanyType, name: 'Company' },
		{ identifier: DataDescriptorBucketType, name: 'Form Data' },
		{ identifier: DataDescriptorCollectionType, name: 'Collection' },
	];

	// Manual
	protected entityType: DataDescriptorType;
	protected buckets: string[];
	protected bucketOptions: string[];
	protected bucketSelected: string | null;
	protected collections: Option[];
	protected collectionOptions: Option[];
	protected collectionSelected: Option | null;

	// Table
	protected tables: TableInfo[];
	protected tableOptions: TableInfo[];
	protected tableSelected: TableInfo | null;

	// Field DS
	protected forms: FormInfo[];
	protected formOptions: FormInfo[];
	protected formSelected: Definition | null;
	protected fields: RuntimeField[] = [];
	protected fieldOptions: RuntimeField[];
	protected fieldSelected: RuntimeField | null;

	// Filters
	protected configError: string | undefined;
	protected loaderError: string | undefined;
	protected dataLoaderFactory: DataLoaderFactory;
	protected dataLoader: DataSourceLoader | undefined;
	protected dataSeeds: DataSeed[] = [];
	protected filterValues: Dictionary<FilterValue> = {};
	protected serializedFilterValues: Dictionary<string | null> | undefined;
	protected deserializedFilterValues: Dictionary<FilterValue> | undefined;
	protected filterEntries: FilterEntry[] | undefined;
	protected filterManager: FilterManager<FilterValue, FilterEntry> | undefined;
	protected query: string | undefined;
	protected astNode: AstNode | undefined;
	protected hiddenFilter: AstNode | undefined;
	protected combineHiddenFilter: boolean;

	private usContent = inject(UsAPIContentClient);
	private modalService = inject(ModalService);
	private dataDescriptorManager = inject(DataDescriptorService);
	private hierarchyUnitProvider = inject(HierarchyUnitProvider);
	private filterEntryAdapter = inject(FilterEntryDataDescriptorAdapter);
	private translateService = inject(TranslateService);
	private filterSerializer = inject<FilterSerializer<FilterValue, FilterEntry>>(FilterSerializer, { optional: true });
	private runtimeDefinitionAdapter = inject(RuntimeDefinitionAdapter);

	constructor(
		@Inject(ProjectContentOptions) @Optional() projectContentOptions: ProjectContentOptionsInterface | null,
			client: Client,
		@Inject(PublishedContent) content: PublishedContent,
	) {
		this.dataLoaderFactory = new DataLoaderFactory(client, content, projectContentOptions);
	}

	get isManualConfigured() {
		switch (this.entityType) {
			case DataDescriptorUsersType: return true;
			case DataDescriptorCompanyType: return true;
			case DataDescriptorBucketType: return this.bucketSelected != null;
			case DataDescriptorCollectionType: return this.collectionSelected != null;
			default: return false;
		}
	}

	async ngOnInit() {

		this.forms = await this.usContent.getForms();
		this.buckets = [...new Set(this.forms.map((d) => d.bucket ))];
		this.collections = [...new Set((await this.usContent.getCollections()).map((d) => ({
			identifier: d.identifier,
			name: d.name,
		})))];

		this.tables = (await this.usContent.getTables()).map((t) => {
			t.title = `[${t.sourceType}] (${t.identifier}) ${t.title}`;

			return t;
		});
	}

	configChange() {
		this.filterEntries = undefined;
		this.filterManager = undefined;
		this.filterValues = {};
		this.serializedFilterValues = undefined;
		this.deserializedFilterValues = undefined;
		this.hiddenFilter = undefined;
		this.combineHiddenFilter = false;
		this.dataLoader = undefined;
		this.dataSeeds = [];
		this.configError = undefined;
		this.loaderError = undefined;
	}

	get hasFilters() {
		return !ValidatorFunctions.isEmpty(this.filterValues);
	}

	searchBucket(q?: string) {
		this.bucketOptions = q ? this.buckets.filter((s) => s.includes(q)) : [...this.buckets];
	}

	searchCollection(q?: string) {
		this.collectionOptions = !q ? [...this.collections] : this.collections.filter((o) => o.identifier.includes(q) || o.name.includes(q));
	}

	searchTable(q?: string) {
		this.tableOptions = q ? this.tables.filter((t) => t.title.toLowerCase().includes(q.toLowerCase()) || t.identifier.toLowerCase().includes(q.toLowerCase()) ) : [...this.tables];
	}

	searchForm(q?: string) {
		this.formOptions = q ? this.forms.filter((f) => f.name.toLowerCase().includes(q.toLowerCase())) : [...this.forms];
	}

	searchField(q?: string) {
		this.fieldOptions = q ? this.fields.filter((t) => (t.label?.toLowerCase().indexOf(q.toLowerCase()) ?? 0) >= 0) : [...this.fields];
	}

	async formChange() {

		this.fields = [];

		if (!this.formSelected) {
			return;
		}

		const definition = await this.runtimeDefinitionAdapter.transform(await this.usContent.getForm(this.formSelected.identifier));

		for (const { field } of fieldIterator(definition.fields)) {
			switch (field.sourceConfig?.type) {
				case DataSourceType.Bucket:
				case DataSourceType.Collection:
				case DataSourceType.Company:
				case DataSourceType.Users:
					if (field.sourceConfig.visibleFilters?.length) {
						this.fields.push(field);
					}
			}
		}
	}

	async manualFilters() {
		try {
			const type = this.dataDescriptorTypeToDataSourceType(this.entityType);

			if (!type) {
				throw new Error(`Unsupported DataDescriptorType ${this.entityType}`);
			}
			const id = this.collectionSelected?.identifier ?? this.bucketSelected;

			const dataDescriptor = await this.getDataDescriptor(type, id);

			if (!dataDescriptor) {
				// eslint-disable-next-line sonarjs/no-duplicate-string
				throw new Error('DataDescriptor load failed');
			}

			const filtersOptions: VisibleFilterDescriptor[] = dataDescriptor.propertyDescriptors
				.filter((dp) => dp.asInputFilter)
				.map((dp) => ({ identifier: dp.identifier }));

			this.initializeFilters(type, dataDescriptor, filtersOptions);

			const dataSource = this.getDefaultSourceConfig(type, id);

			this.dataLoader = this.dataLoaderFactory.create(dataSource) ?? undefined;
			void this.onFiltersChange();
		} catch (e) {
			console.error(e);
			this.configError = (e as Error).message;
		}
	}

	async tableFilters() {
		try {
			if (!this.tableSelected ) {
				return;
			}

			const table = await this.usContent.getTable(this.tableSelected.identifier);

			if (!table.visibleFilters?.length) {
				this.configError = 'No visible filters configured for this table';

				return;
			}

			const sourceType = this.tableSourceTypeToDataSourceType(table.sourceType);
			const dataDescriptor = await this.getDataDescriptor(sourceType, table.source);

			if (!dataDescriptor) {
				throw new Error('DataDescriptor load failed');
			}

			this.initializeFilters(sourceType, dataDescriptor, table.visibleFilters, table.filter);

			const dataSource = this.getDefaultSourceConfig(sourceType, table.source);

			this.dataLoader = this.dataLoaderFactory.create(dataSource) ?? undefined;
			void this.onFiltersChange();
		} catch (e) {
			console.error(e);
			this.configError = (e as Error).message;
		}
	}

	async fieldFilters() {
		try {
			const sourceConfig = this.fieldSelected?.sourceConfig as SourceConfigUser | SourceConfigCompany | SourceConfigBucket | SourceConfigCollection;

			const dataDescriptor = await this.getDataDescriptor(sourceConfig.type, (sourceConfig as SourceConfigBucket | SourceConfigCollection).id);

			if (!dataDescriptor) {
				throw new Error('DataDescriptor load failed');
			}

			this.initializeFilters(sourceConfig.type, dataDescriptor, sourceConfig.visibleFilters ?? [], sourceConfig.filter);

			this.dataLoader = this.dataLoaderFactory.create(this.fieldSelected?.sourceConfig) ?? undefined;
			void this.onFiltersChange();
		} catch (e) {
			console.error(e);
			this.configError = (e as Error).message;
		}
	}

	initializeFilters(type: DataSourceType, dataDescriptor: DataDescriptor, visibleFilterDescriptors: VisibleFilterDescriptor[] = [], hiddenFilter?: AstNode) {

		this.filterEntries = [];
		this.hiddenFilter = hiddenFilter;
		this.configError = undefined;

		const dataPropertyType = dataSourceTypeToDataDescriptorType(type);

		if (!dataPropertyType) {
			return;
		}

		for (const filterOptions of visibleFilterDescriptors) {
			const property = dataDescriptor.propertyDescriptorsMap.get(filterOptions.identifier);

			if (!property) {
				console.warn(`FilterOption with identifier ${filterOptions.identifier} not found in DataDescriptor`);
				continue;
			}
			const filterEntry = this.filterEntryAdapter.transform({
				descriptorType: dataPropertyType,
				descriptorProperty: property,
				visibleFilterDescriptor: filterOptions,
				staticFilter: this.hiddenFilter,
				translateService: this.translateService,
				dataLoaderFactory: this.dataLoaderFactory,
				requestAnalytics: { origin: RequestAnalyticsOrigin.Table, id: 'showcase-filters' },
				searchMinLength: 0,
			});

			if (!filterEntry) {
				console.warn('Failed to generate filter for ', filterOptions);
				continue;
			}

			this.filterEntries.push(filterEntry);
		}

		// Useful for debugging on JS Console, left on purpose
		console.log('VisibleFilterDescriptors:\n', visibleFilterDescriptors, '\nInitialized FilterEntries:\n', this.filterEntries);

		this.filterManager = new FilterManager(this.filterEntries, this.hierarchyUnitProvider, this.filterSerializer, null);
	}

	async onFiltersChange() {
		this.dataSeeds = [];
		this.loaderError = undefined;

		if (!this.filterManager || !this.filterEntries || !this.filterSerializer) {
			return;
		}

		this.serializedFilterValues = this.filterSerializer.serializeAll(this.filterValues, this.filterEntries);
		this.deserializedFilterValues = await this.filterSerializer.deserializeAll(this.serializedFilterValues, this.filterEntries);

		const nodes = [this.filterManager.toAstNode(this.deserializedFilterValues)];

		if (this.combineHiddenFilter) {
			nodes.push(this.hiddenFilter);
		}

		this.astNode = mergeAstNodes('and', ...nodes);
		this.query = Query.fromAst(this.astNode)?.stringify();

		if (!this.dataLoader) {
			return;
		}

		try {
			this.dataSeeds = await this.dataLoader.search(undefined, undefined, undefined, undefined, this.astNode);
		} catch (e) {
			console.error(e);
			this.loaderError = (e as Error).message;
		}
	}

	showAstNode() {
		if (!this.astNode) {
			return;
		}
		void this.modalService.openAlert({ title: 'AstNode', message: JSON.stringify(this.astNode) });
	}

	showFiltersValues() {
		void this.modalService.openAlert({ title: 'Filters Values', message: JSON.stringify(this.deserializedFilterValues) });
	}

	showFiltersURL() {
		if (!this.serializedFilterValues) {
			return;
		}

		let message = '';

		for (const key of Object.keys(this.serializedFilterValues)) {
			if (this.serializedFilterValues[key]) {
				message += `${key}=${this.serializedFilterValues[key]};`;
			}
		}

		void this.modalService.openAlert({ title: 'Filters URL', message });
	}

	private getDataDescriptor(type: DataSourceType, id?: string | null): Promise<DataDescriptor | undefined> {

		switch (type) {
			case DataSourceType.Users:
				return this.dataDescriptorManager.getUserDataDescriptor();
			case DataSourceType.Company:
				return this.dataDescriptorManager.getCompanyDataDescriptor();
			case DataSourceType.Collection:
				if (!id) {
					return Promise.resolve(undefined);
				}

				return this.dataDescriptorManager.getCollectionDataDescriptor(id);
			case DataSourceType.Bucket:
				if (!id) {
					return Promise.resolve(undefined);
				}

				return this.dataDescriptorManager.getBucketDataDescriptor(id);
			default:
				return Promise.resolve(undefined);
		}
	}

	private getDefaultSourceConfig(type: DataSourceType, id?: string | null): SourceConfig | undefined {
		switch (type) {
			case DataSourceType.Users:
				return getUserDefaultSourceConfig();
			case DataSourceType.Bucket:
				if (!id) {
					return undefined;
				}

				return getBucketDefaultSourceConfig(id);
			case DataSourceType.Company:
				return getCompanyDefaultSourceConfig();
			case DataSourceType.Collection:
				if (!id) {
					return undefined;
				}

				return getCollectionDefaultSourceConfig(id);
		}

		return undefined;
	}

	private dataDescriptorTypeToDataSourceType(type: DataDescriptorType): DataSourceType | undefined {
		switch (type) {
			case DataDescriptorUsersType: return DataSourceType.Users;
			case DataDescriptorCompanyType: return DataSourceType.Company;
			case DataDescriptorCollectionType: return DataSourceType.Collection;
			case DataDescriptorBucketType: return DataSourceType.Bucket;
			default: return;
		}
	}

	private tableSourceTypeToDataSourceType(type: TableSourceType): DataSourceType {
		switch (type) {
			case TableSourceType.Bucket: return DataSourceType.Bucket;
			case TableSourceType.Company: return DataSourceType.Company;
			case TableSourceType.Users: return DataSourceType.Users;
		}
	}

}
