import { Dictionary } from '@unifii/sdk';

/**
 * PatternUtil is simple class responsible for
 * testing the equality of 2 value
 */
export class PatternUtil<T> {

	isObject(value?: T | null): boolean {
		return typeof value === 'object' && value != null && !Array.isArray(value);
	}

	isEqual(value1?: T | null, value2?: T | null): boolean {
		// test matching references and falsy values
		if (value1 === value2) {
			return true;
		}

		if (Array.isArray(value1) && Array.isArray(value2)) {
			return this.arraysAreEqual(value1, value2);
		}

		if (this.isObject(value1) && this.isObject(value2)) {
			return this.objectsAreEqual(value1 as Dictionary<any>, value2 as Dictionary<any>);
		}

		return false;
	}

	copy(value?: T | null): T | undefined | null {
		// TODO recursively copy at the mo
		if (Array.isArray(value)) {
			// TODO check why this unknown cast is required
			return [...value] as unknown as T;
		}

		if (this.isObject(value)) {
			return Object.assign({}, value);
		}

		return value;
	}

	private arraysAreEqual(array1: any[], array2: any[]): boolean {
		if (array1.length !== array2.length) {
			return false;
		}

		for (let i = 0; i < array1.length; i++) {
			const equal = this.isEqual(array1[i], array2[i]);

			if (!equal) {
				return false;
			}
		}

		return true;
	}

	private objectsAreEqual(obj1: Dictionary<any>, obj2: Dictionary<any>): boolean {
		const keys1 = Object.keys(obj1);
		const keys2 = Object.keys(obj2);

		if (keys1.length !== keys2.length) {
			return false;
		}

		for (const key of keys1) {
			const equal = this.isEqual(obj1[key], obj2[key]);

			if (!equal) {
				return false;
			}
		}

		return true;
	}

}
