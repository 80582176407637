import { ShowAppBarComponent } from './show-app-bar.component';
import { ShowBoxComponent } from './show-box.component';
import { ShowCardsComponent } from './show-cards.component';
import { ShowDrawersComponent } from './show-drawers.component';
import { ShowFormCardsComponent } from './show-form-cards.component';

export * from './show-app-bar.component';
export * from './show-box.component';
export * from './show-cards.component';
export * from './show-drawers.component';
export * from './show-form-cards.component';

export const SC_PAGE_SURFACE_COMPONENTS = [
	ShowAppBarComponent, ShowBoxComponent, ShowCardsComponent,
	ShowDrawersComponent, ShowFormCardsComponent,
];
