<sc-page-content width="lg" identifier="messages">

	<ng-container editorInputs>
		<uf-radio [columns]="1" [options]="sizeOptions" [(value)]="size" (valueChange)="modifierChange()" class="col-3"
			label="Sizes" valueProperty="value" />
		<uf-radio [columns]="2" [options]="colourOptions" [(value)]="colour" (valueChange)="modifierChange()"
			class="col-6" label="Level" valueProperty="value" />
		<div class="col-3">
			<uf-checkbox [(value)]="untintedBackground" (valueChange)="modifierChange()" label="Untinted Background" />
			<uf-checkbox [(value)]="showIcon" (valueChange)="modifierChange()" label="Show Icon" />
			<uf-checkbox [(value)]="raised" (valueChange)="modifierChange()" label="Raised" />
			<uf-checkbox [(value)]="inactive" (valueChange)="modifierChange()" label="Inactive" />
			<uf-checkbox [(value)]="showExpander" (valueChange)="modifierChange()" label="Show Expander" />
		</div>

	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template" [ngClass]="size">
			<uf-message [ngClass]="[size, colour]" [class.untinted-background]="untintedBackground"
				[class.raised]="raised" [class.inactive]="inactive" [content]="content" [actions]="actions"
				[icon]="showIcon ? icon : undefined" [showExpander]="showExpander" style="max-width: 500px;" />
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>