<uf-panel [bottomThreshold]="0" (scrollBottom)="tableComponent?.load()" class="container scroll-all">

	<div class="pad-sides pad-bottom">
		<h3>Configuration</h3>
		<sc-entity-picker [type]="'table'" (emitChange)="onPickedInfo($event)" />
	</div>

	<uf-table *ngIf="datasource && tableConfig" #pageTable [config]="tableConfig" [datasource]="datasource"
		class="gap-lg-bottom pad-sides list-md">
		<ng-container *ngFor="let descriptor of customColumns">
			<ng-container *cellFor="descriptor.name; let item; let index = index; let column = column;">
				<uf-custom-cell [index]="index" [item]="item" [column]="column" [descriptor]="descriptor" />
			</ng-container>
		</ng-container>
	</uf-table>

</uf-panel>