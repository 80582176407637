import { Component, Input } from '@angular/core';
import { Option } from '@unifii/sdk';

import { RuntimeField } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-choice-display',
	templateUrl: './choice.html',
})
export class ChoiceDisplayComponent implements ContentField {

	@Input() field: RuntimeField;
	@Input() content: Option;

	get display() {
		if (this.content === Object(this.content)) {
			return `${this.content.name} (${this.content.identifier})`;
		}

		return this.content;
	}

}
