import { Component, Input } from '@angular/core';

import { DataDisplayComponentType } from '../../../models';

import { DataDisplayImageValue, isDataDisplayImageValue } from './data-display-components-model';

@Component({
	selector: 'uf-data-display-image',
	templateUrl: './data-display-image.html',
	styleUrls: ['../data-display.less'],
})
export class DataDisplayImageComponent implements DataDisplayComponentType {
	
	@Input({ required: true }) data: DataDisplayImageValue;

	static isDataAccepted = isDataDisplayImageValue;
	
}
