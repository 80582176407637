<svg class="small" width="544" height="311" viewBox="0 0 544 311">
	<rect width="544" height="311" fill="white" />
	<rect x="16" y="16" width="106" height="13" rx="6.5" fill="#E7E7E7" />
	<rect x="16" y="177" width="68" height="13" rx="6.5" fill="#E7E7E7" />
	<rect x="16" y="246" width="68" height="13" rx="6.5" fill="#E7E7E7" />
	<rect x="16" y="86" width="286" height="13" rx="6.5" fill="#E7E7E7" />
	<line x1="16" y1="64.5" x2="528" y2="64.5" stroke="#D0D0D0" />
	<line x1="16" y1="225.5" x2="528" y2="225.5" stroke="#D0D0D0" />
	<line x1="16" y1="294.5" x2="528" y2="294.5" stroke="#D0D0D0" />
	<line x1="16" y1="157.5" x2="528" y2="157.5" stroke="#D0D0D0" />
	<rect x="502" y="268" width="24" height="24" rx="7" fill="#E7E7E7" />
	<rect x="502" y="38" width="24" height="24" rx="7" fill="#E7E7E7" />
</svg>

<svg class="medium" width="736" height="311" viewBox="0 0 736 311">
	<rect class="animate" width="736" height="311" fill="white" />
	<rect x="16" y="16" width="106" height="13" rx="6.5" fill="#E7E7E7" />
	<rect x="16" y="177" width="68" height="13" rx="6.5" fill="#E7E7E7" />
	<rect x="16" y="246" width="68" height="13" rx="6.5" fill="#E7E7E7" />
	<rect x="16" y="86" width="286" height="13" rx="6.5" fill="#E7E7E7" />
	<line x1="16" y1="64.5" x2="720" y2="64.5" stroke="#D0D0D0" />
	<line x1="16" y1="225.5" x2="720" y2="225.5" stroke="#D0D0D0" />
	<line x1="16" y1="294.5" x2="720" y2="294.5" stroke="#D0D0D0" />
	<line x1="16" y1="157.5" x2="720" y2="157.5" stroke="#D0D0D0" />
	<rect x="696" y="268" width="24" height="24" rx="7" fill="#E7E7E7" />
	<rect x="696" y="38" width="24" height="24" rx="7" fill="#E7E7E7" />
</svg>

<svg class="large" width="960" height="311" viewBox="0 0 960 311">
	<g clip-path="url(#clip0_702_2489)">
		<rect width="960" height="311" fill="white" />
		<rect x="15" y="16" width="106" height="13" rx="6.5" fill="#E7E7E7" />
		<rect x="15" y="177" width="68" height="13" rx="6.5" fill="#E7E7E7" />
		<rect x="15" y="246" width="68" height="13" rx="6.5" fill="#E7E7E7" />
		<rect x="15" y="86" width="286" height="13" rx="6.5" fill="#E7E7E7" />
		<line x1="16" y1="64.5" x2="944" y2="64.5" stroke="#D0D0D0" />
		<line x1="16" y1="225.5" x2="944" y2="225.5" stroke="#D0D0D0" />
		<line x1="16" y1="294.5" x2="944" y2="294.5" stroke="#D0D0D0" />
		<line x1="16" y1="157.5" x2="944" y2="157.5" stroke="#D0D0D0" />
		<rect x="920" y="268" width="24" height="24" rx="7" fill="#E7E7E7" />
		<rect x="920" y="38" width="24" height="24" rx="7" fill="#E7E7E7" />
	</g>
	<defs>
		<clipPath id="clip0_702_2489">
			<rect width="960" height="311" fill="white" />
		</clipPath>
	</defs>
</svg>