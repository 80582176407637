import { Component } from '@angular/core';

import { DeviceInfo } from '@unifii/library/common';

@Component({
	selector: 'sc-show-device',
	templateUrl: './show-device.html',
})
export class ShowDeviceComponent {

	public device: any;

	constructor(private deviceInfo: DeviceInfo) {
		this.device = {
			getPlatform: this.deviceInfo.getPlatform(),
			useNativeInput: this.deviceInfo.useNativeInput() ? 'True' : 'False',
			isAndroid: this.deviceInfo.isAndroid() ? 'True' : 'False',
			isIosMobile: this.deviceInfo.isIosMobile() ? 'True' : 'False',
			isNative: this.deviceInfo.isNative() ? 'True' : 'False',
			isSafari: this.deviceInfo.isSafari() ? 'True' : 'False',
		};
	}

}
