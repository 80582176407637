import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfo } from '@unifii/sdk';

import { Modal, ModalRuntime } from '@unifii/library/common';

import { ShowcaseRepositoryService } from '../services';

@Component({
	templateUrl: './show-user-details.html',
})
export class ShowUserDetailsComponent implements Modal<void, void> {

	fullname: string;
	user: {
		username?: string;
		email?: string;
	} = {};

	constructor(public runtime: ModalRuntime<void, void>, public repository: ShowcaseRepositoryService, private router: Router) {

		if (this.repository.user == null) {
			this.logout();

			return;
		}

		this.fullname = this.getFullname(this.repository.user);

		this.user.username = this.repository.user.username;
		this.user.email = this.repository.user.email;
	}

	logout() {
		this.repository.reset();
		this.runtime.close();
		this.router.navigate(['/login']);
	}

	private getFullname(userInfo: UserInfo): string {

		return ['firstName', 'lastName']
			.map((key) => userInfo[key as keyof UserInfo])
			.filter((value) => (value != null && typeof value === 'string'))
			.map((name) => (name as string).charAt(0).toUpperCase() + (name as string).substring(1))
			.join(' ');
	}

}
