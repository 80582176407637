<!-- Vertical Step -->
<ng-container *ngIf="stepper.orientation === orientations.Vertical; else horizontalBlock">
	<uf-step-label *ngIf="label" [label]="label" [index]="index" [valid]="control ? control.valid : true"
		[active]="index === stepper.active" [submitted]="control ? control.submitted : true" (click)="activate()"
		class="row x-small" />

	<div [class.bordered]="!stepper.lastStepVisible"
		[ngStyle]="{ maxHeight: index === stepper.active ? maxHeight : '0' }" (mutations)="onMutation(wrap)"
		class="vertical-content" domObserver>
		<div #wrap>
			<ng-container *ngTemplateOutlet="stepContent" />
			<ng-container *ngTemplateOutlet="stepActions" />
		</div>
	</div>

	<div *ngIf="!stepper.lastStepVisible" class="divider">
	</div>
</ng-container>

<!-- Horizontal Step -->
<ng-template #horizontalBlock>

	<div [ngStyle]="{
		height: index === stepper.active ? 'auto' : '0px',
		transform: index < stepper.active ? 'translateX(-100%)':
			index > stepper.active ? 'translateX(100%)' : 'initial'
	}" class="horizontal-content">
		<ng-container *ngTemplateOutlet="stepContent" />
		<ng-container *ngTemplateOutlet="stepActions" />
	</div>
</ng-template>

<!-- Templates -->

<ng-template #stepContent>
	<ng-content select="[content]"></ng-content>
</ng-template>

<ng-template #stepActions>

	<div [ngClass]="{ 'horizontal': stepper.orientation === orientations.Horizontal, 'vertical': stepper.orientation === orientations.Vertical }"
		class="actions row">
		<button *ngIf="index > 0 && showCancel" [class.left]="stepper.orientation === orientations.Horizontal"
			(click)="back()" type="button" class="uf-button tertiary back-btn">
			<uf-icon name="arrowBack" />
			{{ backLabel }}
		</button>

		<div class="spacer"></div>

		<ng-content select="[actions]"></ng-content>

		<button *ngIf="!stepper.lastStepVisible && showNext" (click)="complete();" type="button"
			class="uf-button primary next-btn">
			{{ nextLabel }}
			<uf-icon name="arrowForward" />
		</button>

		<button *ngIf="stepper.lastStepVisible && !stepper.hideComplete" (click)="complete();" type="button"
			class="uf-button primary right next-btn">
			{{ completeLabel }}
		</button>

	</div>
</ng-template>