import { Component } from '@angular/core';
import { Dictionary, FieldType, FieldWidth, PageField } from '@unifii/sdk';

import { ToastService, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';

import { Fruit, SCDataService } from '../../services';

@Component({
	selector: 'sc-show-autocomplete',
	templateUrl: './show-autocomplete.html',
	styleUrls: ['./show-autocomplete.less'],
})
export class ShowAutocompleteComponent {

	cv: Dictionary<any> = {
		id: 'autocomplete_input_01',
		name: 'autocomplete_input',
		disabled: false,
		customs: true,
		required: true,
		simple: true,
		complex: false,
		template: false,
	};

	config: Dictionary<PageField> = {
		id: { label: 'ID', type: FieldType.Text, value: this.cv.id, width: FieldWidth.Half },
		name: { label: 'Name', type: FieldType.Text, value: this.cv.name, width: FieldWidth.Half },
		required: { label: 'Required', type: FieldType.Bool, value: this.cv.required, width: FieldWidth.Third },
		disabled: { label: 'Disabled', type: FieldType.Bool, value: this.cv.disabled, width: FieldWidth.Third },
		customs: { label: 'Allow customs', type: FieldType.Bool, value: this.cv.customs, width: FieldWidth.Third },
		simple: { label: 'Simple', type: FieldType.Bool, value: this.cv.simple, width: FieldWidth.Third },
		complex: { label: 'Complex', type: FieldType.Bool, value: this.cv.complex, width: FieldWidth.Third },
		template: { label: 'Template', type: FieldType.Bool, value: this.cv.complex, width: FieldWidth.Third },
	};

	rootControl: UfControlGroup;

	staticControl: UfControl;
	staticResults: string[];
	staticValue: string | null;
	staticEmittedValue?: string;

	simpleControl: UfControl;
	simpleResults: string[]; // = ['a', 'b', 'c'];
	simpleValue: string | null; // 'Miami';
	simpleEmittedValue?: string;

	complexControl: UfControl;
	complexResults: any[];
	complexValue: any;
	complexEmittedValue: any;
	complexTemplateControl: UfControl;
	// Use complexResults
	complexTemplateValue: any;
	complexTemplateEmittedValue: any;

	constructor(private dataService: SCDataService, private toast: ToastService) {

		this.staticControl = new UfControl(ValidatorFunctions.custom((v) =>
			!this.cv.required || !ValidatorFunctions.isEmpty(v),
		'A value is required'),
		);

		this.simpleControl = new UfControl(ValidatorFunctions.custom((v) =>
			!this.cv.required || !ValidatorFunctions.isEmpty(v),
		'A value is required'),
		);

		this.complexControl = new UfControl(ValidatorFunctions.custom((v) =>
			!this.cv.required || !ValidatorFunctions.isEmpty(v),
		'A value is required'),
		);

		this.complexTemplateControl = new UfControl(ValidatorFunctions.custom((v) =>
			!this.cv.required || !ValidatorFunctions.isEmpty(v),
		'A value is required'),
		);

		this.staticResults = this.dataService.searchFruitsNames();

		this.rootControl = new UfControlGroup({
			simple: this.simpleControl,
			complex: this.complexControl,
			template: this.complexTemplateControl,
		});
	}

	async findSimple(query?: string) {
		this.simpleResults = await this.dataService.asyncSearchFruitNames(query, 1000);
	}

	async findComplex(query?: string) {
		this.complexResults = await this.dataService.asyncSearchFruits(query, 200);
	}

	// To test UNIFII-3713
	// findComplexSync(query?: string) {
	//	 this.complexResults = this.dataService.queryFruit(query);
	// }

	emittedStatic(value?: string) {
		this.toast.info(`Emitted static ${value}`);
		this.staticEmittedValue = value;
	}

	emittedSimple(value?: string) {
		this.toast.info(`Emitted simple ${value}`);
		this.simpleEmittedValue = value;
	}

	emittedComplex(value?: any) {
		this.toast.info(`Emitted complex ${value ? JSON.stringify(value) : undefined}`);
		this.complexEmittedValue = value;
	}

	emittedComplexTemplate(value?: any) {
		this.toast.info(`Emitted complexTemplate ${value ? JSON.stringify(value) : undefined}`);
		this.complexTemplateEmittedValue = value;
	}

	complexNamePropertyFunction(option: Fruit): string {
		return `${option.name} (${option.id})`;
	}

}
