import { Injectable, inject } from '@angular/core';
import { TenantClient } from '@unifii/sdk';

import { UserDataDescriptorAdapterLoader } from './user-data-descriptor-adapter';

@Injectable()
export class UfUserDataDescriptorAdapterLoader implements UserDataDescriptorAdapterLoader {

	private tenantClient = inject(TenantClient);

	loadUserClaims() {
		return this.tenantClient.getUserClaims();
	}

	loadRoles() {
		return this.tenantClient.getRoles();
	}

}
