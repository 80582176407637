import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, ValidatorFn } from '@angular/forms';
import { HierarchyUnitFormData, HierarchyUnitWithChildCount, isNotNull } from '@unifii/sdk';
import { debounceTime } from 'rxjs';

import { UfControl } from '../../../controls';
import { HierarchyCascadeSelectionMode, HierarchyUnitIdentifier, HierarchyUnitProvider, UfHierarchyError } from '../../../models';
import { HierarchyService } from '../../../services';
import { SharedTermsTranslationKey } from '../../../translations';
import { HierarchyFunctions, getValueAsArray } from '../../../utils';
import { UfControlValueAccessor } from '../uf-control-value-accessor';

import { HierarchyCascadeSelectionDataSource } from './hierarchy-cascade-selection-data-source';
import { HierarchyUnitCache } from './hierarchy-unit-cache';

@Component({
	selector: 'uf-hierarchy-cascade-selection',
	templateUrl: './hierarchy-cascade-selection.html',
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: HierarchyCascadeSelectionComponent, multi: true },
		HierarchyUnitCache,
	],
})
export class HierarchyCascadeSelectionComponent extends UfControlValueAccessor<HierarchyUnitFormData | HierarchyUnitFormData[]> implements OnInit, OnChanges, OnDestroy {

	@Input() mode: HierarchyCascadeSelectionMode = 'single';
	@Input() activeUnitsOnly: boolean | null | undefined;
	@Input() selectLeavesOnly: boolean | null | undefined;
	@Input() unselectableUnits: HierarchyUnitIdentifier[] | null | undefined;
	@Input() isRequired: boolean | null | undefined;
	@Input() nameProperty: string | ((option: HierarchyUnitWithChildCount) => string) = 'label';
	@Output() override valueChange = new EventEmitter<HierarchyUnitFormData | HierarchyUnitFormData[]>();

	protected readonly sharedTK = SharedTermsTranslationKey;
	protected dataSource: HierarchyCascadeSelectionDataSource;
	protected inputControl = new UfControl();
	protected ready = false;

	private provider = inject(HierarchyUnitProvider);
	private cache = inject(HierarchyUnitCache);
	private service = inject(HierarchyService);
	private validatorsFn: ValidatorFn[] | undefined;
	private skipUpdateInputControl = false;
	private _ceiling: string | null;	
	private _error: UfHierarchyError | undefined;
	
	@Input() override set value(v: HierarchyUnitFormData | HierarchyUnitFormData[] | null | undefined) {
		super.value = v;

		if (this.ready) {
			void this.updateInputControl();
		}
	}

	override get value(): HierarchyUnitFormData | HierarchyUnitFormData[] | null | undefined {
		return super.value;
	}

	@Input() override set control(v: UfControl) {
		super.control = v;

		if (this.ready) {
			void this.updateInputControl();
		}
	}

	override get control(): UfControl {
		return super.control;
	}

	@Input() set ceiling(v: HierarchyUnitIdentifier | null | undefined) {
		this._ceiling = HierarchyFunctions.getId(v);
	}

	get ceiling(): string | null {
		return this._ceiling;
	}

	protected set error(v: UfHierarchyError | undefined) {
		this._error = v;
		this.onDisabledChanges(this.control.disabled);
	}

	protected get error(): UfHierarchyError | undefined {
		return this._error;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!this.ready) {
			return;
		}

		if (changes.isRequired || changes.ceiling || changes.activeUnitsOnly || changes.selectLeavesOnly || changes.unselectableUnits || changes.mode) {
			void this.initCascadeSelectionComponent();
		}
	}

	ngOnInit() {
		this.onDisabledChanges(this.control.disabled);

		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		this.subscriptions.add(this.inputControl.valueChanges.pipe(debounceTime(0)).subscribe(async(stepsValue: HierarchyUnitWithChildCount[] | HierarchyUnitWithChildCount[][] | undefined) => {
			this.control.markAsTouched();
			
			const lastSelectedUnits = stepsValue ? getValueAsArray(stepsValue[stepsValue.length - 1]) : [];
			const units = lastSelectedUnits.length ? (await Promise.all(lastSelectedUnits.map((unit) => this.provider.getUnit(unit.id)))).filter(isNotNull) : [];
			
			// When stepsValue is valorized and the last step match the ceiling skip the emit
			if (this.ceiling && lastSelectedUnits.length === 1 && lastSelectedUnits[0]?.id === this.ceiling) {
				this.control.setValue(null);
				
				return;
			}

			this.skipUpdateInputControl = true;
			this.control.setValue(!units.length ? undefined : this.mode === 'single' ? units[0] : units);
		}));

		void this.initCascadeSelectionComponent();
	}

	override ngOnDestroy() {
		super.ngOnDestroy();
		if (this.validatorsFn) {
			this.control.removeValidators(this.validatorsFn);
		}
	}

	override valueEmitPredicate(value?: HierarchyUnitFormData | HierarchyUnitFormData[] | null, prev?: HierarchyUnitFormData | HierarchyUnitFormData[] | null): boolean {
		const valueArray = getValueAsArray(value);
		const prevArray = getValueAsArray(prev);

		const hasChanged = valueArray.length !== prevArray.length || !valueArray.every((v, i) => v.id === prevArray[i]?.id);

		if (hasChanged && !this.skipUpdateInputControl) {
			void this.updateInputControl();
		}

		this.skipUpdateInputControl = false;

		return hasChanged;
	}

	protected override onDisabledChanges(disabled: boolean) {
		if (disabled || this.error) {
			this.inputControl.disable();
		} else {
			this.inputControl.enable();
		}
	}

	protected reset() {
		this.error = undefined;
		this.control.setValue(null);
	}

	protected retry() {
		this.error = undefined;
		void this.initCascadeSelectionComponent();
	}

	private async initCascadeSelectionComponent() {
		this.ready = false;
		this.error = undefined;

		const dataSource = new HierarchyCascadeSelectionDataSource(
			this.provider,
			this.cache,
			this.service,
			{
				mode: this.mode,
				ceiling: this.ceiling,
				activeUnitsOnly: this.activeUnitsOnly,
				nameProperty: this.nameProperty,
			},
		);

		try {
			await dataSource.init();
		} catch (error) {
			this.error = error as UfHierarchyError;
		}

		if (this.error) {
			return;
		}

		this.dataSource = dataSource;

		if (this.validatorsFn) {
			this.control.removeValidators(this.validatorsFn);
		}
		this.validatorsFn = this.service.createValidator({
			ceilings: this.ceiling ? [this.ceiling] : undefined,
			isRequired: !!this.isRequired,
			selectActivesOnly: !!this.activeUnitsOnly,
			selectLeafsOnly: !!this.selectLeavesOnly,
			unselectableUnits: this.unselectableUnits,
		});
		this.control.addValidators(this.validatorsFn);

		await this.updateInputControl();

		this.ready = true;
	}

	private async updateInputControl() {
		try {
			const stepsValue = await this.dataSource.getStepsValue(this.value);

			this.inputControl.setValue(stepsValue);
		} catch (error) {
			this.error = error as UfHierarchyError;
			console.error('updateInputControl error', this.error);
		}
	}

}
