import { Component, Input } from '@angular/core';
import { DataSeed } from '@unifii/sdk';

import { RuntimeField } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-lookup-display',
	templateUrl: './lookup.html',
})
export class LookupDisplayComponent implements ContentField {

	@Input() field: RuntimeField;
	@Input() content: DataSeed;

}
