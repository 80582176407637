import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';

import { TabsComponent } from './tabs.component';

@Component({
	selector: 'uf-tab',
	templateUrl: './tab.html',
	styleUrls: ['./tab.less'],
})
export class TabComponent implements OnInit, OnDestroy {

	@HostBinding('class.visible') get visible() { return this.active; }

	@Input() label: string;
	@Input() icon: string;
	@Input() back: string;
	@Input() path?: string;

	@Input() set active(v: boolean) {
		this._active = v;
	}

	get active(): boolean {
		return this._active;
	}

	get routerLink(): string {
		return this.path === '' ? './' : this.path ?? '';
	}

	private _active: boolean;

	constructor(private parent: TabsComponent | null) {
		if (!this.parent) {
			throw new Error('TabComponent used outside of a parent TabsComponent!');
		}
		this.parent = parent;
	}

	ngOnInit() {
		this.parent?.add(this);
	}

	ngOnDestroy() {
		this.parent?.remove(this);
	}

}
