<uf-expander *ngIf="showConfiguration">
	<div expanderHeader class="uf-app-bar flat">
		<div class="title">Configuration</div>
		<a class="uf-action">
			<uf-icon (click)="applyToCode()" name="tick" alt="Apply" />
		</a>
	</div>
	<div expanderBody>
		<ng-content select="[configuration]"></ng-content>
	</div>
</uf-expander>

<uf-expander class="uf-box flat" style="overflow: hidden;">
	<div expanderHeader class="uf-app-bar flat">
		<div class="title">DOM</div>
		<a (click)="reset();" class="uf-action" title="reset">
			<uf-icon name="close_button" />
		</a>
		<a class="uf-action">
			<uf-icon (click)="copyDOM()" name="copy" alt="Copy" />
		</a>
	</div>
	<div expanderBody>
		<uf-markdown-editor [(value)]="code" />
	</div>
</uf-expander>


<div class="uf-app-bar flat">
	<div class="title">
		Render
	</div>
	<a class="uf-action">
		<uf-icon (click)="downloadImage()" name="camera" alt="Download" />
	</a>
</div>
<div class="uf-grid">
	<div #render class="col-12">
		<sc-dynamic *ngIf="template" [template]="template" />
	</div>
</div>