import { InjectionToken } from '@angular/core';
import { Dictionary, HierarchyUnitExtended, HierarchyUnitFormData, HierarchyUnitWithChildCount, HierarchyUnitWithPath, isDictionary, isStringNotEmpty } from '@unifii/sdk';

export type HierarchyUnitIdentifier = string | { id: string };

export interface HierarchyUnitProvider {
	getUnit(id: string): Promise<HierarchyUnitExtended | undefined>;
	getChildren(id: string, query?: string, params?: Dictionary<any>): Promise<HierarchyUnitWithChildCount[]>;
	getUnits(ids: string[]): Promise<HierarchyUnitWithPath[]>;
	search(params: { q?: string; leaves?: boolean; ceiling?: string; active?: boolean }): Promise<HierarchyUnitWithPath[]>;
}

export const HierarchyUnitProvider = new InjectionToken<HierarchyUnitProvider>('HierarchyUnitLoader');

export interface HierarchyUnitPickerData {
	/** picker input current value */
	value?: HierarchyUnitFormData | null;
	/** ceiling unit to limit the selection to descendant units only */
	ceiling?: HierarchyUnitIdentifier | null;
	/** unit preselected when the picker dialog opens */
	preselected?: HierarchyUnitIdentifier | null;
	/** picker input label */
	label?: string | null;
	/** picker input is readonly */
	readOnly?: boolean | null;
	/** inactive units won't display as selectable options */
	filterInactiveChildren?: boolean;
	/** add a validator to make the selection required */
	isRequired?: boolean | null;
	/** add a validator that allows only leaf units to be selected */
	selectLeafsOnly?: boolean | null;
	/** add a validator to forbid selection of a specific list of units */
	unselectableUnits?: HierarchyUnitIdentifier[] | null;
	/** add a validator to limit selection of unit to the sections of the tree identified by this list of units' identifier */
	matchOrDescendantsUnits?: HierarchyUnitIdentifier[] | null;
}

export const isHierarchyUnitIdentifier = (value: unknown): value is HierarchyUnitIdentifier =>
	isStringNotEmpty(value) || isDictionary(value) && isStringNotEmpty(value.id);
