import { Injectable, InjectionToken } from '@angular/core';
import { Dictionary } from '@unifii/sdk';

export interface FeatureFlagProvider {
	get: () => Promise<Dictionary<boolean>>;
}

export const FeatureFlagProvider = new InjectionToken<FeatureFlagProvider>('FeatureFlagProvider');

@Injectable()
export class UfFeatureFlagProvider implements FeatureFlagProvider {

	get(): Promise<Dictionary<boolean>> {
		return Promise.resolve({});
	}

}
