import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

import { SharedTermsTranslationKey } from '../../translations';

import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-password',
	templateUrl: './uf-password.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfPasswordComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-password.less'],
})
export class UfPasswordComponent extends UfControlValueAccessor<string> {

	@ViewChild('input', { static: true }) input: ElementRef;

	@Input() id: string;
	@Input() name: string;
	@Input() label?: string | null;
	@Input() placeholder?: string | null;
	@Input() maxLength: number | string;
	@Input() autofocus: boolean;
	@Input() suffix: string;
	@Input() autocomplete = 'new-password';
	@Input() showReveal: boolean;
	@Output() override valueChange = new EventEmitter<string>();

	readonly sharedTermsTK = SharedTermsTranslationKey;

	focused: boolean;
	reveal: boolean;

	@Input() set validators(_validators: any) {
		this.control.validator = Validators.required.bind(this);
	}

	// ---------------------------
	// Host methods

	@HostBinding('class.focused') get focusedClass() {
		return this.focused && !this.disabled;
	}

	@HostBinding('class.error') get errorClass() {
		return this.control.showError && !this.disabled;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	@HostBinding('class.value') get valueClass() {
		return !!this.value;
	}

}
