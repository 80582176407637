import { Component, Input, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HierarchyUnitFormData } from '@unifii/sdk';

import { HierarchyUnitIdentifier, HierarchyUnitPickerData } from '../../models';
import { ModalService } from '../../services';
import { SharedTermsTranslationKey } from '../../translations';

import { HierarchyUnitPickerComponent } from './hierarchy-utilities/hierarchy-unit-picker.component';
import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-hierarchy-list',
	templateUrl: './uf-hierarchy-list.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfHierarchyListComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less'],
})
export class UfHierarchyListComponent extends UfControlValueAccessor<HierarchyUnitFormData[]> {

	@Input() label?: string;
	@Input() ceiling: HierarchyUnitIdentifier = 'root';
	@Input() selectLeafsOnly = false;
	@Input() preselected: HierarchyUnitIdentifier | null;
	@Input() allowDuplicates = false;
	@Input() unselectableUnits: HierarchyUnitIdentifier[] | null;

	readonly sharedTermsTK = SharedTermsTranslationKey;

	private isPickerOpen: boolean;
	private modalService = inject(ModalService);

	async addOrEdit(editUnit?: HierarchyUnitFormData, index?: number) {
		if (this.disabled || this.isPickerOpen) {
			return;
		}

		const controlValue = this.value ?? [];
		const valueUnitsExcludeEditedOne = controlValue.filter((valueUnit) => valueUnit.id !== editUnit?.id);
		const unselectableUnits = [...this.unselectableUnits ?? []];

		if (!this.allowDuplicates) {
			// Units in the control value can't be re-selected (with exclusion of the one under edit)
			unselectableUnits.push(...valueUnitsExcludeEditedOne);
		}

		this.isPickerOpen = true;
		const data: HierarchyUnitPickerData = {
			value: editUnit ?? null,
			label: this.label,
			ceiling: this.ceiling,
			preselected: this.preselected,
			selectLeafsOnly: this.selectLeafsOnly,
			unselectableUnits,
		};

		await this.modalService.openLarge(
			HierarchyUnitPickerComponent,
			data,
			{
				update: (value) => {
					const itemIndex = index ?? -1;

					if (value && !editUnit) {
						this.setControlValue([...controlValue, value]);
					}

					if (value && itemIndex >= 0) {
						const copy = [...controlValue];

						copy.splice(itemIndex, 1, value);
						this.setControlValue(copy);
					}
				},
			},
		);
		this.isPickerOpen = false;
	}

	remove(index: number) {
		if (!this.value) {
			return;
		}

		const copy = [...this.value];

		copy.splice(index, 1);
		this.setControlValue(copy);
	}

	private setControlValue(value: HierarchyUnitFormData[]) {
		this.control.setValue(value);
		this.control.markAsTouched();
	}

}
