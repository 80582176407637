<ng-container [ngSwitch]="locationProvider.type">

	<ng-container *ngSwitchCase="locationType.Google">
		<uf-google-map *ngIf="!disabled && !locked" [value]="value" (valueChange)="markerChange($event)" />

		<uf-map *ngIf="disabled || locked" [marker]="value" />

		<ng-container *ngTemplateOutlet="mapButtons" />

	</ng-container>

</ng-container>

<ng-template #mapButtons>
	<div *ngIf="!disabled && !control.disabled" class="uf-button-group small tertiary">
		<ng-content></ng-content>
		<button *ngIf="!disableClear" [title]="sharedTermsTK.ActionClear | translate" (click)="control.setValue(null)"
			class="uf-button" type="button">
			{{ sharedTermsTK.ActionClear | translate }}
			<uf-icon name="backspace" />
		</button>
		<button (click)="locked = !locked" class="uf-button" type="button">
			<span *ngIf="locked">{{sharedTermsTK.ActionUnlock | translate}}</span>
			<span *ngIf="!locked">{{sharedTermsTK.ActionLock | translate}}</span>
			<uf-icon [name]="locked ? 'unlocked' : 'lock'" />
		</button>
	</div>
</ng-template>

<uf-error [control]="control" />