import { BreadcrumbsComponent } from './breadcrumbs.component';
import { NavigationComponent } from './navigation.component';
import { StepLabelComponent } from './step-label.component';
import { StepComponent } from './step.component';
import { StepperComponent } from './stepper.component';

export * from './breadcrumbs.component';
export * from './stepper.component';
export * from './step.component';
export * from './step-label.component';
export * from './navigation.component';

export const UF_NAVIGATION_COMPONENTS = [BreadcrumbsComponent, StepperComponent, StepComponent, StepLabelComponent, NavigationComponent];
