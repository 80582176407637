import { Type } from '@angular/core';
import { ColumnDescriptor, MessageColour, UfRequestError } from '@unifii/sdk';

import { AngularRouterLink } from '../../angular';
import { SortStatus } from '../../directives';

export enum ActionMultiplicity {
	Single,
	Multiple
}

export type TableRowLinkFunction<T> = (element: T) => AngularRouterLink;

export interface TableRowMemoize<T> {
	link?: AngularRouterLink;
	image?: string;
	label?: string;
	columnsValue: Record<string, unknown>;
	availableActions?: TableAction<T>[];
}

export interface TableRow<T> {
	context: TableRowContext<T>;
	memoize: TableRowMemoize<T>;
}

/** Result of the TableDataSource load action */
export interface TableDataSourceResult<T> {
	/** Successful page load content, T items */
	data?: T[];
	/** Failed page load, error details */
	error?: unknown;
}

/** A single Row metadata information, it's context at runtime */
export interface TableRowContext<T> {
	/** Reference to the data T associated with this Row */
	$implicit: T;
	/** The Row index in the Table data list T[] */
	index: number;
	/** Is the first Row */
	first: boolean;
	/** Is the last Row */
	last: boolean;
	/** The data T has been modified from the original loaded one */
	stale: boolean;
	/** The row is selected */
	selected: boolean;
	/** The Row is expanded (Card more only) */
	expanded: boolean;
}

/** Table metadata information, it's context at runtime */
export interface TableStatus<T> {
	/** The data is filtered */
	filtered: boolean;
	/** The data is sorted */
	sorted: boolean;
	/** The data stream is exhausted */
	exhausted?: boolean;
	/** The data stream is empty */
	empty: boolean;
	/** The DataSource is loading*/
	loading: boolean;
	/** An error has occurred on the last data load operation */
	error?: UfRequestError;
	/** The list of selected items */
	selected: T[];
}

/** Row's UI and behavior configuration */
export interface TableRowConfig<T> {
	/** Row's onClick routing information as route commands or URL */
	link?: TableRowLinkFunction<T>;
	/** Row's onClick callback action */
	action?: (element: T) => void;
	/** Row's image for Card view mode only */
	image?: (element: T) => string;
	/** Row's label for Card view mode only */
	label?: (element: T) => string;
}

/** Column's UI and behavior configuration */
export interface TableConfigColumn<T> {
	/** The column unique identifier within the Table*/
	name: string;
	/** The column label used for the header, toggle etc */
	label?: string;
	/** The column allow sort */
	sortable?: boolean;
	/** The column is hidden fro the UI */
	hidden?: boolean;
	/** Calculate the column display value */
	value?: (element: T, index: number) => unknown;
}

interface TableActionBase<T> {
	/** The display label of the action */
	label?: string;
	/** The display icon of the action */
	icon?: string;
	/** Determine if the action is available */
	predicate?: (context: TableRowContext<T>) => boolean;
}

interface TableActionSingle<T> extends TableActionBase<T> {
	/** Single multiplicity */
	multiplicity: ActionMultiplicity.Single;
	/** Callback for the action execution */
	action?: (context: TableRowContext<T>) => Promise<void> | void;
}

interface TableActionMultiple<T> extends TableActionBase<T> {
	/** Single multiplicity */
	multiplicity?: ActionMultiplicity.Multiple;
	/** Callback for the action execution */
	action?: (context: TableRowContext<T>[]) => Promise<void> | void;
}

export type TableAction<T> = TableActionSingle<T> | TableActionMultiple<T>;

export interface TableConfig<T> {
	/** Columns configuration */
	columns: TableConfigColumn<T>[];
	/** Unique identifier within the system, used by TableUserPreference */
	id?: string;
	/** Actions configuration */
	actions?: TableAction<T>[];
	/** Allows rows selection, max selection limit */
	selectable?: boolean | number; // TODO only accept number limit must be set
	/** Allows toggle (show/hide) of columns */
	columnToggles?: boolean;
	/** Allows expand/collapse when in Cards view mode */
	cardExpands?: boolean;
	/** Data pagination size */
	pageSize?: number;
	/** Fetch one extra T on page load to determine ahead the data stream exhausted status */
	exhaustAhead?: boolean;
	/** Customize how to uniquely identify the Ts in the Table */
	trackByFn?: (index: number, element: T) => any;
	/** @deprecated use row.link */
	rowLink?: TableRowLinkFunction<T>;
	/** @deprecated use row.action */
	rowAction?: (element: T) => void;
	/** Row configuration */
	row?: TableRowConfig<T>;
}

export interface TableColumnVisible {
	/** Column unique identifier */
	name: string;
	/** Column is visible */
	visible: boolean;
}

export type TableColumnPreferences = TableColumnVisible;

/** Storable preferences for override Table initial configuration */
export interface TablePreferences {
	columns?: TableColumnPreferences[];
	sort?: SortStatus;
}

/** Descriptor for the Custom Cell feature */
export type ColumnDisplayDescriptor = Pick<ColumnDescriptor, 'defaultCellValue' | 'defaultTemplate' | 'variations'> & {
	/** Mapped from column identifier */
	name: string;
	/** Specific component to render this column's cells */
	component?: Type<TableCell>;
};

export interface TableCell {
	value: unknown;
	colour?: `${MessageColour}`;
}

/**
 * List of CSS classes that change the render mode of the table
 * [render mode]-[apply at breakpoint]
 */
export const TableRenderModifierClassNames = [
	'list', 'list-sm', 'list-md', 'list-lg', 'list-xl', 'list-xxl',
	'cards', 'cards-sm', 'cards-md', 'cards-lg', 'cards-xl', 'cards-xxl',
];

/**
 * Complete list of table modifier classes, these are made public as the table's style
 * is often modified programmatically by consumers of the common library
 */
export const TableModifierClassNames = ['accent', 'small-message', 'sticky', ...TableRenderModifierClassNames];
