import { Component, HostBinding, Input } from '@angular/core';

import { RuntimeField } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-multitext-display',
	templateUrl: './multitext.html',
	styles: [`:host { display: block; }`],
})
export class MultiTextComponent implements ContentField {

	@HostBinding('class.body-copy') bodyclass = true;

	@Input() content?: string | null;
	@Input() field: RuntimeField;

}
