import { WorldSubregion } from './world-data-model';

export const worldSubregions: WorldSubregion[] = [
	{
		id: 19,
		name: 'Australia and New Zealand',
		parent_id: 5,
	},
	{
		id: 7,
		name: 'Caribbean',
		parent_id: 2,
	},
	{
		id: 9,
		name: 'Central America',
		parent_id: 2,
	},
	{
		id: 10,
		name: 'Central Asia',
		parent_id: 3,
	},
	{
		id: 4,
		name: 'Eastern Africa',
		parent_id: 1,
	},
	{
		id: 12,
		name: 'Eastern Asia',
		parent_id: 3,
	},
	{
		id: 15,
		name: 'Eastern Europe',
		parent_id: 4,
	},
	{
		id: 20,
		name: 'Melanesia',
		parent_id: 5,
	},
	{
		id: 21,
		name: 'Micronesia',
		parent_id: 5,
	},
	{
		id: 2,
		name: 'Middle Africa',
		parent_id: 1,
	},
	{
		id: 1,
		name: 'Northern Africa',
		parent_id: 1,
	},
	{
		id: 6,
		name: 'Northern America',
		parent_id: 2,
	},
	{
		id: 18,
		name: 'Northern Europe',
		parent_id: 4,
	},
	{
		id: 22,
		name: 'Polynesia',
		parent_id: 5,
	},
	{
		id: 8,
		name: 'South America',
		parent_id: 2,
	},
	{
		id: 13,
		name: 'South-Eastern Asia',
		parent_id: 3,
	},
	{
		id: 5,
		name: 'Southern Africa',
		parent_id: 1,
	},
	{
		id: 14,
		name: 'Southern Asia',
		parent_id: 3,
	},
	{
		id: 16,
		name: 'Southern Europe',
		parent_id: 4,
	},
	{
		id: 3,
		name: 'Western Africa',
		parent_id: 1,
	},
	{
		id: 11,
		name: 'Western Asia',
		parent_id: 3,
	},
	{
		id: 17,
		name: 'Western Europe',
		parent_id: 4,
	},
];
