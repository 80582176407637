export * from './field-filters';
export * from './filter-displays.component';
export * from './filter-inputs.component';
export * from './filter.component';

import { UF_FILTER_FIELD_COMPONENTS } from './field-filters';
import { FilterDisplaysComponent } from './filter-displays.component';
import { FilterInputsComponent } from './filter-inputs.component';
import { FilterWrapperComponent } from './filter.component';

export const UF_FILTERS_COMPONENTS = [
	...UF_FILTER_FIELD_COMPONENTS,
	FilterDisplaysComponent, FilterInputsComponent, FilterWrapperComponent,
];
