<sc-block title="Actions" collapsable="true">
	<div class="grid row">
		<button (click)="setDate()" class="uf-button" type="button">Set date</button>
		<button (click)="setDateFormat()" class="uf-button" type="button">Set date format</button>
		<button (click)="setTime()" class="uf-button" type="button">Set time</button>
		<button (click)="setTimeFormat()" class="uf-button" type="button">Set time format</button>
		<button (click)="setDatetimeFormat()" class="uf-button" type="button">Set Datetime format</button>
		<button (click)="setDateTime()" class="uf-button" type="button">Set Date Time</button>
	</div>
</sc-block>

<div class="fieldset">
	<div class="field">
		<label>Date Value</label>
		<pre>{{dateValue | json}}</pre>
	</div>
	<div class="field">
		<label>Date Control value</label>
		<pre>{{ dateControl.value | json}}</pre>
	</div>
	<div class="field">
		<label>Time Value</label>
		<pre>{{timeValue | json}}</pre>
	</div>
	<div class="field">
		<label>Time Control value</label>
		<pre>{{ timeControl.value | json}}</pre>
	</div>
	<div class="field">
		<label>Date Time Value</label>
		<pre>{{dateTimeValue | json}}</pre>
	</div>
	<div class="field">
		<label>Date Time Control value</label>
		<pre>{{ dateTimeControl.value | json}}</pre>
	</div>
</div>

<uf-date [control]="dateControl" [format]="dateFormat" [(value)]="dateValue" (valueChange)="changed($event)"
	class="inline" label="Label" />

<uf-time [control]="timeControl" [format]="timeFormat" [(value)]="timeValue" (valueChange)="changed($event)"
	class="inline" label="Label" />

<uf-datetime [control]="dateTimeControl" [format]="dateTimeFormat" [(value)]="dateTimeValue"
	(valueChange)="changed($event)" class="inline" label="Label" />