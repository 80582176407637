<ng-container [ngSwitch]="field.type">

	<!-- todo: find cleaner way of doing this -->
	<uf-text *ngSwitchCase="fieldType.Text" [ngClass]="cssClass" [control]="control" [value]="content"
		[label]="field.label" [placeholder]="field.placeholder" [maxLength]="field.maxLength"
		(valueChange)="changed($event)">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field.help" [content]="field.help" />
		<ng-container actions *ngIf="field.dataCapture?.barcode">
			<div class="input-action-btn">
				<uf-barcode-scanner [disabled]="control.disabled" (valueChange)="changed($event)" />
			</div>
		</ng-container>
	</uf-text>

	<uf-textarea *ngSwitchCase="fieldType.MultiText" [ngClass]="cssClass" [control]="control" [value]="content"
		[placeholder]="field.placeholder" [label]="field.label" [maxLength]="field.maxLength"
		(valueChange)="changed($event)">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field.help" [content]="field.help" />
		<ng-container actions *ngIf="field.dataCapture?.barcode">
			<div class="input-action-btn">
				<uf-barcode-scanner [disabled]="control.disabled" (valueChange)="changed($event)" />
			</div>
		</ng-container>
	</uf-textarea>

	<uf-cost *ngSwitchCase="fieldType.Cost" [ngClass]="cssClass" [control]="control" [value]="content"
		[label]="field.label" [currency]="field.currency || 'AUD'" [placeholder]="field.placeholder"
		(valueChange)="changed($event)">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field.help" [content]="field.help" />
	</uf-cost>

	<uf-date *ngSwitchCase="fieldType.Date" [ngClass]="cssClass" [control]="control" [value]="content"
		[label]="field.label" [placeholder]="field.placeholder" [format]="field.format" (valueChange)="changed($event)">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field.help" [content]="field.help" />
	</uf-date>

	<!-- <uf-date *ngSwitchCase="fieldType.Time" [ngClass]="cssClass" [control]="control" [value]="content"
		(valueChange)="changed($event)" [label]="field.label" [placeholder]="field.placeholder" [format]="field.format">
		<span  *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field?.help" [content]="field?.help"></uf-help>
	</uf-date> -->

	<uf-time *ngSwitchCase="fieldType.Time" [ngClass]="cssClass" [control]="control" [value]="content"
		[label]="field.label" [placeholder]="field.placeholder" [step]="field.step" [format]="field.format"
		(valueChange)="changed($event)">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field.help" [content]="field.help" />
	</uf-time>

	<uf-datetime *ngSwitchCase="fieldType.DateTime" [ngClass]="cssClass" [control]="control" [value]="content"
		[label]="field.label" [placeholder]="field.placeholder" [step]="field.step" [format]="field.format"
		(valueChange)="changed($event)">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field.help" [content]="field.help" />
	</uf-datetime>

	<uf-datetime-tz *ngSwitchCase="fieldType.ZonedDateTime" [ngClass]="cssClass" [control]="control" [value]="content"
		[label]="field.label" [placeholder]="field.placeholder" [step]="field.step" [format]="field.format"
		(valueChange)="changed($event)">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field.help" [content]="field.help" />
	</uf-datetime-tz>

	<uf-number *ngSwitchCase="fieldType.Number" [ngClass]="cssClass" [control]="control" [value]="content"
		[label]="field.label" [placeholder]="field.placeholder" [precision]="field.precision"
		(valueChange)="changed($event)">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field.help" [content]="field.help" />
	</uf-number>

	<uf-phone *ngSwitchCase="fieldType.Phone" [ngClass]="cssClass" [control]="control" [value]="content"
		[label]="field.label" [placeholder]="field.placeholder" [maxLength]="field.maxLength"
		(valueChange)="changed($event)">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field.help" [content]="field.help" />
	</uf-phone>

	<uf-email *ngSwitchCase="fieldType.Email" [ngClass]="cssClass" [control]="control" [value]="content"
		[label]="field.label" [placeholder]="field.placeholder" [maxLength]="field.maxLength"
		(valueChange)="changed($event)">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field.help" [content]="field.help" />
	</uf-email>

	<uf-url *ngSwitchCase="fieldType.Website" [ngClass]="cssClass" [control]="control" [value]="content"
		[label]="field.label" [placeholder]="field.placeholder" [maxLength]="field.maxLength"
		(valueChange)="changed($event)">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field.help" [content]="field.help" />
	</uf-url>

	<uf-signature *ngSwitchCase="fieldType.Signature" [ngClass]="cssClass" [control]="control" [value]="content"
		[label]="field.label" (valueChange)="changed($event)">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field.help" [content]="field.help" />
	</uf-signature>

</ng-container>