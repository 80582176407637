import { AfterViewInit, Component, EventEmitter, HostBinding, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Dictionary, FieldType, FormStyle, GeoLocation, ensureError, isGeoLocation, isNotNull, isNumber } from '@unifii/sdk';
import { Subscription, debounceTime, distinctUntilChanged, filter } from 'rxjs';

import { GeoLocationProperties, LocationProvider, ProgressComponent, RuntimeField, SharedTermsTranslationKey, ToastService,	UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { FormField, FormService, SmartFormsTranslationKey, WorkflowService } from '@unifii/library/smart-forms';

import { NestedField } from './model';

const PreventDisabledBubble = 'preventDisabledBubble';
const LatIdentifier = 'lat';
const LngIdentifier = 'lng';
const ZoomIdentifier = 'zoom';

type GeoLocationNestedField = NestedField & { identifier: string };

@Component({
	selector: 'uf-geo-location-input',
	templateUrl: './geo-location.html',
	styleUrls: ['../form-group.less', './geo-location.less'],
})
export class GeoLocationComponent implements FormField, OnInit, AfterViewInit, OnDestroy {

	@HostBinding('class.uf-box') protected groupClassName = true;
	@ViewChild(ProgressComponent) private progress: ProgressComponent;
	
	contentChange: EventEmitter<GeoLocation>;
	control: UfControlGroup;	
	field: RuntimeField;
	cssClass: string | string[];

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected fields: GeoLocationNestedField[];

	private readonly cssClasses: Dictionary<string> = {
		lat: 'col-1of2',
		lng: 'col-1of2',
		map: 'col-1of1',
	};
	private collapsed: boolean;
	private _content: GeoLocation | null = null;
	private inputLabels: Dictionary<string>;
	private lastEmit: GeoLocation;	
	private requiredValidators: Dictionary<ValidatorFn>;
	private subscriptions = new Subscription();
	private locationProvider = inject(LocationProvider);
	private toastService = inject(ToastService);
	private translateService = inject(TranslateService);
	private formService = inject(FormService, { optional: true });
	private workflowService = inject(WorkflowService, { optional: true });

	@HostBinding('class.collapsed') get hostCollapsed() {
		return this.collapsed;
	}

	@HostBinding('class.error') get hostError() {
		return this.control.submitted && this.control.invalid;
	}

	@HostBinding('class.disabled') get hostDisabled() {
		return this.control.disabled;
	}

	@HostBinding('class.hidden') get hidden() {
		/**
		 * If there are no visible fields the input is considered as hidden and not displayed
		 * this is used in with autodetect so it can silently autodetect
		 * not great behavior this is a side effect that should be fixed
		 */
		return !this.field.visibleFields.some((v) => Object.keys(this.inputLabels).includes(v));
	}

	set content(v: GeoLocation) {

		if (this.isEqual(v, this._content)) {
			return;
		}
		this.lastEmit = v;
		this._content = v;
	}

	get content(): GeoLocation {
		return this._content ?? { lat: 0, lng: 0 };
	}

	ngOnInit() {

		this.inputLabels = {
			lat: this.translateService.instant(SmartFormsTranslationKey.GeoLocationInputFieldLatitudeLabel) as string,
			lng: this.translateService.instant(SmartFormsTranslationKey.GeoLocationInputFieldLongitudeLabel) as string,
			map: this.translateService.instant(SmartFormsTranslationKey.GeoLocationInputFieldPositionLabel) as string,
		};

		this.requiredValidators = {
			map: ValidatorFunctions.custom(this.mapValidator.bind(this),
				this.translateService.instant(SharedTermsTranslationKey.ValidatorValueRequired) as string),
		};

		if (this.hidden) {
			void this.locateHiddenField();

			return;
		}

		const fields = this.createFields();
		const controls = this.createFormControls(fields, this.requiredValidators, this.control);

		/** set an enabled control if all the fields are read only
		 * to make sure the disabled state doesn't buddle up
		 **/
		let enabledControl;

		if (!fields.some((f) => !f.isReadOnly) && !this.control.disabled) {
			enabledControl = new UfControl();
			this.control.setControl(PreventDisabledBubble, enabledControl);
		}

		for (const { identifier, control } of controls) {
			if (identifier === LatIdentifier || identifier === LngIdentifier) {
				this.subscriptions.add(
					control.valueChanges
						.pipe(debounceTime(300), distinctUntilChanged())
						.pipe(filter(isNumber))
						.subscribe((v) => { this.updateGeoLocationLatLngControl(identifier, v); }),
				);
			}
			this.control.setControl(identifier, control);
		}

		// if there is at least one required field, add validator and dependency
		const requiredField = fields.find((f) => f.isRequired);

		if (enabledControl && requiredField) {
			const dependency = this.control.controls[requiredField.identifier] as UfControl;

			enabledControl.setValidators(ValidatorFunctions.custom(() => dependency.value != null, this.translateService.instant(SharedTermsTranslationKey.ValidatorValueRequired) as string));
			enabledControl.addDependencies([dependency]);
			enabledControl.updateValueAndValidity();
		}

		this.subscriptions.add(this.control.valueChanges.subscribe((value) => { this.onContentChange(value); }));

		this.fields = fields;
	}

	ngAfterViewInit() {
		/**
		 * After view required to ensure we have access to all child components
		 * progress component is required for locate
		 */
		if (this.field.autoDetect &&
			!this.hidden &&
			this.inActiveSection &&
			!this.hasValue
		) {
			/**
			 * Auto detect
			 * // TODO shouldn't emit dirty or touched status on control
			 */
			void this.locate();
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	async locate() {
		try {
			this.progress.start();
			this.updateContent(await this.locationProvider.locate());
		} catch (error) {
			this.toastService.error(ensureError(error).message);
		} finally {
			this.progress.complete();
		}
	}

	updateContent(location: GeoLocation = { lat: 0, lng: 0 }) {
		/**
		 * Create object that matches controls otherwise well angular will throw errors
		 */
		const content = (Object.keys(this.control.controls) as (keyof GeoLocation)[])
			.reduce<GeoLocation>((obj, key) => {
				obj[key] = location[key] ?? 0;

				return obj;
			}, { lat: 0, lng: 0 });

		if (this.control.controls.map) {
			Object.assign(content, {
				map: Object.assign({}, location),
			});
		}

		this.control.patchValue(content);

		// trigger validity check on readonly control
		const readonlyControl = this.control.get(PreventDisabledBubble);

		if (readonlyControl != null) {
			readonlyControl.updateValueAndValidity({ emitEvent: false });
		}
	}

	protected updateGeoLocationLatLngControl(identifier: 'lat' | 'lng', latOrLngValue?: number) {
		const mapControl = this.control.get('map');

		if (mapControl) {
			const mapValue = mapControl.getRawValue();

			mapControl.patchValue({
				...mapValue,
				[identifier]: latOrLngValue,
			});
		}
	}

	get isSummary() {
		return this.formService?.style === FormStyle.Summary;
	}

	private createFields(): GeoLocationNestedField[] {

		const order = Object.keys(this.inputLabels);

		return this.field.visibleFields
		// ensure correct order
			.sort((a, b) => order.indexOf(a) - order.indexOf(b))
			.map((key) => ({
				label: this.inputLabels[key],
				type: FieldType.Number,
				identifier: key,
				isRequired: this.field.requiredFields.includes(key),
				isReadOnly: this.field.readOnlyFields.includes(key),
				cssClass: [this.cssClasses[key], ...this.customCssClass].join(' '),
				suffix: this.getFieldSuffix(this.field.requiredFields.includes(key)),
			}))
		// filter results that don't exist
			.filter(this.isGeoLocationNestedField.bind(this));
	}

	private isGeoLocationNestedField(value: Partial<GeoLocationNestedField>): value is GeoLocationNestedField {
		return value.label != null;
	}

	private createFormControls(fields: GeoLocationNestedField[], validatorLookup: Dictionary<ValidatorFn> = {}, parentControl: UfControlGroup): { identifier: string; control: UfControl }[] {

		return fields
			.map((field) => {

				const control = new UfControl();

				if (field.isRequired) {
					const validator = validatorLookup[field.identifier] ??
						ValidatorFunctions.required(this.translateService.instant(SharedTermsTranslationKey.ValidatorValueRequired) as string);

					control.setValidators(validator);
				}

				if (field.isReadOnly) {
					control.component = { disabled: true };
				}

				if (parentControl.disabled || field.isReadOnly) {
					control.disable();
				}

				return {
					identifier: field.identifier,
					control,
				};
			});
	}

	private async locateHiddenField() {
		if (this.hasValue) {
			return;
		}

		try {
			this.contentChange.emit(await this.locationProvider.locate());
		} catch (e) {
			console.error('GeoLocationComponent - auto-locate failed', e);
		}
	}

	private getFieldSuffix(required: boolean): string {

		if (!this.formService) {
			return '';
		}

		if (required && this.formService.definitionSettings.requiredSuffix) {
			return this.formService.definitionSettings.requiredSuffix;
		}

		return this.formService.definitionSettings.optionalSuffix ?? '';
	}

	private get hasValue(): boolean {
		/**
		 * Test for a valid geolocation (must have either lnt or lng property)
		 * - { foo: '', bar: '' } - fail
		 * - { lng: 1234 } - ok
		 */
		return typeof this.content[GeoLocationProperties.Lat] === 'number' || typeof this.content[GeoLocationProperties.Lng] === 'number';
	}

	private mapValidator(value: GeoLocation): boolean {
		return isGeoLocation(value);
	}

	private get inActiveSection(): boolean {
		return this.workflowService != null && this.workflowService.isActive(this.field);
	}

	private get customCssClass(): string[] {
		return (Array.isArray(this.cssClass) ? this.cssClass : [this.cssClass]).filter(isNotNull);
	}

	/** Geolocation | */
	private onContentChange(value: any) {
		/**
		 * Angular does not include values for disabled control values when passing the value
		 * to subscribers could be design or bug, will need to review when upgraded to angular 12
		 */
		if (this.control.get(PreventDisabledBubble) != null) {
			Object.keys(this.control.controls).forEach((key) => {
				value[key] = this.control.get(key)?.value;
			});
		}
		/**
		 * Map control needs to be part of the control tree
		 * for validation but values should not be emitted as part of the value
		 */
		if (value.map !== undefined) {
			Object.assign(value, value.map);
			delete value.map;
		}

		/** Clean data */
		for (const key of Object.keys(value)) {
			if (value[key] == null) {
				// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
				delete value[key];
			}
		}

		if (!this.isEqual(value, this.lastEmit)) {
			this.lastEmit = value;

			if (!Object.keys(value).length) {
				this.contentChange.emit(undefined);
			} else {
				this.contentChange.emit(value);
			}
		}
	}

	private isEqual(geo1: GeoLocation | undefined | null, geo2: GeoLocation | undefined | null): boolean {

		if (!geo1 && !geo2) {
			return true;
		}

		if (!geo1 || !geo2) {
			return false;
		}

		for (const key of [LatIdentifier, LngIdentifier, ZoomIdentifier] as (keyof GeoLocation)[]) {
			if (geo1[key] !== geo2[key]) {
				return false;
			}
		}

		return true;
	}

}
