import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-slider',
	templateUrl: './uf-slider.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfSliderComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-slider.less'],
})
export class UfSliderComponent extends UfControlValueAccessor<string> {

	@Input() name: string;
	@Input() label?: string | null;
	@Input() min = 0;
	@Input() max = 100;
	@Input() step: number;
	@Input() autocomplete: string = Math.random().toString(36).substring(2, 18);
	@Output() override valueChange = new EventEmitter<string>();

	focused: boolean;

	@HostBinding('class.focused') get focusedClass() {
		return this.focused && !this.disabled;
	}

	@HostBinding('class.error') get errorClass() {
		return this.control.showError && !this.disabled;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled || this.control.disabled;
	}

	@HostBinding('class.value') get valueClass() {
		return !!this.value;
	}

}
