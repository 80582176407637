import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AttachmentsClient, Client } from '@unifii/sdk';

import { BucketDataDescriptorPermissionController, CollectionDataDescriptorPermissionController, ContextProvider, FeatureFlagProvider, GoogleLocationProvider, GoogleWindow, LocationProvider, UserDataDescriptorPermissionController, WindowResizeEventHandler, WindowWrapper } from '@unifii/library/common';
import { DisplayModule } from '@unifii/library/smart-forms/display';
import { InputModule } from '@unifii/library/smart-forms/input';

import { SC_COMPONENTS } from './components';
import { SC_DIRECTIVES } from './directives';
import { Config } from './models';
import { SC_PAGES, ShowCascadeSelectionDataSource, ShowcaseDataDescriptorPermissionController } from './pages';
import { ShowcaseFeatureFlagProvider, ShowcaseRepositoryService, UsAPIContentClient } from './services';

const createMapProvider = (
	translateService: TranslateService,
	window: GoogleWindow,
	contextProvider: ContextProvider,
): LocationProvider =>
	new GoogleLocationProvider(translateService, window, 'AIzaSyDt1pfi0XdzWJwNdH8ILiDPJy15IGbX5xs', contextProvider);

@NgModule({
	imports: [RouterModule, InputModule, DisplayModule],
	declarations: [
		SC_PAGES, SC_COMPONENTS, SC_DIRECTIVES,
	],
	exports: [
		InputModule, DisplayModule, SC_PAGES, SC_COMPONENTS, SC_DIRECTIVES,
	],
	providers: [
		{ provide: UsAPIContentClient, useFactory:
			(config: Config, repository: ShowcaseRepositoryService): UsAPIContentClient =>
				new UsAPIContentClient(config.unifii, repository),
		deps: [Config, ShowcaseRepositoryService] },
		{ provide: AttachmentsClient, useFactory:
			(client: Client, repository: ShowcaseRepositoryService) =>
				new AttachmentsClient(client, { projectId: repository.projectId, preview: repository.preview }),
		deps: [Client, ShowcaseRepositoryService] },
		{ provide: LocationProvider, useClass: GoogleLocationProvider, useFactory: createMapProvider, deps: [TranslateService, WindowWrapper, ContextProvider] },
		{ provide: FeatureFlagProvider, useClass: ShowcaseFeatureFlagProvider },
		ShowcaseDataDescriptorPermissionController,
		ShowCascadeSelectionDataSource,
		WindowResizeEventHandler,
		{ provide: UserDataDescriptorPermissionController, useExisting: ShowcaseDataDescriptorPermissionController },
		{ provide: CollectionDataDescriptorPermissionController, useExisting: ShowcaseDataDescriptorPermissionController },
		{ provide: BucketDataDescriptorPermissionController, useExisting: ShowcaseDataDescriptorPermissionController },
		/* Data Display - override default registries
		 * toggle following providers in order to test the extendibility of DataDisplay registries chain
		 * when override registry are provided by the app module
		*/
		// { provide: DataDisplayTableComponentRegistry, useClass: UfDataDisplayContentTableComponentRegistry },
		// { provide: DataDisplayComponentRegistry, useClass: ShowcaseDataDisplayComponentRegistry },
	],
})
export class ShowCaseModule { }
