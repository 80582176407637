import { AstNode, DataSourceType } from '@unifii/sdk';

import { SourceConfig } from '../../models';

export const hasSourceConfigId = (sourceConfig: {type: DataSourceType; id?: string}): sourceConfig is { type: DataSourceType; id: string } => {
	return !!sourceConfig.id;
};

export const hasSourceConfigSort = (sourceConfig: {type: DataSourceType; sort?: string}): sourceConfig is { type: DataSourceType; sort?: string } => {
	return !!sourceConfig.sort;
};

export const hasSourceConfigFilter = (sourceConfig: {type: DataSourceType; filter?: AstNode}): sourceConfig is { type: DataSourceType; filter?: AstNode } => {
	return !!sourceConfig.filter;
};

/** SourceConfigs are considered compatible sources of data when
 *  * same type
 *  * same id if the data type require an id
 */
export const areSourceConfigsCompatible = (a: SourceConfig, b: SourceConfig): boolean => {
	if (a.type !== b.type) {
		return false;
	}

	if (hasSourceConfigId(a) && hasSourceConfigId(b)) {
		return a.id === b.id;
	}

	return true;
};
