import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
	name: 'asSafeAbstractControl',
})
export class AsUfSafeAbstractControlPipe implements PipeTransform {

	transform(value: AbstractControl | null): AbstractControl {
		return value as AbstractControl;
	}

}
