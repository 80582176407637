import { Component, Input, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DataDisplayComponentType } from '../../../models';
import { SharedTermsTranslationKey } from '../../../translations';
import { ContentModalData } from '../../modal';

import { DataDisplaySignatureValue, isDataDisplaySignatureValue } from './data-display-components-model';

@Component({
	selector: 'uf-data-display-signature',
	templateUrl: './data-display-signature.html',
	styleUrls: ['../data-display.less'],
})
export class DataDisplaySignatureComponent implements DataDisplayComponentType, OnInit {
	
	@Input({ required: true }) data: DataDisplaySignatureValue;

	static isDataAccepted = isDataDisplaySignatureValue;
	
	protected linkLabel: string;
	protected contentModalData: ContentModalData;
	
	private translateService = inject(TranslateService);

	ngOnInit() {
		this.linkLabel = this.data.label ?? this.translateService.instant(SharedTermsTranslationKey.ActionView);
		this.contentModalData = { imageUrl: this.data.signature };
	}
	
}
