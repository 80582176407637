<div class="grid--fixed">
	<h2 class="col-1of1">Client</h2>

	<div class="grid col-1of1">
		<uf-message *ngIf="error" [content]="error.error_description" class="col-1of1 error" icon="error"
			heading="Error">
			<div class="col-1of1 row space-children">
				<button (click)="error = null" class="uf-button primary" type="button">Clear</button>
			</div>
		</uf-message>

		<uf-message *ngIf="success" [content]="success" class="col-1of1 success" icon="successSolid" heading="Success">
			<div class="col-1of1 row space-children">
				<button (click)="success = null" class="uf-button primary" type="button">Clear</button>
			</div>
		</uf-message>
	</div>

	<div class="uf-box col-1of1">
		<div class="uf-header--accent">
			<div class="title">Authentication</div>
		</div>
		<div class="grid">
			<ng-template [ngIf]="isAuthenticated">
				<uf-message class="col-1of1 info" icon="info" heading="You are authenticated">
					<div class="col-1of1 row space-children">
						<button (click)="logout()" class="uf-button primary" type="button">Logout</button>
					</div>
				</uf-message>
			</ng-template>
			<ng-template [ngIf]="!isAuthenticated">
				<uf-text [(value)]="oauthUsername" class="col-1of1" label="Username" />
				<uf-text [(value)]="oauthPassword" class="col-1of1" label="Password" />
				<uf-text [value]="options.appId" class="col-1of1" label="AppId" disabled="true" />
				<div class="col-1of1">
					<button (click)="authenticate()" class="uf-button right" type="button">Authenticate</button>
				</div>
			</ng-template>
		</div>
	</div>

	<div class="uf-box col-1of1">
		<div class="uf-header--accent">
			<div class="title">Configuration</div>
		</div>
		<div class=" grid">
			<div *ngIf="!isAuthenticated" class="col-1of1 grid">
				<uf-checkbox [value]="hasApiSecret" (valueChange)="toggleApiSecret()" class="col-1of2"
					label="ApiSecret" />
				<uf-checkbox [value]="hasAppSecret" [disabled]="hasApiSecret" (valueChange)="toggleAppSecret()"
					class="col-1of2" label="AppSecret" />
			</div>
			<uf-message *ngIf="isAuthenticated" class="col-1of1 info" icon="info"
				heading="Logout to use configurations" />
		</div>
	</div>

	<div *ngIf="isAuthenticated || isApiSecret" class="uf-box col-1of1">
		<div class="uf-header--accent">
			<div class="title">{{isAuthenticated ? 'Token Access' : 'ApiSecret Access'}}</div>
		</div>
		<div class=" grid">
			<uf-text *ngIf="options.apiKey" [value]="options.apiKey" class="col-1of1" disabled="true" label="ApiKey" />
			<uf-text *ngIf="options.apiSecret" [value]="options.apiSecret" class="col-1of1" disabled="true"
				label="ApiSecret" />
			<div class="col-1of1">
				<button (click)="loadProjects()" class="uf-button" type="button">Authorised - Projects</button>
			</div>
		</div>
	</div>

	<div *ngIf="isAppSecret" class="uf-box col-1of1">
		<div class="uf-header--accent">
			<div class="title">AppSecret Access</div>
		</div>
		<div class=" grid">
			<uf-text [value]="options.appId" class="col-1of1" disabled="true" label="AppId" />
			<uf-textarea [value]="options.appSecret" class="col-1of1" disabled="true" label="AppSecret" />
			<uf-textarea [(value)]="userToken" class="col-1of1" label="1 - Token" />
			<uf-text [(value)]="username" class="col-1of2" label="2 - Username" />
			<uf-text [(value)]="userId" class="col-1of2" label="3 - UserId" />
			<uf-text [(value)]="oldPassword" class="col-1of2" label="4 - Old password" />
			<uf-text [(value)]="userPassword" class="col-1of2" label="5 - Password" />
			<div class="col-1of1">
				<button *ngIf="username" (click)="requestResetPassword()" class="uf-button" type="button">
					[2] Anonymous - Request Reset Password
				</button>
				<button *ngIf="userId && userToken" (click)="loadUser()" class="uf-button" type="button">
					[1, 3] Anonymous - Load User
				</button>
				<button *ngIf="userId && oldPassword && userPassword && userToken" (click)="updatePassword()"
					class="uf-button" type="button">
					[1, 3, 4] Anonymous - Update Password
				</button>
			</div>
		</div>
	</div>
</div>