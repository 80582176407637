<div class="grid--fixed">
	<div class="col-1of1 row center-all">
		<uf-radio [options]="['Radio', 'Select']" [(value)]="type" />
		<uf-checkbox [(value)]="readonly" label="Readonly" />
		<uf-checkbox [(value)]="isFocused" label="Focused" />
	</div>

	<div class="col-1of1 row center-all">
		<button (click)="resetOptions()" type="button" class="uf-button small">Reset options</button>
		<button (click)="resetValue()" type="button" class="uf-button small">Reset value</button>
		<button (click)="pushOption()" type="button" class="uf-button small">Push option</button>
		<button (click)="emptyOptions()" type="button" class="uf-button small">Empty Options</button>
		<button (click)="assignApple()" type="button" class="uf-button small">Set to Apple</button>
		<button (click)="assignBroccoli()" type="button" class="uf-button small">Set to broccoli</button>
		<button (click)="setNonsense()" type="button" class="uf-button small">Set to item not on the list</button>
	</div>
	<div class="col-1of1">
		<h4>Input</h4>
		<uf-select *ngIf="type === 'Select'" [options]="options" [disabled]="readonly" [control]="control"
			[(value)]="value" (valueChange)="valueChanged($event)" ufAutofocus id="select_input_01" name="select"
			label="Best fruit" placeholder="All" nameProperty="name" valueProperty="identifier" />
		<uf-radio *ngIf="type === 'Radio'" [options]="options" [disabled]="readonly" [control]="control"
			[(value)]="value" (valueChange)="valueChanged($event)" id="select_input_01" name="select" label="Best fruit"
			placeholder="All" nameProperty="name" valueProperty="identifier" />
	</div>
	<div class="col-1of1 row">
		<div class="fieldset-item--small--stacked grow">
			<span>Value:</span>
			<span>
				<pre>{{value | json}}</pre>
			</span>
		</div>
	</div>

</div>