<uf-expander [actionBtn]="true">
	<div [ngClass]="cssClass" expanderHeader class="uf-app-bar medium">
		<div class="title">
			{{field.label}}
			<uf-help *ngIf="field.help" [content]="field.help" icon="infoSolid" class="grow" />
		</div>
		<button *ngIf="!control.disabled" [ngClass]="cssClass" [title]="sharedTermsTK.ActionClear | translate"
			(click)="clear()" class="uf-action" type="button">
			<uf-icon name="backspace" />
		</button>
		<button *ngIf="!control.disabled && mapAvailable" [ngClass]="cssClass"
			[title]="sharedTermsTK.ActionGeolocate | translate" (click)="locate()" class="uf-action" type="button">
			<uf-icon name="myLocation" />
		</button>
	</div>

	<div expanderBody class="uf-grid pad">
		<uf-progress #progress />
		<div *ngIf="control.disabled && isSummary" class="col-12">
			{{ content | dataDisplay: { type: 'Address' } }}
		</div>
		<ng-template [ngForOf]="nestedFields" let-nestedField let-i="index" ngFor>
			<div *ngIf="control.controls[nestedField.identifier] let nestedFieldControl" class="col-12">
				<!-- Nested field 'map' -->
				<uf-map-input *ngIf="nestedField.identifier === 'map' && mapAvailable"
					[control]="nestedFieldControl | asUfControl" [disableClear]="true" class="col-12">
					<button *ngIf="!control.disabled" (click)="findAddress()" type="button" class="uf-button">
						{{inputTK.AddressInputActionUpdateFields |translate}}
						<uf-icon name="arrowUp" />
					</button>
					<button *ngIf="nestedFields.length > 1" (click)="findLocation($any(content))" type="button"
						class="uf-button">
						{{inputTK.AddressInputActionShowLocation |translate}}
						<uf-icon name="arrowDown" />
					</button>
				</uf-map-input>
				<!-- Input fields (excluded 'map') -->
				<ng-template [ngIf]="nestedField.identifier !== 'map' && !(control.disabled && isSummary)">
					<!-- Allow first nestedField to be autocomplete -->
					<uf-address-autocomplete *ngIf="field.autocomplete && i === 0" [label]="nestedField.label"
						[ngClass]="nestedField.cssClass" [control]="nestedFieldControl | asUfControl"
						[options]="autocompleteOptions" [value]="$any(content)[nestedField.identifier]"
						(searchChange)="search($event)" (selected)="findLocation($event)" nameProperty="_display">
						<span *ngIf="!control.disabled" class="suffix">
							{{ nestedField.suffix }}
						</span>
					</uf-address-autocomplete>
					<!-- Standard nested fields -->
					<uf-text *ngIf="!field.autocomplete || i !== 0" [label]="nestedField.label"
						[ngClass]="nestedField.cssClass" [control]="nestedFieldControl | asUfControl"
						[(value)]="$any(content)[nestedField.identifier]">
						<span *ngIf="!control.disabled" class="suffix">
							{{ nestedField.suffix }}
						</span>
					</uf-text>
				</ng-template> <!-- non 'map' nested field -->
			</div> <!-- nestedFieldControl -->
		</ng-template> <!-- ngFor nested fields -->
		<uf-error *ngIf="control.controls.preventDisabledBubble"
			[control]="control.controls.preventDisabledBubble | asUfControl" class="readonly-error" />
	</div>
</uf-expander>