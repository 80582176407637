import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

enum Layouts {
	Centered = 'centered'
}

enum Types {
	Anchor, Container, Modal
}

@Component({
	templateUrl: './show-cards.html',
	styleUrls: ['./show-cards.less'],
})
export class ShowCardsComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	// Table
	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'uf-card', type: 'base', description: 'Styles container to look like a card' },
		{ name: 'centered', type: 'modifier', description: 'Centers text' },
	];

	// Interactive
	readonly typeOptions = [
		{ name: 'Anchor', value: Types.Anchor },
		{ name: 'Container', value: Types.Container },
		{ name: 'Modal', value: Types.Modal },
	];
	readonly layoutOptions = [
		{ name: 'Default', value: '' },
		{ name: 'Centered', value: Layouts.Centered },
	];

	readonly types = Types;

	cssClass: string[] = [];
	layout = '';
	type = Types.Anchor;

}
