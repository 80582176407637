import { Component, OnInit } from '@angular/core';
import { LayoutDirection } from '@unifii/sdk';

import { ColumnGap, Size, ToastService, UfControl } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

const DefaultLabel = 'Rate your day';

const tickCrossLabel = 'Was your day ok?';

const DefaultExampleOptions = [
	{ name: 'One star', value: 'one' },
	{ name: 'Two star', value: 'two' },
	{ name: 'Three star', value: 'three' },
	{ name: 'Four star', value: 'four' },
	{ name: 'Five star', value: 'five' },
];

const tickCrossExampleOptions: any[] = [
	{ name: 'Yes', value: true },
	{ name: 'No', value: false },
];

@Component({
	templateUrl: './show-radio-buttons.html',
})
export class ShowRadioButtonsComponent implements OnInit {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly sizeOptions = [
		{ name: 'Large', value: 'large' },
		{ name: 'Medium (default)', value: '' },
		{ name: 'Small', value: 'small' },
	];
	readonly directionOptions = [
		{ name: 'Row', value: 'Row' },
		{ name: 'Column', value: 'Column' },
	];
	readonly templateOptions = [
		{ name: 'Default', value: '' },
		{ name: 'Tick/Cross', value: 'tickCross' },
		{ name: 'Buttons', value: 'buttons' },
	];

	exampleOptions = [...DefaultExampleOptions];
	buttonsOptions = [{ name: 'Yes', value: true }, { name: 'No', value: false }];
	label = DefaultLabel;
	size = '';
	direction: LayoutDirection = LayoutDirection.Row;
	columns = 3;
	class = '';
	radioTemplate = '';
	template = `
		<uf-radio label="Radio Buttons" direction="${this.direction}" columns="${this.columns}"></uf-radio>
	`;
	value: string | null;
	control: UfControl = new UfControl();

	readonly inputTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'default', name: 'Default' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly inputTableInfo = [
		{ name: 'control', type: 'UfControl', default: 'new UfControl()', description: 'A control object to manage validators, states etc.' },
		{ name: 'id', type: 'string', default: 'undefined', description: 'Sets id attribute of inner input element' },
		{ name: 'name', type: 'string', default: 'undefined', description: 'Sets name attribute of inner input element' },
		{ name: 'nameProperty', type: 'string | ((option: any) => string)', default: '\'name\'', description: 'Defines which property of the option object to use as a name' },
		{ name: 'valueProperty', type: 'string', default: 'undefined', description: 'Defines which property of the option object to use as a value' },
		{ name: 'trackBy', type: 'string', default: 'undefined', description: 'If no valueProperty provided, trackBy is used to get index of selected option item' },
		{ name: 'columns', type: 'number', default: '2', description: 'Sets number of columns across which the options will be laid out' },
		{ name: 'direction', type: '\'Row\' | \'Column\'', default: '\'Row\'', description: 'Direction in which options are listed' },
	];
	readonly outputTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly outputTableInfo = [
		{ name: 'valueChange', type: 'EventEmitter', description: 'Emits an event when the value of the input is changed' },
	];
	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'default', type: 'modifier', description: 'Default input size' },
		{ name: 'small', type: 'modifier', description: 'Small input size' },
	];

	constructor(private toast: ToastService) { }

	ngOnInit() {
		this.modifierChange();
	}

	modifierChange() {
		this.class = this.size;

		if (this.radioTemplate === 'tickCross') {
			this.value = null;
			this.label = tickCrossLabel;
			this.exampleOptions = [...tickCrossExampleOptions];
			this.columns = 2;
		} else {
			this.value = null;
			this.label = DefaultLabel;
			this.exampleOptions = [...DefaultExampleOptions];
		}

		this.template = `<uf-radio label="Radio Buttons"${this.class ? ' class="' + this.class + '"' : ''} direction="${this.direction}" columns="${this.columns}"></uf-radio>`;
	}

	valueChanged(value: any) {
		this.toast.info(`Value changed: ${value.name ? value.name : JSON.stringify(value)}`);
	}

}
