import { AfterViewInit, Directive, ElementRef, EventEmitter, NgZone, OnDestroy, Output } from '@angular/core';
import { Subscription, fromEvent, merge, zip } from 'rxjs';
import { first } from 'rxjs/operators';

/**
 * Simple directive which observe all image nodes
 * and emits an event when the load process finish (either with a success or a failure)
 * Could be extended later to include a DOMOberser which will rerun if items are added
 */
@Directive({
	selector: '[imagesLoaded]',
})
export class ImagesLoadedDirective implements AfterViewInit, OnDestroy {

	@Output() done = new EventEmitter<any>();

	private subscriptions = new Subscription();

	constructor(private el: ElementRef, private ngZone: NgZone) { }

	ngAfterViewInit() {

		// Wait til next digest cycle before running init
		const onStable = this.ngZone.onStable.pipe(first());

		this.subscriptions.add(onStable.subscribe(() => {
			this.ngZone.runTask(() => this.init());
		}));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	private init() {

		const imgs = this.getImgElements(this.el.nativeElement);

		if (!imgs.length) {
			this.done.emit();

			return;
		}

		const observers = [];

		for (const img of imgs) {

			const loadObserable = fromEvent(img, 'load');
			const errorObservable = fromEvent(img, 'error');

			observers.push(merge(loadObserable, errorObservable));
		}

		const subscription = zip(...observers).subscribe(() => {
			this.done.emit();
			subscription.unsubscribe();
		});
	}

	private getImgElements(el: HTMLElement) {

		const nodeList: NodeList = el.querySelectorAll('img');
		const images: any[] = Array.from(nodeList);

		return images.filter((img) => img.src && img.src.indexOf('base64') !== 0);
	}

}
