import { Inject, Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Geolocation as CapacitorGeoLocation } from '@capacitor/geolocation';
import { TranslateService } from '@ngx-translate/core';
import { GeoLocation } from '@unifii/sdk';

import { LocationProvider, LocationProviderType } from '../models';
import { WindowWrapper } from '../native';
import { CommonTranslationKey } from '../translations/common.tk';

@Injectable()
export class UfLocationProvider implements LocationProvider {

	type = LocationProviderType.Unifii;

	constructor(
		protected translate: TranslateService,
		@Inject(WindowWrapper) protected window: Window,
	) { }

	async locate(): Promise<GeoLocation> {

		try {
			const devicePosition = await this.getDevicePosition();

			return {
				lat: +devicePosition.coords.latitude.toFixed(5),
				lng: +devicePosition.coords.longitude.toFixed(5),
				zoom: 15,
			};

		} catch (e) {
			throw new Error(this.getErrorMessage(e));
		}
	}

	private async getDevicePosition(): Promise<GeolocationPosition> {

		if (Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('Geolocation')) {

			try {
				const position = await CapacitorGeoLocation.getCurrentPosition({
					enableHighAccuracy: true,
					timeout: 15000,
					maximumAge: 0,
				});

				// capacitor and browser slightly differ
				return {
					timestamp: position.timestamp,
					coords: {
						latitude: position.coords.latitude,
						longitude: position.coords.longitude,
						altitude: position.coords.altitude,
						altitudeAccuracy: position.coords.altitudeAccuracy ?? null,
						accuracy: position.coords.accuracy,
						heading: position.coords.heading,
						speed: position.coords.speed,
					},
				};
			} catch (e) {
				console.warn('DEVICE LOCATION ERROR: ', e);
				throw e;
			}
		}

		return new Promise((resolve, reject) => {

			this.window.navigator.geolocation.getCurrentPosition((position) => {
				resolve(position);
			}, (e) => {
				console.warn('DEVICE LOCATION ERROR: ', e);
				reject(e);
			}, {
				enableHighAccuracy: true,
				timeout: 15000,
				maximumAge: 0,
			});
			/** Adding options seems to speed update first request */
		});
	}

	private getErrorMessage(error: any): string {

		switch (error.code) {
			case 1:
				return this.translate.instant(CommonTranslationKey.DetectLocationErrorPermissionDenied) as string;
			case 2:
				return this.translate.instant(CommonTranslationKey.DetectLocationErrorPositionUnavailable) as string;
			case 3:
				return this.translate.instant(CommonTranslationKey.DetectLocationErrorDetectTimedOut) as string;
			default:
				return this.translate.instant(CommonTranslationKey.DetectLocationErrorUnknown) as string;
		}
	}

}
