import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, forwardRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { UfFormControl } from '../../controls';
import { SharedTermsTranslationKey } from '../../translations';

import { StepperComponent, StepperTemplate } from './stepper.component';

@Component({
	selector: 'uf-step',
	templateUrl: './step.html',
	styleUrls: ['./step.less'],
})
export class StepComponent<T> implements OnInit, OnDestroy {
	
	@Input() key?: T; // key used to identify step
	@Input() label?: string;
	@Input() control?: UfFormControl;
	@Input() nextLabel = this.translate.instant(SharedTermsTranslationKey.ActionNext) as string;
	@Input() backLabel = this.translate.instant(SharedTermsTranslationKey.ActionBack) as string;
	@Input() completeLabel = this.translate.instant(SharedTermsTranslationKey.ActionComplete) as string;
	@Input() showNext = true;
	@Input() showCancel = true;
	@Input() validate = false;
	@Output() requestChange = new EventEmitter<number>();

	submitted = false;
	index: number;

	protected maxHeight: string;
	protected readonly orientations = StepperTemplate;

	private subscriptions = new Subscription();

	constructor(
		@Inject(forwardRef(() => StepperComponent)) public stepper: StepperComponent<T>,
		private translate: TranslateService,
	) {
	}

	ngOnInit() {		
		this.stepper.register(this);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/**
	 * Required for vertical template only
	 * height must be calculated for animation to work
	 */
	onMutation(elementRef: HTMLElement) {

		const currentHeight = elementRef.clientHeight;

		if (currentHeight > 0) {
			this.maxHeight = elementRef.clientHeight + 'px';
		}
	}

	update(requested: number) {
		this.stepper.active = requested;
	}

	activate() {
		this.stepper.navigateTo(this);
	}

	back() {
		this.stepper.back(this);
	}

	complete() {
		this.submitted = true;

		if (this.control) {
			this.control.setSubmitted();

			if (this.control.invalid) {
				this.stepper.stepFailedValidation.emit(this);

				return;
			}
		}

		this.stepper.completeStep(this);
	}

}
