import { Dictionary } from '@unifii/sdk';

/** Basic menu option entry to be rendered as icon and label, additional attr(s) for custom information.
 * One single option is selected at a time
 */
export interface MenuOption extends Dictionary<any> {
	icon?: string;
	label?: string;
}

/** Menu option with a selected state to be used to show a list of 'selectable' option.
 * Full list of options can be selected/unselected defining a specific state for the list
 */
export interface SelectedMenuOption extends MenuOption {
	selected?: boolean;
}

/** Menu option where its availability is due to an external context represented by a T object.
 * List of options is dynamically filtered based on the result of their predicate run against the context T
 */
export interface ContextMenuOption<T> extends MenuOption {
	predicate?: (context?: T) => boolean;
}

/** Filter a list of ContextMenuOption against an optional T context. */
export const filterMenuOptions = <T, O>(options: O[] | null, context?: T): O[] =>
	options?.filter((option) => {
		const predicate = (option as ContextMenuOption<T>).predicate;

		if (predicate != null) {
			return predicate(context);
		}

		return true;
	}) ?? [];
