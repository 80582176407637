import { Component } from '@angular/core';

import { ColumnGap, CreatePasswordConfig, Size, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';

import { ShowcaseRepositoryService } from '../../services/repository.service';

enum ControlKeys {
	Control = 'control'
}
@Component({
	selector: 'sc-show-reactive-forms',
	templateUrl: './show-reactive-forms.html',
})
export class ShowReactiveFormsComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;
	readonly controlKeys = ControlKeys;
	readonly passwordConfig: CreatePasswordConfig = {
		canCopy: true,
		canGenerate: true,
		showOldPassword: true,
		isRequired: true,
		showChangePasswordOnNextLogin: true,
		showStrengthIndicator: true,
	};

	form: UfControlGroup;
	hide = false;

	constructor(
		public repository: ShowcaseRepositoryService,
		private fb: UfFormBuilder,
	) {
		this.form = this.fb.group({
			[ControlKeys.Control]: [
				'asdfasdf',
				ValidatorFunctions.required('This is required'),
			],
		});
	}

	protected setSubmitted() {
		this.form.setSubmitted();
		this.form.updateValueAndValidity();
	}

}
