import { Component, HostBinding, Injector } from '@angular/core';

import { Modal } from '../modal';
import { ModalRuntime } from '../modal-runtime';
import { ModalAlertData, ModalData } from '../modal-types';

@Component({
	selector: 'uf-alert-modal',
	templateUrl: './alert-modal.html',
	styleUrls: ['./modal.less'],
})
export class AlertModalComponent implements Modal<ModalAlertData, void> {

	@HostBinding('class.uf-form-card') cardClass = true;

	runtime: ModalRuntime<ModalAlertData, void>;
	data: ModalAlertData;

	constructor(injector: Injector) {
		this.runtime = injector.get(ModalRuntime) as ModalRuntime<ModalAlertData, void>;
		this.data = injector.get(ModalData) as ModalAlertData;
	}

	close() {
		this.runtime.close();
	}

}
