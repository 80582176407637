import { Component, OnInit } from '@angular/core';
import { Compound, Definition } from '@unifii/sdk';

import { RuntimeDefinition } from '@unifii/library/common';
import { DisplayContent, DisplayService } from '@unifii/library/smart-forms/display';

import { CollectionInfo, CompoundInfo, UsAPIContentClient } from '../../services';

@Component({
	selector: 'sc-show-collection-item',
	templateUrl: './show-collection-item.html',
})
export class ShowCollectionItemComponent implements OnInit {

	protected collections: CollectionInfo[] = [];
	protected definition: Definition | RuntimeDefinition | undefined;
	protected compounds: CompoundInfo[] = [];
	protected compound?: Compound;
	protected data?: DisplayContent;

	private _identifier?: string | null;
	private _id?: string | null;

	constructor(
		private usContent: UsAPIContentClient,
		private displayService: DisplayService,
	) { }

	async ngOnInit() {
		this.collections = await this.usContent.getCollections();
		// Auto-select first collection
		this.collections[0]?.identifier;
	}

	set identifier(v: string | null | undefined) {

		if (v === this._identifier) {
			return;
		}
		this._identifier = v;
		void this.applyIdentifier();
	}

	get identifier(): string | null | undefined {
		return this._identifier;
	}

	set id(v: string | null | undefined) {
		if (v === this.id) {
			return;
		}
		this._id = v;
		void this.applyId();
	}

	get id(): string | null | undefined {
		return this._id;
	}

	async applyId() {
		if (this.identifier == null || this.id == null) {
			delete this.compound;
			delete this.data;

			return;
		}

		this.compound = await this.usContent.getCollectionItem(this.identifier, +this.id);
		void this.render();
	}

	async applyIdentifier() {

		if (this.identifier == null) {
			this.compounds = [];
			delete this.definition;
			delete this.id;

			return;
		}

		this.compounds = await this.usContent.getCollectionItems(this.identifier);
		this.definition = await this.usContent.getCollectionDefinition(this.identifier);

		// Auto-select first item
		this.id = this.compounds[0]?.id;
	}

	async render() {

		if (!this.definition || !this.compound) {
			delete this.data;

			return;
		}

		try {
			this.data = await this.displayService.renderCompound(this.definition, this.compound);
		} catch (err) {
			console.error('ERROR', err);
		}
	}

}
