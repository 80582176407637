<uf-panel class="stretch col">
	<div *ngIf="data.field" class="uf-app-bar">
		<button [title]="sharedTermsTK.ActionClose | translate" (click)="close()" type="button"
			class="uf-action tertiary">
			<uf-icon name="close" />
		</button>
		<h3>
			{{data.field.label}}
			<uf-help *ngIf="data.field.help" [content]="data.field.help" />
		</h3>
	</div>
	<div class="grow">
		<uf-repeat-form [index]="data.index" [field]="data.field" [scope]="scope" [inModal]="true"
			class="uf-form-group" />
	</div>
	<div *ngIf="!disabled" class="uf-form-actions small bottom">
		<button (click)="close()" class="uf-button tertiary left" type="button">
			{{sharedTermsTK.ActionCancel | translate}}
		</button>
		<button (click)="confirm()" class="uf-button primary" type="button">
			{{sharedTermsTK.ActionConfirm | translate}}
		</button>
	</div>
</uf-panel>