export * from './address.component';
export * from './choice.component';
export * from './email.component';
export * from './field-description.component';
export * from './field-message.component';
export * from './field-table.component';
export * from './file-list-description.component';
export * from './file-list.component';
export * from './geo-location.component';
export * from './image-list.component';
export * from './link-list.component';
export * from './link.component';
export * from './lookup.component';
export * from './multitext.component';
export * from './content.component';
export * from './phone.component';
export * from './signature-print.component';
export * from './sound-list.component';
export * from './sound-list-description.component';
export * from './text.component';
export * from './video-list.component';
export * from './video-list-description.component';
export * from './website.component';

import { AddressDisplayComponent } from './address.component';
import { ChoiceDisplayComponent } from './choice.component';
import { FieldContentComponent } from './content.component';
import { EmailComponent } from './email.component';
import { FieldDescriptionComponent } from './field-description.component';
import { FieldMessageComponent } from './field-message.component';
import { FieldTableComponent } from './field-table.component';
import { FileListDescriptionComponent } from './file-list-description.component';
import { FileListDisplayComponent } from './file-list.component';
import { GeoLocationDisplayComponent } from './geo-location.component';
import { ImageListDisplayComponent } from './image-list.component';
import { LinkListComponent } from './link-list.component';
import { LinkComponent } from './link.component';
import { LookupDisplayComponent } from './lookup.component';
import { MultiTextComponent } from './multitext.component';
import { PhoneComponent } from './phone.component';
import { SignaturePrintComponent } from './signature-print.component';
import { SoundListDescriptionComponent } from './sound-list-description.component';
import { SoundListComponent } from './sound-list.component';
import { TextComponent } from './text.component';
import { VideoListDescriptionComponent } from './video-list-description.component';
import { VideoListComponent } from './video-list.component';
import { WebsiteComponent } from './website.component';

export const UF_DISPLAY_FIELD_COMPONENTS = [
	AddressDisplayComponent, ChoiceDisplayComponent, EmailComponent,
	FieldTableComponent, FileListDescriptionComponent, FileListDisplayComponent, GeoLocationDisplayComponent,
	ImageListDisplayComponent, LinkListComponent, LinkComponent, LookupDisplayComponent,
	MultiTextComponent, PhoneComponent, SoundListComponent, SoundListDescriptionComponent,
	TextComponent, VideoListComponent, VideoListDescriptionComponent, WebsiteComponent, FieldDescriptionComponent,
	SignaturePrintComponent, FieldMessageComponent, FieldContentComponent,
];
