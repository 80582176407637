export class StepperScrollManager {

	private initialPosition = 0;
	private scrollableElement?: HTMLElement;

	constructor(
		element: HTMLElement,
		private window: Window,
		private anchor?: HTMLElement,
	) {
		if (this.anchor) {
			return;
		}

		this.scrollableElement = this.getScrollableElement(element);
		if (!this.scrollableElement) {
			console.warn('StepperScrollManager: Scrollable element not found');

			return;
		}
		this.initialPosition = this.scrollableElement.scrollTop;
	}

	reset() {
		if (this.anchor) {
			this.anchor.scrollIntoView();

			return;
		}

		if (this.scrollableElement) {
			this.scrollableElement.scrollTop = this.initialPosition;
		}
	}

	private getScrollableElement(element: HTMLElement | null): HTMLElement | undefined {
		if (element == null) {
			return;
		}

		const computedStyle = this.window.getComputedStyle(element);

		if (computedStyle.overflow === 'auto' || computedStyle.overflowY === 'auto') {
			return element;
		}

		return this.getScrollableElement(element.parentElement);
	}

}
