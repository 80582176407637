import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Dictionary, ErrorType, HierarchyUnitExtended, HierarchyUnitFormData, HierarchyUnitWithChildCount, UfError, isUfError } from '@unifii/sdk';

import { UfControl } from '../../../controls';
import { HierarchyUnitIdentifier, HierarchyUnitProvider } from '../../../models';
import { CommonTranslationKey, SharedTermsTranslationKey } from '../../../translations';
import { HierarchyFunctions } from '../../../utils';
import { UfControlValueAccessor } from '../uf-control-value-accessor';

import { HierarchyUnitCache } from './hierarchy-unit-cache';
import { HierarchyUnitInput, HierarchyUnitInputController, HierarchyUnitWithHasChildren } from './hierarchy-unit-input-controller';

class HierarchyError extends UfError {

	override data: {
		showRetry?: boolean;
		showClear?: boolean;
	};

	constructor(message: string, showRetry: boolean, showClear: boolean) {
		super(message, ErrorType.Unknown, { showRetry, showClear });
	}

}

@Component({
	selector: 'uf-hierarchy-unit-selector',
	templateUrl: './hierarchy-unit-selector.html',
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: HierarchyUnitSelectorComponent, multi: true },
		/** Cache provided here as the selector component is expected to have a short life span as it is displayed
		 * in a modal or draw component */
		HierarchyUnitCache,
	],
})
export class HierarchyUnitSelectorComponent extends UfControlValueAccessor<HierarchyUnitFormData> implements OnInit {

	@Input() filterInactiveChildren?: boolean;
	@Input() selectLeavesOnly = false;
	@Input() label?: string;
	@Input() showSearch = true;

	/** Notify the selected value has changed */
	@Output() override valueChange = new EventEmitter<HierarchyUnitFormData>();
	@Output() inProgress = new EventEmitter<boolean>();

	protected readonly commonTK = CommonTranslationKey;
	protected readonly sharedTK = SharedTermsTranslationKey;

	protected initialized = false;
	protected error: HierarchyError | null;
	protected optionsLookup: Dictionary<HierarchyUnitWithChildCount[]> = {};
	protected searchOptions: HierarchyUnitFormData[];
	protected inputController = new HierarchyUnitInputController();
	protected searchValue: HierarchyUnitFormData | undefined;

	private rootId: string;
	private ceilingId: string | undefined;
	private translate = inject(TranslateService);
	private unitCache = inject(HierarchyUnitCache);
	private hierarchyUnitProvider = inject(HierarchyUnitProvider);

	get inputs(): HierarchyUnitInput[] {
		return this.inputController.inputs;
	}

	get leafSelected(): boolean {
		return !!this.inputs[this.inputs.length - 1]?.selection;
	}

	@Input() override set value(v: HierarchyUnitFormData | null | undefined) {
		super.value = v;

		if (this.initialized) {
			void this.setValue(v);
		}
	}

	override get value(): HierarchyUnitFormData | null | undefined {
		return super.value;
	}

	@Input() override set control(v: UfControl) {
		super.control = v;

		if (this.initialized) {
			void this.setValue(v.value as HierarchyUnitFormData | null | undefined);
		}
	}

	override get control(): UfControl {
		return super.control;
	}

	/** Starting unit in the hierarchy for this selector to work with */
	@Input() set ceiling(v: HierarchyUnitIdentifier | null | undefined) {
		if (!v || v === 'root') {
			return;
		}

		this.ceilingId = HierarchyFunctions.getId(v) ?? undefined;

		if (this.initialized) {
			void this.init();
		}
	}

	ngOnInit() {
		void this.init();
	}

	override valueEmitPredicate(value?: HierarchyUnitFormData | null, prev?: HierarchyUnitFormData | null): boolean {
		if (!this.patternUtil.isEqual(value, prev) && value != null && prev == null) {
			void this.setValue(value);
		}

		return super.valueEmitPredicate(value, prev);
	}

	protected reset() {
		this.control.setValue(undefined);
		this.inputController.clearSelection(this.ceilingId ?? this.rootId);
	}

	protected retry() {
		void this.init();
	}

	protected select(inputId: string, unit?: HierarchyUnitWithChildCount) {

		this.searchValue = undefined;

		this.inputController.clearSelection(inputId);

		if (unit != null) {
			const { childCount, ...u } = unit;
			const unitWithHasChildren = { ...u, hasChildren: childCount > 0 };

			this.inputController.append(unitWithHasChildren);
		}

		const value = this.getValidSelection();

		this.control.setValue(value);
	}

	protected async search(q?: string) {
		this.searchOptions = await this.hierarchyUnitProvider.search({ q, ceiling: this.ceilingId });
	}

	protected changeSearchValue(v: HierarchyUnitFormData | undefined) {
		this.searchValue = v;
		if (!v) {
			return;
		}

		this.value = v;
	}

	protected displaySearchResult(v: HierarchyUnitFormData): string {
		return HierarchyFunctions.pathToDisplay(v.path);
	}

	protected async searchChildren(id: string, q: string) {
		this.error = null;

		try {
			this.optionsLookup[id] = await this.hierarchyUnitProvider.getChildren(id, q);
		} catch (e) {
			this.optionsLookup[id] = [];
			this.error = this.createError(e);
		}
	}

	private async init() {
		this.inProgress.emit(true);

		try {
			const rootUnit = await this.unitCache.getUnit('root');

			if (rootUnit == null) {
				throw this.invalidError;
			}

			this.rootId = rootUnit.id;
			this.inputController.append(this.convertToUnitsWithHasChildren(rootUnit));

			if (this.ceilingId != null) {
				const { unit, ancestors } = await this.getUnitAndAncestors(this.ceilingId);

				this.inputController.append(...ancestors, unit);
				this.inputController.disable(this.ceilingId);
			}

			if (this.value != null) {
				await this.updateInputsAndSetValue(this.value.id);
			}
		} catch (e) {
			this.error = this.createError(e);

			return;
		} finally {
			this.initialized = true;
			this.inProgress.emit(false);
		}
	}

	private async setValue(unitFormData?: HierarchyUnitFormData | null) {
		if (unitFormData == null || this.invalidFormData(unitFormData)) {
			this.inputController.clearSelection(this.ceilingId ?? this.rootId);

			return;
		}

		this.inProgress.emit(true);
		this.error = null;

		try {
			await this.updateInputsAndSetValue(unitFormData.id);
		} catch (e) {
			this.error = this.createError(e);
		} finally {
			this.inProgress.emit(false);
		}
	}

	private async updateInputsAndSetValue(unitId: string) {
		const { unit, ancestors } = await this.getUnitAndAncestors(unitId);

		this.inputController.append(...ancestors, unit);
		this.control.setValue(this.getValidSelection());
	}

	private getValidSelection(): HierarchyUnitFormData | null {
		if (this.selectLeavesOnly && !this.leafSelected) {
			return null;
		}

		return this.inputController.getSelected();
	}

	private async getUnitAndAncestors(id: string): Promise<{ unit: HierarchyUnitWithHasChildren; ancestors: HierarchyUnitWithHasChildren[] }> {
		const extendedUnit = await this.unitCache.getUnit(id);

		if (extendedUnit == null) {
			throw this.misconfiguredError;
		}

		const ancestorIds = extendedUnit.path.map((p) => p.id);

		ancestorIds.pop();

		const ancestors = await this.unitCache.getUnits(ancestorIds);
		const ancestorsWithHasChildren = ancestors.map((u) => {
			const { path, ...unit } = u;

			return { ...unit, hasChildren: true };
		});

		return { unit: this.convertToUnitsWithHasChildren(extendedUnit), ancestors: ancestorsWithHasChildren };
	}

	private invalidFormData(data: Partial<HierarchyUnitFormData>): boolean {
		return data.id == null || data.label == null || !data.path;
	}

	private createError(error: any): HierarchyError {
		const ufError = isUfError(error) ? error : new UfError((error as Error).message);

		let { message } = ufError;

		if (ufError.type === ErrorType.Forbidden) {
			message = this.translate.instant(SharedTermsTranslationKey.ErrorForbidden) as string;
		}
		if (!message) {
			message = this.translate.instant(CommonTranslationKey.HierarchySelectorLoadErrorMessage) as string;
		}

		return new HierarchyError(message, true, false);
	}

	private get misconfiguredError(): HierarchyError {
		return new HierarchyError(
			this.translate.instant(CommonTranslationKey.HierarchySelectorInvalidConfigurationMessage) as string,
			true, false);
	}

	private get invalidError(): HierarchyError {
		return new HierarchyError(
			this.translate.instant(CommonTranslationKey.SelectSelectableUnitMessage) as string,
			false, true);
	}

	private convertToUnitsWithHasChildren(unit: HierarchyUnitExtended): HierarchyUnitWithHasChildren {
		const { path, childCount, ...hierarchyUnit } = unit;

		return { ...hierarchyUnit, hasChildren: childCount > 0 };
	}

}
