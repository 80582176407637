import { TranslateService } from '@ngx-translate/core';
import { ClaimConfig, CompanyStatus, DataSourceType, Field, FieldType, OperatorComparison, Option, SchemaField, fieldTypeToDataType } from '@unifii/sdk';

import { CompanyIdentifiers, DataSourceDisplayTo, DataSourceUserFullNameExpression, FieldTypeIcon, FormDefinitionMetadataIdentifiers, StepFieldMetadataIdentifiers, UserInfoIdentifiers } from '../../constants';
import { DataDescriptorBucketType, DataDescriptorClaimsType, DataDescriptorCollectionType, DataDescriptorCompanyType, DataDescriptorType, DataDescriptorUsersType, DataDisplayInfo, DataPropertyDescriptor, DataPropertyInfo, SourceConfig, SourceConfigMapping } from '../../models';
import { sourceConfigMappingsToSourceConfigMappingInfo } from '../../services';
import { CommonTranslationKey } from '../../translations';
import { dataSourceToSourceConfig, displayDefaultMapping, idDefaultMapping, parseDataSource } from '../data-source';

const allowedFieldTypes = [
	FieldType.Text,
	FieldType.MultiText,
	FieldType.Date,
	FieldType.Time,
	FieldType.DateTime,
	FieldType.ZonedDateTime,
	FieldType.Cost,
	FieldType.Number,
	FieldType.Phone,
	FieldType.Email,
	FieldType.Website,
	FieldType.Bool,
	FieldType.Choice,
	FieldType.MultiChoice,
	FieldType.Lookup,
	FieldType.GeoLocation,
	FieldType.Address,
	FieldType.TextArray,
	FieldType.Hierarchy,
	FieldType.ImageList,
	FieldType.SoundList,
	FieldType.VideoList,
	FieldType.FileList,
	FieldType.Link,
	FieldType.Repeat,
	FieldType.Signature,
	FieldType.Step,
];

const displayableFieldTypes = [
	FieldType.Text,
	FieldType.MultiText,
	FieldType.Date,
	FieldType.Time,
	FieldType.DateTime,
	FieldType.ZonedDateTime,
	FieldType.Cost,
	FieldType.Number,
	FieldType.Phone,
	FieldType.Email,
	FieldType.Website,
	FieldType.Bool,
	FieldType.Choice,
	FieldType.MultiChoice,
	FieldType.Lookup,
	FieldType.GeoLocation,
	FieldType.Address,
	FieldType.TextArray,
	FieldType.Hierarchy,
	FieldType.ImageList,
	FieldType.SoundList,
	FieldType.VideoList,
	FieldType.FileList,
	FieldType.Link,
	FieldType.Repeat,
];

const sortableFieldTypes = [
	FieldType.Text,
	FieldType.MultiText,
	FieldType.Date,
	FieldType.Time,
	FieldType.DateTime,
	FieldType.Number,
	FieldType.Email,
	FieldType.Website,
	FieldType.Lookup,
];

const staticFilterableFieldTypes = [
	FieldType.Text,
	FieldType.TextArray,
	FieldType.MultiText,
	FieldType.Date,
	FieldType.Time,
	FieldType.DateTime,
	FieldType.ZonedDateTime,
	FieldType.Number,
	FieldType.Cost,
	FieldType.Lookup,
	FieldType.Bool,
	FieldType.Choice,
	FieldType.MultiChoice,
	FieldType.Hierarchy,
];

const inputFilterableFieldTypes = [
	/** UNIFII-5754 - TODO Add back once the unifii filters system support those types
	 * FieldType.Number,
	 * FieldType.Cost,
	 */
	FieldType.TextArray,
	FieldType.Date,
	FieldType.Time,
	FieldType.DateTime,
	FieldType.ZonedDateTime,
	FieldType.Lookup,
	FieldType.Bool,
	FieldType.Choice,
	FieldType.MultiChoice,
	FieldType.Hierarchy,
];

/**
 * Hidden Fields from all flags for Bucket Settings
 */
const hiddenBucketMetaFields: string[] = [
	FormDefinitionMetadataIdentifiers.OpenedAt,
	FormDefinitionMetadataIdentifiers.CompletedAt,
	FormDefinitionMetadataIdentifiers.Action,
	FormDefinitionMetadataIdentifiers.Bucket,
	FormDefinitionMetadataIdentifiers.StoredAt,
	FormDefinitionMetadataIdentifiers.Bucket,
	FormDefinitionMetadataIdentifiers.Seq,
	FormDefinitionMetadataIdentifiers.History,
	FormDefinitionMetadataIdentifiers.Rev,
	FormDefinitionMetadataIdentifiers.Origin,
];

const allowed = (type: FieldType): boolean => {
	return allowedFieldTypes.includes(type);
};

const displayable = (dpd: DataPropertyDescriptor, type: DataDescriptorType): boolean => {

	if (dpd.asDisplay != null) {
		return dpd.asDisplay;
	}

	if (type !== DataDescriptorBucketType && dpd.type === FieldType.Link) {
		return false;
	}

	if (type === DataDescriptorBucketType && hiddenBucketMetaFields.includes(dpd.identifier)) {
		return false;
	}

	if (type === DataDescriptorBucketType && dpd.identifier.endsWith(DataSourceDisplayTo) && dpd.isExpression) {
		return false;
	}

	return displayableFieldTypes.includes(dpd.type);
};

const sortable = (dpd: DataPropertyDescriptor, type: DataDescriptorType): boolean => {

	if (dpd.asSort != null) {
		return dpd.asSort;
	}

	if (type === DataDescriptorBucketType && hiddenBucketMetaFields.includes(dpd.identifier)) {
		return false;
	}

	if (type === DataDescriptorBucketType && dpd.identifier.endsWith(DataSourceDisplayTo) && dpd.isExpression) {
		return false;
	}

	return sortableFieldTypes.includes(dpd.type);
};

const staticFilterable = (dpd: DataPropertyDescriptor, type: DataDescriptorType): boolean => {

	if (dpd.asStaticFilter != null) {
		return dpd.asStaticFilter;
	}

	if (type === DataDescriptorUsersType && dpd.identifier === UserInfoIdentifiers.Units as string) {
		return true;
	}

	if (type === DataDescriptorBucketType && hiddenBucketMetaFields.includes(dpd.identifier)) {
		return false;
	}

	if (type === DataDescriptorBucketType && dpd.identifier.endsWith(DataSourceDisplayTo) && dpd.isExpression) {
		return false;
	}

	return staticFilterableFieldTypes.includes(dpd.type);
};

const inputFilterable = (dpd: DataPropertyDescriptor, type: DataDescriptorType): boolean => {

	if (dpd.asInputFilter != null) {
		return dpd.asInputFilter;
	}

	if (type === DataDescriptorBucketType && [FormDefinitionMetadataIdentifiers.CreatedBy, FormDefinitionMetadataIdentifiers.LastModifiedBy].includes(dpd.identifier as FormDefinitionMetadataIdentifiers)) {
		return true;
	}

	if (type === DataDescriptorUsersType && dpd.identifier === UserInfoIdentifiers.Units as string) {
		return true;
	}

	if (type === DataDescriptorBucketType && hiddenBucketMetaFields.includes(dpd.identifier)) {
		return false;
	}

	if (type === DataDescriptorBucketType && dpd.identifier.endsWith(DataSourceDisplayTo) && dpd.isExpression) {
		return false;
	}

	return inputFilterableFieldTypes.includes(dpd.type);
};

const getDisplay = (identifier: string, label: string, shortLabel?: string): string =>
	`${shortLabel ?? label} (${identifier})`;

const getIcon = (identifier: string, fieldType: FieldType, dataType: DataDescriptorType): string | undefined => {

	if (getUsersReference(identifier, dataType)) {
		return 'user';
	}

	return FieldTypeIcon.get(fieldType);
};

/** Organize the properties in a Map indexed by their identifiers */
const getPropertiesMap = (properties: DataPropertyDescriptor[]): Map<string, DataPropertyDescriptor> =>
	properties.reduce((map, entry) => {
		map.set(entry.identifier, entry);

		return map;
	}, new Map<string, DataPropertyDescriptor>());

/** Check if the identifier represents the 'Users' property */
const getUsersReference = (identifier: string, dataType: DataDescriptorType): UserInfoIdentifiers.Id | UserInfoIdentifiers.Username | undefined => {

	switch (dataType) {
		case DataDescriptorUsersType:
			return [UserInfoIdentifiers.Id, UserInfoIdentifiers.Manager].includes(identifier as UserInfoIdentifiers) ? UserInfoIdentifiers.Id : undefined;
		case DataDescriptorBucketType:
			return [FormDefinitionMetadataIdentifiers.CreatedBy, FormDefinitionMetadataIdentifiers.LastModifiedBy].includes(identifier as FormDefinitionMetadataIdentifiers) ? UserInfoIdentifiers.Username : undefined;
		default:
			return undefined;
	}
};

const getDefaultOptions = (type: FieldType, translate: TranslateService, options?: Option[], sourceConfig?: SourceConfig): Option[] | undefined => {

	if (sourceConfig) {
		return;
	}

	if (options) {
		return options;
	}

	if (type === FieldType.Bool) {
		return [
			{ identifier: 'true', name: translate.instant(CommonTranslationKey.YesLabel) as string },
			{ identifier: 'false', name: translate.instant(CommonTranslationKey.NoLabel) as string },
		];
	}

	return undefined;
};

// eslint-disable-next-line complexity
const getOperators = (fieldType: FieldType, identifier: string, dataDescriptorType: DataDescriptorType): OperatorComparison[] | undefined => {

	if (dataDescriptorType === DataDescriptorUsersType) {
		if (identifier === 'status' && fieldType === FieldType.Choice) {
			return ['eq', 'ne'];
		}
		if (identifier === UserInfoIdentifiers.Units as string) {
			return ['descs'];
		}
		if (identifier === UserInfoIdentifiers.Manager as string) {
			return ['eq', 'ne', 'in'];
		}
	}
	
	if (dataDescriptorType === DataDescriptorBucketType && fieldType === FieldType.Text) {
		return ['eq', 'ne', 'in'];
	}

	if (fieldType === FieldType.DateTime && (identifier.includes(StepFieldMetadataIdentifiers.LastViewedAt) || identifier.includes(StepFieldMetadataIdentifiers.LastCompletedAt))) {
		return undefined;
	}

	switch (fieldType) {
		case FieldType.Text:
		case FieldType.MultiText:
			return ['eq', 'ne'];
		case FieldType.TextArray:
			return ['contains'];
		case FieldType.Number:
		case FieldType.Date:
		case FieldType.Time:
		case FieldType.DateTime:
		case FieldType.ZonedDateTime:
		case FieldType.Cost:
			return ['eq', 'ne', 'lt', 'le', 'gt', 'ge'];
		case FieldType.Lookup:
		case FieldType.Choice:
			return ['eq', 'ne', 'in'];
		case FieldType.Bool:
			return ['eq', 'ne'];
		case FieldType.MultiChoice:
			return ['contains'];
		case FieldType.Hierarchy:
			return ['descs'];
		default: return undefined;
	}
};

/** Sort the properties in DESC order by identifier */
const sortDataPropertyDescriptors = (entries: DataPropertyDescriptor[]): DataPropertyDescriptor[] =>
	entries.sort((a, b) => a.identifier > b.identifier ? 1 : -1);

/** Extends the DataPropertyInfo to a DataPropertyDescriptor */
const dataPropertyInfoToDataPropertyDescriptor = (reference: DataPropertyInfo, type: DataDescriptorType, translate: TranslateService): DataPropertyDescriptor => {

	const entry = Object.assign({}, reference) as DataPropertyDescriptor;

	if (type === DataDescriptorUsersType && entry.identifier === UserInfoIdentifiers.IsExternal as string) {
		entry.options = [
			{ identifier: 'false', name: translate.instant(CommonTranslationKey.InternalLabel) as string },
			{ identifier: 'true', name: translate.instant(CommonTranslationKey.ExternalLabel) as string },
		];
	}

	if ([DataDescriptorUsersType, DataDescriptorCompanyType].includes(type) && entry.identifier === CompanyIdentifiers.Status as string) {
		entry.options = [
			{ identifier: CompanyStatus.Active, name: translate.instant(CommonTranslationKey.CompanyStatusLabelActive) as string },
			{ identifier: CompanyStatus.Inactive, name: translate.instant(CommonTranslationKey.CompanyStatusLabelInactive) as string },
			{ identifier: CompanyStatus.Pending, name: translate.instant(CommonTranslationKey.CompanyStatusLabelPending) as string },
		];
	}

	const userReference = getUsersReference(reference.identifier, type);

	if (userReference) {
		entry.sourceConfig = {
			type: DataSourceType.Users,
			...sourceConfigMappingsToSourceConfigMappingInfo([
				idDefaultMapping(userReference),
				displayDefaultMapping(DataSourceUserFullNameExpression),
			]),
		};
	}

	return entry;
};

const dataSourceTypeToDataDescriptorType = (type: DataSourceType): DataDescriptorType | undefined => {
	switch (type) {
		case DataSourceType.Bucket:
			return DataDescriptorBucketType;
		case DataSourceType.Collection:
			return DataDescriptorCollectionType;
		case DataSourceType.Users:
			return DataDescriptorUsersType;
		case DataSourceType.Company:
			return DataDescriptorCompanyType;
		case DataSourceType.UserClaims:
			return DataDescriptorClaimsType;
		default:
			return;
	}
};

const fieldToSchemaField = (field: Field): SchemaField | undefined => {

	if (field.identifier == null || field.label == null) {
		return;
	}

	return {
		identifier: field.identifier,
		type: field.type,
		label: field.label,
		shortLabel: field.shortLabel,
		isSearchable: field.isSearchable,
		dataSource: field.dataSource,
		dataSourceConfig: field.dataSourceConfig,
		options: field.options,
	};
};

const schemaFieldToDataPropertyDescriptor = (field: SchemaField, dataType: DataDescriptorType): DataPropertyDescriptor => {

	const dataSource = parseDataSource(field.dataSourceConfig ?? field.dataSource);

	return {
		identifier: field.identifier,
		type: field.type,
		label: field.shortLabel ?? field.label,
		display: getDisplay(field.identifier, field.label, field.shortLabel),
		icon: getIcon(field.identifier, field.type, dataType),
		sourceConfig: dataSource ? dataSourceToSourceConfig(dataSource) : undefined,
		options: field.options,
		hierarchyConfig: field.hierarchyConfig,
	};
};

const claimConfigToDataPropertyDescriptor = (claimConfig: ClaimConfig, dataType: DataDescriptorType, translate: TranslateService): DataPropertyDescriptor => {
	const entry: DataPropertyDescriptor = {
		identifier: `${UserInfoIdentifiers.Claims}.${claimConfig.type}`,
		type: claimConfig.valueType,
		label: claimConfig.label,
		display: getDisplay(`${UserInfoIdentifiers.Claims}.${claimConfig.type}`, claimConfig.label),
		icon: getIcon('', claimConfig.valueType, dataType),
	};

	if (claimConfig.options) {
		entry.options = claimConfig.options.map((o) => ({ identifier: o.id, name: o.display }));
	}

	if (entry.type === FieldType.Bool) {
		entry.options = getDefaultOptions(entry.type, translate);
	}

	return entry;
};

/** Return an extended set of the identifiers including all parent identifiers not present in the original list */
const normalizeIdentifiersList = (dataType: DataDescriptorType, identifiers?: string[]): Set<string> | undefined => {

	if (!identifiers) {
		return;
	}

	const all: string[] = [];

	identifiers.map((p) => {

		let done = false;

		// Special cases
		if (dataType === DataDescriptorUsersType && p.startsWith('company.')) {
			all.push(p);
			done = true;
		}

		if (!done) {
			const parts = p.split('.');
			const results: string[] = [];

			parts.forEach((part) => {
				if (results.length) {
					results.push(`${results[results.length - 1]}.${part}`);
				} else {
					results.push(part);
				}
			});
			all.push(...results);
		}
	});

	return new Set(all);
};

/** Filter the identifiers 'children' of the property identifier and transform them by remove the identifier prefix */
const filterIdentifierChildrenIdentifiers = (identifier: string, identifiers: Set<string> | undefined): Set<string> | undefined => {
	const dpdIdentifier = identifier + '.';

	return !identifiers ? undefined : new Set([...identifiers].filter((p) => p.startsWith(dpdIdentifier)).map((p) => p.substring(dpdIdentifier.length)));
};

const propertiesToDataSourceOutputProperties = (mappings: SourceConfigMapping[], properties: Set<string> | undefined): Set<string> | undefined => {
	if (!properties) {
		return undefined;
	}

	const propertiesArray = [...properties];
	
	return new Set(mappings.filter((mapping) => propertiesArray.find((property) => mapping.to === property)).map((mapping) => mapping.from));
};

const clearPropertiesAndRegisterUnknownsAsSkipped = (entries: DataPropertyDescriptor[], skipped: Option[], properties: Set<string> | undefined): DataPropertyDescriptor[] => {
	for (const property of properties ?? []) {
		if (entries.find((e) => property === e.identifier) == null && skipped.find((s) => s.identifier === property) == null) {
			// The requested property has not been found neither skipped, means is unknown
			skipped.push({ identifier: property, name: 'Unknown property' });
		}
	}

	// Filter only generated entries that has been requested
	if (properties) {
		entries = entries.filter((e) => properties.has(e.identifier));
	}

	return entries;
};

const getClaimConfigs = async(
	dataType: DataDescriptorType,
	skipped: Option[],
	loadClaimConfigs: () => Promise<ClaimConfig[]>,
	loadClaimConfig: (type: string) => Promise<ClaimConfig | undefined>,
	canLoadClaims: boolean | undefined,
	properties: Set<string> | undefined,
// eslint-disable-next-line better-max-params/better-max-params
): Promise<ClaimConfig[]> => {

	// claims.*
	let claimConfigs: ClaimConfig[];

	// When no whitelist of identifiers is provided, try to load every claim
	if (!properties) {
		// Requires LIST /claims permission
		if (canLoadClaims === false) {
			skipped.push({ identifier: CompanyIdentifiers.Claims, name: `No permission for /${dataType}-claims` });
			claimConfigs = [];
		} else {
			claimConfigs = await loadClaimConfigs();
		}
		// When whitelist of identifiers, load those specific claims.xyz only
	} else {
		claimConfigs = [];
		const claimTypes = [...properties].filter((i) => i.startsWith('claims.')).map((i) => i.substring('claims.'.length));

		for (const claimType of claimTypes) {
			// Requires LIST /claims permission => No API to GET claim by type, so use the LIST instead
			if (canLoadClaims === false) {
				skipped.push({ identifier: `claims.${claimType}`, name: `No permission for /${dataType}-claims` });
				continue;
			}
			const claimConfig = await loadClaimConfig(claimType);

			if (!claimConfig) {
				skipped.push({ identifier: `claims.${claimType}`, name: `${dataType} claim '${claimType}' not found` });
				continue;
			}
			claimConfigs.push(claimConfig);
		}
	}

	return claimConfigs;
};

const dataDescriptorToDataDisplayInfo = (descriptor: DataPropertyDescriptor | null | undefined, overrideDataDisplayInfo?: Partial<DataDisplayInfo>): DataDisplayInfo | undefined => {
	if (!descriptor) {
		return;
	}

	const dataType = fieldTypeToDataType(descriptor.type);

	if (!dataType) {
		return;
	}

	return Object.assign(
		{
			options: descriptor.options,
		},
		overrideDataDisplayInfo,
		{
			type: dataType,
		},
	) as DataDisplayInfo;
};

export {
	allowed, displayable, sortable, staticFilterable, inputFilterable,
	getUsersReference, getDisplay, getIcon, getDefaultOptions, getOperators,
	sortDataPropertyDescriptors, dataPropertyInfoToDataPropertyDescriptor,
	dataSourceTypeToDataDescriptorType,
	fieldToSchemaField, schemaFieldToDataPropertyDescriptor,
	getClaimConfigs, claimConfigToDataPropertyDescriptor, getPropertiesMap,
	propertiesToDataSourceOutputProperties, filterIdentifierChildrenIdentifiers,
	normalizeIdentifiersList, clearPropertiesAndRegisterUnknownsAsSkipped,
	dataDescriptorToDataDisplayInfo,
};
