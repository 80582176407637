<sc-page-content width="lg" identifier="button-groups">

	<ng-container editorInputs>
		<uf-radio [options]="sizeOptions" [(value)]="size" class="col-6" label="Sizes" columns="2"
			valueProperty="value" />
		<uf-radio [options]="colourOptions" [(value)]="colour" class="col-6" label="Colour" columns="2"
			valueProperty="value" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander>
			<div [ngClass]="[size, colour]" class="uf-button-group">
				<button type="button" class="uf-button">
					Pencil
					<uf-icon name="edit" />
				</button>
				<button type="button" class="uf-button">
					Paper
					<uf-icon name="file" />
				</button>
				<button type="button" class="uf-button">
					Clip
					<uf-icon name="attachment" />
				</button>
			</div>
		</sc-code-editor-expander>

		<h2>Examples</h2>
		<div class="uf-box inset pad-lg col space-children center-all">
			<div class="uf-button-group large">
				<button type="button" class="uf-button tertiary">Print<uf-icon name="print" /></button>
				<button type="button" class="uf-button">Save<uf-icon name="tick" /></button>
				<button type="button" class="uf-button primary">Send<uf-icon name="mail" /></button>
			</div>
			<div class="uf-button-group">
				<button type="button" class="uf-button tertiary">Print<uf-icon name="print" /></button>
				<button type="button" class="uf-button">Save<uf-icon name="tick" /></button>
				<button type="button" class="uf-button primary">Send<uf-icon name="mail" /></button>
			</div>
			<div class="uf-button-group small">
				<button type="button" class="uf-button tertiary">Print<uf-icon name="print" /></button>
				<button type="button" class="uf-button">Save<uf-icon name="tick" /></button>
				<button type="button" class="uf-button primary">Send<uf-icon name="mail" /></button>
			</div>
			<div class="uf-button-group x-small">
				<button type="button" class="uf-button tertiary">Print<uf-icon name="print" /></button>
				<button type="button" class="uf-button">Save<uf-icon name="tick" /></button>
				<button type="button" class="uf-button primary">Send<uf-icon name="mail" /></button>
			</div>
		</div>

	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="tableConfig" [rows]="tableInfo" />
	</ng-container>


</sc-page-content>