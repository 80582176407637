export interface FieldTreeNode {
	fields?: FieldTreeNode[];
}

export interface FieldIteratorOptions<F extends FieldTreeNode> {
	order?: 'preOrder' | 'postOrder';
	canDive?: (field: F, parent?: F) => boolean;
	canIterate?: (field: F, parent?: F) => boolean;
}

export interface FieldIteratedEntry<F extends FieldTreeNode> {
	field: F;
	parent?: F;
}

export function* fieldIterator<F extends FieldTreeNode>(
	input?: F[] | { fields?: F[] },
	parent?: F,
	options: FieldIteratorOptions<F> = { order: 'postOrder' }): Iterable<FieldIteratedEntry<F>> {

	// Normalize input
	const fields = (Array.isArray(input) ? input : input?.fields) ?? [];

	for (const field of fields) {

		const dive = field.fields && (!options.canDive || options.canDive(field, parent));
		const iterate = !options.canIterate || options.canIterate(field, parent);

		if (options.order === 'postOrder') {
			if (dive) {
				yield *fieldIterator(field.fields as F[], field, options);
			}
			if (iterate) {
				yield { field, parent };
			}
		} else {
			if (iterate) {
				yield { field, parent };
			}
			if (dive) {
				yield *fieldIterator(field.fields as F[], field, options);
			}
		}
	}
}
