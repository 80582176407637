import { AnimationBuilder, AnimationPlayer, animate, style } from '@angular/animations';
import { Directive, ElementRef, HostBinding, Input, OnDestroy, Renderer2 } from '@angular/core';

/**
 * This directive is responsible for apply error style and animation to the element
 */
@Directive({
	selector: 'div[ufErrorMessage]',
})
export class ErrorMessageDirective implements OnDestroy {

	@HostBinding('class') errorClass = 'uf-error';

	protected errorMessage: string | null | undefined;

	private readonly showAnimation = [
		style({ opacity: 0, transform: 'translateY(-3px)' }),
		animate('150ms', style({ opacity: 1, transform: 'translateY(0)' })),
	];

	private readonly hideAnimation = [
		style({ opacity: 1 }),
		animate('150ms', style({ opacity: 0, transform: 'translateY(-3px)' })),
	];

	private player: AnimationPlayer | null;

	constructor(
		private animationBuilder: AnimationBuilder,
		private elementRef: ElementRef,
		private renderer: Renderer2,
	) { }

	@Input() set ufErrorMessage(v: string | null | undefined) {

		if ((!this.errorMessage && !v) || this.errorMessage === v) {
			return;
		}

		this.errorMessage = v;

		if (v) {
			this.runShow();
		} else {
			this.runHide();
		}
	}

	get ufErrorMessage(): string {
		return this.errorMessage ?? '';
	}

	ngOnDestroy() {
		this.player?.destroy();
	}

	private runShow() {
		this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', this.errorMessage);
		this.player?.finish();
		this.player?.destroy();
		this.player = this.animationBuilder.build(this.showAnimation).create(this.elementRef.nativeElement);
		this.player.play();
	}

	private runHide() {
		this.player?.finish();
		this.player?.destroy();
		this.player = this.animationBuilder.build(this.hideAnimation).create(this.elementRef.nativeElement);
		this.player.play();
		this.player.onDone(() => {
			this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', this.errorMessage);
		});
	}

}
