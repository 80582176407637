import { DataSeed } from '@unifii/sdk';

/** DataSourceLoader should return DataSeed for getOptions.
 * The CollectionLoader and BuckerLoader are a special case where the DataSeed generated is extended with identifier and name attributes.
 * This is a hack that was initially needed in Unifii until smart-form options based fields become compatibly with DataSeed.
 * The hack has to stay for retro-compatibility reason in case of legacy external consumers that rely on this hack.
 * Do not use this hack in other DataSourceLoader implementations
 */
export const toDataSeedSafeOption = (seed: DataSeed): DataSeed =>
	Object.assign(seed, { identifier: seed._id, name: seed._display });
