<sc-page-content identifier="data-descriptor">
	<ng-container editor>
		<div class="uf-form-card col-12 gap-sides">
			<div class="uf-app-bar">
				<h3>Configuration</h3>
			</div>
			<div class="uf-grid pad gaps">
				<uf-textarea [(value)]="identifiers" label="Limited to identifiers (space separated)" class="col-12" />
				<uf-select [options]="types" [(value)]="type" (valueChange)="typeChange()" label="Source Type"
					placeholder="Select a type" class="col-4" />
				<uf-autocomplete *ngIf="type === 'Form Data' && buckets" [options]="bucketOptions"
					[(value)]="bucketSelected" (valueChange)="typeChange()" (searchChange)="searchBucket($event)"
					label="Select bucket" class="col-8" />
				<uf-autocomplete *ngIf="type === 'Collection' && collections" [options]="collectionOptions"
					[(value)]="collectionSelected" (valueChange)="typeChange()"
					(searchChange)="searchCollection($event)" label="Select collection" nameProperty="name"
					class="col-8" />
				<div *ngIf="permissions" class="uf-grid col-12">
					<uf-checkbox *ngIf="showACL('roles')" [(value)]="permissions.loadRoles" label="ACL Roles"
						class="col-4" />
					<uf-checkbox *ngIf="showACL('companies')" [(value)]="permissions.loadCompanies"
						label="ACL Companies" class="col-4" />
					<uf-checkbox *ngIf="showACL('hierarchy')" [(value)]="permissions.loadHierarchy"
						label="ACL Hierarchy" class="col-4" />
					<uf-checkbox *ngIf="showACL('userClaims')" [(value)]="permissions.loadUserClaims"
						label="ACL User Claims" class="col-4" />
					<uf-checkbox *ngIf="showACL('forms')" [(value)]="permissions.loadForms" label="ACL Forms"
						class="col-4" />
					<uf-checkbox *ngIf="showACL('schema')" [(value)]="permissions.loadSchema" label="ACL Schema"
						class="col-4" />
					<uf-checkbox *ngIf="showACL('collection')" [(value)]="permissions.loadCollectionDefinition"
						label="ACL Collection Definition" class="col-4" />
					<uf-checkbox *ngIf="showACL('users')" [(value)]="permissions.loadUsers" label="ACL Users"
						class="col-4" />
				</div>
			</div>
			<div class="uf-form-actions">
				<button *ngIf="dataDescriptor?.skippedProperties?.length" (click)="openDrawer = true" type="button"
					class="uf-button tertiary">
					{{dataDescriptor?.skippedProperties?.length}} Warnings
				</button>
				<button [disabled]="!showRun" (click)="run()" type="button" class="uf-button primary">
					Run
				</button>
			</div>
		</div>

		<div class="pad-sides">

			<uf-message *ngIf=" error" class="error col-12">
				<h3>{{ error }}</h3>
			</uf-message>

			<ng-template [ngIf]="dataDescriptor">
				<h2 class="col-12">Data Property Descriptors [ executed in {{executionTime}} ms ]</h2>

				<div class="uf-grid col-12">
					<uf-checkbox [(value)]="showDisplay" (valueChange)="refreshVisibleEntries()" label="Display"
						class="col-2" />
					<uf-checkbox [(value)]="showSort" (valueChange)="refreshVisibleEntries()" label="Sort"
						class="col-1" />
					<uf-checkbox [(value)]="showStaticFilter" (valueChange)="refreshVisibleEntries()"
						label="Static Filter" class="col-2" />
					<uf-checkbox [(value)]="showInputFilter" (valueChange)="refreshVisibleEntries()"
						label="Input Filters" class="col-2" />
					<uf-text
						value="Visible {{visibleDataProperties.length}} of {{dataDescriptor.propertyDescriptors.length}}"
						[disabled]="true" class="col-2" />
					<uf-checkbox [value]="dataDescriptor.isSearchable" [disabled]="true" label="Searchable"
						class="col-2" />
					<button (click)="copyIdentifiers()" type="button" title="Copy identifiers" class="uf-action col-1">
						<uf-icon name="copy" />
					</button>
				</div>

				<div class="table">
					<table *ngIf="visibleDataProperties.length" class="uf-table col-12">
						<thead>
							<tr>
								<th>#</th>
								<th>Display</th>
								<th>Icon</th>
								<th>Type</th>
								<th>DS</th>
								<th>Options</th>
								<th>Hierarchy</th>
								<th>Operators</th>
								<th class="icon-column">Display</th>
								<th class="icon-column">Sort</th>
								<th class="icon-column">F. Stat</th>
								<th class="icon-column">F. Input</th>
								<th>Identifier</th>
								<th>Label</th>
							</tr>
						</thead>
						<tbody>
							<ng-template [ngForOf]="visibleDataProperties" let-entry let-i="index" ngFor>
								<tr>
									<td>
										<uf-help [content]="entry | json" icon="view" />
									</td>
									<td [contentEllipsis]="50">
										{{entry.display}}
									</td>
									<td>
										<uf-icon *ngIf="entry.icon" [name]="entry.icon" />
									</td>
									<td>{{entry.type}}</td>
									<td>
										<div *ngIf="entry.sourceConfig" class="uf-lozenge success">
											{{entry.sourceConfig.type}}
										</div>
									</td>
									<td>
										<uf-icon *ngIf="entry.options?.length || entry.sourceConfig"
											(click)="openOptions(entry)" name="view" />
									</td>
									<td>
										<uf-help *ngIf="entry.hierarchyConfig" [content]="entry.hierarchyConfig | json"
											icon="hierarchy" />
									</td>
									<td>
										<uf-icon *ngIf="entry.operators?.length" (click)="openOperators(entry)"
											name="filter" />
									</td>
									<td>
										<uf-icon *ngIf="entry.asDisplay" name="checkboxTick" />
									</td>
									<td>
										<uf-icon *ngIf="entry.asSort" name="checkboxTick" />
									</td>
									<td>
										<uf-icon *ngIf="entry.asStaticFilter" name="checkboxTick" />
									</td>
									<td>
										<uf-icon *ngIf="entry.asInputFilter" name="checkboxTick" />
									</td>
									<td>{{entry.identifier}}</td>
									<td>{{entry.label}}</td>
								</tr>
							</ng-template>
						</tbody>
					</table>
				</div>
			</ng-template>

		</div>

	</ng-container>
</sc-page-content>

<uf-drawer [(open)]="openDrawer">
	<uf-panel class="container">
		<div class="uf-app-bar flat">
			<button (click)="openDrawer = false" type="button" class="uf-action tertiary">
				<uf-icon name="close" />
			</button>
		</div>
		<h3 class="gap-none-top">{{dataDescriptor?.skippedProperties?.length}} Warnings</h3>
		<ng-template [ngForOf]="dataDescriptor?.skippedProperties" let-skipped ngFor>
			<uf-message [content]="skipped.name" class="warning">
				<h3>{{ skipped.identifier }}</h3>
			</uf-message>
		</ng-template>
	</uf-panel>
</uf-drawer>