import { Injectable, Type } from '@angular/core';

import { DataDisplayIconValue, isDataDisplaySignatureValue } from '../../components';
import { DataDisplayComponentType } from '../../models';

import { DataDisplayComponentRegistry } from './data-display-component-registry';

/** Define the display logic differences for table compared to the standard */
@Injectable()
export class UfDataDisplayTableComponentRegistry implements DataDisplayComponentRegistry {

	// Use the DataDisplayComponentRegistry available in the DI (so that it can be replaced at app level and the chain is kept)
	// see "dataDisplayRepeatTableComponentRegistryFactory"
	constructor(protected dataDisplayComponentRegistry: DataDisplayComponentRegistry) { }

	preDataTransform(data: unknown): unknown {

		if (isDataDisplaySignatureValue(data)) {
			return {
				icon: 'signature',
			} satisfies DataDisplayIconValue;
		}

		return this.dataDisplayComponentRegistry.preDataTransform(data);
	}

	get(data: unknown): Type<DataDisplayComponentType> | null {
		return this.dataDisplayComponentRegistry.get(data);
	}

}
