<div class="uf-app-bar">
	<button [title]="sharedTermsTK.ActionClose | translate" (click)="close()" class="uf-action tertiary" type="button">
		<uf-icon name="close" />
	</button>
	<h3>{{commonTK.AddItems | translate }}</h3>
</div>

<div class="uf-grid pad-sides gaps">
	<uf-search [autofocus]="true" [(search)]="query" (searchChange)="onSearch($event)" class="col-12" />
	<uf-progress />
	<span *ngIf="searchMessage" class="col-12">{{searchMessage}}</span>
</div>

<uf-panel *ngIf="!searchMessage" class="stretch gap-sides">
	<ul class="uf-list">
		<li *ngFor="let option of options; let i = index" [class.active]="activeIndex === i"
			(click)="toggleSelection(i)" class="uf-list-button">
			<span>{{option | nameProperty: data.nameProperty}}</span>
			<uf-icon *ngIf="selectedOptions[i]" name="success" class="right" />
		</li>
	</ul>
</uf-panel>

<div class="uf-form-actions">
	<button (click)="close()" class="uf-button tertiary small right" type="button">
		{{ sharedTermsTK.ActionCancel | translate }}
	</button>
	<button *ngIf="showSelectAll" (click)="selectAll()" class="uf-button small" type="button">
		{{ commonTK.SelectAll | translate }}
	</button>
	<button *ngIf="showDeselectAll" (click)="deselectAll()" class="uf-button small" type="button">
		{{ commonTK.DeselectAll | translate }}
	</button>
	<button (click)="add()" class="uf-button primary small" type="button">
		{{ sharedTermsTK.ActionAdd | translate }}
	</button>
</div>