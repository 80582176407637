import { Component } from '@angular/core';
import { FieldTemplate } from '@unifii/sdk';

import { RuntimeField } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-separator',
	templateUrl: './seperator.html',
})
export class SeparatorComponent implements ContentField {

	readonly fieldTemplate = FieldTemplate;
	field: RuntimeField;

}
