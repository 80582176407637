import { Injectable } from '@angular/core';
import { ClaimConfig, Company, Definition, Role, RoleWithDisplay, Schema } from '@unifii/sdk';

@Injectable({ providedIn: 'root' })
export class DataDescriptorAdapterCache {

	collections: Map<string, Promise<Definition | undefined>>;
	schemas: Map<string, Promise<Schema | undefined>>;
	formsInfo?: Promise<{ identifier: string; label: string; bucket?: string }[]>;
	roles?: Promise<RoleWithDisplay<Role>[]>;
	companies?: Promise<Company[]>;
	userClaims?: Promise<ClaimConfig[]>;

	constructor() {
		this.reset();
	}

	reset() {
		this.collections = new Map();
		this.schemas = new Map();
		this.formsInfo = undefined;
		this.roles = undefined;
		this.companies = undefined;
		this.userClaims = undefined;
	}

}
