import { Component, ElementRef, Input, inject } from '@angular/core';

@Component({
	selector: 'uf-icon',
	templateUrl: 'icon.html',
	styleUrls: ['icon.less'],
})
export class IconComponent {

	@Input({ required: true }) name: string | null | undefined;

	// el is exposed to allow external modifications (see SortElementDirective style modifications)
	el = inject<ElementRef<SVGElement>>(ElementRef);

}
