<sc-page-content width="xl" identifier="data-display">
	<ng-container editor>
		<div class="uf-form-card">
			<div class="uf-app-bar">
				<h3>Configuration</h3>
				<uf-text [(value)]="identifiersCSV" label="Identifiers"
					placeholder="Whitelist of CSV identifiers to display" />
				<sc-entity-picker [pickData]="true" (emitChange)="onEntityEmit($event)" />
			</div>
		</div>

		<uf-message *ngIf="error" class="error">
			<h3>{{ error }}</h3>
		</uf-message>

		<div class="uf-form-card">
			<table *ngIf="results.length" class="uf-table">
				<thead>
					<tr>
						<th>#</th>
						<th>Identifier</th>
						<th>DDE FieldType</th>
						<th>Detected DataType</th>
						<th>Detected Data Display</th>
						<th>Description List</th>
						<th>Data</th>
						<th>Options</th>
					</tr>
				</thead>
				<tbody>
					<ng-template [ngForOf]="results" let-entry let-i="index" ngFor>
						<tr>
							<td>{{i}}</td>
							<td>{{entry.identifier}}</td>
							<td>{{entry.dataDescriptor.type}}</td>
							<td>{{entry.detectedDataType}}</td>
							<td *ngIf="entry.detectedDataType">
								<uf-help [content]="entry.value | dataDisplay: $any({
									type: entry.detectedDataType,
									options: entry.dataDescriptor.options
									})" />
							</td>
							<td *ngIf="!entry.detectedDataType">&nbsp;</td>
							<td><uf-data-display [data]="entry.dataDisplayItem?.data" /></td>
							<td>
								<uf-help *ngIf="entry.value != null" content="{{entry.value | json}}" />
							</td>
							<td>
								<uf-help *ngIf="entry.dataDescriptor.options"
									content="{{entry.dataDescriptor.options | json}}" />
							</td>
						</tr>
					</ng-template>
				</tbody>
			</table>
		</div>

	</ng-container>
</sc-page-content>