export * from './custom-cell.component';
export * from './data-display-table-cell.component';
export * from './data-display-table-list.component';
export * from './select-cell.component';
export * from './table-actions.component';
export * from './table-card-expander.component';
export * from './table-column-toggles.component';
export * from './table-data-source';
export * from './table-models';
export * from './table-preferences-provider-model';
export * from './table-sort.component';
export * from './table-status.component';
export * from './table-toolbar.component';
export * from './table.component';
export * from './table-functions';

import { CustomCellComponent } from './custom-cell.component';
import { DataDisplayTableCellComponent } from './data-display-table-cell.component';
import { DataDisplayTableListComponent } from './data-display-table-list.component';
import { SelectCellComponent } from './select-cell.component';
import { TableActionsComponent } from './table-actions.component';
import { TableCardExpanderComponent } from './table-card-expander.component';
import { TableColumnTogglesComponent } from './table-column-toggles.component';
import { TableSortComponent } from './table-sort.component';
import { TableStatusComponent } from './table-status.component';
import { TableToolbarComponent } from './table-toolbar.component';
import { CellForDirective, TableComponent } from './table.component';

export const UF_TABLE_COMPONENTS = [
	TableComponent,
	DataDisplayTableCellComponent,
	DataDisplayTableListComponent,
	CellForDirective,
	TableToolbarComponent,
	TableStatusComponent,
	TableActionsComponent,
	TableSortComponent,
	TableColumnTogglesComponent,
	TableCardExpanderComponent,
	SelectCellComponent,
	CustomCellComponent,
];
