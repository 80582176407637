<ng-container *ngIf="template; else noTemplate">
	<ng-container
		*ngTemplateOutlet="template; context: { options: options, select: select.bind(this), active: activeIndex } " />
</ng-container>

<ng-template #noTemplate>
	<ul *ngIf="options.length > 0" [ngClass]="cssClass" class="uf-list default">
		<li *ngFor="let option of options; let i = index" [class.active]="activeIndex === i"
			[class.disabled]="option?._notSelectable" [ngClass]="option?._cssClass" (click)="select(option)"
			class="uf-list-button">
			<span *ngIf="option?._customOption || option?._notSelectable">{{option.name}}</span>
			<span *ngIf="!option?._customOption && !option?._notSelectable">
				{{option | nameProperty:nameProperty}}
			</span>
		</li>
	</ul>
</ng-template>