import { Component } from '@angular/core';

import { UfControl, UfControlArray, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';

@Component({
	templateUrl: './show-control.html',
	styleUrls: ['./show-control.less'],
})
export class ShowControlComponent {

	optionsForm: UfControlGroup;
	status: string;
	options: { value: string; key: string }[] = [];

	constructor() {
		this.setup();
	}

	setup() {
		this.optionsForm = new UfControlGroup({
			identifierControls: new UfControlArray([]),
		});
	}

	addOption() {
		const option = { value: '', key: 'Input.' + this.options.length };

		this.options.push(option);

		const control = new UfControl(ValidatorFunctions.compose([
			ValidatorFunctions.required('Identifier is required'),
			ValidatorFunctions.custom((v) => this.isUnique(v, option, this.options), 'This value result not unique across the form'),
		]));

		control.setSubmitted(true); // show immediate error visual feedback
		(this.optionsForm.controls.identifierControls as UfControlArray).push(control);
	}

	delete(index: number) {
		this.options.splice(index, 1);
		(this.optionsForm.controls.identifierControls as UfControlArray).removeAt(index);
	}

	updateValue(index: number, value: any) {
		const option = this.options[index];

		if (option) {
			option.value = value;
		}

		this.optionsForm.updateValueAndValidity();
	}

	private isUnique(value: any, self: any, options: { value: string; key: string }[]) {
		let result = true;

		for (const option of options) {
			if (option !== self && option.value === value) {
				result = false;
			}
		}

		return result;
	}

}
