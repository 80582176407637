import { Component, HostBinding, Input } from '@angular/core';
import { Compound } from '@unifii/sdk';

import { RuntimeField } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-link-display',
	templateUrl: './link.html',
})
export class LinkComponent implements ContentField {

	@HostBinding('class.body-copy') bodyclass = true;

	@Input() field: RuntimeField;

	name?: string;

	private _content: Compound;

	@Input() get content(): Compound {
		return this._content;
	}

	set content(v: Compound) {
		// Guard for bad data
		if (!v || !Object.keys(v).length) {
			return;
		}

		// Else display the first non private varaible
		this.name = this.getLinkName(v);
	}

	getLinkName(compound: Compound): string | undefined {

		// Default lookup should be name
		if (compound.name) {
			return compound.name;
		}

		for (const key in compound) {
			if (!key.startsWith('_')) {
				return compound[key];
			}
		}

		return;
	}

}
