<ng-template [ngIf]="mode === modes.Input">
	<div class="wrapper">
		<ng-template [ngIf]="entry.options.length" [ngIfElse]="noOptions">
			<ng-template [ngIf]="entry.options.length < 7" [ngIfElse]="select">
				<uf-radio [options]="entry.options" [(value)]="value" (valueChange)="valueChanged()"
					valueProperty="identifier" columns="1" />
				<button *ngIf="!isEmpty" (click)="remove()" type="button" class="uf-action small tertiary">
					<uf-icon name="backspace" />
				</button>
			</ng-template>
			<ng-template #select>
				<uf-select [placeholder]="sharedTermsTK.ActionSelect | translate" [options]="entry.options"
					[(value)]="value" (valueChange)="valueChanged()" valueProperty="identifier" />
			</ng-template>
		</ng-template>

		<ng-template #noOptions>
			<div>{{commonTK.ChoiceNoOptionsAvailable | translate}}</div>
		</ng-template>
	</div>
</ng-template>

<div *ngIf="mode === modes.Display && !isEmpty" class="uf-chip x-small">
	{{ label }}: {{ displayValue }}
	<button (click)="remove()" type="button" class="uf-action">
		<uf-icon name="delete" />
	</button>
</div>