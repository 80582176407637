import { Component, Input, Output } from '@angular/core';

import { SharedTermsTranslationKey } from '../../translations';

@Component({
	selector: 'uf-expander-button',
	templateUrl: './expander-button.html',
	styleUrls: ['./expander-button.less'],
})
export class ExpanderButtonComponent {

	@Input() isExpanded: boolean;
	@Input() actionBtn: boolean;

	protected readonly sharedTermsTK = SharedTermsTranslationKey;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	@Output() toggle(_event: MouseEvent) { /**/ }
	@Output() expandAll() { /**/ }
	@Output() collapseAll() { /**/ }

}
