import { Pipe, PipeTransform } from '@angular/core';
import { Dictionary } from '@unifii/sdk';

import { NamePropertyInfo } from '../models';

/**
 * Extract a stringified value from an object, eventually driven by a property "information" (path or callback)
 */
@Pipe({ name: 'nameProperty' })
export class NamePropertyPipe implements PipeTransform {

	// TODO accept unknown as type of data
	/**
	 * Extract a string value from the data based on the propertyInfo
	 * @param data source to extract the value from
	 * @param propertyInfo configuration to extract the value
	 */
	transform(data: Dictionary<any> | string | number | null, propertyInfo?: NamePropertyInfo | null | undefined): string {
		if (data == null) {
			return '';
		}

		if (typeof propertyInfo === 'function') {
			return propertyInfo(data) ?? '';
		}

		if (typeof data === 'string' || typeof data === 'number') {
			return `${data}`;
		}

		if (propertyInfo && data[propertyInfo]) {
			return `${data[propertyInfo]}`;
		}

		// Return first key
		const firstKey = Object.keys(data)[0];

		return firstKey ? '' + (data[firstKey] ?? '') : '';
	}

	// TODO Improved implementation, won't pass unit-tests... need more debugging
	// private dataLookupService = inject(DataLookupService);
	/*
	transform(data: unknown, property?: null | string | ((option: any) => string)): string {
		if (data == null) {
			return '';
		}

		if (typeof property === 'function') {
			return property(data);
		}

		let value: unknown;

		if (!isDictionary(data) && !isString(data) && !isNumber(data)) {
			return '';
		}

		if (isDictionary(data)) {
			if (isString(property) && isIdentifiersPathExpression(property)) {
				value = this.dataLookupService.lookupData(data, property, `NamePropertyPipe - lookup value via identifier ${property} from ${JSON.stringify(data)}`);
			} else {
				const firstKey = Object.keys(data)[0];

				value = firstKey ? data[firstKey] : '';
			}
		}

		if (isString(value) || isNumber(value)) {
			return `${value}`;
		}
		
		return '';
	}
*/

}
