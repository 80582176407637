import { Injectable, inject } from '@angular/core';

import { Repository, TablePreferences, TablePreferencesProvider } from '@unifii/library/common';

@Injectable()
export class ShowcaseTablePreferencesProvider implements TablePreferencesProvider {

	private readonly key = 'UfUserTablePreference';
	private repository = inject(Repository);

	loadTablePreferences(id: string) {
		return this.repository.load<TablePreferences>(`${this.key}-${id}`);
	}

	saveTablePreferences(id: string, value?: TablePreferences) {
		this.repository.store(`${this.key}-${id}`, value);
	}

}
