import { Component, Input } from '@angular/core';

export enum SpinnerTypes {
	Square = 'square',
	Grid = 'grid',
	Dots = 'dots'
}

@Component({
	selector: 'uf-spinner',
	templateUrl: './spinner.html',
	styleUrls: ['./spinner.less'],
})
export class SpinnerComponent {

	@Input() colour: string;
	@Input() type: SpinnerTypes | 'square' | 'grid' | 'dots' = SpinnerTypes.Dots;

	protected readonly spinnerTypes = SpinnerTypes;

}
