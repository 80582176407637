import { HierarchyUnitExtended, HierarchyUnitFormData, hasLengthExact, isNotNull } from '@unifii/sdk';

import { HierarchyUnitIdentifier, HierarchyUnitProvider } from '../../../models';
import { HierarchyFunctions } from '../../../utils';

type UnitsAndCommonAncestor = {
	units: HierarchyUnitExtended[];
	ancestor: HierarchyUnitExtended | null;
};

/**
 * @description
 * HierarchyPrefillController is a utility class for prefilling a
 * Hierarchy input with a single valid HierarchyUnit. It handles the following cases:
 *  - Single valid unit => HierarchyUnit
 *  - Two valid units => Close ancestor to both Units
 */
export class HierarchyPrefillController {

	constructor(
		private hierarchyProvider: HierarchyUnitProvider,
		private ceiling?: string,
	) { }

	/**
	 * @param unitsIdentifier expects single/array units' identifier
	 * @returns valid descendent of hierarchy root multiple values
	 */
	async getUnit(unitsIdentifier: HierarchyUnitIdentifier | HierarchyUnitIdentifier[]): Promise<HierarchyUnitFormData | null> {
		const commonAncestor = (await this.getUnitsAndCommonAncestor(unitsIdentifier)).ancestor;

		// TODO why clone the just loaded unit
		return commonAncestor ? structuredClone(commonAncestor) : null;
	}

	/**
	 * Retrieve the list of units matching the references and the common ancestor
	 * The units are filtered by ceiling and the common ancestor can't be the root unit
	 * @param unitsReferences expects single/multiple references of hierarchy units
	 * @returns matching units and their common ancestor
	 */
	async getUnitsAndCommonAncestor(unitsReferences: unknown): Promise<UnitsAndCommonAncestor> {
		const result: UnitsAndCommonAncestor = { units: [], ancestor: null };
		const ids = HierarchyFunctions.toHierarchyUnitsIds(unitsReferences);

		if (!ids) {
			return result;
		}

		result.units = await this.loadUnitsWithinCeiling(ids);

		if (!result.units.length) {
			return result;
		}

		// Single unit
		if (hasLengthExact(result.units, 1)) {
			// root unit (parentId == null) is not a valid return
			result.ancestor = result.units[0].parentId ? result.units[0] : null;
		} else {
			result.ancestor = await this.getCommonAncestorExcludedRoot(result.units);
		}

		return result;
	}

	private async getCommonAncestorExcludedRoot(units: HierarchyUnitExtended[]): Promise<HierarchyUnitExtended | null> {
		const pathDepth = units[0]?.path.length ?? 0;
		let index = 0;
		let ancestorId: string | undefined;

		while (index < pathDepth) {
			const ids = [...new Set(units.map((u) => u.path[index]?.id))];

			if (ids.length === 1) {
				ancestorId = ids[0];
			}
			index++;
		}

		if (!ancestorId) {
			return null;
		}

		return await this.hierarchyProvider.getUnit(ancestorId) ?? null;
	}

	/**
	 * Retrieve the units that are descendant of the configured ceiling
	 * @param ids of the units to load
	 * @returns units matching the ids, limited by the optionally configured ceiling
	 */
	private async loadUnitsWithinCeiling(ids: string[]): Promise<HierarchyUnitExtended[]> {
		// TODO - check if this maybe simplified using descendants API
		const units = (await Promise.all(ids.map((id) => this.hierarchyProvider.getUnit(id)))).filter(isNotNull);
		const ceiling = this.ceiling;
		
		if (!ceiling) {
			return units;
		}

		return units.filter((unit) => HierarchyFunctions.isDescendantOf(unit, ceiling));
	}

}
