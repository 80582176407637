import { Component, HostBinding, Inject } from '@angular/core';

import { Modal, ModalConfirmData, ModalData, ModalRuntime, ModalService } from '@unifii/library/common';

@Component({
	selector: 'sc-show-modal-sample',
	templateUrl: './show-modal-sample.html',
	styleUrls: ['./show-modal-sample.less'],
})
export class ShowModalSampleComponent implements Modal<ModalConfirmData, boolean> {

	@HostBinding('class.uf-form-card') cardClass = true;

	guard: boolean;

	private readonly nestedData: ModalConfirmData = {
		title: 'Choose a Nested modal',
		confirmLabel: 'Yes',
		cancelLabel: 'No',
	};

	constructor(
		public runtime: ModalRuntime<ModalConfirmData, boolean>,
		@Inject(ModalData) public data: ModalConfirmData,
		private service: ModalService,
	) { }

	openFit() {
		void this.service.openFit(ShowModalSampleComponent, this.nestedData);
	}

	openMedium() {
		void this.service.openMedium(ShowModalSampleComponent, this.nestedData);
	}

	openLarge() {
		void this.service.openLarge(ShowModalSampleComponent, this.nestedData);
	}

	openFullScreen() {
		void this.service.openFullScreen(ShowModalSampleComponent, this.nestedData);
	}

	close(data: boolean) {
		this.runtime.close(data);
	}

}
