import { Injectable, inject } from '@angular/core';
import { TenantClient } from '@unifii/sdk';

const MaxScreenWidth = 2000;

/**
 * AssetInterpolator inserts html attributes on html tags that include unifii asset information
 *  eg: <img data-id="123"> => <img src="https://asset-location/123" data-id="123">
 */
@Injectable({ providedIn: 'root' })
export class AssetInterpolator {

	private tenantClient = inject(TenantClient);

	async interpolate(htmlString: string, maxWidth?: number): Promise<string> {
		maxWidth = maxWidth ?? MaxScreenWidth;

		htmlString = await this.updateImageTags(htmlString, maxWidth);
		htmlString = await this.updateVideoTags(htmlString);

		return htmlString;
	}

	private async updateImageTags(htmlString: string, maxWidth: number): Promise<string> {
		const tags = htmlString.match(/<img .*?data-id=".+".*?>/g) ?? [];

		for (const tag of tags) {
			const id = this.getDatasetValue(tag, /data-id="(.*?)"/);
			const width = this.getDatasetValue(tag, /data-width="(.*?)"/);
			const aspectRatio = this.getDatasetValue(tag, /data-aspect-ratio="(.*?)"/);
			const crop = this.getDatasetValue(tag, /data-crop="(.*?)"/);

			let url = id ? await this.getAssetURL(id) : undefined;

			if (url) {
				url = this.appendImageParams(url, maxWidth, width, aspectRatio, crop);
				const updatedTag = this.insertSrc(tag, 'img', url);

				htmlString = htmlString.replace(tag, updatedTag);
			}
		}

		return htmlString;
	}

	private async updateVideoTags(htmlString: string): Promise<string> {
		const tags = htmlString.match(/<video .*?data-id=".+".*?>/g) ?? [];

		for (const tag of tags) {
			const id = this.getDatasetValue(tag, /data-id="(.*?)"/);
			const url = id ? await this.getAssetURL(id) : undefined;

			if (url) {
				const updatedTag = this.insertSrc(tag, 'video', url);

				htmlString = htmlString.replace(tag, updatedTag);
			}
		}

		return htmlString;
	}

	private getDatasetValue(str: string, regex: RegExp): string | undefined {
		// TODO switch to positive look ahead when available in safari
		const matchedGroups = str.match(regex) ?? [];

		return matchedGroups[1];
	}

	private async getAssetURL(id: string): Promise<string | undefined> {
		try {
			const { url } = await this.tenantClient.getAsset(id);

			return url;
		} catch (e) {
			return;
		}
	}

	private appendImageParams(
		baseUrl: string,
		maxWidth: number,
		width?: string,
		aspectRatio?: string,
		crop?: string,
	): string {

		const params = new URLSearchParams();

		const imageWidth = this.getWidth(maxWidth, width);

		params.append('w', ''+imageWidth);

		const height = this.getHeight(aspectRatio, imageWidth);

		if (height) {
			params.append('h', '' + height);
		}

		if (crop) {
			params.append('crop', crop);
		}

		return `${baseUrl}?${params.toString()}`;
	}

	private insertSrc(source: string, tag: string, url: string): string {
		// TODO seems a little messy, see if there is a better way
		const position = `<${tag}`.length;

		return [source.slice(0, position), ` src="${url}"`, source.slice(position)].join('');
	}

	/** width: '33%' | '50%' */
	private getWidth(maxWidth: number, width?: string): number {
		if (!width) {
			return maxWidth;
		}

		if (width.includes('%')) {
			const percentNumber = +(width.replace(/[^0-9]/g, ''));

			return Math.round((maxWidth * percentNumber) / 100);
		}

		if (isNaN(+width)) {
			return maxWidth;
		}

		// specific pixel width requested
		return +width;
	}

	private getHeight(ratio: string | undefined, width: number): number | undefined {

		const [widthRatio, heightRatio] = (ratio ?? '').split('x').map((a) => +a);

		if (widthRatio == null || heightRatio == null) {
			return undefined;
		}

		if (widthRatio >= heightRatio) {
			return (width / widthRatio) * heightRatio;
		}

		return (heightRatio / widthRatio) * width;
	}

}
