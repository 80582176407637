import { AfterViewInit, Component, ContentChild, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, TemplateRef, ViewChild, inject } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Dictionary } from '@unifii/sdk';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { NamePropertyPipe } from '../../pipes';
import { ModalService } from '../../services';
import { CommonTranslationKey, SharedTermsTranslationKey } from '../../translations';
import { ProgressComponent } from '../indicators';

import { UfChipsBulkSelectModalComponent, UfChipsBulkSelectModalData } from './uf-chips-bulk-select-modal.component';
import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-chips',
	templateUrl: './uf-chips.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfChipsComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-chips.less'],
})
export class UfChipsComponent extends UfControlValueAccessor<any[]> implements OnInit, AfterViewInit {

	@ViewChild('inputWrap', { static: true }) inputWrap: ElementRef<HTMLDivElement> | undefined;
	@ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement> | undefined;
	@ContentChild('listBox', { static: true }) listBoxTemplate: TemplateRef<any>;
	@ViewChild(ProgressComponent, { static: true }) progress: ProgressComponent | undefined;

	@Input() name: string | null | undefined;
	@Input() label: string | null | undefined;
	@Input() placeholder: string | null | undefined;
	@Input() debounce: number | null | undefined = 200;
	@Input() autofocus: boolean | null | undefined;
	@Input() valueProperty: string | null | undefined;
	@Input() nameProperty: string | (((option: any) => string) | null) | undefined;
	@Input() canDelete: ((value: any) => boolean) | undefined;
	@Input() allowDuplicates: boolean | null | undefined;
	@Input() allowCustom: boolean | null | undefined; /** Allow the user to provide custom value outside of the options range */
	@Input() noResultsMsg: string | null | undefined;
	@Input() minSearchLength: number | null | undefined = 0;
	@Input() minSearchLengthMsg: string | null | undefined;
	@Output() searchChange = new EventEmitter<string>(); /** Notify search criteria has changed */
	@Output() override valueChange = new EventEmitter<any>(); /** Notify the selected value has changed */

	/** Chrome autofill no longer accepts autocomplete="off" for now a random value seems to work
	in the future we may have to create a unique value for each */
	autocomplete: string = Math.random().toString(36).substring(2, 18);
	focused: boolean;
	queryControl = new FormControl('');
	focusedChip: number | null;
	optionsChange = new Subject<string[]>();

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected hasSearchConfigured: boolean;
	protected showDeleteAll = false;

	private namePropertyPipe = inject(NamePropertyPipe);
	private modalService = inject(ModalService);
	private translateService = inject(TranslateService);
	private _options: any[] | null | undefined;
	private isBulkSelectModalOpen = false;
	private bulkSelectOptionsSubject = new Subject<unknown[] | undefined>();
	
	ngOnInit() {
		this.subscriptions.add(this.queryControl.valueChanges.pipe(
			debounceTime(this.debounce ?? 0),
			distinctUntilChanged(),
		).subscribe((q) => { this.guardedEmitSearchChange(q); }));

		this.hasSearchConfigured = this.searchChange.observed;
	}

	ngAfterViewInit() {
		if (!this.input) {
			return;
		}

		this.subscriptions.add(fromEvent(this.input.nativeElement, 'focus').subscribe(() => { this.onfocus(); }));
		// debounce to guarantee onselect to execute before blur, avoid updateInputControl race condition
		this.subscriptions.add(fromEvent(this.input.nativeElement, 'blur').pipe(debounceTime(100)).subscribe(() => { this.onblur(); }));

		if (this.autofocus) {
			this.input.nativeElement.focus();
		}
	}

	/** @override - needed because directive @Input is not reflected from the parent class */
	@Input() override set value(v: any[] | any | undefined) {
		super.value = v;
		this.updateShowDeleteAll();
	}

	override get value(): any[] {
		return super.value ?? [];
	}

	@Input() set options(v: any[] | null | undefined) {
		
		if (this.disabled) {
			return;
		}

		const options = this.getFilteredOptions(v);

		if (this.isBulkSelectModalOpen) {
			this.bulkSelectOptionsSubject.next(options);
		} else {
			this.progress?.complete();
			this._options = options;
		}
	}

	get options(): any[] | null | undefined {
		return this._options;
	}

	@HostBinding('class.focused') get focusedClass() {
		return this.focused && !this.disabled;
	}

	@HostBinding('class.error') get errorClass() {
		return this.control.showError && !this.disabled;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	@HostBinding('class.value') get valueClass() {
		return !!this.value;
	}

	override valueEmitPredicate(value?: (Dictionary<any> | string)[] | null, prev?: (Dictionary<any> | string)[] | null): boolean {
		if (!this.control.dirty) {
			this.control.markAsDirty();
		}

		this.updateShowDeleteAll();

		return super.valueEmitPredicate(value, prev);
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	@HostListener('keydown', ['$event'])
	keydown(event: KeyboardEvent) {

		if (event.key !== 'Backspace' || !this.value.length || !!this.queryControl.value) {
			return;
		}

		if (this.focused) {
			this.focusLastChip();

			return;
		}

		if (this.focusedChip != null && this.focusedChip >= 0) {
			this.deleteChip(this.focusedChip);
			this.focusLastChip();
		}
	}

	protected allowDelete(chip: Dictionary<any> | string | number) {
		if (this.disabled) {
			return false;
		}

		if (!this.canDelete) {
			return true;
		}

		return this.canDelete(chip);
	}

	protected deleteChip(i: number) {

		const updateValue = this.value;

		updateValue.splice(i, 1);
		this.control.setValue([...updateValue]);

		this.focusedChip = null;
	}

	protected async deleteAll() {

		const confirm = await this.modalService.openConfirm({
			title: this.translateService.instant(CommonTranslationKey.DeleteAllItemsDialogTitle),
			message: this.translateService.instant(CommonTranslationKey.DeleteAllItemsDialogMessage),
			confirmLabel: this.translateService.instant(SharedTermsTranslationKey.ActionDelete),
		});

		if (!confirm) {
			return;
		}
		
		this.control.setValue(null);
		this.focusedChip = null;
	}

	protected async openBulkSelectModal(event: Event) {
		event.preventDefault();
		
		if (!this.hasSearchConfigured) {
			return;
		}

		try {
			this.isBulkSelectModalOpen = true;
			
			const result = await this.modalService.openMedium(UfChipsBulkSelectModalComponent, {
				search: this.guardedEmitSearchChange.bind(this),
				optionsSubject: this.bulkSelectOptionsSubject,
				nameProperty: this.nameProperty,
				minSearchLength: this.minSearchLength ?? undefined,
				minSearchLengthMsg: this.minSearchLengthMsg ?? undefined,
			} satisfies UfChipsBulkSelectModalData);

			if (result) {
				this.onSelect(result);
			}
		} finally {
			this.isBulkSelectModalOpen = false;
		}
	}

	protected onSelect(values: (Dictionary<any> | string)[]) {
		this.clearQueryInput();

		if (!values.length) {
			return;
		}

		const valueProperty = this.valueProperty;
		
		if (valueProperty) {
			this.control.setValue([...this.value, (values as Dictionary<any>[]).map((value) => value[valueProperty])]);

			return;
		}
		/** Create new array reference so change is detected and emitted */
		this.control.setValue([...this.value, ...values]);
	}

	protected clearQueryInput() {
		this.queryControl.setValue(null, { onlySelf: true, emitEvent: false });
	}

	protected namePropertyFunc(v: Dictionary<any> | string | number) {
		if (
			this.valueProperty &&
			this.nameProperty &&
			(typeof this.nameProperty === 'string') &&
			(typeof v === 'string' || typeof v === 'number')
		) {
			const matchOption = this._options?.find((option) => option[this.valueProperty as keyof typeof option] === v) ?? {};

			return matchOption[this.nameProperty] ?? v;
		}

		return this.namePropertyPipe.transform(v, this.nameProperty);
	}

	private onfocus() {

		this.focused = true;

		if (this.disabled) {
			return;
		}

		this.safeClearOptions();
		this.guardedEmitSearchChange(this.queryControl.value);
	}

	private onblur() {
		this.focused = false;
		this.control.markAsTouched();
		this.progress?.complete();
		this.clearQueryInput();
	}

	private getFilteredOptions(options: (Dictionary<any> | string)[] | null = []): (Dictionary<any> | string)[] | undefined {
		if (!options) {
			return;
		}

		return options.filter((filterValue) => {

			if (this.allowDuplicates) {
				return true;
			}

			if (this.valueProperty) {
				const nonNullValueProperty = this.valueProperty;

				/** find matching by identifier */
				return !this.value.find((v: any) => v === (filterValue as Dictionary<any>)[nonNullValueProperty]);
			}

			if (this.nameProperty && typeof this.nameProperty === 'string') {
				const nonNullNameProperty = this.nameProperty;

				/** find matching complex values */
				return !this.value.find((findValue: any) =>
					(findValue as Dictionary<any>)[nonNullNameProperty] === (filterValue as Dictionary<any>)[nonNullNameProperty],
				);
			}

			return !this.value.includes(filterValue);
		});
	}

	private safeClearOptions() {
		if (this.hasSearchConfigured) {
			this.options = null;
		}
	}

	private guardedEmitSearchChange(q: string | null) {

		if (+(this.minSearchLength ?? 0) > (q ?? '').length) {
			this.safeClearOptions();

			return;
		}

		if (!this.hasSearchConfigured) {
			return;
		}
		
		this.searchChange.emit(q ?? undefined);

		if (!this.isBulkSelectModalOpen) {
			this.progress?.start(.4);
		}
	}

	private focusLastChip() {

		if (!this.value.length || !this.inputWrap) {
			return;
		}

		const chips = this.inputWrap.nativeElement.querySelectorAll<HTMLDivElement>('.uf-chip');
		const deleteButton = chips[chips.length - 1]?.querySelector<HTMLButtonElement>('button[data-type="chip"');

		deleteButton?.focus();
	}

	private updateShowDeleteAll() {
		if (!this.value.length) {
			this.showDeleteAll = false;
			
			return;
		}

		this.showDeleteAll = this.value.every((value) => !this.canDelete || this.canDelete(value));
	}

}
