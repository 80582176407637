import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { ModalService } from '../../services';
import { CommonTranslationKey } from '../../translations';
import { ModalPositionAlignment } from '../modal';

import { MenuOption, filterMenuOptions } from './menu-model';
import { MenuSingleChoiceListComponent } from './menu-single-choice-list.component';

@Component({
	selector: 'uf-drop-menu',
	templateUrl: './drop-menu.html',
	styles: [`
		:host {
			background: var(--drop-menu-background);
		}
	`],
})
export class DropMenuComponent<T, O extends MenuOption> {

	@HostBinding('style.display') get display() { return this.availableOptions.length ? 'flex' : 'none'; }

	@Input() icon = 'contextual';
	@Input() title: string;
	@Input() context: T;
	@Input() options: O[];

	@Output() selected = new EventEmitter<O>();

	readonly commonTK = CommonTranslationKey;

	constructor(private modalService: ModalService) { }

	get availableOptions(): O[] {
		return this.options ? filterMenuOptions(this.options, this.context) : [];
	}

	async open(event: MouseEvent) {

		const option = await this.modalService.openAnchor<O[], O>(
			MenuSingleChoiceListComponent,
			event,
			{
				target: event.target as HTMLElement,
				alignmentY: ModalPositionAlignment.Bottom,
				originY: ModalPositionAlignment.Bottom,
			},
			this.availableOptions,
		);

		if (option) {
			this.selected.next(option);
		}
	}

}
