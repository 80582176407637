<div class="uf-form-card">
	<uf-progress class="large" />
	<uf-stepper [showLabels]="false">

		<form [formGroup]="loginForm" (ngSubmit)="login()">
			<uf-step #loginStep [control]="loginForm" [showNext]="false" nextLabel="Login">
				<ng-container content>
					<div class="uf-app-bar">
						<h3>Login</h3>
					</div>
					<div class="uf-grid row-gap pad">
						<uf-text name="tenant" label="Company" formControlName="tenant" class="col-12" />
						<uf-text [(value)]="username" name="username" label="Username" formControlName="username"
							autocomplete="username" class="col-12" />
						<!-- validators on uf-password throws error in CVA get control -->
						<uf-password [(value)]="password" name="password" label="Password" formControlName="password"
							autocomplete="password" class="col-12" />
						<uf-message *ngIf="error" [content]="error" icon="error" class="small error col-12" />
					</div>
				</ng-container>
				<ng-container actions>
					<button type="submit" class="uf-button primary">
						Login
						<uf-icon name="arrowForward" />
					</button>
				</ng-container>
			</uf-step>
		</form>

		<uf-step [control]="projectControl" completeLabel="Enter">
			<ng-container content>
				<div class="uf-app-bar">
					<h3>Projects</h3>
				</div>
				<div class="uf-grid row-gap pad">
					<uf-select *ngIf="projects" [options]="projects" [value]="projectId" [control]="projectControl"
						[nameProperty]="projectLabel" (valueChange)="selectProject($event)" placeholder="Select Project"
						valueProperty="id" class="col-12" />
				</div>
			</ng-container>
		</uf-step>
	</uf-stepper>
</div>