import { Component, OnInit, inject } from '@angular/core';
import { Dictionary } from '@unifii/sdk';

import { ModalService } from '@unifii/library/common';
import { ColumnInfo } from 'showcase/src/app/components';

interface DirectoryEntry extends Dictionary<any> {
    label: string;
    children?: DirectoryEntry[];
}

@Component({
	templateUrl: './show-directory.html',

})
export class ShowDirectoryComponent implements OnInit {

	protected readonly tableInfo = [
		{ name: 'light', type: 'modifier', description: 'Sets icon colour to light' },
	];
	protected readonly tableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	protected directory: DirectoryEntry[] = [];
	protected isSortDown: boolean | undefined;

	private modalService = inject(ModalService);
	private readonly unsortedEntries = [
		{
			label: 'Departments',
			children: [
				{ label: 'Sale' },
				{ label: 'Information Technology' },
				{ label: 'Some department with long text for label' },
				{ label: 'Customer Support' },
			],
		},
		{
			label: 'Localities',
			children: [
				{ label: 'Doonside' },
				{ label: 'Narrabeen' },
				{ label: 'Rooty Hill' },
			],
		},
		{
			label: 'Somewhere',
		},
		{
			label: 'Anywhere but here',
		},
		{
			label: 'Extras',
			children: [
				{
					label: 'Food',
					children: [
						{
							label: 'Savory',
							children: [
								{ label: 'Biscuit' },
								{ label: 'Cheese' },
								{
									label: 'Bread',
									children: [
										{ label: 'White' },
										{ label: 'Brown' },
										{ label: 'Wholemeal' },
									],
								},
							],
						},
						{
							label: 'Sweet',
							children: [
								{ label: 'Cake' },
								{ label: 'Ice cream' },
								{ label: 'Chocolate' },
							],
						},
					],
				},
				{
					label: 'Catering',
				},
			],
		},
	];

	ngOnInit() {
		this.directory = JSON.parse(JSON.stringify(this.unsortedEntries));
	}

	async testClick() {
		await this.modalService.openAlert({ title: 'Click Test', message: 'test a click not triggering collapse' });
	}

	protected onSort() {
		if (this.isSortDown === false) {
			this.isSortDown = undefined;
			this.directory = JSON.parse(JSON.stringify(this.unsortedEntries));

			return;
		}

		this.isSortDown = !this.isSortDown;
		this.directory = [...this.directory.sort(this.sort.bind(this))];
	}

	private sort(a: DirectoryEntry, b: DirectoryEntry): number {

		if (a.children?.length) {
			a.children.sort(this.sort.bind(this));
		}

		if (b.children?.length) {
			b.children.sort(this.sort.bind(this));
		}

		return a.label >= b.label ? (this.isSortDown ? 1 : -1) : (this.isSortDown ? -1 : 1);
	}

}
