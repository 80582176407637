import { Component, inject } from '@angular/core';

import { ModalService } from '@unifii/library/common';
import { ColumnInfo } from 'showcase/src/app/components';

@Component({
	templateUrl: './show-directory.html',

})
export class ShowDirectoryComponent {

	protected readonly directory = [
		{
			label: 'Departments',
			children: [
				{ label: 'Sale' },
				{ label: 'Information Technology' },
				{ label: 'Some department with long text for label' },
				{ label: 'Customer Support' },
			],
		},
		{
			label: 'Localities',
			children: [
				{ label: 'Doonside' },
				{ label: 'Narrabeen' },
				{ label: 'Rooty Hill' },
			],
		},
		{
			label: 'Somewhere',
		},
		{
			label: 'Anywhere but here',
		},
		{
			label: 'Extras',
			children: [
				{
					label: 'Food',
					children: [
						{
							label: 'Savory',
							children: [
								{ label: 'Biscuit' },
								{ label: 'Cheese' },
								{
									label: 'Bread',
									children: [
										{ label: 'White' },
										{ label: 'Brown' },
										{ label: 'Wholemeal' },
									],
								},
							],
						},
						{
							label: 'Sweet',
							children: [
								{ label: 'Cake' },
								{ label: 'Ice cream' },
								{ label: 'Chocolate' },
							],
						},
					],
				},
				{
					label: 'Catering',
				},
			],
		},
	];
	protected readonly tableInfo = [
		{ name: 'light', type: 'modifier', description: 'Sets icon colour to light' },
	];
	protected readonly tableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];

	private modalService = inject(ModalService);

	async testClick() {
		await this.modalService.openAlert({ title: 'Click Test', message: 'test a click not triggering collapse' });
	}

}
