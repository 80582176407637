import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Dictionary, FieldType, FieldWidth, PageField } from '@unifii/sdk';

import { UfControl, ValidatorFunctions } from '@unifii/library/common';

import { Fruit, SCDataService } from '../../services';

@Component({
	selector: 'sc-show-chips-autocomplete',
	templateUrl: './show-chips-autocomplete.html',
	styleUrls: ['./show-chips-autocomplete.less'],
})
export class ShowChipsAutocompleteComponent implements OnInit, OnDestroy {

	protected cv: Dictionary<boolean | number> = {
		disabled: false,
		duplicates: false,
		customs: false,
		required: false,
		minSearchLength: 0,
	};

	protected config: Dictionary<PageField> = {
		required: { label: 'Required', type: FieldType.Bool, value: this.cv.required, width: FieldWidth.Quarter },
		disabled: { label: 'Disabled', type: FieldType.Bool, value: this.cv.disabled, width: FieldWidth.Quarter },
		duplicates: { label: 'Allow duplicates', type: FieldType.Bool, value: this.cv.duplicates, width: FieldWidth.Quarter },
		customs: { label: 'Allow customs', type: FieldType.Bool, value: this.cv.customs, width: FieldWidth.Quarter },
		minSearchLength: { label: 'Min search length', type: FieldType.Number, value: this.cv.minSearchLength, width: FieldWidth.Quarter },
	};

	protected control: UfControl;
	protected complexControl: UfControl;
	protected value: string[] | null;
	protected emittedValue: string[];
	protected results: string[];
	protected complexValue: Fruit[] | null;
	protected complexResults: Fruit[];	
	protected complexEmittedValue: Fruit[];

	private resetTimeout: NodeJS.Timeout | undefined;
	private dataService = inject(SCDataService);

	ngOnInit() {
		this.control = new UfControl(ValidatorFunctions.custom((v) =>
			!this.cv.required || !ValidatorFunctions.isEmpty(v),
		'A value is required'),
		);

		this.complexControl = new UfControl(ValidatorFunctions.custom((v) =>
			!this.cv.required || !ValidatorFunctions.isEmpty(v),
		'A value is required'),
		);
	}

	ngOnDestroy() {
		clearTimeout(this.resetTimeout);
	}

	async find(query?: string) {
		this.results = await this.dataService.asyncSearchFruitNames(query, 1000);
	}

	async findComplex(query?: string) {
		console.log('findComplex', query);
		this.complexResults = await this.dataService.asyncSearchFruits(query, 1000);
	}

	valueChanged(value?: string[]) {
		this.emittedValue = [...(value ?? [])];
	}

	complexValueChanged(value: Fruit[]) {
		console.log('complexValueChanged', value);
		this.complexEmittedValue = value;
	}

	reset() {
		this.value = null;
		clearTimeout(this.resetTimeout);
	}

	protected canDeleteApples = (fruit: string): boolean => fruit.toLowerCase().includes('apple');

}
