export * from './repeat-columns.component';
export * from './repeat-form.component';
export * from './repeat-table-row.component';
export * from './repeat-table.component';
export * from './repeat-cell.component';
export * from './repeat.component';

import { RepeatCellComponent } from './repeat-cell.component';
import { RepeatColumnsComponent } from './repeat-columns.component';
import { RepeatFormComponent } from './repeat-form.component';
import { RepeatTableRowComponent } from './repeat-table-row.component';
import { RepeatTableComponent } from './repeat-table.component';
import { RepeatComponent } from './repeat.component';

export const UF_INPUT_FIELD_REPEAT_COMPONENTS = [
	RepeatColumnsComponent,
	RepeatFormComponent,
	RepeatTableComponent,
	RepeatComponent,
	RepeatTableRowComponent,
	RepeatCellComponent,
];
