import { Inject, Injectable, Optional } from '@angular/core';
import { AstNode, Dictionary, Query, isNotNull, mergeAstNodes } from '@unifii/sdk';

import { FilterAstNodeAdapter, FilterEntries, FilterEntry, FilterManagerProvider, FilterSerializer, FilterValue, HierarchyUnitProvider } from '../../models';

import { UfFilterAstNodeAdapter } from './filter-ast-node-adapter';
import { UfFilterSerializer } from './filter-serializer';

@Injectable()
export class FilterManager<V extends FilterValue, E extends FilterEntry> implements FilterManagerProvider<V, E> {

	private entryMap: Map<string, E> = new Map<string, E>();
	private _serializer: FilterSerializer<V, E>;
	private _filterAstNodeAdapter: FilterAstNodeAdapter<V, E>;

	constructor(
		@Inject(FilterEntries) public entries: E[] = [],
		@Inject(HierarchyUnitProvider) hierarchyUnitProvider: HierarchyUnitProvider,
		@Optional() @Inject(FilterSerializer) private serializer: FilterSerializer<V, E> | null,
		@Optional() @Inject(FilterAstNodeAdapter) filterAstNodeAdapter: FilterAstNodeAdapter<V, E> | null,
	) {
		this._serializer = this.serializer ?? new UfFilterSerializer(hierarchyUnitProvider);
		this._filterAstNodeAdapter = filterAstNodeAdapter ?? new UfFilterAstNodeAdapter();
	}

	add(entry: E | null | undefined) {
		// Guard input
		if (entry == null) {
			console.warn(`FilterConfig: Null entry, add skipped`);

			return;
		}

		// Guard field already registered
		if (this.entryMap.has(entry.identifier)) {
			console.warn(`FilterConfig: identifier '${entry.identifier}' already registered`);

			return;
		}

		this.entryMap.set(entry.identifier, entry);
		this.entries.push(entry);
	}

	remove(identifier: string) {
		// Entry not present
		if (!this.entryMap.has(identifier)) {
			return;
		}

		this.entryMap.delete(identifier);
		this.entries.splice(this.entries.findIndex((entry) => entry.identifier === identifier), 1);
	}

	clean(values: Dictionary<V>): Dictionary<V> {
		for (const identifier of Object.keys(values)) {
			if (values[identifier] == null) {
				// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
				delete values[identifier];
			}
		}

		return values;
	}

	serializeAll(values: Dictionary<V>): Dictionary<string | null> {
		return this._serializer.serializeAll(values, this.entries);
	}

	deserializeAll(values: Dictionary<string | null>): Promise<Dictionary<V>> {
		return this._serializer.deserializeAll(values, this.entries);
	}

	toAstNode(values: Dictionary<V> | null | undefined): AstNode | undefined {

		if (!values) {
			return;
		}

		const nodes = this.entries
			.map((entry) => {
				const value = values[entry.identifier];

				return value ? this._filterAstNodeAdapter.transform(value, entry) : undefined;
			})
			.filter(isNotNull)
			.reduce((result, current) => result.concat(current), []);

		return mergeAstNodes('and', ...nodes);
	}

	toQuery(values: Dictionary<V> | null | undefined): Query {
		const query = new Query();

		return query.fromAst(this.toAstNode(values));
	}

}
