import { Component, Input } from '@angular/core';

import { RuntimeField } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-text-display',
	templateUrl: './text.html',
})
export class TextComponent implements ContentField {

	@Input() content: any;
	@Input() field: RuntimeField;

}
