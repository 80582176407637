<sc-page-content width="lg" identifier="expanders">

	<ng-container editorInputs>
		<uf-radio [columns]="1" [options]="sizeOptions" [(value)]="size" (valueChange)="modifierChange()" class="col-6"
			label="Sizes" valueProperty="value" />
		<uf-radio [columns]="1" [options]="modeOptions" [(value)]="mode" (valueChange)="modifierChange()" class="col-6"
			label="Mode" valueProperty="value" />
	</ng-container>

	<ng-container editor>

		<sc-code-editor-expander [code]="template">

			<ng-container *ngIf="!loading">
				<uf-expander [ngClass]="[size]" [actionBtn]="mode === 'actionBtn'" class="pad"
					style="max-width: 420px; width: 100%; background: white;">
					<div class="uf-expander-header" expanderHeader>
						<span>My Favourite Latin</span>
					</div>
					<p expanderBody>Sed posuere consectetur est at lobortis. Cum sociis natoque penatibus et magnis dis
						parturient montes, nascetur ridiculus mus. Vestibulum id ligula porta felis euismod
						semper. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel
						augue laoreet rutrum faucibus dolor auctor</p>
				</uf-expander>
			</ng-container>

		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>