import { Component, EventEmitter, HostBinding, Injector, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Subject } from 'rxjs';

import { DeviceInfo } from '../../services/device-info';
import { CommonTranslationKey } from '../../translations';

import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-camera-capture',
	templateUrl: './uf-camera-capture.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfCameraCaptureComponent, multi: true,
	}],
	styleUrls: ['./uf-camera-capture.less'],
})
export class UfCameraCaptureComponent extends UfControlValueAccessor<string> {

	@Input() label: string;
	@Output() override valueChange = new EventEmitter();
	@Output() touchedChange = new Subject<boolean>();

	readonly commonTK = CommonTranslationKey;

	focused: boolean;
	entries: any[] = [];
	supported: boolean;

	constructor(injector: Injector, private device: DeviceInfo) {
		super(injector);
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	@HostBinding('class.focused') get focusedClass() {
		return this.focused;
	}
	// TODO check if this is correct, will always fail to emit a value
	override valueEmitPredicate(): boolean {
		return false;
	}

	onClick(event: Event) {

		if (this.device.getPlatform() !== 'android') {
			console.warn('Camera capture not running in an app requires image');

			return;
		}

		event.preventDefault();

		this.takePhoto().then((file) => {
			this.valueChange.emit([file]);
		}, (error) => {
			console.error('Camera failed: ', error);
		});
	}

	private async takePhoto(): Promise<File> {
		const pictureLocation = await this.getPhotoPath();

		return this.getFileEntry(pictureLocation);
	}

	private async getPhotoPath(): Promise<string> {

		const image = await Camera.getPhoto({
			quality: 90,
			allowEditing: false,
			resultType: CameraResultType.Uri,
			source: CameraSource.Camera,
		});

		return image.webPath ?? '';

	}

	private async getFileEntry(uri: string): Promise<File> {

		const filename = uri.substring(uri.lastIndexOf('/') + 1);

		const res = await fetch(uri);

		return this.getPictureFile(await res.blob(), filename);
	}

	private getPictureFile(file: Blob, name: string): File {
		return new File([file], name, { type: file.type }); /** Add file name */
	}

}
