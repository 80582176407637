export * from './show-autocomplete.component';
export * from './show-chips-autocomplete.component';
export * from './show-client.component';
export * from './show-collection-item.component';
export * from './show-control.component';
export * from './show-editor.component';
export * from './show-empty.component';
export * from './show-encoding-funcs.component';
export * from './show-fields.component';
export * from './show-form-inputs.component';
export * from './show-form.component';
export * from './show-inputs.component';
export * from './show-lab-content.component';
export * from './show-lab.component';
export * from './show-modal-sample.component';
export * from './show-modal-testing.component';
export * from './show-page.component';
export * from './show-popups.component';
export * from './show-search-result.component';
export * from './show-search.component';
export * from './show-select.component';
export * from './show-survey.component';
export * from './show-table.component';
export * from './show-time.component';
export * from './show-view.component';
export * from './data-descriptor/show-data-descriptor-permissions';
export * from './data-descriptor/show-data-descriptor.component';
export * from './data-display/show-data-display-entity.component';
export * from './data-display/show-data-type-detector.component';
export * from './data-display/show-form-data-display.component';
export * from './data-display/show-table-item-detail.component';
export * from './filters/show-filters.component';

import { ShowDataDescriptorComponent } from './data-descriptor/show-data-descriptor.component';
import { ShowDataItemDescriptorOptionsComponent } from './data-descriptor/show-data-item-descriptor-options.component';
import { ShowDataDisplayEntityComponent } from './data-display/show-data-display-entity.component';
import { ShowDataTypeDetectorComponent } from './data-display/show-data-type-detector.component';
import { ShowFormDataDisplayComponent } from './data-display/show-form-data-display.component';
import { ShowTableEntityComponent } from './data-display/show-table-entity.component';
import { ShowTableItemDetailComponent } from './data-display/show-table-item-detail.component';
import { ShowFiltersComponent } from './filters/show-filters.component';
import { ShowAutocompleteComponent } from './show-autocomplete.component';
import { ShowChipsAutocompleteComponent } from './show-chips-autocomplete.component';
import { ShowClientComponent } from './show-client.component';
import { ShowCollectionItemComponent } from './show-collection-item.component';
import { ShowControlComponent } from './show-control.component';
import { ShowEditorComponent } from './show-editor.component';
import { ShowEmptyComponent } from './show-empty.component';
import { ShowEncodingFuncsComponent } from './show-encoding-funcs.component';
import { ShowFieldsComponentsComponent } from './show-fields.component';
import { ShowFormInputsComponent } from './show-form-inputs.component';
import { ShowFormComponent } from './show-form.component';
import { ShowInputsComponent } from './show-inputs.component';
import { ShowLabContentComponent } from './show-lab-content.component';
import { ShowLabComponent } from './show-lab.component';
import { ShowModalSampleComponent } from './show-modal-sample.component';
import { ShowModalTestingComponent } from './show-modal-testing.component';
import { ShowPageComponent } from './show-page.component';
import { ShowPopupsComponent } from './show-popups.component';
import { ShowReactiveFormsComponent } from './show-reactive-forms.component';
import { ShowSearchResultComponent } from './show-search-result.component';
import { ShowSearchComponent } from './show-search.component';
import { ShowSelectComponent } from './show-select.component';
import { ShowShowcaseTemplateComponent } from './show-showcase-template.component';
import { ShowSurveyComponent } from './show-survey.component';
import { ShowTableComponent } from './show-table.component';
import { ShowLabTimeComponent } from './show-time.component';
import { ShowViewComponent } from './show-view.component';

export const SC_PAGES_LAB_COMPONENTS = [
	ShowLabComponent, ShowEditorComponent, ShowClientComponent, ShowFieldsComponentsComponent,
	ShowDataDescriptorComponent, ShowDataItemDescriptorOptionsComponent, ShowDataDisplayEntityComponent,
	ShowDataTypeDetectorComponent, ShowTableItemDetailComponent,
	ShowModalSampleComponent, ShowPageComponent, ShowLabContentComponent, ShowModalTestingComponent,
	ShowPopupsComponent, ShowControlComponent, ShowAutocompleteComponent, ShowChipsAutocompleteComponent,
	ShowFormComponent, ShowEmptyComponent, ShowViewComponent,
	ShowCollectionItemComponent, ShowInputsComponent, ShowSelectComponent, ShowTableComponent,
	ShowLabTimeComponent, ShowFormInputsComponent, ShowSearchResultComponent, ShowSearchComponent,
	ShowEncodingFuncsComponent, ShowSurveyComponent, ShowShowcaseTemplateComponent,
	ShowReactiveFormsComponent, ShowFiltersComponent, ShowFormDataDisplayComponent, ShowTableEntityComponent,
];
