import { Component, OnInit } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-badges.html',
})
export class ShowBadgesComponent implements OnInit {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;
	readonly templateSource = `
		<div class="uf-badge visible#level">
			+100
		</div>
		<button type="button" class="uf-action">
			<uf-icon name="mail"></uf-icon>
			<uf-icon name="warning" class="uf-badge#level" [class.visible]="count > 0"></uf-icon>
		</button>
		<button type="button" class="uf-action tertiary">
			<uf-icon name="mail"></uf-icon>
			<div class="uf-badge#level" [class.visible]="count > 0">
				{{ count }}
			</div>
		</button>
	`;

	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'uf-badge', type: 'base class', description: 'Sets base style of badge' },
		{ name: 'visible', type: 'modifier', description: 'Makes badge visible' },
		{ name: 'secondary', type: 'modifier', description: 'Changes colour to secondary colour' },
	];

	// Interactive
	readonly visiblityOptions = [
		{ name: 'Hidden (default)', value: '' },
		{ name: 'Visible', value: 'visible' },
	];
	readonly levelOptions = [
		{ name: 'Primary (default)', value: '' },
		{ name: 'Secondary', value: 'secondary' },
	];
	readonly sizeOptions = [
		{ name: 'Medium (default)', value: '' },
		{ name: 'Small', value: 'small' },
	];

	size = '';
	level = '';
	visible = true;
	template: string;

	ngOnInit() {
		this.modifierChange();
	}

	modifierChange() {
		this.template = this.templateSource.replace(/#level/g, ' ' + this.level);
	}

}
