<label [class.active]="value">
	<input [formControl]="control" [attr.id]="id" [attr.name]="name" [attr.tabindex]="disabled ? -1 : 0"
		(focus)="focused = true" (blur)="focused = false; touchedChange.next(true)" (click)="userClick($event)"
		type="checkbox" />
	<!-- TODO look into why blur event is firing on every click -->
	<div class="switch-container">
		<div class="switch-track"></div>
		<div class="switch"></div>
	</div>
	<span *ngIf="label">{{label}}</span>
</label>
<ng-content></ng-content>
<uf-error [control]="control" />