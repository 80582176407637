import { DataSeed } from '@unifii/sdk';

import { DataSourceLoader, SourceConfigCollection, sortByDisplay } from '@unifii/library/common';

import { Fruit, SCDataService } from './data.service';

export class SCFakeLoader implements DataSourceLoader {

	constructor(public sourceConfig: SourceConfigCollection, private dataService: SCDataService) { }

	getOptions(): Promise<DataSeed[]> {
		return this.lookUpFake(this.itemToDataSeed);
	}

	search(query?: string): Promise<DataSeed[]> {
		return this.lookUpFake(this.itemToDataSeed, query);
	}

	async findAllBy(match: string): Promise<DataSeed[]> {

		if (!this.sourceConfig.findBy) {
			console.warn('Datasource has not set findBy, falling back on search');

			return this.search(match);
		}

		return (await this.findByFake(this.itemToDataSeed, this.sourceConfig.findBy, match)).sort(sortByDisplay);
	}

	async get(id: string): Promise<DataSeed | null> {

		const item = await this.dataService.getFruit(parseInt(id));

		if (!item) {
			return null;
		}

		return this.itemToDataSeed(item);
	}

	mapToSeed(fruit: Fruit): DataSeed | null {

		if (!fruit) {
			return null;
		}

		return this.itemToDataSeed(fruit);
	}

	private async findByFake(mapper: (item: Fruit) => DataSeed, identifier: string, match: string): Promise<DataSeed[]> {
		const items = await this.dataService.asyncFindByFruits(identifier, match);

		return items.map((i) => mapper(i));
	}

	private async lookUpFake(mapper: (item: Fruit) => DataSeed, search?: string): Promise<DataSeed[]> {
		const items = await this.dataService.asyncSearchFruits(search);

		return items.map((i) => mapper(i));
	}

	private itemToDataSeed = (item: Fruit): DataSeed => ({
		_id: `${item.id}`,
		_display: item.name,
		value: item.rating,
	});

}
