import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { hasLengthAtLeast } from '@unifii/sdk';
import { Subscription, interval } from 'rxjs';

import { CarouselPageComponent } from './carousel-page.component';

@Component({
	selector: 'uf-carousel',
	templateUrl: './carousel.html',
	styleUrls: ['./carousel.less'],
})
export class CarouselComponent implements OnDestroy, AfterViewInit {

	/**
	 * Time in ms to automatically transition through pages
	 */
	@Input() set interval(v: number | undefined | null) {

		if (v === this._interval) {
			return;
		}

		this._interval = v;
		this.timerSub?.unsubscribe();
		this.startTimer();
	}

	/**
	 * Show errors on pages not viewed
	 */
	@Input() showError = false;

	/**
	 * Require all pages to be viewed
	 */
	@Input() required = false;

	/**
	 * Emit once all pages have been viewed in required mode
	 */
	@Output() viewed = new EventEmitter<boolean>();

	protected set activeIx(v: number) {
		if (v === this._activeIx) {
			return;
		}

		const previousActivePage = this.pages[this._activeIx];

		if (previousActivePage) {
			previousActivePage.isActive = false;
		}

		this._activeIx = v;

		const nextActivePage = this.pages[this._activeIx];

		if (nextActivePage) {
			nextActivePage.isActive = true;
		}
	}

	protected get activeIx(): number {
		return this._activeIx;
	}

	protected pages: CarouselPageComponent[] = [];
	protected viewedMap = new Map<number, boolean>();

	private timerSub: Subscription | null;
	private _interval: number | undefined | null;
	private _activeIx = 0;

	ngOnDestroy() {
		this.timerSub?.unsubscribe();
	}

	ngAfterViewInit() {
		if (hasLengthAtLeast(this.pages, 1)) {
			this.pages[0].isActive = true;

			if (this.required) {
				this.viewedMap.set(0, true);
				this.checkViewed();
			}
		}
	}

	register(page: CarouselPageComponent) {
		if (this.pages.includes(page)) {
			console.warn('CarouselComponent.register - duplicate registration of CarouselPageComponent instance');

			return;
		}

		this.pages.push(page);
	}

	next() {
		const pageIx = (this.activeIx + 1) % this.pages.length;

		this.activate(pageIx);
	}

	back() {
		const pageIx = (this.activeIx - 1 + this.pages.length) % this.pages.length;

		this.activate(pageIx);
	}

	protected activate(pageIx: number) {
		this.timerSub?.unsubscribe();

		this.activeIx = pageIx;

		if (this.required) {
			this.viewedMap.set(pageIx, true);
			this.checkViewed();

		}

		this.startTimer();
	}

	private timerNext() {
		this.activeIx = (this.activeIx + 1) % this.pages.length;
	}

	private startTimer() {
		if (this._interval) {
			this.timerSub = interval(this._interval).subscribe(() => { this.timerNext(); });
		}
	}

	private checkViewed() {
		if (this.viewedMap.size < this.pages.length) {
			return;
		}

		this.viewed.emit(true);
	}

}
