import { Injectable, Type } from '@angular/core';
import { FieldType } from '@unifii/sdk';

import { ComponentRegistryType, FormComponentRegistryOptions, FormField } from '@unifii/library/smart-forms';
import { FieldMessageComponent, SignaturePrintComponent } from '@unifii/library/smart-forms/display';

import { UfFormComponentRegistry } from '../../services';
import { GroupComponent } from '../fields';

@Injectable()
export class UfPrintFormComponentRegistry extends UfFormComponentRegistry {

	override type = ComponentRegistryType.Input;

	override get(name: string, options?: FormComponentRegistryOptions): Type<FormField> {

		switch (name as FieldType) {
			case FieldType.Signature: return SignaturePrintComponent;
			case FieldType.Content: return FieldMessageComponent;
			case FieldType.Step:
			case FieldType.Stepper: return GroupComponent;
			default: return super.get(name, options);
		}
	}

}
