export * from './progress.component';
export * from './spinner.component';
export * from './password-indicator.component';
export * from './skeleton.component';
export * from './directory-indicator.component';

import { DirectoryIndicatorComponent } from './directory-indicator.component';
import { PasswordIndicatorComponent } from './password-indicator.component';
import { ProgressComponent } from './progress.component';
import { SkeletonComponent } from './skeleton.component';
import { SpinnerComponent } from './spinner.component';

export const UF_INDICATOR_COMPONENTS = [ProgressComponent, SpinnerComponent, PasswordIndicatorComponent, SkeletonComponent, DirectoryIndicatorComponent];
