import { Component, Injector, Input, Optional } from '@angular/core';

import { RuntimePage } from '@unifii/library/common';
import { ComponentRegistry } from '@unifii/library/smart-forms';

import { PageComponentRegistry, UfPageComponentRegistry } from '../../services';

export const pageComponentRegistryFactory = (pageComponentRegistry: ComponentRegistry | null): ComponentRegistry => {

	if (!pageComponentRegistry) {
		// Create default registry
		return Injector.create({
			providers: [{ provide: UfPageComponentRegistry, useClass: UfPageComponentRegistry }],
		}).get(UfPageComponentRegistry);
	}

	return pageComponentRegistry;
};

@Component({
	selector: 'uf-page',
	templateUrl: './page.html',
	styleUrls: ['./page.less'],
	providers: [{ provide: ComponentRegistry, useFactory: pageComponentRegistryFactory, deps: [[new Optional(), PageComponentRegistry]] }],
})
export class UfPageComponent {

	@Input() page: RuntimePage;

	@Input() set classList(v: string[]) {
		this._classList = v;
	}

	get classList(): string[] {
		return this._classList;
	}

	private _classList = ['page-wrap', 'body-copy'];

}
