import { ChangeDetectorRef, Compiler, Component, ElementRef, HostBinding, Input, NgModule, NgModuleFactory, OnInit, Type } from '@angular/core';
import { RouterModule } from '@angular/router';

import { DisplayModule } from '@unifii/library/smart-forms/display';
import { InputModule } from '@unifii/library/smart-forms/input';

@Component({
	selector: 'sc-dynamic',
	templateUrl: './show-dynamic.html',
})
export class ShowDynamicComponent implements OnInit {

	elementClasses: string;
	ready: boolean;
	error: boolean;
	dynamicComponent: Type<any> | null;
	dynamicModule: NgModuleFactory<any> | null;

	@Input() set template(v: string) {
		if (v !== this._template && this.renderComponent) {
			this._template = v;
			this.renderComponent();
		} else {
			this._template = v;
		}
	}

	get template() {
		return this._template;
	}

	private _template: string;

	constructor(
		private compiler: Compiler,
		private cdr: ChangeDetectorRef,
		private element: ElementRef,
	) { }

	ngOnInit() {
		this.elementClasses = this.element.nativeElement.classList.value;
		this.element.nativeElement.className = '';
		this.ready = true;
		this.renderComponent();
	}

	renderComponent() {

		this.error = false;
		this.dynamicComponent = null;
		this.dynamicModule = null;
		this.cdr.detectChanges();

		try {
			this.dynamicModule = this.compiler.compileModuleSync(this.createAll(this.template, this.elementClasses));
			this.cdr.detectChanges();
		} catch (e) {
			console.error(e);
			this.error = true;
		}
	}

	// Remove usage of the _template if need to build with AoT
	protected createAll(_template = '', classes = '') {

		// console.log(`Dynamic template "${_template}"`);

		@Component({ // _template - restore for non AoT execution
			selector: 'sc-dynamic-component',
			template: `<span>Replaced dynamic template content</span>`,
		})
		class DynamicTemplateComponent {

			@HostBinding('class') cls = classes;

		}

		this.dynamicComponent = DynamicTemplateComponent;

		@NgModule({
			imports: [RouterModule, InputModule, DisplayModule],
			declarations: [DynamicTemplateComponent],
		})

		class RuntimeComponentModule { }

		// a module for just this Type
		return RuntimeComponentModule;
	}

}
