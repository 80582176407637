import { Component } from '@angular/core';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-container.html',
})
export class ShowContainerComponent {

	readonly options = [
		{ name: 'Small', value: 'uf-container-sm' },
		{ name: 'Medium', value: 'uf-container-md' },
		{ name: 'Large', value: 'uf-container-lg' },
		{ name: 'Extra Large', value: 'uf-container-xl' },
		{ name: 'Maximum (default)', value: 'uf-container' },
	];
	readonly tableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly tableInfo = [
		{ name: 'uf-container', type: 'base', description: 'Centers content horizontally width a max width' },
		{ name: 'uf-container-sm', type: 'modifier', description: 'Sets max width to small break point' },
		{ name: 'uf-container-md', type: 'modifier', description: 'Sets max width to medium break point' },
		{ name: 'uf-container-lg', type: 'modifier', description: 'Sets max width to large break point' },
		{ name: 'uf-container-xl', type: 'modifier', description: 'Sets max width to extra large break point' },
	];
	readonly breakPointTableConfig: ColumnInfo[] = [
		{ identifier: 'breakpoint', name: 'Breakpoint' },
		{ identifier: 'value', name: 'Value (px/rem)' },
		{ identifier: 'columns', name: 'Columns' },
		{ identifier: 'padding', name: 'Padding (px/rem)' },
	];
	readonly breakPointTableInfo = [
		{ breakpoint: 'Small', value: '320 / 20', columns: '4', padding: '0' },
		{ breakpoint: 'Medium', value: '672 / 42', columns: '8', padding: '0' },
		{ breakpoint: 'Large', value: '1056 / 66', columns: '16', padding: '16 / 1' },
		{ breakpoint: 'X-Large', value: '1312 / 82', columns: '16', padding: '16 / 1' },
		{ breakpoint: 'Max', value: '1584 / 99', columns: '16', padding: '16 / 1' },
	];

	template = `
		<div class="#class">
		</div>
		`;
	className = 'uf-container';

	modifierChange() {
		this.template = this.template.replace(`#class`, this.className);
	}

}
