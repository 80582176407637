import { Component, OnInit, inject } from '@angular/core';

import { ColumnGap, Size, ToastService, UfControl } from '@unifii/library/common';
import { WorldDataValue } from 'showcase/src/app/services';

import { ShowCascadeSelectionDataSource } from './show-cascade-selection-data-source';

@Component({
	selector: 'sc-cascade-selection',
	templateUrl: './show-cascade-selection.html',
})
export class ShowCascadeSelectionComponent implements OnInit {

	protected readonly sizes = Size;
	protected readonly columnGaps = ColumnGap;
	protected dataSource = inject(ShowCascadeSelectionDataSource);
	protected valueChanged: string | undefined;
	protected value: (WorldDataValue | WorldDataValue[])[] | null;
	protected control: UfControl | undefined;
	protected ready = false;

	private toastService = inject(ToastService);

	async ngOnInit() {
		
		const useControl = Math.random() > 0;
		const value = await this.dataSource.generateRandomValue(0);

		if (useControl) {
			this.control = new UfControl();
			this.control.setValue(value);
		} else {
			this.value = value;
		}

		this.ready = true;
	}

	protected onValueChange(value: (WorldDataValue | WorldDataValue[])[] | null) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		this.valueChanged = value?.map((v) => Array.isArray(v) ? v.map((single) => single.name).join(' | ') : v.name).join(' > ');
		this.toastService.info(this.valueChanged ?? `'null value'`);
	}

	protected async setValue(value?: (WorldDataValue | WorldDataValue[])[] | null) {
		if (value === undefined) {
			value = await this.dataSource.generateRandomValue();
		}

		if (this.control) {
			this.control.setValue(value);
		} else {
			this.value = value;
		}
	}

}
