<uf-data-display-list *ngIf="content">
	<label>{{smartFormsTK.AddressInputFieldLineOneLabel | translate}}:</label>
	<div class="value">{{content.address1}}</div>
	<label>{{smartFormsTK.AddressInputFieldLineTwoLabel | translate}}:</label>
	<div class="value">{{content.address2}}</div>
	<label>{{smartFormsTK.AddressInputFieldSuburbLabel | translate}}:</label>
	<div class="value">{{content.suburb}}</div>
	<label>{{smartFormsTK.AddressInputFieldCityLabel | translate}}:</label>
	<div class="value">{{content.city}}</div>
	<label>{{smartFormsTK.AddressInputFieldStateLabel | translate}}:</label>
	<div class="value">{{content.state}}</div>
	<label>{{smartFormsTK.AddressInputFieldPostcodeLabel | translate}}:</label>
	<div class="value">{{content.postcode}}</div>
	<label>{{smartFormsTK.AddressInputFieldCountryLabel | translate}}:</label>
	<div class="value">{{content.country}}</div>
	<label>{{smartFormsTK.GeoLocationInputFieldLatitudeLabel | translate}}:</label>
	<div class="value">{{content.lat}}</div>
	<label>{{smartFormsTK.GeoLocationInputFieldLongitudeLabel | translate}}:</label>
	<div class="value">{{content.lng}}</div>
	<label>{{smartFormsTK.GeoLocationInputFieldZoomLabel | translate}}:</label>
	<div class="value">{{content.zoom}}</div>
</uf-data-display-list>