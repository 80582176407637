<uf-expander *ngIf="field" [actionBtn]="true" [(isExpanded)]="contentExpanded">
	<div [class.small]="isSummary && control.disabled" [class.error]="control.submitted && control.invalid"
		expanderHeader class="uf-app-bar medium">
		<uf-icon *ngIf="field.icon" [name]="field.icon" />
		<div class="title">
			{{ label }}
			<uf-help *ngIf="field?.help" [content]="field.help" icon="infoSolid" class="grow" />
		</div>
	</div>
	<div expanderBody class="grid">
		<ng-template [ngForOf]="field.fields" let-child ngFor>
			<ng-template [ngIf]="isVisible(child)">
				<ng-template [ngIf]="child.identifier" [ngIfElse]="nonContent">
					<uf-field [field]="child" [scope]="scope" [(content)]="scope[child.identifier]" />
				</ng-template>
				<ng-template #nonContent>
					<uf-field [field]="child" [scope]="scope" />
				</ng-template>
				<uf-break-after *ngIf="child?.breakAfter" />
			</ng-template>
		</ng-template>
	</div>
</uf-expander>