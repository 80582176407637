import { Component, EventEmitter, HostBinding, Input, Output, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GeoLocation } from '@unifii/sdk';

import { LocationProvider, LocationProviderType } from '../../models';
import { SharedTermsTranslationKey } from '../../translations';

import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-map-input',
	templateUrl: './uf-map-input.html',
	styleUrls: ['../map/uf-map.less', './uf-map-input.less'],
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfMapInputComponent, multi: true,
	}],
})
export class UfMapInputComponent extends UfControlValueAccessor<GeoLocation> {

	@Input() disableClear?: boolean;
	@Output() override valueChange = new EventEmitter<GeoLocation>();

	protected readonly locationType = LocationProviderType;
	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected locked = true;
	protected locationProvider = inject(LocationProvider);

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled || this.control.disabled;
	}

	protected markerChange(location: GeoLocation) {

		if (!this.control.dirty) {
			this.control.markAsDirty();
		}

		if (!this.control.touched) {
			/**
			 * // TODO control should be marked as touched when user triggers a blur event
			 * to get more accurate indication of this we will have to listen to the map blur event
			 * and each button blur event. For the current requirements this should get us close enough
			 */
			this.control.markAsTouched();
		}
		this.control.setValue(location);
	}

}
