import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { MenuNode, ShowcaseNavigationService } from '../services';

@Component({
	selector: 'sc-menu',
	templateUrl: './show-menu.html',
	styleUrls: ['./show-menu.less'],
})
export class MenuComponent implements OnDestroy {

	@Input() img: string;
	@Output() toggleMenu = new EventEmitter();

	protected tree: MenuNode[] = [];

	private navigationEndSubscription?: Subscription;

	constructor(
		private nav: ShowcaseNavigationService,
		private router: Router,
	) {
		this.tree = this.nav.nodes;

		this.navigationEndSubscription = this.router.events.pipe(
			filter((e) => e instanceof NavigationEnd))
			.subscribe(() => {

				if (this.nav.activeNode != null) {
					this.expandParentNode(this.nav.activeNode);
				}
			});
	}

	ngOnDestroy() {
		this.navigationEndSubscription?.unsubscribe();
	}

	private expandParentNode(node: MenuNode) {

		const parent = this.nav.getParent(node);

		if (parent != null) {
			parent.expanded = true;
			this.expandParentNode(parent);
		}
	}

}
