import { ChangeDetectorRef, Component, ViewChild, inject } from '@angular/core';
import { Client, ColumnDescriptor, CompaniesClient, Company, FormData, FormDataClient, ProjectContentOptions, ProjectContentOptionsInterface, TableSourceType, UserInfo, UsersClient, isNotNull } from '@unifii/sdk';

import { ColumnDisplayDescriptor, DataDisplayInfo, DataDisplayListItem, DataDisplayService, DataDisplaySpecificEntitiesService, DataDisplaySpecificEntitySinglePropertyInfo, DataDisplayTableComponentRegistry, DataLookupService, DataPropertyDescriptor, SEPARATOR_COMMA, TableComponent, TableConfig, TableConfigColumn, TableDataSource, UfDataDisplayContentTableComponentRegistry, columnDescriptorToColumDisplayDescriptor, dataDescriptorToDataDisplayInfo, getDefaultTableConfig, getTableCustomColumnsDisplayDescriptors, isSortableColumn } from '@unifii/library/common';
import { EntityPickerOutput, EntityPickerTableOutput } from 'showcase/src/app/components';

import { ShowEntityCompaniesDataSource } from './show-entity-companies-datasource';
import { ShowEntityFormDataDataSource } from './show-entity-form-data-repository-datasource';
import { ShowEntityUsersDataSource } from './show-entity-users-datasource';

type TableData = FormData | UserInfo | Company;

@Component({
	selector: 'sc-show-table-entity',
	templateUrl: './show-table-entity.html',
	providers: [
		{ provide: DataDisplayTableComponentRegistry, useClass: UfDataDisplayContentTableComponentRegistry },
	],
})
export class ShowTableEntityComponent {

	@ViewChild(TableComponent)
	protected tableComponent: TableComponent<TableData> | undefined;
	protected info: EntityPickerTableOutput | undefined;
	protected error: Error | undefined;
	protected items: DataDisplayListItem[] | undefined;
	protected tableConfig: TableConfig<TableData> | undefined;
	protected datasource: TableDataSource<TableData> | undefined;
	protected customColumns: ColumnDisplayDescriptor[] = [];
	
	private dataLookupService = inject(DataLookupService);
	private dataDisplayService = inject(DataDisplayService);
	private dataDisplayEntityService = inject(DataDisplaySpecificEntitiesService);
	private client = inject(Client);
	private usersClient = inject(UsersClient);
	private companiesClient = inject(CompaniesClient);
	private projectContentOptions = inject(ProjectContentOptions) as ProjectContentOptionsInterface;
	private cdr = inject(ChangeDetectorRef);
	
	protected onPickedInfo(info: EntityPickerOutput | undefined) {
		this.info = info?.type === 'table' ? info as unknown as EntityPickerTableOutput : undefined;
		this.tableComponent = undefined;
		this.datasource = undefined;
		this.cdr.detectChanges();
		this.render();
	}

	private render() {
		const info = this.info;
		
		if (!info) {
			return;
		}
		
		this.customColumns = getTableCustomColumnsDisplayDescriptors(info.table.columns);

		const columns = info.table.columns?.map((column) => {
			const propertyDescriptor = info.dataDescriptor.propertyDescriptorsMap.get(column.identifier);

			if (propertyDescriptor && !propertyDescriptor.asDisplay) {
				return;
			}
			
			const cellDisplayDescriptor = columnDescriptorToColumDisplayDescriptor(column);

			const columnConfig: TableConfigColumn<TableData> = {
				name: column.identifier,
				label: column.heading ?? propertyDescriptor?.label,
				sortable: propertyDescriptor?.asSort && isSortableColumn(cellDisplayDescriptor),
			};

			if (!propertyDescriptor) {
				return columnConfig;
			}

			columnConfig.value = (row) =>
				this.getColumnDisplayValue(info.table.sourceType, row, column, propertyDescriptor);

			return columnConfig;
		}).filter(isNotNull) ?? [];

		this.tableConfig = getDefaultTableConfig(columns);

		switch (info.table.sourceType) {
			case TableSourceType.Users:
				this.datasource = new ShowEntityUsersDataSource(this.usersClient, info.table.filter) as TableDataSource<TableData>;
				break;
			case TableSourceType.Company:
				this.datasource = new ShowEntityCompaniesDataSource(this.companiesClient, info.table.filter) as TableDataSource<TableData>;
				break;
			case TableSourceType.Bucket:
				this.datasource = new ShowEntityFormDataDataSource(new FormDataClient(
					this.client, {
						projectId: this.projectContentOptions.projectId,
						bucket: info.table.source ?? '',
						preview: false,
					}),
				info.table.filter,
				) as TableDataSource<TableData>;
				break;
		}
	}

	private getColumnDisplayValue(entityType: TableSourceType, row: TableData, columnDescriptor: ColumnDescriptor, propertyDescriptor: DataPropertyDescriptor): unknown {

		const overrideDataDisplayInfo = {
			format: columnDescriptor.defaultFormat,
			template: columnDescriptor.itemTemplate,
			separator: SEPARATOR_COMMA,
		} as Partial<DataDisplayInfo>;

		const entitySingleDisplayPropertyInfo: DataDisplaySpecificEntitySinglePropertyInfo = {
			overrideDataDisplayInfo,
		};

		switch (entityType) {
			case TableSourceType.Users: {
				return this.dataDisplayEntityService.displayUserPropertyAsString(
					row as UserInfo,
					propertyDescriptor,
					entitySingleDisplayPropertyInfo,
				);
			}
			case TableSourceType.Company: {
				return this.dataDisplayEntityService.displayCompanyPropertyAsString(
					row as Company,
					propertyDescriptor,
					entitySingleDisplayPropertyInfo,
				);
			}
			case TableSourceType.Bucket: {
				const value = this.dataLookupService.lookupData(row as FormData, columnDescriptor.identifier);
				const displayInfo = dataDescriptorToDataDisplayInfo(propertyDescriptor, overrideDataDisplayInfo);

				return this.dataDisplayService.displayAsDataDisplayValue(value, displayInfo);
			}
		}

	}

}
