import { ShowBodyCopyComponent } from './show-body-copy.component';
import { ShowBordersComponent } from './show-borders.component';
import { ShowDeviceComponent } from './show-device.component';
import { ShowPaletteComponent } from './show-palette.component';
import { ShowShadowsComponent } from './show-shadows.component';
import { ShowSpacingComponent } from './show-spacing.component';
import { ShowTypographyComponent } from './show-typography.component';
import { ShowWritingStyleComponent } from './show-writing-style.component';

export * from './show-borders.component';
export * from './show-palette.component';
export * from './show-shadows.component';
export * from './show-spacing.component';
export * from './show-typography.component';
export * from './show-body-copy.component';
export * from './show-writing-style.component';
export * from './show-device.component';

export const SC_PAGE_FOUNDATION_COMPONENTS = [
	ShowBordersComponent, ShowPaletteComponent, ShowShadowsComponent,
	ShowSpacingComponent, ShowTypographyComponent, ShowWritingStyleComponent, ShowBodyCopyComponent, ShowDeviceComponent,
];
