<div class="uf-form-card col-12">
	<div class="uf-grid pad gaps">
		<!-- Entity Type -->
		<uf-select *ngIf="!type" [options]="entityTypes" [(value)]="entityType" (valueChange)="onEntityTypeChange()"
			label="Entity" nameProperty="name" valueProperty="identifier" placeholder="Select an entity type"
			class="col-4" />

		<!-- Form Data Repositories -->
		<uf-autocomplete *ngIf="entityType === 'bucket' && buckets" [options]="bucketOptions"
			[ngClass]="{'col-12': !!type, 'col-8': !type }" [(value)]="bucketSelected"
			(searchChange)="searchFormDataRepository($event)" placeholder="Select a form data repository"
			label="Form Data Repository" />

		<!-- Tables -->
		<uf-autocomplete *ngIf="entityType === 'table' && tables" [options]="tableOptions"
			[ngClass]="{'col-12': !!type, 'col-8': !type }" [(value)]="tableSelected"
			(searchChange)="searchTable($event)" nameProperty="title" placeholder="Select a table" label="Table" />

		<!-- Collections -->
		<!-- <uf-autocomplete *ngIf="entityType === 'Collection' && collections" [options]="collectionOptions"
			[(value)]="collectionSelected" (valueChange)="onEntityTypeChange()"
			(searchChange)="searchCollection($event)" label="Select collection" nameProperty="name" class="col-8" /> -->

		<ng-template [ngIf]="pickData">
			<!-- Data: User -->
			<uf-autocomplete *ngIf="entityType === 'user'" [options]="userOptions" [(value)]="userSelected"
				(searchChange)="searchUser($event)" label="User" placeholder="Select a user" nameProperty="__label"
				class="col-12" />

			<!-- Data: Company -->
			<uf-autocomplete *ngIf="entityType === 'company'" [options]="companyOptions" [(value)]="companySelected"
				(searchChange)="searchCompany($event)" label="Company" placeholder="Select a company"
				nameProperty="__label" class="col-12" />

			<!-- Data: FormData -->
			<uf-autocomplete *ngIf="entityType === 'bucket' && bucketSelected" [options]="formDataOptions"
				[(value)]="formDataSelected" (searchChange)="searchFormData($event)"
				placeholder="Select a form data (search by id)" label="Form Data" nameProperty="__label"
				class="col-12" />

			<!-- Data: Table -->
			<uf-autocomplete *ngIf="entityType === 'table' && tableSelected" [options]="tableDataOptions"
				[(value)]="tableDataSelected" (searchChange)="searchTableData($event)" label="Row"
				nameProperty="__label" placeholder="Select a table row" class="col-12" />
		</ng-template>
	</div>
	<div class="uf-form-actions">
		<button [disabled]="!canEmit" (click)="go()" class="uf-button" type="button">Go</button>
	</div>
</div>