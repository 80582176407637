<sc-page-content width="lg" identifier="date">

	<ng-container editorInputs>
		<uf-radio [options]="sizeOptions" [(value)]="size" (valueChange)="modifierChange()" class="col-12" label="Sizes"
			columns="1" valueProperty="value" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template">
			<uf-date [ngClass]="size" [(value)]="value" label="Date" />
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">Inputs</h3>
		<sc-info-table [columnConfig]="inputTableConfig" [rows]="inputTableInfo" />
		<h3 class="pad-sides">Outputs</h3>
		<sc-info-table [columnConfig]="outputTableConfig" [rows]="outputTableInfo" />
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>