import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { Option } from '@unifii/sdk';

import { ModalService } from '../../../services';
import { CommonTranslationKey, SharedTermsTranslationKey } from '../../../translations';
import { Modal } from '../modal';
import { ModalRuntime } from '../modal-runtime';
import { ModalConfirmData, ModalData } from '../modal-types';

export interface SingleChoiceListModalData extends ModalConfirmData {
	options: Option[];
}

/**
 * @description
 * A modal dialog that display a set of options as a List.
 * Return the selected option or null if no selection has been made.
 */
@Component({
	selector: 'uf-single-choice-list-modal',
	templateUrl: './single-choice-list-modal.html',
})
export class SingleChoiceListModalComponent implements Modal<SingleChoiceListModalData, Option>, OnInit {

	@HostBinding('class.uf-form-card') formCardClass = true;

	runtime = inject<ModalRuntime<SingleChoiceListModalData, Option>>(ModalRuntime);
	data: SingleChoiceListModalData;

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;

	private modalService = inject(ModalService);
	private injectedData = inject<SingleChoiceListModalData>(ModalData);

	ngOnInit() {
		this.data = Object.assign(
			{},
			this.modalService.defaultModalConfirmData,
			{ title: undefined, message: undefined } satisfies ModalConfirmData,
			this.injectedData,
		);
	}

	close() {
		this.runtime.close();
	}
	
	protected confirm(option: Option) {
		this.runtime.close(option);
	}

}
