<div class="grid--fixed">
	<div class="col-1of1 body-copy">
		<h2>Form Input</h2>

		<sc-block title="Play with field" collapsable="true">

			<div class="col-1of1 row">
				<uf-checkbox [(value)]="initialData" label="Data" />
			</div>

			<div class="col-1of1">
				<button *ngIf="type" (click)="changeType()" class="uf-button" type="button">{{type}} - Reset</button>
				<uf-select *ngIf="!type" [options]="types" [value]="type" (valueChange)="changeType($event)"
					label="Field" placeholder="Select a type" />
			</div>

		</sc-block>

		<uf-field *ngIf="definition && field && field.identifier" [field]="field"
			[control]="$any(control).controls[field.identifier]" [scope]="data" [(content)]="data.identifier"
			(contentChange)="contentChanged()" />
	</div>

	<div *ngIf="field && data && control" class="col-1of1">
		<div class="fieldset-item">
			<span>Original</span>
			<span>{{originalDataValue | json}}</span>
		</div>
		<div class="fieldset-item">
			<span>Value</span>
			<span>{{data.identifier | json}}</span>
		</div>
		<div class="fieldset-item">
			<span>Dirty</span>
			<span>{{control.dirty}}</span>
		</div>
		<div class="fieldset-item">
			<span>Touched</span>
			<span>{{control.touched}}</span>
		</div>
		<div class="fieldset-item">
			<span>Field definition</span>
			<uf-help *ngIf="field" [content]="field | json" class="grow" />
		</div>
	</div>
</div>