import { Component, DestroyRef, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { GeoLocation } from '@unifii/sdk';

import { LocationProvider } from '../../models';
import { WindowResizeEventHandler } from '../../services';

const MapZoomMarker = 15;
const MaximumMapImageWidth = 640;
const MaxContainer = { width: '640px' };
const AdjustedContainer = { width: 'fit-content' };

/**
 * @description
 * Renders a map image with the input marker or centered to the LocationProvider.region
 */
@Component({
	selector: 'uf-map',
	templateUrl: './uf-map.html',
	styleUrls: ['./uf-map.less'],
})
export class UfGoogleStaticMapComponent implements OnInit {

	@ViewChild('mapImage', { static: true }) private imgElement?: ElementRef<HTMLImageElement>;
	@ViewChild('imageContainer', { static: true }) private imageContainer?: ElementRef<HTMLDivElement>;

	protected url: string | undefined;
	protected style = MaxContainer;

	private imageSize = MaximumMapImageWidth;
	private locationProvider = inject(LocationProvider);
	private windowResizeEventHandler = inject(WindowResizeEventHandler, { optional: true });
	private destroy = inject(DestroyRef);
	private _marker?: GeoLocation;

	ngOnInit() {
		this.windowResizeEventHandler?.register({
			listener: this.createImage.bind(this),
			debounceTime: 2000,
			destroy: this.destroy,
			fireOnRegister: true,
		});
	}

	@Input() set marker(v: GeoLocation | undefined | null) {
		if (v == null) {
			this._marker = undefined;
		} else {
			v.zoom = v.zoom ?? MapZoomMarker;
			this._marker = v;
		}

		this.createImage();
	}

	get marker() {
		return this._marker;
	}

	private get mapContainerElement() {
		return this.imageContainer?.nativeElement;
	}

	private get mapElement() {
		return this.imgElement?.nativeElement;
	}

	private createImage() {
		if (!this.mapContainerElement || !this.mapElement) {
			return;
		}

		const { offsetWidth } = this.mapContainerElement;

		this.imageSize = offsetWidth > MaximumMapImageWidth ? MaximumMapImageWidth : offsetWidth;

		if (!this.imageSize) {
			return;
		}

		// edge case when flex box has pushed to 1px borders
		if (this.imageSize === 2) {
			this.imageSize = MaximumMapImageWidth;
		}

		this.style = offsetWidth > MaximumMapImageWidth ? MaxContainer : AdjustedContainer;
		this.url = this.getUrl(this.marker);
	}

	private getUrl(location: GeoLocation | null | undefined): string | undefined {

		const fallbackLat = location?.lat ?? this.locationProvider.regionGeoLocation?.lat ?? 0;
		const fallbackLng = location?.lng ?? this.locationProvider.regionGeoLocation?.lng ?? 0;
		const fallbackZoom = location?.zoom ?? this.locationProvider.regionGeoLocation?.zoom;

		if (!this.locationProvider.getMapUrl) {
			return;
		}

		const size = {
			width: this.imageSize ? this.imageSize : MaximumMapImageWidth,
			height: 280,
		};

		return this.locationProvider.getMapUrl(fallbackLat, fallbackLng, location ?? undefined, fallbackZoom, size);
	}

}
