<uf-expander *ngIf="field" [actionBtn]="true">
	<div [class.small]="isSummary && control.disabled" expanderHeader class="uf-app-bar medium">
		<uf-icon *ngIf="field?.icon" [name]="field.icon" />
		<div class="title">
			{{field.label}}
			<uf-help *ngIf="field?.help" [content]="field.help" icon="infoSolid" class="grow" />
		</div>
	</div>
	<div expanderBody class="content grid">
		<ng-template [ngForOf]="field.fields" let-child ngFor>
			<ng-template [ngIf]="isVisible(child)">
				<ng-template [ngIf]="child.identifier" [ngIfElse]="nonContent">
					<uf-field [field]="child" [scope]="scope" [(content)]="scope[child.identifier]" />
				</ng-template>
				<ng-template #nonContent>
					<uf-field [field]="child" [scope]="scope" />
				</ng-template>

			</ng-template>
		</ng-template>
	</div>
</uf-expander>