import { Injectable } from '@angular/core';
import { Client, ConnectionOptionsInterface, ContentClient, Dictionary, PublishedContent, TenantClient } from '@unifii/sdk';

import { ShowcaseRepositoryService } from './repository.service';

export interface ShowcasePage extends Dictionary<any> {
	title?: string;
	briefDescription?: string;
	whenToUse?: string;
	examples?: string;
}

const ShowcaseProjectId = '92';
const ReportProjectId = '171';
const PagesIdentifier = 'page';
const ShowcaseBaseURL = 'https://showcase.unifii.net';

const opts: ConnectionOptionsInterface = {
	baseUrl: ShowcaseBaseURL,
	apiKey: '2L4FZYUEP2EM7YU0CYDIV0CF2',
	apiSecret: 'Tm4HvxFuszVImMCMX24-9cRuDAGTXD8Kz-R55C-l-R3ulQ1_v-en4jrRHmEDQx3JmS-e6m4adp2XHRBaDVSJrQ',
};

@Injectable({ providedIn: 'root' })
export class ShowcaseContentService {

	pages = new Map<string, ShowcasePage>();
	content?: PublishedContent;

	constructor(client: Client, tenantClient: TenantClient, private repo: ShowcaseRepositoryService) {

		if (this.repo.baseUrl === ShowcaseBaseURL) {
			this.content = new ContentClient(client, tenantClient, {
				projectId: ShowcaseProjectId,
				preview: false,
			});

			return;
		}

		const showcaseClient = new Client(opts);

		this.content = new ContentClient(showcaseClient, tenantClient, {
			projectId: ReportProjectId,
			preview: false,
		});
	}

	getPage(identifier: string) {
		return this.pages.get(identifier) ?? {} as ShowcasePage;
	}

	async init() {

		if (!this.content) {
			return;
		}

		try {
			const pages = await this.content.queryCollection(PagesIdentifier);

			for (const page of pages) {
				if (!page._title) {
					continue;
				}
				this.pages.set(page._title, page);
			}
		} catch (e) {
			console.error('failed not load content!', e);
		}
	}

}
