import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataSourceType, NodeType, Option, QueryOperators, RequestAnalytics, VisibleFilterDescriptor } from '@unifii/sdk';

import { UserInfoIdentifiers } from '../../constants';
import { FilterEntry, FilterLoader, FilterType, SourceConfig, SourceConfigUser } from '../../models';
import { CommonTranslationKey } from '../../translations';
import { DataLoaderFactory, dataSourceToSourceConfig } from '../data-source';
import { getCompanyDefaultSourceConfig, getUserByUsernameSourceConfig } from '../data-source/data-source-helper-functions';

import { FilterEntryAdapter, FilterEntryAdapterInfo } from './filter-entry-adapter-model';
import { visibleAndStaticFilterOptionsIntersection } from './filter-functions';

/**
 * @description
 * Map the set of info provided into a FilterEntry
 * by applying Unifii filter system default logic
 */
@Injectable()
export class UfFilterEntryAdapter implements FilterEntryAdapter {

	transform(info: FilterEntryAdapterInfo): FilterEntry | undefined {
		try {

			let options = this.safeOptions(info.type, info.options);
			const source = this.safeSourceConfig(info.type, info.loader, info.source, info.visibleFilterDescriptor);
			const loader = this.safeLoader(info.loader, source, info.dataLoaderFactory, info.requestAnalytics);

			const queryIdentifier = info.visibleFilterDescriptor?.queryIdentifier ?? this.getQueryIdentifier(info.type, info.identifier, loader);
			const queryOperator = info.visibleFilterDescriptor?.queryOperator;

			this.validate(info.type, options, loader);

			if (options && info.staticFilter) {
				const optionsIdentifier = visibleAndStaticFilterOptionsIntersection(info.identifier, options.map((o) => o.identifier), info.staticFilter);

				options = options.filter((o) => optionsIdentifier.includes(o.identifier));
			}

			const entry: FilterEntry = {
				type: info.type,
				identifier: info.identifier,
				label: info.visibleFilterDescriptor?.label ?? info.label,
				options,
				loader,
				queryIdentifier,
				queryOperator,
				inputType: info.visibleFilterDescriptor?.inputType,
				format: info.visibleFilterDescriptor?.format,
				hierarchyConfig: info.visibleFilterDescriptor?.hierarchyConfig,
				searchMinLength: info.searchMinLength,
			};

			return entry;

		} catch (e) {
			console.error(`UfFilterEntryAdapter [${info.identifier}] - ${(e as Error).message}`);

			return;
		}
	}

	private validate(type: FilterType, options?: Option[], loader?: FilterLoader) {

		switch (type) {
			case FilterType.Bool:
				if (!this.areValidBoolOptions(options)) {
					throw new Error(`invalid Options provided for filter ${type}`);
				}
				break;
			case FilterType.Choice:
			case FilterType.OptionArray:
				if (!options) {
					throw new Error(`no Options provided for filter ${type}`);
				}
				break;
			case FilterType.Company:
			case FilterType.User:
			case FilterType.DataSeed:
			case FilterType.DataSeedArray:
				if (!loader) {
					throw new Error(`no FilterLoader provided for filter ${type}`);
				}
		}
	}

	private safeOptions(type: FilterType, options?: Option[], translateService?: TranslateService): Option[] | undefined {

		// Not a Bool filters
		if (type !== FilterType.Bool) {
			return options;
		}

		// Options are good
		if (this.areValidBoolOptions(options)) {
			return options;
		}

		// Build default options for Bool
		return [{
			identifier: 'true',
			name: translateService ? translateService.instant(CommonTranslationKey.YesLabel) as string : 'Yes',
		}, {
			identifier: 'false',
			name: translateService ? translateService.instant(CommonTranslationKey.NoLabel) as string : 'No',
		}];
	}

	private areValidBoolOptions(options?: Option[]): boolean {
		return (options != null && options.length === 2 &&
			options.find((o) => o.identifier === 'true') != null &&
			options.find((o) => o.identifier === 'false') != null
		);
	}

	private safeSourceConfig(type: FilterType, loader?: FilterLoader, sourceConfig?: SourceConfig, descriptor?: VisibleFilterDescriptor): SourceConfig | undefined {

		// No need for a SourceConfig when an instance of FilterLoader is provided
		if (loader) {
			return undefined;
		}

		switch (type) {
			case FilterType.User:
				sourceConfig = sourceConfig as SourceConfigUser | undefined ?? dataSourceToSourceConfig(getUserByUsernameSourceConfig()) as SourceConfigUser;
				if (!sourceConfig.filter && descriptor?.roles?.length) {
					sourceConfig.filter = {
						type: NodeType.Operator,
						op: QueryOperators.Contains, args: [
							{ type: NodeType.Identifier, value: UserInfoIdentifiers.Roles },
							{ type: NodeType.Value, value: descriptor.roles },
						],
					};
				}
				break;
			case FilterType.Company:
				sourceConfig = sourceConfig ?? dataSourceToSourceConfig(getCompanyDefaultSourceConfig());
		}

		if (descriptor?.sort) {
			switch (sourceConfig?.type) {
				case DataSourceType.Bucket:
				case DataSourceType.Users:
				case DataSourceType.Company:
				case DataSourceType.Collection:
					sourceConfig.sort = descriptor.sort;
			}
		}

		return sourceConfig;
	}

	private safeLoader(loader?: FilterLoader, sourceConfig?: SourceConfig, loaderFactory?: DataLoaderFactory, requestAnalytics?: RequestAnalytics): FilterLoader | undefined {
		if (loader) {
			return loader;
		}
		if (loaderFactory && sourceConfig) {
			return loaderFactory.create(sourceConfig, { requestAnalytics }) ?? undefined;
		}

		return undefined;
	}

	private getQueryIdentifier(type: FilterType, identifier: string, loader?: FilterLoader): string | undefined {

		switch (type) {
			case FilterType.Company:
				return `${identifier}.id`;
			case FilterType.ZonedDatetime:
			case FilterType.ZonedDatetimeRange:
				return `${identifier}.value`;
			case FilterType.DataSeedArray:
				if (loader && !identifier.endsWith('_.id')) {
					return `${identifier}._id`;
				}
				break;
			case FilterType.HierarchyUnit:
				return `${identifier}.id`;
		}

		return undefined;
	}

}
