import { Injectable } from '@angular/core';

import { ExpanderComponent } from '../components';

@Injectable()
export class ExpandersService {

	private expanders = new Map<ExpanderComponent, ExpanderComponent>();

	register(expander: ExpanderComponent) {
		this.expanders.set(expander, expander);
	}

	deregister(expander: ExpanderComponent) {
		this.expanders.delete(expander);
	}

	collapseAll(scope?: HTMLElement): void {
		this.expanders.forEach((expander) => {
			if (!scope) {
				expander.collapse();

				return;
			}

			if (scope.contains(expander.hostElement.nativeElement)) {
				expander.collapse();
			}
		});
	}

	expandAll(scope?: HTMLElement): void {
		this.expanders.forEach((expander) => {
			if (!scope) {
				expander.expand();

				return;
			}

			if (scope.contains(expander.hostElement.nativeElement)) {
				expander.expand();
			}
		});
	}

}
