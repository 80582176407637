<uf-data-display-list *ngIf="field">
	<dt>
		{{ field.shortLabel ?? field.label }}
		<uf-help *ngIf="field.help" [content]="field.help" />
	</dt>
	<dd>
		<uf-expander *ngIf="showExpander; else sourceConfigDescriptionTemplate" [isExpanded]="false">
			<div class="uf-expander-header primary" expanderHeader>
				<ng-container *ngTemplateOutlet="sourceConfigDescriptionTemplate" />
			</div>
			<div class="options" expanderBody>
				<div *ngFor="let option of otherOptions">
					<ng-container
						*ngTemplateOutlet="optionTemplate; context: {label: option.name, markdown: option.content}" />
				</div>
			</div>
		</uf-expander>
	</dd>
</uf-data-display-list>

<ng-template #sourceConfigDescriptionTemplate>
	<div class="description">
		<div *ngFor="let item of dataDisplayItems">
			<!-- TODO review {label: item.description, markdown: item.markdown} -->
			<ng-container *ngTemplateOutlet="optionTemplate; context: {label: item.term, markdown: item.data}" />
		</div>
		<div *ngIf="!dataDisplayItems.length" class="placeholder">
			{{ commonTK.LabelNothingSelected | translate }}
		</div>
	</div>
</ng-template>

<ng-template #optionTemplate let-label="label" let-markdown="markdown">
	<a *ngIf="markdown" [data]="{ markdown }" contentViewerLink>{{ label }}</a>
	<ng-container *ngIf="!markdown">{{ label }}</ng-container>
</ng-template>

<uf-data-seed-mappings-display *ngIf="field.sourceConfig" [sourceConfig]="field.sourceConfig"
	[dataSeed]="singleDataSeedContent" />