import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { Option } from '@unifii/sdk';

import { ModalService } from '../../../services';
import { CommonTranslationKey, SharedTermsTranslationKey } from '../../../translations';
import { Modal } from '../modal';
import { ModalRuntime } from '../modal-runtime';
import { ModalConfirmData, ModalData } from '../modal-types';

export interface SingleChoiceRadioModalData extends ModalConfirmData {
	options: Option[];
	columns?: number;
}

/**
 * @description
 * A modal dialog that display a set of options as Radios.
 * Allow for a pre-selected option to be defined
 * Return the selected option or null if no selection has been made.
 */
@Component({
	selector: 'uf-single-choice-radio-modal',
	templateUrl: './single-choice-radio-modal.html',
})
export class SingleChoiceRadioModalComponent implements Modal<SingleChoiceRadioModalData, Option>, OnInit {

	@HostBinding('class.uf-form-card') formCardClass = true;

	runtime = inject<ModalRuntime<SingleChoiceRadioModalData, Option>>(ModalRuntime);
	data: SingleChoiceRadioModalData;

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;
	protected value: Option | undefined;

	private modalService = inject(ModalService);
	private injectedData = inject<SingleChoiceRadioModalData>(ModalData);

	ngOnInit() {
		this.data = Object.assign(
			{},
			this.modalService.defaultModalConfirmData,
			{ title: undefined, message: undefined } satisfies ModalConfirmData,
			{ columns: 2 } satisfies Pick<SingleChoiceRadioModalData, 'columns'>,
			this.injectedData,
		);
		
		this.value = this.data.options[0];
	}

	close() {
		this.runtime.close();
	}

	protected confirm() {
		this.runtime.close(this.value);
	}

}
