<div class="grid--fixed">
	<h2 class="col-1of1">Content</h2>

	<uf-tabs class="col-1of1">
		<uf-tab label="Views">
			<sc-show-view />
		</uf-tab>
		<uf-tab label="Pages">
			<sc-show-page />
		</uf-tab>
		<uf-tab label="Collection">
			<sc-show-collection-item />
		</uf-tab>
	</uf-tabs>

</div>