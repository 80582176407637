<uf-progress [inProgress]="inProgress" class="large" />
<uf-panel class="stretch col">
	<div class="uf-app-bar">
		<button [title]="sharedTermsTK.ActionClose | translate" (click)="close()" ufAutofocus class="uf-action tertiary"
			type="button">
			<uf-icon name="close" />
		</button>
		<h3>{{label}}</h3>
	</div>
	<div class="grow pad">
		<uf-hierarchy-unit-selector [control]="control" [ceiling]="ceiling" [selectLeavesOnly]="selectLeafsOnly"
			[filterInactiveChildren]="data.filterInactiveChildren" (inProgress)="inProgress = $event" />
	</div>
	<div class="uf-form-actions">
		<button (click)="close()" type="button" class="uf-button tertiary left">
			{{ sharedTermsTK.ActionClose | translate }}
		</button>
		<button (click)="deselect()" type="button" class="uf-button right">
			{{ sharedTermsTK.ActionClear | translate }}
		</button>
		<button *ngIf="!data.readOnly" (click)="select()" type="button" class="uf-button primary">
			{{ sharedTermsTK.ActionSave | translate }}
		</button>
	</div>
</uf-panel>