<div class="row">
	<label *ngIf="label" class="grow">{{ label }}
		<ng-content></ng-content>
	</label>
</div>
<div #containerRef [class.compact-xs]="layoutSize === layoutSizes.CompactExtraSmall"
	class="uf-box flat markdown-editor">
	<div class="uf-app-bar grow small flat border-bottom">
		<button [options]="headingOptions" [template]="headingList"
			[title]="commonTK.MarkdownEditorTextStylesLabel | translate" type="button" listBox class="uf-action">
			<uf-icon name="multiText" />
			<ng-template #headingList let-options="options">
				<ul class="uf-list small uf-box">
					<li *ngFor="let option of options; let i = index" [markdownRenderer]="option.value + option.name"
						[title]="option.name" (click)="changeTextSize(option.value)"
						class="uf-list-button body-copy compact"></li>
				</ul>
			</ng-template>
		</button>
		<div class="uf-divider-vertical lite"></div>
		<button [title]="commonTK.MarkdownEditorBoldLabel | translate" (click)="bold()" type="button" class="uf-action">
			<uf-icon name="bold" />
		</button>
		<button *ngIf="layoutSize !== layoutSizes.CompactExtraSmall"
			[title]="commonTK.MarkdownEditorItalicLabel | translate" (click)="italic()" type="button" class="uf-action">
			<uf-icon name="italic" />
		</button>
		<button [options]="defaultOptions" [template]="formattingOptions"
			[title]="commonTK.MarkdownEditorMoreFormattingLabel | translate" type="button" listBox class="uf-action">
			<uf-icon name="more" />
			<ng-template #formattingOptions>
				<ul class="uf-list small uf-box">
					<li *ngIf="layoutSize === layoutSizes.CompactExtraSmall"
						[title]="commonTK.MarkdownEditorItalicLabel | translate" (click)="italic()"
						class="uf-list-button body-copy compact">
						<uf-icon name="italic" />
						<span class="gap-sm-left">{{commonTK.MarkdownEditorItalicLabel | translate}}</span>
					</li>
					<li [title]="commonTK.MarkdownEditorCodeBlockLabel | translate" (click)="codeblock()"
						class="uf-list-button body-copy compact">
						<uf-icon name="codeBlock" />
						<span class="gap-sm-left">{{commonTK.MarkdownEditorCodeBlockLabel | translate}}</span>
					</li>
					<li [title]="commonTK.MarkdownEditorCodeLabel | translate" (click)="code()"
						class="uf-list-button body-copy compact">
						<uf-icon name="code" />
						<span class="gap-sm-left">{{commonTK.MarkdownEditorCodeLabel | translate}}</span>
					</li>
					<li [title]="commonTK.MarkdownEditorQuoteLabel | translate" (click)="quote()"
						class="uf-list-button body-copy compact">
						<uf-icon name="quote" />
						<span class="gap-sm-left">{{commonTK.MarkdownEditorQuoteLabel | translate}}</span>
					</li>
				</ul>
			</ng-template>
		</button>
		<div class="uf-divider-vertical lite"></div>
		<button *ngIf="layoutSize !== layoutSizes.Normal" [options]="defaultOptions" [template]="linkList"
			[title]="commonTK.MarkdownEditorLinksLabel | translate" type="button" listBox class="uf-action">
			<uf-icon name="link" />
			<ng-template #linkList>
				<ul class="uf-list small uf-box">
					<li [title]="commonTK.MarkdownEditorWebsiteLinkLabel | translate" (click)="websiteLink()"
						class="uf-list-button body-copy compact">
						<uf-icon name="earth" />
						<span class="gap-sm-left">{{commonTK.MarkdownEditorWebsiteLinkLabel | translate}}</span>
					</li>
					<li [title]="commonTK.MarkdownEditorEmailLinkLabel | translate" (click)="emailLink()"
						class="uf-list-button body-copy compact">
						<uf-icon name="mail" />
						<span class="gap-sm-left">{{commonTK.MarkdownEditorEmailLinkLabel | translate}}</span>
					</li>
					<li [title]="commonTK.MarkdownEditorPhoneLinkLabel | translate" (click)="telLink()"
						class="uf-list-button body-copy compact">
						<uf-icon name="telephone" />
						<span class="gap-sm-left">{{commonTK.MarkdownEditorPhoneLinkLabel | translate}}</span>
					</li>
				</ul>
			</ng-template>
		</button>
		<button *ngIf="layoutSize === layoutSizes.Normal" [title]="commonTK.MarkdownEditorWebsiteLinkLabel | translate"
			(click)="websiteLink()" type="button" class="uf-action">
			<uf-icon name="earth" />
		</button>
		<button *ngIf="layoutSize === layoutSizes.Normal" [title]="commonTK.MarkdownEditorEmailLinkLabel | translate"
			(click)="emailLink()" type="button" class="uf-action">
			<uf-icon name="mail" />
		</button>
		<button *ngIf="layoutSize === layoutSizes.Normal" [title]="commonTK.MarkdownEditorPhoneLinkLabel | translate"
			(click)="telLink()" type="button" class="uf-action">
			<uf-icon name="telephone" />
		</button>
		<div class="uf-divider-vertical lite"></div>
		<button *ngIf="layoutSize === layoutSizes.Normal" [title]="commonTK.MarkdownEditorBulletListLabel | translate"
			(click)="unorderedList()" type="button" class="uf-action">
			<uf-icon name="bulletList" />
		</button>
		<button *ngIf="layoutSize === layoutSizes.Normal" [title]="commonTK.MarkdownEditorNumberListLabel | translate"
			(click)="orderedList()" type="button" class="uf-action">
			<uf-icon name="numberList" />
		</button>
		<button *ngIf="layoutSize !== layoutSizes.Normal" [options]="defaultOptions" [template]="listOptions"
			type="button" listBox class="uf-action" title="Lists">
			<uf-icon name="bulletList" />
			<ng-template #listOptions>
				<ul class="uf-list small uf-box">
					<li [title]="commonTK.MarkdownEditorBulletListLabel | translate" (click)="unorderedList()"
						class="uf-list-button body-copy compact">
						<uf-icon name="bulletList" />
						<span class="gap-sm-left">{{commonTK.MarkdownEditorBulletListLabel | translate}}</span>
					</li>
					<li [title]="commonTK.MarkdownEditorNumberListLabel | translate" (click)="orderedList()"
						class="uf-list-button body-copy compact">
						<uf-icon name="numberList" />
						<span class="gap-sm-left">{{commonTK.MarkdownEditorNumberListLabel | translate}}</span>
					</li>
				</ul>
			</ng-template>
		</button>
		<div class="uf-divider-vertical lite"></div>
		<ng-content *ngIf="layoutSize === layoutSizes.Normal" select="[normal]"></ng-content>
		<ng-content *ngIf="layoutSize !== layoutSizes.Normal" select="[compact]"></ng-content>
		<button *ngIf="preview" [title]="commonTK.MarkdownEditorPreviewLabel | translate" (click)="togglePreview()"
			type="button" class="uf-action">
			<uf-icon [name]="showPreview? 'viewHidden': 'view'" />
		</button>
		<div class="title"></div>
	</div>
	<div class="gap-sides gap-sm-top gap-sm-bottom">
		<uf-textarea [value]="value" [control]="control" [disabled]="disabled" [ngClass]="{'preview': showPreview}"
			(valueChange)="valueChange.emit($event)" />
		<div *ngIf="showPreview" [markdownRenderer]="value" class="markdown-preview body-copy"> </div>
	</div>
</div>