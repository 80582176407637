import { Component, Input } from '@angular/core';

import { DataDisplayComponentType } from '../../../models';

import { DataDisplayRouterLinkValue, isDataDisplayRouterLinkValue } from './data-display-components-model';

@Component({
	selector: 'uf-data-display-router-link',
	templateUrl: './data-display-router-link.html',
	styleUrls: ['../data-display.less'],
})
export class DataDisplayRouterLinkComponent implements DataDisplayComponentType {
	
	@Input({ required: true }) data: DataDisplayRouterLinkValue;

	static isDataAccepted = isDataDisplayRouterLinkValue;
	
}
