import { Component, DestroyRef, HostBinding, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ModalAnchoredPosition, ModalPositionAlignment, ModalService, WindowResizeEventHandler, WindowWrapper } from '@unifii/library/common';

import { ShowAppDetailsComponent, ShowUserDetailsComponent } from '../../components';
import { ShowcaseRepositoryService } from '../../services';

@Component({
	templateUrl: './main.html',
	styleUrls: ['./main.less'],
})
export class MainComponent implements OnInit, OnDestroy {

	protected menuOpen: boolean;

	private subscriptions = new Subscription();

	constructor(
		private router: Router,
		public repository: ShowcaseRepositoryService,
		private modalService: ModalService,
		@Inject(WindowWrapper) private window: Window,
		@Optional() private windowResizeEventHandler: WindowResizeEventHandler | null,
		private destroy: DestroyRef,
	) { }

	@HostBinding('class.menu-open') get menuOpenClass() {
		return this.menuOpen;
	}

	ngOnInit() {
		// Close menu on navigation
		this.subscriptions.add(this.router.events.subscribe(() => {
			this.menuOpen = false;
		}));

		this.windowResizeEventHandler?.register({
			listener: () => { this.menuOpen = false; },
			destroy: this.destroy,
			fireOnRegister: true,
		});
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	toggleMenu() {
		this.menuOpen = !this.menuOpen;
	}

	logout() {
		this.repository.reset();
		void this.router.navigate(['/']);
	}

	reload() {
		this.window.location.reload();
	}

	showUserDetails(event: MouseEvent) {

		const position = this.getAnchorPosition(event.target as HTMLElement);

		void this.modalService.openAnchor(ShowUserDetailsComponent, event, position);
	}

	showAppDetails(event: MouseEvent) {

		const position = this.getAnchorPosition(event.target as HTMLElement);

		void this.modalService.openAnchor(ShowAppDetailsComponent, event, position);
	}

	get userInitial(): string | null {

		if (this.repository.user == null) {
			return null;
		}

		return this.repository.user.username.charAt(0).toUpperCase();
	}

	private getAnchorPosition(target: HTMLElement): ModalAnchoredPosition {

		return {
			target,
			originX: ModalPositionAlignment.Right,
			originY: ModalPositionAlignment.Top,
			alignmentX: ModalPositionAlignment.Right,
			alignmentY: ModalPositionAlignment.Bottom,
		};
	}

}
