import { InjectionToken } from '@angular/core';
import { Option } from '@unifii/sdk';

import { DataDisplayInfo } from '../../models/data-display-models';

export interface DataDisplayService {

	/**
	 * Based on the data and info provided return the representing DataDisplayValue. This value is displayable with the DataDisplayComponent
	 * 
	 * @param data to be displayed
	 * @param info context info regarding the data
	 */
	displayAsDataDisplayValue(data: unknown, info?: DataDisplayInfo): unknown;

	/**
	 * Return a string representation of the data provided in input
	 * @param data to be displayed
	 * @param info context info regarding the data
	 */
	displayAsString(data: unknown, info?: DataDisplayInfo): string | null;

	/**
	 * @deprecated
	 * 
	 * This function is meant to expose DataDisplay logic to TemplateExpression expressions execution
	 * The usage is limited to a subset of FieldType in order to cover legacy existing scenarios
	 * It's used in a documented and limited cases of TemplateExpression executions
	 * Usage to not be extended, the function is marked as deprecated
	 * The goal is to swap this technical solution in favour of the "format" capability of Unifii Expressions Language once ready
	 * @param data the data to display
	 * @param fieldType the Field.type FieldType in string format
	 * @param options the Field.option Option[]
	 * @param format type specific format info
	 * @returns data formatted as string
	 */
	displayForTemplateExpression(data: unknown, fieldType?: string, options?: Option[], format?: string): string | null;
}

export const DataDisplayService = new InjectionToken<DataDisplayService>('DataDisplayService');
