<uf-expander *ngIf="field" [actionBtn]="true">
	<div expanderHeader class="uf-app-bar medium small">
		<uf-icon *ngIf="field.icon" [name]="field.icon" />
		<div class="title">
			{{field.label}}
			<uf-help *ngIf="field.help" [content]="field.help" icon="infoSolid" class="grow" />
		</div>
	</div>
	<div expanderBody class="grid">
		<ng-container *ngFor="let child of field?.fields">
			<ng-template [ngIf]="isVisible(child)">
				<!-- child of Survey are guaranteed to have valorized identifier, *ngIf to avoid null check triggered -->
				<uf-field *ngIf="child.identifier" [field]="child" [scope]="scope"
					[(content)]="scope[child.identifier]" />
			</ng-template>
		</ng-container>
	</div>
</uf-expander>