import { InjectionToken } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AstNode, Option, RequestAnalytics, VisibleFilterDescriptor } from '@unifii/sdk';

import { DataDescriptorType, DataPropertyDescriptor, FilterEntry, FilterLoader, FilterType, SourceConfig } from '../../models';
import { DataLoaderFactory } from '../data-source';

export interface FilterEntryAdapterInfo {
	// Core
	type: FilterType;
	identifier: string;
	label: string;
	// Configuration
	visibleFilterDescriptor?: VisibleFilterDescriptor;
	staticFilter?: AstNode;
	// Options
	options?: Option[];
	translateService?: TranslateService;
	// Loader
	loader?: FilterLoader;
	source?: SourceConfig;
	dataLoaderFactory?: DataLoaderFactory;
	// Behavior
	requestAnalytics?: RequestAnalytics;
	searchMinLength?: number;
}

/**
 * @description
 *  Adapter to transform a FilterEntryAdapterInfo into a FilterEntry
 */
export interface FilterEntryAdapter {
	transform(info: FilterEntryAdapterInfo): FilterEntry | undefined;
}

export const FilterEntryAdapter = new InjectionToken<FilterEntryAdapter>('FilterEntryAdapter');

export interface FilterEntryDataDescriptorAdapterInfo {
	descriptorType: DataDescriptorType;
	descriptorProperty: DataPropertyDescriptor;
	visibleFilterDescriptor?: VisibleFilterDescriptor;
	staticFilter?: AstNode;
	translateService?: TranslateService;
	dataLoaderFactory?: DataLoaderFactory;
	requestAnalytics?: RequestAnalytics;
	searchMinLength?: number;
}

/**
 * @description
 *  Adapter to transform a FilterEntryDataDescriptorAdapterInfo into a FilterEntry
 */
export interface FilterEntryDataDescriptorAdapter {
	transform(info: FilterEntryDataDescriptorAdapterInfo): FilterEntry | undefined;
}

export const FilterEntryDataDescriptorAdapter = new InjectionToken<FilterEntryDataDescriptorAdapter>('FilterEntryDataDescriptorAdapter');
