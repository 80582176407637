import { isPlatformBrowser } from '@angular/common';
import { InjectionToken } from '@angular/core';
import { Dictionary } from '@unifii/sdk';

// TODO evaluate usability... is it worth it?
// eslint-disable-next-line @typescript-eslint/ban-types
export type Prettify<T> = { [K in keyof T]: T[K]; } & { };

export const WindowWrapper = new InjectionToken('WindowWrapper');

export interface StorageWrapper extends Dictionary<any> {
	readonly length: number;
	clear(): void;
	getItem(key: string): any;
	key(index: number): any;
	removeItem(key: string): void;
	setItem(key: string, data: any): void;
}
export const StorageWrapper = new InjectionToken<StorageWrapper>('StorageWrapper');

export interface SessionStorageWrapper extends Dictionary<any> {
	readonly length: number;
	clear(): void;
	getItem(key: string): any;
	removeItem(key: string): void;
	setItem(key: string, data: any): void;
}
export const SessionStorageWrapper = new InjectionToken<SessionStorageWrapper>('SessionStorageWrapper');

export const getWindow = (platformId: string): any => {

	// Standard Window Browser API
	const isBrowser = isPlatformBrowser(platformId);

	if (isBrowser) {
		return window;
	}

	// Optional monkey-patched Window for NodeJS
	if ((global as any).window) {
		return global.window;
	}

	// Not available
	return {};
};

export const getGMaps = (platformId: string): any => getWindow(platformId).google?.maps;

export const getStorage = (platformId: string): any => {

	// Standard LocalStorage Browser API
	const isBrowser = isPlatformBrowser(platformId);

	if (isBrowser) {
		return getWindow(platformId).localStorage;
	}

	// Optional monkey-patched LocalStorage for NodeJS
	if ((global as any).localStorage) {
		return global.localStorage;
	}

	// Not available
	return undefined;
};

export const getSessionStorage = (platformId: string): any => getWindow(platformId).sessionStorage;
