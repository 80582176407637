<div *ngIf="ready" [formGroup]="controls">
	<ng-template [ngForOf]="entries" let-entry ngFor let-i="index">
		<uf-data-display-list [items]="[{term: 'Value', data: values[i] | json}]" />
		<uf-field [field]="entry.field" [formControlName]="entry.id" [content]="values[i]"
			(contentChange)="changed($event, i)" />
	</ng-template>

	<div *ngIf="controls.invalid && controls.errors">
		{{controls.errors}} errors:
		<uf-help [content]="controls.errors | json" />
	</div>

	<button (click)="submit()" class="uf-button" type="button">Submit</button>
</div>