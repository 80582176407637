<label *ngIf="label" class="gap-sm-bottom pad-sm-left">{{label}}
	<ng-content></ng-content>
</label>
<ul class="uf-list transparent gap-sm-bottom">
	<li *ngFor="let unit of value; let i = index" class="uf-list-item small">
		<p class="small grow pad-sm-left">{{ unit.path | dataDisplay: { type: 'HierarchyPath'} }}</p>
		<button [title]="sharedTermsTK.ActionEdit | translate" (click)="addOrEdit(unit, i)" type="button"
			data-type="chip" class="uf-action tertiary">
			<uf-icon name="edit" />
		</button>
		<button [title]="sharedTermsTK.ActionRemove | translate" (click)="remove(i)" type="button" data-type="chip"
			class="uf-action tertiary">
			<uf-icon name="delete" />
		</button>
	</li>
</ul>
<button (click)="addOrEdit()" type="button" class="uf-button x-small gap-top gap-right right">
	{{ sharedTermsTK.ActionAdd | translate }}
</button>