import { Injectable } from '@angular/core';
import { Dictionary } from '@unifii/sdk';

import { FeatureFlagProvider } from '@unifii/library/common';

@Injectable()
export class ShowcaseFeatureFlagProvider implements FeatureFlagProvider {

	get(): Promise<Dictionary<boolean>> {
		return Promise.resolve({ companies: true });
	}

}
