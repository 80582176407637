import { Component, OnInit, inject } from '@angular/core';
import { Client, FormData, FormDataClient, FormStyle, ProjectContentOptions, ProjectContentOptionsInterface } from '@unifii/sdk';

import { ContextProvider, DataDescriptorService, RuntimeDefinition, RuntimeDefinitionAdapter, ThemeProvider } from '@unifii/library/common';
import { FormDebugger, FormSettings } from '@unifii/library/smart-forms';
import { InputFormSettings } from '@unifii/library/smart-forms/input';
import { EntityPickerOutput } from 'showcase/src/app/components';
import { UsAPIContentClient } from 'showcase/src/app/services';

@Component({
	selector: 'sc-show-form-data-display',
	templateUrl: './show-form-data-display.html',
	providers: [
		{ provide: FormSettings, useClass: InputFormSettings },
		FormDebugger,
		{ provide: ContextProvider, useExisting: FormDebugger },
	],
})
export class ShowFormDataDisplayComponent implements OnInit {

	protected entityInfo: EntityPickerOutput | null;
	protected error: Error | undefined;
	protected definition: RuntimeDefinition | undefined;
	protected formData: FormData | undefined;

	private projectContentOptions = inject(ProjectContentOptions) as ProjectContentOptionsInterface;
	private client = inject(Client);
	private usContent = inject(UsAPIContentClient);
	private dataDescriptorService = inject(DataDescriptorService);
	private runtimeDefinitionAdapter = inject(RuntimeDefinitionAdapter);
	private themeProvider = inject(ThemeProvider);

	async ngOnInit() {
		const bucket = 'all-fields';
		const formDataId = 'a5d6f083-f538-4966-9fbc-9f10d23fb48b';

		this.themeProvider.theme = { formStyle: FormStyle.Summary };

		const formDataClient = new FormDataClient(this.client, {
			preview: this.projectContentOptions.preview,
			projectId: this.projectContentOptions.projectId,
			bucket,
		});

		const dataDescriptor = await this.dataDescriptorService.getBucketDataDescriptor(bucket);
		const formData = await formDataClient.get(formDataId);

		if (!formData._definitionIdentifier) {
			return;
		}

		const def = await this.usContent.getForm(formData._definitionIdentifier);
		const definition = await this.runtimeDefinitionAdapter.transform(def);

		if (!dataDescriptor) {
			return;
		}

		const config: EntityPickerOutput = {
			type: 'bucket',
			formData,
			dataDescriptor,
			definition,
		};

		this.onFormDataEmit(config);
	}

	protected onFormDataEmit(info: EntityPickerOutput | undefined) {
		if (info?.type !== 'bucket') {
			this.definition = undefined;
			this.formData = undefined;
			
			return;
		}

		this.definition = info.definition;
		this.formData = info.formData;
	}

}
