import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Option } from '@unifii/sdk';

import { CompanyIdentifiers } from '../../constants';
import { DataDescriptor, DataDescriptorAdapter, DataDescriptorCompanyType, DataPropertyDescriptor } from '../../models';
import { FeatureFlagService } from '../feature-flag.service';

import { clearPropertiesAndRegisterUnknownsAsSkipped, dataPropertyInfoToDataPropertyDescriptor, displayable, getDisplay, getIcon, getOperators, getPropertiesMap, inputFilterable, normalizeIdentifiersList, sortDataPropertyDescriptors, sortable, staticFilterable } from './data-descriptor-functions';
import { DataPropertyInfoService } from './data-property-info.service';

@Injectable({ providedIn: 'root' })
export class CompanyDataDescriptorAdapter implements DataDescriptorAdapter {

	private readonly adapterDataType = DataDescriptorCompanyType;

	constructor(
		private dataModelEntryService: DataPropertyInfoService,
		private featureFlagService: FeatureFlagService,
		private translate: TranslateService,
	) { }

	/**
	 * Analyze the entity 'Company' with Tenant specific Claims
	 * @param identifiers optional whitelist of properties' identifier to analyze, all otherwise
	 */
	async getDataDescriptor(identifiers?: string[]): Promise<DataDescriptor> {

		const normalizedIdentifiers = normalizeIdentifiersList(DataDescriptorCompanyType, identifiers);

		// console.log(`DDCompany `, properties);
		if (!await this.featureFlagService.isEnabled('companies')) {
			return {
				type: this.adapterDataType,
				propertyDescriptors: [],
				propertyDescriptorsMap: new Map(),
				isSearchable: false,
				skippedProperties: [{ identifier: this.adapterDataType, name: 'Company feature not enabled' }],
			};
		}

		const companyInfoReferences = new Map<string, DataPropertyDescriptor>();
		const skippedProperties: Option[] = [];

		Object.values(this.dataModelEntryService.companyReferences)
		// TODO Remove claims from the formDefinitionReferences list
			.filter((i) => i.identifier !== CompanyIdentifiers.Claims as string)
			.filter((i) => !normalizedIdentifiers || normalizedIdentifiers.has(i.identifier))
			.map((ref) => dataPropertyInfoToDataPropertyDescriptor(ref, this.adapterDataType, this.translate))
			.forEach((dpd) => companyInfoReferences.set(dpd.identifier, dpd));

		// Sorted properties
		let properties = sortDataPropertyDescriptors([...companyInfoReferences.values()]);

		// Clear properties list
		properties = clearPropertiesAndRegisterUnknownsAsSkipped(properties, skippedProperties, normalizedIdentifiers);

		// Amend
		for (const property of properties) {
			property.display = getDisplay(property.identifier, property.label);
			property.icon = getIcon(property.identifier, property.type, this.adapterDataType);
			property.asDisplay = displayable(property, this.adapterDataType);
			property.asSort = sortable(property, this.adapterDataType);
			property.asStaticFilter = staticFilterable(property, this.adapterDataType);
			property.asInputFilter = inputFilterable(property, this.adapterDataType);
			property.operators = getOperators(property.type, property.identifier, this.adapterDataType);
		}

		return {
			type: this.adapterDataType,
			propertyDescriptors: properties,
			propertyDescriptorsMap: getPropertiesMap(properties),
			isSearchable: true,
			skippedProperties,
		};
	}

}
