import { Injectable, inject } from '@angular/core';
import { FeatureFlagKey } from '@unifii/sdk';

import { FeatureFlagProvider } from './feature-flag-provider';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {

	private featureFlagProvider = inject(FeatureFlagProvider);

	async isEnabled(key: FeatureFlagKey): Promise<boolean> {
		return !!(await this.featureFlagProvider.get())[key];
	}

}
