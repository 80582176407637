import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DataSeed, Dictionary } from '@unifii/sdk';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ProgressComponent } from '../indicators';

import { UfControlValueAccessor } from './uf-control-value-accessor';

/**
 * This component differ from the standard uf-autocomplete for the followings:
 *  - No clear button
 *  - value is always a string, not the selected DataSeed
 *  - value can be inserted/edited without going through the option selection
 */
@Component({
	selector: 'uf-address-autocomplete',
	templateUrl: './uf-address-autocomplete.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfAddressAutocompleteComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-autocomplete.less'],
})
export class UfAddressAutocompleteComponent extends UfControlValueAccessor<DataSeed | string> implements OnInit, AfterViewInit {

	@ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement> | undefined;
	@ViewChild(ProgressComponent, { static: true }) progress: ProgressComponent | undefined;

	@Input() name?: string | null;
	@Input() label?: string | null;
	@Input() placeholder?: string | null;
	@Input() debounce?: number | null = 200;
	@Input() autofocus?: boolean | null;
	@Input() nameProperty?: string | null;
	@Input() noResultsMsg?: string | null;
	@Input() minSearchLengthMsg?: string | null;
	@Output() searchChange = new EventEmitter<string>();
	@Output() override valueChange = new EventEmitter<DataSeed | string>();
	@Output() selected = new EventEmitter<DataSeed>();

	focused: boolean;
	queryControl = new FormControl('');

	/** Chrome autofill no longer accepts autocomplete="off" for now a random value seems to work
	in the future we may have to create a unique value for each */
	protected readonly autocompleteId = Math.random().toString(36).substring(2, 18);
	protected hasSearchConfigured: boolean;
	protected fixedMinSearchLength = 1;

	private _minSearchLength: number | null | undefined;
	private _options: Dictionary<any>[] | null | undefined = [];

	ngOnInit() {
		this.subscriptions.add(this.control.valueChanges.pipe(
			debounceTime(this.debounce ?? 0),
			distinctUntilChanged(),
		).subscribe((q: string) => { this.guardedEmitSearchChange(q); }));

		this.hasSearchConfigured = this.searchChange.observed;
	}

	ngAfterViewInit() {
		if (!this.input) {
			return;
		}

		this.subscriptions.add(fromEvent(this.input.nativeElement, 'focus').subscribe(() => { this.onfocus(); }));
		this.subscriptions.add(fromEvent(this.input.nativeElement, 'blur').subscribe(() => { this.onblur(); }));

		if (this.autofocus) {
			this.input.nativeElement.focus();
		}
	}

	@Input() set minSearchLength(v: number | null | undefined) {
		this._minSearchLength = v;
		const numeric = +(this._minSearchLength ?? 1);

		// Minimum query length for address search is 1
		this.fixedMinSearchLength = numeric >= 1 ? numeric : 1;
	}

	get minSearchLength(): number | null | undefined {
		return this._minSearchLength;
	}

	@Input() set options(v: any[] | null | undefined) {

		this.progress?.complete();

		if (this.disabled) {
			return;
		}

		this._options = v ?? [];
	}

	get options(): any[] | null | undefined {
		return this._options;
	}

	onSelect(value: DataSeed) {
		this.selected.emit(value);
	}

	private onfocus() {
		this.focused = true;

		if (this.disabled || this.value) {
			return;
		}

		/** Clear previous options and immediately fire a new search request */
		this.options = null;
		this.guardedEmitSearchChange(this.queryControl.value);
	}

	private onblur() {
		this.focused = false;
		this.progress?.complete();
		this.control.markAsDirty();
		this.control.markAsTouched();
	}

	private guardedEmitSearchChange(q: string | null = '') {

		if (!this.focused) {
			return;
		}

		this.control.markAsDirty();
		this.control.markAsTouched();

		if (+(this.minSearchLength ?? 0) > (q ?? '').length) {
			return;
		}

		if (this.hasSearchConfigured) {
			this.progress?.start(.4);
			this.searchChange.emit(q ?? '');
		}
	}

	@HostBinding('class.focused') get focusedClass() {
		return this.focused && !this.disabled;
	}

	@HostBinding('class.error') get errorClass() {
		return this.control.showError && !this.disabled;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	@HostBinding('class.value') get valueClass() {
		return !!this.value;
	}

}
