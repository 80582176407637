import { AfterViewInit, Component, ContentChild, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Dictionary } from '@unifii/sdk';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ListBoxDirective } from '../../directives';
import { SharedTermsTranslationKey } from '../../translations';
import { ProgressComponent } from '../indicators';

import { UfControlValueAccessor } from './uf-control-value-accessor';

/**
 * @description
 * The search result is a normal text input enhanced by a panel of suggested options.
 * This widget is useful for selecting a searching and selecting a single-line value.
 */
@Component({
	selector: 'uf-search-result',
	templateUrl: './uf-search-result.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfSearchResultComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-autocomplete.less', './uf-input-action-button.less'],
})
export class UfSearchResultComponent extends UfControlValueAccessor<any> implements OnInit, OnDestroy, AfterViewInit {

	// TemplateRef 'listBox' is mapped into the ListBoxDirective @Input template
	@ContentChild('listBox', { static: true }) listBoxTemplate: TemplateRef<ListBoxDirective>;
	@ViewChild('input', { static: true }) input?: ElementRef<HTMLInputElement>;
	@ViewChild(ProgressComponent, { static: true }) progress: ProgressComponent | undefined;

	@Input() name: string | null | undefined;
	@Input() label: string | null | undefined;
	@Input() placeholder: string | null | undefined;
	@Input() nameProperty: string | ((v: any) => string) | null | undefined; /** Configure the component to work with objects and nameProperty is the attribute used to display its representation */
	@Input() debounce: number | null | undefined = 200;
	@Input() noResultsMsg: string | null | undefined;
	@Input() autofocus: boolean | null | undefined;
	@Input() blurOnSelect = false;
	@Input() clearOnSelect = true; // used when a partial value is to be retained in input box
	@Input() minSearchLength: number | null | undefined = 0;
	@Input() minSearchLengthMsg: string | null | undefined;
	@Output() searchChange = new EventEmitter<string>(); /** Notify search criteria has changed */
	@Output() override valueChange = new EventEmitter<any>(); /** Notify the selected value has changed */

	/**
	 * Chrome autofill no longer accepts autocomplete="off" for now a random value seems to work
	 * in the future we may have to create a unique value for each
	 */
	protected readonly autocomplete = Math.random().toString(36).substring(2, 18);
	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected hasSearchConfigured = false;
	protected focused = false;

	private _options: any[] | null | undefined;
	private delayedEmitSearchOnBlurTimer: NodeJS.Timer | undefined;

	ngOnInit() {
		this.subscriptions.add(this.control.valueChanges.pipe(
			debounceTime(this.debounce ?? 0),
			distinctUntilChanged(),
		).subscribe((v: string | null) => { this.guardedEmitSearchChange(v ?? undefined); }));

		this.hasSearchConfigured = this.searchChange.observed;
	}

	ngAfterViewInit() {
		if (!this.input) {
			return;
		}

		this.subscriptions.add(fromEvent(this.input.nativeElement, 'focus').subscribe(() => { this.onfocus(); }));
		this.subscriptions.add(fromEvent(this.input.nativeElement, 'blur').subscribe(() => { this.onblur(); }));

		if (this.autofocus) {
			this.input.nativeElement.focus();
		}
	}

	override ngOnDestroy() {
		clearTimeout(this.delayedEmitSearchOnBlurTimer);
		super.ngOnDestroy();
	}

	@Input() set options(v: any[] | null | undefined) {

		this.progress?.complete();

		if (this.disabled) {
			return;
		}

		this._options = v;
	}

	get options(): any[] | null | undefined {
		return this._options;
	}

	clear() {
		this.control.setValue(null, { onlySelf: true });
	}

	override valueEmitPredicate(): boolean {
		return false;
	}

	onselect(value: Dictionary<any> | string) {
		this.valueChange.emit(value);

		if (this.clearOnSelect) {
			/**
			 * Set value to null so we control the search change emit below
			 * otherwise emitting 2 empty strings in a row will be filtered by
			 * the distinct on change filter on valueChanges
			 */
			this.control.setValue(null);
		}

		/**
		 * Add slight delay when resetting value for UX reasons
		 * if the query runs to quickly it can feel like nothing has happened
		 */
		if (this.blurOnSelect) {
			this.input?.nativeElement.blur();
		} else {

			clearTimeout(this.delayedEmitSearchOnBlurTimer);
			this.delayedEmitSearchOnBlurTimer = setTimeout(() => { this.guardedEmitSearchChange(); }, 200);
		}
	}

	private onfocus() {
		this.focused = true;

		if (this.disabled) {
			return;
		}

		/** Immediately fire search request */
		this.options = null;
		this.guardedEmitSearchChange((this.control.value as string | null) ?? undefined);
	}

	private onblur() {
		this.focused = false;
		this.progress?.complete();
	}

	private guardedEmitSearchChange(q = '') {

		if (+(this.minSearchLength ?? 0) > q.length) {
			this.options = null;

			return;
		}

		if (this.hasSearchConfigured) {
			this.progress?.start(.4);
			this.searchChange.emit(q);
		}
	}

	@HostBinding('class.focused') get focusedClass() {
		return this.focused && !this.disabled;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

}
