import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-group.html',

})
export class ShowGroupComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	// table
	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'uf-box', type: 'base', description: 'Styles container element' },
		{ name: 'flat', type: 'modifier', description: 'Removes drop shadow and adds an inset border' },
		{ name: 'inset', type: 'modifier', description: 'Adds grey background to give the impression of inserted content' },
	];

	// Interactive
	readonly styleOptions = [
		{ name: 'Raised (Default)', value: '' },
		{ name: 'Flat', value: 'flat' },
		{ name: 'Inset', value: 'inset' }, // To look inserted
	];

	readonly appBarSizeOptions = [
		{ name: 'Large (Default)', value: '' },
		{ name: 'Medium', value: 'medium' },
		{ name: 'small', value: 'small' },
	];
	readonly appBarColourOptions = [
		{ name: 'Default', value: '' },
		{ name: 'Accent', value: 'accent' },
	];

	style = '';
	appBarSize = '';
	appBarColour = 'accent';

	modifierChange() {

	}

}
