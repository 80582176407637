import { Component, HostBinding, Input } from '@angular/core';

import { DataDisplayComponentType } from '../../../models';

import { DataDisplayLozengeValue, isDataDisplayLozengeValue } from './data-display-components-model';

@Component({
	selector: 'uf-data-display-lozenge',
	templateUrl: './data-display-lozenge.html',
	styleUrls: ['../data-display.less'],
})
export class DataDisplayLozengeComponent implements DataDisplayComponentType {
	
	@Input({ required: true }) data: DataDisplayLozengeValue;
	
	@HostBinding('style.white-space') protected noWrap = 'nowrap';

	static isDataAccepted = isDataDisplayLozengeValue;
	
}
