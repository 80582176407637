<ng-template [ngIf]="mode === modes.Input">
	<uf-hierarchy-unit-selector [ceiling]="entry?.hierarchyConfig?.ceiling" [showSearch]="false" [(value)]="value"
		(valueChange)="valueChanged()" />
</ng-template>

<div *ngIf="mode === modes.Display && !isEmpty" class="uf-chip x-small">
	{{ label }}: {{ displayValue }}
	<button (click)="remove()" type="button" class="uf-action">
		<uf-icon name="delete" />
	</button>
</div>