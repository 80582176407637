import { DestroyRef, ElementRef, Injectable, OnDestroy, inject } from '@angular/core';

import { WindowWrapper } from '../native';
import { isHTMLElementRef } from '../utils';

import { DOMEventHandler } from './dom-event-handler';

export enum ScreenSize {
	ScreenWidthSm = 'screen-width-sm', // 576px
	ScreenWidthMd = 'screen-width-md', // 768px
	ScreenWidthLg = 'screen-width-lg', // 992px
	ScreenWidthXl = 'screen-width-xl', // 1200px
	ScreenWidthXXl = 'screen-width-xxl' // 1584px
}

export type WindowResizeInfo = {
	screenSize: ScreenSize;
	innerHeight: number;
	innerWidth: number;
	elementHeight?: number;
	elementWidth?: number;
}

type WindowResizeRegisterOptions = {
	listener: (resizeInfo: WindowResizeInfo) => void;
	listenerOptions?: AddEventListenerOptions | boolean;
	destroy: DestroyRef | undefined;
	debounceTime?: number;
	reference?: ElementRef<HTMLElement> | HTMLElement;
	fireOnRegister?: boolean;
}

/** Orchestrator for Window resize 'addEventListener' with mechanics for unregister and destroy */
@Injectable()
export class WindowResizeEventHandler implements OnDestroy {

	private window = inject(WindowWrapper) as Window;
	private domEventHandler = inject(DOMEventHandler);
	private registerIds = new Map<string, void>();

	/**
	 * Register the requested listener against Window resize event
	 * @param options.listener - callback for the event
	 * @param options.listenerOptions - @see {@link AddEventListenerOptions}
	 * @param options.destroy - @see {@link DestroyRef}
	 * @param options.debounceTime - ms of debounce
	 * @param options.reference - element used to compute the listener callback info
	 * @param options.fireOnRegister - fire a first call of the listener
	 * @returns identify the event within the handler and allow remove via unregister
	 */
	register(options: WindowResizeRegisterOptions) {
		const registerId = this.domEventHandler.register({
			event: 'resize',
			listener: () => {
				options.listener(this.getResizeInfo(options.reference));
			},
			listenerOptions: options.listenerOptions,
			destroy: options.destroy,
			debounceTime: options.debounceTime ?? 250,
		});

		this.registerIds.set(registerId);

		if (options.fireOnRegister) {
			options.listener(this.getResizeInfo(options.reference));
		}

		return registerId;
	}

	/**
	 * Remove the event handler from the element
	 * @param registerId identifier of the handler
	 * @returns
	 */
	unregister(registerId: string) {
		this.domEventHandler.unregister(registerId);
		this.registerIds.delete(registerId);
	}

	ngOnDestroy() {
		for (const registerId of this.registerIds.keys()) {
			this.unregister(registerId);
		}
	}

	private getResizeInfo(reference?: ElementRef<HTMLElement> | HTMLElement) {

		const element = isHTMLElementRef(reference) ? reference.nativeElement : reference;

		return {
			innerHeight: this.window.innerHeight,
			innerWidth: this.window.innerWidth,
			screenSize: this.getScreenSize(window.innerWidth),
			elementHeight: element?.clientHeight,
			elementWidth: element?.clientWidth,
		} as WindowResizeInfo;
	}

	private getScreenSize(width: number): ScreenSize {

		if (width >= 1584) {
			return ScreenSize.ScreenWidthXXl;
		}

		if (width >= 1200) {
			return ScreenSize.ScreenWidthXl;
		}

		if (width >= 992) {
			return ScreenSize.ScreenWidthLg;
		}

		if (width >= 768) {
			return ScreenSize.ScreenWidthMd;
		}

		return ScreenSize.ScreenWidthSm;
	}

}
