import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FilterEntry, FilterViewMode, IFilterComponent } from '../../../models';

@Component({
	selector: 'uf-filter-text-array',
	templateUrl: './filter-text-array.html',
	styleUrls: ['./field-filter.less'],
})
export class FilterTextArrayComponent implements IFilterComponent {

	@Input() mode = FilterViewMode.Input;
	@Input({ required: true }) entry: FilterEntry;
	@Input() value: string[] = [];
	@Output() valueChange = new EventEmitter<string[]>();

	protected readonly modes = FilterViewMode;

	get label(): string {
		return this.entry.label;
	}

	get isEmpty(): boolean {
		return !this.value.length;
	}

	protected remove(index: number) {
		this.value.splice(index, 1);
		this.valueChanged();
	}

	protected valueChanged() {
		this.valueChange.emit(this.isEmpty ? undefined : [...this.value]);
	}

}
