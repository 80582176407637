import { AfterViewInit, Directive, ElementRef, EventEmitter, NgZone, OnDestroy, Output, inject } from '@angular/core';
import { Subscription, fromEvent, merge, zip } from 'rxjs';
import { first } from 'rxjs/operators';

/**
 * Simple directive which observe all image nodes
 * and emits an event when the load process finish (either with a success or a failure)
 * Could be extended later to include a DOMObserver which will rerun if items are added
 */
@Directive({
	selector: '[imagesLoaded]',
})
export class ImagesLoadedDirective implements AfterViewInit, OnDestroy {

	@Output() done = new EventEmitter<any>();

	private subscriptions = new Subscription();

	private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
	private ngZone = inject(NgZone);

	ngAfterViewInit() {

		// Wait til next digest cycle before running init
		const onStable = this.ngZone.onStable.pipe(first());

		this.subscriptions.add(onStable.subscribe(() => {
			this.ngZone.runTask(() => this.init());
		}));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	private init() {

		const images = this.getImgElements(this.elementRef.nativeElement);

		if (!images.length) {
			this.done.emit();

			return;
		}

		const observers = images.map((image) =>	merge(fromEvent(image, 'load'), fromEvent(image, 'error')));
		const subscription = zip(...observers).subscribe(() => {
			this.done.emit();
			subscription.unsubscribe();
		});
	}

	private getImgElements(htmlElement: HTMLElement): HTMLImageElement[] {
		const nodeList = htmlElement.querySelectorAll('img');
		const images = Array.from(nodeList);

		return images.filter((image) => image.src && !image.src.startsWith('base64'));
	}

}
