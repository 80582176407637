import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';

import { DirectoryDirective } from './directory.directive';

export interface DirectoryIndicatorRenderInfo {
	isLastChild: boolean;
	hasChildren: boolean;
	parentRenderInfo?: DirectoryIndicatorRenderInfo;
}

@Directive({
	selector: '[ufDirectoryRoot]',
})
export class DirectoryRootDirective<T extends object> implements OnChanges {

	@Input({ required: true }) root: T[];
	@Input({ required: true }) childrenProperty: keyof T;

	private parentLookup= new WeakMap<T, T>();
	private childrenLookup = new WeakMap<T, T[]>();
	private directiveLookup = new WeakMap<T, DirectoryDirective<T>>();
	private initialized = false;

	ngOnChanges(changes: SimpleChanges) {
		this.parentLookup = new WeakMap<T, T>();
		this.childrenLookup = new WeakMap<T, T[]>();

		for (const { node, parentNode } of this.parentIterator(changes.childrenProperty?.currentValue as typeof this.childrenProperty, this.root)) {
			if (typeof node !== 'object') {
				console.warn('DirectoryRootDirective: directory node not an object');

				return;
			}

			this.parentLookup.set(node, parentNode);
			this.childrenLookup.set(parentNode, [...(this.childrenLookup.get(parentNode) ?? []), node]);
		}

		this.initialized = true;
	}

	register(directive: DirectoryDirective<T>) {
		if (!this.initialized) {
			throw new Error('DirectoryRootDirective: getInfo called to early, try calling from ngOnInit');
		}

		if (typeof directive.item !== 'object') {
			console.warn('DirectoryRootDirective: directory node must be object');

			return;
		}

		this.directiveLookup.set(directive.item, directive);

		const parentNode = this.parentLookup.get(directive.item);

		if (parentNode) {
			this.directiveLookup.get(parentNode)?.addChild(directive);
		}

		const childrenNodes = this.childrenLookup.get(directive.item);

		if (childrenNodes?.length) {
			for (const child of childrenNodes.reduce(this.registeredDirectiveReducer.bind(this), [])) {
				directive.addChild(child);
			}
		}
	}

	getInfo(item: T): DirectoryIndicatorRenderInfo {
		if (!this.initialized) {
			throw new Error('DirectoryRootDirective: getInfo called to early, try calling from ngOnInit');
		}

		const parent = this.parentLookup.get(item);
		const itemList = parent ? this.childrenLookup.get(parent) : this.root;

		if (!itemList) {
			throw new Error('DirectoryRootDirective: failed to find node index, check your dom configuration');
		}

		return {
			parentRenderInfo: parent ? this.getInfo(parent) : undefined,
			hasChildren: (this.childrenLookup.get(item) ?? []).length > 0,
			isLastChild: itemList.findIndex((i) => i === item) === (itemList.length - 1),
		};
	}

	private registeredDirectiveReducer(directives: DirectoryDirective<T>[], node: T): DirectoryDirective<T>[] {
		const directive = this.directiveLookup.get(node);

		if (directive != null) {
			directives.push(directive);
		}

		return directives;
	}

	private *parentIterator(childrenProperty: keyof T, children: T[] = [], parentNode?: T): Iterable<{ node: T; parentNode: T}> {
		for (const node of children) {
			if (parentNode) {
				yield { node, parentNode };
			}

			yield *this.parentIterator(childrenProperty, node[childrenProperty] as T[], node);
		}
	}

}
