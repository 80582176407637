import { Component, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FieldTemplate, Option } from '@unifii/sdk';

import { CommonTranslationKey, RuntimeField, UfControl } from '@unifii/library/common';
import { FormField } from '@unifii/library/smart-forms';

export interface BoolOption {
	value: boolean;
	name: string;
}

@Component({
	selector: 'uf-bool',
	templateUrl: './bool.html',
})
export class BoolComponent implements OnInit, FormField {

	readonly fieldTemplate = FieldTemplate;

	field: RuntimeField;
	control: UfControl;
	content?: boolean;
	contentChange: EventEmitter<boolean>;
	suffix: string;
	cssClass: string | string[];
	options: BoolOption[];

	constructor(private translate: TranslateService) { }

	ngOnInit() {
		this.options = this.getOptions(this.field.options);
	}

	changed(v: boolean) {
		this.contentChange.emit(v);
	}

	private getOptions(options: Option[]): BoolOption[] {

		if (!options.length) {
			return [
				{ value: true, name: this.translate.instant(CommonTranslationKey.YesLabel) },
				{ value: false, name: this.translate.instant(CommonTranslationKey.NoLabel) },
			];
		}

		return options.map((option) => {

			const { identifier, name } = option;
			const value = identifier === 'true' ? true : false;

			return { name, value };
		});
	}

}
