import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { REGEXP_EMAIL, REGEXP_WEBSITE, isDictionary, isString } from '@unifii/sdk';

import { ExpressionParser } from '../services';
import { Regexp } from '../utils';

export const isEmpty = (value: any): boolean => {

	if (value && isDictionary(value)) {
		// Check that the object has at least a valorized attribute
		const keys = Object.keys(value);

		return !keys.some((k) => !isEmpty(value[k]));
	}

	return value == null || value === '';
};

/** When the predicate is false the message is displayed */
export const custom = (predicate: (value: any, c?: AbstractControl) => boolean, message: string): ValidatorFn => (c: AbstractControl) => {
	const valid = predicate(c.value, c);

	if (!valid) {
		return { message };
	}

	return null;
};

export const combine = (...validators: ValidatorFn[]): ValidatorFn | undefined => Validators.compose(validators) ?? undefined;

export const compose = (validators: ValidatorFn[]): ValidatorFn | undefined => Validators.compose(validators) ?? undefined;

export const required = (message: string): ValidatorFn => custom((v) => !isEmpty(v), message);

export const min = (value: number, message: string): ValidatorFn => custom((v) => {

	if (isEmpty(v)) {
		return true;
	}

	return v >= value;
}, message);

export const max = (value: number, message: string): ValidatorFn => custom((v) => {
	if (isEmpty(v)) {
		return true;
	}

	return v <= value;
}, message);

export const minLength = (valueMinLength: number, message: string): ValidatorFn => custom((v) => {
	if (isEmpty(v) || (!Array.isArray(v) && !isString(v))) {
		return true;
	}

	return v.length >= valueMinLength;
}, message);

export const maxLength = (valueMaxLength: number, message: string): ValidatorFn => custom((v) => {
	if (isEmpty(v) || (!Array.isArray(v) && !isString(v))) {
		return true;
	}

	return v.length <= valueMaxLength;
}, message);

export const isValidExpression = (expressionParser: ExpressionParser, msg: string): ValidatorFn => custom((v) =>
	isEmpty(v) || (isString(v) && expressionParser.validate(v)),
msg,
);

export const pattern = (valuePattern: string | RegExp, message: string): ValidatorFn => custom((v) =>
	isEmpty(v) || (isString(v) && new RegExp(valuePattern).test(v)),
message,
);

export const lettersOnly = (msg: string): ValidatorFn => pattern(Regexp.REGEXP_LETTERS_ONLY, msg);

export const alphanumeric = (msg: string): ValidatorFn => pattern(Regexp.REGEXP_ALPHA_NUMERIC, msg);

export const email = (msg: string): ValidatorFn => pattern(REGEXP_EMAIL, msg);

export const website = (msg: string): ValidatorFn => pattern(REGEXP_WEBSITE, msg);

export const noWhiteSpaces = (msg: string): ValidatorFn => pattern(Regexp.REGEXP_NO_WHITE_SPACES, msg);
