import { Component, OnInit } from '@angular/core';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-avatars.html',
	styleUrls: ['./_data-display.less'],
})
export class ShowAvatarsComponent implements OnInit {

	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'uf-avatar', type: 'base class', description: 'Sets base style of avatar' },
		{ name: 'accent', type: 'modifier', description: 'Sets colour to accent colour' },
		{ name: 'info', type: 'modifier', description: 'Sets colour to information message colour' },
		{ name: 'warning', type: 'modifier', description: 'Sets colour to warning message colour' },
		{ name: 'success', type: 'modifier', description: 'Sets colour to success message colour' },
		{ name: 'error', type: 'modifier', description: 'Sets colour to error message colour' },
		{ name: 'disabled', type: 'modifier', description: 'Sets colour to disabled messaage colour' },
		{ name: 'large', type: 'modifier', description: 'Sets size of avatar to large' },
		{ name: 'default', type: 'modifier', description: 'Sets size of avatar to default' },
		{ name: 'small', type: 'modifier', description: 'Sets size of avatar to small' },
		{ name: 'x-small', type: 'modifier', description: 'Sets size of avatar to extra small' },
	];

	// Interactive
	readonly sizeOptions = [
		{ name: 'Large', value: 'large' },
		{ name: 'Medium (default)', value: '' },
		{ name: 'Small', value: 'small' },
		{ name: 'Extra Small', value: 'x-small' },
	];
	readonly colourOptions = [
		{ name: 'Transparent (Default)', value: '' },
		{ name: 'Custom', value: 'custom-colour' },
		{ name: 'Info', value: 'info' },
		{ name: 'Warning', value: 'warning' },
		{ name: 'Error', value: 'error' },
		{ name: 'Success', value: 'success' },
		{ name: 'Accent', value: 'accent' },
		{ name: 'Disabled', value: 'disabled' },
	];
	readonly contentOptions = [
		{ name: 'Text (Identity)', value: 'identity' },
		{ name: 'Icon (Symbol)', value: 'icon' },
		{ name: 'Image (Symbol)', value: 'image' },
	];

	size = '';
	content = 'identity';
	colour = 'custom-colour';
	template: string;

	private templateSource = `
		<div class="uf-avatar#cssClasses">
			#content
		</div>
	`;

	ngOnInit() {
		this.modifierChange();
	}

	contentChange() {

		if (this.content === 'image') {
			this.colour = '';
		} else {
			this.colour = 'custom-colour';
		}

		this.modifierChange();
	}

	modifierChange() {

		let classes = [this.size, this.colour].join(' ').trim();
		const content = this.getContent();

		if (classes !== '') {
			classes = ' ' + classes;
		}

		this.template = this.templateSource.replace('#cssClasses', classes).replace('#content', content);
	}

	private getContent(): string {

		switch (this.content) {
			case 'icon': return `<uf-icon name="edit"></uf-icon>`;
			case 'image': return `<img src="your_url">`;
			default: return 'SD';
		}

	}

}
