import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { LayoutDirection } from '@unifii/sdk';

export type GridGap = 'xs' | 'sm' | 'md' | 'lg';

/**
 * Reusuable way converting common inputs to a grid layout
 * use in
 *  - Radio Options
 *  - Multichoice Options
 *  - Bool Options
 */
@Directive({
	selector: '[ufGrid]',
})
export class GridDirective implements OnInit {

	private htmlElement: HTMLElement;
	private sizes = new Map<GridGap, string>([['xs', '.25rem'], ['sm', '.5rem'], ['md', '1rem'], ['xs', '1.5rem']]);

	private _direction: LayoutDirection | 'Row' | 'Column';
	private _rows?: number | null;
	private _columns: number;
	private _cellTotal: number;
	private _columnGap: GridGap;
	private _rowGap: GridGap;

	@Input() set direction(direction: LayoutDirection | 'Row' | 'Column') {
		this._direction = direction ?? LayoutDirection.Row;
		this.updateStyle();
	}

	@Input() set columns(columns: number | string | null | undefined) {
		this._columns = (typeof columns === 'string' ? +columns : columns) ?? 1;
		this.updateStyle();
	}

	@Input() set rows(rows: number | string | null | undefined) {
		this._rows = typeof rows === 'string' ? +rows : rows;
		this.updateStyle();
	}

	@Input() set cellTotal(cellTotal: number | undefined | null) {
		this._cellTotal = cellTotal ? cellTotal : 1;
		this.updateStyle();
	}

	@Input() set columnGap(columnGap: GridGap) {
		this._columnGap = columnGap;
		this.updateStyle();
	}

	@Input() set rowGap(rowGap: GridGap) {
		this._rowGap = rowGap;
		this.updateStyle();
	}

	constructor(private element: ElementRef) {
		this.htmlElement = this.element.nativeElement;
	}

	ngOnInit() {
		this.updateStyle();
	}

	updateStyle() {

		// if (this._columns === 1) {
		//	 return;
		// }

		this.setStyleProperty('display', 'grid');
		this.setStyleProperty('grid-template-columns', `repeat(${this._columns}, 1fr)`);

		let rows = this._rows;

		if (this._direction === 'Column') {
			rows = Math.ceil(this._cellTotal / this._columns);
			this.setStyleProperty('grid-auto-flow', 'column');
		} else if (this._direction === 'Row') {
			this.setStyleProperty('grid-auto-flow', 'row');
		}

		if (rows) {
			this.setStyleProperty('grid-template-rows', `repeat(${rows}, 1fr)`);
		}

		if (this._columnGap) {
			this.setStyleProperty('grid-column-gap', this.sizes.get(this._columnGap) as string);
		}

		if (this._rowGap) {
			this.setStyleProperty('grid-row-gap', this.sizes.get(this._rowGap) as string);
		}
	}

	private setStyleProperty(property: string, value: string) {
		this.htmlElement.style.setProperty(property, value);
	}

}

