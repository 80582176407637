import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { UfControlArray } from '../controls';

@Pipe({
	name: 'asUfControlArray',
})
export class AsUfControlArrayPipe implements PipeTransform {

	transform(value: AbstractControl): UfControlArray {
		return value as UfControlArray;
	}

}
