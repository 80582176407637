<label *ngIf="label !== null">
	<ng-container *ngIf="label; else elseBlock">
		{{ label }}
	</ng-container>
	<ng-template #elseBlock>
		{{ isExpression ? (commonTK.ExpressionLabel | translate) : (commonTK.ValueLabel | translate) }}
	</ng-template>
	<ng-content></ng-content>
</label>
<div class="input-wrap">
	<ng-container *ngIf="isExpression; else textInput">
		<div class="spacer">
			<span>{{ textControl.value }}</span>
		</div>
		<textarea [formControl]="textControl" [attr.name]="name" [attr.placeholder]="placeholder"
			[attr.maxlength]="maxLength" [readonly]="disabled" (focus)="focused = true"
			(blur)="focused = false; control.markAsTouched();" (keydown)="$event.stopPropagation()"></textarea>
	</ng-container>
	<ng-template #textInput>
		<input [formControl]="textControl" [attr.name]="name" [attr.placeholder]="placeholder"
			[attr.maxlength]="maxLength" [readonly]="disabled" [attr.autocomplete]="autocomplete"
			(focus)="focused = true" (blur)="focused = false; control.markAsTouched();" type="text" />
	</ng-template>
	<button [class.active]="isExpression" (click)="toggleExpression();" class="uf-button x-small toggle" type="button">
		{{ isExpression ? (commonTK.ExpressionLabel | translate) : (commonTK.ValueLabel | translate) }}
	</button>
	<uf-error [control]="textControl" />
	<uf-error *ngIf="textControl.valid" [control]="control" />
	<uf-underline *ngIf="!disabled" [class.active]="focused" />
</div>