import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Output, PLATFORM_ID, ViewChild, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataType } from '@unifii/sdk';
import { format } from 'date-fns';
import { Subject, Subscription } from 'rxjs';

import { ShortTimeFormat, TwentyFourHourTimeFormat } from '../../../constants';
import { UfControl } from '../../../controls';
import { DateTimePickerData, TemporalInputDataTypes } from '../../../models';
import { DeviceInfo, ModalService } from '../../../services';
import { CommonTranslationKey } from '../../../translations';
import { DateConverter, DateTimeFunctions, ValidatorFunctions } from '../../../utils';
import { ModalPositionAlignment } from '../../modal';

import { DatetimePickerComponent } from './datetime-picker.component';

@Component({
	selector: 'uf-date-input',
	templateUrl: './date-input.html',
	styleUrls: ['../uf-input.less', './date-input.less'],
})
export class DateInputComponent implements OnDestroy {

	@ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement>;
	@Input() step: number | string | null | undefined;
	@Output() touchChange = new EventEmitter<boolean>();

	protected inputControl = new UfControl();
	protected focused = false;
	protected nativeControl: UfControl | null;
	protected nativeInputType: string;
	protected inputIcon: string;
	protected dateConverter: DateConverter;

	private pickerOpenPromise: Promise<string | undefined> | undefined;
	private pickerValueSubject = new Subject<string | null>();
	private pickerErrorSubject = new Subject<string | null>();
	private subscriptions = new Subscription();
	private controlValueChangesSub: Subscription | null;
	private errorSubscription: Subscription | null;
	private _control: UfControl = new UfControl();
	private _format: string | undefined;
	private _type: TemporalInputDataTypes = DataType.Date;
	private _placeholder: string | null;
	private isAmPmFormat: boolean;
	private inputErrorMessage: string;
	private useNativeInput: boolean;
	private deviceInfo = inject(DeviceInfo);
	private translate = inject(TranslateService);
	private modalService = inject(ModalService);
	private platformId = inject<object>(PLATFORM_ID);

	constructor() {
		// Use basic (native) input when device or run environment is Node (universal)
		this.useNativeInput = this.deviceInfo.useNativeInput() || !isPlatformBrowser(this.platformId);

		if (this.useNativeInput) {
			this.nativeControl = new UfControl();
			this.subscriptions.add(this.nativeControl.valueChanges.subscribe((value: string) => { this.onNativeInputControlValueChange(value); }));
		}

		this.inputControl.setValidators([ValidatorFunctions.custom((v: string) => this.isValidInputValue(v), this.inputErrorMessage)]);
		this.subscriptions.add(this.inputControl.valueChanges.subscribe((value: string) => { this.onInputControlValueChange(value); }));
	}

	ngOnDestroy() {
		this.controlValueChangesSub?.unsubscribe();
		this.errorSubscription?.unsubscribe();
		this.subscriptions.unsubscribe();
	}

	@Input() set type(v: `${TemporalInputDataTypes}` | undefined) {

		if (!v) {
			return;
		}

		this._type = v as TemporalInputDataTypes;

		this.dateConverter = new DateConverter(this._type, this.format);

		switch (v) {
			case DataType.Date:
				this.nativeInputType = 'date';
				this.inputErrorMessage = this.translate.instant(CommonTranslationKey.DateInvalidErrorMessage) as string;
				this.inputIcon = 'date';
				break;
			case DataType.Time:
				this.nativeInputType = 'time';
				this.inputErrorMessage = this.translate.instant(CommonTranslationKey.TimeInvalidErrorMessage) as string;
				this.inputIcon = 'time';
				break;
			case DataType.DateTime:
				this.nativeInputType = 'datetime-local';
				this.inputErrorMessage = this.translate.instant(CommonTranslationKey.DatetimeInvalidErrorMessage) as string;
				this.inputIcon = 'dateTime';
				break;
		}
	}

	get type(): TemporalInputDataTypes {
		return this._type;
	}

	@Input() set format(v: string | undefined) {

		this._format = v;

		this.dateConverter = new DateConverter(this.type, this._format);
		this.isAmPmFormat = this.dateConverter.inputFormat.includes('a');

		this.setInputValue(this.value);
	}

	get format(): string | undefined {
		return this._format;
	}

	/**
	 * @description
	 * the value input aligns input value when value is passed down via an input binding
	 */
	@Input() set value(v: string | undefined | null) {
		this.setInputValue(v);
	}

	get value(): string | null {
		return this.control.value as string | null;
	}

	@Input() set control(v: UfControl) {

		if (this._control.value) {
			v.setValue(this._control.value, { emitEvent: false });
		}

		this._control = v;

		this.controlValueChangesSub?.unsubscribe();

		if (typeof this._control.value === 'string' && !this.validateControlValue(this._control.value)) {
			this._control.setValue(undefined);
		}

		if (this._control.value) {
			this.nativeControl?.setValue(this._control.value);
		}

		this.setInputValue(this._control.value as string | null);
		this.controlValueChangesSub = this._control.valueChanges.subscribe((value: string | null) => { this.onValueChange(value); });
	}

	get control() : UfControl {
		return this._control;
	}

	@Input() set placeholder(v: string | null | undefined) {
		this._placeholder = typeof v === 'string' ? v : this.dateConverter.placeholderFormat;
	}

	get placeholder(): string | null {
		return this._placeholder;
	}

	@HostBinding('class.disabled')
	get disabled() {
		return this.control.disabled;
	}

	async onFocus(event: FocusEvent) {

		if (this.disabled || this.useNativeInput) {
			return;
		}

		this.setInputValue(this.value);

		if (this.pickerOpenPromise) {
			return;
		}

		this.pickerOpenPromise = this.createPickerRequest(event);
		const pickerResponse = await this.pickerOpenPromise;

		this.control.markAsTouched();

		this.pickerOpenPromise = undefined;

		if (!pickerResponse) {
			return;
		}
		this.control.setValue(pickerResponse);
	}

	onBlur() {
		this.focused = false;

		if (this.disabled) {
			return;
		}

		this.control.markAsTouched();
		this.touchChange.emit(true);
		this.pickerErrorSubject.next(this.mergedErrorMessages ?? null);
	}

	setControlValue() {
		if (this.disabled) {
			return;
		}

		const modelValue = typeof this.inputControl.value === 'string' ?this.dateConverter.fromInputToModel(this.inputControl.value) : undefined;

		this.control.setValue(modelValue);
	}

	private createPickerRequest(event: FocusEvent): Promise<string | undefined> {

		return this.modalService.openAnchor(
			DatetimePickerComponent,
			event,
			{
				target: this.input.nativeElement,
				originX: ModalPositionAlignment.Left,
				originY: ModalPositionAlignment.Bottom,
				alignmentX: ModalPositionAlignment.Left,
				alignmentY: [ModalPositionAlignment.Bottom, ModalPositionAlignment.Top],
			},
			this.pickerData,
			{
				update: this.onPickerUpdate.bind(this),
			},
		);
	}

	private setInputValue(value: string | undefined | null, emitEvent = false) {

		let inputValue: string | undefined;
		let nativeValue: string | undefined;

		if (value) {

			if (this.focused) {
				inputValue = this.dateConverter.fromModelToInput(value);
			} else {
				inputValue = this.dateConverter.fromModelToDisplay(value);
			}

			nativeValue = this.fromModelToNativeValue(value);
		}

		if (inputValue === this.inputControl.value) {
			return;
		}

		this.inputControl.setValue(inputValue, { emitEvent });
		this.nativeControl?.setValue(nativeValue, { emitEvent });
	}

	private validateControlValue(value: string): boolean {
		return DateTimeFunctions.isValidFormat(value, this.dateConverter.modelFormat);
	}

	private get pickerData(): DateTimePickerData {

		if (!this.control.value && !this.inputControl.value) {
			const modelValue = format(new Date(), this.dateConverter.modelFormat);

			this.setInputValue(modelValue, true);
		}

		if (this.control.touched && !this.errorSubscription) {
			this.errorSubscription = this.control.statusChanges.subscribe(() => {
				this.pickerErrorSubject.next(this.mergedErrorMessages ?? null);
			});
		}

		return {
			type: this.type,
			value: this.value,
			valueSubject: this.pickerValueSubject,
			step: typeof this.step === 'string' ? +this.step : this.step,
			amPm: this.isAmPmFormat,
			errorSubject: this.pickerErrorSubject,
			errorMessage: this.control.touched ? this.mergedErrorMessages : undefined,
		};
	}

	private get mergedErrorMessages(): string | undefined {
		return (this.inputControl.errors?.message ?? this.control.errors?.message) as string | undefined;
	}

	private onPickerUpdate(value?: string) {
		this.control.setValue(value);
	}

	private onValueChange(value: string | null) {

		const normalizedValue = value != null ? this.dateConverter.normalizeModelValue(value) : value;

		if (!this.focused) {
			this.setInputValue(normalizedValue);
		}
		/**
		 * Reset control if invalid date set, this should only happen by someone setting control programmatically
		 */
		if (this.control.value !== normalizedValue) {
			this.control.setValue(normalizedValue);
		}

		if (this.control.touched) {
			this.control.updateValueAndValidity({ emitEvent: false });
			this.pickerErrorSubject.next(this.mergedErrorMessages ?? null);
		}
	}

	private onInputControlValueChange(value: string) {

		const modelValue = this.dateConverter.fromInputToModel(value);

		// modelValue and value can be both falsy or both truthy
		if (modelValue !== this.control.value && !modelValue === !value) {
			this.control.setValue(modelValue);
		}

		if (this.control.touched) {
			this.control.updateValueAndValidity({ emitEvent: false });
			this.pickerErrorSubject.next(this.mergedErrorMessages ?? null);
		}
		this.pickerValueSubject.next(modelValue ?? null);
	}

	private onNativeInputControlValueChange(value: string) {
		let inputValue: string | undefined | null;

		if (this.type === DataType.Time) {
			inputValue = this.dateConverter.convertValue(value, TwentyFourHourTimeFormat, ShortTimeFormat);
		} else {
			const date = DateTimeFunctions.parseFallbackISO(value, this.dateConverter.modelFormat);

			inputValue = date ? format(date, this.dateConverter.inputFormat) : null;
		}

		this.inputControl.setValue(inputValue);

		this.setControlValue();
	}

	private isValidInputValue(v: string): boolean {
		if (!v) {
			return true;
		}

		return [this.dateConverter.inputFormat, this.dateConverter.displayFormat].some((f) => DateTimeFunctions.isValidFormat(v, f));
	}

	private fromModelToNativeValue(value: string) {
		return this.type !== DataType.Time ? value :
			this.dateConverter.convertValue(value, this.dateConverter.modelFormat, TwentyFourHourTimeFormat);
	}

}
