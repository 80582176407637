import { Type } from '@angular/core';
import { FieldType } from '@unifii/sdk';

import { ComponentRegistryType, ContentField, FormComponentRegistryOptions } from '@unifii/library/smart-forms';
import { UfDescriptionListRegistry } from '@unifii/library/smart-forms/display';

// Break circular dependency
import * as containers from '../components/fields/containers';
import * as inputs from '../components/fields/inputs';
import * as repeats from '../components/fields/repeats';

export class UfFormDescriptionListRegistry extends UfDescriptionListRegistry {

	override type = ComponentRegistryType.Display;

	override get(name: string, options?: FormComponentRegistryOptions): Type<ContentField> | null {

		switch (name as FieldType) {
			// Structural Fields
			case FieldType.Section:
				return containers.SectionComponent;
			case FieldType.Group:
			case FieldType.Stepper:
			case FieldType.Step:
				return containers.GroupComponent;
			case FieldType.ActionGroup:
				return containers.ActionGroupComponent;
			case FieldType.Content:
				return inputs.FieldContentComponent;
			case FieldType.Carousel:
				return containers.ContentCarouselComponent;
			case FieldType.Separator:
				return containers.SeparatorComponent;
			case FieldType.Survey:
				return containers.SurveyDescriptionComponent;	
			case FieldType.Repeat:
				if (!options?.isDataSourceMappingField) {
					return repeats.RepeatComponent;
				}
				break;
		}

		return super.get(name, options);
	}

}
