<!-- Additionally display unselected options with expander -->
<ng-template [ngIf]="unselectedOptions.length" [ngIfElse]="noUnselectedOptions">
	<uf-expander actionBtn="true" isExpanded="false">
		<div class="uf-expander-header primary" expanderHeader>
			<ng-container *ngTemplateOutlet="selectedOptionsTemplate; context: {$implicit: selectedOptions}" />
		</div>
		<div class="options" expanderBody>
			<div *ngFor="let option of unselectedOptions">
				<ng-container *ngTemplateOutlet="optionTemplate; context: {$implicit: option}" />
			</div>
		</div>
	</uf-expander>
</ng-template>

<!-- All options selected, no expander needed -->
<ng-template #noUnselectedOptions>
	<ng-container *ngTemplateOutlet="selectedOptionsTemplate; context: {$implicit: selectedOptions}" />
</ng-template>

<!-- Selected options display template -->
<ng-template #selectedOptionsTemplate let-selectedOptions>
	<div class="selected-options">
		<div *ngFor="let option of selectedOptions" class="selected-option">
			<ng-container *ngTemplateOutlet="optionTemplate; context: {$implicit: option}" />
		</div>
		<ng-container *ngTemplateOutlet="nothingSelectedTemplate; context: {$implicit: selectedOptions}" />
	</div>
</ng-template>

<!-- Option display template -->
<ng-template #optionTemplate let-option>
	<div class="row">{{option.name}}
		<button *ngIf="option.content" [data]="{ markdown: option.content }" contentViewerLink type="button"
			class="uf-action quaternary xx-small">
			<uf-icon name="arrowForward" />
		</button>
	</div>
</ng-template>

<!-- Nothing Selected -->
<ng-template #nothingSelectedTemplate let-selectedOptions>
	<div *ngIf="!selectedOptions.length" class="placeholder">{{
		commonTK.LabelNothingSelected | translate }}</div>
</ng-template>