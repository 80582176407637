import { Component, ElementRef, ViewChild } from '@angular/core';

import { ColumnInfo } from '../../components';

interface FontInfo {
	element: string;
	size?: string;
	lineHeight?: string;
	topMargin?: string;
	bottomMargin?: string;
	typeface?: string;
	fontWeight?: string;
}

@Component({
	selector: 'sc-show-body-copy',
	templateUrl: './show-body-copy.html',
})
export class ShowBodyCopyComponent {

	@ViewChild('bodyCopyElement') set bodyCopyElement(v: ElementRef<HTMLElement> | null) {
		if (v != null) {
			this._bodyCopyElement = v;
		}
	}

	get bodyCopyElement() {
		return this._bodyCopyElement ?? null;
	}

	readonly defaultTableInfo: FontInfo[] = [
		{ element: 'H1', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ element: 'H2', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ element: 'H3', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ element: 'H4', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ element: 'H5', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ element: 'H6', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ element: 'p', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ element: 'ol', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ element: 'ul', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ element: 'li', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ element: 'a', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
	];

	readonly tableConfig: ColumnInfo[] = [
		{ identifier: 'element', name: 'Scale' },
		{ identifier: 'size', name: 'Size' },
		{ identifier: 'lineHeight', name: 'Line Height' },
		{ identifier: 'topMargin', name: 'Top Margin' },
		{ identifier: 'bottomMargin', name: 'Bottom Margin' },
		{ identifier: 'typeface', name: 'Typeface' },
		{ identifier: 'fontWeight', name: 'Font Weight' },
	];

	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];

	readonly cssTableInfo = [
		{ name: 'body-copy', type: 'modifier', description: 'Typography Overides for rich content' },
	];

	rows: FontInfo[];
	markdown = `# Markdown

Markdown is a text to HTML conversion using a formatting syntax. This means you can use standard characters to format your content without us having to hardcode the designs. Markdown is used for formatting like Bold and italics, in-text URL links or bullet or numbered lists.

Markdown can be used in Help Text in your form fields and also in Multi Text elsewhere in Unifii. Use preview to confirm your markdown is correct.

### Bold

Two asterisks at the beginning and end of your content will bold that content. Make sure there are no spaces between the asterisks and the content.

**Lorem ipsum dolor sit amet, ut est vivendum singulis atomorum, magna dissentiet ut sit. Usu no modo doming ceteros, at quas malorum conceptam vim, vidisse deleniti consetetur et vix.**

### Italics

One asterisk at the beginning and end of your content will italicise that content. Make sure there are no spaces between the asterisks and the content.

*Lorem ipsum dolor sit amet, ut est vivendum singulis atomorum, magna dissentiet ut sit. Usu no modo doming ceteros, at quas malorum conceptam vim, vidisse deleniti consetetur et vix.*

### Quotes

> You can indent text as a quote by putting a > at the beginning of the text.

This can be styled for your project during scoping and design.

### Quotes

\`inline code\`

\`\`\`
var i = 0;
i = i++;
\`\`\`

### Headings

There are 4-6 heading styles. These come as a default however they can be customised for your project if required. Discuss this with us during your project's scoping and design.

Headings are created by putting a certain number of ## symbols (followed by a space) at the beginning of your text.

# Heading 1
## Heading 2
### Heading 3
#### Heading 4
##### Heading 5
###### Heading 6

Using all ODD or all EVEN headings creates a nice balance between styles in your content.

### Paragraphs

To create an extra line space in your content, you will need to enter two returns/enter. This will ensure your content breaks where you want it to.

Lorem ipsum dolor sit amet, ut est vivendum singulis atomorum, magna dissentiet ut sit. Usu no modo doming ceteros,  vidisse deleniti consetetur et vix.

Soleat ignota saperet id nec, modus decore pertinacia sed ut.  Salutandi facilisis democritum ne vis, eam id laboramus torquatos.

###### Starting text on a new line

To move your content onto a new line without a blank line in between - you will need to put two spaces at the end of the sentence, then press return/enter.

Lorem ipsum dolor sit amet,
ut est vivendum singulis atomorum,
magna dissentiet ut sit.
Usu no modo doming ceteros,
at quas malorum conceptam vim,
vidisse deleniti consetetur et vix.

To add a non-breaking space with a return (text/images won't wrap next to it), use "& nbsp;" (without "" and the space after the &).

### Lists

###### Bullet point lists

To create a bullet point list, you will need to enter a hyphen (or asterisks) and a space before each point, followed by pressing return/enter.

There must be an empty line before a list in order for it to format correctly, and don't leave an empty line between points.

Create a second level of indentations by putting **two** spaces before the hyphen.

- Point 1
- Point 2
- Point 3
	- Point 3a
	- Point 3b
- Point 4

###### Numbered lists

To create a numbered list, you will need to enter a number, full stop and a space before each point, followed by pressing return/enter.

There must be an empty line before a list in order for it to format correctly, and don't leave an empty line between points.

Create a second level of indentations by putting **three** spaces before the number.

1. Point 1
2. Point 2
3. Point 3
	1. Point 3a
	2. Point 3b
4.  Point 4


### Links

###### URL links

[To create a link to a website](https://www.unifii.com.au), enter the text you want to display as the link in square brackets [ ] followed by the link in round brackets ( ). Please ensure you enter http:// before the url. Make sure there are no spaces.

###### Email links

[To create an email link](mailto:info@unifii.com.au), enter the text you want to display as the link in square brackets [ ] followed by mailto: and the email address in round brackets ( ). Make sure there are no spaces.

###### Phone links

[To create a phone link](tel:+61299134100), enter the text you want to display as the link in square brackets [ ] followed by tel: and the phone number in international format in round brackets ( ). Make sure there are no spaces.

### Images
!img(746 position=floatLeft width=33%)
!img(746 position=floatRight width=33%)
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ut arcu et libero mattis feugiat sed eget diam. Vivamus lacus ante, ullamcorper quis purus vel, luctus dignissim purus. Suspendisse sed magna ante. Nunc sit amet orci id felis commodo venenatis. Cras lectus augue, scelerisque quis pharetra suscipit, pretium quis purus. Nunc ante diam, tempor eu elit quis, sagittis varius leo. Etiam varius lacus at scelerisque tincidunt.
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ut arcu et libero mattis feugiat sed eget diam. Vivamus lacus ante, ullamcorper quis purus vel, luctus dignissim purus. Suspendisse sed magna ante. Nunc sit amet orci id felis commodo venenatis. Cras lectus augue, scelerisque quis pharetra suscipit, pretium quis purus. Nunc ante diam, tempor eu elit quis, sagittis varius leo. Etiam varius lacus at scelerisque tincidunt.
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ut arcu et libero mattis feugiat sed eget diam. Vivamus lacus ante, ullamcorper quis purus vel, luctus dignissim purus. Suspendisse sed magna ante. Nunc sit amet orci id felis commodo venenatis. Cras lectus augue, scelerisque quis pharetra suscipit, pretium quis purus. Nunc ante diam, tempor eu elit quis, sagittis varius leo. Etiam varius lacus at scelerisque tincidunt.
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ut arcu et libero mattis feugiat sed eget diam. Vivamus lacus ante, ullamcorper quis purus vel, luctus dignissim purus. Suspendisse sed magna ante. Nunc sit amet orci id felis commodo venenatis. Cras lectus augue, scelerisque quis pharetra suscipit, pretium quis purus. Nunc ante diam, tempor eu elit quis, sagittis varius leo. Etiam varius lacus at scelerisque tincidunt.
!img(746 position=stretch)`;

	private _bodyCopyElement?: ElementRef<HTMLElement>;

	updateSize() {

		if (!this.bodyCopyElement) {
			return;
		}

		const valorizedBodyCopyElement = this.bodyCopyElement;

		// hack to wait for new cycle of dom update
		setTimeout(() => {
			this.rows = this.defaultTableInfo.map((info) => {
				info = Object.assign({}, info);
				const element = valorizedBodyCopyElement.nativeElement.querySelector(info.element) as unknown as HTMLElement;
				const style = getComputedStyle(element);

				info.size = style.fontSize;
				info.lineHeight = style.lineHeight;
				info.topMargin = style.marginTop;
				info.bottomMargin = style.marginBottom;
				info.typeface = style.fontFamily;
				info.fontWeight = style.fontWeight;

				return info;
			});
		}, 0);

	}

}
