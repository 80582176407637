import { Component, Input } from '@angular/core';
import { isDictionary } from '@unifii/sdk';

import { DataDisplayItems, DataDisplayListItem } from '../../models';

/** Enforced array type for data attribute */
export type DataDisplayListItemForDisplay = Omit<DataDisplayListItem, 'data'> & {
	data: unknown[];
}

@Component({
	selector: 'uf-data-display-list',
	templateUrl: './data-display-list.html',
	styleUrls: ['./data-display-list.less'],
})
export class DataDisplayListComponent {

	protected displayItems: DataDisplayListItemForDisplay[] = [];
	private _items: DataDisplayItems | null | undefined;

	@Input() set items(items: DataDisplayItems | null | undefined) {

		this._items = items;

		if (!items) {
			this.displayItems = [];

			return;
		}

		if (isDictionary(items)) {
			items = Object.entries(items).map((entries) => this.toDataDisplayItem(entries[0], entries[1]));
		}

		this.displayItems = items.map((item) =>	Object.assign(
			{},
			item,
			{
				data: Array.isArray(item.data) ? item.data : [item.data],
			},
		));
	}

	get items() {
		return this._items;
	}

	private toDataDisplayItem(key: string, value: unknown): DataDisplayListItem {
		// Remove underscore
		const parsedKey = key.replace(/_/g, '');
		// First Character to uppercase
		const term = parsedKey.charAt(0).toUpperCase() + parsedKey.split(/(?=[A-Z])/).join(' ').slice(1);
		const description = value;

		return { term, data: description };
	}

}
