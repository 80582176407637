import { Component } from '@angular/core';

import * as Animations from '../../constants';
import { ToastData } from '../../models';
import { ToastService } from '../../services';

@Component({
	selector: 'uf-toaster, uf-toast-container',
	template: '<uf-toast *ngFor="let toast of toasts" [data]="toast" [@toastAnimation] />',
	styleUrls: ['./toaster.less'],
	animations: [Animations.toastAnimation],
})
export class ToasterComponent {

	/** Value is updated by toast service */
	toasts: ToastData[] = [];

	constructor(service: ToastService) {
		service.registerToaster(this);
	}

}
