import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FilterEntry, FilterViewMode, IFilterComponent } from '../../../models';

@Component({
	selector: 'uf-filter-text',
	templateUrl: './filter-text.html',
	styleUrls: ['./field-filter.less'],
})
export class FilterTextComponent implements IFilterComponent<string> {

	@Input() mode = FilterViewMode.Input;
	@Input({ required: true }) entry: FilterEntry;
	@Input({ required: true }) value: string | null = null;
	@Output() valueChange = new EventEmitter<string | null>();

	protected readonly modes = FilterViewMode;

	get isEmpty(): boolean {
		return !this.value;
	}

	get label(): string {
		return this.entry.label;
	}

	protected remove() {
		this.value = null;
		this.valueChanged();
	}

	protected valueChanged() {
		this.valueChange.emit(this.value ?? null);
	}

}
