<!-- DataSeed visible mappings -->
<td *ngFor="let visibleLabel of dataSeedVisibleLabels">
	<uf-data-display *ngFor="let data of dataSeedVisibleItems[visibleLabel.to] ?? []" [data]="data" />
</td>

<!-- Repeat fields -->
<ng-container *ngFor="let field of visibleFields">
	<td [field]="field" [scope]="scope" uf-repeat-cell></td>
</ng-container>
<td *ngIf="!control.disabled || hiddenColumns.length || control.showError" class="no-padding sticky-cell">
	<div class="row center-all">
		<uf-icon *ngIf="control.showError" name="error" class="error right" />
		<button *ngIf="hiddenColumns.length" [title]="sharedTermsTK.ActionShowMore | translate"
			[ngClass]="{'right': !control.showError}" type="button" class="uf-action tertiary small">
			<uf-icon name="arrowForward" />
		</button>
		<button *ngIf="showAddDeleteButton" [title]="sharedTermsTK.ActionRemove | translate"
			[class.right]="!hiddenColumns.length && !control.showError" (click)="removeItem($event)" type="button"
			class="uf-action tertiary small">
			<uf-icon name="delete" />
		</button>
	</div>
</td>