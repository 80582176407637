import { Inject, Injectable, Optional } from '@angular/core';
import { FieldType, FormStyle, generateUUID } from '@unifii/sdk';

import { ContextProvider, RuntimeDefinition, RuntimeField, ThemeProvider, fieldIterator } from '@unifii/library/common';

import { FieldHelperFunctions } from '../utils';

export interface FormConfiguration {
	hideLabel?: boolean;
	optionalSubmitButtonLabel?: string;
	optionalCancelButtonLabel?: string;
}

/**
 * @description
 * Stateful service that provides additional information for the field components, such as:
 * Pending Uploads, Definition Settings, Additional Configuration
 * Role Based Visibility, Field Base Visibility
 */
@Injectable()
export class FormService {

	private definition: RuntimeDefinition | null;
	private grantedFields = new Map<RuntimeField, boolean>(); // Visibility base on roles
	private _configuration: FormConfiguration = {};
	private _disabled = false;
	private progresses = new Set<string>();

	constructor(
		@Inject(ContextProvider) private contextProvider: ContextProvider,
		@Optional() @Inject(ThemeProvider) private themeProvider: ThemeProvider | null,
	) { }

	get definitionSettings() {
		return this.definition?.settings ?? {};
	}

	set configuration(v: FormConfiguration | undefined | null) {
		this._configuration = v ?? {};
	}

	get configuration(): FormConfiguration {
		return this._configuration;
	}

	get style(): FormStyle {
		return this.themeProvider?.theme.formStyle ?? FormStyle.Summary;
	}

	get definitionIdentifier() {
		return this.definition?.identifier;
	}

	/**
	 * @description
	 * Indicates that workflow and inputs should be disabled
	 */
	get disabled() {
		return this._disabled;
	}

	/**
	 * @description
	 * Indicates that a async action is in progress. eg: upload, download, etc
	 */
	get inProgress() {
		return this.progresses.size > 0;
	}

	/**
	 * @returns registration id that is used to deregister the same progress event
	 */
	registerInProgress(): string {
		const registrationId = generateUUID();

		this.progresses.add(registrationId);

		return registrationId;
	}

	deregisterInProgress(registrationId: string) {
		this.progresses.delete(registrationId);
	}

	/**
	 * @description
	 * Checks current user permissions against field permissions
	 */
	isGranted(field: RuntimeField) {
		return this.grantedFields.get(field) ?? false;
	}

	init(definition: RuntimeDefinition, disabled = false) {

		this._disabled = disabled;
		this.definition = definition;
		this.grantedFields.clear();

		const roles = this.contextProvider.get().user?.roles;

		for (const { field } of fieldIterator(definition.fields)) {

			let granted: boolean;

			if (field.type === FieldType.Section) {
				granted = FieldHelperFunctions.areRolesMatching(roles, field.roles);
			} else {
				granted = FieldHelperFunctions.areRolesMatching(roles, field.visibleTo);
			}
			this.grantedFields.set(field, granted);
		}
	}

	checkFieldAndParentGranted(field: RuntimeField): boolean {
		const isGranted = this.isGranted(field);

		if (!isGranted) {
			return false;
		}

		if (field.parent) {
			return this.checkFieldAndParentGranted(field.parent);
		}

		return isGranted;
	}

}
