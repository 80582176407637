import { Directive, HostListener, Input, inject } from '@angular/core';

import { ContentModalComponent, ContentModalData } from '../components';
import { ModalDialogSize, ModalService } from '../services';

@Directive({ selector: '[contentViewerLink]' })
export class ContentModalLinkDirective {

	@Input({ required: true }) data: ContentModalData;
	@Input() modalSize: ModalDialogSize = 'medium';

	private modalService = inject(ModalService);

	@HostListener('click', ['$event'])
	onClick(e: PointerEvent) {
		e.preventDefault();
		e.stopPropagation();

		switch (this.modalSize) {
			case 'fit':
				void this.modalService.openFit(ContentModalComponent, this.data);
				break;
			case 'medium':
				void this.modalService.openMedium(ContentModalComponent, this.data);
				break;
			case 'large':
				void this.modalService.openLarge(ContentModalComponent, this.data);
				break;
			case 'fullScreen':
				void this.modalService.openFullScreen(ContentModalComponent, this.data);
				break;	
		}
	}

}
