<button (click)="toggleMenu.emit()" type="button" class="uf-action tertiary hamburger">
	<uf-icon name="hamburger" />
</button>
<uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="grow small" />
<uf-autocomplete [options]="nodeOptions" (valueChange)="routeTo($event)" (searchChange)="searchNodes($event)"
	placeholder="Search pages" nameProperty="name">
	<ng-template #listBox let-options="options" let-select="select" let-active="active">
		<ul class="uf-list" style="max-height: 500px;">
			<li *ngFor="let option of options; let i = index" [class.active]="i === active" (click)="select(option)"
				class="uf-list-button small">
				{{ option.name }}
			</li>
		</ul>
	</ng-template>
</uf-autocomplete>
<ng-content />