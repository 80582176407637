import { Component, EventEmitter, OnInit } from '@angular/core';
import { FieldTemplate, FieldType, Option } from '@unifii/sdk';

import { RuntimeField, UfControl } from '@unifii/library/common';
import { FormField } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-multi-choice-input',
	templateUrl: './multi-choice.html',
	styleUrls: ['./multi-choice.less'],
})
export class MultiChoiceComponent implements FormField, OnInit {

	readonly fieldType = FieldType;
	readonly fieldTemplate = FieldTemplate;

	field: RuntimeField;
	content: Option[];
	control: UfControl;
	contentChange: EventEmitter<any>;
	suffix: string;
	cssClass: string | string[];
	filteredOptions: Option[];

	ngOnInit() {
		this.filteredOptions = this.field.options;
	}

	changed(v: any) {
		this.contentChange.emit(v);
	}

	search(v?: string) {
		this.filteredOptions = this.field.options.filter((f) => !v || f.name.toLowerCase().includes(v.toLowerCase().trim()));
	}

	get rows(): number | undefined {

		if (this.field?.layoutDirection !== 'Column' || !this.field.options.length || !this.field.columnCount) {
			return;
		}

		return Math.ceil(this.field.options.length / this.field.columnCount);
	}

}
