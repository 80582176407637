<div class="message-wrap">
	<div *ngIf="icon" class="icon-wrap">
		<uf-icon [name]="icon" class="type-icon" />
	</div>
	<div [class.expanded]="isExpanded || !showExpander" [class.gradient]="!isExpanded && showExpander"
		class="content grow">
		<div class="body-copy small">
			<ng-content></ng-content>
			<div *ngIf="content" [markdownRenderer]="content"></div>
		</div>
		<div *ngIf="actions?.length" class="actions row space-children wrap">
			<button *ngFor="let action of actions" [ngClass]="action.type ?? ''" (click)="run(action.action)"
				type="button" class="uf-button small">
				{{ action.label }}
				<uf-icon *ngIf="action.icon" [name]="action.icon" />
			</button>
		</div>
	</div>
	<div *ngIf="showExpander" class="button-wrap right">
		<uf-expander-button [isExpanded]="isExpanded" [actionBtn]="true" [class.tinted]="!isExpanded" (click)="toggle()"
			class="small" />
	</div>
</div>