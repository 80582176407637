import { Component, Injector, Input, Optional } from '@angular/core';
import { Compound } from '@unifii/sdk';

import { RuntimeDefinition } from '@unifii/library/common';
import { ComponentRegistry } from '@unifii/library/smart-forms';

import { CollectionItemComponentRegistry, UfCollectionItemComponentRegistry } from '../../services';

export const collectionItemComponentRegistryFactory = (collectionItemComponentRegistry: ComponentRegistry | null): ComponentRegistry => {

	if (collectionItemComponentRegistry == null) {
		// Create default registry
		return Injector.create({
			providers: [{ provide: UfCollectionItemComponentRegistry, useClass: UfCollectionItemComponentRegistry }],
		}).get(UfCollectionItemComponentRegistry);
	}

	return collectionItemComponentRegistry;
};

@Component({
	selector: 'uf-collection-item',
	templateUrl: './collection-item.html',
	providers: [{ provide: ComponentRegistry, useFactory: collectionItemComponentRegistryFactory, deps: [[new Optional(), CollectionItemComponentRegistry]] }],
})
export class UfCollectionItemComponent {

	@Input() definition: RuntimeDefinition;
	@Input() compound: Compound;

	@Input() set classList(v: string[]) {
		this._classList = v;
	}

	get classList(): string[] {
		return this._classList;
	}

	private _classList = ['grid--fixed', 'body-copy'];

}
