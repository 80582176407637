<div class="field-renderer grid--no-padding">
	<ng-template [ngForOf]="definition.fields" let-field ngFor>
		<ng-template [ngIf]="field.identifier" [ngIfElse]="nonContent">
			<uf-field [field]="field" [scope]="formData" [(content)]="formData[field.identifier]" />
		</ng-template>
		<ng-template #nonContent>
			<uf-field [field]="field" [scope]="formData" />
		</ng-template>
		<uf-break-after *ngIf="field.breakAfter" />
	</ng-template>
</div>