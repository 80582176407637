import { Directive, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Directive({
	selector: '[ufAutofocus]',
})
export class AutoFocusDirective implements OnInit, OnDestroy {

	readonly querySelector = 'input, select, textarea, button, *[tabindex], *[contenteditable], a[href]';

	private subscriptions = new Subscription();

	constructor(private el: ElementRef<HTMLElement>, private zone: NgZone) { }

	ngOnInit() {

		const element = this.getFocusableElement(this.el);

		if (element != null) {
			// TODO How to for a ChangeDetectorRef on the target element ?

			// Current implementation that lead to autofocus.directive.spec.test to fail
			// setTimeout(() => element.focus(), 0);
			// Implementation that succeed the tests, is this ok? Why the current implementation has a setTimeout
			const onStable = this.zone.onStable.pipe(first());

			this.subscriptions.add(
				onStable.subscribe(() => { this.zone.runTask(() => { element.focus(); }); }),
			);
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	getFocusableElement(el: ElementRef<HTMLElement>): HTMLElement | undefined {

		const htmlElement = el.nativeElement;

		if (this.canFocus(htmlElement)) {
			return htmlElement;
		}

		const children = htmlElement.querySelectorAll<HTMLElement>(this.querySelector);
		const filtered = Array.from(children).filter((selected) => this.canFocus(selected));

		if (htmlElement.getAttribute('focus-first')) {
			return filtered[0];
		}

		return filtered[filtered.length - 1];
	}

	private canFocus(htmlElement: HTMLElement) {
		// isn't hidden and has focus method
		return htmlElement.matches(this.querySelector) && !htmlElement.hidden && !(htmlElement as HTMLSelectElement).disabled && htmlElement.tabIndex !== -1;
	}

}
