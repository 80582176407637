import { Component, NgZone, OnDestroy } from '@angular/core';
import { Dictionary, FieldType, FieldWidth, PageField } from '@unifii/sdk';

import { UfControl, ValidatorFunctions } from '@unifii/library/common';

import { SCDataService } from '../../services';

@Component({
	selector: 'sc-show-chips',
	templateUrl: './show-chips.html',
	styleUrls: ['./show-chips.less'],
})
export class ShowChipsInputComponent implements OnDestroy {

	cv: Dictionary<boolean> = {
		disabled: false,
		duplicates: false,
		customs: false,
		required: false,
	};

	config: Dictionary<PageField> = {
		required: { label: 'Required', type: FieldType.Bool, value: this.cv.required, width: FieldWidth.Quarter },
		disabled: { label: 'Disabled', type: FieldType.Bool, value: this.cv.disabled, width: FieldWidth.Quarter },
		duplicates: { label: 'Allow duplicates', type: FieldType.Bool, value: this.cv.duplicates, width: FieldWidth.Quarter },
		customs: { label: 'Allow customs', type: FieldType.Bool, value: this.cv.customs, width: FieldWidth.Quarter },
	};
	ready: boolean;
	control: UfControl;
	complexControl: UfControl;
	value: string[] | null;
	emittedValue: string[];
	results: string[];
	complexValue: Dictionary<any>[] | null;
	complexResults: Dictionary<any>[];
	complextEmittedValue: Dictionary<any>[];

	private resetTimeout?: NodeJS.Timeout;

	constructor(private zone: NgZone, private data: SCDataService) {
		this.control = new UfControl(ValidatorFunctions.custom((v) =>
			!this.cv.required || !ValidatorFunctions.isEmpty(v),
		'A value is required'),
		);

		this.complexControl = new UfControl(ValidatorFunctions.custom((v) =>
			!this.cv.required || !ValidatorFunctions.isEmpty(v),
		'A value is required'),
		);

		this.ready = true;
	}

	ngOnDestroy() {
		clearTimeout(this.resetTimeout);
	}

	async find(query?: string) {
		this.results = await this.data.asyncSearchFruitNames(query, 200);
	}

	async findComplex(query?: string) {
		this.complexResults = await this.data.asyncSearchFruits(query, 200);
	}

	valueChanged(value?: string[]) {
		this.emittedValue = [...(value ?? [])];
	}

	complexValueChanged(value: Dictionary<any>[]) {
		this.complextEmittedValue = value;
	}

	reset() {
		this.ready = false;
		this.value = null;
		clearTimeout(this.resetTimeout);
		this.resetTimeout = setTimeout(() => {
			this.zone.run(() => {
				this.ready = true;
			});
		}, 500);
	}

	protected canDeleteApples = (fruit: string): boolean => fruit.toLowerCase().includes('apple');

}
