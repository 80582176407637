<sc-page-content width="lg" identifier="borders">

	<ng-container editor>
		<sc-code-editor-expander [showCopy]="false" [showCode]="false">
			<div class="row grow wrap">
				<div class="box border-all"></div>
				<div class="box border-top"></div>
				<div class="box border-right"></div>
				<div class="box border-bottom"></div>
				<div class="box border-left"></div>
			</div>
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>