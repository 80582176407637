<sc-page-content width="lg" identifier="secret">

	<ng-container editorInputs>
		<uf-radio [options]="revealOptions" [(value)]="reveal" (valueChange)="modifierChange()" class="col-6"
			label="Reveal" columns="1" valueProperty="identifier" />
		<uf-radio [options]="sizeOptions" [(value)]="size" (valueChange)="modifierChange()" class="col-6" label="Sizes"
			columns="1" valueProperty="identifier" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template">
			<uf-password [ngClass]="size" [showReveal]="reveal === 'true'" label="Secret" />
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">Input</h3>
		<h4 class="pad-sides">uf-password</h4>
		<sc-info-table [columnConfig]="inputTableConfig" [rows]="inputTableInfo" />
		<h3 class="pad-sides">Outputs</h3>
		<sc-info-table [columnConfig]="outputTableConfig" [rows]="outputTableInfo" />
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>