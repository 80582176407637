import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Address, CompanyInfo, ContentLinkFormData, CostModelFormat, DATE_DATA_FORMAT, DATE_TIME_DATA_FORMAT, DataSeed, DataType, FieldOption, FieldType, FileData, GeoLocation, HierarchyPath, HierarchyStep, HierarchyUnitFormData, HierarchyUnitsPath, LengthAtLeast, LinkContentType, Manager, Option, SortDirections, TIME_DATA_FORMAT, ZonedDateTime, castDataByDataType, detectDataType, fieldTypeToDataType, getUserFullName, hasLengthAtLeast, isArrayOfType, isBoolean, isContentLinkFormData, isDataSeed, isDictionary, isGeoLocation, isNotNull, isOption, isOptionalType, isSignature, isString, isStringNotEmpty, isStringTrimmedNotEmpty, isValueOfStringEnumType, objectKeys, verifyDataType } from '@unifii/sdk';
import { format as dateFnsFormat, formatDistanceToNow, isBefore, isToday, isValid, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { DataDisplayAttachmentValue, DataDisplayDataSeedValue, DataDisplayDataSeedVisibleItem, DataDisplayHrefValue, DataDisplayImageValue, DataDisplayLozengeValue, DataDisplayMapMarkerValue, DataDisplayOptionsValue, DataDisplaySignatureValue } from '../../components';
import { DataSourceDisplayTo, DateWeekDayAndTimeDisplayFormat, DateWeekDayDisplayFormat, SEPARATOR_COMMA, SEPARATOR_CR_LF, ShortTimeFormat } from '../../constants';
import { Context, DataDisplayInfo, DataDisplayInfoAddress, DataDisplayInfoBoolean, DataDisplayInfoChoice, DataDisplayInfoDate, DataDisplayInfoDateTime, DataDisplayInfoGeoLocation, DataDisplayInfoHierarchyPath, DataDisplayInfoHierarchyPaths, DataDisplayInfoHierarchyUnit, DataDisplayInfoMultiChoice, DataDisplayInfoNumber, DataDisplayInfoOffsetDateTime, DataDisplayInfoOptions, DataDisplayInfoRepeat, DataDisplayInfoStringArray, DataDisplayInfoTime, DataDisplayInfoZonedDateTime, DataDisplayListItem, LocationProvider, SourceConfig, SourceConfigMapping } from '../../models';
import { UfSourceConfigMappingsPipe } from '../../pipes';
import { CommonTranslationKey } from '../../translations';
import { DateConverter, DateTimeFunctions, UfExpressionFunctionsSet, getContentLinkLabel, isFileDataExtended, pathToDisplay } from '../../utils';
import { ExpressionParser } from '../expression-parser';
import { TemplateStringParser } from '../template-string-parser';

import { DataDisplayService } from './data-display.service';

@Injectable()
export class UfDataDisplayService implements DataDisplayService {
	
	protected translateService = inject(TranslateService);
	protected templateStringParser = inject(TemplateStringParser);
	protected expressionParser = inject(ExpressionParser);
	protected locationProvider = inject(LocationProvider, { optional: true });

	/**
	 * Some descriptor have type not matching the actual data they describe.
	 * This map allow to fallback from a wrong type to a more-likely correct one
	 * based on know cases
	 */
	private readonly typesFallback = new Map([
		[DataType.Phone, [DataType.String]],
		[DataType.Email, [DataType.String]],
		[DataType.Website, [DataType.String]],
		[DataType.DateTime, [DataType.OffsetDateTime]],
		[DataType.String, [
			// DDE use 'Text' to describe Hierarchy, Company and Manager
			DataType.HierarchyPaths, DataType.CompanyInfo, DataType.Manager,
			// UNIFII-7127 Collection DS _id value Number is described as Text by migrated DSs (in DataSeed display)
			DataType.Number,
		]],
		[DataType.StringArray, [DataType.HierarchyPaths]], // mitigate user.unitPaths
		// UNIFII-7127 Collection DS _id value String is described as Number by DDE (in DataSeed display)
		[DataType.Number, [DataType.String]],
	]);

	private readonly extendedFunctions = {
		...UfExpressionFunctionsSet,
		format: this.displayForTemplateExpression.bind(this),
	};

	private mappingsPipe = inject(UfSourceConfigMappingsPipe);

	/**
	 * Based on the data and info provided return the representing DataDisplayValue. This value is displayable with the DataDisplayComponent
	 * 
	 * @param data to be displayed
	 * @param info context info regarding the data
	 */
	// eslint-disable-next-line complexity
	displayAsDataDisplayValue(data: unknown, info?: DataDisplayInfo): unknown {

		// Media Lists
		if (Array.isArray(data) && isArrayOfType(data, isDictionary) && (info?.type === DataType.FileList || info?.type === DataType.ImageList || info?.type === DataType.SoundList || info?.type === DataType.VideoList)) {
			return data.map((item) => {
				if (isStringNotEmpty(item.url) && isStringNotEmpty(item.label)) {
					// AssetProfile (content media)
					return { label: item.label, href: item.url } satisfies DataDisplayHrefValue;
				}
				if (isStringNotEmpty(item.id) && isStringNotEmpty(item.name)) {
					// FileData (form attachment)
					return { label: item.name, attachmentId: item.id } satisfies DataDisplayAttachmentValue;
				}

				return undefined;
			});
		}

		// Options based value by default are displayed as info.separator primitive values. Alternative display DataDisplayOptionsValue is used if explicitly requested
		if ((info?.type === DataType.Choice || info?.type === DataType.MultiChoice || info?.type === DataType.Boolean) && info.mapToDataDisplayOptions) {
			let selected = info.type === DataType.Boolean && isBoolean(data) ? `${data}` : data;

			selected = (Array.isArray(selected) ? selected : [selected]).filter(isNotNull);
			
			if (isArrayOfType(selected, isString)) {
				return {
					options: info.options as FieldOption[] | undefined ?? [],
					selected,
				} satisfies DataDisplayOptionsValue;
			}
		}
		
		// DataSeed
		if (info?.type === DataType.DataSeed && info.sourceConfig && isOptionalType(data, isDataSeed)) {
			return {
				dataSeed: data,
				sourceConfig: info.sourceConfig,
				display: this.getDataSeedDisplayData(data, info.sourceConfig),
				visibleItems: this.getDataSeedVisibleItems(data, info.sourceConfig, info.showDisplayVisibleField),
			} satisfies DataDisplayDataSeedValue;
		}

		const value = this.displayAsString(data, info);

		// Lozenge
		if (value && info?.colour) {
			return {
				label: value,
				colour: info.colour,
			} satisfies DataDisplayLozengeValue;
		}

		// Href
		const href = this.displayAsHref(data, info);

		if (value && href) {
			return {
				href,
				label: value,
				target: '_blank',
			} satisfies DataDisplayHrefValue;
		}

		// GeoLocation
		if (value && (info?.type === DataType.GeoLocation || info?.type === DataType.Address) && isGeoLocation(data)) {
			if (this.locationProvider?.getMapUrl && info.format === 'map') {
				return [
					value,
					{
						imageUrl: this.locationProvider.getMapUrl(data.lat, data.lng, data, data.zoom ?? 15, { width: 640, height: 422 }),
					} satisfies DataDisplayImageValue,
				];
			}

			return {
				marker: { lat: data.lat, lng: data.lng, zoom: data.zoom },
				label: value,
			} satisfies DataDisplayMapMarkerValue;
		}

		// Signature
		if (value && info?.type === DataType.Signature && isSignature(data)) {
			return { signature: value } satisfies DataDisplaySignatureValue;
		}

		// Content Link
		if (value && info?.type === DataType.Link && isContentLinkFormData(data)) {
			const label = getContentLinkLabel(data, this.translateService, info.hideType);

			switch (data.type) {
				case LinkContentType.Attachment:
					return { label,	attachmentId: data.id, displayAsLink: true } satisfies DataDisplayAttachmentValue;
				case LinkContentType.Url:
					return { label,	href: data.id, displayAsLink: true } satisfies DataDisplayHrefValue;
				case LinkContentType.Asset:
				case LinkContentType.Page:
				case LinkContentType.Form:
					return label;
			}
		}

		return value;
	}

	/**
	 * Return a string representation of the data provided in input
	 * @param data to be displayed
	 * @param info context info regarding the data
	 */
	displayAsString(data: unknown, info?: DataDisplayInfo): string | null {

		if (data == null) {
			return null;
		}
		
		const values = this.displayValue(data, info);

		if (!values) {
			return null;
		}

		if (info?.type === DataType.Repeat) {
			info.separator = info.separator ?? SEPARATOR_CR_LF;
		}

		const infoUnknown = info as unknown;

		return this.displayStringArray(values, {
			type: DataType.StringArray,
			separator: isDictionary(infoUnknown) && isStringNotEmpty(infoUnknown.separator) ? infoUnknown.separator : SEPARATOR_CR_LF,
		});
	}

	/**
	 * @deprecated
	 * 
	 * This function is meant to expose DataDisplay logic to TemplateExpression expressions execution
	 * The usage is limited to a subset of FieldType in order to cover legacy existing scenarios
	 * It's used in a documented and limited cases of TemplateExpression executions
	 * Usage to not be extended, the function is marked as deprecated
	 * The goal is to swap this technical solution in favour of the "format" capability of Unifii Expressions Language once ready
	 * @param data the data to display
	 * @param fieldType the type of the data
	 * @param options value to label lookup entries
	 * @param format type specific format info
	 * @returns data formatted as string
	 */
	displayForTemplateExpression(data: unknown, fieldType = `${FieldType.Text}`, _options?: Option[], format?: string): string | null {
		
		if (data == null) {
			return null;
		}
		
		// Upon changes to this list remember to update the detectedInfo guard accordingly
		const templateExpressionAllowedTypes = [FieldType.Date, FieldType.DateTime, FieldType.Hierarchy];

		// Guard requested fieldType
		if (!templateExpressionAllowedTypes.includes(fieldType as FieldType)) {
			console.warn(`DataDisplayService - usage of "format" in template expression for unsupported type "${fieldType}". Only ${templateExpressionAllowedTypes.join(', ')} are supported`);
			
			return null;
		}

		const detectedInfo = detectDataType(data);

		if (detectedInfo.type !== DataType.HierarchyPath &&
			detectedInfo.type !== DataType.HierarchyPaths &&
			detectedInfo.type !== DataType.HierarchyUnit &&
			detectedInfo.type !== DataType.Date &&
			detectedInfo.type !== DataType.DateTime &&
			detectedInfo.type !== DataType.OffsetDateTime
		) {
			return null;
		}

		const info = {
			type: detectedInfo.type,
			format,
		} satisfies DataDisplayInfo;

		return this.displayAsString(data, info);
	}

	// eslint-disable-next-line complexity
	private displayValue(data: unknown, info?: DataDisplayInfo): string[] & LengthAtLeast<string[], 1> | null {

		const type = this.dataTypeGuard(data, info?.type as DataType);

		if (!type) {
			if (!info?.type) {
				return null;
			}

			const fallbackTypes = this.typesFallback.get(info.type as DataType) ?? [];

			for (const fallbackType of fallbackTypes) {
				const value = this.displayValue(data, Object.assign({}, info, { type: fallbackType }));
				
				if (value != null) {
					return value;
				}
			}

			return null;
		}

		const safeInfo: DataDisplayInfo = Object.assign({}, info, { type });

		// Smart amend of type based on unexpected info
		switch (safeInfo.type) {
			case DataType.String:
				if (isArrayOfType((safeInfo as Record<string, unknown>).options, isOption)) {
					(safeInfo as Record<string, unknown>).type = DataType.Choice;
				}
				break;
			case DataType.StringArray:
				if (isArrayOfType((safeInfo as Record<string, unknown>).options, isOption)) {
					(safeInfo as Record<string, unknown>).type = DataType.MultiChoice;
				}
				break;
		}

		let values: string | string[] | null = null;
	
		switch (safeInfo.type) {
			case DataType.Number:
				// eslint-disable-next-line id-denylist
				values = this.displayNumber(castDataByDataType(data, safeInfo.type as DataType.Number), safeInfo);
				break;
			case DataType.Boolean:
				// eslint-disable-next-line id-denylist
				values = this.displayBoolean(castDataByDataType(data, safeInfo.type as DataType.Boolean), safeInfo);
				break;
			case DataType.String:
			case DataType.Phone:
			case DataType.Email:
			case DataType.Website:
			case DataType.Signature:
				// eslint-disable-next-line id-denylist
				values = castDataByDataType(data, safeInfo.type as DataType.String);
				break;
			case DataType.Date:
				values = this.displayDate(castDataByDataType(data, safeInfo.type as DataType.Date), safeInfo);
				break;
			case DataType.Time:
				values = this.displayTime(castDataByDataType(data, safeInfo.type as DataType.Time), safeInfo);
				break;
			case DataType.DateTime:
				values = this.displayDateTime(castDataByDataType(data, safeInfo.type as DataType.DateTime), safeInfo);
				break;
			case DataType.OffsetDateTime:
				values = this.displayOffsetDateTime(castDataByDataType(data, safeInfo.type as DataType.OffsetDateTime), safeInfo);
				break;
			case DataType.ZonedDateTime:
				values = this.displayZonedDateTime(castDataByDataType(data, safeInfo.type as DataType.ZonedDateTime), safeInfo);
				break;
			case DataType.DataSeed:
				values = this.displayDataSeed(castDataByDataType(data, safeInfo.type as DataType.DataSeed));
				break;
			case DataType.Cost:
				values = this.displayCost(castDataByDataType(data, safeInfo.type as DataType.Cost));
				break;
			case DataType.GeoLocation:
				values = this.displayGeoLocation(castDataByDataType(data, safeInfo.type as DataType.GeoLocation), safeInfo);
				break;
			case DataType.Address:
				values = this.displayAddress(castDataByDataType(data, safeInfo.type as DataType.Address), safeInfo);
				break;
			case DataType.Link:
				values = this.displayLink(castDataByDataType(data, safeInfo.type as DataType.Link));
				break;
			case DataType.CompanyInfo:
				values = this.displayCompanyInfo(castDataByDataType(data, safeInfo.type as DataType.CompanyInfo));
				break;
			case DataType.Manager:
				values = this.displayManager(castDataByDataType(data, safeInfo.type as DataType.Manager));
				break;
			case DataType.HierarchyStep:
				values = this.displayHierarchyStep(castDataByDataType(data, safeInfo.type as DataType.HierarchyStep));
				break;
			case DataType.HierarchyPath:
				values = this.displayHierarchyPath(castDataByDataType(data, safeInfo.type as DataType.HierarchyPath), safeInfo);
				break;
			case DataType.HierarchyPaths:
				values = this.displayHierarchyPaths(castDataByDataType(data, safeInfo.type as DataType.HierarchyPaths), safeInfo);
				break;
			case DataType.HierarchyUnit:
				values = this.displayHierarchyUnit(castDataByDataType(data, safeInfo.type as DataType.HierarchyUnit), safeInfo);
				break;
			case DataType.Choice:
				values = this.displayChoice(castDataByDataType(data, safeInfo.type as DataType.Choice), safeInfo);
				break;
			case DataType.MultiChoice:
				values = this.displayMultiChoice(castDataByDataType(data, safeInfo.type as DataType.MultiChoice), safeInfo);
				break;
			case DataType.Repeat:
				values = this.displayRepeat(castDataByDataType(data, safeInfo.type as DataType.Repeat), safeInfo);
				break;
			case DataType.FileList:
				values = this.displayFileList(castDataByDataType(data, safeInfo.type as DataType.FileList));
				break;
			case DataType.ImageList:
				values = this.displayFileList(castDataByDataType(data, safeInfo.type as DataType.ImageList));
				break;
			case DataType.SoundList:
				values = this.displayFileList(castDataByDataType(data, safeInfo.type as DataType.SoundList));
				break;
			case DataType.VideoList:
				values = this.displayFileList(castDataByDataType(data, safeInfo.type as DataType.VideoList));
				break;
			case DataType.StringArray:
				values = castDataByDataType(data, safeInfo.type as DataType.StringArray);
				break;
			default:
				values = null;
				break;
		}

		let results = this.cleanStringArray(Array.isArray(values) ? values : [values]);

		if (!results) {
			return null;
		}

		const infoUnknown = safeInfo as unknown;
		const sort = isDictionary(infoUnknown) && isValueOfStringEnumType(SortDirections)(infoUnknown.sort) ? infoUnknown.sort : undefined;

		switch (sort) {
			case SortDirections.Descending:
				results = results.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1);
				break;
			case SortDirections.Ascending:
				results = results.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? -1 : 1);
				break;
		}

		return results;
	}

	private displayAsHref(data: unknown, info?: DataDisplayInfo): string | null {
		const type = this.dataTypeGuard(data, info?.type as DataType);
		
		if (!type || (type !== DataType.Email && type !== DataType.Phone && type !== DataType.Website)) {
			return null;
		}
	
		const value = this.displayAsString(data, { type });

		if (!isStringTrimmedNotEmpty(value)) {
			return null;
		}

		switch (type) {
			case DataType.Email:
				return `mailto:${value}`;
			case DataType.Phone:
				return `tel:${value}`;
			case DataType.Website:
				return value;
		}

	}

	private displayNumber(value: number, info: DataDisplayInfoNumber): string {
		if (info.format === 'bytes' && value >= 0) {
			return this.getNumberAsBytesDisplay(value);
		}

		if (info.decimals != null) {
			return value.toFixed(info.decimals);
		}

		return `${value}`;
	}

	private displayBoolean(value: boolean, info: DataDisplayInfoBoolean): string {
		const options = info.options ?? [{
			identifier: `${true}`,
			name: this.translateService.instant(CommonTranslationKey.YesLabel) as string,
		}, {
			identifier: `${false}`,
			name: this.translateService.instant(CommonTranslationKey.NoLabel) as string,
		}];

		return this.getOptionValue(`${value}`, options);
	}

	private displayDate(value: string, info: DataDisplayInfoDate): string {
		const date = DateTimeFunctions.parseFallbackISO(value, DATE_DATA_FORMAT);
		
		if (!date) {
			return value;
		}

		return dateFnsFormat(date, new DateConverter(DataType.Date, info.format, DateWeekDayDisplayFormat).displayFormat);
	}

	private displayTime(value: string, info: DataDisplayInfoTime): string {
		const date = DateTimeFunctions.parseFallbackISO(value, TIME_DATA_FORMAT);
		
		if (!date) {
			return value;
		}

		return dateFnsFormat(date, new DateConverter(DataType.Time, info.format, ShortTimeFormat).displayFormat);
	}

	private displayDateTime(value: string, info: DataDisplayInfoDateTime): string {
		const date = DateTimeFunctions.parseFallbackISO(value, DATE_TIME_DATA_FORMAT);
		
		if (!date) {
			return value;
		}

		let displayFormat = new DateConverter(DataType.DateTime, info.format, DateWeekDayAndTimeDisplayFormat).displayFormat;

		if (displayFormat.toLowerCase() !== info.format?.toLowerCase() && displayFormat !== DateWeekDayAndTimeDisplayFormat) {
			displayFormat = DateWeekDayAndTimeDisplayFormat;
		}

		return dateFnsFormat(date, displayFormat);
	}

	private displayOffsetDateTime(value: string, info: DataDisplayInfoOffsetDateTime): string {
		const date = parseISO(value);
		// DateTimeFunctions.parseFallbackISO(value, OFFSET_DATE_TIME_DATA_FORMAT);
		
		if (!isValid(date)) {
			return value;
		}

		if (info.asDistanceFromNow && isToday(date) && isBefore(date, new Date())) {
			return formatDistanceToNow(date, { addSuffix: true });
		}

		let displayFormat = new DateConverter(DataType.OffsetDateTime, info.format, DateWeekDayAndTimeDisplayFormat).displayFormat;

		if (displayFormat.toLowerCase() !== info.format?.toLowerCase() && displayFormat !== DateWeekDayAndTimeDisplayFormat) {
			displayFormat = DateWeekDayAndTimeDisplayFormat;
		}

		return dateFnsFormat(date, displayFormat);
	}

	private displayZonedDateTime(zonedDateTime: ZonedDateTime, info: DataDisplayInfoZonedDateTime): string {
		const date = DateTimeFunctions.parseFallbackISO(zonedDateTime.value, DATE_TIME_DATA_FORMAT);
		
		if (!date) {
			return zonedDateTime.value;
		}

		const safeFormat = new DateConverter(DataType.DateTime, info.format, DateWeekDayAndTimeDisplayFormat).displayFormat;

		return `${dateFnsFormat(utcToZonedTime(date, zonedDateTime.tz), safeFormat)} ${zonedDateTime.tz}`;
	}

	private displayDataSeed(dataSeed: DataSeed): string {
		return dataSeed._display;
	}

	private displayCost(cost: CostModelFormat): string {
		let amount: string;
		let prefix: string;
		let suffix: string;

		switch (cost.currency) {
			case 'AUD':
			case 'CAD':
			case 'HKD':
			case 'NZD':
			case 'SGD':
			case 'USD':
				amount = this.numberWithCommas(cost.amount / 100);
				prefix = '$';
				suffix = cost.currency;
				break;
			default:
				amount = cost.amount.toString();
				prefix = '';
				suffix = cost.currency;
				break;
		}

		return `${suffix} ${prefix}${amount}`;
	}
	
	private displayGeoLocation(geoLocation: GeoLocation, info: DataDisplayInfoGeoLocation): string {
		const latitudeLabel = this.translateService.instant(CommonTranslationKey.GeoLocationLatitudeShortLabel) as string;
		const longitudeLabel = this.translateService.instant(CommonTranslationKey.GeoLocationLongitudeShortLabel) as string;

		return this.displayStringArray([
			`${latitudeLabel}: ${this.displayNumber(geoLocation.lat, { type: DataType.Number, decimals: 2 })}`,
			`${longitudeLabel}: ${this.displayNumber(geoLocation.lng, { type: DataType.Number, decimals: 2 })}`,
		], { type: DataType.StringArray, separator: info.separator ?? SEPARATOR_COMMA });
	}

	private displayAddress(address: Address, info: DataDisplayInfoAddress): string {
		if (address.formattedAddress) {
			return address.formattedAddress;
		}

		const orderedFields = ['address1', 'address2', 'suburb', 'city', 'state', 'country', 'postcode'];
		const hidden = ['zoom', 'lat', 'lng'];

		const addressEntries = objectKeys(address)
			.filter((k) => !(!address[k]) && !hidden.includes(k) && orderedFields.includes(k))
			.sort((a, b) => orderedFields.indexOf(a) - orderedFields.indexOf(b))
			.map((k) => address[k])
			.filter(isNotNull)
			.map((value) => `${value}`);

		return this.displayStringArray(addressEntries, {
			type: DataType.StringArray,
			separator: info.separator ?? SEPARATOR_COMMA,
		});
	}

	private displayLink(link: ContentLinkFormData): string {
		return `${link.label} (${link.type}): ${link.id}`;
	}

	private displayCompanyInfo(companyInfo: CompanyInfo): string {
		return companyInfo.name;
	}

	private displayManager(manager: Manager): string {
		return getUserFullName(manager) ?? manager.email ?? manager.username;
	}

	private displayHierarchyStep(step: HierarchyStep): string {
		return step.label;
	}

	private displayHierarchyPath(path: HierarchyPath, info: DataDisplayInfoHierarchyPath): string {
		return pathToDisplay(path, info.format);
	}

	private displayHierarchyPaths(paths: HierarchyUnitsPath, info: DataDisplayInfoHierarchyPaths): string[] {
		return paths.map((path) => this.displayHierarchyPath(path, { type: DataType.HierarchyPath, format: info.format }));
	}

	private displayHierarchyUnit(unit: HierarchyUnitFormData, info: DataDisplayInfoHierarchyUnit): string {
		return this.displayHierarchyPath(unit.path, { type: DataType.HierarchyPath, format: info.format });
	}

	private displayChoice(choice: string | DataSeed, info: DataDisplayInfoChoice): string {
		return isDataSeed(choice) ? this.displayDataSeed(choice) : this.getOptionValue(choice, info.options);
	}

	private displayMultiChoice(choices: string[], info: DataDisplayInfoMultiChoice): string[] {
		return choices.map((choice) => this.displayChoice(choice, { type: DataType.Choice, options: info.options }));
	}

	private displayRepeat(data: Record<string, unknown>[], info: DataDisplayInfoRepeat): string[] {
		const template = info.template;
		
		if (!template) {
			return [this.translateService.instant(CommonTranslationKey.LabelCountOfItems, { count: data.length }) as string];
		}

		// TODO fix the forced `as Context` cast
		return data.map((item) => this.templateStringParser.parse(template, item as Context, item, 'UfDataDisplayService.displayRepeat', this.extendedFunctions));
	}

	private displayFileList(files: FileData[]): string[] {
		return files.map((file) => (isFileDataExtended(file) ? file.title : undefined) ?? file.name);
	}
	
	private displayStringArray(value: string[], info: DataDisplayInfoStringArray): string {
		return value.join(info.separator ?? SEPARATOR_CR_LF);
	}

	private getOptionValue(value: string, options?: DataDisplayInfoOptions): string {
		if (!options) {
			return value;
		}

		if (Array.isArray(options)) {
			return this.getOption(value, options)?.name ?? value;
		}

		const matchingRecord = options.entries.find((record) =>
			this.expressionParser.resolve(options.valueExpression, undefined, record, 'DataDisplayService.displayOption') === value,
		);

		if (!matchingRecord) {
			return value;
		}

		return this.expressionParser.resolve(options.displayExpression, undefined, matchingRecord) as string | null ?? value;
	}

	private getOption(value: string, options?: DataDisplayInfoOptions): FieldOption | undefined {
		if (options && Array.isArray(options)) {
			return options.find((option) => option.identifier === value);
		}

		return undefined;
	}

	private dataTypeGuard(data: unknown, type: DataType | undefined): DataType | undefined {

		if (data == null) {
			return undefined;
		}
		
		if (!type) {
			return detectDataType(data).type;
		}

		if (verifyDataType(data, type)) {
			return type;
		}

		let dataString = data;

		try {
			dataString = JSON.stringify(data);
		} catch (e) { /** */ }
		
		// eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
		console.warn(`DataDisplayService.getDisplayValue - Type ${type} does not match data '${dataString}'`);

		return undefined;
	}

	private numberWithCommas(input: number): string {
		const parts = input.toString().split('.');

		parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? '';
		if (parts[1] != null) {
			parts[1] = parts[1].substring(0, 2).padEnd(2, '0');
		}

		return parts.join('.');
	}

	private cleanStringArray(values: (string | null)[]): string[] & LengthAtLeast<string[], 1> | null {
		const validValues = values.filter(isStringNotEmpty);
			
		return hasLengthAtLeast(validValues, 1) ? validValues : null;
	}

	private getNumberAsBytesDisplay(bytes: number): string {
		const defaultUnit = { label: 'byte', scale: 1 };
		const units = [
			{ label: 'TB', scale: 1000000000000 },
			{ label: 'GB', scale: 1000000000 },
			{ label: 'MB', scale: 1000000 },
			{ label: 'KB', scale: 1000 },
			{ label: 'byte', scale: 1 },
		];

		const matchingUnit = units.find((unit) => bytes >= unit.scale) ?? defaultUnit;
		const amount = bytes / matchingUnit.scale;
		const hasDecimals = (amount - Math. floor(amount)) !== 0;
		
		return `${this.displayNumber(amount, { type: DataType.Number, decimals: hasDecimals ? 2 : undefined })} ${matchingUnit.label}`;
	}

	private getDataSeedDisplayData(dataSeed: DataSeed | null | undefined, sourceConfig: SourceConfig): string | undefined {
		if (!dataSeed) {
			return undefined;
		}

		const displayMapping = this.mappingsPipe.transform(sourceConfig)?.mappingsTo[DataSourceDisplayTo];

		if (!displayMapping) {
			return undefined;
		}

		const data = this.sourceConfigMappingToDataDisplayItem(displayMapping, dataSeed)?.data;

		try {
			return isString(data) ? data : undefined;
		} catch (e) {
			return undefined;
		}
	}

	private getDataSeedVisibleItems(dataSeed: DataSeed | null | undefined, sourceConfig: SourceConfig, showDisplayVisibleField: boolean | undefined): DataDisplayDataSeedVisibleItem[] {
		
		const mappingsInfo = this.mappingsPipe.transform(sourceConfig);

		if (!dataSeed || !mappingsInfo) {
			return [];
		}
		
		const mappingsVisibles = [...mappingsInfo.mappingsVisibles];
		const mappingDisplay = mappingsInfo.mappingsTo[DataSourceDisplayTo];

		// Alter the visible mappings only when an explicit request is made for _display
		if (showDisplayVisibleField != null && mappingDisplay && !mappingsVisibles.find((mapping) => mapping.to === DataSourceDisplayTo)) {
			mappingsVisibles.splice(1, 0, mappingDisplay);
		}

		return mappingsVisibles
			.map((mapping) => ({ mapping, item: this.sourceConfigMappingToDataDisplayItem(mapping, dataSeed) }))
			.filter((result) => {
				if (result.mapping.to === DataSourceDisplayTo && showDisplayVisibleField === true) {
					return true;
				}
				if (result.mapping.to === DataSourceDisplayTo && showDisplayVisibleField === false) {
					return false;
				}
				
				return result.item?.data != null || !result.mapping.hideEmpty;
			})
			.filter((result): result is DataDisplayDataSeedVisibleItem => result.item != null);
	}

	private sourceConfigMappingToDataDisplayItem(mapping: SourceConfigMapping, dataSeed: DataSeed): DataDisplayListItem | undefined {
		const mappingDataType = fieldTypeToDataType(mapping.type);
		const mappingValue = dataSeed[mapping.to] as unknown;

		if (!mappingDataType) {
			return;
		}

		const data = this.displayAsDataDisplayValue(mappingValue, {
			type: mappingDataType,
			template: mapping.itemTemplate,
			separator: SEPARATOR_CR_LF,
		} as DataDisplayInfo);

		return {
			term: mapping.label,
			data,
		};
	}

}
