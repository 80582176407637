import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataSeed, DataSourceType } from '@unifii/sdk';

import { Context, ContextProvider, DataSourceAdvancedSearchConfig, FilterEntry, FilterValue, ModalService, RuntimeField, Scope, SharedTermsTranslationKey, UfControl } from '@unifii/library/common';
import { FormField, FormFieldService, FormSettings } from '@unifii/library/smart-forms';

import { DataSeedsModalComponent } from '../../modals';

@Component({
	selector: 'uf-lookup-input',
	templateUrl: './lookup.html',
	styleUrls: ['./lookup.less'],
})
export class LookupComponent implements FormField, OnInit {

	content: DataSeed | undefined;
	field: RuntimeField;
	control: UfControl;
	contentChange: EventEmitter<DataSeed>;
	options: DataSeed[];
	suffix: string;
	cssClass: string | string[];
	scope: Scope;

	protected advancedSearchConfig: DataSourceAdvancedSearchConfig<FilterValue, FilterEntry> | null;

	private cachedQForRetry: string | undefined;

	constructor(
		protected formFieldService: FormFieldService,
		private modalService: ModalService,
		private translate: TranslateService,
		@Inject(FormSettings) protected formSettings: FormSettings,
		@Inject(ContextProvider) protected contextProvider: ContextProvider,
	) { }

	ngOnInit() {

		switch (this.field.sourceConfig?.type) {
			case DataSourceType.Users:
			case DataSourceType.Company:
			case DataSourceType.Bucket:
			case DataSourceType.Collection:
				if (this.field.sourceConfig.visibleFilters?.length) {
					this.advancedSearchConfig = {
						dataLoaderFactory: this.formSettings.dataLoaderFactory,
						sourceConfig: this.field.sourceConfig,
						search: this.formFieldService.search.bind(this.formFieldService),
						context: this.contextProvider.get.bind(this.contextProvider),
						scope: this.scope,
						requestAnalytics: this.formFieldService.requestAnalytics,
						minSearchLength: this.formSettings.dataSourceMinSearchLength,
						filterValues: {},
					};
				}
		}
	}

	retry = () => this.search(this.cachedQForRetry);

	async search(q = '') {
		this.cachedQForRetry = q;
		const context = this.contextProvider.get();

		this.options = await this.formFieldService.search(q, context as Context, this.scope);
	}

	onValueChange(seed: DataSeed | null) {
		this.content = seed ?? undefined;
		this.contentChange.emit(this.content);
	}

	async onScan(value: string) {

		const context = this.contextProvider.get();
		const dataSeeds = await this.formFieldService.findAllBy(value, context as Context, this.scope);

		if (!dataSeeds.length) {
			return;
		}

		if (dataSeeds.length === 1) {
			const onlyDataSeed = dataSeeds[0];

			if (onlyDataSeed) {
				this.onValueChange(onlyDataSeed);
			}

			return;
		}

		// if more than one, open modal to select
		const dataSeed = await this.modalService.openMedium(DataSeedsModalComponent, {
			label: this.translate.instant(SharedTermsTranslationKey.ActionSelect) as string,
			dataSeeds,
			field: this.field,
		});

		if (!dataSeed) {
			return;
		}

		this.onValueChange(dataSeed);
	}

	onSelect(seed?: DataSeed) {
		if (seed) {
			this.onValueChange(seed);
		}
	}

}
