import { Component } from '@angular/core';
import { Dictionary } from '@unifii/sdk';

import { Size } from '@unifii/library/common';

interface Item {
	image: string;
	label: string;
	rating: Dictionary<string>;
}

type Info = {
	image: string;
	label: string;
}

type Rating = {
	rating: string;
	description: string;
}

const ImagePath = '/assets/img/';

const info: Info[] = [
	{ image: 'long_reef.jpg', label: 'Long Reef' },
	// cspell:disable-next-line
	{ image: 'narrabeen.jpg', label: 'Narrabeen' },
	{ image: 'slovakia.jpg', label: 'Slovakia' },
	{ image: 'venice.jpg', label: 'Venice' },
	// cspell:disable-next-line
	{ image: 'hornsby.jpg', label: 'Hornsby' },
];

const ratings: Rating[] = [
	{ rating: '5', description: 'Awesome' },
	{ rating: '4', description: 'Good' },
	{ rating: '3', description: 'Average' },
	{ rating: '2', description: 'Not great' },
	{ rating: '-1', description: 'Get me outta here!' },
];

@Component({
	selector: 'sc-show-card-list',
	templateUrl: './show-card-list.html',
})
export class ShowCardListComponent {

	width = Size.XL;
	items: Item[] = [];

	constructor() {
		const infoEntries: Info[] = [];
		const ratingsEntries: Rating[] = [];

		let count = 0;

		while (count < 18) {
			count++;

			const { image, label } = this.getRandomItem(info, infoEntries);
			const rating = this.getRandomItem(ratings, ratingsEntries);

			const node = this.items.find((item) => item.label === label) ?? { image: ImagePath + image, label, rating };

			this.items.push({ ...node });
		}
	}

	private getRandomItem<T>(sourceItems: T[], items: T[]): T {
		if (!items.length) {
			items.push(...sourceItems);
		}

		const index = this.getRandomInt(items.length);

		return items.splice(index, 1)[0] as T;
	}

	private getRandomInt(max: number): number {
		return Math.floor(Math.random() * max);
	}

}
