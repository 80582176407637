<label *ngIf="label">{{label}}
	<ng-content></ng-content>
</label>

<div [class.push-left]="showReveal" class="input-wrap">
	<input #input [attr.id]="id" [attr.name]="name" [formControl]="control" [attr.type]="reveal ? 'text' : 'password'"
		[attr.placeholder]="placeholder" [readonly]="disabled" [attr.autocomplete]="autocomplete"
		(focus)="focused = true" (blur)="focused = false" />
	<uf-error [control]="control" />
	<uf-underline *ngIf="!disabled" [class.active]="focused" />
</div>
<ng-template [ngIf]="showReveal">
	<button title="{{reveal ? (sharedTermsTK.ActionHide | translate) : (sharedTermsTK.ActionShow | translate)}}"
		(click)="revealPassword(!reveal)" type="button" class="uf-action tertiary">
		<uf-icon name="{{reveal ? 'viewHidden' : 'view'}}" />
	</button>
</ng-template>