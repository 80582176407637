<sc-page-content [width]="width" [showWidthToggle]="true" identifier="card-list">

	<ng-container editor>

		<sc-code-editor-expander class="stretch">
			<div class="uf-card-list pad-sides">
				<a *ngFor="let item of items" class="uf-card">
					<div class="uf-img-2x1 cover">
						<img [src]="item.image">
					</div>
					<div class="uf-card-title">
						<h4>{{ item.label }}</h4>
					</div>
					<div class="uf-grid pad-right pad-left">
						<uf-data-display-list [items]="item.rating" class="col-12 small small-label" />
						<br>
					</div>
				</a>
				<div class="uf-card"></div>
			</div>
		</sc-code-editor-expander>

	</ng-container>

</sc-page-content>