import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FieldType } from '@unifii/sdk';
import { Subscription, first } from 'rxjs';

import { ModalService } from '@unifii/library/common';
import { FormService, ScopeManager, WorkflowService } from '@unifii/library/smart-forms';

import { InputTranslationKey } from '../../translations';

import { WorkflowActionsModalComponent } from './workflow-actions-modal.component';

@Component({
	selector: 'uf-workflow',
	templateUrl: './workflow.html',
	styleUrls: ['./workflow.less'],
})
export class WorkflowComponent implements OnInit, OnDestroy {

	@Output() cancelClick = new EventEmitter<void>();
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() submit = new EventEmitter<void>();

	@Input({ required: true }) positionReference: HTMLDivElement; // anchor for where the component should be positioned when not floating
	@HostBinding('class.is-floating') isFloating = false;

	protected submitButtonLabel?: string;
	protected workflowService = inject(WorkflowService);
	protected cancelButtonLabel?: string;

	private transitionRequested = false;
	private observer: IntersectionObserver | undefined;
	private formService = inject(FormService);
	private translate = inject(TranslateService);
	private scopeManager = inject(ScopeManager);
	private modalService = inject(ModalService);
	private subscription = new Subscription();
	private transitionError = this.translate.instant(InputTranslationKey.FormErrorTransitionInvalid) as string;

	ngOnInit() {
		if (
			this.formService.configuration.optionalSubmitButtonLabel &&
			!this.workflowService.definition?.fields.find((field) => field.type === FieldType.Section)
		) {
			this.submitButtonLabel = this.formService.configuration.optionalSubmitButtonLabel;
		}

		this.cancelButtonLabel = this.formService.configuration.optionalCancelButtonLabel;

		this.subscription.add(this.workflowService.transitionRequested.pipe(first()).subscribe(() => { this.transitionRequested = true; }));

		// TODO Observer disconnection/observe need to be done each time the WorkflowService.actions are changed
		if (this.workflowService.actions.some((action) => action.transition.hasPersistentVisibility)) {

			// when workflow has at least one sticky button the workflow container needs to float when not visible
			this.observer = new IntersectionObserver(([observerEntry]) => {
				if (!observerEntry) {
					return;
				}

				this.isFloating = !observerEntry.isIntersecting;
				this.workflowService.actionPosition = this.isFloating ? 'floating' : 'default';
			},
			);

			this.observer.observe(this.positionReference);
		}
	}

	ngOnDestroy() {
		this.observer?.disconnect();
		this.subscription.unsubscribe();
	}

	protected get disabled(): boolean {
		return this.formService.disabled || this.formService.inProgress;
	}

	protected get errorMessage(): string | null {
		if (this.scopeManager.control.invalid && this.workflowService.isWorkflowEnabled && this.transitionRequested) {
			return this.transitionError;
		}

		return null;
	}

	protected onCancel() {
		this.cancelClick.emit();
	}

	protected onSubmit() {
		if (!this.workflowService.validate()) {
			return;
		}

		this.submit.emit();
	}

	protected async openActionsModal() {
		const action = await this.modalService.openFit(WorkflowActionsModalComponent, {
			actions: this.workflowService.actions,
			cancelClickLabel: this.formService.configuration.optionalCancelButtonLabel,
		});

		if (!action) {
			this.cancelClick.emit();
		} else {
			this.workflowService.trigger(action);
		}
	}

}
