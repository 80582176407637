import { Component, Input } from '@angular/core';
import { Dictionary } from '@unifii/sdk';

export interface Breadcrumb {
	name: string;
	urlSegments?: any[];
	queryParams?: Dictionary<any>;
}

@Component({
	selector: 'uf-breadcrumbs',
	templateUrl: './breadcrumbs.html',
	styleUrls: ['./breadcrumbs.less'],
})
export class BreadcrumbsComponent {

	@Input() minimize?: boolean | null; // minimize breadcrumbs on mobile screens

	@Input() set breadcrumbs(v: Breadcrumb[] | null | undefined) {

		if (!v) {
			this._breadcrumbs = [];

			return;
		}

		this._breadcrumbs = this.insertSlashes(v);
	}

	get breadcrumbs(): Breadcrumb[] {
		return this._breadcrumbs;
	}

	private _breadcrumbs: Breadcrumb[] = [];

	private insertSlashes(breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {

		return breadcrumbs.reduce<Breadcrumb[]>((result, crumb) => {

			if (!result.length) {
				return [crumb];
			}

			return [...result, { name: '/' }, crumb];
		}, []);
	}

}
