import { Inject, Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

import { WindowWrapper } from '../native';

@Injectable({ providedIn: 'root' })
export class DeviceInfo {

	// this constructor is needed as it is used by the subclass DeviceService in Discover
	constructor(@Inject(WindowWrapper) protected window: Window) { }

	useNativeInput(): boolean {
		const useragent = this.window.navigator.userAgent;

		return !!(useragent.match(/Android/i) ?? useragent.match(/iPhone/i) ?? useragent.match(/iPad/i));
	}

	/** Browser Android */
	isAndroid(): boolean {
		const useragent = this.window.navigator.userAgent;

		return !!(useragent.match(/Android/i));
	}

	/** Browser iOS */
	isIosMobile(): boolean {
		const useragent = this.window.navigator.userAgent;

		return !!(useragent.match(/iPhone/i) ?? useragent.match(/iPad/i));
	}

	/** Browser Safari (Not Capacitor) */
	isSafari(): boolean {
		const useragent = this.window.navigator.userAgent;

		// edge case where android contains safari string
		if (useragent.match(/Android/i)) {
			return false;
		}

		return !!(useragent.match(/Version\/[\d\.]+.*Safari/));
	}

	isNative(): boolean {
		return Capacitor.isNativePlatform();
	}

	getPlatform(): 'android' | 'ios' | 'web' {
		return Capacitor.getPlatform() as 'android' | 'ios' | 'web';
	}

}
