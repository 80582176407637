import { Injectable } from '@angular/core';

import { DataDescriptor, DataDescriptorResolveFunction } from '../../models';

import { BucketDataDescriptorAdapter } from './bucket-data-descriptor-adapter';
import { CollectionDataDescriptorAdapter } from './collection-data-descriptor-adapter';
import { CompanyDataDescriptorAdapter } from './company-data-descriptor-adapter';
import { UserDataDescriptorAdapter } from './user-data-descriptor-adapter';

@Injectable({ providedIn: 'root' })
export class DataDescriptorService {

	userCallback: DataDescriptorResolveFunction;
	companyCallback: DataDescriptorResolveFunction;
	collectionCallback: DataDescriptorResolveFunction;
	bucketCallback: DataDescriptorResolveFunction;

	constructor(
        private collectionDataDescriptorAdapter: CollectionDataDescriptorAdapter,
        private userDataDescriptorAdapter: UserDataDescriptorAdapter,
        private companyDataDescriptorAdapter: CompanyDataDescriptorAdapter,
        private bucketDataDescriptorAdapter: BucketDataDescriptorAdapter,
	) {
		// Monkeypatch dependencies, avoid circular dependencies
		bucketDataDescriptorAdapter.userCallback = userDataDescriptorAdapter.getDataDescriptor.bind(userDataDescriptorAdapter);
		bucketDataDescriptorAdapter.companyCallback = companyDataDescriptorAdapter.getDataDescriptor.bind(companyDataDescriptorAdapter);
		bucketDataDescriptorAdapter.collectionCallback = collectionDataDescriptorAdapter.getDataDescriptor.bind(collectionDataDescriptorAdapter);
	}

	getBucketDataDescriptor(bucket: string, properties?: string[]): Promise<DataDescriptor | undefined> {
		return this.bucketDataDescriptorAdapter.getDataDescriptor(bucket, properties);
	}

	getCompanyDataDescriptor(properties?: string[]): Promise<DataDescriptor | undefined> {
		return this.companyDataDescriptorAdapter.getDataDescriptor(properties);
	}

	getCollectionDataDescriptor(identifier: string, properties?: string[]): Promise<DataDescriptor | undefined> {
		return this.collectionDataDescriptorAdapter.getDataDescriptor(identifier, properties);
	}

	getUserDataDescriptor(properties?: string[]): Promise<DataDescriptor | undefined> {
		return this.userDataDescriptorAdapter.getDataDescriptor(properties);
	}

}
