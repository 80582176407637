import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FormData } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { RuntimeDefinition, UfControlGroup } from '@unifii/library/common';
import { ComponentRegistry, FormDebugger, FormNavigationService, FormRevisionService, FormService, NavigationService, ScopeManager, WorkflowService, getUTCTime } from '@unifii/library/smart-forms';

import { UfFormComponentRegistry } from '../../services';

/**
 * Form Definition Renderer Component
 * This component renders a definition without some of the requirements as UfForm needs, plus the layout changes as well.
 * Later the idea is to adopt this component into Uf Form as well.
 */
@Component({
	selector: 'uf-form-definition-renderer',
	templateUrl: './form-definition-renderer.html',
	styleUrls: ['./form.less'],
	providers: [
		WorkflowService,
		ScopeManager,
		FormService,
		FormRevisionService,
		{ provide: NavigationService, useClass: FormNavigationService },
		{ provide: ComponentRegistry, useClass: UfFormComponentRegistry },
	],
})
export class UfFormDefinitionRendererComponent implements OnInit, OnDestroy {

	@Input({ required: true }) definition: RuntimeDefinition;
	@Input({ required: true }) formData: FormData;
	@Input() disabled = false;
	@Output() edited = new EventEmitter<boolean>();
	
	// This needs to be public so we can see if the form is disabled or not by who's using the component
	formService = inject(FormService);
	// Needs to be public - because then you submit and check if it's valid when you're using this component.
	rootControl: UfControlGroup;

	private scopeManager = inject(ScopeManager);
	private revisionService = inject(FormRevisionService);
	private formDebugger = inject(FormDebugger, { optional: true });
	private workflow = inject(WorkflowService);
	private rootControlSubscription = new Subscription();

	ngOnInit() {
		this.formData._openedAt = this.formData._openedAt ?? getUTCTime();

		this.scopeManager.scope = this.formData;

		this.formService.init(this.definition, this.disabled);
		this.scopeManager.init(this.definition);
		this.workflow.init(this.definition, this.formData);
		this.revisionService.init(this.formData);

		this.formDebugger?.register(this, this.scopeManager);

		this.rootControl = this.scopeManager.control;
		this.rootControlSubscription = this.rootControl.valueChanges.subscribe(() => {
			this.edited.emit(this.rootControl.dirty);
		});
	}

	ngOnDestroy() {
		this.rootControlSubscription.unsubscribe();
	}

}
