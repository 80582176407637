import { Component, EventEmitter, Input, OnDestroy, Output, inject } from '@angular/core';
import { Dictionary } from '@unifii/sdk';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { FilterEntry, FilterManagerProvider, FilterValue, FilterViewMode } from '../../models';
import { CommonTranslationKey, SharedTermsTranslationKey } from '../../translations';
import { DrawerComponent } from '../containers';

const filterValueChangeDebounceMilliseconds = 250;

@Component({
	selector: 'uf-filter-inputs',
	templateUrl: './filter-inputs.html',
	styleUrls: ['./filter-inputs.less'],
})
export class FilterInputsComponent<E extends FilterEntry, V extends FilterValue> implements OnDestroy {

	@Input() manager?: FilterManagerProvider<V, E>;
	@Input({ required: true }) value: Dictionary<V>;

	@Output() valueChange = new EventEmitter<Dictionary<V>>();
	@Output() hideComponent = new EventEmitter<void>();

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;
	protected readonly mode = FilterViewMode.Input;
	protected drawerComponent = inject(DrawerComponent, { optional: true });

	private debouncer = new Subject<any>();
	private subscriptions = new Subscription();

	constructor() {
		this.subscriptions.add(
			this.debouncer.pipe(debounceTime(filterValueChangeDebounceMilliseconds)).subscribe(() => {
				this.valueChange.emit(this.manager?.clean(this.value) ?? {});
			}));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	onValueChange() {
		this.debouncer.next(undefined);
		this.valueChange.pipe(debounceTime(filterValueChangeDebounceMilliseconds));
	}

	clearAll() {
		for (const identifier of Object.keys(this.value)) {
			this.value[identifier] = null as V;
		}

		this.onValueChange();
	}

	closeDrawer() {
		this.drawerComponent?.close();
	}

}
