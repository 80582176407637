<sc-page-content width="lg" identifier="lists">

	<ng-container editorInputs>
		<uf-radio [columns]="1" [options]="boxOptions" [(value)]="box" (valueChange)="boxChange()" class="col-md-3"
			label="Box" valueProperty="value" />
		<uf-radio [columns]="1" [options]="listItemOptions" [(value)]="listItem" (valueChange)="modifierChange()"
			class="col-md-3" label="Content" valueProperty="value" />
		<uf-radio [columns]="1" [options]="sizeOptions" [(value)]="size" (valueChange)="modifierChange()"
			class="col-md-3" label="Sizes" valueProperty="value" />
		<uf-radio [columns]="1" [options]="colorOptions" [(value)]="color" (valueChange)="modifierChange()"
			class="col-md-3" label="Colors" valueProperty="value" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template" class="pad-sides">
			<ul [ngClass]="[boxClass, size, color]" [class.grow]="listItem === listItemTypes.Fieldset"
				class="uf-list default" style="min-width: 320px;">

				<ng-container [ngSwitch]="listItem">

					<ng-container *ngSwitchCase="listItemTypes.Button">
						<li class="uf-list-button">
							<button type="button">
								<uf-icon name="camera" />
								<div class="multi-text">Capture</div>
								<button type="button" class="uf-action tertiary small">
									<uf-icon name="delete" />
								</button>
							</button>
						</li>
						<li class="uf-list-button">
							<uf-icon name="copy" />
							<div class="multi-text">Copy</div>
						</li>
						<li class="uf-list-button">
							<uf-icon name="download" />
							<div class="multi-text">Download</div>
						</li>
					</ng-container>

					<ng-container *ngSwitchCase="listItemTypes.Link">
						<a class="uf-list-link" href="https://www.unifii.com.au/">
							<div class="uf-avatar info">
								WW
							</div>
							<uf-data-display-list [items]="{ name: 'Walter White' }" class="stacked multi-text" />
						</a>
						<a class="uf-list-link" href="https://www.unifii.com.au/">
							<div class="uf-avatar info">
								JP
							</div>
							<uf-data-display-list [items]="{ name: 'Jesse Pinkman' }" class="stacked multi-text" />
						</a>
					</ng-container>

					<ng-container *ngSwitchCase="listItemTypes.Item">
						<li class="uf-list-item" checkboxContainer>
							<uf-checkbox [value]="true" />
							<div class="multi-text">Walter White</div>
						</li>
						<li class="uf-list-item" checkboxContainer>
							<uf-checkbox />
							<div class="multi-text">Jesse Pinkman</div>
						</li>
						<li class="uf-list-item" checkboxContainer>
							<uf-checkbox />
							<div class="multi-text">Gus Fring</div>
						</li>
					</ng-container>

					<ng-container *ngSwitchCase="listItemTypes.Fieldset">
						<li *ngFor="let name of ['John', 'Wendy', 'Bob']" class="row">
							<div class="uf-grid grow pad">
								<uf-text [value]="name" class="col-6" label="Username" />
								<uf-text [value]="name + '@unifii.com.au'" class="col-6" label="Email" />
							</div>
							<div class="col center pad-sm-right">
								<div class="uf-action tertiary">
									<uf-icon name="contextual" />
								</div>
							</div>
						</li>
					</ng-container>

					<ng-container *ngSwitchDefault>
						<li class="pad-sides">
							<h4>Walter White</h4>
							<uf-data-display-list [items]="{ occupation: 'School Teacher', age: '54' }" />
							<br>
						</li>
						<li class="pad-sides">
							<h4>Jesse Pinkman</h4>
							<uf-data-display-list [items]="{ occupation: 'Drug Dealer', age: '21' }" />
							<br>
						</li>
						<li class="pad-sides">
							<h4>Gus Fring</h4>
							<uf-data-display-list [items]="{ occupation: 'Chicken Shop Owner', age: '48' }" />
							<br>
						</li>
					</ng-container>
				</ng-container>

			</ul>
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="tableConfig" [rows]="tableData" />
	</ng-container>

</sc-page-content>