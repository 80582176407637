import { Component, HostBinding, Input, OnDestroy } from '@angular/core';

import { MessageLevel } from '../../constants';
import { ToastData } from '../../models';
import { ToastService } from '../../services';

@Component({
	selector: 'uf-toast',
	templateUrl: './toast.html',
	styleUrls: ['./toast.less'],
})
export class ToastComponent implements OnDestroy {

	@Input() data: ToastData;

	constructor(private service: ToastService) { }

	@HostBinding('class') get cssClass(): MessageLevel {
		return this.data.level;
	}

	ngOnDestroy() {
		clearTimeout(this.data.timeout);
	}

	close() {
		this.service.remove(this.data);
	}

}
