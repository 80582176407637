import { Directive, HostListener, Input, inject } from '@angular/core';
import { GeoLocation } from '@unifii/sdk';

import { MapViewerModalComponent } from '../components';
import { ModalService } from '../services';

@Directive({ selector: '[mapViewerLink]' })
export class MapViewerLinkDirective {

	@Input({ required: true }) geoLocation: GeoLocation;

	private modalService = inject(ModalService);

	@HostListener('click', ['$event'])
	onClick(e: PointerEvent) {
		e.preventDefault();
		e.stopPropagation();
		void this.modalService.openMedium(MapViewerModalComponent, this.geoLocation);
	}

}
