import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dictionary, PageField, isNotNull } from '@unifii/sdk';

import { RuntimePageAdapter, RuntimePageField } from '@unifii/library/common';
import { ComponentRegistry } from '@unifii/library/smart-forms';
import { UfFormComponentRegistry } from '@unifii/library/smart-forms/input';

@Component({
	selector: 'sc-configuration',
	templateUrl: './show-configuration.html',
	styleUrls: ['./show-configuration.less'],
	providers: [{ provide: ComponentRegistry, useClass: UfFormComponentRegistry }],
})
export class ConfigurationComponent implements OnInit {

	@Input() configuration: Dictionary<PageField>;
	@Input() title = 'Configuration';
	@Output() valueChange = new EventEmitter<Dictionary<any>>();

	protected fields: Dictionary<RuntimePageField> = {};

	constructor(private runtimeAdapter: RuntimePageAdapter) { }

	ngOnInit() {
		const fieldsDictionary = JSON.parse(JSON.stringify(this.configuration)) as Dictionary<PageField>;
		const fields = Object.keys(fieldsDictionary).map((k) => {
			const field = fieldsDictionary[k];

			if (field) {
				field.identifier = k;
			}

			return field;
		}).filter(isNotNull);

		const page = this.runtimeAdapter.transform({
			_title: 'Configuration',
			identifier: 'configuration',
			fields,
		});

		for (const field of page.fields) {
			if (field.identifier) {
				this.fields[field.identifier] = field;
			}			
		}
	}

	get keys(): string[] {
		return Object.keys(this.fields);
	}

	get value(): Dictionary<any> {
		const data: Dictionary<any> = {};

		for (const k of this.keys) {
			data[k] = this.fields[k]?.value;
		}

		return data;
	}

	fieldChanged() {
		this.valueChange.emit(this.value);
	}

}
