import { Component, EventEmitter } from '@angular/core';
import { FieldType } from '@unifii/sdk';

import { RuntimeField, UfControl } from '@unifii/library/common';
import { FormField } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-input',
	templateUrl: './uf-input.html',
})
export class UfInputComponent implements FormField {

	field: RuntimeField;
	control: UfControl;
	content: any;
	contentChange: EventEmitter<any>;
	suffix: string;
	cssClass: string | string[];

	protected readonly fieldType = FieldType;

	protected changed(v: any) {
		this.contentChange.emit(v);
	}

}
