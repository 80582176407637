import { Component, HostBinding, Inject } from '@angular/core';
import { Dictionary } from '@unifii/sdk';

import { FilterEntry, FilterValue } from '../../../models';
import { CommonTranslationKey, SharedTermsTranslationKey } from '../../../translations';
import { Modal, ModalData, ModalRuntime } from '../../modal';

import { DataSourceAdvancedSearchConfig } from './data-source-advanced-search-model';

@Component({
	templateUrl: './data-source-advanced-search-filters-modal.html',
	styleUrls: ['./data-source-advanced-search-filters-modal.less', '../../modal/modals/modal.less'],
})
export class DataSourceAdvancedSearchFiltersModalComponent<V extends FilterValue, E extends FilterEntry> implements Modal<DataSourceAdvancedSearchConfig<V, E>, boolean> {

	@HostBinding('class.uf-form-card') class = true;

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;

	protected localFilters: Dictionary<V>;

	constructor(
		public runtime: ModalRuntime<DataSourceAdvancedSearchConfig<V, E>, boolean>,
		@Inject(ModalData) public data: DataSourceAdvancedSearchConfig<V, E>,
	) {
		this.localFilters = JSON.parse(JSON.stringify(data.filterValues)) as Dictionary<V>;
	}

	close() {
		this.runtime.close(false);
	}

	protected select() {
		this.data.filterValues = this.localFilters;
		this.runtime.close(true);
	}

}
