import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { SortDirections } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { TableConfig } from '../../components/table';
import { SharedTermsTranslationKey } from '../../translations';
import { MenuOption } from '../drop-menu';

import { TableComponent } from './table.component';

@Component({
	selector: 'uf-table-sort',
	templateUrl: './table-sort.html',
	styleUrls: ['./table-sort.less'],
})
export class TableSortComponent<T> implements OnInit, OnDestroy {

	@Input({ required: true }) table: TableComponent<T>;

	readonly sharedTermsTK = SharedTermsTranslationKey;

	private _visible = false;
	private subscriptions = new Subscription();

	ngOnInit() {
		// Fix for ExpressionChangedAfterItHasBeenCheckedError
		this.subscriptions.add(this.table.ready.subscribe(() => {
			setTimeout(() => {
				this._visible = !!this.table.sortMaster &&
					(this.table.config as TableConfig<T> | null)?.columns.find((c) => c.sortable === true) != null;
			}, 0);
		}));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	@HostBinding('class.visible') get visible(): boolean {
		return this._visible;
	}

	get options(): MenuOption[] {
		const sortStatus = this.table.sortMaster?.sort;

		return this.table.config.columns
			.filter((c) => c.sortable === true)
			.map((c) => ({
				id: c.name,
				label: c.label,
				icon: (sortStatus?.name === c.name) ? (sortStatus.direction === SortDirections.Ascending ? 'sortDown' : 'sortUp') : undefined,
			}));
	}

	sort(option: MenuOption) {
		this.table.sortMaster?.sortBy(option.id as string | undefined, true);
	}

}
