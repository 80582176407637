<div class="uf-app-bar">
	<button [title]="sharedTermsTK.ActionClose | translate" (click)="close()" type="button" class="uf-action tertiary">
		<uf-icon name="close" />
	</button>
	<h3>{{data.title}}</h3>
</div>
<div class="uf-grid pad-sides gaps scrollable-section">
	<div *ngIf="data.message" [markdownRenderer]="data.message" class="col-12"></div>
	<uf-radio *ngIf="data.options.length" [options]="data.options" [columns]="data.columns" [(value)]="value"
		class="col-12" />
	<uf-blockquote *ngIf="!data.options.length" [content]="commonTK.ChoiceNoOptionsAvailable | translate" icon="info"
		class="col-12" />
</div>
<div class="uf-form-actions">
	<button (click)="close()" class="uf-button tertiary small left" type="button">
		{{data.cancelLabel}}
	</button>
	<button (click)="confirm()" class="uf-button primary small" type="button" ufAutofocus>
		{{data.confirmLabel}}
	</button>
</div>