import { BucketDataDescriptorPermissionController, CollectionDataDescriptorPermissionController, UserDataDescriptorPermissionController } from '@unifii/library/common';

export class ShowcaseDataDescriptorPermissionController implements UserDataDescriptorPermissionController, BucketDataDescriptorPermissionController, CollectionDataDescriptorPermissionController {

	loadRoles = true;
	loadCompanies = true;
	loadUserClaims = true;
	loadForms = true;
	loadSchema = true;
	loadCollectionDefinition = true;
	loadHierarchy = true;
	loadUsers = true;

	reset() {
		this.loadRoles = true;
		this.loadCompanies = true;
		this.loadUserClaims = true;
		this.loadForms = true;
		this.loadSchema = true;
		this.loadCollectionDefinition = true;
		this.loadHierarchy = true;
		this.loadUsers = true;
	}

	canLoadRoles(): boolean {
		return this.loadRoles;
	}

	canLoadCompanies(): boolean {
		return this.loadCompanies;
	}

	canLoadUserClaims(): boolean {
		return this.loadUserClaims;
	}

	canLoadForms(): boolean {
		return this.loadForms;
	}

	canLoadSchema(): boolean {
		return this.loadSchema;
	}

	canLoadCollectionDefinition(): boolean {
		return this.loadCollectionDefinition;
	}

	canLoadHierarchy(): boolean {
		return this.loadHierarchy;
	}

	canLoadUsers(): boolean {
		return this.loadUsers;
	}

}
