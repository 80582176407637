import { Component, HostBinding, Inject, OnInit, Optional, SkipSelf } from '@angular/core';
import { FormData } from '@unifii/sdk';

import { Modal, ModalData, ModalRuntime, RuntimeField, SharedTermsTranslationKey, UfFormControl, fieldIterator } from '@unifii/library/common';
import { FormContainerField, FormDebugger, FormService, ScopeManager } from '@unifii/library/smart-forms';

export interface ActionGroupModalData {
	field: RuntimeField;
	parentScopeManager: ScopeManager;
	formData: FormData;
}

@Component({
	selector: 'uf-action-group-modal',
	templateUrl: './action-group-modal.html',
	styleUrls: ['./action-group-modal.less'],
	providers: [ScopeManager],
})
export class ActionGroupModalComponent implements Modal<ActionGroupModalData, FormData | undefined>, FormContainerField, OnInit {

	@HostBinding('class.uf-form-card') class = true;

	readonly sharedTermsTK = SharedTermsTranslationKey;

	control: UfFormControl;
	formData: FormData = {};

	constructor(
		private scopeManager: ScopeManager,
		@SkipSelf() private parentScopeManager: ScopeManager,
		public runtime: ModalRuntime<ActionGroupModalData, FormData | undefined>,
		@Inject(ModalData) public data: ActionGroupModalData,
		@Optional() @Inject(FormService) private formService?: FormService,
		@Optional() private formDebugger?: FormDebugger,
	) {
	}

	get field() {
		return this.data.field;
	}

	get inProgress() {
		return this.formService?.inProgress === true;
	}

	ngOnInit() {

		this.formData = this.getCleanFormData();

		/** Action groups required new scope to get a new instance of now */
		this.scopeManager.init(this.data.field, this.parentScopeManager);
		this.scopeManager.scope = this.formData;
		const control = this.scopeManager.getControl(this.field);

		if (control == null) {
			throw Error('ActionGroupModal control is null');
		}

		this.control = control;

		this.formDebugger?.updateValidators(this.control);
	}

	isVisible(child: RuntimeField): boolean {
		return !this.formService || this.formService.isGranted(child);
	}

	cancel() {
		this.runtime.close();
	}

	confirm() {

		if (!this.control.valid) {
			this.control.setSubmitted(true);

			return;
		}

		this.runtime.close(this.formData);
	}

	private getCleanFormData() {

		const copy = JSON.parse(JSON.stringify(this.data.formData)) as FormData;

		// remove previous action group data important to preserve other data for showIf and other features
		for (const { field } of fieldIterator(this.data.field.fields)) {
			if (field.identifier && copy[field.identifier] != null) {
				// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
				delete copy[field.identifier];
			}
		}

		return copy;
	}

}
