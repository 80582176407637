import { Inject, Injectable, Optional } from '@angular/core';
import { Client, FormData, FormDataClient, ProjectContentOptions, ProjectContentOptionsInterface } from '@unifii/sdk';

@Injectable()
export class FormRevisionStorage {

	get revisions(): FormData[] | undefined {
		return this._revisions;
	}

	private _revisions?: FormData[];

	constructor(
		@Optional() private client: Client | null,
		@Optional() @Inject(ProjectContentOptions) private options: ProjectContentOptionsInterface | null,
	) { }

	clear() {
		this._revisions = undefined;
	}

	async loadRevisionsByFormData(formData: FormData) {
		if (!formData._bucket || !formData.id) {
			this._revisions = [];

			return;
		}

		const formDataClient = this.getFormDataClient(formData._bucket);
		const revisionData = await formDataClient.getRevisions(formData.id);

		this._revisions = revisionData.sort((a, b) => !a._rev || !b._rev ? -1 : (a._rev) <= (b._rev) ? -1 : 1);
	}

	private getFormDataClient(bucket: string): FormDataClient {
		if (!this.client) {
			throw new Error('FormRevisionStorage - cannot get revision data without Client');
		}

		if (this.options == null) {
			throw new Error('FormRevisionStorage - cannot get revision data without ProjectContentOptions');
		}

		return new FormDataClient(this.client, { projectId: this.options.projectId, preview: this.options.preview, bucket });
	}

}
