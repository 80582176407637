import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-buttons.html',
})
export class ShowButtonsComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly tableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];

	readonly tableInfo = [
		{ name: 'uf-button', type: 'base', description: 'Base style to apply default button style.' },
		{ name: 'primary', type: 'modifier', description: 'Sets colour of button and content to primary' },
		{ name: 'secondary', type: 'modifier', description: 'Sets colour of button and content to secondary/default' },
		{ name: 'default', type: 'modifier', description: 'Sets colour of button and content to secondary/default' },
		{ name: 'tertiary', type: 'modifier', description: 'Sets colour of button and content to tertiary' },
		{ name: 'large', type: 'modifier', description: 'Sets size of button to large' },
		{ name: 'default', type: 'modifier', description: 'Sets size of button to default' },
		{ name: 'small', type: 'modifier', description: 'Sets size of button to small' },
		{ name: 'x-small', type: 'modifier', description: 'Sets size of button to extra small' },
	];

	readonly sizeOptions = [
		{ name: 'Large', value: 'large' },
		{ name: 'Medium (default)', value: '' },
		{ name: 'Small', value: 'small' },
		{ name: 'Extra Small', value: 'x-small' },
	];

	readonly alignmentOptions = [
		{ name: 'None (default)', value: '' },
		{ name: 'Left', value: 'align-left' },
		{ name: 'Right', value: 'align-right' },
	];

	readonly iconOptions = [
		{ name: 'None', value: '' },
		{ name: 'Left', value: 'left' },
		{ name: 'Right', value: 'right' },
	];
	readonly colourOptions = [
		{ name: 'Primary', value: 'primary' },
		{ name: 'Secondary (default)', value: '' },
		{ name: 'Tertiary', value: 'tertiary' },
	];
	readonly typeOptions = [
		{ name: 'Button', value: 'button' },
		{ name: 'Anchor', value: 'anchor' },
	];

	type = 'button';
	size = '';
	colour = '';
	icon = '';
	alignment = '';

	class = 'uf-button';

	template = `
		<button type="button" class="uf-button">
			Button
			<uf-icon name="edit"></uf-icon>
		</button>
	`;

	modifierChange() {
		this.class = ('uf-button ' + [this.size, this.colour, this.alignment].filter((s) => s.length).join(' ')).trim();
		let element = '';
		let attributes = '';

		if (this.type === 'button') {
			element = 'button';
			attributes = 'type="button"';
		} else if (this.type === 'anchor') {
			element = 'a';
			attributes = 'href="#"';
		}
		this.template = `<${element} ${attributes} class="${this.class}">
			Button
			<uf-icon name="edit"></uf-icon>
		</${element}>`;
	}

}
