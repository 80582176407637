import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { AstNode, DataSeed, DataSourceType } from '@unifii/sdk';

import { Context, DataDescriptor, DataPropertyDescriptor, FilterEntry, FilterSerializer, FilterValue, HierarchyUnitProvider } from '../../../models';
import { DataDescriptorService, FilterManager, ModalService } from '../../../services';
import { CommonTranslationKey, SharedTermsTranslationKey } from '../../../translations';
import { Modal, ModalData, ModalRuntime } from '../../modal';

import { DataSourceAdvancedSearchFiltersModalComponent } from './data-source-advanced-search-filters-modal.component';
import { DataSourceAdvancedSearchConfig } from './data-source-advanced-search-model';
import { DataSourceFilterEntryAdapter } from './data-source-filter-entry-adapter';

@Component({
	selector: 'uf-data-source-advanced-search-modal',
	templateUrl: './data-source-advanced-search-modal.html',
	styleUrls: ['./data-source-advanced-search-modal.less', '../../modal/modals/modal.less'],
})
export class DataSourceAdvancedSearchModalComponent<V extends FilterValue, E extends FilterEntry> implements Modal<DataSourceAdvancedSearchConfig<V, E>, DataSeed>, OnInit {

	@HostBinding('class.uf-form-card') class = true;

	runtime = inject(ModalRuntime<DataSourceAdvancedSearchConfig<V, E>, DataSeed>);
	data = inject(ModalData) as DataSourceAdvancedSearchConfig<V, E>;

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;

	protected filtersNode: AstNode | undefined;
	protected value: DataSeed | undefined;
	protected options: DataSeed[];

	private modalService = inject(ModalService);
	private filterAdapter = inject(DataSourceFilterEntryAdapter);
	private dataDescriptorService = inject(DataDescriptorService);
	private hierarchyUnitProvider = inject(HierarchyUnitProvider);
	private serializer = inject(FilterSerializer, { optional: true }) as FilterSerializer<V, E> | null;

	ngOnInit() {
		void this.initFilters();
	}

	close() {
		this.runtime.close();
	}

	protected async search(q?: string) {
		this.options = await this.data.search(q, this.data.context as any as Context, this.data.scope, this.filtersNode);
	}

	protected async openFilters() {
		const filtersChanged = await this.modalService.openMedium(DataSourceAdvancedSearchFiltersModalComponent, this.data);

		if (!filtersChanged) {
			return;
		}

		this.applyFilters();
	}

	protected applyFilters() {
		this.filtersNode = this.data.filterManager?.toAstNode(this.data.filterValues);
	}

	protected select() {
		this.runtime.close(this.value);
	}

	private async initFilters() {
		if (this.data.filterManager) {
			this.filtersNode = this.data.filterManager.toAstNode(this.data.filterValues);

			return;
		}

		let descriptor: DataDescriptor | undefined;

		switch (this.data.sourceConfig.type) {

			case DataSourceType.Bucket:
				descriptor = await this.dataDescriptorService.getBucketDataDescriptor(this.data.sourceConfig.id);
				break;

			case DataSourceType.Collection:
				descriptor = await this.dataDescriptorService.getCollectionDataDescriptor(this.data.sourceConfig.id);
				break;

			case DataSourceType.Company:
				descriptor = await this.dataDescriptorService.getCompanyDataDescriptor();
				break;

			case DataSourceType.Users:
				descriptor = await this.dataDescriptorService.getUserDataDescriptor();
				break;
		}

		if (!descriptor) {
			return;
		}

		const filterEntries = (this.data.sourceConfig.visibleFilters ?? [])
			.map((filter) => this.filterAdapter.transform(
				filter,
				descriptor?.propertyDescriptorsMap ?? new Map<string, DataPropertyDescriptor>(),
				this.data.sourceConfig.type,
				this.data.dataLoaderFactory,
				this.data.requestAnalytics,
				this.data.minSearchLength,
			))
			.filter((filter) => filter != null) as E[];

		this.data.filterValues = {};
		this.data.filterManager = new FilterManager(filterEntries, this.hierarchyUnitProvider, this.serializer, null);
	}

}
