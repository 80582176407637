import { AfterViewInit, Component, OnDestroy, ViewChild, inject } from '@angular/core';
import { Client, DataType, SortDirections, UserInfo } from '@unifii/sdk';
import { Subscription, interval, timer } from 'rxjs';
import { delay, take } from 'rxjs/operators';

import { ActionMultiplicity, ColumnDisplayDescriptor, DataDisplayIconValue, DataDisplayLozengeValue, DataDisplayService, SEPARATOR_COMMA, SortStatus, TableComponent, TableConfig, TablePreferences, TablePreferencesProvider, ToastService } from '@unifii/library/common';
import { getUTCTime } from '@unifii/library/smart-forms';

import { Config } from '../../models';
import { ShowcaseTablePreferencesProvider } from '../../services';

import { UserInfoWithSignature, UsersDataSource } from './show-table-datasource';

@Component({
	selector: 'sc-show-table',
	templateUrl: './show-table.html',
	styleUrls: ['./show-table.less'],
	providers: [
		{ provide: TablePreferencesProvider, useClass: ShowcaseTablePreferencesProvider },
	],
})
export class ShowTableComponent implements AfterViewInit, OnDestroy {

	@ViewChild(TableComponent) private table: TableComponent<UserInfoWithSignature>;

	protected query?: string | null;	
	protected sort = new SortStatus('lastName', SortDirections.Descending);
	protected datasource: UsersDataSource | undefined;	
	protected styleModifier = '';
	protected customColumns: ColumnDisplayDescriptor[] = [];

	protected config: TableConfig<UserInfoWithSignature> = {
		id: 'show-table',
		pageSize: 30,
		columns: [{
			name: 'username',
			label: 'Username',
			sortable: true,
		}, {
			name: 'firstName',
			label: 'First Name',
			sortable: true,
			value: (item) => ({ label: item.firstName ?? '', colour: 'accent' } satisfies DataDisplayLozengeValue),
		}, {
			name: 'company.name',
			label: 'Company Name',
			sortable: true,
		}, {
			name: 'non_matching_user_info_identifier',
			label: 'Last Name',
			sortable: true,
		}, {
			name: 'roles',
			label: 'Roles',
			value: (item) =>
				this.dataDisplayService.displayAsString(item.roles, { type: DataType.MultiChoice, separator: SEPARATOR_COMMA }),
		}, {
			name: 'email',
			label: 'Email Link',
			value: (item) => this.dataDisplayService.displayAsDataDisplayValue(item.email, { type: DataType.Email }),
		}, {
			name: 'custom',
			label: 'Custom',
		}, {
			name: 'lastModified',
			label: 'Last Modified',
			hidden: true,
			value: (item) => {
				return this.dataDisplayService.displayAsString(item.lastModifiedAt, { type: DataType.OffsetDateTime, asDistanceFromNow: true });
			},
		}, {
			name: 'hasPassword',
			label: 'Has Password',
			value: (item) => ({ icon: item.hasPassword ? 'radioTick' : 'radioCross', colour: item.hasPassword ? 'success' : 'error' } satisfies DataDisplayIconValue), 
		}, {
			name: 'cc997c77-5b5f-4394-877c-e667d7c97f91',
			label: 'External',
		}, {
			name: 'signature',
			label: `Signature`,
			value: (item) => this.dataDisplayService.displayAsDataDisplayValue(item.signature, { type: DataType.Signature }),
		}],
		actions: [{
			label: 'Single',
			predicate: (context) => {
				this.log(context.$implicit, 'Action.Single.predicate');

				return !context.stale;
			},
			action: (context) => { console.log('Single', context); },
			multiplicity: ActionMultiplicity.Single,
		}, {
			label: 'Multiple',
			action: (rows) => { console.log('Multiple', rows); },
		}, {
			label: 'Modify',
			icon: 'edit',
			predicate: (context) => {
				this.log(context.$implicit, 'Action.Modify.predicate');

				return !context.stale;
			},
			action: async(rows) => {
				await this.asyncAction(2000);
				rows.forEach((r) =>	this.table.updateItem(r.index, Object.assign({}, r.$implicit, { lastModifiedAt: getUTCTime() })));
				this.toastService.success('Modified!!');
			},
		}],
		selectable: 3,
		columnToggles: true,
		cardExpands: true,
		row: {
			// keep the commented ones for ease feature-re-enable in case of debugging
			// link: (item) => {
			// 	this.log(item, `Row.Link`);

			// 	return [item.id];
			// },
			// action: (item) => { this.log(this, item); },
			image: (item) => {
				this.log(item, 'Row.Image');

				return '/assets/img/long_reef.jpg';
			},
			label: (item) => {
				this.log(item, 'Row.label');

				return `${item.firstName} ${item.lastName}`;
			},
		},
	};

	protected styleOptions = [
		{ name: 'Default', identifier: '' },
		{ name: 'Accent', identifier: 'accent' },
		{ name: 'List', identifier: 'list' },
		{ name: 'List (Small Screen)', identifier: 'list-sm' },
		{ name: 'List (Medium Screen)', identifier: 'list-md' },
		{ name: 'List (Large Screen)', identifier: 'list-lg' },
		{ name: 'List (X Large Screen)', identifier: 'list-xl' },
		{ name: 'Cards', identifier: 'cards' },
		{ name: 'Cards (Small Screen)', identifier: 'cards-sm' },
		{ name: 'Cards (Medium Screen)', identifier: 'cards-md' },
		{ name: 'Cards (Large Screen)', identifier: 'cards-lg' },
		{ name: 'Cards (X Large Screen)', identifier: 'cards-xl' },
		{ name: 'Small Message', identifier: 'small-message' },
	];

	protected set empty(v: boolean) {
		if (this.datasource) {
			this.datasource.empty = v;
		}
		this._empty = v;
	}

	protected get empty(): boolean {
		return this._empty;
	}

	private datasourceQuery?: string | null;
	private subscriptions = new Subscription();
	private _empty = false;
	private conf = inject(Config);
	private client = inject(Client);
	private toastService = inject(ToastService);
	private dataDisplayService = inject(DataDisplayService);

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	ngAfterViewInit() {
		this.datasourceQuery = this.query;

		timer(0).subscribe(() => { this.updateDatasource(); });

		this.subscriptions.add(interval(1000).pipe(
			delay(0),
			take(0))
			.subscribe(() => { this.table.load(); }),
		);
	}

	protected onSearch(q?: string | null) {
		this.datasourceQuery = q;
		this.updateDatasource();
	}
	
	protected onSort(sort: SortStatus) {
		this.sort = sort;
		this.updateDatasource();
	}

	protected error() {
		if (!this.datasource) {
			return;
		}
		this.datasource.error = true;
		this.table.load();
	}

	protected slow() {
		if (!this.datasource) {
			return;
		}
		this.datasource.slow = true;
		this.table.load();
	}

	protected add() {
		this.table.addItem({
			id: '64',
			username: 'adam',
			firstName: 'adam',
			lastName: 'lawrence',
			email: 'adam.lawrence@unifii.com.au',
			phone: 'adam',
			lastModifiedAt: '2019-05-06T00:09:30.4113957+00:00',
			isExternal: false,
			isActive: true,
			hasPassword: true,
			isMfaOptional: true,
			isMfaEnabled: false,
			roles: ['Publisher', 'SuperUser', 'UserManager', 'AssetManager', 'ProjectManager', 'ContentEditor', 'FormDesigner', 'Auditor', 'Importer', 'FormDataViewer', 'Translator'],
		});
	}

	protected updateUserPreferences(preferences: TablePreferences) {
		console.log('Updated userTablePreferences', preferences);
	}

	protected async download() {
		if (!this.datasource) {
			return;
		}

		const downloadUrl = this.datasource.getDownloadUrl();

		if (downloadUrl) {

			const downloadToken: { token: string } = await this.client.getDownloadToken(downloadUrl);
			const link = document.createElement('a');

			link.setAttribute('href', downloadUrl + '&_dlt=' + downloadToken.token);
			link.setAttribute('download', 'download.csv');
			link.setAttribute('target', '_blank');
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	protected checkAll() {
		this.table.select?.selectAll();
	}

	protected uncheckAll() {
		this.table.select?.deselectAll();
	}

	protected updateFirstItem() {

		const row = this.table.getRow(0);

		if (!row) {
			console.log('Table has no items');

			return;
		}

		const itemCopy = Object.assign({}, row.context.$implicit, { firstName: 'UPDATED' });

		this.table.updateItem(0, itemCopy);

		console.log('updated first item');
	}

	protected updateDatasource() {
		this.datasource = new UsersDataSource(this.conf, this.client, this.datasourceQuery ?? undefined, this.sort, this.empty);
	}

	protected exhaustAhead() {
		this.config.exhaustAhead = !this.config.exhaustAhead;
		this.datasource = new UsersDataSource(this.conf, this.client, this.datasourceQuery ?? undefined, this.sort, this.empty);
	}

	private asyncAction(msDelay: number): Promise<void> {

		return new Promise((resolve) => {
			setTimeout(() => {
				resolve();
			}, msDelay);
		});
	}

	private log(item: UserInfo, message: string) {
		console.log(`${item.id}: ${message}`);
	}

}
