import { Injectable } from '@angular/core';
import { ensureError } from '@unifii/sdk';
import jsep, { ArrayExpression, BinaryExpression, CallExpression, ConditionalExpression, Expression, Identifier, Literal, MemberExpression, UnaryExpression } from 'jsep';

import { Context, ExpressionFunction, ExpressionTypes, Scope } from '../models';
import { UfExpressionFunctionsSet } from '../utils';

@Injectable({ providedIn: 'root' })
export class ExpressionParser {

	private functionCache = new Map<string, ExpressionFunction>();

	/**
	 * Return the parsed Expression
	 * @param expression expression - to be parsed
	 * @returns Expression if valid or null otherwise
	 */
	parse(expression: string): Expression | null {
		try {
			return jsep(expression);
		} catch (e) {
			return null;
		}
	}

	/**
	 * Return true if the expression is a valid function
	 * @param expression expression - to be tested
	 * @returns validity result
	 */
	validate(expression: string): boolean {
		try {
			const exp = this.parse(expression);
			const func = this.createFn(exp);

			return func != null;
		} catch (e) {
			return false;
		}
	}

	/**
	 * Build the expression's function
	 * @param expression 
	 * @returns 
	 */
	getFunc(expression?: string): ExpressionFunction | null {

		if (!expression) {
			return null;
		}

		const func = this.functionCache.get(expression);

		if (func != null) {
			return func;
		}

		return this.buildFunction(expression);
	}

	/**
	 * Parse the expression into a function and execute under the scope with $self. as context and the available provided functions
	 * @param expression expression - to be executed
	 * @param context context - object accessible via $self. by the expression
	 * @param scope scope - the root scope for the expression to run on
	 * @param source source - log info in case of error
	 * @param functions functions - a dictionary of functions
	 * @returns result of the expression
	 */
	resolve = (
		expression: string,
		context: Context = { self: {}, root: {} },
		scope: Scope = {},
		source = '',
		functions = UfExpressionFunctionsSet,
	): unknown => {

		let func: ExpressionFunction | null = null;

		try {
			func = this.getFunc(expression);

			if (!func) {
				throw Error();
			}

			return func(scope, context, functions);
		} catch (e) {

			const error = ensureError(e);
			const message = `Expression: '${expression}' failed  to ${func ? 'execute' : 'parse'}${source ? '\nSource: ' + source : ''}`;

			// TODO is this detect logic compatible with production obscured compiled code?
			// Silence for DataLookupService.lookupData error 'Cannot read properties of undefined'
			if (!error.message.startsWith('Cannot read properties of undefined') && !error.message.endsWith(' is undefined') && !error.stack?.split('\n')[3]?.includes('DataLookupService.lookupData')) {
				console.warn(message, error);	
			}
			
			return null;
		}
	};

	private buildFunction(expression: string): ExpressionFunction | null {

		const exp = this.parse(expression);
		const func = this.createFn(exp);

		if (!func) {
			return null;
		}

		this.functionCache.set(expression, func);

		return func;
	}

	private createFn(exp: Expression | null): ExpressionFunction | null {

		if (!exp) {
			return null;
		}

		const stringFunc = this.stringify(exp);

		/** Scope is root object and context is self */
		// eslint-disable-next-line @typescript-eslint/no-implied-eval
		return new Function('scope', 'context', 'functions', 'return ' + stringFunc) as ExpressionFunction;
	}

	private stringify(exp: Expression, func = false, level = 0): string | null {

		const type = exp.type;

		if (type === ExpressionTypes.BinaryExpression as string) {
			const binExp = exp as BinaryExpression;
			const left = this.stringify(binExp.left);
			const right = this.stringify(binExp.right);

			return `(${left} ${binExp.operator} ${right})`;
		}

		if (type === ExpressionTypes.CallExpression as string) {
			const callExp = exp as CallExpression;

			const call = this.stringify(callExp.callee, true);

			if (!call) {
				return null;
			}

			if (call.startsWith('functions.')) {
				const arg: Identifier = { name: '$context', type: 'Identifier' };

				if (callExp.arguments.length) {
					callExp.arguments.push(arg);
				} else {
					callExp.arguments = [arg];
				}
			}

			const args = callExp.arguments.map((a) => this.stringify(a)).join(', ');

			return `${call}(${args})`;
		}

		if (type === ExpressionTypes.UnaryExpression as string) {
			const unExp = exp as UnaryExpression;

			return unExp.operator + this.stringify(unExp.argument);
		}

		if (type === ExpressionTypes.MemberExpression as string) {
			const memberExp = exp as MemberExpression;
			const member = this.stringify(memberExp.object);
			const property = this.stringify(memberExp.property, false, (memberExp.computed ? 0 : level + 1));

			return member + (memberExp.computed ? `[${property}]` : `.${property}`);
		}

		if (type === ExpressionTypes.ArrayExpression as string) {
			const arrExp = exp as ArrayExpression;
			const arr = arrExp.elements.map((e) => this.stringify(e)).join(', ');

			return `[${arr}]`;
		}

		// TODO Test it
		// if (type === ExpressionTypes.Compound) {
		//	 const compExp = exp as Compound;
		//	 return compExp.body.map(e => this.stringify(e)).join(', ');
		// }

		if (type === ExpressionTypes.Literal as string) {
			return (exp as Literal).raw;
		}

		if (type === ExpressionTypes.Identifier as string) {
			const idExp = exp as Identifier;

			if (!level) {
				if (func) {
					return 'functions.' + idExp.name;
				}
				if (idExp.name.startsWith('$context')) {
					return 'context';
				}
				if (idExp.name.startsWith('$')) {
					return 'context.' + idExp.name.slice(1);
				}

				return 'scope.' + idExp.name;
			}

			return idExp.name;
		}

		if (type === ExpressionTypes.ConditionalExpression as string) {
			const condExp = exp as ConditionalExpression;

			const test = this.stringify(condExp.test);
			const truly = this.stringify(condExp.consequent);
			const falsy = this.stringify(condExp.alternate);

			return `(${test} ? ${truly} : ${falsy})`;
		}

		return null;
	}

}
