import { Component, HostBinding, OnInit, Optional } from '@angular/core';
import { Compound, FormStyle } from '@unifii/sdk';

import { RuntimeField, UfFormControl } from '@unifii/library/common';
import { ComponentRegistry, FormContainerField, FormService, ScopeManager } from '@unifii/library/smart-forms';

import { FormDescriptionListRegistry } from '../../../services';

const componentRegistryFactory = (
	descriptionListRegistry: ComponentRegistry,
) => descriptionListRegistry;

@Component({
	selector: 'uf-action-group',
	templateUrl: './action-group.html',
	styleUrls: ['../form-group.less'],
	providers: [
		ScopeManager,
		{ provide: ComponentRegistry, useFactory: componentRegistryFactory, deps: [FormDescriptionListRegistry] },
	],
})
export class ActionGroupComponent implements FormContainerField, OnInit {

	@HostBinding('class.uf-form-group') groupClassName = true;
	field: RuntimeField;
	scope: Compound;
	control: UfFormControl;
	collapsed: boolean;
	suffix: string;

	@HostBinding('class.disabled') get hostDisabled() {
		return this.control.disabled && !this.isSummary;
	}

	@HostBinding('class.collapsed') get hostCollapsed() {
		return this.collapsed;
	}

	constructor(
		private scopeManager: ScopeManager,
		@Optional() private formService?: FormService,
	) { }

	ngOnInit() {
		/** Action groups required new scope to get a new instance of now */
		this.scopeManager.init(this.field);
		this.scopeManager.scope = this.scope;

		const control = this.scopeManager.getControl(this.field);

		if (control == null) {
			throw Error(`No control available for ActionGroupControl ${this.field.identifier}`);
		}

		this.control = control;
		this.control.disable();
	}

	isVisible(child: RuntimeField): boolean {
		return !this.formService || this.formService.isGranted(child);
	}

	get isSummary(): boolean {
		return this.formService?.style === FormStyle.Summary;
	}

}
