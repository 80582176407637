import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ShowcaseRepositoryService } from '../../services/repository.service';

@Component({
	selector: 'sc-show-lab',
	templateUrl: './show-lab.html',
})
export class ShowLabComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	constructor(
		public repository: ShowcaseRepositoryService,
	) { }

	logout() {
		this.repository.token = null;
		this.repository.user = null;
		this.repository.project = null;
	}

}
