import { Observable, Subject } from 'rxjs';

import { TableDataSourceResult } from './table-models';

export type TableDataSourceQueryOptions = {
	offset?: number;
	limit?: number;
};

export abstract class TableDataSource<T> {

	loadController?: AbortController;

	protected stream: Subject<TableDataSourceResult<T>>;

	constructor() {
		this.stream = new Subject<TableDataSourceResult<T>>();
	}

	abstract get filtered(): boolean;
	abstract get sorted(): boolean;

	get count(): Promise<number | undefined> {
		return Promise.resolve(undefined);
	}

	connect(): Observable<TableDataSourceResult<T>> {
		return this.stream;
	}

	disconnect() {
		if (this.loadController) {
			this.loadController.abort();
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	getDownloadUrl(_options?: TableDataSourceQueryOptions): string | undefined {
		return;
	}

	abortDownload() { /** */ }

	abstract load(options?: TableDataSourceQueryOptions): void;

}
