<button [options]="options" [template]="columnsList" [title]="commonTK.TableActionToggleVisibleColumn | translate"
	type="button" class="uf-action tertiary" listBox>
	<uf-icon name="view" />
</button>
<ng-template #columnsList let-options="options">
	<ul class="uf-list uf-box small">
		<li (click)="reset()" class="uf-list-button">
			<uf-icon name="refreshApp" class="small" />
			<div>{{commonTK.TableActionResetToDefault | translate}}</div>
		</li>
		<li *ngFor="let option of options" class="uf-list-item" checkboxContainer>
			<uf-checkbox [label]="option.label" [(value)]="option.visible" (valueChange)="update()" />
		</li>
	</ul>
</ng-template>