import { AbstractControl } from '@angular/forms';
import { FieldType } from '@unifii/sdk';

import { ExpressionParser, RuntimeField, RuntimeVariation } from '@unifii/library/common';

import { FieldManager, FormField } from '../models';

import { ScopeManager } from './scope-manager';

export class FieldVariationManager implements FieldManager {

	private currentVariation?: RuntimeVariation;
	private originalFieldAsVariation: RuntimeVariation;

	constructor(
		private component: FormField,
		private field: RuntimeField,
		private scopeManager: ScopeManager,
		private expressionParser: ExpressionParser,
	) {
		this.originalFieldAsVariation = JSON.parse(JSON.stringify({
			name: null as any,
			condition: null as any,
			label: field.label,
			placeholder: field.placeholder,
			help: field.help,
			options: field.options,
			sourceConfig: field.sourceConfig,
			validators: field.validators,
		} as RuntimeVariation));
	}

	update() {

		if (!this.component.control) {
			console.warn(`FieldVariationManager.update - component.control null for field ${this.field.identifier}`);

			return;
		}

		const variation = this.getVariation(this.field.variations, this.component.control.value);

		if (variation === this.currentVariation) {
			return;
		}

		this.currentVariation = variation;
		this.updateField();
	}

	private getVariation(variations: RuntimeVariation[], value: any): RuntimeVariation | undefined {

		return variations.find((v) =>
			this.expressionParser.resolve(v.condition ?? '', this.scopeManager.createContext(value), this.component.scope, 'get variation'),
		);
	}

	private updateField() {

		const variation = this.currentVariation ?? this.originalFieldAsVariation;

		const field: RuntimeField = Object.assign({}, this.field, {
			label: variation.label,
			help: variation.help,
			placeholder: variation.placeholder,
			validators: variation.validators,
		});

		if ([FieldType.Choice, FieldType.MultiChoice].includes(this.field.type)) {
			// Bool variation doesn't override options
			field.options = variation.options;
		}

		this.component.field = field;
		this.scopeManager.updateControl(this.field, field.validators);

		/** if control is touched value must be reset */
		if ((this.component.control as AbstractControl).touched) {
			(this.component.control as AbstractControl).setValue(null);
			if (this.component.scope != null) {
				this.component.scope[this.field.identifier as string] = null;
			}
			this.component.content = null;
		}
	}

}
