import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Definition, Option, SchemaField } from '@unifii/sdk';

import { DataDescriptor, DataDescriptorAdapter, DataDescriptorCollectionType } from '../../models';

import { DataDescriptorAdapterCache } from './data-descriptor-adapter-cache';
import { clearPropertiesAndRegisterUnknownsAsSkipped, dataPropertyInfoToDataPropertyDescriptor, displayable,
	fieldToSchemaField, getDefaultOptions, getDisplay, getIcon, getOperators, getPropertiesMap, inputFilterable,
	normalizeIdentifiersList,
	schemaFieldToDataPropertyDescriptor, sortDataPropertyDescriptors, sortable, staticFilterable } from './data-descriptor-functions';
import { DataPropertyInfoService } from './data-property-info.service';

export interface CollectionDataDescriptorAdapterLoader {
	loadCollectionDefinition(identifier: string): Promise<Definition>;
}
export const CollectionDataDescriptorAdapterLoader = new InjectionToken<CollectionDataDescriptorAdapterLoader>('UserDataDescriptorAdapterLoader');

export interface CollectionDataDescriptorPermissionController {
	canLoadCollectionDefinition(identifier: string): boolean;
}
export const CollectionDataDescriptorPermissionController = new InjectionToken<CollectionDataDescriptorPermissionController>('CollectionDataDescriptorPermissionController');

@Injectable({ providedIn: 'root' })
export class CollectionDataDescriptorAdapter implements DataDescriptorAdapter {

	private readonly adapterDataType = DataDescriptorCollectionType;

	constructor(
		private dataModelEntryService: DataPropertyInfoService,
		private translate: TranslateService,
		private cache: DataDescriptorAdapterCache,
		@Inject(CollectionDataDescriptorAdapterLoader) private loader: CollectionDataDescriptorAdapterLoader,
		@Optional() @Inject(CollectionDataDescriptorPermissionController) private permissions: CollectionDataDescriptorPermissionController | null,
	) { }

	/**
	 * Analyze a 'Collection'
	 * @param collectionIdentifier collection to analyze
	 * @param identifiers whitelist of properties' identifier to analyze, all otherwise
	 */
	async getDataDescriptor(collectionIdentifier: string, identifiers?: string[]): Promise<DataDescriptor | undefined> {

		const normalizedIdentifiers = normalizeIdentifiersList(DataDescriptorCollectionType, identifiers);

		// console.log(`DDCollection ${identifier} `, properties);
		if (this.permissions?.canLoadCollectionDefinition(collectionIdentifier) === false) {
			return {
				type: this.adapterDataType,
				propertyDescriptors: [],
				propertyDescriptorsMap: new Map(),
				isSearchable: false,
				skippedProperties: [{ identifier: this.adapterDataType, name: `No permission for collections/${collectionIdentifier}` }],
			};
		}

		const definition = await this.loadCollection(collectionIdentifier);

		if (!definition) {
			console.warn(`CollectionDataDescriptorAdapter - collection ${collectionIdentifier} not found`);

			return;
		}

		const skippedProperties: Option[] = [];

		const collectionItemMetadataDescriptors = Object.values(this.dataModelEntryService.collectionItemReferences)
			.filter((i) => !normalizedIdentifiers || normalizedIdentifiers.has(i.identifier))
			// UNIFII-7127 override the Collection.id to by of type Text has been moved to the Console DataSourceEditor only to not affect all others DDE based logics
			// .map((ref) => {
			// 	// CollectionItem.id is considered Text when managed as data
			// 	if (ref.identifier === `${CollectionItemMetadataIdentifiers.Id}`) {
			// 		return Object.assign({}, ref, { type: FieldType.Text });
			// 	}

			// 	return ref;
			// })
			.map((ref) => dataPropertyInfoToDataPropertyDescriptor(ref, this.adapterDataType, this.translate));

		const collectionDefinitionDescriptors = (definition.fields
			.map(fieldToSchemaField)
			.filter((sf) => sf) as SchemaField[])
			.filter((sf) => !normalizedIdentifiers || normalizedIdentifiers.has(sf.identifier))
			.map((sf) => schemaFieldToDataPropertyDescriptor(sf, this.adapterDataType));

		// Sorted entries
		let entries = [
			...sortDataPropertyDescriptors(collectionItemMetadataDescriptors),
			...sortDataPropertyDescriptors(collectionDefinitionDescriptors),
		];

		// Clear entries list
		entries = clearPropertiesAndRegisterUnknownsAsSkipped(entries, skippedProperties, normalizedIdentifiers);

		// Amend
		for (const entry of entries) {
			entry.display = getDisplay(entry.identifier, entry.label);
			entry.icon = getIcon(entry.identifier, entry.type, this.adapterDataType);
			entry.options = getDefaultOptions(entry.type, this.translate, entry.options, entry.sourceConfig);
			entry.asDisplay = displayable(entry, this.adapterDataType);
			entry.asSort = sortable(entry, this.adapterDataType);
			entry.asStaticFilter = staticFilterable(entry, this.adapterDataType);
			entry.asInputFilter = inputFilterable(entry, this.adapterDataType);
			entry.operators = getOperators(entry.type, entry.identifier, this.adapterDataType);
		}

		return {
			type: this.adapterDataType,
			propertyDescriptors: entries,
			propertyDescriptorsMap: getPropertiesMap(entries),
			isSearchable: true,
			skippedProperties,
		};
	}

	private loadCollection(id: string): Promise<Definition | undefined> {

		const existing = this.cache.collections.get(id);

		if (existing) {
			return existing;
		}

		const loader = this.loader.loadCollectionDefinition(id).catch((e) => {
			console.error('loadCollection error', e);

			return undefined;
		});

		this.cache.collections.set(id, loader);

		return loader;
	}

}
