import { Component, Input } from '@angular/core';

import { DataDisplayComponentType, DataDisplayPrimitiveValue, isDataDisplayPrimitiveValue } from '@unifii/library/common';

/** Override the DataDisplayPrimitive component, see ShowcaseDataDisplayComponentRegistry */
@Component({
	selector: 'sc-data-display-bold',
	templateUrl: './show-data-display-bold.html',
})
export class DataDisplayBoldComponent implements DataDisplayComponentType {

	@Input({ required: true }) data: DataDisplayPrimitiveValue;

	static isDataAccepted = isDataDisplayPrimitiveValue;

}
