import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

import { DeviceInfo } from '../services';

type Target = '_self' | '_blank' | '_parent' | '_top';

/**
 * This directive is responsible for setting the correct target property on anchor elements
 */
@Directive({
	selector: '[ufTarget]',
})
export class AnchorTargetDirective implements OnInit {

	@Input() ufTarget: Target;

	constructor(
		private deviceInfo: DeviceInfo,
		private elementRef: ElementRef,
		private renderer: Renderer2,
	) { }

	ngOnInit() {
		const target = this.getTarget(this.ufTarget);

		this.renderer.setAttribute(this.elementRef.nativeElement, 'target', target);
	}

	private getTarget(target: Target): Target {
		if (this.deviceInfo.isNative()) {
			return '_self';
		}

		return target;
	}

}
