<sc-page-content width="lg" identifier="toaster">

	<ng-container editorInputs>
		<uf-radio [columns]="timingOptions.length" [options]="timingOptions" [(value)]="time" label="Durations"
			valueProperty="identifier" nameProperty="name" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander>
			<div class="col grow">
				<div class="row space-children justify-center">
					<button [disabled]="inProgress" (click)="show(messageLevels.Error)" type="button" class="uf-button">
						Open Error
					</button>
					<button [disabled]="inProgress" (click)="show(messageLevels.Warning)" type="button"
						class="uf-button">
						Open Warning
					</button>
					<button [disabled]="inProgress" (click)="show(messageLevels.Info)" type="button" class="uf-button">
						Open Information
					</button>
					<button [disabled]="inProgress" (click)="show(messageLevels.Success)" type="button"
						class="uf-button">
						Open Success
					</button>
				</div>
				<div class="col center-all space-children">
					<br>
					<uf-toast [data]="{ level: messageLevels.Error, message: 'This is an error message' }" />
					<uf-toast [data]="{ level: messageLevels.Warning, message: 'This is a warning message' }" />
					<uf-toast [data]="{ level: messageLevels.Info, message: 'This is an information message' }" />
					<uf-toast [data]="{ level: messageLevels.Success, message: 'This is a success message' }" />
				</div>
			</div>
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="apiColConfig" [rows]="propertiesRows" />
	</ng-container>

</sc-page-content>