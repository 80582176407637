import { ChangeDetectorRef, Component, HostBinding, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { Subscription } from 'rxjs';

import { SortStatus } from '../../directives';
import { CommonTranslationKey } from '../../translations';

import { TableColumnVisible } from './table-models';
import { TablePreferencesProvider } from './table-preferences-provider-model';
import { TableComponent } from './table.component';

interface TableColumnVisibleOptions extends TableColumnVisible {
	label?: string;
}

@Component({
	selector: 'uf-table-column-toggles',
	templateUrl: './table-column-toggles.html',
	styleUrls: ['./table-column-toggles.less'],
})
export class TableColumnTogglesComponent<T> implements OnInit, OnDestroy {

	readonly commonTK = CommonTranslationKey;

	options: TableColumnVisibleOptions[];

	private _table: TableComponent<T> | undefined;
	private subscriptions = new Subscription();

	constructor(
		private cd: ChangeDetectorRef,
		@Optional() parent?: TableComponent<T>,
		@Optional() @Inject(TablePreferencesProvider) private tablePreferencesProvider?: TablePreferencesProvider,
	) {
		if (parent) {
			this.table = parent;
		}
	}

	@HostBinding('class.visible') get visible(): boolean {
		if (!this.table?.config) {
			return false;
		}

		return this.table.config.columns.length > 0 && this.table.config.columnToggles === true;
	}

	ngOnInit() {
		this.updateOptions();
		if (this.table) {
			this.subscriptions.add(this.table.visibleColumnsChange.subscribe(() => { this.updateOptions(); }));
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	@Input() set table(t: TableComponent<T>) {
		this._table = t;
		this.subscriptions.add(this._table.ready.subscribe(() => { this.updateOptions(); }));
	}

	get table(): TableComponent<T> | undefined {
		return this._table;
	}

	update() {
		this.table?.updateVisibleColumns(this.options);
	}

	reset() {
		if (!this.table?.defaultPreferences?.columns) {
			return;
		}

		this.table.updateVisibleColumns(this.table.defaultPreferences.columns);

		this.table.sort = this.table.defaultPreferences.sort ?
			new SortStatus(this.table.defaultPreferences.sort.name, this.table.defaultPreferences.sort.direction) :
			undefined;

		this.table.sortChange.emit(this.table.sort ?? undefined);

		if (this.tablePreferencesProvider && this.table.config.id) {
			this.tablePreferencesProvider.saveTablePreferences(this.table.config.id);
		}
	}

	private updateOptions() {
		if (!this.table?.config) {
			return;
		}

		this.options = this.table.config.columns.map((c) =>
			({ name: c.name, label: c.label, visible: !c.hidden }));

		this.cd.detectChanges();
	}

}
