import { Component, EventEmitter, Input, OnInit, inject } from '@angular/core';
import { DataSeed, FieldTemplate, FieldType, OPTION_IDENTIFIER, OPTION_NAME, Option } from '@unifii/sdk';

import { DataSourceDisplayTo, DataSourceIdTo, NamePropertyInfo, RuntimeField, ToastService, UfControl } from '@unifii/library/common';
import { FormField, FormFieldService } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-choice-input',
	templateUrl: './choice.html',
})
export class ChoiceComponent implements FormField, OnInit {

	field: RuntimeField;
	control: UfControl;
	content: any;
	contentChange: EventEmitter<any>;
	suffix: string;
	cssClass: string | string[];
	valueProperty: string | null;
	nameProperty: NamePropertyInfo;
	trackBy: string;

	protected readonly fieldTemplate = FieldTemplate;
	protected readonly fieldType = FieldType;

	private _options: DataSeed[] | Option[] | undefined | null;
	private toastService = inject(ToastService);
	private formFieldService = inject(FormFieldService, { optional: true });

	@Input() set options(v: DataSeed[] | Option[]) {
		this._options = v;
	}

	get options(): DataSeed[] | Option[] {
		return this._options ?? this.field.options;
	}

	ngOnInit() {
		/**
		 * Value type can either be an Option or DataSeed
		 * valueProperty, nameProperty and trackBy have to be updated to accommodate the change in data type
		 */
		const isDataSeed = this.field.sourceConfig != null;

		this.valueProperty = isDataSeed ? null : OPTION_IDENTIFIER;
		this.nameProperty = isDataSeed ? DataSourceDisplayTo : OPTION_NAME;
		this.trackBy = isDataSeed ? DataSourceIdTo : OPTION_IDENTIFIER;
	}

	retry = () => {
		return this.formFieldService?.updateDataSourceOptions();
	};

	changed(v: any) {
		this.content = v;
		this.contentChange.emit(v);
	}

	onError(error: Error) {
		this.toastService.error(error.message);
	}

}
