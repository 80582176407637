<div class="uf-app-bar transparent">
	<div class="img-wrap">
		<img *ngIf="img" [attr.src]="img" />
	</div>
	<button (click)="toggleMenu.emit()" type="button" class="uf-action hamburger transparent">
		<uf-icon name="arrowBack" />
	</button>
</div>
<uf-panel class="container">
	<ul class="uf-nav-list small">
		<ng-template [ngForOf]="tree" let-node ngFor>
			<sc-menu-item [node]="node" />
		</ng-template>
	</ul>
</uf-panel>