<uf-expander *ngIf="field" [actionBtn]="true" [(isExpanded)]="contentExpanded">
	<div [class.small]="control.disabled && isSummary" expanderHeader class="uf-app-bar medium">
		<uf-icon *ngIf="field.icon" [name]="field.icon" />
		<div class="title">
			{{field.label}}
			<uf-help *ngIf="field.help" [content]="field.help" icon="infoSolid" class="grow" />
		</div>
	</div>
	<div expanderBody class="grid">
		<ng-template [ngForOf]="field.fields" let-child ngFor>
			<ng-container *ngIf="isVisible(child) && child.type !== fieldTypes.ActionGroup">
				<ng-template [ngIf]="child.identifier" [ngIfElse]="nonContent">
					<uf-field [field]="child" [scope]="scope" [(content)]="scope[child.identifier]" />
				</ng-template>
				<ng-template #nonContent>
					<uf-field [field]="child" [scope]="scope" />
				</ng-template>

				<uf-break-after *ngIf="child?.breakAfter" />
			</ng-container>
		</ng-template>
		<ng-container *ngFor="let revision of revisionData">
			<uf-field [field]="revision.field" [scope]="revision.formData" [(content)]="revision.formData"
				readOnly="true" />
		</ng-container>
	</div>
</uf-expander>