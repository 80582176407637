import { Component } from '@angular/core';
import { ZonedDateTime } from '@unifii/sdk';
import { format } from 'date-fns';

import { ColumnGap, FnsDatetimeUtc, Size, UfControl, getLocalTimeZone } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-datetime-tz.html',
})
export class ShowDatetimeTimezoneComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly sizeOptions = [
		{ name: 'default', value: '' },
		{ name: 'small', value: 'small' },
	];

	readonly inputTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'default', name: 'Default' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly inputTableInfo = [
		{ name: 'control', type: 'UfControl', default: 'new UfControl()', description: 'A control object to manage validators, states etc.' },
		{ name: 'id', type: 'string', default: 'undefined', description: 'Sets id attribute of inner input element' },
		{ name: 'name', type: 'string', default: 'undefined', description: 'Sets name attribute of inner input element' },
		{ name: 'label', type: 'string', default: 'undefined', description: 'Sets label' },
		{ name: 'placeholder', type: 'string', default: 'undefined', description: 'Sets placeholder' },
		{ name: 'timezone', type: 'string', default: 'undefined', description: 'Sets timezone' },
		{ name: 'step', type: 'number | string', default: 'undefined', description: 'Sets step increments for time input' },
		{ name: 'defaultTime', type: 'string', default: 'undefined', description: 'Sets default time for time input' },
	];
	readonly outputTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly outputTableInfo = [
		{ name: 'valueChange', type: 'EventEmitter', description: 'Emits an event when the value of the input is changed' },
	];
	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'default', type: 'modifier', description: 'Default input size' },
		{ name: 'small', type: 'modifier', description: 'Small input size' },
	];

	protected size = '';
	protected template = `
		<uf-datetime-tz label="Date Time Timezone"></uf-datetime-tz>
	`;

	protected value: ZonedDateTime | null = {
		tz: getLocalTimeZone(),
		value: '2020-09-21T15:08:28.464+02:00',
	};
	protected control = new UfControl();

	protected setValue(empty = false) {

		if (empty) {
			this.value = null;

			return;
		}

		const d = new Date();

		this.value = {
			tz: getLocalTimeZone(),
			value: format(d, FnsDatetimeUtc),
		};
	}

	protected modifierChange() {
		this.template = `<uf-datetime-tz label="Date Time"${this.size ? ' class="' + this.size + '"' : ''}></uf-datetime-tz>`;
	}

}
