import { FieldType } from '@unifii/sdk';

export const areRolesMatching = (available: string | string[] = [], requested: string | string[] = []): boolean => {

	requested = Array.isArray(requested) ? requested : getStringRoles(requested);

	if (!requested.length) {
		return true;
	}

	available = Array.isArray(available) ? available : getStringRoles(available);

	return available.find((availableRole) => requested.includes(availableRole)) != null;
};

export const getStringRoles = (csvRoles?: string): string[] => csvRoles?.split(',').map((role) => role.trim()) ?? [];

export const isGroup = (type: FieldType): boolean =>
	[FieldType.Section, FieldType.Group, FieldType.ActionGroup, FieldType.Address, FieldType.GeoLocation, FieldType.Survey, FieldType.Stepper, FieldType.Step].includes(type);

/** Sort roles in ascending order
 *
 * @param a first input of the comparison
 * @param b second input of the comparison
 */
export const stringsCaseInsensitiveLocalCompare = (a: string, b: string): number => {
	if ((a as string | null) == null) {
		return 1;
	}
	if ((b as string | null) == null) {
		return -1;
	}

	return a.toLowerCase().localeCompare(b.toLowerCase());
};
