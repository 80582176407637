import { Component, HostBinding } from '@angular/core';

import { CommonTranslationKey } from '../../translations';

import { TableComponent } from './table.component';

@Component({
	selector: 'uf-table-status',
	templateUrl: './table-status.html',
	styleUrls: ['./table-status.less'],
})
export class TableStatusComponent<T> {

	readonly commonTK = CommonTranslationKey;

	@HostBinding('class.show') get display() {
		return (
			this.error != null ||
			this.showLoading ||
			this.showEmpty ||
			this.showEmptyFiltered ||
			this.showLoadButton
		);
	}

	constructor(protected table: TableComponent<T>) { }

	get error() {
		return this.table.status.error;
	}

	get showLoading(): boolean {
		return this.table.status.loading;
	}

	get showEmpty(): boolean {
		return !this.showLoading &&
			this.error == null &&
			this.table.datasource != null &&
			this.table.status.empty &&
			!this.table.status.filtered;
	}

	get showEmptyFiltered(): boolean {
		return !this.showLoading &&
			this.error == null &&
			this.table.datasource != null &&
			this.table.status.empty &&
			this.table.status.filtered;
	}

	get showLoadButton(): boolean {
		return !this.showLoading &&
			this.error == null &&
			this.table.showLoad;
	}

}
