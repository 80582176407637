import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../components';

@Component({
	selector: 'sc-show-borders',
	templateUrl: './show-borders.html',
	styleUrls: ['./_foundation.less'],
})
export class ShowBordersComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'border-all', type: 'modifier', description: 'Sets a border on all edges' },
		{ name: 'border-right', type: 'modifier', description: 'Sets a border on the right edge' },
		{ name: 'border-left', type: 'modifier', description: 'Sets a border on the left edge' },
		{ name: 'border-top', type: 'modifier', description: 'Sets a border on the top edge' },
		{ name: 'border-bottom', type: 'modifier', description: 'Sets a border on the bottom edge' },
	];

}
