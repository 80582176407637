import { Component, OnInit } from '@angular/core';
import { hasLengthAtLeast } from '@unifii/sdk';

import { DisplayContent, DisplayService } from '@unifii/library/smart-forms/display';

import { CompoundInfo, UsAPIContentClient } from '../../services';

@Component({
	selector: 'sc-show-view',
	templateUrl: './show-view.html',
})
export class ShowViewComponent implements OnInit {

	protected views: CompoundInfo[] = [];
	protected id: string;
	protected data?: DisplayContent;

	constructor(
		private usContent: UsAPIContentClient,
		private displayService: DisplayService,
	) { }

	async ngOnInit() {
		this.views = await this.usContent.getViews();
		if (hasLengthAtLeast(this.views, 1)) {
			void this.render(this.views[0].id);
		}
	}

	async render(id: string) {

		this.id = id;

		if (!this.id) {
			delete this.data;

			return;
		}

		try {
			const view = await this.usContent.getView(+this.id);

			this.data = await this.displayService.renderCompound(view._definition, view);
		} catch (err) {
			console.error('ERROR', err);
		}
	}

}
