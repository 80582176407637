<div class="grid--fixed">
	<div class="col-1of1 body-copy">
		<h3>Test Modals</h3>

		<p>Start with a fit container</p>
		<button (click)="openFit()" class="uf-button" type="button">Open Fit</button>

		<p>Or is a medium size what your after?</p>
		<button (click)="openMedium()" class="uf-button" type="button">Open</button>

		<p>Or you might need a large size?</p>
		<button (click)="openLarge()" class="uf-button" type="button">Open</button>

		<p>Want something full screen?</p>
		<button (click)="openFullScreen()" class="uf-button" type="button">Open</button>

		<p>Maybe something inline wity your input is what your after?</p>
		<uf-time label="Whats the time?" isFloating="true" />

		<h2>Custom Modals</h2>
		<div class="row">
			<button (click)="openConfirm()" type="button" class="uf-button">Open Confirm</button>
			<button (click)="openAlert()" type="button" class="uf-button">Open Alert</button>
		</div>
	</div>
</div>