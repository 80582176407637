<sc-page-content width="xl" identifier="panel">

	<ng-container editor>
		<sc-code-editor-expander [code]="template">
			<uf-panel class="uf-card grow" style="min-height: 200px;">
				<sc-column-demo class="uf-grid" style="min-height: 220px;" />
			</uf-panel>
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">Properties</h3>
		<sc-info-table [columnConfig]="propertiesColConfig" [rows]="propertiesRows" />
		<h3 class="pad-sides">Outputs</h3>
		<sc-info-table [columnConfig]="outputColConfig" [rows]="outputRows" />
	</ng-container>

</sc-page-content>