<ng-template [ngIf]="mode === modes.Input">
	<ng-container [ngSwitch]="entry.type">
		<uf-date [ngSwitchCase]="filterTypes.Date" [(value)]="valueString" (valueChange)="valueChanged()" />
		<uf-datetime [ngSwitchCase]="filterTypes.Datetime" [(value)]="valueString" (valueChange)="valueChanged()"
			defaultTime="00:00:00" />
		<uf-time [ngSwitchCase]="filterTypes.Time" [(value)]="valueString" (valueChange)="valueChanged()" />
		<uf-datetime-tz [ngSwitchCase]="filterTypes.ZonedDatetime" [(value)]="valueZDT" (valueChange)="valueChanged()"
			class="stacked" />
	</ng-container>
</ng-template>

<div *ngIf="mode === modes.Display && !isEmpty" class="uf-chip x-small">
	{{ label }}: {{ displayValue }}
	<button (click)="remove()" type="button" class="uf-action">
		<uf-icon name="delete" />
	</button>
</div>