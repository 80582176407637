import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { UfControlGroup } from '../controls';

@Pipe({
	name: 'asUfControlsGroups',
})
export class AsUfControlsGroupsPipe implements PipeTransform {

	transform(value: AbstractControl[]): UfControlGroup[] {
		return value as UfControlGroup[];
	}

}
