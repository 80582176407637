import { Component, Input, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HierarchyUnitFormData } from '@unifii/sdk';

import { HierarchyUnitIdentifier, HierarchyUnitPickerData } from '../../models/hierarchy-model';
import { ModalService } from '../../services/modal.service';
import { SharedTermsTranslationKey } from '../../translations';

import { HierarchyUnitPickerComponent } from './hierarchy-utilities/hierarchy-unit-picker.component';
import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-hierarchy-multi-select',
	templateUrl: './uf-hierarchy-multi-select.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfHierarchyMultiSelectComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-hierarchy-multi-select.less'],
})
export class UfHierarchyMultiSelectComponent extends UfControlValueAccessor<HierarchyUnitFormData[]> {

	@Input() label?: string | null;
	@Input() placeholder?: string | null;
	@Input() ceiling?: HierarchyUnitIdentifier | null = 'root';
	@Input() selectLeafsOnly?: boolean | null = true;
	@Input() preselected?: HierarchyUnitIdentifier | null;
	@Input() allowDuplicates?: boolean | null;
	@Input() unselectableUnits?: HierarchyUnitIdentifier[] | null;

	readonly sharedTermsTK = SharedTermsTranslationKey;

	private isPickerOpen: boolean;
	private modalService = inject(ModalService);

	async onFocus() {
		if (this.disabled || this.isPickerOpen) {
			return;
		}

		const componentValue = this.value ?? [];
		const unselectableUnits = this.unselectableUnits ?? [];

		this.isPickerOpen = true;
		const data: HierarchyUnitPickerData = {
			value: null,
			label: this.label,
			ceiling: this.ceiling,
			preselected: this.preselected,
			selectLeafsOnly: this.selectLeafsOnly,
			unselectableUnits: this.allowDuplicates ? unselectableUnits : [...componentValue, ...unselectableUnits],
		};

		await this.modalService.openLarge(HierarchyUnitPickerComponent,
			data,
			{
				update: (value) => {
					if (value as unknown) {
						this.control.setValue([...(this.value ?? []), value]);
						this.control.markAsTouched();
					}
				},
			},
		);
		this.isPickerOpen = false;
	}

	remove(id: string) {
		if (!this.value) {
			return;
		}

		this.control.setValue(this.value.filter((hierarchy) => hierarchy.id !== id));
	}

}
