import { Component, ElementRef, EventEmitter, HostBinding, OnInit, ViewChild, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentLinkFormData, LinkContentType, PublishedContent } from '@unifii/sdk';

import { ModalService, RuntimeField, SharedTermsTranslationKey, UfControlGroup, getContentLinkLabel } from '@unifii/library/common';
import { FormField, FormSettings } from '@unifii/library/smart-forms';

import { ContentLinkPickerComponent, ContentLinkPickerData } from '../../modals';

@Component({
	selector: 'uf-content-link-input',
	templateUrl: './content-link.html',
	styleUrls: ['./content-link.less'],
})
export class ContentLinkComponent implements FormField, OnInit {

	@ViewChild('button') buttonElement: ElementRef<HTMLButtonElement> | null;

	field: RuntimeField;
	control: UfControlGroup;
	contentChange: EventEmitter<ContentLinkFormData>;
	content?: ContentLinkFormData;

	protected readonly sharedTK = SharedTermsTranslationKey;
	protected label: string | undefined;

	private modalService = inject(ModalService);
	private formSettings = inject(FormSettings);
	private publishedContent = inject(PublishedContent);
	private translateService = inject(TranslateService);
	private isPickerOpen: boolean;

	@HostBinding('class.error') get hostError() {
		return this.control.submitted && this.control.invalid;
	}

	@HostBinding('class.disabled') get hostDisabled() {
		return this.disabled;
	}

	ngOnInit() {
		this.control.setValue(this.content as ContentLinkFormData);
		this.label = this.content ? getContentLinkLabel(this.content, this.translateService) : undefined;
	}

	get disabled(): boolean {
		return this.control.disabled;
	}

	protected async showPicker() {

		if (this.disabled || this.isPickerOpen) {
			return;
		}

		this.isPickerOpen = true;

		const data: ContentLinkPickerData = {
			value: this.content,
			label: this.field.label,
			allowedTypes: this.field.visibleFields as LinkContentType[],
		};

		await this.modalService.openLarge(
			ContentLinkPickerComponent,
			data,
			// Work with update to avoid registering a change of value when a null is passed for a dialog close
			{
				update: (value) => {
					this.control.setValue(value as ContentLinkFormData);
					this.control.markAsTouched();
					this.contentChange.emit(value);
					this.label = value ? getContentLinkLabel(value, this.translateService) : undefined;
				},
			},
			[
				{ provide: FormSettings, useValue: this.formSettings },
				{ provide: PublishedContent, useValue: this.publishedContent },
			],
		);
		this.isPickerOpen = false;
		this.buttonElement?.nativeElement.focus();
	}

}
