<ul class="uf-nav-list">
	<ng-container *ngFor="let item of navItems">
		<li *ngIf="item.isRegistered" [ngClass]="{
			active: item.isActive,
			disabled: item.isDisabled,
			error: item.hasError,
			h1: item.parentCount === 0,
			'indented-1': item.parentCount === 2,
			'indented-2': item.parentCount === 3,
			accented: item.isAccented,
			heading: item.isHeading,
			}" (click)="onSelect(item)">
			<uf-icon *ngIf="!item.hasError && !item.isActive && item.hasSuccess" name="tick" />
			<span>{{ item.label }}</span>
		</li>
	</ng-container>
</ul>