import { AstNode, DataSource, DataSourceInputType, DataSourceOutputMap, DataSourceType, Dictionary, FieldType, IntegrationArgument, OutputDescriptor, OutputField, VisibleFilterDescriptor, isNotNull, isValueOfStringEnumType } from '@unifii/sdk';

import { DataSourceDisplayLabel, DataSourceDisplayTo, DataSourceIdFrom, DataSourceIdLabel, DataSourceIdTo, UserInfoIdentifiers } from '../../constants';
import { SortStatus } from '../../directives';
import { SourceConfig, SourceConfigInput, SourceConfigMapping, SourceConfigMappingsInfo } from '../../models';
import { ValidatorFunctions, isTemplateExpression } from '../../utils';

export const idDefaultMapping = (overrideDefaultFrom?: string): SourceConfigMapping => ({
	from: overrideDefaultFrom ?? DataSourceIdFrom,
	to: DataSourceIdTo,
	label: DataSourceIdLabel,
	type: FieldType.Text,
});

export const displayDefaultMapping = (overrideDefaultFrom?: string): SourceConfigMapping => ({
	from: overrideDefaultFrom ?? DataSourceIdFrom,
	to: DataSourceDisplayTo,
	label: DataSourceDisplayLabel,
	type: FieldType.Text,
});

/** String format exists only for Users, Collection and Named types
 *  <type[:identifier]>[[,display[,includes[0],includes[1],...]]]
 */
// eslint-disable-next-line complexity
export const parseDataSource = (source?: string | DataSource): DataSource | undefined => {

	if (!source) {
		return;
	}

	if (typeof source === 'object') {
		return source;
	}

	// Check that type is not 'bucket' or 'external' as those are not allowed to be in literal form
	const typeValueForCheck = source.split(',')[0]?.split(':')[0]?.trim();

	if (!typeValueForCheck) {
		console.warn(`DataSource '${source}' invalid`);

		return;
	}

	if (['bucket', 'external'].includes(typeValueForCheck)) {
		console.warn(`DataSource '${source}' is not allowed for 'bucket' and 'external' types`);

		return;
	}

	// Split the literal form in its components
	const comaSplit = source.split(',');

	// <type> and optional <identifier>
	const typePart = comaSplit[0]?.trim();

	if (!typePart) {
		console.warn(`DataSource '${source}' has no type defined`);

		return;
	}

	// optionals <display> and <includes>
	const displayIncludesPart = comaSplit.length > 1 ? comaSplit.slice(1).join(',').trim() : undefined;

	// Retrieve type and identifier
	const typeSplit = typePart.split(':');
	const typeValue = typeSplit[0]?.trim();

	if (!typeValue) {
		console.warn(`DataSource '${source}' invalid type '${typePart}' format`);

		return;
	}

	const identifier = typeSplit.length > 1 ? typeSplit.slice(1).join(':').trim() : undefined;
	const typeStringValue = (typeValue.charAt(0).toUpperCase() + typeValue.substring(1)); // as keyof typeof DataSourceType;

	let type: DataSourceType;

	// If no display and includes are defined the data-source is considered Custom even when its name match Users or Collection
	if (!displayIncludesPart?.length) {
		type = DataSourceType.Named;
	} else {
		type = isValueOfStringEnumType(DataSourceType)(typeStringValue) ?
			DataSourceType[typeStringValue] :
			DataSourceType.Named;
	}

	if (type === DataSourceType.Named) {
		return {
			type: DataSourceType.Named,
			id: typeValue,
		};
	}

	let display: string | undefined;
	const includes: Dictionary<string> = {};

	if (type === DataSourceType.Collection && (!identifier?.length)) {
		console.warn(`Collection dataSource ${source} missing <id> attribute`);

		return;
	}

	// Display and Includes
	if (displayIncludesPart?.length) {
		const parts = displayIncludesPart.split(',').map((p) => p.trim());

		display = parts[0];
		const outputParts = (parts.length > 1 ? parts.slice(1) : []);

		for (const v of outputParts) {
			includes[v] = v;
		}
	}

	if (!display?.trim().length) {
		display = 'id'; // Default valid for Users and Collection
	}

	const dataSource: DataSource = {
		type,
		outputs: Object.assign(includes, {
			_id: 'id', // Same for Users and Collection
			_display: display,
		}),
	};

	// Addition configuration for Collection only
	if (type === DataSourceType.Collection) {
		dataSource.id = identifier ?? '';
		if (dataSource.outputs) {
			dataSource.outputs[display] = display; // display value map to itself key
		}
	}

	return dataSource;
};

/** Convert a DataSource to a SourceConfig */
export const dataSourceToSourceConfig = (dataSource: DataSource): SourceConfig => {

	const mappingsInfo = toSourceConfigMappings(dataSource);
	const type = dataSource.type;
	const id = dataSource.id ?? '';
	const inputs = toSourceConfigInputs(dataSource);
	const { sort, filter, visibleFilters, findBy } = dataSource;

	let sortStatus: SortStatus | undefined;

	if (sort) {
		sortStatus = SortStatus.fromString(sort) ?? undefined;

		if (type === DataSourceType.Users && sortStatus?.name === 'fullName') {
			/** Projection property "fullName" not supported for sort */
			sortStatus = new SortStatus(UserInfoIdentifiers.Username);
		}
	}

	const normalizedSort = sortStatus?.toString();

	switch (type) {
		case DataSourceType.Users:
			return { type, ...mappingsInfo, sort: normalizedSort, filter, visibleFilters, findBy };
		case DataSourceType.Company:
			return { type, ...mappingsInfo, sort: normalizedSort, filter, visibleFilters };
		case DataSourceType.Collection:
			return { type, ...mappingsInfo, id, sort: normalizedSort, filter, visibleFilters, findBy };
		case DataSourceType.Bucket:
			return { type, ...mappingsInfo, id, sort: normalizedSort, filter, visibleFilters, findBy };
		case DataSourceType.UserClaims:
			return { type, ...mappingsInfo, id };
		case DataSourceType.External:
			return { type, id, inputs, ...mappingsInfo };
		case DataSourceType.Named:
			return { type, id };
	}
};

/** Convert a SourceConfig to a DataSource */
export const sourceConfigToDataSource = (sourceConfig: SourceConfig): DataSource => {

	let id: string | undefined;
	let sort: string | undefined;
	let filter: AstNode | undefined;
	let visibleFilters: VisibleFilterDescriptor[] | undefined;
	let findBy: string | undefined;
	let outputsInfo: DataSourceOutputsInfo | undefined;
	let inputsInfo: DataSourceInputsInfo | undefined;

	switch (sourceConfig.type) {
		case DataSourceType.Users:
			sort = sourceConfig.sort;
			filter = sourceConfig.filter;
			visibleFilters = sourceConfig.visibleFilters;
			findBy = sourceConfig.findBy;
			outputsInfo = toDataSourceOutputs(sourceConfig.mappings);
			break;
		case DataSourceType.Company:
			sort = sourceConfig.sort;
			filter = sourceConfig.filter;
			visibleFilters = sourceConfig.visibleFilters;
			outputsInfo = toDataSourceOutputs(sourceConfig.mappings);
			break;
		case DataSourceType.Bucket:
		case DataSourceType.Collection:
			id = sourceConfig.id;
			sort = sourceConfig.sort;
			filter = sourceConfig.filter;
			visibleFilters = sourceConfig.visibleFilters;
			findBy = sourceConfig.findBy;
			outputsInfo = toDataSourceOutputs(sourceConfig.mappings);
			break;
		case DataSourceType.UserClaims:
			id = sourceConfig.id;
			outputsInfo = toDataSourceOutputs(sourceConfig.mappings);
			break;
		case DataSourceType.External:
			id = sourceConfig.id;
			inputsInfo = toDataSourceInputs(sourceConfig.inputs);
			outputsInfo = toDataSourceOutputs(sourceConfig.mappings);
			break;
		case DataSourceType.Named:
			id = sourceConfig.id;
			break;
	}

	return {
		type: sourceConfig.type,
		id,
		sort,
		filter,
		visibleFilters,
		outputs: outputsInfo?.outputs,
		outputFields: outputsInfo?.outputFields,
		outputDescriptors: outputsInfo?.outputDescriptors,
		inputs: inputsInfo?.inputs,
		inputArgs: inputsInfo?.inputArgs,
		findBy,
	};
};

export const sourceConfigMappingsToSourceConfigMappingInfo = (mappings: SourceConfigMapping[], outputDescriptors?: OutputDescriptor[]): SourceConfigMappingsInfo => {

	const info: SourceConfigMappingsInfo = {
		mappings,
		mappingsFrom: {},
		mappingsTo: {},
		mappingsVisibles: [],
	};

	for (const mapping of mappings) {
		info.mappingsFrom[mapping.from] = mapping;
		info.mappingsTo[mapping.to] = mapping;
	}

	if (outputDescriptors) {
		info.mappingsVisibles = outputDescriptors
			.map((od) => info.mappingsTo[od.output])
			.filter((mapping) => mapping != null) as SourceConfigMapping[];
	} else {
		info.mappingsVisibles = info.mappings.filter((m) => m.isVisible);
	}

	return info;
};

const toSourceConfigMappings = (info: {outputs?: DataSourceOutputMap; outputFields?: Dictionary<OutputField>; outputDescriptors?: OutputDescriptor[] } ): SourceConfigMappingsInfo => {

	if (!info.outputs) {
		return sourceConfigMappingsToSourceConfigMappingInfo([]);
	}

	const outputs = Object.assign({}, info.outputs);
	const mappings = Object.keys(outputs).map((to) => {

		// OutputField override the values of OutputDescriptor
		const outputField = info.outputFields ? info.outputFields[to] ?? {} as OutputField : {} as OutputField;

		// OutputDescriptor used for display only and fallback value for legacy DataSource format
		const outputDescriptor = info.outputDescriptors?.find((d) => d.output === to);

		const from = outputs[to];

		if (!from) {
			return;
		}

		const mapping: SourceConfigMapping = {
			to,
			from,
			isExpression: to === DataSourceDisplayTo && isTemplateExpression(from),
			type: outputField.type,
			label: outputField.label,
			isReportable: outputField.isReportable,
			isVisible: outputDescriptor != null,
			hideEmpty: !!outputDescriptor?.hideEmpty,
			itemTemplate: outputDescriptor?.itemTemplate,
		};

		return cleanObject(mapping);
	}).filter(isNotNull);

	// Amend default _display mapping if not present in the DS configuration
	if (!mappings.find((m) => m.to === DataSourceDisplayTo)) {
		mappings.unshift(displayDefaultMapping());
	}

	// Amend default _id mapping if not present in the DS configuration
	if (!mappings.find((m) => m.to === DataSourceIdTo)) {
		mappings.unshift(idDefaultMapping());
	}

	return sourceConfigMappingsToSourceConfigMappingInfo(mappings, info.outputDescriptors);
};

const toSourceConfigInputs = (info: { inputs?: Dictionary<string>; inputArgs?: IntegrationArgument[] }): SourceConfigInput[] => {

	const inputs = info.inputs ?? {};

	return Object.keys(inputs).map((key) => {

		const inputArg = info.inputArgs?.find((i) => i.identifier === key);

		return {
			parameter: inputArg?.identifier ?? key,
			type: inputArg?.type ?? DataSourceInputType.Text,
			value: inputs[key],
			isRequired: inputArg?.isRequired === true,
		} as SourceConfigInput;
	});
};

interface DataSourceOutputsInfo {
	outputs: DataSourceOutputMap;
	outputFields: Dictionary<OutputField>;
	outputDescriptors: OutputDescriptor[];
}

const toDataSourceOutputs = (mappings: SourceConfigMapping[]): DataSourceOutputsInfo => {

	const outputs: DataSourceOutputMap = { _id: '', _display: '' };
	const outputFields: Dictionary<OutputField> = {};
	const outputDescriptors: OutputDescriptor[] = [];

	for (const mapping of mappings) {
		outputs[mapping.to] = mapping.from;
		outputFields[mapping.to] ={
			type: mapping.type,
			label: mapping.label,
			isReportable: !!mapping.isReportable,
		};
		if (mapping.isVisible) {
			outputDescriptors.push({
				output: mapping.to,
				hideEmpty: mapping.hideEmpty,
				itemTemplate: mapping.itemTemplate,
			});
		}
	}

	return { outputs, outputFields, outputDescriptors };
};

interface DataSourceInputsInfo {
	inputs: Dictionary<string>;
	inputArgs: IntegrationArgument[];
}

const toDataSourceInputs = (inputs: SourceConfigInput[]): DataSourceInputsInfo => {

	const dsInputs: Dictionary<string> = {};
	const inputArgs: IntegrationArgument[] = [];

	for (const input of inputs) {
		dsInputs[input.parameter] = input.parameter;
		inputArgs.push({
			identifier: input.parameter,
			type: input.type,
			isRequired: input.isRequired ?? false,
		});
	}

	return { inputs: dsInputs, inputArgs };
};

const cleanObject = <T extends Record<string, any>>(value: T): T => {

	for (const key of Object.keys(value)) {
		if (ValidatorFunctions.isEmpty(value[key])) {
			// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
			delete value[key];
		}
	}

	return value;
};
