import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { TabComponent } from './tab.component';

export interface TabSelectedEvent {
	index: number;
	tab?: TabComponent;
}

@Component({
	selector: 'uf-tabs',
	templateUrl: './tabs.html',
	styleUrls: ['./tabs.less'],
})
export class TabsComponent implements OnDestroy {

	@Output() protected selectedChange = new EventEmitter<{ index: number; tab: TabComponent }>();

	@HostBinding('class.responsive') private isResponsive: boolean;

	// This property is public because it's accessed by TabComponent and it's added or removed by them
	// So every tab has the responsibility of adding/removing itself
	tabs: TabComponent[] = [];

	protected isExpanded: boolean;

	private subscriptions = new Subscription();
	private _active: TabComponent | null;

	constructor(
		private ref: ChangeDetectorRef,
		private router: Router,
		private route: ActivatedRoute,
	) {
		this.subscriptions.add(
			this.router.events.pipe(
				filter((e) => e instanceof NavigationEnd))
				.subscribe(() => {
					this.onRouteChanged();
				}),
		);
	}

	@Input() set responsive(v: boolean) {
		this.isResponsive = v;
	}

	get responsive() {
		return this.isResponsive;
	}

	get isRoute(): boolean {
		return !!this.tabs.find((tab) => tab.path !== undefined);
	}

	get selected(): TabComponent | null {
		return this.active;
	}

	protected get active(): TabComponent | null {
		return this._active;
	}

	private set active(v: TabComponent | null) {

		// Guard select active tab
		if (this.active === v) {
			return;
		}

		this._active = v;

		for (const tab of this.tabs) {
			tab.active = tab === this.active;
		}

		if (!this.active) {
			this.selectedChange.emit();

			return;
		}

		this.selectedChange.emit({ index: this.tabs.indexOf(this.active), tab: this.active });
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	add(tab: TabComponent) {
		this.tabs.push(tab);
		this.activeDefaultTab();
	}

	remove(tab: TabComponent | null | undefined) {

		if (tab == null) {
			return;
		}

		const index = this.tabs.indexOf(tab);

		// Remove active tab
		// There will be available tab(s) after remove
		if (this.active === tab && this.tabs.length > 1 && !this.isRoute) {
			// Select another content tab
			if (index === 0) {
				this.select(this.tabs[1]);
			} else {
				this.select(this.tabs[0]);
			}
		}

		this.tabs.splice(index, 1);
	}

	select(tab: TabComponent | null | undefined) {

		this.isExpanded = false;

		if (this.isRoute && tab?.routerLink != null) {
			void this.router.navigate([tab.routerLink], { relativeTo: this.route });

			return;
		}

		if (this.active === tab) {
			return;
		}

		this.active = tab ?? null;
		this.ref.detectChanges();
	}

	private activeDefaultTab() {

		// There're no tabs
		if (!this.tabs.length) {
			return;
		}

		// A tab is already selected
		if (this.active) {
			return;
		}

		// Content
		if (!this.isRoute) {
			this.select(this.tabs[0]);

			return;
		}

		// Route
		this.activateRouteTab();
	}

	private onRouteChanged() {

		// Guard only route Tabs
		if (!this.isRoute) {
			return;
		}

		if (this.activateRouteTab()) {
			return;
		}

		this.active = null;
	}

	private activateRouteTab(): boolean {

		if (!this.isRoute) {
			return false;
		}

		// It's a children route (exclude the '' children route)
		if (this.route.children.length && this.route.children[0]?.snapshot.url.length) {
			const path = this.route.children[0].snapshot.url[0]?.path;
			const tab = this.tabs.find((t) => t.path === path);

			this.active = tab ?? null;

			return true;
		} else {
			const emptyPathTab = this.tabs.find((t) => t.path === '');

			if (emptyPathTab) {
				this.active = emptyPathTab;

				return true;
			}
		}

		return false;
	}

}
