import { InjectionToken } from '@angular/core';
import { ConnectionOptionsInterface } from '@unifii/sdk';

export interface Config {
	title: string;
	version: string;
	env: 'localhost' | 'prod';
	translationsUrl: string;
	unifii: ConnectionOptionsInterface;
}

export const Config = new InjectionToken<Config>('Config');

export const Environment = new InjectionToken<Config>('Environment');

