import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataSeed, Dictionary, FieldType, FieldValidator, isNotNull } from '@unifii/sdk';

import { ContextProvider, RuntimeDefinition, RuntimeField, Scope, UfControl, UfControlArray, UfControlGroup, UfFormControl, controlIterator, fieldIterator, getDateTimeFormattedNow } from '@unifii/library/common';

import { ValidatorGroupType } from '../constants';
import { ContextFactory, ScopeFactory } from '../models';
import { FieldHelperFunctions } from '../utils';

import { ControlManager } from './control-manager';
import { FormService } from './form.service';
import { ValidatorBuilder } from './validator-builder';

/* Used by FormComponent and RepeatComponent and ActionGroupModal
   It build a tree parent/child of connected ScopeManagers */
@Injectable()
export class ScopeManager implements OnDestroy {

	// This node associated data and control
	scope: Scope = {};
	seed?: DataSeed;
	control: UfControlGroup;

	private parent: ScopeManager | null;
	private child: ScopeManager | null;
	private initTimeStamp: string;
	private identifier: string; // used for attaching to parent group
	private controlManager: ControlManager;

	constructor(
		private validatorBuilder: ValidatorBuilder,
		@Inject(ContextProvider) private contextProvider: ContextProvider,
		private formService: FormService,
		private translate: TranslateService,
	) {
		this.controlManager = new ControlManager(this, this.validatorBuilder, this.formService, this.translate);
	}

	ngOnDestroy() {
		if (this.parent) {
			this.parent.detach(this.control);
		}
	}

	// TODO Fix logic when fieldGroup == null
	init(fieldGroup: RuntimeDefinition | RuntimeField | null, parent?: ScopeManager) {

		if (!fieldGroup) {
			return;
		}

		this.parent = parent ?? null;
		this.initTimeStamp = getDateTimeFormattedNow();

		this.control = this.createControlGroup(fieldGroup);

		if (this.formService.disabled) {
			this.control.disable();
		}

		if (this.parent) {

			let parentControl: UfControlArray | UfControlGroup = this.parent.control;

			if ((fieldGroup as RuntimeField).type === FieldType.Repeat) {
				// Control Arrays are already attached to the parent control tree
				parentControl = this.parent.getControl(fieldGroup as RuntimeField) as UfControlArray;
			}
			this.parent.attach(this, parentControl, this.control);
		}

		this.addDependencies(fieldGroup);
	}

	getControl(field: RuntimeField): UfFormControl | null {

		const result = this.controlManager.getControl(field);

		if (result != null) {
			return result;
		}

		if (this.parent == null) {
			return null;
		}

		return this.parent.getControl(field);
	}

	updateControl(field: RuntimeField, fieldValidators: FieldValidator[]) {
		this.controlManager.updateControl(field, fieldValidators);
	}

	createScope: ScopeFactory = () => this.scope;

	createContext: ContextFactory = (value = null) => {

		// Look up for the root ScopeManager
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		let rootScopeManager: ScopeManager = this;

		while (rootScopeManager.parent) {
			rootScopeManager = rootScopeManager.parent;
		}

		// Create a Context
		const context = {
			self: value, // function input value (dynamic)
			root: rootScopeManager.scope,
			item: this.scope,
			seed: this.seed,
			now: this.initTimeStamp,
		};

		// Return merged AppContext into Context
		return Object.assign(
			context, // Context
			this.contextProvider.get(), // AppContext
		);
	};

	validateExcludingRequired(group: UfControlGroup): boolean {

		// Switch validators to exclude required
		for (const control of controlIterator(group)) {

			const validators = this.getValidatorFn(control, ValidatorGroupType.RequiredExcluded);

			control.setValidators(validators);

			if (validators != null) {
				control.setSubmitted(true);
			} else {
				control.markAsUntouched();
			}

			control.updateValueAndValidity();
		}

		// Get current valid state of fi
		const valid = group.valid;

		for (const control of controlIterator(group)) {
			const validators = this.getValidatorFn(control);

			control.setValidators(validators);
		}

		return valid;
	}

	getValidatorFn(control: UfFormControl, type?: ValidatorGroupType): ValidatorFn | ValidatorFn[] | null {

		const result = this.controlManager.getValidatorFn(control, type);

		if (result != null) {
			return result;
		}

		if (this.child == null) {
			return null;
		}

		const getValidators = this.getValidatorFn.bind(this.child);

		return getValidators(control, type);
	}

	private attach(child: ScopeManager, control: UfControlGroup | UfControlArray, childControl: UfControlGroup) {

		this.child = child;

		if (control.disabled) {
			childControl.disable();
		}

		if (control instanceof UfControlArray) {
			control.push(childControl);
		} else {
			this.identifier = this.createIdentifier();
			control.addControl(this.identifier, childControl);
		}

	}

	private detach(child: UfControlGroup) {

		const parent = child.parent;

		if (parent instanceof UfControlArray) {
			const i = parent.controls.findIndex((c) => c === child);

			if (i < 0) {
				console.warn('ScopeManager: child control not found');

				return;
			}

			parent.removeAt(i);
		} else if (parent instanceof UfControlGroup) {
			parent.removeControl(this.identifier);
		}
	}

	private createControlGroup(fieldGroup: RuntimeDefinition | RuntimeField): UfControlGroup {

		const controls: Dictionary<UfFormControl> = this.createGroupChildControls(fieldGroup.fields);

		if (fieldGroup.compoundType) {
			/** Root control */
			return new UfControlGroup(controls);
		}

		return this.controlManager.addControlGroup(fieldGroup, controls);
	}

	private createGroupChildControls(fields: RuntimeField[]): Dictionary<UfFormControl> {

		const controls: Dictionary<UfFormControl> = {};

		if (!fields.length) {
			return controls;
		}

		fields.forEach((f, i) => {
			// Add key for fields with no identifiers
			const identifier = f.identifier ?? `control${i + 1}`;

			controls[identifier] = this.createControl(f);
		});
		/**
		 * When all fields in a group are readonly control.disabled propagates upwards
		 * and disables all parent controllers. An extra control is added to prevent propagation
		 */
		controls.preventDisableBubble = new UfControl();

		return controls;
	}

	private createControl(field: RuntimeField): UfFormControl {
		/**
		 * Set Control group
		 * call this function recursively
		 */
		if (field.type === FieldType.ActionGroup) {
			// TODO this should be totally ignored!
			return new UfControlGroup({});
		}

		if (field.type === FieldType.Repeat) {
			return this.createControlArray(field);
		}

		if (FieldHelperFunctions.isGroup(field.type)) {
			return this.createControlGroup(field);
		}

		const control = this.controlManager.addControl(field);

		/**
		 * Control disabled if set to readonly or is not visible to the current
		 * TODO disable does nothing when workflow service enables section controls
		 * find a better way of doing should skip entire part of the control tree if not visible
		 */
		if (field.isReadOnly || !this.formService.isGranted(field)) {
			control.disable({ onlySelf: true, emitEvent: false });
		}

		return control;
	}

	private addDependencies(fieldGroup: RuntimeDefinition | RuntimeField) {

		for (const { field } of fieldIterator(fieldGroup)) {

			const control = this.controlManager.getControl(field);
			const dependencyControls = field.dependencies.map((f) => this.getControl(f)).filter(isNotNull);

			/** ArrayGroups dependents may not exist yet */
			if (dependencyControls.length && control) {
				control.addDependencies(dependencyControls);
			}
		}
	}

	private createControlArray(field: RuntimeField): UfControlArray {

		const controlArray = this.controlManager.addControlArray(field);

		/** Control disabled if set to readonly or is not visible to the current role */
		if (field.isReadOnly || !this.formService.isGranted(field)) {
			controlArray.disable();
		}

		return controlArray;
	}

	private createIdentifier(): string {

		const charset = 'abcdefghijklmnopqrstuvwxyz';
		let length = 6;
		let result = '';

		while (length > 0) {
			result += charset.charAt(Math.floor(Math.random() * charset.length));
			--length;
		}

		return result;
	}

}
