import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Option, isStringTrimmedNotEmpty } from '@unifii/sdk';

import { FilterEntry, FilterViewMode, IFilterComponent } from '../../../models';
import { CommonTranslationKey } from '../../../translations';

@Component({
	selector: 'uf-filter-multi-choice',
	templateUrl: './filter-multi-choice.html',
	styleUrls: ['./field-filter.less'],
})
export class FilterMultiChoiceComponent implements IFilterComponent<Option[]> {

	@Input() mode = FilterViewMode.Input;
	@Input({ required: true }) entry: FilterEntry;
	@Input({ required: true }) value: Option[] | null;
	@Output() valueChange = new EventEmitter<Option[] | null>();

	protected readonly commonTK = CommonTranslationKey;
	protected readonly modes = FilterViewMode;
	protected results: Option[] = [];

	get label(): string {
		return this.entry.label;
	}

	get isEmpty(): boolean {
		return !this.value?.length;
	}

	protected displayOptionValue(v: Option): string {
		return this.entry.options?.find((o) => o.identifier === v.identifier)?.name ?? v.identifier;
	}

	protected remove(index: number) {
		if (!this.value) {
			return;
		}

		this.value.splice(index, 1);
		this.valueChanged();
	}

	protected valueChanged() {
		this.valueChange.emit(this.isEmpty ? null : [...this.value ?? []]);
	}

	protected search(q?: string) {
		if (!this.entry.options) {
			console.warn('FilterMultiChoice options must be set');

			return;
		}

		const query = q?.toLowerCase().trim();

		this.results = isStringTrimmedNotEmpty(query) ?
			this.entry.options.filter((option) => option.name.toLowerCase().includes(query)) :
			[...this.entry.options];
	}

}
