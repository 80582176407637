import { Component, ElementRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Dictionary } from '@unifii/sdk';

import { ColumnInfo } from '../../components';

export interface FontInfo extends Dictionary<any> {
	id: string;
	template: string | SafeHtml;
	size?: string;
	lineHeight?: string;
	topMargin?: string;
	bottomMargin?: string;
	typeface?: string;
	fontWeight?: string;
}

@Component({
	selector: 'sc-show-typography',
	templateUrl: './show-typography.html',
	styleUrls: ['show-typography.less'],
})
export class ShowTypographyComponent {

	@ViewChild('example') exampleElement?: ElementRef<HTMLElement>;

	readonly defaultTableInfo: FontInfo[] = [
		{ id: 'h1', template: '<h1 id="h1">Heading 1</h1>', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ id: 'h2', template: '<h2 id="h2">Heading 2</h2>', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ id: 'h3', template: '<h3 id="h3">Heading 3</h3>', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ id: 'h4', template: '<h4 id="h4">Heading 4</h4>', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ id: 'h5', template: '<h5 id="h5">Heading 5</h5>', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ id: 'h6', template: '<h6 id="h6">Heading 6</h6>', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ id: 'p-xm', template: '<p id="p-xm" class="x-small">x-small paragraph</p>', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ id: 'p-sm', template: '<p id="p-sm" class="small">small paragraph</p>', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ id: 'p', template: '<p id="p">paragraph</p>', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ id: 'p-light', template: '<p id="p-light" class="light">paragraph light</p>', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ id: 'p-lg', template: '<p id="p-lg" class="large">large paragraph</p>', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ id: 'lb-xm', template: '<label id="lb-xm" class="x-small">x-small label</label>', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ id: 'lb-sm', template: '<label id="lb-sm" class="small">small label</label>', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ id: 'lb', template: '<label id="lb">label</label>', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ id: 'lb-lg', template: '<label id="lb-lg" class="large">large label</label>', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ id: 'ol', template: '<ol><li id="ol">Order List</li><li>Order List</li></ol>', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ id: 'ul', template: '<ul><li id="ul">Unorder List</li><li>Unorder List</li></ul>', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
		{ id: 'a', template: '<a id="a" href="www.google.com">Link</a>', size: '...', lineHeight: '...', topMargin: '...', bottomMargin: '...', typeface: '...', fontWeight: '...' },
	];

	readonly tableConfig: ColumnInfo[] = [
		{ identifier: 'template', name: 'Example' },
		{ identifier: 'size', name: 'Size' },
		{ identifier: 'lineHeight', name: 'Line Height' },
		{ identifier: 'topMargin', name: 'Top Margin' },
		{ identifier: 'bottomMargin', name: 'Bottom Margin' },
		{ identifier: 'typeface', name: 'Typeface' },
		{ identifier: 'fontWeight', name: 'Font Weight' },
	];

	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];

	readonly cssTableInfo = [
		{ name: 'default', type: 'modifier', description: 'Paragraph and label size to default for components (default)' },
		{ name: 'light', type: 'modifier', description: 'Paragraph and label text color to light for components' },
		{ name: 'large', type: 'modifier', description: 'Paragraph and label size to large for components' },
		{ name: 'small', type: 'modifier', description: 'Paragraph and label size to small for components' },
		{ name: 'x-small', type: 'modifier', description: 'Paragraph and label size to extra small for components' },
	];

	rows: FontInfo[];

	constructor(sanitizer: DomSanitizer) {
		this.rows = this.defaultTableInfo.map((info) => {
			info.template = sanitizer.bypassSecurityTrustHtml(info.template as string);

			return info;
		});

		this.updateSize();
	}

	updateSize() {

		if (!this.exampleElement) {
			return;
		}

		const valorizedExampleNativeElement = this.exampleElement.nativeElement;

		// hack to wait for new cycle of dom update
		setTimeout(() => {

			if (!this.exampleElement) {
				return;
			}

			this.rows = this.rows.map((info) => {
				info = Object.assign({}, info);
				const element = valorizedExampleNativeElement.querySelector(`#${info.id}`);

				if (!element) {
					return null;
				}

				const style = getComputedStyle(element);

				info.size = style.fontSize;
				info.lineHeight = style.lineHeight;
				info.topMargin = style.marginTop;
				info.bottomMargin = style.marginBottom;
				info.typeface = style.fontFamily;
				info.fontWeight = style.fontWeight;

				return info;
			}).filter((info) => !!info) as FontInfo[];
		}, 0);

	}

}
