export * from './filter-autocomplete.component';
export * from './filter-chips.component';
export * from './filter-choice.component';
export * from './filter-cost.component';
export * from './filter-hierarchies.component';
export * from './filter-multi-choice.component';
export * from './filter-number-range.component';
export * from './filter-number.component';
export * from './filter-tempo-range.component';
export * from './filter-tempo.component';
export * from './filter-text.component';
export * from './filter-text-array.component';

import { FilterAutoCompleteComponent } from './filter-autocomplete.component';
import { FilterChipsComponent } from './filter-chips.component';
import { FilterChoiceComponent } from './filter-choice.component';
import { FilterCostComponent } from './filter-cost.component';
import { FilterHierarchiesComponent } from './filter-hierarchies.component';
import { FilterMultiChoiceComponent } from './filter-multi-choice.component';
import { FilterNumberRangeComponent } from './filter-number-range.component';
import { FilterNumberComponent } from './filter-number.component';
import { FilterTempoRangeComponent } from './filter-tempo-range.component';
import { FilterTempoComponent } from './filter-tempo.component';
import { FilterTextArrayComponent } from './filter-text-array.component';
import { FilterTextComponent } from './filter-text.component';

export const UF_FILTER_FIELD_COMPONENTS = [
	FilterAutoCompleteComponent, FilterChipsComponent, FilterChoiceComponent, FilterCostComponent, FilterHierarchiesComponent,
	FilterMultiChoiceComponent, FilterNumberRangeComponent, FilterNumberComponent, FilterTempoRangeComponent, FilterTempoComponent,
	FilterTextComponent, FilterTextArrayComponent,
];
