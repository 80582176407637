import { Inject } from '@angular/core';
import { Client, Compound, CompoundType, ConnectionOptions, ConnectionOptionsInterface, Definition, DefinitionPublishState, Language, Page, Table, TableDetail, TableSourceType } from '@unifii/sdk';

import { ShowcaseRepositoryService } from './repository.service';

interface ContentInfo {
	compoundType: CompoundType;
	id: string;
	name: string;
	lastModifiedAt?: string;
	lastPublishedAt?: string;
	publishState?: DefinitionPublishState;
}

export interface CompoundInfo extends ContentInfo {
	definitionIdentifier: string;
	definitionLabel: string;
}

export interface CollectionInfo extends ContentInfo {
	identifier: string;
}

export interface FormInfo extends ContentInfo {
	identifier: string;
	bucket: string;
}

export interface TableInfo extends ContentInfo {
	identifier: string;
	title: string;
	sourceType: TableSourceType;
	source?: string;
	hasDetail?: boolean;
	hideExport?: boolean;
}

export interface View extends Compound {
	_definition: Definition;
}

export class UsAPIContentClient extends Client {

	private baseUrl: string;
	private projectId: string;

	constructor(
		@Inject(ConnectionOptions) options: ConnectionOptionsInterface,
			repository: ShowcaseRepositoryService,
	) {
		super(options, repository);

		this.baseUrl = options.baseUrl;
		this.projectId = repository.projectId;
	}

	getForms(): Promise<FormInfo[]> {
		return this.get(this.projectUrl('forms'), { params: { sort: 'name', limit: 500 } }) as Promise<FormInfo[]>;
	}

	getForm(identifier: string): Promise<Definition> {
		return this.get(this.projectUrl('forms', identifier)) as Promise<Definition>;
	}

	getViews(): Promise<CompoundInfo[]> {
		return this.get(this.projectUrl('views'), { params: { sort: 'name' } }) as Promise<CompoundInfo[]>;
	}

	getView(id: number): Promise<View> {
		return this.get(this.projectUrl('views', '' + id)) as Promise<View>;
	}

	getPages(): Promise<CompoundInfo[]> {
		return this.get(this.projectUrl('pages'), { params: { sort: 'name' } }) as Promise<CompoundInfo[]>;
	}

	getPage(id: number): Promise<Page> {
		return this.get(this.projectUrl('pages', '' + id)) as Promise<Page>;
	}

	getCollections(): Promise<CollectionInfo[]> {
		return this.get(this.projectUrl('collections')) as Promise<CollectionInfo[]>;
	}

	getCollectionDefinition(identifier: string): Promise<Definition> {
		return this.get(this.projectUrl('collections', identifier, 'definition')) as Promise<Definition>;
	}

	getCollectionItems(identifier: string): Promise<CompoundInfo[]> {
		return this.get(this.projectUrl('collections', identifier), { params: { sort: 'name', full: false } }) as Promise<CompoundInfo[]>;
	}

	getCollectionItem(identifier: string, id: number): Promise<Compound> {
		return this.get(this.projectUrl('collections', identifier, '' + id)) as Promise<Compound>;
	}

	getTables(): Promise<TableInfo[]> {
		return this.get(this.projectUrl('tables'), { params: { sort: 'title', limit: 500 } }) as Promise<TableInfo[]>;
	}

	getTable(identifier: string): Promise<Table> {
		return this.get(this.projectUrl('tables', identifier)) as Promise<Table>;
	}

	getTableDetail(identifier: string): Promise<TableDetail> {
		return this.get(this.projectUrl('tables', identifier, 'detail')) as Promise<TableDetail>;
	}

	getLanguages(): Promise<Language[]> {
		return this.get(this.buildUrl(['languages'])) as Promise<Language[]>;
	}

	getTranslationsView(langCode: string, viewId: number): Promise<Compound> {
		return this.get(this.projectUrl('views', viewId.toString(), 'translations', langCode)) as Promise<Compound>;
	}

	override buildUrl(...parts: any[]) {
		parts.unshift('api');
		parts.unshift(this.baseUrl);

		return parts.join('/');
	}

	private projectUrl(...extra: string[]): string {
		const extraSafe = extra.map((p) => encodeURIComponent(p));
		const parts = ['projects', this.projectId].concat(extraSafe);

		return this.buildUrl(...parts);
	}

}
