import { Injectable } from '@angular/core';
import { AstNode, CostModelFormat, DataSeed, FieldType, HierarchyUnit, NodeType, OperatorComparison, Option, QueryOperators, UserStatus, getUserStatusInfoFlags } from '@unifii/sdk';
// https://github.com/marnusw/date-fns-tz/issues/183
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';

import { DataSourceLoader, FilterAstNodeAdapter, FilterEntry, FilterType, FilterValue, TempoRange, ZonedTempoRange } from '../../models';
import { UfExpressionFunctionsSet, ValidatorFunctions } from '../../utils';

@Injectable()
export class UfFilterAstNodeAdapter implements FilterAstNodeAdapter<FilterValue, FilterEntry> {

	// eslint-disable-next-line complexity
	transform(value: FilterValue, { type, identifier, queryIdentifier, queryOperator, loader, inputType }: FilterEntry): AstNode[] {

		if (ValidatorFunctions.isEmpty(value)) {
			return [];
		}

		const nodeIdentifier = queryIdentifier ?? identifier;
		const nodeOperator = queryOperator;

		const node: AstNode = {
			type: NodeType.Operator,
			op: nodeOperator ?? QueryOperators.Equal,
			args: [{ type: NodeType.Identifier, value: nodeIdentifier }],
		};

		switch (type) {
			case FilterType.Text:
			case FilterType.Date:
			case FilterType.Time:
			case FilterType.Datetime:
				node.args?.push({ type: NodeType.Value, value });
				break;
			case FilterType.Number:
				node.args?.push({ type: NodeType.Value, value: +(value as number) });
				break;
			case FilterType.Cost:
				node.args?.push({ type: NodeType.Value, value: (value as CostModelFormat).amount });
				break;
			case FilterType.HierarchyUnit:
				node.op = nodeOperator ?? QueryOperators.Descendants;
				node.args = [
					{ type: NodeType.Identifier, value: nodeIdentifier },
					{ type: NodeType.Value, value: (value as HierarchyUnit).id },
				];
				break;
			case FilterType.TimeRange:
			case FilterType.DateRange:
			case FilterType.DatetimeRange:
				return this.buildTempoRangeAST(type, nodeIdentifier, value as TempoRange, inputType);
			case FilterType.ZonedDatetimeRange:
				return this.buildZonedTempoRangeAST(value as ZonedTempoRange, nodeIdentifier);
			case FilterType.TextArray:
			case FilterType.DataSeedArray:
			case FilterType.OptionArray:
			case FilterType.DataSeed:
			case FilterType.Choice:
			case FilterType.User:
			case FilterType.Company:
				return this.buildChoiceAST(value as string | DataSeed | DataSeed[] | Option[], nodeIdentifier, type, loader as DataSourceLoader, nodeOperator);
			case FilterType.Bool:
				node.args?.push({ type: NodeType.Value, value: value === 'true' });
				break;
			case FilterType.UserStatus:
				return this.getUserStatusAstNodes(value as UserStatus);
			default:
				console.error(`UfFilterAstNodeAdapter.transform: filterType '${type}' not supported in filter with identifier '${identifier}'`);

				return [];
		}

		return [node];
	}

	private buildTempoRangeAST(type: FilterType, identifier: string, value: TempoRange, inputType?: FieldType): AstNode[] {

		const nodes: AstNode[] = [];

		if (value.from != null) {
			nodes.push({
				type: NodeType.Operator,
				op: QueryOperators.GreaterEqual,
				args: [{ type: NodeType.Identifier, value: identifier }, { type: NodeType.Value, value: value.from }],
			});
		}

		if (value.to != null) {

			let to = value.to;

			if (type === FilterType.DatetimeRange && inputType === FieldType.Date) {
				// Input Date to defined a DateTime range, hence the tempoValue.to is changed to the next day and filtered via LE
				to = UfExpressionFunctionsSet.toDate(UfExpressionFunctionsSet.add(to, 1, 'days', null), null);
			}

			nodes.push({
				type: NodeType.Operator,
				op: QueryOperators.LowerEqual,
				args: [{ type: NodeType.Identifier, value: identifier }, { type: NodeType.Value, value: to }],
			});
		}

		return nodes;
	}

	private buildZonedTempoRangeAST(value: ZonedTempoRange, identifier: string): AstNode[] {

		if (value.from?.value == null && value.from?.tz == null && value.to?.value == null && value.to?.tz == null) {
			return [];
		}

		const nodes: AstNode[] = [];

		if (value.from?.value != null) {
			const { from } = value;
			const fromValue = this.convertToUTC(from.value, from.tz);

			nodes.push({
				type: NodeType.Operator,
				op: QueryOperators.GreaterEqual,
				args: [{ type: NodeType.Identifier, value: identifier }, { type: NodeType.Value, value: fromValue }],
			});
		}

		if (value.to?.value != null) {
			const { to } = value;
			const toValue = this.convertToUTC(to.value, to.tz);

			nodes.push({
				type: NodeType.Operator,
				op: QueryOperators.LowerEqual,
				args: [{ type: NodeType.Identifier, value: identifier }, { type: NodeType.Value, value: toValue }],
			});
		}

		return nodes;
	}

	private buildChoiceAST(v: string | string[] | DataSeed | DataSeed[] | Option[], identifier: string, type: FilterType, loader?: DataSourceLoader, operator?: OperatorComparison): AstNode[] {

		const filterValue = Array.isArray(v) ? [...v] : [v];
		const parsedValue: (string | number)[] = filterValue.map((fv) => this.parseOption(fv));
		const node = {
			type: NodeType.Operator,
			op: operator ?? QueryOperators.In,
			args: [
				{ type: NodeType.Identifier, value: identifier },
				{ type: NodeType.Value, value: parsedValue },
			],
		};

		// TODO is it accurate to assume all OptionArray filters should use contains (based on multi choice logic)
		if (type === FilterType.OptionArray && loader) {
			node.op = operator ?? QueryOperators.Contains;
		}
		// TODO check that contains is the correct operator for TextArray
		if (type === FilterType.TextArray) {
			node.op = operator ?? QueryOperators.Contains;
		}

		return [node];
	}

	private parseOption(v: string | DataSeed | Option): number | string {
		if ((v as DataSeed)._id) {
			return (v as DataSeed)._id;
		}

		if ((v as Option).identifier) {
			return (v as Option).identifier;
		}

		return v as string;
	}

	private convertToUTC(dateString: string, tz: string): string {
		/**
		 * Convert date string to zoned time eg: 2022-05-27T09:27:00.000+10:00
		 * convert date back to UTC format for correct comparison eg: 2022-05-27T07:45:00.000+00:00
		 */
		const zonedDate = zonedTimeToUtc(dateString, tz);

		return new Date(zonedDate).toISOString().replace('Z', '+00:00');
	}

	private getUserStatusAstNodes(status?: UserStatus): AstNode[] {

		if (!status) {
			return [];
		}

		const flags = getUserStatusInfoFlags(status);

		const nodes: AstNode[] = [{
			type: NodeType.Operator,
			op: QueryOperators.Equal,
			args: [
				{ type: NodeType.Identifier, value: 'isActive' },
				{ type: NodeType.Value, value: flags.isActive },
			],
		}];

		if (!flags.isActive) {
			// lastActivationReason is meaningful only for isActive == false, to discern Pending from Inactive
			nodes.push({
				type: NodeType.Operator,
				op: flags.lastActivationReason == null ? QueryOperators.Hasnt : QueryOperators.Has,
				args: [
					{ type: NodeType.Identifier, value: 'lastActivationReason' },
				],
			});
		}

		return nodes;
	}

}
