import { WorldCountry } from './world-data-model';

export const worldCountries: WorldCountry[] = [
	{
		id: 1,
		name: 'Afghanistan',
		currency: 'AFN',
		phone_code: '93', 
		region_id: '3',
		parent_id: 14,
		emoji: '🇦🇫',
		emojiU: 'U+1F1E6 U+1F1EB',
	},
	{
		id: 2,
		name: 'Aland Islands',
		currency: 'EUR',
		phone_code: '358',
		region_id: '4',
		parent_id: 18,
		emoji: '🇦🇽',
		emojiU: 'U+1F1E6 U+1F1FD',
	},
	{
		id: 3,
		name: 'Albania',
		currency: 'ALL',
		phone_code: '355',
		region_id: '4',
		parent_id: 16,
		emoji: '🇦🇱',
		emojiU: 'U+1F1E6 U+1F1F1',
	},
	{
		id: 4,
		name: 'Algeria',
		currency: 'DZD',
		phone_code: '213',
		region_id: '1',
		parent_id: 1,
		emoji: '🇩🇿',
		emojiU: 'U+1F1E9 U+1F1FF',
	},
	{
		id: 5,
		name: 'American Samoa',
		currency: 'USD',
		phone_code: '1',
		region_id: '5',
		parent_id: 22,
		emoji: '🇦🇸',
		emojiU: 'U+1F1E6 U+1F1F8',
	},
	{
		id: 6,
		name: 'Andorra',
		currency: 'EUR',
		phone_code: '376',
		region_id: '4',
		parent_id: 16,
		emoji: '🇦🇩',
		emojiU: 'U+1F1E6 U+1F1E9',
	},
	{
		id: 7,
		name: 'Angola',
		currency: 'AOA',
		phone_code: '244',
		region_id: '1',
		parent_id: 2,
		emoji: '🇦🇴',
		emojiU: 'U+1F1E6 U+1F1F4',
	},
	{
		id: 8,
		name: 'Anguilla',
		currency: 'XCD',
		phone_code: '1',
		region_id: '2',
		parent_id: 7,
		emoji: '🇦🇮',
		emojiU: 'U+1F1E6 U+1F1EE',
	},	
	{
		id: 10,
		name: 'Antigua and Barbuda',
		currency: 'XCD',
		phone_code: '1',
		region_id: '2',
		parent_id: 7,
		emoji: '🇦🇬',
		emojiU: 'U+1F1E6 U+1F1EC',
	},
	{
		id: 11,
		name: 'Argentina',
		currency: 'ARS',
		phone_code: '54',
		region_id: '2',
		parent_id: 8,
		emoji: '🇦🇷',
		emojiU: 'U+1F1E6 U+1F1F7',
	},
	{
		id: 12,
		name: 'Armenia',
		currency: 'AMD',
		phone_code: '374',
		region_id: '3',
		parent_id: 11,
		emoji: '🇦🇲',
		emojiU: 'U+1F1E6 U+1F1F2',
	},
	{
		id: 13,
		name: 'Aruba',
		currency: 'AWG',
		phone_code: '297',
		region_id: '2',
		parent_id: 7,
		emoji: '🇦🇼',
		emojiU: 'U+1F1E6 U+1F1FC',
	},
	{
		id: 14,
		name: 'Australia',
		currency: 'AUD',
		phone_code: '61',
		region_id: '5',
		parent_id: 19,
		emoji: '🇦🇺',
		emojiU: 'U+1F1E6 U+1F1FA',
	},
	{
		id: 15,
		name: 'Austria',
		currency: 'EUR',
		phone_code: '43',
		region_id: '4',
		parent_id: 17,
		emoji: '🇦🇹',
		emojiU: 'U+1F1E6 U+1F1F9',
	},
	{
		id: 16,
		name: 'Azerbaijan',
		currency: 'AZN',
		phone_code: '994',
		region_id: '3',
		parent_id: 11,
		emoji: '🇦🇿',
		emojiU: 'U+1F1E6 U+1F1FF',
	},
	{
		id: 18,
		name: 'Bahrain',
		currency: 'BHD',
		phone_code: '973',
		region_id: '3',
		parent_id: 11,
		emoji: '🇧🇭',
		emojiU: 'U+1F1E7 U+1F1ED',
	},
	{
		id: 19,
		name: 'Bangladesh',
		currency: 'BDT',
		phone_code: '880',
		region_id: '3',
		parent_id: 14,
		emoji: '🇧🇩',
		emojiU: 'U+1F1E7 U+1F1E9',
	},
	{
		id: 20,
		name: 'Barbados',
		currency: 'BBD',
		phone_code: '1',
		region_id: '2',
		parent_id: 7,
		emoji: '🇧🇧',
		emojiU: 'U+1F1E7 U+1F1E7',
	},
	{
		id: 21,
		name: 'Belarus',
		currency: 'BYN',
		phone_code: '375',
		region_id: '4',
		parent_id: 15,
		emoji: '🇧🇾',
		emojiU: 'U+1F1E7 U+1F1FE',
	},
	{
		id: 22,
		name: 'Belgium',
		currency: 'EUR',
		phone_code: '32',
		region_id: '4',
		parent_id: 17,
		emoji: '🇧🇪',
		emojiU: 'U+1F1E7 U+1F1EA',
	},
	{
		id: 23,
		name: 'Belize',
		currency: 'BZD',
		phone_code: '501',
		region_id: '2',
		parent_id: 9,
		emoji: '🇧🇿',
		emojiU: 'U+1F1E7 U+1F1FF',
	},
	{
		id: 24,
		name: 'Benin',
		currency: 'XOF',
		phone_code: '229',
		region_id: '1',
		parent_id: 3,
		emoji: '🇧🇯',
		emojiU: 'U+1F1E7 U+1F1EF',
	},
	{
		id: 25,
		name: 'Bermuda',
		currency: 'BMD',
		phone_code: '1',
		region_id: '2',
		parent_id: 6,
		emoji: '🇧🇲',
		emojiU: 'U+1F1E7 U+1F1F2',
	},
	{
		id: 26,
		name: 'Bhutan',
		currency: 'BTN',
		phone_code: '975',
		region_id: '3',
		parent_id: 14,
		emoji: '🇧🇹',
		emojiU: 'U+1F1E7 U+1F1F9',
	},
	{
		id: 27,
		name: 'Bolivia',
		currency: 'BOB',
		phone_code: '591',
		region_id: '2',
		parent_id: 8,
		emoji: '🇧🇴',
		emojiU: 'U+1F1E7 U+1F1F4',
	},
	{
		id: 28,
		name: 'Bosnia and Herzegovina',
		currency: 'BAM',
		phone_code: '387',
		region_id: '4',
		parent_id: 16,
		emoji: '🇧🇦',
		emojiU: 'U+1F1E7 U+1F1E6',
	},
	{
		id: 29,
		name: 'Botswana',
		currency: 'BWP',
		phone_code: '267',
		region_id: '1',
		parent_id: 5,
		emoji: '🇧🇼',
		emojiU: 'U+1F1E7 U+1F1FC',
	},
	{
		id: 31,
		name: 'Brazil',
		currency: 'BRL',
		phone_code: '55',
		region_id: '2',
		parent_id: 8,
		emoji: '🇧🇷',
		emojiU: 'U+1F1E7 U+1F1F7',
	},
	{
		id: 32,
		name: 'British Indian Ocean Territory',
		currency: 'USD',
		phone_code: '246',
		region_id: '1',
		parent_id: 4,
		emoji: '🇮🇴',
		emojiU: 'U+1F1EE U+1F1F4',
	},
	{
		id: 33,
		name: 'Brunei',
		currency: 'BND',
		phone_code: '673',
		region_id: '3',
		parent_id: 13,
		emoji: '🇧🇳',
		emojiU: 'U+1F1E7 U+1F1F3',
	},
	{
		id: 34,
		name: 'Bulgaria',
		currency: 'BGN',
		phone_code: '359',
		region_id: '4',
		parent_id: 15,
		emoji: '🇧🇬',
		emojiU: 'U+1F1E7 U+1F1EC',
	},
	{
		id: 35,
		name: 'Burkina Faso',
		currency: 'XOF',
		phone_code: '226',
		region_id: '1',
		parent_id: 3,
		emoji: '🇧🇫',
		emojiU: 'U+1F1E7 U+1F1EB',
	},
	{
		id: 36,
		name: 'Burundi',
		currency: 'BIF',
		phone_code: '257',
		region_id: '1',
		parent_id: 4,
		emoji: '🇧🇮',
		emojiU: 'U+1F1E7 U+1F1EE',
	},
	{
		id: 37,
		name: 'Cambodia',
		currency: 'KHR',
		phone_code: '855',
		region_id: '3',
		parent_id: 13,
		emoji: '🇰🇭',
		emojiU: 'U+1F1F0 U+1F1ED',
	},
	{
		id: 38,
		name: 'Cameroon',
		currency: 'XAF',
		phone_code: '237',
		region_id: '1',
		parent_id: 2,
		emoji: '🇨🇲',
		emojiU: 'U+1F1E8 U+1F1F2',
	},
	{
		id: 39,
		name: 'Canada',
		currency: 'CAD',
		phone_code: '1',
		region_id: '2',
		parent_id: 6,
		emoji: '🇨🇦',
		emojiU: 'U+1F1E8 U+1F1E6',
	},
	{
		id: 40,
		name: 'Cape Verde',
		currency: 'CVE',
		phone_code: '238',
		region_id: '1',
		parent_id: 3,
		emoji: '🇨🇻',
		emojiU: 'U+1F1E8 U+1F1FB',
	},
	{
		id: 41,
		name: 'Cayman Islands',
		currency: 'KYD',
		phone_code: '1',
		region_id: '2',
		parent_id: 7,
		emoji: '🇰🇾',
		emojiU: 'U+1F1F0 U+1F1FE',
	},
	{
		id: 42,
		name: 'Central African Republic',
		currency: 'XAF',
		phone_code: '236',
		region_id: '1',
		parent_id: 2,
		emoji: '🇨🇫',
		emojiU: 'U+1F1E8 U+1F1EB',
	},
	{
		id: 43,
		name: 'Chad',
		currency: 'XAF',
		phone_code: '235',
		region_id: '1',
		parent_id: 2,
		emoji: '🇹🇩',
		emojiU: 'U+1F1F9 U+1F1E9',
	},
	{
		id: 44,
		name: 'Chile',
		currency: 'CLP',
		phone_code: '56',
		region_id: '2',
		parent_id: 8,
		emoji: '🇨🇱',
		emojiU: 'U+1F1E8 U+1F1F1',
	},
	{
		id: 45,
		name: 'China',
		currency: 'CNY',
		phone_code: '86',
		region_id: '3',
		parent_id: 12,
		emoji: '🇨🇳',
		emojiU: 'U+1F1E8 U+1F1F3',
	},
	{
		id: 46,
		name: 'Christmas Island',
		currency: 'AUD',
		phone_code: '61',
		region_id: '5',
		parent_id: 19,
		emoji: '🇨🇽',
		emojiU: 'U+1F1E8 U+1F1FD',
	},
	{
		id: 47,
		name: 'Cocos (Keeling) Islands',
		currency: 'AUD',
		phone_code: '61',
		region_id: '5',
		parent_id: 19,
		emoji: '🇨🇨',
		emojiU: 'U+1F1E8 U+1F1E8',
	},
	{
		id: 48,
		name: 'Colombia',
		currency: 'COP',
		phone_code: '57',
		region_id: '2',
		parent_id: 8,
		emoji: '🇨🇴',
		emojiU: 'U+1F1E8 U+1F1F4',
	},
	{
		id: 49,
		name: 'Comoros',
		currency: 'KMF',
		phone_code: '269',
		region_id: '1',
		parent_id: 4,
		emoji: '🇰🇲',
		emojiU: 'U+1F1F0 U+1F1F2',
	},
	{
		id: 50,
		name: 'Congo',
		currency: 'XAF',
		phone_code: '242',
		region_id: '1',
		parent_id: 2,
		emoji: '🇨🇬',
		emojiU: 'U+1F1E8 U+1F1EC',
	},
];
