import { Component, EventEmitter, Input, OnInit, Optional } from '@angular/core';
import { DataSeed, FieldTemplate, FieldType, Option } from '@unifii/sdk';

import { DataSourceDisplayTo, DataSourceIdTo, RuntimeField, ToastService, UfControl } from '@unifii/library/common';
import { FormField, FormFieldService } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-choice-input',
	templateUrl: './choice.html',
})
export class ChoiceComponent implements FormField, OnInit {

	readonly fieldTemplate = FieldTemplate;
	readonly fieldType = FieldType;

	field: RuntimeField;
	control: UfControl;
	content: any;
	contentChange: EventEmitter<any>;
	suffix: string;
	cssClass: string | string[];

	valueProperty: string | null;
	nameProperty: string;
	trackBy: string;

	private _options: DataSeed[] | Option[] | undefined | null;

	constructor(
		private toast: ToastService,
		@Optional() private formFieldService?: FormFieldService,
	) { }

	@Input() set options(v: DataSeed[] | Option[]) {
		this._options = v;
	}

	get options(): DataSeed[] | Option[] {
		return this._options ?? this.field.options ?? [];
	}

	ngOnInit() {
		/**
		 * Value type can either be an Option or DataSeed
		 * valueProperty, nameProperty and trackBy have to be updated to accomadate the change in data type
		 */
		const isDataSeed = this.field.sourceConfig != null;

		this.valueProperty = isDataSeed ? null : 'identifier';
		this.nameProperty = isDataSeed ? DataSourceDisplayTo : 'name';
		this.trackBy = isDataSeed ? DataSourceIdTo : 'identifier';
	}

	retry = () => {
		this.formFieldService?.updateDataSourceOptions();
	};

	changed(v: any) {
		this.content = v;
		this.contentChange.emit(v);
	}

	onError(error: Error) {
		this.toast.error(error.message);
	}

}
