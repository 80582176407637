import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-button-groups.html',
})
export class ShowButtonGroupsComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly tableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly tableInfo = [
		{ name: 'uf-button-group', type: 'base', description: 'Base style to apply default button group style.' },
		{ name: 'large', type: 'modifier', description: 'Sets size of button group to large' },
		{ name: 'default', type: 'modifier', description: 'Sets size of button group to default' },
		{ name: 'small', type: 'modifier', description: 'Sets size of button group to small' },
		{ name: 'x-small', type: 'modifier', description: 'Sets size of button group to extra small' },
	];

	readonly sizeOptions = [
		{ name: 'Large', value: 'large' },
		{ name: 'Medium (Default)', value: '' },
		{ name: 'Small', value: 'small' },
		{ name: 'Extra Small', value: 'x-small' },
	];
	readonly colourOptions = [
		{ name: '(Default)', value: '' },
		{ name: 'Tertiary', value: 'tertiary' },
	];

	size = '';
	colour = '';
	class = 'uf-button-group';

}
