import { Component, Input, OnChanges, inject } from '@angular/core';
import { DataSeed, DataType } from '@unifii/sdk';

import { DataDisplayListItem, SourceConfig } from '../../../models';
import { DataDisplayService } from '../../../services';
import { isDataDisplayDataSeedValue } from '../components';

@Component({
	selector: 'uf-data-seed-mappings-display',
	templateUrl: './data-seed-mappings-display.html',
})
export class DataSeedMappingsDisplayComponent implements OnChanges {

	@Input({ required: true }) dataSeed: DataSeed | null | undefined;
	@Input({ required: true }) sourceConfig: SourceConfig;
	@Input() showDisplayVisibleField: boolean | null;
	
	protected mappingDisplayItems: DataDisplayListItem[] = [];

	private dataDisplayService = inject(DataDisplayService);

	ngOnChanges() {
		const displayValue = this.dataDisplayService.displayAsDataDisplayValue(
			this.dataSeed, {
				type: DataType.DataSeed,
				sourceConfig: this.sourceConfig,
				showDisplayVisibleField: this.showDisplayVisibleField ?? undefined,
			});
		
		if (!isDataDisplayDataSeedValue(displayValue)) {
			this.mappingDisplayItems = [];

			return;
		}

		this.mappingDisplayItems = displayValue.visibleItems.map((visibleItem) => {
			return Object.assign({}, visibleItem.item, { termTemplate: 'tertiary' });
		});
	}

}
