import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

import { ShowcaseRepositoryService } from './repository.service';

export const initTranslations = (translate: TranslateService, repo: ShowcaseRepositoryService) => () => {
	translate.addLangs(['en', 'it']);

	return firstValueFrom(translate.use(repo.language ?? 'en'));
};
