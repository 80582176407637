<sc-page-content width="lg" identifier="number-fields">

	<ng-container editorInputs>
		<uf-radio [options]="componentOptions" [(value)]="component" (valueChange)="modifierChange()" class="col-6"
			label="Component" columns="1" valueProperty="identifier" />
		<uf-radio [options]="sizeOptions" [(value)]="size" (valueChange)="modifierChange()" class="col-6" label="Sizes"
			columns="1" valueProperty="identifier" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template">
			<uf-number *ngIf="component === 'uf-number'" [ngClass]="size" [control]="control"
				(valueChange)="log($event)" label="Number" />
			<uf-cost *ngIf="component === 'uf-cost'" [ngClass]="size" (valueChange)="log($event)" label="Cost" />
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">Inputs</h3>
		<h4 class="pad-sides">uf-number, uf-cost</h4>
		<sc-info-table [columnConfig]="inputTableConfig" [rows]="inputTableInfo" />
		<h4 class="pad-sides">uf-number</h4>
		<sc-info-table [columnConfig]="inputTableConfig" [rows]="numberInputTableInfo" />
		<h4 class="pad-sides">uf-cost</h4>
		<sc-info-table [columnConfig]="inputTableConfig" [rows]="costInputTableInfo" />
		<h3 class="pad-sides">Outputs</h3>
		<sc-info-table [columnConfig]="outputTableConfig" [rows]="outputTableInfo" />
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>