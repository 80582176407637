import { Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'sc-block',
	templateUrl: './show-block.html',
	styleUrls: ['./show-block.less'],
})
export class BlockComponent {

	@HostBinding('class.collapsed') collapsed = false;

	@Input() title: string;
	@Input() collapsable: boolean | string;

	get isCollapsable() {
		return (this.collapsable === true || (this.collapsable && this.collapsable.toLowerCase() === 'true')) ? true : null;
	}

}
