<label *ngIf="label">
	{{label}}
	<ng-content></ng-content>
</label>
<div class="input-wrap">
	<div *ngFor="let unit of value; let i = index" class="uf-chip x-small center-all">
		{{ unit.path | dataDisplay: { type: 'HierarchyPath'} }}
		<button *ngIf="!control.disabled" [title]="sharedTermsTK.ActionRemove | translate" (click)="remove(unit.id)"
			type="button" data-type="chip" class="uf-action">
			<uf-icon name="delete" />
		</button>
	</div>
	<input [readonly]="true" [attr.placeholder]="placeholder" (focus)="onFocus()" type="text">
	<div class="input-icon">
		<uf-icon name="hierarchy" />
	</div>
</div>
<uf-error [control]="control" />