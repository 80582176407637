import { DestroyRef, Injectable, inject } from '@angular/core';
import { FieldType } from '@unifii/sdk';
import { Subject, Subscription, debounceTime } from 'rxjs';

import { IntersectionObserverWrapper, NavItem, RuntimeDefinition, RuntimeField } from '@unifii/library/common';

import { isFieldEligibleForNavigation } from '../utils';

import { NavigationService } from './navigation.service';

@Injectable()
export class FormNavigationService implements NavigationService<RuntimeField> {

	navItems?: NavItem[];
	onNavigation = new Subject<RuntimeField>();

	private offsetTop = 0; // distance from top of the container to the first registered element
	private zoneHeight = 32; // equals margin between elements
	private scrollableContainer?: HTMLElement;
	private observer?: IntersectionObserverWrapper;
	private navItemLookupByKey = new WeakMap<RuntimeField, NavItem>();
	private navItemLookupByElement = new WeakMap<Element, NavItem>();
	private scrollTargetLookup = new WeakMap<NavItem, Element>();
	private keyLookup = new WeakMap<NavItem, RuntimeField>();
	private keysRegisteredWithoutScrollTarget = new Set<RuntimeField>();
	private activeItem: NavItem | null = null;
	private first?: NavItem;
	private _last: NavItem | null = null;
	private destroyRef = inject(DestroyRef);
	private earlyRegistrations: { key: RuntimeField; scrollTarget?: HTMLElement }[] = [];
	private intersectionChangesListener?: Subscription;

	init(def: RuntimeDefinition, scrollableContainer: HTMLElement, containerOffsetTop = 0) {
		this.scrollableContainer = scrollableContainer;
		this.offsetTop = containerOffsetTop;

		this.initNavigation(def);
		this.initIntersectionObserver(scrollableContainer);

		for (const { key, scrollTarget } of this.earlyRegistrations) {
			this.register(key, scrollTarget);
		}

		this.earlyRegistrations = [];
	}

	register(key: RuntimeField, scrollTarget?: HTMLElement) {
		if (!this.navItems) {
			this.earlyRegistrations.push({ key, scrollTarget });

			return;
		}

		const navItem = this.navItemLookupByKey.get(key);

		if (navItem?.isRegistered) {
			return;
		}

		if (!navItem) {
			console.warn(`FormNavigationManager: node with id '${key.label}' does not exist`);

			return;
		}

		if (scrollTarget) {
			this.navItemLookupByElement.set(scrollTarget, navItem);
			this.scrollTargetLookup.set(navItem, scrollTarget);
			this.observer?.observe(scrollTarget);
		} else {
			this.keysRegisteredWithoutScrollTarget.add(key);
		}

		navItem.isRegistered = true;

		if (navItem.parent?.isRegistered === false && key.parent) {
			this.register(key.parent);
		}

		// reset memoized last element in list
		this._last = null;
	}

	deregister(key: RuntimeField) {
		const navItem = this.navItemLookupByKey.get(key);

		if (!navItem) {
			console.warn(`FormNavigationManager: node with id '${key.label}' does not exist`);

			return;
		}

		navItem.isRegistered = false;

		const scrollTarget = this.scrollTargetLookup.get(navItem);

		if (scrollTarget) {
			this.observer?.unobserve(scrollTarget);
		} else {
			this.keysRegisteredWithoutScrollTarget.delete(key);
		}

		if (navItem.parent?.isRegistered && key.parent) {
			const hasRegisteredSiblings = (this.navItems ?? []).filter((ni) => ni.parent === navItem.parent).some(((nv) => nv.isRegistered));

			if (!hasRegisteredSiblings) {
				this.deregister(key.parent);
			}
		}

		// reset memoized last element in list
		this._last = null;
	}

	navigateTo(navItem: NavItem) {
		const key = this.keyLookup.get(navItem);

		if (!key) {
			console.error('FormNavigationService: navItem does not exist');

			return;
		}

		this.onNavigation.next(key);

		this.scrollTo(this.scrollTargetLookup.get(navItem));
		this.setActiveNavItem(navItem, true);
	}

	setActive(key: RuntimeField, isActive: boolean) {
		const navItem = this.navItemLookupByKey.get(key);

		if (!navItem) {
			console.warn(`FormNavigationService: key not registered Label: ${key.label}`);

			return;
		}

		if (isActive) {
			this.setDisabled(key, false);
		}

		this.scrollTo(this.scrollTargetLookup.get(navItem));
		this.setActiveNavItem(navItem, isActive);
	}

	setError(key: RuntimeField, hasError: boolean) {
		const navItem = this.navItemLookupByKey.get(key);

		if (!navItem) {
			console.warn(`FormNavigationService: key not registered Label: ${key.label}`);

			return;
		}

		navItem.hasError = hasError;
	}

	setDisabled(key: RuntimeField, isDisabled: boolean) {
		const navItem = this.navItemLookupByKey.get(key);

		if (!navItem) {
			console.warn(`FormNavigationService: key not registered Label: ${key.label}`);

			return;
		}

		navItem.isDisabled = isDisabled;

		// update parents disabled state so it is aligned with all of its children
		if (
			navItem.parent && 
			navItem.parent.isDisabled !== isDisabled && 
			(this.navItems ?? []).some(((ni) => ni.parent === navItem.parent && ni.isDisabled !== isDisabled))
		) {
			navItem.parent.isDisabled = isDisabled;			
		}
	}

	setSuccess(key: RuntimeField, hasSuccess: boolean) {
		const navItem = this.navItemLookupByKey.get(key);

		if (!navItem) {
			console.warn(`FormNavigationService: key not registered Label: ${key.label}`);

			return;
		}

		navItem.hasSuccess = hasSuccess;
	}

	getLastItem() {
		return this.last ? this.keyLookup.get(this.last) ?? null : null;
	}

	private scrollTo(scrollTarget?: Element) {
		if (!scrollTarget) {
			return;
		}

		this.intersectionChangesListener?.unsubscribe();

		scrollTarget.scrollIntoView({ behavior: 'smooth' });
		/**
		 * The timeout is required because of the smooth the scroll behavior doesn't give any
		 * guarantee of timing or ability to set the speed so a generic timeout is required to ensure
		 * the listener is added after scrolling has finished
		*/
		setTimeout(() => {
			this.intersectionChangesListener = this.createIntersectionChangesListener();
		}, 1200);
	}

	private initIntersectionObserver(scrollableContainer: HTMLElement) {
		if (this.observer) {
			this.observer.disconnect();
		}

		this.observer = new IntersectionObserverWrapper(scrollableContainer, { zoneHeight: this.zoneHeight, destroyRef: this.destroyRef });

		this.intersectionChangesListener = this.createIntersectionChangesListener();
	}

	private createIntersectionChangesListener(): Subscription | undefined {
		return this.observer?.intersectionChanges
			.pipe(debounceTime(200))
			.subscribe(({ isIntersecting, target }) => {

				if (!this.detectActiveElements) {
					return;
				}

				let navItem = this.navItemLookupByElement.get(target);

				if (!navItem) {
					return;
				}

				// Check if the intersecting item should be overridden by the first or last element
				const override = this.getFirstOrLastActiveElement();

				if (override) {
					navItem = override;
					isIntersecting = true;
				}

				this.setActiveNavItem(navItem, isIntersecting);
			});
	}

	private setActiveNavItem(navItem: NavItem, isActive: boolean) {
		navItem.isActive = isActive;

		if (navItem !== this.activeItem) {
			if (this.activeItem) {
				this.activeItem.isActive = false;
			}
			this.activeItem = navItem;
		}
	}

	private initNavigation(def: RuntimeDefinition) {
		this.navItemLookupByKey = new WeakMap<RuntimeField, NavItem>();

		const navItems: NavItem[] = [];
		const navFields = Array.from(this.iterateNavFields(def.fields));

		if (navFields.length === 0) {
			console.warn('FormNavigationService: no fields are eligible for navigation in your collection');

			return;
		}

		for (const { field, parentCount, parent } of navFields) {
			const navItem: NavItem = {
				label: field.shortLabel ?? field.label ?? '',
				parentCount,
				isActive: false,
				isRegistered: false,
				isDisabled: false,
				isAccented: false,
				hasError: false,
				hasSuccess: false,
				isHeading: field.type === FieldType.Stepper,
			};

			if (parent) {
				navItem.parent = this.navItemLookupByKey.get(parent);
			}

			navItems.push(navItem);

			this.navItemLookupByKey.set(field, navItem);
			this.keyLookup.set(navItem, field);
		}

		this.navItems = navItems;

		this.first = navItems[0];
	}

	private getFirstOrLastActiveElement(): NavItem | null {
		if (!this.scrollableContainer) {
			return null;
		}

		const scrollTop = Math.ceil(this.scrollableContainer.scrollTop);
		const scrollHeight = this.scrollableContainer.scrollHeight;
		const offsetHeight = this.scrollableContainer.offsetHeight;

		// Near top
		if (scrollTop < this.offsetTop) {
			return this.first ?? null;
		}

		// Near bottom
		if (scrollTop >= (scrollHeight - offsetHeight)) {
			return this.last;
		}

		return null;
	}

	private get last(): NavItem | null {
		if (!this.navItems) {
			return null;
		}

		if (this._last) {
			return this._last;
		}

		for (let i = (this.navItems.length - 1); i > 0; i--) {
			const navItem = this.navItems[i];

			if (navItem && navItem.isRegistered) {
				this._last = navItem;

				return this._last;
			}
		}

		return null;
	}

	private get detectActiveElements() {
		return this.keysRegisteredWithoutScrollTarget.size === 0;
	}

	private * iterateNavFields(fields: RuntimeField[], parentCount = 0, parent?: RuntimeField): Iterable<{ field: RuntimeField; parentCount: number; parent?: RuntimeField }> {
		for (const field of fields.filter(isFieldEligibleForNavigation)) {
			yield { field, parentCount, parent };

			yield *this.iterateNavFields(field.fields, parentCount + 1, field);
		}
	}

}
