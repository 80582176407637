import { WorldState } from './world-data-model';

// cspell:disable
export const worldStates: WorldState[] = [
	{ id: 3901, name: 'Badakhshan', parent_id: 1 },
	{ id: 3871, name: 'Badghis', parent_id: 1 },
	{ id: 3875, name: 'Baghlan', parent_id: 1 },
	{ id: 3884, name: 'Balkh', parent_id: 1 },
	{ id: 3872, name: 'Bamyan', parent_id: 1 },
	{ id: 3892, name: 'Daykundi', parent_id: 1 },
	{ id: 3899, name: 'Farah', parent_id: 1 },
	{ id: 3889, name: 'Faryab', parent_id: 1 },
	{ id: 3870, name: 'Ghazni', parent_id: 1 },
	{ id: 3888, name: 'Ghōr', parent_id: 1 },
	{ id: 3873, name: 'Helmand', parent_id: 1 },
	{ id: 3887, name: 'Herat', parent_id: 1 },
	{ id: 3886, name: 'Jowzjan', parent_id: 1 },
	{ id: 3902, name: 'Kabul', parent_id: 1 },
	{ id: 3890, name: 'Kandahar', parent_id: 1 },
	{ id: 3879, name: 'Kapisa', parent_id: 1 },
	{ id: 3878, name: 'Khost', parent_id: 1 },
	{ id: 3876, name: 'Kunar', parent_id: 1 },
	{ id: 3900, name: 'Kunduz Province', parent_id: 1 },
	{ id: 3891, name: 'Laghman', parent_id: 1 },
	{ id: 3897, name: 'Logar', parent_id: 1 },
	{ id: 3882, name: 'Nangarhar', parent_id: 1 },
	{ id: 3896, name: 'Nimruz', parent_id: 1 },
	{ id: 3880, name: 'Nuristan', parent_id: 1 },
	{ id: 3894, name: 'Paktia', parent_id: 1 },
	{ id: 3877, name: 'Paktika', parent_id: 1 },
	{ id: 3881, name: 'Panjshir', parent_id: 1 },
	{ id: 3895, name: 'Parwan', parent_id: 1 },
	{ id: 3883, name: 'Samangan', parent_id: 1 },
	{ id: 3885, name: 'Sar-e Pol', parent_id: 1 },
	{ id: 3893, name: 'Takhar', parent_id: 1 },
	{ id: 3898, name: 'Urozgan', parent_id: 1 },
	{ id: 3874, name: 'Zabul', parent_id: 1 },
	{ id: 5298, name: 'Brändö', parent_id: 2 },
	{ id: 5294, name: 'Eckerö', parent_id: 2 },
	{ id: 5289, name: 'Finström', parent_id: 2 },
	{ id: 5295, name: 'Föglö', parent_id: 2 },
	{ id: 5296, name: 'Geta', parent_id: 2 },
	{ id: 5292, name: 'Hammarland', parent_id: 2 },
	{ id: 5288, name: 'Jomala', parent_id: 2 },
	{ id: 5301, name: 'Kökar', parent_id: 2 },
	{ id: 5300, name: 'Kumlinge', parent_id: 2 },
	{ id: 5290, name: 'Lemland', parent_id: 2 },
	{ id: 5299, name: 'Lumparland', parent_id: 2 },
	{ id: 5287, name: 'Mariehamn', parent_id: 2 },
	{ id: 5291, name: 'Saltvik', parent_id: 2 },
	{ id: 5302, name: 'Sottunga', parent_id: 2 },
	{ id: 5293, name: 'Sund', parent_id: 2 },
	{ id: 5297, name: 'Vårdö', parent_id: 2 },
	{ id: 603, name: 'Berat County', parent_id: 3 },
	{ id: 629, name: 'Berat District', parent_id: 3 },
	{ id: 607, name: 'Bulqizë District', parent_id: 3 },
	{ id: 618, name: 'Delvinë District', parent_id: 3 },
	{ id: 608, name: 'Devoll District', parent_id: 3 },
	{ id: 610, name: 'Dibër County', parent_id: 3 },
	{ id: 605, name: 'Dibër District', parent_id: 3 },
	{ id: 632, name: 'Durrës County', parent_id: 3 },
	{ id: 639, name: 'Durrës District', parent_id: 3 },
	{ id: 598, name: 'Elbasan County', parent_id: 3 },
	{ id: 631, name: 'Fier County', parent_id: 3 },
	{ id: 627, name: 'Fier District', parent_id: 3 },
	{ id: 604, name: 'Gjirokastër County', parent_id: 3 },
	{ id: 621, name: 'Gjirokastër District', parent_id: 3 },
	{ id: 617, name: 'Gramsh District', parent_id: 3 },
	{ id: 600, name: 'Has District', parent_id: 3 },
	{ id: 594, name: 'Kavajë District', parent_id: 3 },
	{ id: 628, name: 'Kolonjë District', parent_id: 3 },
	{ id: 630, name: 'Korçë County', parent_id: 3 },
	{ id: 597, name: 'Korçë District', parent_id: 3 },
	{ id: 614, name: 'Krujë District', parent_id: 3 },
	{ id: 612, name: 'Kuçovë District', parent_id: 3 },
	{ id: 601, name: 'Kukës County', parent_id: 3 },
	{ id: 623, name: 'Kukës District', parent_id: 3 },
	{ id: 622, name: 'Kurbin District', parent_id: 3 },
	{ id: 609, name: 'Lezhë County', parent_id: 3 },
	{ id: 595, name: 'Lezhë District', parent_id: 3 },
	{ id: 596, name: 'Librazhd District', parent_id: 3 },
	{ id: 599, name: 'Lushnjë District', parent_id: 3 },
	{ id: 602, name: 'Malësi e Madhe District', parent_id: 3 },
	{ id: 637, name: 'Mallakastër District', parent_id: 3 },
	{ id: 635, name: 'Mat District', parent_id: 3 },
	{ id: 638, name: 'Mirditë District', parent_id: 3 },
	{ id: 619, name: 'Peqin District', parent_id: 3 },
	{ id: 625, name: 'Përmet District', parent_id: 3 },
	{ id: 606, name: 'Pogradec District', parent_id: 3 },
	{ id: 620, name: 'Pukë District', parent_id: 3 },
	{ id: 624, name: 'Sarandë District', parent_id: 3 },
	{ id: 611, name: 'Shkodër County', parent_id: 3 },
	{ id: 626, name: 'Shkodër District', parent_id: 3 },
	{ id: 593, name: 'Skrapar District', parent_id: 3 },
	{ id: 616, name: 'Tepelenë District', parent_id: 3 },
	{ id: 615, name: 'Tirana County', parent_id: 3 },
	{ id: 633, name: 'Tirana District', parent_id: 3 },
	{ id: 636, name: 'Tropojë District', parent_id: 3 },
	{ id: 634, name: 'Vlorë County', parent_id: 3 },
	{ id: 613, name: 'Vlorë District', parent_id: 3 },
	{ id: 1118, name: 'Adrar', parent_id: 4 },
	{ id: 1119, name: 'Aïn Defla', parent_id: 4 },
	{ id: 1122, name: 'Aïn Témouchent', parent_id: 4 },
	{ id: 1144, name: 'Algiers', parent_id: 4 },
	{ id: 1103, name: 'Annaba', parent_id: 4 },
	{ id: 1142, name: 'Batna', parent_id: 4 },
	{ id: 1108, name: 'Béchar', parent_id: 4 },
	{ id: 1128, name: 'Béjaïa', parent_id: 4 },
	{ id: 4909, name: 'Béni Abbès', parent_id: 4 },
	{ id: 1114, name: 'Biskra', parent_id: 4 },
	{ id: 1111, name: 'Blida', parent_id: 4 },
	{ id: 4908, name: 'Bordj Baji Mokhtar', parent_id: 4 },
	{ id: 1116, name: 'Bordj Bou Arréridj', parent_id: 4 },
	{ id: 1104, name: 'Bouïra', parent_id: 4 },
	{ id: 1125, name: 'Boumerdès', parent_id: 4 },
	{ id: 1105, name: 'Chlef', parent_id: 4 },
	{ id: 1121, name: 'Constantine', parent_id: 4 },
	{ id: 4912, name: 'Djanet', parent_id: 4 },
	{ id: 1098, name: 'Djelfa', parent_id: 4 },
	{ id: 1129, name: 'El Bayadh', parent_id: 4 },
	{ id: 4905, name: `El M'ghair`, parent_id: 4 },
	{ id: 4906, name: 'El Menia', parent_id: 4 },
	{ id: 1099, name: 'El Oued', parent_id: 4 },
	{ id: 1100, name: 'El Tarf', parent_id: 4 },
	{ id: 1127, name: 'Ghardaïa', parent_id: 4 },
	{ id: 1137, name: 'Guelma', parent_id: 4 },
	{ id: 1112, name: 'Illizi', parent_id: 4 },
	{ id: 4914, name: 'In Guezzam', parent_id: 4 },
	{ id: 4913, name: 'In Salah', parent_id: 4 },
	{ id: 1113, name: 'Jijel', parent_id: 4 },
	{ id: 1126, name: 'Khenchela', parent_id: 4 },
	{ id: 1138, name: 'Laghouat', parent_id: 4 },
	{ id: 1134, name: `M'Sila`, parent_id: 4 },
	{ id: 1124, name: 'Mascara', parent_id: 4 },
	{ id: 1109, name: 'Médéa', parent_id: 4 },
	{ id: 1132, name: 'Mila', parent_id: 4 },
	{ id: 1140, name: 'Mostaganem', parent_id: 4 },
	{ id: 1102, name: 'Naama', parent_id: 4 },
	{ id: 1101, name: 'Oran', parent_id: 4 },
	{ id: 1139, name: 'Ouargla', parent_id: 4 },
	{ id: 4907, name: 'Ouled Djellal', parent_id: 4 },
	{ id: 1136, name: 'Oum El Bouaghi', parent_id: 4 },
	{ id: 1130, name: 'Relizane', parent_id: 4 },
	{ id: 1123, name: 'Saïda', parent_id: 4 },
	{ id: 1141, name: 'Sétif', parent_id: 4 },
	{ id: 4902, name: 'Sidi Bel Abbès', parent_id: 4 },
	{ id: 1110, name: 'Skikda', parent_id: 4 },
	{ id: 1143, name: 'Souk Ahras', parent_id: 4 },
	{ id: 1135, name: 'Tamanghasset', parent_id: 4 },
	{ id: 1117, name: 'Tébessa', parent_id: 4 },
	{ id: 1106, name: 'Tiaret', parent_id: 4 },
	{ id: 4910, name: 'Timimoun', parent_id: 4 },
	{ id: 1120, name: 'Tindouf', parent_id: 4 },
	{ id: 1115, name: 'Tipasa', parent_id: 4 },
	{ id: 1133, name: 'Tissemsilt', parent_id: 4 },
	{ id: 1131, name: 'Tizi Ouzou', parent_id: 4 },
	{ id: 1107, name: 'Tlemcen', parent_id: 4 },
	{ id: 4911, name: 'Touggourt', parent_id: 4 },
	{ id: 488, name: 'Andorra la Vella', parent_id: 6 },
	{ id: 489, name: 'Canillo', parent_id: 6 },
	{ id: 487, name: 'Encamp', parent_id: 6 },
	{ id: 492, name: 'Escaldes-Engordany', parent_id: 6 },
	{ id: 493, name: 'La Massana', parent_id: 6 },
	{ id: 491, name: 'Ordino', parent_id: 6 },
	{ id: 490, name: 'Sant Julià de Lòria', parent_id: 6 },
	{ id: 221, name: 'Bengo Province', parent_id: 7 },
	{ id: 218, name: 'Benguela Province', parent_id: 7 },
	{ id: 212, name: 'Bié Province', parent_id: 7 },
	{ id: 228, name: 'Cabinda Province', parent_id: 7 },
	{ id: 226, name: 'Cuando Cubango Province', parent_id: 7 },
	{ id: 217, name: 'Cuanza Norte Province', parent_id: 7 },
	{ id: 216, name: 'Cuanza Sul', parent_id: 7 },
	{ id: 215, name: 'Cunene Province', parent_id: 7 },
	{ id: 213, name: 'Huambo Province', parent_id: 7 },
	{ id: 225, name: 'Huíla Province', parent_id: 7 },
	{ id: 222, name: 'Luanda Province', parent_id: 7 },
	{ id: 223, name: 'Lunda Norte Province', parent_id: 7 },
	{ id: 220, name: 'Lunda Sul Province', parent_id: 7 },
	{ id: 227, name: 'Malanje Province', parent_id: 7 },
	{ id: 219, name: 'Moxico Province', parent_id: 7 },
	{ id: 224, name: 'Uíge Province', parent_id: 7 },
	{ id: 214, name: 'Zaire Province', parent_id: 7 },
	{ id: 5273, name: 'Blowing Point', parent_id: 8 },
	{ id: 5274, name: 'East End', parent_id: 8 },
	{ id: 5275, name: 'George Hill', parent_id: 8 },
	{ id: 5276, name: 'Island Harbour', parent_id: 8 },
	{ id: 5277, name: 'North Hill', parent_id: 8 },
	{ id: 5278, name: 'North Side', parent_id: 8 },
	{ id: 5279, name: 'Sandy Ground', parent_id: 8 },
	{ id: 5280, name: 'Sandy Hill', parent_id: 8 },
	{ id: 5281, name: 'South Hill', parent_id: 8 },
	{ id: 5282, name: 'Stoney Ground', parent_id: 8 },
	{ id: 5283, name: 'The Farrington', parent_id: 8 },
	{ id: 5284, name: 'The Quarter', parent_id: 8 },
	{ id: 5285, name: 'The Valley', parent_id: 8 },
	{ id: 5286, name: 'West End', parent_id: 8 },
	{ id: 3708, name: 'Barbuda', parent_id: 10 },
	{ id: 3703, name: 'Redonda', parent_id: 10 },
	{ id: 3709, name: 'Saint George Parish', parent_id: 10 },
	{ id: 3706, name: 'Saint John Parish', parent_id: 10 },
	{ id: 3707, name: 'Saint Mary Parish', parent_id: 10 },
	{ id: 3705, name: 'Saint Paul Parish', parent_id: 10 },
	{ id: 3704, name: 'Saint Peter Parish', parent_id: 10 },
	{ id: 3710, name: 'Saint Philip Parish', parent_id: 10 },
	{ id: 3656, name: 'Buenos Aires', parent_id: 11 },
	{ id: 3647, name: 'Catamarca', parent_id: 11 },
	{ id: 3640, name: 'Chaco', parent_id: 11 },
	{ id: 3651, name: 'Chubut', parent_id: 11 },
	{ id: 4880, name: 'Ciudad Autónoma de Buenos Aires', parent_id: 11 },
	{ id: 3642, name: 'Córdoba', parent_id: 11 },
	{ id: 3638, name: 'Corrientes', parent_id: 11 },
	{ id: 3654, name: 'Entre Ríos', parent_id: 11 },
	{ id: 3652, name: 'Formosa', parent_id: 11 },
	{ id: 3645, name: 'Jujuy', parent_id: 11 },
	{ id: 3655, name: 'La Pampa', parent_id: 11 },
	{ id: 3653, name: 'La Rioja', parent_id: 11 },
	{ id: 3646, name: 'Mendoza', parent_id: 11 },
	{ id: 3644, name: 'Misiones', parent_id: 11 },
	{ id: 3648, name: 'Neuquén', parent_id: 11 },
	{ id: 3639, name: 'Río Negro', parent_id: 11 },
	{ id: 3643, name: 'Salta', parent_id: 11 },
	{ id: 3634, name: 'San Juan', parent_id: 11 },
	{ id: 3636, name: 'San Luis', parent_id: 11 },
	// eslint-disable-next-line sonarjs/no-duplicate-string
	{ id: 3649, name: 'Santa Cruz', parent_id: 11 },
	{ id: 3641, name: 'Santa Fe', parent_id: 11 },
	{ id: 3635, name: 'Santiago del Estero', parent_id: 11 },
	{ id: 3650, name: 'Tierra del Fuego', parent_id: 11 },
	{ id: 3637, name: 'Tucumán', parent_id: 11 },
	{ id: 2023, name: 'Aragatsotn Region', parent_id: 12 },
	{ id: 2024, name: 'Ararat Province', parent_id: 12 },
	{ id: 2026, name: 'Armavir Region', parent_id: 12 },
	{ id: 2028, name: 'Gegharkunik Province', parent_id: 12 },
	{ id: 2033, name: 'Kotayk Region', parent_id: 12 },
	{ id: 2029, name: 'Lori Region', parent_id: 12 },
	{ id: 2031, name: 'Shirak Region', parent_id: 12 },
	{ id: 2027, name: 'Syunik Province', parent_id: 12 },
	{ id: 2032, name: 'Tavush Region', parent_id: 12 },
	{ id: 2025, name: 'Vayots Dzor Region', parent_id: 12 },
	{ id: 2030, name: 'Yerevan', parent_id: 12 },
	{ id: 5243, name: 'Noord', parent_id: 13 },
	{ id: 5245, name: 'Oranjestad East', parent_id: 13 },
	{ id: 5244, name: 'Oranjestad West', parent_id: 13 },
	{ id: 5246, name: 'Paradera', parent_id: 13 },
	{ id: 5247, name: 'San Nicolaas Noord', parent_id: 13 },
	{ id: 5248, name: 'San Nicolaas Zuid', parent_id: 13 },
	{ id: 5249, name: 'Santa Cruz', parent_id: 13 },
	{ id: 5250, name: 'Savaneta', parent_id: 13 },
	{ id: 3907, name: 'Australian Capital Territory', parent_id: 14 },
	{ id: 3909, name: 'New South Wales', parent_id: 14 },
	{ id: 3910, name: 'Northern Territory', parent_id: 14 },
	{ id: 3905, name: 'Queensland', parent_id: 14 },
	{ id: 3904, name: 'South Australia', parent_id: 14 },
	{ id: 3908, name: 'Tasmania', parent_id: 14 },
	{ id: 3903, name: 'Victoria', parent_id: 14 },
	{ id: 3906, name: 'Western Australia', parent_id: 14 },
	{ id: 2062, name: 'Burgenland', parent_id: 15 },
	{ id: 2057, name: 'Carinthia', parent_id: 15 },
	{ id: 2065, name: 'Lower Austria', parent_id: 15 },
	{ id: 2061, name: 'Salzburg', parent_id: 15 },
	{ id: 2059, name: 'Styria', parent_id: 15 },
	{ id: 2064, name: 'Tyrol', parent_id: 15 },
	{ id: 2058, name: 'Upper Austria', parent_id: 15 },
	{ id: 2060, name: 'Vienna', parent_id: 15 },
	{ id: 2063, name: 'Vorarlberg', parent_id: 15 },
	{ id: 540, name: 'Absheron District', parent_id: 16 },
	{ id: 559, name: 'Agdam District', parent_id: 16 },
	{ id: 553, name: 'Agdash District', parent_id: 16 },
	{ id: 577, name: 'Aghjabadi District', parent_id: 16 },
	{ id: 543, name: 'Agstafa District', parent_id: 16 },
	{ id: 547, name: 'Agsu District', parent_id: 16 },
	{ id: 528, name: 'Astara District', parent_id: 16 },
	{ id: 575, name: 'Babek District', parent_id: 16 },
	{ id: 552, name: 'Baku', parent_id: 16 },
	{ id: 560, name: 'Balakan District', parent_id: 16 },
	{ id: 569, name: 'Barda District', parent_id: 16 },
	{ id: 554, name: 'Beylagan District', parent_id: 16 },
	{ id: 532, name: 'Bilasuvar District', parent_id: 16 },
	{ id: 561, name: 'Dashkasan District', parent_id: 16 },
	{ id: 527, name: 'Fizuli District', parent_id: 16 },
	{ id: 585, name: 'Ganja', parent_id: 16 },
	{ id: 589, name: 'Gədəbəy', parent_id: 16 },
	{ id: 573, name: 'Gobustan District', parent_id: 16 },
	{ id: 551, name: 'Goranboy District', parent_id: 16 },
	{ id: 531, name: 'Goychay', parent_id: 16 },
	{ id: 574, name: 'Goygol District', parent_id: 16 },
	{ id: 571, name: 'Hajigabul District', parent_id: 16 },
	{ id: 544, name: 'Imishli District', parent_id: 16 },
	{ id: 564, name: 'Ismailli District', parent_id: 16 },
	{ id: 570, name: 'Jabrayil District', parent_id: 16 },
	{ id: 578, name: 'Jalilabad District', parent_id: 16 },
	{ id: 572, name: 'Julfa District', parent_id: 16 },
	{ id: 525, name: 'Kalbajar District', parent_id: 16 },
	{ id: 567, name: 'Kangarli District', parent_id: 16 },
	{ id: 590, name: 'Khachmaz District', parent_id: 16 },
	{ id: 537, name: 'Khizi District', parent_id: 16 },
	{ id: 524, name: 'Khojali District', parent_id: 16 },
	{ id: 549, name: 'Kurdamir District', parent_id: 16 },
	{ id: 541, name: 'Lachin District', parent_id: 16 },
	{ id: 587, name: 'Lankaran', parent_id: 16 },
	{ id: 558, name: 'Lankaran District', parent_id: 16 },
	{ id: 546, name: 'Lerik District', parent_id: 16 },
	{ id: 568, name: 'Martuni', parent_id: 16 },
	{ id: 555, name: 'Masally District', parent_id: 16 },
	{ id: 580, name: 'Mingachevir', parent_id: 16 },
	{ id: 562, name: 'Nakhchivan Autonomous Republic', parent_id: 16 },
	{ id: 530, name: 'Neftchala District', parent_id: 16 },
	{ id: 556, name: 'Oghuz District', parent_id: 16 },
	{ id: 534, name: 'Ordubad District', parent_id: 16 },
	{ id: 542, name: 'Qabala District', parent_id: 16 },
	{ id: 526, name: 'Qakh District', parent_id: 16 },
	{ id: 521, name: 'Qazakh District', parent_id: 16 },
	{ id: 563, name: 'Quba District', parent_id: 16 },
	{ id: 548, name: 'Qubadli District', parent_id: 16 },
	{ id: 588, name: 'Qusar District', parent_id: 16 },
	{ id: 557, name: 'Saatly District', parent_id: 16 },
	{ id: 565, name: 'Sabirabad District', parent_id: 16 },
	{ id: 522, name: 'Sadarak District', parent_id: 16 },
	{ id: 545, name: 'Salyan District', parent_id: 16 },
	{ id: 536, name: 'Samukh District', parent_id: 16 },
	{ id: 591, name: 'Shabran District', parent_id: 16 },
	{ id: 579, name: 'Shahbuz District', parent_id: 16 },
	{ id: 518, name: 'Shaki', parent_id: 16 },
	{ id: 586, name: 'Shaki District', parent_id: 16 },
	{ id: 529, name: 'Shamakhi District', parent_id: 16 },
	{ id: 583, name: 'Shamkir District', parent_id: 16 },
	{ id: 535, name: 'Sharur District', parent_id: 16 },
	{ id: 520, name: 'Shirvan', parent_id: 16 },
	{ id: 592, name: 'Shusha District', parent_id: 16 },
	{ id: 584, name: 'Siazan District', parent_id: 16 },
	{ id: 582, name: 'Sumqayit', parent_id: 16 },
	{ id: 519, name: 'Tartar District', parent_id: 16 },
	{ id: 533, name: 'Tovuz District', parent_id: 16 },
	{ id: 539, name: 'Ujar District', parent_id: 16 },
	{ id: 550, name: 'Yardymli District', parent_id: 16 },
	{ id: 538, name: 'Yevlakh', parent_id: 16 },
	{ id: 523, name: 'Yevlakh District', parent_id: 16 },
	{ id: 581, name: 'Zangilan District', parent_id: 16 },
	{ id: 566, name: 'Zaqatala District', parent_id: 16 },
	{ id: 576, name: 'Zardab District', parent_id: 16 },
	{ id: 1992, name: 'Capital', parent_id: 18 },
	{ id: 1996, name: 'Central', parent_id: 18 },
	{ id: 1995, name: 'Muharraq', parent_id: 18 },
	{ id: 1994, name: 'Northern', parent_id: 18 },
	{ id: 1993, name: 'Southern', parent_id: 18 },
	{ id: 807, name: 'Barisal ', parent_id: 19 },
	{ id: 803, name: 'Chittagong ', parent_id: 19 },
	{ id: 760, name: 'Dhaka ', parent_id: 19 },
	{ id: 775, name: 'Khulna ', parent_id: 19 },
	{ id: 758, name: 'Mymensingh ', parent_id: 19 },
	{ id: 753, name: 'Rajshahi ', parent_id: 19 },
	{ id: 750, name: 'Rangpur ', parent_id: 19 },
	{ id: 765, name: 'Sylhet ', parent_id: 19 },
	{ id: 1228, name: 'Christ Church', parent_id: 20 },
	{ id: 1229, name: 'Saint Andrew', parent_id: 20 },
	{ id: 1226, name: 'Saint George', parent_id: 20 },
	{ id: 1224, name: 'Saint James', parent_id: 20 },
	{ id: 1227, name: 'Saint John', parent_id: 20 },
	{ id: 1223, name: 'Saint Joseph', parent_id: 20 },
	{ id: 1221, name: 'Saint Lucy', parent_id: 20 },
	{ id: 1230, name: 'Saint Michael', parent_id: 20 },
	{ id: 1222, name: 'Saint Peter', parent_id: 20 },
	{ id: 1220, name: 'Saint Philip', parent_id: 20 },
	{ id: 1225, name: 'Saint Thomas', parent_id: 20 },
	{ id: 2959, name: 'Brest Region', parent_id: 21 },
	{ id: 2955, name: 'Gomel Region', parent_id: 21 },
	{ id: 2956, name: 'Grodno Region', parent_id: 21 },
	{ id: 2958, name: 'Minsk', parent_id: 21 },
	{ id: 2957, name: 'Minsk Region', parent_id: 21 },
	{ id: 2954, name: 'Mogilev Region', parent_id: 21 },
	{ id: 2960, name: 'Vitebsk Region', parent_id: 21 },
	{ id: 1381, name: 'Antwerp', parent_id: 22 },
	{ id: 1376, name: 'Brussels-Capital Region', parent_id: 22 },
	{ id: 1377, name: 'East Flanders', parent_id: 22 },
	{ id: 1373, name: 'Flanders', parent_id: 22 },
	{ id: 1374, name: 'Flemish Brabant', parent_id: 22 },
	{ id: 1375, name: 'Hainaut', parent_id: 22 },
	{ id: 1384, name: 'Liège', parent_id: 22 },
	{ id: 1372, name: 'Limburg', parent_id: 22 },
	{ id: 1379, name: 'Luxembourg', parent_id: 22 },
	{ id: 1378, name: 'Namur', parent_id: 22 },
	{ id: 1380, name: 'Wallonia', parent_id: 22 },
	{ id: 1382, name: 'Walloon Brabant', parent_id: 22 },
	{ id: 1383, name: 'West Flanders', parent_id: 22 },
	{ id: 264, name: 'Belize District', parent_id: 23 },
	{ id: 269, name: 'Cayo District', parent_id: 23 },
	{ id: 266, name: 'Corozal District', parent_id: 23 },
	{ id: 268, name: 'Orange Walk District', parent_id: 23 },
	{ id: 265, name: 'Stann Creek District', parent_id: 23 },
	{ id: 267, name: 'Toledo District', parent_id: 23 },
	{ id: 3077, name: 'Alibori Department', parent_id: 24 },
	{ id: 3076, name: 'Atakora Department', parent_id: 24 },
	{ id: 3079, name: 'Atlantique Department', parent_id: 24 },
	{ id: 3078, name: 'Borgou Department', parent_id: 24 },
	{ id: 3070, name: 'Collines Department', parent_id: 24 },
	{ id: 3072, name: 'Donga Department', parent_id: 24 },
	{ id: 3071, name: 'Kouffo Department', parent_id: 24 },
	{ id: 3081, name: 'Littoral Department', parent_id: 24 },
	{ id: 3075, name: 'Mono Department', parent_id: 24 },
	{ id: 3080, name: 'Ouémé Department', parent_id: 24 },
	{ id: 3074, name: 'Plateau Department', parent_id: 24 },
	{ id: 3073, name: 'Zou Department', parent_id: 24 },
	{ id: 4860, name: 'Devonshire', parent_id: 25 },
	{ id: 4861, name: 'Hamilton', parent_id: 25 },
	{ id: 4863, name: 'Paget', parent_id: 25 },
	{ id: 4864, name: 'Pembroke', parent_id: 25 },
	{ id: 4866, name: `Saint George's`, parent_id: 25 },
	{ id: 4867, name: 'Sandys', parent_id: 25 },
	{ id: 4868, name: `Smith's`, parent_id: 25 },
	{ id: 4869, name: 'Southampton', parent_id: 25 },
	{ id: 4870, name: 'Warwick', parent_id: 25 },
	{ id: 240, name: 'Bumthang ', parent_id: 26 },
	{ id: 239, name: 'Chukha ', parent_id: 26 },
	{ id: 238, name: 'Dagana ', parent_id: 26 },
	{ id: 229, name: 'Gasa ', parent_id: 26 },
	{ id: 232, name: 'Haa ', parent_id: 26 },
	{ id: 234, name: 'Lhuntse ', parent_id: 26 },
	{ id: 242, name: 'Mongar ', parent_id: 26 },
	{ id: 237, name: 'Paro ', parent_id: 26 },
	{ id: 244, name: 'Pemagatshel ', parent_id: 26 },
	{ id: 235, name: 'Punakha ', parent_id: 26 },
	{ id: 243, name: 'Samdrup Jongkhar ', parent_id: 26 },
	{ id: 246, name: 'Samtse ', parent_id: 26 },
	{ id: 247, name: 'Sarpang ', parent_id: 26 },
	{ id: 241, name: 'Thimphu ', parent_id: 26 },
	{ id: 5242, name: 'Trashi Yangtse\t', parent_id: 26 },
	{ id: 236, name: 'Trashigang ', parent_id: 26 },
	{ id: 245, name: 'Trongsa ', parent_id: 26 },
	{ id: 230, name: 'Tsirang ', parent_id: 26 },
	{ id: 231, name: 'Wangdue Phodrang ', parent_id: 26 },
	{ id: 233, name: 'Zhemgang ', parent_id: 26 },
	{ id: 3375, name: 'Beni Department', parent_id: 27 },
	{ id: 3382, name: 'Chuquisaca Department', parent_id: 27 },
	{ id: 3381, name: 'Cochabamba Department', parent_id: 27 },
	{ id: 3380, name: 'La Paz Department', parent_id: 27 },
	{ id: 3376, name: 'Oruro Department', parent_id: 27 },
	{ id: 3379, name: 'Pando Department', parent_id: 27 },
	{ id: 3383, name: 'Potosí Department', parent_id: 27 },
	{ id: 3377, name: 'Santa Cruz Department', parent_id: 27 },
	{ id: 3378, name: 'Tarija Department', parent_id: 27 },
	{ id: 472, name: 'Bosnian Podrinje Canton', parent_id: 28 },
	{ id: 460, name: 'Brčko District', parent_id: 28 },
	{ id: 471, name: 'Canton 10', parent_id: 28 },
	{ id: 462, name: 'Central Bosnia Canton', parent_id: 28 },
	{ id: 463, name: 'Herzegovina-Neretva Canton', parent_id: 28 },
	{ id: 464, name: 'Posavina Canton', parent_id: 28 },
	{ id: 470, name: 'Republika Srpska', parent_id: 28 },
	{ id: 466, name: 'Sarajevo Canton', parent_id: 28 },
	{ id: 461, name: 'Tuzla Canton', parent_id: 28 },
	{ id: 465, name: 'Una-Sana Canton', parent_id: 28 },
	{ id: 469, name: 'West Herzegovina Canton', parent_id: 28 },
	{ id: 468, name: 'Zenica-Doboj Canton', parent_id: 28 },
	{ id: 3067, name: 'Central District', parent_id: 29 },
	{ id: 3061, name: 'Ghanzi District', parent_id: 29 },
	{ id: 3066, name: 'Kgalagadi District', parent_id: 29 },
	{ id: 3062, name: 'Kgatleng District', parent_id: 29 },
	{ id: 3069, name: 'Kweneng District', parent_id: 29 },
	{ id: 3060, name: 'Ngamiland', parent_id: 29 },
	{ id: 3068, name: 'North-East District', parent_id: 29 },
	{ id: 3065, name: 'North-West District', parent_id: 29 },
	{ id: 3064, name: 'South-East District', parent_id: 29 },
	{ id: 3063, name: 'Southern District', parent_id: 29 },
	{ id: 2012, name: 'Acre', parent_id: 31 },
	{ id: 2007, name: 'Alagoas', parent_id: 31 },
	{ id: 1999, name: 'Amapá', parent_id: 31 },
	{ id: 2004, name: 'Amazonas', parent_id: 31 },
	{ id: 2002, name: 'Bahia', parent_id: 31 },
	{ id: 2016, name: 'Ceará', parent_id: 31 },
	{ id: 2017, name: 'Distrito Federal', parent_id: 31 },
	{ id: 2018, name: 'Espírito Santo', parent_id: 31 },
	{ id: 2000, name: 'Goiás', parent_id: 31 },
	{ id: 2015, name: 'Maranhão', parent_id: 31 },
	{ id: 2011, name: 'Mato Grosso', parent_id: 31 },
	{ id: 2010, name: 'Mato Grosso do Sul', parent_id: 31 },
	{ id: 1998, name: 'Minas Gerais', parent_id: 31 },
	{ id: 2009, name: 'Pará', parent_id: 31 },
	{ id: 2005, name: 'Paraíba', parent_id: 31 },
	{ id: 2022, name: 'Paraná', parent_id: 31 },
	{ id: 2006, name: 'Pernambuco', parent_id: 31 },
	{ id: 2008, name: 'Piauí', parent_id: 31 },
	{ id: 1997, name: 'Rio de Janeiro', parent_id: 31 },
	{ id: 2019, name: 'Rio Grande do Norte', parent_id: 31 },
	{ id: 2001, name: 'Rio Grande do Sul', parent_id: 31 },
	{ id: 2013, name: 'Rondônia', parent_id: 31 },
	{ id: 4858, name: 'Roraima', parent_id: 31 },
	{ id: 2014, name: 'Santa Catarina', parent_id: 31 },
	{ id: 2021, name: 'São Paulo', parent_id: 31 },
	{ id: 2003, name: 'Sergipe', parent_id: 31 },
	{ id: 2020, name: 'Tocantins', parent_id: 31 },
	{ id: 1217, name: 'Belait District', parent_id: 33 },
	{ id: 1216, name: 'Brunei-Muara District', parent_id: 33 },
	{ id: 1218, name: 'Temburong District', parent_id: 33 },
	{ id: 1219, name: 'Tutong District', parent_id: 33 },
	{ id: 4699, name: 'Blagoevgrad Province', parent_id: 34 },
	{ id: 4715, name: 'Burgas Province', parent_id: 34 },
	{ id: 4718, name: 'Dobrich Province', parent_id: 34 },
	{ id: 4693, name: 'Gabrovo Province', parent_id: 34 },
	{ id: 4704, name: 'Haskovo Province', parent_id: 34 },
	{ id: 4702, name: 'Kardzhali Province', parent_id: 34 },
	{ id: 4703, name: 'Kyustendil Province', parent_id: 34 },
	{ id: 4710, name: 'Lovech Province', parent_id: 34 },
	{ id: 4696, name: 'Montana Province', parent_id: 34 },
	{ id: 4712, name: 'Pazardzhik Province', parent_id: 34 },
	{ id: 4695, name: 'Pernik Province', parent_id: 34 },
	{ id: 4706, name: 'Pleven Province', parent_id: 34 },
	{ id: 4701, name: 'Plovdiv Province', parent_id: 34 },
	{ id: 4698, name: 'Razgrad Province', parent_id: 34 },
	{ id: 4713, name: 'Ruse Province', parent_id: 34 },
	{ id: 4882, name: 'Shumen', parent_id: 34 },
	{ id: 4708, name: 'Silistra Province', parent_id: 34 },
	{ id: 4700, name: 'Sliven Province', parent_id: 34 },
	{ id: 4694, name: 'Smolyan Province', parent_id: 34 },
	{ id: 4705, name: 'Sofia City Province', parent_id: 34 },
	{ id: 4719, name: 'Sofia Province', parent_id: 34 },
	{ id: 4707, name: 'Stara Zagora Province', parent_id: 34 },
	{ id: 4714, name: 'Targovishte Province', parent_id: 34 },
	{ id: 4717, name: 'Varna Province', parent_id: 34 },
	{ id: 4709, name: 'Veliko Tarnovo Province', parent_id: 34 },
	{ id: 4697, name: 'Vidin Province', parent_id: 34 },
	{ id: 4711, name: 'Vratsa Province', parent_id: 34 },
	{ id: 4716, name: 'Yambol Province', parent_id: 34 },
	{ id: 3160, name: 'Balé Province', parent_id: 35 },
	{ id: 3155, name: 'Bam Province', parent_id: 35 },
	{ id: 3120, name: 'Banwa Province', parent_id: 35 },
	{ id: 3152, name: 'Bazèga Province', parent_id: 35 },
	{ id: 3138, name: 'Boucle du Mouhoun Region', parent_id: 35 },
	{ id: 3121, name: 'Bougouriba Province', parent_id: 35 },
	{ id: 3131, name: 'Boulgou', parent_id: 35 },
	{ id: 3153, name: 'Cascades Region', parent_id: 35 },
	{ id: 3136, name: 'Centre', parent_id: 35 },
	{ id: 3162, name: 'Centre-Est Region', parent_id: 35 },
	{ id: 3127, name: 'Centre-Nord Region', parent_id: 35 },
	{ id: 3115, name: 'Centre-Ouest Region', parent_id: 35 },
	{ id: 3149, name: 'Centre-Sud Region', parent_id: 35 },
	{ id: 3167, name: 'Comoé Province', parent_id: 35 },
	{ id: 3158, name: 'Est Region', parent_id: 35 },
	{ id: 3148, name: 'Ganzourgou Province', parent_id: 35 },
	{ id: 3122, name: 'Gnagna Province', parent_id: 35 },
	{ id: 3143, name: 'Gourma Province', parent_id: 35 },
	{ id: 3165, name: 'Hauts-Bassins Region', parent_id: 35 },
	{ id: 3129, name: 'Houet Province', parent_id: 35 },
	{ id: 3135, name: 'Ioba Province', parent_id: 35 },
	{ id: 3168, name: 'Kadiogo Province', parent_id: 35 },
	{ id: 3112, name: 'Kénédougou Province', parent_id: 35 },
	{ id: 3132, name: 'Komondjari Province', parent_id: 35 },
	{ id: 3157, name: 'Kompienga Province', parent_id: 35 },
	{ id: 3146, name: 'Kossi Province', parent_id: 35 },
	{ id: 3133, name: 'Koulpélogo Province', parent_id: 35 },
	{ id: 3161, name: 'Kouritenga Province', parent_id: 35 },
	{ id: 3147, name: 'Kourwéogo Province', parent_id: 35 },
	{ id: 3159, name: 'Léraba Province', parent_id: 35 },
	{ id: 3151, name: 'Loroum Province', parent_id: 35 },
	{ id: 3123, name: 'Mouhoun', parent_id: 35 },
	{ id: 3116, name: 'Nahouri Province', parent_id: 35 },
	{ id: 3113, name: 'Namentenga Province', parent_id: 35 },
	{ id: 3142, name: 'Nayala Province', parent_id: 35 },
	{ id: 3164, name: 'Nord Region, Burkina Faso', parent_id: 35 },
	{ id: 3156, name: 'Noumbiel Province', parent_id: 35 },
	{ id: 3141, name: 'Oubritenga Province', parent_id: 35 },
	{ id: 3144, name: 'Oudalan Province', parent_id: 35 },
	{ id: 3117, name: 'Passoré Province', parent_id: 35 },
	{ id: 3125, name: 'Plateau-Central Region', parent_id: 35 },
	{ id: 3163, name: 'Poni Province', parent_id: 35 },
	{ id: 3114, name: 'Sahel Region', parent_id: 35 },
	{ id: 3154, name: 'Sanguié Province', parent_id: 35 },
	{ id: 3126, name: 'Sanmatenga Province', parent_id: 35 },
	{ id: 3139, name: 'Séno Province', parent_id: 35 },
	{ id: 3119, name: 'Sissili Province', parent_id: 35 },
	{ id: 3166, name: 'Soum Province', parent_id: 35 },
	{ id: 3137, name: 'Sourou Province', parent_id: 35 },
	{ id: 3140, name: 'Sud-Ouest Region', parent_id: 35 },
	{ id: 3128, name: 'Tapoa Province', parent_id: 35 },
	{ id: 3134, name: 'Tuy Province', parent_id: 35 },
	{ id: 3124, name: 'Yagha Province', parent_id: 35 },
	{ id: 3150, name: 'Yatenga Province', parent_id: 35 },
	{ id: 3145, name: 'Ziro Province', parent_id: 35 },
	{ id: 3130, name: 'Zondoma Province', parent_id: 35 },
	{ id: 3118, name: 'Zoundwéogo Province', parent_id: 35 },
	{ id: 3196, name: 'Bubanza Province', parent_id: 36 },
	{ id: 3198, name: 'Bujumbura Mairie Province', parent_id: 36 },
	{ id: 3200, name: 'Bujumbura Rural Province', parent_id: 36 },
	{ id: 3202, name: 'Bururi Province', parent_id: 36 },
	{ id: 3201, name: 'Cankuzo Province', parent_id: 36 },
	{ id: 3190, name: 'Cibitoke Province', parent_id: 36 },
	{ id: 3197, name: 'Gitega Province', parent_id: 36 },
	{ id: 3194, name: 'Karuzi Province', parent_id: 36 },
	{ id: 3192, name: 'Kayanza Province', parent_id: 36 },
	{ id: 3195, name: 'Kirundo Province', parent_id: 36 },
	{ id: 3188, name: 'Makamba Province', parent_id: 36 },
	{ id: 3193, name: 'Muramvya Province', parent_id: 36 },
	{ id: 3186, name: 'Muyinga Province', parent_id: 36 },
	{ id: 3187, name: 'Mwaro Province', parent_id: 36 },
	{ id: 3199, name: 'Ngozi Province', parent_id: 36 },
	{ id: 3185, name: 'Rumonge Province', parent_id: 36 },
	{ id: 3189, name: 'Rutana Province', parent_id: 36 },
	{ id: 3191, name: 'Ruyigi Province', parent_id: 36 },
	{ id: 3984, name: 'Banteay Meanchey', parent_id: 37 },
	{ id: 3976, name: 'Battambang', parent_id: 37 },
	{ id: 3991, name: 'Kampong Cham', parent_id: 37 },
	{ id: 3979, name: 'Kampong Chhnang', parent_id: 37 },
	{ id: 3988, name: 'Kampong Speu', parent_id: 37 },
	{ id: 5070, name: 'Kampong Thom', parent_id: 37 },
	{ id: 3981, name: 'Kampot', parent_id: 37 },
	{ id: 3983, name: 'Kandal', parent_id: 37 },
	{ id: 3978, name: 'Kep', parent_id: 37 },
	{ id: 3982, name: 'Koh Kong', parent_id: 37 },
	{ id: 3986, name: 'Kratie', parent_id: 37 },
	{ id: 3985, name: 'Mondulkiri', parent_id: 37 },
	{ id: 3987, name: 'Oddar Meanchey', parent_id: 37 },
	{ id: 3980, name: 'Pailin', parent_id: 37 },
	{ id: 3994, name: 'Phnom Penh', parent_id: 37 },
	{ id: 3973, name: 'Preah Vihear', parent_id: 37 },
	{ id: 3974, name: 'Prey Veng', parent_id: 37 },
	{ id: 3977, name: 'Pursat', parent_id: 37 },
	{ id: 3990, name: 'Ratanakiri', parent_id: 37 },
	{ id: 3992, name: 'Siem Reap', parent_id: 37 },
	{ id: 3989, name: 'Sihanoukville', parent_id: 37 },
	{ id: 3993, name: 'Stung Treng', parent_id: 37 },
	{ id: 3972, name: 'Svay Rieng', parent_id: 37 },
	{ id: 3975, name: 'Takeo', parent_id: 37 },
	{ id: 2663, name: 'Adamawa', parent_id: 38 },
	{ id: 2660, name: 'Centre', parent_id: 38 },
	{ id: 2661, name: 'East', parent_id: 38 },
	{ id: 2656, name: 'Far North', parent_id: 38 },
	{ id: 2662, name: 'Littoral', parent_id: 38 },
	{ id: 2665, name: 'North', parent_id: 38 },
	{ id: 2657, name: 'Northwest', parent_id: 38 },
	{ id: 2659, name: 'South', parent_id: 38 },
	{ id: 2658, name: 'Southwest', parent_id: 38 },
	{ id: 2664, name: 'West', parent_id: 38 },
	{ id: 872, name: 'Alberta', parent_id: 39 },
	{ id: 875, name: 'British Columbia', parent_id: 39 },
	{ id: 867, name: 'Manitoba', parent_id: 39 },
	{ id: 868, name: 'New Brunswick', parent_id: 39 },
	{ id: 877, name: 'Newfoundland and Labrador', parent_id: 39 },
	{ id: 878, name: 'Northwest Territories', parent_id: 39 },
	{ id: 874, name: 'Nova Scotia', parent_id: 39 },
	{ id: 876, name: 'Nunavut', parent_id: 39 },
	{ id: 866, name: 'Ontario', parent_id: 39 },
	{ id: 871, name: 'Prince Edward Island', parent_id: 39 },
	{ id: 873, name: 'Quebec', parent_id: 39 },
	{ id: 870, name: 'Saskatchewan', parent_id: 39 },
	{ id: 869, name: 'Yukon', parent_id: 39 },
	{ id: 2994, name: 'Barlavento Islands', parent_id: 40 },
	{ id: 2999, name: 'Boa Vista', parent_id: 40 },
	{ id: 2996, name: 'Brava', parent_id: 40 },
	{ id: 2991, name: 'Maio Municipality', parent_id: 40 },
	{ id: 2987, name: 'Mosteiros', parent_id: 40 },
	{ id: 2997, name: 'Paul', parent_id: 40 },
	{ id: 2989, name: 'Porto Novo', parent_id: 40 },
	{ id: 2988, name: 'Praia', parent_id: 40 },
	{ id: 2982, name: 'Ribeira Brava Municipality', parent_id: 40 },
	{ id: 3002, name: 'Ribeira Grande', parent_id: 40 },
	{ id: 2984, name: 'Ribeira Grande de Santiago', parent_id: 40 },
	{ id: 2998, name: 'Sal', parent_id: 40 },
	{ id: 2985, name: 'Santa Catarina', parent_id: 40 },
	{ id: 2995, name: 'Santa Catarina do Fogo', parent_id: 40 },
	{ id: 3004, name: 'Santa Cruz', parent_id: 40 },
	{ id: 2986, name: 'São Domingos', parent_id: 40 },
	{ id: 3000, name: 'São Filipe', parent_id: 40 },
	{ id: 2993, name: 'São Lourenço dos Órgãos', parent_id: 40 },
	{ id: 2990, name: 'São Miguel', parent_id: 40 },
	{ id: 3001, name: 'São Vicente', parent_id: 40 },
	{ id: 2992, name: 'Sotavento Islands', parent_id: 40 },
	{ id: 2983, name: 'Tarrafal', parent_id: 40 },
	{ id: 3003, name: 'Tarrafal de São Nicolau', parent_id: 40 },
	{ id: 5252, name: 'Cayman Brac', parent_id: 41 },
	{ id: 5251, name: 'Grand Cayman', parent_id: 41 },
	{ id: 5253, name: 'Little Cayman', parent_id: 41 },
	{ id: 1259, name: 'Bamingui-Bangoran Prefecture', parent_id: 42 },
	{ id: 1262, name: 'Bangui', parent_id: 42 },
	{ id: 1264, name: 'Basse-Kotto Prefecture', parent_id: 42 },
	{ id: 1258, name: 'Haut-Mbomou Prefecture', parent_id: 42 },
	{ id: 1268, name: 'Haute-Kotto Prefecture', parent_id: 42 },
	{ id: 1263, name: 'Kémo Prefecture', parent_id: 42 },
	{ id: 1256, name: 'Lobaye Prefecture', parent_id: 42 },
	{ id: 1257, name: 'Mambéré-Kadéï', parent_id: 42 },
	{ id: 1266, name: 'Mbomou Prefecture', parent_id: 42 },
	{ id: 1253, name: 'Nana-Grébizi Economic Prefecture', parent_id: 42 },
	{ id: 1260, name: 'Nana-Mambéré Prefecture', parent_id: 42 },
	{ id: 1255, name: `Ombella-M'Poko Prefecture`, parent_id: 42 },
	{ id: 1265, name: 'Ouaka Prefecture', parent_id: 42 },
	{ id: 1254, name: 'Ouham Prefecture', parent_id: 42 },
	{ id: 1267, name: 'Ouham-Pendé Prefecture', parent_id: 42 },
	{ id: 1252, name: 'Sangha-Mbaéré', parent_id: 42 },
	{ id: 1261, name: 'Vakaga Prefecture', parent_id: 42 },
	{ id: 3583, name: 'Bahr el Gazel', parent_id: 43 },
	{ id: 3590, name: 'Batha', parent_id: 43 },
	{ id: 3574, name: 'Borkou', parent_id: 43 },
	{ id: 5114, name: 'Chari-Baguirmi', parent_id: 43 },
	{ id: 3575, name: 'Ennedi-Est', parent_id: 43 },
	{ id: 3584, name: 'Ennedi-Ouest', parent_id: 43 },
	{ id: 3576, name: 'Guéra', parent_id: 43 },
	{ id: 3573, name: 'Hadjer-Lamis', parent_id: 43 },
	{ id: 3588, name: 'Kanem', parent_id: 43 },
	{ id: 3577, name: 'Lac', parent_id: 43 },
	{ id: 3585, name: 'Logone Occidental', parent_id: 43 },
	{ id: 3591, name: 'Logone Oriental', parent_id: 43 },
	{ id: 3589, name: 'Mandoul', parent_id: 43 },
	{ id: 3580, name: 'Mayo-Kebbi Est', parent_id: 43 },
	{ id: 3571, name: 'Mayo-Kebbi Ouest', parent_id: 43 },
	{ id: 3570, name: 'Moyen-Chari', parent_id: 43 },
	{ id: 3586, name: `N'Djamena`, parent_id: 43 },
	{ id: 3582, name: 'Ouaddaï', parent_id: 43 },
	{ id: 3592, name: 'Salamat', parent_id: 43 },
	{ id: 3572, name: 'Sila', parent_id: 43 },
	{ id: 3579, name: 'Tandjilé', parent_id: 43 },
	{ id: 3587, name: 'Tibesti', parent_id: 43 },
	{ id: 3581, name: 'Wadi Fira', parent_id: 43 },
	{ id: 2832, name: 'Antofagasta', parent_id: 44 },
	{ id: 2829, name: 'Arica y Parinacota', parent_id: 44 },
	{ id: 2823, name: 'Atacama', parent_id: 44 },
	{ id: 2827, name: 'Biobío', parent_id: 44 },
	{ id: 2825, name: 'Coquimbo', parent_id: 44 },
	{ id: 2826, name: 'La Araucanía', parent_id: 44 },
	{
		id: 2838,
		name: `Libertador General Bernardo O'Higgins`,
		parent_id: 44,
	},
	{ id: 2835, name: 'Los Lagos', parent_id: 44 },
	{ id: 2834, name: 'Los Ríos', parent_id: 44 },
	{
		id: 2836,
		name: 'Magallanes y de la Antártica Chilena',
		parent_id: 44,
	},
	{ id: 2833, name: 'Maule', parent_id: 44 },
	{ id: 2831, name: 'Ñuble', parent_id: 44 },
	{ id: 2824, name: 'Región Metropolitana de Santiago', parent_id: 44 },
	{ id: 2837, name: 'Tarapacá', parent_id: 44 },
	{ id: 2830, name: 'Valparaíso', parent_id: 44 },
	{ id: 2251, name: 'Anhui', parent_id: 45 },
	{ id: 2257, name: 'Beijing', parent_id: 45 },
	{ id: 2271, name: 'Chongqing', parent_id: 45 },
	{ id: 2248, name: 'Fujian', parent_id: 45 },
	{ id: 2275, name: 'Gansu', parent_id: 45 },
	{ id: 2279, name: 'Guangdong', parent_id: 45 },
	{ id: 2278, name: 'Guangxi Zhuang', parent_id: 45 },
	{ id: 2261, name: 'Guizhou', parent_id: 45 },
	{ id: 2273, name: 'Hainan', parent_id: 45 },
	{ id: 2280, name: 'Hebei', parent_id: 45 },
	{ id: 2265, name: 'Heilongjiang', parent_id: 45 },
	{ id: 2259, name: 'Henan', parent_id: 45 },
	{ id: 2267, name: 'Hong Kong SAR', parent_id: 45 },
	{ id: 2274, name: 'Hubei', parent_id: 45 },
	{ id: 2258, name: 'Hunan', parent_id: 45 },
	{ id: 2269, name: 'Inner Mongolia', parent_id: 45 },
	{ id: 2250, name: 'Jiangsu', parent_id: 45 },
	{ id: 2256, name: 'Jiangxi', parent_id: 45 },
	{ id: 2253, name: 'Jilin', parent_id: 45 },
	{ id: 2268, name: 'Liaoning', parent_id: 45 },
	{ id: 2266, name: 'Macau SAR', parent_id: 45 },
	{ id: 2262, name: 'Ningxia Huizu', parent_id: 45 },
	{ id: 2270, name: 'Qinghai', parent_id: 45 },
	{ id: 2272, name: 'Shaanxi', parent_id: 45 },
	{ id: 2252, name: 'Shandong', parent_id: 45 },
	{ id: 2249, name: 'Shanghai', parent_id: 45 },
	{ id: 2254, name: 'Shanxi', parent_id: 45 },
	{ id: 2277, name: 'Sichuan', parent_id: 45 },
	{ id: 2255, name: 'Taiwan', parent_id: 45 },
	{ id: 2276, name: 'Tianjin', parent_id: 45 },
	{ id: 2263, name: 'Xinjiang', parent_id: 45 },
	{ id: 2264, name: 'Xizang', parent_id: 45 },
	{ id: 2260, name: 'Yunnan', parent_id: 45 },
	{ id: 2247, name: 'Zhejiang', parent_id: 45 },
	{ id: 2895, name: 'Amazonas', parent_id: 48 },
	{ id: 2890, name: 'Antioquia', parent_id: 48 },
	{ id: 2881, name: 'Arauca', parent_id: 48 },
	{
		id: 2900,
		name: 'Archipiélago de San Andrés, Providencia y Santa Catalina',
		parent_id: 48,
	},
	{ id: 2880, name: 'Atlántico', parent_id: 48 },
	{ id: 4921, name: 'Bogotá D.C.', parent_id: 48 },
	{ id: 2893, name: 'Bolívar', parent_id: 48 },
	{ id: 2903, name: 'Boyacá', parent_id: 48 },
	{ id: 2887, name: 'Caldas', parent_id: 48 },
	{ id: 2891, name: 'Caquetá', parent_id: 48 },
	{ id: 2892, name: 'Casanare', parent_id: 48 },
	{ id: 2884, name: 'Cauca', parent_id: 48 },
	{ id: 2899, name: 'Cesar', parent_id: 48 },
	{ id: 2876, name: 'Chocó', parent_id: 48 },
	{ id: 2898, name: 'Córdoba', parent_id: 48 },
	{ id: 2875, name: 'Cundinamarca', parent_id: 48 },
	{ id: 2882, name: 'Guainía', parent_id: 48 },
	{ id: 2888, name: 'Guaviare', parent_id: 48 },
	{ id: 4871, name: 'Huila', parent_id: 48 },
	{ id: 2889, name: 'La Guajira', parent_id: 48 },
	{ id: 2886, name: 'Magdalena', parent_id: 48 },
	{ id: 2878, name: 'Meta', parent_id: 48 },
	{ id: 2897, name: 'Nariño', parent_id: 48 },
	{ id: 2877, name: 'Norte de Santander', parent_id: 48 },
	{ id: 2896, name: 'Putumayo', parent_id: 48 },
	{ id: 2874, name: 'Quindío', parent_id: 48 },
	{ id: 2879, name: 'Risaralda', parent_id: 48 },
	{ id: 2901, name: 'Santander', parent_id: 48 },
	{ id: 2902, name: 'Sucre', parent_id: 48 },
	{ id: 2883, name: 'Tolima', parent_id: 48 },
	{ id: 2904, name: 'Valle del Cauca', parent_id: 48 },
	{ id: 2885, name: 'Vaupés', parent_id: 48 },
	{ id: 2894, name: 'Vichada', parent_id: 48 },
	{ id: 2821, name: 'Anjouan', parent_id: 49 },
	{ id: 2822, name: 'Grande Comore', parent_id: 49 },
	{ id: 2820, name: 'Mohéli', parent_id: 49 },
	{ id: 2866, name: 'Bouenza Department', parent_id: 50 },
	{ id: 2870, name: 'Brazzaville', parent_id: 50 },
	{ id: 2864, name: 'Cuvette Department', parent_id: 50 },
	{ id: 2869, name: 'Cuvette-Ouest Department', parent_id: 50 },
	{ id: 2867, name: 'Kouilou Department', parent_id: 50 },
	{ id: 2868, name: 'Lékoumou Department', parent_id: 50 },
	{ id: 2865, name: 'Likouala Department', parent_id: 50 },
	{ id: 2872, name: 'Niari Department', parent_id: 50 },
	{ id: 2862, name: 'Plateaux Department', parent_id: 50 },
	{ id: 2863, name: 'Pointe-Noire', parent_id: 50 },
	{ id: 2873, name: 'Pool Department', parent_id: 50 },
	{ id: 2871, name: 'Sangha Department', parent_id: 50 },
	{ id: 3601, name: 'Acklins', parent_id: 17 },
	{ id: 3628, name: 'Acklins and Crooked Islands', parent_id: 17 },
	{ id: 3593, name: 'Berry Islands', parent_id: 17 },
	{ id: 3629, name: 'Bimini', parent_id: 17 },
	{ id: 3605, name: 'Black Point', parent_id: 17 },
	{ id: 3611, name: 'Cat Island', parent_id: 17 },
	{ id: 3603, name: 'Central Abaco', parent_id: 17 },
	{ id: 3631, name: 'Central Andros', parent_id: 17 },
	{ id: 3596, name: 'Central Eleuthera', parent_id: 17 },
	{ id: 3621, name: 'Crooked Island', parent_id: 17 },
	{ id: 3614, name: 'East Grand Bahama', parent_id: 17 },
	{ id: 3612, name: 'Exuma', parent_id: 17 },
	{ id: 3626, name: 'Freeport', parent_id: 17 },
	{ id: 3619, name: 'Fresh Creek', parent_id: 17 },
	{ id: 3597, name: `Governor's Harbour`, parent_id: 17 },
	{ id: 3632, name: 'Grand Cay', parent_id: 17 },
	{ id: 3595, name: 'Green Turtle Cay', parent_id: 17 },
	{ id: 3613, name: 'Harbour Island', parent_id: 17 },
	{ id: 3598, name: 'High Rock', parent_id: 17 },
	{ id: 3624, name: 'Hope Town', parent_id: 17 },
	{ id: 3609, name: 'Inagua', parent_id: 17 },
	{ id: 3618, name: 'Kemps Bay', parent_id: 17 },
	{ id: 3610, name: 'Long Island', parent_id: 17 },
	{ id: 3625, name: 'Mangrove Cay', parent_id: 17 },
	{ id: 3604, name: 'Marsh Harbour', parent_id: 17 },
	{ id: 3633, name: 'Mayaguana District', parent_id: 17 },
	{ id: 4881, name: 'New Providence', parent_id: 17 },
	{ id: 3594, name: 'Nichollstown and Berry Islands', parent_id: 17 },
	{ id: 3616, name: 'North Abaco', parent_id: 17 },
	{ id: 3617, name: 'North Andros', parent_id: 17 },
	{ id: 3602, name: 'North Eleuthera', parent_id: 17 },
	{ id: 3615, name: 'Ragged Island', parent_id: 17 },
	{ id: 3623, name: 'Rock Sound', parent_id: 17 },
	{ id: 3600, name: 'Rum Cay District', parent_id: 17 },
	{ id: 3620, name: 'San Salvador and Rum Cay', parent_id: 17 },
	{ id: 3627, name: 'San Salvador Island', parent_id: 17 },
	{ id: 3606, name: 'Sandy Point', parent_id: 17 },
	{ id: 3608, name: 'South Abaco', parent_id: 17 },
	{ id: 3622, name: 'South Andros', parent_id: 17 },
	{ id: 3607, name: 'South Eleuthera', parent_id: 17 },
	{ id: 3630, name: 'Spanish Wells', parent_id: 17 },
	{ id: 3599, name: 'West Grand Bahama', parent_id: 17 },
];
  
