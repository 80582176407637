<div [ngClass]="typeClass" class="pad col">
	<ng-template [ngIf]="data.type !== typeTime">
		<uf-month-picker [(month)]="month" [(year)]="year" />
		<uf-day-picker [day]="day" [month]="month" [year]="year" [(date)]="date" />
	</ng-template>
	<uf-time-picker *ngIf="data.type !== typeDate" [step]="data.step" [amPm]="data.amPm ?? true" [(date)]="date" />
	<div [ufErrorMessage]="errorMessage"></div>
	<div class="row center-all">
		<button (click)="clear()" type="button" class="uf-button small tertiary right">
			{{sharedTermsTK.ActionClear | translate}}
		</button>
		<button (click)="now()" type="button" class="uf-button small tertiary right">
			{{sharedTermsTK.NowLabel | translate}}
		</button>
		<button (click)="done()" type="button" class="uf-button small primary gap-left">
			{{commonTranslationKey.DatePickerInputActionDone | translate}}
		</button>
	</div>
</div>