import { Inject, Injectable, Optional } from '@angular/core';
import { Client, FileType, ProjectContentOptions, ProjectContentOptionsInterface, PublishedContent, TenantClient } from '@unifii/sdk';

import { DataLoaderFactory, FileUploader } from '@unifii/library/common';
import { FormSettings } from '@unifii/library/smart-forms';

@Injectable()
export class InputFormSettings implements FormSettings {

	dataLoaderFactory: DataLoaderFactory;
	uploader?: FileUploader;
	print: boolean;

	private _allowedFileTypesPromise: Promise<FileType[]> | undefined;

	constructor(
		client: Client,
		private tenantClient: TenantClient,
		@Optional() @Inject(PublishedContent) content: PublishedContent | null,
		@Optional() @Inject(ProjectContentOptions) options: ProjectContentOptionsInterface | null,
	) {
		this.dataLoaderFactory = new DataLoaderFactory(client, content, options);
	}

	getAllowedFileTypes(): Promise<FileType[]> {

		// allowedFileTypes not needed in print mode
		if (this.print) {
			return Promise.resolve([]);
		}

		// Cache and return the load promise
		if (!this._allowedFileTypesPromise) {
			this._allowedFileTypesPromise = this.tenantClient.getAllowedFileTypes().catch((error) => {
				console.error('Failed to get allowed file types', error);

				return Promise.resolve([]);
			});
		}

		return this._allowedFileTypesPromise;
	}

}
