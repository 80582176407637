import { AbstractControl, AsyncValidatorFn, FormGroup, ValidatorFn } from '@angular/forms';
import { Dictionary } from '@unifii/sdk';

import { ControlDependencyManager } from './control-dependency-manager';
import { UfControlOptions, UfFormControl, UpdateValueValidityArgs } from './uf-control-types';

export class UfControlGroup extends FormGroup {

	dependents: UfFormControl[] = [];

	private onValueUpdate = new Map<any,(updatedControls: Set<AbstractControl>) => any>();
	private _submitted: boolean;
	private _component?: { disabled: boolean };
	private dependencyManager: ControlDependencyManager<UfFormControl>;

	constructor(
		controls: Dictionary<AbstractControl>,
		options?: UfControlOptions,
		validator?: ValidatorFn,
		asyncValidator?: AsyncValidatorFn,
	) {
		super(controls, validator, asyncValidator);

		this.dependencyManager = new ControlDependencyManager<UfFormControl>(this, options?.deps);
	}

	override enable(opts: { onlySelf?: boolean; emitEvent?: boolean } = {}) {

		if (this.component?.disabled === true) {
			return;
		}
		super.enable(opts);
	}

	override disable(opts: { onlySelf?: boolean; emitEvent?: boolean } = {}) {
		super.disable(opts);
	}

	addDependencies(controls: UfFormControl[]) {
		this.dependencyManager.addDependencies(controls);
	}

	removeDependencies() {
		this.dependencyManager.removeDependencies();
	}

	updateDependencies() {
		this.dependencyManager.updateDependencies();

		for (const controlName of Object.keys(this.controls)) {
			(this.controls[controlName] as UfFormControl).updateDependencies();
		}
	}

	registerOnValueUpdate(key: any, func: (updatedControls: Set<AbstractControl>) => any) {
		this.onValueUpdate.set(key, func);
	}

	removeOnValueUpdate(key: any) {
		this.onValueUpdate.delete(key);
	}

	override updateValueAndValidity({ onlySelf, emitEvent, updatedControls }: UpdateValueValidityArgs = {}): void {
		super.updateValueAndValidity({ onlySelf, emitEvent });

		if (onlySelf || updatedControls?.has(this)) {
			return;
		}

		if (updatedControls == null) {
			updatedControls = new Set();
		}
		updatedControls.add(this);

		for (const callback of this.onValueUpdate.values()) {
			callback(updatedControls);
		}

		for (const dependant of this.dependents) {
			if (!updatedControls.has(dependant)) {
				dependant.updateValueAndValidity({ onlySelf, emitEvent, updatedControls });
			}
		}
	}

	setSubmitted(v = true) {
		this._submitted = v;

		const keys = Object.keys(this.controls);

		for (const key of keys) {
			const control = this.controls[key] as UfFormControl | undefined;

			if (control?.setSubmitted) {
				control.setSubmitted(v);
			}
		}
	}

	set component(v: { disabled: boolean }) {
		this._component = v;
	}

	get component(): { disabled: boolean } | undefined {
		return this._component;
	}

	get submitted(): boolean {
		return this._submitted;
	}

	get showError(): boolean {
		return !this.valid && this.enabled && (this._submitted || this.touched);
	}

	get dependencies() {
		return this.dependencyManager.dependencies;
	}

}
