<ul [class.desktop-only]="minimize">
	<li *ngFor="let breadcrumb of breadcrumbs; let i = index;">
		<p>
			<a *ngIf="(breadcrumb.urlSegments != null && (i + 1) !== breadcrumbs.length ); else elseBlock"
				[routerLink]="breadcrumb.urlSegments" [queryParams]="breadcrumb.queryParams">
				{{ breadcrumb.name }}
			</a>
			<ng-template #elseBlock>
				{{ breadcrumb.name }}
			</ng-template>
		</p>
	</li>
	<li>
		<p>
			<ng-content />
		</p>
	</li>
</ul>