import { Inject, Injectable, Optional } from '@angular/core';
import { Client, FieldType, FormData, FormDataHistory, ProjectContentOptions, ProjectContentOptionsInterface } from '@unifii/sdk';
import { parseISO } from 'date-fns';

import { RuntimeField, RuntimeTransition } from '@unifii/library/common';

import { FormDebugger } from './form-debugger';
import { FormRevisionStorage } from './form-revision-storage.service';

export interface RevisionInfo {
	field: RuntimeField;
	formData: FormData;
}

@Injectable()
export class FormRevisionService {

	private formData: FormData = {};

	private revisionStorage: FormRevisionStorage;

	constructor(
		@Optional() private client: Client | null,
		@Optional() @Inject(ProjectContentOptions) options: ProjectContentOptionsInterface | null,
		@Optional() revisionStorageInput: FormRevisionStorage | null,
		@Optional() private formDebugger: FormDebugger | null,
	) {
		this.revisionStorage = revisionStorageInput ?? new FormRevisionStorage(this.client, options);
	}

	init(formData: FormData) {
		this.formData = formData;
		this.revisionStorage.clear();
	}

	async getRevisionData(section: RuntimeField): Promise<RevisionInfo[]> {
		const { transitions } = section;
		const actionGroups = section.fields.filter((f) => this.actionGroupFilter(f, transitions, this.formData._history));

		if (!actionGroups.length || !transitions.length) {
			return [];
		}
		
		try {
			const revisions = await this.getRevisions();

			return this.filterRevisions(revisions, actionGroups, transitions);
		} catch (e) {
			console.error('Failed to fetch form revisions', e);

			// fallback on old action group display
			return actionGroups.map((field) => ({ field, formData: this.formData }));
		}
	}

	private actionGroupFilter(field: RuntimeField, transitions: RuntimeTransition[], history: FormDataHistory[] = []): boolean {

		if (field.type !== FieldType.ActionGroup || !field.showOn) {
			return false;
		}

		const historicalTransitions = transitions.filter((t) => this.transitionFilter(t, history));
		const historicalActions = historicalTransitions.map((t) => t.action);

		return historicalActions.includes(field.showOn);
	}

	private transitionFilter({ source, action }: RuntimeTransition, history: FormDataHistory[]): boolean {
		return history.find((h) => h.action === action && h.state === source) != null;
	}

	private filterRevisions(revisions: FormData[], actionGroups: RuntimeField[], transitions: RuntimeTransition[]): RevisionInfo[] {

		const revisionInfo: RevisionInfo[] = [];

		for (const formData of revisions) {
			const visibleGroups = actionGroups.filter((ag) => this.actionGroupFilter(ag, transitions, formData._history));
			const groups = visibleGroups.filter((f) => f.showOn === formData._action);

			for (const field of groups) {
				revisionInfo.push({ formData, field });
			}
		}

		return revisionInfo;
	}

	private async getRevisions(): Promise<FormData[]> {
		const error = this.revisionError;

		if (error) {
			throw error;
		}

		// Check revision data exists and revisions match formData.id
		if (this.revisionStorage.revisions == null || this.revisionStorage.revisions.some((rv) => rv.id !== this.formData.id)) {
			await this.revisionStorage.loadRevisionsByFormData(this.formData);
		}
		const storedRevisions = this.revisionStorage.revisions!;

		// Filter by revision that have been created before the same time as the current data
		return storedRevisions.filter((rv) => parseISO(rv._lastModifiedAt!) <= parseISO(this.formData._lastModifiedAt!));
	}

	private get revisionError(): Error | undefined {
		if (this.formDebugger) {
			return new Error('Cannot get revision data in debug mode');
		}
		if (this.formData == null) {
			return new Error('Cannot get revision data without form data');
		}
		if (!this.formData._bucket) {
			// if a form is in the offline queue a bucket has not been set yet
			throw new Error('Cannot get revision data with bucket set on FormData');
		}

		return;
	}

}
