import { Component } from '@angular/core';

@Component({
	templateUrl: './show-drawers.html',

})
export class ShowDrawersComponent {

	openDrawer = false;

}
