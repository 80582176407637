import { FormRevisionService } from './form-revision.service';
import { FormValidators } from './form-validators';
import { FormService } from './form.service';
import { ScopeManager } from './scope-manager';
import { ValidatorBuilder } from './validator-builder';
import { WorkflowService } from './workflow.service';

/**
 * All services required for smart forms to work, this list is used to when providing the services 
 * in the form component and for detecting when field is within a smart form
 */
export const SmartFormServices = [
	ScopeManager,
	FormService,
	FormValidators,
	ValidatorBuilder,
	WorkflowService,
	FormRevisionService,
];
