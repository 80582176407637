<div class="wrapper">
	<div class="d-name">
		<div>{{daysLabel.monday}}</div>
		<div>{{daysLabel.tuesday}}</div>
		<div>{{daysLabel.wednesday}}</div>
		<div>{{daysLabel.thursday}}</div>
		<div>{{daysLabel.friday}}</div>
		<div>{{daysLabel.saturday}}</div>
		<div>{{daysLabel.sunday}}</div>
	</div>
	<div id="date-grid box border-all" class="date">
		<div *ngFor="let item of days"
			[ngClass]="{disabled: !item?.label, selected: day === item?.label && !!day && currentMonth === item?.month && currentYear === item?.year }"
			(click)="dayClick(item?.label)">
			{{item?.label}}
		</div>
	</div>
</div>