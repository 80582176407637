<div *ngIf="showLoadButton" class="col pad center-all">
	<button (click)="table.load()" class="uf-button" type="button">
		{{ commonTK.TableActionDataLoad | translate }}
	</button>
</div>

<div *ngIf="showLoading" class="col pad center-all">
	<uf-spinner type="dots" />
</div>

<div *ngIf="error" class="col center-all pad">
	<uf-icon name="error" class="x-large error" />
	<h3 class="gap-none-bottom gap-sm-top">{{ error.code + '' || commonTK.TableFeedbackDataErrorTitle | translate }}
	</h3>
	<p class="gap-sm-top">{{error.message || (commonTK.TableFeedbackDataErrorDescription | translate)}}</p>
	<button (click)="table.load()" class="uf-button small gap-sm-top" type="button">
		{{commonTK.TableFeedbackDataErrorActionRetry | translate}}
	</button>
</div>

<ng-container *ngIf="showEmpty">
	<div class="message-sm col center-all">
		<div class="row center-all">
			<uf-icon class="gap-sm" name="info" />
			<h3 class="gap-sm">{{commonTK.TableFeedbackDataEmptyTitle | translate}}</h3>
			<p class="gap-sm">{{commonTK.TableFeedbackDataEmptyDescription | translate}}</p>
		</div>
	</div>
	<div class="col center-all pad message-lg">
		<uf-icon name="big_empty" class="x-large accent" />
		<h3 class="gap-none-bottom gap-sm-top">{{ commonTK.TableFeedbackDataEmptyTitle | translate }}</h3>
		<p class="gap-sm-top">{{commonTK.TableFeedbackDataEmptyDescription | translate}}</p>
	</div>
</ng-container>

<ng-container *ngIf="showEmptyFiltered">
	<uf-message [content]="commonTK.TableFeedbackDataFilteredEmptyDescription | translate" icon="info"
		class="message-sm">
		<h3>{{ commonTK.TableFeedbackDataFilteredEmptyTitle | translate }}</h3>
	</uf-message>
	<div class="col center-all pad message-lg">
		<uf-icon name="big_empty" class="x-large accent" />
		<h3 class="gap-none-bottom gap-sm-top">{{ commonTK.TableFeedbackDataFilteredEmptyTitle | translate }}</h3>
		<p class="gap-sm-top">{{commonTK.TableFeedbackDataFilteredEmptyDescription | translate}}</p>
	</div>
</ng-container>