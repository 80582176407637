import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { Subscription } from 'rxjs';

import { DirectoryDirective, DirectoryIndicatorRenderInfo } from '../../directives';

@Component({
	selector: 'uf-directory-indicator',
	templateUrl: './directory-indicator.html',
	styleUrls: ['./directory-indicator.less'],
})
export class DirectoryIndicatorComponent<T extends object> implements OnInit, OnDestroy {

	@Input() icon = 'folder';
	@Input() label: string | undefined;

	protected expanded = true;
	protected hasChildren = false;
	protected isChild = false;
	protected indicatorClasses: string[] = [];

	private directive = inject(DirectoryDirective<T>, { optional: true });
	private subscriptions = new Subscription();

	ngOnInit() {
		if (!this.directive) {
			console.warn('DirectoryIndicatorComponent: DirectoryDirective not provided');

			return;
		}

		this.subscriptions.add(this.directive.onChanges.subscribe(this.onChanges.bind(this)));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected toggleExpander(event: MouseEvent) {
		event.stopPropagation();
		this.expanded = !this.expanded;
		this.directive?.updateChildrensVisiblity(this.expanded);
	}

	private onChanges(indicatorInfo: DirectoryIndicatorRenderInfo) {
		this.isChild = indicatorInfo.parentRenderInfo != null;
		this.hasChildren = indicatorInfo.hasChildren;

		const infoList = [...this.indicatorIterator(indicatorInfo)];

		this.indicatorClasses = infoList.map((_, i) => {
			const baseClass = 'indicator';

			if (i === (infoList.length - 2)) {
				return `${baseClass} horizontal-line ${indicatorInfo.isLastChild ? 'vertical-top' : 'vertical'}`;
			}

			if (infoList.length > 2 && (infoList[i + 1] as DirectoryIndicatorRenderInfo | undefined)?.isLastChild === false) {
				return `${baseClass} vertical`;
			}

			if (i === (infoList.length - 1) && indicatorInfo.hasChildren) {
				return `${baseClass} vertical-bottom`;
			}

			return baseClass;
		});
	}

	private *indicatorIterator(item: DirectoryIndicatorRenderInfo): Iterable<DirectoryIndicatorRenderInfo> {

		if (item.parentRenderInfo) {
			yield *this.indicatorIterator(item.parentRenderInfo);
		}
		yield item;
	}

}
