import { Component, Input } from '@angular/core';

import { RuntimeField, SharedTermsTranslationKey } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-video-list-display',
	templateUrl: './video-list.html',
})
export class VideoListComponent implements ContentField {

	@Input() content: any[];
	@Input() field: RuntimeField;

	readonly sharedTermsTK = SharedTermsTranslationKey;

}
