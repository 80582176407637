import { Dictionary, Field, Transition } from '@unifii/sdk';
import { Identifier } from 'jsep';

import { FieldAdapter, RuntimeField, RuntimeTransition } from '../../models';
import { ExpressionParser } from '../expression-parser';

export class TransitionDependenciesAdapter implements FieldAdapter {

	private fields = new Map<string, Field[]>();
	private transitionDepLookup = new Map<Transition, string[]>();

	constructor(private expressionParser: ExpressionParser) { }

	transform(field: Field) {

		// Create look up for fields that can be references in an expression
		if (field.identifier) {
			const fields = this.fields.get(field.identifier) ?? [];

			this.fields.set(field.identifier, [field, ...fields]);
		}

		// Track workflow dependencies for hiding and showing workflow buttons
		const transitionDeps = this.getTransitionDeps(field.transitions);

		for (const t of transitionDeps) {
			this.transitionDepLookup.set(t.transition, [...new Set(t.dependencies)]);
		}
	}

	reset() {
		this.fields.clear();
		this.transitionDepLookup.clear();
	}

	done() {
		for (const [transition, value] of this.transitionDepLookup) {
			const transitionDeps = this.identifierToFieldMapper(value, this.fields);

			(transition as RuntimeTransition).dependencies = transitionDeps as unknown as RuntimeField[];
		}

	}

	private getTransitionDeps(transitions?: Transition[]) {
		return transitions?.map((transition) => ({
			transition,
			dependencies: this.getExpressionDeps(transition.showIf),
		})) ?? [];
	}

	private getExpressionDeps(expString?: string | null): string[] {

		if (!expString) {
			return [];
		}

		const expression = this.expressionParser.parse(expString);

		if (!expression) {
			return [];
		}

		const deps: string[] = [];

		if (expression.type === 'Identifier') {
			deps.push((expression as Identifier).name);
		}

		for (const item of this.iterateValues(expression)) {
			// TODO check that identifiers are catching all deps
			if (item && item.type === 'Identifier') {
				deps.push(item.name);
			}
		}

		return deps;
	}

	private identifierToFieldMapper(identifiers: string[], fieldLookup: Map<string, Field[]>): Field[] {

		return identifiers.reduce<Field[]>((result, identifier) => {

			const fields = fieldLookup.get(identifier);

			if (fields) {
				result.push(...fields);
			}

			return result;
		}, []);
	}

	/** flatten complex object into values */
	private *iterateValues(obj: Dictionary<any>): Iterable<any> {

		for (const key of Object.keys(obj)) {
			const value = obj[key];

			if (value == null) {
				yield '';
			}

			if (value && typeof value === 'object' && Object.keys(value).length) {
				yield *this.iterateValues(value);
			}

			if (Array.isArray(value)) {
				for (const v of value) {
					yield *this.iterateValues(v);
				}
			}

			yield value;
		}
	}

}
