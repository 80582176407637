<ng-template [ngIf]="node && visible">

	<uf-expander *ngIf="node.children?.length; else linkBlock" [actionBtn]="true" [isExpanded]="node.expanded">
		<a *ngIf="node.urlSegments" #rla="routerLinkActive" [routerLink]="node.urlSegments"
			[class.active]="rla?.isActive" expanderHeader routerLinkActive class="uf-expander-header--padded">
			<span>{{ node.name }}</span>
			<ng-container *ngTemplateOutlet="privateMarkerBlock" />
		</a>
		<div *ngIf="node.urlSegments == null" expanderHeader class="uf-expander-header--padded">
			<span>{{ node.name }}</span>
			<ng-container *ngTemplateOutlet="privateMarkerBlock" />
		</div>
		<ul expanderBody class="uf-nav-list">
			<ng-template [ngForOf]="node.children" let-child ngFor>
				<sc-menu-item [node]="child" />
			</ng-template>
		</ul>
	</uf-expander>

</ng-template>

<ng-template #linkBlock>
	<a #rla="routerLinkActive" [routerLink]="node.urlSegments" [class.active]="rla?.isActive" routerLinkActive>
		{{node.name}}
		<ng-container *ngTemplateOutlet="privateMarkerBlock" />
		<uf-icon *ngIf="node.notes" [title]="node.notes" name="info" />
	</a>
</ng-template>

<ng-template #privateMarkerBlock>
	<ng-container *ngIf="!node.public">
		&nbsp;&nbsp;
		<uf-icon name="viewHidden" />
	</ng-container>
</ng-template>