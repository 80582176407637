<ng-template [ngIf]="mode === modes.Input">
	<uf-number [(value)]="value.from" (valueChange)="valueChanged()" label="From" />
	<uf-number [(value)]="value.to" (valueChange)="valueChanged()" label="To" />
</ng-template>

<ng-template [ngIf]="mode === modes.Display && !isEmpty">
	<div *ngIf="value.from" class="uf-chip x-small">
		{{ displayFrom }}
		<button (click)="removeFrom()" type="button" class="uf-action">
			<uf-icon name="delete" />
		</button>
	</div>
	<div *ngIf="value.to" class="uf-chip x-small">
		{{ displayTo }}
		<button (click)="removeTo()" type="button" class="uf-action">
			<uf-icon name="delete" />
		</button>
	</div>
</ng-template>