import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FieldType } from '@unifii/sdk';

import { FilterEntry, FilterType, FilterViewMode, IFilterComponent, TempoRange, ZonedTempoRange } from '../../../models';
import { DataDisplayService } from '../../../services';
import { SharedTermsTranslationKey } from '../../../translations';

export interface FilterTempoRangeEntry extends FilterEntry {
	inputType?: FieldType.Date;
}

type FilterTempoRangeData = TempoRange | ZonedTempoRange;

@Component({
	selector: 'uf-filter-tempo-range',
	templateUrl: './filter-tempo-range.html',
	styleUrls: ['./field-filter.less'],
})
export class FilterTempoRangeComponent implements IFilterComponent<FilterTempoRangeData> {

	@Input() mode = FilterViewMode.Input;
	@Output() valueChange = new EventEmitter<FilterTempoRangeData | null>();

	protected readonly fieldType = FieldType;
	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly modes = FilterViewMode;
	protected inputType = FieldType.Date;

	private _value: FilterTempoRangeData = {};
	private _entry: FilterTempoRangeEntry;
	private dataDisplayService = inject(DataDisplayService);

	@Input({ required: true }) set entry(v: FilterTempoRangeEntry) {
		this._entry = v;

		if (this._entry.inputType) {
			this.inputType = this._entry.inputType;

			return;
		}

		switch (this._entry.type) {
			case FilterType.DateRange:
				this.inputType = FieldType.Date;
				break;
			case FilterType.TimeRange:
				this.inputType = FieldType.Time;
				break;
			case FilterType.DatetimeRange:
				this.inputType = FieldType.DateTime;
				break;
			case FilterType.ZonedDatetimeRange:
				this.inputType = FieldType.ZonedDateTime;
				break;
		}
	}

	get entry(): FilterTempoRangeEntry {
		return this._entry;
	}

	@Input({ required: true }) set value(v: FilterTempoRangeData | null) {
		this._value = v ?? {};
	}

	get value(): FilterTempoRangeData {
		return this._value;
	}

	get isEmpty(): boolean {
		return !this.value.from && !this.value.to;
	}

	get label(): string {
		return this.entry.label;
	}

	protected get displayFrom(): string {
		return `${this.label} > ${this.dataDisplayService.displayAsString(this.value.from)}`;
	}

	protected get displayTo(): string {
		return `${this.label} < ${this.dataDisplayService.displayAsString(this.value.to)}`;
	}

	protected removeFrom() {
		delete this.value.from;
		this.valueChanged();
	}

	protected removeTo() {
		delete this.value.to;
		this.valueChanged();
	}

	protected valueChanged() {
		this.valueChange.emit(this.isEmpty ? undefined : this.value);
	}

}
