import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClaimConfig, DataSourceType, FieldType, LinkContentType, Option, Schema, SchemaField } from '@unifii/sdk';

import { DataSourceDisplayLabel, DataSourceDisplayTo, DataSourceIdLabel, DataSourceIdTo, FormDefinitionMetadataIdentifiers, StepFieldMetadataIdentifiers } from '../../constants';
import { DataDescriptor, DataDescriptorAdapter, DataDescriptorBucketType, DataDescriptorResolveFunction, DataPropertyDescriptor, SourceConfig } from '../../models';
import { CommonTranslationKey } from '../../translations';
import { isTemplateExpression } from '../../utils';

import { DataDescriptorAdapterCache } from './data-descriptor-adapter-cache';
import { allowed, clearPropertiesAndRegisterUnknownsAsSkipped, dataPropertyInfoToDataPropertyDescriptor, displayable, filterIdentifierChildrenIdentifiers, getDisplay, getIcon, getOperators, getPropertiesMap, inputFilterable, normalizeIdentifiersList, propertiesToDataSourceOutputProperties, schemaFieldToDataPropertyDescriptor, sortDataPropertyDescriptors, sortable, staticFilterable } from './data-descriptor-functions';
import { DataPropertyInfoService } from './data-property-info.service';

export interface BucketDataDescriptorAdapterLoader {
	loadSchema(identifier: string): Promise<Schema>;
	loadForms(): Promise<{ identifier: string; label: string; bucket?: string }[]>;
	loadUserClaims(): Promise<ClaimConfig[]>;
}
export const BucketDataDescriptorAdapterLoader = new InjectionToken<BucketDataDescriptorAdapterLoader>('BucketDataDescriptorAdapterLoader');

export interface BucketDataDescriptorPermissionController {
	canLoadSchema(identifier: string): boolean;
	canLoadForms(): boolean;
	canLoadUserClaims(): boolean;
}
export const BucketDataDescriptorPermissionController = new InjectionToken<BucketDataDescriptorPermissionController>('BucketDataDescriptorPermissionController');

@Injectable({ providedIn: 'root' })
export class BucketDataDescriptorAdapter implements DataDescriptorAdapter {

	userCallback?: DataDescriptorResolveFunction;
	companyCallback?: DataDescriptorResolveFunction;
	collectionCallback?: DataDescriptorResolveFunction;

	private readonly adapterDataType = DataDescriptorBucketType;

	constructor(
		private dataModelEntryService: DataPropertyInfoService,
		private translate: TranslateService,
		private cache: DataDescriptorAdapterCache,
		@Inject(BucketDataDescriptorAdapterLoader) private loader: BucketDataDescriptorAdapterLoader,
		@Optional() @Inject(BucketDataDescriptorPermissionController) private permissions: BucketDataDescriptorPermissionController | null,
	) { }

	/**
	 * Analyze a 'FormDataRepository' via its Schema
	 * @param bucket Schema to analyze
	 * @param identifiers whitelist of properties' identifier to analyze, all otherwise
	 */
	async getDataDescriptor(bucket: string, identifiers?: string[]): Promise<DataDescriptor | undefined> {

		// console.log(`DDBucket ${bucket} `, properties);

		const flattenedIdentifiers = normalizeIdentifiersList(this.adapterDataType, identifiers);

		if (this.permissions?.canLoadSchema(bucket) === false) {
			return {
				type: this.adapterDataType,
				propertyDescriptors: [],
				propertyDescriptorsMap: getPropertiesMap([]),
				isSearchable: false,
				skippedProperties: [{ identifier: 'schema', name: `No permission for buckets/${bucket}` }],
			};
		}

		const schema: Schema | undefined = await this.loadSchema(bucket);

		if (!schema) {
			console.warn(`BucketDataDescriptorAdapter - bucket ${bucket} not found`);

			return;
		}

		// Retrieve schema default properties and schema first level fields properties
		const [metadataProperties, rootFieldsProperties, skippedProperties] = await this.getSchemaRootProperties(schema, flattenedIdentifiers);

		const flattenFieldsProperties: DataPropertyDescriptor[] = [];

		for (const rootProperty of rootFieldsProperties) {
			const { properties: fieldSubProperties, skippedProperties: fieldSkippedProperties } = await this.getPropertyAndSubProperties({ property: rootProperty, depth: 0, schema, identifiers: flattenedIdentifiers });

			flattenFieldsProperties.push(...fieldSubProperties);
			skippedProperties.push(...fieldSkippedProperties ?? []);
		}

		// Sorted entries (we want metadata ones to be on top)
		let entries = [
			...sortDataPropertyDescriptors(metadataProperties),
			...sortDataPropertyDescriptors(flattenFieldsProperties),
		];

		// Clear entries list
		entries = clearPropertiesAndRegisterUnknownsAsSkipped(entries, skippedProperties, flattenedIdentifiers);

		// Amend
		for (const entry of entries) {
			entry.display = getDisplay(entry.identifier, entry.label);
			entry.icon = getIcon(entry.identifier, entry.type, this.adapterDataType);
			entry.asDisplay = displayable(entry, this.adapterDataType);
			entry.asSort = sortable(entry, this.adapterDataType);
			entry.asStaticFilter = staticFilterable(entry, this.adapterDataType);
			entry.asInputFilter = inputFilterable(entry, this.adapterDataType);
			entry.operators = getOperators(entry.type, entry.identifier, this.adapterDataType);

			// UNIFII-6029 Sub-property _id of a DS based field skipped from the static filter, the base field itself is available instead
			if (entry.identifier.endsWith(`.${DataSourceIdTo}`)) {
				const parentIdentifier = entry.identifier.substring(0, entry.identifier.length - 4);
				const parentEntry = entries.find((e) => e.identifier === parentIdentifier);

				if (parentEntry?.sourceConfig) {
					entry.asStaticFilter = false;
				}
			}
		}

		// const propertyIdentifiers = [...new Set(propertyDescriptors.map(pd => pd.identifier))];
		// const missingEntryIdentifiers = [...this.missingSubEntries.values()];
		// console.log('Missing:');
		// let count = 0;
		// for (const missing of missingEntryIdentifiers) {
		//	 if (propertyIdentifiers.find(v => v.toLowerCase() === missing.toLowerCase()) == null) {
		//		 count++;
		//		 console.log(`${count}: ${missing}`);
		//	 }
		// }

		// const skippedEntries = missingEntryIdentifiers.filter(m => propertyIdentifiers.indexOf(m) < 0);
		// console.log('Found #', propertyIdentifiers.length);
		// console.log('Missing #', missingEntryIdentifiers.length, missingEntryIdentifiers);
		// console.log('SkippedEntries', skippedEntries.length, skippedEntries);

		// FormDataRepository search work only on first level fields, no necessary to inspect nested fields (Repeat subfields or DS mappings)
		const hasSearchableFields = schema.fields.some((f) => f.isSearchable);
		// new attribute Schema.searchableMetaFields is not nullable but check is implemented for safe assess until 2.1 release migration retro generate the new field for every published Schema
		const hasSearchableMetadata = !!(schema.searchableMetaFields as string[] | undefined)?.length;

		return {
			type: this.adapterDataType,
			propertyDescriptors: entries,
			propertyDescriptorsMap: getPropertiesMap(entries),
			isSearchable: hasSearchableFields || hasSearchableMetadata,
			skippedProperties,
		};

	}

	// eslint-disable-next-line complexity
	private async getPropertyAndSubProperties(config: {
		property: DataPropertyDescriptor;
		depth: number;
		schema: Schema;
		identifiers: Set<string> | undefined;
	}): Promise<{properties: DataPropertyDescriptor[]; skippedProperties?: Option[]}> {

		const { property, depth, schema, identifiers } = config;
		const skippedProperties: Option[] = [];

		// console.log(level, ' - getEntryAndDataSourceSubEntries', entry.identifier, entry.dataSource != null);

		switch (property.type) {
			case FieldType.Hierarchy:
				return { properties: [property, ...this.getHierarchySubEntries(property)] };
			case FieldType.Link:
				return { properties: [property, ...this.getLinkSubEntries(property)] };
			case FieldType.Step:
				return { properties: [property, ...this.getStepSubEntries(property)] };
			case FieldType.Repeat:
				return { properties: [property] };
		}

		if (!property.sourceConfig) {
			return { properties: [property] };
		}

		/*
		UNIFII-6818 Repeat and subfields mock up implementation
		if (entry.type === FieldType.Repeat) {
			// add repeat
			entries.push(entry);
			// get schema field of repeat
			const schemaField: SchemaField = fieldIterator();
			// maybe get properties for later
			const repeatProperties = dataPropertyDescriptorAssociatedDataSourceProperties(entry, properties);
			// get subFields
		   const repeatFields = this.getSchemaFieldDataPropertyDescriptors(schemaField, repeatProperties);

		   for ( const subEntry of repeatFields) {

				const composedEntry: DataPropertyDescriptor = Object.assign({}, subEntry, { identifier: `${entry.identifier}.${subEntry.identifier}` });

				if (level < 3) {
					entries = await this.getEntryAndDataSourceSubEntries(composedEntry, entries, level + 1, skipped, schema, properties);
				} else {
					entries.push(composedEntry);
				}
		   }
		}
*/
		const sourceConfigPropertiesResult = await this.getSourceConfigProperties(property.identifier, property.sourceConfig, identifiers);

		if (typeof sourceConfigPropertiesResult === 'string') {
			return { properties: [], skippedProperties: [{ identifier: property.identifier, name: sourceConfigPropertiesResult }] };
		}

		const dsEntries = sourceConfigPropertiesResult.properties;

		skippedProperties.push(...sourceConfigPropertiesResult.skippedProperties);

		const subEntriesMap = new Map<string, DataPropertyDescriptor>();

		dsEntries.forEach((dse) => subEntriesMap.set(dse.identifier, dse));

		const result: DataPropertyDescriptor[] = [property];

		switch (property.sourceConfig.type) {
			case DataSourceType.Users:
			case DataSourceType.Company:
			case DataSourceType.Collection:
			case DataSourceType.Bucket:
			case DataSourceType.UserClaims: // TODO keep it?
				for (const mapping of property.sourceConfig.mappings) {

					const key = mapping.to;
					const subKey = mapping.from;
					const subEntryFullIdentifier = `${property.identifier}.${subKey}`;
					let subEntry = subEntriesMap.get(subKey);

					if (!subEntry) {
						// expression value are not supported but doesn't count towards missing entries
						if (isTemplateExpression(subKey)) {
							result.push({
								identifier: `${property.identifier}.${key}`,
								type: mapping.type,
								label: mapping.label,
								isExpression: true,
							});
							continue;
						}

						// Datasource entry targeting another datasource entry
						const subKeys = subKey.split('.');
						const firstSubKey = subKeys[0];

						if (!firstSubKey || subKeys.length !== 2) {
							console.warn('MissingSubEntry', subEntryFullIdentifier);
							continue;
						}
						const otherDSEntry = subEntriesMap.get(firstSubKey);

						if (!otherDSEntry?.sourceConfig) {
							console.warn('MissingSubEntry', subEntryFullIdentifier);
							continue;
						}
						const { properties: otherDSProperties, skippedProperties: otherDSSkippedProperties } = await this.getPropertyAndSubProperties({ property: otherDSEntry, depth, schema, identifiers });

						const otherDSEntrySubEntry = otherDSProperties.find((e) => e.identifier === subKey);

						skippedProperties.push(...otherDSSkippedProperties ?? []);

						if (!otherDSEntrySubEntry) {
							console.warn('MissingSubEntry', subEntryFullIdentifier);
							continue;
						}
						// Found entry of the DS targeting another DS entry
						subEntry = otherDSEntrySubEntry;
					}

					const composedEntry: DataPropertyDescriptor = Object.assign({}, subEntry, { identifier: `${property.identifier}.${key}` });

					if (property.sourceConfig.type === DataSourceType.Bucket && depth < 3) {
						const { properties: bucketDSSubProperties, skippedProperties: bucketDSSkippedProperties } = await this.getPropertyAndSubProperties({ property: composedEntry, depth: depth + 1, schema, identifiers });

						result.push(...bucketDSSubProperties);
						skippedProperties.push(...bucketDSSkippedProperties ?? []);
					} else {
						result.push(composedEntry);
					}
				}
		}

		return { properties: result, skippedProperties };
	}

	/** Retrieve the properties of a SourceConfig or return an error message */
	// eslint-disable-next-line complexity
	private async getSourceConfigProperties(propertyIdentifier: string, sourceConfig: SourceConfig, identifiers: Set<string> | undefined): Promise<{properties: DataPropertyDescriptor[]; skippedProperties: Option[]} | string> {

		// Retrieve DS optional mapping whitelist properties to analyze
		let mappingsWhitelistProperties: Set<string> | undefined;

		switch (sourceConfig.type) {
			case DataSourceType.Users:
			case DataSourceType.Company:
			case DataSourceType.Collection:
			case DataSourceType.Bucket: {
				// Only interested in subProperties of the current entry
				const dsAssociatedProperties = filterIdentifierChildrenIdentifiers(propertyIdentifier, identifiers);

				// Map subProperties to the output value entry to correctly look for the DS original descriptor entries
				mappingsWhitelistProperties = propertiesToDataSourceOutputProperties(sourceConfig.mappings, dsAssociatedProperties);
				// add DS original descriptor entries identifiers to the global identifiers list
				if (identifiers && mappingsWhitelistProperties) {
					for (const prop of mappingsWhitelistProperties) {
						identifiers.add(prop);
					}
				}
			} break;
		}

		const dsEntries: DataPropertyDescriptor[] = [];
		const skippedProperties: Option[] = [];
		const callbackProperties = mappingsWhitelistProperties ? [...mappingsWhitelistProperties] : undefined;

		switch (sourceConfig.type) {

			case DataSourceType.External:
			case DataSourceType.Named:
				/** External have fields stored in integration/feature (not available for /api)
				 *  Named are custom with unknown data model
				 *  for both case consider only '_id' and '_display' as Text fields
				 */
				dsEntries.push({
					identifier: DataSourceIdTo,
					type: FieldType.Text,
					label: DataSourceIdLabel,
				});

				dsEntries.push({
					identifier: DataSourceDisplayTo,
					type: FieldType.Text,
					label: DataSourceDisplayLabel,
				});
				break;

			case DataSourceType.Users:
				if (this.userCallback) {
					dsEntries.push(...(await this.userCallback(callbackProperties))?.propertyDescriptors ?? []);
				}
				break;

			case DataSourceType.Company:
				if (this.companyCallback) {
					dsEntries.push(...(await this.companyCallback(callbackProperties))?.propertyDescriptors ?? []);
				}
				break;

			case DataSourceType.Collection: {
				if (this.collectionCallback) {
					const collectionDataDescriptor = await this.collectionCallback(sourceConfig.id, callbackProperties);

					if (!collectionDataDescriptor) {
						return `error loading collection ${sourceConfig.id}`;
					}

					dsEntries.push(...collectionDataDescriptor.propertyDescriptorsMap.values());
					skippedProperties.push(...collectionDataDescriptor.skippedProperties ?? []);
				}
				break;
			}

			case DataSourceType.UserClaims:
				if (this.permissions?.canLoadUserClaims() === false) {
					return 'No permission for /default-claims';
				}
				dsEntries.push(...this.getClaimSubEntries(await this.loadUserClaimById(sourceConfig.id)));
				break;

			case DataSourceType.Bucket: {
				if (this.permissions?.canLoadSchema(sourceConfig.id) === false) {
					return `No permission for buckets/${sourceConfig.id}`;
				}
				const dataSourceSchema = await this.loadSchema(sourceConfig.id);

				if (!dataSourceSchema) {
					return `error loading schema ${sourceConfig.id}`;
				}

				// Retrieve schema default properties and schema first level fields properties
				const [metadataProperties, rootFieldsProperties, schemaSkippedProperties] = await this.getSchemaRootProperties(dataSourceSchema, mappingsWhitelistProperties);

				dsEntries.push(...metadataProperties);
				skippedProperties.push(...schemaSkippedProperties);

				for (const subEntry of rootFieldsProperties) {
					dsEntries.push(subEntry);
				}
			}
		}

		/**
		 * Override static/non-context retrieved dsEntries info with context sourceConfig mappings info (label only)
		 * This allows the Field.SourceConfig.OutputMappings.Label to override the label otherwise statically computed for DS's Entry
		 */
		if (sourceConfig.type !== DataSourceType.Named) {
			for (const entry of dsEntries) {
				const sourceConfigEntry = sourceConfig.mappingsTo[entry.identifier];

				if (sourceConfigEntry) {
					entry.label = sourceConfigEntry.label;
				}
			}
		}

		return { properties: dsEntries, skippedProperties };
	}

	private async getSchemaRootProperties(schema: Schema, identifiers?: Set<string>): Promise<[metadataProperties: DataPropertyDescriptor[], rootFieldsProperties: DataPropertyDescriptor[], skippedProperties: Option[]]> {
		const metadataResult = await this.getSchemaMetadataDataPropertyDescriptors(schema, identifiers);
		const rootFieldsResult = this.getSchemaRootFieldsDataPropertyDescriptors(schema, identifiers);

		return [metadataResult[0], rootFieldsResult, metadataResult[1]];
	}

	/** Generate the properties associated with the Schema metadata fields */
	private async getSchemaMetadataDataPropertyDescriptors(schema: Schema, properties?: Set<string>): Promise<[properties: DataPropertyDescriptor[], skippedProperties: Option[]]> {

		const schemaInfoReferences = new Map<string, DataPropertyDescriptor>();
		const skipped: Option[] = [];

		Object.values(this.dataModelEntryService.formDefinitionReferences)
		// TODO Remove _parent from the formDefinitionReferences list
			.filter((f) => f.identifier !== FormDefinitionMetadataIdentifiers.Parent as string)
			.filter((i) => !properties || properties.has(i.identifier))
			.map((ref) => dataPropertyInfoToDataPropertyDescriptor(ref, this.adapterDataType, this.translate))
			.forEach((dpd) => schemaInfoReferences.set(dpd.identifier, dpd));

		// Requires LIST /forms permissions
		[
			FormDefinitionMetadataIdentifiers.DefinitionIdentifier,
			FormDefinitionMetadataIdentifiers.ParentDefinitionIdentifier,
			FormDefinitionMetadataIdentifiers.ParentBucket,
		].forEach((identifier) => {
			if (schemaInfoReferences.has(identifier) && this.permissions?.canLoadForms() === false) {
				skipped.push({ identifier, name: 'No permission for /forms' });
				schemaInfoReferences.delete(identifier);
			}
		});

		const entries = [...schemaInfoReferences.values()];

		// Get options for metadata fields
		for (const entry of entries) {
			const options = await this.getSchemaMetadataFieldOptions(entry, schema);

			if (options) {
				entry.options = options;
				entry.type = FieldType.Choice;
			}
		}

		return [entries, skipped];
	}

	private getSchemaMetadataFieldOptions = async(entry: DataPropertyDescriptor, schema: Schema): Promise<Option[] | undefined> => {

		if (entry.identifier === FormDefinitionMetadataIdentifiers.State as string) {
			// Map options for _state field
			const states = new Set<string>();

			for (const transition of schema.transitions) {
				states.add(transition.source);
				states.add(transition.target);
			}

			return Array.from(states.values()).map((state) => ({ identifier: state, name: state }));
		}

		if (entry.identifier === FormDefinitionMetadataIdentifiers.Result as string) {
			// Map options for _result field
			const results = new Set<string>();

			for (const transition of schema.transitions) {
				if (transition.result?.length) {
					results.add(transition.result);
				}
			}

			return Array.from(results.values()).map((result) => ({ identifier: result, name: result }));
		}

		if (entry.identifier === FormDefinitionMetadataIdentifiers.DefinitionIdentifier as string) {
			return [...(await this.loadForms())]
				.filter((d) => d.bucket === schema.bucket)
				.map((definition) => ({ identifier: definition.identifier, name: definition.label }));
		}

		if (entry.identifier === FormDefinitionMetadataIdentifiers.ParentDefinitionIdentifier as string) {
			return [...(await this.loadForms())]
				.map((definition) => ({ identifier: definition.identifier, name: definition.label }));
		}

		if (entry.identifier === FormDefinitionMetadataIdentifiers.ParentBucket as string) {
			return [...new Set(([...(await this.loadForms())].map((d) => d.bucket) as string[]))]
				.map((bucketId) => ({ identifier: bucketId, name: bucketId }));
		}

		return undefined;
	};

	/** Generate the list of DataPropertyDescriptor for the Schema root fields */
	private getSchemaRootFieldsDataPropertyDescriptors(schema: Schema, properties: Set<string> | undefined): DataPropertyDescriptor[] {
		return schema.fields
			.filter((field) => allowed(field.type))
			.filter((field) => !properties || [...properties].some((property) => property.split('.')[0] === field.identifier))
			.map((field) => schemaFieldToDataPropertyDescriptor(JSON.parse(JSON.stringify(field)) as SchemaField, this.adapterDataType));
	}

	private getHierarchySubEntries(entry: DataPropertyDescriptor): DataPropertyDescriptor[] {
		return [
			// Hierarchy Id
			{
				identifier: `${entry.identifier}.id`,
				type: FieldType.Text,
				label: 'Id',
			},
			// Hierarchy Label
			{
				identifier: `${entry.identifier}.label`,
				type: FieldType.Text,
				label: 'Label',
			}];
	}

	private getLinkSubEntries(entry: DataPropertyDescriptor): DataPropertyDescriptor[] {
		return [
			// Link Type
			{
				identifier: `${entry.identifier}.type`,
				type: FieldType.Choice,
				label: `${entry.label} ${this.translate.instant(CommonTranslationKey.ContentTypeTypeLabel)}`,
				options: [
					// { identifier: LinkContentType.Asset, name: this.translate.instant(CommonTranslationKey.ContentTypeAssetLabel) }, // TODO - Version 2
					{ identifier: LinkContentType.Attachment, name: this.translate.instant(CommonTranslationKey.ContentTypeAttachmentLabel) as string },
					{ identifier: LinkContentType.Form, name: this.translate.instant(CommonTranslationKey.ContentTypeFormLabel) as string },
					{ identifier: LinkContentType.Page, name: this.translate.instant(CommonTranslationKey.ContentTypePageLabel) as string },
					{ identifier: LinkContentType.Url, name: this.translate.instant(CommonTranslationKey.ContentTypeUrlLabel) as string },
				],
				asDisplay: false,
				asSort: false,
				asStaticFilter: false,
				asInputFilter: true,
			},
		];
	}

	private getStepSubEntries(entry: DataPropertyDescriptor): DataPropertyDescriptor[] {
		return [
			{
				identifier: `${entry.identifier}.${StepFieldMetadataIdentifiers.LastViewedAt}`,
				type: FieldType.DateTime,
				label: this.translate.instant(CommonTranslationKey.StepTypeLastViewedAtLabel),
				asDisplay: false,
				asSort: false,
				asInputFilter: false,
				asStaticFilter: false,
				asSearch: false,
			},
			{
				identifier: `${entry.identifier}.${StepFieldMetadataIdentifiers.LastCompletedAt}`,
				type: FieldType.DateTime,
				label: this.translate.instant(CommonTranslationKey.StepTypeLastCompletedAtLabel),
				asDisplay: false,
				asSort: false,
				asInputFilter: false,
				asStaticFilter: false,
				asSearch: false,
			},
		];
	}

	private getClaimSubEntries(claim?: ClaimConfig): DataPropertyDescriptor[] {
		return !claim ? [] : [{
			identifier: 'id',
			label: claim.label,
			type: claim.valueType,
			options: claim.options?.map((option) => ({ identifier: option.id, name: option.display })) ?? [],
		}, {
			identifier: 'display',
			label: claim.label,
			type: claim.valueType,
			options: claim.options?.map((option) => ({ identifier: option.id, name: option.display })) ?? [],
		}];
	}

	private loadSchema(id: string): Promise<Schema | undefined> {

		const existing = this.cache.schemas.get(id);

		if (existing) {
			return existing;
		}

		const loader = this.loader.loadSchema(id);

		this.cache.schemas.set(id, loader);

		return loader;
	}

	private loadForms(): Promise<{ identifier: string; label: string; bucket?: string }[]> {

		if (this.cache.formsInfo) {
			return this.cache.formsInfo;
		}

		this.cache.formsInfo = this.loader.loadForms();

		return this.cache.formsInfo;
	}

	private async loadUserClaimById(id: string): Promise<ClaimConfig | undefined> {

		let loader = this.cache.userClaims;

		if (!loader) {
			loader = this.loader.loadUserClaims();
			this.cache.userClaims = loader;
		}

		const claims = await loader;

		return claims.find((c) => c.id === id);
	}

}
