<div class="grow">
	<p class="label"><b>{{timeLabels.hour}}</b></p>
	<div class="row">
		<button (click)="updateHours('-')" class="uf-action tertiary small" type="button">
			<uf-icon name="arrowBackSolid" />
		</button>
		<div class="title grow">
			<label class="value">{{ hours.display }}</label>
			<select #hourSelect (change)="changeHour($event)">
				<option *ngFor="let hourOption of hoursSelect; let i = index" [selected]="hours.display === hourOption"
					[value]="hourOption">
					{{hourOption}}
				</option>
			</select>
		</div>
		<button (click)="updateHours('+')" class="uf-action tertiary small" type="button">
			<uf-icon name="arrowForwardSolid" />
		</button>
	</div>
</div>
<div class="grow">
	<p class="label"><b>{{timeLabels.minute}}</b></p>
	<div class="row">
		<button (click)="updateMinutes('-');" class="uf-action tertiary small" type="button">
			<uf-icon name="arrowBackSolid" />
		</button>
		<div class="title grow">
			<label class="value">{{ minutes.display }}</label>
			<select #minuteSelect (change)="changeMinute($event)">
				<option *ngFor="let minuteOption of minutesSelect; let i = index"
					[selected]="minutes.display === minuteOption" [value]="minuteOption">
					{{minuteOption}}
				</option>
			</select>
		</div>
		<button (click)="updateMinutes('+');" class="uf-action tertiary small" type="button">
			<uf-icon name="arrowForwardSolid" />
		</button>
	</div>
</div>

<div *ngIf="!is24hours" class="pad-left row">
	<div class="uf-button-group x-small tertiary bottom">
		<button [ngClass]="{primary: ampmValue !== '1'}" (click)="updateAmPm('0'); updateValue();" type="button"
			class="uf-button"> AM
		</button>
		<button [ngClass]="{primary: ampmValue === '1'}" (click)="updateAmPm('1'); updateValue();" type="button"
			class="uf-button"> PM
		</button>
	</div>

</div>