// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	title: 'Library',
	version: '1.0.0',
	env: 'localhost',
	translationsUrl: 'https://l10n.dev.unifii.net',
	unifii: {
		baseUrl: 'https://dev.unifii.net',
		appId: 'au.com.unifii.unifii.showcase',
		appSecret: '024904b0-f123-47c0-9a1d-433708b4aa99',
		version: 'x.y.z',
		platform: 'web',
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
