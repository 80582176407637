import { Component, ViewChild } from '@angular/core';

import { ToastService, UfSearchComponent } from '@unifii/library/common';

@Component({
	selector: 'sc-show-search',
	templateUrl: './show-search.html',
})
export class ShowSearchComponent {

	@ViewChild('searchInput') searchInput: UfSearchComponent;

	search: string | null;

	constructor(public toast: ToastService) {
	}

	onSearch(q?: string | null) {
		console.log('Search for =>', q);
		this.toast.info(q ?? `"NULL"`);
	}

	set() {
		this.search = 'something';
	}

	setNull() {
		this.search = null;
	}

	clear() {
		this.searchInput.clear();
	}

}
