import { Component } from '@angular/core';

/**
 * Simple component for separating fields that would fall side by side
 * This component to make its easier for style to be appled
 * can be removed when we have a better of providing global styles attributes to form
 */
@Component({
	selector: 'uf-break-after',
	template: `<div></div>`,
	styles: [`
		:host {
			display: block;
			min-width: 100%;
		}
	`],
})
export class BreakAfterComponent {
}
