import { InjectionToken } from '@angular/core';

import { TablePreferences } from './table-models';

export interface TablePreferencesProvider {

	/** Provide the TablePreferences for the id specific table */
	loadTablePreferences: (id: string) => TablePreferences | null;

	/** Save the TablePreferences for the id specific table */
	saveTablePreferences: (id: string, value?: TablePreferences) => void;

}

export const TablePreferencesProvider = new InjectionToken<TablePreferencesProvider>('TablePreferencesProvider');
