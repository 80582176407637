import { Component, HostBinding, inject } from '@angular/core';

import { Modal, ModalData, ModalRuntime, SharedTermsTranslationKey } from '@unifii/library/common';
import { WorkflowAction } from '@unifii/library/smart-forms';

export interface WorkflowActionsData {
	cancelClickLabel?: string;
	actions: WorkflowAction[];
}

@Component({
	selector: 'uf-workflow-actions',
	templateUrl: './workflow-actions-modal.html',
	styleUrls: ['./workflow-actions-modal.less'],
})
export class WorkflowActionsModalComponent implements Modal<WorkflowActionsData, WorkflowAction> {

	@HostBinding('class.uf-form-card') ufFormCard = true;

	runtime = inject(ModalRuntime<WorkflowActionsData, WorkflowAction>);
	data = inject(ModalData) as WorkflowActionsData;

	protected readonly sharedTermsTK = SharedTermsTranslationKey;

}
