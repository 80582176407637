<sc-header (toggleMenu)="toggleMenu()" class="uf-app-bar">
	<ng-container *ngIf="repository.user">
		<button (click)="showAppDetails($event)" type="button" class="uf-action tertiary">
			<uf-icon name="settings" />
		</button>
		<div (click)="showUserDetails($event)" class="uf-avatar-btn info">{{userInitial}}</div>
	</ng-container>
</sc-header>
<div class="content">
	<router-outlet />
</div>
<div (click)="menuOpen = false" class="overlay"></div>
<sc-menu (toggleMenu)="toggleMenu()" class="menu" img="assets/img/unifii-logo-colour-reverse.png" />