import { Component, DestroyRef, ElementRef, HostListener, Injector, Input, OnInit, ViewChild, ViewContainerRef, inject } from '@angular/core';

import { WindowWrapper } from '../../native';
import { DOMEventHandler, DeviceInfo, ModalService } from '../../services';

import { ModalRuntime } from './modal-runtime';
import { ModalConfig, ModalData } from './modal-types';
import { PositionManager } from './position-manager';

@Component({
	selector: 'uf-modal-anchored-render',
	templateUrl: './modal-anchored-render.html',
	styleUrls: ['./modal-anchored-render.less'],
})
export class ModalAnchoredRenderComponent<Data, Result> implements OnInit {

	@ViewChild('anchor', { read: ViewContainerRef, static: true }) viewContainerRef: ViewContainerRef;

	@Input({ required: true }) config: ModalConfig<Data, Result>;

	protected showBackground: boolean;

	private insideClickEvent: MouseEvent;
	private openEvent: MouseEvent | FocusEvent;
	private originElement?: HTMLElement;
	private positionManager: PositionManager;

	private window = inject(WindowWrapper) as Window;
	private domEventHandler = inject(DOMEventHandler);
	private destroy = inject(DestroyRef);
	private deviceInfo = inject(DeviceInfo);
	private modalService = inject(ModalService);
	private element = inject(ElementRef) as ElementRef<HTMLElement>;

	ngOnInit() {

		const providers = [
			{ provide: ModalRuntime, useValue: new ModalRuntime(this.modalService, this.config) },
			{ provide: ModalData, useValue: this.config.data },
			...(this.config.providers ?? []),
		];

		const injector = Injector.create({ providers, parent: this.config.injector ?? this.viewContainerRef.injector });
		const component = this.viewContainerRef.createComponent(this.config.type, { index: 0, injector });

		// Element must be given a position otherwise the fixed bg element will overlay it
		(component.location.nativeElement as HTMLElement).setAttribute(...['style', 'position:relative;']);
		component.changeDetectorRef.detectChanges();

		this.openEvent = this.config.event as MouseEvent | FocusEvent;
		this.showBackground = this.openEvent instanceof MouseEvent;
		this.originElement = this.config.position ? this.config.position.target : undefined;

		const popover = this.element.nativeElement.querySelector<HTMLElement>(':not(div[data-modal-element]') ?? undefined;

		this.positionManager = new PositionManager(
			this.window,
			this.deviceInfo,
			this.domEventHandler,
			this.destroy,
			this.originElement!,
			this.element.nativeElement,
			this.config.position,
			popover,
		);
		this.positionManager.apply();
	}

	@HostListener('document:click', ['$event'])
	documentClick(globalEvent: MouseEvent) {

		if (this.openEvent === globalEvent || this.insideClickEvent === globalEvent) {
			return;
		}
		/**
		 * Guard against:
		 *  - Focus event conflicting with click event
		 *  - Click event happening prior to focus event (can happen on programmatic focus)
		 *  - Fast click prevent from closing if clicks
		 */
		if (this.openEvent instanceof FocusEvent && (this.openEvent.target === globalEvent.target || (globalEvent.timeStamp - this.openEvent.timeStamp) < 100)) {
			return;
		}

		this.modalService.close(this.config);
	}

	@HostListener('click', ['$event']) // only needed in IE10 that doesn't support CSS pointer-events
	insideClick(e: MouseEvent) {

		this.insideClickEvent = e;

		if (e.target === this.element.nativeElement) {
			this.modalService.closeAll();
		}
	}

	@HostListener('document:keydown', ['$event'])
	keydown(e: KeyboardEvent) {
		e.stopPropagation();

		if (e.keyCode === 27) {
			this.close();
		}
	}

	@HostListener('document:focusin', ['$event'])
	focusin(globalFocus: FocusEvent) {

		if (globalFocus.target === this.openEvent.target) {
			return;
		}

		if (this.isDescendant(this.element.nativeElement, globalFocus.target as HTMLElement)) {
			return;
		}

		this.close();
	}

	@HostListener('window:keyboardDidShow', ['$event'])
	keyboardShown() {
		this.positionManager.apply();
	}

	@HostListener('window:keyboardDidHide', ['$event'])
	keyboardHidden() {
		this.close();
	}

	focus() {
		this.element.nativeElement.focus();
	}

	close() {
		this.modalService.close(this.config);
	}

	private isDescendant(parent: HTMLElement, child: HTMLElement) {
		let node = child.parentNode;

		while (node != null) {
			if (node === parent) {
				return true;
			}
			node = node.parentNode;
		}

		return false;
	}

}
