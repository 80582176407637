import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DataType } from '@unifii/sdk';
import { format, parse } from 'date-fns';
import { Subscription } from 'rxjs';

import { DateTimePickerData } from '../../../models';
import { CommonTranslationKey, SharedTermsTranslationKey } from '../../../translations';
import { modelFormatByType } from '../../../utils';
import { Modal, ModalData, ModalRuntime } from '../../modal';

@Component({
	templateUrl: './datetime-picker.html',
	styleUrls: ['../uf-input.less', './datetime-picker.less'],
})
export class DatetimePickerComponent implements Modal<DateTimePickerData, string | undefined>, OnInit, OnDestroy {

	protected readonly commonTranslationKey = CommonTranslationKey;
	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly typeDate = DataType.Date;
	protected readonly typeTime = DataType.Time;
	protected readonly typeClass: string;
	protected day: number;
	protected month: number;
	protected year: number;
	protected hours: number;
	protected minutes: number;
	protected errorMessage: string | null | undefined;

	private readonly modelFormat: string;
	private _date: Date = new Date();
	private subscriptions = new Subscription();

	constructor(
		public runtime: ModalRuntime<DateTimePickerData, string | undefined>,
		@Inject(ModalData) public data: DateTimePickerData,
	) {
		this.modelFormat = modelFormatByType(this.data.type);
		switch (this.data.type) {
			case DataType.Date:
				this.typeClass = 'date';
				break;
			case DataType.Time:
				this.typeClass = 'time';
				break;
			case DataType.DateTime:
				this.typeClass = 'datetime';
				break;
		}
	}

	ngOnInit() {
		if (this.data.valueSubject) {
			this.subscriptions.add(this.data.valueSubject.subscribe((value) => {
				this.setup(value);
			}));
		}

		if (this.data.errorSubject) {
			this.subscriptions.add(this.data.errorSubject.subscribe((errorMessage) => { this.errorMessage = errorMessage; }));
		}

		this.setup(this.data.value);
		this.errorMessage = this.data.errorMessage;
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	get date() {
		return this._date;
	}

	set date(date: Date) {
		this.runtime.update(format(date, this.modelFormat));

		this._date = date;
	}

	now() {
		this.date = new Date();
		this.setup(format(this.date, this.modelFormat));
	}

	done() {
		this.runtime.close(format(this.date, this.modelFormat));
	}

	clear() {
		this.runtime.update(undefined);
		this.runtime.close();
	}

	private setup(data?: string | null) {

		if (!data) {
			return;
		}

		const date = parse(data, this.modelFormat, new Date());

		if (!isNaN(date.getTime())) {
			this._date = date;
		}

		this.month = this.date.getMonth();
		this.year = this.date.getFullYear();

		this.hours = this.date.getHours();
		this.minutes = this.date.getMinutes();
	}

}
