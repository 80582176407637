import { Injectable, inject } from '@angular/core';
import { PublishedContent } from '@unifii/sdk';

import { CollectionDataDescriptorAdapterLoader } from './collection-data-descriptor-adapter';

@Injectable()
export class UfCollectionDataDescriptorAdapterLoader implements CollectionDataDescriptorAdapterLoader {

	// Allows DDE to work in an injection context without Content (user-provisioning)
	private content = inject(PublishedContent, { optional: true });

	loadCollectionDefinition(identifier: string) {
		if (!this.content) {
			throw new Error('UfCollectionDataDescriptorAdapterLoader.loadCollectionDefinition: PublishedContent not provided!');

		}

		return this.content.getCollectionDefinition(identifier);
	}

}
