import { Pipe, PipeTransform } from '@angular/core';
import { FieldType } from '@unifii/sdk';

import { RuntimeField } from '@unifii/library/common';
import { FieldHelperFunctions } from '@unifii/library/smart-forms';

/**
 * Filter fields that match the type and has no role specified or one of its role is contained in the requested roles list
*/
@Pipe({ name: 'fieldFilter' })
export class FieldFilterPipe implements PipeTransform {
	
	transform(fields: RuntimeField[], type?: FieldType, roles?: string[]): RuntimeField[] {
		return fields.filter((field) =>
			(!type || field.type === type) && FieldHelperFunctions.areRolesMatching(roles, field.roles),
		);
	}

}
