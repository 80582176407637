import { Component, QueryList, ViewChildren } from '@angular/core';

import * as Animations from '../../constants';
// Break circular dependency
import { ModalService } from '../../services/modal.service';

import { ModalCenteredRenderComponent } from './modal-centered-render.component';
import { ModalConfig, ModalDisplayType } from './modal-types';

@Component({
	selector: 'uf-modal-container',
	templateUrl: './modal-container.html',
	styleUrls: ['./modal-container.less'],
	animations: [Animations.fadeInOut],
})
export class ModalContainerComponent {

	@ViewChildren(ModalCenteredRenderComponent) children: QueryList<ModalCenteredRenderComponent<any, any> | undefined>;

	configs: ModalConfig<any, any>[] = [];

	constructor(service: ModalService) {
		service.registerContainer(this);
	}

	push(config: ModalConfig<any, any>) {
		this.configs.push(config);
	}

	pop(): ModalConfig<any, any> | undefined {
		return this.configs.pop();
	}

	remove(config: ModalConfig<any, any>) {
		const i = this.configs.indexOf(config);

		if (i >= 0) {
			this.configs.splice(i, 1);
		}
	}

	focusLast() {
		this.children.last?.focus();
	}

	isAnchored(config: ModalConfig<any, any>) {
		return config.display === ModalDisplayType.Anchored;
	}

}
