import { ColumnDescriptor } from '@unifii/sdk';

import { isIdentifiersPathExpression } from '../../utils';

import { ColumnDisplayDescriptor, TableConfig, TableConfigColumn } from './table-models';

export const columnDescriptorToColumDisplayDescriptor = (column: ColumnDescriptor): ColumnDisplayDescriptor => ({
	name: column.identifier,
	defaultCellValue: column.defaultCellValue,
	defaultTemplate: column.defaultTemplate,
	variations: column.variations,
	component: undefined, // extension logic configurable on need
});

/** Determine if the column is sortable */
export const isSortableColumn = (descriptor: ColumnDisplayDescriptor): boolean =>
	isIdentifiersPathExpression(descriptor.name) &&
	!descriptor.variations?.length &&
	!descriptor.defaultCellValue &&
	!descriptor.component;

/** Determine if the column need the custom render logic */
export const isCustomColumn = (descriptor: ColumnDisplayDescriptor): boolean =>
	!isSortableColumn(descriptor) ||
	!!descriptor.defaultTemplate;

/** Transform the columns to ColumnDisplayDescriptor and filter the custom ones */
export const getTableCustomColumnsDisplayDescriptors = (columns: ColumnDescriptor[] | undefined): ColumnDisplayDescriptor[] =>
	columns?.map(columnDescriptorToColumDisplayDescriptor).filter(isCustomColumn) ?? [];

/**
 * @description
 * utility function for creating table config with useful defaults
 */
export const getDefaultTableConfig = <T>(columns: TableConfigColumn<T>[], id?: string): TableConfig<T> => {
	const config: TableConfig<T> = {
		columns,
		pageSize: 100,
		cardExpands: true,
		columnToggles: true,
	};

	if (id) {
		config.id = id;
	}

	return config;
};
