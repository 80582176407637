import { Component, EventEmitter, Input, OnChanges, Output, SkipSelf, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isDataSeed } from '@unifii/sdk';

import { ContextProvider, DataDisplayComponentRegistry, DataDisplayService, RuntimeField, Scope, SharedTermsTranslationKey, SourceConfigMapping, UfSourceConfigMappingsPipe } from '@unifii/library/common';
import { FormService, ScopeManager, ValidatorBuilder } from '@unifii/library/smart-forms';

import { InputTranslationKey } from '../../../translations';

import { getDataSeedVisibleItems, getDataSeedVisibleLabels } from './repeat-functions';
import { dataDisplayRepeatTableComponentRegistryFactory } from './uf-data-display-repeat-table-component-registry';

@Component({
	selector: 'uf-repeat-columns',
	templateUrl: './repeat-columns.html',
	styleUrls: ['./repeat-columns.less'],
	providers: [{
		provide: DataDisplayComponentRegistry,
		useFactory: dataDisplayRepeatTableComponentRegistryFactory,
		deps: [
			[new SkipSelf(), DataDisplayComponentRegistry],
		],
	}],
})
export class RepeatColumnsComponent implements OnChanges {

	@Input({ required: true }) field: RuntimeField;
	@Output() columnClick = new EventEmitter<number>();
	@Output() remove = new EventEmitter<number>();

	protected readonly inputTK = InputTranslationKey;
	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected formService = inject(FormService);
	protected optionalSuffix = this.formService.definitionSettings.optionalSuffix;
	protected requiredSuffix = this.formService.definitionSettings.requiredSuffix;
	protected hover: boolean[] = [];
	protected scopeManagers: ScopeManager[] = [];
	protected dataSeedVisibleLabels: SourceConfigMapping[];
	protected dataSeedsVisibleItems: Record<string, unknown[]>[] = [];

	private validatorBuilder = inject(ValidatorBuilder);
	private contextProvider = inject(ContextProvider);
	private translate = inject(TranslateService);
	private scopeManager = inject(ScopeManager);
	private dataDisplayService = inject(DataDisplayService);
	private sourceConfigMappingsPipe = inject(UfSourceConfigMappingsPipe);
	private _items: Scope[] = [];	

	protected get disabled(): boolean {
		return !!this.scopeManager.getControl(this.field)?.disabled;
	}

	@Input() set items(v: Scope[] | null) {
		if (v == null) {
			return;
		}

		for (const manager of this.scopeManagers) {
			manager.ngOnDestroy();
		}
		this.scopeManagers = v.map((scope) => this.createScopeManager(scope));
		this._items = v;
	}

	get items(): Scope[] {
		return this._items;
	}

	ngOnChanges() {
		this.dataSeedVisibleLabels = this.field.sourceConfig ? getDataSeedVisibleLabels(this.field.sourceConfig, this.sourceConfigMappingsPipe) : [];
		this.dataSeedsVisibleItems = this.getDataSeedsVisibleItems();
	}

	protected isVisible(child: RuntimeField): boolean {
		return !!child.identifier && this.formService.isGranted(child);
	}

	protected clicked(i: number) {
		this.columnClick.emit(i);
	}

	protected removeAt(i: number) {
		this.scopeManagers[i]?.ngOnDestroy();

		this.scopeManagers.splice(i, 1);
		this.remove.emit(i);
	}

	private createScopeManager(scope: Scope): ScopeManager {
		const manager = new ScopeManager(this.validatorBuilder, this.contextProvider, this.formService, this.translate);

		manager.init(this.field, this.scopeManager);
		manager.scope = scope;
		manager.control.patchValue(scope);

		return manager;
	}

	private getDataSeedsVisibleItems(): Record<string, unknown[]>[] {

		const sourceConfig = this.field.sourceConfig;
		
		if (!sourceConfig) {
			return [];
		}

		return this.items.map((item) => {
			if (!isDataSeed(item)) {
				return {};
			}

			return getDataSeedVisibleItems(item, sourceConfig, this.dataSeedVisibleLabels, this.dataDisplayService) ?? {};
		});
	}

}
