import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface MenuNode {
	name: string;
	urlSegments?: string[];
	notes?: string;
	public?: boolean;
	children?: MenuNode[];
	expanded?: boolean; // Default expanded state if it has children nodes
}

@Injectable({ providedIn: 'root' })
export class ShowcaseNavigationService implements OnDestroy {

	readonly nodes: MenuNode[] = [
		{
			name: 'About', urlSegments: ['/about'], public: true,
		},
		{
			name: 'Foundation', public: true, children: [
				{ name: 'Color Palette', urlSegments: ['/palette'], public: true },
				{ name: 'Spacing', urlSegments: ['/spacing'], public: true },
				{ name: 'Borders', urlSegments: ['/borders'], public: true },
				{ name: 'Shadows', urlSegments: ['/shadows'], public: true },
				{ name: 'Typography', urlSegments: ['/typography'], public: true },
				{ name: 'Body Copy', urlSegments: ['/body-copy'], public: true },
				{ name: 'Writing Style', urlSegments: ['/writing-style'], public: true },
				{ name: 'Device', urlSegments: ['/device'], public: true },
			],
		},
		{
			name: 'Components', urlSegments: ['/components'], expanded: false, public: true, children: [
				{
					name: 'Layout', expanded: false, public: true, children: [
						{ name: 'Container', urlSegments: ['/container'], public: true },
						{ name: 'Grid', urlSegments: ['/grid'], public: true },
						{ name: 'Panel', urlSegments: ['/panel'], public: true },
					],
				},
				{
					name: 'Inputs', expanded: false, public: true, children: [
						{ name: 'Buttons', urlSegments: ['/buttons'], public: true },
						{ name: 'Button Groups', urlSegments: ['/button-groups'], public: true },
						{ name: 'Cascade Selection', urlSegments: ['/cascade-selection'] },
						{ name: 'Hierarchy Cascade Selection', urlSegments: ['/hierarchy-cascade-selection'] },
						{ name: 'Barcode Scanner', urlSegments: ['/barcode-scanner'], public: true },
						{ name: 'Actions', urlSegments: ['/actions'], public: true },
						{ name: 'Checkboxes', urlSegments: ['/checkboxes'], public: true },
						{ name: 'Radio Buttons', urlSegments: ['/radio-buttons'], public: true },
						{ name: 'Selects', urlSegments: ['/selects'], public: true },
						{ name: 'Date', urlSegments: ['/date'], public: true },
						{ name: 'Time', urlSegments: ['/time'], public: true },
						{ name: 'Date / Time', urlSegments: ['/datetime'], public: true },
						{ name: 'Date / Time Timezone', urlSegments: ['/datetime-tz'], public: true },
						{ name: 'Sliders', urlSegments: ['/sliders'], public: true },
						{ name: 'Switches', urlSegments: ['/switches'], public: true },
						{ name: 'Text Fields', urlSegments: ['/text-fields'], public: true },
						{ name: 'Number Fields', urlSegments: ['/number-fields'], public: true },
						{ name: 'Secret', urlSegments: ['/secret'], public: true },
						{ name: 'Markdown Editor', urlSegments: ['/markdown-editor'], public: true },
					],
				},
				{
					name: 'Navigation', expanded: false, public: true, children: [
						{ name: 'Breadcrumbs', urlSegments: ['/breadcrumbs'], public: true },
						{ name: 'Drawers', urlSegments: ['/drawers'], public: true },
						{ name: 'Links', urlSegments: ['/links'], public: true },
						{ name: 'Steppers', urlSegments: ['/steppers'], public: true },
						{ name: 'Tabs', urlSegments: ['/tabs'], public: true },
						{ name: 'Carousel', urlSegments: ['/carousel'], public: true },
					],
				},
				{
					name: 'Surfaces', expanded: false, public: true, children: [
						{ name: 'App Bar', urlSegments: ['/app-bar'], public: true },
						{ name: 'Box', urlSegments: ['/box'], public: true },
						{ name: 'Cards', urlSegments: ['/cards'], public: true },
						{ name: 'Form Card', urlSegments: ['/form-cards'], public: true },
					],
				},
				{
					name: 'Feedback', expanded: false, public: true, children: [
						{ name: 'Modals', urlSegments: ['/modals'], public: true },
						{ name: 'Progress', urlSegments: ['/progress'], public: true },
						{ name: 'Toaster', urlSegments: ['/toaster'], public: true },
					],
				},
				{
					name: 'Data Display', expanded: false, public: true, children: [
						{ name: 'Avatars', urlSegments: ['/avatars'], public: true },
						{ name: 'Badges', urlSegments: ['/badges'], public: true },
						{ name: 'Chips', urlSegments: ['/chips'], public: true },
						{ name: 'Dividers', urlSegments: ['/dividers'], public: true },
						{ name: 'Expanders', urlSegments: ['/expanders'], public: true },
						{ name: 'Icons', urlSegments: ['/icons'], public: true },
						{ name: 'Images', urlSegments: ['/images'], public: true },
						{ name: 'Data Displays', urlSegments: ['/data-display-components'], public: true },
						{ name: 'Data Display List', urlSegments: ['/data-display-list'], public: true },
						{ name: 'Directory', urlSegments: ['./directory'], public: true },
						{ name: 'Lists', urlSegments: ['/lists'], public: true },
						{ name: 'Lozenges', urlSegments: ['/lozenges'], public: true },
						{ name: 'Messages', urlSegments: ['/messages'], public: true },
						{ name: 'Tables', urlSegments: ['/tables'], public: true },
					],
				},
			],
		},
		{
			name: 'Templates', public: true, children: [
				{ name: 'Form', urlSegments: ['/forms'], public: true },
				{ name: 'Content', urlSegments: ['/content-template'], public: true },
				{ name: 'Card list', urlSegments: ['/card-list'], public: true },
				{ name: 'Group', urlSegments: ['/group'], public: true },
			],
		},
		{
			name: 'Pages', public: true, children: [
				{ name: 'Dashboard Page', urlSegments: ['/dashboard-page'], public: true },
				{ name: 'Form Page', urlSegments: ['/form-page'], public: true },
				{ name: 'Tab Page', urlSegments: ['/tab-page'], public: true },
				{ name: 'Table Page', urlSegments: ['/table-page'], public: true },
			],
		},
		{
			name: 'Engine', public: true, children: [
				{ name: 'Data Descriptor', urlSegments: ['/data-descriptor'], public: true },
				{ name: 'DataType Detector', urlSegments: ['/data-type-detector'], public: true },
				{ name: 'Entity Display', urlSegments: ['/data-display-entity'], public: true },
				{ name: 'FormData Display', urlSegments: ['/form-data-display'], public: true },
				{ name: 'Table Display', urlSegments: ['/table-entity'], public: true },
				{ name: 'Table Detail Display', urlSegments: ['/table-item-detail'], public: true },
				{ name: 'Filters', urlSegments: ['/filters'], public: true },
			],
		},
		{
			name: 'Lab', urlSegments: ['/lab'], public: false, children: [
				{ name: 'Fields', urlSegments: ['/fields'], public: false },
				{
					name: 'Inputs', urlSegments: ['/inputs'], children: [
						{ name: 'Select', urlSegments: ['/select'] },
						{ name: 'Autocomplete', urlSegments: ['/autocomplete'] },
						{ name: 'Chips Autocomplete', urlSegments: ['/chips-autocomplete'] },
						{ name: 'Time', urlSegments: ['/time'] },
						{ name: 'Search', urlSegments: ['/search'] },
						{ name: 'Search Result', urlSegments: ['/search-result'] },
					],
				},
				{ name: 'Reactive Forms Lab', urlSegments: ['/reactive-forms'], public: false },
				{ name: 'Smart Form', urlSegments: ['/smart-form'], public: false },
				{ name: 'Form Inputs', urlSegments: ['/form-inputs'] },
				{ name: 'Survey Field', urlSegments: ['/survey'] },
				{ name: 'Smart Table', urlSegments: ['/smart-table'], public: false }, // Restore print button
				{ name: 'Content Viewer', urlSegments: ['/lab-content'], public: false },
				{
					name: 'Popups', urlSegments: ['/popups'], notes: 'Modal Dialogs & Toasts', public: false, children: [
						{ name: 'Modal Testing', urlSegments: ['/modal-testing'], public: false },
					],
				},
				{ name: 'Client', urlSegments: ['/client'] },
				{ name: 'Encoding Funcs', urlSegments: ['/encoding-funcs'] },
				{ name: 'Empty Test Page', urlSegments: ['/empty'], notes: 'Quick code test, do not commit yours!' },
				{ name: 'Showcase Template', urlSegments: ['/template'] },
			],
		},
	];

	private _activeNode: MenuNode | null;
	private parentNodes = new Map<MenuNode, MenuNode>();
	private url: string | null;
	private subscriptions = new Subscription();

	constructor(
		private router: Router,
	) {

		this.buildParentMap(this.nodes);

		this.subscriptions.add(this.router.events.pipe(filter((e) => e instanceof NavigationEnd || e instanceof NavigationStart))
			.subscribe((event) => {

				if (event instanceof NavigationStart) {
					this.activeNode = null;
				}

				if (event instanceof NavigationEnd) {
					this.url = event.url;
				}
			}),
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	getParent(node: MenuNode): MenuNode | null {
		return this.parentNodes.get(node) ?? null;
	}

	set activeNode(v: MenuNode | null) {
		this._activeNode = v;
	}

	get activeNode(): MenuNode | null {

		if (this._activeNode != null) {
			return this._activeNode;
		}

		for (const node of this.nodeIterator(this.nodes)) {
			const nodeUrl = (node.urlSegments ?? []).join('');

			if (nodeUrl === this.url) {
				this._activeNode = node;

				return node;
			}
		}

		return null;
	}

	*nodeIterator(nodes: MenuNode[]): Iterable<MenuNode> {

		for (const node of nodes) {

			if (node.children?.length) {
				yield *this.nodeIterator(node.children);
			}
			yield node;
		}
	}

	private buildParentMap(nodes: MenuNode[], parent?: MenuNode) {

		for (const node of nodes) {

			if (parent != null) {
				this.parentNodes.set(node, parent);
			}

			if (node.children?.length) {
				// Recursively call function for children
				this.buildParentMap(node.children, node);
			}
		}
	}

}
