import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CostModelFormat } from '@unifii/sdk';

import { UfControl } from '../../controls';

import { UfControlValueAccessor } from './uf-control-value-accessor';

/** When add other currencies extends FieldDisplayPipe too */
@Component({
	selector: 'uf-cost',
	templateUrl: './uf-cost.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfCostComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-cost.less'],
})
export class UfCostComponent extends UfControlValueAccessor<CostModelFormat> implements OnInit {

	@Input() name: string;
	@Input() label?: string | null;
	@Input() placeholder?: string | null;
	@Input() suffix = '$';
	@Input() currency = 'AUD'; /** Defaulted to Australian */
	@Output() override valueChange = new EventEmitter<CostModelFormat>();

	/** inputControl different format to model control */
	protected inputControl = new UfControl();
	protected focused: boolean;

	ngOnInit() {
		this.subscriptions.add(this.inputControl.valueChanges.subscribe((v) => {
			if (v && !/^(|\d)\d*\.?\d{0,2}$/.test(v)) {
				this.setInputValue(null);

				return;
			}

			this.setControlValue(v);
		}));
	}

	@Input() override set value(v: CostModelFormat | null | undefined) {
		if (v && !this.patternUtil.isObject(v)) {
			v = undefined;
		}

		super.value = v;
		this.setInputValue(v);
	}

	@HostBinding('class.focused') get focusedClass() {
		return this.focused && !this.disabled;
	}

	@HostBinding('class.error') get errorClass() {
		return this.control.showError && !this.disabled;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	@HostBinding('class.value') get valueClass() {
		return !!this.value;
	}

	override valueEmitPredicate(value?: CostModelFormat | null, prev?: CostModelFormat | null): boolean {
		if (!value) {
			this.setInputValue(undefined);
		} else {
			const amount = `${value.amount}`;

			if (!this.equalAmounts(amount, this.inputControl.value)) {
				this.setInputValue(value);
			}
		}

		return super.valueEmitPredicate(value, prev);
	}

	protected onFocusChange(focused: boolean) {
		this.focused = focused;

		if (!focused) {
			this.control.markAsTouched();
		}
	}

	private setInputValue(modelValue: CostModelFormat | null | undefined) {
		const cents = this.getCents(modelValue);
		let dollars = cents ? '' + (+cents / 100) : undefined;

		if (this.value === modelValue && this.equalAmounts(cents, this.inputControl.value)) {
			return;
		}

		// Amend computed dollars value when model value match inputControl but formatted value doesn't
		// This to avoid that a copy-pasted value like 40.50 result in 40.5 in the inputControl
		if (dollars && this.inputControl.value !== dollars &&
			(+(this.inputControl.value as string)).toFixed(2) === (+dollars).toFixed(2)
		) {
			dollars = this.inputControl.value as string;
		}

		this.inputControl.setValue(dollars, { emitEvent: false });
		this.control.setValue(modelValue, { emitEvent: false });
	}

	private setControlValue(dollars: string) {
		const costValue = this.getModelValue(dollars, this.currency);

		this.control.markAsDirty();
		this.control.setValue(costValue);
	}

	private getCents(v: CostModelFormat | null | undefined): string | undefined {
		return v?.amount ? `${v.amount}` : undefined;
	}

	private getModelValue(dollars: string, currency: string): CostModelFormat | null {
		if (!dollars) {
			return null;
		}

		return {
			amount: +dollars * 100, /* cost value must always be cents */
			currency,
		};
	}

	private equalAmounts(cents = '', dollars = '') {
		return +cents === +dollars * 100;
	}

}
