import { Component, Input } from '@angular/core';

import { SharedTermsTranslationKey } from '../../translations';

export interface MessageAction {
	label: string;
	action: () => void;
	icon?: string;
	type?: 'primary' | 'secondary' | 'tertiary';
}

@Component({
	selector: 'uf-blockquote, uf-message',
	templateUrl: './message.html',
	styleUrls: ['./message.less'],
})
export class MessageComponent {

	@Input() icon?: string | null;
	@Input() content?: string | null;
	@Input() showExpander: boolean;
	@Input() actions: MessageAction[];

	readonly sharedTermsTK: SharedTermsTranslationKey;
	protected isExpanded: boolean;

	@Input() set collapsed(v: boolean) {

		if (v !== !!this.isExpanded) {
			return;
		}

		this.isExpanded = !v;
	}

	protected run(action: () => void) {
		action();
	}

	protected toggle() {
		this.isExpanded = !this.isExpanded;
	}

}
