<label *ngIf="label">{{label}}
	<ng-content></ng-content>
</label>
<div class="row">
	<div class="input-wrap">
		<div class="spacer">
			<pre>{{ control.value }}</pre>
		</div>
		<textarea #textarea [formControl]="control" [attr.name]="name" [attr.placeholder]="placeholder"
			[attr.maxlength]="maxLength ?? ''" [readonly]="disabled" (focus)="focused = true" (blur)="focused = false"
			(keydown)="$event.stopPropagation()"></textarea>

		<uf-error [control]="control" />
		<uf-underline *ngIf="!disabled" [class.active]="focused" />
	</div>
	<ng-content select="[actions]"></ng-content>
</div>