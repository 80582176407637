import { Component, Input, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DataDisplayComponentType } from '../../../models';
import { SharedTermsTranslationKey } from '../../../translations';

import { DataDisplayContentModalValue, isDataDisplayContentModalValue } from './data-display-components-model';

@Component({
	selector: 'uf-data-display-content-modal',
	templateUrl: './data-display-content-modal.html',
	styleUrls: ['../data-display.less'],
})
export class DataDisplayContentModalComponent implements DataDisplayComponentType, OnInit {
	
	@Input({ required: true }) data: DataDisplayContentModalValue;

	static isDataAccepted = isDataDisplayContentModalValue;
	
	protected linkLabel: string;
	
	private translateService = inject(TranslateService);

	ngOnInit() {
		this.linkLabel = this.data.label ?? this.translateService.instant(SharedTermsTranslationKey.ActionView);
	}
	
}
