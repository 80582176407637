import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { DataSeed } from '@unifii/sdk';

import { Modal, ModalData, ModalRuntime, RuntimeField, SharedTermsTranslationKey } from '@unifii/library/common';

export interface DataSeedsModalData {
	label: string;
	dataSeeds: DataSeed[];
	field: RuntimeField;
}

/**
 * @description
 * DataSeedsModalComponent a simple utility component
 * that renders DataSeeds as list buttons and returns the selected DataSeed
 */
@Component({
	selector: 'uf-data-seeds-modal',
	templateUrl: './data-seeds-modal.html',
})
export class DataSeedsModalComponent implements Modal<DataSeedsModalData, DataSeed>, OnInit {

	@HostBinding('class.uf-form-card') formCardClass = true;

	runtime = inject<ModalRuntime<DataSeedsModalData, DataSeed>>(ModalRuntime);
	data = inject<DataSeedsModalData>(ModalData);

	protected readonly sharedTermsTK = SharedTermsTranslationKey;

	ngOnInit() {
		if (!this.data.dataSeeds.length) {
			this.close();
		}
	}

	close() {
		this.runtime.close();
	}

	protected onSelect(dataSeed: DataSeed) {
		this.runtime.close(dataSeed);
	}

}
