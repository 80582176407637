export * from './address.component';
export * from './bool.component';
export * from './choice.component';
export * from './content.component';
export * from './content-link.component';
export * from './file-list.component';
export * from './geo-location.component';
export * from './hierarchy-unit-input.component';
export * from './image-list.component';
export * from './lookup.component';
export * from './multi-choice.component';
export * from './uf-input.component';

import { AddressComponent } from './address.component';
import { BoolComponent } from './bool.component';
import { ChoiceComponent } from './choice.component';
import { ContentLinkComponent } from './content-link.component';
import { FieldContentComponent } from './content.component';
import { FileListComponent } from './file-list.component';
import { GeoLocationComponent } from './geo-location.component';
import { HierarchyUnitInputComponent } from './hierarchy-unit-input.component';
import { ImageListComponent } from './image-list.component';
import { LookupComponent } from './lookup.component';
import { MultiChoiceComponent } from './multi-choice.component';
import { UfInputComponent } from './uf-input.component';

export const UF_INPUT_FIELD_INPUT_COMPONENTS = [
	AddressComponent,
	BoolComponent,
	ChoiceComponent,
	ContentLinkComponent,
	FieldContentComponent,
	FileListComponent,
	GeoLocationComponent,
	HierarchyUnitInputComponent,
	ImageListComponent,
	LookupComponent,
	MultiChoiceComponent,
	UfInputComponent,
];
