import { Injectable, inject } from '@angular/core';

import { StorageWrapper } from '../native';

/**
 * Repository use a StorageWrapper, handles string/non-string values and provides a caching layer
 */
@Injectable({ providedIn: 'root' })
export class Repository {

	private cache = new Map<string, any>();
	private storage = inject(StorageWrapper);

	store(key: string, value: any) {
		this.cache.set(key, value);

		if (value == null) {
			this.storage.removeItem(key);
		} else {
			this.storage.setItem(key, JSON.stringify(value));
		}
	}

	storeString(key: string, value: string | null) {
		this.cache.set(key, value);

		if (value == null) {
			this.storage.removeItem(key);
		} else {
			this.storage.setItem(key, value);
		}
	}

	load<T>(key: string): T | null {

		if (this.cache.get(key) === undefined) {

			const itemRaw = this.storage.getItem(key) as string | null;

			if (!itemRaw) {
				return null;
			}

			this.cache.set(key, JSON.parse(itemRaw) as T);
		}

		return this.cache.get(key) as T;
	}

	loadString(key: string): string | null {
		if (this.cache.get(key) === undefined) {
			this.cache.set(key, this.storage.getItem(key));
		}

		return this.cache.get(key) as string;
	}

	clear() {
		this.storage.clear();
		this.cache.clear();
	}

}
