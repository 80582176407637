import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { printSubscribers, setup, track } from 'observable-profiler';
import { Observable } from 'rxjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';

const isProduction = environment.env !== 'localhost';

if (isProduction) {
	enableProdMode();
} else {
	setup(Observable);
}

platformBrowserDynamic().bootstrapModule(AppModule)
	.then((ref) => {
		if (!isProduction) {
			
			console.info(`You can use "_trackSubs()" to track unhandled subscriptions`);
			
			// Start tracking observables
			track();

			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			(window as any)._trackSubs = () => {
				ref.destroy();
				// Stop tracking observable
				const subscribers = track(false);

				void printSubscribers({
					subscribers,
				});
			};
		}
	})
	.catch((err) => { console.error(err); });
