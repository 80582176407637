import { Component } from '@angular/core';

@Component({
	templateUrl: './show-barcode-scanner.html',
})
export class ShowBarcodeScannerComponent {

	public value: string;

}
