import { AstNode, NodeType, QueryOperators } from '@unifii/sdk';

/**
 * A static filter, based on its tree of conditions, can exclude some options for a specific filter identifier
 * This function return the intersection between the filter identifier full list of options and those left available by the static filter
 *
 * @param identifier of the visible filter
 * @param optionsIdentifier of the full list of available options
 * @param staticFilter to intersect options with
 * @returns options identifiers of the intersection between input options and static filter used options
 */
export const visibleAndStaticFilterOptionsIntersection = (identifier: string, optionsIdentifier: string[], staticFilter: AstNode): string[] => {
	if (staticFilter.type === NodeType.Operator && staticFilter.args && staticFilter.args.length === 2) {
		const identifierNode = staticFilter.args.find((n) => n.type === NodeType.Identifier && n.value === identifier);
		const valueNode = staticFilter.args.find((n) => n.type === NodeType.Value);

		if (identifierNode && valueNode) {
			switch (staticFilter.op) {
				case QueryOperators.In:
					return valueNode.value as string[];
				case QueryOperators.Equal:
					return [valueNode.value as string];
				case QueryOperators.NotEqual:
					return optionsIdentifier.filter((o) => o !== valueNode.value as string);
				default:
					return optionsIdentifier;
			}
		}
	}

	if (staticFilter.type === NodeType.Combinator && staticFilter.args && staticFilter.args.length > 0) {
		const valueSets = staticFilter.args.map((n) => [...new Set(visibleAndStaticFilterOptionsIntersection(identifier, optionsIdentifier, n))]);

		switch (staticFilter.op) {
			case QueryOperators.And:
				return valueSets.reduce((a, b) => a.filter((c) => b.includes(c)));
			case QueryOperators.Or:
				return [...new Set(valueSets.flat())];
			case QueryOperators.Not:
				return optionsIdentifier.filter((o) => !valueSets[0]?.includes(o));
		}
	}

	return optionsIdentifier;
};
