import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-app-bar.html',
})
export class ShowAppBarComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	// table
	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'uf-app-bar', type: 'base', description: 'Styles container element' },
		{ name: 'medium', type: 'modifier', description: 'Sets the size of container and actions to medium' },
		{ name: 'small', type: 'modifier', description: 'Sets the size of the container and actions to small' },
		{ name: 'accent', type: 'modifier', description: 'Sets the background to accents colour' },
		{ name: 'primary (Used on title element)', type: 'modifier', description: 'Sets title font to primary font family' },
	];

	readonly sizeOptions = [
		{ name: 'Large (Default)', value: '' },
		{ name: 'Medium', value: 'medium' },
		{ name: 'Small', value: 'small' },
	];
	readonly colourOptions = [
		{ name: 'Default', value: '' },
		{ name: 'Accent', value: 'accent' },
		{ name: 'Transparent', value: 'transparent' },
	];
	readonly depthOptions = [
		{ name: 'Raised (Default)', value: '' },
		{ name: 'Flat', value: 'flat' },
	];
	readonly titleModifierOptions = [
		{ name: 'Primary font', value: 'primary' },
	];

	size = '';
	colour = '';
	depth = '';
	titleModifiers = [];
	template: string;

	private readonly templateSource = `
		<div class="uf-app-bar #appBarCss">
			<button type="button" class="uf-action">
				<uf-icon name="hamburger"></uf-icon>
			</button>
			<div class="title #titleCss">
				Your title
			</div>
			<button type="button" class="uf-action">
				<uf-icon name="address"></uf-icon>
			</button>
			<button type="button" class="uf-action">
				<uf-icon name="contextual"></uf-icon>
			</button>
		</div>
	`;

	modifierChange() {

		const appBarCss = [this.size, this.colour, this.depth].filter((className) => className !== '').join('');

		this.template = this.templateSource.replace('#appBarCss', appBarCss).replace('#titleCss', this.titleModifiers.join(''));
	}

}
