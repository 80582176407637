import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

@Component({
	selector: 'sc-show-table-page',
	templateUrl: './show-table-page.html',
})
export class ShowTablePageComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

}
