<div [formGroup]="formGroup" [columns]="columns" [columnGap]="sizes.SM" ufGrid>
	<ng-container *ngFor="let option of options; let i = index">
		<uf-radio-input-wrapper [label]="option | nameProperty:nameProperty" [class.checked]="option === value"
			[class.disabled]="formGroup.disabled" [class.focused]="focused === i"
			[ngClass]="{ 'success': (option === value && option.value === true), 'error':  (option === value && option.value === false) }"
			[icon]="{ checked: option.value === true ? 'radioTick' : 'radioCross', unchecked: 'radioCircle' }">
			<input [formControlName]="groupName" [attr.name]="groupName" [value]="option" (focus)="focused = i"
				(blur)="focused = null" radioInput type="radio" />
		</uf-radio-input-wrapper>
	</ng-container>
</div>