export * from './modals';
export * from './modal';
export * from './modal-anchored-render.component';
export * from './modal-centered-render.component';
export * from './modal-container.component';
export * from './modal-runtime';
export * from './modal-types';
export * from './position-manager';

import { ModalAnchoredRenderComponent } from './modal-anchored-render.component';
import { ModalCenteredRenderComponent } from './modal-centered-render.component';
import { ModalContainerComponent } from './modal-container.component';
import { UF_MODAL_MODALS_COMPONENTS } from './modals';

export const UF_MODAL_COMPONENTS = [ModalContainerComponent, ModalCenteredRenderComponent, ModalAnchoredRenderComponent, UF_MODAL_MODALS_COMPONENTS];
