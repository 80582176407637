import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

@Component({
	selector: 'sc-show-content',
	templateUrl: './show-content.html',
})
export class ShowContentComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

}
