import { Type } from '@angular/core';
import { FieldType } from '@unifii/sdk';

import { ComponentRegistry, ComponentRegistryOptions, ComponentRegistryType, ContentField } from '@unifii/library/smart-forms';

// Break circular dependency
import * as display from '../components/fields';

export class UfPageComponentRegistry implements ComponentRegistry {

	type = ComponentRegistryType.Display;

	get(name: string, _options?: ComponentRegistryOptions): Type<ContentField> | null {

		switch (name as FieldType) {
			case FieldType.MultiText: return display.FieldContentComponent;
			case FieldType.ImageList: return display.ImageListDisplayComponent;
			case FieldType.FileList: return display.FileListDisplayComponent;
			case FieldType.SoundList: return display.SoundListComponent;
			case FieldType.VideoList: return display.VideoListComponent;
			case FieldType.LinkList: return display.LinkListComponent;

			case FieldType.Link:
			case FieldType.DefinitionLink:
				return display.LinkComponent;

			default: return null;
		}
	}

}
