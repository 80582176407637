<ng-template [ngIf]="mode === modes.Input">
	<uf-date *ngIf="entry?.type === filterTypes.Date" [(value)]="valueString" (valueChange)="valueChanged()" />
	<uf-datetime *ngIf="entry?.type === filterTypes.Datetime" [(value)]="valueString" (valueChange)="valueChanged()"
		defaultTime="00:00:00" />
	<uf-time *ngIf="entry?.type === filterTypes.Time" [(value)]="valueString" (valueChange)="valueChanged()" />
	<uf-datetime-tz *ngIf="entry?.type === filterTypes.ZonedDatetime" [(value)]="valueZDT"
		(valueChange)="valueChanged()" class="stacked" />
</ng-template>

<div *ngIf="mode === modes.Input && !isEmpty" class="uf-chip x-small">
	{{ label }}: {{ displayValue }}
	<button (click)="remove()" type="button" class="uf-action">
		<uf-icon name="delete" />
	</button>
</div>