import { InjectionToken } from '@angular/core';
import { Progress } from '@unifii/sdk';

export interface FileUploader {
	upload: (input: File, progressCallback?: (progress: Progress) => void, signal?: AbortSignal) => Promise<Progress>;
	getUrl: (id: string) => Promise<string>;
	remove?: (id: string) => Promise<void>;
}

export const FileUploader = new InjectionToken<FileUploader>('FileUploader');
