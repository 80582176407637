import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FilterEntry, FilterViewMode, IFilterComponent } from '../../../models';

@Component({
	selector: 'uf-filter-text-array',
	templateUrl: './filter-text-array.html',
	styleUrls: ['./field-filter.less'],
})
export class FilterTextArrayComponent implements IFilterComponent<string[]> {

	@Input() mode = FilterViewMode.Input;
	@Input({ required: true }) entry: FilterEntry;
	@Input({ required: true }) value: string[] | null;
	@Output() valueChange = new EventEmitter<string[] | null>();

	protected readonly modes = FilterViewMode;

	get label(): string {
		return this.entry.label;
	}

	get isEmpty(): boolean {
		return !this.value?.length;
	}

	protected remove(index: number) {
		if (!this.value) {
			return;
		}

		this.value.splice(index, 1);
		this.valueChanged();
	}

	protected valueChanged() {
		this.valueChange.emit(this.isEmpty ? null : [...this.value ?? []]);
	}

}
