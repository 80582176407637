import { Component, OnInit } from '@angular/core';

import { Breadcrumb, ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-breadcrumbs.html',
})
export class ShowBreadcrumbsComponent implements OnInit {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	// Table
	readonly propertiesColConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'default', name: 'Default' },
		{ identifier: 'description', name: 'Description' },
	];

	readonly propertiesRows = [
		{
			name: 'minimize',
			type: 'boolean',
			default: 'false',
			description: 'Show back button on mobile screen size',
		},
		{
			name: 'anchors',
			type: 'Breadcrumb[]',
			default: '[]',
			description: 'An array of data that will be displayed as anchor elements',
		},
	];

	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'large', type: 'modifier', description: 'Sets size of breadcrumb to large' },
		// { name: 'default', type: 'modifier', description: 'Sets size of breadcrumb to default' },
		{ name: 'small', type: 'modifier', description: 'Sets size of breadcrumb to small' },
		{ name: 'x-small', type: 'modifier', description: 'Sets size of breadcrumb to extra small' },
		{ name: 'light', type: 'modifier', description: 'Sets the font colour of breadcrumbs to white to work on dark backgrounds' },
	];

	// Demo
	readonly demoBreadcrumbs: Breadcrumb[] = [{
		name: 'About',
		urlSegments: ['/about'],
	},
	{
		name: 'Navigation',
		urlSegments: ['/navigation'],
	},
	{
		name: 'With matrix parameter',
		urlSegments: ['/navigation', { test: 'test' }],
	},
	{
		name: 'With query parameter',
		urlSegments: ['/navigation'],
		queryParams: { test: 'test' },
	},
	{
		name: 'Breadcrumbs',
	},
	];

	// Interactive
	readonly sizeOptions = [
		{ name: 'large', value: 'large' },
		{ name: 'default', value: '' },
		{ name: 'small', value: 'small' },
		{ name: 'x-small', value: 'x-small' },
	];
	readonly minimizeOptions = [
		{ name: 'On', value: 'on' },
		{ name: 'Off (default)', value: '' },
	];
	readonly colourOptions = [
		{ name: 'Default', value: '' },
		{ name: 'Light', value: 'light' },
	];

	size = '';
	minimize = '';
	colour = '';
	template: string;
	class = '';

	private templateSource = `
		<uf-breadcrumbs #class #minimize [breadcrumbs]="${this.demoBreadcrumbs.toString()}">
		</uf-breadcrumbs>
	`;

	ngOnInit() {
		this.modifierChange();
	}

	modifierChange() {
		this.class = [this.size, this.colour].join(' ').trim();
		this.template = this.templateSource
			.replace('#class', `${this.class !== '' ? 'class="' + this.class + '"' : ''}`)
			.replace('#minimize', `${this.minimize ? '[minimize]="true"' : ''}`);
	}

}
