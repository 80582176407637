import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

/**
 * @description
 * Utility class for accessing FormControls
 *
 * paths
 * . access child // TODO add []
 * [*] iterate over controls // TODO add [:1]
 * * return all elements
 * .. recursive decent
 */

enum Operator {
	Identifier, // Single result
	Recursive, // multi result
	Iterator, // multi result
	Wildcard, // multi result,
	Root, // Single result
	Parent, // Single result,
	Index
}

interface AccessorToken {
	operator: Operator;
	controlName?: string; // controlName | token string
	controlIndex?: number;
}

export class ControlAccessor {

	constructor(public control?: AbstractControl) { }

	get(path: string): AbstractControl[] {
		if (!this.control) {
			console.warn('starting control is undefined all results will be empty until this is set.');

			return [];
		}

		const tokens = this.tokenizePath(path);
		const baseControl = this.getBaseControl(tokens, this.control);

		if (tokens.length) {
			return this.getControls(0, tokens, baseControl).filter((c) => c !== this.control);
		}

		return [baseControl];
	}

	getPath(control?: AbstractControl): string | null {
		if (!control) {
			return null;
		}

		let parts: string[] = [];

		for (const part of this.pathIterator(control)) {
			parts = [part, ...parts];
		}

		if (!parts.length) {
			return null;
		}

		return `$${parts.join('')}`;
	}

	/**
	 * @description
	 * Splits path by operator symbols and sets controlName's
	 */
	private tokenizePath(path: string): AccessorToken[] {
		const parts = path.split(/(\$|\.\.\/|\.{2}|\.|\[\*\]*|\[\d*\]*)/)
		// remove '.' and empty values
			.filter((v) => (v && v !== '.'));

		return parts.reduce<AccessorToken[]>((tokens, part, i) => {
			const token: AccessorToken = {
				operator: this.getOperator(part),
			};

			if (token.operator === Operator.Recursive) {
				// get control name from next identifier and remove identifier from this list
				token.controlName = parts.splice(i + 1, 1)[0] ?? '';
			}

			if (token.operator === Operator.Identifier) {
				token.controlName = part;
			}

			if (token.operator === Operator.Index) {
				token.controlIndex = +part.replace(/\D/g, '');
			}

			return [...tokens, token];
		}, []);
	}

	private getBaseControl(tokens: AccessorToken[], control: AbstractControl): AbstractControl {
		const token = tokens[0];

		if (token?.operator === Operator.Root) {
			tokens.splice(0, 1);

			const parent = this.getRootControl(control);

			if (parent) {
				return parent;
			}
		}

		if (token?.operator === Operator.Parent) {
			tokens.splice(0, 1);

			const { parent } = control;

			if (parent) {
				return this.getBaseControl(tokens, parent);
			}
		}

		return control;
	}

	private getControls(index: number, tokens: AccessorToken[], parentControl: AbstractControl): AbstractControl[] {
		const { operator, controlName, controlIndex } = tokens[index] ?? { operator: Operator.Wildcard };
		const next = tokens[index + 1];

		if (operator === Operator.Recursive) {
			return this.getAll(parentControl, controlName);
		}

		if (operator === Operator.Wildcard) {
			if (next?.controlName) {
				return this.getGroupDescendants(index + 1, tokens, parentControl);
			}

			return this.getAll(parentControl);
		}

		if (operator === Operator.Iterator) {
			return this.getArrayDescendants(index + 1, tokens, parentControl);
		}

		let control: AbstractControl | undefined | null;

		if (controlIndex != null && parentControl instanceof FormArray) {
			control = parentControl.at(controlIndex);
		} else if (controlName && parentControl instanceof FormGroup) {
			control = parentControl.get(controlName);
		}

		if (control) {
			if (!next) {
				return [control];
			}

			return this.getControls(index + 1, tokens, control);
		}

		return [];
	}

	private getAll(rootControl: AbstractControl, controlName?: string): AbstractControl[] {
		const controls: AbstractControl[] = [];

		for (const { control, parent } of this.iterator(rootControl)) {
			if (controlName) {
				const c = parent?.get(controlName);

				if (parent == null || c !== control) {
					continue;
				}
			}
			controls.push(control);
		}

		return controls;
	}

	private getArrayDescendants(index: number, tokens: AccessorToken[], parentControl: AbstractControl): AbstractControl[] {
		if (!(parentControl instanceof FormArray)) {
			return [];
		}

		const controls: AbstractControl[] = [];

		for (const control of parentControl.controls) {
			const next = tokens[index];

			if (next) {
				const list = this.getControls(index, tokens, control);

				controls.push(...list);
			} else {
				controls.push(control);
			}
		}

		return controls;
	}

	/**
	 * @description
	 * returns all descendent controls from control groups
	 * this function is required for wildcard operators that are followed by more operators
	 * eg: property_1.*.property_2
	 */
	private getGroupDescendants(index: number, tokens: AccessorToken[], parentControl: AbstractControl): AbstractControl[] {
		if (!(parentControl instanceof FormGroup)) {
			return [];
		}

		const controlGroups = Object.keys(parentControl.controls)
			.map((k) => parentControl.get(k))
			.filter((c) => c instanceof FormGroup) as FormGroup[];

		const controls: AbstractControl[] = [];

		for (const c of controlGroups) {
			controls.push(...this.getControls(index, tokens, c));
		}

		return controls;
	}

	private getOperator(str: string): Operator {
		switch (str) {
			case '*': return Operator.Wildcard;
			case '[*]': return Operator.Iterator;
			case '..': return Operator.Recursive;
			case '$': return Operator.Root;
			case '../': return Operator.Parent;
		}
		if (/\[\d*\]/.test(str)) {
			return Operator.Index;
		}

		return Operator.Identifier;
	}

	/**
	 * @description
	 * iterate all controls from top down
	 */
	private *iterator(control: AbstractControl, parent?: FormArray | FormGroup): Iterable<{ control: AbstractControl; parent?: FormArray | FormGroup }> {
		yield { control, parent };

		if (control instanceof FormGroup) {
			const controls = control.controls;

			for (const key of Object.keys(controls)) {
				const child = controls[key];

				if (child) {
					yield *this.iterator(child, control);
				}
			}
		}

		if (control instanceof FormArray) {
			const controls = control.controls;

			for (const c of controls) {
				yield *this.iterator(c, control);
			}
		}
	}

	private getRootControl({ parent }: AbstractControl): FormArray | FormGroup | null {
		if (parent?.parent) {
			return this.getRootControl(parent);
		}

		return parent;
	}

	/**
	 * iterates upwards through the control tree and returns
	 * a path segment for each control
	 */
	private *pathIterator(control: AbstractControl): Iterable<string> {
		const { parent } = control;

		if (parent instanceof FormGroup) {
			for (const key of Object.keys(parent.controls)) {
				if (parent.get(key) === control) {
					yield `.${key}`;
				}
			}
		}

		if (parent instanceof FormArray) {
			const index = parent.controls.findIndex((c) => c === control);

			yield `[${index}]`;
		}

		if (parent?.parent) {
			yield *this.pathIterator(parent);
		}
	}

}

