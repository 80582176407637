import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { SelectMasterDirective } from '../../directives';
import { SharedTermsTranslationKey } from '../../translations';

@Component({
	selector: 'uf-select-cell',
	templateUrl: './select-cell.html',
	styleUrls: ['./select-cell.less'],
})
export class SelectCellComponent<T> implements OnInit, OnDestroy {

	@Input() item: T;
	@Input() index: number;
	@Input() isTableCell = true;

	readonly sharedTermsTK = SharedTermsTranslationKey;

	selected: boolean;

	constructor(private master: SelectMasterDirective<T>) { }

	ngOnInit() {
		this.master.register(this);
	}

	ngOnDestroy() {
		this.master.deselect([this.item]);
		this.master.deregister(this);
	}

	toggle(value: boolean) {

		if (this.item) {
			this.master.toggle(this.item);

			return;
		}

		if (value) {
			this.master.selectAll();
		} else {
			this.master.deselectAll();
		}
	}

	get disabled(): boolean {
		return this.item && !this.master.canSelect && !this.selected;
	}

}
