import { APP_BASE_HREF } from '@angular/common';
import { APP_INITIALIZER, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Client, ContentClient, Interceptor, ProjectContentOptions, PublishedContent, TenantClient, TokenStorage, TokenStorageInterface } from '@unifii/sdk';

import { ContextProvider, GoogleMaps, SessionStorageWrapper, StorageWrapper, UfMissingTranslationHandler, WindowWrapper, getGMaps, getSessionStorage, getStorage, getWindow } from '@unifii/library/common';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Config, Environment } from './models';
import { ShowcaseContextProvider, ShowcaseInterceptor, ShowcaseRepositoryService, ShowcaseTranslateLoader, initTranslations } from './services';
import { ShowCaseModule } from './showcase.module';

@NgModule({
	bootstrap: [AppComponent],
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (config: Config) => new ShowcaseTranslateLoader(config),
				deps: [Config],
			},
			missingTranslationHandler: {
				provide: MissingTranslationHandler,
				useClass: UfMissingTranslationHandler,
			},
		}),
		ShowCaseModule,
	],
	providers: [
		// Native
		{ provide: WindowWrapper, useFactory: getWindow, deps: [PLATFORM_ID] },
		{ provide: StorageWrapper, useFactory: getStorage, deps: [PLATFORM_ID] },
		{ provide: SessionStorageWrapper, useFactory: getSessionStorage, deps: [PLATFORM_ID] },
		{ provide: GoogleMaps, useFactory: getGMaps, deps: [PLATFORM_ID] },
		// Configs
		{ provide: Environment, useValue: environment },
		{ provide: Config, useFactory:
		(repository: ShowcaseRepositoryService, env: Config) => {
			const config = Object.assign({}, env);

			config.unifii.baseUrl = repository.baseUrl ?? config.unifii.baseUrl;

			return config;
		},
		deps: [ShowcaseRepositoryService, Environment] },
		// App
		{ provide: APP_BASE_HREF, useValue: '/' },
		{ provide: APP_INITIALIZER, useFactory: initTranslations, multi: true, deps: [TranslateService, ShowcaseRepositoryService] },
		{ provide: Interceptor, useClass: ShowcaseInterceptor },
		{ provide: ContextProvider, useClass: ShowcaseContextProvider },
		// SDK
		{ provide: ProjectContentOptions, useExisting: ShowcaseRepositoryService },
		{ provide: TokenStorage, useExisting: ShowcaseRepositoryService },
		{ provide: Client, useFactory:
		(config: Config, storage: TokenStorageInterface, interceptor: Interceptor) =>
			new Client(config.unifii, storage, interceptor),
		deps: [Config, TokenStorage, Interceptor] },
		{ provide: PublishedContent, useFactory:
		(client: Client, tenantClient: TenantClient, repository: ShowcaseRepositoryService) =>
			new ContentClient(client, tenantClient, repository),
		deps: [Client, TenantClient, ShowcaseRepositoryService] },
	],
})
export class AppModule { }
