import { Directive, ElementRef, HostListener, Input, inject } from '@angular/core';
import { AttachmentsClient, isStringNotEmpty } from '@unifii/sdk';

import { CordovaWindow } from '../models';
import { WindowWrapper } from '../native';
import { DeviceInfo } from '../services';

/**
 * Open the href native location or use 'attachmentLink' input as attachmentId to retrieve the downloadable url
 */
@Directive({ selector: '[attachmentLink]' })
export class AttachmentLinkDirective {

	/** Attachment id */
	@Input() attachmentLink: string | null | undefined;

	private window = inject(WindowWrapper) as Window;
	private deviceInfo = inject(DeviceInfo);
	private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
	private attachmentsClient = inject(AttachmentsClient, { optional: true });

	@HostListener('click', ['$event'])
	protected async onclick(e: PointerEvent) {
		e.preventDefault();
		e.stopPropagation();
		// Url must be generated on the fly as download token has an expiry
		const url = await this.getUrl();

		if (!url) {
			return;
		}
		
		if (!this.deviceInfo.isNative() && !this.deviceInfo.isIosMobile()) {
			/**
			 * New reference for window required for safari, firefox, android
			 * then location is applied after async call
			 */
			const windowRef = this.window.open();

			if (windowRef != null) {
				windowRef.location = url;
			}

			return;
		}

		/**
		 * Though Window.location is a read-only Location object, you can also assign a DOMString to it.
		 * This means that you can work with location as if it were a string in most cases: location = 'http://www.example.com' is a synonym of location.href = 'http://www.example.com'.
		 */
		if (this.deviceInfo.isNative()) {
			(this.window as unknown as CordovaWindow).cordova.InAppBrowser.open(url, '_system');
		} else {
			// must be mobile safari
			this.window.location.href = url;
		}
	}

	private getUrl(): Promise<string | undefined> {
		// Priority to hrefAttributeValue
		const hrefAttributeValue = this.elementRef.nativeElement.getAttribute('href');

		if (isStringNotEmpty(hrefAttributeValue)) {
			return Promise.resolve(hrefAttributeValue);
		}

		if (!this.attachmentLink) {
			return Promise.resolve(undefined);
		}

		if (!this.attachmentsClient) {
			console.warn('AttachmentsClient not provided');

			return Promise.resolve(undefined);
		}

		return this.attachmentsClient.getAttachmentUrl(this.attachmentLink);
	}

}
