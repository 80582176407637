<label *ngIf="label">{{label}}
	<ng-content></ng-content>
</label>
<div class="row">
	<div class="input-wrap grow">
		<input #input [attr.id]="id" [attr.name]="name" [formControl]="control"
			[attr.type]="reveal ? 'text' : 'password'" [attr.placeholder]="placeholder" [readonly]="disabled"
			[attr.autocomplete]="autocomplete" (focus)="focused = true" (blur)="focused = false; " />
		<uf-error [control]="control" />
		<uf-underline *ngIf="!disabled" [class.active]="focused" />
	</div>
	<div class="actions row">
		<button *ngIf="reveal" [title]="sharedTermsTK.ActionHide | translate" (click)="reveal = false" type="button"
			class="uf-action tertiary">
			<uf-icon name="viewHidden" />
		</button>
		<button *ngIf="!reveal" [title]="sharedTermsTK.ActionShow | translate" (click)="reveal = true" type="button"
			class="uf-action tertiary">
			<uf-icon name="view" />
		</button>
		<button *ngIf="!disabled" [title]="commonTS.CreatePasswordActionGeneratePassword | translate"
			(click)="generatePassword()" type="button" class="uf-action tertiary">
			<uf-icon name="generatePassword" />
		</button>
		<button [title]="sharedTermsTK.ActionCopy | translate" (click)="clipboard.setText(value)" type="button"
			class="uf-action tertiary">
			<uf-icon name="copy" />
		</button>
	</div>
</div>

<uf-message *ngIf="pwned" [content]="commonTS.CreatePasswordErrorPwnedPassword | translate"
	class="error small gap-md-top" icon="alert" />