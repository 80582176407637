import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

@Component({
	selector: 'sc-show-tab-page',
	templateUrl: './show-tab-page.html',
})
export class ShowTabPageComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

}
