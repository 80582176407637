import { ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Dictionary, FieldType, FieldValidator, ValidatorType } from '@unifii/sdk';

import { RuntimeField, SharedTermsTranslationKey, UfControl, UfControlArray, UfControlGroup, UfFormControl, ValidatorFunctions } from '@unifii/library/common';

import { ValidatorGroupType } from '../constants';
import { SmartFormsTranslationKey } from '../translations';

import { FormService } from './form.service';
import { ScopeManager } from './scope-manager';
import { ValidatorBuilder } from './validator-builder';

/**
 * ControlManger
 * This class handles all control and validator logic for the ScopeManager.
 * A new instance is created with every ScopeManager
 *
 * Responsible for:
 *  - Creating FormControls
 *  - Storing and retrieving controls
 *  - Storing and retrieving ValidatorFns
 */
export class ControlManager {

	private controls = new WeakMap<RuntimeField, UfFormControl>();
	private initialValidators = new WeakMap<UfFormControl, ValidatorFn | ValidatorFn[] | undefined>();
	private requiredExcludedValidators = new WeakMap<UfFormControl, ValidatorFn | ValidatorFn[] | undefined>();

	constructor(
		private scopeManager: ScopeManager,
		private validatorBuilder: ValidatorBuilder,
		private formService: FormService,
		private translate: TranslateService,
	) { }

	getValidatorFn(control: UfFormControl, type?: ValidatorGroupType): ValidatorFn | ValidatorFn[] | undefined {

		if (type === ValidatorGroupType.RequiredExcluded) {
			return this.requiredExcludedValidators.get(control);
		}

		return this.initialValidators.get(control);
	}

	// for variations field reference may change
	updateControl(field: RuntimeField, fieldValidators?: FieldValidator[]) {

		const control = this.controls.get(field);

		if (control == null) {
			return;
		}

		const { validators, requiredExcluded } = this.createValidatorFns(field, fieldValidators);

		this.initialValidators.set(control, validators);
		this.requiredExcludedValidators.set(control, requiredExcluded);

		const validatorFn = ValidatorFunctions.compose(validators);

		control.setValidators(validatorFn ?? null);
	}

	getControl(field: RuntimeField): UfFormControl | undefined {
		return this.controls.get(field);
	}

	addControl(field: RuntimeField): UfControl {

		const { validators, requiredExcluded } = this.createValidatorFns(field);
		const control = new UfControl(validators);

		this.initialValidators.set(control, validators);
		this.requiredExcludedValidators.set(control, requiredExcluded);
		this.controls.set(field, control);

		return control;
	}

	addControlGroup(field: RuntimeField, childControls: Dictionary<UfFormControl>): UfControlGroup {

		const { validators, requiredExcluded } = field.type !== FieldType.Repeat ? this.createValidatorFns(field) : this.createRepeatGroupValidatorFns(field);

		const validatorFn = ValidatorFunctions.compose(validators);
		const validatorFnRequiredExclude = ValidatorFunctions.compose(requiredExcluded);

		const control = new UfControlGroup(childControls, {}, validatorFn);

		this.initialValidators.set(control, validatorFn);
		this.requiredExcludedValidators.set(control, validatorFnRequiredExclude);
		this.controls.set(field, control);

		return control;
	}

	addControlArray(field: RuntimeField, childControls: UfFormControl[] = []): UfControlArray {

		const requiredExcluded = this.validatorBuilder.createValidators(field.validators
			.filter((v) => v.type !== ValidatorType.ItemExpression), this.scopeManager.createScope, this.scopeManager.createContext);
		const validators: ValidatorFn[] = [...requiredExcluded];

		if (field.isRequired) {
			const fieldValidator = {
				type: ValidatorType.Expression,
				value: '$self.length >= 1',
				message: this.translate.instant(SmartFormsTranslationKey.FormRepeatFieldErrorRequired) as string,
			};
			const requiredValidator = this.validatorBuilder.createValidators([fieldValidator], this.scopeManager.createScope, this.scopeManager.createContext);

			validators.push(...requiredValidator);
		}

		const validatorFn = ValidatorFunctions.compose(validators);
		const validatorFnRequiredExclude = ValidatorFunctions.compose(requiredExcluded);
		const control = new UfControlArray(childControls, {}, validatorFn);

		this.initialValidators.set(control, validatorFn);
		this.requiredExcludedValidators.set(control, validatorFnRequiredExclude);
		this.controls.set(field, control);

		return control;
	}

	private createValidatorFns(field: RuntimeField, validators?: FieldValidator[]): { validators: ValidatorFn[]; requiredExcluded: ValidatorFn[] } {

		if (!this.formService.checkFieldAndParentGranted(field) || field.type === FieldType.Stepper) {
			return { validators: [], requiredExcluded: [] };
		}

		const requiredExcluded = this.validatorBuilder.createValidators(validators ?? field.validators, this.scopeManager.createScope, this.scopeManager.createContext);
		const validatorFns: ValidatorFn[] = [...requiredExcluded];

		if (field.isRequired) {
			validatorFns.push(ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired) as string));
		}

		return {
			validators: validatorFns, requiredExcluded,
		};
	}

	private createRepeatGroupValidatorFns(field: RuntimeField): { validators: ValidatorFn[]; requiredExcluded: ValidatorFn[] } {

		if (!this.formService.isGranted(field) || field.isReadOnly) {
			return { validators: [], requiredExcluded: [] };
		}

		// only item expressions should be applied to repeat groups other validators are applied to ControlArray parent
		const validators = field.validators.filter((v) => v.type === ValidatorType.ItemExpression);
		const requiredExcluded = this.validatorBuilder.createValidators(validators, this.scopeManager.createScope, this.scopeManager.createContext);

		return { validators: requiredExcluded, requiredExcluded };
	}

}
