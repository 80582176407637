import { Type } from '@angular/core';
import { FieldType } from '@unifii/sdk';

import { ComponentRegistry, ComponentRegistryOptions, ComponentRegistryType, ContentField } from '@unifii/library/smart-forms';

// Break circular dependency
import * as display from '../components/fields';

export class UfViewComponentRegistry implements ComponentRegistry {

	type = ComponentRegistryType.Display;

	get(name: string, _options?: ComponentRegistryOptions): Type<ContentField> | null {

		switch (name as FieldType) {
			case FieldType.Text:
			case FieldType.Number:
			case FieldType.Date:
			case FieldType.Time:
			case FieldType.DateTime:
				return display.TextComponent;

			case FieldType.Link:
			case FieldType.DefinitionLink:
				return display.LinkComponent;

			case FieldType.MultiText: return display.MultiTextComponent;
			case FieldType.Phone: return display.PhoneComponent;
			case FieldType.Email: return display.EmailComponent;
			case FieldType.Website: return display.WebsiteComponent;
			case FieldType.ImageList: return display.ImageListDisplayComponent;
			case FieldType.FileList: return display.FileListDisplayComponent;
			case FieldType.SoundList: return display.SoundListComponent;
			case FieldType.VideoList: return display.VideoListComponent;
			case FieldType.LinkList: return display.LinkListComponent;
			case FieldType.Address: return display.AddressDisplayComponent;
			case FieldType.GeoLocation: return display.GeoLocationDisplayComponent;

			default: return null;
		}
	}

}

