import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataSourceType, RequestAnalytics, VisibleFilterDescriptor } from '@unifii/sdk';

import { DataPropertyDescriptor, FilterEntry } from '../../../models';
import { DataLoaderFactory, FilterEntryDataDescriptorAdapter, dataSourceTypeToDataDescriptorType } from '../../../services';

@Injectable({ providedIn: 'root' })
export class DataSourceFilterEntryAdapter {

	private filterEntryAdapter = inject(FilterEntryDataDescriptorAdapter);
	private translateService = inject(TranslateService);

	// eslint-disable-next-line better-max-params/better-max-params
	transform(visibleFilterDescriptor: VisibleFilterDescriptor, propertyDescriptors: Map<string, DataPropertyDescriptor>, sourceType: DataSourceType, dataLoaderFactory: DataLoaderFactory, requestAnalytics?: RequestAnalytics, searchMinLength?: number): FilterEntry | undefined {

		const descriptorType = dataSourceTypeToDataDescriptorType(sourceType);
		const descriptorProperty = propertyDescriptors.get(visibleFilterDescriptor.identifier);

		if (!descriptorType || !descriptorProperty) {
			return;
		}

		return this.filterEntryAdapter.transform({
			descriptorType,
			descriptorProperty,
			visibleFilterDescriptor,
			translateService: this.translateService,
			dataLoaderFactory,
			requestAnalytics,
			searchMinLength,
		});
	}

}
