import { Component, HostBinding, Injector } from '@angular/core';

import { Modal } from '../modal';
import { ModalRuntime } from '../modal-runtime';
import { ModalConfirmData, ModalData } from '../modal-types';

@Component({
	selector: 'uf-confirm-modal',
	templateUrl: './confirm-modal.html',
	styleUrls: ['./modal.less'],
})
export class ConfirmModalComponent implements Modal<ModalConfirmData, boolean> {

	@HostBinding('class.uf-form-card') class = true;

	runtime: ModalRuntime<ModalConfirmData, boolean>;
	data: ModalConfirmData;

	constructor(injector: Injector) {
		this.runtime = injector.get(ModalRuntime) as ModalRuntime<ModalConfirmData, boolean>;
		this.data = injector.get(ModalData) as ModalConfirmData;
	}

	close(value: boolean) {
		this.runtime.close(value);
	}

}
