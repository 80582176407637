import { InjectionToken, Type } from '@angular/core';

import { RuntimeField } from '@unifii/library/common';

import { ContentField, FormField } from './form-models';

export enum ComponentRegistryType {
	Display, Input
}

export interface ComponentRegistryOptions {
	tags?: string[];
}

export interface FormComponentRegistryOptions extends ComponentRegistryOptions {
	isActive?: boolean;
	isDataSourceMappingField?: boolean;
	parent?: RuntimeField;
}

export interface ComponentRegistry {
	type: ComponentRegistryType;
	get: (name: string, options?: ComponentRegistryOptions) => Type<ContentField | FormField> | null;
}

export const ComponentRegistry = new InjectionToken<ComponentRegistry>('ComponentRegistry');
