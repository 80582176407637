import { Component, Inject } from '@angular/core';

import { Modal, ModalData, ModalRuntime } from '../modal';

import { MenuOption } from './menu-model';

@Component({
	templateUrl: './menu-single-choice-list.html',
	styleUrls: ['./menu-single-choice-list.less'],
})
export class MenuSingleChoiceListComponent<O extends MenuOption> implements Modal<O[], O> {

	hasIcons: boolean;

	constructor(public runtime: ModalRuntime<O[], O>, @Inject(ModalData) public data: O[]) {

		this.data = data;
		this.hasIcons = this.data.find((o) => o.icon != null) != null;
	}

	select(option: O) {
		this.runtime.close(option);
	}

}
