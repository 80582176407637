import { Component, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ColumnGap, ModalAlertData, ModalConfirmData, ModalService, SingleChoiceListModalComponent, SingleChoiceRadioModalComponent, Size, ToastService } from '@unifii/library/common';
import { getPrintFormStyleChoiceModalData } from '@unifii/library/smart-forms';

import { ShowModalSampleComponent } from '../../lab';

type GuardOptions = 'component' | 'guarded' | 'unguarded';
@Component({
	templateUrl: './show-modals.html',
})
export class ShowModalsComponent {

	protected readonly sizes = Size;
	protected readonly columnGaps = ColumnGap;

	protected readonly alert = `this.modal.alert('YourTitle', 'YourMessage', { cancelLabel: 'YourCancelLabel' })`;
	protected readonly confirm = `this.modal.confirm('YourTitle', 'YourMessage', { confirmLabel: 'YourConfirmLabel', cancelLabel: 'YourCancelLabel' });`;
	protected readonly choiceRadio = `this.modal.openMedium(SingleChoiceModalComponent, {
		title: 'Time to choose!',
		message: 'What will be?',
		options: someOptionsHere,
		columns: 3,
	});`;
	protected readonly choiceList = `this.modal.openMedium(SingleChoiceModalComponent, {
		title: 'Time to choose!',
		message: 'What will be?',
		options: someOptionsHere,
	});`;
	protected readonly guardOptions: { identifier: GuardOptions; name: string }[] = [
		{ identifier: 'component', name: 'Component Guard' },
		{ identifier: 'guarded', name: 'Enabled Guard' },
		{ identifier: 'unguarded', name: 'Disabled Guard' },
	];
	protected choice: GuardOptions = 'component';

	private readonly data = {
		title: 'Modal Title',
		confirm: 'Yes',
		cancel: 'No',
	};
	private modalService = inject(ModalService);
	private toastService = inject(ToastService);
	private translateService = inject(TranslateService);

	protected openFit() {
		void this.modalService.openFit(ShowModalSampleComponent, this.data);
	}

	protected openMedium(choice: GuardOptions = 'component') {
		const guard = choice === 'component' ? undefined : choice === 'guarded';

		void this.modalService.openMedium(ShowModalSampleComponent, this.data, { guard });
	}

	protected openLarge() {
		void this.modalService.openLarge(ShowModalSampleComponent, this.data);
	}

	protected openFullScreen() {
		void this.modalService.openFullScreen(ShowModalSampleComponent, this.data);
	}

	protected openAlert(custom = false) {
		void this.modalService.openAlert( custom ? {
			title: 'Login Failed',
			message: 'Your username and/or password do not match',
			confirmLabel: 'Retry',
		} satisfies ModalAlertData : this.modalService.defaultModalAlertData);
	}

	protected async openConfirm(custom = false) {
		const result = await this.modalService.openConfirm( custom ? {
			title: 'Network Error',
			message: 'The network connection is lost',
			cancelLabel: 'No worries',
			confirmLabel: 'Reconnect',
		} satisfies ModalConfirmData : this.modalService.defaultModalConfirmData);

		this.toastService.info(`Modal confirm => ${result}`);
	}

	protected async openChoiceList(noOptions = false) {
		const result = await this.modalService.openMedium(SingleChoiceListModalComponent, {
			title: 'Time to choose!',
			message: 'What will be?',
			options: noOptions ? [] : this.guardOptions,
		});

		this.toastService.info(`Modal choice list => ${result?.identifier}`);
	}

	protected async openChoiceRadio() {
		const result = await this.modalService.openMedium(SingleChoiceRadioModalComponent, {
			title: 'Time to choose!',
			message: 'What will be?',
			options: this.guardOptions,
			columns: 2,
		});

		this.toastService.info(`Modal choice radio => ${result?.identifier}`);
	}

	protected async openPrintFormOptions() {
		const result = await this.modalService.openFit(SingleChoiceRadioModalComponent, getPrintFormStyleChoiceModalData(this.translateService));

		this.toastService.info(`Print => ${result?.identifier}`);
	}

}
