import { Injectable } from '@angular/core';
import { Page } from '@unifii/sdk';

import { RuntimePage } from '../../models';

import { getSafeRuntimeField } from './runtime-functions';

@Injectable({ providedIn: 'root' })
export class RuntimePageAdapter {

	transform(page: Page | RuntimePage): RuntimePage {

		if ((page as RuntimePage)._original as Page | null != null) {
			return page as RuntimePage;
		}

		const copy = JSON.parse(JSON.stringify(page)) as Page;
		const original = page as Page;

		for (const field of copy.fields as [] | null ?? []) {
			getSafeRuntimeField(field, false);
		}

		const runtimePage = copy as unknown as RuntimePage;

		runtimePage._original = original;
		runtimePage.fields = runtimePage.fields as [] | null ?? [];

		return runtimePage;
	}

}
