import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef, inject } from '@angular/core';

import { FilterComponentRegistry, FilterEntry, FilterValue, FilterViewMode, IFilterComponent } from '../../models';

@Component({
	selector: 'uf-filter',
	template: ``,
	styles: [`:host { display: block; }`],
})
export class FilterWrapperComponent<E extends FilterEntry, V extends FilterValue> implements OnInit {

	@Input({ required: true }) entry: E;
	@Input() mode: FilterViewMode = FilterViewMode.Input;
	@Output() valueChange = new EventEmitter<V[]>();

	private component?: IFilterComponent;
	private _value: V;
	private container = inject(ViewContainerRef);
	private registry = inject<FilterComponentRegistry<E>>(FilterComponentRegistry);

	@Input() set value(v: V) {
		this._value = v;
		if (this.component) {
			this.component.value = this.value;
		}
	}

	get value(): V {
		return this._value;
	}

	ngOnInit() {
		const component = this.registry.get(this.entry);

		// TODO can this be null at runtime, against what declared by the FilterComponentRegistry interface?
		if (!component) {
			console.warn(`FilterWrapperComponent - no component available for entry '${this.entry.identifier}'`);

			return;
		}

		this.component = this.container.createComponent(component).instance;
		this.component.entry = this.entry;
		this.component.mode = this.mode;
		this.component.value = this.value;
		this.component.valueChange = this.valueChange;
	}

}
