import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';

import { ModalService } from '@unifii/library/common';

export interface EditData {
	edited: boolean;
}

export const unsavedChangesGuard: CanDeactivateFn<any> = async(component: EditData) => {

	if (!component.edited) {
		return true;
	}

	return await inject(ModalService).openConfirm({
		title: 'Unsaved changes',
		message: 'Leave this page without saving?',
	}) ?? false;
};
