import { Dictionary, HierarchyUnit, HierarchyUnitFormData, HierarchyUnitInfo } from '@unifii/sdk';

export interface HierarchyUnitInput {
	label: string;
	id: string;
	disabled: boolean;
	selection: HierarchyUnitFormData | null;
}

export interface HierarchyUnitWithHasChildren extends HierarchyUnit {
	hasChildren: boolean;
}

export class HierarchyUnitInputController {

	inputs: HierarchyUnitInput[] = [];

	private disabledLookup: Dictionary<boolean> = {};

	constructor(inputs: HierarchyUnitInput[] = []) {
		this.inputs = inputs.map((i) => JSON.parse(JSON.stringify(i)) as HierarchyUnitInput);
	}

	append(...units: HierarchyUnitWithHasChildren[]): HierarchyUnitInput[] {
		for (const unit of units) {
			this.adjustListForNextItem(unit);

			const parent = this.inputs.find((i) => i.id === unit.parentId);

			if (parent != null) {
				parent.selection = this.createFormData(unit);
			}

			if (unit.hasChildren) {
				this.appendInput(unit, this.disabledLookup[unit.id]);
			}
		}

		return this.inputs;
	}

	disable(id: string): HierarchyUnitInput[] {
		this.disabledLookup = {};

		const index = this.inputs.findIndex((i) => i.id === id || i.selection?.id === id);

		for (let i = 0; i <= index; i++) {
			const input = this.inputs[i];

			if (!input) {
				continue;
			}

			input.disabled = true;

			this.disabledLookup[input.id] = true;
		}

		return this.inputs;
	}

	clearSelection(inputId: string): HierarchyUnitInput[] {
		// Clear input value
		const index = this.inputs.findIndex((i) => i.id === inputId);
		const input = this.inputs[index];

		if (!input) {
			console.warn(`HierarchyUnitInputController: Invalid input used calling setValue method. inputId: ${inputId}`);

			return this.inputs;
		}

		input.selection = null;
		if (this.inputs.length - 1 > index) {
			this.inputs.splice(index + 1);
		}

		return this.inputs;
	}

	getSelected(): HierarchyUnitFormData | null {
		const selections = this.inputs.map((i) => i.selection).filter(Boolean) as HierarchyUnitFormData[];

		return selections[selections.length - 1] ?? null;
	}

	reset() {
		this.disabledLookup = {};
		this.inputs.length = 0;
	}

	private adjustListForNextItem(unit: HierarchyUnit) {
		const index = this.inputs.findIndex((i) => i.id === unit.parentId);

		if (index === -1) {
			this.inputs.length = 0;
		} else if (this.inputs.length > (index + 1)) {
			this.inputs.splice(index + 1);
		}
	}

	private createFormData(unit?: HierarchyUnitInfo): HierarchyUnitFormData | null {
		if (unit == null) {
			return null;
		}

		return {
			id: unit.id,
			label: unit.label,
			path: this.createPath(unit),
		};
	}

	private createPath(unit: HierarchyUnitInfo): HierarchyUnitInfo[] {
		const path: HierarchyUnitInfo[] = [];

		for (const { selection } of this.inputs) {
			if (selection != null && selection.id !== unit.id) {
				path.push({ id: selection.id, label: selection.label });
			}
		}

		path.push({ id: unit.id, label: unit.label });

		return path;
	}

	private appendInput(unit: HierarchyUnit, disabled = false) {
		this.inputs.push({ id: unit.id, label: unit.childrenLabel ?? '', disabled, selection: null });
	}

}
