import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { generateUUID } from '@unifii/sdk';

import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-email',
	templateUrl: './uf-email.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfEmailComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less'],
})
export class UfEmailComponent extends UfControlValueAccessor<string> {

	@Input() id: string;
	@Input() name: string;
	@Input() label?: string | null;
	@Input() placeholder?: string | null;
	@Input() maxLength?: number | string;
	@Input() autofocus?: boolean | null;
	@Input() autocomplete = generateUUID();
	@Output() override valueChange = new EventEmitter<string>();

	focused: boolean;

	@HostBinding('class.focused') get focusedClass() {
		return this.focused && !this.disabled;
	}

	@HostBinding('class.error') get errorClass() {
		return this.control.showError && !this.disabled;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	@HostBinding('class.value') get valueClass() {
		return !!this.value;
	}

}
