import { Directive, EventEmitter, Injector, Input, OnDestroy } from '@angular/core';
import { ControlContainer, ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';

import { UfControl } from '../../controls';
import { PatternUtil } from '../../services/pattern-util';

@Directive()
export abstract class UfControlValueAccessor<T> implements ControlValueAccessor, OnDestroy {

	protected valueChange: EventEmitter<T> | null;
	protected patternUtil = new PatternUtil<T>();
	protected subscriptions = new Subscription(); // for extended components

	private valueBindingTouched: boolean; // TODO evaluate if this can be removed after builders are refactored UNIFII-3936
	private _control: UfControl | null; // internal control or control set through the input binding
	private valueChangesSubscription: Subscription | null;
	private _previousValue?: T | null; // Represents previously set value and last emitted value
	private _formControlName: string;

	constructor(private injector: Injector) { }

	/**
	 * @description
	 * onDestroy needs to be called to remove valueChanges subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.unsubscribe();
		this.valueChangesSubscription?.unsubscribe();
	}

	@Input() set disabled(v: boolean | string | null | undefined) {
		const isTrue = v === true || v === 'true';

		if (isTrue && this.control.enabled) {
			this.control.disable({ emitEvent: false });
			this.onDisabledChanges(isTrue);
		} else if (!isTrue && this.control.disabled) {
			this.control.enable({ emitEvent: false });
			this.onDisabledChanges(isTrue);
		}
	}

	get disabled(): boolean {
		return this.control.disabled;
	}

	@Input() set formControlName(v: string) {
		this._formControlName = v;
		/**
		 * Reset control cache if control has been accessed before formControlName has been set
		 */
		if (this._control != null) {
			this._control = null;
		}
	}

	get formControlName(): string {
		return this._formControlName;
	}

	@Input() set value(v: T | undefined | null) {
		this.valueBindingTouched = true;

		if (this.patternUtil.isEqual(v, this.value)) {
			return;
		}

		/**
		 * Create copy of emitted value so it cannot be mutated
		 * from the outside by reference
		 */
		this._previousValue = this.patternUtil.copy(v);
		this.control.setValue(v, { emitEvent: false });
	}

	get value() {
		return this.control.value as T | undefined | null;
	}

	@Input() set control(v: UfControl) {
		// TODO is this guard needed?
		if (!(v as UfControl | null)) {
			return;
		}

		if (this.valueBindingTouched) {
			v.setValue(this.value, { emitEvent: false });
		}

		// set internal value to ensure emit is called the correct number of times
		if (!this.valueBindingTouched && v.value) {
			this._previousValue = this.patternUtil.copy(v.value as T);
		}

		if (this.disabled) {
			v.disable({ emitEvent: false });
		}

		this._control = v;
		this.updateSubscription();
	}
	/**
	 * @description
	 * This getter method provides access to the components control
	 * and is responsible for lazily setting the control and updating any of it's subscriptions.
	 * The control can be set by the control or formControlName inputs.
	 * If a control hasn't been set an internal control will be used.
	 *
	 * Lazy logic is required when using formControlName to set your control. If you try to access
	 * the control via a setter or constructor the control is not available.
	 */
	get control(): UfControl {
		// use cache
		if (this._control) {
			return this._control;
		}

		if (this.formControlName) {
			const control = this.controlContainer.control?.get(this.formControlName) as UfControl | null;

			if (control) {
				this._control = control;
				this.updateSubscription();
				// set internal value to ensure emit is called the correct number of times
				if (control.value) {
					this._previousValue = this.patternUtil.copy(control.value as T);
				}

				return control;
			}
			console.error(`Control not found for formControlName: '${this.formControlName}'.`);
		}

		this._control = new UfControl();
		this.updateSubscription();

		return this._control;
	}

	get controlContainer() {
		return this.injector.get(ControlContainer);
	}

	onTouched = () => { /**/ };

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onChanged = (_val: T) => { /**/ };

	writeValue(value: T) {
		this.value = value;
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	registerOnChange(fn: any) {
		this.onChanged = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	valueEmitPredicate(value?: T | null, prev?: T | null): boolean {
		return !this.patternUtil.isEqual(value, prev);
	}

	/** Notify the extended class of a status change on the control */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	protected onDisabledChanges(_disabled: boolean) { /**/ }

	private updateSubscription() {
		if (!this._control) {
			return;
		}

		this.valueChangesSubscription?.unsubscribe();
		this.valueChangesSubscription = this._control.valueChanges.subscribe((value) => { this.onValueChanges(value as T | undefined, this._previousValue); });
		this._control.registerOnDisabledChange(this.onDisabledChanges.bind(this));
	}

	private onValueChanges(value?: T, prev?: T | null) {
		/**
		 * force all null values to be undefined
		 * eg: result will equal {} rather than { [identifier]: null }
		 */
		if (value == null) {
			value = undefined;
		}

		if (!this.valueChange || !this.valueEmitPredicate(value, prev)) {
			return;
		}

		/**
		 * Create copy of emitted value so it cannot be mutated
		 * from the outside by reference
		 */
		this._previousValue = this.patternUtil.copy(value);
		this.valueChange.emit(value);

		/**
		 * Run validators after value has been emitted
		 * to cover cases where custom validators may reference the emitted value not the control value
		 */
		this.control.updateValueAndValidity({ onlySelf: true });
	}

}
