import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagKey } from '@unifii/sdk';

import { FeatureFlagService } from '../services';

@Directive({
	selector: '[ufFeatureFlag]',
})
export class FeatureFlagDirective implements OnInit {

	@Input() ufFeatureFlag: FeatureFlagKey;

	constructor(
		private vcr: ViewContainerRef,
		private tpl: TemplateRef<any>,
		private featureFlagService: FeatureFlagService,
	) { }

	async ngOnInit() {
		if (await this.featureFlagService.isEnabled(this.ufFeatureFlag)) {
			this.vcr.createEmbeddedView(this.tpl);
		}
	}

}
