<sc-page-content width="lg" identifier="shadows">

	<sc-code-editor-expander [showCode]="false" [showCopy]="false" editor class="flat" class="col-12">
		<div class="row grow wrap">
			<div class="box pad row justify-center center-all small shadow">
				<p>Default</p>
			</div>
			<div class="box-md pad row justify-center center-all small shadow-md">
				<p>Medium</p>
			</div>
			<div class="box-lg pad row justify-center center-all small shadow-lg">
				<p>Large</p>
			</div>
			<div class="box-xl pad row justify-center center-all small shadow-xl">
				<p>Extra Large</p>
			</div>
		</div>
	</sc-code-editor-expander>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>