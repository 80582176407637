import { Component, EventEmitter, HostBinding, Inject, OnDestroy, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Breadcrumb, WindowWrapper } from '@unifii/library/common';

import { MenuNode, ShowcaseNavigationService, ShowcaseRepositoryService } from '../services';

interface NodeOption {
	url: string[];
	name: string;
	breadcrumbs: Breadcrumb[];
}

@Component({
	selector: 'sc-header',
	templateUrl: './show-header.html',
	styleUrls: ['./show-header.less'],
})
export class HeaderComponent implements OnDestroy {

	@Output() toggleMenu = new EventEmitter();
	@HostBinding('class.preview') get previewClass() { return this.repository.preview; }

	protected searchValue: string;
	protected breadcrumbs: Breadcrumb[] = [];
	protected nodeOptions: NodeOption[] = []; // for search box

	private staticNodeOptions: NodeOption[] = []; // static options for search box
	private navigationEndSubscription?: Subscription;

	constructor(
		private repository: ShowcaseRepositoryService,
		private router: Router,
		private nav: ShowcaseNavigationService,
		@Inject(WindowWrapper) private window: Window,
	) {

		this.staticNodeOptions = this.createStaticNodeOptions();
		this.nodeOptions = [...this.staticNodeOptions];

		this.navigationEndSubscription = this.router.events.pipe(
			filter((type) => type instanceof NavigationEnd))
			.subscribe(() => {
				if (this.nav.activeNode != null) {
					this.breadcrumbs = this.createBreadcrumbs(this.nav.activeNode);
				} else {
					this.breadcrumbs = [];
				}
			});
	}

	ngOnDestroy() {
		this.navigationEndSubscription?.unsubscribe();
	}

	protected routeTo(node?: NodeOption) {

		if (!node) {
			return;
		}

		void this.router.navigate(node.url);
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
		(this.window.document.activeElement as any).blur();
	}

	protected searchNodes(q: string) {

		if (!q) {
			this.nodeOptions = [...this.staticNodeOptions];

			return;
		}

		this.nodeOptions = this.staticNodeOptions.filter((node) =>
			node.name.toLowerCase().includes(q.toLowerCase()),
		);
	}

	private createStaticNodeOptions(): NodeOption[] {

		const options: NodeOption[] = [];

		for (const node of this.nav.nodeIterator(this.nav.nodes)) {

			if (node.urlSegments != null) {
				options.push({
					url: node.urlSegments,
					name: node.name,
					breadcrumbs: this.createBreadcrumbs(node),
				});
			}
		}

		return options;
	}

	private createBreadcrumbs(node: MenuNode, result: Breadcrumb[] = []): Breadcrumb[] {

		const breadcrumb = this.getBreadcrumb(node);
		const parent = this.nav.getParent(node);

		result = [breadcrumb, ...result];

		if (parent != null) {
			return this.createBreadcrumbs(parent, result);
		}

		return result;
	}

	private getBreadcrumb(node: MenuNode): Breadcrumb {

		return {
			name: node.name,
			urlSegments: node.urlSegments,
		};
	}

}
