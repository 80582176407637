import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-actions.html',
	styleUrls: ['_inputs.less'],
})
export class ShowActionsComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly sizeOptions = [
		{ name: 'Large', value: 'large' },
		{ name: 'Default', value: '' },
		{ name: 'Small', value: 'small' },
		{ name: 'Extra Small', value: 'x-small' },
		{ name: 'XX Small', value: 'xx-small' },
	];
	readonly colourOptions = [
		{ name: 'Primary', value: 'primary' },
		{ name: 'Secondary (default)', value: '' },
		{ name: 'Tertiary', value: 'tertiary' },
	];
	readonly typeOptions = [
		{ name: 'Button', value: 'button' },
		{ name: 'Anchor', value: 'anchor' },
	];
	readonly positionOptions = [
		{ name: 'Default', value: '' },
		{ name: 'Fixed', value: 'fixed' },
	];

	readonly tableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly tableInfo = [
		{ name: 'uf-action', type: 'base', description: 'Base style to apply action button style.' },
		{ name: 'primary', type: 'modifier', description: 'Sets colour of action and icon to primary' },
		{ name: 'secondary', type: 'modifier', description: 'Sets colour of action and icon to secondary/default' },
		{ name: 'default', type: 'modifier', description: 'Sets colour of action and icon to secondary/default' },
		{ name: 'tertiary', type: 'modifier', description: 'Sets colour of action and icon to tertiary' },
		{ name: 'large', type: 'modifier', description: 'Sets size of action to large' },
		{ name: 'default', type: 'modifier', description: 'Sets size of action to default' },
		{ name: 'small', type: 'modifier', description: 'Sets size of action to small' },
		{ name: 'fixed', type: 'modifier', description: 'Makes action a fixed floating action in the bottom right corner.' },
	];

	type = 'button';
	size = '';
	colour = '';
	position = '';

	class = 'uf-action';

	template = `
		<button type="button" class="uf-action">
			<uf-icon name="edit"></uf-icon>
		</button>
	`;

	modifierChange() {
		this.class = ('uf-action ' + [this.size, this.colour, this.position].filter((s) => s.length).join(' ')).trim();
		let element = '';
		let attributes = '';

		if (this.type === 'button') {
			element = 'button';
			attributes = 'type="button"';
		} else if (this.type === 'anchor') {
			element = 'a';
			attributes = 'href="#"';
		}
		this.template = `<${element} ${attributes} class="${this.class}">
			<uf-icon name="edit"></uf-icon>
		</${element}>`;
	}

}
