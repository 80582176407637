import { Component } from '@angular/core';
import { format } from 'date-fns';

import { ColumnGap, FnsDatetimeUtc, Size, UfControl } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-datetime.html',
})
export class ShowDatetimeComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly sizeOptions = [
		{ name: 'default', value: '' },
		{ name: 'small', value: 'small' },
	];

	readonly inputTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'default', name: 'Default' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly inputTableInfo = [
		{ name: 'control', type: 'UfControl', default: 'new UfControl()', description: 'A control object to manage validators, states etc.' },
		{ name: 'id', type: 'string', default: 'undefined', description: 'Sets id attribute of inner input element' },
		{ name: 'name', type: 'string', default: 'undefined', description: 'Sets name attribute of inner input element' },
		{ name: 'label', type: 'string', default: 'undefined', description: 'Sets label' },
		{ name: 'placeholder', type: 'string', default: 'undefined', description: 'Sets placeholder' },
		{ name: 'timezone', type: 'string', default: 'undefined', description: 'Sets timezone' },
		{ name: 'step', type: 'number | string', default: 'undefined', description: 'Sets step increments for time input' },
		{ name: 'defaultTime', type: 'string', default: 'undefined', description: 'Sets default time for time input' },
	];
	readonly outputTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly outputTableInfo = [
		{ name: 'valueChange', type: 'EventEmitter', description: 'Emits an event when the value of the input is changed' },
	];
	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'default', type: 'modifier', description: 'Default input size' },
		{ name: 'small', type: 'modifier', description: 'Small input size' },
	];

	size = '';
	template = `
		<uf-datetime label="Date Time"></uf-datetime>
	`;

	value: string | null;
	control = new UfControl();

	constructor() {
		this.value = '2020-09-21T15:08:28';
	}

	setValue(empty = false) {

		if (empty) {
			this.value = null;

			return;
		}

		const d = new Date();

		this.value = format(d, FnsDatetimeUtc);

	}

	modifierChange() {
		this.template = `<uf-datetime label="Date Time"${this.size ? ' class="' + this.size + '"' : ''}></uf-datetime>`;
	}

}
