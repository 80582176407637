import { DestroyRef } from '@angular/core';

/**
 * ClassListObserver observes and notifies of changes to a html elements classList
 */
export class ClassListObserver {

	private classListCache?: string[];
	private observer: MutationObserver;

	constructor(
		private element: HTMLElement,
		private onChanges: (classList: string[]) => void,
		private destroy: DestroyRef | undefined,
	) {
		this.observer = new MutationObserver((records) => { this.recordChanges(records); });
		this.observer.observe(this.element, { childList: false, attributes: true, subtree: false, attributeFilter: ['class'] });
		this.destroy?.onDestroy(() => { this.observer.disconnect(); });
	}

	get classList(): string[] {
		return this.classListCache ?? this.safeClassNamesSplit(this.element.className);
	}

	disconnect() {
		this.observer.disconnect();
	}

	private recordChanges(records: MutationRecord[]) {
		const classes: string[] = [];

		for (const record of records) {
			classes.push(...this.safeClassNamesSplit((record.target as HTMLElement | null)?.className));
		}
		const deduped = [...new Set(classes)];

		this.classListCache = deduped;
		this.onChanges(deduped);
	}

	private safeClassNamesSplit(className?: string): string[] {
		return className?.split(' ').filter((name) => !!name) ?? [];
	}

}
