import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, inject } from '@angular/core';
import { HorizontalTableTemplate } from '@unifii/sdk';

import { RuntimeField, Scope, ScreenSize, SharedTermsTranslationKey, SourceConfigMapping, UfSourceConfigMappingsPipe, WindowResizeEventHandler, WindowResizeInfo } from '@unifii/library/common';
import { FormService } from '@unifii/library/smart-forms';

import { InputTranslationKey } from '../../../translations';

import { getDataSeedVisibleLabels } from './repeat-functions';

@Component({
	selector: 'uf-repeat-table',
	templateUrl: './repeat-table.html',
	styleUrls: ['./repeat-table.less'],
})
export class RepeatTableComponent implements OnInit, OnChanges {

	@Input({ required: true }) field: RuntimeField;
	@Input({ required: true }) items: Scope[];
	
	@Output() remove = new EventEmitter<number>();
	@Output() rowClick = new EventEmitter<any>();

	protected readonly inputTK = InputTranslationKey;
	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected formService = inject(FormService);
	protected optionalSuffix = this.formService.definitionSettings.optionalSuffix;
	protected requiredSuffix = this.formService.definitionSettings.requiredSuffix;
	protected dataSeedVisibleLabels: SourceConfigMapping[] = [];
	protected tableFields: RuntimeField[] = [];
	protected hiddenColumns: string[] = [];

	private windowResizeEventHandler = inject(WindowResizeEventHandler, { optional: true });
	private destroyRef = inject(DestroyRef);
	private sourceConfigMappingsPipe = inject(UfSourceConfigMappingsPipe);

	ngOnInit() {
		if (!this.hideFromColumnsOnDesktop.length && !this.hideFromColumnsOnMobile.length) {
			return;
		}

		this.windowResizeEventHandler?.register({
			listener: (info: WindowResizeInfo) => {
				this.hiddenColumns = info.screenSize === ScreenSize.ScreenWidthSm ?
					this.hideFromColumnsOnMobile :
					this.hideFromColumnsOnDesktop;
			},
			destroy: this.destroyRef,
			fireOnRegister: true,
		});
	}

	ngOnChanges() {
		this.dataSeedVisibleLabels = this.field.sourceConfig ? getDataSeedVisibleLabels(this.field.sourceConfig, this.sourceConfigMappingsPipe) : [];
	}

	protected isVisible(child: RuntimeField): boolean {
		return !!child.identifier && this.formService.isGranted(child) && !this.hiddenColumns.includes(child.identifier);
	}

	private get hideFromColumnsOnMobile(): string[] {
		return (this.field.templateConfig as HorizontalTableTemplate | undefined)?.hideFromColumnsOnMobile ?? [];
	}

	private get hideFromColumnsOnDesktop(): string[] {
		return (this.field.templateConfig as HorizontalTableTemplate| undefined)?.hideFromColumnsOnDesktop ?? [];
	}

}
