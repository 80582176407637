import { Component, Input, OnInit, inject } from '@angular/core';
import { DataSeed, FieldOption, FieldType } from '@unifii/sdk';

import { CommonTranslationKey, DataDisplayListItem, FieldDescriptionServiceProvider, RuntimeField, Scope } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

/**
 * // TODO - is still used in SSR, update logic there before delete this one
 * @deprecated
 */
@Component({
	selector: 'uf-choice-description',
	templateUrl: './choice-description.html',
	styleUrls: ['./choice-description.less'],
})
export class ChoiceDescriptionComponent implements ContentField, OnInit {

	@Input() content?: string | string[] | boolean | boolean[] | DataSeed | DataSeed[];
	@Input() scope?: Scope;

	protected readonly commonTK = CommonTranslationKey;
	protected dataDisplayItems: DataDisplayListItem[];
	protected otherOptions: FieldOption[] = [];
	protected singleDataSeedContent: DataSeed | null;
	protected showExpander: boolean;

	private _field: RuntimeField;
	private options: FieldOption[] = [];
	private descriptionService = inject(FieldDescriptionServiceProvider);

	@Input()
	set field(v: RuntimeField) {
		this._field = v;
		this.options = !v.sourceConfig ? v.options : [];
	}

	get field(): RuntimeField {
		return this._field;
	}

	ngOnInit() {
		this.singleDataSeedContent = this.getSingleDataSeedContent();
		const dataDisplayItems = this.descriptionService.transform(this.content, this.field, this.scope);
		
		this.dataDisplayItems = dataDisplayItems ?? [];

		this.otherOptions = this.options.filter((option) => {

			if (this.content == null) {
				return true;
			}

			// Configured this.options means that the content is string | string[] | boolean | boolean[];
			const optionsBasedOriginalContent = (this.content as string | string[] | boolean | boolean[]);
			const optionsBasedContent = Array.isArray(optionsBasedOriginalContent) ? optionsBasedOriginalContent.map((value) => `${value}`) : `${optionsBasedOriginalContent}`;
			let contentIndex = -1;

			if (Array.isArray(optionsBasedContent)) {
				contentIndex = optionsBasedContent.indexOf(option.identifier);
			} else if (option.identifier === optionsBasedContent) {
				contentIndex = 0;
			}

			return !(contentIndex + 1);
		});

		this.showExpander = this.getShowExpander();
	}

	private getShowExpander() {

		if (!this.otherOptions.length || this.field.type === FieldType.Lookup) {
			return false;
		}

		if ([FieldType.Bool, FieldType.Choice, FieldType.MultiChoice].includes(this.field.type) && !this.field.sourceConfig) {
			return true;
		}

		return this.field.parent?.sourceConfig != null;
	}

	private getSingleDataSeedContent(): DataSeed | null {
		// Guard for single content value and type dataSeed
		if (![FieldType.Choice, FieldType.Lookup].includes(this.field.type) || !this.field.sourceConfig) {
			return null;
		}

		return this.content as DataSeed | null;
	}

}
