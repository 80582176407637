import { Injectable } from '@angular/core';

export interface Fruit {
	id: number;
	key: string;
	name: string;
	rating: number;
}

@Injectable({ providedIn: 'root' })
export class SCDataService {

	readonly fruits: Fruit[] = [{
		id: 0,
		key: 'AP',
		name: 'Apple',
		rating: 5,
	}, {
		id: 1,
		key: 'PE',
		name: 'Peach',
		rating: 5,
	}, {
		id: 2,
		key: 'OR',
		name: 'Orange',
		rating: 5,
	}, {
		id: 3,
		key: 'LE',
		name: 'Lemon',
		rating: 3,
	}, {
		id: 4,
		key: 'BB',
		name: 'Blueberry',
		rating: 3,
	}, {
		id: 5,
		key: 'SB',
		name: 'Strawberry',
		rating: 2,
	}, {
		id: 6,
		key: 'LI',
		name: 'Lime',
		rating: 2,
	}, {
		id: 7,
		key: 'PP',
		name: 'Pineapple',
		rating: 11,
	}, {
		id: 8,
		key: 'WM',
		name: 'Watermelon',
		rating: 9,
	}, {
		id: 9,
		key: 'RM',
		name: 'Rockmelon',
		rating: 10,
	}, {
		id: 10,
		key: 'PA',
		name: 'Pink Apple',
		rating: 10,
	}];

	searchFruits(query?: string): Fruit[] {
		return [...(query ?
			this.fruits.filter((f) =>
				f.name.toLowerCase().includes(query.toLowerCase()) ||
				f.key.toLowerCase().includes(query.toLowerCase()),
			) :
			this.fruits
		)];
	}

	findByFruits(identifier: string, match: string): Fruit[] {
		return this.fruits.filter((f) => (f as any)[identifier] === match);
	}

	searchFruitsNames(query?: string): string[] {
		return this.searchFruits(query).map((f) => f.name);
	}

	async asyncFindByFruits(identifier: string, match: string, delay?: number | boolean): Promise<Fruit[]> {
		await this.getLatency(delay);

		return this.findByFruits(identifier, match);
	}

	async asyncSearchFruitNames(query?: string, delay?: number | boolean): Promise<string[]> {
		await this.getLatency(delay);

		return this.searchFruitsNames(query);
	}

	async asyncSearchFruits(query?: string, delay?: number | boolean): Promise<Fruit[]> {
		await this.getLatency(delay);

		return this.searchFruits(query);
	}

	async getFruit(id: number, delay?: number | boolean): Promise<Fruit | null> {
		await this.getLatency(delay);

		return this.fruits.find((f) => f.id === id) ?? null;
	}

	private getLatency(delay?: number | boolean): Promise<void> {
		const ms = typeof delay === 'boolean' ? (delay ? 500 : 0) : delay ? delay : 0;

		return new Promise((res) => setTimeout(res, ms));
	}

}
