<div *ngIf="label" class="label">
	{{ label }}
	<ng-content></ng-content>
</div>
<label>
	<uf-icon name="upload" />
	{{uploadLabel}}
	<input #fileInput [formControl]="inputControl" [attr.multiple]="multiple ? '': null" [readonly]="disabled"
		[accept]="type ?? ''" (focus)="focused = true" (blur)="focused = false; touchedChange.next(true)"
		(change)="onChange()" type="file">
</label>