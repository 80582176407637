import { Component, HostBinding, OnInit, forwardRef, inject } from '@angular/core';

import { CarouselComponent } from './carousel.component';

@Component({
	selector: 'uf-carousel-page',
	templateUrl: './carousel-page.html',
	styleUrls: ['./carousel-page.less'],
})
export class CarouselPageComponent implements OnInit {

	@HostBinding('class.active') isActive: boolean;

	private carousel = inject<CarouselComponent>(forwardRef(() => CarouselComponent));

	ngOnInit() {
		this.carousel.register(this);
	}

	protected swipeLeft() {
		this.carousel.next();
	}

	protected swipeRight() {
		this.carousel.back();
	}

}
