import { Injectable, inject } from '@angular/core';
import { DATE_TIME_DATA_FORMAT, HierarchyUnitExtended, HierarchyUnitWithPath } from '@unifii/sdk';
import { add, format } from 'date-fns';

import { HierarchyUnitProvider } from '../../../models';

/**
 * HierarchyUnitCache caches unit data
 * to improve performance when the same endpoint is called repeatedly.
 * Is provided as sibling to the component that use the cache mechanism
 */
@Injectable()
export class HierarchyUnitCache {

	private provider = inject(HierarchyUnitProvider);
	private cacheLifetime = { minutes: 20 };
	private _cacheExpiry: string | null;
	private unitsExtended = new Map<string, HierarchyUnitExtended>();
	private unitsWithPath = new Map<string, HierarchyUnitWithPath>();

	async getUnit(id: string): Promise<HierarchyUnitExtended | undefined> {
		this.checkCache();

		let unit = this.unitsExtended.get(id);

		if (unit) {
			return unit;
		}

		unit = await this.provider.getUnit(id);
		if (unit) {
			this.unitsExtended.set(id, unit);

			const { childCount, ...unitWithPath } = unit;

			this.unitsWithPath.set(id, unitWithPath);
		}

		return unit;
	}

	async getUnits(ids: string[]): Promise<HierarchyUnitWithPath[]> {
		this.checkCache();

		let units: HierarchyUnitWithPath[] = [];

		for (const id of ids) {
			const unitWithPath = this.unitsWithPath.get(id);

			if (unitWithPath) {
				units.push(unitWithPath);
			}
		}

		if (ids.length === units.length) {
			return units;
		}

		units = await this.provider.getUnits(ids);

		for (const unit of units) {
			this.unitsWithPath.set(unit.id, unit);
		}

		return units;
	}

	private checkCache() {
		if (this.cacheExpiry < this.now) {
			this.unitsExtended.clear();
			this.unitsWithPath.clear();

			this._cacheExpiry = null;
		}
	}

	get cacheExpiry(): string {
		if (this._cacheExpiry == null) {
			this._cacheExpiry = format(add(new Date(), this.cacheLifetime), DATE_TIME_DATA_FORMAT);
		}

		return this._cacheExpiry;
	}

	get now(): string {
		return format(new Date(), DATE_TIME_DATA_FORMAT);
	}

}
