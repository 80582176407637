import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Dictionary } from '@unifii/sdk';

import { FilterEntry, FilterManagerProvider, FilterValue, FilterViewMode } from '../../models';

@Component({
	selector: 'uf-filter-display',
	templateUrl: './filter-displays.html',
	styleUrls: ['./filter-displays.less'],
})
export class FilterDisplaysComponent<V extends FilterValue, E extends FilterEntry> {

	@Input() manager?: FilterManagerProvider<V, E>;
	@Input() value?: Dictionary<V>;
	@Output() valueChange = new EventEmitter<Dictionary<V>>();

	readonly mode = FilterViewMode.Display;

	onValueChanged() {
		if (this.manager == null) {
			return;
		}
		this.valueChange.emit(this.manager.clean(this.value ?? {}));
	}

}
