import { Injectable, Injector, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorType, Interceptor, ensureUfError } from '@unifii/sdk';

import { ShowcaseRepositoryService } from './repository.service';

@Injectable()
export class ShowcaseInterceptor implements Interceptor {

	private repository?: ShowcaseRepositoryService;
	private injector = inject(Injector);
	private router = inject(Router);

	intercept<T>(response: Promise<T>): Promise<T> {

		return response.catch((e) => {

			const error = ensureUfError(e, 'Unauthorized');

			if (error.type === ErrorType.Unauthorized) {

				if (!this.repository) {
					this.repository = this.injector.get(ShowcaseRepositoryService);
				}

				console.warn('ShowcaseInterceptor.catch - logout and redirect', error);

				this.repository.reset();
				void this.router.navigate(['/login']);

				return Promise.resolve(null as T);
			}

			throw error;
		});
	}

}
