import { Component, OnInit } from '@angular/core';
import { Dictionary } from '@unifii/sdk';

import { ColumnGap, MessageAction, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-messages.html',
})
export class ShowMessagesComponent implements OnInit {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'info', type: 'modifier', description: 'Sets colour to information message colour' },
		{ name: 'warning', type: 'modifier', description: 'Sets colour to warning message colour' },
		{ name: 'success', type: 'modifier', description: 'Sets colour to success message colour' },
		{ name: 'error', type: 'modifier', description: 'Sets colour to error message colour' },
		{ name: 'disabled', type: 'modifier', description: 'Sets content to disabled' },
		{ name: 'raised', type: 'modifier', description: 'Sets shaddow to raised' },
		{ name: 'tinted-background', type: 'modifier', description: 'Sets background to message colour' },
		{ name: 'shadow', type: 'modifier', description: 'Sets colour to disabled messaage colour' },
		{ name: 'large', type: 'modifier', description: 'Sets size of content to large' },
		{ name: 'default', type: 'modifier', description: 'Sets size of content to default' },
		{ name: 'small', type: 'modifier', description: 'Sets size of content to small' },
		{ name: 'x-small', type: 'modifier', description: 'Sets size of content to extra small' },
	];
	// Table
	readonly propertiesColConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'default', name: 'Default' },
		{ identifier: 'description', name: 'Description' },
	];

	readonly propertiesRows = [
		{
			name: 'icon',
			type: 'string',
			default: 'undefined',
			description: 'Displays icon as part of content',
		},
		{
			name: 'ng-content',
			type: 'template',
			default: 'undefined',
			description: 'Renders content using ng-content',
		},
		{
			name: 'content',
			type: 'string',
			default: 'undefined',
			description: 'Renders string as markdown',
		},
		{
			name: 'heading',
			type: 'string',
			default: 'undefined',
			description: 'Renders string in heading style',
		},
		{
			name: 'actions',
			type: 'string[]',
			default: '[]',
			description: 'Renders strings as action buttons',
		},
		{
			name: 'heading',
			type: 'string',
			default: 'undefined',
			description: 'Renders string in heading style',
		},
	];
	// Interactive
	readonly sizeOptions = [
		{ name: 'Large', value: 'large' },
		{ name: 'Medium (default)', value: '' },
		{ name: 'Small', value: 'small' },
		{ name: 'Extra Small', value: 'x-small' },
	];
	readonly colourOptions = [
		{ name: 'Default', value: '' },
		{ name: 'Info', value: 'info' },
		{ name: 'Warning', value: 'warning' },
		{ name: 'Error', value: 'error' },
		{ name: 'Success', value: 'success' },
	];
	readonly iconMap: Dictionary<string> = {
		info: 'infoSolid',
		warning: 'warningSolid',
		alert: 'alertSolid',
		success: 'successSolid',
		disabled: 'warningSolid',
		error: 'errorSolid',
	};

	readonly actions: MessageAction[] = [
		{ label: 'Primary', action: () => console.log('hello world'), icon: 'errorSolid', type: 'primary' },
		{ label: 'Default', action: () => console.log('hello world'), icon: 'errorSolid' },
		{ label: 'Tertiary', action: () => console.log('hello world'), icon: 'errorSolid', type: 'tertiary' },
	];

	readonly content = `
# This is a test a very long test of what could be put into a section
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
`;

	size = '';
	colour = '';
	heading = '';
	icon = '';
	showIcon = true;
	untintedBackground: boolean;
	raised: boolean;
	inactive: boolean;
	template: string;
	showExpander: boolean;

	private templateSource = `
		<!-- Content set through bindings -->
		<uf-message #classBinding [content]="yourContent" [icon]="yourIcon" [actions]="actions" [showExpander]="true">
			<h3>Your Heading</h3>
		</uf-message>

		<!-- Content set using ng-content -->
		<uf-message #classBinding [icon]="yourIcon">
			<h3>yourHeading</h3>
			<p>yourContent</p>
		</uf-message>
	`;

	ngOnInit() {
		this.modifierChange();
	}

	modifierChange() {

		let classes = [this.size, this.colour].join(' ').trim();

		if (this.untintedBackground) {
			classes = classes + 'untinted-background';
		}

		if (this.raised) {
			classes = classes + 'raised';
		}

		if (this.inactive) {
			classes = classes + 'inactive';
		}

		if (classes !== '') {
			classes = `class="${classes}" `;
		}

		this.heading = this.colour.slice(0, 1).toUpperCase() + this.colour.substring(1);

		if (this.heading === '') {
			this.heading = 'Default';
		}

		this.icon = this.colour ? this.iconMap[this.colour] || this.colour : 'moreInfoSolid';

		this.template = this.templateSource.replace(/#classBinding/g, classes);
	}

}
