import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { Compound, FormCarouselTemplate, FormContentTemplate, FormStyle } from '@unifii/sdk';

import { ContextProvider, RuntimeField, UfFormControl } from '@unifii/library/common';
import { FieldHelperFunctions, FormContainerField, FormService, WorkflowService, contentIconMap, getContentClasses } from '@unifii/library/smart-forms';

interface ContentPage {
	field: RuntimeField;
	classes: string[];
	icon?: string;
}

@Component({
	selector: 'uf-content-carousel',
	templateUrl: './content-carousel.html',
	styleUrls: ['./content-carousel.less'],
})
export class ContentCarouselComponent implements FormContainerField, OnInit {

	@HostBinding('class.uf-form-group') groupClassName = true;
	@HostBinding('class.disabled') get hostDisabled() {
		return this.control.disabled && !this.isSummary;
	}
	@HostBinding('class.collapsed') get hostCollapsed() {
		return this.collapsed;
	}

	field: RuntimeField;
	scope: Compound;
	control: UfFormControl;

	protected interval?: number;
	protected pages: ContentPage[] = [];
	protected isActive: boolean;
	protected isSummary: boolean;
	protected readonly iconMap = contentIconMap;

	private formService = inject(FormService, { optional: true });
	private workflowService = inject(WorkflowService);
	private contextProvider = inject(ContextProvider);
	private collapsed: boolean;

	ngOnInit() {
		this.isActive = this.workflowService.isActive(this.field);
		this.pages = this.field.fields
			.filter((field) => this.fieldIsVisible(field))
			.map((field) => ({
				field,
				classes: getContentClasses(field.templateConfig as FormContentTemplate | undefined, this.isActive, field.template),
				icon: field.template ? this.iconMap[field.template] : undefined,
			}));

		this.isSummary = this.formService?.style === FormStyle.Summary;
		this.interval = (this.field.templateConfig as FormCarouselTemplate | undefined)?.scrollTime;
	}

	isVisible(child: RuntimeField): boolean {
		// Not a SmartForm context
		if (!this.formService) {
			return true;
		}

		// SmartForm context, workflow lead
		return !!this.workflowService.isVisible(child);
	}

	protected updateRequired(viewed: boolean) {

		if (!viewed) {
			return;
		}

		this.control.clearValidators();
		this.control.updateValueAndValidity();
	}

	private fieldIsVisible(childField: RuntimeField): boolean {

		if (!FieldHelperFunctions.areRolesMatching(this.contextProvider.get().user?.roles, childField.visibleTo)) {
			return false;
		}

		return this.isActive || !(childField.templateConfig as FormContentTemplate | undefined)?.hideWhenInactive;
	}

}
