import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DataType, Dictionary, LayoutDirection, OPTION_NAME, Option, coerceDataToTarget, hasLengthAtLeast, isDictionary, isString } from '@unifii/sdk';

import { GridGap } from '../../directives';
import { NamePropertyInfo } from '../../models';

import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-multi-choice',
	templateUrl: './uf-multi-choice.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfMultiChoiceComponent, multi: true,
	}],
	styleUrls: ['./uf-multi-choice.less'],
})
export class UfMultiChoiceComponent extends UfControlValueAccessor<(string | Dictionary<any>)[] | null> {

	@Input() id: string | null;
	@Input() label: string | null | undefined;
	@Input() nameProperty: NamePropertyInfo | null | undefined = OPTION_NAME;
	@Input() valueProperty: string | null | undefined = 'value';
	@Input() trackBy: string | null;
	@Input() maxLength: number | string | null | undefined;
	@Input() name: string | null;
	@Input() direction: `${LayoutDirection}` | null | undefined = 'Row';
	@Input() columns: number | string | null | undefined = 2;
	@Input() rows: number | string | null | undefined;
	@Output() override valueChange = new EventEmitter();

	protected labels: string[] = [];
	protected checkboxValues: boolean[] = [];
	protected columnRowGap: GridGap = 'sm';

	private _options: (string | Dictionary<any>)[] = [];

	@Input() set options(options: (string | Dictionary<any>)[] | undefined | null) {
		this._options = options ?? [];
		this.labels = this.options.map(this.getOptionName.bind(this));
		this.checkboxValues = this.getCheckboxValues(this.options, this.value);
		this.columnRowGap = hasLengthAtLeast(this.options, 1)
			&& !isString(this.options[0])
			&& this.options[0].content !== undefined ? 'md' : 'sm';
	}

	get options(): (string | Dictionary<any>)[] {
		return this._options;
	}

	@Input() override set value(v: (string | Dictionary<any>)[] | null | undefined) {
		if (v === super.value) {
			return;
		}

		if (!this.options.length && v?.length) {
			this.options = v;
		}

		this.checkboxValues = this.getCheckboxValues(this.options, v);
		super.value = v;
	}

	override get value(): (string | Dictionary<any>)[] | null | undefined {
		return super.value;
	}

	get selected(): number {
		return this.checkboxValues.filter((v) => v).length;
	}

	get hasContent(): boolean {
		if (!this.options.length) {
			return false;
		}

		const option = this.options[0];

		if (typeof option === 'string') {
			return false;
		}

		return option?.content !== undefined;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	valueChanged() {
		const value = this.options
		// Filter by selected
			.filter((_, i) => this.checkboxValues[i])
		// Map to value property
			.map((option) => {
				// to pass tests typeof option === 'object' instead of isDictionary(option) // Why??
				if (isDictionary(option) && this.valueProperty != null && option[this.valueProperty] != null) {
					return option[this.valueProperty];
				}

				return option;
			});

		this.control.setValue(value);
	}

	getContent(option: string | Option | Dictionary<any>): any {
		return !this.hasContent ? undefined : (option as Dictionary<any>).content;
	}

	getOptionLabel(option: string | Option | Dictionary<any>): string | undefined {
		if (!this.hasContent) {
			return (option as Option).name || option as string;
		}

		return undefined;
	}

	override valueEmitPredicate(value: (string | Dictionary<any>)[] | null, prev: (string | Dictionary<any>)[] | null): boolean {
		const checkboxValues = this.getCheckboxValues(this.options, value);
		const requiresUpdate = checkboxValues.reduce((mismatch, v, i) => (mismatch || this.checkboxValues[i] !== v), false);

		if (requiresUpdate) {
			this.checkboxValues = checkboxValues;
		}

		return super.valueEmitPredicate(value, prev);
	}

	private getCheckboxValues(options: (string | Dictionary<any>)[], value?: (string | Dictionary<any>)[] | null): boolean[] {
		return options.map((option) => this.isSelected(option, value));
	}

	private getOptionName(option: string | number | Dictionary<any>): string {

		if (typeof this.nameProperty === 'function') {
			return this.nameProperty(option) ?? '';
		}

		if (typeof option === 'string' || typeof option === 'number') {
			return '' + option;
		}

		if (this.nameProperty && option[this.nameProperty]) {
			return coerceDataToTarget(option[this.nameProperty], { type: DataType.String }) ?? '';
		}

		// Return first key
		const firstKey = Object.keys(option)[0];

		return firstKey ? '' + (option[firstKey] ?? '') : '';
	}

	private isSelected(option: string | Dictionary<any>, values?: (string | Dictionary<any>)[] | null): boolean {

		return (values ?? []).find((v) => {
			// matching strings or objects with same reference
			if (v === option) {
				return true;
			}

			// match using trackBy
			if (
				typeof option === 'object' &&
				typeof v === 'object' &&
				this.trackBy &&
				option[this.trackBy] != null &&
				option[this.trackBy] === v[this.trackBy]
			) {
				return true;
			}

			return typeof option === 'object' && (this.valueProperty && option[this.valueProperty] === v);

		}) != null;
	}

}
