import { ClaimConfig, DataSeed, TenantClient, isNotNull } from '@unifii/sdk';

import { DataSourceLoader, DataSourceLoaderConfig, SourceConfigUserClaims } from '../../models';

import { DataSourceConverter } from './data-source-converter';

interface ClaimOption { id: string; display: string }

/** Initialized via DataLoaderFactory */
export class ClaimLoader implements DataSourceLoader {

	constructor(
		public sourceConfig: SourceConfigUserClaims,
		public config: DataSourceLoaderConfig | undefined,
		private tenantClient: TenantClient,
		private dataSeedConverter: DataSourceConverter,
	) { }

	async getOptions(): Promise<DataSeed[]> {
		if (!this.sourceConfig.id) {
			return [];
		}

		const claim = await this.getClaim(this.sourceConfig.id);

		return claim.options?.map((c) => this.mapToSeed(c)).filter(isNotNull) ?? [];
	}

	async search(q?: string | undefined): Promise<DataSeed[]> {
		if (!this.sourceConfig.id) {
			return [];
		}

		const claim = await this.getClaim(this.sourceConfig.id);
		const options = claim.options?.filter((c) => c.display.toLowerCase().includes(q ?? '')) ?? [];

		return options.map((o) => this.mapToSeed(o)).filter(isNotNull);
	}

	findAllBy(match: string): Promise<DataSeed[]> {
		console.warn('ClaimLoader datasource does not support findByAll, falling back on search');

		return this.search(match);
	}

	async get(id: string): Promise<DataSeed | null> {
		if (!this.sourceConfig.id) {
			return null;
		}

		const claim = await this.getClaim(this.sourceConfig.id);

		return this.mapToSeed(claim.options?.find((c) => c.id === id));
	}

	mapToSeed(claimOption?: ClaimOption): DataSeed | null {
		return this.dataSeedConverter.toDataSeed(claimOption, this.sourceConfig);
	}

	private getClaim(id: string): Promise<ClaimConfig> {
		return this.tenantClient.getUserClaim(id);
	}

}
