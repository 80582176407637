import { Component, DestroyRef, EventEmitter, HostBinding, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataSeed, FieldTemplate, FormStyle, SortDirections, ZonedDateTime, generateUUID, isNumber, isString } from '@unifii/sdk';

import { CommonTranslationKey, ContextProvider, ExpressionParser, ModalService, RuntimeField, Scope, SharedTermsTranslationKey, SortStatus, ToastService, UfControlArray, UfFormControl, WindowResizeEventHandler, WindowWrapper, controlIterator } from '@unifii/library/common';
import { ComponentRegistry, FormDebugger, FormField, FormFieldService, FormRevisionService, FormService, FormSettings, ScopeManager, WorkflowService } from '@unifii/library/smart-forms';

import { InputTranslationKey } from '../../../translations';
import { DataSeedsModalComponent } from '../../modals/data-seeds-modal.component';
import { RepeatModalComponent, RepeatModalData } from '../modals/repeat-modal.component';

@Component({
	selector: 'uf-repeat-group',
	templateUrl: './repeat.html',
	styleUrls: ['../form-group.less', './repeat.less'],
})
export class RepeatComponent implements FormField, OnInit {

	@HostBinding('class.uf-form-group') groupClassName = true;

	field: RuntimeField;
	scope: Scope;
	control: UfControlArray;
	contentChange: EventEmitter<any>;
	
	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;
	protected readonly fieldTemplates = FieldTemplate;
	protected formSettings = inject(FormSettings);
	protected formFieldService = inject(FormFieldService);
	protected options: DataSeed[] = [];
	protected disabled: boolean;
	
	private formService = inject(FormService);
	private scopeManager = inject(ScopeManager);
	private window = inject<Window>(WindowWrapper);
	private windowResizeEventHandler = inject(WindowResizeEventHandler, { optional: true });
	private destroy = inject(DestroyRef);
	private toast = inject(ToastService);
	private translate = inject(TranslateService);
	private modalService = inject(ModalService);
	private contextProvider = inject(ContextProvider);
	private expressionParser = inject(ExpressionParser);
	private workflow = inject(WorkflowService);
	private formRevisionService = inject(FormRevisionService);
	private componentRegistry = inject(ComponentRegistry);
	private formDebugger = inject(FormDebugger, { optional: true });
	private cachedQForRetry: string | undefined;
	private collapsed: boolean;
	private screenWidth: number;

	retry = () => this.loadOptions(this.cachedQForRetry);

	ngOnInit() {
		// First resize computation need to be done always, even when windowResizeEventHandler is not provided
		this.onResize();

		this.windowResizeEventHandler?.register({
			listener: this.onResize.bind(this),
			destroy: this.destroy,
			listenerOptions: true,
		});
	}

	@HostBinding('class.collapsed') protected get hostCollapsed() {
		return this.collapsed;
	}

	@HostBinding('class.disabled') protected get hostDisabled() {
		return (this.field.isReadOnly || this.control.disabled) && !this.isSummary;
	}

	@HostBinding('class.error') protected get hostError() {
		return this.control.submitted && this.control.invalid;
	}

	protected get maxReached(): boolean {
		if (!this.field.maxLength) {
			return false;
		}

		return this.size >= this.field.maxLength;
	}

	protected get size(): number {
		return this.items.length;
	}

	protected get items(): any[] {
		if (!this.field.identifier) {
			return [];
		}

		const fieldValueInScope = this.scope[this.field.identifier];

		if (Array.isArray(fieldValueInScope)) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return fieldValueInScope;
		}

		return [];
	}

	protected get displayForms(): boolean {
		return this.field.template === FieldTemplate.Form ||
			(this.screenWidth <= 768 && (this.field.template === FieldTemplate.HorizontalTable || this.field.template === FieldTemplate.VerticalTable));
	}

	protected get isSummary(): boolean {
		return this.formService.style === FormStyle.Summary;
	}

	protected get canAddItem(): boolean {
		return !this.disabled && !this.control.disabled && !this.field.autofill;
	}

	private get isSortItems(): boolean {
		return this.field.sort != null && !!this.field.template && [FieldTemplate.HorizontalTable, FieldTemplate.HorizontalTableMobile].includes(this.field.template);
	}

	protected childContentChange() {
		// TODO throws an exception in ngFor doChecks
		this.contentChange.emit(this.items);
	}

	protected async loadOptions(q = '') {

		this.cachedQForRetry = q;

		const context = this.scopeManager.createContext();

		context.scope = this.scope;

		try {
			const seeds = await this.formFieldService.search(q, context);

			if (this.field.avoidDuplicates) {
				this.options = seeds.filter((searchResultSeed) => !this.items.find((item) => item._id === searchResultSeed._id));
			} else {
				this.options = seeds;
			}
		} catch (error) {
			this.options = [];
		}
	}

	protected async onScan(value: string) {

		const context = this.scopeManager.createContext();

		context.scope = this.scope;

		let dataSeeds = await this.formFieldService.findAllBy(value, context);

		if (this.field.avoidDuplicates) {
			dataSeeds = dataSeeds.filter((seed) => !this.items.find((item) => item._id === seed._id));
		}

		if (!dataSeeds.length) {
			return;
		}

		if (dataSeeds.length === 1) {
			void this.add(dataSeeds[0]);

			return;
		}

		// if more than one, open modal to select
		const dataSeed = await this.modalService.openMedium(DataSeedsModalComponent, {
			label: this.translate.instant(SharedTermsTranslationKey.ActionSelect) as string,
			dataSeeds,
			field: this.field,
		});

		if (!dataSeed) {
			return;
		}

		void this.add(dataSeed);
	}

	protected async add(seed?: DataSeed) {
		if (this.maxReached) {
			this.toast.warning(this.translate.instant(InputTranslationKey.RepeatInputErrorMaxLength) as string);

			return;
		}

		let scope: Scope | undefined = Object.assign({}, seed ?? {});

		if (!seed) {
			scope._id = generateUUID();
		}

		const index = this.items.length;

		if (!this.displayForms && this.field.template !== FieldTemplate.Form) {
			scope = await this.openEditorModal(index, scope);
			if (!scope) {
				return;
			}
		}

		this.patchValues(index, scope);
	}

	protected async edit(index: number) {
		const scope = await this.openEditorModal(index, this.items[index]);

		if (scope) {
			this.patchValues(index, scope);
		}
	}

	protected remove(index: number) {
		if (!this.field.identifier) {
			return;
		}

		this.control.markAsDirty();
		this.control.markAsTouched();

		this.scope[this.field.identifier]?.splice(index, 1);

		// TODO Force a UI refresh for the rootControl.valueChanges.subscribe actions
		this.control.updateValueAndValidity();
	}

	private extractItemSortableValue(v: string | number | ZonedDateTime | undefined): string | number | undefined {
		if (!v || isString(v) || isNumber(v)) {
			return v;
		}

		return v.value;
	}

	private sortItems(items: Scope[]): Scope[] {

		if (!this.isSortItems) {
			return items;
		}

		const sortStatus = SortStatus.fromString(this.field.sort);

		if (!sortStatus) {
			return items;
		}

		return items.map((item) => ({
			sortValue: this.extractItemSortableValue(this.expressionParser.resolve(
				sortStatus.name,
				undefined,
				item,
				`RepeatComponent sortItems by ${sortStatus.toString()}`,
			) as string | number | ZonedDateTime | undefined),
			item,
		})).sort((wrapperA, wrapperB) => {

			if (wrapperA.sortValue == null || wrapperB.sortValue == null) {
				if (wrapperA.sortValue != null) {
					return -1;
				}
				if (wrapperB.sortValue != null) {
					return 1;
				}

				return 0;
			}

			if (sortStatus.direction === SortDirections.Ascending) {
				return wrapperA.sortValue > wrapperB.sortValue ? 1 :- 1;
			}

			return wrapperA.sortValue > wrapperB.sortValue ? -1 : 1;
		}).map((wrapper) => wrapper.item);
	}

	private patchValues(index: number, scope: Scope) {

		if (!this.field.identifier) {
			return;
		}

		const items = [...this.items];

		items[index] = scope;
		this.scope[this.field.identifier] = this.sortItems(items);

		this.markAsUntouched();
		this.control.markAsDirty();

		// TODO Force a UI refresh for the rootControl.valueChanges.subscribe actions
		this.control.updateValueAndValidity();

		// TODO Inject the seed in the item scope on ScopeManager
		// Get rid of the this.dataSeeds[] and all the chain down binding
		// this.scopeManager."identifier".scope.seed = seed;
		this.formDebugger?.updateValidators(this.control.at(index) as UfFormControl | undefined);
	}

	private openEditorModal(index: number, scope: Scope): Promise<Scope | undefined> {

		const data: RepeatModalData = {
			field: this.field,
			scope,
			index,
		};

		return this.modalService.openLarge(
			RepeatModalComponent,
			data,
			undefined,
			[
				{ provide: FormService, useValue: this.formService },
				{ provide: ScopeManager, useValue: this.scopeManager },
				{ provide: FormDebugger, useValue: this.formDebugger },
				{ provide: ComponentRegistry, useValue: this.componentRegistry },
				{ provide: ContextProvider, useValue: this.contextProvider },
				{ provide: FormSettings, useValue: this.formSettings },
				{ provide: WorkflowService, useValue: this.workflow },
				{ provide: FormRevisionService, useValue: this.formRevisionService },
				{ provide: FormFieldService, useValue: this.formFieldService },
			]);
	}

	private markAsUntouched() {
		/**
		 * If children are in a valid state mark the group
		 * as untouched so error messages are aligned
		 */
		for (const control of controlIterator(this.control)) {
			if (control.invalid) {
				return;
			}
		}
		this.control.markAsUntouched();
	}

	/**
	 * Repeat types are displayed at different screen sizes
	*/
	private onResize() {
		this.screenWidth = this.window.document.documentElement.clientWidth;
	}

}
