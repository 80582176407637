<ng-template [ngIf]="mode === modes.Input">
	<ng-container [ngSwitch]="inputType">
		<ng-template [ngSwitchCase]="fieldType.Date">
			<uf-date [(value)]="$any(value).from" (valueChange)="valueChanged()" label="From" />
			<uf-date [(value)]="$any(value).to" (valueChange)="valueChanged()" label="To" class="gap-top" />
		</ng-template>
		<ng-template [ngSwitchCase]="fieldType.Time">
			<uf-time [(value)]="$any(value).from" (valueChange)="valueChanged()" label="From" />
			<uf-time [(value)]="$any(value).to" (valueChange)="valueChanged()" label="To"
				class="gap-top gap-lg-bottom" />
		</ng-template>
		<ng-template [ngSwitchCase]="fieldType.DateTime">
			<uf-datetime [(value)]="$any(value).from" (valueChange)="valueChanged()" label="From"
				defaultTime="00:00:00" />
			<uf-datetime [(value)]="$any(value).to" (valueChange)="valueChanged()" label="To" defaultTime="00:00:00"
				class="gap-top gap-lg-bottom" />
		</ng-template>
		<ng-template [ngSwitchCase]="fieldType.ZonedDateTime">
			<uf-datetime-tz [(value)]="$any(value).from" (valueChange)="valueChanged()" label="From" class="stacked"
				defaultTime="00:00:00" />
			<uf-datetime-tz [(value)]="$any(value).to" (valueChange)="valueChanged()" label="To" defaultTime="00:00:00"
				class="gap-top gap-lg-bottom stacked" />
		</ng-template>
	</ng-container>
</ng-template>

<ng-container *ngIf="mode === modes.Display && !isEmpty">
	<div *ngIf="value.from" class="uf-chip x-small">
		{{ displayFrom }}
		<button (click)="removeFrom()" type="button" class="uf-action">
			<uf-icon name="delete" />
		</button>
	</div>
	<div *ngIf="value.to" class="uf-chip x-small">
		{{ displayTo }}
		<button (click)="removeTo()" type="button" class="uf-action">
			<uf-icon name="delete" />
		</button>
	</div>
</ng-container>