import { Component, ViewChild } from '@angular/core';
import { Dictionary, FieldType, FieldWidth, PageField } from '@unifii/sdk';

import { MessageLevel, ToastService } from '@unifii/library/common';

import { ConfigurationComponent } from '../../components';

@Component({
	selector: 'sc-show-popups',
	templateUrl: './show-popups.html',
	styleUrls: ['./show-popups.less'],
})
export class ShowPopupsComponent {

	@ViewChild('toastConfiguration') toastConfiguration: ConfigurationComponent;

	readonly levels = MessageLevel;

	toastConfig: Dictionary<PageField> = {
		message: { label: 'Message', type: FieldType.Text, value: 'Toast content', width: FieldWidth.Half },
		displayTime: { label: 'Display Time', type: FieldType.Number, value: 1500, width: FieldWidth.Quarter },
	};

	constructor(private toastService: ToastService) { }

	show(level: MessageLevel) {
		const data = this.toastConfiguration.value;

		this.toastService.info(data.message);
	}

}
