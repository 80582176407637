import { Component, Input, OnInit, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { CellTemplateType, isBoolean, isNumber, isString } from '@unifii/sdk';

import { Scope } from '../../models';
import { CustomCellDisplayValueResolver } from '../../services';
import { DataDisplayComponent, DataDisplayIconValue, DataDisplayImageValue, DataDisplayLozengeValue } from '../data-display';

import { ColumnDisplayDescriptor, TableConfigColumn } from './table-models';

/**
 * This component is used to render a Table cell when the associated column configuration is custom
 */
@Component({
	selector: 'uf-custom-cell',
	template: `<div #target></div>`,
	styleUrls: ['./cell.less'],
})
export class CustomCellComponent<T extends Scope> implements OnInit {

	@Input({ required: true }) item: T;
	@Input({ required: true }) column: TableConfigColumn<T>;
	@Input({ required: true }) index: number;
	@Input({ required: true }) descriptor: ColumnDisplayDescriptor;

	@ViewChild('target', { read: ViewContainerRef, static: true })
	private target: ViewContainerRef;

	private customCellDisplayValueResolver = inject(CustomCellDisplayValueResolver);

	ngOnInit() {
		const { value, template } = this.customCellDisplayValueResolver.getDisplayValue({
			columnDisplayDescriptor: this.descriptor,
			columnConfig: this.column,
			item: this.item,
			index: this.index,
		});
		
		if (this.descriptor.component) {
			const componentRef = this.target.createComponent(this.descriptor.component, { index: 0 });
			
			componentRef.instance.value = value;
			
			return;
		}

		let dataDisplayValue = value;

		if (template) {
			const dataDisplayStringValue = (isString(value) || isBoolean(value) || isNumber(value)) ? `${value}` : null;

			if (dataDisplayStringValue) {
				switch (template.type) {
					case CellTemplateType.Lozenge:
						dataDisplayValue = template.colour ? { label: dataDisplayStringValue, colour: template.colour } satisfies DataDisplayLozengeValue : dataDisplayStringValue;
						break;
					case CellTemplateType.Image:
						dataDisplayValue = { imageUrl: dataDisplayStringValue } satisfies DataDisplayImageValue;
						break;
					case CellTemplateType.Icon:
						dataDisplayValue = { icon: dataDisplayStringValue } satisfies DataDisplayIconValue;
						break;
				}
			}
		}

		const componentRef = this.target.createComponent(DataDisplayComponent, { index: 0 });
		
		componentRef.instance.data = dataDisplayValue;		
	}

}
