import { Directive, ElementRef, Input, OnInit, inject } from '@angular/core';
import { LayoutDirection } from '@unifii/sdk';

export type GridGap = 'xs' | 'sm' | 'md' | 'lg';

/**
 * Reusable way converting common inputs to a grid layout use in
 *  - Radio Options
 *  - Multi-choice Options
 *  - Bool Options
 *  - Cascade-selection Options
 */
@Directive({
	selector: '[ufGrid]',
})
export class GridDirective implements OnInit {

	private readonly sizes: Record<GridGap, string> = {
		xs: '.25rem',
		sm: '.5rem',
		md: '1rem',
		lg: '1.5rem',
	};

	private htmlElement = inject<ElementRef<HTMLElement>>(ElementRef).nativeElement;
	private _direction: `${LayoutDirection}` = 'Row';
	private _rows?: number | null;
	private _columns: number;
	private _cellTotal: number;
	private _columnGap: GridGap | undefined;
	private _rowGap: GridGap | undefined;

	@Input() set direction(direction: `${LayoutDirection}`) {
		this._direction = direction;
		this.updateStyle();
	}

	@Input() set columns(columns: number | string | null | undefined) {
		this._columns = (typeof columns === 'string' ? +columns : columns) ?? 1;
		this.updateStyle();
	}

	@Input() set rows(rows: number | string | null | undefined) {
		this._rows = typeof rows === 'string' ? +rows : rows;
		this.updateStyle();
	}

	@Input() set cellTotal(cellTotal: number | undefined | null) {
		this._cellTotal = cellTotal ? cellTotal : 1;
		this.updateStyle();
	}

	@Input() set columnGap(columnGap: GridGap) {
		this._columnGap = columnGap;
		this.updateStyle();
	}

	@Input() set rowGap(rowGap: GridGap) {
		this._rowGap = rowGap;
		this.updateStyle();
	}

	ngOnInit() {
		this.updateStyle();
	}

	private updateStyle() {

		this.setStyleProperty('display', 'grid');
		this.setStyleProperty('grid-template-columns', `repeat(${this._columns}, 1fr)`);

		let rows = this._rows;

		if (this._direction === 'Column') {
			rows = Math.ceil(this._cellTotal / this._columns);
			this.setStyleProperty('grid-auto-flow', 'column');
		} else {
			this.setStyleProperty('grid-auto-flow', 'row');
		}

		if (rows) {
			this.setStyleProperty('grid-template-rows', `repeat(${rows}, 1fr)`);
		}

		if (this._columnGap) {
			this.setStyleProperty('grid-column-gap', this.sizes[this._columnGap]);
		}

		if (this._rowGap) {
			this.setStyleProperty('grid-row-gap', this.sizes[this._rowGap]);
		}
	}

	private setStyleProperty(property: string, value: string) {
		this.htmlElement.style.setProperty(property, value);
	}

}

