<ng-container *ngIf="field.parent?.type !== fieldType.Survey; else surveyInput">
	<uf-radio *ngIf="field?.template === fieldTemplate.Radio" [ngClass]="cssClass" [label]="field.label"
		[control]="control" [options]="options" [nameProperty]="nameProperty" [valueProperty]="valueProperty"
		[columns]="field.columnCount" [direction]="field.layoutDirection" [trackBy]="trackBy" [(value)]="content"
		(valueChange)="changed($event)">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field?.help" [content]="field.help" />
	</uf-radio>

	<uf-radio *ngIf="field?.template === fieldTemplate.RadioWithContent" [ngClass]="cssClass" [label]="field.label"
		[control]="control" [options]="options" [nameProperty]="nameProperty" [valueProperty]="valueProperty"
		[columns]="field.columnCount" [direction]="field.layoutDirection" [trackBy]="trackBy" [(value)]="content"
		(valueChange)="changed($event)">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field?.help" [content]="field.help" />
		<uf-content-radios #radioOptions options />
	</uf-radio>

	<uf-radio *ngIf="field?.template === fieldTemplate.OptionWithContent" [ngClass]="cssClass" [label]="field.label"
		[control]="control" [options]="options" [nameProperty]="nameProperty" [valueProperty]="valueProperty"
		[columns]="field.columnCount" [direction]="field.layoutDirection" [trackBy]="trackBy" [(value)]="content"
		(valueChange)="changed($event)">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field?.help" [content]="field.help" />
		<uf-content-radios #radioOptions [class.hide-icon]="true" options />
	</uf-radio>

	<uf-select *ngIf="field?.template === fieldTemplate.DropDown" [ngClass]="cssClass" [label]="field.label"
		[control]="control" [placeholder]="field.placeholder" [options]="options" [nameProperty]="nameProperty"
		[valueProperty]="valueProperty" [trackBy]="trackBy" [(value)]="content" (valueChange)="changed($event)">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field?.help" [content]="field.help" />
	</uf-select>

	<uf-data-seed-mappings-display *ngIf="field && field.sourceConfig" [sourceConfig]="field.sourceConfig"
		[dataSeed]="content" />
</ng-container>

<ng-template #surveyInput>
	<uf-radio [ngClass]="cssClass" [control]="control" [label]="field.label" [options]="field.parent?.options"
		[nameProperty]="nameProperty" [valueProperty]="valueProperty" [trackBy]="trackBy"
		[columns]="field.parent?.options?.length" [rows]="1" [(value)]="content" (valueChange)="changed($event)"
		class="survey center-all">
		<!-- TODO check if suffix is required here the word 'optional' takes up a lot of space -->
		<!-- <span *ngIf="suffix" class="suffix">{{ suffix }}</span> -->
		<uf-help *ngIf="field?.help" [content]="field.help" />
	</uf-radio>
</ng-template>