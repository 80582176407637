import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getHours, getMinutes } from 'date-fns';

import { UfControl } from '../../../controls';
import { CommonTranslationKey, SharedTermsTranslationKey } from '../../../translations';

export interface TimePickerInput {
	value: number;
	max: number;
	min: number;
	display: string;
}

export interface TimePickerData {
	timezone: string;
	format: string;
	value: string;
	step?: string | number | null;
	is24hourDisabled?: boolean | null;
}

@Component({
	selector: 'uf-time-picker',
	templateUrl: './time-picker.html',
	styleUrls: ['./time-picker.less'],
})
export class UfDaytimePickerComponent implements OnInit {
	
	@Input() amPm: boolean | null | undefined = true;
	@Output() dateChange = new EventEmitter<Date>();

	is24hours: boolean;
	ampmValue = '1';

	hourControl = new UfControl();
	minuteControl = new UfControl();

	hoursSelect: string[] = [];
	minutesSelect: string[] = [];

	hours: TimePickerInput = {
		value: 0,
		max: 23,
		min: 0,
		display: '',
	};

	minutes: TimePickerInput = {
		value: 0,
		max: 59,
		min: 0,
		display: '',
	};

	protected readonly commonTK = CommonTranslationKey;
	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly timeLabels = {
		hour: inject(TranslateService).instant(this.sharedTermsTK.TimeHourLabel),
		minute: inject(TranslateService).instant(this.sharedTermsTK.TimeMinuteLabel),
	};

	private _date: Date = new Date();
	private _step = 1;
	private initialized = false;

	ngOnInit() {
		this.is24hours = !this.amPm;

		this.init();
		this.setHoursAndMinutes(getHours(this.date), getMinutes(this.date));

		this.initialized = true;
	}

	@Input({ required: true }) set date(v: Date) {
		this._date = v;

		if (this.hours.value === getHours(v) && this.minutes.value === getMinutes(v)) {
			return;
		}

		if (this.initialized) {
			this.setHoursAndMinutes(getHours(v), getMinutes(v));
		}
	}

	get date() {
		return this._date;
	}

	get step(): number {
		return this._step;
	}

	@Input() set step(v: number | string | null | undefined) {
		this._step = this.getStep(v);
	}

	updateHours(operator: string) {
		// increase or decrease hours
		const value = this.addSubtract(operator, this.hours.value, 1);

		this.hours.value = this.getHourValue(operator, value);
		this.hours.display = this.getHoursDisplay(this.hours.value);

		this.updateValue();
	}

	updateMinutes(operator: string) {
		this.setHoursAndMinutes(this.hours.value, this.addSubtract(operator, this.minutes.value, this.step));
	}

	updateAmPm(value: string) {
		this.ampmValue = value;

		if (value === '0' && this.hours.value >= 12) {
			// hours must be between 0 - 11 hours
			this.hours.value = this.hours.value - 12;
		}

		// Time is pm
		if (value === '1' && this.hours.value < 12) {
			// Hours must be between 12 - 23 hours
			this.hours.value = this.hours.value + 12;
		}
	}

	updateValue() {
		// Guard incase update tries to run with invalid value
		if (this.hours.value == null || this.minutes.value == null) {
			return;
		}

		this.hourControl.setValue(this.hours.display, { emitEvent: false });
		this.minuteControl.setValue(this.minutes.display, { emitEvent: false });

		const date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), this.hours.value, this.minutes.value);
		
		this.dateChange.emit(date);
	}

	changeHour(event: Event) {
		const hour = (event.target as HTMLSelectElement).value;
		const hourValue = this.convertAmPmTo24(hour);

		this.hours.value = hourValue;
		this.hours.display = this.getHoursDisplay(hourValue);
		this.updateValue();
	}

	changeMinute(event: Event) {
		const minute = parseInt((event.target as HTMLSelectElement).value);

		this.minutes.value = minute;
		this.minutes.display = this.getMinutesDisplay(minute);
		this.updateValue();
	}

	private convertAmPmTo24(hour: string): number {
		let h = parseInt(hour);

		if (!this.is24hours) {
			h = this.ampmValue === '1' ? h < 12 ? h + 12 : h : h === 12 ? 0 : h;
		}

		return h;
	}

	private init() {
		this.hoursSelect = [];
		this.minutesSelect = [];
		
		if (this.is24hours) {
			for (let i = 0; i < 24; i++) {
				this.hoursSelect.push(i.toString());
			}
		} else {
			for (let i = 1; i <= 12; i++) {
				this.hoursSelect.push(i.toString());
			}
		}
		
		for (let i = 0; i <= 59; i += (this.step || 1)) {
			this.minutesSelect.push(i.toString());
		}
		
		this.minutes.max = 60 - this.step;
	}

	private setHoursAndMinutes(hours: number, minutes: number) {
		const adjusted = this.getAdjustedMinutesAndHours(hours, minutes);

		this.hours.value = adjusted.hours;
		this.hours.display = this.getHoursDisplay(adjusted.hours);

		this.minutes.value = adjusted.minutes;
		this.minutes.display = this.getMinutesDisplay(adjusted.minutes);

		// Always run update as increment may change
		this.updateValue();
	} 

	private getAdjustedMinutesAndHours(hours: number, minutes: number): { hours: number; minutes: number } {
		minutes = this.roundUpMinutes(minutes);

		if (minutes < 0) {
			minutes = this.minutes.max;
			hours = this.getValidHour(hours - 1);
		}

		if (minutes === 60) {
			minutes = 0;
			hours = this.getValidHour(hours + 1);
		}

		return { hours, minutes };
	}

	private addSubtract(operator: string, value: number, increment: number): number {
		return operator === '+' ?
			value + increment :
			value - increment;
	}

	private getValidHour(hour: number): number {
		return hour > 23 ? 0 : hour < 0 ? 23 : hour;
	}

	private getHourValue(operator: string, value: number): number {
		/** 24th or a negative hour is not a valid hour */
		if (operator === '+') {
			return value > 23 ? 0 : value;
		}

		return value < 0 ? 23 : value;
	}

	private getStep(step: string | number | null | undefined) {
		if (step == null) {
			return 1;
		}

		// Force to integer
		if (typeof +step === 'number' && (+step / 60 >= 1) && +step < 3601) {
			// Step will be passed as seconds perform conversion to use
			// Ensure that number is valid step
			const validSteps = [1, 2, 3, 4, 5, 10, 12, 15, 20, 30, 60];

			return validSteps.includes(step as number / 60) ? step as number / 60 : 1;
		}

		return 1;
	}

	private roundUpMinutes(minutes: number): number {
		return Math.ceil(minutes / this.step) * this.step;
	}

	private getMinutesDisplay(minutes: number): string {
		const str = minutes.toString();

		return str.length === 1 ? '0' + str : str;
	}

	private getHoursDisplay(hours: number): string {

		// if 24 hours are always correct
		if (this.is24hours) {
			return hours.toString();
		}

		if (hours === 0) {
			this.updateAmPm('0');

			return '12';
		}

		if (hours === 12) {
			this.updateAmPm('1');

			return hours.toString();
		}

		if (hours > 12) {
			this.updateAmPm('1');

			return (hours - 12).toString();
		}

		this.updateAmPm('0');

		return hours.toString();
	}

}
