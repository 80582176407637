import { Component, OnInit } from '@angular/core';
import { Dictionary, FieldType } from '@unifii/sdk';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-tables.html',
})
export class ShowTablesComponent implements OnInit {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	// Demo
	readonly demoTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name', fieldType: FieldType.Text, placeholder: 'John Smith' },
		{ identifier: 'username', name: 'Username', fieldType: FieldType.Text, placeholder: 'john.smith@emailservice.com' },
		{ identifier: 'occupation', name: 'Occupation', fieldType: FieldType.Text, placeholder: 'Lawyer' },
		{ identifier: 'status', name: 'Status', level: 'success', fieldType: FieldType.Bool },
	];
	readonly demoTableInfo = [
		{ name: 'Joey Ramone', status: 'approved', username: 'joey@ramones.com.au', occupation: 'Lead singer' },
		{ name: 'Dee Dee Ramone ', status: 'approved', username: 'deedee@ramones.com.au', occupation: 'Bass player' },
		{ name: 'Johnny Ramone', status: 'approved', username: 'johnny@ramones.com.au', occupation: 'Guitar player' },
		{ name: 'Marky Ramone', status: 'approved', username: 'marky@ramones.com.au', occupation: 'Drummer' },
		{ name: null, status: null, username: null, occupation: null },
	];

	// api information
	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'uf-table', type: 'base class', description: 'Sets base style of avatar' },
		{ name: 'accent', type: 'modifier', description: 'Adds prominant style to table' },
		{ name: 'outline', type: 'modifier', description: 'Adds outline style to table cells' },
		{ name: 'input-cell', type: 'modifier', description: 'Makes cell compatible with HTML inputs' },
		{ name: 'break-word', type: 'modifier', description: 'Allows breaking of words within table. Can be applied per cell or to the whole table' },
		{ name: 'compact', type: 'modifier', description: 'Makes the "No record error" smaller for pages with multiple tables' },
	];

	readonly templateSource = `
		<table class="uf-table#cssClasses">
			<thead>
				<tr>
					<th>ColumnName</th>
					<th>ColumnName</th>
				</tr>
			</thead>
			#content
		</table>
	`;
	readonly staticContent = `
			<tbody>
				<tr>
					<td>ColumnContent</td>
					<td>ColumnContent</td>
				</tr>
			</tbody>
		`;
	readonly editableContent = `
			<tbody>
				<tr>
					<td class="input-cell">
						<uf-text class="table-cell"></uf-text>
					</td>
					<td class="input-cell">
						<uf-checkbox></uf-checkbox>
					</td>
				</tr>
			</tbody>
		`;

	// Interactive
	readonly colourOptions = [
		{ name: 'Default', value: '' },
		{ name: 'Accent', value: 'accent' },
	];
	readonly contentOptions = [
		{ name: 'Static Content', value: '' },
		{ name: 'Word Wrap', value: 'words' },
		{ name: 'Editable', value: 'inputs' },
	];

	readonly sizeOptions = [
		{ name: 'Default', value: '' },
		{ name: 'Small', value: 'small' },
	];

	template: string;
	colour = '';
	content = '';
	size = '';
	outline = false;
	demoConfig: ColumnInfo[] = [];
	demoTable: Dictionary<any>[] = [];

	ngOnInit() {
		this.contentChange();
		this.modifierChange();
	}

	contentChange() {

		this.demoConfig = this.demoTableConfig.map((config) => {

			if (this.content === 'inputs') {
				return config;
			}

			config = Object.assign({}, config);
			delete config.fieldType;
			delete config.placeholder;

			return config;
		});

		this.demoTable = this.demoTableInfo.filter((row) => (this.content === 'inputs' || row.name != null));
	}

	modifierChange() {

		const content = this.content === '' ? this.staticContent : this.editableContent;
		let cssClasses = [this.content, this.colour, this.size, this.outline ? 'outline' : ''].join(' ').trim();

		if (cssClasses !== '') {
			cssClasses = ' ' + cssClasses;
		}

		this.template = this.templateSource
			.replace('#content', content)
			.replace('#cssClasses', cssClasses);

	}

}
