import { Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'uf-step-label',
	templateUrl: './step-label.html',
	styleUrls: ['./step-label.less'],
})
export class StepLabelComponent {

	// Display
	@Input() index: number;
	@Input() label: string;
	// State
	@Input() active: boolean; /** For changing appearance */
	@Input() readonly: boolean;
	@Input() submitted: boolean; /** When step has been activated then deactivated */
	@Input() valid: boolean; /** Control Value */

	@HostBinding('class.clickable') get clickableClass() {
		return this.submitted && this.valid && !this.active;
	}

}
