import { ValidatorFn } from '@angular/forms';
import { HierarchyPath, HierarchyUnitInfo, HierarchyUnitWithPath, isDictionary, isHierarchyPath, isHierarchyStep, isHierarchyUnitsPath, isNumber, isString, isStringNotEmpty } from '@unifii/sdk';

import { HierarchyLeafFormat, SEPARATOR_SLASH } from '../constants';
import { HierarchyUnitIdentifier, isHierarchyUnitIdentifier } from '../models';

import { custom } from './validator-functions';

/**
 * Extract the unit id from the input
 * @param hierarchyIdentifier
 * @returns unit's id
 */
export const getId = (hierarchyIdentifier?: HierarchyUnitIdentifier): string | null => {

	const identifier = isDictionary(hierarchyIdentifier) ? hierarchyIdentifier.id : hierarchyIdentifier;

	if (isStringNotEmpty(identifier) || isNumber(identifier)) {
		return `${identifier}`;
	}

	return null;
};

/**
 * Is the unit a descendant of parent
 * @param parent unit - identifier
 * @param unit potential - descendant
 * @param excludeParent - exclude the parent from the allowed descendants [default true]
 * @returns true when the unit is a descendant of parent
 */
export const isDescendantOf = (unit: HierarchyUnitWithPath, parent: HierarchyUnitIdentifier, excludeParent = true): boolean => {
	// Parent and Unit match
	if (unit.id === getId(parent)) {
		return !excludeParent;
	}

	// Parent id must be within the child path
	return unit.path.some((step) => step.id === getId(parent));
};

/**
 * Transform the Hierarchy steps into a string
 *
 * @param path hierarchy steps
 * @param format 'leaf' will consider only the last step, all steps otherwise
 * @returns the string representation of the unit's path
 */
export const pathToDisplay = (path: HierarchyPath, format?: string): string => {

	const lastLeaf = path[path.length - 1];
	const scopedPath = format === HierarchyLeafFormat ? lastLeaf ? [lastLeaf] : [] : path;

	return scopedPath.map(({ label }) => label).join(SEPARATOR_SLASH);
};

/**
 * Build a validator to check that the selected unit is not in the unselectable list
 * @param unselectableUnits - list of units invalid for selection 
 * @param message - validator error message
 * @returns ValidatorFn
 */
export const getUnselectableUnitsValidator = (unselectableUnits: HierarchyUnitIdentifier[], message: string): ValidatorFn =>
	custom((unitInfo: HierarchyUnitInfo | null) => !unitInfo || !unselectableUnits.length || !unselectableUnits.some((unselectableUnit) => !isDictionary(unitInfo) || getId(unselectableUnit) === unitInfo.id), message);

/**
 * Build a validator to check that the selected unit is descendant of one of the parents
 * @param parents - units' identifier
 * @param message - validator error message
 * @returns ValidatorFn
 */
export const getDescendantsValidator = (parents: HierarchyUnitIdentifier[], allowParents: boolean, message: string): ValidatorFn =>
	custom((unitInfo: HierarchyUnitWithPath | null) => !unitInfo || !parents.length || parents.some((parent) => isDescendantOf(unitInfo, parent, !allowParents)), message);

/**
 * Extract units' id from an unknown format input
 * by matching all potential hierarchy associated data models
 * @param input of unknown type
 * @returns units' ids
 */
export const toHierarchyUnitsIds = (input: unknown): string[] | null => {

	const inputs: unknown[] = Array.isArray(input) ? input : [input];

	let identifiers: string[] = [];

	for (const v of inputs) {
		if (isHierarchyUnitIdentifier(v)) {
			isString(v) ? identifiers.push(v) : identifiers.push(v.id);
			continue;
		}

		if (isHierarchyStep(v)) {
			identifiers.push(v.id);
			continue;
		}

		if (isHierarchyPath(v)) {
			const lastStep = v[v.length - 1];

			if (lastStep) {
				identifiers.push(lastStep.id);
			}
			continue;
		}

		if (isHierarchyUnitsPath(v)) {
			for (const path of v) {
				const pathLastStep = path[path.length - 1];

				if (pathLastStep) {
					identifiers.push(pathLastStep.id);
				}
			}
		}
	}

	identifiers = identifiers.map((identifier) => identifier.trim()).filter(isStringNotEmpty);

	return identifiers.length ? identifiers : null;
};
