<uf-panel class="container">
	<div [ngClass]="[containerClassName]">
		<div class="pad-sides col">
			<h1 *ngIf="data.title">{{ data.title }}</h1>
			<uf-message *ngIf="data.warnings?.includes('UnderConstruction')" class="warning gap-bottom" icon="warning"
				content="Under construction" />
			<uf-message *ngIf="data.warnings?.includes('Experimental')" class="warning gap-bottom" icon="warning"
				content="Experimental component requires testing and tweaking when more use cases are found" />
			<uf-message *ngIf="!data.id" class="error gap-top gap-bottom" icon="error" content="Not Found!" />
			<ng-container *ngIf="data.briefDescription?.trim()">
				<h2>Overview</h2>
				<div [markdownRenderer]="data.briefDescription" class="body-copy"></div>
			</ng-container>
			<h2 *ngIf="editorRef.nativeElement.hasChildNodes()">Interactive Example</h2>
			<div class="uf-grid gap-bottom">
				<ng-content select="[editorInputs]"></ng-content>
			</div>
		</div>

		<div #editor>
			<ng-content select="[editor]"></ng-content>
		</div>

		<h2 *ngIf="data.whenToUse?.trim()" class="pad-sides">Usage</h2>
		<div [markdownRenderer]="data.whenToUse" class="body-copy pad-sides"></div>

		<div *ngIf="data.notes?.trim()" class="pad-sides">
			<h2>Do's & Dont's</h2>
			<div [markdownRenderer]="data.notes" class="body-copy"></div>
		</div>

		<div *ngIf="data.relatedElements" class="pad-sides">
			<h2>Related Elements</h2>
			<div class="row">
				<a *ngFor="let element of data?.relatedElements" [href]="element._title" class="uf-chip">
					{{element.recordName}}
				</a>
			</div>
		</div>

		<div *ngIf="data.examples?.trim()" class="pad-sides">
			<h2>Features & Theming</h2>
			<div [markdownRenderer]="data.examples"></div>
		</div>

		<div *ngIf="apiRef.nativeElement.hasChildNodes()" class="pad-sides">
			<h2>API</h2>
		</div>

		<div #api>
			<ng-content select="[api]"></ng-content>
		</div>
	</div>

	<div *ngIf="showWidthToggle" class="page-size-btn uf-box col">
		<button (click)="changePageSize(-1)" type="button" class="uf-action tertiary">
			<uf-icon name="arrowBack" />
		</button>
		<button (click)="changePageSize(1)" type="button" class="uf-action tertiary">
			<uf-icon name="arrowForward" />
		</button>
	</div>
</uf-panel>