<sc-page-content width="lg" identifier="dividers">

	<ng-container editorInputs>
		<uf-radio [columns]="1" [options]="dividerOptions" [(value)]="type" (valueChange)="typeChange()" class="col-6"
			label="Type" valueProperty="value" />
		<uf-radio [columns]="1" [options]="styleOptions" [(value)]="style" (valueChange)="modifierChange()"
			class="col-6" label="Style" valueProperty="value" />
		<uf-radio [columns]="1" [options]="sizeOptions" [(value)]="size" (valueChange)="modifierChange()" class="col-6"
			label="Gap Sizes" valueProperty="value" />
		<uf-radio [columns]="1" [options]="weightOptions" [(value)]="weight" (valueChange)="modifierChange()"
			class="col-6" label="Weight" valueProperty="value" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template">
			<ng-container [ngSwitch]="type">

				<div *ngSwitchCase="dividerTypes.DividerVertical" class="uf-box condensed pad row default"
					style="max-width: 560px; margin: auto;">
					<div class="grow">
						<ng-container *ngTemplateOutlet="summaryBlock" />
					</div>
					<div [ngClass]="[size,style,weight]" class="uf-divider-vertical"></div>
					<div class="grow">
						<ng-container *ngTemplateOutlet="termsBlock" />
					</div>
				</div>

				<div *ngSwitchDefault class="uf-box uf-grid condensed pad-sides default"
					style="max-width: 420px; margin: auto;">
					<div class="col-12">
						<ng-container *ngTemplateOutlet="summaryBlock" />
					</div>
					<div [ngClass]="[size,style,weight]" class="col-12 uf-divider"></div>
					<div class="col-12">
						<ng-container *ngTemplateOutlet="termsBlock" />
					</div>
				</div>

			</ng-container>
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="tableConfig" [rows]="tableInfo" />
	</ng-container>

</sc-page-content>


<ng-template #summaryBlock>
	<h3>Payment Summary</h3>
	<uf-data-display-list [items]="{ cost: '$20:00', delivery: '$10:00', total: '$30:00' }"
		class="small-label pad-bottom" />
</ng-template>

<ng-template #termsBlock>
	<div class="body-copy">
		<p class="small">Terms and conditions are payments are to be made to the Company without any deduction or
			discount
			other than as stated in these terms or in the relevant invoice or statement</p>
	</div>
</ng-template>