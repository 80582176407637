import { DataSourceType, Dictionary, Field, FieldValidator, ValidatorType, Variation } from '@unifii/sdk';
import { ExpressionType, Identifier } from 'jsep';

import { FieldAdapter, RuntimeField, SourceConfig } from '../../models';
import { ExpressionParser } from '../expression-parser';

export class FieldDependenciesAdapter implements FieldAdapter {

	private fields = new Map<string, Field[]>();
	private dependencyIdentifiers = new Map<Field, string[]>();

	constructor(private expressionParser: ExpressionParser) { }

	transform(field: Field) {

		const identifiers = this.extractExpressionIdentifiers(field);

		if (identifiers.length) {
			this.dependencyIdentifiers.set(field, identifiers);
		}

		// Creates look up for fields that can be references in an expression
		// Identifier can be duplicate within the scope for Repeat
		if (field.identifier) {
			const fields = this.fields.get(field.identifier) ?? [];

			this.fields.set(field.identifier, [field, ...fields]);
		}
	}

	reset() {
		this.fields.clear();
		this.dependencyIdentifiers.clear();
	}

	done() {
		for (const [field, value] of this.dependencyIdentifiers) {
			const dependencies = this.identifierToFieldMapper(value, this.fields);

			(field as unknown as RuntimeField).dependencies = dependencies as unknown as RuntimeField[];
		}
	}

	private extractExpressionIdentifiers(field: Field): string[] {

		const validatorDeps = this.getValidatorDeps(field.validators);
		const showIfDeps = this.getExpressionDeps(field.showIf);
		const variationDeps = this.getVariationDeps(field.variations);
		const datasourceDeps = this.getDatasourceDeps((field as unknown as RuntimeField).sourceConfig);
		const bindToDeps = this.getExpressionDeps(field.bindTo);

		/** dedupe dependencies */
		return [...new Set(([] as string[])
			.concat(validatorDeps, showIfDeps, variationDeps, datasourceDeps, bindToDeps))]
		// prevent field from depending on itself
			.filter((d) => d !== field.identifier);

	}

	private getExpressionDeps(expString?: string | null): string[] {

		if (!expString) {
			return [];
		}

		const expression = this.expressionParser.parse(expString);

		if (!expression) {
			return [];
		}

		const deps: string[] = [];

		if (expression.type as ExpressionType === 'Identifier') {
			deps.push((expression as Identifier).name);
		}

		for (const item of this.iterateValues(expression)) {
			// TODO check that identifiers are catching all deps
			if (item && item.type as ExpressionType === 'Identifier') {
				deps.push(item.name);
			}
		}

		return deps;
	}

	private getValidatorDeps(validators?: FieldValidator[]): string[] {

		if (!validators) {
			return [];
		}

		return validators
			.filter((f) => [ValidatorType.Expression, ValidatorType.ItemExpression].includes(f.type))
			.reduce<string[]>((result, validator) => {

				const deps = this.getExpressionDeps(validator.value);

				return result.concat(deps);
			}, []);
	}

	private getVariationDeps(variations?: Variation[]): string[] {

		const deps: string[] = [];

		if (!variations) {
			return deps;
		}

		for (const variation of variations) {
			deps.push(...this.getExpressionDeps(variation.condition));
			deps.push(...this.getValidatorDeps(variation.validators));
		}

		return deps;
	}

	private identifierToFieldMapper(identifiers: string[], fieldLookup: Map<string, Field[]>): Field[] {

		return identifiers.reduce<Field[]>((result, identifier) => {

			const fields = fieldLookup.get(identifier);

			if (fields) {
				result.push(...fields);
			}

			return result;
		}, []);
	}

	private getDatasourceDeps(sourceConfig?: SourceConfig): string[] {

		if (sourceConfig == null || sourceConfig.type !== DataSourceType.External || sourceConfig.inputs.length === 0) {
			return [];
		}

		return sourceConfig.inputs
			.map((input) => this.getExpressionDeps(input.value))
			.reduce((result, arr) => result.concat(...arr), []);
	}

	/** flatten complex object into values */
	private *iterateValues(obj: Dictionary<any>): Iterable<any> {

		for (const key of Object.keys(obj)) {
			const value = obj[key];

			if (value == null) {
				yield '';
			}

			if (value && typeof value === 'object' && Object.keys(value).length) {
				yield *this.iterateValues(value);
			}

			if (Array.isArray(value)) {
				for (const v of value) {
					yield *this.iterateValues(v);
				}
			}

			yield value;
		}
	}

}
