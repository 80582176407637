import { Injectable, inject } from '@angular/core';
import { mapUserToUserContext } from '@unifii/sdk';

import { AppContext, ContextProvider } from '@unifii/library/common';

import { ShowcaseRepositoryService } from './repository.service';

@Injectable()
export class ShowcaseContextProvider implements ContextProvider {

	private repository = inject(ShowcaseRepositoryService);

	get(): AppContext {
		return {
			user: mapUserToUserContext(this.repository.user),
		};
	}

}
