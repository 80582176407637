import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

@Component({
	selector: 'sc-show-form-page',
	templateUrl: './show-form-page.html',
})
export class ShowFormPageComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

}
