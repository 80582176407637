<label *ngIf="label">
	{{label}}
	<ng-content></ng-content>
</label>
<!-- Box -->
<div *ngIf="template === 'box'">
	<p *ngIf="value" class="small gap-sm-top gap-none-bottom">{{ value | dataDisplay: { type: 'HierarchyUnit' } }}</p>
	<p *ngIf="!value && placeholder" class="small gap-sm-top gap-none-bottom">{{ placeholder }}</p>
	<button *ngIf="!disabled" #button (click)="showPicker()" class="uf-button x-small right" type="button">
		{{ (value == null ? sharedTK.ActionAdd : sharedTK.ActionEdit ) | translate }}
	</button>
</div>
<!-- Input -->
<div *ngIf="template === 'input'" class="input-wrap">
	<input [value]="value | dataDisplay: { type: 'HierarchyUnit' } " [readonly]="true" [attr.placeholder]="placeholder"
		(focus)="showPicker()" type="text">
	<div class="input-icon">
		<uf-icon name="hierarchy" />
	</div>
</div>

<uf-error [control]="control" />