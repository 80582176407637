import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, inject } from '@angular/core';

import { WindowWrapper } from '../../native';

import { StepComponent } from './step.component';
import { StepperScrollManager } from './stepper-scroll-manager';
	
export enum StepperTemplate {
	Vertical = 'vertical',
	Horizontal = 'horizontal'
}

@Component({
	selector: 'uf-stepper',
	templateUrl: './stepper.html',
	styleUrls: ['./stepper.less'],
})
export class StepperComponent<T> implements AfterViewInit {

	@Input() hideComplete = false; /** provide ability to supply your own complete action */
	@Input() showLabels = true;
	@Input() scrollAnchor?: HTMLElement; // Element that stepper will scroll to after each transition
	@Input() orientation = StepperTemplate.Horizontal;
	@Input() startingStep?: T;
	@Output() stepActivated = new EventEmitter<StepComponent<T>>();
	@Output() stepCompleted = new EventEmitter<StepComponent<T>>();
	@Output() stepDeactivated = new EventEmitter<StepComponent<T>>();
	@Output() lastStepVisibleChange = new EventEmitter<boolean>();
	@Output() stepFailedValidation = new EventEmitter<StepComponent<T>>();

	readonly template = StepperTemplate;

	active = 0;
	steps: StepComponent<T>[] = [];
	lastStepVisible: boolean;

	private scrollManager?: StepperScrollManager;
	private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
	private window = inject(WindowWrapper) as Window;
	private viewReady = false;
	private hiddenSteps = new Set<StepComponent<T>>();

	ngAfterViewInit() {
		if (this.orientation === StepperTemplate.Horizontal) {
			const parent = this.elementRef.nativeElement.parentElement as HTMLElement;

			this.scrollManager = new StepperScrollManager(parent, this.window, this.scrollAnchor);
		}

		this.updateLastStepVisible();
		this.emitActiveStep();

		this.viewReady = true;
	}

	register(step: StepComponent<T>) {
		step.index = this.steps.length;
		this.steps.push(step);

		if ((step.key && this.startingStep === step.key) || !this.startingStep && this.steps.length === 1) {
			this.active = step.index;
		}

		if (this.viewReady) {
			this.updateLastStepVisible();
			this.emitActiveStep();
		}
	}
	
	back(step: StepComponent<T>) {
		const index = this.steps.findIndex((s) => s === step);

		if (!index) {
			return;
		}

		this.activateStep('previous');
	}

	navigateTo(step: StepComponent<T>) {
		if (this.hiddenSteps.has(step)) {
			return;
		}
		
		const requestedIndex = this.steps.findIndex((s) => s === step);

		// needs to be a valid index change or validating steps need to be initialized from a submitted step
		if (requestedIndex === -1 ||
			requestedIndex === this.active ||
			(step.validate && this.steps[requestedIndex - 1]?.submitted === false)) {
			return;
		}
		
		if (requestedIndex > this.active) {
			const activeControl = this.steps[this.active]?.control;
			
			activeControl?.setSubmitted();
			if (activeControl?.invalid) {
				
				return;
			}
		}
		
		this.setActiveStep(requestedIndex);
	}
	
	updateStepVisibility(step: StepComponent<T>, isHidden: boolean) {
		isHidden ? this.hiddenSteps.add(step) : this.hiddenSteps.delete(step);

		this.updateLastStepVisible();
	}

	navigateToByKey(key: T) {
		this.scrollManager?.reset();
		
		const step = this.steps.find((s) => s.key === key);
		
		if (step == null) {
			return;
		}

		this.navigateTo(step);
	}
	
	completeStep(step: StepComponent<T>) {
		const index = this.steps.findIndex((s) => s === step);
		const lastActiveStepIndex = this.steps.filter((s) => !this.hiddenSteps.has(s)).length - 1;
		
		if (index === lastActiveStepIndex) {
			return;
		}
		
		this.stepCompleted.emit(step);
		this.activateStep('next');
	}
	
	private getLastStep(): StepComponent<T> | undefined {
		for (let i = this.steps.length - 1; i >= 0; i--) {
			const step = this.steps[i];

			if (step && !this.hiddenSteps.has(step)) {
				return step;
			}
		}

		return undefined;
	}
	
	private updateLastStepVisible() {
		const lastStepVisible = this.steps[this.active] === this.getLastStep();

		if (lastStepVisible !== this.lastStepVisible) {
			this.lastStepVisible = lastStepVisible;
			this.lastStepVisibleChange.emit(lastStepVisible);
		}
	}

	private activateStep(direction: 'previous' | 'next') {
		const increment = direction === 'previous' ? -1 : 1;

		let index = this.active + increment;

		while (index >= 0 && index < this.steps.length) {
			const step = this.steps[index];

			if (step &&	!this.hiddenSteps.has(step)) {
				this.setActiveStep(index);

				return;
			}

			index += increment;
		}
	}

	private setActiveStep(activeIndex: number) {
		const deactivatedStep = this.steps[this.active];

		if (deactivatedStep) {
			this.stepDeactivated.emit(deactivatedStep);
		}

		this.active = activeIndex;
		this.scrollManager?.reset();

		const activeStep = this.steps[activeIndex];

		if (activeStep) {
			this.updateLastStepVisible();
			
			this.stepActivated.emit(activeStep);
		}
	}

	private emitActiveStep() {
		const activeStep = this.steps[this.active];

		if (activeStep) {
			this.stepActivated.emit(activeStep);
		}
	}

}
