<uf-progress [inProgress]="inProgress" class="large" />
<uf-panel class="stretch col">
	<div class="uf-app-bar">
		<button [title]="sharedTermsTK.ActionClose | translate" (click)="close()" ufAutofocus class="uf-action tertiary"
			type="button">
			<uf-icon name="close" />
		</button>
		<h3>{{label}}</h3>
	</div>
	<div class="grow pad">
		<div *ngIf="form" [formGroup]="form" class="uf-grid">

			<uf-select [formControlName]="controlKeys.Type" [label]="commonTK.ContentTypeTypeLabel | translate"
				[placeholder]="commonTK.ContentTypeTypeLabel | translate" [options]="allowedTypes"
				(valueChange)="updateType()" valueProperty="identifier" class="col-12" />

			<uf-autocomplete *ngIf="type === linkContentType.Form" [formControlName]="controlKeys.Id"
				[label]="commonTK.ContentTypeFormLabel | translate" [placeholder]="commonTK.SearchLabel | translate"
				[options]="contentOptions" (searchChange)="loadContent($event)" (valueChange)="select($event)"
				nameProperty="name" class="col-12" />

			<uf-autocomplete *ngIf="type === linkContentType.Page" [formControlName]="controlKeys.Id"
				[label]="commonTK.ContentTypePageLabel | translate" [placeholder]="commonTK.SearchLabel | translate"
				[options]="contentOptions" (searchChange)="loadContent($event)" (valueChange)="select($event)"
				nameProperty="name" class="col-12" />

			<uf-media-list *ngIf="type === linkContentType.Attachment" [formControlName]="controlKeys.Id"
				[allowedFileTypes]="allowedFileTypes" [label]="commonTK.ContentTypeAttachmentLabel | translate"
				[maxLength]="1" (valueChange)="fileUploaded($event)" class="col-12" />

			<uf-url *ngIf="type === linkContentType.Url" [formControlName]="controlKeys.Id"
				[label]="commonTK.ContentTypeUrlLabel | translate" class="col-12" placeholder="https://" />

			<uf-checkbox *ngIf="type === linkContentType.Attachment || type === linkContentType.Asset"
				[formControlName]="controlKeys.UseViewer" [label]="smartFormsTK.ContentTypeUseViewerLabel | translate"
				class="col-12" />

			<uf-text *ngIf="type" [formControlName]="controlKeys.Label"
				[label]="smartFormsTK.ContentTypeTitleLabel | translate" class="col-12" />
		</div>
	</div>
	<div class="uf-form-actions">
		<button (click)="close()" type="button" class="uf-button tertiary left">
			{{ sharedTermsTK.ActionClose | translate }}
		</button>
		<button (click)="clear()" type="button" class="uf-button right">
			{{ sharedTermsTK.ActionClear | translate }}
		</button>
		<button (click)="save()" type="button" class="uf-button primary">
			{{ sharedTermsTK.ActionSave | translate }}
		</button>
	</div>
</uf-panel>