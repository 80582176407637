import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

enum DividerTypes {
	Divider = 'divider',
	DividerVertical = 'verticalDivider'
}

enum DividerStyles {
	Dashed = 'dashed',
	Dotted = 'dotted',
	Double = 'doubled',
	Space = 'space'
}

enum DividerWeights {
	Lite = 'lite',
	Bold = 'bold',
	ExtraBold = 'x-bold'
}

@Component({
	templateUrl: './show-dividers.html',
})
export class ShowDividersComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly tableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly tableInfo = [
		{ name: 'uf-divider', type: 'base', description: 'Creates a horizontal line.' },
		{ name: 'uf-divider-vertical', type: 'base', description: 'Creates a vertical line.' },
		{ name: 'large', type: 'modifier', description: 'Sets divider margin to large' },
		{ name: 'default', type: 'modifier', description: 'Sets divider margin to default' },
		{ name: 'small', type: 'modifier', description: 'Sets divider margin to small' },
		{ name: 'x-small', type: 'modifier', description: 'Sets divider margin to extra small' },
	];

	readonly dividerTypes = DividerTypes;
	readonly dividerOptions = [
		{ name: 'Divider', value: DividerTypes.Divider },
		{ name: 'Vertical Divider', value: DividerTypes.DividerVertical },
	];

	readonly sizeOptions = [
		{ name: 'None (default)', value: '' },
		{ name: 'Large', value: 'large' },
		{ name: 'Medium', value: 'medium' },
		{ name: 'Small', value: 'small' },
		{ name: 'Extra Small', value: 'x-small' },
	];

	readonly styleOptions = [
		{ name: 'Solid (default)', value: '' },
		{ name: 'Dashed', value: DividerStyles.Dashed },
		{ name: 'Dotted', value: DividerStyles.Dotted },
		{ name: 'Double', value: DividerStyles.Double },
		{ name: 'Space', value: DividerStyles.Space },
	];

	readonly weightOptions = [
		{ name: 'Lite', value: DividerWeights.Lite },
		{ name: 'Regular (default)', value: '' },
		{ name: 'Bold', value: DividerWeights.Bold },
		{ name: 'Extra Bold', value: DividerWeights.ExtraBold },
	];

	readonly dividerTemplate = `<div class="uf-dividier#size #style"></div>`;
	readonly dividerVerticalTemplate = `<div class="uf-divider-vertical#size #style"></div>`;
	readonly spaceTemplate = '<br>';

	template = this.dividerTemplate.replace('#size', '');
	type = DividerTypes.Divider;
	style = '';
	size = '';
	weight = '';

	typeChange() {

		if (this.type === DividerTypes.DividerVertical) {
			this.size = 'large';
		}

		if (this.type === DividerTypes.Divider) {
			this.size = '';
		}

		this.modifierChange();
	}

	modifierChange() {

		const size = this.size !== '' ? ' ' + this.size : '';

		this.template = this.getTemplate(this.type).replace('#size', size);
	}

	private getTemplate(type: DividerTypes): string {

		switch (type) {
			case DividerTypes.Divider: return this.dividerTemplate;
			case DividerTypes.DividerVertical: return this.dividerVerticalTemplate;
			default: return this.spaceTemplate;
		}
	}

}
