<div *ngIf="label" class="label">{{label}}
	<ng-content></ng-content>
</div>
<div [class.locked]="locked || disabled" class="canvas-wrap">
	<canvas #canvas class="signature"></canvas>
</div>
<div class="uf-button-group small">
	<button *ngIf="!disabled && !locked" [title]="sharedTermsTK.ActionClear | translate" (click)="clear()"
		class="uf-button" type="button">
		{{sharedTermsTK.ActionClear | translate}}
		<uf-icon name="backspace" />
	</button>
	<button *ngIf="!disabled" (click)="locked = !locked" type="button" class="uf-button">
		<span *ngIf="locked">{{sharedTermsTK.ActionUnlock | translate}}</span>
		<span *ngIf="!locked">{{sharedTermsTK.ActionLock | translate}}</span>
		<uf-icon [name]="locked ? 'unlocked' : 'lock'" />
	</button>
</div>
<uf-error [control]="control" />