import { Injectable, inject } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { FieldValidator, ValidatorType } from '@unifii/sdk';

import { UfExpressionFunctionsSet, ValidatorFunctions } from '@unifii/library/common';

import { ContextFactory, ScopeFactory } from '../models';

import { FormValidators } from './form-validators';

@Injectable({ providedIn: 'root' })
export class ValidatorBuilder {

	readonly validatorFuncs: Record<string, (value: any, msg: string) => ValidatorFn> = {
		[ValidatorType.Min]: ValidatorFunctions.min,
		[ValidatorType.Max]: ValidatorFunctions.max,
		[ValidatorType.MinLength]: ValidatorFunctions.minLength,
		[ValidatorType.MaxLength]: ValidatorFunctions.maxLength,
		[ValidatorType.Pattern]: ValidatorFunctions.pattern,
	};

	readonly validatorFuncsMessageOnly: Record<string, (msg: string) => ValidatorFn> = {
		[ValidatorType.Alphanumeric]: ValidatorFunctions.alphanumeric,
		[ValidatorType.LettersOnly]: ValidatorFunctions.lettersOnly,
		[ValidatorType.Email]: ValidatorFunctions.email,
		[ValidatorType.Website]: ValidatorFunctions.website,
	};

	private formValidators = inject(FormValidators);

	createValidators(fieldValidators: FieldValidator[] = [], createScope: ScopeFactory, createContext: ContextFactory): ValidatorFn[] {

		const validators: ValidatorFn[] = [];

		for (const fieldValidator of fieldValidators) {

			/** Add default angular validators if type matches */
			const validatorFunction = this.validatorFuncs[fieldValidator.type];

			if (validatorFunction) {
				validators.push(validatorFunction(fieldValidator.value, fieldValidator.message));
			}

			/** Funcs with only message required */
			const validatorFunctionMessageOnly = this.validatorFuncsMessageOnly[fieldValidator.type];

			if (validatorFunctionMessageOnly) {
				validators.push(validatorFunctionMessageOnly(fieldValidator.message));
			}

			if (fieldValidator.type === ValidatorType.AfterNow) {
				const func = this.formValidators.afterNow;

				validators.push(func(createContext, fieldValidator.message));
			}

			if (fieldValidator.type === ValidatorType.BeforeNow) {
				const func = this.formValidators.beforeNow;

				validators.push(func(createContext, fieldValidator.message));
			}

			if (fieldValidator.type === ValidatorType.Expression || fieldValidator.type === ValidatorType.ItemExpression) {
				const func = this.formValidators.expression;

				validators.push(func(createScope, createContext, UfExpressionFunctionsSet, fieldValidator.value, fieldValidator.message));
			}
		}

		return validators.reverse();

	}

}
