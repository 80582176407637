<sc-page-content width="lg" identifier="tabs">

	<ng-container editorInputs>
		<uf-radio [options]="sizeOptions" [(value)]="size" (valueChange)="modifierChange()" label="Sizes" columns="1"
			valueProperty="value" class="col-6 col-3-md" />
		<uf-radio [options]="orientationOptions" [(value)]="orientation" (valueChange)="modifierChange()"
			label="Orientation" columns="1" valueProperty="value" class="col-6 col-3-md" />
		<uf-checkbox [(value)]="isResponsive" (valueChange)="modifierChange()" label="Responsive" class="col-6" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template">
			<uf-tabs [ngClass]="class" [responsive]="isResponsive">
				<uf-tab label="Tab 1">
					<p>Content 1</p>
				</uf-tab>
				<uf-tab icon="edit" label="Tab 2">
					<p>Content 2</p>
				</uf-tab>
				<uf-tab label="Tab 3">
					<p>Content 3</p>
				</uf-tab>
			</uf-tabs>
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">Outputs</h3>
		<sc-info-table [columnConfig]="outputTableConfig" [rows]="outputTableInfo" />
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="tableConfig" [rows]="tableInfo" />
	</ng-container>

</sc-page-content>