import { Component, Inject } from '@angular/core';
import { Option } from '@unifii/sdk';

import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';

export interface FieldEditorFormData {
	inputClass: string;
	fieldClass: string;
	breakAfter: boolean;
}

@Component({
	selector: 'sc-field-editor-form',
	templateUrl: './show-field-editor-form.html',
	styleUrls: ['./show-field-editor-form.less'],
})
export class FieldEditorFormComponent implements Modal<FieldEditorFormData, FieldEditorFormData> {

	readonly inputClassOptions: Option[] = [
		{ name: 'Stacked', identifier: '' },
		{ name: 'Inline', identifier: 'inline' },
		{ name: 'Stacked Centered', identifier: 'centered' },
	];

	readonly fieldClassOptions: Option[] = [
		{ name: 'Quarter', identifier: 'col-1of4' },
		{ name: 'Third', identifier: 'col-1of3' },
		{ name: 'Two Thirds', identifier: 'col-2of3' },
		{ name: 'Half', identifier: 'col-1of2' },
		{ name: 'Stretch', identifier: 'col-1of1' },
	];

	constructor(
		public runtime: ModalRuntime<FieldEditorFormData, FieldEditorFormData>,
		@Inject(ModalData) public data: FieldEditorFormData,
	) { }

	update() {
		this.runtime.update(this.data);
	}

	close() {
		this.runtime.close(this.data);
	}

}
