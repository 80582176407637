import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../components';

@Component({
	templateUrl: './show-components.html',
})
export class ShowComponentsComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly globalModifiersTableInfo = [
		{ name: 'default', type: 'size', description: 'Sets size to default' },
		{ name: 'large', type: 'size', description: 'Sets size to large' },
		{ name: 'small', type: 'size', description: 'Sets size to small' },
		{ name: 'x-small', type: 'size', description: 'Sets size to extra small' },
	];

	readonly scopedModifiersTableInfo = [
		{ name: 'default', type: 'colour', description: 'Sets colour to default colour' },
		{ name: 'primary', type: 'colour', description: 'Sets colour to primary colour' },
		{ name: 'secondary', type: 'colour', description: 'Sets colour to secondary colour' },
		{ name: 'tertiary', type: 'colour', description: 'Sets colour to tertiary colour' },
		{ name: 'accent', type: 'colour', description: 'Sets colour to accent colour' },
		{ name: 'info', type: 'colour', description: 'Sets colour to information message colour' },
		{ name: 'warning', type: 'colour', description: 'Sets colour to warning message colour' },
		{ name: 'success', type: 'colour', description: 'Sets colour to success message colour' },
		{ name: 'error', type: 'colour', description: 'Sets colour to error message colour' },
		{ name: 'disabled', type: 'colour', description: 'Sets colour to disabled message colour' },
		{ name: 'outlined', type: 'style', description: 'Sets to outlined style' },
		{ name: 'focused', type: 'style', description: 'Sets to focused style' },
		{ name: 'clickable', type: 'style', description: 'Sets to clickable style' },
		{ name: 'narrow', type: 'layout', description: '-' },
		{ name: 'wide', type: 'layout', description: '-' },
		{ name: 'condensed', type: 'layout', description: '-' },
		{ name: 'left', type: 'layout', description: '-' },
		{ name: 'right', type: 'layout', description: '-' },
		{ name: 'row', type: 'layout', description: '-' },
		{ name: 'col', type: 'layout', description: '-' },
		{ name: 'space-children', type: 'layout', description: '-' },
		{ name: 'grow', type: 'layout', description: '-' },
		{ name: 'shrink', type: 'layout', description: '-' },
		{ name: 'stretch', type: 'layout', description: '-' },
		{ name: 'center', type: 'layout', description: '-' },
		{ name: 'center-all', type: 'layout', description: '-' },
		{ name: 'align-flex-end', type: 'layout', description: '-' },
		{ name: 'align-flex-start', type: 'layout', description: '-' },
		{ name: 'justify-center', type: 'layout', description: '-' },
		{ name: 'wrap', type: 'layout', description: '-' },
	];

}
