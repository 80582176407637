import { Component, HostBinding, Input } from '@angular/core';
import { Compound, DataType, coerceDataToTarget } from '@unifii/sdk';

import { RuntimeField } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-link-display',
	templateUrl: './link.html',
})
export class LinkComponent implements ContentField {

	@HostBinding('class.body-copy') bodyClass = true;

	@Input() field: RuntimeField;

	protected name: string | undefined;

	private _content: Compound | null;

	@Input() set content(v: Compound | null) {
		// Guard for bad data
		if (!v || !Object.keys(v).length) {
			return;
		}

		// Else display the first non private variable
		this.name = this.getLinkName(v);
	}

	get content(): Compound | null {
		return this._content;
	}

	private getLinkName(compound: Compound): string | undefined {
		// Default lookup should be name
		if (compound.name) {
			return coerceDataToTarget(compound.name, { type: DataType.String }) ?? undefined;
		}

		for (const key in compound) {
			if (!key.startsWith('_')) {
				return coerceDataToTarget(compound[key], { type: DataType.String }) ?? undefined;
			}
		}

		return undefined;
	}

}
