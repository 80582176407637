import { AstNode, DATE_TIME_DATA_FORMAT, NodeType, QueryOperators, astNodeIterator } from '@unifii/sdk';
import { format } from 'date-fns';

import { FnsDatetimeUtc } from '../constants';
import { Context, Scope } from '../models';
import { ExpressionParser } from '../services';

import { parseFallbackISO } from './date-time-functions';

/** Create a copy of the @input astNode by iterating the tree and:
 * - transform NodeType Expression to NodeType Value by resolving the expression value
 * - transform deprecated node.expression format to NodeType.Value by resolving the expression value and delete the expression flag
 * - normalize complex Value to query ready value (ZonedDateTime)
 */
export const normalizeAstNode = (
	astNode: AstNode | null | undefined,
	expressionParser: ExpressionParser,
	context?: Context,
	scope?: Scope,
): AstNode | undefined => {

	if (!astNode) {
		return;
	}

	const copy = JSON.parse(JSON.stringify(astNode)) as AstNode;

	for (const { node } of astNodeIterator(copy, undefined, { canEmit: (n) => n.type === NodeType.Expression })) {

		// Standard scenario
		if (node.type === NodeType.Expression) {
			node.type = NodeType.Value;
			node.value = expressionParser.resolve(node.value, context, scope, 'normalizeAstNode');
			continue;
		}

		// Normalize ZonedDateTime
		if (node.type === NodeType.Value && node.value?.tz && node.value?.value) {
			const date = parseFallbackISO(node.value.value, FnsDatetimeUtc);

			if (date) {
				node.value = format(date, DATE_TIME_DATA_FORMAT);
			} else {
				node.value = null;
			}
		}

	}

	return copy;
};

export const astNodeToExpression = (ast: AstNode, context: Context): string | undefined => {

	if (!ast.args || !ast.op) {
		return undefined;
	}

	switch (ast.type) {
		case NodeType.Combinator:
			if (![QueryOperators.And, QueryOperators.Or].includes(ast.op as QueryOperators)) {
				return undefined;
			}

			return (ast.args
				.map((arg) => astNodeToExpression(arg, context))
				.filter((exp) => exp != null) as string[])
				.join(`${ast.op === QueryOperators.And ? ' && ' : ' || '}`);

		case NodeType.Operator:
			return astNodeOperatorToExpression(ast, context);

		default:
			return undefined;
	}
};

/* ****************************** PRIVATE FUNCTIONS ********************************************* */

const astNodeOperatorToExpression = (ast: AstNode, context: Context): string | undefined => {

	if (!ast.args || ast.type !== NodeType.Operator) {
		return undefined;
	}

	const isIdentifierOnly = ast.op && [QueryOperators.Has, QueryOperators.Hasnt].includes(ast.op as QueryOperators);
	const identifier = ast.args.find((node) => node.type === NodeType.Identifier);
	const rawValue = ast.args.find((node) => node.type === NodeType.Value);

	if (isIdentifierOnly && identifier && !rawValue) {
		switch (ast.op) {
			case QueryOperators.Has:
				return `(${getIdentifierSafeAccessPrefixExpression(identifier.value as string)}${identifier.value} != null)`;
			case QueryOperators.Hasnt:
				return `(${getIdentifierSafeAccessPrefixExpression(identifier.value as string, false)}${identifier.value} == null)`;
			default:
				return 'true';
		}
	}

	if (identifier && rawValue) {

		let expression: string | undefined;

		switch (ast.op) {
			case QueryOperators.Equal: {
				const rightSideValue = typeof rawValue.value === 'string' ? `'${rawValue.value}'` : rawValue.value;

				expression = `${identifier.value} == ${rightSideValue}`;
				break;
			}
			case QueryOperators.In:
			case QueryOperators.Descendants: {
				// remove 'descs' case once backend implement operator switch to 'in'
				const arrayVariableName = [...Array(20)].map(() => 'abcefgh'.charAt(Math.floor(Math.random() * 7))).join('');
				const arrayValue = Array.isArray(rawValue.value) ? rawValue.value : [rawValue.value];

				context[arrayVariableName] = arrayValue;

				expression = `$${arrayVariableName}.indexOf(${identifier.value}) >= 0`;
				break;
			}
			default:
				expression = 'true';
				break;
		}

		return `${getIdentifierSafeAccessPrefixExpression(identifier.value as string)}${expression}`;
	}

	return undefined;
};

const getIdentifierSafeAccessPrefixExpression = (identifier: string, checkExistence = true): string => {
	const parts = (identifier).split('.');

	if (parts.length === 1) {
		return '';
	}

	parts.pop();

	const condition = checkExistence ? ' != null' : ' == null';
	const combinator = checkExistence ? ' && ' : ' || ';

	return parts.map((_, i) => `${parts.slice(0, i+1)
		.join('.')}${condition}`)
		.join(combinator) + combinator;
};
