export * from './components';
export * from './core';
export * from './foundation';
export * from './lab';
export * from './pages';
export * from './patterns';

import { SC_PAGE_COMPONENTS_COMPONENTS } from './components';
import { SC_PAGE_CORE_COMPONENTS } from './core';
import { SC_PAGE_FOUNDATION_COMPONENTS } from './foundation';
import { SC_PAGES_LAB_COMPONENTS } from './lab';
import { SC_PAGE_PAGES_COMPONENTS } from './pages';
import { SC_PAGE_PATTERNS_COMPONENTS } from './patterns';

export const SC_PAGES = [
	...SC_PAGE_COMPONENTS_COMPONENTS,
	...SC_PAGE_CORE_COMPONENTS,
	...SC_PAGE_FOUNDATION_COMPONENTS,
	...SC_PAGES_LAB_COMPONENTS,
	...SC_PAGE_PAGES_COMPONENTS,
	...SC_PAGE_PATTERNS_COMPONENTS,
];
