import { Component } from '@angular/core';

@Component({
	selector: 'sc-show-palette',
	templateUrl: './show-palette.html',
})
export class ShowPaletteComponent {

	readonly colors = [
		{
			class: 'brand-bg',
			name: 'Brand',
		},
		{
			class: 'brand-accent-bg',
			name: 'Brand Accent',
		},
		{
			class: 'primary-action-bg',
			name: 'Primary Action',
		},
		{
			class: 'secondary-action-bg',
			name: 'Secondary Action',
		},
		{
			class: 'group-bg',
			name: 'Group',
		},
		{
			class: 'group--child-bg',
			name: 'Group Child',
		},
		{
			class: 'group--second-level-bg',
			name: 'Group Second Level',
		},
		{
			class: 'group--third-level-bg',
			name: 'Group Third Level',
		},
		{
			class: 'group--fourth-level-bg',
			name: 'Group Fourth Level',
		},
		{
			class: 'info-bg',
			name: 'Info',
		},
		{
			class: 'success-bg',
			name: 'Success',
		},
		{
			class: 'warning-bg',
			name: 'Warning',
		},
		{
			class: 'error-bg',
			name: 'Error',
		}];

}
