import { DataType, FieldOption, MessageColour, SortDirection, isString } from '@unifii/sdk';

import { SourceConfig } from './source-config-model';

export interface DataDisplayComponentType {
	data: unknown;
}

export type DataDisplayHrefTarget = '_blank' | '_self' | '_parent' | '_top';
export type DataDisplayColour = `${MessageColour}` | 'default';

export const isDataDisplayColour = (value: unknown): value is DataDisplayColour =>
	isString(value) &&
	['success', 'accent', 'info', 'warning', 'error', 'default'].includes(value);

export const isDataDisplayHrefTarget = (value: unknown): value is DataDisplayHrefTarget =>
	isString(value) &&
	['_blank', '_self', '_parent', '_top'].includes(value);

export type DataDisplayInfoOptions = FieldOption[] | { entries: Record<string, unknown>[]; valueExpression: string; displayExpression: string };

export type DataDisplayInfoNumber = {
	// eslint-disable-next-line id-denylist
	type: DataType.Number | `${DataType.Number}`;
	format?: 'bytes';
	decimals?: number;
	colour?: DataDisplayColour;
};

export type DataDisplayInfoString = {
	// eslint-disable-next-line id-denylist
	type: DataType.String | `${DataType.String}`;
	colour?: DataDisplayColour;
};

export type DataDisplayInfoPhone = {
	type: DataType.Phone | `${DataType.Phone}`;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoEmail = {
	type: DataType.Email | `${DataType.Email}`;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoWebsite = {
	type: DataType.Website | `${DataType.Website}`;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoSignature = {
	type: DataType.Signature | `${DataType.Signature}`;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoDate = {
	type: DataType.Date | `${DataType.Date}`;
	format?: string;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoTime = {
	type: DataType.Time | `${DataType.Time}`;
	format?: string;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoDateTime = {
	type: DataType.DateTime | `${DataType.DateTime}`;
	format?: string;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoOffsetDateTime = {
	type: DataType.OffsetDateTime | `${DataType.OffsetDateTime}`;
	format?: string;
	asDistanceFromNow?: boolean;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoZonedDateTime = {
	type: DataType.ZonedDateTime | `${DataType.ZonedDateTime}`;
	format?: string;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoDataSeed = {
	type: DataType.DataSeed | `${DataType.DataSeed}`;
	sourceConfig?: SourceConfig;
	// true = force display | false = hide display | undefined = follow DS mapping configuration
	showDisplayVisibleField?: boolean;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoCost = {
	type: DataType.Cost | `${DataType.Cost}`;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoGeoLocation = {
	type: DataType.GeoLocation | `${DataType.GeoLocation}`;
	separator?: string;
	format?: 'map';
	colour?: DataDisplayColour;
}

export type DataDisplayInfoAddress = {
	type: DataType.Address | `${DataType.Address}`;
	separator?: string;
	format?: 'map';
	colour?: DataDisplayColour;
}

export type DataDisplayInfoCompanyInfo = {
	type: DataType.CompanyInfo | `${DataType.CompanyInfo}`;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoManager = {
	type: DataType.Manager | `${DataType.Manager}`;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoLink = {
	type: DataType.Link | `${DataType.Link}`;
	hideType?: boolean;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoHierarchyStep = {
	type: DataType.HierarchyStep | `${DataType.HierarchyStep}`;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoHierarchyPath = {
	type: DataType.HierarchyPath | `${DataType.HierarchyPath}`;
	format?: string;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoHierarchyPaths = {
	type: DataType.HierarchyPaths | `${DataType.HierarchyPaths}`;
	format?: string;
	sort?: SortDirection;
	separator?: string;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoHierarchyUnit = {
	type: DataType.HierarchyUnit | `${DataType.HierarchyUnit}`;
	format?: string;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoBoolean = {
	// eslint-disable-next-line id-denylist
	type: DataType.Boolean | `${DataType.Boolean}`;
	options?: DataDisplayInfoOptions;
	mapToDataDisplayOptions?: boolean;
	colour?: DataDisplayColour;
};

export type DataDisplayInfoChoice = {
	type: DataType.Choice | `${DataType.Choice}`;
	options?: DataDisplayInfoOptions;
	mapToDataDisplayOptions?: boolean;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoMultiChoice = {
	type: DataType.MultiChoice | `${DataType.MultiChoice}`;
	options?: DataDisplayInfoOptions;
	mapToDataDisplayOptions?: boolean;
	sort?: SortDirection;
	separator?: string;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoRepeat = {
	type: DataType.Repeat | `${DataType.Repeat}`;
	template?: string;
	separator?: string;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoFileList = {
	type: DataType.FileList | `${DataType.FileList}`;
	sort?: SortDirection;
	separator?: string;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoImageList = {
	type: DataType.ImageList | `${DataType.ImageList}`;
	sort?: SortDirection;
	separator?: string;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoSoundList = {
	type: DataType.SoundList | `${DataType.SoundList}`;
	sort?: SortDirection;
	separator?: string;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoVideoList = {
	type: DataType.VideoList | `${DataType.VideoList}`;
	sort?: SortDirection;
	separator?: string;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoStringArray = {
	type: DataType.StringArray | `${DataType.StringArray}`;
	sort?: SortDirection;
	separator?: string;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoClaim = {
	type: DataType.Claim | `${DataType.Claim}`;
	colour?: DataDisplayColour;
}

export type DataDisplayInfoClaimsRecord = {
	type: DataType.ClaimsRecord | `${DataType.ClaimsRecord}`;
	colour?: DataDisplayColour;
}

export type DataDisplayInfo =
	DataDisplayInfoNumber |
	DataDisplayInfoBoolean |
	DataDisplayInfoString |
	DataDisplayInfoPhone |
	DataDisplayInfoEmail |
	DataDisplayInfoWebsite |
	DataDisplayInfoSignature |
	DataDisplayInfoDate |
	DataDisplayInfoTime |
	DataDisplayInfoDateTime |
	DataDisplayInfoOffsetDateTime |
	DataDisplayInfoZonedDateTime |
	DataDisplayInfoDataSeed |
	DataDisplayInfoCost |
	DataDisplayInfoGeoLocation |
	DataDisplayInfoAddress |
	DataDisplayInfoCompanyInfo |
	DataDisplayInfoManager |
	DataDisplayInfoLink |
	DataDisplayInfoHierarchyStep |
	DataDisplayInfoHierarchyPath |
	DataDisplayInfoHierarchyPaths |
	DataDisplayInfoHierarchyUnit |
	DataDisplayInfoChoice |
	DataDisplayInfoMultiChoice |
	DataDisplayInfoRepeat |
	DataDisplayInfoFileList |
	DataDisplayInfoImageList |
	DataDisplayInfoSoundList |
	DataDisplayInfoVideoList |
	DataDisplayInfoStringArray |
	DataDisplayInfoClaim |
	DataDisplayInfoClaimsRecord;

export type DataDisplayListItem = {
	// TODO Improve the optional `term`
	term?: string; // Currently optional for RepeatCell
	termTemplate?: 'tertiary';
	help?: string;
	data: unknown; // single or array of values
}

/** DataDisplayListComponent accepted input */
export type DataDisplayItems = DataDisplayListItem[] | Record<string, unknown>;

export interface DataDisplayGroup {
	heading?: string | null | undefined;
	items: DataDisplayItems;
}
