import { Component, Input } from '@angular/core';

import { DataDisplayGroup } from '../../models';

@Component({
	selector: 'uf-data-display-group',
	templateUrl: './data-display-group.html',
})
export class DataDisplayGroupComponent {

	@Input({ required: true }) group: DataDisplayGroup;

}
