import { Component, OnInit, inject } from '@angular/core';
import { FormContentTemplate } from '@unifii/sdk';

import { RuntimeField, UfControl } from '@unifii/library/common';
import { FieldComponent, FormField, WorkflowService, contentIconMap, getContentClasses } from '@unifii/library/smart-forms';

import { PrintField } from '../../renderer';

@Component({
	selector: 'uf-content-input',
	templateUrl: './content.html',
	styleUrls: ['./content.less'],
})
export class FieldContentComponent implements FormField, OnInit {

	field: RuntimeField | PrintField;
	control: UfControl;
	content: string;

	protected readonly iconMap = contentIconMap;

	protected icon: string;
	protected classes: string[];
	protected showExpander: boolean;
	protected collapsed: boolean;

	private isActive: boolean;
	private workflowService = inject(WorkflowService);
	private fieldComponent = inject(FieldComponent);

	ngOnInit() {
		this.isActive = this.workflowService.isActive(this.field);

		this.showExpander = !(this.field.templateConfig as FormContentTemplate | undefined)?.alwaysExpanded;
		this.collapsed = !this.isActive && !(this.field.templateConfig as FormContentTemplate | undefined)?.expandWhenInactive;

		if (!this.isActive && !!(this.field.templateConfig as FormContentTemplate | undefined)?.hideWhenInactive) {
			this.fieldComponent.hidden = true;
		}

		if (this.field.template) {
			const icon = this.iconMap[this.field.template];

			if (icon) {
				this.icon = icon;
			}
		}

		this.classes = getContentClasses(this.field.templateConfig as FormContentTemplate | undefined, this.isActive, this.field.template);
	}

	get markdown(): string {

		const content = this.field.help ?? '';

		// Add label as heading for backwards compat label should be deprecated at some stage
		if (this.field.label) {
			return '### ' + this.field.label + '\r\n' + content;
		}

		return content;
	}

}
