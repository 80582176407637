import { Component, ElementRef, EventEmitter, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { FieldType, FormStyle } from '@unifii/sdk';
import { Subscription, first } from 'rxjs';

import { RuntimeField, Scope, UfControlGroup } from '@unifii/library/common';
import { FieldComponent, FormContainerField, FormService, NavigationService, WorkflowService, isFieldEligibleForNavigation } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-form-group',
	templateUrl: './form-group.html',
	styleUrls: ['../form-group.less'],
})
export class GroupComponent implements FormContainerField, OnInit, OnDestroy {

	@HostBinding('class.uf-box') groupClassName = true;
	field: RuntimeField;
	scope: Scope;
	control: UfControlGroup;
	contentChange: EventEmitter<any>;

	protected label: string;
	protected contentExpanded = true;

	private formService = inject(FormService, { optional: true });
	private fieldComponent = inject(FieldComponent);
	private navService = inject<NavigationService<RuntimeField>>(NavigationService);
	private workflowService = inject(WorkflowService, { optional: true });
	private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
	private subscriptions = new Subscription();

	ngOnInit() {
		this.initNavigation();

		this.label = this.field.type === FieldType.Step && this.field.shortLabel ? this.field.shortLabel : this.field.label ?? '';
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	@HostBinding('class.disabled') get hostDisabled() {
		return (this.field.isReadOnly || this.control.disabled) && !this.isSummary;
	}

	@HostBinding('class.collapsed') get hostCollapsed() {
		return !this.contentExpanded;
	}

	@HostBinding('class.error') get hostError() {
		return this.control.submitted && this.control.invalid;
	}

	isVisible(child: RuntimeField): boolean {
		return !this.formService || this.formService.isGranted(child);
	}

	get isSummary(): boolean {
		return this.formService?.style === FormStyle.Summary;
	}

	private initNavigation() {
		if (!this.formService?.definitionSettings.isNavigationEnabled && !isFieldEligibleForNavigation(this.field)) {
			return;
		}

		if (!this.fieldComponent.hidden) {
			this.navService.register(this.field, this.elementRef.nativeElement);
		}

		this.subscriptions.add(this.navService.onNavigation.subscribe((field) => {
			if (field === this.field && !this.contentExpanded) {
				this.contentExpanded = true;
				
				// Wait for the template update and set as active so the navService can react to its new position
				setTimeout(() => this.navService.setActive(field, true), 0);
			}
		}));

		// Register or deregister when visibility changes
		this.subscriptions.add(this.fieldComponent.hiddenChanges.subscribe((isHidden) => {
			isHidden ? this.navService.deregister(this.field) : this.navService.register(this.field, this.elementRef.nativeElement);
		}));

		this.subscriptions.add(this.workflowService?.transitionRequested.pipe(first()).subscribe(() => {
			if (this.control.invalid) {
				this.navService.setError(this.field, true);
			}

			// Subscribe to status changes to so error status can be reset when valid
			this.subscriptions.add(this.control.statusChanges.subscribe(() => {
				this.navService.setError(this.field, this.control.invalid);
			}));
		}));
	}

}
