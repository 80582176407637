import { Injectable, inject } from '@angular/core';
import { ErrorType, HierarchyChildrenSearchOptions, HierarchyClient, HierarchySearchOptions, HierarchyUnitExtended, HierarchyUnitWithChildCount, HierarchyUnitWithPath, ensureUfError } from '@unifii/sdk';

import { HierarchyUnitProvider } from '../models';

@Injectable()
export class UfHierarchyUnitProvider implements HierarchyUnitProvider {

	private hierarchyClient = inject(HierarchyClient);

	getUnit(id: string): Promise<HierarchyUnitExtended | undefined> {
		try {
			return this.hierarchyClient.getUnit(id);
		} catch (e) {
			if (ensureUfError(e).type === ErrorType.NotFound) {
				return Promise.resolve(undefined);
			} else {
				throw e;
			}
		}
	}

	getUnits(ids: string[]): Promise<HierarchyUnitWithPath[]> {
		return this.hierarchyClient.getUnits(ids);
	}

	async getChildren(id: string, params?: HierarchyChildrenSearchOptions): Promise<HierarchyUnitWithChildCount[]> {
		try {
			return await this.hierarchyClient.getUnitChildren(id, params);
		} catch (error) {
			if (ensureUfError(error).type === ErrorType.NotFound) {
				return Promise.resolve([]);
			} else {
				throw error;
			}
		}
	}

	search(params?: HierarchySearchOptions): Promise<HierarchyUnitWithPath[]> {
		return this.hierarchyClient.search(params);
	}

}
