<ng-container #formOutlet />

<ng-template #formTemplate>
	<uf-form *ngIf="formData && definition && !printConfig" [disabled]="disabled" [config]="config"
		[definition]="definition" [(formData)]="formData" (submit)="onSubmit($event)" (cancelClick)="onCancel()"
		(edited)="edited = $event">
		<div header>
			<div class="uf-app-bar flat">
				<div class="title">
					<uf-select [options]="forms" [nameProperty]="formLabel" [(value)]="formId"
						placeholder="Select a Form" valueProperty="id" />
				</div>
				<button (click)="printForm()" class="uf-action" type="button">
					<uf-icon name="print" />
				</button>
				<button (click)="initialize()" class="uf-action" type="button">
					<uf-icon name="refreshApp" />
				</button>
				<button (click)="showSettings = true" class="uf-action" type="button">
					<uf-icon name="settings" />
				</button>
			</div>
		</div>
	</uf-form>
</ng-template>

<uf-drawer [showHandle]="!definition" [(open)]="showSettings">
	<div class="uf-app-bar flat">
		<div class="title">Settings</div>
		<button (click)="showSettings = false" class="uf-action tertiary" type="button">
			<uf-icon name="close" />
		</button>
	</div>
	<div *ngIf="formDebugger" class="pad-sides">
		<uf-select *ngIf="!definition" [options]="forms" [nameProperty]="formLabel" [(value)]="formId"
			label="Select a form" placeholder="Select a Form" valueProperty="id" />

		<uf-select [options]="formDebugger.formStates" [(value)]="formDebugger.state" label="State"
			class="gap-bottom" />

		<uf-select [options]="validationOptions" [(value)]="formDebugger.validation" nameProperty="label"
			valueProperty="value" label="Validation" class="gap-bottom" />

		<uf-select [options]="styleOptions" [(value)]="formStyle" (valueChange)="updateStyleCustomProperty($event)"
			label="Form Style" nameProperty="label" valueProperty="value" class="gap-bottom" />

		<uf-checkbox [label]="useApi ? 'from API' : 'from v0'" [(value)]="useApi" (valueChange)="initialize()"
			class="gap-bottom col-2" />

		<uf-multi-choice [value]="formDebugger.roles" [options]="roles" (valueChange)="initialize($event)"
			class="gap-bottom" label="Roles" />

		<button (click)="formData = null;" class="uf-button" type="button">
			Reset Data
		</button>
		<button (click)="pasteDefinition()" class="uf-button" type="button">Paste Definition</button>
		<button (click)="pasteFormData()" class="uf-button" type="button">
			Paste FormData
		</button>

		<uf-checkbox [(value)]="formDebugger.enableWorkflow" label="Workflow" />
		<uf-checkbox [(value)]="disabled" label="Disable" />
		<uf-checkbox [(value)]="print" (valueChange)="updatePrint()" label="Print" />
		<uf-checkbox [(value)]="addCancel" (valueChange)="updateCancelLabel()" label="AddCancel" />
		<uf-checkbox [(value)]="addSubmit" (valueChange)="updateSubmitLabel()" label="AddSubmit" />

		<p>
			<span>Definition: <uf-help [content]="definition?._original| json" /></span>
			<span>Data: <uf-help [content]="formData | json" /></span>
			<span>Config: <uf-help [content]="config | json" /></span>
			<span *ngIf="edited">Edited</span>
		</p>

	</div>
</uf-drawer>

<!-- Print Form -->
<uf-print-form *ngIf="printConfig" [config]="printConfig" (done)="printConfig = null" />