<div class="uf-app-bar">
	<button [title]="sharedTermsTK.ActionClose | translate" (click)="close()" type="button" class="uf-action tertiary">
		<uf-icon name="close" />
	</button>
	<h3>{{data.title}}</h3>
</div>
<div *ngIf="data.message" class="uf-grid pad-sides gaps scrollable-section">
	<div [markdownRenderer]="data.message" class="col-12"></div>
</div>
<uf-panel class="grow">
	<div class="uf-grid pad">
		<ul *ngIf="data.options.length" class="col-12 uf-list uf-box flat">
			<a *ngFor="let option of data.options" (click)="confirm(option)" class="uf-list-link">
				{{ option.name }}
			</a>
		</ul>
		<uf-blockquote *ngIf="!data.options.length" [content]="commonTK.ChoiceNoOptionsAvailable | translate"
			icon="info" class="col-12" />
	</div>
</uf-panel>