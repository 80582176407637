<uf-expander *ngIf="!inModal; else repeatForm" [actionBtn]="true">
	<div [class.small]="control.disabled && isSummary" expanderHeader class="uf-app-bar medium">
		<div class="title">{{ title }}</div>
		<button *ngIf="showAddDeleteButton" [title]="sharedTermsTK.ActionRemove | translate" (click)="remove.emit()"
			type="button" class="uf-action">
			<uf-icon name="delete" />
		</button>
	</div>
	<div expanderBody class="grid content">
		<ng-template [ngTemplateOutlet]="repeatForm"></ng-template>
	</div>
</uf-expander>

<ng-template #repeatForm>
	<uf-data-seed-mappings-display *ngIf="field && field.sourceConfig && dataSeedScope"
		[sourceConfig]="field.sourceConfig" [dataSeed]="dataSeedScope" class="col-1of1" />

	<ng-template [ngForOf]="field.fields" let-child ngFor>
		<ng-template [ngIf]="isVisible(child)">
			<ng-template [ngIf]="child.identifier" [ngIfElse]="nonContent">
				<uf-field [field]="child" [scope]="scope" [(content)]="scope[child.identifier]"
					(contentChange)="changed($event)" />
			</ng-template>
			<ng-template #nonContent>
				<uf-field [field]="child" [scope]="scope" (contentChange)="changed($event)" />
			</ng-template>

			<div *ngIf="child?.breakAfter" class="col-1of1"></div>
		</ng-template>
	</ng-template>
</ng-template>