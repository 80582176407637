export enum InputTranslationKey {
	RepeatInputErrorMaxLength = 'repeat_input_error_max_length',
	RepeatInputDeleteConfirmTitle = 'repeat_input_delete_confirm_title',
	RepeatInputDeleteConfirmMessage = 'repeat_input_delete_confirm_message',
	AddressInputErrorInsufficientDataToLocate = 'address_input_error_insufficient_data_to_locate',
	AddressInputErrorNoMarkerPosition = 'address_input_error_no_marker_position',
	AddressInputActionUpdateFields = 'address_input_action_update_fields',
	AddressInputActionShowLocation = 'address_input_action_show_location',
	FormErrorTransitionInvalid = 'form_error_transition_invalid',
	FormPrintFeedbackLoading = 'form_print_feedback_loading'
}
