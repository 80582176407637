import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { MeClient, UsersClient } from '@unifii/sdk';

import { ShowcaseRepositoryService } from '../services';

export const userAndProjectGuard: CanActivateFn = async() => {

	const router = inject(Router);
	const repository = inject(ShowcaseRepositoryService);

	// Guard access token
	if (!repository.token || !repository.user || !repository.project) {

		void redirect(router);

		return false;
	}

	const usersClient = inject(UsersClient);
	const meClient = inject(MeClient);

	try {
		const userId = repository.user.id ?? '';

		try {
			repository.user = await usersClient.get(userId);
		} catch (e) {
			repository.user = await meClient.get();
		}

		return true;
	} catch (error) {
		repository.reset();
		void redirect(router);

		return false;
	}
};

const redirect = (router: Router) => router.navigate(['login']);
