<sc-page-content width="lg" identifier="typography">

	<ng-container editor>
		<table #example class="uf-table accent">
			<thead>
				<tr>
					<th *ngFor="let column of tableConfig">{{ column.name }}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let row of rows">
					<td *ngFor="let column of tableConfig">
						<div [innerHTML]="row[column.identifier]"
							*ngIf="column.identifier === 'template'; else cellText" class="template"></div>
						<ng-template #cellText>
							{{ row[column.identifier] }}
						</ng-template>
					</td>
				</tr>
			</tbody>
		</table>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>
</sc-page-content>