import { Pipe, PipeTransform } from '@angular/core';
import { Dictionary } from '@unifii/sdk';

/**
 * Extract a stringified value from an object, eventually driven by a property "information" (path or callback)
 */
@Pipe({ name: 'nameProperty' })
export class NamePropertyPipe implements PipeTransform {

	transform(option: Dictionary<any> | string | number | null, property?: null | string | ((option: any) => string)): string {

		if (option == null) {
			return '';
		}

		if (typeof property === 'function') {
			return property(option);
		}

		if (typeof option === 'string' || typeof option === 'number') {
			return `${option}`;
		}

		if (property && option[property]) {
			return `${option[property]}`;
		}

		// Return first key
		const firstKey = Object.keys(option)[0];

		return firstKey ? '' + (option[firstKey] ?? '') : '';
	}

	// TODO Improved implementation, won't pass unit-tests... need more debugging
	// private dataLookupService = inject(DataLookupService);
	/*
	transform(data: unknown, property?: null | string | ((option: any) => string)): string {

		if (data == null) {
			return '';
		}

		if (typeof property === 'function') {
			return property(data);
		}

		let value: unknown;

		if (!isDictionary(data) && !isString(data) && !isNumber(data)) {
			return '';
		}

		if (isDictionary(data)) {
			if (isString(property) && isIdentifiersPathExpression(property)) {
				value = this.dataLookupService.lookupData(data, property, `NamePropertyPipe - lookup value via identifier ${property} from ${JSON.stringify(data)}`);
			} else {
				const firstKey = Object.keys(data)[0];

				value = firstKey ? data[firstKey] : '';
			}
		}

		if (isString(value) || isNumber(value)) {
			return `${value}`;
		}
		
		return '';
	}
*/

}
