import { Directive, ElementRef, Input, OnInit, inject } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { DirectoryIndicatorRenderInfo, DirectoryRootDirective } from './directory-root.directive';

@Directive({
	selector: '[ufDirectory], [ufFile]',
})
export class DirectoryDirective<T extends object> implements OnInit {

	@Input({ required: true }) item: T;
	@Input() expanded = true;

	onChanges = new ReplaySubject<DirectoryIndicatorRenderInfo>();

	private root = inject(DirectoryRootDirective<T>);
	private elementRef: ElementRef = inject(ElementRef);
	private children: DirectoryDirective<T>[] = [];
	private _visible = true;

	@Input() set visible(v: boolean) {
		this._visible = v;

		if (!v) {
			this.htmlElement.style.display = 'none';
		} else {
			this.htmlElement.style.removeProperty('display');
		}

		for (const child of this.children) {
			child.visible = v;
		}
	}

	get visible(): boolean {
		return this._visible;
	}

	ngOnInit() {
		this.root.register(this);

		this.onChanges.next(this.root.getInfo(this.item));
	}

	addChild(child: DirectoryDirective<T>) {
		this.children.push(child);

		if (!this.expanded) {
			child.visible = false;
		}

		this.onChanges.next(this.root.getInfo(this.item));
	}

	updateChildrensVisiblity(visible: boolean) {
		for (const child of this.children) {
			child.visible = visible;
		}
	}

	private get htmlElement() {
		return this.elementRef.nativeElement as HTMLElement;
	}

}
