import { Pipe, PipeTransform } from '@angular/core';
import { SchemaTransition } from '@unifii/sdk';

@Pipe({
	name: 'actionFilter',
})
export class ActionFilterPipe implements PipeTransform {

	transform(transitions: SchemaTransition[], source: string) {
		return transitions.filter((t) => t.source === source);
	}

}
