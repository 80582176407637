import { Injectable } from '@angular/core';
import { isString } from '@unifii/sdk';

import { worldCities } from './world-data-cities';
import { worldCountries } from './world-data-countries';
import { WorldCity, WorldCountry, WorldDataValue, WorldRegion, WorldState, WorldSubregion } from './world-data-model';
import { worldRegions } from './world-data-regions';
import { worldStates } from './world-data-states';
import { worldSubregions } from './world-data-subregions';

@Injectable({ providedIn: 'root' })
export class WorldDataService {

	latency = 250;

	private regions = worldRegions;
	private subregions = worldSubregions;
	private countries = worldCountries;
	private states = worldStates;
	private cities = worldCities;

	private get delay(): Promise<void> {
		return new Promise((res) => setTimeout(res, this.latency));
	}
	
	queryRegions(query?: string): Promise<WorldRegion[]> {
		return this.filter(this.regions, 'name', query);
	}

	getRegion(id: number): Promise<WorldRegion | undefined> {
		return this.findById(this.regions, id);
	}

	querySubregions(query?: string, parentId?: number | number[]): Promise<WorldSubregion[]> {
		return this.filter(this.subregions, 'name', query, parentId);
	}

	getSubregion(id: number): Promise<WorldSubregion | undefined> {
		return this.findById(this.subregions, id);
	}

	queryCountries(query?: string, parentId?: number | number[]): Promise<WorldCountry[]> {
		return this.filter(this.countries, 'name', query, parentId);
	}

	getCountry(id: number): Promise<WorldCountry | undefined> {
		return this.findById(this.countries, id);
	}

	queryStates(query?: string, parentId?: number | number[]): Promise<WorldState[]> {
		return this.filter(this.states, 'name', query, parentId);
	}

	getState(id: number): Promise<WorldState | undefined> {
		return this.findById(this.states, id);
	}

	queryCities(query?: string, parentId?: number | number[]): Promise<WorldCity[]> {
		return this.filter(this.cities, 'name', query, parentId);
	}

	getCity(id: number): Promise<WorldCity | undefined> {
		return this.findById(this.cities, id);
	}

	private async findById<O extends {id: number}>(objects: O[], id: number): Promise<O | undefined> {
		await this.delay;

		return objects.find((object) => object.id === id);
	}

	private async filter<O extends WorldDataValue>(objects: O[], key: keyof O, query?: string, parentId?: number | number[]): Promise<O[]> {
		await this.delay;
		
		if (!query?.length && parentId == null) {
			return objects;
		}

		return objects.filter((object) => {
			// as string won't be necessary in future (smarter) Typescript versions
			return (!query?.length || isString(object[key]) && (object[key] as string).toLowerCase().includes(query.toLowerCase())) &&
			(parentId == null || (Array.isArray(parentId) && !parentId.length) || Array.isArray(parentId) && parentId.includes(object.parent_id) || object.parent_id === parentId);
		});
	}

}
