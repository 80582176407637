import { Component, EventEmitter, Input, OnInit, Output, Type, ViewContainerRef, inject } from '@angular/core';

import { FilterComponentRegistry, FilterEntry, FilterValue, FilterViewMode, IFilterComponent } from '../../models';

@Component({
	selector: 'uf-filter',
	template: ``,
	styles: [`:host { display: block; }`],
})
export class FilterWrapperComponent<E extends FilterEntry, V extends FilterValue> implements OnInit {

	@Input({ required: true }) entry: E;
	@Input() mode = FilterViewMode.Input;
	@Output() valueChange = new EventEmitter<V | null>();

	private component: IFilterComponent<V> | undefined;
	private _value: V | null;
	private container = inject(ViewContainerRef);
	private registry = inject<FilterComponentRegistry<E>>(FilterComponentRegistry);

	@Input() set value(v: V | null) {
		this._value = v;
		if (this.component) {
			this.component.value = this.value;
		}
	}

	get value(): V | null {
		return this._value;
	}

	ngOnInit() {
		const component = this.registry.get(this.entry) as Type<IFilterComponent<V>> | undefined;

		if (!component) {
			console.warn(`FilterWrapperComponent - no component available for entry '${this.entry.identifier}'`);

			return;
		}

		this.component = this.container.createComponent(component).instance;
		this.component.entry = this.entry;
		this.component.mode = this.mode;
		this.component.value = this.value;
		this.component.valueChange = this.valueChange;
	}

}
