import { Component, HostBinding, OnInit, ViewChild, inject } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HierarchyUnitFormData } from '@unifii/sdk';

import { SEPARATOR_COMMA } from '../../../constants';
import { UfControl } from '../../../controls';
import { HierarchyUnitIdentifier, HierarchyUnitPickerData, HierarchyUnitProvider } from '../../../models';
import { UfFormBuilder } from '../../../services';
import { CommonTranslationKey, SharedTermsTranslationKey } from '../../../translations';
import { HierarchyFunctions, ValidatorFunctions } from '../../../utils';
import { Modal, ModalData, ModalRuntime } from '../../modal';

import { HierarchyPrefillController } from './hierarchy-prefill-controller';
import { HierarchyUnitSelectorComponent } from './hierarchy-unit-selector.component';

@Component({
	selector: 'uf-hierarchy-unit-picker',
	templateUrl: './hierarchy-unit-picker.html',
})
export class HierarchyUnitPickerComponent implements Modal<HierarchyUnitPickerData, HierarchyUnitFormData | undefined>, OnInit {

	@HostBinding('class.uf-form-card') cardClass = true;
	@ViewChild(HierarchyUnitSelectorComponent) private selector?: HierarchyUnitSelectorComponent;

	runtime = inject<ModalRuntime<HierarchyUnitPickerData, HierarchyUnitFormData | undefined>>(ModalRuntime);
	data = inject<HierarchyUnitPickerData>(ModalData);

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected control: UfControl;
	protected label: string;
	protected ceiling?: HierarchyUnitIdentifier | null;
	protected selectLeafsOnly = false;
	protected inProgress = true;

	private ufFormBuilder = inject(UfFormBuilder);
	private translateService = inject(TranslateService);
	private hierarchyProvider = inject(HierarchyUnitProvider);

	async ngOnInit() {
		const { selectLeafsOnly, preselected, ceiling, label, value } = this.data;

		this.ceiling = ceiling;
		this.label = label ?? '';
		this.selectLeafsOnly = selectLeafsOnly === true;
		this.control = this.ufFormBuilder.control(this.data.value, this.createValidatorFns(this.data));

		if (preselected != null && value == null) {
			// verify that the preselected unit exists and is descendant the configured ceiling
			const hierarchyCtrl = new HierarchyPrefillController(this.hierarchyProvider);
			const preselectedUnit = await hierarchyCtrl.getUnit(preselected);

			this.control.setValue(preselectedUnit);
		}
	}

	close() {
		this.runtime.close();
	}
	
	protected deselect() {
		this.runtime.update(undefined);
		this.close();
	}

	protected select() {
		if (this.control.invalid) {
			this.control.setSubmitted();

			return;
		}

		this.runtime.update(this.control.value as HierarchyUnitFormData);
		this.close();
	}

	private createValidatorFns(info: HierarchyUnitPickerData): ValidatorFn[] | null {
		const validators: ValidatorFn[] = [];

		if (info.isRequired ?? true) {
			validators.push(ValidatorFunctions.required(this.translateService.instant(this.sharedTermsTK.ValidatorValueRequired)));
		}

		if (info.selectLeafsOnly) {
			validators.push(ValidatorFunctions.custom(
				() => !!this.selector?.leafSelected,
				this.translateService.instant(CommonTranslationKey.SelectLeafUnitMessage),
			));
		}

		if (info.unselectableUnits) {
			validators.push(HierarchyFunctions.getUnselectableUnitsValidator(
				info.unselectableUnits,
				this.translateService.instant(CommonTranslationKey.SelectSelectableUnitMessage)),
			);
		}

		if (info.matchOrDescendantsUnits) {
			const descendantsCSVIds = info.matchOrDescendantsUnits
				.map((descendant) => `'${HierarchyFunctions.getId(descendant)}'`)
				.join(SEPARATOR_COMMA);
			
			validators.push(HierarchyFunctions.getDescendantsValidator(
				info.matchOrDescendantsUnits,
				true,
				this.translateService.instant(CommonTranslationKey.SelectDescendantUnitMessage, { descendants: descendantsCSVIds })),
			);
		}

		return validators;
	}

}
