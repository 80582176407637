import { Component, HostBinding, inject } from '@angular/core';

import { Modal } from '../modal';
import { ModalRuntime } from '../modal-runtime';
import { ModalData } from '../modal-types';

@Component({
	selector: 'uf-help-modal',
	templateUrl: './help-modal.html',
	styleUrls: ['./modal.less', './help-modal.less'],
})
export class HelpModalComponent implements Modal<string, undefined> {

	@HostBinding('class.uf-card') cardClass = true;
	@HostBinding('class.body-copy') bodyClass = true;

	runtime = inject<ModalRuntime<string, undefined>>(ModalRuntime);
	data = inject<string>(ModalData);
	
	close() {
		this.runtime.close();
	}

}
