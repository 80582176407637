import { Dictionary, FieldTemplate } from '@unifii/sdk';

import { MessageLevel } from '@unifii/library/common';

export const FIELD_WIDTH_MAP: Dictionary<string> = {
	Quarter: 'col-1of4',
	Third: 'col-1of3',
	TwoThirds: 'col-2of3',
	Half: 'col-1of2',
	Stretch: 'col-1of1',
};

/** Datasource error handling */
export const InputClassLookup: Dictionary<string> = {
	[MessageLevel.Warning]: 'warning',
	[MessageLevel.Error]: 'external-error',
};

export const IconLookup: Dictionary<string> = {
	[MessageLevel.Warning]: 'warningSolid',
	[MessageLevel.Error]: 'errorSolid',
};

export const contentIconMap: Partial<Record<FieldTemplate, string>> = {
	[FieldTemplate.Success]: 'successSolid',
	[FieldTemplate.Warning]: 'warningSolid',
	[FieldTemplate.Alert]: 'errorSolid',
	[FieldTemplate.Info]: 'infoSolid',
};

export const contentLevelMap: Partial<Record<FieldTemplate, string>> = {
	[FieldTemplate.Alert]: 'error',
};
