import { ModalService } from '../../services';

import { ModalConfig } from './modal-types';

export class ModalRuntime<Data, Result> {

	constructor(
		private service: ModalService,
		private config: ModalConfig<Data, Result>,
	) { }

	update(result: Result) {
		if (this.config.update) {
			this.config.update(result);
		}
	}

	close(result?: Result) {
		this.config.done(result);
		this.service.close(this.config);
	}

}
