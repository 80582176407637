import { AstNode, CompaniesClient, Company, DataSeed, isNotNull } from '@unifii/sdk';

import { Context, DataSourceLoader, DataSourceLoaderConfig, Scope, SourceConfigCompany } from '../../models';
import { ExpressionParser } from '../expression-parser';

import { DataSourceConverter } from './data-source-converter';
import { SourceConfigQueryBuilder } from './source-config-query-builder';

/** Initialized via DataLoaderFactory */
export class CompanyLoader implements DataSourceLoader {

	private queryBuilder: SourceConfigQueryBuilder;

	constructor(
		public sourceConfig: SourceConfigCompany,
		public config: DataSourceLoaderConfig | undefined,
		private companiesClient: CompaniesClient,
		private dataSeedConverter: DataSourceConverter,
		expressionParser: ExpressionParser,
	) {
		this.queryBuilder = new SourceConfigQueryBuilder(sourceConfig, expressionParser);
	}

	async getOptions(context?: Context | undefined, scope?: Scope | undefined, signal?: AbortSignal): Promise<DataSeed[]> {
		const query = this.queryBuilder.create('', context, scope);
		const companies = await this.companiesClient.query(query, { signal, analytics: this.config?.requestAnalytics });

		return companies.map((company) => this.mapToSeed(company)).filter(isNotNull);
	}

	async search(q?: string | undefined, context?: Context | undefined, scope?: Scope | undefined, signal?: AbortSignal, contextFilters?: AstNode): Promise<DataSeed[]> {
		const query = this.queryBuilder.create(q, context, scope, undefined, contextFilters);
		const companies = await this.companiesClient.query(query, { signal, analytics: this.config?.requestAnalytics });

		return companies.map((company) => this.mapToSeed(company)).filter(isNotNull);
	}

	findAllBy(match: string, context?: Context, scope?: Scope, signal?: AbortSignal): Promise<DataSeed[]> {
		console.warn('Company datasource does not support findByAll, falling back on search');

		return this.search(match, context, scope, signal);
	}

	async get(id: string): Promise<DataSeed | null> {
		return this.mapToSeed(await this.companiesClient.get(id));
	}

	mapToSeed(company?: Company | null): DataSeed | null {
		return this.dataSeedConverter.toDataSeed(company ?? null, this.sourceConfig);
	}

}
