import { DataSeed, Dictionary, ErrorType, ExternalDataSourcesClient, UfError, isString } from '@unifii/sdk';

import { Context, DataSourceLoader, DataSourceLoaderConfig, Scope, SourceConfigExternal, SourceConfigInput } from '../../models';
import { ExpressionParser } from '../../services';
import { ValidatorFunctions } from '../../utils';

import { DataSourceConverter } from './data-source-converter';
import { toDataSeedSafeOption } from './retrocompatibility-loader-hack';

// TODO may need to be exported
const SearchExpression = '$q';

/** Initialized via DataLoaderFactory */
export class ExternalLoader implements DataSourceLoader {

	private searchInfo?: { param: string; isRequired: boolean };
	private id: string;
	private inputs: SourceConfigInput[] = [];
	private requiredParams: string[] = [];

	constructor(
		public sourceConfig: SourceConfigExternal,
		public config: DataSourceLoaderConfig | undefined,
		private externalDataSourcesClient: ExternalDataSourcesClient,
		private dataSourceConverter: DataSourceConverter,
		private expressionParser: ExpressionParser,
	) {
		this.id = this.sourceConfig.id;
		this.inputs = this.sourceConfig.inputs;
		this.requiredParams = this.inputs.filter((input) => input.isRequired).map((input) => input.parameter);

		const searchParam = this.inputs.find((input) => input.value === SearchExpression)?.parameter;

		if (searchParam) {
			this.searchInfo = {
				param: searchParam,
				isRequired: this.requiredParams.includes(searchParam),
			};
		}

	}

	async getOptions(context?: Context, scope?: Scope, signal?: AbortSignal): Promise<DataSeed[]> {

		const params = this.getUrlParams(context, scope);
		const valid = this.validateParams(params);

		if (!valid) {
			throw this.getRequiredError(params);
		}

		const dataSeeds = await this.externalDataSourcesClient.query(this.id, { params, signal, analytics: this.config?.requestAnalytics });

		const seeds = dataSeeds.map((fd) => this.mapToSeed(fd)).filter((seed): seed is DataSeed => !!seed);

		return seeds.map((seed) => toDataSeedSafeOption(seed));
	}

	async search(q?: string, context?: Context, scope?: Scope, signal?: AbortSignal): Promise<DataSeed[]> {
		const args = this.getUrlParams(context, scope);
		const params = Object.assign({}, args);

		// Add search param
		if (this.searchInfo != null) {
			Object.assign(params, { [this.searchInfo.param]: q });
		}

		const valid = this.validateParams(params);

		if (!valid) {
			// don't throw when q is a required field
			if (!q && this.searchInfo?.isRequired) {
				return [];
			}
			throw this.getRequiredError(params);
		}

		const dataSeeds = await this.externalDataSourcesClient.query(this.id, { params, signal, analytics: this.config?.requestAnalytics });

		return dataSeeds.map((seed) => Object.assign(seed, this.mapToSeed(seed)));
	}

	// TODO provide implementation
	findAllBy(match: string, context?: Context, scope?: Scope, signal?: AbortSignal): Promise<DataSeed[]> {
		console.warn('External datasource does not support findByAll, falling back on search');

		return this.search(match, context, scope, signal);
	}

	async get(id: string): Promise<DataSeed | null> {
		const data = await this.externalDataSourcesClient.get(this.id, id);

		if (!data) {
			return null;
		}

		return this.mapToSeed(data);
	}

	mapToSeed(dataSeed?: DataSeed): DataSeed | null {
		return this.dataSourceConverter.toDataSeed(dataSeed ?? null, this.sourceConfig);
	}

	private getUrlParams(context?: Context, scope: Scope = {}): Dictionary<string> {

		return this.inputs.reduce<Dictionary<string>>((params, input) => {
			const expression = input.value;
			let value: unknown;

			if (expression) {
				value = this.expressionParser.resolve(expression, context, scope, 'External Loader');
			}

			if (isString(value) && expression !== SearchExpression) {
				params[input.parameter] = value;
			}

			return params;
		}, {});
	}

	private validateParams(params: Dictionary<string>): boolean {
		return this.getRequiredParamsNotProvided(params).length === 0;
	}

	private getRequiredError(params: Dictionary<string>): UfError {

		const identifiers = this.getRequiredParamsNotProvided(params);

		return new UfError(
			`Error loading data. Missing required parameters ${identifiers.join(', ')}`,
			ErrorType.Validation,
			{
				formError: true,
			},
		);
	}

	private getRequiredParamsNotProvided(params: Dictionary<string>): string[] {
		return this.requiredParams.filter((p) => ValidatorFunctions.isEmpty(params[p]));
	}

}
