import { Component, Input } from '@angular/core';
import { AssetProfile, FileData } from '@unifii/sdk';

import { RuntimeField } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

interface Item {
	id?: string;
	label?: string;
	url?: string;
}

@Component({
	selector: 'uf-file-list-description',
	templateUrl: './file-list-description.html',
	styleUrls: ['./file-list-description.less', './field-description.less'],
})
export class FileListDescriptionComponent implements ContentField {

	@Input({ required: true }) field: RuntimeField;
	@Input() content: (FileData | AssetProfile)[] | null;

	protected getItem(entry: FileData | AssetProfile): Item {

		// TODO extract detection logic to a guard function
		if ((entry as any).name) {
			const fileData = entry as FileData;

			return {
				id: fileData.id,
				label: fileData.name,
			};
		} else {
			const assetProfile = entry as AssetProfile;

			return {
				id: assetProfile.id,
				label: assetProfile.title,
				url: assetProfile.url,
			};
		}
	}

}
