<dl>
	<ng-container *ngFor="let item of displayItems">
		<dt *ngIf="item.term != null" [ngClass]="item.termTemplate">
			{{ item.term }}
			<uf-help *ngIf="item.help" [content]="item.help" class="gap-xs-top" />
		</dt>
		<dd *ngFor="let value of item.data; let i = index" [class.noBorder]="i !== 0">
			<uf-data-display [data]="value" />
		</dd>
	</ng-container>
	<ng-content></ng-content>
</dl>