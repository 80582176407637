import { Component, Injector, Input, inject } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { UfControl, UfFormControl } from '../../controls';
import { controlIterator } from '../../utils';

@Component({
	selector: 'uf-error-deep',
	templateUrl: './uf-error-deep.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfErrorDeepComponent, multi: true,
	}],
})
export class UfErrorDeepComponent implements ControlValueAccessor {

	/** Parallel reduced implementation of Control value accessor due to no value involved*/
	@Input() formControlName: string | null;

	private _control: UfFormControl = new UfControl();
	private _errorMessage: string | null;
	private injector = inject(Injector);

	@Input() set control(v: UfFormControl | null) {
		if (v) {
			this._control = v;
		}
	}

	get control(): UfFormControl {
		if (this.formControlName) {
			return this.injector.get(ControlContainer).control?.get(this.formControlName) as UfFormControl;
		}

		return this._control;
	}

	@Input() set errorMessage(v: string | null | undefined) {
		this._errorMessage = v?.trim().length ? v : null;
	}

	get errorMessage(): string | null {
		return this._errorMessage;
	}

	// empty implementation as this is a read only component
	writeValue() { /**/ }
	registerOnChange() { /**/ }
	registerOnTouched() { /**/ }

	protected get displayErrorMessage(): string | undefined {
		if (this.control.showError && this.control.errors?.message) {
			return this.errorMessage ?? this.control.errors.message as string | undefined;
		}

		for (const control of controlIterator(this.control)) {
			if (control.showError && control.errors?.message) {
				return this.errorMessage ?? control.errors.message as string;
			}
		}

		return undefined;
	}

}
