<uf-panel *ngIf="datasource" [bottomThreshold]="0" (scrollBottom)="pageTable.load()" class="container scroll-all">
	<div class="pad-sides">

		<div class="uf-app-bar transparent">

			<uf-checkbox [(value)]="empty" label="Empty" />
			<div>
				<uf-search [search]="query" [minSearchLength]="3" [debounce]="0" (searchChange)="onSearch($event)" />
			</div>
			<button [options]="styleOptions" [template]="columnsList" listBox title="styles" class="uf-action tertiary"
				type="button">
				<uf-icon name="sortDown" />
			</button>
			<ng-template #columnsList let-options="options">
				<ul class="uf-list uf-box small">
					<li *ngFor="let option of options" class="uf-list-button">
						<button (click)="styleModifier = option.identifier" type="button">
							<div class="multi-text">{{ option.name }}</div>
							<uf-icon *ngIf="styleModifier === option.identifier" name="tick" class="success" />
						</button>
					</li>
				</ul>
			</ng-template>
			<button (click)="updateDatasource()" title="Reload" class="uf-action" type="button">
				<uf-icon name="refreshApp" />
			</button>
			<button (click)="pageTable.load()" title="Next Page" class="uf-action" type="button">
				<uf-icon name="add" />
			</button>
			<button (click)="error()" title="Load Error" class="uf-action" type="button">
				<uf-icon name="offline" />
			</button>
			<button (click)="slow()" title="Slow" class="uf-action" type="button">
				<uf-icon name="app" />
			</button>
			<button (click)="updateFirstItem()" title="updateFirst" class="uf-action" type="button">
				<uf-icon name="edit" />
			</button>
			<button (click)="checkAll()" title="Check All" class="uf-action" type="button">
				<uf-icon name="checkboxTick" />
			</button>
			<button (click)="uncheckAll()" title="Uncheck All" class="uf-action" type="button">
				<uf-icon name="checkbox" />
			</button>
			<button (click)="download()" title="Download" class="uf-action" type="button">
				<uf-icon name="download" />
			</button>
			<button (click)="exhaustAhead()" title="Exhaust ahead" class="uf-action" type="button">
				<uf-icon name="radioDot" />
			</button>
			<uf-table-sort [table]="pageTable" />
			<uf-table-column-toggles [table]="pageTable" />
			<uf-table-card-expander [table]="pageTable" />
		</div>

		<uf-table #pageTable [config]="config" [datasource]="datasource" [sort]="sort" [ngClass]="styleModifier"
			(sortChange)="onSort($event)" (userPreferencesChange)="updateUserPreferences($event)" class="gap-lg-bottom">
			<div *cellFor="'custom'; let item" class="cellcustom">
				<a href="https://www.google.com/search?q=user+{{item.id}}" (click)="$event.stopPropagation()"
					target="_blank">
					Google me
				</a>
			</div>
			<ng-container *ngFor="let descriptor of customColumns">
				<ng-container *cellFor="descriptor.name; let item; let index = index; let column = column;">
					<uf-custom-cell [index]="index" [item]="item" [column]="column" [descriptor]="descriptor" />
				</ng-container>
			</ng-container>

			<div *cellFor="'cc997c77-5b5f-4394-877c-e667d7c97f91'; let item">
				<a [routerLink]="config.rowLink ? config.rowLink(item) : []">
					<uf-icon *ngIf="item.isExternal" name="tick" />
				</a>
			</div>
		</uf-table>

	</div>
</uf-panel>

<button (click)="add()" class="uf-action fixed primary large" type="button">
	<uf-icon name="add" />
</button>