import { Component } from '@angular/core';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-panel.html',

})
export class ShowPanelComponent {

	// Table
	readonly propertiesColConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'default', name: 'Default' },
		{ identifier: 'description', name: 'Description' },
	];

	readonly propertiesRows = [
		{
			name: 'scrollable',
			type: 'boolean',
			default: 'true',
			description: 'Allows the container to be scrollable',
		},
		{
			name: 'bottomThreshold',
			type: 'number',
			default: 'undefined',
			description: 'Emits an event if container scrolled within threshold',
		},
		{
			name: 'hasScrollBar',
			type: 'boolean',
			default: '',
			description: 'Is true when container is scrollable',
		},
		{
			name: 'forceReflow',
			type: 'boolean',
			default: 'undefined',
			description: 'Added for mobile iOS 12.2 scroll bug where scroll position fixes (forces container reflow)',
		},
	];

	readonly outputColConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];

	readonly outputRows = [
		{
			name: 'scrollBottom',
			type: 'number',
			description: 'Notifies listeners when scroll position is within the set threshold',
		},
		{
			name: 'scrollStop',
			type: 'boolean',
			description: 'Notify\'s listeners when scrolling has stoped',
		},
	];

	// Interactive
	template = `
		<uf-panel>
		</uf-panel>
	`;

}
