import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Optional, Output, inject } from '@angular/core';
import { Compound, Definition } from '@unifii/sdk';

import { CommonTranslationKey, FileUploader, RuntimeDefinition, RuntimeDefinitionAdapter, RuntimeField, WindowWrapper } from '@unifii/library/common';
import { ComponentRegistry, FormConfiguration, FormSettings } from '@unifii/library/smart-forms';
import { PrintFormComponentRegistry } from '@unifii/library/smart-forms/display';

import { FormComponentRegistry, FormDescriptionListRegistry } from '../../services';
import { InputTranslationKey } from '../../translations';

import { UfPrintFormComponentRegistry } from './print-component-registry';
import { UfPrintFormDescriptionListRegistry } from './print-description-list-component-registry';
import { PrintImage, amendDefinitionForPrinting, getImageLists } from './print-utils';

const formComponentRegistryFactory = (formComponentRegistry: ComponentRegistry | null): ComponentRegistry | undefined => {
	if (!formComponentRegistry) {
		// Create default registry
		return Injector.create({
			providers: [{ provide: UfPrintFormComponentRegistry, useClass: UfPrintFormComponentRegistry }],
		}).get(UfPrintFormComponentRegistry);
	}

	return formComponentRegistry;
};

export interface PrintConfig {
	definition: RuntimeDefinition;
	data: Compound;
	uploader: FileUploader;
	logoUrl?: string;
	disabled?: boolean;
	summary?: boolean;
	keepControls?: boolean;
}

export interface PrintField extends RuntimeField {
	print?: boolean;
	summaryPrint?: boolean;
}

@Component({
	selector: 'uf-print-form',
	templateUrl: './print-form.html',
	styleUrls: ['./print-form.less'],
	providers: [
		{ provide: FormComponentRegistry, useFactory: formComponentRegistryFactory, deps: [[new Optional(), PrintFormComponentRegistry]] },
		{ provide: FormDescriptionListRegistry, useClass: UfPrintFormDescriptionListRegistry },
	],
})
export class PrintFormComponent implements OnInit, OnDestroy {

	@Input({ required: true }) config: PrintConfig;
	@Output() done = new EventEmitter<boolean>();

	protected readonly inputTK = InputTranslationKey;
	protected readonly commonTK = CommonTranslationKey;
	protected readonly formConfig: FormConfiguration = { hideLabel: true };

	protected images: PrintImage[] = [];
	protected definition: RuntimeDefinition | undefined;
	protected logo: string | undefined;

	private imageReadyTimer?: NodeJS.Timer;

	private window = inject<Window>(WindowWrapper);
	private settings = inject(FormSettings);
	private runtimeDefinitionAdapter = inject(RuntimeDefinitionAdapter);

	async ngOnInit() {
		this.logo = this.config.logoUrl;

		const amendedDefinition = this.config.keepControls ?
			JSON.parse(JSON.stringify(this.config.definition._original)) as Definition :
			amendDefinitionForPrinting(this.config.definition._original, this.config);

		const definition = await this.runtimeDefinitionAdapter.transform(amendedDefinition);

		/** Append images to bottom of page */
		this.images = await getImageLists(this.config.definition._original, this.config.data, this.config.uploader);

		this.settings.uploader = this.config.uploader;

		this.definition = definition;
	}

	ngOnDestroy() {
		clearTimeout(this.imageReadyTimer);
	}

	protected imagesReady() {
		clearTimeout(this.imageReadyTimer);

		this.imageReadyTimer = setTimeout(() => {
			const currentPageTitle = document.title;
			
			// tricking print dialog into using form id or definition label
			document.title = this.config.data._seqId as string | undefined ?? this.definition?.label ?? ''; 
			this.print();
			// set the title back to what it was
			document.title = currentPageTitle; 
		}, 250);
	}

	private print() {
		this.window.print();
		this.done.emit();
	}

}
