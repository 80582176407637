import { Component, OnInit } from '@angular/core';
import { Dictionary } from '@unifii/sdk';

import { RuntimeField } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-field-message',
	templateUrl: './field-message.html',
	styleUrls: ['./field-message.less'],
})
export class FieldMessageComponent implements ContentField, OnInit {

	readonly iconNameMap: Dictionary<string> = {
		callout: 'moreInfoSolid',
		error: 'errorSolid',
		warning: 'warningSolid',
		alert: 'errorSolid',
		info: 'infoSolid',
	};

	readonly cssClassMap: Dictionary<string> = {
		alert: 'error',
	};

	field: RuntimeField;
	cssClassName?: string;
	iconName?: string;
	markdown?: string;

	ngOnInit() {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (this.field?.template) {
			this.iconName = this.iconNameMap[this.field.template] ?? this.field.template;
			this.cssClassName = this.cssClassMap[this.field.template] ?? this.field.template;
		}

		this.markdown = this.getMarkdown();
	}

	private getMarkdown(): string {

		const nullableField = this.field as RuntimeField | undefined;
		const content = nullableField?.help ?? '';

		// Add label as heading for backwards compat label should be deprecated at some stage
		if (nullableField?.label) {
			return '### ' + nullableField.label + '\r\n' + content;
		}

		return content;
	}

}
