<uf-panel class="stretch col">
	<div class="uf-app-bar">
		<h3>
			{{field.label}}
			<uf-help *ngIf="field.help" [content]="field.help" />
		</h3>
	</div>
	<div class="grid">
		<ng-template [ngForOf]="field.fields" let-field ngFor>
			<ng-template [ngIf]="field.identifier" [ngIfElse]="nonContent">
				<uf-field [field]="field" [scope]="formData" [(content)]="formData[field.identifier]" />
			</ng-template>
			<ng-template #nonContent>
				<uf-field [field]="field" [scope]="formData" />
			</ng-template>

			<uf-break-after *ngIf="field?.breakAfter" />
		</ng-template>
		<uf-error [control]="control" />
	</div>
	<div class="uf-form-actions small bottom">
		<button [disabled]="inProgress" (click)="cancel()" class="uf-button tertiary left"
			type="button">{{sharedTermsTK.ActionCancel | translate}}</button>
		<button [disabled]="inProgress" (click)="confirm()" class="uf-button primary"
			type="button">{{sharedTermsTK.ActionConfirm | translate}}</button>
	</div>
</uf-panel>