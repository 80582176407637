import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { EditData } from '../../guards';

@Component({
	selector: 'sc-editor',
	templateUrl: './show-editor.html',
})
export class ShowEditorComponent implements EditData {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	edited: boolean;

}
