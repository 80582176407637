export * from './data-display';
export * from './feedback';
export * from './inputs';
export * from './layout';
export * from './navigation';
export * from './surfaces';
export * from './show-components.component';

import { SC_PAGE_DATA_DISPLAY_COMPONENTS } from './data-display';
import { SC_PAGE_FEEDBACK_COMPONENTS } from './feedback';
import { SC_PAGE_INPUTS_COMPONENTS } from './inputs';
import { SC_PAGE_LAYOUT_COMPONENTS } from './layout';
import { SC_PAGE_NAVIGATION_COMPONENTS } from './navigation';
import { ShowComponentsComponent } from './show-components.component';
import { SC_PAGE_SURFACE_COMPONENTS } from './surfaces';

export const SC_PAGE_COMPONENTS_COMPONENTS = [
	...SC_PAGE_DATA_DISPLAY_COMPONENTS,
	...SC_PAGE_FEEDBACK_COMPONENTS,
	...SC_PAGE_INPUTS_COMPONENTS,
	...SC_PAGE_LAYOUT_COMPONENTS,
	...SC_PAGE_NAVIGATION_COMPONENTS,
	...SC_PAGE_SURFACE_COMPONENTS,
	ShowComponentsComponent,
];
