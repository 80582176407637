<h3 *ngIf="heading">{{ heading }}</h3>
<table *ngIf="ready" [ngClass]="cssClass" class="uf-table__container">
	<thead>
		<tr>
			<th *ngFor="let column of columns">{{column.label}}</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let row of rows">
			<ng-template [ngForOf]="columns" let-column ngFor>
				<th *ngIf="column.identifier && row[column.identifier] let cell" [ngSwitch]="cell.type">
					<ng-container *ngSwitchCase="fieldTypes.ImageList">
						<img *ngFor="let img of cell.value" [attr.src]="getImageUrl(img)"
							[attr.alt]="img.description" />
					</ng-container>
					<ng-container *ngSwitchCase="fieldTypes.MultiText">
						<div [markdownRenderer]="cell.value"></div>
					</ng-container>
					<ng-container *ngSwitchCase="fieldTypes.Phone">
						<a [attr.href]="'tel:' + cell.value">{{ cell.value }}</a>
					</ng-container>
					<ng-container *ngSwitchCase="fieldTypes.Email">
						<a [attr.href]="'mailto:' + cell.value">{{ cell.value }}</a>
					</ng-container>
					<ng-container *ngSwitchCase="fieldTypes.Website">
						<a [attr.href]="cell.value">{{ cell.value }}</a>
					</ng-container>
					<ng-container *ngSwitchCase="fieldTypes.Date">
						<p>{{ cell.value | dataDisplay:{type: dataTypes.Date, format: 'd/M/y'} }}</p>
					</ng-container>
					<ng-container *ngSwitchCase="fieldTypes.Time">
						<p>{{ cell.value | dataDisplay:{type: dataTypes.Time, format: 'h:mm a'} }}</p>
					</ng-container>
					<ng-container *ngSwitchCase="fieldTypes.DateTime">
						<p>{{ cell.value | dataDisplay:{type: dataTypes.DateTime, format: 'd/M/y, h:mm a'} }}</p>
					</ng-container>
					<ng-container *ngSwitchDefault>
						<p>{{ cell.value }}</p>
					</ng-container>
				</th>
			</ng-template>
		</tr>
	</tbody>
</table>