import { Component } from '@angular/core';
import { FormRecord } from '@angular/forms';
import { OPTION_NAME } from '@unifii/sdk';

import { Size } from '../../../constants';
import { RadioInputGroup, RadioOption } from '../../../models';

@Component({
	selector: 'uf-bool-radios',
	templateUrl: './bool-radio-inputs.html',
	styleUrls: ['./bool-radio-inputs.less'],
})
export class BoolRadioInputsComponent implements RadioInputGroup {

	readonly nameProperty = OPTION_NAME;
	
	focused: number | null;
	// style inputs
	columns = 2;
	// functional inputs
	groupName: string;
	formGroupName: string;
	formGroup: FormRecord;
	options: RadioOption[] = [];
	labels: string[] = [];
	unmatchedValue: any;
	
	protected readonly sizes = Size;

	get value(): any {
		return this.formGroup.get(this.groupName)?.value;
	}

}
