<p *ngIf="labels.message" class="small">
	{{ labels.message }}
</p>

<div [formGroup]="groupControl">
	<div class="input-wrap">

		<uf-password *ngIf="config.showOldPassword" [label]="labels.oldPasswordLabel"
			[formControlName]="controlKeys.OldPassword" [showReveal]="true">
			<span *ngIf="config.isRequired" class="suffix">*</span>
		</uf-password>

		<div class="row">
			<uf-password #passwordInput [label]="labels.passwordLabel" [formControlName]="controlKeys.Password"
				[showReveal]="true" class="grow">
				<span *ngIf="config.isRequired" class="suffix">*</span>
			</uf-password>

			<div *ngIf="config.canGenerate && passwordControl?.enabled" class="action-btn">
				<button [title]="labels.generateLabel" (click)="generatePassword()" type="button"
					class="uf-action tertiary">
					<uf-icon name="generatePassword" />
				</button>
			</div>

			<div *ngIf="config.canCopy && passwordControl?.enabled" class="action-btn">
				<button [title]="sharedTermsTK.ActionCopy | translate" (click)="clipboard.setText(value?.password)"
					type="button" class="uf-action tertiary">
					<uf-icon name="copy" />
				</button>
			</div>
		</div>

		<uf-password-indicator *ngIf="config.showStrengthIndicator" [formControlName]="controlKeys.Password"
			[label]="labels.strengthIndicatorLabel" [class.hidden]="!value?.password" />

	</div>

	<uf-checkbox *ngIf="config.showChangePasswordOnNextLogin" [label]="labels.changeFlagLabel"
		[formControlName]="controlKeys.ChangePasswordOnNextLogin" class="gap-md-top" />

	<uf-message *ngIf="pwned" [content]="commonTS.CreatePasswordErrorPwnedPassword | translate" icon="alert"
		class="error small" />
</div>