import { ComponentRef, ElementRef, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ErrorType, ensureUfError, isDictionary } from '@unifii/sdk';

import { MessageComponent, MessageLevel, SharedTermsTranslationKey } from '@unifii/library/common';

import { IconLookup, InputClassLookup } from '../constants';
import { FormField } from '../models';

export class FieldWarning {

	private messageComponentRef: ComponentRef<MessageComponent> | null;

	constructor(
		private component: FormField,
		private viewContainerRef: ViewContainerRef,
		private translate: TranslateService,
	) { }

	create(error?: unknown) {
		const ufError = ensureUfError(error);

		const level = isDictionary(ufError.data) && ufError.data.formError ?
			MessageLevel.Warning :
			MessageLevel.Error;

		const componentRef = this.componentRef;
		const { instance } = this.componentRef;

		instance.content = ufError.message;
		instance.icon = IconLookup[level];

		if (this.canRetry(ufError.type) && this.component.retry) {
			instance.actions = [{
				label: this.translate.instant(SharedTermsTranslationKey.ActionRetry) as string,
				action: this.component.retry,
			}];
		}

		(componentRef.location as ElementRef<HTMLElement>).nativeElement.classList.add(...[level, 'gap-bottom', 'gap-top', 'small']);

		this.updateInputClassList(level);
	}

	destroy() {

		if (this.messageComponentRef == null) {
			return;
		}

		this.messageComponentRef.destroy();
		this.messageComponentRef = null;
		this.updateInputClassList();
	}

	private get componentRef(): ComponentRef<MessageComponent> {

		if (this.messageComponentRef != null) {
			return this.messageComponentRef;
		}

		this.messageComponentRef = this.viewContainerRef.createComponent(MessageComponent);

		return this.messageComponentRef;
	}

	private updateInputClassList(level?: MessageLevel) {

		// Remove any existing classes set by this component
		const classList = this.inputClass
			.filter((name) => !Object.keys(InputClassLookup)
				.find((key) => InputClassLookup[key] === name),
			);

		const classValue = level ? InputClassLookup[level] : undefined;

		if (level && classValue) {
			classList.push(classValue);
		}

		this.component.cssClass = classList;
	}

	private get inputClass(): string[] {

		if (!Array.isArray(this.component.cssClass)) {
			return (this.component.cssClass ?? '').split(' ');
		}

		return this.component.cssClass;
	}

	private canRetry(type: ErrorType): boolean {
		return type === ErrorType.Connection || type === ErrorType.Server || type === ErrorType.Unknown;
	}

}
