import { Injectable } from '@angular/core';
import { FormDataClient, Progress } from '@unifii/sdk';

import { FileUploader } from '@unifii/library/common';

@Injectable({ providedIn: 'root' })
export class EmpFormUploader implements FileUploader {

	// Keep the constructor, programmatic instance of EmpFormUploader are a valid usage
	constructor(private formDataClient: FormDataClient) { }

	upload(file: File, progressCallback?: (progress: Progress) => void, signal?: AbortSignal): Promise<Progress> {
		return this.formDataClient.uploadAttachment(file, { onProgress: progressCallback, signal });
	}

	getUrl(id: string): Promise<string> {
		return this.formDataClient.getAttachmentUrl(id);
	}

}
