import { Component } from '@angular/core';

import { ModalConfirmData, ModalService } from '@unifii/library/common';

import { ShowModalSampleComponent } from './show-modal-sample.component';

@Component({
	selector: 'sc-show-modal-testing',
	templateUrl: './show-modal-testing.html',
	styleUrls: ['./show-modal-testing.less'],
})
export class ShowModalTestingComponent {

	private readonly data: ModalConfirmData = {
		title: 'Modal Title',
		confirmLabel: 'Yes',
		cancelLabel: 'No',
	};

	constructor(private modalService: ModalService) { }

	openFit() {
		void this.modalService.openFit<ModalConfirmData, boolean>(ShowModalSampleComponent, this.data);
	}

	openMedium() {
		void this.modalService.openMedium<ModalConfirmData, boolean>(ShowModalSampleComponent, this.data);
	}

	openLarge() {
		void this.modalService.openLarge<ModalConfirmData, boolean>(ShowModalSampleComponent, this.data);
	}

	openFullScreen() {
		void this.modalService.openFullScreen<ModalConfirmData, boolean>(ShowModalSampleComponent, this.data);
	}

	openConfirm() {
		void this.modalService.openConfirm({ title: 'Title', message: '# Subtitle  \nA description here...' });
	}

	openAlert() {
		void this.modalService.openAlert({ title: 'Alert', message: 'Yewwww my awesome alert!' });
	}

}
