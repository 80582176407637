import { Dictionary, getError } from '@unifii/sdk';

import { MergedTranslationEntry, TranslationEntry } from './translations-model';

/**
 * Load the translations for the requested language from the repositories.
 * Apply an overriding merge logic that prioritize entries of repositories based on the repositories order
 * Translations are fetched from ${repositoriesURI}/${language}
 * @param language ISO code of the language
 * @param repositoriesURI repositories, in order of priority
 * @param expectedEntries translations entries requested by the app
 * @returns dictionary of translations loaded
 */
export const loadDictionary = async(language: string, repositoriesURI: string[], expectedEntries?: TranslationEntry[]): Promise<Dictionary<string>> => {

	const translations: Dictionary<string> = {};

	for (const uri of repositoriesURI.reverse()) {
		try {
			const response = await fetch(`${uri}/${language}.json`);

			if (response.ok) {
				Object.assign(translations, await response.json());
			} else {
				throw await getError(response);
			}
		} catch (err) {
			console.warn(`Failed to load "${language}" translations from repository "${uri}"`, err);
		}
	}

	if (expectedEntries) {
		verifyKeys(expectedEntries, translations, language);
	}

	return translations;
};

const verifyKeys = (entries: TranslationEntry[], langBundle: Dictionary<string>, lang: string) => {

	const mergedEntries = entries.reduce(mergeEntries, []);

	langBundle = Object.assign({}, langBundle);

	const duplicatesMap = new Map<string, string[]>();

	for (const entry of entries) {
		for (const key of Object.values(entry.dictionary)) {

			let duplicates = duplicatesMap.get(key);

			if (!duplicates) {
				duplicates = [entry.identifier];
				duplicatesMap.set(key, duplicates);
			} else {
				duplicates.push(entry.identifier);
			}
		}
	}

	for (const [key, duplicates] of duplicatesMap.entries()) {
		if (duplicates.length > 1) {
			console.warn(`(?) ${key}: Duplicated between dictionaries [${entries.map((entry) => entry.identifier).join(', ')}]`);
		}
	}

	const limit = 25;
	let missingCount = 0;
	let deprecatedCount = 0;

	for (const entry of mergedEntries) {
		if (!langBundle[entry.value]) {
			missingCount += 1;
			if (missingCount < limit) {
				console.warn(`(-) ${entry.identifier}: Missing ${lang} translation for key '${entry.value}'`);
			}
		} else {
			// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
			delete langBundle[entry.value];
		}
	}

	if (missingCount > limit) {
		console.error(`(-) Missing an additional ${missingCount - 25} keys`);
	}

	for (const key of Object.keys(langBundle)) {
		deprecatedCount += 1;
		if (deprecatedCount < limit) {
			console.warn(`(+) Deprecated ${lang} translation for key '${key}'`);
		}
	}

	if (deprecatedCount > limit) {
		console.error(`(+) An additional ${missingCount - 25} deprecated keys have been found`);
	}
};

const mergeEntries = (merged: MergedTranslationEntry[], { identifier, dictionary }: TranslationEntry) => {
	const flattened = Object.keys(dictionary).map((key) => ({ identifier, key, value: dictionary[key] ?? '' }));

	return [...merged, ...flattened] satisfies MergedTranslationEntry[];
};
