import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FilterEntry, FilterViewMode, IFilterComponent, NumberRange } from '../../../models';

@Component({
	selector: 'uf-filter-number-range',
	templateUrl: './filter-number-range.html',
	styleUrls: ['./field-filter.less'],
})
export class FilterNumberRangeComponent implements IFilterComponent<NumberRange> {

	@Input() mode = FilterViewMode.Input;
	@Input({ required: true }) entry: FilterEntry;
	@Output() valueChange = new EventEmitter<NumberRange | null>();

	protected readonly modes = FilterViewMode;

	private _value: NumberRange = {};

	@Input({ required: true }) set value(v: NumberRange | null) {
		this._value = v ?? {};
	}

	get value(): NumberRange {
		return this._value;
	}

	get label(): string {
		return this.entry.label;
	}

	get isEmpty(): boolean {
		return this.value.from == null && this.value.to == null;
	}

	protected get displayFrom(): string {
		return `${this.entry.label} > ${this.value.from}`;
	}

	protected get displayTo(): string {
		return `${this.entry.label} < ${this.value.to}`;
	}

	protected removeFrom() {
		delete this.value.from;
		this.valueChanged();
	}

	protected removeTo() {
		delete this.value.to;
		this.valueChanged();
	}

	protected valueChanged() {
		this.valueChange.emit(this.isEmpty ? null : this.value);
	}

}
