import { Inject, Injectable } from '@angular/core';
import { Compound, Definition, FieldType, Link, Page, PublishedContent, Query } from '@unifii/sdk';

import { RuntimeDefinition, RuntimeDefinitionAdapter, RuntimePage, RuntimePageAdapter } from '@unifii/library/common';

import { DisplayContent } from '../models';

/**
 * Prepares Definitions and Compound for rendering
 * 1. Gets data from published content
 * 2. Replaces Links with Compounds
 */
@Injectable({ providedIn: 'root' })
export class DisplayService {

	constructor(
		@Inject(PublishedContent) private content: PublishedContent,
		private runtimeDefinitionAdapter: RuntimeDefinitionAdapter,
		private runtimePageAdapter: RuntimePageAdapter,
	) { }

	async getView(identifier: string): Promise<DisplayContent> {
		return this.renderCompound(
			await this.content.getViewDefinition(identifier),
			await this.content.getView(identifier),
		);
	}

	/**
	 * @param identifier - backwards compatibility with id
	 */
	async getPage(identifier: string): Promise<DisplayContent> {
		return this.renderPage(await this.content.getPage(identifier));
	}

	async getCollectionItem(identifier: string, id: string): Promise<DisplayContent> {
		return this.renderCompound(
			await this.content.getCollectionDefinition(identifier),
			await this.content.getCollectionItem(identifier, id),
		);
	}

	renderPage(page: Page | RuntimePage): Promise<DisplayContent> {
		return this.updatePageLinks(page);
	}

	renderCompound(definition: Definition | RuntimeDefinition, compound: Compound): Promise<DisplayContent> {
		const compoundCopy = JSON.parse(JSON.stringify(compound)) as Compound;

		return this.updateCompoundLinks(definition, compoundCopy);
	}

	private async updateCompoundLinks(definition: Definition | RuntimeDefinition, compound: Compound = {}): Promise<DisplayContent> {

		const runtimeDefinition = await this.runtimeDefinitionAdapter.transform(definition);

		runtimeDefinition.fields = await Promise.all(runtimeDefinition.fields.map(async(field) => {

			if (!field.identifier || !field.definitionIdentifier) {
				return field;
			}

			const value = compound[field.identifier] as Link[];

			if (field.type !== FieldType.LinkList || !Array.isArray(value) || value.length === 0) {
				return field;
			}

			const identifier = field.definitionIdentifier;
			const collectionDefinition = await this.runtimeDefinitionAdapter.transform(await this.content.getCollectionDefinition(identifier));

			/** Update compound and fields */
			compound[field.identifier] = await this.convertLinksToCompounds(identifier, value);
			field.fields = collectionDefinition.fields;

			return field;
		}));

		return { definition: runtimeDefinition, compound };
	}

	private async updatePageLinks(page: Page | RuntimePage): Promise<DisplayContent> {

		const runtimePage = this.runtimePageAdapter.transform(page);

		runtimePage.fields = await Promise.all(runtimePage.fields.map(async(field) => {

			if (!field.definitionIdentifier) {
				return field;
			}

			const value = field.value as Link[];

			if (field.type !== FieldType.LinkList || !Array.isArray(value) || value.length === 0) {
				return field;
			}

			const identifier = field.definitionIdentifier;
			const collectionDefinition = await this.runtimeDefinitionAdapter.transform(await this.content.getCollectionDefinition(identifier));

			/** Update compound and fields */
			field.value = await this.convertLinksToCompounds(identifier, value);
			field.fields = collectionDefinition.fields;

			return field;
		}));

		return { page: runtimePage };
	}

	private async convertLinksToCompounds(identifier: string, linkValue: Link[]): Promise<Compound> {

		const query = new Query().in('id', linkValue.map((l) => l.id));
		const collections = await this.content.queryCollection(identifier, query);

		/**
		 * Merge value with collection data so it can display as tables etc
		 * map required to keep original order
		 */
		return linkValue.map((v) => Object.assign(v, collections.find((c) => c.id === v.id)));
	}

}
