<sc-page-content width="xl" identifier="container">
	<ng-container editorInputs>
		<uf-radio [options]="options" [(value)]="className" (valueChange)="modifierChange()" class="col-12"
			label="Max Width" columns="1" valueProperty="value" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander>
			<sc-column-demo [ngClass]="className" class="uf-grid" style="--containerBackgroundColour: white;" />
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">Breakpoints</h3>
		<sc-info-table [columnConfig]="breakPointTableConfig" [rows]="breakPointTableInfo" />
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="tableConfig" [rows]="tableInfo" />
	</ng-container>

</sc-page-content>