import { HierarchyUnitFormData, HierarchyUnitState, HierarchyUnitWithChildCount, isNotNull } from '@unifii/sdk';

import { HierarchyCascadeSelectionMode, HierarchyUnitIdentifier, HierarchyUnitProvider } from '../../../models';
import { HierarchyService } from '../../../services';
import { HierarchyFunctions, getValueAsArray } from '../../../utils';
import { CascadeSelectionDataSource, CascadeSelectionStep } from '../../cascade-selection';

import { HierarchyUnitCache } from './hierarchy-unit-cache';

export class HierarchyCascadeSelectionDataSource implements CascadeSelectionDataSource<HierarchyUnitWithChildCount> {

	private mode: HierarchyCascadeSelectionMode;
	private ceiling: string | undefined;
	private activeUnitsOnly: boolean;
	private rootUnit: HierarchyUnitWithChildCount | undefined;
	private ceilingUnitsId: string[] = [];

	constructor(
		private provider: HierarchyUnitProvider,
		private cache: HierarchyUnitCache,
		private errors: HierarchyService,
		private options?: { // TODO UNIFII-7942 point 2.a
			mode?: HierarchyCascadeSelectionMode;
			ceiling?: HierarchyUnitIdentifier | null;
			activeUnitsOnly?: boolean | null;
			nameProperty: string | ((option: HierarchyUnitWithChildCount) => string);
		},
	) {
		this.mode = this.options?.mode ?? 'single';
		// TODO defined 'root' as a const in SDK
		this.ceiling = (!this.options?.ceiling || this.options.ceiling === 'root') ? undefined : HierarchyFunctions.getId(this.options.ceiling);
		this.activeUnitsOnly = !!options?.activeUnitsOnly;
	}

	/**
	 * Initialize the dataSource by pre-loading root and ceiling units
	 * @throws UfHierarchyError in case of configuration issues
	 */
	async init() {
		try {
			this.rootUnit = await this.provider.getUnit('root');
		} catch (e) {
			console.error('HierarchyCascadeSelectionDataSource.init - load root unit error', e);
		}

		if (!this.rootUnit || this.rootUnit.childCount === 0) {
			throw this.errors.misconfiguredError;
		}
		
		if (!this.ceiling) {
			return;
		}

		const ceiling = await this.provider.getUnit(this.ceiling);

		if (!ceiling?.childCount) {
			throw this.errors.misconfiguredError;
		}

		const ceilingUnits = await this.getUnits(ceiling.path);

		if (ceilingUnits.length !== ceiling.path.length) {
			throw this.errors.misconfiguredError;
		}
		
		this.ceilingUnitsId = ceilingUnits.map((unit) => unit.id);
	}
	
	getNextStep(value: (HierarchyUnitWithChildCount | HierarchyUnitWithChildCount[])[]): Promise<CascadeSelectionStep<HierarchyUnitWithChildCount> | undefined> {

		if (!this.rootUnit) {
			return Promise.resolve(undefined);
		}

		const lastUnit = getValueAsArray(value[value.length - 1] ?? this.rootUnit)[0];
		
		if (!lastUnit?.childCount) {
			return Promise.resolve(undefined);
		}

		const nextStep = {
			label: lastUnit.childrenLabel,
			predetermined: false,
			isMultiple: this.mode === 'siblings',
			disabled: this.ceilingUnitsId.length > value.length,
			search: (query: string | undefined) =>
				this.provider.getChildren(lastUnit.id, { q: query, state: this.activeUnitsOnly ? HierarchyUnitState.Active : undefined }),
			display: this.options?.nameProperty ?? 'label',
		} satisfies CascadeSelectionStep<HierarchyUnitWithChildCount>;
		
		return Promise.resolve(nextStep);
	}

	/**
	 * Transform the component value to its equivalent cascade-selection steps' values
	 * @param value this component value
	 * @throws UfHierarchyError - when the value is not compatible with the component configuration
	 * @returns cascade-selection steps' values
	 */
	async getStepsValue(value: HierarchyUnitFormData | HierarchyUnitFormData[] | null | undefined): Promise<HierarchyUnitWithChildCount[] | HierarchyUnitWithChildCount[][] | undefined> {
		
		if (value && ((this.mode === 'single' && Array.isArray(value)) || this.mode === 'siblings' && !Array.isArray(value))) {
			throw this.errors.invalidError;
		}

		const values = getValueAsArray(value);
		const firstUnit = values[0];
		
		if (!firstUnit) {
			if (!this.ceiling) {
				return undefined;
			}

			const ceilingUnits = await this.getUnits((await this.cache.getUnit(this.ceiling))?.path ?? []);

			return this.mode === 'single' ? ceilingUnits : ceilingUnits.map(getValueAsArray);
		}

		// Check that all units are siblings
		if (new Set(values.map((unit) => unit.path[unit.path.length - 2]?.id).filter(isNotNull)).size > 1) {
			throw this.errors.invalidError;
		}

		if (this.ceiling && !firstUnit.path.some((step) => step.id === this.ceiling)) {
			throw this.errors.invalidError;
		}
		
		if (this.mode === 'single') {
			return this.getUnits(firstUnit.path);
		}

		const parentUnits = (await this.getUnits(firstUnit.path.slice(0, -1))).map(getValueAsArray);
		const siblingUnits = await this.getUnits(values);

		return [...parentUnits, siblingUnits];
	}

	private async getUnits(identifiers: HierarchyUnitIdentifier[]): Promise<HierarchyUnitWithChildCount[]> {
		const units: HierarchyUnitWithChildCount[] = [];
			
		for (const identifier of identifiers) {
			const unit = await this.cache.getUnit(identifier);
			
			if (unit) {
				units.push(unit);
			}
		}
		
		return units;
	}

}
