import { ExpressionParser, RuntimeField } from '@unifii/library/common';

import { FormField } from '../models';

import { ScopeManager } from './scope-manager';

export class ShowIfManager {

	constructor(
		private component: FormField,
		private field: RuntimeField,
		private scopeManager: ScopeManager,
		private expressionParser: ExpressionParser,
	) { }

	exec(): boolean {
		if (!this.component.control) {
			console.warn(`ShowIfManager.exec - component.control null for field ${this.field.identifier}, result defaulted to 'true'`);

			return true;
		}

		return !!this.expressionParser.resolve(
			this.field.showIf ?? '',
			this.scopeManager.createContext(this.component.control.value),
			this.component.scope,
			'Visibility',
		);
	}

}
