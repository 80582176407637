<div class="container">
	<div class="grid">
		<sc-configuration [configuration]="config" (valueChange)="cv = $event" class="col-1of1" title="Settings" />

		<div *ngIf="cv.simple" class="col-1of1">
			<h3>Statics - no search</h3>
			<div class="fieldset-item">
				<span>Model value</span>
				<span>{{simpleValue}}</span>
			</div>
			<div class="fieldset-item">
				<span>Emitted value</span>
				<span>{{staticEmittedValue}}</span>
			</div>
			<div class="fieldset-item">
				<span>Control touched | valid | enabled | # options</span>
				<span>{{staticControl.touched}} | {{staticControl.valid}} | {{staticControl.enabled}} |
					{{staticResults.length}}</span>
			</div>

			<div class="row space-children">
				<button (click)="staticValue = null" class="uf-button" type="button">Clear</button>
				<button (click)="staticValue = 'Pineapple'" class="uf-button" type="button">Set (Pineapple)</button>
			</div>

			<uf-autocomplete [name]="cv.name" [disabled]="cv.disabled" [control]="staticControl"
				[focusAfterDelete]="true" [options]="staticResults" [allowCustom]="cv.customs" [(value)]="staticValue"
				(valueChange)="emittedSimple($event)" label="Statics" placeholder="Search for..." />
		</div>

		<div *ngIf="cv.simple" class="col-1of1">
			<h3>Simple values</h3>
			<div class="fieldset-item">
				<span>Model value</span>
				<span>{{simpleValue}}</span>
			</div>
			<div class="fieldset-item">
				<span>Emitted value</span>
				<span>{{simpleEmittedValue}}</span>
			</div>
			<div class="fieldset-item">
				<span>Control touched | valid | enabled</span>
				<span>{{simpleControl.touched}} | {{simpleControl.valid}} | {{simpleControl.enabled}}</span>
			</div>

			<div class="row space-children">
				<button (click)="simpleValue = null" class="uf-button" type="button">Clear</button>
				<button (click)="simpleValue = 'Pineapple'" class="uf-button" type="button">Set (Pineapple)</button>
			</div>

			<uf-autocomplete [minSearchLength]="3" [name]="cv.name" [disabled]="cv.disabled" [control]="simpleControl"
				[focusAfterDelete]="true" [options]="simpleResults" [allowCustom]="cv.customs" [(value)]="simpleValue"
				(valueChange)="emittedSimple($event)" (searchChange)="findSimple($event)" label="Simple source"
				placeholder="Search for..." />
		</div>

		<div *ngIf="cv.complex" class="col-1of1">
			<h3>Complex values</h3>
			<div class="fieldset-item">
				<span>Model value</span>
				<span>{{complexValue | json}}</span>
			</div>
			<div class="fieldset-item">
				<span>Emitted value</span>
				<span>{{complexEmittedValue | json}}</span>
			</div>

			<div class="row space-children">
				<button (click)="complexValue = null" class="uf-button" type="button">Clear</button>
				<button (click)="complexValue = {id: 7,  key: 'PP', name: 'Pineapple', rating: 11}" class="uf-button"
					type="button">Set
					(Pineapple)</button>
			</div>
			<div class="spacer"></div>
			<uf-autocomplete [minSearchLength]="2" [name]="cv.name" [disabled]="cv.disabled" [control]="complexControl"
				[options]="complexResults" [allowCustom]="cv.customs" [nameProperty]="complexNameProperty"
				[(value)]="complexValue" (valueChange)="emittedComplex($event)" (searchChange)="findComplex($event)"
				label="Complex source" placeholder="Search for..." />
		</div>

		<div *ngIf="cv.template" class="col-1of1">
			<h3>Custom Template</h3>
			<p></p>
			<div class="fieldset-item">
				<span>Model value</span>
				<span>{{complexTemplateValue | json}}</span>
			</div>
			<div class="fieldset-item">
				<span>Emitted value</span>
				<span>{{complexTemplateEmittedValue | json}}</span>
			</div>

			<div class="row space-children">
				<button (click)="complexTemplateValue = null" class="uf-button" type="button">Clear</button>
				<button (click)="complexTemplateValue = {id: 7,  key: 'PP', name: 'Pineapple', rating: 11}"
					class="uf-button" type="button">Set
					(Pineapple)</button>
			</div>

			<uf-autocomplete [name]="cv.name" [disabled]="cv.disabled" [control]="complexTemplateControl"
				[options]="complexResults" [allowCustom]="cv.customs" [(value)]="complexTemplateValue"
				(valueChange)="emittedComplexTemplate($event)" (searchChange)="findComplex($event)"
				label="Complex template" placeholder="Search for..." nameProperty="name">
				<ng-template #listBox let-options="options" let-select="select" let-active="active">
					<ul class="uf-list">
						<li *ngFor="let option of options; let i = index" [class.active]="i === active"
							(click)="select(option)" class="uf-list-button block">
							<uf-data-display-list>
								<dt>Term:</dt>
								<dd>{{ option.name }}</dd>
								<dt>Desciription:</dt>
								<dd>Sed posuere consectetur est at lobortis. Cum sociis natoque penatibus et
									magnis dis
									parturient montes, nascetur ridiculus mus. Vestibulum id ligula porta felis euismod
									semper.</dd>
							</uf-data-display-list>
						</li>
					</ul>
				</ng-template>
			</uf-autocomplete>
		</div>

	</div>
</div>