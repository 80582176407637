<ng-content />

<ng-template [ngIf]="pages.length > 1">
	<div class="links row justify-center">
		<a (click)="back()" class="cursor">
			<uf-icon name="arrowBack" class="small" />
		</a>
		<div class="row">
			<a *ngFor="let page of pages; let i = index" (click)="activate(i)" class="cursor carousel-button">
				<uf-icon
					[name]="i === activeIx ? 'radioSolid' : viewedMap.has(i) ? 'success' : showError ? 'radioSolid' : 'radioDot' "
					[class.primary]="i === activeIx" [class.success]="required && viewedMap.has(i) && i !== activeIx"
					[class.error]="showError && !viewedMap.has(i)" class="x-small carousel-icon" />
				<uf-icon [name]="viewedMap.has(i) ? 'successSolid' : 'radioSolid'" [class.primary]="i === activeIx"
					class="x-small carousel-icon--hover" />
			</a>
		</div>
		<a (click)="next()" class="cursor">
			<uf-icon name="arrowForward" class="small" />
		</a>
	</div>
</ng-template>