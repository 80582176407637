import { Pipe, PipeTransform } from '@angular/core';
import { FieldType } from '@unifii/sdk';

import { RuntimeField } from '@unifii/library/common';
import { FieldHelperFunctions } from '@unifii/library/smart-forms';

@Pipe({ name: 'fieldFilter' })
export class FieldFilterPipe implements PipeTransform {

	transform(fields: RuntimeField[], type: FieldType, roles: string[]) {

		if (!type || !roles) {
			return fields;
		}

		/**
		 * A field match the filter when:
		 * a) Its type match the requested type
		 * b) It has no role specified or one of its role (is a coma separated list) is contained in the requested roles list
		 */
		return fields.filter((f) => {
			// Check condition A
			if (f.type !== type) {
				return false;
			}

			// Check condition B
			return FieldHelperFunctions.areRolesMatching(roles, f.roles);
		});
	}

}
