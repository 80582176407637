import { InjectionToken } from '@angular/core';
import { Address, DataSeed, GeoLocation } from '@unifii/sdk';

export interface GeocodeOptions {
	address?: Address;
	location?: GeoLocation;
}

export enum LocationProviderType {
	Google = 'Google',
	Unifii = 'Unifii'
}

/**
 * This interface is used to provide Location, now we only have one Provider which is Google
 * because some customers don't want to pay for google keys, we're providing a simple location service without maps.
 * that's why getMapUrl is optional, because it's provided by google to load an image of a map based on Geolocation
 */
export interface LocationProvider {
	type: LocationProviderType;
	regionGeoLocation?: GeoLocation;
	locate(): Promise<GeoLocation>;
	search?: (q: string) => Promise<DataSeed[]>;
	findLocation?: (address: string, region: string) => Promise<Address>;
	findAddress?: (location: GeoLocation) => Promise<Address>;
	getMapUrl?(lat: number, lng: number, marker?: GeoLocation, zoom?: number, size?: { width: number; height: number }): string;
}

export const LocationProvider = new InjectionToken<LocationProvider>('LocationProvider');
