import { InjectionToken } from '@angular/core';
import { FileType } from '@unifii/sdk';

import { DataLoaderFactory, FileUploader } from '@unifii/library/common';

export interface FormSettings {

	dataLoaderFactory: DataLoaderFactory;
	uploader?: FileUploader;
	print?: boolean;
	dataSourceMinSearchLength?: number;

	getAllowedFileTypes?(): Promise<FileType[] | null>;
}

export const FormSettings = new InjectionToken<FormSettings>('FormSettings');
