import { Component, Injector, Input, Optional } from '@angular/core';
import { Compound } from '@unifii/sdk';

import { RuntimeDefinition } from '@unifii/library/common';
import { ComponentRegistry } from '@unifii/library/smart-forms';

import { UfViewComponentRegistry, ViewComponentRegistry } from '../../services';

export const viewComponentRegistryFactory = (viewComponentRegistry: ComponentRegistry | null): ComponentRegistry => {

	if (viewComponentRegistry == null) {
		// Create default registry
		return Injector.create({
			providers: [{ provide: UfViewComponentRegistry, useClass: UfViewComponentRegistry }],
		}).get(UfViewComponentRegistry);
	}

	return viewComponentRegistry;
};

@Component({
	selector: 'uf-view',
	templateUrl: './view.html',
	styleUrls: ['./view.less'],
	providers: [{ provide: ComponentRegistry, useFactory: viewComponentRegistryFactory, deps: [[new Optional(), ViewComponentRegistry]] }],
})
export class UfViewComponent {

	@Input() definition: RuntimeDefinition;
	@Input() compound: Compound;

	@Input() set classList(v: string[]) {
		this._classList = v;
	}

	get classList(): string[] {
		return this._classList;
	}

	private _classList = ['grid--fixed', 'body-copy'];

}
