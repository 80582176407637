export * from './data-seeds-modal.component';
export * from './content-link-picker.component';

import { ContentLinkPickerComponent } from './content-link-picker.component';
import { DataSeedsModalComponent } from './data-seeds-modal.component';

export const UF_INPUT_MODALS = [
	DataSeedsModalComponent,
	ContentLinkPickerComponent,
];
