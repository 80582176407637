import { Injectable, inject } from '@angular/core';
import { isBoolean, isNumber, isString } from '@unifii/sdk';

import { Context, ExpressionFunctionsSet, Scope } from '../models';
import { UfExpressionFunctionsSet } from '../utils';

import { ExpressionParser } from './expression-parser';

/**
 * Template String Parser is responsible for parsing expression when placed in brackets
 * eg 'Username is {{ $user.username }}
 */
@Injectable({ providedIn: 'root' })
export class TemplateStringParser {

	private expressionParser = inject(ExpressionParser);

	parse(template: string, context: Context = { self: {}, root: {} }, scope: Scope = {}, source = '', functions: ExpressionFunctionsSet = UfExpressionFunctionsSet): string {

		const matches = template.match(/{{.*?}}/g) ?? [];

		for (const match of matches) {
			const expression = match.replace(/{{|}}/g, '').trim();
			const result = this.expressionParser.resolve(expression, context, scope, source, functions) ?? '';
			// TODO should we support more types? should we relax the SDK coerceDataToString function instead
			const stringResult = isString(result) || isNumber(result) || isBoolean(result) ? `${result}` : '';
			
			template = template.replace(match, stringResult);
		}

		return template;
	}

}

