import { Component } from '@angular/core';

import { SafeUrlFunctions } from '@unifii/library/common';

@Component({
	selector: 'sc-encoding-funcs',
	templateUrl: './show-encoding-funcs.html',
})
export class ShowEncodingFuncsComponent {

	protected jsonValue: string | null;
	protected encoded: string | null;

	protected encode() {
		this.encoded = SafeUrlFunctions.encodeStr(this.jsonValue ?? '');
	}

	protected decode() {
		this.jsonValue = SafeUrlFunctions.decodeStr(this.encoded ?? '') ?? '';
	}

}
