import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input, Renderer2, ViewContainerRef, inject } from '@angular/core';

import { HelpComponent } from '@unifii/library/common';

/**
 * Ellipsis the element innerHTML when is longer than [contentEllipsis] input
 * and add an info help-component to modal display the original innerHTML
 */
@Directive({
	selector: '[contentEllipsis]',
})
export class ContentEllipsisDirective implements AfterViewInit {
	
	@Input() contentEllipsis: number | null;
	
	private viewContainerRef = inject(ViewContainerRef);
	private render = inject(Renderer2);
	private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
	private nativeElement = this.elementRef.nativeElement;
	private cdr = inject(ChangeDetectorRef);
	
	ngAfterViewInit() {
		if (this.contentEllipsis && this.nativeElement.innerHTML.length > this.contentEllipsis) {
			const helpComponentRef = this.viewContainerRef.createComponent(HelpComponent);

			helpComponentRef.instance.content = this.nativeElement.innerHTML;
			this.nativeElement.innerHTML = this.nativeElement.innerHTML.substring(0, this.contentEllipsis) + '...';
			this.render.appendChild(this.nativeElement, helpComponentRef.location.nativeElement);
			this.cdr.detectChanges();
		}
	}
	
}
