export * from './drawer.component';
export * from './expander-button.component';
export * from './expander.component';
export * from './panel.component';
export * from './message.component';

import { DrawerComponent } from './drawer.component';
import { ExpanderButtonComponent } from './expander-button.component';
import { ExpanderComponent } from './expander.component';
import { MessageComponent } from './message.component';
import { PanelComponent } from './panel.component';

export const UF_CONTAINER_COMPONENTS = [
	DrawerComponent, PanelComponent, ExpanderComponent, MessageComponent, ExpanderButtonComponent,
];
