import { Component } from '@angular/core';

import { ToastService, UfControl } from '@unifii/library/common';

const options = [
	{ name: 'Apple', identifier: 'apple' },
	'pear',
	{ name: 'Orange', identifier: 'orange' },
	{ name: 'Toblerone', identifier: 'toblerone' },
	{ name: 'Peach', identifier: 'peach' },
	{ identifier: 'kiwi' },
	{ name: 'Banana', identifier: 'banana' },
];

@Component({
	selector: 'sc-show-select',
	templateUrl: './show-select.html',
	styleUrls: ['./show-select.less'],
})
export class ShowSelectComponent {

	type = 'Select';
	options: any[];
	value: any;
	readonly: boolean;
	isInline: boolean;
	isFloating: boolean;
	isFocused: boolean;
	control = new UfControl();

	constructor(private toast: ToastService) {
		this.resetOptions();

		this.control.valueChanges.subscribe((v) => { this.toast.info('Control change ' + JSON.stringify(v) + '\n' + JSON.stringify(this.value)); },
		);
	}

	emptyOptions() {
		this.options = [];
	}

	resetOptions() {
		this.options = JSON.parse(JSON.stringify(options));
	}

	resetValue() {
		this.value = undefined;
	}

	valueChanged(value: any) {
		this.toast.success('Selected ' + JSON.stringify(value));
		console.log('value', value, this.value);
	}

	setNonsense() {
		this.value = { name: 'hey!' };
	}

	pushOption() {
		this.options = this.options.concat('broccoli');
	}

	assignBroccoli() {
		this.value = 'broccoli';
	}

	assignApple() {
		this.value = 'apple';
	}

}
