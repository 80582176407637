import { Component, HostBinding, inject } from '@angular/core';
import { GeoLocation } from '@unifii/sdk';

import { SharedTermsTranslationKey } from '../../../translations';
import { Modal } from '../modal';
import { ModalRuntime } from '../modal-runtime';
import { ModalData } from '../modal-types';

@Component({
	selector: 'uf-map-viewer-modal',
	templateUrl: './map-viewer-modal.html',
})
export class MapViewerModalComponent implements Modal<GeoLocation, void> {

	@HostBinding('class.uf-form-card') cardClass = true;

	runtime = inject<ModalRuntime<GeoLocation, void>>(ModalRuntime);
	data = inject<GeoLocation>(ModalData);

	protected readonly sharedTermsTK = SharedTermsTranslationKey;

	close() {
		this.runtime.close();
	}

}
