import { DestroyRef, Inject } from '@angular/core';

import { WindowWrapper } from '../../native';
import { DOMEventHandler, DeviceInfo } from '../../services';

import { ModalPositionAlignment as Alignment, ModalAnchoredPosition, Rect } from './modal-types';

interface AlignmentYInfo {
	y: number;
	alignment: Alignment;
	fits: boolean;
	availableSpace: number;
}

/**
 * Responsible for positioning an overlay to its origin element
 */
export class PositionManager {

	private originRect: Rect;
	private overlayRect: Rect;
	private viewportHeight: number;
	private viewportWidth: number;

	constructor(
		@Inject(WindowWrapper) private window: Window,
		private deviceInfo: DeviceInfo,
		private domEventHandler: DOMEventHandler,
		private destroy: DestroyRef,
		private origin: HTMLElement,
		private overlay: HTMLElement,
		private position?: ModalAnchoredPosition,
		private popoverElement?: HTMLElement,
	) {
		this.domEventHandler.register({
			event: 'resize',
			listener: this.apply.bind(this),
			destroy: this.destroy,
			debounceTime: 500,
		});

		this.domEventHandler.register({
			event: 'scroll',
			listener: (event) => {
				if (!event.target || !this.isDescendant(event.target as HTMLElement)) {
					return;
				}
				this.apply();
			},
			destroy: this.destroy,
			listenerOptions: true,
			debounceTime: 50,
		});
	}

	apply = () => {

		this.originRect = this.getOriginRect();
		this.overlayRect = this.overlay.getBoundingClientRect();

		this.viewportHeight = this.window.document.documentElement.clientHeight;
		this.viewportWidth = this.window.document.documentElement.clientWidth;

		const x = this.getPointX();
		const { y, alignment, fits, availableSpace } = this.getPointYInfo();

		this.setPosition({ x, y, alignment, fitsY: fits, availableSpaceY: availableSpace });
	};

	private setPosition({ x, y, alignment, fitsY, availableSpaceY }: { x: number; y: number; alignment: Alignment; fitsY: boolean; availableSpaceY: number }) {
		/**
		 * Transform required for mobile safari only applied
		 * for safari as the transform may cause a fuzzy render on
		 * low quality monitors
		 */
		if (!fitsY) {
			this.overlay.style.height = availableSpaceY + 'px';
			this.overlay.style.overflowY = 'scroll';
			this.overlay.classList.add('border-all');

			if (alignment === Alignment.Top) {
				y = 0;
			}
		}

		if (this.deviceInfo.isIosMobile()) {
			if (alignment === Alignment.Top && y > 0) {
				this.overlay.style.transform = `translate3d(${x}px, ${-Math.abs(y)}px, 0px)`;
				this.overlay.style.top = 'auto';
				this.overlay.style.bottom = '0px';
			} else {
				this.overlay.style.transform = `translate3d(${x}px, ${y}px, 0px)`;
				this.overlay.style.bottom = 'auto';
				this.overlay.style.top = '0px';
			}

			return;
		}

		this.overlay.style.left = x + 'px';

		if (alignment === Alignment.Top && y > 0) {
			this.overlay.style.top = 'auto';
			this.overlay.style.bottom = y + 'px';
		} else {
			this.overlay.style.top = y + 'px';
			this.overlay.style.bottom = 'initial';
		}

	}

	private getOriginRect(): Rect {

		const rect: Rect = this.origin.getBoundingClientRect();

		if (!this.deviceInfo.isIosMobile()) {
			return rect;
		}
		/**
		 * Extra calculations required for mobile safari keyboard
		 * getBoundingClientRect is not correct
		 */
		const parent = this.overlay.parentElement;

		let yOffset = 0;

		if (parent != null) {
			yOffset = (parent.getBoundingClientRect() as Rect).y;
		}

		return {
			bottom: rect.bottom,
			height: rect.height,
			left: rect.left,
			right: rect.right,
			width: rect.width,
			x: rect.x,
			y: rect.y - yOffset,
			top: rect.y - yOffset,
		};
	}

	private isDescendant(parent: HTMLElement) {

		let node = this.origin.parentNode;

		while (node != null) {
			if (node === parent) {
				return true;
			}
			node = node.parentNode;
		}

		return false;
	}

	/**
	 * Calculate Y point
	 */
	private getPointYInfo(retry = true): AlignmentYInfo {

		const preferences: AlignmentYInfo[] = [];

		for (const alignment of this.yAlignmentOptions) {
			const preference = this.calcYAlignment(alignment);

			if (preference.fits) {
				return preference;
			}
			preferences.push(preference);
		}

		preferences.sort((a, b) => b.availableSpace - a.availableSpace);

		// Guaranteed to have at least one value due to previous logic of this method
		// eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
		const bestPreference = preferences[0] as NonNullable<typeof preferences[0]>;

		// If a pop over element is supplied set height to available space and make scrollable
		if (this.popoverElement && retry) {
			this.popoverElement.style.overflow = 'auto';
			this.popoverElement.style.maxHeight = bestPreference.availableSpace - 20 + 'px';

			this.originRect = this.getOriginRect();
			this.overlayRect = this.overlay.getBoundingClientRect();

			return this.getPointYInfo(false);
		}

		// TODO - its possible that this can be removed, looks like this was legacy code from
		if (this.position?.target) {
			const scrollableParent = this.getScrollableParent(this.position.target);

			if (scrollableParent) {
				const offsetTop = this.getOffsetTop(scrollableParent, this.position.target);

				bestPreference.fits = offsetTop > this.overlayRect.height;
			}
		}

		return bestPreference;
	}

	private getScrollableParent(element: HTMLElement): HTMLElement | undefined {

		let parent = element.parentElement;

		while (parent) {
			const hasScrollBar = parent.scrollHeight > parent.clientHeight;
			const { overflowY, overflow } = window.getComputedStyle(parent);
			const scrollableValues = ['auto', 'scroll'];
			const isScrollable = scrollableValues.includes(overflow) || scrollableValues.includes(overflowY);

			if (hasScrollBar && isScrollable) {
				return parent;
			}
			parent = parent.parentElement;
		}

		return undefined;
	}

	private getOffsetTop(container: HTMLElement, element: HTMLElement) {

		let yPosition = 0;
		let parent: HTMLElement = element;

		while (container !== parent) {
			yPosition += (parent.offsetTop - parent.scrollTop + parent.clientTop);
			parent = parent.offsetParent as HTMLElement;
		}

		return yPosition;
	}

	private calcYAlignment(alignment: Alignment): AlignmentYInfo {

		const y = this.calculatePointY(alignment);
		const originPoint = this.getOriginPointY(alignment);
		const availableSpace = alignment === Alignment.Top ? originPoint : this.viewportHeight - originPoint;

		let fits = false;

		if (y < 0) {
			return { fits, y, alignment, availableSpace };
		}

		if (alignment === Alignment.Top) {
			fits = (this.viewportHeight - y) >= this.overlayRect.height;

			return {
				alignment,
				y: this.viewportHeight - this.originRect.top,
				fits,
				availableSpace,
			};
		}

		fits = (y + this.overlayRect.height) <= this.viewportHeight;

		return { alignment, y, fits, availableSpace };
	}

	private calculatePointY(alignment: Alignment): number {

		const originPointY = this.getOriginPointY(alignment);

		if (alignment === Alignment.Fit) {
			return (this.viewportHeight - this.overlayRect.height) / 2;
		}

		if (alignment === Alignment.Center) {
			return originPointY - (this.overlayRect.height / 2);
		}

		if (alignment === Alignment.Top) {
			return originPointY - this.overlayRect.height;
		}

		return originPointY;
	}

	private getOriginPointY(alignment: Alignment): number {

		if (alignment === Alignment.Center) {
			return this.originRect.top + (this.originRect.height / 2);
		}

		if (alignment === Alignment.Bottom) {
			return this.originRect.top + this.originRect.height;
		}

		return this.originRect.top;
	}

	/**
	 * Calculate X point
	 */
	private getPointX(): number {

		let x = 0;

		for (const alignment of this.xAlignmentOptions) {
			x = this.getOverlayPointX(alignment);

			// fits
			if (x > 0 && x + this.overlayRect.width < this.viewportWidth) {
				return x;
			}
		}
		console.warn('Element to large for anchor');

		return x;
	}

	private getOverlayPointX(alignment: Alignment) {

		const originPointX = this.getOriginPointX(alignment);

		if (alignment === Alignment.Fit) {
			return (this.viewportWidth - this.overlayRect.width) / 2;
		}

		if (alignment === Alignment.Center) {
			return originPointX - (this.overlayRect.width / 2);
		}

		if (alignment === Alignment.Right) {
			return originPointX - this.overlayRect.width;
		}

		return originPointX;
	}

	private getOriginPointX(alignment: Alignment) {

		if (alignment === Alignment.Center) {
			return this.originRect.left + (this.originRect.width / 2);
		}

		if (alignment === Alignment.Right) {
			return this.originRect.left + this.originRect.width;
		}

		return this.originRect.left;
	}

	/** Getters */
	private get _position(): ModalAnchoredPosition {

		if (this.position) {
			// TODO remove when possible, not sure why these defaults are here
			return Object.assign({ originX: Alignment.Right, alignmentX: Alignment.Right }, this.position);
		}

		return {
			target: this.origin, // deprecated remove at some stage
			originX: Alignment.Left,
			originY: Alignment.Bottom,
			alignmentX: Alignment.Left,
			alignmentY: Alignment.Bottom,
		};
	}

	private get yAlignmentOptions(): Alignment[] {

		const all = [Alignment.Top, Alignment.Bottom, Alignment.Center, Alignment.Fit];

		if (!this._position.alignmentY) {
			return all;
		}

		if (Array.isArray(this._position.alignmentY)) {
			// If array provided only use provided values
			return this._position.alignmentY;
		}

		return [this._position.alignmentY, ...all];
	}

	private get xAlignmentOptions(): Alignment[] {

		const all = [Alignment.Left, Alignment.Right, Alignment.Center, Alignment.Fit];

		if (!this._position.alignmentX) {
			return all;
		}

		if (Array.isArray(this._position.alignmentX)) {
			// If array provided only use provided values
			return this._position.alignmentX;
		}

		return [this._position.alignmentX, ...all];
	}

}
