import { Injectable, inject } from '@angular/core';
import { CoerceDataDescriptor, Company, Compound, DataSeed, DataSourceType, FieldType, FormData, UserInfo, coerceDataToTarget, fieldTypeToDataType, getUserFullName, isCompanyInfo, isHierarchyUnitsPath, isManager, isStringTrimmedNotEmpty, mapClaimsToClaimsRecord } from '@unifii/sdk';

import { DataSourceDisplayTo, DataSourceIdTo } from '../../constants';
import { DataSourceMappingDisplayAllowedDataTypes, SourceConfigMapping } from '../../models';
import { UfExpressionFunctionsSet } from '../../utils';
import { DataDisplayService } from '../data-display';
import { DataLookupService } from '../data-lookup';

interface DataSourceAttributes {
	type: DataSourceType;
	mappings: SourceConfigMapping[];
}

type Scope = FormData | UserInfo | Compound | Company | { id: string; display: string };

/**
 * Strict type checking and type coercion is applied to the following types
 * This strict flag is added to Company and UserClaims dataSources
 * // TODO evaluate moving all dataSources to strict type checks without breaking dataSources in use
 */
const StrictFieldTypes = [
	FieldType.Text,
	FieldType.MultiText,
	FieldType.Number,
	FieldType.Bool,
	FieldType.Date,
	FieldType.Time,
	FieldType.MultiChoice,
	FieldType.ZonedDateTime,
	FieldType.Phone,
	FieldType.Email,
	FieldType.GeoLocation,
	FieldType.FileList,
	FieldType.ImageList,
];

/**
 * @description
 * DataSourceConverter is responsible for converting the DataSourceLoader output to
 * a DataSeed based on the SourceConfig provided
 */
@Injectable({ providedIn: 'root' })
export class DataSourceConverter {

	private dataDisplayService = inject(DataDisplayService);
	private dataLookupService = inject(DataLookupService);
	
	private readonly extendedFunctions = {
		...UfExpressionFunctionsSet,
		format: this.dataDisplayService.displayForTemplateExpression.bind(this.dataDisplayService),
	};

	toDataSeed(item: Scope | null | undefined, dataSource: DataSourceAttributes): DataSeed | null {
		if (item == null) {
			return null;
		}

		item = JSON.parse(JSON.stringify(item)) as Scope;

		switch (dataSource.type) {
			case DataSourceType.Bucket:
			case DataSourceType.Collection:
			case DataSourceType.External:
				return this.createDataSeed(item, dataSource.mappings);
			case DataSourceType.UserClaims:
			case DataSourceType.Company:
				return this.createDataSeed(item, dataSource.mappings, StrictFieldTypes);
			case DataSourceType.Users:
				(item as any).claims = mapClaimsToClaimsRecord((item as UserInfo).claims);
				(item as any).fullName = getUserFullName(item as UserInfo);

				return this.createDataSeed(item, dataSource.mappings, StrictFieldTypes);
			default:
				throw new Error(`Unsupported DataSourceType ${dataSource.type}`);
		}
	}

	private createDataSeed(scope: Scope, mappings: SourceConfigMapping[], strictTypes?: FieldType[]): DataSeed {
		const dataSeed: DataSeed = {
			_id: '',
			_display: '',
		};

		for (const { from, to, type } of mappings) {
			const value = this.parseValue(
				scope,
				from,
				type,
				to === DataSourceIdTo ? [FieldType.Text] : strictTypes,
				to === DataSourceDisplayTo ? DataSourceMappingDisplayAllowedDataTypes : undefined,
			);

			if (value != null) {
				dataSeed[to] = value;
			}
		}

		if (!isStringTrimmedNotEmpty(dataSeed._display)) {
			dataSeed._display = `${dataSeed._id}`;
		}

		return dataSeed;
	}

	private parseValue(scope: Scope, from: string, type?: FieldType, parsableTypes?: FieldType[], restrictedTypes?: FieldType[]): unknown {

		let value = null;

		if (type && restrictedTypes && !restrictedTypes.includes(type)) {
			return null;
		}

		value = this.dataLookupService.lookupData(scope as Record<string, unknown>, from, `DataSource: ${from}`, this.extendedFunctions);

		// Some special data type are flagged as FieldType.Text due to FieldType limitations
		if (type === FieldType.Text &&
			(isHierarchyUnitsPath(value) || isManager(value) || isCompanyInfo(value))
		) {
			return value;
		}

		const dataType = type ? fieldTypeToDataType(type) : undefined;

		if (dataType && type && parsableTypes?.includes(type)) {
			value = coerceDataToTarget(value, { type: dataType } as CoerceDataDescriptor);
		}

		return value;
	}

}
