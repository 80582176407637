import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Dictionary } from '@unifii/sdk';
import { endOfMonth, set, startOfMonth } from 'date-fns';

import { SharedTermsTranslationKey } from '../../../translations/shared-terms.tk';

const GridQuantity = 42;

@Component({
	selector: 'uf-day-picker',
	templateUrl: './day-picker.html',
	styleUrls: ['./day-picker.less'],
})
export class DayPickerComponent implements OnInit {

	@Output() dateChange = new EventEmitter<Date>();

	readonly sharedTermsTK = SharedTermsTranslationKey;
	readonly daysLabel = {
		sunday: this.translate.instant(this.sharedTermsTK.DateSundayShortLabel),
		monday: this.translate.instant(this.sharedTermsTK.DateMondayShortLabel),
		tuesday: this.translate.instant(this.sharedTermsTK.DateTuesdayShortLabel),
		wednesday: this.translate.instant(this.sharedTermsTK.DateWednesdayShortLabel),
		thursday: this.translate.instant(this.sharedTermsTK.DateThursdayShortLabel),
		friday: this.translate.instant(this.sharedTermsTK.DateFridayShortLabel),
		saturday: this.translate.instant(this.sharedTermsTK.DateSaturdayShortLabel),
	};

	days: Dictionary<any>[] = [];

	// private _value: string;
	private _date: Date;
	private _day: number | undefined;
	private _month: number;
	private _year: number;

	constructor(private translate: TranslateService) { }

	ngOnInit(): void {
		this.buildGrid();
	}

	@Input() set date(v: Date) {
		if (v) {
			this._year = v.getFullYear();
			this._month = v.getMonth();
			this._day = v.getDate();
		} else {
			this._day = undefined;
		}

		this._date = v;
	}

	get date() {
		return this._date;
	}

	@Input() set day(v: number | undefined) {
		this._day = v;
		this._date = set(this.date, { year: this.year, month: this.month, date: this.day });
	}

	get day(): number | undefined {
		return this._day;
	}

	@Input() set month(v: number) {
		this._month = v;
		if (this.year !== undefined) {
			this.buildGrid();
		}
	}

	get month(): number {
		return this._month;
	}

	@Input() set year(v: number) {
		this._year = v;
		if (this.month !== undefined) {
			this.buildGrid();
		}
	}

	get year(): number {
		return this._year;
	}

	get currentMonth(): number {
		return (this._date || new Date()).getMonth();
	}

	get currentYear(): number {
		return (this._date || new Date()).getFullYear();
	}

	dayClick(day: number | undefined) {
		if (day) {
			this.day = day;
			// this._date = new Date(this.year, this.month, day);
			this._date = set(this.date, { year: this.year, month: this.month, date: this.day });
			this.dateChange.emit(this._date);
		}
	}

	private buildGrid() {

		// if it doesn't receive value, build for current month
		if (this.year === undefined && this.month === undefined) {
			this._date = new Date();
			this.year = this._date.getFullYear();
			this.month = this._date.getMonth();
			this.day = this._date.getDate();
		}

		const firstDayOfTheMonth = startOfMonth(new Date(this.year, this.month, 1));
		const lastDayOfTheMonth = endOfMonth(firstDayOfTheMonth);
		// this is to calculate the empty spots on the grid
		const firstDay = firstDayOfTheMonth.getDay() === 0 ? 6 : firstDayOfTheMonth.getDay() - 1;
		const lastDay = lastDayOfTheMonth.getDate();
		const lastDayIndex = lastDay + firstDay;

		let day = 1;

		this.days = [];

		// the maximum number of rows is 6, remove whenever possible to make the grid smaller
		let quantity = GridQuantity;

		while (lastDayIndex + 7 <= quantity) {
			quantity -= 7;
		}

		// populate the grid
		for (let index = 0; index < quantity; index++) {
			if (index < firstDay || lastDayIndex <= index) {
				this.days.push({});
			} else {
				this.days.push({
					label: day++,
					month: this.month,
					year: this.year,
				});
			}
		}
	}

}
