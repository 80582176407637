<ng-template [ngIf]="data.label" [ngIfElse]="inline">
	<div class="row center-all">{{data.label}}
		<button *ngIf="openMap" [geoLocation]="data.marker" mapViewerLink type="button"
			class="uf-action quaternary xx-small">
			<uf-icon name="address" />
		</button>
	</div>
</ng-template>

<ng-template #inline>
	<uf-map [marker]="data.marker" />
</ng-template>