import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { UfControl } from '../controls';

@Pipe({
	name: 'asUfControls',
})
export class AsUfControlsPipe implements PipeTransform {

	transform(value: AbstractControl[]): UfControl[] {
		return value as UfControl[];
	}

}
