<label *ngIf="label">
	{{label}}
	<ng-content></ng-content>
</label>
<div class="input-wrap">
	<input #input [formControl]="control" [attr.placeholder]="placeholder" [readonly]="disabled"
		[tabindex]="disabled ? -1 : 0" [attr.autocomplete]="autocompleteId" [nameProperty]="nameProperty"
		[options]="options" [hasSearchConfigured]="hasSearchConfigured" [noResultsMsg]="noResultsMsg"
		[minSearchLength]="fixedMinSearchLength" [minSearchLengthMsg]="minSearchLengthMsg"
		(optionSelected)="onSelect($event)" type="text" listBoxResults />
	<uf-error [control]="control" />
	<uf-underline *ngIf="!disabled" [class.active]="focused" />
	<uf-progress />
</div>