import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { ModalService } from '../../../services';
import { CommonTranslationKey } from '../../../translations';

import { BarcodeScannerModalProvider } from './barcode-scanner-modal-provider';

@Component({
	selector: 'uf-barcode-scanner',
	templateUrl: './uf-barcode-scanner.html',
})
export class UfBarcodeScannerComponent {

	@Input() disabled: boolean;
	@Output() valueChange = new EventEmitter<string>();

	readonly commonTK = CommonTranslationKey;

	constructor(
		private modalService: ModalService,
		@Inject(BarcodeScannerModalProvider) private componentProvider: BarcodeScannerModalProvider,
	) { }

	async scan() {

		const value = await this.modalService.openFit(this.componentProvider());

		if (!value) {
			return;
		}

		this.valueChange.emit(value);

	}

}
