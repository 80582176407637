import { UfControlArray, UfControlGroup, UfFormControl } from '../controls';

export function* controlIterator(control: UfFormControl): Iterable<UfFormControl> {

	if (control instanceof UfControlGroup) {
		for (const key of Object.keys(control.controls)) {
			yield *controlIterator(control.controls[key] as UfFormControl);
		}
	}

	if (control instanceof UfControlArray) {
		for (const c of control.controls) {
			yield *controlIterator(c as UfFormControl);
		}
	}

	yield control;
}
