import { Component, Input } from '@angular/core';

import { RuntimeField, SharedTermsTranslationKey } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-sound-list-description',
	templateUrl: './sound-list-description.html',
	styleUrls: ['./field-description.less'],
})
export class SoundListDescriptionComponent implements ContentField {

	@Input() content: any[];
	@Input() field: RuntimeField;

	readonly sharedTermsTK = SharedTermsTranslationKey;

}
