import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SharedTermsTranslationKey } from '../../../translations/shared-terms.tk';

interface SelectOption {
	label: string;
	value: number;
}

@Component({
	selector: 'uf-month-picker',
	templateUrl: './month-picker.html',
	styleUrls: ['./month-picker.less'],
})
export class MonthPickerComponent implements OnInit {

	@Input() month: number;
	@Input() year: number;
	@Output() monthChange = new EventEmitter<number>();
	@Output() yearChange = new EventEmitter<number>();

	protected readonly months: SelectOption[] = [
		{ label: this.translate.instant(SharedTermsTranslationKey.DateJanuaryLabel) as string, value: 1 },
		{ label: this.translate.instant(SharedTermsTranslationKey.DateFebruaryLabel) as string, value: 2 },
		{ label: this.translate.instant(SharedTermsTranslationKey.DateMarchLabel) as string, value: 3 },
		{ label: this.translate.instant(SharedTermsTranslationKey.DateAprilLabel) as string, value: 4 },
		{ label: this.translate.instant(SharedTermsTranslationKey.DateMayLabel) as string, value: 5 },
		{ label: this.translate.instant(SharedTermsTranslationKey.DateJuneLabel) as string, value: 6 },
		{ label: this.translate.instant(SharedTermsTranslationKey.DateJulyLabel) as string, value: 7 },
		{ label: this.translate.instant(SharedTermsTranslationKey.DateAugustLabel) as string, value: 8 },
		{ label: this.translate.instant(SharedTermsTranslationKey.DateSeptemberLabel) as string, value: 9 },
		{ label: this.translate.instant(SharedTermsTranslationKey.DateOctoberLabel) as string, value: 10 },
		{ label: this.translate.instant(SharedTermsTranslationKey.DateNovemberLabel) as string, value: 11 },
		{ label: this.translate.instant(SharedTermsTranslationKey.DateDecemberLabel) as string, value: 12 },
	];

	protected years: SelectOption[];

	private readonly offsetYears = 200;

	constructor(private translate: TranslateService) { }

	ngOnInit() {
		const currentYear = new Date().getFullYear();

		this.years = [];
		for (let index = currentYear - this.offsetYears; index <= currentYear + this.offsetYears; index++) {
			this.years.push({
				label: index.toString(),
				value: index,
			});
		}
	}

	protected addMonth(add: boolean) {
		if (this.canChange(add)) {
			this.month = this.month + (add ? 1 : -1);
		} else {
			this.year = this.year + (add ? 1 : -1);
			this.month = add ? 0 : this.months.length - 1;
			this.yearChange.emit(this.year);
		}

		this.monthChange.emit(this.month);
	}

	protected addYear(add: boolean) {
		this.year = this.year + (add ? 1 : -1);
		this.yearChange.emit(this.year);
	}

	protected changeYear(event: Event) {
		const year = parseInt((event.target as HTMLSelectElement).value);

		this.year = +year;
		this.yearChange.emit(this.year);
	}

	protected changeMonth(event: Event) {
		const month = parseInt((event.target as HTMLSelectElement).value);

		this.month = +month;
		this.monthChange.emit(this.month);
	}

	private canChange(add: boolean) {
		return add ? (this.month + 1) !== this.months.length : this.month > 0;
	}

}
