export * from './data-display-attachment.component';
export * from './data-display-content-modal.component';
export * from './data-display-data-seed.component';
export * from './data-display-href.component';
export * from './data-display-icon.component';
export * from './data-display-image.component';
export * from './data-display-lozenge.component';
export * from './data-display-map-marker.component';
export * from './data-display-markdown.component';
export * from './data-display-options.component';
export * from './data-display-primitive.component';
export * from './data-display-router-link.component';
export * from './data-display-signature.component';
export * from './data-display-components-model';

import { DataDisplayAttachmentComponent } from './data-display-attachment.component';
import { DataDisplayContentModalComponent } from './data-display-content-modal.component';
import { DataDisplayDataSeedComponent } from './data-display-data-seed.component';
import { DataDisplayHrefComponent } from './data-display-href.component';
import { DataDisplayIconComponent } from './data-display-icon.component';
import { DataDisplayImageComponent } from './data-display-image.component';
import { DataDisplayLozengeComponent } from './data-display-lozenge.component';
import { DataDisplayMapMarkerComponent } from './data-display-map-marker.component';
import { DataDisplayMarkdownComponent } from './data-display-markdown.component';
import { DataDisplayOptionsComponent } from './data-display-options.component';
import { DataDisplayPrimitiveComponent } from './data-display-primitive.component';
import { DataDisplayRouterLinkComponent } from './data-display-router-link.component';
import { DataDisplaySignatureComponent } from './data-display-signature.component';

export const UF_DATA_DISPLAY_COMPONENTS = [
	DataDisplayAttachmentComponent,
	DataDisplayContentModalComponent,
	DataDisplayDataSeedComponent,
	DataDisplayHrefComponent,
	DataDisplayIconComponent,
	DataDisplayImageComponent,
	DataDisplayLozengeComponent,
	DataDisplayMapMarkerComponent,
	DataDisplayMarkdownComponent,
	DataDisplayOptionsComponent,
	DataDisplayPrimitiveComponent,
	DataDisplayRouterLinkComponent,
	DataDisplaySignatureComponent,
];
