<ng-template [ngIf]="manager && value">
	<div *ngIf="drawerComponent" class="uf-app-bar flat border-bottom">
		<div class="title">{{ commonTK.FiltersLabel | translate }}</div>
		<button (click)="clearAll()" type="button" class="uf-button secondary x-small">{{ sharedTermsTK.ActionClearAll |
			translate }}</button>
		<button (click)="closeDrawer()" class="uf-action" type="button">
			<uf-icon [title]="sharedTermsTK.ActionClose | translate" class="close" name="close" />
		</button>
	</div>

	<uf-panel *ngIf="drawerComponent; else entries" class="container">
		<ng-container *ngTemplateOutlet="entries" />
	</uf-panel>

</ng-template>

<ng-template #entries>
	<ng-container *ngFor="let entry of manager?.entries">
		<div class="pad-sides pad border-bottom">
			<label class="gap-sm-bottom">{{ entry.label }}</label>
			<!-- TODO here $any() is just used to solve the type error of undefined vs null, find a better solution -->
			<uf-filter [mode]="mode" [entry]="entry" [(value)]="$any(value)[entry.identifier]"
				(valueChange)="onValueChange()" />
		</div>
	</ng-container>
</ng-template>