import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SmartFormsModule } from '@unifii/library/smart-forms';

import { UF_FORM_RENDERER_COMPONENTS, UF_INPUT_COMPONENTS, UF_INPUT_MODALS, UF_WORKFLOW_COMPONENTS } from './components';
import { INPUT_PIPES } from './pipes';
import { FormComponentRegistry, FormDescriptionListRegistry, UfFormComponentRegistry, UfFormDescriptionListRegistry } from './services';

@NgModule({
	imports: [SmartFormsModule, RouterModule],
	declarations: [
		INPUT_PIPES, UF_FORM_RENDERER_COMPONENTS, UF_WORKFLOW_COMPONENTS, UF_INPUT_COMPONENTS, UF_INPUT_MODALS,
	],
	exports: [
		SmartFormsModule,
		INPUT_PIPES, UF_FORM_RENDERER_COMPONENTS, UF_WORKFLOW_COMPONENTS, UF_INPUT_COMPONENTS, UF_INPUT_MODALS,
	],
	providers: [
		INPUT_PIPES,
		{ provide: FormComponentRegistry, useClass: UfFormComponentRegistry },
		{ provide: FormDescriptionListRegistry, useClass: UfFormDescriptionListRegistry },
	],
})
export class InputModule { }
