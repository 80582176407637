import { Component } from '@angular/core';

import { RuntimeField } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

@Component({
	templateUrl: './signature-print.html',
})
export class SignaturePrintComponent implements ContentField {

	field: RuntimeField;
	content: string;

}
