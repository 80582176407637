import { Component, EventEmitter, HostBinding, Input, OnInit, Output, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ExpressionModel } from '@unifii/sdk';
import { debounceTime } from 'rxjs/operators';

import { UfControl } from '../../controls';
import { ExpressionParser } from '../../services';
import { CommonTranslationKey } from '../../translations';
import { ValidatorFunctions } from '../../utils';

import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-expression',
	templateUrl: './uf-expression.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfExpressionComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-textarea.less', './uf-expression.less'],
})
export class UfExpressionComponent extends UfControlValueAccessor<ExpressionModel> implements OnInit {

	@Input() name: string | null;
	@Input() label: string | null;
	@Input() placeholder: string | null;
	@Input() maxLength: number | string | null;
	@Input() autocomplete: string = Math.random().toString(36).substring(2, 18);
	@Input() debounce = 300; // debounce time for user input changes
	@Output() override valueChange = new EventEmitter<ExpressionModel>();

	protected readonly commonTK = CommonTranslationKey;
	protected focused: boolean;
	protected isExpression = false;
	protected textControl: UfControl;

	private translateService = inject(TranslateService);
	private expressionParser = inject(ExpressionParser);

	ngOnInit() {
		this.textControl = new UfControl(
			ValidatorFunctions.custom(this.validateExpression, this.translateService.instant(CommonTranslationKey.ExpressionInvalidErrorMessage) as string),
		);

		this.subscriptions.add(this.textControl.valueChanges
			.pipe(debounceTime(this.debounce))
			.subscribe((value: string) => {
				this.onValueChange({ value, isExpression: this.isExpression });
			}));

		this.syncInternalControls(this.value);
	}

	@Input() override set control(v: UfControl) {
		// Force cast to optional for guarding against potential null bound as any
		if ((v as UfControl | undefined) == null) {
			return;
		}
		super.control = v;

		if (super.value != null) {
			this.syncInternalControls(super.value);
		}
	}

	override get control(): UfControl {
		return super.control;
	}

	@Input() override set value(v: ExpressionModel | undefined | null) {
		if (this.patternUtil.isEqual(this.value, v) || (v == null && this.value == null)) {
			return;
		}

		super.value = v;
		this.syncInternalControls(v);
	}

	override get value(): ExpressionModel | undefined | null {
		return super.value;
	}

	@HostBinding('class.focused') get focusedClass() {
		return this.focused && !this.disabled;
	}

	@HostBinding('class.error') get errorClass() {
		return this.control.showError && !this.disabled;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	@HostBinding('class.value') get valueClass() {
		return !!this.value;
	}

	override valueEmitPredicate(modelValue?: ExpressionModel | null, prev?: ExpressionModel | null): boolean {
		this.syncInternalControls(modelValue);

		return super.valueEmitPredicate(modelValue, prev);
	}

	protected toggleExpression() {
		this.isExpression = !this.isExpression;
		this.textControl.updateValueAndValidity();

		this.onValueChange({
			value: this.value?.value ?? '',
			isExpression: this.isExpression,
		});
		this.control.markAsTouched();
	}

	private onValueChange(value: ExpressionModel) {
		if (!value.value) {
			value = undefined as any as ExpressionModel;
		}
		this.control.setValue(value);
	}

	private syncInternalControls(modelValue?: ExpressionModel | null) {
		const { value, isExpression } = modelValue ?? { value: '' };

		if (value !== this.textControl.value) {
			this.textControl.setValue(value, { emitEvent: false });
		}

		if (isExpression != null && isExpression !== this.isExpression) {
			this.isExpression = isExpression;
		}
	}

	private validateExpression = (value: string): boolean => {
		if (!this.value?.isExpression) {
			return true;
		}

		if (value === '') {
			return false;
		}

		return this.expressionParser.validate(value);
	};

}
