import { Dictionary, isDictionary } from '@unifii/sdk';
import { fromByteArray, toByteArray } from 'base64-js';

/**
 * Helper functions for safely encoding URL data
 */
export const encodeObj = (obj: Dictionary<any>): string =>
	encodeStr(JSON.stringify(obj));

export const decodeObj = (encoded: string): Dictionary<any> | undefined => {

	const decoded = decodeStr(encoded);

	if (decoded) {
		try {
			const parsed = JSON.parse(decoded);

			if (isDictionary(parsed)) {
				return parsed;
			} else {
				throw new Error('Decoded value is not an obj');
			}
		} catch (e) {
			console.error(`Failed to decode ${encoded}`, e);
		}
	}

	return undefined;
};

export const encodeStr = (str: string): string => {

	const encoder = new TextEncoder();
	const bytes = encoder.encode(str);
	let encoded = fromByteArray(bytes);

	// strip ends = characters
	encoded = encoded.replace(/=/g, '');
	// replace '/' with '_'
	encoded = encoded.replace(/\//g, '_');
	// replace '+' with '-'
	encoded = encoded.replace(/\+/g, '-');

	return encoded;
};

export const decodeStr = (encoded: string): string | undefined => {

	try {
		// restore replaced '+' with '-'
		encoded = encoded.replace(/-/g, '+');
		// restore replaced '/' with '_'
		encoded = encoded.replace(/_/g, '/');
		// restore stripped padding characters '=' till multiplier of 4
		encoded += new Array(((4 - encoded.length % 4) % 4) + 1).join('=');

		// decode
		const bytes = toByteArray(encoded);

		return new TextDecoder('utf-8').decode(bytes);
	} catch (e) {
		console.error(`Failed to decode ${encoded}`);

		return;
	}
};
