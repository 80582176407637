import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Option, ProjectInfo, TenantClient } from '@unifii/sdk';

import { Modal, ModalRuntime, WindowWrapper } from '@unifii/library/common';

import { ShowcaseRepositoryService } from '../services';

@Component({
	templateUrl: './show-app-details.html',
})
export class ShowAppDetailsComponent implements Modal<void, void> {

	protected readonly baseSizeOptions: Option[] = [
		{ identifier: '16px', name: '16px (Default)' },
		{ identifier: '17px', name: '17px' },
		{ identifier: '18px', name: '18px' },
		{ identifier: '19px', name: '19px' },
		{ identifier: '20px', name: '20px' },
		{ identifier: '21px', name: '21px' },
		{ identifier: '22px', name: '22px' },
	];	
	protected projects: ProjectInfo[] = [];
	protected projectId: string | null;	
	protected baseSize = '16px';
	protected htmlBaseElement: HTMLElement;

	constructor(
		public runtime: ModalRuntime<void, void>,
		private tenantClient: TenantClient,
		public repository: ShowcaseRepositoryService,
		public translate: TranslateService,
		@Inject(WindowWrapper) private window: Window,
	) {

		this.projectId = this.repository.projectId;
		this.htmlBaseElement = this.window.document.querySelector('html') as HTMLElement;
		void this.loadProjects();
	}

	selectProject(projectId: string) {
		this.projectId = projectId;
		this.repository.project = this.projects.find((p) => p.id === projectId) ?? null;
		this.reload();
	}

	reload() {
		this.window.location.reload();
	}

	projectLabel(project: ProjectInfo) {
		return `${project.id} - ${project.name}`;
	}

	selectLang(lang: string) {
		this.repository.language = lang;
		this.reload();
	}

	updateBaseSize(value: string) {
		this.htmlBaseElement.style.fontSize = value;
	}

	private async loadProjects() {

		try {
			const projects = await this.tenantClient.getProjects();

			this.projects = projects.sort((a, b) => +(a.id) - (+b.id));
		} catch (e) {
			this.repository.reset();
		}
	}

}
