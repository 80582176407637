<div class="uf-card">
	<div class="uf-app-bar">
		<button [title]="sharedTermsTK.ActionClose | translate" (click)="close()" class="uf-action tertiary"
			type="button">
			<uf-icon name="close" />
		</button>
		<h3>{{commonTK.ScanBarcodeLabel | translate }}</h3>
	</div>
	<div class="uf-grid pad">
		<uf-text [(value)]="barcode" ufAutofocus label="Barcode" class="col-12" />
	</div>
	<div class="uf-form-actions">
		<button (click)="close()" class="uf-button tertiary small left" type="button">
			{{sharedTermsTK.ActionCancel | translate}}
		</button>
		<button (click)="submit()" class="uf-button primary small" type="button">
			{{sharedTermsTK.ActionConfirm | translate}}
		</button>
	</div>
</div>