<sc-page-content width="lg" identifier="modals">

	<ng-container editor>
		<h4>Modal Sizes</h4>
		<sc-code-editor-expander class="gap-bottom">
			<div class="row space-children justify-center">
				<button (click)="openFit()" type="button" class="uf-button">
					Open Responsive
				</button>
				<button (click)="openMedium()" type="button" class="uf-button">
					Open Medium
				</button>
				<button (click)="openLarge()" type="button" class="uf-button">
					Open Large
				</button>
				<button (click)="openFullScreen()" type="button" class="uf-button">
					Open Full Screen
				</button>
			</div>
		</sc-code-editor-expander>

		<h4>Guard Behaviors</h4>
		<sc-code-editor-expander class="gap-bottom">
			<uf-select [options]="guardOptions" [(value)]="choice" valueProperty="identifier" nameProperty="name">
				<uf-help
					content="Use the component internal guard or force the enabled/disabled guard from the openModal call" />
			</uf-select>
			<button (click)="openMedium(choice)" type="button" class="uf-button">
				Open with guard option
			</button>
		</sc-code-editor-expander>

		<h4>Alert Modal</h4>
		<sc-code-editor-expander [code]="alert" class="gap-bottom">
			<div class="row space-children justify-center">
				<button (click)="openAlert()" type="button" class="uf-button">
					Open Alert (default)
				</button>
				<button (click)="openAlert(true)" type="button" class="uf-button">
					Open Alert (custom)
				</button>
			</div>
		</sc-code-editor-expander>

		<h4>Confirm Modal</h4>
		<sc-code-editor-expander [code]="confirm" class="gap-bottom">
			<div class="row space-children justify-center">
				<button (click)="openConfirm()" type="button" class="uf-button">
					Open Confirm (default)
				</button>
				<button (click)="openConfirm(true)" type="button" class="uf-button">
					Open Confirm (custom)
				</button>
			</div>
		</sc-code-editor-expander>

		<h4>Choice List Modal</h4>
		<sc-code-editor-expander [code]="choiceList" class="gap-bottom">
			<div class="row space-children justify-center">
				<button (click)="openChoiceList()" type="button" class="uf-button">
					Open Choice List
				</button>
				<button (click)="openChoiceList(true)" type="button" class="uf-button">
					Open Choice List (no options)
				</button>
			</div>
		</sc-code-editor-expander>

		<h4>Choice Radio Modal</h4>
		<sc-code-editor-expander [code]="choiceRadio" class="gap-bottom">
			<div class="row space-children justify-center">
				<button (click)="openChoiceRadio()" type="button" class="uf-button">
					Open Choice Radio
				</button>

				<button (click)="openPrintFormOptions()" type="button" class="uf-button">
					Open Choice Radio (Form Print)
				</button>
			</div>
		</sc-code-editor-expander>

	</ng-container>

</sc-page-content>