import { Injectable, NgZone, OnDestroy, ViewContainerRef, inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AstNode, DataSeed, FieldType, RequestAnalytics, isNumber, isString } from '@unifii/sdk';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { Context, ExpressionParser, PatternUtil, RuntimeField, Scope, SourceConfig, UfFormControl, ValidatorFunctions } from '@unifii/library/common';

import { FieldComponent } from '../components';
import { FormField, FormSettings } from '../models';

import { FieldAutofillManager } from './field-autofill-manager';
import { FieldBindToManager } from './field-bind-to-manager';
import { FieldDataSourceManager } from './field-data-source-manager';
import { ShowIfManager } from './field-show-if-manager';
import { FieldVariationManager } from './field-variation-manager';
import { FormService } from './form.service';
import { ScopeManager } from './scope-manager';
import { WorkflowService } from './workflow.service';

interface FieldFeatures {
	autofill: boolean;
	showIf: boolean;
	dataSource: boolean;
	variations: boolean;
	bindTo: boolean;
	options: boolean;
}

/**
 * Features: autofill, showIf, dataSources, variation
 */
@Injectable()
export class FormFieldService implements OnDestroy {

	private features: FieldFeatures;
	private fieldComponent: FieldComponent;
	private component: FormField; /** References to field.component attributes */
	private field: RuntimeField;
	private container: ViewContainerRef;
	private dataSourceManager?: FieldDataSourceManager;
	private showIfManager?: ShowIfManager;
	private variationsManager: FieldVariationManager;
	private _bindToManager?: FieldBindToManager;
	private _autofillManager?: FieldAutofillManager;
	private controlDependencies: UfFormControl[] = [];
	private subscriptions = new Subscription();
	private onStableSubscription: Subscription | undefined;
	private patternUtil = new PatternUtil<any>();

	private expressionParser = inject(ExpressionParser);
	private scopeManager = inject(ScopeManager);
	private formSettings = inject(FormSettings);
	private translate = inject(TranslateService);
	private workflow = inject(WorkflowService);
	private ngZone = inject(NgZone);
	private formService = inject(FormService);
	private formFieldService = inject(FormFieldService, { optional: true, skipSelf: true });

	get sourceConfig(): SourceConfig | undefined {
		if (!this.features.dataSource || !this.dataSourceManager) {
			return;
		}

		return this.dataSourceManager.sourceConfig;
	}

	get requestAnalytics(): RequestAnalytics | undefined {
		if (!this.features.dataSource || !this.dataSourceManager) {
			return;
		}

		return this.dataSourceManager.requestAnalytics;
	}

	private get hasFeatures(): boolean {
		return Object.values(this.features).filter(Boolean).length >= 1;
	}

	private get autofillManager(): FieldAutofillManager {
		if (!this._autofillManager) {
			this._autofillManager = new FieldAutofillManager(this.component, this.field, this.scopeManager, this.expressionParser);
		}

		return this._autofillManager;
	}

	private get bindToManager(): FieldBindToManager | undefined {
		if (this._bindToManager) {
			return this._bindToManager;
		}

		if (!this.component.control) {
			console.warn(`FieldBindToManager.getContent - component.control null for field ${this.field.identifier}`);

			return;
		}

		this._bindToManager = new FieldBindToManager(this.component.control, this.field, this.scopeManager, this.expressionParser);

		return this._bindToManager;
	}

	ngOnDestroy() {
		for (const control of this.controlDependencies) {
			control.removeOnValueUpdate(this);
		}
		this.subscriptions.unsubscribe();
	}

	onDestroy() {
		// this.component can be null during destruction
		const nullableComponent = this.component as FormField | null;

		if (nullableComponent?.control && this.hasFeatures) {
			nullableComponent.control.removeOnValueUpdate(this);
		}
	}

	init(component: FormField, fieldComponent: FieldComponent, container: ViewContainerRef) {
		this.container = container;
		this.features = this.getFeatures(fieldComponent.field);

		if (!this.hasFeatures) {
			return;
		}

		this.component = component;
		this.fieldComponent = fieldComponent;
		this.field = fieldComponent.field;

		/**
		 * The next block relies on control in init and
		 * content, field, scope, contentChange being set by the fieldComponent
		 */
		if (this.features.dataSource && !this.formSettings.print) {
			this.dataSourceManager = new FieldDataSourceManager(this.component, this.field, this.container, this.formSettings, this.scopeManager, this.formService, this.translate);

			if (this.features.options) {
				void this.setDataSourceOptions();
			}
		}

		if (this.features.showIf) {
			this.runShowIf();
		}

		if (this.features.autofill && !this.features.showIf) {
			this.runAutofill();
		}

		if (this.features.bindTo) {
			this.runBindTo();
		}

		if (this.features.autofill || this.features.bindTo) {
			this.addShowIfListeners();
		}

		if (this.features.variations) {
			this.variationsManager = new FieldVariationManager(this.component, this.field, this.scopeManager, this.expressionParser);
			this.variationsManager.update();
		}

		for (const dependency of (this.component.control?.dependencies ?? [])) {
			dependency.registerOnValueUpdate(this, (updatedControls) => { this.dependencyValueChange(updatedControls); });
			this.controlDependencies.push(dependency);
		}
	}

	search(q?: string, context?: Context, scope?: Scope, contextFilters?: AstNode): Promise<DataSeed[]> {
		if (this.dataSourceManager?.search == null) {
			console.warn(`Data Source not setup for field: ${this.field.identifier}`);

			return Promise.resolve([]);
		}

		return this.dataSourceManager.search(q, context, scope, contextFilters);
	}

	findAllBy(match: string, context?: Context, scope?: Scope): Promise<DataSeed[]> {
		if (this.dataSourceManager?.findAllBy == null) {
			console.warn(`Data Source not setup for field: ${this.field.identifier}`);

			return Promise.resolve([]);
		}

		return this.dataSourceManager.findAllBy(match, context, scope);
	}

	/**
	 * Public access method, keep it
	 */
	updateDataSourceOptions() {
		return this.setDataSourceOptions();
	}

	private dependencyValueChange(updatedControls: Set<AbstractControl>) {
		// Check controls has already been updated as part of this cycle
		if (this.component.control && updatedControls.has(this.component.control)) {
			return;
		}

		// Update options list as context and scope may effect list items
		if (this.features.dataSource && this.features.options) {
			void this.setDataSourceOptions();
		}

		if (this.features.variations) {
			this.variationsManager.update();
		}

		if (this.features.showIf) {
			this.runShowIf();
		}

		if (this.features.bindTo) {
			this.runBindTo();
		}
	}

	private runAutofill() {
		if (this.formSettings.print || !this.workflow.isActive(this.field) || this.hidden || !ValidatorFunctions.isEmpty(this.component.content)) {
			return;
		}

		/**
		 * To autofill dataSources raw value is used to request a valid value
		 */
		if (this.field.sourceConfig) {
			void this.autofillViaDataSource();

			return;
		}

		const value = this.autofillManager.getValue();

		if (value != null) {
			this.setFieldContent(value);
		}
	}

	private runBindTo() {
		if (this.formSettings.print || !this.workflow.isActive(this.field) || this.hidden) {
			return;
		}

		if (!this.bindToManager) {
			return;
		}

		const value = this.bindToManager.getValue();

		this.setFieldContent(value);
	}

	private async autofillViaDataSource() {
		const progressId = this.formService.registerInProgress();

		try {
			/**
			 * Get raw value from autofill manager and get first value if it is an array with a single value
			 * this is required for when the autofill source is a textArray
			 */
			let id = this.autofillManager.getValue();

			if (Array.isArray(id) && id.length === 1) {
				id = id[0];
			}

			if (this.dataSourceManager && (isString(id) || isNumber(id)) && id) {
				const dataSeed = await this.dataSourceManager.getValue(`${id}`);

				if (dataSeed) {
					this.setFieldContent(dataSeed);
				}
			}
		} catch (error) {
			console.warn('FormFieldService.autofillViaDataSource error', error);
		} finally {
			this.formService.deregisterInProgress(progressId);
		}
	}

	private setFieldContent(v: any) {
		if (this.patternUtil.isEqual(v, this.component.content)) {
			return;
		}

		if (this.onStableSubscription?.closed === false) {
			this.onStableSubscription.unsubscribe();
		}

		// Prevent expression changed error and set content after the first
		const onStable = this.ngZone.onStable.pipe(first());

		this.onStableSubscription = onStable.subscribe(() => {
			this.ngZone.run(() => {
				this.fieldComponent.setValue(v);
				/**
				 * Run show second time to ensure that expression value is up to date
				 * when an expression references itself the value has not been applied to the scope so needs
				 * to be calculated onces the fields value has been emitted and applied to the scope
				 */
				if (this.features.showIf) {
					this.runShowIf();
				}
			});
		});
	}

	private runShowIf() {
		if (!this.showIfManager) {
			this.showIfManager = new ShowIfManager(this.component, this.field, this.scopeManager, this.expressionParser);
		}
		this.fieldComponent.hidden = !this.showIfManager.exec();

		if (this.features.autofill) {
			this.runAutofill();
		}

		if (this.features.bindTo) {
			this.runBindTo();
		}
	}

	private async setDataSourceOptions(source?: AbstractControl) {
		if (this.formSettings.print) {
			return;
		}

		if (this.dataSourceManager?.getOptions == null) {
			console.warn(`DataSource not setup for field: ${this.field.identifier}`);

			return;
		}

		const options = await this.dataSourceManager.getOptions();

		if (this.fieldComponent.field.type === FieldType.Choice) {
			(this.component as any).options = options;
		}

		// autofill first value
		if (options.length === 1) {
			this.fieldComponent.setValue(options[0]);

			return;
		}

		// Clear the value when the content was an option's value that's not available anymore
		if (this.component.content) {
			const found = options.find((dataSeed: DataSeed) => this.component.content._id === dataSeed._id);

			if (!found && source && source.dirty) {
				this.fieldComponent.setValue(null);
			}
		}

		// Rerun autofill
		if (this.features.autofill) {
			this.runAutofill();
		}
	}

	private getFeatures(field: RuntimeField): FieldFeatures {
		/**
		 * Important that null checks also include empty strings there is current production data with '' as values
		 * // TODO test !!field.autofill could do the same job
		 */
		return {
			autofill: field.autofill != null && field.autofill !== '',
			showIf: field.showIf != null && field.showIf !== '',
			dataSource: field.sourceConfig != null,
			variations: !!field.variations.length,
			bindTo: !!field.bindTo,
			options: [FieldType.Choice, FieldType.MultiChoice, FieldType.Bool, FieldType.Survey].includes(field.type),
		};
	}

	private addShowIfListeners() {
		for (const parent of this.parentIterator(this)) {
			if (parent?.features.showIf && parent.component.control) {
				const control = parent.component.control;

				control.registerOnValueUpdate(this, (updatedControls: Set<AbstractControl>) => {

					if (this.component.control && updatedControls.has(this.component.control)) {
						return;
					}

					if (this.features.autofill) {
						this.runAutofill();
					}

					if (this.features.bindTo) {
						this.runBindTo();
					}
				});

				this.controlDependencies.push(control);
			}
		}
	}

	private *parentIterator(service?: FormFieldService): Iterable<FormFieldService | undefined> {
		if (service?.formFieldService) {
			yield service.formFieldService;
			yield *this.parentIterator(service.formFieldService);
		}
	}

	private get hidden(): boolean {
		if (this.fieldComponent.hidden) {
			return true;
		}
		for (const parent of this.parentIterator(this)) {
			if ((parent?.fieldComponent)?.hidden) {
				return true;
			}
		}

		return false;
	}

}
