import { NgModule } from '@angular/core';

import { SmartFormsModule } from '@unifii/library/smart-forms';

import { UF_DISPLAY_FIELD_COMPONENTS, UF_DISPLAY_RENDERER } from './components';

@NgModule({
	imports: [SmartFormsModule],
	declarations: [
		UF_DISPLAY_FIELD_COMPONENTS, UF_DISPLAY_RENDERER,
	],
	exports: [
		SmartFormsModule,
		UF_DISPLAY_FIELD_COMPONENTS, UF_DISPLAY_RENDERER,
	],
})
export class DisplayModule { }
