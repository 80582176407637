import { ShowActionsComponent } from './show-actions.component';
import { ShowBarcodeScannerComponent } from './show-barcode-scanner.component';
import { ShowButtonGroupsComponent } from './show-button-groups.component';
import { ShowButtonsComponent } from './show-buttons.component';
import { ShowCascadeSelectionComponent } from './show-cascade-selection.component';
import { ShowCheckboxesComponent } from './show-checkboxes.component';
import { ShowDateComponent } from './show-date.component';
import { ShowDatetimeTimezoneComponent } from './show-datetime-tz.component';
import { ShowDatetimeComponent } from './show-datetime.component';
import { ShowHierarchyCascadeSelectionComponent } from './show-hierarchy-cascade-selection.component';
import { ShowMarkdownEditorComponent } from './show-markdown-editor.component';
import { ShowNumberFieldsComponent } from './show-number-fields.component';
import { ShowRadioButtonsComponent } from './show-radio-buttons.component';
import { ShowSecretFieldComponent } from './show-secret-field.component';
import { ShowSelectsComponent } from './show-selects.component';
import { ShowSlidersComponent } from './show-sliders.component';
import { ShowSwitchesComponent } from './show-switches.component';
import { ShowTextFieldsComponent } from './show-text-fields.component';
import { ShowTimeComponent } from './show-time.component';

export * from './show-actions.component';
export * from './show-barcode-scanner.component';
export * from './show-button-groups.component';
export * from './show-buttons.component';
export * from './show-cascade-selection.component';
export * from './show-cascade-selection-data-source';
export * from './show-checkboxes.component';
export * from './show-date.component';
export * from './show-datetime.component';
export * from './show-hierarchy-cascade-selection.component';
export * from './show-number-fields.component';
export * from './show-radio-buttons.component';
export * from './show-selects.component';
export * from './show-sliders.component';
export * from './show-switches.component';
export * from './show-text-fields.component';
export * from './show-time.component';
export * from './show-secret-field.component';

export const SC_PAGE_INPUTS_COMPONENTS = [
	ShowActionsComponent,
	ShowBarcodeScannerComponent,
	ShowButtonGroupsComponent,
	ShowButtonsComponent,
	ShowCascadeSelectionComponent,
	ShowCheckboxesComponent,
	ShowDateComponent,
	ShowDatetimeComponent,
	ShowHierarchyCascadeSelectionComponent,
	ShowDatetimeTimezoneComponent,
	ShowNumberFieldsComponent,
	ShowRadioButtonsComponent,
	ShowSelectsComponent,
	ShowSlidersComponent,
	ShowSwitchesComponent,
	ShowTextFieldsComponent,
	ShowMarkdownEditorComponent,
	ShowTimeComponent,
	ShowSecretFieldComponent,
];
