import { Component, Inject, OnInit } from '@angular/core';
import { Option, PublishedContent, ensureError } from '@unifii/sdk';

import { BucketDataDescriptorAdapterLoader, ClipboardService, ColumnGap, DataDescriptor, DataDescriptorService, DataPropertyDescriptor, ModalService, Size } from '@unifii/library/common';

import { ShowcaseDataDescriptorPermissionController } from './show-data-descriptor-permissions';
import { ShowDataItemDescriptorOptionsComponent } from './show-data-item-descriptor-options.component';

type ACLTypes = 'roles' | 'userClaims' | 'companies' | 'forms' | 'schema' | 'collection' | 'hierarchy' | 'users';
type SourceDataType = 'User' | 'Company' | 'Form Data' | 'Collection';

@Component({
	selector: 'sc-show-data-descriptor',
	templateUrl: './show-data-descriptor.html',
	styleUrls: ['./show-data-descriptor.less'],
})
export class ShowDataDescriptorComponent implements OnInit {

	protected readonly sizes = Size;
	protected readonly columnGaps = ColumnGap;
	protected readonly types = ['User', 'Company', 'Form Data', 'Collection'];

	protected readonly sourceTypeToACL = new Map<ACLTypes, SourceDataType[]>([
		['roles', ['Company', 'User', 'Form Data']],
		['userClaims', ['Company', 'User', 'Form Data']],
		['companies', ['User']],
		['hierarchy', ['User']],
		['users', ['User']],
		['forms', ['Company', 'Form Data']],
		['schema', ['Company', 'Form Data']],
		['collection', ['Company', 'Collection', 'Form Data']],
	]);

	protected type: SourceDataType;
	protected buckets: string[];
	protected bucketOptions: string[];
	protected bucketSelected: string | null;
	protected collections: Option[];
	protected collectionOptions: Option[];
	protected collectionSelected: Option | null;

	protected showDisplay: boolean;
	protected showSort: boolean;
	protected showStaticFilter: boolean;
	protected showInputFilter: boolean;
	protected visibleDataProperties: DataPropertyDescriptor[] = [];
	protected executionTime: number;
	protected identifiers: string;
	protected error: string | undefined;
	protected openDrawer: boolean;

	private _dataDescriptor: DataDescriptor | undefined;

	constructor(
		@Inject(PublishedContent) private content: PublishedContent,
		private modalService: ModalService,
		private dataDescriptorManager: DataDescriptorService,
		private bucketAdapterLoader: BucketDataDescriptorAdapterLoader,
		private clipboard: ClipboardService,
		protected permissions: ShowcaseDataDescriptorPermissionController,
	) { }

	get dataDescriptor(): DataDescriptor | undefined {
		return this._dataDescriptor;
	}

	set dataDescriptor(v: DataDescriptor | undefined) {
		this._dataDescriptor = v;
		this.refreshVisibleEntries();
	}

	get showRun() {

		switch (this.type) {
			case 'User': return true;
			case 'Company': return true;
			case 'Form Data': return this.bucketSelected != null;
			case 'Collection': return this.collectionSelected != null;
			default: return false;
		}
	}

	async ngOnInit() {

		const forms = await this.bucketAdapterLoader.loadForms();

		this.buckets = [...new Set(forms.filter((d): d is { identifier: string; label: string; bucket: string} => !!d.bucket).map((d) => d.bucket))];
		this.collections = [...new Set((await this.content.getCollections()).map((d) => ({
			identifier: d.identifier,
			name: d.label,
		})))];
	}

	protected refreshVisibleEntries() {
		if (!this.dataDescriptor) {
			this.visibleDataProperties = [];

			return;
		}

		this.visibleDataProperties = this.dataDescriptor.propertyDescriptors.filter((i) => this.show(i));
	}

	protected typeChange() {
		this.dataDescriptor = undefined;
		this.error = undefined;
		this.permissions.reset();
	}

	protected searchBucket(q?: string) {
		const found = q ? this.buckets.filter((s) => s.includes(q)) : [...this.buckets];

		this.bucketOptions = ['NonExistingBucket', ...found];
	}

	protected searchCollection(q?: string) {
		const found = !q ? [...this.collections] : this.collections.filter((o) => o.identifier.includes(q) || o.name.includes(q));

		this.collectionOptions = [{ identifier: 'nonexistingCollection2109309932', name: 'NonExistingCollection' }, ...found];
	}

	protected openOptions(entry: DataPropertyDescriptor) {
		void this.modalService.openMedium(
			ShowDataItemDescriptorOptionsComponent, {
				display: entry.display,
				sourceConfig: entry.sourceConfig,
				options: entry.options,
			}, undefined, [{
				provide: PublishedContent,
				useValue: this.content,
			}]);
	}

	protected openOperators(entry: DataPropertyDescriptor) {
		void this.modalService.openMedium(
			ShowDataItemDescriptorOptionsComponent,
			{
				display: entry.display,
				operators: entry.operators,
			}, undefined, [{
				provide: PublishedContent,
				useValue: this.content,
			}]);
	}

	protected async run() {

		this.dataDescriptor = undefined;
		this.error = undefined;

		let identifiers: string[] | undefined = this.identifiers ? this.identifiers.trim().split(' ') : [];

		identifiers = identifiers.length ? identifiers : undefined;

		const start = performance.now();

		try {
			switch (this.type) {
				case 'User':
					this.dataDescriptor = await this.dataDescriptorManager.getUserDataDescriptor(identifiers);
					break;
				case 'Company':
					this.dataDescriptor = await this.dataDescriptorManager.getCompanyDataDescriptor(identifiers);
					break;
				case 'Collection':
					if (!this.collectionSelected) {
						return;
					}
					this.dataDescriptor = await this.dataDescriptorManager.getCollectionDataDescriptor((this.collectionSelected).identifier, identifiers);
					this.error = this.dataDescriptor == null ? 'Data Descriptor load error' : undefined;
					break;
				case 'Form Data':
					if (!this.bucketSelected) {
						return;
					}
					this.dataDescriptor = await this.dataDescriptorManager.getBucketDataDescriptor(this.bucketSelected, identifiers);
					this.error = this.dataDescriptor == null ? 'Data Descriptor load error' : undefined;
			}
		} catch (e) {
			console.error(e);
			this.error = ensureError(e).message;
		} finally {
			this.executionTime = Math.round(performance.now() - start);
		}
	}

	protected copyIdentifiers() {
		if (!this.dataDescriptor) {
			return;
		}

		const identifiers = [...this.dataDescriptor.propertyDescriptorsMap.keys()].join(' ');

		void this.clipboard.setText(identifiers);
	}

	protected showACL(type: ACLTypes) {
		return !!this.sourceTypeToACL.get(type)?.includes(this.type);
	}

	private show(entry: DataPropertyDescriptor): boolean {

		return !(
			(this.showDisplay && !entry.asDisplay) ||
			(this.showStaticFilter && !entry.asStaticFilter) ||
			(this.showInputFilter && !entry.asInputFilter) ||
			(this.showSort && !entry.asSort)
		);
	}

}
