<sc-configuration [configuration]="config" (valueChange)="cv = $event" title="Settings" />

<div class="row">
	<button (click)="value = null; complexValue = null;" class="uf-button primary" type="button">Clear</button>
	<button (click)="value = ['Pineapple']; complexValue = [{
		id: 7,
		key: 'PP',
		name: 'Pineapple',
		rating: 11
	}]" class="uf-button primary" type="button">Set (Pineapple)</button>
	<button (click)="value = ['Pineapple', 'Apple']; complexValue = [
	{
		id: 7,
		key: 'PP',
		name: 'Pineapple',
		rating: 11
	},
	{
		id: 0,
		key: 'AP',
		name: 'Apple',
		rating: 5
	}
	]" class="uf-button primary" type="button">
		Set (Pineapple, Apple)
	</button>
</div>
<div class="spacer"></div>

<div class="grid">
	<div class="col-1of1">
		<div class="fieldset-item--small">
			<span>Model value:</span>
			<span>{{value}}</span>
		</div>
		<div class="fieldset-item--small">
			<span>Emitted value:</span>
			<span>{{emittedValue | json}}</span>
		</div>
		<div class="fieldset-item--small">
			<span>Complex Model value:</span>
			<span>{{complexValue | json }}</span>
		</div>
		<div class="fieldset-item--small">
			<span>Complext Emitted value:</span>
			<span>{{complextEmittedValue | json}}</span>
		</div>
	</div>

	<uf-chips [disabled]="cv.disabled" [control]="control" [options]="results" [allowCustom]="cv.customs"
		[allowDuplicates]="cv.duplicates" [minSearchLength]="3" [canDelete]="canDeleteApples" [(value)]="value"
		(valueChange)="valueChanged($event)" (searchChange)="find($event)" class="col-1of1" label="Simple Fruits"
		placeholder="Search for...">
		<uf-help content="Apple like fruits are deletable" />
	</uf-chips>


	<uf-chips [disabled]="cv.disabled" [control]="complexControl" [options]="complexResults" [allowCustom]="cv.customs"
		[allowDuplicates]="cv.duplicates" [(value)]="complexValue" (valueChange)="complexValueChanged($event)"
		(searchChange)="findComplex($event)" class="col-1of1" label="Complex Fruits" placeholder="Search for..."
		nameProperty="name" />


</div>