<div class="grid--fixed">
	<div class="col-1of1">
		<h3>Basic Inputs</h3>
		<sc-configuration [configuration]="inputsConfig" (valueChange)="icv = $event" />
		<uf-checkbox (valueChange)="toggleControl($event)" label="Disable controls" />
		<div class="inputs grid">

			<uf-text [ngClass]="icv.cssClass" [label]="icv.label" [name]="icv.name" [placeholder]="icv.placeholder"
				[disabled]="icv.disabled" [control]="icv.required ? control : emptyControl" class="col-1of1">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-text>

			<uf-autocomplete [ngClass]="icv.cssClass" [label]="icv.label" [name]="icv.name"
				[placeholder]="icv.placeholder" [disabled]="icv.disabled"
				[control]="icv.required ? control : emptyControl" class="col-1of1">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-autocomplete>

			<uf-url [ngClass]="icv.cssClass" [label]="icv.label" [name]="icv.name" [placeholder]="icv.placeholder"
				[disabled]="icv.disabled" [control]="icv.required ? control : emptyControl" class="col-1of1">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-url>

			<uf-number [ngClass]="icv.cssClass" [label]="icv.label" [name]="icv.name" [placeholder]="icv.placeholder"
				[disabled]="icv.disabled" [control]="icv.required ? control : emptyControl" class="col-1of1">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-number>

			<uf-password [ngClass]="icv.cssClass" [label]="icv.label" [name]="icv.name" [placeholder]="icv.placeholder"
				[disabled]="icv.disabled" [control]="icv.required ? control : emptyControl" class="col-1of1">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-password>

			<uf-email [ngClass]="icv.cssClass" [label]="icv.label" [name]="icv.name" [placeholder]="icv.placeholder"
				[disabled]="icv.disabled" class="col-1of1">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-email>

			<uf-textarea [ngClass]="icv.cssClass" [label]="icv.label" [name]="icv.name" [placeholder]="icv.placeholder"
				[disabled]="icv.disabled" class="col-1of1">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-textarea>

			<uf-phone [ngClass]="icv.cssClass" [label]="icv.label" [name]="icv.name" [placeholder]="icv.placeholder"
				[disabled]="icv.disabled" class="col-1of1">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-phone>

			<uf-cost [ngClass]="icv.cssClass" [label]="icv.label" [name]="icv.name" [placeholder]="icv.placeholder"
				[disabled]="icv.disabled" class="col-1of1">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-cost>

			<uf-chips [ngClass]="icv.cssClass" [label]="icv.label" [placeholder]="icv.placeholder" [name]="icv.name"
				[disabled]="icv.disabled" [options]="options" class="col-1of1" nameProperty="name">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-chips>

			<uf-search-result [ngClass]="icv.cssClass" [label]="icv.label" [placeholder]="icv.placeholder"
				[name]="icv.name" [disabled]="icv.disabled" [options]="options" class="col-1of1" nameProperty="name">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-search-result>

			<uf-search [ngClass]="icv.cssClass" [label]="icv.label" [placeholder]="icv.placeholder" [name]="icv.name"
				[disabled]="icv.disabled" class="col-1of1" />

			<uf-file [ngClass]="icv.cssClass" [label]="icv.label" [disabled]="icv.disabled" class="col-1of1">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-file>

			<uf-date [ngClass]="icv.cssClass" [label]="icv.label" [name]="icv.name" [placeholder]="icv.placeholder"
				[disabled]="icv.disabled" class="col-1of1">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-date>

			<uf-time [ngClass]="icv.cssClass" [label]="icv.label" [name]="icv.name" [placeholder]="icv.placeholder"
				[disabled]="icv.disabled" class="col-1of1">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-time>

			<uf-datetime [ngClass]="icv.cssClass" [label]="icv.label" [name]="icv.name" [placeholder]="icv.placeholder"
				[disabled]="icv.disabled" class="col-1of1">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-datetime>

			<uf-datetime-tz [ngClass]="icv.cssClass" [label]="icv.label" [name]="icv.name"
				[placeholder]="icv.placeholder" [disabled]="icv.disabled" class="col-1of1">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-datetime-tz>

			<uf-radio [ngClass]="icv.cssClass" [label]="icv.label" [disabled]="icv.disabled" [options]="options"
				class="col-1of1">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-radio>

			<uf-checkbox [ngClass]="icv.cssClass" [label]="icv.label" [name]="icv.name" [disabled]="icv.disabled"
				class="col-1of1">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-checkbox>

			<uf-select [ngClass]="icv.cssClass" [label]="icv.label" [name]="icv.name" [placeholder]="icv.placeholder"
				[disabled]="icv.disabled" [options]="options" class="col-1of1">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-select>

			<uf-multi-choice [ngClass]="icv.cssClass" [label]="icv.label" [name]="icv.name" [disabled]="icv.disabled"
				[options]="options" class="col-1of1">
				<div *ngIf="icv.suffix.length" class="suffix">{{ icv.suffix }}</div>
				<uf-help *ngIf="icv.help" content="My help text!" />
			</uf-multi-choice>

		</div>
	</div>
</div>