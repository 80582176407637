import { AfterViewInit, ChangeDetectorRef, Component, HostBinding, ViewChild, inject } from '@angular/core';
import { DataSeed } from '@unifii/sdk';

import { Modal, ModalData, ModalRuntime, RuntimeField, Scope, SharedTermsTranslationKey } from '@unifii/library/common';
import { ScopeManager, WorkflowService } from '@unifii/library/smart-forms';

import { RepeatFormComponent } from '../repeats/repeat-form.component';

export interface RepeatModalData {
	field: RuntimeField;
	scope: Scope;
	index: number;
	dataSeed?: DataSeed;
}

@Component({
	templateUrl: './repeat-modal.html',
	styleUrls: ['./repeat-modal.less'],
})
export class RepeatModalComponent implements Modal<RepeatModalData, Scope | undefined>, AfterViewInit {

	@HostBinding('class.uf-form-card') class = true;
	@ViewChild(RepeatFormComponent) private repeatForm: RepeatFormComponent;
	
	runtime = inject<ModalRuntime<RepeatModalData, Scope | undefined>>(ModalRuntime);
	data = inject<RepeatModalData>(ModalData);

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected disabled: boolean;
	protected scope = structuredClone(this.data.scope);

	private changeDetectorRef = inject(ChangeDetectorRef);
	private scopeManager = inject(ScopeManager);
	private workflowService = inject(WorkflowService);
	
	ngAfterViewInit() {
		this.disabled = this.repeatForm.control.disabled;
		this.changeDetectorRef.detectChanges();
	}

	close() {
		this.runtime.close();
	}

	protected confirm() {
		let invalid: boolean;

		if (this.workflowService.actions.every((a) => a.transition.validate)) {
			invalid = this.repeatForm.control.invalid;
		} else {
			invalid = !this.scopeManager.validateExcludingRequired(this.repeatForm.control);
		}

		if (invalid) {
			this.repeatForm.control.setSubmitted();

			return;
		}

		this.runtime.close(this.scope);
	}

}
