export * from './components';
export * from './wrapper-components';
export * from './data-display-group.component';
export * from './data-display-list.component';
export * from './data-display.component';

import { UF_DATA_DISPLAY_COMPONENTS } from './components';
import { DataDisplayGroupComponent } from './data-display-group.component';
import { DataDisplayListComponent } from './data-display-list.component';
import { DataDisplayComponent } from './data-display.component';
import { DATA_DISPLAY_WRAPPER_COMPONENTS } from './wrapper-components';

export const DATA_DISPLAY_COMPONENTS = [
	DataDisplayComponent, DataDisplayListComponent, DataDisplayGroupComponent,
	...UF_DATA_DISPLAY_COMPONENTS,
	...DATA_DISPLAY_WRAPPER_COMPONENTS,
];
