import { Component, EventEmitter, HostBinding, Input, OnInit, Output, inject } from '@angular/core';
import { DataSeed, DataSourceType, FormStyle, isDataSeed, isStringTrimmedNotEmpty } from '@unifii/sdk';

import { DataSourceDisplayTo, RuntimeField, Scope, SharedTermsTranslationKey, UfControlGroup } from '@unifii/library/common';
import { FormService, ScopeManager } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-repeat-form',
	templateUrl: './repeat-form.html',
	styleUrls: ['../form-group.less', './repeat-form.less'],
	providers: [ScopeManager],
})
export class RepeatFormComponent implements OnInit {

	@Input({ required: true }) field: RuntimeField;
	@Input({ required: true }) scope: Scope;
	@Input({ required: true }) index: number;
	@Input() inModal = false;
	@Output() remove = new EventEmitter<number>();
	@Output() contentChange = new EventEmitter<any>();

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected dataSeedScope: DataSeed | undefined;
	
	private scopeManager = inject(ScopeManager);
	private parentScopeManager = inject(ScopeManager, { skipSelf: true });
	private formService = inject(FormService, { optional: true });
	private collapsed: boolean;
	private defaultLabel: string;

	ngOnInit() {
		this.scopeManager.init(this.field, this.parentScopeManager);
		this.scopeManager.scope = this.scope;
		this.scopeManager.seed = this.scope as DataSeed;
		this.dataSeedScope = isDataSeed(this.scope) ? this.scope : undefined;
		this.defaultLabel = [this.field.itemLabel, `${this.index + 1}`].filter(isStringTrimmedNotEmpty).join(' ');
	}

	get control(): UfControlGroup {
		return this.scopeManager.control;
	}

	protected get title() {
		return this.dataSourceLabel ?? this.userLabel ?? this.defaultLabel;
	}

	protected get showAddDeleteButton(): boolean {
		return !this.control.disabled && !this.field.autofill;
	}
	
	protected get isSummary(): boolean {
		return this.formService?.style === FormStyle.Summary;
	}

	@HostBinding('class.disabled') protected get hostDisabled(): boolean {
		return this.scopeManager.control.disabled && !this.isSummary;
	}

	@HostBinding('class.collapsed') protected get hostCollapsed(): boolean {
		return this.collapsed;
	}

	@HostBinding('class.error') protected get hostError(): boolean {
		return this.scopeManager.control.submitted && this.scopeManager.control.invalid;
	}

	protected changed(v: any) {
		this.contentChange.emit(v);
	}

	protected isVisible(child: RuntimeField): boolean {
		return !this.formService || this.formService.isGranted(child);
	}

	private get dataSourceLabel(): string | undefined {

		if (this.field.sourceConfig && this.field.sourceConfig.type !== DataSourceType.Named) {
			const mapping = this.field.sourceConfig.mappings.find((m) => m.to === DataSourceDisplayTo);

			return mapping ? this.scope[mapping.from] as string : undefined;
		}

		return undefined;
	}

	// User datasource is required to display firstName + lastName as a label
	private get userLabel(): string | undefined {
		if (
			this.field.sourceConfig?.type !== DataSourceType.Users ||
			!this.field.sourceConfig.mappingsTo.firstName ||
			!this.field.sourceConfig.mappingsTo.lastName
		) {
			return;
		}

		const firstNameFrom = this.field.sourceConfig.mappingsTo.firstName.from;
		const lastNameFrom = this.field.sourceConfig.mappingsTo.lastName.from;

		return `${this.scope[firstNameFrom] ?? ''} ${this.scope[lastNameFrom] ?? ''}`;
	}

}
