<sc-page-content width="xl" identifier="data-display">
	<ng-container editor>
		<div class="uf-form-card">
			<div class="uf-app-bar">
				<h3>Configuration</h3>
				<sc-entity-picker [pickData]="true" [type]="'bucket'" (emitChange)="onFormDataEmit($event)" />
			</div>
		</div>

		<uf-message *ngIf="error" class="error">
			<h3>{{ error }}</h3>
		</uf-message>

		<uf-form *ngIf="definition && formData" [definition]="definition" [formData]="formData" [disabled]="true"
			class="print" />

	</ng-container>
</sc-page-content>