import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';

import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-checkbox',
	templateUrl: './uf-checkbox.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfCheckboxComponent, multi: true,
	}],
	styleUrls: ['./uf-checkbox.less'],
})
export class UfCheckboxComponent extends UfControlValueAccessor<boolean> {

	@Input() id: string;
	@Input() name: string;
	@Input() label?: string | null;
	@Input() content?: string;
	@Output() override valueChange = new EventEmitter<boolean>();
	@Output() touchedChange = new Subject<boolean>();

	focused: boolean;

	@HostBinding('class.error') get errorClass() {
		return this.control.showError && (!this.disabled);
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	userClick(event: MouseEvent) {

		if (this.disabled) {
			event.preventDefault();
		}
	}

}
