import { Component, Input, inject } from '@angular/core';

import { Config } from '../models';
import { MenuNode } from '../services';

@Component({
	selector: 'sc-menu-item',
	templateUrl: './show-menu-item.html',
	styleUrls: ['./show-menu-item.less'],
})
export class ShowMenuItemComponent {

	@Input() node: MenuNode;

	private config = inject(Config);

	get visible() {
		return this.config.env !== 'prod' || this.node.public;
	}

}
