import { Component, HostBinding, Input } from '@angular/core';

import { RadioIcon, RadioInputTemplate } from '../../../models';

@Component({
	selector: 'uf-radio-input-wrapper',
	templateUrl: './radio-input-wrapper.html',
	styleUrls: ['./radio-input-wrapper.less'],
})
export class RadioInputWrapperComponent {

	@Input() label: string;
	@Input() icon: RadioIcon = { checked: 'radioDot', unchecked: 'radioCircle' }; // Makes component reusable for radios with alternative icons
	@Input() content: string;
	@Input() focused: boolean;
	@Input() disabled: boolean;
	@Input() checked: boolean;
	@Input() template: RadioInputTemplate;

	@HostBinding('class.focused') get focusedClass() {
		return this.focused;
	}

	@HostBinding('class.checked') get checkedClass() {
		return this.checked;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

}
