import { Component, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { UfControl, UfControlGroup } from '../../controls';
import { ClipboardService, PasswordService, UfFormBuilder } from '../../services';
import { CommonTranslationKey, SharedTermsTranslationKey } from '../../translations';
import { ValidatorFunctions } from '../../utils';

import { UfControlValueAccessor } from './uf-control-value-accessor';
import { UfPasswordComponent } from './uf-password.component';

export interface CreatePasswordConfigLabels {
	message?: string;
	oldPasswordLabel?: string;
	passwordLabel?: string;
	changeFlagLabel?: string;
	generateLabel?: string;
	strengthIndicatorLabel?: string;
}

export interface CreatePasswordConfig {
	isRequired?: boolean;
	showStrengthIndicator?: boolean;
	showOldPassword?: boolean;
	showChangePasswordOnNextLogin?: boolean;
	canCopy?: boolean;
	canGenerate?: boolean;
	labels?: CreatePasswordConfigLabels;
}

export interface CreatePasswordValue {
	oldPassword?: string;
	password?: string;
	changePasswordOnNextLogin?: boolean;
}

enum ControlKeys {
	OldPassword = 'oldPassword',
	Password = 'password',
	ChangePasswordOnNextLogin = 'changePasswordOnNextLogin'
}

@Component({
	selector: 'uf-create-password',
	templateUrl: './uf-create-password.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfCreatePasswordComponent, multi: true,
	}],
	styleUrls: ['./uf-create-password.less'],
})
export class UfCreatePasswordComponent extends UfControlValueAccessor<CreatePasswordValue> implements OnInit {

	@Input() config: CreatePasswordConfig = {};
	@Output() override valueChange = new EventEmitter<CreatePasswordValue>();	
	
	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly commonTS = CommonTranslationKey;
	protected readonly controlKeys = ControlKeys;
	protected labels: CreatePasswordConfigLabels;
	protected clipboard = inject(ClipboardService);

	protected groupControl: UfControlGroup;
	protected pwned = false;
	private _submitted = false;
	private passwordService = inject(PasswordService);
	private translate = inject(TranslateService);
	private ufb = inject(UfFormBuilder);

	ngOnInit() {
		this.labels = Object.assign({
			oldPasswordLabel: this.translate.instant(CommonTranslationKey.CreatePasswordLabelOldPassword),
			passwordLabel: this.translate.instant(CommonTranslationKey.CreatePasswordLabelNewPassword),
			changeFlagLabel: this.translate.instant(CommonTranslationKey.CreatePasswordLabelChangePasswordNextLogin),
			generateLabel: this.translate.instant(CommonTranslationKey.CreatePasswordActionGeneratePassword),
			strengthIndicatorLabel: this.translate.instant(CommonTranslationKey.PasswordStrengthLabel),
		}, this.config.labels ?? {});

		this.groupControl = this.buildGroupControl(this.config, this.value ?? {});
		this.groupControl.patchValue(super.value ?? {});
		this.groupControl.updateValueAndValidity();

		this.subscriptions.add(this.passwordControl.valueChanges.subscribe((v) => { void this.onPasswordChange(v as string | null); }));
		this.subscriptions.add(this.groupControl.valueChanges.subscribe(() => { this._onValueChanges(); }));

		if (this.submitted) {
			this.setSubmitted(true);
		}
	}

	@ViewChild('passwordInput', { static: true }) set passwordInput(v: UfPasswordComponent | null) {
		if (v) {
			v.revealPassword(true);
		}
	}

	@Input() set submitted(v: boolean | null) {
		if (v == null) {
			return;
		}

		this._submitted = v;
		this.setSubmitted(v);
	}

	get submitted(): boolean {
		return this._submitted;
	}

	@Input() override set value(v: CreatePasswordValue | null | undefined) {
		super.value = v;

		// guard for race condition with ngOnInit
		if (this.patternUtil.isEqual(v, this.groupControl.value as CreatePasswordValue | null)) {
			return;
		}

		this.groupControl.patchValue(super.value ?? {});
		this.groupControl.updateValueAndValidity();
	}

	override get value(): CreatePasswordValue | null | undefined {
		return super.value;
	}

	get passwordControl(): UfControl {
		return this.groupControl.get(ControlKeys.Password) as UfControl;
	}

	get isValid(): boolean {
		return this.groupControl.valid;
	}

	private get oldPasswordControl(): UfControl | undefined {
		return this.groupControl.get(ControlKeys.OldPassword) as UfControl | undefined;
	}

	protected async generatePassword() {
		const password = await this.passwordService.generate();

		this.passwordControl.markAsTouched();
		this.passwordControl.markAsDirty();
		this.passwordControl.setValue(password, { emitEvent: true, onlySelf: false });
	}

	private buildGroupControl(config: CreatePasswordConfig, value: CreatePasswordValue | null): UfControlGroup {

		const passwordControl = this.ufb.control({ value: value?.password ?? null, disabled: this.control.disabled }, ValidatorFunctions.compose([
			ValidatorFunctions.custom((v: string | null) => !v || v.length >= 12, this.translate.instant(CommonTranslationKey.CreatePasswordErrorMinLength12)),
			ValidatorFunctions.custom((v: string | null) => !v || v.length < 256, this.translate.instant(CommonTranslationKey.CreatePasswordErrorMaxLength256)),
			ValidatorFunctions.custom((v: string | null) => !config.isRequired || !ValidatorFunctions.isEmpty(v), this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired)),
		]));

		const groupControl = this.ufb.group({
			[ControlKeys.Password]: passwordControl,
		});

		if (config.showOldPassword) {
			const oldPasswordControl = this.ufb.control(
				{ value: value?.oldPassword ?? null, disabled: this.control.disabled },
				ValidatorFunctions.custom((v) =>
					(!config.isRequired || !ValidatorFunctions.isEmpty(v)) || (!passwordControl.value || !!v),
				this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired),
				),
				null,
				{ deps: [passwordControl] },
			);

			groupControl.setControl(ControlKeys.OldPassword, oldPasswordControl);
		}

		if (config.showChangePasswordOnNextLogin) {
			const changePasswordControl = this.ufb.control(
				{ value: value?.changePasswordOnNextLogin, disabled: this.control.disabled },
			);

			groupControl.setControl(ControlKeys.ChangePasswordOnNextLogin, changePasswordControl);
		}

		return groupControl;
	}

	private async onPasswordChange(v: string | null) {
		this.pwned = false;

		if (v != null && this.passwordControl.valid) {
			this.pwned = await this.passwordService.hasBeenPwned(v);
		}
	}

	/**
	 * Subscription for internal control changes
	 */
	private _onValueChanges() {
		const controlValue = this.groupControl.getRawValue() as CreatePasswordValue | null;
		const value = Object.assign({
			oldPassword: undefined,
			password: undefined,
			changePasswordOnNextLogin: undefined,
		} as CreatePasswordValue, controlValue);

		if (!this.patternUtil.isEqual(value, this.value)) {
			this.control.setValue(value, { onlySelf: false, emitEvent: true });
		}
	}

	private setSubmitted(v: boolean) {
		this.passwordControl.setSubmitted(v);
		this.oldPasswordControl?.setSubmitted(v);
	}

}
