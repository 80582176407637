import { AfterViewInit, ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import JSZip from 'jszip';

import { ClipboardService, ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-icons.html',
	styleUrls: ['./show-icons.less'],
})
export class ShowIconsComponent implements AfterViewInit {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly propertiesColConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'default', name: 'Default' },
		{ identifier: 'description', name: 'Description' },
	];

	readonly propertiesRows = [
		{
			name: 'name',
			type: 'string',
			default: 'undefined',
			description: 'Sets the icon',
		},
	];

	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'large', type: 'modifier', description: 'Sets size of icon to large' },
		{ name: 'default', type: 'modifier', description: 'Sets size of icon to default' },
		{ name: 'small', type: 'modifier', description: 'Sets size of icon to small' },
		{ name: 'x-small', type: 'modifier', description: 'Sets size of icon to extra small' },
	];

	readonly sizeOptions = [
		{ name: 'Large', value: 'large' },
		{ name: 'Medium (default)', value: '' },
		{ name: 'Small', value: 'small' },
		{ name: 'Extra Small', value: 'x-small' },
	];
	readonly colourOptions = [
		{ name: 'Grey (default)', value: '' },
		{ name: 'Light Grey', value: 'light' },
		{ name: 'Primary Action', value: 'primary' },
		{ name: 'White', value: 'white' },
	];

	size = '';
	color = '';
	template = '';

	selected: string | null;
	all: string[] = [];
	icons: string[] = [];
	expected: number;
	duplicates = [] as string[];
	content: string;

	constructor(
		private clipboard: ClipboardService,
		private elementRef: ElementRef,
		private cdr: ChangeDetectorRef,
	) { }

	ngAfterViewInit() {
		try {
			const iconsElement = this.elementRef.nativeElement.querySelector('uf-icons');

			if (iconsElement) {
				this.content = iconsElement.innerHTML;
				this.init();
			}
		} catch (e) {
			console.error(e);
		}
	}

	updateCode() {

		if (!this.selected) {
			this.template = '';

			return;
		}

		this.template = `<uf-icon name="${this.selected}"></uf-icon>`;
	}

	isDuplicated(icon: string) {
		return this.duplicates.find((v) => v === icon);
	}

	filter(q?: string | null) {
		this.selected = null;
		if (!q?.trim().length) {
			this.icons = this.all;
		} else {
			this.icons = this.all.filter((i) => i.toLowerCase().includes(q.toLowerCase()));
		}
	}

	async selectIcon(name: string) {
		this.selected = name;
		this.clipboard.setText(name, { successFeedback: `'${name}' copied to clipboard` });
	}

	exportIcons(event: Event) {

		event.preventDefault();
		event.stopPropagation();

		const doc = this.loadXMLDoc(this.content as any as string);

		if (!doc) {
			return;
		}

		const zip = new JSZip();
		const icons = Array.from(doc.getElementsByTagName('symbol'));

		// Respect file indentation
		const pre = '<?xml version="1.0" encoding="utf-8"?>' +
			'\n<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">' +
			'\n<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 28 28" style="enable-background:new 0 0 28 28;" xml:space="preserve">';
		const post = `</svg>`;

		for (const icon of icons) {
			const iconFileContent = pre +
				'\n\t<title>${icon.id}</title>' +
				'\n\t<desc>Exported from Unifii library</desc>' +
				icon.innerHTML.trim() + '\n' + post;

			zip.file(icon.id + '.svg', iconFileContent);
		}

		zip.generateAsync({ type: 'base64' }).then((blob: any) => {
			const link = document.createElement('a');

			link.setAttribute('href', 'data:application/zip;base64,' + blob);
			link.setAttribute('download', 'icons.zip');
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		});
	}

	private async init() {

		this.expected = (((this.content as any as string).match(/symbol/g) || []).length / 2);
		const doc = this.loadXMLDoc(this.content as any as string);

		if (doc) {

			const icons = Array.from(doc.getElementsByTagName('symbol'));

			for (const icon of icons) {
				const iconName = icon.id;

				// Check duplicates
				if (this.all.includes(iconName)) {
					this.duplicates.push(iconName);
				}
				// Add icon id
				this.all.push(iconName);
			}
			// Sort icons
			this.all = this.all.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1);

			// Run empty search
			this.filter();

			this.cdr.detectChanges();
		}
	}

	private loadXMLDoc(xml: string) {
		let xmlDoc = null;

		if (window.DOMParser) {
			const parser = new DOMParser();

			xmlDoc = parser.parseFromString(xml, 'text/xml');
		} else {
			// Internet Explorer
			// xmlDoc = new ActiveXObject('Microsoft.XMLDOM');
			// xmlDoc.async = false;
			// xmlDoc.loadXML(content);
			console.error('ShowIconsComponent not supported in IE');
		}

		return xmlDoc;
	}

}
