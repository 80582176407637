import { InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';

import { NavItem } from '@unifii/library/common';

export interface NavigationService<T> {
	navItems?: NavItem[];
	onNavigation: Subject<T>;
	register: (key: T, scrollTarget?: HTMLElement) => void;
	deregister: (key: T) => void;
	setActive: (key: T, isActive: boolean) => void;
	setError: (key: T, hasError: boolean) => void;
	setDisabled: (key: T, isDisabled: boolean) => void;
	setSuccess: (key: T, hasSuccess: boolean) => void;
	navigateTo: (navItem: NavItem) => void;
	getLastItem: () => T | null;
}

export const NavigationService = new InjectionToken<NavigationService<any>>('NavigationService');
