<div class="grid">
	<uf-select *ngIf="views?.length" [options]="views" [(value)]="id" (valueChange)="render($event)" class="col-1of1"
		label="Select View" nameProperty="name" valueProperty="id" placeholder="Select" />

	<div *ngIf="!views.length" class="col-1of1">
		<uf-message class="info" icon="info" content="No Views" />
	</div>
</div>

<ng-template [ngIf]="data && data.definition && data.compound">
	<uf-view [definition]="data.definition" [compound]="data.compound" />
</ng-template>