import { AfterViewInit, Component, ContentChild, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, TemplateRef, ViewChild, inject } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Dictionary } from '@unifii/sdk';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { NamePropertyPipe } from '../../pipes';
import { SharedTermsTranslationKey } from '../../translations';
import { ProgressComponent } from '../indicators';

import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-chips',
	templateUrl: './uf-chips.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfChipsComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-chips.less'],
})
export class UfChipsComponent extends UfControlValueAccessor<any[]> implements OnInit, AfterViewInit {

	@ViewChild('inputWrap', { static: true }) inputWrap: ElementRef<HTMLDivElement> | undefined;
	@ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement> | undefined;
	@ContentChild('listBox', { static: true }) listBoxTemplate: TemplateRef<any>;
	@ViewChild(ProgressComponent, { static: true }) progress: ProgressComponent | undefined;

	@Input() name?: string | null;
	@Input() label?: string | null;
	@Input() placeholder?: string | null;
	@Input() debounce?: number | null = 200;
	@Input() autofocus?: boolean | null;
	@Input() nameProperty?: string | ((option: any) => string) | null;
	@Input() canDelete?: (option: any) => boolean;
	@Input() allowDuplicates?: boolean | null;
	@Input() allowCustom?: boolean | null; /** Allow the user to provide custom value outside of the options range */
	@Input() noResultsMsg?: string | null;
	@Input() minSearchLength?: number | null = 0;
	@Input() minSearchLengthMsg?: string | null;
	@Input() valueProperty?: string | null;
	@Output() searchChange = new EventEmitter<string>(); /** Notify search criteria has changed */
	@Output() override valueChange = new EventEmitter<any>(); /** Notify the selected value has changed */

	readonly sharedTermsTK = SharedTermsTranslationKey;

	/** Chrome autofill no longer accepts autocomplete="off" for now a random value seems to work
	in the future we may have to create a unique value for each */
	autocomplete: string = Math.random().toString(36).substring(2, 18);
	focused: boolean;
	queryControl = new FormControl('');
	focusedChip: number | null;
	optionsChange = new Subject<string[]>();

	protected hasSearchConfigured: boolean;

	private _options: any[] | null | undefined;
	private namePropertyPipe = inject(NamePropertyPipe);

	ngOnInit() {
		this.subscriptions.add(this.queryControl.valueChanges.pipe(
			debounceTime(this.debounce ?? 0),
			distinctUntilChanged(),
		).subscribe((q) => { this.guardedEmitSearchChange(q); }));

		this.hasSearchConfigured = this.searchChange.observed;
	}

	ngAfterViewInit() {
		if (!this.input) {
			return;
		}

		this.subscriptions.add(fromEvent(this.input.nativeElement, 'focus').subscribe(() => { this.onfocus(); }));
		// debounce to guarantee onselect to execute before blur, avoid updateInputControl race condition
		this.subscriptions.add(fromEvent(this.input.nativeElement, 'blur').pipe(debounceTime(100)).subscribe(() => { this.onblur(); }));

		if (this.autofocus) {
			this.input.nativeElement.focus();
		}
	}

	/** @override - needed because directive @Input is not reflected from the parent class */
	@Input() override set value(v: any[] | any | undefined) {
		super.value = v;
	}

	override get value(): any[] {
		return super.value ?? [];
	}

	@Input() set options(v: any[] | null | undefined) {

		this.progress?.complete();

		if (this.disabled) {
			return;
		}

		this._options = this.getFilteredOptions(v);
	}

	get options(): any[] | null | undefined {
		return this._options;
	}

	@HostBinding('class.focused') get focusedClass() {
		return this.focused && !this.disabled;
	}

	@HostBinding('class.error') get errorClass() {
		return this.control.showError && !this.disabled;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	@HostBinding('class.value') get valueClass() {
		return !!this.value;
	}

	override valueEmitPredicate(value?: (Dictionary<any> | string)[] | null, prev?: (Dictionary<any> | string)[] | null): boolean {
		if (!this.control.dirty) {
			this.control.markAsDirty();
		}

		return super.valueEmitPredicate(value, prev);
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	@HostListener('keydown', ['$event'])
	keydown(event: KeyboardEvent) {

		if (event.key !== 'Backspace' || !this.value.length || !!this.queryControl.value) {
			return;
		}

		if (this.focused) {
			this.focusLastChip();

			return;
		}

		if (this.focusedChip != null && this.focusedChip >= 0) {
			this.delete(this.focusedChip);
			this.focusLastChip();
		}
	}

	protected allowDelete(chip: Dictionary<any> | string | number) {
		if (this.disabled) {
			return false;
		}

		if (!this.canDelete) {
			return true;
		}

		return this.canDelete(chip);
	}

	protected delete(i: number) {

		const updateValue = this.value;

		updateValue.splice(i, 1);
		this.control.setValue([...updateValue]);

		this.focusedChip = null;
	}

	protected onSelect(value: Dictionary<any> | string) {
		this.clearQueryInput();
		if (this.valueProperty) {
			this.control.setValue([...this.value, (value as Dictionary<any>)[this.valueProperty]]);

			return;
		}
		/** Create new array reference so change is detected and emitted */
		this.control.setValue([...this.value, value]);
	}

	protected clearQueryInput() {
		this.queryControl.setValue(null, { onlySelf: true, emitEvent: false });
	}

	protected namePropertyFunc = (v: Dictionary<any> | string | number) => {
		if (
			this.valueProperty &&
			this.nameProperty &&
			(typeof this.nameProperty === 'string') &&
			(typeof v === 'string' || typeof v === 'number')
		) {
			const option = this._options?.find((o) => o[this.valueProperty as keyof typeof o] === v) ?? {};

			return option[this.nameProperty] ?? v;
		}

		return this.namePropertyPipe.transform(v, this.nameProperty);
	};

	private onfocus() {

		this.focused = true;

		if (this.disabled) {
			return;
		}

		this.safeClearOptions();
		this.guardedEmitSearchChange(this.queryControl.value);
	}

	private onblur() {
		this.focused = false;
		this.control.markAsTouched();
		this.progress?.complete();
		this.clearQueryInput();
	}

	private getFilteredOptions(options: (Dictionary<any> | string)[] | null = []): (Dictionary<any> | string)[] | undefined {
		if (!options) {
			return;
		}

		return options.filter((filterValue) => {

			if (this.allowDuplicates) {
				return true;
			}

			if (this.valueProperty) {
				const nonNullValueProperty = this.valueProperty;

				/** find matching by identifier */
				return !this.value.find((v: any) => v === (filterValue as Dictionary<any>)[nonNullValueProperty]);
			}

			if (this.nameProperty && typeof this.nameProperty === 'string') {
				const nonNullNameProperty = this.nameProperty;

				/** find matching complex values */
				return !this.value.find((findValue: any) =>
					(findValue as Dictionary<any>)[nonNullNameProperty] === (filterValue as Dictionary<any>)[nonNullNameProperty],
				);
			}

			return !this.value.includes(filterValue);
		});
	}

	private safeClearOptions() {
		if (this.hasSearchConfigured) {
			this.options = null;
		}
	}

	private guardedEmitSearchChange(q: string | null) {

		if (+(this.minSearchLength ?? 0) > (q ?? '').length) {
			this.safeClearOptions();

			return;
		}

		if (this.hasSearchConfigured) {
			this.progress?.start(.4);
			this.searchChange.emit(q ?? undefined);
		}
	}

	private focusLastChip() {

		if (!this.value.length || !this.inputWrap) {
			return;
		}

		const chips = this.inputWrap.nativeElement.querySelectorAll<HTMLDivElement>('.uf-chip');
		const deleteButton = chips[chips.length - 1]?.querySelector<HTMLButtonElement>('button[data-type="chip"');

		deleteButton?.focus();
	}

}
