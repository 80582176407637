import { Component, OnInit } from '@angular/core';
import { FieldTemplate } from '@unifii/sdk';

import { RuntimeField } from '@unifii/library/common';
import { ContentField, contentIconMap, contentLevelMap } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-content-input',
	templateUrl: './content.html',
	styleUrls: ['./content.less'],
})
export class FieldContentComponent implements ContentField, OnInit {

	field: RuntimeField;
	content: string;
	classes: string[];

	protected readonly iconMap = contentIconMap;

	ngOnInit() {
		this.classes = this.getClasses();
	}

	private getClasses(): string[] {
		const classes: string[] = [];

		if (this.field.template) {
			classes.push(contentLevelMap[this.field.template] ?? this.field.template);
		}

		if (this.field.template === FieldTemplate.Content) {
			classes.push('untinted-background');
		}

		if (this.field.template && this.field.template !== FieldTemplate.Content) {
			classes.push('raised');
		}

		return classes;
	}

}

