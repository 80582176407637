import { Injectable, inject } from '@angular/core';

import { ExpressionFunctionsSet } from '../../models';
import { UfExpressionFunctionsSet, isTemplateExpression } from '../../utils';
import { ExpressionParser } from '../expression-parser';
import { TemplateStringParser } from '../template-string-parser';

// ExpressionParser has a log silence policy for DataLookupService.
// Check it before rename this class or its method 'lookupData'

@Injectable({ providedIn: 'root' })
export class DataLookupService {

	private expressionParser = inject(ExpressionParser);
	private templateStringParser = inject(TemplateStringParser);

	/**
	 * Extract a value from the data using the expression provided
	 * @param data 
	 * @param expression a path within the data or a template
	 * @returns 
	 */
	lookupData(data: Record<string, unknown>, expression: string, origin?: string, extendedFunctions: ExpressionFunctionsSet = UfExpressionFunctionsSet): unknown {

		if (isTemplateExpression(expression)) {
			return this.templateStringParser.parse(expression, undefined, data, `DataLookupService.lookupData (template)${origin ? ':' + origin : ''}`, extendedFunctions);
		}

		return this.expressionParser.resolve(expression, undefined, data, `DataLookupService.lookupData (expression)${origin ? ':' + origin : ''}`);
	}

}
