export * from './action-group.component';
export * from './break-after.component';
export * from './form-group.component';
export * from './section.component';
export * from './separator.component';
export * from './survey-description.component';
export * from './survey.component';
export * from './content-carousel.component';
export * from './form-stepper.component';
export * from './form-step.component';

import { ActionGroupComponent } from './action-group.component';
import { BreakAfterComponent } from './break-after.component';
import { ContentCarouselComponent } from './content-carousel.component';
import { GroupComponent } from './form-group.component';
import { FormStepComponent } from './form-step.component';
import { FormStepperComponent } from './form-stepper.component';
import { SectionComponent } from './section.component';
import { SeparatorComponent } from './separator.component';
import { SurveyDescriptionComponent } from './survey-description.component';
import { SurveyComponent } from './survey.component';

export const UF_INPUT_FIELD_CONTAINER_COMPONENTS = [
	ActionGroupComponent,
	GroupComponent,
	SectionComponent,
	SeparatorComponent,
	SurveyComponent,
	BreakAfterComponent,
	SurveyDescriptionComponent,
	ContentCarouselComponent,
	FormStepperComponent,
	FormStepComponent,
];
