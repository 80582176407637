import { Component } from '@angular/core';

@Component({
	selector: 'sc-column-demo',
	templateUrl: './show-column-demo.html',
	styleUrls: ['./show-column-demo.less'],
})
export class ShowColumnDemoComponent {

	// Demo columns
	readonly columns = this.getColumns(12);

	private getColumns(total: number): { className: string; name: string }[] {

		const length = (total / 2) + 1;

		return Array.from({ length }, (v, index) => index + (total / 2))
			.reverse()
			.reduce<{ className: string; name: string }[]>((list, index) => {

				const prefix = 'col-';
				const remainer = total - index;

				if (remainer > 0) {
					list.push({
						className: prefix + remainer,
						name: '' + remainer,
					});
				}

				list.push({
					className: prefix + index,
					name: '' + index,
				});

				return list;

			}, []);

	}

}
