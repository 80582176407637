import { Component, Input } from '@angular/core';

import { DataDisplayComponentType } from '../../../models';

import { DataDisplayAttachmentValue, isDataDisplayAttachmentValue } from './data-display-components-model';

@Component({
	selector: 'uf-data-display-attachment',
	templateUrl: './data-display-attachment.html',
	styleUrls: ['../data-display.less'],
})
export class DataDisplayAttachmentComponent implements DataDisplayComponentType {
	
	@Input({ required: true }) data: DataDisplayAttachmentValue;

	static isDataAccepted = isDataDisplayAttachmentValue;
	
}
