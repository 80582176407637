<svg>
	<defs>
		<symbol id="add">
			<path d="M25,13H15V3a1,1,0,0,0-2,0V13H3a1,1,0,0,0,0,2H13V25a1,1,0,0,0,2,0V15H25a1,1,0,0,0,0-2Z" />
		</symbol>

		<symbol id="addImage">
			<path d="M16.86,14a3,3,0,1,0-3-3A3,3,0,0,0,16.86,14Zm0-5a2,2,0,1,1-2,2A2,2,0,0,1,16.86,9Z" />
			<path
				d="M24,11v6.2l-3.42-3.62a.43.43,0,0,0-.37-.15.5.5,0,0,0-.37.17L17,16.89,9.12,8.17A.48.48,0,0,0,8.75,8h0a.5.5,0,0,0-.37.17L4,13V5A1,1,0,0,1,5,4H17V2H5A3,3,0,0,0,2,5V22.71A3.29,3.29,0,0,0,5.29,26H22.71A3.29,3.29,0,0,0,26,22.71V11ZM5.29,24A1.29,1.29,0,0,1,4,22.71V14.52L8.75,9.25l7.55,8.4L10.74,24ZM24,22.71A1.29,1.29,0,0,1,22.71,24H12.07l8.16-9.33,3.77,4Z" />
			<path d="M26.5,4H24V1.5a1,1,0,0,0-2,0V4H19.5a1,1,0,0,0,0,2H22V8.5a1,1,0,0,0,2,0V6h2.5a1,1,0,0,0,0-2Z" />
		</symbol>

		<symbol id="linkAdd">
			<path
				d="M20.94,3.25l-2.52-2.52c-.97-.97-2.56-.97-3.54,0l-5.27,5.27c.75,0,1.5,.23,2.13,.7l4.56-4.56c.1-.1,.22-.14,.35-.14s.26,.05,.35,.14l2.52,2.52c.19,.19,.19,.52,0,.71l-7.15,7.16c-.19,.19-.52,.19-.71,0l-2.16-2.16-1.41,1.41,2.16,2.16c.49,.49,1.13,.73,1.77,.73s1.28-.24,1.77-.73l7.15-7.16c.97-.97,.97-2.56,0-3.54Z" />
			<path
				d="M2.14,16.3l7.15-7.16c.19-.19,.52-.19,.71,0l2.15,2.15,1.42-1.41-2.16-2.16c-.97-.97-2.56-.97-3.54,0L.73,14.89c-.97,.97-.97,2.56,0,3.53h0l2.52,2.52c.49,.49,1.13,.73,1.77,.73s1.28-.24,1.77-.73l5.27-5.27s-.03,0-.04,0c-.74,0-1.47-.24-2.09-.71l-4.56,4.56c-.19,.19-.52,.19-.71,0l-2.52-2.52h0c-.19-.19-.19-.52,0-.71Z" />
			<path
				d="M25,19h-4v-4c0-.55-.45-1-1-1s-1,.45-1,1v4h-4c-.55,0-1,.45-1,1s.45,1,1,1h4v4c0,.55,.45,1,1,1s1-.45,1-1v-4h4c.55,0,1-.45,1-1s-.45-1-1-1Z" />
		</symbol>

		<symbol id="address">
			<path
				d="M14,1C8.85,1,4.5,5.58,4.5,11c0,7.08,8.44,15.37,8.8,15.72l.7.68.7-.69c.36-.35,8.8-8.73,8.8-15.71C23.5,5.58,19.15,1,14,1Zm0,23.56C12,22.45,6.5,16.14,6.5,11,6.5,6.74,10,3,14,3s7.5,3.74,7.5,8C21.5,16.06,16,22.44,14,24.56Z" />
			<path
				d="M14,16.5A5.5,5.5,0,1,1,19.5,11,5.51,5.51,0,0,1,14,16.5Zm0-9A3.5,3.5,0,1,0,17.5,11,3.5,3.5,0,0,0,14,7.5Z" />
		</symbol>

		<symbol id="addressWithMap">
			<path
				d="M27.79,28,24.15,14H15.09A12.34,12.34,0,0,0,17,8,8,8,0,1,0,1,8,13.66,13.66,0,0,0,3.56,15.1L.21,28ZM12.7,26,17,22.33,23.87,26Zm9.9-10,2.14,8.19L12.52,17.67c.42-.51.85-1.07,1.28-1.67ZM9,2a6,6,0,0,1,6,6c0,4-4.31,8.83-6,10.56C7.3,16.89,3,12.14,3,8A6,6,0,0,1,9,2ZM8.33,20.74l.67.62.68-.62c.08-.08.68-.64,1.49-1.52l3.92,2.1L9.63,26H2.79l2.29-8.79A32.17,32.17,0,0,0,8.33,20.74Z" />
			<path
				d="M13.5,7.5A4.5,4.5,0,1,0,9,12,4.51,4.51,0,0,0,13.5,7.5Zm-7,0A2.5,2.5,0,1,1,9,10,2.5,2.5,0,0,1,6.5,7.5Z" />
		</symbol>

		<symbol id="addUser">
			<path
				d="M26.64,4.5H24V1.86a1,1,0,1,0-2,0V4.5H19.37a1,1,0,0,0,0,2H22V9.14a1,1,0,1,0,2,0V6.5h2.63a1,1,0,0,0,0-2Z" />
			<path
				d="M17.88,13.92H13.57a1,1,0,0,0-.88.53L12,15.73l-.69-1.28a1,1,0,0,0-.88-.53H6.12A3.64,3.64,0,0,0,2.5,17.57v7.19a1,1,0,0,0,1,1h17a1,1,0,0,0,1-1V17.57A3.64,3.64,0,0,0,17.88,13.92Zm1.62,9.84H4.5V17.57a1.63,1.63,0,0,1,1.62-1.65H9.83l1.29,2.4a1,1,0,0,0,1.76,0l1.29-2.4h3.71a1.63,1.63,0,0,1,1.62,1.65Z" />
			<path
				d="M12,13.15A5.46,5.46,0,1,0,6.55,7.69,5.46,5.46,0,0,0,12,13.15Zm0-8.91A3.46,3.46,0,1,1,8.55,7.69,3.46,3.46,0,0,1,12,4.24Z" />
		</symbol>

		<symbol id="alert">
			<path
				d="M14,.5C6.56,.5,.5,6.56,.5,14s6.06,13.5,13.5,13.5,13.5-6.06,13.5-13.5S21.44,.5,14,.5Zm0,25c-6.34,0-11.5-5.16-11.5-11.5S7.66,2.5,14,2.5s11.5,5.16,11.5,11.5-5.16,11.5-11.5,11.5Z" />
			<path
				d="M13.99,19.5c-1.12,0-1.99,.89-1.99,1.98s.86,2.01,1.99,2.01,2.01-.89,2.01-2.01-.86-1.98-2.01-1.98Z" />
			<polygon points="12.67 18 15.33 18 16 5 12 5 12.67 18" />
		</symbol>

		<symbol id="alertSolid">
			<path
				d="M27.5,14c0,7.44-6.06,13.5-13.5,13.5S.5,21.44,.5,14,6.56,.5,14,.5s13.5,6.06,13.5,13.5Zm-13.51,5.5c-1.12,0-1.99,.89-1.99,1.98s.86,2.01,1.99,2.01,2.01-.89,2.01-2.01-.86-1.98-2.01-1.98Zm2.01-14.5h-4l.67,13h2.67l.67-13Z" />
		</symbol>

		<symbol id="alignCenter">
			<rect x="5" y="5" width="18" height="2" rx="1" />
			<rect x="5" y="21" width="18" height="2" rx="1" />
			<rect x="11" y="11" width="6" height="6" rx="1" />
		</symbol>

		<symbol id="alignLeft">
			<rect x="5" y="5" width="18" height="2" rx="1" />
			<rect x="5" y="21" width="18" height="2" rx="1" />
			<rect x="5" y="11" width="6" height="6" rx="1" />
		</symbol>

		<symbol id="alignRight">
			<rect x="5" y="5" width="18" height="2" rx="1" />
			<rect x="5" y="21" width="18" height="2" rx="1" />
			<rect x="17" y="11" width="6" height="6" rx="1" />
		</symbol>

		<symbol id="alignStretch">
			<rect x="5" y="5" width="18" height="2" rx="1" />
			<rect x="5" y="21" width="18" height="2" rx="1" />
			<rect x="5" y="11" width="18" height="6" rx="1" />
		</symbol>

		<symbol id="arrowBack">
			<path
				d="M18.5,4a1,1,0,0,1,.71.29,1,1,0,0,1,0,1.42L10.91,14l8.3,8.29a1,1,0,0,1-1.42,1.42l-9-9a1,1,0,0,1,0-1.42l9-9A1,1,0,0,1,18.5,4Z" />
		</symbol>

		<symbol id="arrowBackSolid">
			<polygon points="18.5 20 9.5 14 18.5 8 18.5 20" />
		</symbol>

		<symbol id="arrowDown">
			<path
				d="M14,19.5a1,1,0,0,1-.71-.29l-9-9A1,1,0,0,1,5.71,8.79L14,17.09l8.29-8.3a1,1,0,0,1,1.42,1.42l-9,9A1,1,0,0,1,14,19.5Z" />
		</symbol>

		<symbol id="arrowDownSolid">
			<polygon points="20 9.5 14 18.5 8 9.5 20 9.5" />
		</symbol>

		<symbol id="arrowForward">
			<path
				d="M9.5,24a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L17.09,14,8.79,5.71a1,1,0,0,1,1.42-1.42l9,9a1,1,0,0,1,0,1.42l-9,9A1,1,0,0,1,9.5,24Z" />
		</symbol>

		<symbol id="arrowForwardSolid">
			<polygon points="9.5 8 18.5 14 9.5 20 9.5 8" />
		</symbol>

		<symbol id="arrowUp">
			<path
				d="M23,19.5a1,1,0,0,1-.71-.29L14,10.91l-8.29,8.3a1,1,0,0,1-1.42-1.42l9-9a1,1,0,0,1,1.42,0l9,9a1,1,0,0,1,0,1.42A1,1,0,0,1,23,19.5Z" />
		</symbol>

		<symbol id="arrowUpSolid">
			<polygon points="8 18.5 14 9.5 20 18.5 8 18.5" />
		</symbol>

		<symbol id="app">
			<path
				d="M18.47,11.05l-4-2a.49.49,0,0,0-.44,0l-4,2a.51.51,0,0,0-.28.45v5A.51.51,0,0,0,10,17l4,2a.51.51,0,0,0,.44,0l4-2a.51.51,0,0,0,.28-.45v-5A.51.51,0,0,0,18.47,11.05Zm-4.22-1,2.88,1.44-2.88,1.44L11.37,11.5Zm-3.5,2.25,3,1.5v3.88l-3-1.5Zm4,5.38V13.81l3-1.5v3.88Z" />
			<path
				d="M19,1H9A3,3,0,0,0,6,4V24a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V4A3,3,0,0,0,19,1ZM8,22V6H20V22Zm6,4a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,14,26ZM16,4.5H12a.5.5,0,0,1,0-1h4a.5.5,0,0,1,0,1Z" />
		</symbol>

		<symbol id="media">
			<path
				d="M23.61,5h-.61v-.61c0-1.87-1.52-3.39-3.39-3.39H4.39c-1.87,0-3.39,1.52-3.39,3.39v15.22c0,1.87,1.52,3.39,3.39,3.39h.61v.61c0,1.87,1.52,3.39,3.39,3.39h15.22c1.87,0,3.39-1.52,3.39-3.39V8.39c0-1.87-1.52-3.39-3.39-3.39Zm1.39,3.39v9.42l-1.98-2.21c-.1-.11-.24-.15-.38-.17-.15,0-.28,.07-.38,.18l-2.99,3.62L11.11,9.68c-.09-.11-.23-.18-.38-.18h0c-.15,0-.29,.06-.38,.17l-3.35,3.92v-5.2c0-.77,.62-1.39,1.39-1.39h15.22c.77,0,1.39,.62,1.39,1.39ZM4.39,21c-.77,0-1.39-.62-1.39-1.39V4.39c0-.77,.62-1.39,1.39-1.39h15.22c.77,0,1.39,.62,1.39,1.39v.61H8.39c-1.87,0-3.39,1.52-3.39,3.39v12.61h-.61Zm2.61,2.61v-1.47s.03-.09,.03-.14-.02-.09-.03-.14v-6.73l3.73-4.36,7.89,9.25-4.11,4.98h-6.13c-.77,0-1.39-.62-1.39-1.39Zm16.61,1.39h-7.8l6.85-8.3,2.34,2.61v4.31c0,.77-.62,1.39-1.39,1.39Z" />
			<path
				d="M19.25,15c1.65,0,3-1.35,3-3s-1.35-3-3-3-3,1.35-3,3,1.35,3,3,3Zm0-5c1.1,0,2,.9,2,2s-.9,2-2,2-2-.9-2-2,.9-2,2-2Z" />
		</symbol>

		<symbol id="attachment">
			<path
				d="M19.12,12s.85-.65.2-1.29-1.28.18-1.28.18l-6.49,6.5s-1.53,2-2.68.78.77-2.68.77-2.68l8.52-8.52s2-2.19,3.86-.32-.33,3.86-.33,3.86L12.12,20s-3.07,3.54-5.9.71S7,14.87,7,14.87L14.22,7.6s.82-.68.21-1.29-1.29.2-1.29.2L4.8,14.85a4.94,4.94,0,0,0,.36,7,4.94,4.94,0,0,0,7,.37L23.6,10.73a3.72,3.72,0,0,0-.44-5.27A3.71,3.71,0,0,0,17.89,5L8,14.88s-2.53,2.06-.24,4.35S12.1,19,12.1,19Z" />
		</symbol>

		<symbol id="audio">
			<path
				d="M14,25a1,1,0,0,1-.62-.22L8.63,21H2a1,1,0,0,1-1-1V8A1,1,0,0,1,2,7H8.63l4.75-3.78a1,1,0,0,1,1-.12A1,1,0,0,1,15,4V24a1,1,0,0,1-.57.9A.91.91,0,0,1,14,25ZM3,19H9a1,1,0,0,1,.62.22L13,21.93V6.07L9.6,8.78A1,1,0,0,1,9,9H3Z" />
			<path
				d="M22,23a1,1,0,0,1-.66-.25,1,1,0,0,1-.09-1.41,11,11,0,0,0,0-14.62,1,1,0,1,1,1.49-1.33,13,13,0,0,1,0,17.28A1,1,0,0,1,22,23Z" />
			<path
				d="M18.78,19.78a1,1,0,0,1-.64-.23A1,1,0,0,1,18,18.14a6.51,6.51,0,0,0,0-8.28,1,1,0,0,1,.13-1.4,1,1,0,0,1,1.41.13,8.5,8.5,0,0,1,0,10.82A1,1,0,0,1,18.78,19.78Z" />
		</symbol>


		<symbol id="audit">
			<rect x="7.5" y="3" width="6" height="2" />
			<path
				d="M14.5,6.49h-8A.49.49,0,0,1,6,6V2a.49.49,0,0,1,.49-.49h8A.49.49,0,0,1,15,2V6A.49.49,0,0,1,14.5,6.49ZM7,5.51h7v-3H7Z" />
			<path
				d="M26.7,25.3l-4.85-4.86A8,8,0,0,0,19.5,7.81V5.87A2.88,2.88,0,0,0,16.63,3H15.5V5h1.13a.87.87,0,0,1,.87.87V7.15a8.36,8.36,0,0,0-1-.13,7.92,7.92,0,0,0-4.34,1H5A.5.5,0,0,0,5,9h5.72a8.12,8.12,0,0,0-1.78,2.25H5a.5.5,0,0,0,0,1H8.49A8,8,0,0,0,8,14.5H5a.5.5,0,0,0,0,1H8a8.05,8.05,0,0,0,.47,2.25H5a.5.5,0,0,0,0,1H9A7.91,7.91,0,0,0,10.73,21H5a.5.5,0,0,0,0,1h7.15a7.78,7.78,0,0,0,3.37,1A3.85,3.85,0,0,0,16,23a8.2,8.2,0,0,0,1.5-.16v.29a.87.87,0,0,1-.87.87H4.37a.87.87,0,0,1-.87-.87V5.87A.87.87,0,0,1,4.37,5H5.5V3H4.37A2.88,2.88,0,0,0,1.5,5.87V23.13A2.88,2.88,0,0,0,4.37,26H16.63a2.88,2.88,0,0,0,2.87-2.87v-.95a8.13,8.13,0,0,0,.82-.45l5,5a1,1,0,0,0,1.4,0A1,1,0,0,0,26.7,25.3ZM15.64,21a6,6,0,0,1-4.83-3H20a1,1,0,0,0,0-2H10.09A5.59,5.59,0,0,1,10,14.64a4.48,4.48,0,0,1,.08-.64H20a1,1,0,0,0,0-2H10.81A6,6,0,0,1,16,9h.36a6,6,0,1,1-.72,12Z" />
		</symbol>

		<symbol id="barcode">
			<rect x="2" y="5" width="2" height="18" />
			<rect x="5" y="5" width="1" height="15" />
			<rect x="7" y="5" width="1" height="15" />
			<rect x="9" y="5" width="2" height="15" />
			<rect x="12" y="5" width="1" height="15" />
			<rect x="14" y="5" width="1" height="15" />
			<rect x="16" y="5" width="2" height="15" />
			<rect x="19" y="5" width="1" height="15" />
			<rect x="21" y="5" width="2" height="15" />
			<rect x="23.9" y="5" width="2.19" height="18" />
		</symbol>

		<symbol id="backspace">
			<path
				d="M26,4H9a1,1,0,0,0-.75.34l-7.5,8.5a1,1,0,0,0,0,1.28l7.5,9.5A1,1,0,0,0,9,24H26a1,1,0,0,0,1-1V5A1,1,0,0,0,26,4ZM25,22H9.48L2.8,13.54,9.45,6H25Z" />
			<path
				d="M12.29,18.71a1,1,0,0,0,1.42,0L17,15.41l3.29,3.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L18.41,14l3.3-3.29a1,1,0,0,0-1.42-1.42L17,12.59l-3.29-3.3a1,1,0,0,0-1.42,1.42L15.59,14l-3.3,3.29A1,1,0,0,0,12.29,18.71Z" />
		</symbol>

		<symbol id="bell">
			<path d="M14,28a4,4,0,0,1-4-4,.5.5,0,0,1,1,0,3,3,0,0,0,6,0,.5.5,0,0,1,1,0A4,4,0,0,1,14,28Z" />
			<path
				d="M25,23H24V13a10,10,0,0,0-7.5-9.67V2.5a2.5,2.5,0,0,0-5,0v.83A10,10,0,0,0,4,13V23H3a1,1,0,0,0,0,2h7.14a4,4,0,0,0,7.72,0h4.91a.55.55,0,0,0,.46,0H25a1,1,0,0,0,0-2ZM12.5,2.5a1.5,1.5,0,0,1,3,0v.62A10.59,10.59,0,0,0,14,3a10.59,10.59,0,0,0-1.5.12ZM14,27a3,3,0,0,1-2.82-2h5.64A3,3,0,0,1,14,27Zm8-4H6V13a8,8,0,0,1,16,0Z" />
		</symbol>

		<symbol id="big_empty">
			<path d="M14,26A12,12,0,1,1,26,14,12,12,0,0,1,14,26ZM14,3A11,11,0,1,0,25,14,11,11,0,0,0,14,3Z" />
			<path
				d="M14.71,20.29A1,1,0,0,1,15,21a1,1,0,0,1-1,1,1,1,0,0,1-.71-.29A1,1,0,0,1,13,21a.94.94,0,0,1,.28-.7A1,1,0,0,1,14,20,1,1,0,0,1,14.71,20.29Z" />
			<path
				d="M14.87,5.3H13.13a.45.45,0,0,0-.45.48l.62,11.8a.45.45,0,0,0,.45.42h.5a.45.45,0,0,0,.45-.42l.62-11.8A.45.45,0,0,0,14.87,5.3Z" />
		</symbol>

		<symbol id="bold">
			<path
				d="M18.81,15.45c.49,.63,.74,1.36,.74,2.17,0,1.17-.41,2.1-1.23,2.79s-1.96,1.03-3.43,1.03h-6.54V7.4h6.32c1.43,0,2.54,.33,3.35,.98,.81,.65,1.21,1.54,1.21,2.66,0,.83-.22,1.51-.65,2.06s-1.01,.93-1.73,1.14c.81,.17,1.47,.58,1.96,1.21Zm-7.04-2.37h2.24c.56,0,.99-.12,1.29-.37,.3-.25,.45-.61,.45-1.09s-.15-.85-.45-1.1c-.3-.25-.73-.38-1.29-.38h-2.24v2.94Zm3.85,5.21c.31-.26,.47-.64,.47-1.13s-.16-.88-.49-1.16c-.33-.28-.78-.42-1.35-.42h-2.48v3.1h2.52c.57,0,1.02-.13,1.33-.39Z" />
		</symbol>

		<symbol id="bool">
			<circle cx="9" cy="14" r="5" />
			<path d="M19,22H9A8,8,0,0,1,9,6H19a8,8,0,0,1,0,16ZM9,8A6,6,0,0,0,9,20H19A6,6,0,0,0,19,8Z" />
		</symbol>

		<symbol id="bulletList">
			<path d="M25,6H11c-.55,0-1-.45-1-1s.45-1,1-1h14c.55,0,1,.45,1,1s-.45,1-1,1Z" />
			<path d="M25,15H11c-.55,0-1-.45-1-1s.45-1,1-1h14c.55,0,1,.45,1,1s-.45,1-1,1Z" />
			<path d="M25,24H11c-.55,0-1-.45-1-1s.45-1,1-1h14c.55,0,1,.45,1,1s-.45,1-1,1Z" />
			<circle cx="5" cy="5" r="2" />
			<circle cx="5" cy="14" r="2" />
			<circle cx="5" cy="23" r="2" />
		</symbol>

		<symbol id="calculations">
			<path
				d="M10.5,0h-8A2.5,2.5,0,0,0,0,2.5v8A2.5,2.5,0,0,0,2.5,13h8A2.5,2.5,0,0,0,13,10.5v-8A2.5,2.5,0,0,0,10.5,0ZM12,10.5A1.5,1.5,0,0,1,10.5,12h-8A1.5,1.5,0,0,1,1,10.5v-8A1.5,1.5,0,0,1,2.5,1h8A1.5,1.5,0,0,1,12,2.5Z" />
			<path
				d="M10.5,15h-8A2.5,2.5,0,0,0,0,17.5v8A2.5,2.5,0,0,0,2.5,28h8A2.5,2.5,0,0,0,13,25.5v-8A2.5,2.5,0,0,0,10.5,15ZM12,25.5A1.5,1.5,0,0,1,10.5,27h-8A1.5,1.5,0,0,1,1,25.5v-8A1.5,1.5,0,0,1,2.5,16h8A1.5,1.5,0,0,1,12,17.5Z" />
			<path
				d="M17.5,13h8A2.5,2.5,0,0,0,28,10.5v-8A2.5,2.5,0,0,0,25.5,0h-8A2.5,2.5,0,0,0,15,2.5v8A2.5,2.5,0,0,0,17.5,13ZM16,2.5A1.51,1.51,0,0,1,17.5,1h8A1.5,1.5,0,0,1,27,2.5v8A1.5,1.5,0,0,1,25.5,12h-8A1.5,1.5,0,0,1,16,10.5Z" />
			<path
				d="M25.54,14.87h-8a2.6,2.6,0,0,0-2.6,2.6v8a2.6,2.6,0,0,0,2.6,2.6h8a2.61,2.61,0,0,0,2.6-2.6v-8A2.61,2.61,0,0,0,25.54,14.87Zm1.4,10.6a1.4,1.4,0,0,1-1.4,1.4h-8a1.4,1.4,0,0,1-1.4-1.4v-8a1.4,1.4,0,0,1,1.4-1.4h8a1.4,1.4,0,0,1,1.4,1.4Z" />
			<polygon points="7 3 6 3 6 6 3 6 3 7 6 7 6 10 7 10 7 7 10 7 10 6 7 6 7 3" />
			<rect x="18" y="6" width="7" height="1" />
			<rect x="18.04" y="19.45" width="7" height="1.04" />
			<rect x="18.04" y="22.45" width="7" height="1.04" />
			<rect x="3" y="20.94" width="7" height="1" />
			<circle cx="6.5" cy="19.44" r="1" />
			<circle cx="6.5" cy="23.44" r="1" />
		</symbol>

		<symbol id="camera">
			<path
				d="M14,8.5A6.5,6.5,0,1,0,20.5,15,6.51,6.51,0,0,0,14,8.5Zm0,11A4.5,4.5,0,1,1,18.5,15,4.51,4.51,0,0,1,14,19.5Z" />
			<path
				d="M24,5h-.5a1,1,0,0,0,0-2h-5a1,1,0,0,0,0,2H4A3,3,0,0,0,1,8V22a3,3,0,0,0,3,3H24a3,3,0,0,0,3-3V8A3,3,0,0,0,24,5Zm1,17a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V8A1,1,0,0,1,4,7H24a1,1,0,0,1,1,1Z" />
		</symbol>

		<symbol id="chat">
			<path d="M24.3,9H22v2h2.3c0.4,0,0.7,0.3,0.7,0.7v7.6c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0,0,0h-0.9c-0.3,0-0.5,0.1-0.7,0.3
				c-0.2,0.2-0.3,0.4-0.3,0.7v2.2l-2.3-2.9c-0.2-0.2-0.5-0.4-0.8-0.4h-6.7c-0.4,0-0.7-0.3-0.7-0.7c0,0,0,0,0,0V17h-1.1L10,18.1v1.1
				c0,1.5,1.2,2.7,2.6,2.7c0,0,0,0,0,0h6.2l3.9,4.7c0.2,0.2,0.5,0.4,0.8,0.4c0.1,0,0.2,0,0.4-0.1c0.4-0.2,0.7-0.6,0.6-1l-0.1-4
				c1.5-0.1,2.6-1.3,2.6-2.7v-7.6C27,10.2,25.8,9,24.3,9z" />
			<path d="M5,22c-0.1,0-0.2,0-0.3-0.1C4.3,21.8,4,21.4,4,21l0.1-5H4c-1.7,0-3-1.3-3-3V4c0-1.7,1.3-3,3-3h14c1.7,0,3,1.3,3,3v9
				c0,1.7-1.3,3-3,3h-7.5l-4.7,5.6C5.6,21.9,5.3,22,5,22z M4,3C3.4,3,3,3.4,3,4v9c0,0.6,0.4,1,1,1h1.1c0.3,0,0.5,0.1,0.7,0.3
				C6,14.5,6.1,14.8,6.1,15v3.2l3.2-3.8C9.5,14.1,9.7,14,10,14h8c0.6,0,1-0.4,1-1V4c0-0.6-0.4-1-1-1H4z" />
		</symbol>

		<symbol id="change">
			<path
				d="M3.29,9.71l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L6.41,10H23a1,1,0,0,0,0-2H6.41l3.3-3.29A1,1,0,1,0,8.29,3.29l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76A1,1,0,0,0,3.29,9.71Z" />
			<path
				d="M24.92,19.38a1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L21.59,18H5a1,1,0,0,0,0,2H21.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5A1,1,0,0,0,24.92,19.38Z" />
		</symbol>

		<symbol id="checkbox">
			<path
				d="M22.51,26h-17A3.5,3.5,0,0,1,2,22.51v-17A3.5,3.5,0,0,1,5.49,2h17A3.5,3.5,0,0,1,26,5.49v17A3.5,3.5,0,0,1,22.51,26ZM5.49,4A1.49,1.49,0,0,0,4,5.49v17A1.49,1.49,0,0,0,5.49,24h17A1.49,1.49,0,0,0,24,22.51v-17A1.49,1.49,0,0,0,22.51,4Z" />
		</symbol>

		<symbol id="checkboxTick">
			<path
				d="M22.51,26h-17A3.5,3.5,0,0,1,2,22.51v-17A3.5,3.5,0,0,1,5.49,2h17A3.5,3.5,0,0,1,26,5.49v17A3.5,3.5,0,0,1,22.51,26ZM10,23.21a1,1,0,0,0,.73-.4L21.91,7.91a1,1,0,0,0-.2-1.4,1,1,0,0,0-1.4.19l-10.53,14L6.46,17.37a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.14,4.14a1,1,0,0,0,.7.29Z" />
		</symbol>

		<symbol id="checkboxCross">
			<path
				d="M22.51,26h-17A3.5,3.5,0,0,1,2,22.51v-17A3.5,3.5,0,0,1,5.49,2h17A3.5,3.5,0,0,1,26,5.49v17A3.5,3.5,0,0,1,22.51,26Zm-7.1-12,6.8-6.79a1,1,0,1,0-1.42-1.42L14,12.59,7.21,5.79A1,1,0,0,0,5.79,7.21L12.59,14l-6.8,6.79a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L14,15.41l6.79,6.8a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
		</symbol>

		<symbol id="close">
			<path
				d="M15.41,14l6.8-6.79a1,1,0,1,0-1.42-1.42L14,12.59,7.21,5.79A1,1,0,0,0,5.79,7.21L12.59,14l-6.8,6.79a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L14,15.41l6.79,6.8a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
		</symbol>

		<symbol id="close_button">
			<path d="M14,0A14,14,0,1,0,28,14,14,14,0,0,0,14,0Zm0,26A12,12,0,1,1,26,14,12,12,0,0,1,14,26Z" />
			<path
				d="M21.21,6.79a1,1,0,0,0-1.42,0L14,12.59,8.21,6.79A1,1,0,1,0,6.79,8.21L12.59,14l-5.8,5.79a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L14,15.41l5.79,5.8a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L15.41,14l5.8-5.79A1,1,0,0,0,21.21,6.79Z" />
		</symbol>

		<symbol id="code">
			<path
				d="M13,23s-.08,0-.12,0c-.55-.07-.94-.57-.87-1.12L14.01,5.88c.07-.55,.57-.93,1.12-.87,.55,.07,.94,.57,.87,1.12l-2,16c-.06,.51-.49,.88-.99,.88Z" />
			<path
				d="M18,21c-.26,0-.51-.1-.71-.29-.39-.39-.39-1.02,0-1.41l5.29-5.29-5.29-5.29c-.39-.39-.39-1.02,0-1.41s1.02-.39,1.41,0l6,6c.39,.39,.39,1.02,0,1.41l-6,6c-.2,.2-.45,.29-.71,.29Z" />
			<path
				d="M10,21c-.26,0-.51-.1-.71-.29L3.29,14.71c-.39-.39-.39-1.02,0-1.41l6-6c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41l-5.29,5.29,5.29,5.29c.39,.39,.39,1.02,0,1.41-.2,.2-.45,.29-.71,.29Z" />
		</symbol>

		<symbol id="codeBlock">
			<path
				d="M7,11c-.05,0-.1,0-.16-.01-.55-.09-.92-.6-.83-1.14L7.26,1.85c.08-.54,.6-.92,1.14-.83,.55,.09,.92,.6,.83,1.14l-1.25,8c-.08,.49-.5,.85-.99,.85Z" />
			<path
				d="M4,10c-.26,0-.51-.1-.71-.29L.29,6.71c-.39-.39-.39-1.02,0-1.41L3.29,2.29c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41l-2.29,2.29,2.29,2.29c.39,.39,.39,1.02,0,1.41-.2,.2-.45,.29-.71,.29Z" />
			<path
				d="M11,10c-.26,0-.51-.1-.71-.29-.39-.39-.39-1.02,0-1.41l2.29-2.29-2.29-2.29c-.39-.39-.39-1.02,0-1.41s1.02-.39,1.41,0l3,3c.39,.39,.39,1.02,0,1.41l-3,3c-.2,.2-.45,.29-.71,.29Z" />
			<path
				d="M22.79,25H5.21c-1.77,0-3.21-1.42-3.21-3.17V12h2v9.83c0,.65,.54,1.17,1.21,1.17H22.79c.67,0,1.21-.53,1.21-1.17V8.17c0-.65-.54-1.17-1.21-1.17h-6.79v-2h6.79c1.77,0,3.21,1.42,3.21,3.17v13.66c0,1.75-1.44,3.17-3.21,3.17Z" />
		</symbol>

		<symbol id="collapse">
			<path
				d="M23,19.5a1,1,0,0,1-.71-.29L14,10.91l-8.29,8.3a1,1,0,0,1-1.42-1.42l9-9a1,1,0,0,1,1.42,0l9,9a1,1,0,0,1,0,1.42A1,1,0,0,1,23,19.5Z" />
		</symbol>

		<symbol id="content">
			<path
				d="M7.66,10.8c.63-1.08,1.49-1.92,2.58-2.53,1.09-.61,2.28-.91,3.57-.91,1.48,0,2.8,.36,3.95,1.09s1.99,1.76,2.51,3.09h-2.74c-.36-.73-.86-1.28-1.5-1.64-.64-.36-1.38-.54-2.22-.54-.92,0-1.74,.21-2.46,.62s-1.28,1.01-1.69,1.78c-.41,.77-.61,1.67-.61,2.7s.2,1.93,.61,2.7c.41,.77,.97,1.37,1.69,1.79s1.54,.63,2.46,.63c.84,0,1.58-.18,2.22-.54,.64-.36,1.14-.91,1.5-1.64h2.74c-.52,1.33-1.36,2.36-2.51,3.08-1.15,.72-2.47,1.08-3.95,1.08-1.31,0-2.5-.3-3.58-.91s-1.94-1.45-2.57-2.53c-.63-1.08-.95-2.3-.95-3.66s.32-2.58,.95-3.66Z" />
		</symbol>

		<symbol id="copy">
			<path d="M8,23v-1h-3c-.55,0-1,.45-1,1s.45,1,1,1h4c-.55,0-1-.45-1-1Z" style="opacity:.5;" />
			<path d="M18,24v2H2V6h6v-2H1c-.55,0-1,.45-1,1V27c0,.55,.45,1,1,1H19c.55,0,1-.45,1-1v-3h-2Z"
				style="opacity:.5;" />
			<path d="M8,10h-3c-.55,0-1,.45-1,1s.45,1,1,1h3v-2Z" style="opacity:.5;" />
			<path d="M8,14h-3c-.55,0-1,.45-1,1s.45,1,1,1h3v-2Z" style="opacity:.5;" />
			<path d="M8,18h-3c-.55,0-1,.45-1,1s.45,1,1,1h3v-2Z" style="opacity:.5;" />
			<g>
				<path
					d="M27.93,7.63s-.05-.08-.07-.12c-.03-.06-.05-.12-.1-.17L21.76,.35s-.02-.01-.03-.02c-.07-.07-.15-.12-.23-.17-.03-.02-.06-.05-.1-.06-.12-.05-.26-.09-.4-.09H9c-.55,0-1,.45-1,1V23c0,.55,.45,1,1,1H27c.55,0,1-.45,1-1V8c0-.13-.03-.25-.07-.37Zm-5.93-3.93l2.83,3.3h-2.83V3.7ZM10,22V2h10v6c0,.55,.45,1,1,1h5v13H10Z" />
				<path d="M23,10H13c-.55,0-1,.45-1,1s.45,1,1,1h10c.55,0,1-.45,1-1s-.45-1-1-1Z" />
				<path d="M13,8h5c.55,0,1-.45,1-1s-.45-1-1-1h-5c-.55,0-1,.45-1,1s.45,1,1,1Z" />
				<path d="M23,18H13c-.55,0-1,.45-1,1s.45,1,1,1h10c.55,0,1-.45,1-1s-.45-1-1-1Z" />
				<path d="M23,14H13c-.55,0-1,.45-1,1s.45,1,1,1h10c.55,0,1-.45,1-1s-.45-1-1-1Z" />
			</g>
		</symbol>

		<symbol id="cost">
			<path
				d="M17.65,19.36c-.33,.59-.81,1.08-1.44,1.48-.63,.4-1.38,.64-2.25,.72v1.62h-1.3v-1.62c-1.25-.12-2.27-.51-3.04-1.18-.77-.67-1.16-1.55-1.16-2.64h2.44c.04,.52,.21,.95,.5,1.29,.29,.34,.71,.56,1.26,.65v-4.4c-.89-.23-1.62-.46-2.18-.69-.56-.23-1.04-.6-1.43-1.11s-.59-1.2-.59-2.08c0-1.11,.39-2.02,1.16-2.75,.77-.73,1.79-1.15,3.04-1.27v-1.62h1.3v1.62c1.17,.11,2.12,.48,2.83,1.11,.71,.63,1.12,1.5,1.21,2.61h-2.44c-.04-.41-.2-.78-.48-1.11-.28-.33-.65-.55-1.12-.67v4.32c.89,.21,1.62,.44,2.18,.67,.56,.23,1.03,.6,1.42,1.1,.39,.5,.58,1.19,.58,2.07,0,.67-.16,1.29-.49,1.88Zm-6.38-6.8c.33,.31,.79,.55,1.39,.74v-4.04c-.57,.07-1.03,.27-1.37,.62-.34,.35-.51,.81-.51,1.4,0,.55,.16,.97,.49,1.28Zm4.07,6.41c.33-.38,.5-.84,.5-1.37s-.16-.95-.49-1.25c-.33-.3-.79-.54-1.39-.73v4.06c.59-.09,1.05-.33,1.38-.71Z" />
		</symbol>

		<symbol id="contextual">
			<circle cx="14" cy="6" r="2" />
			<circle cx="14" cy="14" r="2" />
			<circle cx="14" cy="22" r="2" />
		</symbol>

		<symbol id="crop">
			<path d="M8,3A1,1,0,0,0,6,3V5H8Z" />
			<path d="M25,20H23v2h2a1,1,0,0,0,0-2Z" />
			<path
				d="M25.35,3.35a.49.49,0,0,0-.7-.7l-3.41,3.4A.93.93,0,0,0,21,6H3A1,1,0,0,0,3,8H19.29L8,19.29V9H6V21a1,1,0,0,0,1,1H19V20H8.71L20,8.71V25a1,1,0,0,0,2,0V7A.93.93,0,0,0,22,6.76Z" />
		</symbol>

		<symbol id="custom">
			<path
				d="M9.71,11.86l-1-1L10.1,9.4l-.71-.71L8,10.12l-1.6-1.6L7.8,7.1l-.71-.71L5.66,7.82,4.43,6.58l3.21-3.2,5.27,5.27,1.44-1.43-6-6a1,1,0,0,0-1.44,0L2.28,5.87a1,1,0,0,0,0,1.43l6,6Z" />
			<path
				d="M26.77,19.64l-6.06-6.06L19.28,15l5.34,5.34-3.2,3.21-1.24-1.23,1.43-1.43-.71-.71-1.42,1.43L17.88,20l1.43-1.42-.71-.71-1.43,1.43-1.1-1.1-1.43,1.43,6.06,6.06a1,1,0,0,0,1.43,0l4.64-4.64A1,1,0,0,0,26.77,19.64Z" />
			<path
				d="M25.76,5.71,22.22,2.17a1,1,0,0,0-.7-.29,1,1,0,0,0-.71.29L5.45,17.53a1,1,0,0,0,0,1.41L9,22.48a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29L25.76,7.12A1,1,0,0,0,25.76,5.71ZM9.7,20.36,7.57,18.23l14-13.94,2.12,2.12Z" />
			<path
				d="M5,19.71a1,1,0,0,0-1-.26,1,1,0,0,0-.71.7l-1.25,4.5a1,1,0,0,0,1,1.27.84.84,0,0,0,.27,0l4.5-1.25a1,1,0,0,0,.7-.71,1,1,0,0,0-.26-1Zm-.52,3.77.32-1.14.82.82Z" />
		</symbol>

		<symbol id="dataIn">
			<path
				d="M8.63,9.08a1,1,0,0,0-1,1V23.59L2.71,18.67a1,1,0,0,0-1.42,1.41l6.63,6.63a.93.93,0,0,0,.33.21.92.92,0,0,0,.76,0,1.15,1.15,0,0,0,.33-.21L16,20.08a1,1,0,1,0-1.42-1.41L9.63,23.59V10.08A1,1,0,0,0,8.63,9.08Z" />
			<path d="M15.28,1.08V6.82H14.21V2H13v-.9Z" />
			<path
				d="M17.58,6.55a2.37,2.37,0,0,1-.85-1A3.73,3.73,0,0,1,16.42,4a3.73,3.73,0,0,1,.31-1.59,2.37,2.37,0,0,1,.85-1A2.24,2.24,0,0,1,18.81,1a2.3,2.3,0,0,1,1.24.35,2.37,2.37,0,0,1,.85,1A3.73,3.73,0,0,1,21.21,4a3.73,3.73,0,0,1-.31,1.59,2.37,2.37,0,0,1-.85,1,2.21,2.21,0,0,1-1.24.35A2.16,2.16,0,0,1,17.58,6.55Zm2.2-1.08A2.64,2.64,0,0,0,20.14,4a2.64,2.64,0,0,0-.36-1.52,1.18,1.18,0,0,0-1.93,0A2.64,2.64,0,0,0,17.49,4a2.64,2.64,0,0,0,.36,1.52,1.17,1.17,0,0,0,1.93,0Z" />
			<path d="M23.88,1.08V6.82H22.81V2H21.6v-.9Z" />
			<path d="M27,1.08V6.82H25.93V2H24.72v-.9Z" />
			<path d="M15.28,8.42v5.73H14.21V9.31H13V8.42Z" />
			<path d="M18.4,8.42v5.73H17.34V9.31H16.12V8.42Z" />
			<path
				d="M20.7,13.89a2.34,2.34,0,0,1-.85-1,3.65,3.65,0,0,1-.31-1.58,3.67,3.67,0,0,1,.31-1.59,2.34,2.34,0,0,1,.85-1A2.26,2.26,0,0,1,24,9.7a3.66,3.66,0,0,1,.32,1.59A3.65,3.65,0,0,1,24,12.87a2.3,2.3,0,0,1-3.32,1Zm2.21-1.09a2.69,2.69,0,0,0,.35-1.51,2.71,2.71,0,0,0-.35-1.52,1.14,1.14,0,0,0-1-.51,1.12,1.12,0,0,0-1,.51,2.62,2.62,0,0,0-.36,1.52A2.61,2.61,0,0,0,21,12.8a1.12,1.12,0,0,0,1,.51A1.14,1.14,0,0,0,22.91,12.8Z" />
			<path d="M27,8.42v5.73H25.93V9.31H24.72V8.42Z" />
		</symbol>

		<symbol id="dataOut">
			<path
				d="M19.75,1.08a.92.92,0,0,0-.76,0,1.15,1.15,0,0,0-.33.21L12,7.92a1,1,0,1,0,1.42,1.41l4.92-4.92V17.92a1,1,0,1,0,2,0V4.41l4.92,4.92a1,1,0,1,0,1.42-1.41L20.08,1.29A.93.93,0,0,0,19.75,1.08Z" />
			<path d="M3.28,13.85v5.73H2.21V14.74H1v-.89Z" />
			<path
				d="M5.58,19.32a2.34,2.34,0,0,1-.85-1,3.67,3.67,0,0,1-.31-1.59,3.65,3.65,0,0,1,.31-1.58,2.34,2.34,0,0,1,.85-1,2.24,2.24,0,0,1,1.23-.35,2.3,2.3,0,0,1,1.24.35,2.34,2.34,0,0,1,.85,1,3.65,3.65,0,0,1,.31,1.58A3.67,3.67,0,0,1,8.9,18.3a2.34,2.34,0,0,1-.85,1,2.3,2.3,0,0,1-1.24.35A2.24,2.24,0,0,1,5.58,19.32Zm2.2-1.09a2.62,2.62,0,0,0,.36-1.52,2.61,2.61,0,0,0-.36-1.51,1.17,1.17,0,0,0-1.93,0,2.61,2.61,0,0,0-.36,1.51,2.62,2.62,0,0,0,.36,1.52,1.17,1.17,0,0,0,1.93,0Z" />
			<path d="M11.88,13.85v5.73H10.81V14.74H9.6v-.89Z" />
			<path d="M15,13.85v5.73H13.93V14.74H12.72v-.89Z" />
			<path d="M3.28,21.18v5.74H2.21V22.07H1v-.89Z" />
			<path d="M6.4,21.18v5.74H5.34V22.07H4.12v-.89Z" />
			<path
				d="M8.7,26.65a2.37,2.37,0,0,1-.85-1,4.23,4.23,0,0,1,0-3.18,2.37,2.37,0,0,1,.85-1,2.35,2.35,0,0,1,2.47,0,2.3,2.3,0,0,1,.85,1,4.11,4.11,0,0,1,0,3.18,2.3,2.3,0,0,1-3.32,1Zm2.21-1.08a2.73,2.73,0,0,0,.35-1.52,2.73,2.73,0,0,0-.35-1.52,1.14,1.14,0,0,0-1-.51,1.12,1.12,0,0,0-1,.51,2.64,2.64,0,0,0-.36,1.52A2.64,2.64,0,0,0,9,25.57a1.13,1.13,0,0,0,1,.5A1.16,1.16,0,0,0,10.91,25.57Z" />
			<path d="M15,21.18v5.74H13.93V22.07H12.72v-.89Z" />
		</symbol>


		<symbol id="date">
			<path
				d="M22,4H19V3a1,1,0,0,0-2,0V4H11V3A1,1,0,0,0,9,3V4H6A3,3,0,0,0,3,7V23a3,3,0,0,0,3,3H22a3,3,0,0,0,3-3V7A3,3,0,0,0,22,4Zm1,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V7A1,1,0,0,1,6,6H9V7a1,1,0,0,0,2,0V6h6V7a1,1,0,0,0,2,0V6h3a1,1,0,0,1,1,1Z" />
			<rect x="8" y="15.04" width="6" height="6" />
		</symbol>

		<symbol id="dateTime">
			<rect x="6" y="15" width="6" height="6" />
			<path
				d="M21,15v8a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V7A1,1,0,0,1,4,6H7v.93A1,1,0,0,0,9,6.93V6h4.11a7.85,7.85,0,0,1,.52-2H9V2.93A1,1,0,0,0,7,2.93V4H4A3,3,0,0,0,1,7V23a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V14.74A8.24,8.24,0,0,1,21,15Z" />
			<path d="M21,14a7,7,0,1,1,7-7A7,7,0,0,1,21,14ZM21,2a5,5,0,1,0,5,5A5,5,0,0,0,21,2Z" />
			<path
				d="M23,8.77a.43.43,0,0,1-.27-.08l-2-1.29a.46.46,0,0,1-.18-.21L19.06,4a.51.51,0,0,1,.24-.67.5.5,0,0,1,.67.24l1.44,3.1L23.3,7.85a.5.5,0,0,1,.15.69A.51.51,0,0,1,23,8.77Z" />
		</symbol>

		<symbol id="delete">
			<path
				d="M22,3H17.5V2A1.5,1.5,0,0,0,16,.5H12A1.5,1.5,0,0,0,10.5,2V3H6A2,2,0,0,0,4,5V7A2,2,0,0,0,5.51,8.93S5.5,9,5.5,9V25a2,2,0,0,0,2,2h13a2,2,0,0,0,2-2V9s0-.05,0-.07A2,2,0,0,0,24,7V5A2,2,0,0,0,22,3ZM11.5,2a.5.5,0,0,1,.5-.5h4a.5.5,0,0,1,.5.5V3h-5ZM6,5H22V7H6ZM20.5,25H7.5V9h13Z" />
			<path d="M14,24a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0V23A1,1,0,0,0,14,24Z" />
			<path d="M18,24a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0V23A1,1,0,0,0,18,24Z" />
			<path d="M10,24a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0V23A1,1,0,0,0,10,24Z" />
		</symbol>

		<symbol id="download">
			<path
				d="M22,12V23a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V7A1,1,0,0,1,5,6H16V4H5A3,3,0,0,0,2,7V23a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V12Z" />
			<path d="M25.71,2.29a1,1,0,0,0-1.42,0L12,14.58V9H10v9h9V16H13.42L25.71,3.71A1,1,0,0,0,25.71,2.29Z" />
		</symbol>

		<symbol id="edit">
			<path
				d="M25.76,5.71,22.22,2.17a1,1,0,0,0-1.41,0L5.45,17.53a1,1,0,0,0,0,1.41L9,22.48a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29L25.76,7.12A1,1,0,0,0,25.76,5.71ZM9.7,20.36,7.57,18.23l14-13.94,2.12,2.12Z" />
			<path
				d="M5,19.71a1,1,0,0,0-1-.26,1,1,0,0,0-.71.7l-1.25,4.5a1,1,0,0,0,1,1.27.84.84,0,0,0,.27,0l4.5-1.25a1,1,0,0,0,.7-.71,1,1,0,0,0-.26-1Z" />
		</symbol>

		<symbol id="earth">
			<path
				d="M14,1.8a12,12,0,1,0,12,12A12,12,0,0,0,14,1.8ZM6,17.31l.39,0,.36-.72,0-.78a20.83,20.83,0,0,0,1.85-2c.4.16.69.29.87.38l1.61.16a21.91,21.91,0,0,0,2.49,1.7l-.22-1.22.92,0,.48-.92-.39-2.14.44-1.18.56.26.18-1.4,1.35-.35.61-1.65,1.27-.36,1.66.44.34-.59a9.33,9.33,0,0,1,3.06,6.92A9.2,9.2,0,0,1,21,20.68a9.65,9.65,0,0,1-7,2.82,9.62,9.62,0,0,1-6.94-2.81,9.21,9.21,0,0,1-2.92-6.87v0l.56,0-.05.78Z" />
			<polygon points="17.34 22.19 17.54 22.11 17.74 21.67 17.05 21.7 17.34 22.19" />
			<path
				d="M19.92,21l.34-.79c.53-.36,1-.7,1.54-1l.33-.75-.36.27-.31-.47a4.22,4.22,0,0,0-.63,1.12l-.73.33a3.57,3.57,0,0,0-.74,1.16l.25.37Z" />
			<path d="M16.7,10.77l-.3-.17a7.07,7.07,0,0,0-1.08,2.13l.26-.05A13.58,13.58,0,0,0,16.7,10.77Z" />
			<path
				d="M13.69,20.77l1.7-.09,1.79.74.53,0a4.47,4.47,0,0,0,1.44-2.27,6.74,6.74,0,0,1-1.32-2.27L17.41,18l-.76-.62a4,4,0,0,1-2.57.44,3.51,3.51,0,0,0-.87,1.57C13.33,20.08,13.35,20.57,13.69,20.77Z" />
			<polygon points="17.5 9.95 17.27 9.6 16.96 9.54 16.52 10.29 16.96 10.29 17.5 9.95" />
		</symbol>

		<symbol id="error">
			<path
				d="M19.39,1H8.61L1,8.61v10.77l7.61,7.61h10.77l7.61-7.61V8.61L19.39,1Zm5.61,17.56l-6.44,6.44H9.44l-6.44-6.44V9.44L9.44,3h9.12l6.44,6.44v9.11Z" />
			<path
				d="M13.99,19.5c-1.12,0-1.99,.89-1.99,1.98s.86,2.01,1.99,2.01,2.01-.89,2.01-2.01-.86-1.98-2.01-1.98Z" />
			<polygon points="12.67 18 15.33 18 16 5 12 5 12.67 18" />
		</symbol>

		<symbol id="errorSolid">
			<path
				d="M27,8.61v10.77l-7.61,7.61H8.61L1,19.39V8.61L8.61,1h10.77l7.61,7.61Zm-13.01,10.89c-1.12,0-1.99,.89-1.99,1.98s.86,2.01,1.99,2.01,2.01-.89,2.01-2.01-.86-1.98-2.01-1.98Zm2.01-14.5h-4l.67,13h2.67l.67-13Z" />
		</symbol>

		<symbol id="expand">
			<polygon points="7.5 8.38 14 19.63 20.5 8.38 7.5 8.38" />
		</symbol>

		<symbol id="expression">
			<circle cx="11" cy="20" r="2" />
			<path
				d="M17.21,5.22h1.61l0,3.35,2.92-1.71.79,1.49L19.55,10l2.95,1.65-.79,1.49-2.92-1.71,0,3.35H17.21l0-3.39-2.92,1.72-.82-1.46L16.45,10,13.5,8.35l.82-1.52,2.92,1.74Z" />
		</symbol>

		<symbol id="file">
			<path
				d="M23.93,10.63a.83.83,0,0,0-.08-.12.59.59,0,0,0-.1-.17l-7-8s0,0,0,0a1,1,0,0,0-.24-.17.47.47,0,0,0-.09-.06A.9.9,0,0,0,16,2H5A1,1,0,0,0,4,3V25a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1V11A1.16,1.16,0,0,0,23.93,10.63ZM17,5.66,20.8,10H17ZM6,24V4h9v7a1,1,0,0,0,1,1h6V24Z" />
		</symbol>

		<symbol id="fileList">
			<path
				d="M25.71,10.29l-5-5A1,1,0,0,0,20,5H18.41l-3.7-3.71A1,1,0,0,0,14,1H3A1,1,0,0,0,2,2V22a1,1,0,0,0,1,1H8v3a1,1,0,0,0,1,1H25a1,1,0,0,0,1-1V11A1,1,0,0,0,25.71,10.29ZM20.94,11V8.35L23.61,11ZM4,21V3h9.59l2,2H9A1,1,0,0,0,8,6H6A.5.5,0,0,0,6,7H8V9H6a.5.5,0,0,0,0,1H8v2H6a.5.5,0,0,0,0,1H8v2H6a.5.5,0,0,0,0,1H8v2H6a.5.5,0,0,0,0,1H8v2Zm6,4V7h8.94v3H12a.5.5,0,0,0,0,1h6.94v1a1,1,0,0,0,.87,1H12a.5.5,0,0,0,0,1H22a.5.5,0,0,0,.5-.5.48.48,0,0,0-.37-.47H24V25Z" />
			<path d="M22,22H12a.5.5,0,0,0,0,1H22a.5.5,0,0,0,0-1Z" />
			<path d="M22,19H12a.5.5,0,0,0,0,1H22a.5.5,0,0,0,0-1Z" />
			<path d="M22,16H12a.5.5,0,0,0,0,1H22a.5.5,0,0,0,0-1Z" />
		</symbol>

		<symbol id="filter">
			<path
				d="M5.5,8h6.61A3.11,3.11,0,0,0,18,8H22.5a1,1,0,0,0,0-2H18a3.1,3.1,0,0,0-5.93,0H5.5a1,1,0,0,0,0,2ZM15,5.06a1.88,1.88,0,1,1-1.88,1.88A1.88,1.88,0,0,1,15,5.06Z" />
			<path
				d="M22.5,13.48H12a3.1,3.1,0,0,0-5.92,0H5.5a1,1,0,0,0,0,2h.61a3.1,3.1,0,0,0,5.86,0H22.5a1,1,0,0,0,0-2ZM9,16.31a1.88,1.88,0,1,1,1.87-1.87A1.88,1.88,0,0,1,9,16.31Z" />
			<path
				d="M22.5,21H20a3.11,3.11,0,0,0-5.87,0H5.5a1,1,0,0,0,0,2h8.58A3.1,3.1,0,0,0,20,23h2.5a1,1,0,0,0,0-2ZM17,23.92A1.88,1.88,0,1,1,18.91,22,1.88,1.88,0,0,1,17,23.92Z" />
		</symbol>

		<symbol id="generatePassword">
			<path
				d="M26.12,19.29a1,1,0,0,0-1.36.4A12,12,0,1,1,13.65,2,11.81,11.81,0,0,1,23.2,6.07l-3.81,0h0a1,1,0,0,0-1,1,1,1,0,0,0,1,1l5.74,0h0a1,1,0,0,0,1-1l0-5.75a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1l0,2.82A14,14,0,0,0,.24,14.67,14,14,0,0,0,14.21,28l.68,0a13.87,13.87,0,0,0,11.63-7.33A1,1,0,0,0,26.12,19.29Z" />
			<path
				d="M8,13.94v5a3,3,0,0,0,3,3h6a3,3,0,0,0,3-3v-5a3,3,0,0,0-2-2.82S18,11,18,11v-.5a4,4,0,1,0-8,0V11s0,.08,0,.12A3,3,0,0,0,8,13.94Zm4.59-4.85A2,2,0,0,1,14,8.5a2,2,0,0,1,2,2v.44H12V10.5A2,2,0,0,1,12.59,9.09ZM10,13.94a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v5a1,1,0,0,1-1,1H11a1,1,0,0,1-1-1Z" />
			<path d="M15.8,19.5l-1.07-3.2a1.5,1.5,0,1,0-1.46,0L12.2,19.5Z" />
		</symbol>

		<symbol id="geoLocation">
			<path
				d="M27.47,26.75l-3.13-12a1,1,0,0,0-1-.75h-9A12.38,12.38,0,0,0,16,8.41,8.32,8.32,0,0,0,8,0,8.32,8.32,0,0,0,0,8.41c0,2.76,1.53,5.72,3.19,8.13L.53,26.75a1,1,0,0,0,.18.86A1,1,0,0,0,1.5,28h25a1,1,0,0,0,1-1.25ZM22.6,16l2.14,8.19L12.13,17.47c.34-.47.69-.95,1-1.47Zm1.27,10H12.7L17,22.33ZM8,2a6.38,6.38,0,0,1,6,6.41c0,4-4.29,9.05-6,10.91C6.28,17.48,2,12.48,2,8.41A6.38,6.38,0,0,1,8,2ZM4.72,18.6A36,36,0,0,0,7.3,21.48a1,1,0,0,0,.7.28,1,1,0,0,0,.7-.28c.1-.1,1-1,2.18-2.41l4.21,2.25L9.63,26H2.79Z" />
			<path
				d="M12.5,8.38A4.5,4.5,0,1,0,8,12.88,4.51,4.51,0,0,0,12.5,8.38Zm-7,0A2.5,2.5,0,1,1,8,10.88,2.5,2.5,0,0,1,5.5,8.38Z" />
		</symbol>

		<symbol id="groupRepeat">
			<path d="M21.5,12.5H6.5c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h15c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5Z" />
			<path d="M21.5,16.5H6.5c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h15c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5Z" />
			<path d="M21.5,20.5H6.5c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h15c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5Z" />
			<g>
				<path
					d="M24,13.03v9.97H4V9.01h14V5.01H4.01c-1.11,0-2.01,.89-2.01,2v15.98c0,1.11,.9,2.01,2.01,2.01H23.99c1.11,0,2.01-.9,2.01-2.01V13.03h-2Z" />
				<path
					d="M27,6.01h-2.5V3.51c0-.55-.45-1-1-1s-1,.45-1,1v2.5h-2.5c-.55,0-1,.45-1,1s.45,1,1,1h2.5v2.5c0,.55,.45,1,1,1s1-.45,1-1v-2.5h2.5c.55,0,1-.45,1-1s-.45-1-1-1Z" />
			</g>
		</symbol>

		<symbol id="hamburger">
			<g>
				<path
					d="M24.4,22.6H3.6c-0.7,0-1.3-0.6-1.3-1.3S2.9,20,3.6,20h20.7c0.7,0,1.3,0.6,1.3,1.3S25.1,22.6,24.4,22.6z" />
			</g>
			<g>
				<path
					d="M24.4,15.3H3.7c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3h20.6c0.7,0,1.3,0.6,1.3,1.3S25.1,15.3,24.4,15.3z" />
			</g>
			<g>
				<path d="M24.4,8H3.6C2.9,8,2.3,7.4,2.3,6.7s0.6-1.3,1.3-1.3h20.7c0.7,0,1.3,0.6,1.3,1.3S25.1,8,24.4,8z" />
			</g>
		</symbol>

		<symbol id="hierarchy">
			<g>
				<path d="M4,10.8c0.6,0,1-0.4,1-1V9.2h8v0.5c0,0.6,0.4,1,1,1s1-0.4,1-1V8.6c0-0.7-0.7-1.3-1.5-1.3H10V6.5c0-0.6-0.4-1-1-1
					s-1,0.4-1,1v0.8H4.5C3.7,7.2,3,7.8,3,8.6v1.2C3,10.3,3.4,10.8,4,10.8z" />
				<path d="M15,18.1c0-0.6-0.4-1-1-1s-1,0.4-1,1v1.6c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3v1.2c0,0.6,0.4,1,1,1s1-0.4,1-1v-0.5h8v0.5
					c0,0.6,0.4,1,1,1s1-0.4,1-1v-1.2c0-0.7-0.7-1.3-1.5-1.3H15V18.1z" />
				<path
					d="M7.3,5h3.4c0.4,0,0.8-0.3,0.8-0.8V0.8c0-0.4-0.3-0.8-0.8-0.8H7.3C6.8,0,6.5,0.3,6.5,0.8v3.4C6.5,4.7,6.8,5,7.3,5z" />
				<path d="M5.7,11.5H2.3c-0.4,0-0.8,0.3-0.8,0.8v3.4c0,0.4,0.3,0.8,0.8,0.8h3.4c0.4,0,0.8-0.3,0.8-0.8v-3.4
					C6.5,11.8,6.2,11.5,5.7,11.5z" />
				<path d="M11.5,12.3v3.4c0,0.4,0.3,0.8,0.8,0.8h3.4c0.4,0,0.8-0.3,0.8-0.8v-3.4c0-0.4-0.3-0.8-0.8-0.8h-3.4
					C11.8,11.5,11.5,11.8,11.5,12.3z" />
				<path d="M15.7,23h-3.4c-0.4,0-0.8,0.3-0.8,0.8v3.4c0,0.4,0.3,0.8,0.8,0.8h3.4c0.4,0,0.8-0.3,0.8-0.8v-3.4
					C16.5,23.3,16.2,23,15.7,23z" />
				<path d="M25.7,23h-3.4c-0.4,0-0.8,0.3-0.8,0.8v3.4c0,0.4,0.3,0.8,0.8,0.8h3.4c0.4,0,0.8-0.3,0.8-0.8v-3.4
					C26.5,23.3,26.2,23,25.7,23z" />
			</g>
		</symbol>
		<symbol id="folder">
			<path
				d="m23,5.97h-8.58s-.95-1.84-.95-1.84c-.34-.65-1.01-1.06-1.76-1.06h-6.53c-.75,0-1.42.41-1.75,1.04l-1.28,2.36c-.08.14-.12.3-.12.47v15.01c0,1.64,1.33,2.98,2.98,2.98h18c1.64,0,2.98-1.34,2.98-2.98v-13c0-1.64-1.33-2.98-2.98-2.98Zm-11.27-.94l.95,1.83s.05.06.06.09H4.11l1.06-1.92h6.56Zm12.29,16.92c0,.56-.46,1.02-1.02,1.02H5c-.56,0-1.02-.46-1.02-1.02V7.95h12.02v-.03h7s0,0,0,0c.56,0,1.02.46,1.02,1.02v13Z" />
		</symbol>

		<symbol id="imageList">
			<path
				d="M22.71,2H5A2.94,2.94,0,0,0,2,5V22.71A3.28,3.28,0,0,0,5.29,26H22.71A3.28,3.28,0,0,0,26,22.71V5.29A3.28,3.28,0,0,0,22.71,2ZM5,4H22.71A1.31,1.31,0,0,1,24,5.29V17.22l-3.44-3.64a.39.39,0,0,0-.37-.15.5.5,0,0,0-.37.17L17,16.89,9.12,8.17A.48.48,0,0,0,8.75,8a.5.5,0,0,0-.37.17L4,13.05V5A1,1,0,0,1,5,4ZM4,22.71V14.55l4.77-5.3,7.55,8.4L10.72,24H5.29A1.31,1.31,0,0,1,4,22.71ZM22.71,24H12.05l8.18-9.35,3.79,4v4A1.31,1.31,0,0,1,22.71,24Z" />
			<path d="M16.86,14a3,3,0,1,0-3-3A3,3,0,0,0,16.86,14Zm0-5a2,2,0,1,1-2,2A2,2,0,0,1,16.86,9Z" />
		</symbol>

		<symbol id="info">
			<path
				d="M14,.5C6.56,.5,.5,6.56,.5,14s6.06,13.5,13.5,13.5,13.5-6.06,13.5-13.5S21.44,.5,14,.5Zm0,25c-6.34,0-11.5-5.16-11.5-11.5S7.66,2.5,14,2.5s11.5,5.16,11.5,11.5-5.16,11.5-11.5,11.5Z" />
			<rect x="12.5" y="10.5" width="3" height="13" />
			<circle cx="14" cy="7" r="2" />
		</symbol>

		<symbol id="infoSolid">
			<path
				d="M27.5,14c0,7.44-6.06,13.5-13.5,13.5S.5,21.44,.5,14,6.56,.5,14,.5s13.5,6.06,13.5,13.5Zm-12-3.5h-3v13h3V10.5Zm-3.5-3.5c0,1.1,.9,2,2,2s2-.9,2-2-.9-2-2-2-2,.9-2,2Z" />
		</symbol>

		<symbol id="italic">
			<polygon
				points="17.06 9.17 17.37 7.4 13.69 7.4 13.38 9.17 14.09 9.17 12.24 19.58 11.52 19.58 11.21 21.35 11.93 21.35 11.93 21.35 14.21 21.35 14.21 21.35 14.89 21.35 15.2 19.58 14.52 19.58 16.37 9.17 17.06 9.17" />
		</symbol>

		<symbol id="link">
			<path
				d="M24.1,6.41l-2.52-2.52c-.97-.97-2.56-.97-3.54,0l-5.27,5.27c.75,0,1.5,.23,2.13,.7l4.56-4.56c.1-.1,.22-.14,.35-.14s.26,.05,.35,.14l2.52,2.52c.19,.19,.19,.52,0,.71l-7.15,7.16c-.19,.19-.52,.19-.71,0l-2.16-2.16-1.41,1.41,2.16,2.16c.49,.49,1.13,.73,1.77,.73s1.28-.24,1.77-.73l7.15-7.16c.97-.97,.97-2.56,0-3.54Z" />
			<path
				d="M5.31,19.47l7.15-7.16c.19-.19,.52-.19,.71,0l2.15,2.15,1.42-1.41-2.16-2.16c-.97-.97-2.56-.97-3.54,0l-7.15,7.16c-.97,.97-.97,2.56,0,3.53h0l2.52,2.52c.49,.49,1.13,.73,1.77,.73s1.28-.24,1.77-.73l5.27-5.27s-.03,0-.04,0c-.74,0-1.47-.24-2.09-.71l-4.56,4.56c-.19,.19-.52,.19-.71,0l-2.52-2.52h0c-.19-.19-.19-.52,0-.71Z" />
		</symbol>

		<symbol id="linkOpen">
			<path
				d="M20.94,3.25l-2.52-2.52c-.97-.97-2.56-.97-3.54,0l-5.27,5.27c.75,0,1.5,.23,2.13,.7l4.56-4.56c.1-.1,.22-.14,.35-.14s.26,.05,.35,.14l2.52,2.52c.19,.19,.19,.52,0,.71l-7.15,7.16c-.19,.19-.52,.19-.71,0l-2.16-2.16-1.41,1.41,2.16,2.16c.49,.49,1.13,.73,1.77,.73s1.28-.24,1.77-.73l7.15-7.16c.97-.97,.97-2.56,0-3.54Z" />
			<path
				d="M2.14,16.3l7.15-7.16c.19-.19,.52-.19,.71,0l2.15,2.15,1.42-1.41-2.16-2.16c-.97-.97-2.56-.97-3.54,0L.73,14.89c-.97,.97-.97,2.56,0,3.53h0l2.52,2.52c.49,.49,1.13,.73,1.77,.73s1.28-.24,1.77-.73l5.27-5.27s-.03,0-.04,0c-.74,0-1.47-.24-2.09-.71l-4.56,4.56c-.19,.19-.52,.19-.71,0l-2.52-2.52h0c-.19-.19-.19-.52,0-.71Z" />
			<path
				d="M26.71,13.79c-.21-.21-.49-.3-.76-.28h-7.95c-.55-.01-1,.44-1,.99s.45,1,1,1h5.58l-10.29,10.29c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l10.29-10.29v5.58c0,.55,.46,1,1.01,1h0c.55,0,1-.45,1-1v-7.94c0-.27-.08-.55-.29-.76Z" />
		</symbol>

		<symbol id="lock">
			<path
				d="M21,9V8A7,7,0,0,0,7,8V9a3,3,0,0,0-3,3V24a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V12A3,3,0,0,0,21,9ZM9,8A5,5,0,0,1,19,8V9H9ZM22,24a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H21a1,1,0,0,1,1,1Z" />
			<path d="M16.5,15.5a2.5,2.5,0,1,0-3.72,2.17L11,23h6l-1.78-5.33A2.5,2.5,0,0,0,16.5,15.5Z" />
		</symbol>

		<symbol id="login">
			<path
				d="M14,22.75A8.74,8.74,0,0,1,9.48,6.52a1,1,0,1,1,1,1.71A6.69,6.69,0,0,0,7.25,14a6.75,6.75,0,0,0,13.5,0,6.69,6.69,0,0,0-3.27-5.77,1,1,0,1,1,1-1.71A8.74,8.74,0,0,1,14,22.75Z" />
			<path d="M14,14a1,1,0,0,1-1-1V5a1,1,0,0,1,2,0v8A1,1,0,0,1,14,14Z" />
		</symbol>

		<symbol id="lookup">
			<path
				d="M26.5,6H20A8.48,8.48,0,0,0,7,6H1.5a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H6.3a8.48,8.48,0,0,0,11.88,2.59l6.61,6.62a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42l-6.51-6.5a8.25,8.25,0,0,0,1-1.29h5.8a1,1,0,0,0,1-1V7A1,1,0,0,0,26.5,6Zm-24,8V8H5.76A8.52,8.52,0,0,0,5,11.5,8.27,8.27,0,0,0,5.38,14ZM7,11.5A6.5,6.5,0,1,1,13.5,18,6.51,6.51,0,0,1,7,11.5ZM25.5,14H21.62A8.27,8.27,0,0,0,22,11.5,8.52,8.52,0,0,0,21.24,8H25.5Z" />
		</symbol>

		<symbol id="mail">
			<path
				d="M23.1,5.63H5.1a3,3,0,0,0-3,3v12a3,3,0,0,0,3,3h18a3,3,0,0,0,3-3v-12A3,3,0,0,0,23.1,5.63Zm-.36,2L15,16.05a1.35,1.35,0,0,1-1,.45h0a1.37,1.37,0,0,1-1-.45L5.2,7.63Zm1.36,13a1,1,0,0,1-1,1H5.1a1,1,0,0,1-1-1v-12a1,1,0,0,1,.17-.54l8,8.64A2.36,2.36,0,0,0,14,17.5h0a2.36,2.36,0,0,0,1.73-.77l8.12-8.8a1,1,0,0,1,.28.7Z" />
		</symbol>

		<symbol id="minus">
			<path d="M3,15a1,1,0,0,1,0-2H25a1,1,0,0,1,0,2Z" />
		</symbol>

		<symbol id="more">
			<circle cx="6" cy="14" r="2" />
			<circle cx="14" cy="14" r="2" />
			<circle cx="22" cy="14" r="2" />
		</symbol>

		<symbol id="moreInfo">
			<path d="M14,24A10,10,0,1,1,24,14,10,10,0,0,1,14,24ZM14,6a8,8,0,1,0,8,8A8,8,0,0,0,14,6Z" />
			<path
				d="M12.5,20a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L16.09,14l-4.3-4.29a1,1,0,0,1,1.42-1.42l5,5a1,1,0,0,1,0,1.42l-5,5A1,1,0,0,1,12.5,20Z" />
		</symbol>

		<symbol id="moreInfoSolid">
			<path
				d="M12.08,21.39a1,1,0,0,1-.7-1.71L17.06,14,11.38,8.32a1,1,0,0,1,0-1.42,1,1,0,0,1,1.41,0l6.39,6.39a1,1,0,0,1,0,1.42L12.79,21.1A1,1,0,0,1,12.08,21.39ZM26.5,14A12.5,12.5,0,1,0,14,26.5,12.52,12.52,0,0,0,26.5,14Z" />
		</symbol>

		<symbol id="multiChoice">
			<path
				d="M6.5,18h-5A1.5,1.5,0,0,1,0,16.5v-5A1.5,1.5,0,0,1,1.5,10h5A1.5,1.5,0,0,1,8,11.5v5A1.5,1.5,0,0,1,6.5,18Zm-5-7a.5.5,0,0,0-.5.5v5a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-5a.5.5,0,0,0-.5-.5Z" />
			<path d="M25,6H11a1,1,0,0,1,0-2H25a1,1,0,0,1,0,2Z" />
			<path d="M25,15H11a1,1,0,0,1,0-2H25a1,1,0,0,1,0,2Z" />
			<path d="M25,24H11a1,1,0,0,1,0-2H25a1,1,0,0,1,0,2Z" />
			<path
				d="M6.84,8.93H1.16A1.16,1.16,0,0,1,0,7.77V2.1A1.17,1.17,0,0,1,1.16.93H6.84A1.17,1.17,0,0,1,8,2.1V7.77A1.16,1.16,0,0,1,6.84,8.93ZM2.65,8a.28.28,0,0,0,.24-.13l3.75-5a.32.32,0,0,0-.07-.46.34.34,0,0,0-.47.06L2.59,7.16l-1.1-1.1a.34.34,0,0,0-.48,0,.33.33,0,0,0,0,.47L2.39,7.91A.34.34,0,0,0,2.63,8Z" />
			<path
				d="M6.84,27H1.16A1.16,1.16,0,0,1,0,25.84V20.16A1.16,1.16,0,0,1,1.16,19H6.84A1.16,1.16,0,0,1,8,20.16v5.68A1.16,1.16,0,0,1,6.84,27Zm-4.19-.93a.34.34,0,0,0,.24-.13l3.75-5a.34.34,0,0,0-.54-.4L2.59,25.23l-1.1-1.11a.34.34,0,0,0-.48,0,.33.33,0,0,0,0,.47L2.39,26a.35.35,0,0,0,.24.1Z" />
		</symbol>

		<symbol id="multiText">
			<path
				d="M10.13,18.6H4.32l-1,2.84H.94L5.92,7.52h2.64l4.98,13.92h-2.4l-1-2.84Zm-.64-1.86l-2.26-6.46-2.28,6.46h4.54Z" />
			<path
				d="M15.12,12.94c.46-.85,1.09-1.52,1.88-1.99,.79-.47,1.67-.71,2.63-.71,.87,0,1.62,.17,2.27,.51,.65,.34,1.16,.76,1.55,1.27v-1.6h2.3v11.02h-2.3v-1.64c-.39,.52-.91,.95-1.58,1.3s-1.43,.52-2.28,.52c-.95,0-1.81-.24-2.6-.73-.79-.49-1.41-1.17-1.87-2.04s-.69-1.86-.69-2.97,.23-2.09,.69-2.94Zm7.86,1c-.31-.56-.72-.99-1.23-1.28-.51-.29-1.05-.44-1.64-.44s-1.13,.14-1.64,.43c-.51,.29-.92,.71-1.23,1.26-.31,.55-.47,1.21-.47,1.97s.16,1.43,.47,2,.73,1.01,1.24,1.31c.51,.3,1.06,.45,1.63,.45s1.13-.15,1.64-.44c.51-.29,.92-.72,1.23-1.29,.31-.57,.47-1.23,.47-1.99s-.16-1.42-.47-1.98Z" />
		</symbol>

		<symbol id="myLocation">
			<path
				d="M27,13H23.45A9.53,9.53,0,0,0,15,4.55V1a1,1,0,0,0-2,0V4.55A9.53,9.53,0,0,0,4.55,13H1a1,1,0,0,0,0,2H4.55A9.53,9.53,0,0,0,13,23.45V27a1,1,0,0,0,2,0V23.45A9.53,9.53,0,0,0,23.45,15H27a1,1,0,0,0,0-2ZM15,21.43V19a1,1,0,0,0-2,0v2.43A7.51,7.51,0,0,1,6.57,15H9a1,1,0,0,0,0-2H6.57A7.51,7.51,0,0,1,13,6.57V9a1,1,0,0,0,2,0V6.57A7.51,7.51,0,0,1,21.43,13H19a1,1,0,0,0,0,2h2.43A7.51,7.51,0,0,1,15,21.43Z" />
		</symbol>

		<symbol id="notes">
			<path d="M15.6,10H6.4a.51.51,0,0,0-.5.5.5.5,0,0,0,.5.5h9.2a.5.5,0,0,0,.5-.5A.51.51,0,0,0,15.6,10Z" />
			<path d="M16.1,14.16a.5.5,0,0,0-.5-.5H6.4a.5.5,0,0,0,0,1h9.2A.5.5,0,0,0,16.1,14.16Z" />
			<path d="M12.9,17.84a.5.5,0,0,0-.5-.5h-6a.5.5,0,0,0,0,1h6A.5.5,0,0,0,12.9,17.84Z" />
			<path d="M6.4,21a.5.5,0,0,0-.5.5.51.51,0,0,0,.5.5H9.83a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5Z" />
			<path
				d="M18.31,21.7a1.07,1.07,0,0,1-.3-.06v1.42a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V6.94A1,1,0,0,1,5,6h.45V6.5a1,1,0,0,0,2.1,0V6H10V6.5a1.05,1.05,0,1,0,2.1,0V6h2.4V6.5a1.05,1.05,0,1,0,2.1,0V6H17a1,1,0,0,1,1,1v8.63l2-2V6.94A3,3,0,0,0,17,4h-.45V3.5a1.05,1.05,0,0,0-2.1,0V4h-2.4V3.5a1,1,0,0,0-2.1,0V4H7.55V3.5a1,1,0,0,0-2.1,0V4H5A3,3,0,0,0,2,6.94V23.06A3,3,0,0,0,5,26H17a3,3,0,0,0,3-2.93V20.43l-1,1A1,1,0,0,1,18.31,21.7Z" />
			<path
				d="M27.35,11.65l-2-2a.5.5,0,0,0-.71,0L16,18.34a.5.5,0,0,0,0,.71l2,2a.53.53,0,0,0,.36.15.51.51,0,0,0,.35-.15l8.69-8.69A.5.5,0,0,0,27.35,11.65Zm-9,8.34L17,18.69l8-8L26.29,12Z" />
			<path
				d="M15.11,19.45a.49.49,0,0,0-.36.35L14,22.34a.49.49,0,0,0,.13.49.47.47,0,0,0,.35.15l.14,0,2.54-.71a.49.49,0,0,0,.35-.36.48.48,0,0,0-.13-.48l-1.83-1.83A.48.48,0,0,0,15.11,19.45Z" />
		</symbol>

		<symbol id="number">
			<path d="M.04,9.33v-2H4.2v14H1.9V9.33H.04Z" />
			<path
				d="M8.6,18.36c1.28-1.06,2.29-1.94,3.03-2.62s1.36-1.4,1.85-2.14,.74-1.48,.74-2.23-.19-1.37-.57-1.8c-.38-.43-.98-.65-1.79-.65s-1.4,.24-1.83,.72c-.43,.48-.66,1.12-.69,1.93h-2.2c.04-1.46,.5-2.58,1.37-3.35,.87-.77,1.98-1.16,3.33-1.16,1.45,0,2.59,.38,3.41,1.15,.82,.77,1.23,1.79,1.23,3.07,0,.92-.24,1.81-.73,2.66-.49,.85-1.07,1.61-1.74,2.29-.67,.67-1.53,1.45-2.57,2.33l-.9,.77h6.34v1.82H7.16v-1.59l1.44-1.19Z" />
			<path
				d="M19.77,8.01c.87-.67,1.99-1,3.35-1,.93,0,1.74,.16,2.42,.48,.68,.32,1.19,.75,1.54,1.31,.35,.55,.52,1.17,.52,1.86,0,.79-.22,1.47-.66,2.04-.44,.56-.97,.94-1.58,1.13v.08c.79,.23,1.4,.65,1.84,1.26,.44,.61,.66,1.39,.66,2.33,0,.75-.18,1.43-.54,2.02-.36,.59-.89,1.05-1.6,1.39-.71,.34-1.55,.51-2.54,.51-1.44,0-2.62-.36-3.55-1.07-.93-.71-1.42-1.73-1.49-3.06h2.2c.05,.68,.33,1.23,.82,1.66,.49,.43,1.16,.64,2,.64s1.44-.21,1.88-.64c.44-.43,.66-.98,.66-1.66,0-.9-.3-1.53-.89-1.91-.59-.38-1.5-.57-2.73-.57h-.52v-1.81h.54c1.08-.01,1.9-.18,2.47-.52,.57-.33,.85-.86,.85-1.58,0-.61-.21-1.1-.62-1.47-.41-.36-1-.55-1.76-.55s-1.33,.18-1.74,.55c-.41,.37-.66,.82-.74,1.35h-2.22c.08-1.19,.56-2.12,1.43-2.78Z" />
		</symbol>

		<symbol id="numberList">
			<path d="M3.98,2.59V1.55h1.93V7.39h-1.15V2.59h-.78Z" />
			<path
				d="M3.59,15.14c.51-.43,.92-.78,1.22-1.07,.3-.29,.56-.58,.76-.89,.2-.31,.3-.61,.3-.91,0-.27-.06-.48-.19-.64s-.33-.23-.59-.23-.47,.09-.62,.27c-.14,.18-.22,.42-.22,.73h-1.09c.02-.64,.21-1.13,.57-1.46,.36-.33,.82-.5,1.37-.5,.61,0,1.08,.16,1.4,.48s.49,.75,.49,1.28c0,.42-.11,.81-.34,1.19-.22,.38-.48,.71-.77,.99s-.66,.62-1.13,1.01h2.36v.93H3.17v-.83l.42-.35Z" />
			<path
				d="M3.72,19.76c.35-.29,.81-.44,1.38-.44,.39,0,.72,.07,1,.2,.28,.14,.49,.32,.63,.55,.14,.23,.21,.49,.21,.79,0,.34-.09,.62-.26,.85-.17,.23-.38,.39-.62,.47v.03c.31,.1,.55,.27,.72,.51,.17,.25,.26,.56,.26,.94,0,.32-.07,.61-.22,.86-.15,.25-.36,.45-.65,.59-.29,.14-.63,.21-1.03,.21-.6,0-1.09-.15-1.47-.46s-.58-.75-.6-1.34h1.09c.01,.26,.1,.47,.27,.63s.4,.24,.69,.24c.27,0,.48-.08,.63-.23s.22-.35,.22-.59c0-.32-.1-.55-.3-.69-.2-.14-.52-.21-.94-.21h-.23v-.92h.23c.76,0,1.14-.25,1.14-.76,0-.23-.07-.41-.2-.54-.14-.13-.33-.19-.59-.19s-.44,.07-.58,.2c-.14,.14-.21,.31-.24,.52h-1.1c.03-.53,.21-.95,.56-1.24Z" />
			<path d="M25,6H11c-.55,0-1-.45-1-1s.45-1,1-1h14c.55,0,1,.45,1,1s-.45,1-1,1Z" />
			<path d="M25,15H11c-.55,0-1-.45-1-1s.45-1,1-1h14c.55,0,1,.45,1,1s-.45,1-1,1Z" />
			<path d="M25,24H11c-.55,0-1-.45-1-1s.45-1,1-1h14c.55,0,1,.45,1,1s-.45,1-1,1Z" />
		</symbol>

		<symbol id="offline">
			<circle cx="14" cy="20.37" r="2" />
			<path d="M18,7.19,19.57,5.6a16.82,16.82,0,0,0-17.47,4A1,1,0,0,0,3.51,11,14.85,14.85,0,0,1,18,7.19Z" />
			<path
				d="M25.9,9.56a16.76,16.76,0,0,0-2.55-2.09L21.9,8.92A15.25,15.25,0,0,1,24.49,11a1,1,0,0,0,1.41,0A1,1,0,0,0,25.9,9.56Z" />
			<path
				d="M19.92,10.9l-1.48,1.48a10.11,10.11,0,0,1,2.71,1.93,1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42A12.26,12.26,0,0,0,19.92,10.9Z" />
			<path
				d="M5.43,14.31a1,1,0,0,0,1.42,0,10,10,0,0,1,7-3l1.88-1.88A12.11,12.11,0,0,0,5.43,12.89,1,1,0,0,0,5.43,14.31Z" />
			<path
				d="M16.11,14.72l-1.72,1.72a5,5,0,0,1,3.19,1.44,1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29,1,1,0,0,0,0-1.41A6.94,6.94,0,0,0,16.11,14.72Z" />
			<path
				d="M24.71,3.29a1,1,0,0,0-1.42,0l-20,20a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l20-20A1,1,0,0,0,24.71,3.29Z" />
		</symbol>

		<symbol id="open">
			<path
				d="M22.79,3H5.21c-1.77,0-3.21,1.42-3.21,3.17v5.77H4v-3.44H24v11.33c0,.65-.54,1.17-1.21,1.17h-6.79v2h6.79c1.77,0,3.21-1.42,3.21-3.17V6.17c0-1.75-1.44-3.17-3.21-3.17ZM4,7.5v-1.33c0-.65,.54-1.17,1.21-1.17H22.79c.67,0,1.21,.53,1.21,1.17v1.33H4Z" />
			<path
				d="M13.38,14.08c-.12-.05-.25-.08-.38-.08H5.5c-.55,0-1,.45-1,1s.45,1,1,1h5.09L.29,26.29c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l10.29-10.29v5.09c0,.55,.45,1,1,1s1-.45,1-1v-7.5c0-.13-.03-.26-.08-.38-.1-.24-.3-.44-.54-.54Z" />
		</symbol>

		<symbol id="pages">
			<path
				d="M16.74,10.41A5.46,5.46,0,0,0,14.52,10h-4v9.8H12.3V17h2.22a5.46,5.46,0,0,0,2.22-.42,3.14,3.14,0,0,0,1.44-1.21,3.34,3.34,0,0,0,.51-1.85,3.38,3.38,0,0,0-.51-1.87A3.18,3.18,0,0,0,16.74,10.41Zm-.5,4.51a2.83,2.83,0,0,1-1.81.5H12.3V11.53h2.13a2.83,2.83,0,0,1,1.81.5,1.77,1.77,0,0,1,.61,1.45A1.76,1.76,0,0,1,16.24,14.92Z" />
			<path
				d="M21,2H7A3,3,0,0,0,4,5V23a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V5A3,3,0,0,0,21,2ZM7,4H21a1,1,0,0,1,1,1v.41H6V5A1,1,0,0,1,7,4ZM21,24H7a1,1,0,0,1-1-1V6.41H22V23A1,1,0,0,1,21,24Z" />
		</symbol>

		<symbol id="paste">
			<rect x="7" y="2" width="6" height="2" />
			<path
				d="M27,13.78V13a1,1,0,0,0-.36-.75L21.78,8.4A1,1,0,0,0,21,8a1.43,1.43,0,0,0-.22,0H19V4.87A2.88,2.88,0,0,0,16.13,2H15V4h1.13a.87.87,0,0,1,.87.87V8H11a1,1,0,0,0-1,1V23H3.87A.87.87,0,0,1,3,22.13V4.87A.87.87,0,0,1,3.87,4H5V2H3.87A2.88,2.88,0,0,0,1,4.87V22.13A2.88,2.88,0,0,0,3.87,25H10v2a1,1,0,0,0,1,1H26a1,1,0,0,0,1-1V14.22A1.43,1.43,0,0,0,27,14,1.43,1.43,0,0,0,27,13.78ZM22,11l2.47,2H22Zm3,15H12V10h8v4a1,1,0,0,0,1,1h4Z" />
			<path d="M6,5.5h8a.5.5,0,0,0,.5-.5V1A.5.5,0,0,0,14,.5H6a.5.5,0,0,0-.5.5V5A.5.5,0,0,0,6,5.5Zm.5-4h7v3h-7Z" />
			<path d="M23,23H14a1,1,0,0,0,0,2h9a1,1,0,0,0,0-2Z" />
			<path d="M23,19.5H14a1,1,0,0,0,0,2h9a1,1,0,0,0,0-2Z" />
			<path d="M13,17a1,1,0,0,0,1,1h9a1,1,0,0,0,0-2H14A1,1,0,0,0,13,17Z" />
			<path d="M14,14.5h4a1,1,0,0,0,0-2H14a1,1,0,0,0,0,2Z" />
			<path
				d="M24.79,6.5H22.25a.5.5,0,0,0,0,1H26a.41.41,0,0,0,.19,0,.51.51,0,0,0,.27-.27.41.41,0,0,0,0-.19V3.25a.5.5,0,0,0-1,0V5.79L21.35,1.65a.49.49,0,0,0-.7.7Z" />
		</symbol>

		<symbol id="pdf">
			<path
				d="M8.79,16.81H6.87V22.4h1V20.76h1a3,3,0,0,0,1.83-.47,1.82,1.82,0,0,0,.59-1.49,1.86,1.86,0,0,0-.58-1.51A2.92,2.92,0,0,0,8.79,16.81ZM10,19.6a1.47,1.47,0,0,1-1.07.3H7.82V17.66h1a2,2,0,0,1,1.12.24.91.91,0,0,1,.36.82A1.22,1.22,0,0,1,10,19.6Z" />
			<path
				d="M13.66,16.81H11.74V22.4H13.6a3.35,3.35,0,0,0,2.4-.76,2.77,2.77,0,0,0,.78-2.07,2.62,2.62,0,0,0-.8-2A3.33,3.33,0,0,0,13.66,16.81ZM15.29,21a2.27,2.27,0,0,1-1.55.48H12.69V17.68h.94c1.47,0,2.2.64,2.2,1.92A1.83,1.83,0,0,1,15.29,21Z" />
			<polygon
				points="17.4 22.4 18.34 22.4 18.34 20.09 20.82 20.09 20.82 19.22 18.34 19.22 18.34 17.69 21.12 17.69 21.13 16.81 17.4 16.81 17.4 22.4" />
			<path
				d="M23.93,10.63a.5.5,0,0,0-.08-.12.59.59,0,0,0-.1-.17l-7-8s0,0,0,0a1.5,1.5,0,0,0-.24-.18l-.09-.05A.9.9,0,0,0,16,2H5A1,1,0,0,0,4,3V25a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1V11A1.16,1.16,0,0,0,23.93,10.63ZM17,5.66,20.8,10H17ZM6,24V4h9v7a1,1,0,0,0,1,1h6V24Z" />
		</symbol>

		<symbol id="print">
			<circle cx="23" cy="12" r="1" />
			<path
				d="M25,7H23V3a2,2,0,0,0-2-2H7A2,2,0,0,0,5,3V7H3A2,2,0,0,0,1,9V20a2,2,0,0,0,2,2H5v2.5a2,2,0,0,0,2,2H21a2,2,0,0,0,2-2V22h2a2,2,0,0,0,2-2V9A2,2,0,0,0,25,7ZM7,3H21V7H7ZM21,24.5H7v-9H21ZM23,20V15.5a2,2,0,0,0-2-2H7a2,2,0,0,0-2,2V20H3V9H25V20Z" />
			<path d="M9,19H19a.5.5,0,0,0,0-1H9a.5.5,0,0,0,0,1Z" />
			<path d="M9,21H19a.5.5,0,0,0,0-1H9a.5.5,0,0,0,0,1Z" />
			<path d="M9,23h6a.5.5,0,0,0,0-1H9a.5.5,0,0,0,0,1Z" />
		</symbol>

		<symbol id="privacyPolicy">
			<path
				d="M23.93,10.63a.5.5,0,0,0-.08-.12.59.59,0,0,0-.1-.17l-7-8s0,0,0,0a1,1,0,0,0-.24-.17.47.47,0,0,0-.09-.06A.9.9,0,0,0,16,2H5A1,1,0,0,0,4,3V25a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1V11A1.16,1.16,0,0,0,23.93,10.63ZM17,5.66,20.8,10H17ZM15,11a1,1,0,0,0,1,1h6V24H6V4h9V6H8.5a1,1,0,0,0,0,2H15v3Z" />
			<path d="M19,11H12a1,1,0,0,0,0,2h7a1,1,0,0,0,0-2Z" />
			<path d="M19,15H12a1,1,0,0,0,0,2h7a1,1,0,0,0,0-2Z" />
			<path d="M19,19H12a1,1,0,0,0,0,2h7a1,1,0,0,0,0-2Z" />
			<circle cx="8.5" cy="12" r="1" />
			<circle cx="8.5" cy="16" r="1" />
			<circle cx="8.5" cy="20" r="1" />
		</symbol>

		<symbol id="query">
			<path
				d="M17.3,4.13a4.61,4.61,0,0,1,1.93,1.7,4.26,4.26,0,0,1,.65,2.26,4.47,4.47,0,0,1-.4,2,4.32,4.32,0,0,1-1,1.31A16.17,16.17,0,0,1,17,12.54a10,10,0,0,0-1.86,1.58,2.86,2.86,0,0,0-.61,1.93v1.32H11.86V15.9a5,5,0,0,1,.43-2.15,4.42,4.42,0,0,1,1-1.41,14.6,14.6,0,0,1,1.56-1.21A8.47,8.47,0,0,0,16.52,9.8a2.21,2.21,0,0,0,.53-1.53,2.31,2.31,0,0,0-.8-1.87,3.3,3.3,0,0,0-2.18-.66A5.17,5.17,0,0,0,10,7.86L8.12,6.42A7.71,7.71,0,0,1,14.25,3.5,6.86,6.86,0,0,1,17.3,4.13ZM14.75,21a2,2,0,0,1,.59,1.44,2,2,0,0,1-.59,1.46,2.08,2.08,0,0,1-2.92,0,2,2,0,0,1-.58-1.46,2,2,0,0,1,2-2A2,2,0,0,1,14.75,21Z" />
		</symbol>

		<symbol id="quote">
			<path
				d="M19.23,23.24c-.16,0-.33-.05-.47-.16l-2.96-2.34c-.16-.13-.27-.32-.28-.53-.02-.21,.06-.42,.2-.57,1.62-1.73,2.65-3.52,3.06-5.32h-2.52c-.45,.17-1.01-.2-1.01-.71V5.51c0-.41,.34-.75,.75-.75h8c.41,0,.75,.34,.75,.75v5.66c0,2.29-.39,4.4-1.16,6.25-.74,1.95-2.04,3.84-3.84,5.6-.15,.14-.33,.21-.52,.21Zm-1.87-3.19l1.81,1.43c1.4-1.46,2.42-3.01,3.03-4.61,.7-1.7,1.06-3.61,1.06-5.7V6.26h-6.5v6.54h2.92c.21,0,.42,.09,.56,.25,.14,.16,.21,.37,.18,.59-.26,2.2-1.29,4.35-3.06,6.4Z" />
			<path
				d="M7.23,23.24c-.16,0-.33-.05-.47-.16l-2.96-2.34c-.16-.13-.27-.32-.28-.53-.02-.21,.06-.42,.2-.57,1.62-1.73,2.65-3.52,3.06-5.32h-2.52c-.45,.17-1.01-.2-1.01-.71V5.51c0-.41,.34-.75,.75-.75H12c.41,0,.75,.34,.75,.75v5.66c0,2.29-.39,4.4-1.16,6.25-.74,1.95-2.04,3.84-3.84,5.6-.15,.14-.33,.21-.52,.21Zm-1.87-3.19l1.81,1.43c1.4-1.46,2.42-3.01,3.03-4.61,.7-1.7,1.06-3.61,1.06-5.7V6.26H4.75v6.54h2.92c.21,0,.42,.09,.56,.25,.14,.16,.21,.37,.18,.59-.26,2.2-1.29,4.35-3.06,6.4Z" />
		</symbol>

		<symbol id="radioCircle">
			<path d="M14,28A14,14,0,1,1,28,14,14,14,0,0,1,14,28ZM14,2A12,12,0,1,0,26,14,12,12,0,0,0,14,2Z" />
		</symbol>

		<symbol id="radioSolid">
			<circle cx="14" cy="14" r="14" />
		</symbol>

		<symbol id="radioCross">
			<path
				d="M28,14A14,14,0,1,1,14,0,14,14,0,0,1,28,14ZM15.41,14l6.8-6.79a1,1,0,1,0-1.42-1.42L14,12.59,7.21,5.79A1,1,0,0,0,5.79,7.21L12.59,14l-6.8,6.79a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L14,15.41l6.79,6.8a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
		</symbol>

		<symbol id="radioDot">
			<circle cx="14" cy="14" r="9" />
		</symbol>

		<symbol id="radioTick">
			<path
				d="M28,14c0,7.72-6.28,14-14,14S0,21.72,0,14,6.28,0,14,0s14,6.28,14,14ZM9.87,22.5c.44-.03.85-.25,1.11-.61l10.23-14c.49-.67.34-1.61-.33-2.1-.67-.49-1.61-.34-2.1.33l-9.18,12.56-2.53-2.61c-.58-.59-1.53-.61-2.12-.03-.59.58-.61,1.53-.03,2.12l3.77,3.88c.28.29.67.46,1.08.46.03,0,.07,0,.1,0Z" />
		</symbol>

		<symbol id="refreshApp">
			<path
				d="M21.94,15.37a1,1,0,0,0-1.25.66,6.93,6.93,0,0,1-5,4.75A7,7,0,0,1,8,10.42a7,7,0,0,1,9.32-2.57l-1.24.24a1,1,0,0,0,.19,2l.2,0,3.47-.69a1,1,0,0,0,.79-1.17L20,4.73a1,1,0,1,0-2,.39l.19,1a9,9,0,0,0-13,10.12A9,9,0,0,0,14,23a8.73,8.73,0,0,0,2.2-.28,8.91,8.91,0,0,0,6.39-6.1A1,1,0,0,0,21.94,15.37Z" />
		</symbol>

		<symbol id="save">
			<path
				d="M23,6.9644 L23,22.8484 C23,23.5012402 22.5496197,24 22.057,24 L5.943,24 C5.45038027,24 5,23.5012402 5,22.8484 L5,5.1516 C5,4.49875978 5.45038027,4 5.943,4 L20.25,4 L19.5235217,3.6871894 L23.2735217,7.6515894 L23,6.9644 L23,6.9644 Z M25,6.9644 L25,6.56637044 L24.7264783,6.2772106 L20.9764783,2.3128106 L20.6805849,2 L20.25,2 L5.943,2 C4.2977241,2 3,3.43712464 3,5.1516 L3,22.8484 C3,24.5628754 4.2977241,26 5.943,26 L22.057,26 C23.7022759,26 25,24.5628754 25,22.8484 L25,6.9644 L25,6.9644 Z" />
			<path
				d="M19.5,4.0052 L19.5,10.1952 C19.5,10.2680636 19.3840953,10.3662 19.2071342,10.3662 L8.79286583,10.3662 C8.61590469,10.3662 8.5,10.2680636 8.5,10.1952 L8.5,4.0052 L7.5,4.0052 L7.5,10.1952 C7.5,10.8606549 8.0970779,11.3662 8.79286583,11.3662 L19.2071342,11.3662 C19.9029221,11.3662 20.5,10.8606549 20.5,10.1952 L20.5,4.0052 L19.5,4.0052 L19.5,4.0052 Z" />
			<path
				d="M20.5,23.9975 L20.5,15.0655 C20.5,14.4353065 20.0363409,13.8925 19.4251339,13.8925 L8.57486611,13.8925 C7.96365905,13.8925 7.5,14.4353065 7.5,15.0655 L7.5,23.9975 L8.5,23.9975 L8.5,15.0655 C8.5,14.9556967 8.55398185,14.8925 8.57486611,14.8925 L19.4251339,14.8925 C19.4460182,14.8925 19.5,14.9556967 19.5,15.0655 L19.5,23.9975 L20.5,23.9975 L20.5,23.9975 Z" />
		</symbol>

		<symbol id="search">
			<path
				d="M25.78,24.22l-7.69-7.69a9,9,0,1,0-1.56,1.56l7.69,7.69a1.11,1.11,0,0,0,1.56,0A1.1,1.1,0,0,0,25.78,24.22ZM4,11a7,7,0,1,1,7,7A7,7,0,0,1,4,11Z" />
		</symbol>

		<symbol id="section">
			<path d="M24,4H4A2,2,0,0,0,2,6V22a2,2,0,0,0,2,2H24a2,2,0,0,0,2-2V6A2,2,0,0,0,24,4ZM4,22V8H24V22Z" />
			<path d="M21.5,10.5H6.5a.5.5,0,0,0,0,1h15a.5.5,0,0,0,0-1Z" />
		</symbol>

		<symbol id="settings">
			<path
				d="M25.74,16.42l-2.35-1.35a10.9,10.9,0,0,0,.07-1.14,8.55,8.55,0,0,0-.06-1l2.34-1.34a1,1,0,0,0,.36-1.37l-2.77-4.8A1,1,0,0,0,22,5.05L19.68,6.37a9.38,9.38,0,0,0-1.91-1.12V2.62a1,1,0,0,0-1-1H11.23a1,1,0,0,0-1,1V5.25A9.38,9.38,0,0,0,8.32,6.37L6,5.05a1,1,0,0,0-1.36.36L1.9,10.21a1,1,0,0,0,.36,1.37L4.6,12.92a8.55,8.55,0,0,0-.06,1,10.9,10.9,0,0,0,.07,1.14L2.26,16.42a1,1,0,0,0-.36,1.37l2.77,4.8A1,1,0,0,0,6,23l2.4-1.38a8.92,8.92,0,0,0,1.8,1v2.78a1,1,0,0,0,1,1h5.54a1,1,0,0,0,1-1V22.6a8.92,8.92,0,0,0,1.8-1L22,23a1,1,0,0,0,1.36-.36l2.77-4.8A1,1,0,0,0,25.74,16.42Zm-3.64,4.3L20,19.5a1,1,0,0,0-1.15.1A7.22,7.22,0,0,1,16.44,21a1,1,0,0,0-.67,1v2.46H12.23V21.92a1,1,0,0,0-.67-1A7.22,7.22,0,0,1,9.17,19.6,1,1,0,0,0,8,19.5L5.9,20.72,4.13,17.65l2.08-1.19a1,1,0,0,0,.48-1.07,7.16,7.16,0,0,1-.15-1.46,6.86,6.86,0,0,1,.13-1.35,1,1,0,0,0-.49-1.05l-2-1.18L5.9,7.28l2,1.16a1,1,0,0,0,1.16-.11,7.3,7.3,0,0,1,2.48-1.45,1,1,0,0,0,.67-.94V3.62h3.54V5.94a1,1,0,0,0,.67.94,7.3,7.3,0,0,1,2.48,1.45,1,1,0,0,0,1.16.11l2-1.16,1.77,3.07-2.05,1.18a1,1,0,0,0-.49,1.05,6.86,6.86,0,0,1,.13,1.35,7.16,7.16,0,0,1-.15,1.46,1,1,0,0,0,.48,1.07l2.08,1.19Z" />
			<path
				d="M14,8.92A5.08,5.08,0,1,0,19.08,14,5.09,5.09,0,0,0,14,8.92Zm0,8.16A3.08,3.08,0,1,1,17.08,14,3.08,3.08,0,0,1,14,17.08Z" />
		</symbol>

		<symbol id="separator">
			<path d="M22,15H6a1,1,0,0,1,0-2H22a1,1,0,0,1,0,2Z" />
			<path d="M24,11.5H4a2,2,0,0,0-2,2v2H26v-2A2,2,0,0,0,24,11.5Z" />
		</symbol>

		<symbol id="signature">
			<path
				d="M7.77,23.75a6.19,6.19,0,0,1-1-.07A5.08,5.08,0,0,1,2.85,21a4.79,4.79,0,0,1,.39-4.32,11,11,0,0,1,2.91-3.07c.53-.41,1-.78,1.54-1.11A13.92,13.92,0,0,1,7,7.9,4.68,4.68,0,0,1,7.39,6,2.55,2.55,0,0,1,9,4.57a3.08,3.08,0,0,1,1.36,0,6.31,6.31,0,0,1,4.76,4.23.5.5,0,0,1-1,.3,5.3,5.3,0,0,0-4-3.55,2.22,2.22,0,0,0-.92,0,1.57,1.57,0,0,0-1,.88A3.6,3.6,0,0,0,8,7.94a12.72,12.72,0,0,0,.58,4A15.44,15.44,0,0,1,11,10.74a.5.5,0,0,1,.51.08.51.51,0,0,1,.17.48,9.34,9.34,0,0,0-.2,1.89,16.22,16.22,0,0,0,1.48-3.67.5.5,0,1,1,1,.25,11.85,11.85,0,0,0-.34,3.84,9.76,9.76,0,0,0,1.12-2.38.5.5,0,0,1,1,.19,13.07,13.07,0,0,0,0,1.77c.37-.55.72-1.11,1-1.69a.51.51,0,0,1,.62-.22.5.5,0,0,1,.3.59,3.69,3.69,0,0,0-.15,1.67,1,1,0,0,0,.68.74l-.22-.16a.48.48,0,0,1-.17-.37,8.6,8.6,0,0,1,.33-3,3.6,3.6,0,0,1,2.25-2.31.51.51,0,0,1,.43.08A1.85,1.85,0,0,1,21.48,10,3.87,3.87,0,0,1,22,9.62a.48.48,0,0,1,.54,0,.51.51,0,0,1,.2.49,6.42,6.42,0,0,1-.67,1.9,4.53,4.53,0,0,1,0,.52,6,6,0,0,1,.94-.74.85.85,0,0,1,1-.08.9.9,0,0,1,.35.47l.45,1.21,0-.05a.5.5,0,1,1,.89.44,1.56,1.56,0,0,1-1.1.83.53.53,0,0,1-.56-.32l-.59-1.59a4.69,4.69,0,0,0-1.76,2.13.51.51,0,0,1-.62.29.51.51,0,0,1-.33-.6c.08-.33.15-.67.21-1l-.13.12a3.19,3.19,0,0,1-1,.64,2.22,2.22,0,0,1-1.47,1,1.94,1.94,0,0,1-1.94-1.51.06.06,0,0,0,0,0c-.25.37-.52.74-.79,1.1a.53.53,0,0,1-.53.17.49.49,0,0,1-.36-.4c0-.3-.09-.59-.11-.89a12.09,12.09,0,0,1-1.05,1.52.49.49,0,0,1-.51.17.52.52,0,0,1-.37-.4,14.32,14.32,0,0,1-.2-1.73,17.24,17.24,0,0,1-1,1.75.5.5,0,0,1-.91-.2,10.51,10.51,0,0,1-.05-2.8,14.84,14.84,0,0,0-1.63.9c.15.42.3.83.45,1.21A14,14,0,0,0,10.25,16a9,9,0,0,1,1.49,4.52,2.89,2.89,0,0,1-1,2.35A4.69,4.69,0,0,1,7.77,23.75ZM8,13.49c-.41.28-.82.59-1.25.92a10.06,10.06,0,0,0-2.66,2.78,3.77,3.77,0,0,0-.35,3.4,4.19,4.19,0,0,0,3.17,2.1,4.27,4.27,0,0,0,3.19-.63,1.88,1.88,0,0,0,.62-1.57,8,8,0,0,0-1.36-4,14.65,14.65,0,0,1-1-1.94C8.27,14.18,8.14,13.85,8,13.49Zm10.68,0a.87.87,0,0,0,.53,0,8.82,8.82,0,0,0,1.11-2.34,2.09,2.09,0,0,0,0-1.61A2.68,2.68,0,0,0,19,11.09,7.08,7.08,0,0,0,18.7,13.46Z" />
		</symbol>

		<symbol id="singleChoice">
			<circle cx="14.24" cy="14.24" r="5" />
			<path
				d="M14.24,22.31a8.08,8.08,0,1,1,8.07-8.07A8.08,8.08,0,0,1,14.24,22.31Zm0-15a6.93,6.93,0,1,0,6.92,6.93A6.94,6.94,0,0,0,14.24,7.31Z" />
		</symbol>

		<symbol id="sort">
			<path d="M20.05,17h-6.3a1,1,0,0,0,0,2h6.3a1,1,0,0,0,0-2Z" />
			<path d="M24.25,13H13.75a1,1,0,0,0,0,2h10.5a1,1,0,0,0,0-2Z" />
			<path d="M13.75,11h10.5a1,1,0,0,0,0-2H13.75a1,1,0,0,0,0,2Z" />
			<path
				d="M9.79,7.71a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42l-3.5-3.5a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-3.5,3.5A1,1,0,0,0,4.21,7.71L6,5.91V22.09l-1.79-1.8a1,1,0,0,0-1.42,1.42l3.5,3.5a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l3.5-3.5a1,1,0,0,0-1.42-1.42L8,22.09V5.91Z" />
		</symbol>

		<symbol id="sortDown">
			<path d="M20.05,17h-6.3a1,1,0,0,0,0,2h6.3a1,1,0,0,0,0-2Z" />
			<path d="M24.25,13H13.75a1,1,0,0,0,0,2h10.5a1,1,0,0,0,0-2Z" />
			<path d="M13.75,11h10.5a1,1,0,0,0,0-2H13.75a1,1,0,0,0,0,2Z" />
			<path
				d="M9.79,20.29,8,22.09V3.5a1,1,0,0,0-2,0V22.09l-1.79-1.8a1,1,0,0,0-1.42,1.42l3.5,3.5a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l3.5-3.5a1,1,0,0,0-1.42-1.42Z" />
		</symbol>

		<symbol id="sortUp">
			<path d="M20.05,17h-6.3a1,1,0,0,0,0,2h6.3a1,1,0,0,0,0-2Z" />
			<path d="M24.25,13H13.75a1,1,0,0,0,0,2h10.5a1,1,0,0,0,0-2Z" />
			<path d="M13.75,11h10.5a1,1,0,0,0,0-2H13.75a1,1,0,0,0,0,2Z" />
			<path
				d="M7.71,2.79a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-3.5,3.5A1,1,0,0,0,4.21,7.71L6,5.91V24.5a1,1,0,0,0,2,0V5.91l1.79,1.8a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
		</symbol>

		<symbol id="stepper">
			<path style="opacity: .5;"
				d="M17.03,21c0-.35.07-.69.18-1h-2.21v-5h2.21c-.11-.31-.18-.65-.18-1s.07-.69.18-1h-6.21v2h2v6c0,.55.45,1,1,1h3.21c-.11-.31-.18-.65-.18-1Z" />
			<g>
				<path
					d="M19.87,16.97h4.27c1.56,0,2.83-1.33,2.83-2.97s-1.27-2.97-2.83-2.97h-4.27c-1.56,0-2.83,1.33-2.83,2.97s1.27,2.97,2.83,2.97ZM19.87,12.97h4.27c.5,0,.9.46.9,1.03s-.4,1.03-.9,1.03h-4.27c-.5,0-.9-.46-.9-1.03s.4-1.03.9-1.03Z" />
				<path
					d="M24.13,18.03h-4.27c-1.56,0-2.83,1.33-2.83,2.97s1.27,2.97,2.83,2.97h4.27c1.56,0,2.83-1.33,2.83-2.97s-1.27-2.97-2.83-2.97ZM24.13,22.03h-4.27c-.5,0-.9-.46-.9-1.03s.4-1.03.9-1.03h4.27c.5,0,.9.46.9,1.03s-.4,1.03-.9,1.03Z" />
				<path
					d="M10,1H2c-.55,0-1,.45-1,1v4c0,.55.45,1,1,1h3.25v4H2c-.55,0-1,.45-1,1v4c0,.55.45,1,1,1h8c.55,0,1-.45,1-1v-4c0-.55-.45-1-1-1h-3.25v-4h3.25c.55,0,1-.45,1-1V2c0-.55-.45-1-1-1ZM9,15H3v-2h6v2ZM9,5H3v-2h6v2Z" />
				<path
					d="M10,21H2c-.55,0-1,.45-1,1v4c0,.55.45,1,1,1h8c.55,0,1-.45,1-1v-4c0-.55-.45-1-1-1ZM9,25H3v-2h6v2Z" />
				<rect x="5" y="19.5" width="2" height="1" />
				<rect x="5" y="17.5" width="2" height="1" />
			</g>
		</symbol>

		<symbol id="strikethrough">
			<path
				d="M12.17,12c-.1-.08-.21-.17-.3-.26-.26-.29-.39-.66-.39-1.11,0-.66,.21-1.17,.62-1.53,.41-.35,.95-.53,1.6-.53,.72,0,1.3,.19,1.74,.57,.44,.38,.68,.84,.72,1.38h2.52c-.09-1.24-.57-2.22-1.43-2.94-.86-.71-1.96-1.07-3.31-1.07-.95,0-1.78,.17-2.5,.51-.72,.34-1.28,.83-1.68,1.46-.4,.64-.6,1.38-.6,2.23,0,.48,.06,.91,.17,1.29h2.84Z" />
			<path
				d="M15.93,16c.06,.06,.14,.11,.2,.18,.27,.31,.41,.71,.41,1.21,0,.64-.22,1.16-.66,1.56-.44,.4-1.05,.6-1.84,.6s-1.35-.21-1.77-.62c-.42-.41-.66-.93-.71-1.57h-2.44c0,.85,.21,1.59,.64,2.23,.43,.64,1.01,1.13,1.76,1.47,.75,.35,1.59,.52,2.52,.52,.99,0,1.84-.2,2.57-.6,.73-.4,1.28-.94,1.66-1.6,.38-.66,.57-1.37,.57-2.12,0-.47-.05-.89-.15-1.26h-2.76Z" />
			<rect x="8" y="13" width="12" height="2" />
		</symbol>

		<symbol id="success">
			<path
				d="M9.77,22.5c-.4,0-.79-.16-1.08-.46l-3.77-3.88c-.58-.59-.56-1.54.03-2.12.59-.58,1.54-.56,2.12.03l2.53,2.61,9.18-12.56c.49-.67,1.43-.82,2.1-.33.67.49.81,1.43.33,2.1l-10.23,14c-.26.36-.67.58-1.11.61-.04,0-.07,0-.1,0Z" />
		</symbol>

		<symbol id="successRadio">
			<path
				d="M14,28C6.28,28,0,21.72,0,14S6.28,0,14,0s14,6.28,14,14-6.28,14-14,14ZM14,2C7.38,2,2,7.38,2,14s5.38,12,12,12,12-5.38,12-12S20.62,2,14,2Z" />
			<path
				d="M9.77,22.5c-.4,0-.79-.16-1.08-.46l-3.77-3.88c-.58-.59-.56-1.54.03-2.12.59-.58,1.54-.56,2.12.03l2.53,2.61,9.18-12.56c.49-.67,1.43-.82,2.1-.33.67.49.81,1.43.33,2.1l-10.23,14c-.26.36-.67.58-1.11.61-.04,0-.07,0-.1,0Z" />
		</symbol>

		<symbol id="successSolid">
			<path
				d="M28,14c0,7.72-6.28,14-14,14S0,21.72,0,14,6.28,0,14,0s14,6.28,14,14ZM9.87,22.5c.44-.03.85-.25,1.11-.61l10.23-14c.49-.67.34-1.61-.33-2.1-.67-.49-1.61-.34-2.1.33l-9.18,12.56-2.53-2.61c-.58-.59-1.53-.61-2.12-.03-.59.58-.61,1.53-.03,2.12l3.77,3.88c.28.29.67.46,1.08.46.03,0,.07,0,.1,0Z" />
		</symbol>

		<symbol id="survey">
			<path
				d="M21.13,1H6.87A2.88,2.88,0,0,0,4,3.87V24.13A2.88,2.88,0,0,0,6.87,27H21.13A2.88,2.88,0,0,0,24,24.13V3.87A2.88,2.88,0,0,0,21.13,1ZM9.5,3h9V4.5h-9ZM22,24.13a.87.87,0,0,1-.87.87H6.87A.87.87,0,0,1,6,24.13V3.87A.87.87,0,0,1,6.87,3H8.5V5a.5.5,0,0,0,.5.5H19a.5.5,0,0,0,.5-.5V3h1.63a.87.87,0,0,1,.87.87Z" />
			<path d="M20.25,9.5h-7a.5.5,0,0,0,0,1h7a.5.5,0,0,0,0-1Z" />
			<path
				d="M10.75,8h-3a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,10.75,8Zm-.5,3h-2V9h2Z" />
			<path d="M20.25,14.5h-7a.5.5,0,0,0,0,1h7a.5.5,0,0,0,0-1Z" />
			<path
				d="M10.75,13h-3a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,10.75,13Zm-.5,3h-2V14h2Z" />
			<path d="M20.25,19.5h-7a.5.5,0,0,0,0,1h7a.5.5,0,0,0,0-1Z" />
			<path
				d="M10.75,18h-3a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,10.75,18Zm-.5,3h-2V19h2Z" />
		</symbol>

		<symbol id="table">
			<path
				d="M24.49,4.5h-21A1.52,1.52,0,0,0,2,6V22A1.52,1.52,0,0,0,3.51,23.5h21A1.52,1.52,0,0,0,26,22V6A1.52,1.52,0,0,0,24.49,4.5Zm.51,3v3H20v-3Zm0,7H20v-3h5Zm-6-3v3H14.5v-3Zm-4.5-1v-3H19v3Zm-1-3v3H9v-3Zm0,4v3H9v-3ZM8,14.5H3v-3H8Zm-5,1H8v3H3Zm6,0h4.5v3H9Zm4.5,4v3H9v-3Zm1,0H19v3H14.5Zm0-1v-3H19v3Zm5.5-3h5v3H20ZM3,6V7.5H8v3H3ZM3,22V19.5H8v3H3.51A.51.51,0,0,1,3,22Zm22,0a.51.51,0,0,1-.51.51H20v-3h5Z" />
		</symbol>

		<symbol id="telephone">
			<path
				d="M20.28,25c-2.88,0-7.06-2.44-10.92-6.37S3,10.6,3,7.72C3,5.37,6.27,3,7.77,3c.28,0,1.15,0,3,2.72,0,0,1.7,2.49,1.75,3.4A3,3,0,0,1,12,10.84a1.89,1.89,0,0,0-.39,1,10.81,10.81,0,0,0,2.06,2.54h0a9.54,9.54,0,0,0,2.61,2.07,2.09,2.09,0,0,0,.92-.4,3,3,0,0,1,1.67-.59c1,0,3.42,1.73,3.45,1.75C25,19.08,25,20,25,20.23,25,21.73,22.63,25,20.28,25ZM7.61,5C6.82,5.27,5,6.76,5,7.72c0,2.29,2.32,6.11,5.77,9.5S18,23,20.28,23c1,0,2.45-1.82,2.69-2.61a9.5,9.5,0,0,0-1.83-1.53,14,14,0,0,0-2.41-1.39,2.82,2.82,0,0,0-.52.28,3.75,3.75,0,0,1-2,.69c-1.08,0-2.75-1.44-4-2.65l.7-.72-.71.71c-1.21-1.2-2.65-2.87-2.65-3.95a3.75,3.75,0,0,1,.69-2,2.3,2.3,0,0,0,.28-.53,14.71,14.71,0,0,0-1.39-2.4A9.16,9.16,0,0,0,7.61,5Z" />
		</symbol>

		<symbol id="text">
			<path d="M18.59,7.54v1.86h-3.7v12.04h-2.28V9.4h-3.72v-1.86h9.7Z" />
		</symbol>

		<symbol id="tick">
			<path
				d="M10,23a1,1,0,0,1-.71-.29l-4-4a1,1,0,0,1,1.42-1.42L9.92,20.5,21.22,6.38a1,1,0,1,1,1.56,1.24l-12,15a1,1,0,0,1-.72.38Z" />
		</symbol>


		<symbol id="time">
			<path
				d="M14,26.5A12.5,12.5,0,1,1,26.5,14,12.52,12.52,0,0,1,14,26.5Zm0-23A10.5,10.5,0,1,0,24.5,14,10.51,10.51,0,0,0,14,3.5Z" />
			<path
				d="M18.66,17.75a.78.78,0,0,1-.41-.12l-4.66-3a.74.74,0,0,1-.27-.31l-3.5-7.5a.76.76,0,0,1,.36-1,.75.75,0,0,1,1,.36l3.41,7.31,4.48,2.88a.76.76,0,0,1,.22,1A.74.74,0,0,1,18.66,17.75Z" />
		</symbol>

		<symbol id="translations">
			<path
				d="M2.61,24.2a1,1,0,0,1-.88-.53,1,1,0,0,1,.16-1.16l3.44-3.62c-1.11-1.66-2-3-2-5.1C3.36,8.42,8.47,4.21,15,4.21S26.61,8.58,26.61,14,21.39,23.7,15,23.7a10.49,10.49,0,0,1-4.75-.86L2.79,24.18A.55.55,0,0,1,2.61,24.2Zm7.81-3.41A1,1,0,0,1,11,21a7.91,7.91,0,0,0,4,.74c5.31,0,9.63-3.48,9.63-7.74S20.29,6.21,15,6.21,5.36,9.54,5.36,13.79c0,1.56.63,2.49,1.76,4.18l.32.48a1,1,0,0,1-.11,1.24l-1.89,2,4.8-.86Z" />
			<path d="M11.29,9.2h7.65v1.23H15.78V19H14.44V10.43H11.29Z" />
		</symbol>

		<symbol id="type">
			<path
				d="M16.67,18.6h-5.82l-1,2.84h-2.38L12.45,7.52h2.64l4.98,13.92h-2.4l-1-2.84Zm-.64-1.86l-2.26-6.46-2.28,6.46h4.54Z" />
		</symbol>

		<symbol id="textColor">
			<rect style="fill: #1b1464;" y="23" width="7" height="5" />
			<rect style="fill: #3fa9f5;" x="7" y="23" width="7" height="5" />
			<rect style="fill: #f7931e;" x="14" y="23" width="7" height="5" />
			<rect style="fill: #ff1d25;" x="21" y="23" width="7" height="5" />
			<path
				d="M17.16,18.6h-5.82l-1,2.84h-2.38L12.94,7.52h2.64l4.98,13.92h-2.4l-1-2.84Zm-.64-1.86l-2.26-6.46-2.28,6.46h4.54Z" />
		</symbol>

		<symbol id="underline">
			<path
				d="M10.86,7.54v8.86c0,1.05,.28,1.85,.83,2.38,.55,.53,1.32,.8,2.31,.8s1.78-.27,2.33-.8c.55-.53,.83-1.33,.83-2.38V7.54h2.28v8.82c0,1.13-.25,2.09-.74,2.88-.49,.79-1.15,1.37-1.98,1.76-.83,.39-1.74,.58-2.74,.58s-1.91-.19-2.73-.58c-.82-.39-1.47-.97-1.95-1.76-.48-.79-.72-1.75-.72-2.88V7.54h2.28Z" />
			<rect x="8" y="22.38" width="12" height="2" />
		</symbol>

		<symbol id="unlocked">
			<path
				d="M21,1a7,7,0,0,0-7,7V9H3a3,3,0,0,0-3,3V24a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V12a3,3,0,0,0-3-3H16V8A5,5,0,0,1,26,8V9a1,1,0,0,0,2,0V8A7,7,0,0,0,21,1ZM17,11a1,1,0,0,1,1,1V24a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1Z" />
			<path d="M13,23l-1.78-5.33a2.5,2.5,0,1,0-2.44,0L7,23Z" />
		</symbol>

		<symbol id="upload">
			<path
				d="M22,12V23a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V7A1,1,0,0,1,5,6H16V4H5A3,3,0,0,0,2,7V23a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V12Z" />
			<path d="M27,10V1H18V3h5.58L10.29,16.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L25,4.42V10Z" />
		</symbol>

		<symbol id="url">
			<path
				d="M13.5,2.08A11.46,11.46,0,1,0,25,13.54,11.46,11.46,0,0,0,13.5,2.08Zm1.65,20.85a8.8,8.8,0,0,1-1.15.13V18.15a18.45,18.45,0,0,1,3.79.47A8.33,8.33,0,0,1,15.15,22.93ZM9.22,18.65A18.25,18.25,0,0,1,13,18.16v4.9a8.8,8.8,0,0,1-1.15-.13A8.33,8.33,0,0,1,9.22,18.65ZM11.74,4.16A10.33,10.33,0,0,1,13,4V8.89A18.26,18.26,0,0,1,9.21,8.4,8.47,8.47,0,0,1,11.74,4.16ZM17.8,8.43a17.89,17.89,0,0,1-3.8.46V4a10.33,10.33,0,0,1,1.26.14A8.56,8.56,0,0,1,17.8,8.43ZM14,9.89a19.7,19.7,0,0,0,4.06-.5A18.66,18.66,0,0,1,18.51,13H14Zm-1,0V13H8.49A19,19,0,0,1,9,9.36,19.5,19.5,0,0,0,13,9.89ZM7.54,13H4A9.38,9.38,0,0,1,5.64,8.14,13,13,0,0,0,8,9.1,19,19,0,0,0,7.54,13Zm0,1A18.56,18.56,0,0,0,8,17.94a13.68,13.68,0,0,0-2.4,1A9.47,9.47,0,0,1,4,14Zm1,0H13v3.16a19.32,19.32,0,0,0-4,.52A17.71,17.71,0,0,1,8.49,14ZM14,17.15V14h4.51a18.31,18.31,0,0,1-.46,3.66A18.86,18.86,0,0,0,14,17.15ZM19.46,14H23a9.39,9.39,0,0,1-1.61,4.87,13.87,13.87,0,0,0-2.42-1A19.12,19.12,0,0,0,19.46,14Zm0-1A19.34,19.34,0,0,0,19,9.14a14,14,0,0,0,2.4-1A9.53,9.53,0,0,1,23,13Zm1.32-5.62a13.18,13.18,0,0,1-2,.79A11.42,11.42,0,0,0,17.1,4.7,9.63,9.63,0,0,1,20.78,7.38ZM9.9,4.7A11.46,11.46,0,0,0,8.26,8.14a12.85,12.85,0,0,1-2-.81A9.58,9.58,0,0,1,9.9,4.7Zm-3.66,15a12.94,12.94,0,0,1,2-.81A10.91,10.91,0,0,0,10,22.4,9.62,9.62,0,0,1,6.24,19.72ZM17,22.4a11.05,11.05,0,0,0,1.7-3.53,13.31,13.31,0,0,1,2.07.81A9.67,9.67,0,0,1,17,22.4Z" />
		</symbol>

		<symbol id="user">
			<path
				d="M22.5,25.76H5.5a1,1,0,0,1-1-1V17.57a3.63,3.63,0,0,1,3.62-3.65h4.31a1,1,0,0,1,.88.53L14,15.73l.69-1.28a1,1,0,0,1,.88-.53h4.31a3.63,3.63,0,0,1,3.62,3.65v7.19A1,1,0,0,1,22.5,25.76Zm-16-2h15V17.57a1.63,1.63,0,0,0-1.62-1.65H16.17l-1.29,2.4a1,1,0,0,1-1.76,0l-1.29-2.4H8.12A1.63,1.63,0,0,0,6.5,17.57Z" />
			<path
				d="M14,13.15A5.46,5.46,0,1,1,19.45,7.7,5.46,5.46,0,0,1,14,13.15Zm0-8.91A3.46,3.46,0,1,0,17.45,7.7,3.46,3.46,0,0,0,14,4.24Z" />
		</symbol>

		<symbol id="video">
			<polygon points="12 10 18 14 12 18 12 10" />
			<path
				d="M22.51,2h-17A3.5,3.5,0,0,0,2,5.49v17A3.5,3.5,0,0,0,5.49,26h17A3.5,3.5,0,0,0,26,22.51v-17A3.5,3.5,0,0,0,22.51,2ZM4,20V8H24V20Zm3,4H4V21H7Zm5.67,0h-3V21h3Zm5.66,0h-3V21h3ZM24,24H21V21h3ZM7,7H4V4H7Zm5.67,0h-3V4h3Zm5.66,0h-3V4h3ZM24,7H21V4h3Z" />
		</symbol>


		<symbol id="view">
			<path d="M14,19a5,5,0,1,1,5-5A5,5,0,0,1,14,19Zm0-8a3,3,0,1,0,3,3A3,3,0,0,0,14,11Z" />
			<path
				d="M14,22.5A14,14,0,0,1,1.08,14.39L.92,14l.16-.39A14,14,0,0,1,14,5.5a14,14,0,0,1,12.92,8.11l.16.39-.16.39A14,14,0,0,1,14,22.5ZM3.1,14A12,12,0,0,0,14,20.5,12,12,0,0,0,24.9,14,12,12,0,0,0,14,7.5,12,12,0,0,0,3.1,14Z" />
		</symbol>

		<symbol id="viewHidden">
			<path
				d="M26.92,13.61a12.73,12.73,0,0,0-4.34-5.36L21.15,9.68A10.91,10.91,0,0,1,24.9,14,12,12,0,0,1,14,20.5a12.93,12.93,0,0,1-3.25-.42l-1.6,1.6A14.83,14.83,0,0,0,14,22.5a14,14,0,0,0,12.92-8.11l.16-.39Z" />
			<path
				d="M5.42,19.75l1.43-1.43A10.91,10.91,0,0,1,3.1,14,12,12,0,0,1,14,7.5a12.93,12.93,0,0,1,3.25.42l1.6-1.6A14.83,14.83,0,0,0,14,5.5,14,14,0,0,0,1.08,13.61L.92,14l.16.39A12.73,12.73,0,0,0,5.42,19.75Z" />
			<path d="M17,13.84A1,1,0,0,1,17,14a3,3,0,0,1-3,3l-.16,0-1.66,1.67a5,5,0,0,0,6.47-6.47Z" />
			<path d="M11,14.16A1,1,0,0,1,11,14a3,3,0,0,1,3-3l.16,0,1.66-1.67a5,5,0,0,0-6.47,6.47Z" />
			<path d="M4,25a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42l20-20a1,1,0,1,1,1.42,1.42l-20,20A1,1,0,0,1,4,25Z" />
		</symbol>

		<symbol id="warning">
			<path
				d="M27.33,20.83L17.21,2.74c-.81-1.44-1.95-2.24-3.21-2.24s-2.41,.79-3.21,2.24L.67,20.83c-.8,1.42-.88,2.87-.24,3.97,.65,1.1,1.89,1.7,3.51,1.7H24.06c1.62,0,2.86-.61,3.51-1.7,.64-1.1,.56-2.54-.24-3.97Zm-1.49,2.95c-.27,.46-.92,.72-1.78,.72H3.94c-.87,0-1.52-.26-1.78-.72-.28-.47-.18-1.19,.26-1.98L12.53,3.71c.43-.77,.96-1.21,1.47-1.21s1.04,.44,1.47,1.21h0l10.12,18.09c.44,.79,.54,1.51,.26,1.98Z" />
			<path d="M13.99,20c-1.12,0-1.99,.89-1.99,1.98s.86,2.01,1.99,2.01,2.01-.89,2.01-2.01-.86-1.98-2.01-1.98Z" />
			<polygon points="12.67 18.5 15.33 18.5 16 5.5 12 5.5 12.67 18.5" />
		</symbol>

		<symbol id="warningSolid">
			<path
				d="M27.57,24.8c-.65,1.1-1.89,1.7-3.51,1.7H3.94c-1.62,0-2.86-.61-3.51-1.7-.64-1.1-.56-2.54,.24-3.97L10.79,2.74c.81-1.44,1.95-2.24,3.21-2.24s2.41,.79,3.21,2.24l10.12,18.09c.8,1.42,.88,2.87,.24,3.97Zm-13.58-4.8c-1.12,0-1.99,.89-1.99,1.98s.86,2.01,1.99,2.01,2.01-.89,2.01-2.01-.86-1.98-2.01-1.98Zm2.01-14.5h-4l.67,13h2.67l.67-13Z" />
		</symbol>

		<symbol id="workflow">
			<g>
				<path
					d="M9,17H7V14.67l.79.8a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29,1,1,0,0,0,0-1.41l-2.5-2.53a1,1,0,0,0-1.42,0l-2.5,2.53a1,1,0,0,0,1.42,1.41l.79-.8V17H3a2,2,0,0,0-2,2v6a2,2,0,0,0,2,2H9a2,2,0,0,0,2-2V19A2,2,0,0,0,9,17Zm0,8H3V19H9Z" />
				<path
					d="M20.65,10.52a2.19,2.19,0,0,0,.35.27v1.78l-.79-.8a1,1,0,1,0-1.42,1.41l2.5,2.52a1,1,0,0,0,1.42,0l2.5-2.52a1,1,0,1,0-1.42-1.41l-.79.8V10.79a2.19,2.19,0,0,0,.35-.27l3.1-3.14a1.91,1.91,0,0,0,0-2.68l-3.1-3.14a2,2,0,0,0-2.7,0L17.55,4.7a1.91,1.91,0,0,0,0,2.68ZM22,3l3,3L22,9,19,6Z" />
				<path
					d="M12,9.2a1,1,0,0,0,.71.3,1,1,0,0,0,.7-.29L16,6.71a1,1,0,0,0,0-1.42l-2.53-2.5A1,1,0,0,0,12,4.21l.8.79H10.9a5,5,0,1,0,0,2h1.93l-.8.79A1,1,0,0,0,12,9.2ZM6,9A3,3,0,1,1,9,6,3,3,0,0,1,6,9Z" />
				<path
					d="M22,17a5,5,0,0,0-4.9,4H15.43l.8-.79a1,1,0,1,0-1.41-1.42l-2.52,2.5a1,1,0,0,0,0,1.42l2.52,2.5a1,1,0,0,0,.71.29,1,1,0,0,0,.7-1.71l-.8-.79H17.1A5,5,0,1,0,22,17Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,22,25Z" />
			</g>
		</symbol>

		<symbol id="wrapRight">
			<rect x="23" y="23" width="18" height="2" rx="1" transform="rotate(-180 23 23)" />
			<rect x="16" y="17" width="11" height="2" rx="1" transform="rotate(-180 16 17)" />
			<rect x="16" y="13" width="11" height="2" rx="1" transform="rotate(-180 16 13)" />
			<rect x="23" y="7" width="18" height="2" rx="1" transform="rotate(-180 23 7)" />
			<rect x="23" y="17" width="6" height="6" rx="1" transform="rotate(-180 23 17)" />
		</symbol>

		<symbol id="wrapLeft">
			<rect x="5" y="5" width="18" height="2" rx="1" />
			<rect x="12" y="11" width="11" height="2" rx="1" />
			<rect x="12" y="15" width="11" height="2" rx="1" />
			<rect x="5" y="21" width="18" height="2" rx="1" />
			<rect x="5" y="11" width="6" height="6" rx="1" />
		</symbol>

		<symbol id="zonedDateTime">
			<rect x="6" y="15" width="6" height="6" />
			<path
				d="M13.91,24H4a1,1,0,0,1-1-1V7A1,1,0,0,1,4,6H7v.93A1,1,0,0,0,9,6.93V6h4.11a7.85,7.85,0,0,1,.52-2H9V2.93A1,1,0,0,0,7,2.93V4H4A3,3,0,0,0,1,7V23a3,3,0,0,0,3,3H14.89A7.59,7.59,0,0,1,13.91,24Z" />
			<path
				d="M21.5,15A6.5,6.5,0,1,0,28,21.5,6.51,6.51,0,0,0,21.5,15Zm4.74,9.27H24.92A10.59,10.59,0,0,0,25.26,22H27A5.38,5.38,0,0,1,26.24,24.27ZM16,22h1.71a10.59,10.59,0,0,0,.34,2.27H16.76A5.38,5.38,0,0,1,16,22Zm.73-3.27h1.32A10.59,10.59,0,0,0,17.74,21H16A5.38,5.38,0,0,1,16.76,18.73Zm5.24-1V16.1a3,3,0,0,1,1.49,1.63Zm1.88,1A9.78,9.78,0,0,1,24.26,21H22V18.73ZM21,16.1v1.63H19.51A3,3,0,0,1,21,16.1Zm0,2.63V21H18.74a9.78,9.78,0,0,1,.38-2.27ZM18.74,22H21v2.27H19.12A9.78,9.78,0,0,1,18.74,22ZM21,25.27V26.9a3,3,0,0,1-1.49-1.63Zm1,1.63V25.27h1.49A3,3,0,0,1,22,26.9Zm0-2.63V22h2.26a9.78,9.78,0,0,1-.38,2.27ZM25.26,21a10.59,10.59,0,0,0-.34-2.27h1.32A5.38,5.38,0,0,1,27,21Zm.23-3.27h-.9A6.56,6.56,0,0,0,24,16.61,5.66,5.66,0,0,1,25.49,17.73ZM19,16.61a6.56,6.56,0,0,0-.59,1.12h-.9A5.66,5.66,0,0,1,19,16.61Zm-1.49,8.66h.9A6.56,6.56,0,0,0,19,26.39,5.66,5.66,0,0,1,17.51,25.27ZM24,26.39a6.56,6.56,0,0,0,.59-1.12h.9A5.66,5.66,0,0,1,24,26.39Z" />
			<path d="M21,14a7,7,0,1,1,7-7A7,7,0,0,1,21,14ZM21,2a5,5,0,1,0,5,5A5,5,0,0,0,21,2Z" />
			<path
				d="M23,8.77a.43.43,0,0,1-.27-.08l-2-1.29a.46.46,0,0,1-.18-.21L19.06,4a.51.51,0,0,1,.24-.67.5.5,0,0,1,.67.24l1.44,3.1L23.3,7.85a.5.5,0,0,1,.15.69A.51.51,0,0,1,23,8.77Z" />
		</symbol>

		<symbol id="autofill">
			<path
				d="m14.0351562,16.7890625c.1875.1875.4418945.2929688.7070312.2929688s.5195312-.1054688.7070312-.2929688l12.2578125-12.2578125c.390625-.390625.390625-1.0234375,0-1.4140625l-2.8242188-2.8242188c-.390625-.390625-1.0234375-.390625-1.4140625,0l-12.2578125,12.2573242c-.390625.390625-.390625,1.0234375,0,1.4140625l2.8242188,2.824707ZM24.1757812,2.4140625l1.4101562,1.4101562-10.84375,10.84375-1.4101562-1.4106445L24.1757812,2.4140625Z" />
			<path
				d="m13.5585938,18.2509766c.090332-.34375-.0087891-.7094727-.2602539-.9609375l-2.5883789-2.5883789c-.2514648-.2514648-.6181641-.3515625-.9609375-.2602539-.34375.090332-.6137695.355957-.7094727.6982422l-1.0029297,3.5913086c-.0966797.3476562.0009766.7207031.2563477.9760742.1899414.1904297.4453125.2929688.7070312.2929688.0898438,0,.1801758-.012207.269043-.0366211l3.5913086-1.0029297c.3422852-.0957031.6079102-.3657227.6982422-.7094727Z" />
			<path
				d="m6,22c.5522461,0,1-.4477539,1-1s-.4477539-1-1-1H1c-.5522461,0-1,.4477539-1,1v6c0,.5522461.4477539,1,1,1h5c.5522461,0,1-.4477539,1-1s-.4477539-1-1-1H2v-4h4Z" />
			<path
				d="m16.6669922,20h-5.3334961c-.5522461,0-1,.4477539-1,1s.4477539,1,1,1h5.3334961c.5522461,0,1-.4477539,1-1s-.4477539-1-1-1Z" />
			<path
				d="m27,20h-5c-.5522461,0-1,.4477539-1,1s.4477539,1,1,1h4v4h-4c-.5522461,0-1,.4477539-1,1s.4477539,1,1,1h5c.5522461,0,1-.4477539,1-1v-6c0-.5522461-.4477539-1-1-1Z" />
			<path
				d="m16.6665039,26h-5.3334961c-.5522461,0-1,.4477539-1,1s.4477539,1,1,1h5.3334961c.5522461,0,1-.4477539,1-1s-.4477539-1-1-1Z" />
		</symbol>

		<symbol id="tagged">
			<path
				d="m12.0976562,27.831543c-.265625,0-.5195312-.1054688-.7070312-.2929688L.4619141,16.6098633c-.390625-.390625-.390625-1.0234375,0-1.4140625L15.3642578.2929688c.1875-.1875.4414062-.2929688.7070312-.2929688h10.9287109c.5527344,0,1,.4477539,1,1v10.9287109c0,.2651367-.1054688.5195312-.2929688.7070312l-14.9023438,14.902832c-.1875.1875-.4414062.2929688-.7070312.2929688ZM2.5830078,15.902832l9.5146484,9.5146484,13.9023438-13.902832V2h-9.5146484L2.5830078,15.902832Zm24.4169922-3.9741211h.0097656-.0097656Z" />
			<circle cx="23.0000003" cy="5.0000003" r="2" />
		</symbol>

		<symbol id="showIf">
			<path
				d="m21.2797852,28c-.2592773,0-.5087891-.1005859-.6958008-.2817383l-2.4462891-2.3710938c-.3969727-.3847656-.4067383-1.0175781-.0224609-1.4140625.3842773-.3979492,1.019043-.4057617,1.4140625-.0224609l1.6650391,1.6137695,5.03125-6.1572266c.3496094-.4267578.9799805-.4907227,1.4072266-.1416016.4277344.3496094.4912109.9794922.1416016,1.4072266l-5.7202148,7c-.1772461.2167969-.4370117.3491211-.7163086.3657227-.0195312.0009766-.0390625.0014648-.0581055.0014648Z" />
			<path
				d="m5.9140625,23.5l2.7929688-2.7929688c.390625-.390625.390625-1.0234375,0-1.4140625s-1.0234375-.390625-1.4140625,0l-2.7929688,2.7929688-2.7929688-2.7929688c-.390625-.390625-1.0234375-.390625-1.4140625,0s-.390625,1.0234375,0,1.4140625l2.7929688,2.7929688-2.7929688,2.7929688c-.390625.390625-.390625,1.0234375,0,1.4140625.1953125.1953125.4511719.2929688.7070312.2929688s.5117188-.0976562.7070312-.2929688l2.7929688-2.7929688,2.7929688,2.7929688c.1953125.1953125.4511719.2929688.7070312.2929688s.5117188-.0976562.7070312-.2929688c.390625-.390625.390625-1.0234375,0-1.4140625l-2.7929688-2.7929688Z" />
			<path
				d="m23.5,13h-4.3173218c-.0586548-.0844116-.1221313-.1665039-.1973267-.2416992l-3.7436523-3.7436523c-.0751953-.0751953-.1572876-.1386719-.2416992-.1973267v-1.8173218c0-.0544434-.022644-.1014404-.0310059-.1535645,1.456665-.4234619,2.5310059-1.755188,2.5310059-3.3464355,0-1.9296875-1.5703125-3.5-3.5-3.5s-3.5,1.5703125-3.5,3.5c0,1.5912476,1.0743408,2.9229736,2.5310059,3.3464355-.0083618.052124-.0310059.0991211-.0310059.1535645v1.8173828c-.0843506.0586548-.1665649.1221313-.2416992.1972656l-3.7436523,3.7436523c-.0751953.0751953-.1386719.1572876-.1973267.2416992h-4.3173218c-.5522461,0-1,.4477539-1,1v4c0,.5522461.4477539,1,1,1s1-.4477539,1-1v-3h3.3173828c.0586548.0843506.1221313.1665649.1972656.2416992l3.7436523,3.7436523c.3422852.3422852.7919922.5136719,1.2416992.5136719s.8994141-.1713867,1.2416992-.5136719l3.7436523-3.7436523c.0751343-.0751343.1386108-.1573486.1972656-.2416992h3.3173828v3c0,.5522461.4477539,1,1,1s1-.4477539,1-1v-4c0-.5522461-.4477539-1-1-1ZM12.5,3.5c0-.8271484.6728516-1.5,1.5-1.5s1.5.6728516,1.5,1.5-.6728516,1.5-1.5,1.5-1.5-.6728516-1.5-1.5Zm1.5,13.8989258l-3.3989258-3.3989258,3.3989258-3.3989258,3.3989258,3.3989258-3.3989258,3.3989258Z" />
		</symbol>

		<symbol id="required">
			<path
				d="m22.6689453,12h-5.0482178l2.2679443-3.9282227c.5522461-.956543.2246094-2.1796875-.7319336-2.7319336-.956543-.5532227-2.1801758-.2250977-2.7319336.7319336l-2.2680664,3.9284058-2.2680664-3.9284058c-.5522461-.9570312-1.7744141-1.2851562-2.7319336-.7319336-.956543.5522461-1.2841797,1.7753906-.7319336,2.7319336l2.2679443,3.9282227h-5.0487061c-1.1044922,0-2,.8955078-2,2s.8955078,2,2,2h5.0487061l-2.2679443,3.9282227c-.5522461.956543-.2246094,2.1796875.7319336,2.7319336.3149414.1821289.6586914.2685547.9980469.2685547.6914062,0,1.3637695-.3588867,1.7338867-1.0004883l2.2680664-3.9284058,2.2680664,3.9284058c.3701172.6416016,1.0424805,1.0004883,1.7338867,1.0004883.3393555,0,.6831055-.0864258.9980469-.2685547.956543-.5522461,1.2841797-1.7753906.7319336-2.7319336l-2.2679443-3.9282227h5.0482178c1.1044922,0,2-.8955078,2-2s-.8955078-2-2-2Z" />
		</symbol>

		<symbol id="reportable">
			<g style="opacity: .5;">
				<path
					d="m8.1630859,11h-3.3261719c-1.0126953,0-1.8369141.8237305-1.8369141,1.8364258v12.3271484c0,1.0126953.8242188,1.8364258,1.8369141,1.8364258h3.3261719c1.0126953,0,1.8369141-.8237305,1.8369141-1.8364258v-12.3271484c0-1.0126953-.8242188-1.8364258-1.8369141-1.8364258Zm-.1630859,14h-3v-12h3v12Z" />
				<path
					d="m16.9755859,4h-2.9511719c-1.1162109,0-2.0244141.9082031-2.0244141,2.0249023v18.9501953c0,1.1166992.9082031,2.0249023,2.0244141,2.0249023h2.9511719c1.1162109,0,2.0244141-.9082031,2.0244141-2.0249023V6.0249023c0-1.1166992-.9082031-2.0249023-2.0244141-2.0249023Zm0,21l-2.9755859-.0249023.0244141-18.9750977h2.9511719l.0244141.0249023-.0244141,18.9750977Z" />
				<path
					d="m25.2929688,15h-3.5859375c-.9414062,0-1.7070312.765625-1.7070312,1.7070312v8.5859375c0,.9414062.765625,1.7070312,1.7070312,1.7070312h3.5859375c.9414062,0,1.7070312-.765625,1.7070312-1.7070312v-8.5859375c0-.9414062-.765625-1.7070312-1.7070312-1.7070312Zm-.2929688,10h-3v-8h3v8Z" />
			</g>
			<path
				d="m27,26H3.1864014l8.0772705-9.3442383,4.2480469,3.3984375c.4306641.34375,1.0527344.2763672,1.3984375-.1474609l8.8637085-10.8296509,1.6705322,1.3671265-.444397-4.4442139-4.4443359.4432983,1.6710205,1.3675537-8.2374268,10.0641479-4.2275391-3.3823242c-.4199219-.3359375-1.0302734-.2802734-1.3818359.1269531L2,24.3138428V1c0-.5522461-.4472656-1-1-1S0,.4477539,0,1v26c0,.0120239.0064087.0219727.0068359.0338745.0025024.0749512.0247803.1451416.0439453.2178955.0145264.0545044.0206909.1107788.0437622.1611328.0244751.053772.0651245.0981445.0993652.147522.0410156.0588379.0773926.1189575.1298218.1676025.0090332.0084229.0125122.0202026.0219727.0283203.0483398.041748.1055298.062561.1588135.0935059.0437622.0256348.0817261.057373.1294556.0762329.118103.0466309.241333.0739136.3660278.0739136h26c.5527344,0,1-.4477539,1-1s-.4472656-1-1-1Z" />
		</symbol>

		<symbol id="searchable">
			<path
				d="m11,14.1030273c-1.7109375,0-3.1030273-1.3920898-3.1030273-3.1030273s1.3920898-3.1030273,3.1030273-3.1030273,3.1030273,1.3920898,3.1030273,3.1030273-1.3920898,3.1030273-3.1030273,3.1030273Zm0-4.7060547c-.8837891,0-1.6030273.7192383-1.6030273,1.6030273s.7192383,1.6030273,1.6030273,1.6030273,1.6030273-.7192383,1.6030273-1.6030273-.7192383-1.6030273-1.6030273-1.6030273Z" />
			<path
				d="m11,16.1616211c-3.4077148,0-6.5219727-1.9570312-7.7495117-4.8701172l-.1230469-.2915039.1230469-.2915039c1.2275391-2.9130859,4.3417969-4.8701172,7.7495117-4.8701172s6.5219727,1.9570312,7.7495117,4.8701172l.1230469.2915039-.1230469.2915039c-1.2275391,2.9130859-4.3417969,4.8701172-7.7495117,4.8701172Zm-6.2348633-5.1616211c1.0771484,2.2026367,3.5395508,3.6616211,6.2348633,3.6616211s5.1577148-1.4589844,6.2348633-3.6616211c-1.0771484-2.2026367-3.5395508-3.6616211-6.2348633-3.6616211s-5.1577148,1.4589844-6.2348633,3.6616211Z" />
			<path
				d="m11,22.1333008C4.8608398,22.1333008-.1333008,17.1391602-.1333008,11S4.8608398-.1333008,11-.1333008s11.1333008,4.9941406,11.1333008,11.1333008-4.9941406,11.1333008-11.1333008,11.1333008Zm0-20C6.1108398,2.1333008,2.1333008,6.1108398,2.1333008,11s3.9775391,8.8666992,8.8666992,8.8666992,8.8666992-3.9775391,8.8666992-8.8666992S15.8891602,2.1333008,11,2.1333008Z" />
			<path
				d="m26.5,27.7856445c-.3291016,0-.6582031-.1254883-.9091797-.3764648l-8-8c-.5019531-.5019531-.5019531-1.3164062,0-1.8183594s1.3164062-.5019531,1.8183594,0l8,8c.5019531.5019531.5019531,1.3164062,0,1.8183594-.2509766.2509766-.5800781.3764648-.9091797.3764648Z" />
		</symbol>

		<symbol id="trigger">
			<g>
				<path d="M18,13h6c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1S17.4,13,18,13z" />
				<path d="M18,7.5h6c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1S17.4,7.5,18,7.5z" />
				<path d="M18,18.5h6c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1S17.4,18.5,18,18.5z" />
				<path d="M11.5,8.5h3C14.8,8.5,15,8.3,15,8V5c0-0.3-0.2-0.5-0.5-0.5h-3C11.2,4.5,11,4.7,11,5v3C11,8.3,11.2,8.5,11.5,8.5z M12,5.5h2
					v2h-2V5.5z" />
				<path d="M11,13.5c0,0.3,0.2,0.5,0.5,0.5h3c0.3,0,0.5-0.2,0.5-0.5v-3c0-0.3-0.2-0.5-0.5-0.5h-3c-0.3,0-0.5,0.2-0.5,0.5V13.5z M12,11
					h2v2h-2V11z" />
				<path d="M11,19c0,0.3,0.2,0.5,0.5,0.5h3c0.3,0,0.5-0.2,0.5-0.5v-3c0-0.3-0.2-0.5-0.5-0.5h-3c-0.3,0-0.5,0.2-0.5,0.5V19z M12,16.5h2
					v2h-2V16.5z" />
				<path d="M27,0H9C8.4,0,8,0.4,8,1v16.1h1c0.1,0,0.3,0,0.4,0c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1
					c0,0,0,0,0,0V2h16v20H11v2h16c0.6,0,1-0.4,1-1V1C28,0.4,27.6,0,27,0z" />
			</g>
			<path
				d="M9.9,18.7c-0.1-0.2-0.3-0.4-0.5-0.5c-0.1-0.1-0.3-0.1-0.4-0.1H2.6c-0.6,0-1,0.4-1,1s0.4,1,1,1h4l-6.3,6.3
				c-0.4,0.4-0.4,1,0,1.4C0.5,28,0.7,28.1,1,28.1s0.5-0.1,0.7-0.3L8,21.5v4c0,0.6,0.4,1,1,1s1-0.4,1-1v-6.4C10,18.9,10,18.8,9.9,18.7z" />
		</symbol>

		<symbol id="object">
			<path style="stroke-width: 0px;"
				d="m1,12.97c.6-.05,1.06-.21,1.37-.49.31-.28.46-.65.46-1.11,0-.53-.06-1.29-.17-2.29-.13-1.18-.19-2.05-.19-2.59,0-.98.29-1.75.88-2.3.59-.55,1.36-.82,2.33-.82h1.3v2.23h-.79c-.78,0-1.17.37-1.17,1.11,0,.35.05,1.06.15,2.12.01.13.04.48.09,1.07.05.59.08,1.1.08,1.56,0,.67-.16,1.22-.49,1.66s-.82.72-1.49.85v.04c.67.14,1.16.43,1.49.86s.49.99.49,1.67c0,.43-.03.93-.08,1.52s-.08.95-.09,1.1c-.1,1.06-.15,1.76-.15,2.1,0,.39.09.67.28.85.19.18.49.26.89.26h.79v2.25h-1.3c-.97,0-1.75-.27-2.33-.82-.59-.55-.88-1.31-.88-2.3,0-.55.06-1.42.19-2.61.11-1,.17-1.75.17-2.27,0-.47-.15-.84-.46-1.11-.31-.28-.77-.44-1.37-.49v-2.06Z" />
			<path style="stroke-width: 0px;"
				d="m7.92,20.56c-.31-.3-.46-.66-.46-1.11s.15-.81.46-1.1c.31-.3.7-.44,1.18-.44s.85.15,1.15.44c.3.3.45.66.45,1.1s-.15.81-.45,1.11c-.3.3-.69.44-1.15.44s-.87-.15-1.18-.44Z" />
			<path style="stroke-width: 0px;"
				d="m12.84,20.56c-.31-.3-.46-.66-.46-1.11s.15-.81.46-1.1c.31-.3.7-.44,1.18-.44s.85.15,1.15.44c.3.3.45.66.45,1.1s-.15.81-.45,1.11c-.3.3-.69.44-1.15.44s-.87-.15-1.18-.44Z" />
			<path style="stroke-width: 0px;"
				d="m17.75,20.56c-.31-.3-.46-.66-.46-1.11s.15-.81.46-1.1c.31-.3.7-.44,1.18-.44s.85.15,1.15.44c.3.3.45.66.45,1.1s-.15.81-.45,1.11c-.3.3-.69.44-1.15.44s-.87-.15-1.18-.44Z" />
			<path style="stroke-width: 0px;"
				d="m25.63,15.52c-.31.28-.46.65-.46,1.11,0,.52.06,1.27.17,2.27.13,1.18.19,2.05.19,2.61,0,.98-.29,1.75-.88,2.3s-1.36.82-2.33.82h-1.3v-2.25h.79c.4,0,.7-.09.89-.26.19-.18.28-.46.28-.85,0-.34-.05-1.04-.15-2.1-.01-.15-.04-.52-.09-1.1s-.07-1.09-.07-1.52c0-.68.16-1.24.49-1.67s.82-.72,1.49-.86v-.04c-.67-.13-1.16-.41-1.49-.85s-.49-1-.49-1.66c0-.45.02-.97.07-1.56.05-.59.08-.94.09-1.07.1-1.06.15-1.76.15-2.12,0-.74-.39-1.11-1.17-1.11h-.79v-2.23h1.3c.97,0,1.75.27,2.33.82.59.55.88,1.31.88,2.3,0,.54-.06,1.4-.19,2.59-.11,1-.17,1.76-.17,2.29,0,.47.15.84.46,1.11.31.28.77.44,1.37.49v2.06c-.6.05-1.06.21-1.37.49Z" />
		</symbol>
	</defs>
</svg>