import { Definition, Field, FieldOption, FieldTemplate, FieldType, FileData, FormData, isString } from '@unifii/sdk';

import { FileUploader, fieldIterator } from '@unifii/library/common';

import { PrintConfig, PrintField } from './print-form.component';

interface PrintFieldsToRemove {
	parent: Field | Definition;
	field: Field;
}

export interface PrintImage {
	url: string;
	name: string;
}

// eslint-disable-next-line complexity
export const amendDefinitionForPrinting = (definition: Definition, config: PrintConfig): Definition => {
	const definitionResult = JSON.parse(JSON.stringify(definition)) as Definition;
	const fieldsToDelete: PrintFieldsToRemove[] = [];

	/** Update choice templates */
	for (const { field, parent } of fieldIterator<Field>(definitionResult.fields)) {

		if (config.summary) {

			delete field.help;

			if (field.options && field.identifier) {
				const selected = config.data[field.identifier];

				if (field.type === FieldType.MultiChoice) {
					field.options = selected ? field.options.filter((option: FieldOption) => selected.includes(option.identifier)) : [];
				} else {
					field.options = field.options.filter((option: FieldOption) => option.identifier === selected);
				}
			}

			// add content fields to be deleted
			if ([FieldType.Carousel, FieldType.Content].includes(field.type) && parent?.fields) {
				fieldsToDelete.push({ parent, field });
			}
		} else {
			// Switch choice fields to radio buttons
			if (field.type === FieldType.Choice && field.template === FieldTemplate.DropDown) {
				field.template = FieldTemplate.Radio;
				field.columnCount = 2;
			}
		}

		// Switch markdown options template to regular options template
		if (field.template === FieldTemplate.CheckboxWithContent) {
			field.template = FieldTemplate.Checkbox;
		}
		if (field.template === FieldTemplate.RadioWithContent) {
			field.template = FieldTemplate.Radio;
		}
		if (field.template === FieldTemplate.OptionWithContent) {
			field.template = field.type === FieldType.MultiChoice ? FieldTemplate.Checkbox : FieldTemplate.Radio;
		}
		if (field.options != null) {
			field.options.forEach((option: FieldOption) => {
				delete option.content;
			});
		}

		if ([FieldType.Step, FieldType.Stepper].includes(field.type)) {
			field.type = FieldType.Group;
		}

		// flatten carousel content blocks into parent
		if (field.type === FieldType.Carousel && parent?.fields) {
				
			const index = parent.fields.indexOf(field);

			if (index >= 0) {
				parent.fields.splice(index, 1, ...field.fields ?? []);
			}

		}

		// Switch booleans to checkbox
		if (field.type === FieldType.Bool && field.template !== FieldTemplate.Checkbox) {
			field.template = FieldTemplate.Checkbox;
		}

		(field as unknown as PrintField).print = true;
		(field as unknown as PrintField).summaryPrint = config.summary;
	}

	// delete fields that shouldn't display
	for (const { parent, field } of fieldsToDelete) {
		const index = parent.fields?.indexOf(field) ?? -1;

		if (index >= 0) {
			parent.fields?.splice(index, 1);
		}
	}

	return definitionResult;
};

export const getImageLists = async(definition: Definition, compound: FormData, uploader: FileUploader): Promise<PrintImage[]> => {

	const imageList = [];

	for (const { field } of fieldIterator(definition.fields)) {

		if (field.identifier && (field.type === FieldType.ImageList || field.type === FieldType.FileList) && compound[field.identifier]) {
			const fileList = compound[field.identifier] as FileData[];

			const imageUrls = await getImageList(fileList, uploader);

			imageList.push(...imageUrls);
		}
	}

	return imageList;
};

const getImageList = (fileList: FileData[], uploader: FileUploader): Promise<PrintImage[]> => {

	// Test for supported images extensions by img tag
	// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img
	const supportedImgExtensions = /.*\.(apng|bmp|gif|ico|cur|jpg|jpeg|jfif|pjpeg|pjp|png|svg|tif|tiff|webp)$/;

	return Promise.all(fileList
		.filter((file): file is FileData & { id: string } => isString(file.id) && !!file.name.toLowerCase().match(supportedImgExtensions))
		.map(async(file): Promise<PrintImage> => {
			const url = await uploader.getUrl(file.id);

			return { url, name: file.name };
		}));
};
