import { isNotNull } from '@unifii/sdk';

/**
 * Return or wrap and return as array the input value
 * @param value a single or array value
 * @returns the value as array value
 */
export const getValueAsArray = <T>(value: T | T[] | null | undefined): T[] => {
	return (!Array.isArray(value) ? [value] : value).filter(isNotNull);
};
