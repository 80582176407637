import { CoerceChildrenDataDescriptor, CoerceDataDescriptor, DataSourceType, DataType, Field, FieldType, fieldTypeToDataType } from '@unifii/sdk';

import { RuntimeField } from '../../models';

/**
 * This function is not a proper transformation, to be used with caution, instead use the proper adapters
 * Amend the input field to respect the RuntimeField interface
 *
 * @see RuntimeDefinitionAdapter or @see RuntimePageAdapter
 * @param field to be amended
 * @param preserveOriginal original field remain as Field type if set to true (default), otherwise transformed to runtime field
 * @returns casted reference of the input
 */
export const getSafeRuntimeField = (field: Field, preserveOriginal=true): RuntimeField => {

	const wannaBeRuntimeField = preserveOriginal ? structuredClone(field) : field;

	wannaBeRuntimeField.fields = field.fields ?? [];
	wannaBeRuntimeField.isReadOnly = field.isReadOnly ?? false;
	wannaBeRuntimeField.isRequired = field.isRequired ?? false;
	wannaBeRuntimeField.tags = field.tags ?? [];
	wannaBeRuntimeField.visibleFields = field.visibleFields ?? [];
	wannaBeRuntimeField.requiredFields = field.requiredFields ?? [];
	wannaBeRuntimeField.readOnlyFields = field.readOnlyFields ?? [];
	wannaBeRuntimeField.options = field.options ?? [];
	wannaBeRuntimeField.transitions = field.transitions ?? [];
	(wannaBeRuntimeField as unknown as RuntimeField).visibleTo = field.visibleTo ? field.visibleTo.split(',') : [];
	(wannaBeRuntimeField as unknown as RuntimeField).roles = field.role ? field.role.split(',') : [];
	(wannaBeRuntimeField as unknown as RuntimeField).dependencies = [];

	// delete role after converted to roles array
	delete wannaBeRuntimeField.role;
	wannaBeRuntimeField.validators = field.validators ?? [];
	wannaBeRuntimeField.variations = field.variations ?? [];

	return wannaBeRuntimeField as unknown as RuntimeField;
};

export const runtimeFieldToCoerceDataDescriptor = (field: RuntimeField): CoerceDataDescriptor => {

	const descriptor = getCoerceDataDescriptorByFieldType(field.type);

	switch (descriptor.type) {
		case DataType.Number:
			descriptor.precision = field.precision;
			break;
		case DataType.DataSeed:
		case DataType.Choice:
			descriptor.children = getDataSourceDescriptors(field);
			break;
		case DataType.Repeat:
			descriptor.children = getRepeatDescriptors(field.fields);
			break;
	}

	return descriptor;

};

const getDataSourceDescriptors = (field: RuntimeField): CoerceChildrenDataDescriptor | undefined => {

	const sourceConfig = field.sourceConfig;

	if (!sourceConfig || sourceConfig.type === DataSourceType.Named) {
		return;
	}

	return sourceConfig.mappings.reduce<CoerceChildrenDataDescriptor>((descriptors, mapping) => {
		descriptors[mapping.to] = getCoerceDataDescriptorByFieldType(mapping.type);
		
		return descriptors;
	}, {});
};

const getRepeatDescriptors = (fields: RuntimeField[]): CoerceChildrenDataDescriptor => {
	return [...scopeIterator(fields)].reduce<CoerceChildrenDataDescriptor>((descriptors, field) => {
		if (field.identifier) {
			descriptors[field.identifier] = runtimeFieldToCoerceDataDescriptor(field);
		}

		return descriptors;
	}, {});
};

const getCoerceDataDescriptorByFieldType = (fieldType: FieldType): CoerceDataDescriptor => 
	({ type: fieldTypeToDataType(fieldType) ?? DataType.String } as unknown as CoerceDataDescriptor);

function* scopeIterator(fields: RuntimeField[]): Iterable<RuntimeField> {
	for (const field of fields) {
		if (field.type === FieldType.Repeat) {
			continue;
		}

		if (field.fields.length) {
			yield *scopeIterator(field.fields);
		}
		yield field;
	}
}
