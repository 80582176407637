import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FileType } from '@unifii/sdk';

import { DataSourceLoader, DeviceInfo, FileUploader, ImageFileLabel, InputFile, UfControl } from '@unifii/library/common';
import { FormField, FormService, FormSettings } from '@unifii/library/smart-forms';

const createFileUploader = (formSettings: FormSettings) => formSettings.uploader;

@Component({
	selector: 'uf-file-list-input',
	templateUrl: './file-list.html',
	providers: [{
		provide: FileUploader, useFactory: createFileUploader, deps: [FormSettings],
	}],
})
export class FileListComponent implements FormField, OnInit {

	fileType = '';
	fileTypeLabel = '';
	allowedFileTypes: FileType[];

	scope?: any;
	diagnostics?: boolean | undefined;
	dataLoader?: DataSourceLoader | undefined;
	onError?: ((error: Error) => void) | undefined;
	retry?: (() => void) | undefined;
	field: any;
	contentChange?: EventEmitter<any> | undefined;
	control?: UfControl;
	suffix?: string | undefined;
	cssClass?: string | string[] | undefined;
	content?: any;
	cameraRequired: boolean;

	private progressId?: string;

	constructor(
		device: DeviceInfo,
		@Inject(FormSettings) protected formSettings: FormSettings,
		private formService: FormService,
	) {
		this.cameraRequired = device.getPlatform() === 'android';
	}

	async ngOnInit() {

		if (!this.formSettings.getAllowedFileTypes) {
			return;
		}

		const allowedFileTypes = await this.formSettings.getAllowedFileTypes();

		if (allowedFileTypes == null) {
			return;
		}

		if (!this.fileType) {
			this.allowedFileTypes = allowedFileTypes;

			return;
		}

		// Filter only files of specified mime type
		this.allowedFileTypes = allowedFileTypes.filter((fileType) =>
			fileType.mimeTypes.findIndex((type) => type.startsWith(ImageFileLabel)) >= 0,
		);
	}

	progressChange(files: InputFile[]) {
		const inProgress = files.filter((f) => !f.completed).length > 0;

		if (inProgress && this.progressId == null) {
			this.progressId = this.formService.registerInProgress();

			return;
		}

		if (!inProgress && this.progressId != null) {
			this.formService.deregisterInProgress(this.progressId);
			this.progressId = undefined;
		}
	}

	onContentChange(value: any) {
		if (this.contentChange) {
			this.contentChange.emit(value);
		}
	}

}
