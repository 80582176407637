import { Component, Input } from '@angular/core';
import { Address } from '@unifii/sdk';

import { RuntimeField } from '@unifii/library/common';
import { ContentField, SmartFormsTranslationKey } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-address-display',
	templateUrl: './address.html',
	styleUrls: ['./address.less'],
})
export class AddressDisplayComponent implements ContentField {

	@Input() content: Address;
	@Input() field: RuntimeField;

	readonly smartFormsTK = SmartFormsTranslationKey;

}
