import { Component } from '@angular/core';
import { FormRecord } from '@angular/forms';
import { LayoutDirection } from '@unifii/sdk';

import { Size } from '../../../constants';
import { RadioInputGroup, RadioOption } from '../../../models';

@Component({
	selector: 'uf-content-radios',
	templateUrl: './content-radio-inputs.html',
	styleUrls: ['./content-radio-inputs.less'],
})
export class ContentRadioInputsComponent implements RadioInputGroup {

	readonly nameProperty = 'name';
	readonly sizes = Size;

	focused: number | null;
	// style inputs
	direction: LayoutDirection = LayoutDirection.Row;
	rows: number;
	columns: number;
	// functional inputs
	groupName: string;
	formGroupName: string;
	formGroup: FormRecord;
	options: RadioOption[] = [];
	unmatchedValue: any;

	get value(): any {
		return this.formGroup.get(this.groupName)?.value;
	}

}
