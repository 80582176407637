<div [formGroup]="formGroup" [direction]="direction" [columns]="columns" [rows]="rows" [cellTotal]="options.length"
	[rowGap]="sizes.MD" [columnGap]="sizes.MD" [ngClass]="{'half-screen': columns === 1}" ufGrid>

	<uf-radio-input-wrapper *ngIf="unmatchedValue" [label]="unmatchedValue | nameProperty:nameProperty"
		[class.checked]="unmatchedValue === value" [class.selected-border]="unmatchedValue === value" class="disabled">
		<input [formControlName]="groupName" [attr.name]="groupName" [value]="unmatchedValue" [attr.disabled]="true"
			radioInput type="radio" />
	</uf-radio-input-wrapper>

	<ng-container *ngFor="let option of options; let i = index">
		<uf-radio-input-wrapper [label]="option | nameProperty:nameProperty" [class.checked]="option === value"
			[class.disabled]="formGroup.disabled" [class.focused]="focused === i"
			[ngClass]="{ 'selected-border': option === value }" [content]="option.content" class="uf-box flat">
			<input [formControlName]="groupName" [attr.name]="groupName" [value]="option" (focus)="focused = i"
				(blur)="focused = null" radioInput type="radio" />
		</uf-radio-input-wrapper>
	</ng-container>

</div>