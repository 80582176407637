import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';

import { ModalService } from '../../services';
import { ExpanderButtonComponent } from '../containers';
import { ListBoxComponent, ListBoxConfig, ListBoxOption, ModalPositionAlignment } from '../modal';
import { TabComponent } from '../tabs';

interface DropDownListBoxOption extends ListBoxOption {
	tab?: TabComponent;
}

@Component({
	selector: 'uf-dropdown-tabs',
	templateUrl: './dropdown-tabs.html',
})

export class DropdownTabsComponent {

	@Input({ required: true }) tabs: TabComponent[];
	@Input({ required: true }) active: TabComponent | null;

	@Output() protected activeChange = new EventEmitter<TabComponent>();

	@ViewChild(ExpanderButtonComponent) protected get expanderButton() {
		return this._expanderButton;
	}

	protected set expanderButton(v: ExpanderButtonComponent | undefined) {
		this._expanderButton = v;
		if (this._expanderButton) {
			this._expanderButton.toggle = (event: MouseEvent) => { void this.toggle(event); };
		}
	}

	@ViewChild('appBar') private headerBar: ElementRef<HTMLDivElement>;

	protected isExpanded: boolean;

	private modalService = inject(ModalService);
	private _expanderButton: ExpanderButtonComponent | undefined;

	protected selectTab(tab: TabComponent) {
		this.activeChange.emit(tab);
	}

	private async toggle(mouseEvent: MouseEvent) {
		this.isExpanded = !this.isExpanded;

		if (!this.isExpanded) {
			return;
		}

		const popupConfig: ListBoxConfig = {
			options: this.tabs.map((tab) => ({
				name: tab.label,
				active: tab.active,
				tab,
			})),
			minWidth: `${this.headerBar.nativeElement.clientWidth}px`,
			disableKeyNavigation: true,
			cssClass: 'full-height',
		};

		const result = await this.modalService.openAnchor(
			ListBoxComponent,
			mouseEvent,
			{
				target: this.headerBar.nativeElement,
				originX: ModalPositionAlignment.Left,
				originY: ModalPositionAlignment.Bottom,
				alignmentX: ModalPositionAlignment.Left,
				alignmentY: [ModalPositionAlignment.Bottom, ModalPositionAlignment.Top],
			},
			popupConfig,
		) as DropDownListBoxOption | undefined;

		if (result?.tab) {
			this.activeChange.emit(result.tab);
		}

		this.isExpanded = false;
	}

}
