import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataSeed } from '@unifii/sdk';

import { FilterEntry, FilterViewMode, IFilterComponent } from '../../../models';

@Component({
	selector: 'uf-filter-autocomplete',
	templateUrl: './filter-autocomplete.html',
	styleUrls: ['./field-filter.less'],
})
export class FilterAutoCompleteComponent implements IFilterComponent, OnInit {

	@Input() mode = FilterViewMode.Input;
	@Input({ required: true }) entry: FilterEntry;
	@Input() value: DataSeed | null = null;
	@Output() valueChange = new EventEmitter<DataSeed>();

	protected readonly modes = FilterViewMode;
	protected results: DataSeed[] = [];
	protected searchMinLength: number;

	private searchAbortController: AbortController | null = null;

	ngOnInit() {
		this.searchMinLength = this.entry.searchMinLength ?? 0;
	}

	get isEmpty() {
		return !this.value;
	}

	get label() {
		return this.entry.label;
	}

	protected async search(q?: string) {
		if (!this.entry.loader) {
			console.error('FilterAutoCompleteComponent: Loader not provided ');

			return;
		}

		if (this.searchAbortController) {
			this.searchAbortController.abort();
			// To guaranteed previous abort is digested
			await new Promise((resolve) => setTimeout(resolve, 0));
		}

		this.searchAbortController = new AbortController();
		this.results = await this.entry.loader.search(q, undefined, undefined, this.searchAbortController.signal);
	}

	protected remove() {
		this.value = null;
		this.valueChanged();
	}

	protected valueChanged() {
		this.valueChange.emit(this.isEmpty ? undefined : this.value as unknown as DataSeed);
	}

}
