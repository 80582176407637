<uf-data-display-list *ngIf="field">
	<dt>
		{{ field.shortLabel || field.label }}
		<uf-help *ngIf="field?.help" [content]="field.help" />
	</dt>
	<dd *ngFor="let file of content" class="small">
		<ng-container *ngIf="getItem(file) as item">
			<a [attachmentLink]="item.id" [href]="item.url">{{item.label}}</a>
		</ng-container>
	</dd>
</uf-data-display-list>