import { Component, Input } from '@angular/core';

import { DataDisplayComponentType } from '../../../models';

import { DataDisplayHrefValue, isDataDisplayHrefValue } from './data-display-components-model';

@Component({
	selector: 'uf-data-display-href',
	templateUrl: './data-display-href.html',
	styleUrls: ['../data-display.less'],
})
export class DataDisplayHrefComponent implements DataDisplayComponentType {
	
	@Input({ required: true }) data: DataDisplayHrefValue;

	static isDataAccepted = isDataDisplayHrefValue;
	
}
