import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Option } from '@unifii/sdk';

import { FilterEntry, FilterViewMode, IFilterComponent } from '../../../models';
import { CommonTranslationKey } from '../../../translations';

@Component({
	selector: 'uf-filter-multi-choice',
	templateUrl: './filter-multi-choice.html',
	styleUrls: ['./field-filter.less'],
})
export class FilterMultiChoiceComponent implements IFilterComponent {

	@Input() mode: FilterViewMode = FilterViewMode.Input;
	@Input() entry?: FilterEntry;
	@Input() value: Option[] = [];
	@Output() valueChange = new EventEmitter<Option[] | undefined>();

	protected readonly commonTK = CommonTranslationKey;
	protected readonly modes = FilterViewMode;

	protected results: Option[] = [];

	get label(): string {
		return this.entry?.label ?? '';
	}

	get isEmpty(): boolean {
		return !this.value.length;
	}

	protected displayOptionValue(v: Option): string {
		// TODO why v.identifier is not good enough?
		if (this.entry?.options) {
			const option = this.entry.options.find((o) => o.identifier === v.identifier);

			if (option) {
				return option.name;
			}
		}

		return v.identifier;
	}

	protected remove(index: number) {
		this.value.splice(index, 1);
		this.valueChanged();
	}

	protected valueChanged() {
		this.valueChange.emit(this.isEmpty ? undefined : [...this.value]);
	}

	protected search(q?: string) {
		if (!this.entry?.options) {
			console.warn('FilterMultiChoice options must be set');

			return;
		}

		const query = q?.toLowerCase().trim();

		if (!query?.length) {
			this.results = [...this.entry.options];

			return;
		}

		this.results = this.entry.options.filter((o) => o.name.toLowerCase().includes(query));
	}

}
