import { Injectable, inject } from '@angular/core';
import { CellTemplate, isBoolean, isNumber, isStringNotEmpty } from '@unifii/sdk';

import { ColumnDisplayDescriptor, TableConfigColumn } from '../components';
import { Context, Scope } from '../models';
import { UfExpressionFunctionsSet, getDateTimeFormattedNow, isIdentifiersPathExpression } from '../utils';

import { ContextProvider } from './context-provider';
import { DataDisplayService } from './data-display';
import { DataLookupService } from './data-lookup';
import { ExpressionParser } from './expression-parser';
import { TemplateStringParser } from './template-string-parser';

export type CustomCellDisplayValue = {
	value: unknown;
	template: CellTemplate | undefined;
}

type CustomCellInfo<T extends Scope> = {
	columnConfig: TableConfigColumn<T>;
	columnDisplayDescriptor: ColumnDisplayDescriptor;
	item: T; // row value
	index: number;
}

@Injectable({ providedIn: 'root' })
export class CustomCellDisplayValueResolver {

	private expressionParser = inject(ExpressionParser);
	private templateStringParser = inject(TemplateStringParser);
	private dataDisplayService = inject(DataDisplayService);
	private dataLookupService = inject(DataLookupService);
	private contextProvider = inject(ContextProvider);
	private readonly extendedFunctions = {
		...UfExpressionFunctionsSet,
		format: this.dataDisplayService.displayForTemplateExpression.bind(this.dataDisplayService),
	};

	/**
	 * Retrieve value and template for a cell that has a column custom configuration
	 */
	getDisplayValue<T extends Scope>({ columnConfig, columnDisplayDescriptor: columnDisplayDescriptor, item, index }: CustomCellInfo<T>): CustomCellDisplayValue {

		let template = columnDisplayDescriptor.defaultTemplate;
		let valueExpression = columnDisplayDescriptor.defaultCellValue;
		let displayValue: unknown;
		// TODO implement a generic getContext factory function
		const context: Context = Object.assign(this.contextProvider.get(), {
			now: getDateTimeFormattedNow(),
			self: {},
			root: {},
			item,
		});

		const matchingVariation = columnDisplayDescriptor.variations?.find((variation) => {
			return this.expressionParser.resolve(
				variation.condition,
				context,
				item,
				'CustomCellDisplayValueResolver.getDisplayValue - variation.condition',
			);
		});

		// Variation override
		if (matchingVariation) {
			valueExpression = matchingVariation.value;
			template = matchingVariation.template;
		}

		if (valueExpression) {
			displayValue = this.templateStringParser.parse(
				valueExpression,
				context,
				item,
				'CustomCellDisplayValueResolver.getDisplayValue - expression value',
				this.extendedFunctions,
			);
		} else {
			if (columnConfig.value != null) {
				displayValue = columnConfig.value(item, index);
			} else if (isIdentifiersPathExpression(columnDisplayDescriptor.name)) {
				displayValue = this.dataLookupService.lookupData(
					item,
					columnDisplayDescriptor.name,
					`CustomCellDisplayValueResolver.getDisplayValue - lookup value by identifier '${columnDisplayDescriptor.name}'`,
				);
			}
		}

		const displayStringValue = isStringNotEmpty(displayValue) || isNumber(displayValue) || isBoolean(displayValue) ? `${displayValue}` : null;

		return { value: displayStringValue, template };
	}

}
