<ng-template [ngForOf]="configs" let-config ngFor>

	<ng-template [ngIf]="isAnchored(config)" [ngIfElse]="centered">
		<uf-modal-anchored-render [config]="config" />
	</ng-template>

	<ng-template #centered>
		<div [@fadeInOut] class="mask"></div>
		<uf-modal-centered-render [config]="config" />
	</ng-template>

</ng-template>