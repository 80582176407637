import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[onclickFocus]',
})
export class OnclickFocusDirective {

	private walkTheDOMBlock = false;

	@HostListener('click', ['$event.target'])
	focusElement(target: HTMLElement) {
		this.walkTheDOMBlock = false;
		this.walkDOM(target);
	}

	private walkDOM(node: HTMLElement) {
		if (this.walkTheDOMBlock) {
			return;
		}

		this.focusInput(node);
		let nextNode = node.firstChild as HTMLElement | undefined;

		while (nextNode) {
			this.walkDOM(nextNode);
			nextNode = node.nextSibling as HTMLElement | undefined;
		}
	}

	private focusInput(node: HTMLElement) {
		if (node.tagName === 'INPUT' || node.tagName === 'TEXTAREA') {
			node.focus();
			this.walkTheDOMBlock = true;
		}
	}

}
