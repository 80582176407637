<label *ngIf="label">{{label}}
	<ng-content></ng-content>
</label>

<div #inputWrap class="input-wrap">

	<div *ngFor="let chip of value; let i = index" [class.focused]="focusedChip === i"
		class="uf-chip x-small center-all">
		{{chip | nameProperty:namePropertyFunc}}
		<button *ngIf="allowDelete(chip)" [title]="sharedTermsTK.ActionRemove | translate" (focus)="focusedChip = i"
			(blur)="focusedChip = null" (click)="delete(i)" type="button" data-type="chip" class="uf-action">
			<uf-icon name="delete" />
		</button>
	</div>

	<input #input [formControl]="queryControl" [attr.name]="name" [attr.placeholder]="value.length ? '' : placeholder"
		[readonly]="disabled" [tabindex]="disabled ? -1 : 0" [attr.autocomplete]="autocomplete"
		[noResultsMsg]="noResultsMsg" [container]="inputWrap" [template]="listBoxTemplate" [allowCustom]="allowCustom"
		[nameProperty]="nameProperty" [options]="options" [hasSearchConfigured]="hasSearchConfigured"
		[minSearchLength]="minSearchLength" [minSearchLengthMsg]="minSearchLengthMsg"
		(optionSelected)="onSelect($event)" type="text" listBoxResults />
	<div class="input-icon">
		<ng-template [ngIf]="queryControl.value && queryControl.value.length > 0 && !disabled" [ngIfElse]="search">
			<uf-icon [title]="sharedTermsTK.SearchActionClear | translate"
				(click)="clearQueryInput(); control.markAsDirty();" class="clear-btn" name="backspace" />
		</ng-template>
		<ng-template #search>
			<uf-icon name="search" />
		</ng-template>
	</div>
	<uf-error [control]="control" />
	<uf-underline *ngIf="!disabled" [class.active]="focused" />
	<uf-progress />
</div>