import { Component, HostBinding, Input, Optional } from '@angular/core';

import { SharedTermsTranslationKey } from '../../translations';

import { TableRowContext } from './table-models';
import { TableComponent } from './table.component';

@Component({
	selector: 'uf-table-card-expander',
	templateUrl: './table-card-expander.html',
	styleUrls: ['./table-card-expander.less'],
})
export class TableCardExpanderComponent<T> {

	@Input() context: TableRowContext<T> | null;

	readonly sharedTermsTK = SharedTermsTranslationKey;

	private _table: TableComponent<T> | null;

	constructor(
		@Optional() public parent: TableComponent<T> | null,
	) { }

	@HostBinding('class.visible') get visible(): boolean {
		if (!this.table?.config) {
			return false;
		}

		return this.table.config.cardExpands === true &&
			this.table.config.columns.find((c) => c.hidden === true) != null;
	}

	@Input() set table(t: TableComponent<T>) {
		this._table = t;
	}

	get table(): TableComponent<T> | null {
		return this._table ?? this.parent;
	}

	get expanded(): boolean {
		if (this.context) {
			return this.context.expanded;
		}

		return !!this.table?.cardsExpanded;
	}

	toggle() {
		if (!this.table || !this.context) {
			return;
		}

		this.table.toggleCardExpanded(this.context);
	}

}
