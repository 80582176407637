import { Component, HostBinding, Input, NgZone, OnInit, inject } from '@angular/core';

import { ActionMultiplicity, TableAction, TableRow } from './table-models';
import { TableComponent } from './table.component';

@Component({
	selector: 'uf-table-actions',
	templateUrl: './table-actions.html',
	styleUrls: ['./table-actions.less'],
})
export class TableActionsComponent<T> implements OnInit {

	@HostBinding('style.display') get display() {
		return this.table.status.selected.length ? 'none' : 'block';
	}

	@Input({ required: true }) row: TableRow<T>;

	protected actions?: TableAction<T>[];

	private table = inject(TableComponent<T>);
	private ngZone = inject(NgZone);

	ngOnInit() {
		this.actions = this.row.memoize.availableActions?.
			filter((action) => action.multiplicity !== ActionMultiplicity.Multiple);
	}

	protected selected(action: TableAction<T>) {
		// Guaranteed detection of mutations made within the action callback
		this.ngZone.run(() => {
			if (!action.action) {
				return;
			}

			if (action.multiplicity === ActionMultiplicity.Single) {
				void action.action(this.row.context);
			} else {
				void action.action([this.row.context]);
			}
		});
	}

}
