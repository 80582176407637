import { Component, OnInit } from '@angular/core';
import { FormRecord } from '@angular/forms';
import { LayoutDirection } from '@unifii/sdk';

import { Size } from '../../../constants';
import { RadioInputGroup, RadioInputTemplate, RadioOption } from '../../../models';

@Component({
	selector: 'uf-radio-inputs',
	templateUrl: './radio-inputs.html',
	styleUrls: ['./radio-inputs.less'],
})
export class RadioInputsComponent implements RadioInputGroup, OnInit {

	readonly nameProperty = 'name';
	readonly sizes = Size;

	focused: number | null;
	// style inputs
	direction: LayoutDirection = LayoutDirection.Row;
	rows: number;
	columns: number;
	// functional inputs
	groupName: string;
	formGroupName: string;
	formGroup: FormRecord;
	options: RadioOption[] = [];
	unmatchedValue: any;
	template: RadioInputTemplate;

	protected isButtonRadio: boolean;

	ngOnInit() {
		this.isButtonRadio = this.template === 'Button';
	}

	get value(): any {
		return this.formGroup.get(this.groupName)?.value;
	}

}
