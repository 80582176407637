import { Component } from '@angular/core';

import { DataDisplayContentModalValue, DataDisplayHrefValue, DataDisplayImageValue, DataDisplayListItem, DataDisplayLozengeValue, DataDisplayMapMarkerValue, DataDisplayRouterLinkValue } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

const longText = 'ajcloeichajaflkhasdfhalkjahsdfmbqwjaflkhasdfhalkjahsdfmbqwjaflkhasdfhalkjahsdfmbqwjaflkhasdfhalkjahsdfmbqwjaflkhasdfhalkjahsdfmbqwjaflkhasdfhalkjahsdfmbqwjaflkhasdfhalkjahsdfmbqw';
const brokenLongText = 'ajcloeicha/jaflkhasdfhalkjah/sdfmb/qwjaflkhasdfhalkjahsdfmbqwjaflkhasdfhalkjahsdfmbqwjaflkhasdfhal/kjah/sdfmbqwjaflkhasdfhalkjahsdfmbqwjaflkhasdfhalkjahsdfmbq/wjaflkhasdfhalkjahsdfmbqw';
const markdown = `The *italian* content is **important**, call us [04987541658](tel:)`;

@Component({
	selector: 'sc-data-display-list',
	templateUrl: './show-data-display-list.html',
})
export class ShowDataDisplayListComponent {

	// Table
	protected readonly pairCssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	
	protected readonly pairCss = [
		{ name: 'large', type: 'modifier', description: 'Increases the font size, line height and row gap' },
		{ name: 'small', type: 'modifier', description: 'Decreases the font size, line height and row gap' },
		{ name: 'stacked', type: 'modifier', description: 'Stacks list items' },
		{ name: 'stacked-center', type: 'modifier', description: 'Stacks list items, center title' },
		{ name: 'columns', type: 'modifier', description: 'Sets equal column widths' },
		{ name: 'small-label', type: 'modifier', description: 'Minimizes amount of space taken up by label' },
		{ name: 'split', type: 'modifier', description: 'Aligns value to right of column' },
	];

	protected readonly pairPropertiesColConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'default', name: 'Default' },
		{ identifier: 'description', name: 'Description' },
	];
	
	protected readonly pairPropertiesRows = [
		{
			name: 'value',
			type: 'DescriptionListItem[] | Dictionary<any>',
			default: '[]',
			description: 'Data input can be of type pair or any object',
		},
	];

	// Interactive
	protected readonly sizeOptions = [
		{ name: 'Large', value: 'large' },
		{ name: 'Medium (Default)', value: '' },
		{ name: 'Small', value: 'small' },
		{ name: 'Extra Small', value: 'x-small' },
	];
	
	protected readonly layoutOptions = [
		{ name: 'Columns 1-3 (Default)', value: '' },
		{ name: 'Columns 1-1', value: 'columns' },
		{ name: 'Small label', value: 'small-label' },
		{ name: 'Stacked', value: 'stacked' },
		{ name: 'Stacked, centered title', value: 'stacked-center' },
		{ name: 'Split', value: 'split' },
	];
	
	protected readonly colourOptions = [
		{ name: 'Default', value: '' },
		{ name: 'White', value: 'white' },
	];

	protected readonly rowGapOptions = [
		{ identifier: 'row-gap-lg', name: 'Large' },
		{ identifier: '', name: 'Medium (default)' },
		{ identifier: 'row-gap-sm', name: 'Small' },
	];

	protected readonly descriptionLozenge: DataDisplayLozengeValue = { label: 'Legend', colour: 'success' };
	protected readonly descriptionGeoLocation: DataDisplayMapMarkerValue[] = [
		{ label: 'They are here', marker: { lat: -33.67666,	lng: 151.28892 } },
		{ marker: { lat: -33.67666,	lng: 151.28892 } },
	];
	protected readonly descriptionHrefPhone: DataDisplayHrefValue = { label: '09230920934', href: 'tel:09230920934' };
	protected readonly descriptionHrefEmail: DataDisplayHrefValue = { label: 'ramones@music.music', href: 'mailto:ramones@music.music' };
	protected readonly descriptionRouterLink: DataDisplayRouterLinkValue = { label: 'Go', routerLink: ['/'] };
	protected readonly descriptionMarkdown: DataDisplayContentModalValue[] = [
		{ label: 'Show markdown in dialog', content: { markdown } },
		{ content: { markdown } },
	];

	protected readonly value: DataDisplayListItem[] = [
		{ term: 'First Name', data: 'Joey', help: 'test' },
		{ term: 'Surname', data: 'Ramone', help: 'test' },
		{ term: 'Occupation', data: 'Singer', help: 'test' },
		{ term: 'Status', data: this.descriptionLozenge as any, help: 'test' },
		{ term: 'Instruments', data: [
			'Vocals',
			'Drums',
			{ label: 'Guitar', href: 'https://en.wikipedia.org/wiki/Guitar', target: '_self' } satisfies DataDisplayHrefValue,
		], help: 'test' },
		{ term: 'Band', data: [
			'The Ramones',
			{ label: 'Wikipedia page', href: 'https://en.wikipedia.org/wiki/Ramones', target: '_blank' } satisfies DataDisplayHrefValue,
			{ imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/3/3a/Ramones_Toronto_1976.jpg' } satisfies DataDisplayImageValue,
		], help: 'test' },
		{ term: 'Location', data: this.descriptionGeoLocation, help: 'test' },
		{ term: 'Phone', data: this.descriptionHrefPhone, help: 'test' },
		{ term: 'Email', data: this.descriptionHrefEmail, help: 'test' },
		{ term: 'To Homepage', data: this.descriptionRouterLink, help: 'test' },
		{ term: 'Info', data: this.descriptionMarkdown, help: 'test' },
	];

	protected readonly debugValue: DataDisplayListItem[] = [
		{ term: 'a', data: '1' },
		{ term: 'b', data: longText },
		{ term: 'c', data: brokenLongText },

		{ term: longText, data: 'a' },
		{ term: longText, data: longText },
		{ term: longText, data: brokenLongText },

		{ term: brokenLongText, data: 'a' },
		{ term: brokenLongText, data: longText },
		{ term: brokenLongText, data: brokenLongText },
	];

	protected readonly staticTemplate = `
		<uf-data-display-list [items]="foo" #cssBinding />
	`;

	protected debug = false;
	protected size = '';
	protected layout = '';
	protected textColour = '';
	protected rowGap = '';
	protected template = `<uf-data-display-list [items]="foo" />`;

	protected modifierChange() {

		let cssBinding = [this.size, this.layout, this.textColour, this.rowGap].join(' ').trim();

		if (cssBinding !== '') {
			cssBinding = ` class="${cssBinding}"`;
		}

		this.template = this.staticTemplate.replace('#cssBinding', cssBinding);
	}

}
