import { Type } from '@angular/core';
import { FieldType } from '@unifii/sdk';

import { ComponentRegistryType, ContentField, FormComponentRegistryOptions } from '@unifii/library/smart-forms';
import { FieldMessageComponent } from '@unifii/library/smart-forms/display';

import { UfFormDescriptionListRegistry } from '../../services';

export class UfPrintFormDescriptionListRegistry extends UfFormDescriptionListRegistry {

	override type = ComponentRegistryType.Display;

	override get(name: string, options?: FormComponentRegistryOptions): Type<ContentField> | null {

		switch (name as FieldType) {
			case FieldType.Content: return FieldMessageComponent;
			default: return super.get(name, options);
		}
	}

}
