import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-selects.html',

})
export class ShowSelectsComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly sizeOptions = [
		{ name: 'Medium (default)', value: '' },
		{ name: 'Small', value: 'small' },
	];

	readonly directionOptions = [
		{ name: 'Row', value: 'Row' },
		{ name: 'Column', value: 'Column' },
	];

	readonly exampleOptions = [
		{ name: 'One', value: 'one' },
		{ name: 'Two', value: 'two' },
		{ name: 'Three', value: 'three' },
		{ name: 'Four', value: 'four' },
		{ name: 'Five', value: 'five' },
	];

	size = '';

	direction = 'Row';

	columns = 3;

	class = '';

	option = 'one';

	template = `
		<uf-select label="Select"></uf-select>
	`;

	readonly inputTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'default', name: 'Default' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly inputTableInfo = [
		{ name: 'control', type: 'UfControl', default: 'new UfControl()', description: 'A control object to manage validators, states etc.' },
		{ name: 'id', type: 'string', default: 'undefined', description: 'Sets id attribute of inner input element' },
		{ name: 'name', type: 'string', default: 'undefined', description: 'Sets name attribute of inner input element' },
		{ name: 'label', type: 'string', default: 'undefined', description: 'Sets label' },
		{ name: 'placeholder', type: 'string', default: 'undefined', description: 'Sets placeholder' },
		{ name: 'nameProperty', type: 'string | ((option: any) => string)', default: '\'name\'', description: 'Defines which property of the option object to use as a name' },
		{ name: 'valueProperty', type: 'string', default: 'undefined', description: 'Defines which property of the option object to use as a value' },
		{ name: 'trackBy', type: 'string', default: 'undefined', description: 'If no valueProperty provided, trackBy is used to get index of selected option item' },
	];
	readonly outputTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly outputTableInfo = [
		{ name: 'valueChange', type: 'EventEmitter', description: 'Emits an event when the value of the input is changed' },
	];
	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'default', type: 'modifier', description: 'Default input size' },
		{ name: 'small', type: 'modifier', description: 'Small input size' },
	];

	modifierChange() {
		this.class = this.size;
		this.template = `<uf-radio label="Select"${this.class ? ' class="' + this.class + '"' : ''}></uf-select>`;
	}

}
