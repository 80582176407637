import { Pipe, PipeTransform } from '@angular/core';

/**
 * A custom pipe that flattens an array by concatenating all sub-elements recursively.
 * It supports diving into sub-arrays or complex objects using the specified children property.
 */
@Pipe({	name: 'flatten' })
export class FlattenPipe<T> implements PipeTransform {

	/**
	 * Transforms the input array, flattening it by recursively concatenating sub-elements.
	 * @param value - The array to be flattened.
	 * @param childrenProperty - Optional. The property to access sub-arrays or complex objects.
	 * @returns A new array with all elements concatenated into it.
	 */
	transform(value: T[] | null | undefined, childrenProperty?: keyof T): T[] {
		if (!value) {
			return [];
		}

		const result: T[] = [];

		for (const item of this.iterator(value, childrenProperty)) {
			result.push(item);
		}

		return result;
	}

	private *iterator(items: T[] = [], childrenProperty?: keyof T): Iterable<T> {
		for (const item of items) {
			if (Array.isArray(item)) {
				yield *this.iterator(item );
			}

			yield item;

			if (childrenProperty) {
				yield *this.iterator(item[childrenProperty] as T[], childrenProperty);
			}
		}
	}

}
