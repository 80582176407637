import { Component, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { UfControl } from '../../controls';
import { PasswordService, PasswordStrengthResult } from '../../services';
import { CommonTranslationKey } from '../../translations';
import { UfControlValueAccessor } from '../form';

@Component({
	selector: 'uf-password-indicator',
	templateUrl: './password-indicator.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: PasswordIndicatorComponent, multi: true,
	}],
	styleUrls: ['./password-indicator.less'],
})
export class PasswordIndicatorComponent extends UfControlValueAccessor<string> {

	@Input() label?: string | null;

	readonly commonTK = CommonTranslationKey;

	protected strength: PasswordStrengthResult | undefined;
	protected strengthWidth: string;

	@Input() override set value(v: string | null | undefined) {
		super.value = v;
		this.updateIndicator();
	}

	override get value(): string | null | undefined {
		return super.value;
	}

	@Input() override set control(v: UfControl) {
		super.control = v;
		this.updateIndicator();
	}

	override get control(): UfControl {
		return super.control;
	}

	constructor(
		private passwordService: PasswordService,
		injector: Injector,
	) {
		super(injector);
	}

	override valueEmitPredicate(value?: string | null, prev?: string | null): boolean {
		this.updateIndicator();

		return super.valueEmitPredicate(value, prev);
	}

	private updateIndicator() {
		this.strength = this.passwordService.getStrength(this.value);
		this.strengthWidth = this.strength != null ? ((this.strength.score + 1) / 5) * 100 + '%' : '0';
	}

}
