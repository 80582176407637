import { AfterContentInit, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Dictionary, OPTION_NAME, isDictionary } from '@unifii/sdk';

import { UfControl } from '../../controls';
import { NamePropertyInfo } from '../../models';

import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-select',
	templateUrl: './uf-select.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfSelectComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-select.less'],
})
export class UfSelectComponent extends UfControlValueAccessor<any> implements AfterContentInit {

	@Input() id: string | null;
	@Input() name: string | null;
	@Input() label: string | null | undefined;
	@Input() placeholder: string | null | undefined;
	@Input() nameProperty: NamePropertyInfo | null | undefined = OPTION_NAME;
	@Input() valueProperty: string | null | undefined;
	@Input() trackBy: string | null;
	@Output() override valueChange = new EventEmitter<any>();

	unmatchedValue: any; /** Value previously set that no longer exist in options */
	error: boolean; // TODO not referenced, is it safe to delete?
	inputControl = new FormControl<Dictionary<any> | string | null>(null);

	private _options: any[] = [];
	private _focused: boolean;

	ngAfterContentInit() {
		this.updateInput(this.value);
		this.subscriptions.add(this.inputControl.valueChanges.subscribe((v) => { this.inputValueChange(v); }));
	}

	@Input() set options(v: any[] | undefined | null) {

		this._options = v ?? [];

		this.updateInput(this.value);
		// Reset last emit since options have been refreshed
	}

	get options(): any[] {
		return this._options;
	}

	@Input() override set value(v: any) {
		super.value = v;
		this.updateInput(v);
	}

	override get value(): any {
		return super.value;
	}

	@Input() override set control(v: UfControl) {
		super.control = v;
		this.updateInput(this.value);
	}

	override get control(): UfControl {
		return super.control;
	}

	set focused(v: boolean) {
		this._focused = v;
	}

	get focused(): boolean {
		return this._focused;
	}

	@HostBinding('class.focused') get focusedClass() {
		return this.focused && !this.disabled;
	}

	@HostBinding('class.error') get errorClass() {
		return this.control.showError && !this.disabled;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled || this.control.disabled;
	}

	@HostBinding('class.value') get valueClass() {
		return this.control.value != null;
	}

	reset() {
		this.options = [];
		this.unmatchedValue = null;
		this.value = '';

		this.control.setValue(undefined, { onlySelf: true, emitEvent: false });
		this.control.markAsUntouched();
		this.valueChange.emit(undefined);
	}

	override valueEmitPredicate(value: any | null, prev: any | null): boolean {
		this.updateInput(value);

		return super.valueEmitPredicate(value, prev);
	}

	private updateInput(value: any) {

		const { matched, unmatched } = this.matchToOption(value);
		const next = matched || unmatched;

		if (next !== this.inputControl.value) {
			this.inputControl.setValue(next, { emitEvent: false });
		}
		this.unmatchedValue = unmatched;
	}

	private matchToOption(value: any): { matched: any; unmatched: any } {

		const matched = this.options.find((option) => (
			value != null && (
			// Reference match,
				(option === value) ||
				// match using trackBy
				(this.trackBy && option[this.trackBy] != null && option[this.trackBy] === value[this.trackBy]) ||
				// match by valueProperty
				(this.valueProperty && option[this.valueProperty] === value))));

		let unmatched;

		if (matched == null) {
			unmatched = value || null;
		}

		return { matched, unmatched };
	}

	private inputValueChange(inputValue: Dictionary<any> | string | null) {

		if (!this.control.dirty) {
			this.control.markAsDirty();
		}

		if (!this.control.touched) {
			this.control.markAsTouched();
		}

		const value = this.valueProperty && isDictionary(inputValue) ? inputValue[this.valueProperty] : inputValue;

		this.control.setValue(value);
	}

}
