import { DestroyRef } from '@angular/core';
import { Subject } from 'rxjs';

export interface IntersectionChangeEvent {
	isIntersecting: boolean;
	target: Element;
}

export interface IntersectionObserverOptions {
	offsetTop?: number; // distance from the top of the container which the observable zone will start
	zoneHeight?: number; // height of the zone which will be observed. Default is 1px
	destroyRef?: DestroyRef; // used to automatically disconnect intersection observer
}

/**
 * IntersectionObserverWrapper simple wrapper for the native IntersectionObserver class
 * which emits changes if an elements scroll position intersects with the top of the
 * scrollable container
*/
export class IntersectionObserverWrapper {

	intersectionChanges = new Subject<IntersectionChangeEvent>();

	private observer: IntersectionObserver;
	private targets: Element[] = [];
	private offsetTop = 0;
	private zoneHeight?: number;
	private intersectingElements: Element[] = [];

	constructor(private scrollableContainer: HTMLElement, options?: IntersectionObserverOptions) {
		this.offsetTop = options?.offsetTop ?? 0;
		this.zoneHeight = options?.zoneHeight;

		if (options?.destroyRef) {
			options.destroyRef.onDestroy(() => { this.disconnect(); });
		}

		this.init();
	}

	disconnect() {
		this.observer.disconnect();
		this.intersectionChanges.complete();
	}

	observe(target: Element) {
		if (this.targets.includes(target)) {
			return;
		}

		this.targets.push(target);
		this.observer.observe(target);
	}

	unobserve(target: Element) {
		const index = this.targets.findIndex((t) => t === target);

		if (index < 0) {
			return;
		}

		this.targets.splice(index, 1);
		this.observer.unobserve(target);
	}

	resetObserver() {
		this.disconnect();

		this.init();

		for (const target of this.targets) {
			this.observe(target);
		}
	}

	private init() {
		let marginTop = 0;
		let marginBottom = this.scrollableContainer.clientHeight - this.offsetTop;

		if (this.offsetTop > 0) {
			marginTop = -(this.offsetTop - 1);
		}

		if (this.zoneHeight) {
			marginBottom -= this.zoneHeight;
		}

		// For rootMargin create a 1px intersection zone so only 1 item can intersect at a time
		const options = {
			rootMargin: `${marginTop}px 0px -${marginBottom}px 0px`,
			root: this.scrollableContainer,
			threshold: 0,
		};

		this.observer = new IntersectionObserver(this.onIntersectionChange.bind(this), options);
	}

	private onIntersectionChange(entries: IntersectionObserverEntry[]) {

		for (const { isIntersecting, target } of entries) {
			const index = this.intersectingElements.findIndex((e) => e === target);

			if (!isIntersecting) {
				if (index > -1) {
					this.intersectingElements.splice(index, 1);
				}

				this.intersectionChanges.next({ isIntersecting, target });
			} else if (index < 0) {
				this.intersectingElements.push(target);
			}
		}

		const lastIntersectingElement = this.intersectingElements[this.intersectingElements.length - 1];

		// Only emit the last intersecting element
		if (lastIntersectingElement) {
			this.intersectionChanges.next({ isIntersecting: true, target: lastIntersectingElement });
		}
	}

}
