import { Directive, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit, Renderer2, ViewContainerRef, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SortDirection, SortDirections } from '@unifii/sdk';

// Break circular dependency
import { IconComponent } from '../components/icons/icon.component';
import { SharedTermsTranslationKey } from '../translations';

import { SortDirective, Sortable } from './sort.directive';

@Directive({
	selector: '[ufSortElement]',
})
export class SortElementDirective implements Sortable, OnInit, OnDestroy {

	@HostBinding('class.sort-element') get classSort() {
		return !this.sortDisabled;
	}

	@HostBinding('class.sort-element-active') get classSortActive() {
		return !this.sortDisabled && this.direction != null;
	}

	@Input({ alias: 'ufSortElement', required: true }) id: string;
	@Input() sortDisabled = false;

	private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
	private viewContainerRef = inject(ViewContainerRef);
	private renderer = inject(Renderer2);
	private translateService = inject(TranslateService);
	private sortDirective = inject(SortDirective, { optional: true });
	private iconComponent: IconComponent | undefined;
	
	private get direction(): SortDirection | null {
		if (this.sortDirective?.sort?.name === this.id) {
			return this.sortDirective.sort.direction ?? null;
		}

		return null;
	}

	ngOnInit() {

		if (!this.sortDirective) {
			throw new Error('SortElementDirective: Master Directive is missing!');
		}

		if (!this.id) {
			throw new Error(`SortElementDirective: Identifier not specified`);
		}

		if (!this.sortDisabled) {
			this.sortDirective.register(this);

			this.elementRef.nativeElement.style.cursor = 'pointer';

			const ref = this.viewContainerRef.createComponent(IconComponent);

			this.renderer.appendChild(this.elementRef.nativeElement, ref.location.nativeElement);
			this.renderer.setAttribute(this.elementRef.nativeElement, 'title', this.translateService.instant(SharedTermsTranslationKey.ActionSort));

			this.iconComponent = ref.instance;
			this.iconComponent.el.nativeElement.classList.add('small');
			this.iconComponent.el.nativeElement.style.setProperty('margin-left', '0.5rem');
			this.iconComponent.el.nativeElement.style.setProperty('flex-shrink', '0');
			this.iconComponent.el.nativeElement.style.setProperty('opacity', '0.25');

			this.refresh();
		}
	}

	/*
	* Postpone deregister because if meanwhile this.master is null means the SortDirective has been destroyed too
	* In this case there is no need to deregister itself and trigger the chain reaction of a sort update
	*/
	ngOnDestroy() {
		setTimeout(() => {
			if (this.sortDirective) {
				this.sortDirective.deregister(this);
			}
		}, 10);
	}

	refresh() {
		if (!this.sortDirective || !this.iconComponent) {
			return;
		}

		// Non active sort
		if (!this.sortDirective.sort || this.sortDirective.sort.name !== this.id) {

			this.iconComponent.name = 'sort';
			this.iconComponent.el.nativeElement.style.setProperty('opacity', '0.25');

			return;
		}

		// Active sort
		switch (this.sortDirective.sort.direction) {
			case null:
			case SortDirections.Descending:
				this.iconComponent.name = 'sortUp';
				break;
			case SortDirections.Ascending:
				this.iconComponent.name = 'sortDown';
				break;
		}

		this.iconComponent.el.nativeElement.style.setProperty('opacity', '1');
	}

	/** Triggers the sort on this sort header and removes the indicator hint. */
	@HostListener('click', ['$event'])
	protected click() {
		if (this.sortDirective && !this.sortDisabled) {
			this.sortDirective.sortBy(this.id);
		}
	}

}
