import { DestroyRef, ElementRef, Injectable, OnDestroy, inject } from '@angular/core';
import { generateUUID } from '@unifii/sdk';
import { Subscription, debounceTime, fromEvent } from 'rxjs';

import { WindowWrapper } from '../native';
import { isHTMLElementRef } from '../utils';

type DOMHTMLElementEventHandlerRegisterOptions<EE extends keyof HTMLElementEventMap> = {
	element: ElementRef<HTMLElement> | HTMLElement;
	event: EE;
	listener: (event: HTMLElementEventMap[EE]) => void;
	listenerOptions?: AddEventListenerOptions | boolean;
	destroy: DestroyRef | undefined;
	debounceTime?: number;
}

type DOMWindowEventHandlerRegisterOptions<WE extends keyof WindowEventMap> = {
	event: WE;
	listener: (event: WindowEventMap[WE]) => void;
	listenerOptions?: AddEventListenerOptions | boolean;
	destroy: DestroyRef | undefined;
	debounceTime?: number;
}

/**
 * Orchestrator service for DOM 'addEventListener' with mechanics for unregister and destroy
 */
@Injectable({ providedIn: 'root' })
export class DOMEventHandler implements OnDestroy {

	private window = inject(WindowWrapper) as Window;
	private subscriptions = new Map<string, Subscription>();

	/**
	 * Register the requested event listener
	 * @param options.event - DOM event name @see {@link HTMLElementEventMap}
	 * @param options.listener - callback for the event
	 * @param options.listenerOptions - @see {@link AddEventListenerOptions}
	 * @param options.debounceTime - ms of debounce
	 * @param options.destroy - @see {@link DestroyRef}
	 * @returns {string} identify the event within the handler and allow remove via unregister
	 */
	register<WE extends keyof WindowEventMap>(options: DOMWindowEventHandlerRegisterOptions<WE>): string;
	/**
	 * Register the requested event listener
	 * @param options.element - target of the listener event (window otherwise) @see {@link HTMLElement} @see {@link ElementRef}
	 * @param options.event - DOM event name @see {@link HTMLElementEventMap}
	 * @param options.listener - callback for the event
	 * @param options.listenerOptions - @see {@link AddEventListenerOptions}
	 * @param options.debounceTime - ms of debounce
	 * @param options.destroy - @see {@link DestroyRef}
	 * @returns {string} identify the event within the handler and allow remove via unregister
	 */
	register<EE extends keyof HTMLElementEventMap>(options: DOMHTMLElementEventHandlerRegisterOptions<EE>): string;

	register(options: any): string {

		const uuid = generateUUID();

		const typedOptions = this.isWindowOptions(options) ? options : this.isElementOptions(options) ? options : undefined;

		if (!typedOptions) {
			console.warn(`DOMEventHandler.register - incompatible input 'options'`, options);

			return '';
		}

		const listenerOptions = typeof typedOptions.listenerOptions === 'boolean' ?
			{ capture: typedOptions.listenerOptions } as AddEventListenerOptions :
			typedOptions.listenerOptions;

		let element: HTMLElement | Window = this.window;

		if (this.isElementOptions(options)) {
			element = isHTMLElementRef(options.element) ? options.element.nativeElement : options.element;
		}

		let observable = listenerOptions ?
			fromEvent(element, typedOptions.event, listenerOptions) :
			fromEvent(element, typedOptions.event);

		if (typedOptions.debounceTime) {
			observable = observable.pipe(debounceTime(typedOptions.debounceTime));
		}

		const subscription = observable.subscribe((event) => { typedOptions.listener(event); });

		this.subscriptions.set(uuid, subscription);

		typedOptions.destroy?.onDestroy(() => { this.unregister(uuid); });

		return uuid;
	}

	/**
	 * Remove the event handler from the element
	 * @param registerId identifier of the handler
	 * @returns
	 */
	unregister(registerId: string) {
		const subscription = this.subscriptions.get(registerId);

		if (!subscription) {
			console.warn(`DOMEventHandler.unregister: listener ${registerId} not found, removeEventListener failed`);

			return;
		}

		subscription.unsubscribe();
		this.subscriptions.delete(registerId);
	}

	ngOnDestroy() {
		for (const registerId of this.subscriptions.keys()) {
			this.unregister(registerId);
		}
	}

	private isWindowOptions<WE extends keyof WindowEventMap>(options: any): options is DOMWindowEventHandlerRegisterOptions<WE> {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		return !options.element;
	}

	private isElementOptions<EE extends keyof HTMLElementEventMap>(options: any): options is DOMHTMLElementEventHandlerRegisterOptions<EE> {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		return !!options.element;
	}

}
