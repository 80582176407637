import { Directive, Input, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs';

// Break circular dependency
import { ListBoxOption } from '../components/modal/modals/list-box.component';
import { NamePropertyPipe } from '../pipes';
import { SharedTermsTranslationKey } from '../translations';

import { ListBoxDirective } from './list-box.directive';

/**
 * Directive that implement the specific logic to support search based options list, configurable with the following flags:
 * - noResultsMgs: override the default message provided when no options match the search input
 * - minSearchLength: generate a _notSelectable option to show the min length message
 * - minSearchLengthMsg: override the default message
 * - allowCustom: when the search input doesn't exactly match an option, allow the user to select the input itself as valid option
 * - customPrefixLabel: to compose the option display as: `${customPrefixLabel} '${option._display}'` example "select 'Australia'"
 */
@Directive({
	selector: 'input[listBoxResults], button[listBoxResults]',
})
export class ListBoxResultsDirective extends ListBoxDirective implements OnInit {

	@Input() minSearchLength: number | null | undefined;
	@Input() minSearchLengthMsg: string | null | undefined;
	@Input() minSearchClass: string | string[] | null | undefined;
	@Input() noResultsMsg: string | null | undefined;
	@Input() allowCustom: boolean | null | undefined;
	@Input() hasSearchConfigured: boolean | null | undefined;
	@Input() customPrefixLabel: string | null | undefined;
	@Input() formControl: FormControl<string | null> | null | undefined;

	protected readonly minSearchDebounce = 500;

	private lastInputStamp = 0;
	private translateService = inject(TranslateService);
	private namePropertyPipe = inject(NamePropertyPipe);

	protected override set pendingPopup(v: Promise<any> | null) {
		if (v == null) {
			this.lastInputStamp = 0;
		}
		this._pendingPopup = v;
	}

	protected override get pendingPopup(): Promise<any> | null {
		return this._pendingPopup;
	}

	override ngOnInit() {
		super.ngOnInit();

		// Add listeners to ensure options and custom options remain synced with input changes
		if (this.formControl) {
			this.subscriptions.add(this.formControl.valueChanges.subscribe((v) => {
				if (!(v ?? '').length) {
					this.lastInputStamp = 0;
				} else if (this.lastInputStamp === 0) {
					this.lastInputStamp = performance.now();
				}
				void this.openOrUpdateDialog();
			}));

			this.subscriptions.add(this.formControl.valueChanges.pipe(debounceTime(this.minSearchDebounce)).subscribe(() => {
				void this.openOrUpdateDialog();
			}));
		}
	}

	/* Override default logic for options to include minSearchLength, noResults and selectCustomValue 'virtual' options */
	protected override generateDisplayableOptions(): ListBoxOption[] {

		const queryValue = this.formControl?.value ?? '';
		const queryLength = queryValue.length;
		const numericalMinSearchLength = +(this.minSearchLength ?? 0);

		if (queryLength < numericalMinSearchLength) {

			if (queryLength === 0 || (performance.now() - this.lastInputStamp < (this.minSearchDebounce))) {
				return [];
			}

			return [{
				_notSelectable: true,
				_cssClass: this.minSearchClass,
				name: this.minSearchLengthMsg ?? this.translateService.instant(SharedTermsTranslationKey.SearchLabelMinSearchLength, { length: numericalMinSearchLength }) as string,
			}] as ListBoxOption[];
		}

		if (!this.options && this.hasSearchConfigured) {
			// Wait for async search callback to complete
			return [];
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const options = [...this.options ?? []] as ListBoxOption[];

		// Custom value
		if (this.allowCustom && queryLength > 0) {
			if (options.find((option) => this.namePropertyPipe.transform(option, this.nameProperty) === queryValue) == null) {
				options.unshift({
					_customOption: true,
					identifier: queryValue,
					name: this.customPrefixLabel ?? this.translateService.instant(SharedTermsTranslationKey.SearchLabelSelectResults) + ` '${queryValue}'`,
				});
			}

			return options;
		}

		// No results entry
		const noResultsMessage = this.noResultsMsg === undefined ? this.translateService.instant(SharedTermsTranslationKey.SearchLabelNoResults) as string : this.noResultsMsg;

		if (options.length === 0 && noResultsMessage) {
			options.push({
				_notSelectable: true,
				name: noResultsMessage,
			});
		}

		return options;
	}

}
