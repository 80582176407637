import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	selector: 'sc-show-progress',
	templateUrl: './show-progress.html',
})
export class ShowProgressComponent implements OnInit {

	progressValue: boolean;

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	/** Table */
	readonly propertiesColConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'default', name: 'Default' },
		{ identifier: 'description', name: 'Description' },
	];

	readonly propertiesRows = [
		{
			name: 'progress',
			type: 'number',
			default: 'undefined',
			description: 'Sets the position of the progress bar',
		},
		{
			name: 'inProgress',
			type: 'boolean',
			default: 'undefined',
			description: 'Starts progress and finishes progress with true/false input',
		},
		{
			name: 'start',
			type: 'method',
			default: '',
			description: 'Provides the ability to programmatically start progress',
		},
		{
			name: 'complete',
			type: 'method',
			default: '',
			description: 'Completes any progress operation',
		},
	];
	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'large', type: 'modifier', description: 'Increases this progress bar size' },
	];

	readonly linearProgressSource = `<uf-progress [progress]="yourProgress" #size></uf-progress>`;
	readonly linearSizeOptions = [
		{ name: 'Small (Default)', value: '' },
		{ name: 'Large', value: 'large' },
	];
	readonly durationOptions = [
		{ name: 'Random', value: 0 },
		{ name: '.5 Second', value: 500 },
		{ name: '2 Seconds', value: 2000 },
		{ name: '3 Seconds', value: 3000 },
		{ name: '5 Seconds', value: 5000 },
		{ name: '1 Minute', value: 60000 },
		{ name: '5 Minutes', value: 300000 },
		{ name: '10 Minutes', value: 600000 },
	];

	inProgress = false;
	linearTemplate: string;
	value: number | undefined = 0;
	linearSize = '';
	duration = 0;

	private timeoutIds: NodeJS.Timeout[] = [];

	constructor() {

		interval(600).subscribe(() => {
			this.value = this.calcProgress(this.value);
		});
	}

	ngOnInit() {
		this.modifierChange();
	}

	modifierChange() {
		const size = this.linearSize !== '' ? `class="${this.linearSize}"` : '';

		this.linearTemplate = this.linearProgressSource.replace('#size', size);
	}

	durationChange(duration: number) {

		this.clearDurationTimeouts();

		if (duration === 0) {
			return;
		}

		this.inProgress = false;

		this.timeoutIds.push(setTimeout(() => {
			this.inProgress = true;
			this.addProgressEvent(false, duration);
		}, 405));
	}

	private addProgressEvent(value: boolean, delay = 0) {
		this.timeoutIds.push(setTimeout(() => {
			this.inProgress = value;
		}, delay));
	}

	private clearDurationTimeouts() {
		for (const id of this.timeoutIds) {
			clearTimeout(id);
		}
	}

	private calcProgress(current: number | undefined): number | undefined {

		if (current === 1) {
			return undefined;
		}

		if (current === undefined) {
			return 0;
		}

		if (current > 0.8) {
			return 1;
		}

		return (Math.random() * (1 - current) / 4) + current;
	}

}
