import { ShowBreadcrumbsComponent } from './show-breadcrumbs.component';
import { ShowCarouselComponent } from './show-carousel.component';
import { ShowLinksComponent } from './show-links.component';
import { ShowSteppersComponent } from './show-steppers.component';
import { ShowTabsComponent } from './show-tabs.component';

export * from './show-breadcrumbs.component';
export * from './show-links.component';
export * from './show-steppers.component';
export * from './show-tabs.component';
export * from './show-carousel.component';

export const SC_PAGE_NAVIGATION_COMPONENTS = [
	ShowBreadcrumbsComponent, ShowLinksComponent, ShowSteppersComponent, ShowTabsComponent, ShowCarouselComponent,
];
