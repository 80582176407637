import { Inject, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AstNode, DataSeed, RequestAnalytics, RequestAnalyticsOrigin } from '@unifii/sdk';

import { Context, DataLoaderFactory, DataSourceLoader, RuntimeField, Scope, SourceConfig, SourceConfigQueryBuilder } from '@unifii/library/common';

import { FormField, FormSettings } from '../models';

import { FieldWarning } from './field-warning';
import { FormService } from './form.service';
import { ScopeManager } from './scope-manager';

export class FieldDataSourceManager {

	private dataLoader: DataSourceLoader | undefined;
	private fieldWarning: FieldWarning;
	private searchAbortController: AbortController | null;

	constructor(
		private component: FormField,
		private field: RuntimeField,
		private container: ViewContainerRef,
		@Inject(FormSettings) private formSettings: FormSettings,
		private scopeManager: ScopeManager,
		private formService: FormService,
		private translate: TranslateService,
	) {
		this.dataLoader = this.createDataSourceLoader(this.field, this.formSettings);
		this.fieldWarning = new FieldWarning(this.component, this.container, this.translate);
	}

	get sourceConfig(): SourceConfig | undefined {
		return this.dataLoader?.sourceConfig;
	}

	get requestAnalytics(): RequestAnalytics {
		return {
			origin: RequestAnalyticsOrigin.Form,
			id: `${this.formService.definitionIdentifier}.${this.field.identifier}`,
		};
	}

	get searchAbortSignal() {
		return this.searchAbortController?.signal;
	}

	async getValue(id: string): Promise<DataSeed | undefined> {

		if (!this.dataLoader) {
			return;
		}

		try {
			return await this.dataLoader.get(id) ?? undefined;
		} catch (error) {
			this.fieldWarning.create(error);

			return;
		}
	}

	async search(q?: string, customContext?: Context, customScope?: Scope, contextFilters?: AstNode): Promise<DataSeed[]> {

		if (!this.dataLoader) {
			return [];
		}

		const context = Object.assign(this.scopeManager.createContext(this.component.content), customContext ?? {});
		const scope = Object.assign(this.scopeManager.createScope(), customScope ?? {});

		try {
			if (this.searchAbortController) {
				this.searchAbortController.abort();
				await new Promise((resolve) => setTimeout(resolve, 0)); // To guaranteed previous abort is digested
			}
			this.searchAbortController = new AbortController();
			const seeds = await this.dataLoader.search(q, context, scope, this.searchAbortController.signal, contextFilters);

			this.fieldWarning.destroy();

			return seeds;
		} catch (error) {
			if (!this.searchAbortController?.signal.aborted) {
				this.fieldWarning.create(error as Error);
			}

			return [];

		} finally {
			this.searchAbortController = null;
		}
	}

	async findAllBy(match: string, customContext?: Context, customScope?: Scope): Promise<DataSeed[]> {

		if (!this.dataLoader) {
			return [];
		}

		const context = Object.assign(this.scopeManager.createContext(this.component.content), customContext ?? {});
		const scope = Object.assign(this.scopeManager.createScope(), customScope ?? {});

		try {
			if (this.searchAbortController) {
				this.searchAbortController.abort();
				await new Promise((resolve) => setTimeout(resolve, 0)); // To guaranteed previous abort is digested
			}
			this.searchAbortController = new AbortController();
			const seeds = await this.dataLoader.findAllBy(match, context, scope, this.searchAbortController.signal);

			this.fieldWarning.destroy();

			return seeds;
		} catch (error) {
			if (!this.searchAbortController?.signal.aborted) {
				this.fieldWarning.create(error as Error);
			}

			return [];
		} finally {
			this.searchAbortController = null;
		}
	}

	async getOptions(): Promise<DataSeed[]> {

		if (!this.dataLoader) {
			console.warn(`Dataloader not created ${this.field.label}, field:`, this.field);

			return [];
		}

		const context = this.scopeManager.createContext(this.component.content);
		const scope = this.scopeManager.createScope();

		try {

			let options = (await this.dataLoader.getOptions(context, scope));

			// Retro-compatibility for dataSources without internal query limit
			options = options.slice(0, SourceConfigQueryBuilder.queryLimit);
			// Remove message
			this.fieldWarning.destroy();

			return options;

		} catch (error) {
			if (!this.component.control || !this.component.control.disabled) {
				this.fieldWarning.create(error as Error);
			}
			console.error(error);

			return [];
		}
	}

	mapToSeed(value: any): DataSeed | null {
		return this.dataLoader?.mapToSeed(value) ?? null;
	}

	private createDataSourceLoader(field: RuntimeField, settings: FormSettings): DataSourceLoader | undefined {

		if (!(settings.dataLoaderFactory as DataLoaderFactory | null)) {
			console.error(`No DataSourceLoader found for ${field.identifier ?? ''}`);

			return;
		}

		return settings.dataLoaderFactory.create(field.sourceConfig, { requestAnalytics: this.requestAnalytics });
	}

}
