import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { DataType, ZonedDateTime, isString } from '@unifii/sdk';

import { FilterEntry, FilterType, FilterViewMode, IFilterComponent } from '../../../models';
import { DataDisplayService } from '../../../services';
import { SharedTermsTranslationKey } from '../../../translations';

// eslint-disable-next-line id-denylist
type FilterTempoDataTypes = DataType.Date | DataType.Time | DataType.DateTime | DataType.ZonedDateTime | DataType.String;

@Component({
	selector: 'uf-filter-tempo',
	templateUrl: './filter-tempo.html',
	styleUrls: ['./field-filter.less'],
})
export class FilterTempoComponent implements IFilterComponent {

	@Input() mode = FilterViewMode.Input;
	@Input({ required: true }) entry: FilterEntry;
	@Input() value: string | ZonedDateTime | null = null;
	@Output() valueChange = new EventEmitter<string>();

	protected readonly sharedTerms = SharedTermsTranslationKey;
	protected readonly filterTypes = FilterType;
	protected readonly modes = FilterViewMode;

	private dataDisplayService = inject(DataDisplayService);

	get isEmpty(): boolean {
		if (this.value == null) {
			return true;
		}

		if (isString(this.value)) {
			return !this.value.trim().length;
		}

		return !this.value.value.trim().length;
	}

	get label(): string {
		return this.entry.label;
	}

	// TODO Start - typed wrapper of value to be reviewed
	protected get valueString(): string {
		return this.value as string;
	}

	protected set valueString(v: string) {
		this.value = v;
	}

	protected get valueZDT(): ZonedDateTime {
		return this.value as ZonedDateTime;
	}

	protected set valueZDT(v: ZonedDateTime) {
		this.value = v;
	}
	// TODO End - typed wrapper of value to be reviewed

	protected get displayValue(): string | null {
		const dataType = this.getDataType(this.entry.type);

		return this.dataDisplayService.displayAsString(this.value, { type: dataType });
	}

	protected remove() {
		this.value = null;
		this.valueChanged();
	}

	protected valueChanged() {
		this.valueChange.emit(this.isEmpty ? undefined : this.value as string);
	}

	private getDataType(type: FilterType | undefined): FilterTempoDataTypes {
		switch (type) {
			case FilterType.Date:
				return DataType.Date;
			case FilterType.Time:
				return DataType.Time;
			case FilterType.Datetime:
				return DataType.DateTime;
			case FilterType.ZonedDatetime:
				return DataType.ZonedDateTime;
			default:
				return DataType.String;
		}
	}

}
