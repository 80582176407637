import { Injectable } from '@angular/core';

import { ToasterComponent } from '../components';
import { MessageLevel } from '../constants';
import { ToastData, ToastOptions } from '../models';

@Injectable({ providedIn: 'root' })
export class ToastService {

	toaster?: ToasterComponent;

	private durations = new Map<MessageLevel, number>([
		[MessageLevel.Success, 2000],
		[MessageLevel.Info, 3000],
		[MessageLevel.Warning, 3000],
	]);

	info(message: string, options?: ToastOptions) {
		this.show(MessageLevel.Info, message, options);
	}

	success(message: string, options?: ToastOptions) {
		this.show(MessageLevel.Success, message, options);
	}

	warning(message: string, options?: ToastOptions) {
		this.show(MessageLevel.Warning, message, options);
	}

	error(message: string, options?: ToastOptions) {
		this.show(MessageLevel.Error, message, options);
	}

	registerToaster(toaster: ToasterComponent) {
		this.toaster = toaster;
	}

	remove(data: ToastData) {

		if (this.toaster == null) {
			return;
		}

		const existingToastIndex = this.toaster.toasts.indexOf(data);
		// console.log('Remove', data, 'position', existingToastIndex);

		if (existingToastIndex > -1) {
			this.toaster.toasts.splice(existingToastIndex, 1);
			clearTimeout(data.timeout);
		}
	}

	/** When the entry (same level and message) is already present, the existing one is removed and replaced by the new one */
	private show(level: MessageLevel, message: string, options?: ToastOptions) {

		if (!this.toaster) {
			return;
		}

		const toast: ToastData = { level, message };
		const duration = options?.duration ?? this.durations.get(level);

		if (duration === 0) {
			return;
		}

		const existingToastIndex = this.toaster.toasts.findIndex((t) => t.level === toast.level && t.message === toast.message);
		const existingToast = this.toaster.toasts[existingToastIndex];

		// Update existing toast
		if (existingToast) {

			if (this.toaster.toasts.length !== 1) {
				this.toaster.toasts.splice(existingToastIndex, 1);
				this.toaster.toasts.push(existingToast);
			}

			if (existingToast.timeout) {
				clearTimeout(existingToast.timeout);
			}

			if (duration && duration > 0) {
				existingToast.timeout = setTimeout(() => { this.remove(existingToast); }, duration);
				// console.log('Updated', existingToast.level, 'duration', duration, 'timeoutId', existingToast.timeout);
			}

			return;
		}

		// Push new toast
		this.toaster.toasts.push(toast);
		if (duration && duration > 0) {
			clearTimeout(toast.timeout);
			toast.timeout = setTimeout(() => { this.remove(toast); }, duration);
			// console.log('Added', toast.level, 'duration', duration, 'timeoutId', toast.timeout);
		}
	}

}
