import { InjectionToken, Type } from '@angular/core';

import { DataDisplayComponentType } from '../../models';

/**
 * A registry that get the DataDisplayComponentType associated with the provided value
 */
export interface DataDisplayComponentRegistry {
	/**
	 * Determine the component responsible for the display of the value
	 * @param data to be displayed
	 * @returns DataDisplayComponentType to display the value
	 */
	get: (data: unknown) => Type<DataDisplayComponentType> | null;

	/**
	 * Transform hook executed before the data is used to detect what is the component responsible for the display
	 * @param data 
	 * @returns 
	 */
	preDataTransform: (data: unknown) => unknown;
}

export const DataDisplayComponentRegistry = new InjectionToken<DataDisplayComponentRegistry>('DataDisplayComponentRegistry');
export const DataDisplayTableComponentRegistry = new InjectionToken<DataDisplayComponentRegistry>('DataDisplayTableComponentRegistry');
