import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { Client, CompaniesClient, Company, Definition, FormData, FormDataClient, Option, ProjectContentOptions, ProjectContentOptionsInterface, Query, Table, TableDetail, TableSourceType, UserInfo, UsersClient, ensureError } from '@unifii/sdk';

import { DataDescriptor, DataDescriptorService, RuntimeDefinition, RuntimeDefinitionAdapter, RuntimeField } from '@unifii/library/common';

import { FormInfo, TableInfo, UsAPIContentClient } from '../services';

const EntityUserType = 'user';
const EntityCompanyType = 'company';
const EntityFormDataRepositoryType = 'bucket';
const EntityTableType = 'table';

type EntityType = typeof EntityUserType | typeof EntityCompanyType | typeof EntityFormDataRepositoryType | typeof EntityTableType;

type TableData = FormData | UserInfo | Company;

export type EntityPickerUserOutput = {
	type: typeof EntityUserType;
	dataDescriptor: DataDescriptor;
	user?: UserInfo;
}

export type EntityPickerFormDataRepositoryOutput = {
	type: typeof EntityFormDataRepositoryType;
	dataDescriptor: DataDescriptor;
	formData?: FormData;
	definition?: RuntimeDefinition;
}

export type EntityPickerTableOutput = {
	type: typeof EntityTableType;
	dataDescriptor: DataDescriptor;
	table: Table;
	tableDetail?: TableDetail;
	row?: TableData;
}

export type EntityPickerOutput = EntityPickerUserOutput | EntityPickerFormDataRepositoryOutput | EntityPickerTableOutput;

@Component({
	selector: 'sc-entity-picker',
	templateUrl: './show-entity-picker.html',
})
export class ShowEntityPickerComponent implements OnInit {

	@Input() type: EntityType | null;
	@Input() pickData = false;

	@Output() emitChange = new EventEmitter<EntityPickerOutput>();

	protected entityTypes: Option[] = [
		{ identifier: EntityUserType, name: 'User' },
		{ identifier: EntityFormDataRepositoryType, name: 'Form Data Repository' },
		{ identifier: EntityTableType, name: 'Table' },
		{ identifier: EntityCompanyType, name: 'Company' },
		// { identifier: DataDescriptorCollectionType, name: 'Collection' },
	];

	// Manual
	protected entityType: EntityType;
	protected buckets: string[];
	protected bucketOptions: string[];
	protected bucketSelected: string | null;
	protected collections: Option[];
	protected collectionOptions: Option[];
	protected collectionSelected: Option | null;

	// Data
	protected userOptions: UserInfo[] = [];
	protected userSelected: UserInfo | null;
	protected companyOptions: Company[] = [];
	protected companySelected: Company | null;
	protected formDataOptions: FormData[] = [];
	protected formDataSelected: FormData | null;

	// Table
	protected tables: TableInfo[];
	protected tableOptions: TableInfo[];
	protected tableSelected: TableInfo | null;
	protected tableDataOptions: TableData[];
	protected tableDataSelected: TableData | null;

	// Field DS
	protected forms: FormInfo[];
	protected formOptions: FormInfo[];
	protected formSelected: Definition | null;
	protected fields: RuntimeField[] = [];
	protected fieldOptions: RuntimeField[];
	protected fieldSelected: RuntimeField | null;
	protected error: string | null;

	private projectContentOptions = inject(ProjectContentOptions) as ProjectContentOptionsInterface;
	private usContent = inject(UsAPIContentClient);
	private client = inject(Client);
	private usersClient = inject(UsersClient);
	private companiesClient = inject(CompaniesClient);
	private dataDescriptorService = inject(DataDescriptorService);
	private runtimeDefinitionAdapter = inject(RuntimeDefinitionAdapter);

	protected get canEmit(): boolean {
		if (this.error) {
			return false;
		}

		switch (this.entityType) {
			case EntityUserType:
				return !this.pickData || !!this.userSelected;
			case EntityFormDataRepositoryType:
				return !!this.bucketSelected && (!this.pickData || !!this.formDataSelected);
			case EntityTableType:
				return !!this.tableSelected && (!this.pickData || !!this.tableDataSelected);
			default:
				return false;
		}
	}

	async ngOnInit() {

		if (this.type) {
			this.entityType = this.type;
		}

		try {
			this.forms = await this.usContent.getForms();
			this.buckets = [...new Set(this.forms.map((d) => d.bucket ))];
			this.collections = [...new Set((await this.usContent.getCollections()).map((d) => ({
				identifier: d.identifier,
				name: d.name,
			})))];

			this.tables = (await this.usContent.getTables()).map((t) => {
				const type = `${t.sourceType}${t.sourceType === TableSourceType.Bucket ? ': ' + t.source : ''}`;

				t.title = `[${type}] (${t.identifier}) ${t.title} ${t.hasDetail ? ' (DetailsPage)' : ''}`;

				return t;
			});
		} catch (e) {
			this.error = ensureError(e).message;
		}
	}

	protected async go() {

		let output: EntityPickerOutput | undefined;
		
		switch (this.entityType) {
			case 'user':
				if (this.userSelected) {
					delete (this.userSelected as any).__label;
				}

				output = {
					type: this.entityType,
					dataDescriptor: await this.getDataDescriptor(this.entityType),
					user: this.userSelected ?? undefined,
				};
				break;
			case 'bucket': {
				let definition: RuntimeDefinition | undefined;

				if (this.formDataSelected?._definitionIdentifier) {
					const def = await this.usContent.getForm(this.formDataSelected._definitionIdentifier);
					
					definition = await this.runtimeDefinitionAdapter.transform(def);
				}

				if (this.formDataSelected) {
					delete (this.formDataSelected as any).__label;
				}

				output = {
					type: this.entityType,
					dataDescriptor: await this.getDataDescriptor(this.entityType, this.bucketSelected),
					formData: this.formDataSelected ?? undefined,
					definition,
				};
			}
				break;
			case 'table': {
				if (!this.tableSelected) {
					return;
				}

				const table = await this.usContent.getTable(this.tableSelected.identifier);
				const tableDetail = this.tableSelected.hasDetail ? await this.usContent.getTableDetail(this.tableSelected.id) : undefined;
				const tableEntityType = this.tableSourceTypeToEntityType(table.sourceType);
				const descriptor = await this.getDataDescriptor(tableEntityType, table.source);

				if (this.tableDataSelected) {
					delete (this.tableDataSelected as any).__label;
				}

				output = {
					type: this.entityType,
					dataDescriptor: descriptor,
					table,
					tableDetail,
					row: this.tableDataSelected ?? undefined,
				};
			}
				break; 
		}

		this.emitChange.emit(output);
	}

	protected onEntityTypeChange() {
		this.error = null;
		this.userSelected = null;
		this.collectionSelected = null;
		this.tableSelected = null;
		this.formSelected = null;
		this.bucketSelected = null;
		this.fieldSelected = null;
		this.formDataSelected = null;
		this.tableDataSelected = null;
	}

	protected searchFormDataRepository(q?: string) {
		this.bucketOptions = q ? this.buckets.filter((bucket) => bucket.includes(q)) : [...this.buckets];
	}

	protected searchCollection(q?: string) {
		this.collectionOptions = !q ? [...this.collections] : this.collections.filter((o) => o.identifier.includes(q) || o.name.includes(q));
	}

	protected searchTable(q?: string) {
		this.tableOptions = q ? this.tables.filter((t) => t.title.toLowerCase().includes(q.toLowerCase()) || t.identifier.toLowerCase().includes(q.toLowerCase()) ) : [...this.tables];
	}

	protected searchForm(q?: string) {
		this.formOptions = q ? this.forms.filter((f) => f.name.toLowerCase().includes(q.toLowerCase())) : [...this.forms];
	}

	protected async searchUser(q?: string) {
		this.userOptions = await this.getUserOptions(q);
	}

	protected async searchCompany(q?: string) {
		this.companyOptions = await this.getCompanyOptions(q);
	}

	protected async searchFormData(q?: string) {
		this.formDataOptions = await this.getFormDataOptions(this.bucketSelected, q);
	}

	protected async searchTableData(q?: string) {
		if (!this.tableSelected) {
			this.tableDataOptions = [];
		}

		switch (this.tableSelected?.sourceType) {
			case TableSourceType.Bucket:
				this.tableDataOptions = await this.getFormDataOptions(this.tableSelected.source, q);
				break;
			case TableSourceType.Users:
				this.tableDataOptions = await this.getUserOptions(q);
				break;
			case TableSourceType.Company:
				this.tableDataOptions = await this.getCompanyOptions(q);
		}

	}

	private async getDataDescriptor(type: Exclude<EntityType, typeof EntityTableType>, id?: string | null | undefined): Promise<DataDescriptor> {

		let descriptor: DataDescriptor | undefined;

		switch (type) {
			case 'user':
				descriptor = await this.dataDescriptorService.getUserDataDescriptor();
				break;
			case 'company':
				descriptor = await this.dataDescriptorService.getCompanyDataDescriptor();
				break;
			// case DataSourceType.Collection:
			// 	descriptor = id ? await this.dataDescriptorService.getCollectionDataDescriptor(id) : undefined;
			// 	break;
			case 'bucket':
				descriptor = id ? await this.dataDescriptorService.getBucketDataDescriptor(id) : undefined;
				break;
		}

		if (!descriptor) {
			throw new Error('Failed to load the DataDescriptor for entity `${type}`');
		}

		return descriptor;
	}

	private tableSourceTypeToEntityType(sourceType: TableSourceType): Exclude<EntityType, typeof EntityTableType> {
		switch (sourceType) {
			case TableSourceType.Bucket: return 'bucket';
			case TableSourceType.Users: return 'user';
			case TableSourceType.Company: return 'company';
		}
	}

	private async getUserOptions(q?: string) {
		let query = new Query();
		
		if (q) {
			query = query.q(q);
		}
		
		return (await this.usersClient.query(query))
			.map((user) => Object.assign(user, { __label: user.username }));
	}

	private async getCompanyOptions(q?: string) {
		let query = new Query();
		
		if (q) {
			query = query.q(q);
		}
		
		return (await this.companiesClient.query(query))
			.map((company) => Object.assign(company, { __label: company.name }));
	}

	private async getFormDataOptions(bucket: string | null | undefined, formDataId?: string) {
		if (!bucket) {
			return Promise.resolve([]);
		}

		const formDataClient = new FormDataClient(this.client, {
			preview: this.projectContentOptions.preview,
			projectId: this.projectContentOptions.projectId,
			bucket,
		});

		let query = new Query();
		
		if (formDataId) {
			query = query.and(Query.ge('id', formDataId), Query.le('id', formDataId + 'zzzz'));
		}

		return (await formDataClient.query(query))
			.map((formData) => Object.assign(formData, { __label: `${formData.id} - [${formData._seqId}]` }));
	}

}
