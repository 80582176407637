import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { FieldType, FormContentTemplate, FormData, FormStyle } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { RuntimeField, UfControlGroup } from '@unifii/library/common';
import { ComponentRegistry, FieldComponent, FormContainerField, FormRevisionService, FormService, NavigationService, RevisionInfo, WorkflowService } from '@unifii/library/smart-forms';

// Break circular dependency
import { FormComponentRegistry } from '../../../services/form-component-registry';
import { FormDescriptionListRegistry } from '../../../services/form-description-list-component-registry';

export const componentRegistryFactory = (
	parent: FieldComponent,
	workflowService: WorkflowService,
	formService: FormService,
	formRegistry: ComponentRegistry,
	descriptionListRegistry: ComponentRegistry,
): ComponentRegistry => {

	if (formService.style === FormStyle.Summary && (!workflowService.isActive(parent.field) || !formService.isGranted(parent.field))) {
		return descriptionListRegistry;
	}

	return formRegistry;
};

@Component({
	selector: 'uf-section',
	templateUrl: './section.html',
	providers: [{
		provide: ComponentRegistry, useFactory: componentRegistryFactory, deps: [
			FieldComponent, WorkflowService, FormService, FormComponentRegistry, FormDescriptionListRegistry,
		],
	},
	],
	styleUrls: ['../form-group.less', './section.less'],
})
export class SectionComponent implements FormContainerField, OnInit, AfterViewInit, OnDestroy {

	@HostBinding('class.uf-form-group') groupClassName = true;

	readonly fieldTypes = FieldType;

	field: RuntimeField;
	scope: FormData;
	control: UfControlGroup;
	contentChange: EventEmitter<any>;
	contentExpanded: boolean;
	cssClass: string | string[];
	disabled: boolean;
	revisionData: RevisionInfo[] = [];
	elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

	private formService = inject(FormService, { optional: true });
	private workflowService = inject(WorkflowService, { optional: true });
	private revisionService = inject(FormRevisionService, { optional: true });
	private navService = inject<NavigationService<RuntimeField>>(NavigationService);
	private subscriptions = new Subscription();

	@HostBinding('class.collapsed') get hostCollapsed() {
		return !this.contentExpanded;
	}

	@HostBinding('class.disabled') get hostDisabled() {
		return this.control.disabled && !this.isSummary;
	}

	@HostBinding('attr.data-active') get hostActive() {
		return !this.control.disabled;
	}

	ngOnInit() {
		if (this.formService?.definitionSettings.isNavigationEnabled) {
			this.navService.register(this.field, this.elementRef.nativeElement);
		}

		if (this.revisionService) {
			void this.revisionService.getRevisionData(this.field).then((revisionData) => {
				this.revisionData = revisionData;
			});
		}

		// TODO: Implement a better way to discern between HorizontalTableTemplate and FormContentTemplate
		this.contentExpanded = !(this.control.disabled && this.field.templateConfig && !(this.field.templateConfig as FormContentTemplate | undefined)?.expandWhenInactive);

		this.subscriptions.add(this.navService.onNavigation.subscribe((field) => {
			if (!this.contentExpanded) {
				this.contentExpanded = this.field === field || this.field.fields.includes(field);

				if (this.field === field) {					
					// Wait for the template update and set as active so the navService can react to its new position
					setTimeout(() => this.navService.setActive(field, true), 0);
				}
			}
		}));
	}

	ngAfterViewInit() {
		this.initScrollTo();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	// WorkflowService affect ActionGroup
	isVisible(child: RuntimeField): boolean {
		// Not a SmartForm context
		if (!this.formService) {
			return true;
		}

		// SmartForm context, workflow lead
		return !!this.workflowService?.isVisible(child);
	}

	get isSummary(): boolean {
		return this.formService?.style === FormStyle.Summary;
	}

	private initScrollTo() {
		if (
			!this.workflowService?.definition ||
			!this.formService?.definitionSettings.scrollToActiveSection || // scroll feature must be enabled
			!this.workflowService.isActive(this.field) ||
			!this.scope._history?.length || // form contains at least one workflow iteration
			this.field === this.workflowService.definition.fields[0] // first field should not be scrolled to as it will scroll the header out of view
		) {
			return;
		}

		const workflowService = this.workflowService; // create reference for this scope
		const firstActiveSection = this.workflowService.definition.fields.find((f) => this.field.type === FieldType.Section && workflowService.isActive(f));

		if (firstActiveSection === this.field) {
			setTimeout(() => this.elementRef.nativeElement.scrollIntoView(), 0);
		}
	}

}
