<uf-skeleton *ngIf="loading" class="gap-none" />
<div *ngIf="controls.length" [columns]="columns" direction="Row" columnGap="md" rowGap="md" ufGrid>
	<ng-template [ngForOf]="controls" ngFor let-stepControl let-i="index">
		<ng-container *ngIf="steps[i]; let step">
			<!-- Single value step -->
			<ng-template [ngIf]="!step.isMultiple" [ngIfElse]="multiple">
				<uf-select *ngIf="step.predetermined" [control]="stepControl" [options]="step.options"
					[label]="step.label" [nameProperty]="step.display" [placeholder]="step.placeholder" />
				<uf-autocomplete *ngIf="!step.predetermined" [control]="stepControl" [label]="step.label"
					[nameProperty]="step.display" [options]="searchedOptions[i]" [placeholder]="step.placeholder"
					(searchChange)="onStepSearch(step, i, $event)" />
			</ng-template>
			<!-- Multiple values step -->
			<ng-template #multiple>
				<uf-chips *ngIf="step.predetermined" [control]="stepControl" [options]="step.options"
					[label]="step.label" [placeholder]="step.placeholder" [nameProperty]="step.display" />
				<uf-chips *ngIf="!step.predetermined" [control]="stepControl" [options]="searchedOptions[i]"
					[placeholder]="step.placeholder" [label]="step.label" [nameProperty]="step.display"
					(searchChange)="onStepSearch(step, i, $event)" />
			</ng-template>
		</ng-container>
	</ng-template>
</div>
<uf-message *ngIf="loadStepError" [content]="loadStepError.message" icon="warningSolid" class="warning" />