<label *ngIf="field.label">
	{{ field.label }}
	<uf-help *ngIf="field.help" [content]="field.help" />
</label>
<div>
	<p *ngIf="content" class="small gap-sm-top gap-none-bottom">{{ label }}</p>
	<p *ngIf="!content && field.placeholder" class="small gap-sm-top gap-none-bottom">{{ field.placeholder }}</p>
	<button *ngIf="!disabled" #button (click)="showPicker()" class="uf-button x-small right" type="button">
		{{ (!content ? sharedTK.ActionAdd : sharedTK.ActionEdit) | translate }}
	</button>
</div>
<uf-error [control]="control" />