import { Inject, Injectable, InjectionToken } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Address, DataSeed, Dictionary, GeoLocation, isString, objectKeys } from '@unifii/sdk';

import { GoogleMaps, LocationProviderType } from '../models';
import { WindowWrapper } from '../native';
import { CommonTranslationKey } from '../translations/common.tk';

import { ContextProvider } from './context-provider';
import { UfLocationProvider } from './uf-location-provider';

export const ApiBaseUrl = 'https://maps.googleapis.com/maps/api';

export interface GoogleWindow extends Window {
	google: {
		maps: GoogleMaps;
	};
}

const GoogleMapsApiKey = new InjectionToken<string>('GoogleMapsApiKey');

type CountriesKey = 'AU' | 'NZ';

interface PlaceValuesResult extends Record<string, string | number | undefined> {
	lat?: number;
	lng?: number;
}

@Injectable()
export class GoogleLocationProvider extends UfLocationProvider {

	override type = LocationProviderType.Google;

	/** TODO should come from project configuration */
	private readonly countriesCenterPoint: Record<CountriesKey, Required<GeoLocation>> = {
		AU: { lat: -25.2744, lng: 133.7751, zoom: 3 },
		NZ: { lat: 40.9006, lng: 174.8860, zoom: 3 },
	};
	private _geocoder?: google.maps.Geocoder;
	private _autocompleteService?: google.maps.places.AutocompleteService;
	private geocodeFailMessage = this.translate.instant(CommonTranslationKey.DetectLocationErrorPositionUnavailable) as string;
	private ready: () => Promise<boolean>;

	constructor(
		translate: TranslateService,
		@Inject(WindowWrapper) protected windowWrapper: GoogleWindow,
		@Inject(GoogleMapsApiKey) private googleMapsApiKey: string,
		@Inject(ContextProvider) private contextProvider: ContextProvider,
	) {
		super(translate, windowWrapper);

		// TODO check this function because it's running every time
		this.ready = this.addGoogleMaps.bind(this);
		void this.ready();
	}

	get maps(): GoogleMaps {
		return this.windowWrapper.google.maps;
	}

	get regionGeoLocation(): GeoLocation {
		return this.countriesCenterPoint[this.region];
	}

	private get region(): CountriesKey {
		// pretending type to allow check later in the method
		const appRegion = this.contextProvider.get().region as CountriesKey;
		const keys = objectKeys(this.countriesCenterPoint);

		return isString(appRegion) && keys.includes(appRegion ) ? appRegion : 'AU';
	}

	get defaultBounds(): google.maps.LatLngBounds {

		const bounds = this.countriesCenterPoint[this.region];
		const k = 34218;
		const n = bounds.lat - k;
		const e = bounds.lng - k;
		const s = bounds.lat + k;
		const w = bounds.lng + k;

		const southwest: google.maps.LatLng = new this.maps.LatLng(n, e);
		const northeast: google.maps.LatLng = new this.maps.LatLng(s, w);
		const mapBounds: google.maps.LatLngBounds = new this.maps.LatLngBounds(southwest, northeast);

		return mapBounds;
	}

	private get geocoder(): google.maps.Geocoder | undefined {

		if (!this._geocoder) {
			this._geocoder =new this.maps.Geocoder();
		}

		return this._geocoder;
	}

	private get autocompleteService(): google.maps.places.AutocompleteService {

		if (this._autocompleteService == null) {
			this._autocompleteService = new google.maps.places.AutocompleteService();
		}

		return this._autocompleteService;
	}

	search(query: string): Promise<DataSeed[]> {

		if (!query.trim().length) {
			return Promise.resolve([]);
		}

		return new Promise((resolve) => {
			this.autocompleteService.getQueryPredictions({ bounds: this.defaultBounds, input: query }, (predictions, status) => {
				if (status === google.maps.places.PlacesServiceStatus.OK) {
					resolve((predictions ?? []).map((p) => ({ _display: p.description, _id: p.place_id ?? '' })));
				} else {
					resolve([]);
				}
			});
		});
	}

	async findLocation(address: string, region: string): Promise<Address> {

		try {
			const geocodeResult = await this.geocoderGeocode({ address, region });
			const firstResult = geocodeResult[0];
			const lat = firstResult ? +firstResult.geometry.location.lat().toFixed(5) : undefined;
			const lng = firstResult ? +firstResult.geometry.location.lng().toFixed(5) : undefined;

			if (lat == null || lng == null) {
				throw new Error(this.geocodeFailMessage);
			}

			const foundAddress = await this.findAddress({ lat, lng });

			return {
				...foundAddress,
				lat,
				lng,
			};

		} catch (e) {
			throw new Error(this.geocodeFailMessage);
		}
	}

	async findAddress(location: GeoLocation | null | undefined): Promise<Address> {

		await this.ready();

		try {
			const geocodeResult = (await this.geocoderGeocode({ location: { lat: location?.lat ?? 0, lng: location?.lng ?? 0 } }))[0];

			let address: Address = {
				lat: location?.lat,
				lng: location?.lng,
			};

			if (geocodeResult) {
				address = Object.assign(this.getAddress(geocodeResult), address);
			}

			return address;
		} catch (e) {
			throw new Error(this.geocodeFailMessage);
		}
	}

	getMapUrl(lat: number, lng: number, marker?: GeoLocation, zoom = 3, size?: { width: number; height: number }): string {
		let url = `${ApiBaseUrl}/staticmap?key=${this.googleMapsApiKey}&center=${lat},${lng}&scale=1&zoom=${zoom}`;

		if (size != null) {
			url += `&size=${size.width}x${size.height}`;
		}
		if (marker) {
			url += `&markers=${marker.lat},${marker.lng}`;
		}

		return url;
	}

	private geocoderGeocode(options: google.maps.GeocoderRequest): Promise<google.maps.GeocoderResult[]> {
		return new Promise((resolve, reject) => {

			void this.geocoder?.geocode(options, (results, status) => {
				if (!results) {
					reject();

					return;
				}
				if (status === google.maps.GeocoderStatus.OK) {
					resolve(results);
				} else {
					const error = new Error(this.geocodeFailMessage);

					reject(error);
				}
			});
		});
	}

	private getAddress(place: google.maps.places.PlaceResult | google.maps.GeocoderResult): Address {
		/** Should be set by address service */
		const fields: Record<string, string[]> = {
			address1: ['street_number', 'route'],
			suburb: ['locality'],
			city: ['administrative_area_level_2'],
			state: ['administrative_area_level_1'],
			postcode: ['postal_code'],
			country: ['country'],
		};

		const placeValues = this.getPlaceValues(place);

		return Object.keys(fields).reduce<Address>((address, key) => {

			const fieldKeys = fields[key];
			const value = fieldKeys?.map((k) => placeValues[k])
				.filter((v) => v != null)
				.join(' ').trim();

			if (value) {
				(address as Dictionary<any>)[key] = value;
			}

			return address;

		}, {
			formattedAddress: place.formatted_address,
			lat: 0,
			lng: 0,
		});
	}

	private getPlaceValues(place: google.maps.places.PlaceResult | google.maps.GeocoderResult): PlaceValuesResult {
		const resultsLookup = {
			street_number: 'long_name',
			locality: 'long_name',
			route: 'long_name',
			administrative_area_level_2: 'short_name',
			administrative_area_level_1: 'long_name',
			postal_code: 'short_name',
			country: 'long_name',
		};
		
		const values: PlaceValuesResult = {};

		if (place.geometry?.location) {
			values.lat = place.geometry.location.lat();
			values.lng = place.geometry.location.lng();
		}

		if (place.address_components) {
			for (const component of place.address_components) {
				const types = component.types;

				for (const t of types) {
					const value = (component as Dictionary<any>)[(resultsLookup as Dictionary<any>)[t]] ?? null;

					values[t] = value;
				}
			}
		}

		return values;
	}

	private addGoogleApi() {
		if (!this.googleMapsApiKey) {
			return;
		}

		const googleMaps = document.createElement('script');

		googleMaps.src = `${ApiBaseUrl}/js?key=${this.googleMapsApiKey}&callback=Function.prototype&libraries=places`;
		document.body.appendChild(googleMaps);
	}

	private get isGoogleApiAdded(): boolean {
		const scripts = document.getElementsByTagName('script');

		for (const script of Array.from(scripts)) {

			if (script.src.includes('maps.googleapis')) {
				return true;
			}
		}

		return false;
	}

	private addGoogleMaps(): Promise<boolean> {
		if (this.isGoogleApiAdded) {
			return Promise.resolve(true);
		}

		this.addGoogleApi();

		return new Promise((resolve) => {

			const apiReady = setInterval(() => {
				if ((window as any).google?.maps) {
					clearInterval(apiReady);
					resolve(true);
				}
			}, 100);

		});
	}

}
