import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-checkboxes.html',
})
export class ShowCheckboxesComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly sizeOptions = [
		{ name: 'Medium (default)', value: '' },
		{ name: 'Small', value: 'small' },
	];

	size = '';

	readonly inputTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'default', name: 'Default' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly inputTableInfo = [
		{ name: 'control', type: 'UfControl', default: 'new UfControl()', description: 'A control object to manage validators, states etc.' },
		{ name: 'id', type: 'string', default: 'undefined', description: 'Sets id attribute of inner input element' },
		{ name: 'name', type: 'string', default: 'undefined', description: 'Sets name attribute of inner input element' },
		{ name: 'label', type: 'string', default: 'undefined', description: 'Sets label' },
	];
	readonly outputTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly outputTableInfo = [
		{ name: 'valueChange', type: 'EventEmitter', description: 'Emits an event when the value of the input is changed' },
		{ name: 'touchedChange', type: 'EventEmitter', description: 'Emits an event when the input is set to touched' },
	];
	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'default', type: 'modifier', description: 'Default input size' },
		{ name: 'small', type: 'modifier', description: 'Small input size' },
	];

	template = `
		<uf-checkbox label="Checkbox"></uf-checkbox>
	`;

	modifierChange() {
		this.template = `<uf-checkbox label="Checkbox"${this.size ? ' class="' + this.size + '"' : ''}></uf-checkbox>`;
	}

}
