import { Inject } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Dictionary } from '@unifii/sdk';
import { Observable, from } from 'rxjs';

import { CommonTranslationKey, SharedTermsTranslationKey, TimezonesTranslationKey, loadDictionary } from '@unifii/library/common';
import { SmartFormsTranslationKey } from '@unifii/library/smart-forms';
import { DisplayTranslationKey } from '@unifii/library/smart-forms/display';
import { InputTranslationKey } from '@unifii/library/smart-forms/input';

import { Config } from '../models';

export class ShowcaseTranslateLoader implements TranslateLoader {

	constructor(@Inject(Config) public config: Config) { }

	getTranslation(lang: string): Observable<Dictionary<string>> {

		return from(loadDictionary(
			lang,
			[`${this.config.translationsUrl}/${this.config.unifii.appId}`],
			[
				{ identifier: 'library-shared-terms', dictionary: SharedTermsTranslationKey },
				{ identifier: 'library-common', dictionary: CommonTranslationKey },
				{ identifier: 'library-smart-forms', dictionary: SmartFormsTranslationKey },
				{ identifier: 'library-input', dictionary: InputTranslationKey },
				{ identifier: 'library-display', dictionary: DisplayTranslationKey },
				{ identifier: 'timezones', dictionary: TimezonesTranslationKey },
			],
		));
	}

}
