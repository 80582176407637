<ng-template [ngIf]="mode === modes.Input">
	<ng-template [ngIf]="entry.options && entry.options.length" [ngIfElse]="noOptions">
		<ng-template [ngIf]="entry.options.length <= 6" [ngIfElse]="chips">
			<uf-multi-choice [options]="entry.options" [columns]="1" [(value)]="value" (valueChange)="valueChanged()" />
		</ng-template>
		<ng-template #chips>
			<uf-chips [options]="results" [(value)]="value" (searchChange)="search($event)"
				(valueChange)="valueChanged()" nameProperty="name" />
		</ng-template>
	</ng-template>

	<ng-template #noOptions>
		<div>{{commonTK.ChoiceNoOptionsAvailable | translate}}</div>
	</ng-template>
</ng-template>

<ng-template [ngIf]="mode === modes.Display && value && value.length">
	<div *ngFor="let v of value; let i = index" class="uf-chip x-small">
		{{ label }}: {{ displayOptionValue(v) }}
		<button (click)="remove(i)" type="button" class="uf-action">
			<uf-icon name="delete" />
		</button>
	</div>
</ng-template>