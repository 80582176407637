export * from './show-dashboard-page.component';
export * from './show-form-page.component';
export * from './show-tab-page.component';
export * from './show-table-page.component';

import { ShowDashboardPageComponent } from './show-dashboard-page.component';
import { ShowFormPageComponent } from './show-form-page.component';
import { ShowTabPageComponent } from './show-tab-page.component';
import { ShowTablePageComponent } from './show-table-page.component';

export const SC_PAGE_PAGES_COMPONENTS = [ShowDashboardPageComponent, ShowFormPageComponent, ShowTabPageComponent, ShowTablePageComponent];
