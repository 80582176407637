<label [class.content]="!!content">
	<ng-content></ng-content>
	<div *ngIf="template !== 'Button'" [class.push-down]="content" class="icon">
		<uf-icon [name]="icon.checked" class="checked-icon" />
		<uf-icon [name]="icon.unchecked" />
	</div>
	<div *ngIf="!content" [class.uf-button]="template === 'Button'" class="radio-input-label grow">
		{{label}}
	</div>
	<div *ngIf="content" [markdownRenderer]="content" class="grow body-copy small push-content">
	</div>
</label>