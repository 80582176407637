import { Component, Input } from '@angular/core';

import { RuntimeField } from '@unifii/library/common';
import { ContentField, SmartFormsTranslationKey } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-geo-location-display',
	templateUrl: './geo-location.html',
	styleUrls: ['./geo-location.less'],
})
export class GeoLocationDisplayComponent implements ContentField {

	@Input() content: any;
	@Input() field: RuntimeField;

	readonly smartFormsTK = SmartFormsTranslationKey;

}
