<div *ngIf="optionsForm" [ngClass]="{'error': optionsForm.errors?.length > 0}" class="form-status">
	<!-- Branch errors deprecated -->
	<!-- <ul>
		<li>Form BranchErrors: {{optionsForm.branchErrors.length}}</li>
		<li>----------------------------------------</li>
		<li *ngFor="let o of options, let i = index">
			{{o.key}}: {{o.value}} {{optionsForm.controls.identifierControls.at(i).status}}
		</li>
	</ul> -->
</div>
<h2>Controller Test</h2>
<button (click)="addOption()" class="uf-button primary" type="button">Add</button> Form Status: {{ status }}
<ul style="list-style: none; padding: 0;">
	<li *ngFor="let o of options, let i = index">
		<div class="box">
			<h3>Option - {{i}}</h3>
			<button (click)="delete(i)" class="uf-button primary" type="button">Delete</button>
			<div class="content grow">
				<uf-textarea [value]="o.value" [control]="$any(optionsForm.controls.identifierControls).at(i)"
					(valueChange)="updateValue(i, $event)" class="stretch" label="Identifier" placeholder="NewOption" />
			</div>
		</div>
	</li>
</ul>