import { Component } from '@angular/core';

import { ColumnInfo } from '../../components';

@Component({
	selector: 'sc-show-shadows',
	templateUrl: './show-shadows.html',
	styleUrls: ['./_foundation.less'],
})
export class ShowShadowsComponent {

	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'shadow', type: 'base class', description: 'Default shadow' },
		{ name: 'shadow-md', type: 'base class', description: 'Medium shadow' },
		{ name: 'shadow-lg', type: 'base class', description: 'Large shadow' },
		{ name: 'shadow-xl', type: 'base class', description: 'Extra Large shadow' },
	];

}
