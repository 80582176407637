import { DATE_DATA_FORMAT, DATE_TIME_DATA_FORMAT, DataType, TIME_DATA_FORMAT } from '@unifii/sdk';
import { addMinutes, format, isValid, parse, parseISO, secondsInDay, secondsInHour, secondsInMinute, secondsInMonth, secondsInWeek, secondsInYear, set } from 'date-fns';

import { DateAndTimeFormat, DateFormat, FnsDatetimeMillisServer, FnsDatetimeUtc, ShortTimeFormat, TwentyFourHourTimeFormat } from '../constants';
import { TemporalDataTypes } from '../models';

export const isValidFormat = (dateString: string | null, formatString: string): boolean => {

	try {
		if (!dateString || !isValid(parse(dateString, formatString, new Date()))) {
			return false;
		}

		return format(parse(dateString, formatString, new Date()), formatString) === dateString;
	} catch (e) {
		return false;
	}
};

export const getFormat = (value: string): string | undefined => {

	const formats = [DATE_DATA_FORMAT, DATE_TIME_DATA_FORMAT, TIME_DATA_FORMAT, FnsDatetimeMillisServer, FnsDatetimeUtc];

	return formats.find((f) => isValidFormat(value, f));
};

/**
 * Parse the value with the expected formatString
 * If value result as an invalid format, fallback to ISO format
 * Needed fallback to accept ISO format in any Time, Date or DateTime value
 * See https://unifii.atlassian.net/browse/UNIFII-3131
 */
export const parseFallbackISO = (value: string, formatString: string): Date | undefined => {

	const date = parse(value, formatString, new Date());

	if (isValid(date)) {
		return date;
	}

	return safeParseISO(value);
};

/** Parse the value with ISO format and return the Date or undefined if the value is an invalid date */
export const safeParseISO = (value: string): Date | undefined => {

	const date = parseISO(value);

	if (!isValid(date)) {
		return;
	}

	return date;
};

/**
 * Return UTC timestamp of input date or fallback to actual time
 */
export const getUTCTimestamp = (date?: Date): string => {
	date = date ?? new Date();
	const utcDate = addMinutes(date, date.getTimezoneOffset());

	return format(utcDate, FnsDatetimeMillisServer) + '+00:00';
};

export const getDateTimeFormattedNow = (): string =>
	format(set(new Date(), { seconds: 0 }), DATE_TIME_DATA_FORMAT);

export const getLocalTimeZone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const modelFormatByType = (type: `${TemporalDataTypes}`): string => {
	switch (type as TemporalDataTypes) {
		case DataType.Date:
			return DATE_DATA_FORMAT;
		case DataType.Time:
			return TIME_DATA_FORMAT;
		case DataType.DateTime:
		case DataType.OffsetDateTime:
			return DATE_TIME_DATA_FORMAT;
	}
};

export const displayFormatByType = (type: `${TemporalDataTypes}`, displayFormat?: string | null, displayFallbackFormat?: string | null): string => {
	switch (type as TemporalDataTypes) {
		case DataType.Date:
			return normalizeDateFormat(displayFormat, displayFallbackFormat ?? undefined);
		case DataType.Time:
			return normalizeTimeFormat(displayFormat, displayFallbackFormat ?? undefined);
		case DataType.DateTime:
		case DataType.OffsetDateTime:
			return normalizeDateTimeFormat(displayFormat, displayFallbackFormat ?? undefined, true);
	}
};

export const inputFormatByType = (type: `${TemporalDataTypes}`, displayFormat?: string | null): string => {
	const normalizedFormat = displayFormatByType(type, displayFormat);

	switch (type as TemporalDataTypes) {
		case DataType.Date:
			return DateFormat;
		case DataType.Time:
			return normalizedFormat.includes('a') ? ShortTimeFormat : TwentyFourHourTimeFormat;
		case DataType.DateTime:
		case DataType.OffsetDateTime:
			return normalizedFormat.includes('a') ? DateAndTimeFormat : `${DateFormat} ${TwentyFourHourTimeFormat}`;
	}
};

const normalizeDateFormat = (v?: string | null, fallback = DateFormat): string => {

	if (!v) {
		return fallback;
	}

	const normalized = v.replace(/D/g, 'd').replace(/m/g, 'M').replace(/Y/g, 'y').replace(/e/g, 'E');

	try {
		if (!/d|M|y/.test(v)) {
			throw new Error();
		}

		format(new Date(), normalized);
	} catch (e) {
		console.warn(`NormalizeDateFormat '${v}' failed, fallback to ${fallback}`);

		return fallback;
	}

	return normalized;
};

const normalizeTimeFormat = (v?: string | null, fallback = ShortTimeFormat): string => {

	if (!v) {
		return fallback;
	}

	const normalized = v.replace(/M/g, 'm').replace(/A/g, 'a');

	try {
		if (!/h|H/.test(v)) {
			throw new Error();
		}

		if ( // !h && !H
			((!v.includes('h') && !v.includes('H'))) ||
			// 'h' without 'a'
			(v.includes('h') && (!v.includes('a'))) ||
			// 'H' with 'a'
			(v.includes('H') && (v.includes('a')))
		) {
			throw new Error();
		}

		format(new Date(), normalized);
	} catch (e) {
		console.warn(`normalizeTimeFormat - Invalid format '${v}' fallback to '${fallback}'!`);

		return fallback;
	}

	return normalized;
};

/**
 * @param v requested format to normalize
 * @param fallback format to return if v is invalid
 * @param forDisplayUse relax normalization as this format is intended for display purpose only
 * @returns a valid DateTime format
 */
const normalizeDateTimeFormat = (v?: string | null, fallback = DateAndTimeFormat, forDisplayUse = false): string => {

	if (!v) {
		return fallback;
	}

	const hasTimePart = /h|H/.test(v);

	if (!hasTimePart && !forDisplayUse) {
		console.warn(`normalizeDateTimeFormat - Invalid format '${v}' fallback to '${fallback}'!`);

		return fallback;
	}

	let datePart;
	let timePart;

	if (hasTimePart) {
		const regExResult = /h|H/.exec(v) ?? { index: 0 };
		const split = regExResult.index;

		datePart = v.slice(0, split - 1);
		timePart = v.slice(split, v.length);
	} else {
		datePart = v;
	}

	let result = normalizeDateFormat(datePart);

	if (timePart) {
		result = `${result} ${normalizeTimeFormat(timePart)}`;
	}

	return result;
};

export const durationToSeconds = ((duration: Duration | undefined): number | undefined => {
	if (!duration) {
		return;
	}

	return (duration.years ?? 0) * secondsInYear +
		(duration.months ?? 0) * secondsInMonth +
		(duration.weeks ?? 0) * secondsInWeek +
		(duration.days ?? 0) * secondsInDay +
		(duration.hours ?? 0) * secondsInHour +
		(duration.minutes ?? 0) * secondsInMinute +
		(duration.seconds ?? 0);
}) as
	((duration: Duration) => number) &
	((duration: undefined) => undefined) &
	((duration: Duration | undefined) => number | undefined);

/** This transformation ignore 'weeks' in favour of 'days' */
export const secondsToDuration = ((value: number | undefined): Duration | undefined => {
	if (value == null || value < 0) {
		return;
	}

	let leftover = value;

	const years = Math.floor(value / secondsInYear);

	leftover -= years * secondsInYear;
	const months = Math.floor(leftover / secondsInMonth);

	leftover -= months * secondsInMonth;
	const days = Math.floor(leftover / secondsInDay);

	leftover -= days * secondsInDay;
	const hours = Math.floor(leftover / secondsInHour);

	leftover -= hours * secondsInHour;
	const minutes = Math.floor(leftover / secondsInMinute);

	leftover -= minutes * secondsInMinute;
	const seconds = leftover;

	return { years, months, weeks: 0, days, hours, minutes, seconds };
}) as
	((value: number) => Duration) &
	((value: undefined) => undefined) &
	((value: number | undefined) => Duration | undefined);
