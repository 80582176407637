import { Component, HostBinding, Input } from '@angular/core';

import { RuntimeField } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-email-display',
	templateUrl: './email.html',
})
export class EmailComponent implements ContentField {

	@HostBinding('class.body-copy') bodyclass = true;

	@Input() content: string;
	@Input() field: RuntimeField;

}
