<!-- ANG9 [ngClass]="size" -->
<button *ngIf="!actionBtn"
	[title]="(isExpanded ? sharedTermsTK.ActionCollapse : sharedTermsTK.ActionExpand) | translate"
	(click)="toggle($event)" type="button" class="uf-action tertiary toggle small" tabindex="-1">
	<uf-icon [class.collapsed]="!isExpanded" name="expand" />
</button>

<button *ngIf="actionBtn" [title]="(isExpanded ? sharedTermsTK.ActionCollapse : sharedTermsTK.ActionExpand) | translate"
	(click)="toggle($event)" type="button" class="uf-action tertiary action">
	<uf-icon [class.collapsed]="isExpanded" name="collapse" />
</button>