import { WorldRegion } from './world-data-model';

export const worldRegions: WorldRegion[] = [
	{
		id: 1,
		name: 'Africa',
		parent_id: 0,
	},
	{
		id: 2,
		name: 'Americas',
		parent_id: 0,
	},
	{
		id: 3,
		name: 'Asia',
		parent_id: 0,
	},
	{
		id: 4,
		name: 'Europe',
		parent_id: 0,
	},
	{
		id: 5,
		name: 'Oceania',
		parent_id: 0,
	},
];
