import { Injectable, Renderer2, RendererFactory2, RendererStyleFlags2 } from '@angular/core';
import { Theme } from '@unifii/sdk';

import { ThemeProvider } from './theme-provider';

/**
 * Applies CSS variables to document to change look of the app
 */
@Injectable()
export class ThemeService implements ThemeProvider {

	private renderer: Renderer2;
	private _theme: Theme = {};

	constructor(
		private htmlElement: HTMLElement,
		private rendererFactory: RendererFactory2,
	) {
		this.renderer = this.rendererFactory.createRenderer(this.htmlElement, null);
	}

	set theme(v: Theme | undefined) {
		v = v ?? {};
		this.removeTheme(this._theme);
		this._theme = v;
		this.applyTheme(this._theme);
	}

	get theme(): Theme {
		return this._theme;
	}

	setStyle(key: string, value: string) {
		this.renderer.setStyle(this.htmlElement, `--${key}`, value, RendererStyleFlags2.DashCase);
	}

	removeStyle(key: string) {
		this.renderer.removeStyle(this.htmlElement, `--${key}`, RendererStyleFlags2.DashCase);
	}

	private removeTheme(theme: Theme) {
		for (const { key } of this.themeIterable(theme)) {
			this.renderer.removeStyle(this.htmlElement, `--${this.toDashCase(key)}`, RendererStyleFlags2.DashCase);
		}
	}

	private applyTheme(theme: Theme) {
		for (const { key, value } of this.themeIterable(theme)) {
			this.renderer.setStyle(this.htmlElement, `--${this.toDashCase(key)}`, value, RendererStyleFlags2.DashCase);
		}
	}

	private *themeIterable(theme: Theme): Iterable<{ value: string; key: string }> {
		for (const key of Object.keys(theme)) {
			const value = theme[key as keyof Theme];

			if (value != null) {
				yield { key, value };
			}
		}
	}

	private toDashCase(str: string): string {
		return str.replace(/[A-Z]/g, (match) => '-' + match.toLowerCase());
	}

}
