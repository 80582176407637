<div *ngIf="ready" class="uf-grid pad-sides">
	<h2 class="col-12">Cascade Selection Component</h2>
	<pre class="col-12">Value: {{valueChanged}}</pre>
	<button (click)="setValue()" class="uf-button" type="button">Set random value</button>

	<!-- Control -->
	<ng-template [ngIf]="control">
		<h2 class="col-12">Configured via Control</h2>
		<button *ngIf="control.disabled" (click)="control.enable()" class="uf-button" type="button">Enable</button>
		<button *ngIf="control.enabled" (click)="control.disable()" class="uf-button" type="button">Disable</button>

		<uf-cascade-selection [control]="control" [dataSource]="dataSource" [columns]="2"
			(valueChange)="onValueChange($event)" class="col-12" />
	</ng-template>

	<!-- Value -->
	<ng-template [ngIf]="value">
		<h2 class="col-12">Configured via Value</h2>
		<uf-cascade-selection [dataSource]="dataSource" [columns]="3" [(value)]="value"
			(valueChange)="onValueChange($event)" class="col-12" />
	</ng-template>
</div>