<sc-page-content width="xl" identifier="data-display">
	<ng-container editor>
		<div class="uf-form-card">
			<div class="uf-app-bar">
				<h3>Configuration</h3>
				<sc-entity-picker [pickData]="true" [type]="'table'" (emitChange)="onPickedInfo($event)" />
			</div>
		</div>

		<uf-message *ngIf="error" class="error">
			<h3>{{ error }}</h3>
		</uf-message>

		<div *ngIf="groups.length">
			<uf-data-display-group *ngFor="let group of groups" [group]="group" />
		</div>

	</ng-container>
</sc-page-content>