import { DATE_TIME_DATA_FORMAT } from '@unifii/sdk';

// TODO Review should be moved to SDK
/** All data model formats for date (date-fns tokens) */
export const FnsDatetimeMillisServer = `${DATE_TIME_DATA_FORMAT}.SSS`;
// TODO similar to SDK OFFSET_DATE_TIME_DATA_FORMAT_NO_MILLISECONDS (review)
export const FnsDatetimeUtc = `${FnsDatetimeMillisServer}XXX`;

// TODO review usage of those display formats and move them to constants/data-display.ts
// Display formats for Date, Time, DateTime
export const DateFormat = 'dd/MM/yyyy';
export const DateDisplayFormat = 'MMM d, yyyy';
export const DateWeekDayDisplayFormat = 'EEE d MMM, yyyy';
export const ShortTimeFormat = 'h:mm a';
export const TwentyFourHourTimeFormat = 'HH:mm';
export const DateAndTimeFormat = `${DateFormat} ${ShortTimeFormat}`;
export const DateAndTimeDisplayFormat = `${DateDisplayFormat}, ${ShortTimeFormat}`;
export const DateWeekDayAndTimeDisplayFormat = `${DateWeekDayDisplayFormat}, ${ShortTimeFormat}`;

// Display formats for Hierarchy
export const HierarchyLeafFormat = 'leaf';
