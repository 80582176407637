import { WorldCity } from './world-data-model';

export const worldCities: WorldCity[] = [
	{ id: 52, name: 'Ashkāsham', parent_id: 3901 },
	{ id: 68, name: 'Fayzabad', parent_id: 3901 },
	{ id: 78, name: 'Jurm', parent_id: 3901 },
	{ id: 84, name: 'Khandūd', parent_id: 3901 },
	{ id: 115, name: 'Rāghistān', parent_id: 3901 },
	{ id: 72, name: 'Ghormach', parent_id: 3871 },
	{ id: 108, name: 'Qala i Naw', parent_id: 3871 },
	{ id: 54, name: 'Baghlān', parent_id: 3875 },
	{ id: 140, name: 'Ḩukūmatī Dahanah-ye Ghōrī', parent_id: 3875 },
	{ id: 101, name: 'Nahrīn', parent_id: 3875 },
	{ id: 105, name: 'Pul-e Khumrī', parent_id: 3875 },
	{ id: 55, name: 'Balkh', parent_id: 3884 },
	{ id: 65, name: 'Dowlatābād', parent_id: 3884 },
	{ id: 85, name: 'Khulm', parent_id: 3884 },
	{ id: 91, name: 'Lab-Sar', parent_id: 3884 },
	{ id: 97, name: 'Mazār-e Sharīf', parent_id: 3884 },
	{ id: 57, name: 'Bāmyān', parent_id: 3872 },
	{ id: 104, name: 'Panjāb', parent_id: 3872 },
	{ id: 102, name: 'Nīlī', parent_id: 3892 },
	{ id: 66, name: 'Farah', parent_id: 3899 },
	{ id: 50, name: 'Andkhoy', parent_id: 3889 },
	{ id: 96, name: 'Maymana', parent_id: 3889 },
	{ id: 71, name: 'Ghazni', parent_id: 3870 },
	{ id: 67, name: 'Fayrōz Kōh', parent_id: 3888 },
	{ id: 121, name: 'Shahrak', parent_id: 3888 },
	{ id: 141, name: '‘Alāqahdārī Dīshū', parent_id: 3873 },
	{ id: 70, name: 'Gereshk', parent_id: 3873 },
	{ id: 93, name: 'Lashkar Gāh', parent_id: 3873 },
	{ id: 95, name: 'Markaz-e Ḩukūmat-e Darwēshān', parent_id: 3873 },
	{ id: 118, name: 'Sangīn', parent_id: 3873 },
	{ id: 60, name: 'Chahār Burj', parent_id: 3887 },
	{ id: 73, name: 'Ghōriyān', parent_id: 3887 },
	{ id: 74, name: 'Herāt', parent_id: 3887 },
	{ id: 80, name: 'Kafir Qala', parent_id: 3887 },
	{ id: 82, name: 'Karukh', parent_id: 3887 },
	{ id: 136, name: 'Āqchah', parent_id: 3886 },
	{ id: 63, name: 'Darzāb', parent_id: 3886 },
	{ id: 113, name: 'Qarqīn', parent_id: 3886 },
	{ id: 122, name: 'Shibirghān', parent_id: 3886 },
	{ id: 79, name: 'Kabul', parent_id: 3902 },
	{ id: 99, name: 'Mīr Bachah Kōṯ', parent_id: 3902 },
	{ id: 103, name: 'Paghmān', parent_id: 3902 },
	{ id: 81, name: 'Kandahār', parent_id: 3890 },
	{ id: 124, name: 'Sidqābād', parent_id: 3879 },
	{ id: 87, name: 'Khōst', parent_id: 3878 },
	{ id: 51, name: 'Asadabad', parent_id: 3876 },
	{ id: 138, name: 'Āsmār', parent_id: 3876 },
	{ id: 64, name: 'Dasht-e Archī', parent_id: 3900 },
	{ id: 75, name: 'Imām Şāḩib', parent_id: 3900 },
	{ id: 83, name: 'Khanabad', parent_id: 3900 },
	{ id: 89, name: 'Kunduz', parent_id: 3900 },
	{ id: 114, name: 'Qarāwul', parent_id: 3900 },
	{ id: 98, name: 'Mehtar Lām', parent_id: 3891 },
	{ id: 56, name: 'Baraki Barak', parent_id: 3897 },
	{ id: 139, name: 'Ḩukūmatī Azrah', parent_id: 3897 },
	{ id: 106, name: 'Pul-e ‘Alam', parent_id: 3897 },
	{ id: 58, name: 'Bāsawul', parent_id: 3882 },
	{ id: 77, name: 'Jalālābād', parent_id: 3882 },
	{ id: 94, name: 'Markaz-e Woluswalī-ye Āchīn', parent_id: 3882 },
	{ id: 86, name: 'Khāsh', parent_id: 3896 },
	{ id: 100, name: 'Mīrābād', parent_id: 3896 },
	{ id: 116, name: 'Rūdbār', parent_id: 3896 },
	{ id: 132, name: 'Zaranj', parent_id: 3896 },
	{ id: 107, name: 'Pārūn', parent_id: 3880 },
	{ id: 69, name: 'Gardez', parent_id: 3894 },
	{ id: 120, name: 'Saṟōbī', parent_id: 3877 },
	{ id: 134, name: 'Zaṟah Sharan', parent_id: 3877 },
	{ id: 133, name: 'Zarghūn Shahr', parent_id: 3877 },
	{ id: 59, name: 'Bāzārak', parent_id: 3881 },
	{ id: 61, name: 'Charikar', parent_id: 3895 },
	{ id: 76, name: 'Jabal os Saraj', parent_id: 3895 },
	{ id: 53, name: 'Aībak', parent_id: 3883 },
	{ id: 62, name: 'Chīras', parent_id: 3885 },
	{ id: 92, name: 'Larkird', parent_id: 3885 },
	{ id: 110, name: 'Qal‘ah-ye Shahr', parent_id: 3885 },
	{ id: 117, name: 'Sang-e Chārak', parent_id: 3885 },
	{ id: 119, name: 'Sar-e Pul', parent_id: 3885 },
	{ id: 137, name: 'Ārt Khwājah', parent_id: 3893 },
	{ id: 126, name: 'Taloqan', parent_id: 3893 },
	{ id: 127, name: 'Tarinkot', parent_id: 3898 },
	{ id: 130, name: 'Uruzgān', parent_id: 3898 },
	{ id: 109, name: 'Qalāt', parent_id: 3874 },
	{ id: 153, name: 'Banaj', parent_id: 629 },
	{ id: 154, name: 'Bashkia Berat', parent_id: 629 },
	{ id: 170, name: 'Bashkia Kuçovë', parent_id: 629 },
	{ id: 180, name: 'Bashkia Poliçan', parent_id: 629 },
	{ id: 186, name: 'Bashkia Skrapar', parent_id: 629 },
	{ id: 155, name: 'Bashkia Bulqizë', parent_id: 610 },
	{ id: 165, name: 'Bashkia Klos', parent_id: 610 },
	{ id: 176, name: 'Bashkia Mat', parent_id: 610 },
	{ id: 193, name: 'Bulqizë', parent_id: 610 },
	{ id: 194, name: 'Burrel', parent_id: 610 },
	{ id: 159, name: 'Bashkia Durrës', parent_id: 639 },
	{ id: 168, name: 'Bashkia Krujë', parent_id: 639 },
	{ id: 185, name: 'Bashkia Shijak', parent_id: 639 },
	{ id: 197, name: 'Durrës', parent_id: 639 },
	{ id: 198, name: 'Durrës District', parent_id: 639 },
	{ id: 152, name: 'Ballsh', parent_id: 631 },
	{ id: 157, name: 'Bashkia Divjakë', parent_id: 631 },
	{ id: 160, name: 'Bashkia Fier', parent_id: 631 },
	{ id: 174, name: 'Bashkia Mallakastër', parent_id: 631 },
	{ id: 179, name: 'Bashkia Patos', parent_id: 631 },
	{ id: 158, name: 'Bashkia Dropull', parent_id: 621 },
	{ id: 164, name: 'Bashkia Kelcyrë', parent_id: 621 },
	{ id: 172, name: 'Bashkia Libohovë', parent_id: 621 },
	{ id: 177, name: 'Bashkia Memaliaj', parent_id: 621 },
	{ id: 183, name: 'Bashkia Përmet', parent_id: 621 },
	{ id: 156, name: 'Bashkia Devoll', parent_id: 630 },
	{ id: 166, name: 'Bashkia Kolonjë', parent_id: 630 },
	{ id: 173, name: 'Bashkia Maliq', parent_id: 630 },
	{ id: 182, name: 'Bashkia Pustec', parent_id: 630 },
	{ id: 192, name: 'Bilisht', parent_id: 630 },
	{ id: 151, name: 'Bajram Curri', parent_id: 623 },
	{ id: 215, name: 'Krumë', parent_id: 623 },
	{ id: 217, name: 'Kukës', parent_id: 623 },
	{ id: 247, name: 'Rrethi i Hasit', parent_id: 623 },
	{ id: 251, name: 'Rrethi i Kukësit', parent_id: 623 },
	{ id: 169, name: 'Bashkia Kurbin', parent_id: 609 },
	{ id: 171, name: 'Bashkia Lezhë', parent_id: 609 },
	{ id: 178, name: 'Bashkia Mirditë', parent_id: 609 },
	{ id: 218, name: 'Kurbnesh', parent_id: 609 },
	{ id: 222, name: 'Laç', parent_id: 609 },
	{ id: 175, name: 'Bashkia Malësi e Madhe', parent_id: 626 },
	{ id: 181, name: 'Bashkia Pukë', parent_id: 626 },
	{ id: 188, name: 'Bashkia Vau i Dejës', parent_id: 626 },
	{ id: 202, name: 'Fushë-Arrëz', parent_id: 626 },
	{ id: 211, name: 'Koplik', parent_id: 626 },
	{ id: 163, name: 'Bashkia Kavajë', parent_id: 633 },
	{ id: 190, name: 'Bashkia Vorë', parent_id: 633 },
	{ id: 207, name: 'Kamëz', parent_id: 633 },
	{ id: 208, name: 'Kavajë', parent_id: 633 },
	{ id: 213, name: 'Krrabë', parent_id: 633 },
	{ id: 161, name: 'Bashkia Finiq', parent_id: 634 },
	{ id: 162, name: 'Bashkia Himarë', parent_id: 634 },
	{ id: 167, name: 'Bashkia Konispol', parent_id: 634 },
	{ id: 184, name: 'Bashkia Selenicë', parent_id: 634 },
	{ id: 189, name: 'Bashkia Vlorë', parent_id: 634 },
	{ id: 31227, name: 'Adrar', parent_id: 1118 },
	{ id: 31234, name: 'Aoulef', parent_id: 1118 },
	{ id: 31406, name: 'Reggane', parent_id: 1118 },
	{ id: 31450, name: 'Timimoun', parent_id: 1118 },
	{ id: 31243, name: 'Aïn Defla', parent_id: 1119 },
	{ id: 31325, name: 'El Abadia', parent_id: 1119 },
	{ id: 31331, name: 'El Attaf', parent_id: 1119 },
	{ id: 31361, name: 'Khemis Miliana', parent_id: 1119 },
	{ id: 31448, name: 'Theniet el Had', parent_id: 1119 },
	{ id: 31250, name: 'Aïn Temouchent', parent_id: 1122 },
	{ id: 31265, name: 'Beni Saf', parent_id: 1122 },
	{ id: 31329, name: 'El Amria', parent_id: 1122 },
	{ id: 31340, name: 'El Malah', parent_id: 1122 },
	{ id: 31351, name: 'Hammam Bou Hadjar', parent_id: 1122 },
	{ id: 31249, name: 'Aïn Taya', parent_id: 1144 },
	{ id: 31230, name: 'Algiers', parent_id: 1144 },
	{ id: 31254, name: 'Bab Ezzouar', parent_id: 1144 },
	{ id: 31274, name: 'Birkhadem', parent_id: 1144 },
	{ id: 31281, name: 'Bordj el Kiffan', parent_id: 1144 },
	{ id: 31233, name: 'Annaba', parent_id: 1103 },
	{ id: 31267, name: 'Berrahal', parent_id: 1103 },
	{ id: 31323, name: 'Drean', parent_id: 1103 },
	{ id: 31334, name: 'El Hadjar', parent_id: 1103 },
	{ id: 31251, name: 'Aïn Touta', parent_id: 1142 },
	{ id: 31237, name: 'Arris', parent_id: 1142 },
	{ id: 31257, name: 'Barika', parent_id: 1142 },
	{ id: 31258, name: 'Batna', parent_id: 1142 },
	{ id: 31292, name: 'Boumagueur', parent_id: 1142 },
	{ id: 31298, name: 'Béchar', parent_id: 1108 },
	{ id: 31229, name: 'Akbou', parent_id: 1128 },
	{ id: 31231, name: 'Amizour', parent_id: 1128 },
	{ id: 31256, name: 'Barbacha', parent_id: 1128 },
	{ id: 31259, name: 'Bejaïa', parent_id: 1128 },
	{ id: 31467, name: 'el hed', parent_id: 1128 },
	{ id: 31275, name: 'Biskra', parent_id: 1114 },
	{ id: 31405, name: 'Oumache', parent_id: 1114 },
	{ id: 31427, name: 'Sidi Khaled', parent_id: 1114 },
	{ id: 31430, name: 'Sidi Okba', parent_id: 1114 },
	{ id: 31461, name: 'Tolga', parent_id: 1114 },
	{ id: 31263, name: 'Beni Mered', parent_id: 1111 },
	{ id: 31276, name: 'Blida', parent_id: 1111 },
	{ id: 31296, name: 'Boû Arfa', parent_id: 1111 },
	{ id: 31287, name: 'Boufarik', parent_id: 1111 },
	{ id: 31289, name: 'Bougara', parent_id: 1111 },
	{ id: 31278, name: 'Bordj Bou Arreridj', parent_id: 1116 },
	{ id: 31279, name: 'Bordj Ghdir', parent_id: 1116 },
	{ id: 31280, name: 'Bordj Zemoura', parent_id: 1116 },
	{ id: 31327, name: 'El Achir', parent_id: 1116 },
	{ id: 31373, name: 'Mansourah', parent_id: 1116 },
	{ id: 31241, name: 'Aïn Bessem', parent_id: 1104 },
	{ id: 31295, name: 'Bouïra', parent_id: 1104 },
	{ id: 31309, name: 'Chorfa', parent_id: 1104 },
	{ id: 31322, name: 'Draa el Mizan', parent_id: 1104 },
	{ id: 31368, name: 'Lakhdaria', parent_id: 1104 },
	{ id: 31235, name: 'Arbatache', parent_id: 1125 },
	{ id: 31261, name: 'Beni Amrane', parent_id: 1125 },
	{ id: 31286, name: 'Boudouaou', parent_id: 1125 },
	{ id: 31294, name: 'Boumerdas', parent_id: 1125 },
	{ id: 31299, name: 'Chabet el Ameur', parent_id: 1125 },
	{ id: 31226, name: 'Abou el Hassan', parent_id: 1105 },
	{ id: 31291, name: 'Boukadir', parent_id: 1105 },
	{ id: 31308, name: 'Chlef', parent_id: 1105 },
	{ id: 31324, name: 'Ech Chettia', parent_id: 1105 },
	{ id: 31397, name: 'Oued Fodda', parent_id: 1105 },
	{ id: 31469, name: '’Aïn Abid', parent_id: 1121 },
	{ id: 31248, name: 'Aïn Smara', parent_id: 1121 },
	{ id: 31310, name: 'Constantine', parent_id: 1121 },
	{ id: 31315, name: 'Didouche Mourad', parent_id: 1121 },
	{ id: 31338, name: 'El Khroub', parent_id: 1121 },
	{ id: 31474, name: '’Aïn el Bell', parent_id: 1098 },
	{ id: 31246, name: 'Aïn Oussera', parent_id: 1098 },
	{ id: 31273, name: 'Birine', parent_id: 1098 },
	{ id: 31300, name: 'Charef', parent_id: 1098 },
	{ id: 31311, name: 'Dar Chioukh', parent_id: 1098 },
	{ id: 31297, name: 'Brezina', parent_id: 1129 },
	{ id: 31326, name: 'El Abiodh Sidi Cheikh', parent_id: 1129 },
	{ id: 31332, name: 'El Bayadh', parent_id: 1129 },
	{ id: 31313, name: 'Debila', parent_id: 1099 },
	{ id: 31341, name: 'El Oued', parent_id: 1099 },
	{ id: 31408, name: 'Reguiba', parent_id: 1099 },
	{ id: 31411, name: 'Robbah', parent_id: 1099 },
	{ id: 31260, name: 'Ben Mehidi', parent_id: 1100 },
	{ id: 31270, name: 'Besbes', parent_id: 1100 },
	{ id: 31337, name: 'El Kala', parent_id: 1100 },
	{ id: 31342, name: 'El Tarf', parent_id: 1100 },
	{ id: 31268, name: 'Berriane', parent_id: 1127 },
	{ id: 31347, name: 'Ghardaïa', parent_id: 1127 },
	{ id: 31386, name: 'Metlili Chaamba', parent_id: 1127 },
	{ id: 31293, name: 'Boumahra Ahmed', parent_id: 1137 },
	{ id: 31348, name: 'Guelma', parent_id: 1137 },
	{ id: 31355, name: 'Héliopolis', parent_id: 1137 },
	{ id: 31358, name: 'Illizi', parent_id: 1112 },
	{ id: 31359, name: 'Jijel', parent_id: 1113 },
	{ id: 31363, name: 'Khenchela', parent_id: 1126 },
	{ id: 31228, name: 'Aflou', parent_id: 1138 },
	{ id: 31367, name: 'Laghouat', parent_id: 1138 },
	{ id: 31468, name: '‘Aïn el Hadjel', parent_id: 1134 },
	{ id: 31477, name: '’Aïn el Melh', parent_id: 1134 },
	{ id: 31391, name: 'M’Sila', parent_id: 1134 },
	{ id: 31426, name: 'Sidi Aïssa', parent_id: 1134 },
	{ id: 31282, name: 'Bou Hanifia el Hamamat', parent_id: 1124 },
	{ id: 31375, name: 'Mascara', parent_id: 1124 },
	{ id: 31400, name: 'Oued el Abtal', parent_id: 1124 },
	{ id: 31433, name: 'Sig', parent_id: 1124 },
	{ id: 31471, name: '’Aïn Boucif', parent_id: 1109 },
	{ id: 31269, name: 'Berrouaghia', parent_id: 1109 },
	{ id: 31366, name: 'Ksar el Boukhari', parent_id: 1109 },
	{ id: 31390, name: 'Médéa', parent_id: 1109 },
	{ id: 31302, name: 'Chelghoum el Aïd', parent_id: 1132 },
	{ id: 31387, name: 'Mila', parent_id: 1132 },
	{ id: 31412, name: 'Rouached', parent_id: 1132 },
	{ id: 31429, name: 'Sidi Mérouane', parent_id: 1132 },
	{ id: 31446, name: 'Telerghma', parent_id: 1132 },
	{ id: 31388, name: 'Mostaganem', parent_id: 1140 },
	{ id: 31247, name: 'Aïn Sefra', parent_id: 1102 },
	{ id: 31392, name: 'Naama', parent_id: 1102 },
	{ id: 31478, name: '’Aïn el Turk', parent_id: 1101 },
	{ id: 31252, name: 'Aïn el Bya', parent_id: 1101 },
	{ id: 31272, name: 'Bir el Djir', parent_id: 1101 },
	{ id: 31284, name: 'Bou Tlelis', parent_id: 1101 },
	{ id: 31343, name: 'Es Senia', parent_id: 1101 },
	{ id: 31316, name: 'Djamaa', parent_id: 1139 },
	{ id: 31335, name: 'El Hadjira', parent_id: 1139 },
	{ id: 31353, name: 'Hassi Messaoud', parent_id: 1139 },
	{ id: 31378, name: 'Megarine', parent_id: 1139 },
	{ id: 31396, name: 'Ouargla', parent_id: 1139 },
	{ id: 31242, name: 'Aïn Beïda', parent_id: 1136 },
	{ id: 31244, name: 'Aïn Fakroun', parent_id: 1136 },
	{ id: 31245, name: 'Aïn Kercha', parent_id: 1136 },
	{ id: 31330, name: 'El Aouinet', parent_id: 1136 },
	{ id: 31384, name: 'Meskiana', parent_id: 1136 },
	{ id: 31473, name: '’Aïn Merane', parent_id: 1130 },
	{ id: 31232, name: 'Ammi Moussa', parent_id: 1130 },
	{ id: 31319, name: 'Djidiouia', parent_id: 1130 },
	{ id: 31376, name: 'Mazouna', parent_id: 1130 },
	{ id: 31398, name: 'Oued Rhiou', parent_id: 1130 },
	{ id: 31475, name: '’Aïn el Hadjar', parent_id: 1123 },
	{ id: 31419, name: 'Saïda', parent_id: 1123 },
	{ id: 31240, name: 'Aïn Arnat', parent_id: 1141 },
	{ id: 31253, name: 'BABOR - VILLE', parent_id: 1141 },
	{ id: 31288, name: 'Bougaa', parent_id: 1141 },
	{ id: 31333, name: 'El Eulma', parent_id: 1141 },
	{ id: 31417, name: 'Salah Bey', parent_id: 1141 },
	{ id: 146185, name: 'Aïn El Berd District', parent_id: 4902 },
	{ id: 146186, name: 'Balidat Ameur', parent_id: 4902 },
	{ id: 146187, name: 'Belarbi', parent_id: 4902 },
	{ id: 146188, name: 'Ben Badis Sid Bel Abbés', parent_id: 4902 },
	{ id: 146189, name: 'Djamaa', parent_id: 4902 },
	{ id: 31239, name: 'Azzaba', parent_id: 1110 },
	{ id: 31360, name: 'Karkira', parent_id: 1110 },
	{ id: 31434, name: 'Skikda', parent_id: 1110 },
	{ id: 31442, name: 'Tamalous', parent_id: 1110 },
	{ id: 31422, name: 'Sedrata', parent_id: 1143 },
	{ id: 31437, name: 'Souk Ahras', parent_id: 1143 },
	{ id: 31356, name: 'I-n-Salah', parent_id: 1135 },
	{ id: 31443, name: 'Tamanrasset', parent_id: 1135 },
	{ id: 31271, name: 'Bir el Ater', parent_id: 1117 },
	{ id: 31305, name: 'Cheria', parent_id: 1117 },
	{ id: 31352, name: 'Hammamet', parent_id: 1117 },
	{ id: 31463, name: 'Tébessa', parent_id: 1117 },
	{ id: 31472, name: '’Aïn Deheb', parent_id: 1106 },
	{ id: 31317, name: 'Djebilet Rosfa', parent_id: 1106 },
	{ id: 31346, name: 'Frenda', parent_id: 1106 },
	{ id: 31365, name: 'Ksar Chellala', parent_id: 1106 },
	{ id: 31379, name: 'Mehdia daira de meghila', parent_id: 1106 },
	{ id: 31452, name: 'Tindouf', parent_id: 1120 },
	{ id: 31470, name: '’Aïn Benian', parent_id: 1115 },
	{ id: 31255, name: 'Baraki', parent_id: 1115 },
	{ id: 31283, name: 'Bou Ismaïl', parent_id: 1115 },
	{ id: 31304, name: 'Cheraga', parent_id: 1115 },
	{ id: 31320, name: 'Douera', parent_id: 1115 },
	{ id: 31370, name: 'Lardjem', parent_id: 1133 },
	{ id: 31455, name: 'Tissemsilt', parent_id: 1133 },
	{ id: 31476, name: '’Aïn el Hammam', parent_id: 1131 },
	{ id: 31236, name: 'Arhribs', parent_id: 1131 },
	{ id: 31238, name: 'Azazga', parent_id: 1131 },
	{ id: 31262, name: 'Beni Douala', parent_id: 1131 },
	{ id: 31277, name: 'Boghni', parent_id: 1131 },
	{ id: 31264, name: 'Beni Mester', parent_id: 1107 },
	{ id: 31266, name: 'Bensekrane', parent_id: 1107 },
	{ id: 31306, name: 'Chetouane', parent_id: 1107 },
	{ id: 31354, name: 'Hennaya', parent_id: 1107 },
	{ id: 31374, name: 'Mansoûra', parent_id: 1107 },
	{ id: 1, name: 'Andorra la Vella', parent_id: 488 },
	{ id: 3, name: 'Canillo', parent_id: 489 },
	{ id: 4, name: 'El Tarter', parent_id: 489 },
	{ id: 5, name: 'Encamp', parent_id: 487 },
	{ id: 7, name: 'Pas de la Casa', parent_id: 487 },
	{ id: 10, name: 'les Escaldes', parent_id: 492 },
	{ id: 2, name: 'Arinsal', parent_id: 493 },
	{ id: 9, name: 'la Massana', parent_id: 493 },
	{ id: 6, name: 'Ordino', parent_id: 491 },
	{ id: 8, name: 'Sant Julià de Lòria', parent_id: 490 },
	{ id: 153645, name: 'Ambriz', parent_id: 221 },
	{ id: 153646, name: 'Bula Atumba', parent_id: 221 },
	{ id: 598, name: 'Caxito', parent_id: 221 },
	{ id: 153647, name: 'Dande', parent_id: 221 },
	{ id: 153648, name: 'Muxima', parent_id: 221 },
	{ id: 153653, name: 'Baía Farta', parent_id: 218 },
	{ id: 153654, name: 'Balombo', parent_id: 218 },
	{ id: 590, name: 'Benguela', parent_id: 218 },
	{ id: 153656, name: 'Bocoio', parent_id: 218 },
	{ id: 153659, name: 'Caimbambo', parent_id: 218 },
	{ id: 595, name: 'Camacupa', parent_id: 212 },
	{ id: 596, name: 'Catabola', parent_id: 212 },
	{ id: 605, name: 'Chissamba', parent_id: 212 },
	{ id: 606, name: 'Cuito', parent_id: 212 },
	{ id: 591, name: 'Cabinda', parent_id: 228 },
	{ id: 625, name: 'Menongue', parent_id: 226 },
	{ id: 594, name: 'Camabatela', parent_id: 217 },
	{ id: 627, name: 'N’dalatando', parent_id: 217 },
	{ id: 629, name: 'Quibala', parent_id: 216 },
	{ id: 634, name: 'Sumbe', parent_id: 216 },
	{ id: 635, name: 'Uacu Cungo', parent_id: 216 },
	{ id: 628, name: 'Ondjiva', parent_id: 215 },
	{ id: 600, name: 'Caála', parent_id: 213 },
	{ id: 601, name: 'Chela', parent_id: 213 },
	{ id: 609, name: 'Huambo', parent_id: 213 },
	{ id: 614, name: 'Longonjo', parent_id: 213 },
	{ id: 592, name: 'Caconda', parent_id: 225 },
	{ id: 593, name: 'Caluquembe', parent_id: 225 },
	{ id: 602, name: 'Chibia', parent_id: 225 },
	{ id: 603, name: 'Chicomba', parent_id: 225 },
	{ id: 604, name: 'Chipindo', parent_id: 225 },
	{ id: 589, name: 'Belas', parent_id: 222 },
	{ id: 153641, name: 'Cacuaco', parent_id: 222 },
	{ id: 153642, name: 'Cazenga', parent_id: 222 },
	{ id: 611, name: 'Icolo e Bengo', parent_id: 222 },
	{ id: 615, name: 'Luanda', parent_id: 222 },
	{ id: 618, name: 'Lucapa', parent_id: 223 },
	{ id: 599, name: 'Cazaji', parent_id: 220 },
	{ id: 632, name: 'Saurimo', parent_id: 220 },
	{ id: 622, name: 'Malanje', parent_id: 227 },
	{ id: 621, name: 'Léua', parent_id: 219 },
	{ id: 616, name: 'Luau', parent_id: 219 },
	{ id: 619, name: 'Luena', parent_id: 219 },
	{ id: 620, name: 'Lumeje', parent_id: 219 },
	{ id: 636, name: 'Uíge', parent_id: 224 },
	{ id: 624, name: 'Mbanza Congo', parent_id: 214 },
	{ id: 626, name: 'N\'zeto', parent_id: 214 },
	{ id: 633, name: 'Soio', parent_id: 214 },
	{ id: 144, name: 'Codrington', parent_id: 3708 },
	{ id: 148, name: 'Piggotts', parent_id: 3709 },
	{ id: 149, name: 'Potters Village', parent_id: 3706 },
	{ id: 150, name: 'Saint John’s', parent_id: 3706 },
	{ id: 143, name: 'Bolands', parent_id: 3707 },
	{ id: 145, name: 'Falmouth', parent_id: 3705 },
	{ id: 146, name: 'Liberta', parent_id: 3705 },
	{ id: 142, name: 'All Saints', parent_id: 3704 },
	{ id: 147, name: 'Parham', parent_id: 3704 },
	{ id: 682, name: 'Balvanera', parent_id: 3656 },
	{ id: 683, name: 'Barracas', parent_id: 3656 },
	{ id: 687, name: 'Belgrano', parent_id: 3656 },
	{ id: 697, name: 'Boedo', parent_id: 3656 },
	{ id: 704, name: 'Buenos Aires', parent_id: 3656 },
	{ id: 659, name: 'Ancasti', parent_id: 3647 },
	{ id: 661, name: 'Andalgalá', parent_id: 3647 },
	{ id: 663, name: 'Antofagasta de la Sierra', parent_id: 3647 },
	{ id: 726, name: 'Capayán', parent_id: 3647 },
	{ id: 824, name: 'Departamento de Ambato', parent_id: 3647 },
	{ id: 677, name: 'Aviá Terai', parent_id: 3640 },
	{ id: 685, name: 'Barranqueras', parent_id: 3640 },
	{ id: 686, name: 'Basail', parent_id: 3640 },
	{ id: 719, name: 'Campo Largo', parent_id: 3640 },
	{ id: 730, name: 'Capitán Solari', parent_id: 3640 },
	{ id: 655, name: 'Alto Río Senguer', parent_id: 3651 },
	{ id: 715, name: 'Camarones', parent_id: 3651 },
	{ id: 787, name: 'Comodoro Rivadavia', parent_id: 3651 },
	{ id: 839, name: 'Departamento de Biedma', parent_id: 3651 },
	{ id: 873, name: 'Departamento de Cushamen', parent_id: 3651 },
	{ id: 639, name: 'Achiras', parent_id: 3642 },
	{ id: 640, name: 'Adelia María', parent_id: 3642 },
	{ id: 641, name: 'Agua de Oro', parent_id: 3642 },
	{ id: 647, name: 'Alejandro Roca', parent_id: 3642 },
	{ id: 648, name: 'Alejo Ledesma', parent_id: 3642 },
	{ id: 658, name: 'Alvear', parent_id: 3638 },
	{ id: 696, name: 'Berón de Astrada', parent_id: 3638 },
	{ id: 698, name: 'Bonpland', parent_id: 3638 },
	{ id: 756, name: 'Chavarría', parent_id: 3638 },
	{ id: 789, name: 'Concepción', parent_id: 3638 },
	{ id: 645, name: 'Aldea San Antonio', parent_id: 3654 },
	{ id: 665, name: 'Aranguren', parent_id: 3654 },
	{ id: 700, name: 'Bovril', parent_id: 3654 },
	{ id: 735, name: 'Caseros', parent_id: 3654 },
	{ id: 745, name: 'Ceibas', parent_id: 3654 },
	{ id: 772, name: 'Clorinda', parent_id: 3652 },
	{ id: 785, name: 'Comandante Fontana', parent_id: 3652 },
	{ id: 958, name: 'Departamento de Pilcomayo', parent_id: 3652 },
	{ id: 1053, name: 'El Colorado', parent_id: 3652 },
	{ id: 1072, name: 'Estanislao del Campo', parent_id: 3652 },
	{ id: 638, name: 'Abra Pampa', parent_id: 3645 },
	{ id: 709, name: 'Caimancito', parent_id: 3645 },
	{ id: 713, name: 'Calilegua', parent_id: 3645 },
	{ id: 862, name: 'Departamento de Cochinoca', parent_id: 3645 },
	{ id: 965, name: 'Departamento de Rinconada', parent_id: 3645 },
	{ id: 652, name: 'Alpachiri', parent_id: 3655 },
	{ id: 654, name: 'Alta Italia', parent_id: 3655 },
	{ id: 662, name: 'Anguil', parent_id: 3655 },
	{ id: 666, name: 'Arata', parent_id: 3655 },
	{ id: 692, name: 'Bernardo Larroudé', parent_id: 3655 },
	{ id: 667, name: 'Arauco', parent_id: 3653 },
	{ id: 738, name: 'Castro Barros', parent_id: 3653 },
	{ id: 752, name: 'Chamical', parent_id: 3653 },
	{ id: 761, name: 'Chilecito', parent_id: 3653 },
	{ id: 831, name: 'Departamento de Arauco', parent_id: 3653 },
	{ id: 850, name: 'Departamento de Capital', parent_id: 3646 },
	{ id: 889, name: 'Departamento de General Alvear', parent_id: 3646 },
	{ id: 898, name: 'Departamento de Godoy Cruz', parent_id: 3646 },
	{ id: 905, name: 'Departamento de Guaymallén', parent_id: 3646 },
	{ id: 919, name: 'Departamento de La Paz', parent_id: 3646 },
	{ id: 643, name: 'Alba Posse', parent_id: 3644 },
	{ id: 650, name: 'Almafuerte', parent_id: 3644 },
	{ id: 669, name: 'Aristóbulo del Valle', parent_id: 3644 },
	{ id: 674, name: 'Arroyo del Medio', parent_id: 3644 },
	{ id: 678, name: 'Azara', parent_id: 3644 },
	{ id: 657, name: 'Aluminé', parent_id: 3648 },
	{ id: 660, name: 'Andacollo', parent_id: 3648 },
	{ id: 680, name: 'Añelo', parent_id: 3648 },
	{ id: 684, name: 'Barrancas', parent_id: 3648 },
	{ id: 706, name: 'Buta Ranquil', parent_id: 3648 },
	{ id: 649, name: 'Allen', parent_id: 3639 },
	{ id: 739, name: 'Catriel', parent_id: 3639 },
	{ id: 750, name: 'Cervantes', parent_id: 3639 },
	{ id: 759, name: 'Chichinales', parent_id: 3639 },
	{ id: 763, name: 'Chimpay', parent_id: 3639 },
	{ id: 664, name: 'Apolinario Saravia', parent_id: 3643 },
	{ id: 707, name: 'Cachí', parent_id: 3643 },
	{ id: 708, name: 'Cafayate', parent_id: 3643 },
	{ id: 720, name: 'Campo Quijano', parent_id: 3643 },
	{ id: 760, name: 'Chicoana', parent_id: 3643 },
	{ id: 644, name: 'Albardón', parent_id: 3634 },
	{ id: 714, name: 'Calingasta', parent_id: 3634 },
	{ id: 741, name: 'Caucete', parent_id: 3634 },
	{ id: 762, name: 'Chimbas', parent_id: 3634 },
	{ id: 821, name: 'Departamento de Albardón', parent_id: 3634 },
	{ id: 703, name: 'Buena Esperanza', parent_id: 3636 },
	{ id: 725, name: 'Candelaria', parent_id: 3636 },
	{ id: 788, name: 'Concarán', parent_id: 3636 },
	{ id: 1170, name: 'Juan Martín de Pueyrredón', parent_id: 3636 },
	{ id: 1174, name: 'Justo Daract', parent_id: 3636 },
	{ id: 637, name: '28 de Noviembre', parent_id: 3649 },
	{ id: 711, name: 'Caleta Olivia', parent_id: 3649 },
	{ id: 786, name: 'Comandante Luis Piedra Buena', parent_id: 3649 },
	{ id: 874, name: 'Departamento de Deseado', parent_id: 3649 },
	{ id: 906, name: 'Departamento de Güer Aike', parent_id: 3649 },
	{ id: 670, name: 'Armstrong', parent_id: 3641 },
	{ id: 673, name: 'Arroyo Seco', parent_id: 3641 },
	{ id: 675, name: 'Arrufó', parent_id: 3641 },
	{ id: 676, name: 'Avellaneda', parent_id: 3641 },
	{ id: 689, name: 'Bella Italia', parent_id: 3641 },
	{ id: 679, name: 'Añatuya', parent_id: 3635 },
	{ id: 691, name: 'Beltrán', parent_id: 3635 },
	{ id: 717, name: 'Campo Gallo', parent_id: 3635 },
	{ id: 771, name: 'Clodomira', parent_id: 3635 },
	{ id: 777, name: 'Colonia Dora', parent_id: 3635 },
	{ id: 1392, name: 'Río Grande', parent_id: 3650 },
	{ id: 1508, name: 'Tolhuin', parent_id: 3650 },
	{ id: 1524, name: 'Ushuaia', parent_id: 3650 },
	{ id: 642, name: 'Aguilares', parent_id: 3637 },
	{ id: 646, name: 'Alderetes', parent_id: 3637 },
	{ id: 690, name: 'Bella Vista', parent_id: 3637 },
	{ id: 705, name: 'Burruyacú', parent_id: 3637 },
	{ id: 840, name: 'Departamento de Burruyacú', parent_id: 3637 },
	{ id: 286, name: 'Agarakavan', parent_id: 2023 },
	{ id: 303, name: 'Aparan', parent_id: 2023 },
	{ id: 305, name: 'Aragats', parent_id: 2023 },
	{ id: 329, name: 'Arteni', parent_id: 2023 },
	{ id: 334, name: 'Ashnak', parent_id: 2023 },
	{ id: 282, name: 'Abovyan', parent_id: 2024 },
	{ id: 307, name: 'Aralez', parent_id: 2024 },
	{ id: 309, name: 'Ararat', parent_id: 2024 },
	{ id: 314, name: 'Arevabuyr', parent_id: 2024 },
	{ id: 319, name: 'Arevshat', parent_id: 2024 },
	{ id: 289, name: 'Aghavnatun', parent_id: 2026 },
	{ id: 292, name: 'Aknalich', parent_id: 2026 },
	{ id: 293, name: 'Aknashen', parent_id: 2026 },
	{ id: 297, name: 'Alashkert', parent_id: 2026 },
	{ id: 302, name: 'Apaga', parent_id: 2026 },
	{ id: 296, name: 'Akunk’', parent_id: 2028 },
	{ id: 336, name: 'Astghadzor', parent_id: 2028 },
	{ id: 364, name: 'Chambarak', parent_id: 2028 },
	{ id: 371, name: 'Ddmashen', parent_id: 2028 },
	{ id: 375, name: 'Drakhtik', parent_id: 2028 },
	{ id: 281, name: 'Abovyan', parent_id: 2033 },
	{ id: 287, name: 'Aghavnadzor', parent_id: 2033 },
	{ id: 295, name: 'Akunk’', parent_id: 2033 },
	{ id: 308, name: 'Aramus', parent_id: 2033 },
	{ id: 323, name: 'Argel', parent_id: 2033 },
	{ id: 283, name: 'Agarak', parent_id: 2029 },
	{ id: 290, name: 'Akht’ala', parent_id: 2029 },
	{ id: 298, name: 'Alaverdi', parent_id: 2029 },
	{ id: 316, name: 'Arevashogh', parent_id: 2029 },
	{ id: 353, name: 'Bazum', parent_id: 2029 },
	{ id: 291, name: 'Akhuryan', parent_id: 2031 },
	{ id: 299, name: 'Amasia', parent_id: 2031 },
	{ id: 301, name: 'Anushavan', parent_id: 2031 },
	{ id: 318, name: 'Arevik', parent_id: 2031 },
	{ id: 320, name: 'Arevshat', parent_id: 2031 },
	{ id: 284, name: 'Agarak', parent_id: 2027 },
	{ id: 294, name: 'Akner', parent_id: 2027 },
	{ id: 300, name: 'Angeghakot’', parent_id: 2027 },
	{ id: 358, name: 'Brrnakot’', parent_id: 2027 },
	{ id: 381, name: 'Dzorastan', parent_id: 2027 },
	{ id: 312, name: 'Archis', parent_id: 2032 },
	{ id: 331, name: 'Artsvaberd', parent_id: 2032 },
	{ id: 339, name: 'Aygehovit', parent_id: 2032 },
	{ id: 345, name: 'Azatamut', parent_id: 2032 },
	{ id: 348, name: 'Bagratashen', parent_id: 2032 },
	{ id: 285, name: 'Agarakadzor', parent_id: 2025 },
	{ id: 288, name: 'Aghavnadzor', parent_id: 2025 },
	{ id: 313, name: 'Areni', parent_id: 2025 },
	{ id: 393, name: 'Getap’', parent_id: 2025 },
	{ id: 397, name: 'Gladzor', parent_id: 2025 },
	{ id: 304, name: 'Arabkir', parent_id: 2030 },
	{ id: 321, name: 'Argavand', parent_id: 2030 },
	{ id: 422, name: 'Jrashen', parent_id: 2030 },
	{ id: 436, name: 'K’anak’erravan', parent_id: 2030 },
	{ id: 555, name: 'Vardadzor', parent_id: 2030 },
	{ id: 3915, name: 'Acton', parent_id: 3907 },
	{ id: 3923, name: 'Ainslie', parent_id: 3907 },
	{ id: 3974, name: 'Amaroo', parent_id: 3907 },
	{ id: 3995, name: 'Aranda', parent_id: 3907 },
	{ id: 4098, name: 'Banks', parent_id: 3907 },
	{ id: 3903, name: 'Abbotsbury', parent_id: 3909 },
	{ id: 3905, name: 'Abbotsford', parent_id: 3909 },
	{ id: 3906, name: 'Abercrombie', parent_id: 3909 },
	{ id: 3907, name: 'Aberdare', parent_id: 3909 },
	{ id: 3908, name: 'Aberdeen', parent_id: 3909 },
	{ id: 3928, name: 'Alawa', parent_id: 3910 },
	{ id: 3957, name: 'Alice Springs', parent_id: 3910 },
	{ id: 3973, name: 'Alyangula', parent_id: 3910 },
	{ id: 3989, name: 'Anula', parent_id: 3910 },
	{ id: 3993, name: 'Araluen', parent_id: 3910 },
	{ id: 3914, name: 'Acacia Ridge', parent_id: 3905 },
	{ id: 3922, name: 'Agnes Water', parent_id: 3905 },
	{ id: 3925, name: 'Airlie Beach', parent_id: 3905 },
	{ id: 3927, name: 'Aitkenvale', parent_id: 3905 },
	{ id: 3931, name: 'Albany Creek', parent_id: 3905 },
	{ id: 3910, name: 'Aberfoyle Park', parent_id: 3904 },
	{ id: 3919, name: 'Adelaide', parent_id: 3904 },
	{ id: 3921, name: 'Adelaide city centre', parent_id: 3904 },
	{ id: 3920, name: 'Adelaide Hills', parent_id: 3904 },
	{ id: 3933, name: 'Albert Park', parent_id: 3904 },
	{ id: 3916, name: 'Acton Park', parent_id: 3908 },
	{ id: 4047, name: 'Austins Ferry', parent_id: 3908 },
	{ id: 4062, name: 'Bagdad', parent_id: 3908 },
	{ id: 4145, name: 'Battery Point', parent_id: 3908 },
	{ id: 4160, name: 'Beaconsfield', parent_id: 3908 },
	{ id: 3904, name: 'Abbotsford', parent_id: 3903 },
	{ id: 3909, name: 'Aberfeldie', parent_id: 3903 },
	{ id: 3926, name: 'Airport West', parent_id: 3903 },
	{ id: 3929, name: 'Albanvale', parent_id: 3903 },
	{ id: 3934, name: 'Albert Park', parent_id: 3903 },
	{ id: 3902, name: 'Abbey', parent_id: 3906 },
	{ id: 3930, name: 'Albany', parent_id: 3906 },
	{ id: 3932, name: 'Albany city centre', parent_id: 3906 },
	{ id: 3946, name: 'Alexander Heights', parent_id: 3906 },
	{ id: 3953, name: 'Alfred Cove', parent_id: 3906 },
	{ id: 1657, name: 'Andau', parent_id: 2062 },
	{ id: 1671, name: 'Antau', parent_id: 2062 },
	{ id: 1673, name: 'Apetlon', parent_id: 2062 },
	{ id: 1734, name: 'Bad Sauerbrunn', parent_id: 2062 },
	{ id: 1737, name: 'Bad Tatzmannsdorf', parent_id: 2062 },
	{ id: 143783, name: 'Albeck', parent_id: 2057 },
	{ id: 1646, name: 'Althofen', parent_id: 2057 },
	{ id: 1668, name: 'Annabichl', parent_id: 2057 },
	{ id: 1679, name: 'Arnoldstein', parent_id: 2057 },
	{ id: 1681, name: 'Arriach', parent_id: 2057 },
	{ id: 1602, name: 'Absdorf', parent_id: 2065 },
	{ id: 1606, name: 'Achau', parent_id: 2065 },
	{ id: 1608, name: 'Aderklaa', parent_id: 2065 },
	{ id: 1614, name: 'Aggsbach', parent_id: 2065 },
	{ id: 1627, name: 'Alland', parent_id: 2065 },
	{ id: 1603, name: 'Abtenau', parent_id: 2061 },
	{ id: 1611, name: 'Adnet', parent_id: 2061 },
	{ id: 1644, name: 'Altenmarkt im Pongau', parent_id: 2061 },
	{ id: 1663, name: 'Anger', parent_id: 2061 },
	{ id: 1667, name: 'Anif', parent_id: 2061 },
	{ id: 1604, name: 'Abtissendorf', parent_id: 2059 },
	{ id: 1610, name: 'Admont', parent_id: 2059 },
	{ id: 1613, name: 'Aflenz Kurort', parent_id: 2059 },
	{ id: 1616, name: 'Aibl', parent_id: 2059 },
	{ id: 1618, name: 'Aigen im Ennstal', parent_id: 2059 },
	{ id: 1600, name: 'Abfaltersbach', parent_id: 2064 },
	{ id: 1601, name: 'Absam', parent_id: 2064 },
	{ id: 1607, name: 'Achenkirch', parent_id: 2064 },
	{ id: 1620, name: 'Ainet', parent_id: 2064 },
	{ id: 1625, name: 'Aldrans', parent_id: 2064 },
	{ id: 1605, name: 'Abwinden', parent_id: 2058 },
	{ id: 1609, name: 'Adlwang', parent_id: 2058 },
	{ id: 1612, name: 'Afiesl', parent_id: 2058 },
	{ id: 1615, name: 'Ahorn', parent_id: 2058 },
	{ id: 1617, name: 'Aichkirchen', parent_id: 2058 },
	{ id: 1856, name: 'Donaustadt', parent_id: 2060 },
	{ id: 1963, name: 'Favoriten', parent_id: 2060 },
	{ id: 2002, name: 'Floridsdorf', parent_id: 2060 },
	{ id: 2272, name: 'Hernals', parent_id: 2060 },
	{ id: 2280, name: 'Hietzing', parent_id: 2060 },
	{ id: 1623, name: 'Alberschwende', parent_id: 2063 },
	{ id: 1635, name: 'Altach', parent_id: 2063 },
	{ id: 1658, name: 'Andelsbuch', parent_id: 2063 },
	{ id: 1701, name: 'Au', parent_id: 2063 },
	{ id: 1712, name: 'Ausserbraz', parent_id: 2063 },
	{ id: 8069, name: 'Ceyranbatan', parent_id: 540 },
	{ id: 8073, name: 'Digah', parent_id: 540 },
	{ id: 8085, name: 'Gyuzdek', parent_id: 540 },
	{ id: 8099, name: 'Khirdalan', parent_id: 540 },
	{ id: 8148, name: 'Qobu', parent_id: 540 },
	{ id: 8049, name: 'Ağdam', parent_id: 559 },
	{ id: 8050, name: 'Ağdaş', parent_id: 553 },
	{ id: 8037, name: 'Agdzhabedy', parent_id: 577 },
	{ id: 8048, name: 'Avşar', parent_id: 577 },
	{ id: 8038, name: 'Aghstafa', parent_id: 543 },
	{ id: 8163, name: 'Saloğlu', parent_id: 543 },
	{ id: 8188, name: 'Vurğun', parent_id: 543 },
	{ id: 8039, name: 'Aghsu', parent_id: 547 },
	{ id: 8047, name: 'Astara', parent_id: 528 },
	{ id: 8103, name: 'Kizhaba', parent_id: 528 },
	{ id: 8042, name: 'Amirdzhan', parent_id: 552 },
	{ id: 8051, name: 'Badamdar', parent_id: 552 },
	{ id: 8053, name: 'Bakıxanov', parent_id: 552 },
	{ id: 8052, name: 'Baku', parent_id: 552 },
	{ id: 8054, name: 'Balakhani', parent_id: 552 },
	{ id: 8058, name: 'Belokany', parent_id: 560 },
	{ id: 8135, name: 'Qabaqçöl', parent_id: 560 },
	{ id: 8055, name: 'Barda', parent_id: 569 },
	{ id: 8167, name: 'Samuxlu', parent_id: 569 },
	{ id: 8059, name: 'Beylagan', parent_id: 554 },
	{ id: 8064, name: 'Birinci Aşıqlı', parent_id: 554 },
	{ id: 8078, name: 'Dünyamalılar', parent_id: 554 },
	{ id: 8128, name: 'Orjonikidze', parent_id: 554 },
	{ id: 8200, name: 'Yuxarı Aran', parent_id: 554 },
	{ id: 8133, name: 'Pushkino', parent_id: 532 },
	{ id: 8187, name: 'Verkhniy Dashkesan', parent_id: 561 },
	{ id: 8199, name: 'Yukhary-Dashkesan', parent_id: 561 },
	{ id: 8080, name: 'Fizuli', parent_id: 527 },
	{ id: 8092, name: 'Horadiz', parent_id: 527 },
	{ id: 8081, name: 'Ganja', parent_id: 585 },
	{ id: 8044, name: 'Arıqdam', parent_id: 589 },
	{ id: 8045, name: 'Arıqıran', parent_id: 589 },
	{ id: 8067, name: 'Böyük Qaramurad', parent_id: 589 },
	{ id: 8105, name: 'Kyadabek', parent_id: 589 },
	{ id: 8124, name: 'Novosaratovka', parent_id: 589 },
	{ id: 8150, name: 'Qobustan', parent_id: 573 },
	{ id: 8084, name: 'Goranboy', parent_id: 551 },
	{ id: 8158, name: 'Qızılhacılı', parent_id: 551 },
	{ id: 8082, name: 'Geoktschai', parent_id: 531 },
	{ id: 8196, name: 'Yelenendorf', parent_id: 574 },
	{ id: 8088, name: 'Hacıqabul', parent_id: 571 },
	{ id: 8119, name: 'Mughan', parent_id: 571 },
	{ id: 8094, name: 'Imishli', parent_id: 544 },
	{ id: 8056, name: 'Basqal', parent_id: 564 },
	{ id: 8210, name: 'İsmayıllı', parent_id: 564 },
	{ id: 8096, name: 'Jebrail', parent_id: 570 },
	{ id: 8095, name: 'Jalilabad', parent_id: 578 },
	{ id: 8132, name: 'Prishibinskoye', parent_id: 578 },
	{ id: 8098, name: 'Kerbakhiar', parent_id: 525 },
	{ id: 8186, name: 'Vank', parent_id: 525 },
	{ id: 8189, name: 'Xaçmaz', parent_id: 590 },
	{ id: 8191, name: 'Xudat', parent_id: 590 },
	{ id: 8041, name: 'Altıağac', parent_id: 537 },
	{ id: 8101, name: 'Khyzy', parent_id: 537 },
	{ id: 8102, name: 'Kilyazi', parent_id: 537 },
	{ id: 8213, name: 'Şuraabad', parent_id: 537 },
	{ id: 8046, name: 'Askyaran', parent_id: 524 },
	{ id: 8190, name: 'Xocalı', parent_id: 524 },
	{ id: 8106, name: 'Kyurdarmir', parent_id: 549 },
	{ id: 8109, name: 'Laçın', parent_id: 541 },
	{ id: 8090, name: 'Haftoni', parent_id: 558 },
	{ id: 8108, name: 'Lankaran', parent_id: 558 },
	{ id: 8110, name: 'Lerik', parent_id: 546 },
	{ id: 8089, name: 'Hadrut', parent_id: 568 },
	{ id: 8125, name: 'Novyy Karanlug', parent_id: 568 },
	{ id: 8155, name: 'Qırmızı Bazar', parent_id: 568 },
	{ id: 8065, name: 'Boradigah', parent_id: 555 },
	{ id: 8115, name: 'Masally', parent_id: 555 },
	{ id: 8118, name: 'Mingelchaur', parent_id: 580 },
	{ id: 8068, name: 'Cahri', parent_id: 562 },
	{ id: 8206, name: 'Çalxanqala', parent_id: 562 },
	{ id: 8071, name: 'Culfa', parent_id: 562 },
	{ id: 8072, name: 'Deste', parent_id: 562 },
	{ id: 8091, name: 'Heydarabad', parent_id: 562 },
	{ id: 8122, name: 'Neftçala', parent_id: 530 },
	{ id: 8171, name: 'Severo-Vostotchnyi Bank', parent_id: 530 },
	{ id: 8178, name: 'Sovetabad', parent_id: 530 },
	{ id: 8192, name: 'Xıllı', parent_id: 530 },
	{ id: 8130, name: 'Oğuz', parent_id: 556 },
	{ id: 8154, name: 'Qutqashen', parent_id: 542 },
	{ id: 8208, name: 'Çinarlı', parent_id: 526 },
	{ id: 8144, name: 'Qax', parent_id: 526 },
	{ id: 8145, name: 'Qax İngiloy', parent_id: 526 },
	{ id: 8146, name: 'Qaxbaş', parent_id: 526 },
	{ id: 8147, name: 'Qazax', parent_id: 521 },
	{ id: 8087, name: 'Hacıhüseynli', parent_id: 563 },
	{ id: 8151, name: 'Quba', parent_id: 563 },
	{ id: 8152, name: 'Qubadlı', parent_id: 548 },
	{ id: 8153, name: 'Qusar', parent_id: 588 },
	{ id: 8165, name: 'Samur', parent_id: 588 },
	{ id: 8214, name: 'Əhmədbəyli', parent_id: 557 },
	{ id: 8160, name: 'Saatlı', parent_id: 557 },
	{ id: 8161, name: 'Sabirabad', parent_id: 565 },
	{ id: 8141, name: 'Qaraçala', parent_id: 545 },
	{ id: 8164, name: 'Salyan', parent_id: 545 },
	{ id: 8140, name: 'Qarayeri', parent_id: 536 },
	{ id: 8156, name: 'Qırmızı Samux', parent_id: 536 },
	{ id: 8166, name: 'Samux', parent_id: 536 },
	{ id: 8074, name: 'Divichibazar', parent_id: 591 },
	{ id: 8176, name: 'Sheki', parent_id: 518 },
	{ id: 8057, name: 'Baş Göynük', parent_id: 586 },
	{ id: 8173, name: 'Shamakhi', parent_id: 529 },
	{ id: 8075, name: 'Dolyar', parent_id: 583 },
	{ id: 8077, name: 'Dzagam', parent_id: 583 },
	{ id: 8143, name: 'Qasım İsmayılov', parent_id: 583 },
	{ id: 8174, name: 'Shamkhor', parent_id: 583 },
	{ id: 8212, name: 'Şirvan', parent_id: 520 },
	{ id: 8177, name: 'Shushi', parent_id: 592 },
	{ id: 8083, name: 'Gilgilçay', parent_id: 584 },
	{ id: 8107, name: 'Kyzyl-Burun', parent_id: 584 },
	{ id: 8070, name: 'Corat', parent_id: 582 },
	{ id: 8086, name: 'Hacı Zeynalabdin', parent_id: 582 },
	{ id: 8180, name: 'Sumqayıt', parent_id: 582 },
	{ id: 8114, name: 'Martakert', parent_id: 519 },
	{ id: 8182, name: 'Terter', parent_id: 519 },
	{ id: 8207, name: 'Çatax', parent_id: 533 },
	{ id: 8209, name: 'Çobansığnaq', parent_id: 533 },
	{ id: 8076, name: 'Dondar Quşçu', parent_id: 533 },
	{ id: 8139, name: 'Qaraxanlı', parent_id: 533 },
	{ id: 8183, name: 'Tovuz', parent_id: 533 },
	{ id: 8185, name: 'Ujar', parent_id: 539 },
	{ id: 8194, name: 'Yardımlı', parent_id: 550 },
	{ id: 8198, name: 'Yevlakh', parent_id: 538 },
	{ id: 8043, name: 'Aran', parent_id: 523 },
	{ id: 8138, name: 'Qaramanlı', parent_id: 523 },
	{ id: 8117, name: 'Mincivan', parent_id: 581 },
	{ id: 8202, name: 'Zangilan', parent_id: 581 },
	{ id: 8040, name: 'Aliabad', parent_id: 566 },
	{ id: 8079, name: 'Faldarlı', parent_id: 566 },
	{ id: 8112, name: 'Mamrux', parent_id: 566 },
	{ id: 8137, name: 'Qandax', parent_id: 566 },
	{ id: 8203, name: 'Zaqatala', parent_id: 566 },
	{ id: 8204, name: 'Zardob', parent_id: 576 },
	{ id: 9757, name: 'Jidd Ḩafş', parent_id: 1992 },
	{ id: 9760, name: 'Manama', parent_id: 1992 },
	{ id: 9761, name: 'Sitrah', parent_id: 1992 },
	{ id: 9758, name: 'Madīnat Ḩamad', parent_id: 1996 },
	{ id: 9754, name: 'Al Ḩadd', parent_id: 1995 },
	{ id: 9753, name: 'Al Muharraq', parent_id: 1995 },
	{ id: 9755, name: 'Ar Rifā‘', parent_id: 1993 },
	{ id: 9756, name: 'Dār Kulayb', parent_id: 1993 },
	{ id: 9759, name: 'Madīnat ‘Īsá', parent_id: 1993 },
	{ id: 153858, name: 'Barguna ', parent_id: 807 },
	{ id: 153857, name: 'Barisal ', parent_id: 807 },
	{ id: 153859, name: 'Bhola ', parent_id: 807 },
	{ id: 153860, name: 'Jhalokati ', parent_id: 807 },
	{ id: 153861, name: 'Patuakhali ', parent_id: 807 },
	{ id: 8457, name: 'Bandarban', parent_id: 803 },
	{ id: 8470, name: 'Brahmanbaria', parent_id: 803 },
	{ id: 8474, name: 'Chandpur', parent_id: 803 },
	{ id: 8481, name: 'Chittagong', parent_id: 803 },
	{ id: 8483, name: 'Comilla', parent_id: 803 },
	{ id: 153863, name: 'Dhaka ', parent_id: 760 },
	{ id: 153871, name: 'Faridpur ', parent_id: 760 },
	{ id: 153864, name: 'Gazipur ', parent_id: 760 },
	{ id: 153872, name: 'Gopalganj ', parent_id: 760 },
	{ id: 153865, name: 'Kishoreganj ', parent_id: 760 },
	{ id: 153876, name: 'Bagerhat ', parent_id: 775 },
	{ id: 153877, name: 'Chuadanga ', parent_id: 775 },
	{ id: 153878, name: 'Jessore ', parent_id: 775 },
	{ id: 153879, name: 'Jhenaidah ', parent_id: 775 },
	{ id: 153880, name: 'Khulna ', parent_id: 775 },
	{ id: 153887, name: 'Jamalpur ', parent_id: 758 },
	{ id: 153886, name: 'Mymensingh ', parent_id: 758 },
	{ id: 153888, name: 'Netrokona ', parent_id: 758 },
	{ id: 153889, name: 'Sherpur ', parent_id: 758 },
	{ id: 153894, name: 'Bogra ', parent_id: 753 },
	{ id: 153895, name: 'Chapai Nawabganj', parent_id: 753 },
	{ id: 153897, name: 'Joypurhat ', parent_id: 753 },
	{ id: 153896, name: 'Naogaon ', parent_id: 753 },
	{ id: 153890, name: 'Natore ', parent_id: 753 },
	{ id: 153898, name: 'Dinajpur ', parent_id: 750 },
	{ id: 153900, name: 'Gaibandha ', parent_id: 750 },
	{ id: 153899, name: 'Kurigram ', parent_id: 750 },
	{ id: 153901, name: 'Lalmonirhat ', parent_id: 750 },
	{ id: 153902, name: 'Nilphamari ', parent_id: 750 },
	{ id: 153906, name: 'Habiganj ', parent_id: 765 },
	{ id: 153907, name: 'Moulvibazar ', parent_id: 765 },
	{ id: 153908, name: 'Sunamganj ', parent_id: 765 },
	{ id: 153909, name: 'Sylhet ', parent_id: 765 },
	{ id: 8452, name: 'Oistins', parent_id: 1228 },
	{ id: 8450, name: 'Greenland', parent_id: 1229 },
	{ id: 8451, name: 'Holetown', parent_id: 1224 },
	{ id: 8447, name: 'Bathsheba', parent_id: 1223 },
	{ id: 8448, name: 'Bridgetown', parent_id: 1230 },
	{ id: 8453, name: 'Speightstown', parent_id: 1222 },
	{ id: 8449, name: 'Crane', parent_id: 1220 },
	{ id: 15805, name: 'Antopal’', parent_id: 2959 },
	{ id: 15810, name: 'Asnyezhytsy', parent_id: 2959 },
	{ id: 15820, name: 'Baranovichi', parent_id: 2959 },
	{ id: 15821, name: 'Baranovichskiy Rayon', parent_id: 2959 },
	{ id: 15833, name: 'Brest', parent_id: 2959 },
	{ id: 15804, name: 'Aktsyabrski', parent_id: 2955 },
	{ id: 15829, name: 'Brahin', parent_id: 2955 },
	{ id: 15830, name: 'Brahinski Rayon', parent_id: 2955 },
	{ id: 15835, name: 'Buda-Kashalyova', parent_id: 2955 },
	{ id: 15848, name: 'Chachersk', parent_id: 2955 },
	{ id: 15806, name: 'Ashmyanski Rayon', parent_id: 2956 },
	{ id: 15807, name: 'Ashmyany', parent_id: 2956 },
	{ id: 15812, name: 'Astravyets', parent_id: 2956 },
	{ id: 15813, name: 'Astravyetski Rayon', parent_id: 2956 },
	{ id: 15823, name: 'Baruny', parent_id: 2956 },
	{ id: 15881, name: 'Frunzyenski Rayon', parent_id: 2958 },
	{ id: 15927, name: 'Kastrychnitski Rayon', parent_id: 2958 },
	{ id: 15969, name: 'Lyeninski Rayon', parent_id: 2958 },
	{ id: 15984, name: 'Maskowski Rayon', parent_id: 2958 },
	{ id: 15989, name: 'Minsk', parent_id: 2958 },
	{ id: 15811, name: 'Astrashytski Haradok', parent_id: 2957 },
	{ id: 15814, name: 'Atolina', parent_id: 2957 },
	{ id: 15815, name: 'Azyartso', parent_id: 2957 },
	{ id: 15819, name: 'Bal’shavik', parent_id: 2957 },
	{ id: 15824, name: 'Barysaw', parent_id: 2957 },
	{ id: 15808, name: 'Asipovichy', parent_id: 2954 },
	{ id: 15809, name: 'Asipovitski Rayon', parent_id: 2954 },
	{ id: 15816, name: 'Babruysk', parent_id: 2954 },
	{ id: 15817, name: 'Babruyski Rayon', parent_id: 2954 },
	{ id: 15836, name: 'Buynichy', parent_id: 2954 },
	{ id: 15818, name: 'Balbasava', parent_id: 2960 },
	{ id: 15822, name: 'Baran’', parent_id: 2960 },
	{ id: 15831, name: 'Braslaw', parent_id: 2960 },
	{ id: 15832, name: 'Braslawski Rayon', parent_id: 2960 },
	{ id: 15837, name: 'Byahoml’', parent_id: 2960 },
	{ id: 8693, name: 'Brussels', parent_id: 1376 },
	{ id: 8608, name: 'Aalst', parent_id: 1373 },
	{ id: 8609, name: 'Aalter', parent_id: 1373 },
	{ id: 8610, name: 'Aarschot', parent_id: 1373 },
	{ id: 8611, name: 'Aartselaar', parent_id: 1373 },
	{ id: 8613, name: 'Alken', parent_id: 1373 },
	{ id: 8612, name: 'Aiseau', parent_id: 1380 },
	{ id: 8615, name: 'Amay', parent_id: 1380 },
	{ id: 8616, name: 'Amblève', parent_id: 1380 },
	{ id: 8617, name: 'Andenne', parent_id: 1380 },
	{ id: 8618, name: 'Anderlues', parent_id: 1380 },
	{ id: 16133, name: 'Belize City', parent_id: 264 },
	{ id: 16143, name: 'San Pedro', parent_id: 264 },
	{ id: 16134, name: 'Belmopan', parent_id: 269 },
	{ id: 16135, name: 'Benque Viejo el Carmen', parent_id: 269 },
	{ id: 16142, name: 'San Ignacio', parent_id: 269 },
	{ id: 16145, name: 'Valley of Peace', parent_id: 269 },
	{ id: 16136, name: 'Corozal', parent_id: 266 },
	{ id: 16138, name: 'Hopelchén', parent_id: 268 },
	{ id: 16139, name: 'Orange Walk', parent_id: 268 },
	{ id: 16144, name: 'Shipyard', parent_id: 268 },
	{ id: 16137, name: 'Dangriga', parent_id: 265 },
	{ id: 16140, name: 'Placencia', parent_id: 265 },
	{ id: 16141, name: 'Punta Gorda', parent_id: 267 },
	{ id: 9782, name: 'Banikoara', parent_id: 3077 },
	{ id: 9800, name: 'Kandi', parent_id: 3077 },
	{ id: 9803, name: 'Malanville', parent_id: 3077 },
	{ id: 9797, name: 'Guilmaro', parent_id: 3076 },
	{ id: 9804, name: 'Natitingou', parent_id: 3076 },
	{ id: 9813, name: 'Tanguieta', parent_id: 3076 },
	{ id: 9780, name: 'Abomey-Calavi', parent_id: 3079 },
	{ id: 9781, name: 'Allada', parent_id: 3079 },
	{ id: 9799, name: 'Hévié', parent_id: 3079 },
	{ id: 9798, name: 'Hinvi', parent_id: 3079 },
	{ id: 9806, name: 'Ouidah', parent_id: 3079 },
	{ id: 9784, name: 'Bembèrèkè', parent_id: 3078 },
	{ id: 9786, name: 'Bétérou', parent_id: 3078 },
	{ id: 9805, name: 'Nikki', parent_id: 3078 },
	{ id: 9807, name: 'Parakou', parent_id: 3078 },
	{ id: 9815, name: 'Tchaourou', parent_id: 3078 },
	{ id: 9790, name: 'Comé', parent_id: 3070 },
	{ id: 9793, name: 'Dassa-Zoumé', parent_id: 3070 },
	{ id: 9811, name: 'Savalou', parent_id: 3070 },
	{ id: 9812, name: 'Savé', parent_id: 3070 },
	{ id: 9783, name: 'Bassila', parent_id: 3072 },
	{ id: 9789, name: 'Commune of Djougou', parent_id: 3072 },
	{ id: 9795, name: 'Djougou', parent_id: 3072 },
	{ id: 9794, name: 'Djakotomey', parent_id: 3071 },
	{ id: 9796, name: 'Dogbo', parent_id: 3071 },
	{ id: 9791, name: 'Cotonou', parent_id: 3081 },
	{ id: 9788, name: 'Commune of Athieme', parent_id: 3075 },
	{ id: 9802, name: 'Lokossa', parent_id: 3075 },
	{ id: 9809, name: 'Porto-Novo', parent_id: 3080 },
	{ id: 9801, name: 'Kétou', parent_id: 3074 },
	{ id: 9808, name: 'Pobé', parent_id: 3074 },
	{ id: 9810, name: 'Sakété', parent_id: 3074 },
	{ id: 9779, name: 'Abomey', parent_id: 3073 },
	{ id: 9785, name: 'Bohicon', parent_id: 3073 },
	{ id: 9787, name: 'Commune of Agbangnizoun', parent_id: 3073 },
	{ id: 9792, name: 'Cové', parent_id: 3073 },
	{ id: 153518, name: 'Devonshire', parent_id: 4860 },
	{ id: 153515, name: 'Hamilton', parent_id: 4861 },
	{ id: 153516, name: 'Paget', parent_id: 4863 },
	{ id: 153514, name: 'St. George\'s', parent_id: 4866 },
	{ id: 153517, name: 'Tucker’s Town', parent_id: 4866 },
	{ id: 153513, name: 'Somerset', parent_id: 4867 },
	{ id: 153512, name: 'Flatts', parent_id: 4868 },
	{ id: 153511, name: 'Southampton', parent_id: 4869 },
	{ id: 153510, name: 'Warwick', parent_id: 4870 },
	{ id: 15709, name: 'Jakar', parent_id: 240 },
	{ id: 153824, name: 'Tang Valley', parent_id: 240 },
	{ id: 153825, name: 'Chapchha', parent_id: 239 },
	{ id: 15706, name: 'Daphu', parent_id: 239 },
	{ id: 153827, name: 'Khitokha', parent_id: 239 },
	{ id: 153826, name: 'Lobnig', parent_id: 239 },
	{ id: 15726, name: 'Pachu', parent_id: 239 },
	{ id: 15705, name: 'Daga', parent_id: 238 },
	{ id: 15728, name: 'Sibsu', parent_id: 238 },
	{ id: 153828, name: 'Thumgaon', parent_id: 238 },
	{ id: 15707, name: 'Gasa', parent_id: 229 },
	{ id: 15708, name: 'Ha', parent_id: 232 },
	{ id: 153829, name: 'Naktsang', parent_id: 232 },
	{ id: 153830, name: 'Sangkari', parent_id: 232 },
	{ id: 15710, name: 'Lhuentse', parent_id: 234 },
	{ id: 153832, name: 'Kengkhar', parent_id: 242 },
	{ id: 15711, name: 'Mongar', parent_id: 242 },
	{ id: 153831, name: 'Thebong', parent_id: 242 },
	{ id: 15714, name: 'Paro', parent_id: 237 },
	{ id: 153834, name: 'Suchha', parent_id: 237 },
	{ id: 153833, name: 'Tshalunang', parent_id: 237 },
	{ id: 153835, name: 'Nganglam', parent_id: 244 },
	{ id: 15715, name: 'Pemagatshel', parent_id: 244 },
	{ id: 15712, name: 'Pajo', parent_id: 235 },
	{ id: 15717, name: 'Punākha', parent_id: 235 },
	{ id: 15718, name: 'Samdrup Jongkhar', parent_id: 243 },
	{ id: 153838, name: 'Denchukha', parent_id: 246 },
	{ id: 153837, name: 'Dorokha', parent_id: 246 },
	{ id: 15719, name: 'Samtse', parent_id: 246 },
	{ id: 153836, name: 'Tendu', parent_id: 246 },
	{ id: 153841, name: 'Bhurgaon', parent_id: 247 },
	{ id: 153840, name: 'Galechugaon', parent_id: 247 },
	{ id: 153839, name: 'Geylegphug', parent_id: 247 },
	{ id: 15720, name: 'Sarpang', parent_id: 247 },
	{ id: 15722, name: 'Thimphu', parent_id: 241 },
	{ id: 153842, name: 'Shali', parent_id: 5242 },
	{ id: 153843, name: 'Trashi Yangtse', parent_id: 5242 },
	{ id: 153844, name: 'Yalang ', parent_id: 5242 },
	{ id: 153850, name: 'Balfai', parent_id: 236 },
	{ id: 153846, name: 'Ghunkarah', parent_id: 236 },
	{ id: 153845, name: 'Ngalangkang', parent_id: 236 },
	{ id: 153847, name: 'Rungzyung', parent_id: 236 },
	{ id: 153851, name: 'Sassi', parent_id: 236 },
	{ id: 153852, name: 'Trongsa', parent_id: 245 },
	{ id: 153854, name: 'Beteni', parent_id: 230 },
	{ id: 153853, name: 'Dagapela', parent_id: 230 },
	{ id: 15725, name: 'Damphu', parent_id: 230 },
	{ id: 15727, name: 'Tsirang', parent_id: 230 },
	{ id: 153856, name: 'Manikyangsa', parent_id: 231 },
	{ id: 153855, name: 'Ritang', parent_id: 231 },
	{ id: 15723, name: 'Wangdue Phodrang', parent_id: 231 },
	{ id: 15713, name: 'Panbang', parent_id: 233 },
	{ id: 15721, name: 'Zhemgang', parent_id: 233 },
	{ id: 9867, name: 'Guayaramerín', parent_id: 3375 },
	{ id: 9920, name: 'Provincia Cercado', parent_id: 3375 },
	{ id: 9932, name: 'Provincia General José Ballivián', parent_id: 3375 },
	{ id: 9941, name: 'Provincia Iténez', parent_id: 3375 },
	{ id: 9949, name: 'Provincia Mamoré', parent_id: 3375 },
	{ id: 9839, name: 'Camargo', parent_id: 3382 },
	{ id: 9887, name: 'Monteagudo', parent_id: 3382 },
	{ id: 9892, name: 'Padilla', parent_id: 3382 },
	{ id: 9909, name: 'Provincia Azurduy', parent_id: 3382 },
	{ id: 9911, name: 'Provincia Belisario Boeto', parent_id: 3382 },
	{ id: 9827, name: 'Aiquile', parent_id: 3381 },
	{ id: 9829, name: 'Arani', parent_id: 3381 },
	{ id: 9836, name: 'Bolivar', parent_id: 3381 },
	{ id: 9841, name: 'Capinota', parent_id: 3381 },
	{ id: 9845, name: 'Chimoré', parent_id: 3381 },
	{ id: 9826, name: 'Achacachi', parent_id: 3380 },
	{ id: 9828, name: 'Amarete', parent_id: 3380 },
	{ id: 9833, name: 'Batallas', parent_id: 3380 },
	{ id: 9842, name: 'Caranavi', parent_id: 3380 },
	{ id: 9846, name: 'Chulumani', parent_id: 3380 },
	{ id: 9843, name: 'Challapata', parent_id: 3376 },
	{ id: 9868, name: 'Huanuni', parent_id: 3376 },
	{ id: 9879, name: 'Litoral de Atacama', parent_id: 3376 },
	{ id: 9882, name: 'Machacamarca', parent_id: 3376 },
	{ id: 9889, name: 'Nor Carangas Province', parent_id: 3376 },
	{ id: 9848, name: 'Cobija', parent_id: 3379 },
	{ id: 9899, name: 'Provincia Abuná', parent_id: 3379 },
	{ id: 9931, name: 'Provincia General Federico Román', parent_id: 3379 },
	{ id: 9948, name: 'Provincia Madre de Dios', parent_id: 3379 },
	{ id: 9952, name: 'Provincia Manuripi', parent_id: 3379 },
	{ id: 9832, name: 'Atocha', parent_id: 3383 },
	{ id: 9835, name: 'Betanzos', parent_id: 3383 },
	{ id: 9851, name: 'Colchani', parent_id: 3383 },
	{ id: 9853, name: 'Colquechaca', parent_id: 3383 },
	{ id: 9861, name: 'Enrique Baldivieso', parent_id: 3383 },
	{ id: 9825, name: 'Abapó', parent_id: 3377 },
	{ id: 9830, name: 'Ascención de Guarayos', parent_id: 3377 },
	{ id: 9831, name: 'Ascensión', parent_id: 3377 },
	{ id: 9837, name: 'Boyuibe', parent_id: 3377 },
	{ id: 9838, name: 'Buena Vista', parent_id: 3377 },
	{ id: 9834, name: 'Bermejo', parent_id: 3378 },
	{ id: 9862, name: 'Entre Ríos', parent_id: 3378 },
	{ id: 9903, name: 'Provincia Arce', parent_id: 3378 },
	{ id: 9907, name: 'Provincia Avilez', parent_id: 3378 },
	{ id: 9919, name: 'Provincia Cercado', parent_id: 3378 },
	{ id: 8233, name: 'Brčko', parent_id: 460 },
	{ id: 8230, name: 'Brka', parent_id: 460 },
	{ id: 8217, name: 'Banovići', parent_id: 467 },
	{ id: 8218, name: 'Barice', parent_id: 467 },
	{ id: 8219, name: 'Bihać', parent_id: 467 },
	{ id: 8220, name: 'Bijela', parent_id: 467 },
	{ id: 8222, name: 'Bila', parent_id: 467 },
	{ id: 8215, name: 'Balatun', parent_id: 470 },
	{ id: 8216, name: 'Banja Luka', parent_id: 470 },
	{ id: 8221, name: 'Bijeljina', parent_id: 470 },
	{ id: 8223, name: 'Bileća', parent_id: 470 },
	{ id: 8225, name: 'Blatnica', parent_id: 470 },
	{ id: 15738, name: 'Gobojango', parent_id: 3067 },
	{ id: 15739, name: 'Gweta', parent_id: 3067 },
	{ id: 15742, name: 'Kalamare', parent_id: 3067 },
	{ id: 15750, name: 'Letlhakane', parent_id: 3067 },
	{ id: 15752, name: 'Letsheng', parent_id: 3067 },
	{ id: 15731, name: 'Dekar', parent_id: 3061 },
	{ id: 15737, name: 'Ghanzi', parent_id: 3061 },
	{ id: 15740, name: 'Hukuntsi', parent_id: 3066 },
	{ id: 15743, name: 'Kang', parent_id: 3066 },
	{ id: 15748, name: 'Lehututu', parent_id: 3066 },
	{ id: 15759, name: 'Manyana', parent_id: 3066 },
	{ id: 15802, name: 'Tshabong', parent_id: 3066 },
	{ id: 15729, name: 'Bokaa', parent_id: 3062 },
	{ id: 15767, name: 'Mmathubudukwane', parent_id: 3062 },
	{ id: 15769, name: 'Mochudi', parent_id: 3062 },
	{ id: 15785, name: 'Pilane', parent_id: 3062 },
	{ id: 15730, name: 'Botlhapatlou', parent_id: 3069 },
	{ id: 15733, name: 'Dutlwe', parent_id: 3069 },
	{ id: 15734, name: 'Gabane', parent_id: 3069 },
	{ id: 15736, name: 'Gaphatshwe', parent_id: 3069 },
	{ id: 15746, name: 'Khudumelapye', parent_id: 3069 },
	{ id: 15732, name: 'Dukwe', parent_id: 3068 },
	{ id: 15756, name: 'Makaleng', parent_id: 3068 },
	{ id: 15760, name: 'Masunga', parent_id: 3068 },
	{ id: 15791, name: 'Sebina', parent_id: 3068 },
	{ id: 15764, name: 'Maun', parent_id: 3065 },
	{ id: 15780, name: 'Nokaneng', parent_id: 3065 },
	{ id: 15784, name: 'Pandamatenga', parent_id: 3065 },
	{ id: 15793, name: 'Sehithwa', parent_id: 3065 },
	{ id: 15796, name: 'Shakawe', parent_id: 3065 },
	{ id: 15735, name: 'Gaborone', parent_id: 3064 },
	{ id: 15741, name: 'Janeng', parent_id: 3064 },
	{ id: 15747, name: 'Kopong', parent_id: 3064 },
	{ id: 15782, name: 'Otse', parent_id: 3064 },
	{ id: 15789, name: 'Ramotswa', parent_id: 3064 },
	{ id: 15744, name: 'Kanye', parent_id: 3063 },
	{ id: 15745, name: 'Khakhea', parent_id: 3063 },
	{ id: 15777, name: 'Mosopa', parent_id: 3063 },
	{ id: 15794, name: 'Sekoma', parent_id: 3063 },
	{ id: 10078, name: 'Acrelândia', parent_id: 2012 },
	{ id: 10428, name: 'Assis Brasil', parent_id: 2012 },
	{ id: 10774, name: 'Brasiléia', parent_id: 2012 },
	{ id: 10819, name: 'Bujari', parent_id: 2012 },
	{ id: 11091, name: 'Capixaba', parent_id: 2012 },
	{ id: 10227, name: 'Anadia', parent_id: 2007 },
	{ id: 10337, name: 'Arapiraca', parent_id: 2007 },
	{ id: 10434, name: 'Atalaia', parent_id: 2007 },
	{ id: 10527, name: 'Barra de Santo Antônio', parent_id: 2007 },
	{ id: 10204, name: 'Amapá', parent_id: 1999 },
	{ id: 10933, name: 'Calçoene', parent_id: 1999 },
	{ id: 11541, name: 'Cutias', parent_id: 1999 },
	{ id: 11812, name: 'Ferreira Gomes', parent_id: 1999 },
	{ id: 12462, name: 'Itaubal', parent_id: 1999 },
	{ id: 10191, name: 'Alvarães', parent_id: 2004 },
	{ id: 10212, name: 'Amaturá', parent_id: 2004 },
	{ id: 10233, name: 'Anamã', parent_id: 2004 },
	{ id: 10266, name: 'Anori', parent_id: 2004 },
	{ id: 10303, name: 'Apuí', parent_id: 2004 },
	{ id: 10061, name: 'Abaíra', parent_id: 2002 },
	{ id: 10059, name: 'Abaré', parent_id: 2002 },
	{ id: 10069, name: 'Acajutiba', parent_id: 2002 },
	{ id: 10084, name: 'Adustina', parent_id: 2002 },
	{ id: 15659, name: 'Água Fria', parent_id: 2002 },
	{ id: 10058, name: 'Abaiara', parent_id: 2016 },
	{ id: 10070, name: 'Acarape', parent_id: 2016 },
	{ id: 10071, name: 'Acaraú', parent_id: 2016 },
	{ id: 10076, name: 'Acopiara', parent_id: 2016 },
	{ id: 10104, name: 'Aiuaba', parent_id: 2016 },
	{ id: 10776, name: 'Brasília', parent_id: 2017 },
	{ id: 13934, name: 'Planaltina', parent_id: 2017 },
	{ id: 10087, name: 'Afonso Cláudio', parent_id: 2018 },
	{ id: 15658, name: 'Água Doce do Norte', parent_id: 2018 },
	{ id: 15676, name: 'Águia Branca', parent_id: 2018 },
	{ id: 10123, name: 'Alegre', parent_id: 2018 },
	{ id: 10131, name: 'Alfredo Chaves', parent_id: 2018 },
	{ id: 10053, name: 'Abadia de Goiás', parent_id: 2000 },
	{ id: 10055, name: 'Abadiânia', parent_id: 2000 },
	{ id: 10079, name: 'Acreúna', parent_id: 2000 },
	{ id: 10081, name: 'Adelândia', parent_id: 2000 },
	{ id: 15660, name: 'Água Fria de Goiás', parent_id: 2000 },
	{ id: 10463, name: 'Açailândia', parent_id: 2015 },
	{ id: 10088, name: 'Afonso Cunha', parent_id: 2015 },
	{ id: 15657, name: 'Água Doce do Maranhão', parent_id: 2015 },
	{ id: 10119, name: 'Alcântara', parent_id: 2015 },
	{ id: 10121, name: 'Aldeias Altas', parent_id: 2015 },
	{ id: 10077, name: 'Acorizal', parent_id: 2011 },
	{ id: 15649, name: 'Água Boa', parent_id: 2011 },
	{ id: 10150, name: 'Alta Floresta', parent_id: 2011 },
	{ id: 10165, name: 'Alto Araguaia', parent_id: 2011 },
	{ id: 10167, name: 'Alto Boa Vista', parent_id: 2011 },
	{ id: 15654, name: 'Água Clara', parent_id: 2010 },
	{ id: 10117, name: 'Alcinópolis', parent_id: 2010 },
	{ id: 10202, name: 'Amambai', parent_id: 2010 },
	{ id: 10238, name: 'Anastácio', parent_id: 2010 },
	{ id: 10239, name: 'Anaurilândia', parent_id: 2010 },
	{ id: 10054, name: 'Abadia dos Dourados', parent_id: 1998 },
	{ id: 10057, name: 'Abaeté', parent_id: 1998 },
	{ id: 10065, name: 'Abre Campo', parent_id: 1998 },
	{ id: 10068, name: 'Acaiaca', parent_id: 1998 },
	{ id: 10465, name: 'Açucena', parent_id: 1998 },
	{ id: 10056, name: 'Abaetetuba', parent_id: 2009 },
	{ id: 10063, name: 'Abel Figueiredo', parent_id: 2009 },
	{ id: 10073, name: 'Acará', parent_id: 2009 },
	{ id: 10090, name: 'Afuá', parent_id: 2009 },
	{ id: 15648, name: 'Água Azul do Norte', parent_id: 2009 },
	{ id: 15653, name: 'Água Branca', parent_id: 2005 },
	{ id: 10100, name: 'Aguiar', parent_id: 2005 },
	{ id: 10108, name: 'Alagoa Grande', parent_id: 2005 },
	{ id: 10109, name: 'Alagoa Nova', parent_id: 2005 },
	{ id: 10110, name: 'Alagoinha', parent_id: 2005 },
	{ id: 10060, name: 'Abatiá', parent_id: 2022 },
	{ id: 10083, name: 'Adrianópolis', parent_id: 2022 },
	{ id: 10099, name: 'Agudos do Sul', parent_id: 2022 },
	{ id: 10144, name: 'Almirante Tamandaré', parent_id: 2022 },
	{ id: 10155, name: 'Altamira do Paraná', parent_id: 2022 },
	{ id: 10066, name: 'Abreu e Lima', parent_id: 2006 },
	{ id: 10085, name: 'Afogados da Ingazeira', parent_id: 2006 },
	{ id: 10089, name: 'Afrânio', parent_id: 2006 },
	{ id: 10091, name: 'Agrestina', parent_id: 2006 },
	{ id: 15663, name: 'Água Preta', parent_id: 2006 },
	{ id: 10074, name: 'Acauã', parent_id: 2008 },
	{ id: 10092, name: 'Agricolândia', parent_id: 2008 },
	{ id: 15651, name: 'Água Branca', parent_id: 2008 },
	{ id: 10112, name: 'Alagoinha do Piauí', parent_id: 2008 },
	{ id: 10125, name: 'Alegrete do Piauí', parent_id: 2008 },
	{ id: 10257, name: 'Angra dos Reis', parent_id: 1997 },
	{ id: 10291, name: 'Aperibé', parent_id: 1997 },
	{ id: 10356, name: 'Araruama', parent_id: 1997 },
	{ id: 10381, name: 'Areal', parent_id: 1997 },
	{ id: 10402, name: 'Armação dos Búzios', parent_id: 1997 },
	{ id: 10072, name: 'Acari', parent_id: 2019 },
	{ id: 10464, name: 'Açu', parent_id: 2019 },
	{ id: 10086, name: 'Afonso Bezerra', parent_id: 2019 },
	{ id: 15662, name: 'Água Nova', parent_id: 2019 },
	{ id: 10128, name: 'Alexandria', parent_id: 2019 },
	{ id: 10075, name: 'Aceguá', parent_id: 2001 },
	{ id: 15664, name: 'Água Santa', parent_id: 2001 },
	{ id: 10097, name: 'Agudo', parent_id: 2001 },
	{ id: 10106, name: 'Ajuricaba', parent_id: 2001 },
	{ id: 10122, name: 'Alecrim', parent_id: 2001 },
	{ id: 10151, name: 'Alta Floresta d\'Oeste', parent_id: 2013 },
	{ id: 10164, name: 'Alto Alegre dos Parecis', parent_id: 2013 },
	{ id: 10176, name: 'Alto Paraíso', parent_id: 2013 },
	{ id: 10196, name: 'Alvorada d\'Oeste', parent_id: 2013 },
	{ id: 10396, name: 'Ariquemes', parent_id: 2013 },
	{ id: 142968, name: 'Amajari', parent_id: 4858 },
	{ id: 142969, name: 'Boa Vista', parent_id: 4858 },
	{ id: 142970, name: 'Bonfim', parent_id: 4858 },
	{ id: 142971, name: 'Cantá', parent_id: 4858 },
	{ id: 142972, name: 'Caracaraí', parent_id: 4858 },
	{ id: 10062, name: 'Abdon Batista', parent_id: 2014 },
	{ id: 10064, name: 'Abelardo Luz', parent_id: 2014 },
	{ id: 10093, name: 'Agrolândia', parent_id: 2014 },
	{ id: 10094, name: 'Agronômica', parent_id: 2014 },
	{ id: 15656, name: 'Água Doce', parent_id: 2014 },
	{ id: 10080, name: 'Adamantina', parent_id: 2021 },
	{ id: 10082, name: 'Adolfo', parent_id: 2021 },
	{ id: 10096, name: 'Aguaí', parent_id: 2021 },
	{ id: 15671, name: 'Águas da Prata', parent_id: 2021 },
	{ id: 15673, name: 'Águas de Lindóia', parent_id: 2021 },
	{ id: 10221, name: 'Amparo de São Francisco', parent_id: 2003 },
	{ id: 10304, name: 'Aquidabã', parent_id: 2003 },
	{ id: 10308, name: 'Aracaju', parent_id: 2003 },
	{ id: 10364, name: 'Arauá', parent_id: 2003 },
	{ id: 10385, name: 'Areia Branca', parent_id: 2003 },
	{ id: 10067, name: 'Abreulândia', parent_id: 2020 },
	{ id: 10101, name: 'Aguiarnópolis', parent_id: 2020 },
	{ id: 10138, name: 'Aliança do Tocantins', parent_id: 2020 },
	{ id: 10140, name: 'Almas', parent_id: 2020 },
	{ id: 10194, name: 'Alvorada', parent_id: 2020 },
	{ id: 9820, name: 'Kuala Belait', parent_id: 1217 },
	{ id: 9823, name: 'Seria', parent_id: 1217 },
	{ id: 9816, name: 'Bandar Seri Begawan', parent_id: 1216 },
	{ id: 9818, name: 'Berakas A', parent_id: 1216 },
	{ id: 9819, name: 'Kapok', parent_id: 1216 },
	{ id: 9821, name: 'Mentiri', parent_id: 1216 },
	{ id: 9822, name: 'Serasa', parent_id: 1216 },
	{ id: 9817, name: 'Bangar', parent_id: 1218 },
	{ id: 9824, name: 'Tutong', parent_id: 1219 },
	{ id: 9265, name: 'Bansko', parent_id: 4699 },
	{ id: 9270, name: 'Belitsa', parent_id: 4699 },
	{ id: 9275, name: 'Blagoevgrad', parent_id: 4699 },
	{ id: 9329, name: 'Garmen', parent_id: 4699 },
	{ id: 9335, name: 'Gotse Delchev', parent_id: 4699 },
	{ id: 9250, name: 'Aheloy', parent_id: 4715 },
	{ id: 9251, name: 'Ahtopol', parent_id: 4715 },
	{ id: 9261, name: 'Aytos', parent_id: 4715 },
	{ id: 9266, name: 'Bata', parent_id: 4715 },
	{ id: 9292, name: 'Burgas', parent_id: 4715 },
	{ id: 9262, name: 'Balchik', parent_id: 4718 },
	{ id: 9310, name: 'Dobrich', parent_id: 4718 },
	{ id: 9330, name: 'General Toshevo', parent_id: 4718 },
	{ id: 9357, name: 'Kavarna', parent_id: 4718 },
	{ id: 9378, name: 'Krushari', parent_id: 4718 },
	{ id: 9318, name: 'Dryanovo', parent_id: 4693 },
	{ id: 9328, name: 'Gabrovo', parent_id: 4693 },
	{ id: 9465, name: 'Obshtina Dryanovo', parent_id: 4693 },
	{ id: 9474, name: 'Obshtina Gabrovo', parent_id: 4693 },
	{ id: 9586, name: 'Obshtina Sevlievo', parent_id: 4693 },
	{ id: 9308, name: 'Dimitrovgrad', parent_id: 4704 },
	{ id: 9342, name: 'Harmanli', parent_id: 4704 },
	{ id: 9343, name: 'Haskovo', parent_id: 4704 },
	{ id: 9350, name: 'Ivaylovgrad', parent_id: 4704 },
	{ id: 9392, name: 'Lyubimets', parent_id: 4704 },
	{ id: 9257, name: 'Ardino', parent_id: 4702 },
	{ id: 9323, name: 'Dzhebel', parent_id: 4702 },
	{ id: 9354, name: 'Kardzhali', parent_id: 4702 },
	{ id: 9362, name: 'Kirkovo', parent_id: 4702 },
	{ id: 9377, name: 'Krumovgrad', parent_id: 4702 },
	{ id: 9276, name: 'Boboshevo', parent_id: 4703 },
	{ id: 9277, name: 'Bobov Dol', parent_id: 4703 },
	{ id: 9321, name: 'Dupnitsa', parent_id: 4703 },
	{ id: 9367, name: 'Kocherinovo', parent_id: 4703 },
	{ id: 9381, name: 'Kyustendil', parent_id: 4703 },
	{ id: 9256, name: 'Apriltsi', parent_id: 4710 },
	{ id: 9385, name: 'Letnitsa', parent_id: 4710 },
	{ id: 9388, name: 'Lovech', parent_id: 4710 },
	{ id: 9390, name: 'Lukovit', parent_id: 4710 },
	{ id: 9523, name: 'Obshtina Lovech', parent_id: 4710 },
	{ id: 9274, name: 'Berkovitsa', parent_id: 4696 },
	{ id: 9283, name: 'Boychinovtsi', parent_id: 4696 },
	{ id: 9290, name: 'Brusartsi', parent_id: 4696 },
	{ id: 9301, name: 'Chiprovtsi', parent_id: 4696 },
	{ id: 9387, name: 'Lom', parent_id: 4696 },
	{ id: 9267, name: 'Batak', parent_id: 4712 },
	{ id: 9273, name: 'Belovo', parent_id: 4712 },
	{ id: 9286, name: 'Bratsigovo', parent_id: 4712 },
	{ id: 9384, name: 'Lesichovo', parent_id: 4712 },
	{ id: 9423, name: 'Obshtina Batak', parent_id: 4712 },
	{ id: 9268, name: 'Batanovtsi', parent_id: 4695 },
	{ id: 9288, name: 'Breznik', parent_id: 4695 },
	{ id: 9509, name: 'Obshtina Kovachevtsi', parent_id: 4695 },
	{ id: 9552, name: 'Obshtina Pernik', parent_id: 4695 },
	{ id: 9567, name: 'Obshtina Radomir', parent_id: 4695 },
	{ id: 9269, name: 'Belene', parent_id: 4706 },
	{ id: 9300, name: 'Cherven Bryag', parent_id: 4706 },
	{ id: 9312, name: 'Dolna Mitropolia', parent_id: 4706 },
	{ id: 9314, name: 'Dolni Dabnik', parent_id: 4706 },
	{ id: 9337, name: 'Gulyantsi', parent_id: 4706 },
	{ id: 9259, name: 'Asenovgrad', parent_id: 4701 },
	{ id: 9289, name: 'Brezovo', parent_id: 4701 },
	{ id: 9345, name: 'Hisarya', parent_id: 4701 },
	{ id: 9351, name: 'Kalofer', parent_id: 4701 },
	{ id: 9352, name: 'Kaloyanovo', parent_id: 4701 },
	{ id: 9348, name: 'Isperih', parent_id: 4698 },
	{ id: 9379, name: 'Kubrat', parent_id: 4698 },
	{ id: 9389, name: 'Loznitsa', parent_id: 4698 },
	{ id: 9399, name: 'Medovene', parent_id: 4698 },
	{ id: 9491, name: 'Obshtina Isperih', parent_id: 4698 },
	{ id: 9281, name: 'Borovo', parent_id: 4713 },
	{ id: 9322, name: 'Dve Mogili', parent_id: 4713 },
	{ id: 9349, name: 'Ivanovo', parent_id: 4713 },
	{ id: 9435, name: 'Obshtina Borovo', parent_id: 4713 },
	{ id: 9443, name: 'Obshtina Byala', parent_id: 4713 },
	{ id: 146184, name: 'Shumen', parent_id: 4882 },
	{ id: 9253, name: 'Alfatar', parent_id: 4708 },
	{ id: 9319, name: 'Dulovo', parent_id: 4708 },
	{ id: 9331, name: 'Glavinitsa', parent_id: 4708 },
	{ id: 9358, name: 'Kaynardzha', parent_id: 4708 },
	{ id: 9413, name: 'Obshtina Alfatar', parent_id: 4708 },
	{ id: 9360, name: 'Kermen', parent_id: 4700 },
	{ id: 9371, name: 'Kotel', parent_id: 4700 },
	{ id: 9410, name: 'Nova Zagora', parent_id: 4700 },
	{ id: 9508, name: 'Obshtina Kotel', parent_id: 4700 },
	{ id: 9542, name: 'Obshtina Nova Zagora', parent_id: 4700 },
	{ id: 9264, name: 'Banite', parent_id: 4694 },
	{ id: 9279, name: 'Borino', parent_id: 4694 },
	{ id: 9298, name: 'Chepelare', parent_id: 4694 },
	{ id: 9306, name: 'Devin', parent_id: 4694 },
	{ id: 9315, name: 'Dospat', parent_id: 4694 },
	{ id: 9291, name: 'Buhovo', parent_id: 4705 },
	{ id: 9701, name: 'Sofia', parent_id: 4705 },
	{ id: 9707, name: 'Stolichna Obshtina', parent_id: 4705 },
	{ id: 9254, name: 'Anton', parent_id: 4719 },
	{ id: 9282, name: 'Botevgrad', parent_id: 4719 },
	{ id: 9285, name: 'Bozhurishte', parent_id: 4719 },
	{ id: 9296, name: 'Chavdar', parent_id: 4719 },
	{ id: 9297, name: 'Chelopech', parent_id: 4719 },
	{ id: 9258, name: 'Asen', parent_id: 4707 },
	{ id: 9302, name: 'Chirpan', parent_id: 4707 },
	{ id: 9340, name: 'Gŭlŭbovo', parent_id: 4707 },
	{ id: 9338, name: 'Gurkovo', parent_id: 4707 },
	{ id: 9359, name: 'Kazanlak', parent_id: 4707 },
	{ id: 9255, name: 'Antonovo', parent_id: 4714 },
	{ id: 9415, name: 'Obshtina Antonovo', parent_id: 4714 },
	{ id: 9543, name: 'Obshtina Omurtag', parent_id: 4714 },
	{ id: 9544, name: 'Obshtina Opaka', parent_id: 4714 },
	{ id: 9561, name: 'Obshtina Popovo', parent_id: 4714 },
	{ id: 9252, name: 'Aksakovo', parent_id: 4717 },
	{ id: 9260, name: 'Asparuhovo', parent_id: 4717 },
	{ id: 9263, name: 'Balgarevo', parent_id: 4717 },
	{ id: 9272, name: 'Beloslav', parent_id: 4717 },
	{ id: 9293, name: 'Byala', parent_id: 4717 },
	{ id: 9294, name: 'Byala Cherkva', parent_id: 4709 },
	{ id: 9305, name: 'Debelets', parent_id: 4709 },
	{ id: 9324, name: 'Elena', parent_id: 4709 },
	{ id: 9334, name: 'Gorna Oryahovitsa', parent_id: 4709 },
	{ id: 9361, name: 'Kilifarevo', parent_id: 4709 },
	{ id: 9271, name: 'Belogradchik', parent_id: 4697 },
	{ id: 9284, name: 'Boynitsa', parent_id: 4697 },
	{ id: 9287, name: 'Bregovo', parent_id: 4697 },
	{ id: 9303, name: 'Chuprene', parent_id: 4697 },
	{ id: 9309, name: 'Dimovo', parent_id: 4697 },
	{ id: 9280, name: 'Borovan', parent_id: 4711 },
	{ id: 9295, name: 'Byala Slatina', parent_id: 4711 },
	{ id: 9344, name: 'Hayredin', parent_id: 4711 },
	{ id: 9373, name: 'Kozloduy', parent_id: 4711 },
	{ id: 9376, name: 'Krivodol', parent_id: 4711 },
	{ id: 9278, name: 'Bolyarovo', parent_id: 4716 },
	{ id: 9325, name: 'Elhovo', parent_id: 4716 },
	{ id: 9432, name: 'Obshtina Bolyarovo', parent_id: 4716 },
	{ id: 9471, name: 'Obshtina Elhovo', parent_id: 4716 },
	{ id: 9600, name: 'Obshtina Straldzha', parent_id: 4716 },
	{ id: 9159, name: 'Barani', parent_id: 3138 },
	{ id: 9164, name: 'Boromo', parent_id: 3138 },
	{ id: 9172, name: 'Dédougou', parent_id: 3138 },
	{ id: 9192, name: 'Nouna', parent_id: 3138 },
	{ id: 9203, name: 'Province de la Kossi', parent_id: 3138 },
	{ id: 9158, name: 'Banfora', parent_id: 3153 },
	{ id: 9200, name: 'Province de la Comoé', parent_id: 3153 },
	{ id: 9204, name: 'Province de la Léraba', parent_id: 3153 },
	{ id: 9241, name: 'Sindou', parent_id: 3153 },
	{ id: 9181, name: 'Kadiogo Province', parent_id: 3136 },
	{ id: 9193, name: 'Ouagadougou', parent_id: 3136 },
	{ id: 9174, name: 'Garango', parent_id: 3162 },
	{ id: 9187, name: 'Koupéla', parent_id: 3162 },
	{ id: 9188, name: 'Kouritenga Province', parent_id: 3162 },
	{ id: 9195, name: 'Ouargaye', parent_id: 3162 },
	{ id: 9211, name: 'Province du Boulgou', parent_id: 3162 },
	{ id: 9165, name: 'Boulsa', parent_id: 3127 },
	{ id: 9182, name: 'Kaya', parent_id: 3127 },
	{ id: 9185, name: 'Kongoussi', parent_id: 3127 },
	{ id: 9210, name: 'Province du Bam', parent_id: 3127 },
	{ id: 9222, name: 'Province du Namentenga', parent_id: 3127 },
	{ id: 9178, name: 'Goulouré', parent_id: 3115 },
	{ id: 9183, name: 'Kokologo', parent_id: 3115 },
	{ id: 9186, name: 'Koudougou', parent_id: 3115 },
	{ id: 9189, name: 'Léo', parent_id: 3115 },
	{ id: 9198, name: 'Pitmoaga', parent_id: 3115 },
	{ id: 9161, name: 'Bazega Province', parent_id: 3149 },
	{ id: 9184, name: 'Kombissiri', parent_id: 3149 },
	{ id: 9190, name: 'Manga', parent_id: 3149 },
	{ id: 9191, name: 'Nahouri Province', parent_id: 3149 },
	{ id: 9237, name: 'Pô', parent_id: 3149 },
	{ id: 9163, name: 'Bogandé', parent_id: 3158 },
	{ id: 9168, name: 'Diapaga', parent_id: 3158 },
	{ id: 9173, name: 'Fada N\'gourma', parent_id: 3158 },
	{ id: 9175, name: 'Gayéri', parent_id: 3158 },
	{ id: 9176, name: 'Gnagna Province', parent_id: 3158 },
	{ id: 9162, name: 'Bobo-Dioulasso', parent_id: 3165 },
	{ id: 9180, name: 'Houndé', parent_id: 3165 },
	{ id: 9215, name: 'Province du Houet', parent_id: 3165 },
	{ id: 9219, name: 'Province du Kénédougou', parent_id: 3165 },
	{ id: 9232, name: 'Province du Tuy', parent_id: 3165 },
	{ id: 9179, name: 'Gourcy', parent_id: 3164 },
	{ id: 9194, name: 'Ouahigouya', parent_id: 3164 },
	{ id: 9220, name: 'Province du Loroum', parent_id: 3164 },
	{ id: 9225, name: 'Province du Passoré', parent_id: 3164 },
	{ id: 9234, name: 'Province du Yatenga', parent_id: 3164 },
	{ id: 9166, name: 'Boussé', parent_id: 3125 },
	{ id: 9196, name: 'Oubritenga', parent_id: 3125 },
	{ id: 9213, name: 'Province du Ganzourgou', parent_id: 3125 },
	{ id: 9218, name: 'Province du Kourwéogo', parent_id: 3125 },
	{ id: 9247, name: 'Ziniaré', parent_id: 3125 },
	{ id: 9170, name: 'Djibo', parent_id: 3114 },
	{ id: 9171, name: 'Dori', parent_id: 3114 },
	{ id: 9177, name: 'Gorom-Gorom', parent_id: 3114 },
	{ id: 9207, name: 'Province de l’Oudalan', parent_id: 3114 },
	{ id: 9231, name: 'Province du Séno', parent_id: 3114 },
	{ id: 9160, name: 'Batié', parent_id: 3140 },
	{ id: 9167, name: 'Dano', parent_id: 3140 },
	{ id: 9169, name: 'Diébougou', parent_id: 3140 },
	{ id: 9199, name: 'Province de la Bougouriba', parent_id: 3140 },
	{ id: 9216, name: 'Province du Ioba', parent_id: 3140 },
	{ id: 9762, name: 'Bubanza', parent_id: 3196 },
	{ id: 9763, name: 'Bujumbura', parent_id: 3198 },
	{ id: 9764, name: 'Bururi', parent_id: 3202 },
	{ id: 9765, name: 'Cankuzo', parent_id: 3201 },
	{ id: 9766, name: 'Cibitoke', parent_id: 3190 },
	{ id: 9767, name: 'Gitega', parent_id: 3197 },
	{ id: 9768, name: 'Karuzi', parent_id: 3194 },
	{ id: 9769, name: 'Kayanza', parent_id: 3192 },
	{ id: 9770, name: 'Kirundo', parent_id: 3195 },
	{ id: 9771, name: 'Makamba', parent_id: 3188 },
	{ id: 9772, name: 'Muramvya', parent_id: 3193 },
	{ id: 9773, name: 'Muyinga', parent_id: 3186 },
	{ id: 9774, name: 'Mwaro', parent_id: 3187 },
	{ id: 9775, name: 'Ngozi', parent_id: 3199 },
	{ id: 9776, name: 'Rumonge', parent_id: 3185 },
	{ id: 9777, name: 'Rutana', parent_id: 3189 },
	{ id: 9778, name: 'Ruyigi', parent_id: 3191 },
	{ id: 64967, name: 'Mongkol Borei', parent_id: 3984 },
	{ id: 64969, name: 'Paoy Paet', parent_id: 3984 },
	{ id: 64981, name: 'Sisophon', parent_id: 3984 },
	{ id: 65009, name: 'Srŏk Malai', parent_id: 3984 },
	{ id: 65022, name: 'Srŏk Svay Chék', parent_id: 3984 },
	{ id: 64940, name: 'Battambang', parent_id: 3976 },
	{ id: 65028, name: 'Srŏk Âk Phnŭm', parent_id: 3976 },
	{ id: 64988, name: 'Srŏk Banăn', parent_id: 3976 },
	{ id: 64995, name: 'Srŏk Bâvĭl', parent_id: 3976 },
	{ id: 65017, name: 'Srŏk Rotanak Mondol', parent_id: 3976 },
	{ id: 64941, name: 'Cheung Prey', parent_id: 3991 },
	{ id: 64944, name: 'Kampong Cham', parent_id: 3991 },
	{ id: 64990, name: 'Srŏk Bathéay', parent_id: 3991 },
	{ id: 64939, name: 'Baribour', parent_id: 3979 },
	{ id: 64945, name: 'Kampong Chhnang', parent_id: 3979 },
	{ id: 64974, name: 'Rolea B\'ier', parent_id: 3979 },
	{ id: 64997, name: 'Srŏk Chol Kiri', parent_id: 3979 },
	{ id: 65019, name: 'Srŏk Sameakki Mean Chey', parent_id: 3979 },
	{ id: 64946, name: 'Kampong Speu', parent_id: 3988 },
	{ id: 64962, name: 'Krŏng Chbar Mon', parent_id: 3988 },
	{ id: 64989, name: 'Srŏk Basedth', parent_id: 3988 },
	{ id: 65008, name: 'Srŏk Kông Pĭsei', parent_id: 3988 },
	{ id: 65032, name: 'Srŏk Ŏdŏngk', parent_id: 3988 },
	{ id: 64935, name: 'Angkor Chey', parent_id: 3981 },
	{ id: 64938, name: 'Banteay Meas', parent_id: 3981 },
	{ id: 64942, name: 'Chhouk District', parent_id: 3981 },
	{ id: 64943, name: 'Kampong Bay', parent_id: 3981 },
	{ id: 64947, name: 'Kampong Tranch', parent_id: 3981 },
	{ id: 64965, name: 'Krŏng Ta Khmau', parent_id: 3983 },
	{ id: 65003, name: 'Srŏk Khsăch Kândal', parent_id: 3983 },
	{ id: 65037, name: 'Ta Khmau', parent_id: 3983 },
	{ id: 64961, name: 'Krong Kep', parent_id: 3978 },
	{ id: 65000, name: 'Srŏk Dâmnăk Châng’aeur', parent_id: 3978 },
	{ id: 64957, name: 'Koh Kong', parent_id: 3982 },
	{ id: 64982, name: 'Smach Mean Chey', parent_id: 3982 },
	{ id: 64984, name: 'Srae Ambel', parent_id: 3982 },
	{ id: 64991, name: 'Srŏk Batum Sakôr', parent_id: 3982 },
	{ id: 65011, name: 'Srŏk Môndôl Seima', parent_id: 3982 },
	{ id: 64958, name: 'Kracheh', parent_id: 3986 },
	{ id: 64960, name: 'Kratié', parent_id: 3986 },
	{ id: 64983, name: 'Snuol', parent_id: 3986 },
	{ id: 64964, name: 'Krŏng Sênmônoŭrôm', parent_id: 3985 },
	{ id: 64978, name: 'Sen Monorom', parent_id: 3985 },
	{ id: 65002, name: 'Srŏk Kaev Seima', parent_id: 3985 },
	{ id: 65014, name: 'Srŏk Pech Chreada', parent_id: 3985 },
	{ id: 64976, name: 'Samraong', parent_id: 3987 },
	{ id: 64994, name: 'Srŏk Bântéay Âmpĭl', parent_id: 3987 },
	{ id: 65023, name: 'Srŏk Sâmraông', parent_id: 3987 },
	{ id: 65026, name: 'Srŏk Trâpeăng Prasat', parent_id: 3987 },
	{ id: 64956, name: 'Khan Sala Krau', parent_id: 3980 },
	{ id: 64968, name: 'Pailin', parent_id: 3980 },
	{ id: 64949, name: 'Khan 7 Makara', parent_id: 3994 },
	{ id: 64950, name: 'Khan Châmkar Mon', parent_id: 3994 },
	{ id: 64952, name: 'Khan Dângkaô', parent_id: 3994 },
	{ id: 64951, name: 'Khan Duŏn Pénh', parent_id: 3994 },
	{ id: 64953, name: 'Khan Méan Chey', parent_id: 3994 },
	{ id: 64977, name: 'Sangkom Thmei', parent_id: 3973 },
	{ id: 64999, name: 'Srŏk Ch’êh Sên', parent_id: 3973 },
	{ id: 64996, name: 'Srŏk Chhêb', parent_id: 3973 },
	{ id: 65005, name: 'Srŏk Kulén', parent_id: 3973 },
	{ id: 65018, name: 'Srŏk Rôviĕng', parent_id: 3973 },
	{ id: 64972, name: 'Prey Veng', parent_id: 3974 },
	{ id: 65006, name: 'Srŏk Kâmpóng Léav', parent_id: 3974 },
	{ id: 65010, name: 'Srŏk Mésang', parent_id: 3974 },
	{ id: 65016, name: 'Srŏk Preăh Sdéch', parent_id: 3974 },
	{ id: 64936, name: 'Bakan', parent_id: 3977 },
	{ id: 64959, name: 'Krakor', parent_id: 3977 },
	{ id: 64973, name: 'Pursat', parent_id: 3977 },
	{ id: 64975, name: 'Sampov Meas', parent_id: 3977 },
	{ id: 65007, name: 'Srŏk Kândiĕng', parent_id: 3977 },
	{ id: 64937, name: 'Banlung', parent_id: 3990 },
	{ id: 64966, name: 'Lumphat', parent_id: 3990 },
	{ id: 65029, name: 'Srŏk Ândong Méas', parent_id: 3990 },
	{ id: 64993, name: 'Srŏk Bâ Kêv', parent_id: 3990 },
	{ id: 64987, name: 'Srŏk Ban Lŭng', parent_id: 3990 },
	{ id: 64979, name: 'Siem Reap', parent_id: 3992 },
	{ id: 65031, name: 'Srŏk Ângkôr Thum', parent_id: 3992 },
	{ id: 65015, name: 'Srŏk Prasat Bakong', parent_id: 3992 },
	{ id: 65035, name: 'Svay Leu', parent_id: 3992 },
	{ id: 65040, name: 'Varin', parent_id: 3992 },
	{ id: 64980, name: 'Sihanoukville', parent_id: 3989 },
	{ id: 64985, name: 'Srok Stueng Hav', parent_id: 3989 },
	{ id: 65020, name: 'Srŏk Srêsén', parent_id: 3993 },
	{ id: 65033, name: 'Stueng Traeng', parent_id: 3993 },
	{ id: 65034, name: 'Stung Treng', parent_id: 3993 },
	{ id: 65021, name: 'Srŏk Svay Chrŭm', parent_id: 3972 },
	{ id: 65036, name: 'Svay Rieng', parent_id: 3972 },
	{ id: 64963, name: 'Krŏng Doun Kaev', parent_id: 3975 },
	{ id: 64971, name: 'Phumĭ Véal Srê', parent_id: 3975 },
	{ id: 65030, name: 'Srŏk Ângkôr Borei', parent_id: 3975 },
	{ id: 64992, name: 'Srŏk Borei Cholsar', parent_id: 3975 },
	{ id: 65038, name: 'Takeo', parent_id: 3975 },
	{ id: 19148, name: 'Bankim', parent_id: 2663 },
	{ id: 19150, name: 'Banyo', parent_id: 2663 },
	{ id: 19162, name: 'Bélel', parent_id: 2663 },
	{ id: 19168, name: 'Djohong', parent_id: 2663 },
	{ id: 19187, name: 'Kontcha', parent_id: 2663 },
	{ id: 19134, name: 'Akono', parent_id: 2660 },
	{ id: 19135, name: 'Akonolinga', parent_id: 2660 },
	{ id: 19139, name: 'Bafia', parent_id: 2660 },
	{ id: 19174, name: 'Eséka', parent_id: 2660 },
	{ id: 19173, name: 'Essé', parent_id: 2660 },
	{ id: 19132, name: 'Abong Mbang', parent_id: 2661 },
	{ id: 19152, name: 'Batouri', parent_id: 2661 },
	{ id: 19161, name: 'Bélabo', parent_id: 2661 },
	{ id: 19156, name: 'Bertoua', parent_id: 2661 },
	{ id: 19163, name: 'Bétaré Oya', parent_id: 2661 },
	{ id: 19157, name: 'Bogo', parent_id: 2656 },
	{ id: 19186, name: 'Kaélé', parent_id: 2656 },
	{ id: 19189, name: 'Kousséri', parent_id: 2656 },
	{ id: 19190, name: 'Koza', parent_id: 2656 },
	{ id: 19199, name: 'Makary', parent_id: 2656 },
	{ id: 19158, name: 'Bonabéri', parent_id: 2662 },
	{ id: 19164, name: 'Diang', parent_id: 2662 },
	{ id: 19165, name: 'Dibombari', parent_id: 2662 },
	{ id: 19167, name: 'Dizangué', parent_id: 2662 },
	{ id: 19169, name: 'Douala', parent_id: 2662 },
	{ id: 19176, name: 'Faro Department', parent_id: 2665 },
	{ id: 19181, name: 'Garoua', parent_id: 2665 },
	{ id: 19183, name: 'Guider', parent_id: 2665 },
	{ id: 19194, name: 'Lagdo', parent_id: 2665 },
	{ id: 19204, name: 'Mayo-Louti', parent_id: 2665 },
	{ id: 19137, name: 'Babanki', parent_id: 2657 },
	{ id: 19141, name: 'Bali', parent_id: 2657 },
	{ id: 19142, name: 'Bamenda', parent_id: 2657 },
	{ id: 19151, name: 'Batibo', parent_id: 2657 },
	{ id: 19155, name: 'Belo', parent_id: 2657 },
	{ id: 19133, name: 'Akom II', parent_id: 2659 },
	{ id: 19136, name: 'Ambam', parent_id: 2659 },
	{ id: 19269, name: 'Ébolowa', parent_id: 2659 },
	{ id: 19191, name: 'Kribi', parent_id: 2659 },
	{ id: 19197, name: 'Lolodorf', parent_id: 2659 },
	{ id: 19144, name: 'Bamusso', parent_id: 2658 },
	{ id: 19154, name: 'Bekondo', parent_id: 2658 },
	{ id: 19160, name: 'Buea', parent_id: 2658 },
	{ id: 19175, name: 'Fako Division', parent_id: 2658 },
	{ id: 19177, name: 'Fontem', parent_id: 2658 },
	{ id: 19138, name: 'Bafang', parent_id: 2664 },
	{ id: 19140, name: 'Bafoussam', parent_id: 2664 },
	{ id: 19143, name: 'Bamendjou', parent_id: 2664 },
	{ id: 19145, name: 'Bana', parent_id: 2664 },
	{ id: 19146, name: 'Bandjoun', parent_id: 2664 },
	{ id: 16151, name: 'Airdrie', parent_id: 872 },
	{ id: 16178, name: 'Athabasca', parent_id: 872 },
	{ id: 16190, name: 'Banff', parent_id: 872 },
	{ id: 16192, name: 'Barrhead', parent_id: 872 },
	{ id: 16196, name: 'Bassano', parent_id: 872 },
	{ id: 16146, name: 'Abbotsford', parent_id: 875 },
	{ id: 16150, name: 'Agassiz', parent_id: 875 },
	{ id: 16155, name: 'Aldergrove', parent_id: 875 },
	{ id: 16156, name: 'Aldergrove East', parent_id: 875 },
	{ id: 16169, name: 'Anmore', parent_id: 875 },
	{ id: 16160, name: 'Altona', parent_id: 867 },
	{ id: 16206, name: 'Beausejour', parent_id: 867 },
	{ id: 16225, name: 'Boissevain', parent_id: 867 },
	{ id: 16238, name: 'Brandon', parent_id: 867 },
	{ id: 16275, name: 'Carberry', parent_id: 867 },
	{ id: 16184, name: 'Baie Ste. Anne', parent_id: 868 },
	{ id: 16198, name: 'Bathurst', parent_id: 868 },
	{ id: 16232, name: 'Bouctouche', parent_id: 868 },
	{ id: 16264, name: 'Campbellton', parent_id: 868 },
	{ id: 16373, name: 'Dieppe', parent_id: 868 },
	{ id: 16199, name: 'Bay Roberts', parent_id: 877 },
	{ id: 16200, name: 'Bay St. George South', parent_id: 877 },
	{ id: 16228, name: 'Bonavista', parent_id: 877 },
	{ id: 16230, name: 'Botwood', parent_id: 877 },
	{ id: 16251, name: 'Burgeo', parent_id: 877 },
	{ id: 16209, name: 'Behchokǫ̀', parent_id: 878 },
	{ id: 16431, name: 'Fort McPherson', parent_id: 878 },
	{ id: 16434, name: 'Fort Smith', parent_id: 878 },
	{ id: 16498, name: 'Hay River', parent_id: 878 },
	{ id: 16522, name: 'Inuvik', parent_id: 878 },
	{ id: 16161, name: 'Amherst', parent_id: 874 },
	{ id: 16170, name: 'Annapolis County', parent_id: 874 },
	{ id: 16171, name: 'Antigonish', parent_id: 874 },
	{ id: 16216, name: 'Berwick', parent_id: 874 },
	{ id: 16242, name: 'Bridgewater', parent_id: 874 },
	{ id: 16315, name: 'Clyde River', parent_id: 876 },
	{ id: 16453, name: 'Gjoa Haven', parent_id: 876 },
	{ id: 16524, name: 'Iqaluit', parent_id: 876 },
	{ id: 16555, name: 'Kugluktuk', parent_id: 876 },
	{ id: 16793, name: 'Pangnirtung', parent_id: 876 },
	{ id: 16152, name: 'Ajax', parent_id: 866 },
	{ id: 16157, name: 'Algoma', parent_id: 866 },
	{ id: 16158, name: 'Alliston', parent_id: 866 },
	{ id: 16162, name: 'Amherstburg', parent_id: 866 },
	{ id: 16163, name: 'Amigo Beach', parent_id: 866 },
	{ id: 16154, name: 'Alberton', parent_id: 871 },
	{ id: 16210, name: 'Belfast', parent_id: 871 },
	{ id: 16297, name: 'Charlottetown', parent_id: 871 },
	{ id: 16338, name: 'Cornwall', parent_id: 871 },
	{ id: 16417, name: 'Fallingbrook', parent_id: 871 },
	{ id: 16147, name: 'Abitibi-Témiscamingue', parent_id: 873 },
	{ id: 16148, name: 'Acton Vale', parent_id: 873 },
	{ id: 16149, name: 'Adstock', parent_id: 873 },
	{ id: 16153, name: 'Albanel', parent_id: 873 },
	{ id: 16159, name: 'Alma', parent_id: 873 },
	{ id: 16177, name: 'Assiniboia', parent_id: 870 },
	{ id: 16217, name: 'Biggar', parent_id: 870 },
	{ id: 16268, name: 'Canora', parent_id: 870 },
	{ id: 16282, name: 'Carlyle', parent_id: 870 },
	{ id: 16353, name: 'Dalmeny', parent_id: 870 },
	{ id: 16358, name: 'Dawson City', parent_id: 869 },
	{ id: 16479, name: 'Haines Junction', parent_id: 869 },
	{ id: 17180, name: 'Watson Lake', parent_id: 869 },
	{ id: 17204, name: 'Whitehorse', parent_id: 869 },
	{ id: 22003, name: 'Sal Rei', parent_id: 2999 },
	{ id: 21995, name: 'Nova Sintra', parent_id: 2996 },
	{ id: 22010, name: 'Vila do Maio', parent_id: 2991 },
	{ id: 21992, name: 'Igreja', parent_id: 2987 },
	{ id: 21997, name: 'Pombas', parent_id: 2997 },
	{ id: 21999, name: 'Porto Novo', parent_id: 2989 },
	{ id: 22000, name: 'Praia', parent_id: 2988 },
	{ id: 22001, name: 'Ribeira Brava', parent_id: 2982 },
	{ id: 21998, name: 'Ponta do Sol', parent_id: 3002 },
	{ id: 22002, name: 'Ribeira Grande', parent_id: 3002 },
	{ id: 21989, name: 'Cidade Velha', parent_id: 2984 },
	{ id: 21991, name: 'Espargos', parent_id: 2998 },
	{ id: 22005, name: 'Santa Maria', parent_id: 2998 },
	{ id: 21987, name: 'Assomada', parent_id: 2985 },
	{ id: 21990, name: 'Cova Figueira', parent_id: 2995 },
	{ id: 21996, name: 'Pedra Badejo', parent_id: 3004 },
	{ id: 22004, name: 'Santa Cruz', parent_id: 3004 },
	{ id: 22006, name: 'São Domingos', parent_id: 2986 },
	{ id: 22007, name: 'São Filipe', parent_id: 3000 },
	{ id: 21993, name: 'João Teves', parent_id: 2993 },
	{ id: 21988, name: 'Calheta', parent_id: 2990 },
	{ id: 21994, name: 'Mindelo', parent_id: 3001 },
	{ id: 22008, name: 'Tarrafal', parent_id: 2983 },
	{ id: 22009, name: 'Tarrafal de São Nicolau', parent_id: 3003 },
	{ id: 153943, name: 'Cayman Brac', parent_id: 5252 },
	{ id: 153938, name: 'Bodden Town', parent_id: 5251 },
	{ id: 153939, name: 'East End', parent_id: 5251 },
	{ id: 153940, name: 'George Town', parent_id: 5251 },
	{ id: 153941, name: 'North Side', parent_id: 5251 },
	{ id: 153942, name: 'West Bay', parent_id: 5251 },
	{ id: 153944, name: 'Little Cayman', parent_id: 5253 },
	{ id: 17296, name: 'Bamingui', parent_id: 1259 },
	{ id: 17329, name: 'Ndélé', parent_id: 1259 },
	{ id: 17298, name: 'Bangui', parent_id: 1262 },
	{ id: 17294, name: 'Alindao', parent_id: 1264 },
	{ id: 17323, name: 'Kembé', parent_id: 1264 },
	{ id: 17327, name: 'Mobaye', parent_id: 1264 },
	{ id: 17331, name: 'Obo', parent_id: 1258 },
	{ id: 17338, name: 'Zemio', parent_id: 1258 },
	{ id: 17313, name: 'Bria', parent_id: 1268 },
	{ id: 17332, name: 'Ouadda', parent_id: 1268 },
	{ id: 17337, name: 'Sibut', parent_id: 1263 },
	{ id: 17307, name: 'Boda', parent_id: 1256 },
	{ id: 17308, name: 'Boganangone', parent_id: 1256 },
	{ id: 17325, name: 'Mbaiki', parent_id: 1256 },
	{ id: 17328, name: 'Mongoumba', parent_id: 1256 },
	{ id: 17301, name: 'Berberati', parent_id: 1257 },
	{ id: 17314, name: 'Carnot', parent_id: 1257 },
	{ id: 17317, name: 'Gamboula', parent_id: 1257 },
	{ id: 17297, name: 'Bangassou', parent_id: 1266 },
	{ id: 17316, name: 'Gambo', parent_id: 1266 },
	{ id: 17334, name: 'Ouango', parent_id: 1266 },
	{ id: 17336, name: 'Rafai', parent_id: 1266 },
	{ id: 17321, name: 'Kaga Bandoro', parent_id: 1253 },
	{ id: 17322, name: 'Kaga-Bandoro', parent_id: 1253 },
	{ id: 17299, name: 'Baoro', parent_id: 1260 },
	{ id: 17310, name: 'Bouar', parent_id: 1260 },
	{ id: 17303, name: 'Bimbo', parent_id: 1255 },
	{ id: 17305, name: 'Boali', parent_id: 1255 },
	{ id: 17315, name: 'Damara', parent_id: 1255 },
	{ id: 17295, name: 'Bambari', parent_id: 1265 },
	{ id: 17318, name: 'Grimari', parent_id: 1265 },
	{ id: 17319, name: 'Ippy', parent_id: 1265 },
	{ id: 17324, name: 'Kouango', parent_id: 1265 },
	{ id: 17300, name: 'Batangafo', parent_id: 1254 },
	{ id: 17309, name: 'Bossangoa', parent_id: 1254 },
	{ id: 17311, name: 'Bouca', parent_id: 1254 },
	{ id: 17320, name: 'Kabo', parent_id: 1254 },
	{ id: 17306, name: 'Bocaranga', parent_id: 1267 },
	{ id: 17312, name: 'Bozoum', parent_id: 1267 },
	{ id: 17335, name: 'Paoua', parent_id: 1267 },
	{ id: 17330, name: 'Nola', parent_id: 1252 },
	{ id: 17304, name: 'Birao', parent_id: 1261 },
	{ id: 17333, name: 'Ouanda-Djallé', parent_id: 1261 },
	{ id: 105152, name: 'Moussoro', parent_id: 3583 },
	{ id: 105120, name: 'Ati', parent_id: 3590 },
	{ id: 105154, name: 'Oum Hadjer', parent_id: 3590 },
	{ id: 105133, name: 'Faya-Largeau', parent_id: 3574 },
	{ id: 153256, name: 'Baguirmi Department', parent_id: 5114 },
	{ id: 153257, name: 'Bousso', parent_id: 5114 },
	{ id: 153258, name: 'Chari Department', parent_id: 5114 },
	{ id: 153259, name: 'Dababa', parent_id: 5114 },
	{ id: 153260, name: 'Gaoui', parent_id: 5114 },
	{ id: 105132, name: 'Fada', parent_id: 3584 },
	{ id: 105124, name: 'Bitkine', parent_id: 3576 },
	{ id: 105149, name: 'Melfi', parent_id: 3576 },
	{ id: 105150, name: 'Mongo', parent_id: 3576 },
	{ id: 105125, name: 'Bokoro', parent_id: 3573 },
	{ id: 105146, name: 'Massaguet', parent_id: 3573 },
	{ id: 105147, name: 'Massakory', parent_id: 3573 },
	{ id: 105145, name: 'Mao', parent_id: 3588 },
	{ id: 105126, name: 'Bol', parent_id: 3577 },
	{ id: 105122, name: 'Beïnamar', parent_id: 3585 },
	{ id: 105121, name: 'Benoy', parent_id: 3585 },
	{ id: 105143, name: 'Lac Wey', parent_id: 3585 },
	{ id: 105151, name: 'Moundou', parent_id: 3585 },
	{ id: 105129, name: 'Bébédja', parent_id: 3591 },
	{ id: 105128, name: 'Béboto', parent_id: 3591 },
	{ id: 105131, name: 'Doba', parent_id: 3591 },
	{ id: 105134, name: 'Goundi', parent_id: 3589 },
	{ id: 105141, name: 'Koumra', parent_id: 3589 },
	{ id: 105153, name: 'Moïssala', parent_id: 3589 },
	{ id: 105127, name: 'Bongor', parent_id: 3580 },
	{ id: 105135, name: 'Gounou Gaya', parent_id: 3580 },
	{ id: 105138, name: 'Guelendeng', parent_id: 3580 },
	{ id: 105148, name: 'Mboursou Léré', parent_id: 3571 },
	{ id: 105155, name: 'Pala', parent_id: 3571 },
	{ id: 105142, name: 'Kyabé', parent_id: 3570 },
	{ id: 105156, name: 'Sarh', parent_id: 3570 },
	{ id: 153264, name: 'N\'Djamena', parent_id: 3586 },
	{ id: 105116, name: 'Abéché', parent_id: 3582 },
	{ id: 105117, name: 'Adré', parent_id: 3582 },
	{ id: 105137, name: 'Goz Béïda', parent_id: 3582 },
	{ id: 105118, name: 'Am Timan', parent_id: 3592 },
	{ id: 105136, name: 'Goz Beïda', parent_id: 3572 },
	{ id: 105130, name: 'Béré', parent_id: 3579 },
	{ id: 105140, name: 'Kelo', parent_id: 3579 },
	{ id: 105144, name: 'Laï', parent_id: 3579 },
	{ id: 105119, name: 'Aozou', parent_id: 3587 },
	{ id: 105123, name: 'Biltine', parent_id: 3581 },
	{ id: 105139, name: 'Iriba', parent_id: 3581 },
	{ id: 19082, name: 'Aysén', parent_id: 2828 },
	{ id: 18962, name: 'Chile Chico', parent_id: 2828 },
	{ id: 19084, name: 'Cisnes', parent_id: 2828 },
	{ id: 18966, name: 'Cochrane', parent_id: 2828 },
	{ id: 18976, name: 'Coyhaique', parent_id: 2828 },
	{ id: 18946, name: 'Antofagasta', parent_id: 2832 },
	{ id: 18952, name: 'Calama', parent_id: 2832 },
	{ id: 148326, name: 'María Elena', parent_id: 2832 },
	{ id: 19033, name: 'Mejillones', parent_id: 2832 },
	{ id: 19074, name: 'Ollagüe', parent_id: 2832 },
	{ id: 18948, name: 'Arica', parent_id: 2829 },
	{ id: 19035, name: 'Camarones', parent_id: 2829 },
	{ id: 148327, name: 'General Lagos', parent_id: 2829 },
	{ id: 19067, name: 'Putre', parent_id: 2829 },
	{ id: 148330, name: 'Alto del Carmen', parent_id: 2823 },
	{ id: 148328, name: 'Caldera', parent_id: 2823 },
	{ id: 19042, name: 'Chañaral', parent_id: 2823 },
	{ id: 18972, name: 'Copiapó', parent_id: 2823 },
	{ id: 18981, name: 'Diego de Almagro', parent_id: 2823 },
	{ id: 19036, name: 'Alto Biobío', parent_id: 2827 },
	{ id: 148337, name: 'Antuco', parent_id: 2827 },
	{ id: 18947, name: 'Arauco', parent_id: 2827 },
	{ id: 18951, name: 'Cabrero', parent_id: 2827 },
	{ id: 18958, name: 'Cañete', parent_id: 2827 },
	{ id: 148352, name: 'Andacollo', parent_id: 2825 },
	{ id: 148353, name: 'Canela', parent_id: 2825 },
	{ id: 148354, name: 'Combarbalá', parent_id: 2825 },
	{ id: 18973, name: 'Coquimbo', parent_id: 2825 },
	{ id: 18988, name: 'Illapel', parent_id: 2825 },
	{ id: 18945, name: 'Angol', parent_id: 2826 },
	{ id: 18954, name: 'Carahue', parent_id: 2826 },
	{ id: 148360, name: 'Cholchol', parent_id: 2826 },
	{ id: 18969, name: 'Collipulli', parent_id: 2826 },
	{ id: 148361, name: 'Cunco', parent_id: 2826 },
	{ id: 148378, name: 'Chépica', parent_id: 2838 },
	{ id: 18964, name: 'Chimbarongo', parent_id: 2838 },
	{ id: 148379, name: 'Codegua', parent_id: 2838 },
	{ id: 148381, name: 'Coínco', parent_id: 2838 },
	{ id: 148380, name: 'Coltauco', parent_id: 2838 },
	{ id: 18944, name: 'Ancud', parent_id: 2835 },
	{ id: 18953, name: 'Calbuco', parent_id: 2835 },
	{ id: 18956, name: 'Castro', parent_id: 2835 },
	{ id: 18959, name: 'Chaitén', parent_id: 2835 },
	{ id: 18965, name: 'Chonchi', parent_id: 2835 },
	{ id: 18975, name: 'Corral', parent_id: 2834 },
	{ id: 148493, name: 'Futrono', parent_id: 2834 },
	{ id: 18995, name: 'La Unión', parent_id: 2834 },
	{ id: 148494, name: 'Lago Ranco', parent_id: 2834 },
	{ id: 148495, name: 'Lanco', parent_id: 2834 },
	{ id: 19029, name: 'Antártica', parent_id: 2836 },
	{ id: 148404, name: 'Cabo de Hornos', parent_id: 2836 },
	{ id: 148405, name: 'Laguna Blanca', parent_id: 2836 },
	{ id: 148406, name: 'Natales', parent_id: 2836 },
	{ id: 19028, name: 'Porvenir', parent_id: 2836 },
	{ id: 18957, name: 'Cauquenes', parent_id: 2833 },
	{ id: 148435, name: 'Chanco', parent_id: 2833 },
	{ id: 18968, name: 'Colbún', parent_id: 2833 },
	{ id: 18971, name: 'Constitución', parent_id: 2833 },
	{ id: 148436, name: 'Curepto', parent_id: 2833 },
	{ id: 18950, name: 'Bulnes', parent_id: 2831 },
	{ id: 18963, name: 'Chillán', parent_id: 2831 },
	{ id: 148419, name: 'Chillán Viejo', parent_id: 2831 },
	{ id: 148420, name: 'Cobquecura', parent_id: 2831 },
	{ id: 148421, name: 'Coelemu', parent_id: 2831 },
	{ id: 148500, name: 'Alhué', parent_id: 2824 },
	{ id: 18949, name: 'Buin', parent_id: 2824 },
	{ id: 148501, name: 'Calera de Tango', parent_id: 2824 },
	{ id: 148535, name: 'Cerrillos', parent_id: 2824 },
	{ id: 148540, name: 'Cerro Navia', parent_id: 2824 },
	{ id: 148412, name: 'Alto Hospicio', parent_id: 2837 },
	{ id: 148413, name: 'Camiña', parent_id: 2837 },
	{ id: 148414, name: 'Colchane', parent_id: 2837 },
	{ id: 148415, name: 'Huara', parent_id: 2837 },
	{ id: 18989, name: 'Iquique', parent_id: 2837 },
	{ id: 148452, name: 'Algarrobo', parent_id: 2830 },
	{ id: 148453, name: 'Cabildo', parent_id: 2830 },
	{ id: 148454, name: 'Calle Larga', parent_id: 2830 },
	{ id: 18955, name: 'Cartagena', parent_id: 2830 },
	{ id: 148455, name: 'Casablanca', parent_id: 2830 },
	{ id: 19284, name: 'Anqing', parent_id: 2251 },
	{ id: 19285, name: 'Anqing Shi', parent_id: 2251 },
	{ id: 19338, name: 'Bengbu', parent_id: 2251 },
	{ id: 19351, name: 'Bozhou', parent_id: 2251 },
	{ id: 19380, name: 'Chaohu', parent_id: 2251 },
	{ id: 19332, name: 'Beijing', parent_id: 2257 },
	{ id: 19370, name: 'Changping', parent_id: 2257 },
	{ id: 19439, name: 'Daxing', parent_id: 2257 },
	{ id: 19499, name: 'Fangshan', parent_id: 2257 },
	{ id: 19817, name: 'Liangxiang', parent_id: 2257 },
	{ id: 19327, name: 'Beibei', parent_id: 2271 },
	{ id: 19356, name: 'Caijia', parent_id: 2271 },
	{ id: 19404, name: 'Chongqing', parent_id: 2271 },
	{ id: 19479, name: 'Dongxi', parent_id: 2271 },
	{ id: 19521, name: 'Fuling', parent_id: 2271 },
	{ id: 19296, name: 'Badu', parent_id: 2248 },
	{ id: 19301, name: 'Baiqi', parent_id: 2248 },
	{ id: 19310, name: 'Baiyun', parent_id: 2248 },
	{ id: 19334, name: 'Beishancun', parent_id: 2248 },
	{ id: 19371, name: 'Changqiao', parent_id: 2248 },
	{ id: 19309, name: 'Baiyin', parent_id: 2275 },
	{ id: 19330, name: 'Beidao', parent_id: 2275 },
	{ id: 19460, name: 'Dingxi Shi', parent_id: 2275 },
	{ id: 19622, name: 'Hezuo', parent_id: 2275 },
	{ id: 19716, name: 'Jiayuguan', parent_id: 2275 },
	{ id: 19278, name: 'Anbu', parent_id: 2279 },
	{ id: 19383, name: 'Chaozhou', parent_id: 2279 },
	{ id: 19389, name: 'Chenghua', parent_id: 2279 },
	{ id: 19422, name: 'Daliang', parent_id: 2279 },
	{ id: 19426, name: 'Danshui', parent_id: 2279 },
	{ id: 19295, name: 'Babu', parent_id: 2278 },
	{ id: 19299, name: 'Baihe', parent_id: 2278 },
	{ id: 19303, name: 'Baise City', parent_id: 2278 },
	{ id: 19304, name: 'Baise Shi', parent_id: 2278 },
	{ id: 19331, name: 'Beihai', parent_id: 2278 },
	{ id: 19288, name: 'Anshun', parent_id: 2261 },
	{ id: 19292, name: 'Aoshi', parent_id: 2261 },
	{ id: 19297, name: 'Bahuang', parent_id: 2261 },
	{ id: 19307, name: 'Baishi', parent_id: 2261 },
	{ id: 19313, name: 'Bangdong', parent_id: 2261 },
	{ id: 19321, name: 'Basuo', parent_id: 2273 },
	{ id: 19406, name: 'Chongshan', parent_id: 2273 },
	{ id: 19415, name: 'Dadonghai', parent_id: 2273 },
	{ id: 19584, name: 'Haikou', parent_id: 2273 },
	{ id: 19745, name: 'Jinjiang', parent_id: 2273 },
	{ id: 19314, name: 'Baoding', parent_id: 2280 },
	{ id: 19329, name: 'Beidaihehaibin', parent_id: 2280 },
	{ id: 19349, name: 'Botou', parent_id: 2280 },
	{ id: 19357, name: 'Cangzhou', parent_id: 2280 },
	{ id: 19358, name: 'Cangzhou Shi', parent_id: 2280 },
	{ id: 19272, name: 'Acheng', parent_id: 2265 },
	{ id: 19279, name: 'Anda', parent_id: 2265 },
	{ id: 19302, name: 'Baiquan', parent_id: 2265 },
	{ id: 19312, name: 'Bamiantong', parent_id: 2265 },
	{ id: 19316, name: 'Baoqing', parent_id: 2265 },
	{ id: 19290, name: 'Anyang', parent_id: 2259 },
	{ id: 19291, name: 'Anyang Shi', parent_id: 2259 },
	{ id: 19342, name: 'Binhe', parent_id: 2259 },
	{ id: 19388, name: 'Chengguan', parent_id: 2259 },
	{ id: 19390, name: 'Chengjiao', parent_id: 2259 },
	{ id: 19282, name: 'Anlu', parent_id: 2274 },
	{ id: 19353, name: 'Buhe', parent_id: 2274 },
	{ id: 19355, name: 'Caidian', parent_id: 2274 },
	{ id: 19359, name: 'Caohe', parent_id: 2274 },
	{ id: 19396, name: 'Chengzhong', parent_id: 2274 },
	{ id: 19280, name: 'Anjiang', parent_id: 2258 },
	{ id: 19283, name: 'Anping', parent_id: 2258 },
	{ id: 19289, name: 'Anxiang', parent_id: 2258 },
	{ id: 19305, name: 'Baisha', parent_id: 2258 },
	{ id: 19345, name: 'Biyong', parent_id: 2258 },
	{ id: 19318, name: 'Baotou', parent_id: 2269 },
	{ id: 19323, name: 'Bayan Nur', parent_id: 2269 },
	{ id: 19324, name: 'Bayannur Shi', parent_id: 2269 },
	{ id: 19328, name: 'Beichengqu', parent_id: 2269 },
	{ id: 19399, name: 'Chifeng', parent_id: 2269 },
	{ id: 149266, name: 'ChangZhou', parent_id: 2250 },
	{ id: 19592, name: 'Haizhou', parent_id: 2250 },
	{ id: 149270, name: 'HuaiAn', parent_id: 2250 },
	{ id: 19734, name: 'Jingjiang', parent_id: 2250 },
	{ id: 149269, name: 'LianYunGang', parent_id: 2250 },
	{ id: 19367, name: 'Changleng', parent_id: 2256 },
	{ id: 19513, name: 'Fenyi', parent_id: 2256 },
	{ id: 19538, name: 'Ganzhou', parent_id: 2256 },
	{ id: 19539, name: 'Ganzhou Shi', parent_id: 2256 },
	{ id: 19574, name: 'Guixi', parent_id: 2256 },
	{ id: 19298, name: 'Baicheng', parent_id: 2253 },
	{ id: 19306, name: 'Baishan', parent_id: 2253 },
	{ id: 19308, name: 'Baishishan', parent_id: 2253 },
	{ id: 19363, name: 'Changchun', parent_id: 2253 },
	{ id: 19369, name: 'Changling', parent_id: 2253 },
	{ id: 19287, name: 'Anshan', parent_id: 2268 },
	{ id: 19333, name: 'Beipiao', parent_id: 2268 },
	{ id: 19339, name: 'Benxi', parent_id: 2268 },
	{ id: 19377, name: 'Changtu', parent_id: 2268 },
	{ id: 19382, name: 'Chaoyang', parent_id: 2268 },
	{ id: 19438, name: 'Dawukou', parent_id: 2262 },
	{ id: 19476, name: 'Dongta', parent_id: 2262 },
	{ id: 20106, name: 'Shitanjing', parent_id: 2262 },
	{ id: 20111, name: 'Shizuishan', parent_id: 2262 },
	{ id: 20246, name: 'Wuzhong', parent_id: 2262 },
	{ id: 19448, name: 'Delingha', parent_id: 2270 },
	{ id: 19554, name: 'Golmud', parent_id: 2270 },
	{ id: 19652, name: 'Huangnan Zangzu Zizhizhou', parent_id: 2270 },
	{ id: 19281, name: 'Ankang', parent_id: 2272 },
	{ id: 19315, name: 'Baoji Shi', parent_id: 2272 },
	{ id: 19578, name: 'Guozhen', parent_id: 2272 },
	{ id: 19595, name: 'Hancheng', parent_id: 2272 },
	{ id: 19600, name: 'Hanzhong', parent_id: 2272 },
	{ id: 19286, name: 'Anqiu', parent_id: 2252 },
	{ id: 19335, name: 'Beizhai', parent_id: 2252 },
	{ id: 19340, name: 'Bianzhuang', parent_id: 2252 },
	{ id: 19343, name: 'Binzhou', parent_id: 2252 },
	{ id: 19348, name: 'Boshan', parent_id: 2252 },
	{ id: 20063, name: 'Shanghai', parent_id: 2249 },
	{ id: 20132, name: 'Songjiang', parent_id: 2249 },
	{ id: 20417, name: 'Zhabei', parent_id: 2249 },
	{ id: 20469, name: 'Zhujiajiao', parent_id: 2249 },
	{ id: 19378, name: 'Changzhi', parent_id: 2254 },
	{ id: 19436, name: 'Datong', parent_id: 2254 },
	{ id: 19437, name: 'Datong Shi', parent_id: 2254 },
	{ id: 19580, name: 'Gutao', parent_id: 2254 },
	{ id: 19722, name: 'Jiexiu', parent_id: 2254 },
	{ id: 19320, name: 'Barkam', parent_id: 2277 },
	{ id: 19326, name: 'Bazhong Shi', parent_id: 2277 },
	{ id: 19362, name: 'Changchi', parent_id: 2277 },
	{ id: 19387, name: 'Chengdu', parent_id: 2277 },
	{ id: 19319, name: 'Baoying', parent_id: 2255 },
	{ id: 19376, name: 'Changshu City', parent_id: 2255 },
	{ id: 19379, name: 'Changzhou', parent_id: 2255 },
	{ id: 19394, name: 'Chengxiang', parent_id: 2255 },
	{ id: 19442, name: 'Dazhong', parent_id: 2255 },
	{ id: 149146, name: 'Badaogu', parent_id: 2276 },
	{ id: 149147, name: 'Baijian', parent_id: 2276 },
	{ id: 149148, name: 'Bamencheng', parent_id: 2276 },
	{ id: 149149, name: 'Bangjun', parent_id: 2276 },
	{ id: 149150, name: 'Beicang', parent_id: 2276 },
	{ id: 19273, name: 'Ailan Mubage', parent_id: 2263 },
	{ id: 19274, name: 'Aksu', parent_id: 2263 },
	{ id: 19275, name: 'Aksu Diqu', parent_id: 2263 },
	{ id: 19276, name: 'Altay', parent_id: 2263 },
	{ id: 19277, name: 'Altay Diqu', parent_id: 2263 },
	{ id: 19354, name: 'Burang', parent_id: 2264 },
	{ id: 19488, name: 'Dêqên', parent_id: 2264 },
	{ id: 19705, name: 'Jiangzi', parent_id: 2264 },
	{ id: 19812, name: 'Lhasa', parent_id: 2264 },
	{ id: 19933, name: 'Nagqu', parent_id: 2264 },
	{ id: 19409, name: 'Chuxiong Yizu Zizhizhou', parent_id: 2260 },
	{ id: 19419, name: 'Dali', parent_id: 2260 },
	{ id: 19420, name: 'Dali Baizu Zizhizhou', parent_id: 2260 },
	{ id: 19446, name: 'Dehong Daizu Jingpozu Zizhizhou', parent_id: 2260 },
	{ id: 19453, name: 'Deqing', parent_id: 2247 },
	{ id: 19481, name: 'Dongyang', parent_id: 2247 },
	{ id: 19505, name: 'Fenghua', parent_id: 2247 },
	{ id: 19526, name: 'Fuyang', parent_id: 2247 },
	{ id: 19576, name: 'Guli', parent_id: 2247 },
	{ id: 20807, name: 'El Encanto', parent_id: 2895 },
	{ id: 20977, name: 'La Chorrera', parent_id: 2895 },
	{ id: 20998, name: 'La Pedrera', parent_id: 2895 },
	{ id: 21014, name: 'La Victoria', parent_id: 2895 },
	{ id: 21027, name: 'Leticia', parent_id: 2895 },
	{ id: 20485, name: 'Abejorral', parent_id: 2890 },
	{ id: 20487, name: 'Abriaquí', parent_id: 2890 },
	{ id: 20504, name: 'Alejandría', parent_id: 2890 },
	{ id: 20512, name: 'Amagá', parent_id: 2890 },
	{ id: 20513, name: 'Amalfi', parent_id: 2890 },
	{ id: 20536, name: 'Arauca', parent_id: 2881 },
	{ id: 20537, name: 'Arauquita', parent_id: 2881 },
	{ id: 20746, name: 'Cravo Norte', parent_id: 2881 },
	{ id: 20856, name: 'Fortul', parent_id: 2881 },
	{ id: 21253, name: 'Puerto Rondón', parent_id: 2881 },
	{ id: 21224, name: 'Providencia', parent_id: 2900 },
	{ id: 21331, name: 'San Andrés', parent_id: 2900 },
	{ id: 20564, name: 'Baranoa', parent_id: 2880 },
	{ id: 20574, name: 'Barranquilla', parent_id: 2880 },
	{ id: 20640, name: 'Campo de la Cruz', parent_id: 2880 },
	{ id: 20643, name: 'Candelaria', parent_id: 2880 },
	{ id: 20876, name: 'Galapa', parent_id: 2880 },
	{ id: 149237, name: 'Bogotá D.C.', parent_id: 4921 },
	{ id: 20490, name: 'Achí', parent_id: 2893 },
	{ id: 20510, name: 'Altos del Rosario', parent_id: 2893 },
	{ id: 20543, name: 'Arenal', parent_id: 2893 },
	{ id: 20549, name: 'Arjona', parent_id: 2893 },
	{ id: 20554, name: 'Arroyohondo', parent_id: 2893 },
	{ id: 20507, name: 'Almeida', parent_id: 2903 },
	{ id: 20532, name: 'Aquitania', parent_id: 2903 },
	{ id: 20542, name: 'Arcabuco', parent_id: 2903 },
	{ id: 20583, name: 'Belén', parent_id: 2903 },
	{ id: 20587, name: 'Berbeo', parent_id: 2903 },
	{ id: 20494, name: 'Aguadas', parent_id: 2887 },
	{ id: 20525, name: 'Anserma', parent_id: 2887 },
	{ id: 20534, name: 'Aranzazu', parent_id: 2887 },
	{ id: 20576, name: 'Belalcázar', parent_id: 2887 },
	{ id: 20688, name: 'Chinchiná', parent_id: 2887 },
	{ id: 20499, name: 'Albania', parent_id: 2891 },
	{ id: 20584, name: 'Belén de Los Andaquies', parent_id: 2891 },
	{ id: 20660, name: 'Cartagena del Chairá', parent_id: 2891 },
	{ id: 20759, name: 'Curillo', parent_id: 2891 },
	{ id: 20805, name: 'El Doncello', parent_id: 2891 },
	{ id: 20495, name: 'Aguazul', parent_id: 2892 },
	{ id: 20677, name: 'Chameza', parent_id: 2892 },
	{ id: 20937, name: 'Hato Corozal', parent_id: 2892 },
	{ id: 149243, name: 'La Salina', parent_id: 2892 },
	{ id: 21062, name: 'Maní', parent_id: 2892 },
	{ id: 20506, name: 'Almaguer', parent_id: 2884 },
	{ id: 20545, name: 'Argelia', parent_id: 2884 },
	{ id: 20562, name: 'Balboa', parent_id: 2884 },
	{ id: 20599, name: 'Bolívar', parent_id: 2884 },
	{ id: 20610, name: 'Buenos Aires', parent_id: 2884 },
	{ id: 20492, name: 'Aguachica', parent_id: 2899 },
	{ id: 20496, name: 'Agustín Codazzi', parent_id: 2899 },
	{ id: 20555, name: 'Astrea', parent_id: 2899 },
	{ id: 20575, name: 'Becerril', parent_id: 2899 },
	{ id: 149238, name: 'Bosconia', parent_id: 2899 },
	{ id: 20489, name: 'Acandí', parent_id: 2876 },
	{ id: 20509, name: 'Alto Baudó', parent_id: 2876 },
	{ id: 20557, name: 'Atrato', parent_id: 2876 },
	{ id: 20559, name: 'Bagadó', parent_id: 2876 },
	{ id: 20560, name: 'Bahía Solano', parent_id: 2876 },
	{ id: 20558, name: 'Ayapel', parent_id: 2898 },
	{ id: 20608, name: 'Buenavista', parent_id: 2898 },
	{ id: 20642, name: 'Canalete', parent_id: 2898 },
	{ id: 20669, name: 'Cereté', parent_id: 2898 },
	{ id: 20686, name: 'Chimá', parent_id: 2898 },
	{ id: 20491, name: 'Agua de Dios', parent_id: 2875 },
	{ id: 20501, name: 'Albán', parent_id: 2875 },
	{ id: 20516, name: 'Anapoima', parent_id: 2875 },
	{ id: 20523, name: 'Anolaima', parent_id: 2875 },
	{ id: 20530, name: 'Apulo', parent_id: 2875 },
	{ id: 20572, name: 'Barranco Minas', parent_id: 2882 },
	{ id: 20618, name: 'Cacahual', parent_id: 2882 },
	{ id: 20949, name: 'Inírida', parent_id: 2882 },
	{ id: 20986, name: 'La Guadalupe', parent_id: 2882 },
	{ id: 21063, name: 'Mapiripana', parent_id: 2882 },
	{ id: 20627, name: 'Calamar', parent_id: 2888 },
	{ id: 20823, name: 'El Retorno', parent_id: 2888 },
	{ id: 21083, name: 'Miraflores', parent_id: 2888 },
	{ id: 21370, name: 'San José del Guaviare', parent_id: 2888 },
	{ id: 143877, name: 'Acevedo', parent_id: 4871 },
	{ id: 143878, name: 'Aipe', parent_id: 4871 },
	{ id: 143879, name: 'Algeciras', parent_id: 4871 },
	{ id: 143880, name: 'Altamira', parent_id: 4871 },
	{ id: 143881, name: 'Baraya', parent_id: 4871 },
	{ id: 20498, name: 'Albania', parent_id: 2889 },
	{ id: 20571, name: 'Barrancas', parent_id: 2889 },
	{ id: 20776, name: 'Dibulla', parent_id: 2889 },
	{ id: 20777, name: 'Distracción', parent_id: 2889 },
	{ id: 20812, name: 'El Molino', parent_id: 2889 },
	{ id: 20505, name: 'Algarrobo', parent_id: 2886 },
	{ id: 20533, name: 'Aracataca', parent_id: 2886 },
	{ id: 20548, name: 'Ariguaní', parent_id: 2886 },
	{ id: 20673, name: 'Cerro de San Antonio', parent_id: 2886 },
	{ id: 20700, name: 'Chivolo', parent_id: 2886 },
	{ id: 20488, name: 'Acacías', parent_id: 2878 },
	{ id: 20569, name: 'Barranca de Upía', parent_id: 2878 },
	{ id: 20617, name: 'Cabuyaro', parent_id: 2878 },
	{ id: 20665, name: 'Castilla la Nueva', parent_id: 2878 },
	{ id: 20749, name: 'Cubarral', parent_id: 2878 },
	{ id: 20503, name: 'Aldana', parent_id: 2897 },
	{ id: 20517, name: 'Ancuya', parent_id: 2897 },
	{ id: 20539, name: 'Arboleda', parent_id: 2897 },
	{ id: 20565, name: 'Barbacoas', parent_id: 2897 },
	{ id: 20582, name: 'Belén', parent_id: 2897 },
	{ id: 20486, name: 'Abrego', parent_id: 2877 },
	{ id: 20540, name: 'Arboledas', parent_id: 2877 },
	{ id: 20594, name: 'Bochalema', parent_id: 2877 },
	{ id: 20604, name: 'Bucarasica', parent_id: 2877 },
	{ id: 20620, name: 'Cachirá', parent_id: 2877 },
	{ id: 20721, name: 'Colón', parent_id: 2896 },
	{ id: 21089, name: 'Mocoa', parent_id: 2896 },
	{ id: 21152, name: 'Orito', parent_id: 2896 },
	{ id: 21233, name: 'Puerto Asís', parent_id: 2896 },
	{ id: 149248, name: 'Puerto Caicedo', parent_id: 2896 },
	{ id: 20550, name: 'Armenia', parent_id: 2874 },
	{ id: 20607, name: 'Buenavista', parent_id: 2874 },
	{ id: 20629, name: 'Calarca', parent_id: 2874 },
	{ id: 20709, name: 'Circasia', parent_id: 2874 },
	{ id: 20771, name: 'Córdoba', parent_id: 2874 },
	{ id: 20531, name: 'Apía', parent_id: 2879 },
	{ id: 20563, name: 'Balboa', parent_id: 2879 },
	{ id: 20585, name: 'Belén de Umbría', parent_id: 2879 },
	{ id: 20782, name: 'Dosquebradas', parent_id: 2879 },
	{ id: 20926, name: 'Guática', parent_id: 2879 },
	{ id: 20493, name: 'Aguada', parent_id: 2901 },
	{ id: 20497, name: 'Albania', parent_id: 2901 },
	{ id: 20535, name: 'Aratoca', parent_id: 2901 },
	{ id: 20567, name: 'Barbosa', parent_id: 2901 },
	{ id: 20568, name: 'Barichara', parent_id: 2901 },
	{ id: 149239, name: 'Buenavista', parent_id: 2902 },
	{ id: 20623, name: 'Caimito', parent_id: 2902 },
	{ id: 20676, name: 'Chalán', parent_id: 2902 },
	{ id: 20719, name: 'Coloso', parent_id: 2902 },
	{ id: 20739, name: 'Corozal', parent_id: 2902 },
	{ id: 20508, name: 'Alpujarra', parent_id: 2883 },
	{ id: 20511, name: 'Alvarado', parent_id: 2883 },
	{ id: 20514, name: 'Ambalema', parent_id: 2883 },
	{ id: 20528, name: 'Anzoátegui', parent_id: 2883 },
	{ id: 20552, name: 'Armero', parent_id: 2883 },
	{ id: 20502, name: 'Alcalá', parent_id: 2904 },
	{ id: 20519, name: 'Andalucía', parent_id: 2904 },
	{ id: 20526, name: 'Ansermanuevo', parent_id: 2904 },
	{ id: 20546, name: 'Argelia', parent_id: 2904 },
	{ id: 20598, name: 'Bolívar', parent_id: 2904 },
	{ id: 20663, name: 'Caruru', parent_id: 2885 },
	{ id: 21088, name: 'Mitú', parent_id: 2885 },
	{ id: 21160, name: 'Pacoa', parent_id: 2885 },
	{ id: 21181, name: 'Papunaua', parent_id: 2885 },
	{ id: 21511, name: 'Taraira', parent_id: 2885 },
	{ id: 20755, name: 'Cumaribo', parent_id: 2894 },
	{ id: 21002, name: 'La Primavera', parent_id: 2894 },
	{ id: 21237, name: 'Puerto Carreño', parent_id: 2894 },
	{ id: 21428, name: 'Santa Rosalia', parent_id: 2894 },
	{ id: 65079, name: 'Adda-Douéni', parent_id: 2821 },
	{ id: 65080, name: 'Antsahé', parent_id: 2821 },
	{ id: 65081, name: 'Assimpao', parent_id: 2821 },
	{ id: 65084, name: 'Bambao', parent_id: 2821 },
	{ id: 65085, name: 'Bandajou', parent_id: 2821 },
	{ id: 65082, name: 'Bahani', parent_id: 2822 },
	{ id: 65083, name: 'Bambadjani', parent_id: 2822 },
	{ id: 65089, name: 'Bouni', parent_id: 2822 },
	{ id: 65091, name: 'Chezani', parent_id: 2822 },
	{ id: 65092, name: 'Chindini', parent_id: 2822 },
	{ id: 65098, name: 'Djoyézi', parent_id: 2820 },
	{ id: 65103, name: 'Fomboni', parent_id: 2820 },
	{ id: 65108, name: 'Hoani', parent_id: 2820 },
	{ id: 65140, name: 'Mtakoudja', parent_id: 2820 },
	{ id: 65143, name: 'Nioumachoua', parent_id: 2820 },
	{ id: 17345, name: 'Kayes', parent_id: 2866 },
	{ id: 17348, name: 'Madingou', parent_id: 2866 },
	{ id: 17339, name: 'Brazzaville', parent_id: 2870 },
	{ id: 17349, name: 'Makoua', parent_id: 2864 },
	{ id: 17352, name: 'Owando', parent_id: 2864 },
	{ id: 17342, name: 'Ewo', parent_id: 2869 },
	{ id: 17354, name: 'Sibiti', parent_id: 2868 },
	{ id: 17344, name: 'Impfondo', parent_id: 2865 },
	{ id: 17341, name: 'Dolisie', parent_id: 2872 },
	{ id: 17350, name: 'Mossendjo', parent_id: 2872 },
	{ id: 17340, name: 'Djambala', parent_id: 2862 },
	{ id: 17343, name: 'Gamboma', parent_id: 2862 },
	{ id: 17347, name: 'Loandjili', parent_id: 2863 },
	{ id: 17353, name: 'Pointe-Noire', parent_id: 2863 },
	{ id: 17346, name: 'Kinkala', parent_id: 2873 },
	{ id: 17351, name: 'Ouésso', parent_id: 2871 },
	{ id: 17355, name: 'Sémbé', parent_id: 2871 },
	{ id: 15686, name: 'Alice Town', parent_id: 3629 },
	{ id: 15688, name: 'Arthur’s Town', parent_id: 3611 },
	{ id: 15699, name: 'Marsh Harbour', parent_id: 3603 },
	{ id: 15691, name: 'Colonel Hill', parent_id: 3621 },
	{ id: 15697, name: 'High Rock', parent_id: 3614 },
	{ id: 15696, name: 'George Town', parent_id: 3612 },
	{ id: 15695, name: 'Freeport', parent_id: 3626 },
	{ id: 15698, name: 'Lucaya', parent_id: 3626 },
	{ id: 15694, name: 'Dunmore Town', parent_id: 3613 },
	{ id: 15700, name: 'Matthew Town', parent_id: 3609 },
	{ id: 15689, name: 'Clarence Town', parent_id: 3610 },
	{ id: 15685, name: 'Abraham’s Bay', parent_id: 3633 },
	{ id: 143913, name: 'Nassau', parent_id: 4881 },
	{ id: 15692, name: 'Cooper’s Town', parent_id: 3616 },
	{ id: 15687, name: 'Andros Town', parent_id: 3617 },
	{ id: 15702, name: 'San Andros', parent_id: 3617 },
	{ id: 15693, name: 'Duncan Town', parent_id: 3615 },
	{ id: 15701, name: 'Port Nelson', parent_id: 3600 },
	{ id: 15690, name: 'Cockburn Town', parent_id: 3627 },
	{ id: 15703, name: 'Spanish Wells', parent_id: 3630 },
	{ id: 15704, name: 'West End', parent_id: 3599 },
];
