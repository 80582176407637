import { FieldType } from '@unifii/sdk';

export const FieldTypeIcon = new Map<FieldType, string>([
	[FieldType.ActionGroup, 'actionGroup'],
	[FieldType.Address, 'address'],
	[FieldType.Bool, 'bool'],
	[FieldType.Choice, 'singleChoice'],
	[FieldType.Content, 'content'],
	[FieldType.Cost, 'cost'],
	[FieldType.Date, 'date'],
	[FieldType.DateTime, 'dateTime'],
	[FieldType.ZonedDateTime, 'zonedDateTime'],
	[FieldType.Email, 'mail'],
	[FieldType.GeoLocation, 'geoLocation'],
	[FieldType.Group, 'group'],
	[FieldType.ImageList, 'imageList'],
	[FieldType.VideoList, 'video'],
	[FieldType.FileList, 'fileList'],
	[FieldType.SoundList, 'audio'],
	[FieldType.MultiChoice, 'multiChoice'],
	[FieldType.MultiText, 'multiText'],
	[FieldType.Number, 'number'],
	[FieldType.Phone, 'telephone'],
	[FieldType.Repeat, 'groupRepeat'],
	[FieldType.Section, 'section'],
	[FieldType.Separator, 'separator'],
	[FieldType.Signature, 'signature'],
	[FieldType.Survey, 'survey'],
	[FieldType.Text, 'text'],
	[FieldType.Time, 'time'],
	[FieldType.Website, 'earth'],
	[FieldType.Lookup, 'lookup'],
	[FieldType.TextArray, 'text'],
	[FieldType.Hierarchy, 'hierarchy'],
	[FieldType.Link, 'link'],
	[FieldType.Stepper, 'stepper'],
	[FieldType.Step, 'pages'],
]);
