import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-sliders.html',

})
export class ShowSlidersComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly sizeOptions = [
		{ name: 'Medium (default)', value: '' },
		{ name: 'Small', value: 'small' },
	];

	size = '';

	sliderValue = '75';

	readonly inputTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'default', name: 'Default' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly inputTableInfo = [
		{ name: 'control', type: 'UfControl', default: 'new UfControl()', description: 'A control object to manage validators, states etc.' },
		{ name: 'id', type: 'string', default: 'undefined', description: 'Sets id attribute of inner input element' },
		{ name: 'name', type: 'string', default: 'undefined', description: 'Sets name attribute of inner input element' },
		{ name: 'label', type: 'string', default: 'undefined', description: 'Sets label' },
		{ name: 'min', type: 'number', default: '0', description: 'Sets minimum boundary of slider' },
		{ name: 'max', type: 'number', default: '100', description: 'Sets maximum boundary of slider' },
		{ name: 'step', type: 'number', default: 'undefined', description: 'Sets step size of slider' },
		{ name: 'autocomplete', type: 'string', default: 'UUID', description: 'Sets autocomplete name for the input' },
	];
	readonly outputTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly outputTableInfo = [
		{ name: 'valueChange', type: 'EventEmitter', description: 'Emits an event when the value of the input is changed' },
	];
	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'default', type: 'modifier', description: 'Default input size' },
		{ name: 'small', type: 'modifier', description: 'Small input size' },
	];

	template = `
		<uf-slider label="Slider"></uf-slider>
	`;

	modifierChange() {
		this.template = `<uf-slider label="Slider"${this.size ? ' class="' + this.size + '"' : ''}></uf-slider>`;
	}

}
