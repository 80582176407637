<div class="grid">
	<uf-select *ngIf="pages.length" [options]="pages" [(value)]="id" (valueChange)="render($event)" class="col-1of1"
		label="Select page" valueProperty="id" placeholder="Select" nameProperty="name" />

	<div *ngIf="!pages.length" class="col-1of1">
		<uf-message class="info" icon="info" content="No Pages" />
	</div>
</div>

<ng-template [ngIf]="page">
	<uf-page [page]="page" />
</ng-template>