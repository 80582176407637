import { Inject } from '@angular/core';
import { AstNode, Compound, DataSeed, PublishedContent, isNotNull } from '@unifii/sdk';

import { Context, DataSourceLoader, DataSourceLoaderConfig, Scope, SourceConfigCollection } from '../../models';
import { ExpressionParser } from '../expression-parser';

import { DataSourceConverter } from './data-source-converter';
import { sortByDisplay } from './data-source-helper-functions';
import { toDataSeedSafeOption } from './retrocompatibility-loader-hack';
import { SourceConfigQueryBuilder } from './source-config-query-builder';

export class CollectionLoader implements DataSourceLoader {

	private queryBuilder: SourceConfigQueryBuilder;

	constructor(
		public sourceConfig: SourceConfigCollection,
		public config: DataSourceLoaderConfig | undefined,
		@Inject(PublishedContent) private content: PublishedContent,
		private dataSourceConverter: DataSourceConverter,
		expressionParser: ExpressionParser,
	) {
		this.queryBuilder = new SourceConfigQueryBuilder(sourceConfig, expressionParser);
	}

	async getOptions(context?: Context, scope?: Scope): Promise<DataSeed[]> {
		if (!this.sourceConfig.id) {
			return [];
		}

		const query = this.queryBuilder.create(undefined, context, scope);
		const compounds = await this.content.queryCollection(this.sourceConfig.id, query, { analytics: this.config?.requestAnalytics });

		return compounds.map((compound) => this.mapToSeed(compound)).filter(isNotNull).map(toDataSeedSafeOption);
	}

	async search(q?: string, context?: Context, scope?: Scope, signal?: AbortSignal, contextFilters?: AstNode): Promise<DataSeed[]> {
		if (!this.sourceConfig.id) {
			return [];
		}

		const query = this.queryBuilder.create(q, context, scope, undefined, contextFilters);
		const compounds = await this.content.queryCollection(this.sourceConfig.id, query, { signal, analytics: this.config?.requestAnalytics });

		return compounds.map((compound) => this.mapToSeed(compound)).filter(isNotNull);
	}

	async findAllBy(match: string, context?: Context, scope?: Scope, signal?: AbortSignal): Promise<DataSeed[]> {

		if (!this.sourceConfig.findBy) {
			console.warn('Datasource has not set findBy, falling back on search');

			return this.search(match, context, scope, signal);
		}

		if (!this.sourceConfig.id) {
			return [];
		}

		const query = this.queryBuilder.create(match, context, scope, this.sourceConfig.findBy);
		const compounds = await this.content.queryCollection(this.sourceConfig.id, query, { signal, analytics: this.config?.requestAnalytics });

		return compounds.map((compound) => this.mapToSeed(compound)).filter(isNotNull).sort(sortByDisplay);
	}

	async get(id: string): Promise<DataSeed | null> {
		if (!this.sourceConfig.id) {
			return null;
		}

		const compound = await this.content.getCollectionItem(this.sourceConfig.id, id);

		return this.mapToSeed(compound);
	}

	mapToSeed(compound?: Compound): DataSeed | null {
		return this.dataSourceConverter.toDataSeed(compound, this.sourceConfig);
	}

}
