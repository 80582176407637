<ng-container [ngSwitch]="field.template">
	<uf-radio *ngSwitchCase="fieldTemplate.Radio" [ngClass]="cssClass" [label]="field.label" [control]="control"
		[value]="content" [options]="options" [columns]="field.columnCount" [direction]="field.layoutDirection"
		(valueChange)="changed($event)" nameProperty="name" valueProperty="value">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field?.help" [content]="field.help" />
	</uf-radio>
	<uf-radio *ngSwitchCase="fieldTemplate.BoolTickCross" [ngClass]="cssClass" [label]="field.label" [control]="control"
		[value]="content" [options]="options" [columns]="field.columnCount" [direction]="field.layoutDirection"
		(valueChange)="changed($event)" nameProperty="name" valueProperty="value">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field?.help" [content]="field.help" />
		<uf-bool-radios #radioOptions options />
	</uf-radio>
	<uf-radio *ngSwitchCase="fieldTemplate.Buttons" [ngClass]="cssClass" [label]="field.label" [control]="control"
		[value]="content" [options]="options" [direction]="field.layoutDirection" (valueChange)="changed($event)"
		boolTemplate="Button" nameProperty="name" valueProperty="value" columns="2">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field?.help" [content]="field.help" />
	</uf-radio>
	<uf-select *ngSwitchCase="fieldTemplate.DropDown" [ngClass]="cssClass" [label]="field.label" [control]="control"
		[value]="content" [placeholder]="field.placeholder || 'Select Answer'" [options]="options"
		(valueChange)="changed($event)" nameProperty="name" valueProperty="value" trackBy="identifier">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field?.help" [content]="field.help" />
	</uf-select>
	<uf-checkbox *ngSwitchDefault [ngClass]="cssClass" [control]="control" [value]="content" [label]="field.label"
		(valueChange)="changed($event)">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field?.help" [content]="field.help" />
	</uf-checkbox>
</ng-container>