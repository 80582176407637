import { AfterContentInit, Component, ComponentRef, ContentChild, ContentChildren, ElementRef, EventEmitter, Injector, Input, OnChanges, OnDestroy, Output, QueryList, ViewContainerRef, forwardRef, inject } from '@angular/core';

import { ExpanderHeaderDirective } from '../../directives';
import { ExpandersService } from '../../services';

import { ExpanderButtonComponent } from './expander-button.component';

@Component({
	selector: 'uf-expander',
	templateUrl: './expander.html',
	styleUrls: ['./expander.less'],
})
export class ExpanderComponent implements AfterContentInit, OnChanges, OnDestroy {

	@Output() isExpandedChange = new EventEmitter<boolean>();

	@ContentChild(ExpanderHeaderDirective, { read: ViewContainerRef, static: false })
	private headerContainerRef: ViewContainerRef;
	@ContentChild(ExpanderHeaderDirective, { read: ElementRef, static: false })
	private header: ElementRef<HTMLElement>;
	@ContentChildren(forwardRef(() => ExpanderComponent))
	private expanderChildren: QueryList<ExpanderComponent>;

	hostElement = inject<ElementRef<HTMLElement>>(ElementRef);

	private injector = inject(Injector);
	private expandersService = inject(ExpandersService, { optional: true });
	private expanderButtons?: ComponentRef<ExpanderButtonComponent>;
	private _isExpanded = true;
	private _actionBtn = false;

	constructor( ) {
		this.expandersService?.register(this);
	}

	@Input() set isExpanded(v: boolean | string | null | undefined) {
		if (!v) {
			this._isExpanded = true;
		}

		this._isExpanded = typeof v === 'string' ? v === 'true' : v === true;
	}

	get isExpanded(): boolean {
		return this._isExpanded;
	}

	@Input() set actionBtn(v: boolean | string | null | undefined) {
		this._actionBtn = !v ? false : true;
		this._isExpanded = typeof v === 'string' ? v === 'true' : v === true;
	}

	get actionBtn(): boolean {
		return this._actionBtn;
	}

	ngOnDestroy() {
		this.expandersService?.deregister(this);
	}

	ngAfterContentInit() {

		if (this.actionBtn) {
			this.expanderButtons = this.headerContainerRef.createComponent(ExpanderButtonComponent, { injector: this.injector });
			this.header.nativeElement.appendChild(this.expanderButtons.location.nativeElement);
		} else {
			this.expanderButtons = this.headerContainerRef.createComponent(ExpanderButtonComponent, { injector: this.injector, index: 0 });
			this.header.nativeElement.prepend(this.expanderButtons.location.nativeElement as HTMLElement);
		}

		this.expanderButtons.instance.toggle = this.toggle;
		this.expanderButtons.instance.expandAll = this.expandAll;
		this.expanderButtons.instance.collapseAll = this.collapseAll;
		this.expanderButtons.instance.isExpanded = this.isExpanded;
		this.expanderButtons.instance.actionBtn = this.actionBtn;
		this.expanderButtons.hostView.detectChanges();
	}

	ngOnChanges() {
		this.update();
	}

	toggle = () => {
		if (this.isExpanded) {
			this.collapse();

			return;
		}
		this.expand();
	};

	expandAll = () => {
		this.expand();

		this.expanderChildren.filter((x) => x !== this).forEach((expander) => {
			expander.expandAll();
		});
	};

	collapseAll = () => {

		this.collapse();

		this.expanderChildren.filter((x) => x !== this).forEach((expander) => {
			expander.collapseAll();
		});
	};

	expand() {
		this.isExpanded = true;
		this.update();
		this.isExpandedChange.emit(this.isExpanded);
	}

	collapse() {
		this.isExpanded = false;
		this.update();
		this.isExpandedChange.emit(this.isExpanded);
	}

	private update() {

		if (!this.expanderButtons) {
			return;
		}

		this.expanderButtons.instance.isExpanded = this.isExpanded;
		this.expanderButtons.hostView.detectChanges();
	}

}
