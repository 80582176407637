import { Pipe, PipeTransform } from '@angular/core';
import { DataSourceType } from '@unifii/sdk';

import { SourceConfig, SourceConfigMappingsInfo } from '../models';

@Pipe({ name: 'scMappings' })
export class UfSourceConfigMappingsPipe implements PipeTransform {

	transform(sourceConfig?: SourceConfig): SourceConfigMappingsInfo | undefined {
		if (!sourceConfig || sourceConfig.type === DataSourceType.Named) {
			return;
		}

		return {
			mappings: sourceConfig.mappings,
			mappingsFrom: sourceConfig.mappingsFrom,
			mappingsTo: sourceConfig.mappingsTo,
			mappingsVisibles: sourceConfig.mappingsVisibles,
		};
	}

}
