import { Component, ElementRef, Input, inject } from '@angular/core';
import { ImageProfile, PublishedContent } from '@unifii/sdk';

import { RuntimeField } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-image-list-display',
	templateUrl: './image-list.html',
	styleUrls: ['./image-list.less'],
})
export class ImageListDisplayComponent implements ContentField {

	protected readonly interval = 8000; // ms
	protected paddingBottom: string;

	private devicePixelRatio = devicePixelRatio;
	private _content: ImageProfile[] = [];
	private _field: RuntimeField;

	private imageListElement = inject<ElementRef<HTMLElement>>(ElementRef);
	private contentService = inject(PublishedContent);

	@Input({ required: true }) set field(v: RuntimeField) {
		this._field = v;
		this.paddingBottom = this.calculatePaddingBottom();
	}

	get field(): RuntimeField {
		return this._field;
	}

	@Input() set content(v: ImageProfile[] | null) {

		if (!v) {
			return;
		}

		this._content = v;
	}

	get content() {
		return this._content;
	}

	protected getImageUrl(img: ImageProfile): string | undefined {
		const desiredWidth = this.imageListElement.nativeElement.offsetWidth * Math.min(this.devicePixelRatio, 2);

		return this.contentService.buildImageUrl(img, { width: desiredWidth });
	}

	private calculatePaddingBottom(): string {

		if (!this.field.minWidth || !this.field.minHeight) {
			return '50%';
		}

		return this.field.minHeight * 100 / this.field.minWidth + '%';
	}

}
