import { Injectable, inject } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { DATE_DATA_FORMAT, DATE_TIME_DATA_FORMAT } from '@unifii/sdk';
import { addMinutes, format, isAfter, isBefore, isSameMinute, parse, parseISO, startOfDay, subMinutes } from 'date-fns';
// https://github.com/marnusw/date-fns-tz/issues/183
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

import { Context, DateTimeFunctions, ExpressionFunctionsSet, ExpressionParser, Scope, ValidatorFunctions, getDateTimeFormattedNow, getLocalTimeZone } from '@unifii/library/common';

import { ContextFactory } from '../models';

// Creates validator functions that are understood by form elements
@Injectable({ providedIn: 'root' })
export class FormValidators {

	private expParser = inject(ExpressionParser);

	beforeNow = (buildContext: ContextFactory, msg: string): ValidatorFn => ValidatorFunctions.custom((v) => {

		if (ValidatorFunctions.isEmpty(v)) {
			return true;
		}

		let inputValue = v;

		if (inputValue.value && inputValue.tz) {
			// DTZ format, convert to local DateTime
			const browserTZ = getLocalTimeZone();

			inputValue = format(utcToZonedTime(inputValue.value, browserTZ), DATE_TIME_DATA_FORMAT);
		}

		const stringFormat = DateTimeFunctions.getFormat(inputValue);

		if (!stringFormat) {
			return true;
		}

		const value = parse(inputValue, stringFormat, new Date());
		const now = parseISO(buildContext(v).now ?? getDateTimeFormattedNow());
		const adjustedNow = stringFormat === DATE_DATA_FORMAT ? startOfDay(now) : addMinutes(now, 5);

		return stringFormat === DATE_DATA_FORMAT ? 
			isBefore(value, adjustedNow) :
			isBefore(value, adjustedNow) || isSameMinute(value, adjustedNow);
	}, msg);

	afterNow = (buildContext: ContextFactory, msg: string): ValidatorFn => ValidatorFunctions.custom((v) => {

		if (ValidatorFunctions.isEmpty(v)) {
			return true;
		}

		let inputValue = v;

		if (inputValue.value && inputValue.tz) {
			// DTZ format, convert to local DateTime
			const browserTZ = getLocalTimeZone();

			inputValue = format(utcToZonedTime(inputValue.value, browserTZ), DATE_TIME_DATA_FORMAT);
		}

		const stringFormat = DateTimeFunctions.getFormat(inputValue);

		if (!stringFormat) {
			return true;
		}

		const value = parse(inputValue, stringFormat, new Date());
		const now = parseISO(buildContext(v).now ?? getDateTimeFormattedNow());
		const adjustedNow = stringFormat === DATE_DATA_FORMAT ? startOfDay(now) : subMinutes(now, 5);

		return stringFormat === DATE_DATA_FORMAT ?
			isAfter(value, adjustedNow) :
			isAfter(value, adjustedNow) || isSameMinute(value, adjustedNow);
	}, msg);

	expression = (scope: () => Scope, buildContext: (v: any) => Context, functions: ExpressionFunctionsSet, expression: string, msg: string): ValidatorFn => ValidatorFunctions.custom((value) => {

		let parsed = false;

		try {
			const func = this.expParser.getFunc(expression);

			if (!func) {
				throw new Error();
			}

			parsed = true;
			const context = buildContext(value);

			return func(scope(), context, functions);
		} catch (e) {
			console.warn(`${parsed ? 'Execute' : 'Parse'} validator '${expression}'`, e);

			return true;
		}
	}, msg);

}
