import { Component, Input, inject } from '@angular/core';

import { DataDisplayComponentType, LocationProvider } from '../../../models';

import { DataDisplayMapMarkerValue, isDataDisplayMapMarkerValue } from './data-display-components-model';

@Component({
	selector: 'uf-data-display-map-marker',
	templateUrl: './data-display-map-marker.html',
	styleUrls: ['../data-display.less'],
})
export class DataDisplayMapMarkerComponent implements DataDisplayComponentType {
	
	@Input({ required: true }) data: DataDisplayMapMarkerValue;

	static isDataAccepted = isDataDisplayMapMarkerValue;
	
	protected readonly openMap = !!inject(LocationProvider).getMapUrl;	
	
}
