import { Type } from '@angular/core';

import { ComponentRegistry, ComponentRegistryOptions, ComponentRegistryType, ContentField } from '@unifii/library/smart-forms';

// Break circular dependency
import * as display from '../components/fields';

// TODO Rename it UfDataDisplayListRegistry
export class UfDescriptionListRegistry implements ComponentRegistry {

	type = ComponentRegistryType.Display;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	get(_name: string, _options?: ComponentRegistryOptions): Type<ContentField> | null {
		return display.FieldDescriptionComponent;
	}

}
