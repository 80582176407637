import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-date.html',
})
export class ShowDateComponent {

	protected readonly sizes = Size;
	protected readonly columnGaps = ColumnGap;

	protected readonly sizeOptions = [
		{ name: 'Medium (default)', value: '' },
		{ name: 'Small', value: 'small' },
	];

	protected size = '';

	protected readonly inputTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'default', name: 'Default' },
		{ identifier: 'description', name: 'Description' },
	];

	protected readonly inputTableInfo = [
		{ name: 'control', type: 'UfControl', default: 'new UfControl()', description: 'A control object to manage validators, states etc.' },
		{ name: 'name', type: 'string', default: 'undefined', description: 'Sets name attribute of inner input element' },
		{ name: 'label', type: 'string', default: 'undefined', description: 'Sets label' },
		{ name: 'placeholder', type: 'string', default: 'undefined', description: 'Sets placeholder' },
		{ name: 'format', type: 'string', default: 'dd/MM/yyyy', description: 'Sets display format' },
	];
	protected readonly outputTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	protected readonly outputTableInfo = [
		{ name: 'valueChange', type: 'EventEmitter', description: 'Emits an event when the value of the input is changed' },
		{ name: 'touchChange', type: 'EventEmitter', description: 'Emits an event when the input is set to touched' },
	];
	protected readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	protected readonly cssTableInfo = [
		{ name: 'default', type: 'modifier', description: 'Default input size' },
		{ name: 'small', type: 'modifier', description: 'Small input size' },
	];

	protected template = `<uf-date label="Date"/>`;

	protected value = '2020-02-25';

	modifierChange() {
		this.template = `<uf-date label="Date"${this.size ? ' class="' + this.size + '"' : ''}></uf-date>`;
	}

}
