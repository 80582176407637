import { InjectionToken, Injector, StaticProvider, Type } from '@angular/core';

import { Modal } from './modal';

export enum ModalDisplayType {
	Fit = 'fit',
	Medium = 'medium',
	Large = 'large',
	FullScreen = 'full-screen',
	Anchored = 'anchored'
}

export interface ModalOptions<Result> {
	guard?: boolean;
	update?: (result: Result) => void;
}

export interface ModalConfig<Data, Result> extends ModalOptions<Result> {
	type: Type<Modal<Data, Result>>;
	display: ModalDisplayType;
	data?: Data;
	position?: ModalAnchoredPosition;
	event?: Event;
	providers?: StaticProvider[];
	injector?: Injector;
	done: (result?: Result) => void;
}

export interface ModalConfirmData {
	title?: string;
	message?: string;
	confirmLabel?: string;
	cancelLabel?: string;
}

export interface ModalAlertData {
	title?: string;
	message?: string;
	confirmLabel?: string;
	closeButtonLabel?: string;
}

export const ModalData = new InjectionToken<any>('ModalData');

/*
 * Popup related interfaces
 * The values of the position interface are references to the edges of either
 * the clicked element
 * or the anchored with the the x and y referring the axis
 * eg: originX: 'left', originY: 'bottom'
 * refers to the bottom left corner of the clicked element
 */
export enum ModalPositionAlignment {
	Left = 'left',
	Right = 'right',
	Bottom = 'bottom',
	Top = 'top',
	Center = 'center',
	Fit = 'fit'
}

export interface ModalAnchoredPosition {
	target: HTMLElement;
	originX?: ModalPositionAlignment.Left | ModalPositionAlignment.Center | ModalPositionAlignment.Right | ModalPositionAlignment[];
	originY?: ModalPositionAlignment.Top | ModalPositionAlignment.Center | ModalPositionAlignment.Bottom | ModalPositionAlignment[];
	alignmentX?: ModalPositionAlignment.Left | ModalPositionAlignment.Center | ModalPositionAlignment.Right | ModalPositionAlignment[];
	alignmentY?: ModalPositionAlignment.Top | ModalPositionAlignment.Center | ModalPositionAlignment.Bottom | ModalPositionAlignment[];
}
