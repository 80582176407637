import { Injectable } from '@angular/core';

import { isDataDisplayAttachmentValue, isDataDisplayHrefValue, isDataDisplayMapMarkerValue } from '../../components';

import { DataDisplayComponentRegistry } from './data-display-component-registry';
import { UfDataDisplayTableComponentRegistry } from './uf-data-display-table-component-registry';

/** Define the display logic differences for content based table, compared to the standard table */
@Injectable()
export class UfDataDisplayContentTableComponentRegistry extends UfDataDisplayTableComponentRegistry implements DataDisplayComponentRegistry {

	override preDataTransform(data: unknown): unknown {

		if ((isDataDisplayHrefValue(data) || isDataDisplayAttachmentValue(data)) && data.displayAsLink == null) {
			data.displayAsLink = false;

			return data;
		}

		if (isDataDisplayMapMarkerValue(data)) {
			return data.label;
		}
		
		return super.preDataTransform(data);
	}

}
