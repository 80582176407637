import { Component, Input } from '@angular/core';

import { DataDisplayComponentType } from '../../../models';

import { DataDisplayPrimitiveValue, isDataDisplayPrimitiveValue } from './data-display-components-model';

@Component({
	selector: 'uf-data-display-primitive',
	templateUrl: './data-display-primitive.html',
	styleUrls: ['../data-display.less'],
})
export class DataDisplayPrimitiveComponent implements DataDisplayComponentType {
	
	@Input({ required: true }) data: DataDisplayPrimitiveValue;

	static isDataAccepted = isDataDisplayPrimitiveValue;
	
}
