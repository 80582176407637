<div class="uf-app-bar">
	<a (click)="close(false)" class="uf-action tertiary">
		<uf-icon name="close" />
	</a>
	<h3>Attention</h3>
</div>
<div class="uf-grid pad gaps">
	<div class="col-12">
		<uf-checkbox [(value)]="guard" label="Guard">
			<uf-help content="Disable modal close on outside click or 'esc'" />
		</uf-checkbox>
		<uf-datetime label="test" />
		<p>You can open dialogues inside dialogues</p>
	</div>
	<div class="col-12 uf-box inset pad">
		<div class="col space-children center-all">
			<button (click)="openFit()" class="uf-button" type="button">Open Responsive</button>
			<button (click)="openMedium()" class="uf-button" type="button">Open Medium</button>
			<button (click)="openLarge()" class="uf-button" type="button">Open Large</button>
		</div>
		<uf-datetime label="test" />
	</div>
</div>
<div class="uf-form-actions">
	<button (click)="close(false)" type="button" class="uf-button tertiary left">
		Cancel
	</button>
	<button (click)="close(false)" type="button" class="uf-button primary">
		Submit
	</button>
</div>