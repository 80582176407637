import { Component, Input } from '@angular/core';
import { Dictionary, FieldType, Option } from '@unifii/sdk';

import { ComponentRegistry } from '@unifii/library/smart-forms';
import { UfFormComponentRegistry } from '@unifii/library/smart-forms/input';

export interface ColumnInfo extends Option {
	level?: 'error' | 'info' | 'warning' | 'success';
	fieldType?: FieldType;
	placeholder?: string;
}

@Component({
	selector: 'sc-info-table',
	templateUrl: './show-info-table.html',
	providers: [{ provide: ComponentRegistry, useClass: UfFormComponentRegistry }],
})
export class ShowInfoTableComponent {

	@Input() columnConfig: ColumnInfo[] = [];
	@Input() rows: Dictionary<any>[] = [];
	@Input() cssClasses: string[] = [];

	fieldType = FieldType;

}
