import { Injectable, inject } from '@angular/core';
import { ClaimConfigFieldType, Company, DataType, SortDirections, UserInfo, UserStatus, amendUserAddStatusRemoveIsActiveUNIFII7598, isArrayOfType, isBoolean, isClaim, isDictionary, isHierarchyUnitsPath, isValueOfStringEnumType, mapClaimsToClaimsRecordUNIFII7599 } from '@unifii/sdk';

import { SEPARATOR_CR_LF, UserInfoIdentifiers } from '../../constants';
import { DataDisplayColour, DataDisplayInfo, DataDisplayInfoMultiChoice, DataDisplayListItem, DataPropertyDescriptor } from '../../models';
import { dataDescriptorToDataDisplayInfo } from '../data-descriptor';
import { DataLookupService } from '../data-lookup';

import { DataDisplayService } from './data-display.service';

/** @deprecated */
export type DataDisplaySpecificEntitySinglePropertyInfo = {
	overrideDataDisplayInfo?: Partial<DataDisplayInfo>;
}

/** @deprecated */
export type DataDisplaySpecificEntityInfo = {
	identifiersWhitelist?: string[];
	overrideDataDisplayInfo?: Record<string, Partial<DataDisplayInfo>>;
}

/**
 * Wrapper of DataDisplayService for specific entities
 * All entity specific display logic are centralized here
 * The goal is to replace this service in favour of:
 * - BE DataType descriptor for each entity type
 * - BE entity data changes to reflect entity usage (see claims, user status etc)
 * @deprecated
 */
@Injectable({ providedIn: 'root' })
export class DataDisplaySpecificEntitiesService {

	private dataLookupService = inject(DataLookupService);
	private dataDisplayService = inject(DataDisplayService);

	/**
	 * Transform a user's single property to its associated string format for display purposes
	 * @deprecated
	 * @param user - the UserInfo
	 * @param dataPropertyDescriptor - the descriptor of the property to be transformed
	 * @param displayInfo - optional configuration for the display system
	 * @returns - successful string value or null otherwise
	 */
	displayUserPropertyAsString(user: UserInfo, dataPropertyDescriptor: DataPropertyDescriptor | null | undefined, displayInfo?: DataDisplaySpecificEntitySinglePropertyInfo): string | null {
		if (!dataPropertyDescriptor) {
			return null;
		}

		return this.getUserPropertyAsValue(
			this.mapToAmendedUser(user, { [dataPropertyDescriptor.identifier]: dataPropertyDescriptor }),
			dataPropertyDescriptor,
			displayInfo?.overrideDataDisplayInfo,
		);
	}

	/**
	 * Transform a user's single property to its associated DescriptionListItem format for display purposes
	 * @deprecated
	 * @param user - the UserInfo
	 * @param dataPropertyDescriptor - the descriptor of the property to be transformed
	 * @param displayInfo - optional configuration for the display system
	 * @returns - successful DescriptionListItem or null otherwise
	 */
	displayUserPropertyAsDescriptionItem(user: UserInfo, dataPropertyDescriptor: DataPropertyDescriptor | null | undefined, displayInfo?: DataDisplaySpecificEntitySinglePropertyInfo): DataDisplayListItem | null {
		if (!dataPropertyDescriptor) {
			return null;
		}

		return this.getUserPropertyAsDescriptionItem(
			this.mapToAmendedUser(user, { [dataPropertyDescriptor.identifier]: dataPropertyDescriptor }),
			dataPropertyDescriptor,
			displayInfo?.overrideDataDisplayInfo,
		);
	}

	/**
	 * Transform a user to its associated list of DescriptionListItem for display purposes
	 * @deprecated
	 * @param user - the UserInfo
	 * @param dataPropertyDescriptors - the descriptors of the User properties
	 * @param displayInfo - optional configuration for the display system
	 * @returns - list of DescriptionListItem of the successfully transformed properties
	 */
	displayUserAsDescriptionItems(user: UserInfo, dataPropertyDescriptors: Map<string, DataPropertyDescriptor> | null | undefined, displayInfo?: DataDisplaySpecificEntityInfo): DataDisplayListItem[] {

		if (!dataPropertyDescriptors) {
			return [];
		}		

		const record = this.mapToAmendedUser(user, Object.fromEntries(dataPropertyDescriptors));

		if (!record) {
			return [];
		}

		const results: DataDisplayListItem[] = [];
		const identifiers = displayInfo?.identifiersWhitelist ?? Object.keys(record);

		for (const identifier of identifiers) {
			const dataPropertyDescriptor = dataPropertyDescriptors.get(identifier);
			
			if (!dataPropertyDescriptor) {
				continue;
			}
			
			const overrideDisplayInfo = displayInfo?.overrideDataDisplayInfo ? displayInfo.overrideDataDisplayInfo[identifier] : undefined;
			const item = this.getUserPropertyAsDescriptionItem(record, dataPropertyDescriptor, overrideDisplayInfo);
			
			if (item) {
				results.push(item);
			}
		}	

		return results;
	}

	/**
	 * Transform a company's single property to its associated string format for display purposes
	 * @deprecated
	 * @param company - the Company
	 * @param dataPropertyDescriptor - the descriptor of the property to be transformed
	 * @param displayInfo - optional configuration for the display system
	 * @returns - successful string value or null otherwise
	 */
	displayCompanyPropertyAsString(company: Company, dataPropertyDescriptor: DataPropertyDescriptor | null | undefined, displayInfo?: DataDisplaySpecificEntitySinglePropertyInfo): string | null {
		if (!dataPropertyDescriptor || !isDictionary(company)) {
			return null;
		}

		const identifier = dataPropertyDescriptor.identifier;
		const propertyDisplayInfo = dataDescriptorToDataDisplayInfo(dataPropertyDescriptor, displayInfo?.overrideDataDisplayInfo);
		const value = this.dataLookupService.lookupData(company as unknown as Record<string, unknown>, identifier);

		if (value == null || !propertyDisplayInfo) {
			return null;
		}

		return this.dataDisplayService.displayAsString(value, propertyDisplayInfo);
	}

	/**
	 * Transform a company's single property to its associated DescriptionListItem format for display purposes
	 * @deprecated
	 * @param company - the Company
	 * @param dataPropertyDescriptor - the descriptor of the property to be transformed
	 * @param displayInfo - optional configuration for the display system
	 * @returns - successful DescriptionListItem or null otherwise
	 */
	displayCompanyPropertyAsDescriptionItem(company: Company, dataPropertyDescriptor: DataPropertyDescriptor | null | undefined, displayInfo?: DataDisplaySpecificEntitySinglePropertyInfo): DataDisplayListItem | null {
		if (!dataPropertyDescriptor || !isDictionary(company)) {
			return null;
		}

		const identifier = dataPropertyDescriptor.identifier;
		const propertyDisplayInfo = dataDescriptorToDataDisplayInfo(dataPropertyDescriptor, displayInfo?.overrideDataDisplayInfo);
		const value = this.dataLookupService.lookupData(company as unknown as Record<string, unknown>, identifier);

		if (value == null || !propertyDisplayInfo) {
			return null;
		}

		propertyDisplayInfo.colour = this.getDisplayColour(identifier, value);

		const description = this.dataDisplayService.displayAsDataDisplayValue(value, propertyDisplayInfo);

		return description ? { term: dataPropertyDescriptor.label, data: description } : null;
	}

	/**
	 * Transform a company to its associated list of DescriptionListItem for display purposes
	 * @deprecated
	 * @param company - the Company
	 * @param dataPropertyDescriptors - the descriptors of the Company properties
	 * @param displayInfo - optional configuration for the display system
	 * @returns - list of DescriptionListItem of the successfully transformed properties
	 */
	displayCompanyAsDescriptionItems(company: Company, dataPropertyDescriptors: Map<string, DataPropertyDescriptor> | null | undefined, displayInfo?: DataDisplaySpecificEntityInfo): DataDisplayListItem[] {
		if (!dataPropertyDescriptors || !isDictionary(company)) {
			return [];
		}

		const results: DataDisplayListItem[] = [];
		const identifiers = displayInfo?.identifiersWhitelist ?? Object.keys(company);

		for (const identifier of identifiers) {
			const dataPropertyDescriptor = dataPropertyDescriptors.get(identifier);
			const overrideDataDisplayInfo = displayInfo?.overrideDataDisplayInfo ? displayInfo.overrideDataDisplayInfo[identifier] : undefined;
			const item = this.displayCompanyPropertyAsDescriptionItem(company, dataPropertyDescriptor, { overrideDataDisplayInfo });
			
			if (item) {
				results.push(item);
			}
		}	

		return results;
	}

	private getDisplayColour(identifier: string, value: unknown): DataDisplayColour | undefined {
		if (identifier === 'status' && isValueOfStringEnumType(UserStatus)(value)) {
			switch (value) {
				case UserStatus.Active:
					return 'success';
				case UserStatus.Inactive:
					return 'default';
				case UserStatus.Pending:
					return 'warning';
			}
		}

		if (identifier === `${UserInfoIdentifiers.IsMfaEnabled}` && isBoolean(value)) {
			return value ? 'success' : 'warning';
		}

		return undefined;
	}

	private mapToAmendedUser(user: UserInfo, dataPropertyDescriptors: Record<string, DataPropertyDescriptor>): Record<string, unknown> | undefined {
		
		if (!isDictionary(user)) {
			return undefined;
		}

		const record = structuredClone(user) as unknown as Record<string, unknown>;

		// Amend claims
		if (isArrayOfType(record.claims, isClaim)) {
			// isClaim guard guarantee that dataPropertyDescriptors used by mapClaimsToClaimsRecordUNIFII7599 are compatible with function inputs
			const claimsDataPropertyDescriptors = dataPropertyDescriptors as Record<string, DataPropertyDescriptor & { type: ClaimConfigFieldType}>;
			
			record.claims = mapClaimsToClaimsRecordUNIFII7599(record.claims, claimsDataPropertyDescriptors);
		}

		// Amend status
		amendUserAddStatusRemoveIsActiveUNIFII7598(record);

		return record;
	}

	private getUserPropertyAsValue(amendedUser: Record<string, unknown> | undefined, dataPropertyDescriptor: DataPropertyDescriptor | null | undefined, overrideDataDisplayInfo?: Partial<DataDisplayInfo>): string | null {
		if (!amendedUser || !dataPropertyDescriptor) {
			return null;
		}

		const identifier = dataPropertyDescriptor.identifier;
		let displayInfo = dataDescriptorToDataDisplayInfo(dataPropertyDescriptor, overrideDataDisplayInfo);
		let value = this.dataLookupService.lookupData(amendedUser, identifier);
				
		if (value == null || !displayInfo) {
			return null;
		}

		if (identifier === `${UserInfoIdentifiers.Manager}` && displayInfo.type === DataType.String) {
			displayInfo = { type: DataType.Manager, colour: displayInfo.colour };
		}

		if (identifier === `${UserInfoIdentifiers.Company}` && displayInfo.type === DataType.String) {
			displayInfo = { type: DataType.CompanyInfo, colour: displayInfo.colour };
		}

		if ([`${UserInfoIdentifiers.LastModifiedAt}`, `${UserInfoIdentifiers.CreatedAt}`].includes(identifier) && displayInfo.type === DataType.DateTime) {
			displayInfo = { type: DataType.OffsetDateTime, colour: displayInfo.colour, format: displayInfo.format };
		}

		if (identifier === `${UserInfoIdentifiers.Roles}`) {
			(displayInfo as DataDisplayInfoMultiChoice).sort = SortDirections.Descending;
			(displayInfo as DataDisplayInfoMultiChoice).separator = SEPARATOR_CR_LF;
		}

		if (identifier === `${UserInfoIdentifiers.UnitPaths}`) {
			displayInfo.type = DataType.HierarchyPaths;
			// guard empty HierarchyUnitsPath that are flagged as invalid by DataDisplay logic
			value = isHierarchyUnitsPath(value) ? value : undefined;
		}

		return this.dataDisplayService.displayAsString(value, displayInfo);
	}

	private getUserPropertyAsDescriptionItem(amendedUser: Record<string, unknown> | undefined, dataPropertyDescriptor: DataPropertyDescriptor | null | undefined, overrideDataDisplayInfo?: Partial<DataDisplayInfo>): DataDisplayListItem | null {
		if (!amendedUser || !dataPropertyDescriptor) {
			return null;
		}

		const identifier = dataPropertyDescriptor.identifier;
		const displayInfo = dataDescriptorToDataDisplayInfo(dataPropertyDescriptor, overrideDataDisplayInfo);
		const value = this.dataLookupService.lookupData(amendedUser, identifier);
		
		if (value == null || !displayInfo) {
			return null;
		}

		if (identifier === `${UserInfoIdentifiers.Roles}`) {
			(displayInfo as Record<string, unknown>).sort = SortDirections.Descending;
		}

		if (identifier === `${UserInfoIdentifiers.UnitPaths}`) {
			displayInfo.type = DataType.HierarchyPaths;
		}

		displayInfo.colour = this.getDisplayColour(identifier, value);

		const description = this.dataDisplayService.displayAsDataDisplayValue(value, displayInfo);

		return description ? { term: dataPropertyDescriptor.label, data: description } : null;
	}

}
