import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import html2canvas from 'html2canvas';

import { ClipboardService } from '@unifii/library/common';

@Component({
	selector: 'sc-playground',
	templateUrl: './show-playground.html',
	styleUrls: ['./show-playground.less'],
})
export class ShowPlaygroundComponent {

	@ViewChild('render') render: ElementRef;

	@Input() showConfiguration: boolean;

	@Output() applyConfiguration = new EventEmitter<void>();
	@Output() codeChange = new EventEmitter<string>();

	editorOptions = { theme: 'vs-dark', language: 'html' };
	template: string | null;

	@Input() set code(v: string) {
		this._code = v;
		this.onCodeChanged();
	}

	get code() {
		return this._code || '';
	}

	private _code: string;

	constructor(
		private clipboard: ClipboardService,
		private cdr: ChangeDetectorRef,
	) { }

	onCodeChanged() {
		this.template = null;
		this.cdr.detectChanges();
		this.template = `${this.code}`;
		this.cdr.detectChanges();
		this.codeChange.next(this.code);
	}

	applyToCode() {
		this.applyConfiguration.next();
	}

	reset() {
		this.code = '';
	}

	async copyDOM() {
		this.clipboard.setText(this.code);
	}

	async downloadImage() {
		const canvas = await html2canvas(this.render.nativeElement);
		const link = document.createElement('a');

		link.setAttribute('href', canvas.toDataURL('image/png'));
		link.setAttribute('download', 'rendered-component');
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

}
