<div [formGroup]="formGroup" [direction]="direction" [columns]="columns" [rows]="rows" [cellTotal]="options.length"
	[columnGap]="sizes.SM" [class.radio-button]="isButtonRadio" ufGrid>
	<uf-radio-input-wrapper *ngIf="unmatchedValue" [label]="unmatchedValue | nameProperty:nameProperty"
		[class.checked]="unmatchedValue === value" class="disabled">
		<input [formControlName]="groupName" [attr.name]="groupName" [value]="unmatchedValue" [attr.disabled]="true"
			radioInput type="radio" />
	</uf-radio-input-wrapper>

	<ng-container *ngFor="let option of options; let i = index">
		<uf-radio-input-wrapper [label]="option | nameProperty:nameProperty" [template]="template"
			[class.positive]="!!option.value" [class.negative]="!option.value" [class.disabled]="formGroup.disabled"
			[class.focused]="focused === i" [class.checked]="option === value">
			<input [formControlName]="groupName" [attr.name]="groupName" [value]="option" (focus)="focused = i"
				(blur)="focused = null" radioInput type="radio" />
		</uf-radio-input-wrapper>
	</ng-container>

</div>