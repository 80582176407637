<div [ngClass]="{ 'disabled': !submitted && !active, 'error': submitted && !valid, 'accent': (submitted && valid) || active }"
	class="uf-avatar">
	<ng-container *ngIf="submitted && !valid">
		!
	</ng-container>
	<uf-icon *ngIf="submitted && valid" name="tick" />
	<ng-container *ngIf="!submitted">
		{{ index + 1 }}
	</ng-container>
</div>
{{ label }}