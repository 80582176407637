import { Component, Input } from '@angular/core';

import { RuntimeField, SharedTermsTranslationKey } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-file-list-display',
	templateUrl: './file-list.html',
})
export class FileListDisplayComponent implements ContentField {

	@Input() content: any[];
	@Input() field: RuntimeField;

	readonly sharedTermsTK = SharedTermsTranslationKey;

}
