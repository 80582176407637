import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import SignaturePad from 'signature_pad';

import { UfControl } from '../../controls';
import { SharedTermsTranslationKey } from '../../translations';

import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-signature',
	templateUrl: './uf-signature.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfSignatureComponent, multi: true,
	}],
	styleUrls: ['./uf-signature.less'],
})
export class UfSignatureComponent extends UfControlValueAccessor<string> implements OnInit, OnDestroy {

	@ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;

	@Input() suffix: string;
	@Input() label?: string | null;
	@Output() override valueChange = new EventEmitter();

	readonly sharedTermsTK = SharedTermsTranslationKey;

	private signaturePad?: SignaturePad;
	private _locked: boolean;
	private prevStatus: string;
	private hiddenControlSubscription?: Subscription;
	private onEndStrokeBoundReference: () => void;

	ngOnInit() {
		this.signaturePad = new SignaturePad(this.canvas.nativeElement, {});
		this.onEndStrokeBoundReference = this.onEndStroke.bind(this);
		this.signaturePad.addEventListener('endStroke', this.onEndStrokeBoundReference);

		this.reflectEnabledStatusToSignaturePad();

		if (this.signaturePad.isEmpty() && this.value != null) {
			this.load();
		}
	}

	override ngOnDestroy() {

		super.ngOnDestroy();

		this.hiddenControlSubscription?.unsubscribe();
		this.signaturePad?.removeEventListener('endStroke', this.onEndStrokeBoundReference);
		this.signaturePad?.off();
	}

	@Input() override set control(v: UfControl) {
		super.control = v;

		this.setHiddenControlSubscription();
	}

	override get control() {
		return super.control;
	}

	@Input() override set value(v: string | null | undefined) {
		super.value = v;
		this.load();
	}

	override get value(): string | null | undefined {
		return super.value;
	}

	@Input() override set disabled(v: boolean) {
		super.disabled = v;
		this.reflectEnabledStatusToSignaturePad();
	}

	override get disabled(): boolean {
		return super.disabled;
	}

	set locked(v: boolean) {
		this._locked = v;
		this.reflectEnabledStatusToSignaturePad();
	}

	get locked(): boolean {
		return this._locked;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	clear() {
		this.signaturePad?.clear();
		this.control.setValue(undefined);
	}

	private reflectEnabledStatusToSignaturePad() {

		// Guard while signature pad is setup
		if (!this.signaturePad) {
			return;
		}

		if (this.disabled || this._locked) {
			this.signaturePad.off();
		} else {
			this.signaturePad.on();
		}
	}

	private load() {
		if (!this.signaturePad) {
			return;
		}
		// You have to manually update the canvas
		// to handle high ratio pixel devices
		this.signaturePad.clear();

		if (!this.value) {
			return;
		}

		const image = new Image();
		const width = this.canvas.nativeElement.width;
		const height = this.canvas.nativeElement.height;

		image.src = this.value;
		image.onload = () => {
			this.canvas.nativeElement.getContext('2d')?.drawImage(image, 0, 0, width, height);
		};

		// Even after you sign, the property _isEmpty is still true
		// That's why we need to set the value.
		(this.signaturePad as any)._isEmpty = false;
	}

	private onEndStroke() {
		const dataUri = this.getDataURI();

		if (dataUri) {
			this.updateControl(dataUri);
		}
	}

	private updateControl(value: string) {

		if (this.disabled || this.locked) {
			return;
		}

		if (!this.control.touched) {
			this.control.markAsTouched();
		}

		if (!this.control.dirty) {
			this.control.markAsDirty();
		}

		// Even after you sign, the property _isEmpty is still true
		// That's why we need to set the value.
		if (this.signaturePad) {
			(this.signaturePad as any)._isEmpty = false;
		}

		this.control.setValue(value);
	}

	/**
	 * @description
	 * Uses signature_pads api canvas dataUri
	 * anything under 1700 is not legible on the canvas so will be ignored
	 */
	private getDataURI(): string | undefined {
		const dataUri = this.signaturePad?.toDataURL();

		if ((dataUri?.length ?? 0) >= 1700) {
			return dataUri;
		}

		return undefined;
	}

	/**
	 * @description
	 * Creates subscription to observe when control changes status
	 * so we can turn on/off the signature pad when the component is changing between hidden and visible
	 * This specific status is managed at form-field-service
	 */
	private setHiddenControlSubscription() {

		this.hiddenControlSubscription?.unsubscribe();

		if (!(this.control as UfControl | null)) {
			return;
		}

		this.hiddenControlSubscription = this.control.statusChanges.subscribe((status) => {
			if (status === this.prevStatus) {
				return;
			}

			if (status !== 'DISABLED') {
				this.signaturePad?.on();
			} else {
				this.signaturePad?.off();
			}

			this.prevStatus = status;
		});
	}

	// Signature API
	// // Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible parameters)
	// signaturePad.toDataURL(); // save image as PNG
	// signaturePad.toDataURL("image/jpeg"); // save image as JPEG

	// // Draws signature image from data URL
	// signaturePad.fromDataURL("data:image/png;base64,iVBORw0K...");

	// // Clears the canvas
	// signaturePad.clear();

	// // Returns true if canvas is empty, otherwise returns false
	// signaturePad.isEmpty();

	// // Unbinds all event handlers
	// signaturePad.off();

	// // Rebinds all event handlers
	// signaturePad.on();

}
