import { InjectionToken } from '@angular/core';
import { Dictionary, UserContext } from '@unifii/sdk';

/** Extendable for custom context */
export interface AppContext extends Dictionary<any> {
	user?: UserContext;
}

export interface ContextProvider {
	get: () => AppContext;
}

export const ContextProvider = new InjectionToken<ContextProvider>('ContextProvider');
