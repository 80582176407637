<!-- Tabs -->

<div class="header">
	<ng-template [ngForOf]="tabs" let-tab ngFor>
		<!-- Route -->
		<ng-template [ngIf]="isRoute" [ngIfElse]="content">
			<a [routerLink]="tab.routerLink" [ngClass]="{'primary': tab.active, 'tertiary': !tab.active}"
				class="uf-button">
				{{tab.label}}
				<uf-icon *ngIf="tab.icon" [name]="tab.icon" />
			</a>
		</ng-template>
		<!-- Content -->
		<ng-template #content>
			<button [ngClass]="{'primary': tab.active, 'tertiary': !tab.active}" (click)="select(tab)" type="button"
				class="uf-button">
				{{tab.label}}
				<uf-icon *ngIf="tab.icon" [name]="tab.icon" />
			</button>
		</ng-template>
	</ng-template>
</div>

<uf-dropdown-tabs *ngIf="responsive" [tabs]="tabs" [active]="active" (activeChange)="select($event)"
	class="expander-component" />

<div class="content">
	<!-- Router tab content holder -->
	<ng-template [ngIf]="isRoute">
		<router-outlet />
	</ng-template>
	<!-- Content tab content holder -->
	<ng-template [ngIf]="!isRoute">
		<ng-content></ng-content>
	</ng-template>
</div>