<div *ngIf="type === spinnerTypes.Square"
  [ngClass]="{ 'spinner-square' : type === spinnerTypes.Square, 'spinner-default' : !type }"
  [style.background-color]="type === spinnerTypes.Square ? colour : 'transparent'"
  [style.border-left-color]="!type && !!colour ? colour : !type ? '#ddd' : 'transparent'" class="spinner">
</div>
<div *ngIf="type === spinnerTypes.Dots" class="spinner-dots">
  <div [style.background-color]="colour" class="dot1"></div>
  <div [style.background-color]="colour" class="dot2"></div>
</div>
<div *ngIf="type === spinnerTypes.Grid" class="spinner-grid">
  <div [style.background-color]="colour" class="grid-cube grid-cube1"></div>
  <div [style.background-color]="colour" class="grid-cube grid-cube2"></div>
  <div [style.background-color]="colour" class="grid-cube grid-cube3"></div>
  <div [style.background-color]="colour" class="grid-cube grid-cube4"></div>
  <div [style.background-color]="colour" class="grid-cube grid-cube5"></div>
  <div [style.background-color]="colour" class="grid-cube grid-cube6"></div>
  <div [style.background-color]="colour" class="grid-cube grid-cube7"></div>
  <div [style.background-color]="colour" class="grid-cube grid-cube8"></div>
  <div [style.background-color]="colour" class="grid-cube grid-cube9"></div>
</div>