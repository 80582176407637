<table class="uf-table">
	<tbody>
		<!-- DataSeed visible mappings -->
		<tr *ngFor="let visibleLabel of dataSeedVisibleLabels">
			<th class="data-seed-label">
				{{visibleLabel.label}}
			</th>
			<td *ngFor="let visibleItem of dataSeedsVisibleItems, let i = index" [class.hover]="hover[i]"
				(mouseenter)="hover[i] = true" (mouseleave)="hover[i] = false" (click)="clicked(i)">
				<uf-data-display *ngFor="let data of visibleItem[visibleLabel.to] ?? []" [data]="data" />
			</td>
		</tr>
		<!-- Repeat fields -->
		<ng-container *ngFor="let child of field.fields">
			<tr *ngIf="isVisible(child)">
				<th>
					{{ child.shortLabel || child.label }}
					<span *ngIf="requiredSuffix || optionalSuffix" [ngSwitch]="child.isRequired" class="suffix">
						<ng-container *ngSwitchCase="true">
							{{ requiredSuffix }}
						</ng-container>
						<ng-container *ngSwitchDefault>
							{{ optionalSuffix }}
						</ng-container>
					</span>
					<uf-help *ngIf="child?.help" [content]="child?.help" />
				</th>
				<ng-container *ngFor="let manager of scopeManagers, let i = index">
					<td [field]="child" [scope]="manager.scope" [scopeManager]="manager" [class.hover]="hover[i]"
						[style.width]="100/(scopeManagers.length)+'%'" (click)="clicked(i)"
						(mouseenter)="hover[i] = true" (mouseleave)="hover[i] = false" uf-repeat-cell>
					</td>
				</ng-container>

			</tr>
		</ng-container>
		<tr *ngIf="!disabled">
			<th></th>
			<td *ngFor="let manager of scopeManagers, let i = index" [class.hover]="hover[i]"
				(mouseenter)="hover[i] = true" (mouseleave)="hover[i] = false" class="no-padding delete-cell">
				<button [title]="sharedTermsTK.ActionRemove | translate" (click)="removeAt(i)" type="button"
					class="uf-action tertiary small">
					<uf-icon name="delete" />
				</button>
			</td>
		</tr>
	</tbody>
</table>