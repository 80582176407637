import { Component, ViewChild } from '@angular/core';
import { Option } from '@unifii/sdk';

import { UfControl, UfMarkdownEditorComponent, ValidatorFunctions } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-markdown-editor.html',

})
export class ShowMarkdownEditorComponent {

	@ViewChild(UfMarkdownEditorComponent, { static: true }) markdownEditor: UfMarkdownEditorComponent;

	readonly sizeOptions: Option[] = [
		{ name: 'Medium (default)', identifier: '' },
		{ name: 'Small', identifier: 'small' },
	];

	readonly previewOptions: Option[] = [
		{ name: 'Disable (default)', identifier: 'false' },
		{ name: 'Enabled', identifier: 'true' },
	];

	readonly inputTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'default', name: 'Default' },
		{ identifier: 'description', name: 'Description' },
	];

	readonly inputTableInfo = [
		{ name: 'control', type: 'UfControl', default: 'new UfControl()', description: 'A control object to manage validators, states etc.' },
		{ name: 'id', type: 'string', default: 'undefined', description: 'Sets id attribute of inner input element' },
		{ name: 'name', type: 'string', default: 'undefined', description: 'Sets name attribute of inner input element' },
		{ name: 'label', type: 'string', default: 'undefined', description: 'Sets label' },
		{ name: 'placeholder', type: 'string', default: 'undefined', description: 'Sets placeholder' },
		{ name: 'maxLength', type: 'number | string', default: 'undefined', description: 'Sets max character length of input' },
		{ name: 'preview', type: 'boolean', default: 'undefined', description: 'Enables preview toggle' },
		{ name: 'compact', type: 'boolean', default: 'undefined', description: 'Enables compact view' },
	];

	readonly textInputTableInfo = [
		{ name: 'autocomplete', type: 'number | string', default: 'Math.random().toString(36).substr(2, 18);', description: 'Sets autocomplete name for the input' },
	];

	readonly outputTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];

	readonly outputTableInfo = [
		{ name: 'valueChange', type: 'EventEmitter', description: 'Emits an event when the value of the input is changed' },
	];

	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];

	readonly cssTableInfo = [
		{ name: 'default', type: 'modifier', description: 'Default input size' },
	];

	control = new UfControl(ValidatorFunctions.required('Is required'));

	size = '';

	preview = 'true';

	value = '# Heading 1\n## Heading 1\n### Heading 3\n#### Heading 4\n##### Heading 5\nParagraph Text\n\n**Bold**\n\n*Italic*\n\n[Link](https://www.unifii.com.au)\n\nThings at Narrabeen Lagoon: \n- Ducks\n- Range Rovers\n\nHow to pick up a duck:\n1. Find a duck.\n1. Get in front of the duck.\n1. Introduce yourself and explain your intentions.\n1. Place your hand underneath the duck, Palm side up.\n1. Pick up the duck.\n\n```\n{\n"CodeBlock": "example"\n}\n```\ninline `codeblock` example\n> Unifii is great - Joel\n';

	component = 'uf-markdown-editor';

	template = `
		<uf-markdown-editor label="Text"></uf-text>
	`;

	modifierChange() {
		this.template = `<${this.component} label="Markdown Editor"${this.size ? ' class="' + this.size + '"' : ''}></${this.component}>`;
	}

	addCustom() {

		if (!this.markdownEditor) {
			return;
		}

		this.markdownEditor.insertCustom('[CUSTOM]');
	}

}
