<table class="uf-table">
	<thead>
		<tr>
			<th *ngFor="let visibleLabel of dataSeedVisibleLabels" class="data-seed-label">
				{{visibleLabel.label}}
			</th>
			<ng-template [ngForOf]="field.fields" let-child ngFor>
				<ng-template [ngIf]="isVisible(child)">
					<th>
						{{ child.shortLabel || child.label }}
						<span *ngIf="requiredSuffix || optionalSuffix" [ngSwitch]="child.isRequired" class="suffix">
							<ng-container *ngSwitchCase="true">
								{{ requiredSuffix }}
							</ng-container>
							<ng-container *ngSwitchDefault>
								{{ optionalSuffix }}
							</ng-container>
						</span>
						<uf-help *ngIf="child?.help" [content]="child?.help" />
					</th>
				</ng-template>
			</ng-template>
			<th class="sticky-cell"></th>
		</tr>
	</thead>

	<tbody>
		<!-- TODO check if seeds are required -->
		<tr *ngFor="let scope of items; let i = index" [field]="field" [scope]="scope" [hiddenColumns]="hiddenColumns"
			[dataSeedVisibleLabels]="dataSeedVisibleLabels" (remove)="remove.emit(i)" (click)="rowClick.emit(i)"
			uf-repeat-table-row>
		</tr>
	</tbody>

</table>