<uf-step [control]="stepControl" [key]="field" [validate]="field.parent?.isRequired === true">
	<ng-container content>
		<div class="content-wrap">
			<div *ngIf="isNested" class="uf-app-bar">
				<div class="title">{{field.parent?.parent?.label}}
					<uf-help *ngIf="field.parent?.parent?.help; let help" [content]="help" icon="infoSolid"
						class="grow" />
				</div>
			</div>
			<div class="uf-app-bar">
				<div class="title">{{field.parent?.label}}
					<uf-help *ngIf="field.parent?.help; let help" [content]="help" icon="infoSolid" class="grow" />
				</div>
			</div>
			<div class="uf-app-bar">
				<div class="title">{{field.label}}
					<uf-help *ngIf="field.help" [content]="field.help" icon="infoSolid" class="grow" />
				</div>
			</div>
			<!-- Delay rendering fields until _lastViewAt is set to ensure autofill's run as late as possible -->
			<div *ngIf="scope[identifier]?._lastViewedAt" class="grid pad-none-sides">
				<ng-container *ngFor="let child of field.fields">
					<ng-container *ngIf="isVisible(child)">
						<ng-template [ngIf]="child.identifier" [ngIfElse]="nonContent">
							<uf-field [field]="child" [scope]="scope" [(content)]="scope[child.identifier]" />
						</ng-template>
						<ng-template #nonContent>
							<uf-field [field]="child" [scope]="scope" />
						</ng-template>
						<uf-break-after *ngIf="child.breakAfter" />
					</ng-container>
				</ng-container>
			</div>
		</div>
	</ng-container>
</uf-step>