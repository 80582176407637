<sc-page-content width="xl" identifier="data-type-detector">
	<ng-container editor>
		<div class="uf-form-card">
			<div class="uf-app-bar">
				<h3>Data</h3>
			</div>
			<div class="uf-grid pad gaps">
				<uf-textarea [(value)]="raw" label="JSON object" class="col-12" />
				<uf-text [(value)]="lookupExpression" label="Lookup Expression" class="col-12" />
			</div>
			<div class="uf-form-actions">
				<button (click)="lookup()" class="uf-button" type="button">Lookup</button>
				<button (click)="detect()" class="uf-button" type="button">Detect</button>
			</div>
		</div>

		<uf-message *ngIf="error" class="error">
			<h3>{{ error }}</h3>
		</uf-message>

		<div class="uf-form-card">
			<div *ngIf="lookupResult" class="uf-grid pad gaps">
				<div class="col-2">
					{{lookupResult.type}}
				</div>
				<div class="col-6">
					{{lookupResult.data | json}}
				</div>
				<div class="col-4">
					{{lookupResult.display}}
				</div>
			</div>

			<table *ngIf="result.length" class="uf-table">
				<thead>
					<tr>
						<th>#</th>
						<th>Identifier</th>
						<th>Type</th>
						<th>Data</th>
						<th>Display</th>
					</tr>
				</thead>
				<tbody>
					<ng-template [ngForOf]="result" let-entry let-i="index" ngFor>
						<tr>
							<td>{{i}}</td>
							<td>{{entry.identifier}}</td>
							<td>{{entry.type}}</td>
							<td><uf-help content="{{entry.value | json}}" /></td>
							<td>{{display(entry.identifier, entry.value, entry.type)}}</td>
						</tr>
					</ng-template>
				</tbody>
			</table>
		</div>

	</ng-container>
</sc-page-content>