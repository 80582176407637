import { Component, Inject, Input } from '@angular/core';
import { DataType, FieldType, ImageProfile, PublishedContent } from '@unifii/sdk';

import { RuntimeField } from '@unifii/library/common';

import { FieldTableRow } from '../../models';

/**
 * Used to display simple tables not responsive comes with some CSS modifiers
 */
@Component({
	selector: 'uf-field-table',
	styleUrls: ['./field-table.less'],
	templateUrl: 'field-table.html',
})
export class FieldTableComponent {

	@Input() heading: string | null | undefined;
	@Input({ required: true }) columns: RuntimeField[];
	@Input({ required: true }) rows: FieldTableRow[];

	protected readonly fieldTypes = FieldType;
	protected readonly dataTypes = DataType;
	protected cssClass = '';

	private _cssModifiers: string[];
	private readonly _cssClassPrefix = 'uf-table__container--';

	constructor(@Inject(PublishedContent) private contentService: PublishedContent) { }

	protected get ready(): boolean {
		return !!(this.rows.length && this.columns.length);
	}

	get cssModifiers() {
		return this._cssModifiers;
	}

	@Input() set cssModifiers(cssModifiers: string[] | null | undefined) {
		if (!cssModifiers) {
			return;
		}
		
		let cssClass = '';

		for (const name of cssModifiers) {
			cssClass += ' ' + this._cssClassPrefix + name;
		}

		this.cssClass = cssClass;
	}

	protected getImageUrl(imageProfile: ImageProfile) {
		return this.contentService.buildImageUrl(imageProfile, { width: 150 });
	}

}

