import { Component, Input, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DataDisplayComponentType, DataDisplayItems } from '../../../models';
import { CommonTranslationKey } from '../../../translations';

import { DataDisplayDataSeedValue, isDataDisplayDataSeedValue } from './data-display-components-model';

@Component({
	selector: 'uf-data-display-data-seed',
	templateUrl: './data-display-data-seed.html',
	styleUrls: ['../data-display.less', './data-display-data-seed.less'],
})
export class DataDisplayDataSeedComponent implements DataDisplayComponentType {
	
	static isDataAccepted = isDataDisplayDataSeedValue;
	
	protected readonly commonTK = CommonTranslationKey;
	protected display: string;
	protected dataDisplayVisibleItems: DataDisplayItems;

	private translate = inject(TranslateService);

	@Input({ required: true }) set data(v: DataDisplayDataSeedValue) {
		this.display = v.display ?? this.translate.instant(CommonTranslationKey.LabelNothingSelected) as string;
		this.dataDisplayVisibleItems = v.visibleItems.map((item) => item.item);
	}
	
}
