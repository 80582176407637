export * from './containers';
export * from './inputs';
export * from './modals';
export * from './repeats';

import { UF_INPUT_FIELD_CONTAINER_COMPONENTS } from './containers';
import { UF_INPUT_FIELD_INPUT_COMPONENTS } from './inputs';
import { UF_INPUT_FIELD_MODAL_COMPONENTS } from './modals';
import { UF_INPUT_FIELD_REPEAT_COMPONENTS } from './repeats';

export const UF_INPUT_COMPONENTS = [
	...UF_INPUT_FIELD_CONTAINER_COMPONENTS,
	...UF_INPUT_FIELD_INPUT_COMPONENTS,
	...UF_INPUT_FIELD_MODAL_COMPONENTS,
	...UF_INPUT_FIELD_REPEAT_COMPONENTS,
];
