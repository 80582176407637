<div class="page-container">
	<uf-panel #panel class="container bg-gray">
		<div class="form-wrapper">
			<div #headerWrapper class="header-wrapper">
				<ng-content select="[header]" />
				<!-- Label and seqId -->
				<h3 *ngIf="formService.configuration.hideLabel !== true">
					{{definition.label}}
					<small [formData]="formData" formDataLabel>
					</small>
				</h3>
			</div>

			<ng-container #formOutlet />

			<ng-template #formTemplate>
				<div *ngIf="definition" class="field-renderer grid--no-padding">
					<ng-template [ngForOf]="definition.fields" let-field ngFor>
						<ng-container *ngIf="isVisible(field)">
							<ng-template [ngIf]="field.identifier" [ngIfElse]="nonContent">
								<uf-field [field]="field" [scope]="formData" [(content)]="formData[field.identifier]" />
							</ng-template>
							<ng-template #nonContent>
								<uf-field [field]="field" [scope]="formData" />
							</ng-template>
							<uf-break-after *ngIf="field.breakAfter" />
						</ng-container>
					</ng-template>
				</div>

				<div #positionReference></div>
				<uf-workflow *ngIf="positionReference" [positionReference]="positionReference"
					(cancelClick)="onCancel()" (submitted)="onSubmit()" />

			</ng-template>

			<ng-content select="[footer]" />
		</div>

		<div *ngIf="navService.navItems && navService.navItems.length" class="desktop-nav-wrapper">
			<uf-nav [navItems]="navService.navItems" (selected)="navService.navigateTo($event)" />
		</div>

		<div class="horizontal-spacer"></div>

	</uf-panel>

	<uf-drawer *ngIf="navService.navItems && navService.navItems.length" [showHandle]="true" class="mobile-nav-wrapper">
		<uf-nav [navItems]="navService.navItems" (selected)="navService.navigateTo($event)" />
	</uf-drawer>
</div>