import { DataSeed, DataType } from '@unifii/sdk';

import { DataDisplayService, DataSourceDisplayTo, SourceConfig, SourceConfigMapping, UfSourceConfigMappingsPipe, isDataDisplayDataSeedValue } from '@unifii/library/common';

/** Return the SourceConfigMappings involved in the Repeat rows/columns associated with the DataSeed (for Repeat with DS) */
export const getDataSeedVisibleLabels = (sourceConfig: SourceConfig, sourceConfigMappingsPipe: UfSourceConfigMappingsPipe): SourceConfigMapping[] => {
	const mappingsInfo = sourceConfigMappingsPipe.transform(sourceConfig);

	if (!mappingsInfo) {
		return [];
	}

	const mappingsVisibles = [...mappingsInfo.mappingsVisibles];
	const mappingDisplay = mappingsInfo.mappingsTo[DataSourceDisplayTo];

	// Alter the visible mappings only when an explicit request is made for _display
	if (mappingDisplay && !mappingsVisibles.find((mapping) => mapping.to === DataSourceDisplayTo)) {
		mappingsVisibles.splice(1, 0, mappingDisplay);
	}

	return mappingsVisibles;
};

/** Return the DataDisplayValue(s) of a DataSeed to render (in Repeat with DS) */
export const getDataSeedVisibleItems = (dataSeed: DataSeed, sourceConfig: SourceConfig, dataSeedVisibleLabels: SourceConfigMapping[], dataDisplayService: DataDisplayService): Record<string, unknown[]> | undefined => {
	const dataSeedDisplayValue = dataDisplayService.displayAsDataDisplayValue(
		dataSeed,
		{
			type: DataType.DataSeed,
			sourceConfig,
			showDisplayVisibleField: true,
		},
	);

	if (!isDataDisplayDataSeedValue(dataSeedDisplayValue)) {
		return;
	}

	const result: Record<string, unknown[]> = {};

	for (const label of dataSeedVisibleLabels) {
		const match = dataSeedDisplayValue.visibleItems.find((visibleItem) => visibleItem.mapping.to === label.to);
		
		if (match) {
			result[label.to] = Array.isArray(match.item.data) ? match.item.data : [match.item.data];
		}
	}

	return result;
};
