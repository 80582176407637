import { AfterViewInit, Component, HostBinding, NgZone, OnDestroy, inject } from '@angular/core';
import { Subscription } from 'rxjs';

import { CommonTranslationKey } from '../../translations';

import { ActionMultiplicity, TableAction, TableRow } from './table-models';
import { TableComponent } from './table.component';

@Component({
	selector: 'uf-table-toolbar',
	templateUrl: './table-toolbar.html',
	styleUrls: ['./table-toolbar.less'],
})
export class TableToolbarComponent<T> implements AfterViewInit, OnDestroy {

	@HostBinding('class') classAttribute = 'uf-app-bar';
	@HostBinding('style.display') display = 'none';

	readonly commonTK = CommonTranslationKey;

	protected toolbarActions: TableAction<T>[] = [];
	protected inProgress: boolean;
	protected table = inject(TableComponent<T>);

	private ngZone = inject(NgZone);
	private subscriptions = new Subscription();

	ngAfterViewInit() {
		this.subscriptions.add(this.table.selectionChange.subscribe(() => { this.update(); }));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected onAction(action: TableAction<T>) {
		// Guaranteed detection of mutations made within the action callback
		void this.ngZone.run(async() => {
			if (!action.action) {
				return;
			}

			const selectedRows = this.selectedRows;

			if (!selectedRows.length) {
				return;
			}

			this.inProgress = true;

			try {
				if (action.multiplicity === ActionMultiplicity.Single) {
					// Apply action to single row
					const firstRow = selectedRows[0];

					if (!firstRow) {
						console.warn('TableToolbarComponent no row selected for Single action');
					} else {
						await action.action(firstRow.context);
					}
				} else {
					// Apply action to multiple rows
					await action.action(selectedRows.map((row) => row.context));
				}

			} catch (e) {
				// Fail silently
			} finally {
				this.inProgress = false;
			}
		});
	}

	private update() {

		// Display
		this.display = this.table.status.selected.length ? 'flex' : 'none';

		// Actions
		const rows = this.selectedRows;
		const firstRow = rows[0] as TableRow<T> | undefined;

		if (!firstRow) {
			this.toolbarActions = [];

			return;
		}

		this.toolbarActions = firstRow.memoize.availableActions?.filter((action) => {
			// Guard correct multiplicity
			if (
				(action.multiplicity === ActionMultiplicity.Single && rows.length > 1) ||
				(action.multiplicity === ActionMultiplicity.Multiple && rows.length === 1)
			) {
				return false;
			}

			// Verify the action is available in every selected row (skip first row cause it's self)
			return rows.every((row, index) => !index || row.memoize.availableActions?.find((availableAction) => availableAction.action === action.action));
		}) ?? [];
	}

	private get selectedRows() {
		return this.table.status.selected
			.map((item) => this.table.getRow(this.table.indexOf(item)))
			.filter((row) => !!row) as TableRow<T>[];
	}

}
