import { FieldType, coerceDataToTarget } from '@unifii/sdk';

import { ExpressionParser, RuntimeField, runtimeFieldToCoerceDataDescriptor } from '@unifii/library/common';

import { FormField } from '../models';

import { ScopeManager } from './scope-manager';

export class FieldAutofillManager {

	constructor(
		private component: FormField,
		private field: RuntimeField,
		private scopeManager: ScopeManager,
		private expressionParser: ExpressionParser,
	) { }

	/**
	 * Return the value of the autofill expression (FieldType.Hierarchy and DataSource fields are not coerced)
	 */
	getValue(): unknown {
		const expressionValue = this.expressionParser.resolve(
			this.field.autofill ?? '',
			this.scopeManager.createContext(),
			this.component.scope,
			'Autofill',
		);

		if (this.field.type === FieldType.Hierarchy || this.field.sourceConfig) {
			return expressionValue;
		}

		return coerceDataToTarget(expressionValue, runtimeFieldToCoerceDataDescriptor(this.field));
	}

}
