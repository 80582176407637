import { EventEmitter, InjectionToken, Type } from '@angular/core';
import { AstNode, CostModelFormat, DataSeed, Dictionary, FieldType, HierarchyUnitFormData, OperatorComparison, Option, SchemaHierarchyConfiguration, ZonedDateTime } from '@unifii/sdk';

import { Context, Scope } from './expression-interfaces';

export enum FilterType {
	Text = 'Text',
	TextArray = 'TextArray',
	// eslint-disable-next-line id-denylist
	Number = 'Number',
	NumberRange = 'NumberRange',
	// eslint-disable-next-line @typescript-eslint/no-shadow
	HierarchyUnit = 'HierarchyUnit',
	Choice = 'Choice',
	Bool = 'Bool',
	// eslint-disable-next-line @typescript-eslint/no-shadow
	DataSeed = 'DataSeed',
	DataSeedArray = 'DataSeedArray',
	OptionArray = 'OptionArray',
	Cost = 'Cost',
	Time = 'Time',
	Date = 'Date',
	Datetime = 'Datetime',
	// eslint-disable-next-line @typescript-eslint/no-shadow
	ZonedDatetime = 'ZonedDatetime',
	TimeRange = 'TimeRange',
	DateRange = 'DateRange',
	DatetimeRange = 'DatetimeRange',
	ZonedDatetimeRange = 'ZonedDatetimeRange',
	User = 'User',
	UserStatus = 'UserStatus',
	Company = 'Company'
}

export interface FilterEntry {
	type: FilterType; // Change to DataType
	identifier: string;
	label: string;
	inputType?: FieldType; // Override the FilterType default inputType
	queryIdentifier?: string; // Override default identifier for AstNode and RQL
	queryOperator?: OperatorComparison; // Override default operator for AstNode and RQL
	options?: Option[];
	loader?: FilterLoader;
	hierarchyConfig?: SchemaHierarchyConfiguration;
	searchMinLength?: number; // For loader based input
	format?: string; // Override the filter value displayed default format
}

export const FilterEntries = new InjectionToken<FilterEntry[]>('FilterEntries');

// Filter Values
export interface NumberRange {
	from?: number;
	to?: number;
}

// Date and DateTime formats
export interface TempoRange {
	from?: string;
	to?: string;
}

export interface ZonedTempoRange {
	from?: ZonedDateTime;
	to?: ZonedDateTime;
}

export type FilterValue = null | string | number | ZonedDateTime | DataSeed | string[] | DataSeed[] | Option[] | CostModelFormat | HierarchyUnitFormData[] | NumberRange | TempoRange | ZonedTempoRange;

export interface FilterLoader {
	getOptions: (context?: Context, scope?: Scope) => Promise<DataSeed[]>;
	search: (query?: string, context?: Context, scope?: Scope, signal?: AbortSignal) => Promise<DataSeed[]>;
	get: (id: string) => Promise<DataSeed | null>;
	mapToSeed: (value: any) => DataSeed | null;
}

export enum FilterViewMode {
	Input = 'Input',
	Display = 'Display'
}

/** Component rendering the filter for input and display mode */
export interface IFilterComponent {
	value: FilterValue ; // TODO use FilterValue and generic type
	label: string;
	valueChange: EventEmitter<any>; // TODO user FilterValue and generic type
	mode: FilterViewMode;
	isEmpty: boolean; // the component has an empty value
	loader?: FilterLoader;
	entry?: FilterEntry;
}

export interface FilterManagerProvider<V extends FilterValue, E extends FilterEntry> {
	entries: E[];
	clean: (values: Dictionary<V>) => Dictionary<V>;
}

/**
 * @description
 * Filter Component Registry used to register filter input components
 */
export interface FilterComponentRegistry<T extends FilterEntry> {
	get(entry: T): Type<IFilterComponent> | undefined;
}

export const FilterComponentRegistry = new InjectionToken<FilterComponentRegistry<FilterEntry>>('FilterRegistry');

/**
 * @description
 * Filter Serializer used for stringify filter values for use in URL's
 */
export interface FilterSerializer<V extends FilterValue, E extends FilterEntry> {
	serialize(value: V, entry: FilterEntry): string | null;
	deserialize(value: string | null, entry: E): Promise<V>;
	serializeAll(values: Dictionary<V>, entries: E[]): Dictionary<string | null>;
	deserializeAll(values: Dictionary<string | null>, entries: E[]): Promise<Dictionary<V>>;
}

export const FilterSerializer = new InjectionToken<FilterSerializer<FilterValue, FilterEntry>>('FilterSerializer');

/**
 * @description
 * Filter to AstNode Adapter used to convert a filter value to an ast node
 */
export interface FilterAstNodeAdapter<V extends FilterValue, E extends FilterEntry> {
	transform(value: V, entry: E): AstNode[];
}

export const FilterAstNodeAdapter = new InjectionToken<FilterAstNodeAdapter<FilterValue, FilterEntry>>('FilterAstNodeAdapter');
