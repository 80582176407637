<ng-container *ngIf="field.sourceConfig">
	<!-- DataSource via Autocomplete -->
	<uf-autocomplete [ngClass]="cssClass" [control]="control" [label]="field.label" [placeholder]="field.placeholder"
		[minSearchLength]="formSettings.dataSourceMinSearchLength" [options]="options" [(value)]="content"
		(searchChange)="search($event)" (valueChange)="onValueChange($event)" nameProperty="_display">
		<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
		<uf-help *ngIf="field.help" [content]="field.help" />
		<ng-container actions *ngIf="field.dataCapture?.barcode || advancedSearchConfig">
			<div *ngIf="advancedSearchConfig" class="input-action-btn">
				<uf-data-source-advanced-search [disabled]="control.disabled" [config]="advancedSearchConfig"
					(valueChange)="onSelect($event)" />
			</div>
			<div *ngIf="field.dataCapture?.barcode" class="input-action-btn">
				<uf-barcode-scanner [disabled]="control.disabled" (valueChange)="onScan($event)" />
			</div>
		</ng-container>
	</uf-autocomplete>
</ng-container>

<uf-data-seed-mappings-display *ngIf="field.sourceConfig && content" [sourceConfig]="field.sourceConfig"
	[dataSeed]="content" />