import { Component } from '@angular/core';
import { Dictionary, FieldType, FieldWidth, PageField } from '@unifii/sdk';

import { UfControl, UfControlArray, ValidatorFunctions } from '@unifii/library/common';
import { FIELD_WIDTH_MAP } from '@unifii/library/smart-forms';

@Component({
	selector: 'sc-show-inputs',
	templateUrl: './show-inputs.html',
	styleUrls: ['./show-inputs.less'],
})
export class ShowInputsComponent {

	emptyControl = new UfControl();
	control = new UfControl(ValidatorFunctions.required('Is required'));
	controlArray = new UfControlArray([
		new UfControl(ValidatorFunctions.required('Is required')),
	]);

	// Basic Inputs
	icv: Dictionary<any> = {
		id: 'basic_input_01',
		name: 'input',
		label: 'Input label',
		placeholder: 'Value here...',
		suffix: '*',
		width: 'Stretch',
		disabled: false,
		floating: false,
		inline: false,
		required: false,
		help: false,
		cssClass: '',
	};

	inputsConfig: Dictionary<PageField> = {
		id: { label: 'ID', type: FieldType.Text, value: this.icv.id, width: FieldWidth.Third },
		name: { label: 'Name', type: FieldType.Text, value: this.icv.name, width: FieldWidth.Third },
		label: { label: 'Label', type: FieldType.Text, value: this.icv.label, width: FieldWidth.Third },
		placeholder: { label: 'Placeholder', type: FieldType.Text, value: this.icv.placeholder, width: FieldWidth.Third },
		suffix: { label: 'Suffix', type: FieldType.Text, value: this.icv.suffix, width: FieldWidth.Third },
		disabled: { label: 'Disabled', type: FieldType.Bool, value: this.icv.disabled, width: FieldWidth.Third },
		required: { label: 'Required', type: FieldType.Bool, value: this.icv.required, width: FieldWidth.Third },
		help: { label: 'Help Text', type: FieldType.Bool, value: this.icv.help, width: FieldWidth.Third },
		cssClass: {
			label: 'Input style', type: FieldType.Choice, value: this.icv.cssClass, width: FieldWidth.Third, options: [
				{ name: 'None', identifier: '' },
				{ name: 'Smart forms - centered', identifier: 'centered' },
				{ name: 'Smart forms - inline', identifier: 'inline' },
				{ name: 'Smart forms - auto sized', identifier: 'uf-input' },
				{ name: 'Smart forms - table', identifier: 'table-cell' },
				{ name: 'Materialesque', identifier: 'm' },
				{ name: 'Small', identifier: 'small' },
				{ name: 'Large', identifier: 'large' },
				{ name: 'Floating', identifier: 'floating' },
			],
		},
	};

	options = [{ name: 'Option 1', id: 1 }, { name: 'Option 2', id: 2 }, { name: 'Option 3', id: 2 }, { name: 'Option 4', id: 2 }];

	// Buttons
	bcv = {
		id: 'button_confirm_01',
		name: 'confirm',
		label: 'Confirm',
		accent: false,
		icon: false,
		disabled: false,
	};

	buttonsConfig = {
		id: { label: 'ID', type: FieldType.Text, value: this.bcv.id, width: FieldWidth.Third },
		name: { label: 'Name', type: FieldType.Text, value: this.bcv.name, width: FieldWidth.Third },
		label: { label: 'Label', type: FieldType.Text, value: this.bcv.label, width: FieldWidth.Third },
		accent: { label: 'Accent', type: FieldType.Bool, value: this.bcv.accent, width: FieldWidth.Third },
		icon: { label: 'Icon', type: FieldType.Bool, value: this.bcv.icon, width: FieldWidth.Third },
		disabled: { label: 'Disabled', type: FieldType.Bool, value: this.bcv.disabled, width: FieldWidth.Third },
	};

	toggleControl(disable: boolean) {

		if (disable) {
			this.emptyControl.disable();

			return;
		}
		this.emptyControl.enable();
	}

	getWidth(width: string): string {
		return FIELD_WIDTH_MAP[width] || '98%';
	}

}
