import { Component } from '@angular/core';

import { ColumnInfo } from '../../components';

@Component({
	selector: 'sc-show-spacing',
	templateUrl: './show-spacing.html',
	styleUrls: ['./_foundation.less'],
})
export class ShowSpacingComponent {

	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'rem', name: 'Rem' },
		{ identifier: 'pixel', name: 'Pixel' },
		{ identifier: 'point', name: 'point' },

	];
	readonly cssTableInfo = [
	];

	readonly spacingOptions = [
		{ name: 'Button', value: 'button' },
		{ name: 'Text Input', value: 'textInput' },
	];

	preview = 'textInput';

}
