import { DATE_DATA_FORMAT, DATE_TIME_DATA_FORMAT, Dictionary, TIME_DATA_FORMAT, isStringTrimmedNotEmpty } from '@unifii/sdk';
import { Duration, add as addDuration, format } from 'date-fns';

import { ExpressionFunctionsSet } from '../models';

import { safeParseISO } from './date-time-functions';
import { REGEXP_TEMPLATE_EXPRESSION } from './regexp';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const add = (value: string, quantity: number, unit: keyof Duration, _context: any): string => {

	const date = safeParseISO(value);

	if (!date) {
		console.warn('ExpressionFunctions.add - invalid date input', value);

		return value;
	}

	const duration: Duration = { [unit]: quantity };

	return format(addDuration(date, duration), DATE_TIME_DATA_FORMAT);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const toDate = (value: string, _context: any): string => {

	const date = safeParseISO(value);

	if (!date) {
		console.warn('ExpressionFunctions.toDate - invalid date input', value);

		return value;
	}

	return format(date, DATE_DATA_FORMAT);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const toTime = (value: string, _context: any): string => {

	const date = safeParseISO(value);

	if (!date) {
		console.warn('ExpressionFunctions.toTime - invalid input', value);

		return value;
	}

	return format(date, TIME_DATA_FORMAT);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const toNumbers = (value: string[] | string | null | undefined, _context: any): number[] | undefined => {

	if (!value) {
		return;
	}

	const input = Array.isArray(value) ? value : [value];

	return input.map((s) => parseFloat(s));
};

const getClaimValues = (claims: Dictionary<string | string[]>, type: string): string[] | null => {

	console.warn('getClaimValues deprecated in 1.17, replaced by mapUserToUserContext');

	const claimValue = claims[type];

	if (!claimValue) {
		return null;
	}

	if (!Array.isArray(claims[type])) {
		return [claimValue as string];
	}

	return claimValue as string[];
};

const some = (arr: any[], propName: string, value: any) => arr.some((item: any) => item[propName] === value);

const concat = (values: string[], separator: string) => values.join(separator);

const functionsSet: ExpressionFunctionsSet = {
	add,
	toDate,
	toTime,
	toNumbers,
	some,
	concat,
	getClaimValues,
};

export const UfExpressionFunctionsSet = Object.freeze(functionsSet);

/** Test for TemplateExpression, a string that contains one or more {{}} mustaches */
export const isTemplateExpression = (value: unknown): boolean =>
	isStringTrimmedNotEmpty(value) && REGEXP_TEMPLATE_EXPRESSION.test(value);

/** Test for a path, means a sequence of one or more valid identifiers separated by '.' */
export const isIdentifiersPathExpression = (value?: string | null): boolean =>
	value ?
		/^(?!.*(false|true|and|not|or|in|null)$)(^[A-Za-z_][A-Za-z0-9_]*)((.[A-Za-z_][A-Za-z0-9_]*)*)$/g.test(value) :
		false;
