export * from './show-avatars.component';
export * from './show-badges.component';
export * from './show-chips.component';
export * from './show-dividers.component';
export * from './show-expanders.components';
export * from './show-group.component';
export * from './show-icons.component';
export * from './show-images.component';
export * from './show-data-display-list.component';
export * from './show-data-display-components.component';
export * from './show-lists.component';
export * from './show-lozenges.component';
export * from './show-messages.component';
export * from './show-tables.component';
export * from './show-tool-tips.component';
export * from './show-directory.component';

import { ShowAvatarsComponent } from './show-avatars.component';
import { ShowBadgesComponent } from './show-badges.component';
import { ShowChipsComponent } from './show-chips.component';
import { ShowDataDisplayComponentsComponent } from './show-data-display-components.component';
import { ShowDataDisplayListComponent } from './show-data-display-list.component';
import { ShowDirectoryComponent } from './show-directory.component';
import { ShowDividersComponent } from './show-dividers.component';
import { ShowExpandersComponent } from './show-expanders.components';
import { ShowGroupComponent } from './show-group.component';
import { ShowIconsComponent } from './show-icons.component';
import { ShowImagesComponent } from './show-images.component';
import { ShowListsComponent } from './show-lists.component';
import { ShowLozengesComponent } from './show-lozenges.component';
import { ShowMessagesComponent } from './show-messages.component';
import { ShowTablesComponent } from './show-tables.component';
import { ShowToolTipsComponent } from './show-tool-tips.component';

export const SC_PAGE_DATA_DISPLAY_COMPONENTS = [
	ShowAvatarsComponent, ShowBadgesComponent, ShowChipsComponent, ShowDividersComponent, ShowExpandersComponent,
	ShowGroupComponent, ShowIconsComponent, ShowImagesComponent, ShowDataDisplayListComponent, ShowListsComponent,
	ShowDirectoryComponent, ShowLozengesComponent, ShowMessagesComponent, ShowTablesComponent, ShowToolTipsComponent,
	ShowDataDisplayComponentsComponent,
];
