import { Injectable } from '@angular/core';
import { patchAssets } from '@unifii/markdown-it-assets';
import MarkdownIt from 'markdown-it';

import { MarkdownProvider } from './markdown-provider';

@Injectable()
export class UfMarkdownService implements MarkdownProvider {

	private markdown: MarkdownIt;

	constructor() {
		this.markdown = MarkdownIt('commonmark');
		patchAssets(this.markdown);
	}

	render(v = ''): string {
		try {
			return this.markdown.render(v);
		} catch (e) {
			console.warn('MarkdownIt.render - Parse exception for text', v);
			console.error(e);

			return '';
		}
	}

}
