<label *ngIf="label">{{ label }}
	<ng-content></ng-content>
</label>
<ul>
	<li *ngFor="let input of inputFiles" class="small">
		<uf-progress [completed]="input.completed" [progress]="input.progress" />
		<uf-icon *ngIf="fileType !== imageFileType" name="file" />
		<uf-icon *ngIf="fileType === imageFileType" name="imageList" />
		<ng-container *ngIf="input.file.name">
			<a *ngIf="input.completed && input.file?.id; else fileName" [attachmentLink]="input.file.id">
				{{input.file.name}}
			</a>
			<ng-template #fileName>
				<span>{{input.file.name}}</span>
			</ng-template>
		</ng-container>
		<a *ngIf="!control.disabled" (click)="remove(input); control.markAsDirty()" class="delete">
			<uf-icon name="close" />
		</a>
	</li>
</ul>
<div [class.multiple]="cameraRequired" class="input-wrap">
	<uf-file [type]="fileType" [ngClass]="cssClass" [typeLabel]="fileTypeLabel" [disabled]="!canAdd"
		(valueChange)="add($event); control.markAsDirty()" (touchedChange)="control.markAsTouched()" multiple="true" />
	<uf-camera-capture *ngIf="cameraRequired" [disabled]="!canAdd" (valueChange)="add($event)"
		(touchedChange)="control.markAsTouched()" />
</div>
<uf-error [control]="control" />