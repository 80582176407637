import { Component, HostBinding } from '@angular/core';

@Component({
	selector: 'uf-icons',
	templateUrl: './icons.html',
})
export class IconsComponent {

	@HostBinding('style.display') hostDisplay = 'none';

}
