<button (click)="addMonth(false)" class="uf-action tertiary small" type="button">
	<uf-icon name="arrowBackSolid" />
</button>

<div class="title grow">
	<label *ngIf="months[month] let month">{{month.label}}</label>
	<select #monthSelect (change)="changeMonth($event)">
		<option *ngFor="let optionMonth of months; let i = index" [selected]="month === i" [value]="i">
			{{optionMonth.label}}
		</option>
	</select>
</div>

<button (click)="addMonth(true)" type="button" class="uf-action tertiary small">
	<uf-icon name="arrowForwardSolid" />
</button>

<button (click)="addYear(false)" class="uf-action tertiary small" type="button">
	<uf-icon name="arrowBackSolid" />
</button>

<div class="title grow">
	<label>{{year}}</label>
	<select (change)="changeYear($event)">
		<option *ngFor="let optionYear of years" [selected]="optionYear.value === year" [value]="optionYear.value">
			{{optionYear.label}}
		</option>
	</select>
</div>

<button (click)="addYear(true)" class="uf-action tertiary small" type="button">
	<uf-icon name="arrowForwardSolid" />
</button>