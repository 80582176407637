import { DataSeed, FieldOption, GeoLocation, isArrayOfType, isBoolean, isDataSeed, isDictionary, isFieldOption, isGeoLocation, isNumber, isOptionalType, isSignature, isString, isStringTrimmedNotEmpty } from '@unifii/sdk';

import { AngularRouterLink, isAngularRouterLink } from '../../../angular';
import { DataDisplayColour, DataDisplayHrefTarget, DataDisplayListItem, SourceConfig, SourceConfigMapping, isDataDisplayColour, isDataDisplayHrefTarget } from '../../../models';
import { isDataDisplayListItem, isSourceConfig, isSourceConfigMapping } from '../../../utils';
import { ContentModalData } from '../../modal';

export type DataDisplayAttachmentValue = {
	label: string;
	attachmentId: string;
	displayAsLink?: boolean;
};

export const isDataDisplayAttachmentValue = (data: unknown): data is DataDisplayAttachmentValue =>
	isDictionary(data) &&
	[2, 3].includes(Object.keys(data).length) &&
	isStringTrimmedNotEmpty(data.label) &&
	isStringTrimmedNotEmpty(data.attachmentId) &&
	isOptionalType(data.displayAsLink, isBoolean);

export type DataDisplayContentModalValue = {
	/** Modal open link label, defaulted to `View` if not provided */
	label?: string;
	content: ContentModalData;
};

export const isDataDisplayContentModalValue = (data: unknown): data is DataDisplayContentModalValue =>
	isDictionary(data) &&
	isOptionalType(data.label, isString) &&
	isDictionary(data.content) &&
	[1, 2, 3].includes(Object.keys(data.content).length) &&
	isOptionalType(data.content.markdown, isString) &&
	isOptionalType(data.content.imageUrl, isString) &&
	isOptionalType(data.content.imageAlt, isString);

export type DataDisplayDataSeedVisibleItem = {
	item: DataDisplayListItem;
	mapping: SourceConfigMapping;
}

const isDataDisplayDataSeedVisibleItem = (data: unknown): data is DataDisplayDataSeedVisibleItem =>
	isDictionary(data) &&
	Object.keys(data).length === 2 &&
	isDataDisplayListItem(data.item) &&
	isSourceConfigMapping(data.mapping);

export type DataDisplayDataSeedValue = {
	dataSeed: DataSeed | undefined;
	sourceConfig: SourceConfig;
	display: string | undefined;
	visibleItems: DataDisplayDataSeedVisibleItem[];
};

export const isDataDisplayDataSeedValue = (data: unknown): data is DataDisplayDataSeedValue =>
	isDictionary(data) &&
	Object.keys(data).length === 4 &&
	isOptionalType(data.dataSeed, isDataSeed) &&
	isSourceConfig(data.sourceConfig) &&
	isOptionalType(data.display, isString) &&
	isArrayOfType(data.visibleItems, isDataDisplayDataSeedVisibleItem);

export type DataDisplayHrefValue = {
	label: string;
	href: string;
	displayAsLink?: boolean;
	target?: DataDisplayHrefTarget;
};

export const isDataDisplayHrefValue =(data: unknown): data is DataDisplayHrefValue =>
	isDictionary(data) &&
	[2, 3, 4].includes(Object.keys(data).length) &&
	isStringTrimmedNotEmpty(data.label) &&
	isStringTrimmedNotEmpty(data.href) &&
	isOptionalType(data.displayAsLink, isBoolean) &&
	isOptionalType(data.target, isDataDisplayHrefTarget);

export type DataDisplayIconValue = {
	icon: string;
	colour?: DataDisplayColour;
};

export const isDataDisplayIconValue = (data: unknown): data is DataDisplayIconValue =>
	isDictionary(data) &&
	[1, 2].includes(Object.keys(data).length) &&
	isStringTrimmedNotEmpty(data.icon) &&
	isOptionalType(data.colour, isDataDisplayColour);

export type DataDisplayImageValue = {
	imageUrl: string;
	alt?: string;
};

export const isDataDisplayImageValue = (data: unknown): data is DataDisplayImageValue =>
	isDictionary(data) &&
	[1, 2].includes(Object.keys(data).length) &&
	isStringTrimmedNotEmpty(data.imageUrl) &&
	isOptionalType(data.alt, isString);

export type DataDisplayLozengeValue = {
	label: string;
	colour: DataDisplayColour;
};

export const isDataDisplayLozengeValue = (data: unknown): data is DataDisplayLozengeValue =>
	isDictionary(data) &&
	Object.keys(data).length === 2 &&
	isStringTrimmedNotEmpty(data.label) &&
	isDataDisplayColour(data.colour);

export type DataDisplayMapMarkerValue = {
	marker: GeoLocation;
	label?: string;
};

export const isDataDisplayMapMarkerValue = (data: unknown): data is DataDisplayMapMarkerValue =>
	isDictionary(data) &&
	[1, 2].includes(Object.keys(data).length) && 
	isGeoLocation(data.marker) &&
	isOptionalType(data.label, isString);

export type DataDisplayMarkdownValue = {
	markdown: string;
};

export const isDataDisplayMarkdownValue = (data: unknown): data is DataDisplayMarkdownValue =>
	isDictionary(data) &&
	isStringTrimmedNotEmpty(data.markdown) &&
	Object.keys(data).length === 1;

export type DataDisplayOptionsValue = {
	selected: string[];
	options: FieldOption[];
};

export const isDataDisplayOptionsValue = (data: unknown): data is DataDisplayOptionsValue =>
	isDictionary(data) &&
	Object.keys(data).length === 2 &&
	isArrayOfType(data.options, isFieldOption) &&
	isArrayOfType(data.selected, isString);

export type DataDisplayPrimitiveValue = string | number | boolean;

export const isDataDisplayPrimitiveValue = (data: unknown): data is DataDisplayPrimitiveValue =>
	isStringTrimmedNotEmpty(data) || isBoolean(data) || isNumber(data);

export type DataDisplayRouterLinkValue = {
	label: string;
	routerLink: AngularRouterLink;
	target?: DataDisplayHrefTarget;
};

export const isDataDisplayRouterLinkValue = (data: unknown): data is DataDisplayRouterLinkValue =>
	isDictionary(data) &&
	[2, 3].includes(Object.keys(data).length) &&
	isStringTrimmedNotEmpty(data.label) &&
	isAngularRouterLink(data.routerLink) &&
	isOptionalType(data.target, isDataDisplayHrefTarget);

export type DataDisplaySignatureValue = {
	/** Modal open link label, defaulted to `View` if not provided */
	label?: string;
	signature: string;
};

export const isDataDisplaySignatureValue = (data: unknown): data is DataDisplaySignatureValue =>
	isDictionary(data) &&
	[1, 2].includes(Object.keys(data).length) &&
	isOptionalType(data.label, isString) &&
	isSignature(data.signature);
