import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-images.html',
})
export class ShowImagesComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'uf-img-2x1', type: 'base class', description: 'Wraps to image with 2x1 ratio' },
		{ name: 'uf-img-3x2', type: 'base class', description: 'Wraps to image with 3x2 ratio' },
		{ name: 'uf-img-16x9', type: 'base class', description: 'Wraps to image with 16x9 ratio' },
		{ name: 'uf-img-4x3', type: 'base class', description: 'Wraps to image with 4x3 ratio' },
		{ name: 'uf-img-1x1', type: 'base class', description: 'Wraps to image with 1x1 ratio' },
		{ name: 'cover', type: 'base class', description: 'Scales the image to the container width, it is cropped either vertically or horizontally so that no empty space remains.' },
	];

	// Interactive
	readonly typeOptions = [
		{ name: '2 x 1', value: 'uf-img-2x1' },
		{ name: '3 x 2', value: 'uf-img-3x2' },
		{ name: '16 x 9', value: 'uf-img-16x9' },
		{ name: '4 x 3', value: 'uf-img-4x3' },
		{ name: '1 x 1', value: 'uf-img-1x1' },
	];
	readonly imageOptions = [
		{ name: 'Cropped Image', value: '' },
		{ name: 'Uncropped', value: 'uncropped' },
	];
	readonly fitOptions = [
		{ name: 'Fit (Default)', value: '' },
		{ name: 'Cover', value: 'cover' },
	];

	type = 'uf-img-2x1';
	fit = '';
	image = '';

}
