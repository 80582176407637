import { Injectable, inject } from '@angular/core';
import { DataSourceType, FieldType, VisibleFilterDescriptor, ensureError } from '@unifii/sdk';

import { DataSourceIdTo, FormDefinitionMetadataIdentifiers, UserInfoIdentifiers } from '../../constants';
import { DataDescriptorBucketType, DataDescriptorCompanyType, DataDescriptorType, DataDescriptorUsersType, DataPropertyDescriptor, FilterEntry, FilterType, SourceConfig } from '../../models';

import { FilterEntryAdapter, FilterEntryAdapterInfo, FilterEntryDataDescriptorAdapter, FilterEntryDataDescriptorAdapterInfo } from './filter-entry-adapter-model';

/**
 * @description
 * UfFilterEntryByDataDescriptorFactory is a middle layer service responsible to map the
 * DataDescriptorProperty to the info needed by Unifii standard FilterEntryFactory for
 * generating the associated FilterEntry
 */
@Injectable()
export class UfFilterEntryDataDescriptorAdapter implements FilterEntryDataDescriptorAdapter {

	private filterEntryAdapter = inject(FilterEntryAdapter, { optional: true });

	transform(info: FilterEntryDataDescriptorAdapterInfo): FilterEntry | undefined {

		if (!this.filterEntryAdapter) {
			console.warn('UfFilterEntryDataDescriptorAdapter: FilterEntryAdapter not provided');

			return;
		}

		try {

			if (!info.descriptorProperty.asInputFilter) {
				return;
			}

			// The factory need to make changes to the propertyDescriptor, a clone guarantee that the original input is not modified
			const property: DataPropertyDescriptor = JSON.parse(JSON.stringify(info.descriptorProperty)) as DataPropertyDescriptor;
			const descriptor: VisibleFilterDescriptor = JSON.parse(JSON.stringify(
				info.visibleFilterDescriptor ??
				{ identifier: info.descriptorProperty.identifier } satisfies VisibleFilterDescriptor),
			) as VisibleFilterDescriptor;

			// UNIFII-6503
			if (info.descriptorType === DataDescriptorBucketType && property.type === FieldType.MultiChoice) {
				descriptor.queryOperator = 'contains';
			}

			this.amendDataPropertyDescriptorType(property, info.descriptorType);
			
			const filterType = this.getFilterTypeByTarget(property, info.descriptorType);
			
			descriptor.queryIdentifier = this.getQueryIdentifier(property, info.descriptorType);

			if (filterType === FilterType.HierarchyUnit) {
				// Optionally override HierarchyConfig inside the visibleFilterDescriptor with the one from the property
				descriptor.hierarchyConfig = descriptor.hierarchyConfig ?? property.hierarchyConfig;
			}

			const input: FilterEntryAdapterInfo = {
				type: filterType,
				identifier: property.identifier,
				label: property.label,
				visibleFilterDescriptor: descriptor,
				staticFilter: info.staticFilter,
				options: property.options,
				translateService: info.translateService,
				loader: undefined,
				source: property.sourceConfig,
				dataLoaderFactory: info.dataLoaderFactory,
				requestAnalytics: info.requestAnalytics,
				searchMinLength: info.searchMinLength,
			};

			return this.filterEntryAdapter.transform(input);

		} catch (e) {
			console.warn(`UfFilterEntryDataDescriptorAdapter: ${ensureError(e).message}`);

			return;
		}
	}

	private getQueryIdentifier(dataPropertyDescriptor: DataPropertyDescriptor, type: DataDescriptorType): string | undefined {
		
		if (type === DataDescriptorUsersType && dataPropertyDescriptor.identifier === UserInfoIdentifiers.Units as string) {
			// Force for User.units the queryIdentifier to the '<identifier>' instead of Unifii default '<identifier>.id'
			return dataPropertyDescriptor.identifier;
		}

		// FieldType.Text with SourceConfig are special properties
		// eslint-disable-next-line sonarjs/no-collapsible-if
		if (dataPropertyDescriptor.type === FieldType.Text && dataPropertyDescriptor.sourceConfig) {
	
			if (dataPropertyDescriptor.sourceConfig.type === DataSourceType.Users &&
				dataPropertyDescriptor.sourceConfig.mappingsTo[DataSourceIdTo]?.from === UserInfoIdentifiers.Id
			) {
				// Force for User.manager the queryIdentifier to '<identifier>.id'
				return `${dataPropertyDescriptor.identifier}.${UserInfoIdentifiers.Id}`;
			}
		}
		
		return undefined;
	}

	private amendDataPropertyDescriptorType(dataPropertyDescriptor: DataPropertyDescriptor, type: DataDescriptorType) {

		switch (type) {

			case DataDescriptorBucketType:
				if (dataPropertyDescriptor.type === FieldType.Choice) {
					// SourceConfig based Choice
					if (dataPropertyDescriptor.sourceConfig && !dataPropertyDescriptor.identifier.endsWith('._id')) {
						// Force to Lookup
						dataPropertyDescriptor.type = FieldType.Lookup;
					} else {
						// Force to Multi-choice
						dataPropertyDescriptor.type = FieldType.MultiChoice;
					}
				}
				break;

			case DataDescriptorUsersType:
			case DataDescriptorCompanyType: {
				const claimsRegex = /^claims\.*./;

				if (dataPropertyDescriptor.type === FieldType.Choice && claimsRegex.test(dataPropertyDescriptor.identifier)) {
					// Modify claims choice descriptors
					dataPropertyDescriptor.type = FieldType.MultiChoice;
				}
				break;
			}
		}
	}

	/** Amend the DataPropertyDescriptor.type for specific DataDescriptorType
	 * This will be covered out of the box once DataDescriptor will be based on DataType instead of FieldType
	 */
	private getFilterTypeByTarget({ type, identifier, sourceConfig }: DataPropertyDescriptor, descriptorType: DataDescriptorType): FilterType {

		switch (descriptorType) {
			case DataDescriptorBucketType:
				// eslint-disable-next-line sonarjs/no-collapsible-if
				if (type === FieldType.Text) {
					if ([FormDefinitionMetadataIdentifiers.CreatedBy, FormDefinitionMetadataIdentifiers.LastModifiedBy].includes(identifier as FormDefinitionMetadataIdentifiers)) {
						return FilterType.User;
					}
					if (sourceConfig?.type === DataSourceType.Company) {
						return FilterType.Company;
					}
					if (sourceConfig?.type === DataSourceType.Users) {
						return FilterType.User;
					}
				}

				break;
			case DataDescriptorUsersType:
				if (type === FieldType.Text) {
					if (identifier === UserInfoIdentifiers.Company as string) {
						return FilterType.Company;
					}
					if (identifier === UserInfoIdentifiers.Manager as string) {
						return FilterType.User;
					}
				}

				if (type === FieldType.TextArray && identifier === UserInfoIdentifiers.Units as string) {
					return FilterType.HierarchyUnit;
				}
				if (type === FieldType.Choice && identifier === 'status') {
					return FilterType.UserStatus;
				}
				break;
		}

		return this.getFilterType(type, sourceConfig);
	}

	private getFilterType(type: FieldType, sourceConfig?: SourceConfig): FilterType {
		switch (type) {
			case FieldType.Text:
			case FieldType.MultiText:
			case FieldType.Phone:
			case FieldType.Email:
			case FieldType.Website:
				return FilterType.Text;

			case FieldType.TextArray:
				return FilterType.TextArray;

			case FieldType.Date:
				return FilterType.DateRange;

			case FieldType.Time:
				return FilterType.TimeRange;

			case FieldType.DateTime:
				return FilterType.DatetimeRange;

			case FieldType.ZonedDateTime:
				return FilterType.ZonedDatetimeRange;

			case FieldType.Hierarchy:
				return FilterType.HierarchyUnit;

			case FieldType.Number:
				return FilterType.NumberRange;

			case FieldType.Cost:
				return FilterType.Cost;

			case FieldType.Bool:
				return FilterType.Bool;

			case FieldType.Choice:
				return sourceConfig ? FilterType.DataSeed : FilterType.Choice;

			case FieldType.MultiChoice:
				return sourceConfig ? FilterType.DataSeedArray : FilterType.OptionArray;

			case FieldType.Lookup:
				return FilterType.DataSeedArray;

			default:
				throw new Error(`property type ${type} not recognized`);
		}
	}

}
