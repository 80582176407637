export enum Size {
	XS = 'xs',
	SM = 'sm',
	MD = 'md',
	LG = 'lg',
	XL = 'xl'
}

export enum ColumnGap {
	Narrow = 'narrow',
	Wide = 'wide',
	Condensed = 'condensed'
}
