<sc-page-content width="lg" identifier="icons">

	<ng-container editorInputs>
		<uf-radio [columns]="1" [options]="sizeOptions" [(value)]="size" (valueChange)="updateCode()"
			class="col-6 col-2-md" label="Size" valueProperty="value" />
		<uf-radio [columns]="1" [options]="colourOptions" [(value)]="color" (valueChange)="updateCode()"
			class="col-6 col-2-md" label="Colour" valueProperty="value" />
		<uf-search (searchChange)="filter($event)" class="col-12 col-3-md" label="Search" />
	</ng-container>

	<ng-container editor>

		<uf-icons />

		<sc-code-editor-expander [code]="template" class="flat">
			<div [class.dark]="color === 'white'" class="uf-grid grow pad-bottom">
				<div *ngFor="let name of icons" [class.selected]="selected === name" title="{{name}}"
					[ngClass]="{'selected': name === selected, 'duplicated': isDuplicated(name)}"
					(click)="selectIcon(name); updateCode()"
					class="col-3 col-2-md col-2-lg col-xl-1 pad col center-all icon" style="cursor: pointer;">
					<uf-icon [name]="name" [ngClass]="[size, color]" />
				</div>
			</div>
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">Properties</h3>
		<sc-info-table [columnConfig]="propertiesColConfig" [rows]="propertiesRows" />
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>