import { Component } from '@angular/core';
import { addDays, addMinutes, format } from 'date-fns';

import { UfControl, ValidatorFunctions } from '@unifii/library/common';

@Component({
	selector: 'sc-show-time',
	templateUrl: './show-time.html',
	styleUrls: ['./show-time.less'],
})
export class ShowLabTimeComponent {

	dateValue: string;
	dateFormat = 'd/M/yyyy';
	dateControl = new UfControl(ValidatorFunctions.compose([
		ValidatorFunctions.required('Input is required'),
	]));

	timeValue: string;
	timeFormat = 'hh:mm a';
	timeControl = new UfControl(ValidatorFunctions.compose([
		ValidatorFunctions.required('Input is required'),
	]));

	dateTimeValue: string;
	dateTimeFormat = 'd/M/yyyy hh:mm a';
	dateTimeControl = new UfControl(ValidatorFunctions.compose([
		ValidatorFunctions.required('Input is required'),
	]));

	private count = 0;

	setDate() {
		this.dateValue = format(addDays(new Date(), this.count), 'yyyy-MM-dd');
		this.count++;
	}

	setDateFormat() {

		if (this.dateFormat === 'dd-MM-yyyy') {
			this.dateFormat = 'd/M/yyyy';

			return;
		}

		this.dateFormat = 'dd-MM-yyyy';
	}

	setTime() {
		this.timeValue = format(addMinutes(new Date(), this.count), 'HH:mm:ss');
		this.count++;
	}

	setTimeFormat() {

		if (this.timeFormat === 'HH:mm') {
			this.timeFormat = 'hh:mm a';

			return;
		}

		this.timeFormat = 'HH:mm';
	}

	setDatetimeFormat() {

		if (this.dateTimeFormat === 'd/M/yyyy hh:mm a') {
			this.dateTimeFormat = 'yyyy-MM-dd HH:mm';

			return;
		}

		this.dateTimeFormat = 'd/M/yyyy hh:mm a';
	}

	setDateTime() {
		const date = new Date();

		console.log(date);
		const modified = addDays(addMinutes(date, this.count), this.count);

		console.log(modified);
		const formatted = format(modified, `yyyy-MM-dd'T'HH:mm:ss`);

		console.log(formatted);
		this.dateTimeValue = formatted;
		this.count++;
	}

	changed(value: string) {
		console.log(`ShowTime, value changed '${value}'`);
	}

}
