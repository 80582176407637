<label [ngClass]="{'full-width left-pad': !!content}">
	<input [formControl]="control" [attr.id]="id" [attr.name]="name" [attr.tabindex]="disabled ? -1 : 0"
		(focus)="focused = true" (blur)="focused = false; touchedChange.next(true)" (click)="userClick($event)"
		type="checkbox" />
	<!-- todo: look into why blur event is firing on every click -->
	<div [class.push-down]="!!content" [class.active]="value" class="icon">
		<uf-icon name="checkboxTick" />
		<uf-icon name="checkbox" />
	</div>
	<div *ngIf="label && !content" class="check-label grow">{{label}}</div>
	<div *ngIf="content" [markdownRenderer]="content" class="grow body-copy small push-content">
	</div>
</label>
<ng-content></ng-content>
<uf-error [control]="control" />