<sc-page-content width="lg" identifier="badges">

	<ng-container editorInputs>
		<uf-switch [(value)]="visible" class="col-3" label="Show Badge" />
		<uf-radio [columns]="2" [options]="levelOptions" [(value)]="level" (valueChange)="modifierChange()"
			class="col-6" label="Message Level" valueProperty="value" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template" class="flat">
			<div class="row center-all justify-center space-children">
				<div [ngClass]="[level]" [class.visible]="visible" class="uf-badge visible">
					+100
				</div>
				<button [ngClass]="[size]" class="uf-button" type="button">
					Mail
					<uf-icon [ngClass]="[level]" [class.visible]="visible" name="warning" class="uf-badge" />
				</button>
				<button [ngClass]="[size]" class="uf-action" type="button">
					<uf-icon name="mail" />
					<uf-icon [ngClass]="[level]" [class.visible]="visible" name="warning" class="uf-badge" />
				</button>
				<button [ngClass]="[size]" class="uf-action tertiary" type="button">
					<uf-icon name="mail" />
					<div [ngClass]="[level]" [class.visible]="visible" class="uf-badge">
						2
					</div>
				</button>
			</div>
		</sc-code-editor-expander>

	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>