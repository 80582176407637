import { Component } from '@angular/core';
import { Option } from '@unifii/sdk';

import { ColumnGap, Size, UfControl } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-number-fields.html',

})
export class ShowNumberFieldsComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly sizeOptions: Option[] = [
		{ name: 'Medium (default)', identifier: '' },
		{ name: 'Small', identifier: 'small' },
	];

	readonly componentOptions: Option[] = [
		{ name: 'Number', identifier: 'uf-number' },
		{ name: 'Cost', identifier: 'uf-cost' },
	];

	readonly inputTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'default', name: 'Default' },
		{ identifier: 'description', name: 'Description' },
	];

	readonly inputTableInfo = [
		{ name: 'control', type: 'UfControl', default: 'new UfControl()', description: 'A control object to manage validators, states etc.' },
		{ name: 'id', type: 'string', default: 'undefined', description: 'Sets id attribute of inner input element' },
		{ name: 'name', type: 'string', default: 'undefined', description: 'Sets name attribute of inner input element' },
		{ name: 'label', type: 'string', default: 'undefined', description: 'Sets label' },
		{ name: 'placeholder', type: 'string', default: 'undefined', description: 'Sets placeholder' },
	];

	readonly numberInputTableInfo = [
		{ name: 'step', type: 'number', default: 'undefined', description: 'Sets increments for number input' },
		{ name: 'autocomplete', type: 'number | string', default: 'UUID', description: 'Sets autocomplete name for the input' },
	];

	readonly costInputTableInfo = [
		{ name: 'suffix', type: 'string', default: 'undefined', description: 'Sets suffix for the cost input, such as currency sign' },
		{ name: 'currency', type: 'string', default: '\'AUD\'', description: 'Sets currency' },
	];

	readonly outputTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];

	readonly outputTableInfo = [
		{ name: 'valueChange', type: 'EventEmitter', description: 'Emits an event when the value of the input is changed' },
	];

	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];

	readonly cssTableInfo = [
		{ name: 'default', type: 'modifier', description: 'Default input size' },
		{ name: 'small', type: 'modifier', description: 'Small input size' },
	];

	size = '';

	control = new UfControl(undefined, undefined, undefined, 123.123);

	component = 'uf-number';

	template = `
		<uf-number label="Number"></uf-number>
	`;

	modifierChange() {
		this.template = `<${this.component} label="${this.component === 'uf-number' ? 'Number' : 'Cost'}"${this.size ? ' class="' + this.size + '"' : ''}></${this.component}>`;
	}

	log(value: any) {
		console.log(value);
	}

}
