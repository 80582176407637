<sc-page-content width="lg" identifier="markdown-editor">

	<ng-container editorInputs>
		<uf-radio [options]="previewOptions" [(value)]="preview" class="col-6" label="Preview" columns="1"
			valueProperty="identifier" />
		<uf-radio [options]="sizeOptions" [(value)]="size" class="col-6" label="Sizes" columns="1"
			valueProperty="identifier" />
	</ng-container>

	<ng-container editor>
		<sc-code-editor-expander [code]="template">
			<div style="width: 600px; max-width: 100%;">
				<uf-markdown-editor [ngClass]="size" [value]="value" [control]="control | asUfControl"
					[preview]="preview === 'true'" label="Markdown Editor">
					<button (click)="addCustom()" normal type="button" class="uf-action" title="Add Custom - Normal">
						<uf-icon name="custom" />
					</button>
					<button (click)="addCustom()" compact type="button" class="uf-action" title="Add Custom - Compact">
						<uf-icon name="custom" />
					</button>
				</uf-markdown-editor>
			</div>
		</sc-code-editor-expander>
	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">Inputs</h3>
		<h4 class="pad-sides">uf-markdown-editor</h4>
		<sc-info-table [columnConfig]="inputTableConfig" [rows]="inputTableInfo" />
		<h3 class="pad-sides">Outputs</h3>
		<sc-info-table [columnConfig]="outputTableConfig" [rows]="outputTableInfo" />
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="cssTableConfig" [rows]="cssTableInfo" />
	</ng-container>

</sc-page-content>