import { Injectable, inject } from '@angular/core';
import { ErrorType, HierarchyClient, HierarchyUnitExtended, HierarchyUnitWithChildCount, HierarchyUnitWithPath, ensureUfError } from '@unifii/sdk';

import { HierarchyUnitProvider } from '../models';

@Injectable()
export class UfHierarchyUnitProvider implements HierarchyUnitProvider {

	private hierarchyClient = inject(HierarchyClient);

	getUnit(id: string): Promise<HierarchyUnitExtended | undefined> {
		try {
			return this.hierarchyClient.getUnit(id);
		} catch (e) {
			if (ensureUfError(e).type === ErrorType.NotFound) {
				return Promise.resolve(undefined);
			} else {
				throw e;
			}
		}
	}

	getUnits(ids: string[]) {
		return this.hierarchyClient.getUnits(ids);
	}

	async getChildren(id: string, query?: string, params?: Record<string, unknown>): Promise<HierarchyUnitWithChildCount[]> {
		try {
			const children = await this.hierarchyClient.getUnitChildren(id, { params });

			if (!query?.trim().length) {
				return children;
			}

			// TODO once UNIFII-6526 is implemented, swap local filter for proper usage of v0
			return children.filter((u) => u.label.toLowerCase().includes(query.toLowerCase()));
		} catch (e) {
			if (ensureUfError(e).type === ErrorType.NotFound) {
				return Promise.resolve([]);
			} else {
				throw e;
			}
		}
	}

	search(params: { q?: string | undefined; leaves?: boolean | undefined; ceiling?: string | undefined; active?: boolean | undefined }): Promise<HierarchyUnitWithPath[]> {
		return this.hierarchyClient.search(params);
	}

}
