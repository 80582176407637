import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SharedTermsTranslationKey } from '../translations';

import { DeviceInfo } from './device-info';
import { ToastService } from './toast.service';

export interface ClipboardWriteOptions {
	silent?: boolean;
	successFeedback?: string;
	failFeedback?: string;
}

@Injectable({ providedIn: 'root' })
export class ClipboardService {

	constructor(
		private toast: ToastService,
		private device: DeviceInfo,
		private translate: TranslateService,
	) { }

	/** Copy text into the system clipboard */
	async setText(text?: string | null, options?: ClipboardWriteOptions): Promise<void> {

		if (!text) {
			return;
		}

		try {
			await navigator.clipboard.writeText(text);
			if (!options?.silent) {
				this.toast.success(
					options?.successFeedback ??
					this.translate.instant(SharedTermsTranslationKey.ActionCopyFeedbackSuccess),
				);
			}
		} catch (err) {
			console.error('ClipboardService.setText error', err);
			if (this.showErrorFeedback(options)) {
				this.toast.error(
					options?.failFeedback ??
					this.translate.instant(SharedTermsTranslationKey.ActionCopyFeedbackFail),
				);
			}
		}
	}

	/** Read text fron the system clipboard */
	getText(): Promise<string | undefined> {
		return navigator.clipboard.readText();
	}

	/** For mobile apps (that fails the writeText), error feedback is shown only if explicitly requested
	 *  silenced otherwise
	 */
	private showErrorFeedback(options?: ClipboardWriteOptions): boolean {
		const explicitNotSilent = options?.silent === false;
		const notSilent = !options?.silent;
		const isMobile = this.device.isAndroid() || this.device.isIosMobile();
		const isWeb = !isMobile;

		// On mobile shows error only if explicitly requested with configured options
		// On web	shows error if not explicity silenced
		return (isMobile && explicitNotSilent) || (isWeb && notSilent);
	}

}
