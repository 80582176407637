import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, DestroyRef, Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, QueryList, TemplateRef, TrackByFunction, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Dictionary, ensureUfRequestError } from '@unifii/sdk';
import { Subject, Subscription, timer } from 'rxjs';

import { SelectMasterDirective, SelectionChange, SortDirective, SortStatus } from '../../directives';
import { WindowWrapper } from '../../native';
import { DataLookupService, ModalService, WindowResizeEventHandler, WindowResizeInfo } from '../../services';
import { ClassListObserver } from '../../services/class-list-observer';
import { CommonTranslationKey } from '../../translations';
import { PanelComponent } from '../containers';

import { TableDataSource } from './table-data-source';
import { TableAction, TableColumnVisible, TableConfig, TableConfigColumn, TablePreferences, TableRenderModifierClassNames, TableRow, TableRowContext, TableRowLinkFunction, TableRowMemoize, TableStatus } from './table-models';
import { TablePreferencesProvider } from './table-preferences-provider-model';

@Directive({
	selector: '[cellFor]',
})
export class CellForDirective {

	@Input({ required: true }) cellFor: string;

	template = inject(TemplateRef);

}

@Component({
	selector: 'uf-table',
	templateUrl: './table.html',
	styleUrls: ['./table.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent<T> implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {

	@Input({ required: true }) config: TableConfig<T>;
	@Input() sort?: SortStatus;

	@ViewChild('outlet', { read: ViewContainerRef }) outletRef: ViewContainerRef;
	@ViewChild('tableTemplate', { read: TemplateRef }) tableTemplateRef: TemplateRef<TableComponent<T>>;
	@ViewChild('listTemplate', { read: TemplateRef }) listTemplateRef: TemplateRef<TableComponent<T>>;
	@ViewChild('cardsTemplate', { read: TemplateRef }) cardsTemplateRef: TemplateRef<TableComponent<T>>;

	@ViewChild(SelectMasterDirective, { static: false }) set select(v: SelectMasterDirective<T> | undefined) {
		if (!v) {
			return;
		}

		this._select = v;

		this.selectSubscriptions?.unsubscribe();
		this.selectSubscriptions = new Subscription();

		this.selectSubscriptions.add(this._select.selectionChange.subscribe((changes) => {
			this.onSelectionChange(changes);
			this.refresh();
		},
		));

		this.selectSubscriptions.add(this._select.selectionLimitReached.subscribe(this.showSelectionLimitModal.bind(this)));
	}

	get select() {
		return this._select;
	}

	@ViewChild(SortDirective, { static: false }) set sortMaster(v: SortDirective | undefined) {
		if (!v) {
			return;
		}

		this._sortMaster = v;

		this.sortSubscription?.unsubscribe();
		this.sortSubscription = v.sortChange.subscribe((sort) => {
			this.sort = sort;
			this.sortChange.next(sort);
			this.notifyUserPreferences();
		});
	}

	get sortMaster() {
		return this._sortMaster;
	}

	@ContentChildren(CellForDirective) cells: QueryList<CellForDirective>;
	@Output() sortChange = new EventEmitter<SortStatus>();
	@Output() selectionChange = new EventEmitter<void>();
	@Output() userPreferencesChange = new EventEmitter<TablePreferences>();
	@Output() visibleColumnsChange = new EventEmitter<TableColumnVisible[]>();

	status: TableStatus<T> = {
		loading: false,
		error: undefined,
		selected: [],
		filtered: false,
		sorted: false,
		empty: true,
	};
	ready = new Subject<void>();

	protected cellsTemplate: Record<string, TemplateRef<any>>;
	protected rows: TableRow<T>[] = [];
	protected rowAction?: (element: T) => void;

	private readonly defaultPageSize = 10;
	private rowLink?: TableRowLinkFunction<T>;
	private itemsDeprecated: boolean;
	private _datasource?: TableDataSource<T>;
	private dataSubscription: Subscription | null;
	private subscriptions: Subscription = new Subscription();
	private sortSubscription?: Subscription;
	private selectSubscriptions?: Subscription;
	private _showLoad: boolean;
	private _defaultPreferences?: TablePreferences;
	private _select?: SelectMasterDirective<T>;
	private _sortMaster?: SortDirective;
	private classListObserver: ClassListObserver;
	private window = inject(WindowWrapper) as Window;
	private screenWidthCache = this.window.innerWidth;
	private currentTemplate: TemplateRef<TableComponent<T>>;
	private changeDetectorRef = inject(ChangeDetectorRef);
	private ngZone = inject(NgZone);
	private modalService = inject(ModalService);
	private translateService = inject(TranslateService);
	private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
	private destroyRef = inject(DestroyRef);
	private dataLookupService = inject(DataLookupService);
	private windowResizeEventHandler = inject(WindowResizeEventHandler, { optional: true });
	private panelComponent = inject(PanelComponent, { optional: true });
	private tablePreferencesProvider = inject(TablePreferencesProvider, { optional: true });

	@Input() set datasource(v: TableDataSource<T> | null) {
		if (this._datasource !== v) {
			this.changeDatasource(v ?? undefined);
		}

		this.refresh();
	}

	get datasource() {
		return this._datasource ?? null;
	}

	get columns(): TableConfigColumn<T>[] {
		return [
			{ name: '__select', hidden: !this.config.selectable },
			...this.config.columns,
			{ name: '__actions', hidden: !this.config.selectable },
		];
	}

	get showStatusAlways() {
		return this.panelComponent?.bottomThreshold != null && !this.status.exhausted;
	}

	get showLoad() {
		return this.showStatusAlways && this._showLoad;
	}

	get defaultPreferences(): TablePreferences | undefined {
		if (!this._defaultPreferences) {
			return undefined;
		}

		// Return a copy to guarantee the private default to be untouched
		return {
			columns: this._defaultPreferences.columns ? JSON.parse(JSON.stringify(this._defaultPreferences.columns)) as TableColumnVisible[] : undefined,
			sort: this._defaultPreferences.sort ? JSON.parse(JSON.stringify(this._defaultPreferences.sort)) as SortStatus : undefined,
		};
	}

	get cardsExpanded() {
		return !this.rows.find((row) => !row.context.expanded);
	}

	// Disables checkboxes once limit has been reached
	get canSelect(): boolean {

		if (!this.config.selectable || typeof this.config.selectable === 'boolean') {
			return false;
		}

		/**
		 * If selectable set to negative becomes unlimited not sure if this ok or weather user
		 * should have to set an extra large positive amount eg 10,000
		 */
		return this.config.selectable > this.status.selected.length || this.config.selectable < 0;
	}

	private get pageSize() {
		return this.config.pageSize ?? this.defaultPageSize;
	}

	ngOnInit() {
		this.rowAction = this.config.row?.action ?? this.config.rowAction;
		this.rowLink = this.config.row?.link ?? this.config.rowLink;

		// Store initial config columns and sort preferences
		this._defaultPreferences = {
			sort: this.sort ? JSON.parse(JSON.stringify(this.sort)) as SortStatus : undefined,
			columns: this.config.columns.map((c) => ({ name: c.name, visible: !c.hidden })),
		};

		this.applyUserPreferences();
	}

	ngAfterContentInit() {
		// make a lookup out of cell templates
		this.cellsTemplate = this.cells.reduce((acc, cell) => {
			acc[cell.cellFor] = cell.template;

			return acc;
		}, {} as Dictionary<TemplateRef<any>>);
	}

	ngAfterViewInit() {
		this.classListObserver = new ClassListObserver(
			this.elementRef.nativeElement,
			(classList: string[]) => { this.setTemplate(classList, this.screenWidthCache); },
			this.destroyRef,
		);

		if (this.windowResizeEventHandler) {
			this.windowResizeEventHandler.register({
				listener: this.onResize.bind(this),
				destroy: this.destroyRef,
				fireOnRegister: true,
			});
		} else {
			this.setTemplate(this.elementRef.nativeElement.className.split(' '), this.screenWidthCache);
		}

		this.ready.next();

		// Provide self load button
		if (this.panelComponent?.bottomThreshold != null) {
			this.subscriptions.add(timer(100).subscribe(() => {

				if (!this.panelComponent) {
					return;
				}

				const show = !this.panelComponent.hasScrollBar;

				if (this._showLoad !== show) {
					this._showLoad = show;
					this.refresh();
				}
			}));
		}
	}

	ngOnDestroy() {
		this.sortSubscription?.unsubscribe();
		this.selectSubscriptions?.unsubscribe();
		this.subscriptions.unsubscribe();
		this.dataSubscription?.unsubscribe();
	}

	/** Request to load the [next page of] data */
	load() {

		if (!this.datasource) {
			console.warn('Table: Requested load without a datasource, skipped');
			this.refresh();

			return;
		}

		if (this.status.exhausted) {
			console.warn('Table: All records loaded');
			this.refresh();

			return;
		}

		if (this.status.loading) {
			console.warn('Table: loading in progress, skipped');
			this.refresh();

			return;
		}

		const offset = this.itemsDeprecated ? 0 : this.rows.length;

		this.status.error = undefined;
		this.status.loading = true;
		this.datasource.loadController = new AbortController();

		// pageSize + 1 include "1st of next page" into the request range, to test for exhausted stream
		if (this.config.exhaustAhead) {
			this.datasource.load({ offset, limit: this.pageSize + 1 });
		} else {
			this.datasource.load({ offset, limit: this.pageSize });
		}

		this.refresh();
	}

	/** Position of the item inside the table
	 *
	 * @param T - The item
	 * @param trackBy - the keyof T to use for comparison
	 * @returns The index inside the table
	 */
	indexOf(item: T, trackByFn?: TrackByFunction<T>): number {
		return this.rows.findIndex((row, index) =>
			trackByFn ?
				trackByFn(index, row.context.$implicit) === trackByFn(index, item) :
				row.context.$implicit === item,
		);
	}

	getRow(index: number): TableRow<T> | undefined {
		return this.rows[index];
	}

	/** Update the item on index position with the newValue
	 *
	 * @param index - The position based item to update
	 * @param newItem - Item to replace the existing one
	 */
	updateItem(index: number, newItem: T): boolean {

		// Guard valid index
		const row = this.getRow(index);

		if (!row) {
			return false;
		}

		// Replace item in the select
		if (this.config.selectable) {
			this.select?.updateSelectItem(row.context.$implicit, newItem);
		}

		// Update row
		row.context.$implicit = newItem;
		row.context.stale = true;
		this.onUpdateRow(row, { item: true, context: true });

		this.refresh();

		return true;
	}

	/** Add new item to the table, append when no index is specified
	 *
	 * @param item - The item to add to the table
	 */
	addItem(item: T) {

		// Update last row context
		const lastRow = this.getRow(this.rows.length - 1);

		if (lastRow) {
			lastRow.context.last = false;
			this.onUpdateRow(lastRow, { context: true });
		}

		this.rows.push(this.createRow(
			item,
			this.rows.length,
			{ last: true, stale: true },
		));

		// Update status
		this.status.empty = false;

		this.refresh();
	}

	onRowAction(item: T) {
		if (!this.rowAction) {
			return;
		}

		const rowAction = this.rowAction;

		this.ngZone.run(() => { rowAction(item); });
	}

	refresh() {
		// UNIFII-2813 TODO Verify why breaks without Timeout
		setTimeout(() => {
			this.changeDetectorRef.detectChanges();
		}, 0);
	}

	updateVisibleColumns(columns: TableColumnVisible[], skipNotify?: boolean) {
		for (const columnConfig of this.config.columns) {
			const found = columns.find((c) => columnConfig.name === c.name);

			if (found) {
				columnConfig.hidden = !found.visible;
			}
		}

		if (!skipNotify) {
			this.notifyUserPreferences();
			this.visibleColumnsChange.emit(this.config.columns.map((c) => ({ name: c.name, visible: !c.hidden })));
		}

		this.refresh();
	}

	toggleCardExpanded(context?: TableRowContext<T>) {
		if (!context) {
			const cardsExpanded = this.cardsExpanded;

			for (const row of this.rows) {
				row.context.expanded = !cardsExpanded;
				this.onUpdateRow(row, { context: true });
			}
		} else {
			const row = this.getRow(this.indexOf(context.$implicit));

			if (row) {
				row.context.expanded = !row.context.expanded;
				this.onUpdateRow(row, { context: true });
			} else {
				// TODO should not reach this code, improve the API input ?
				context.expanded = !context.expanded;
			}
		}

		this.refresh();
	}

	// Invoked from ngForOf, need bind to `this`
	protected trackBy = (index: number, row: TableRow<T>): any => {

		if (!this.config.trackByFn) {
			return row.context.$implicit;
		}

		return this.config.trackByFn(index, row.context.$implicit);
	};

	private clear() {
		// Remove loaded data, out of sync with new source
		this.itemsDeprecated = false;
		this.rows = [];
		this.status.empty = false;
	}

	private changeDatasource(datasource?: TableDataSource<T>) {

		this.itemsDeprecated = true;
		this.status.error = undefined;
		this.status.loading = false;

		// Stop listening to current datasource
		this.dataSubscription?.unsubscribe();
		// Notify current datasource that the component is not listening anymore
		this.datasource?.disconnect();

		// Store new datasource
		this._datasource = datasource;

		if (!this.datasource) {
			this.status.sorted = false;
			this.status.filtered = false;
			this.refresh();

			return;
		}

		this.status.sorted = this.datasource.sorted;
		this.status.filtered = this.datasource.filtered;
		this.status.exhausted = undefined;

		// Listen to datasource
		this.dataSubscription = this.datasource.connect().subscribe({
			next: (result) => {
				this.status.exhausted = false;

				if (this.datasource) {
					this.datasource.loadController = undefined;
				}

				this.status.loading = false;

				if (this.itemsDeprecated) {
					this.clear();
				}

				if (result.error) {
					this.status.error = ensureUfRequestError(result.error);
					console.error('Table: loading ERROR:', this.status.error.message);

				} else if (result.data) {

					if (this.pageSize < 0 || (!this.config.exhaustAhead && result.data.length < this.pageSize) || (this.config.exhaustAhead && result.data.length <= this.pageSize)) {
						this.status.exhausted = true;
					} else if (this.config.exhaustAhead) {
						// Remove the additional item requested to check for exhausted
						result.data.pop();
					}

					if (result.data.length) {

						// Update last row context
						const lastRow = this.getRow(this.rows.length - 1);

						if (lastRow) {
							lastRow.context.last = false;
							this.onUpdateRow(lastRow, { context: true });
						}

						this.status.empty = false;
						this.status.error = undefined;

						const data = result.data;
						const appendFromIndex = this.rows.length;

						// First create contexts for new items
						this.rows.push(
							...data.map((item, index) =>
								this.createRow(
									item,
									appendFromIndex + index,
									{ last: index === data.length - 1 },
								),
							),
						);

					} else {

						if (!this.rows.length) {
							this.status.empty = true;
						}
					}
				} else {
					console.warn('Table: Data Source stream missing data and error values', result);
				}

				this.refresh();

			}, error: (error) => {
				console.error('Table: Data Source stream unexpected error', error);
				this.status.error = ensureUfRequestError(error);
				this.status.loading = false;

				if (this.datasource) {
					this.datasource.loadController = undefined;
				}

				this.refresh();
			},
		});

		// Trigger first data request
		this.load();
	}

	private createRow(item: T, index: number, options?: { last?: boolean; stale?: boolean } ) {

		const context: TableRowContext<T> = {
			$implicit: item,
			stale: !!options?.stale,
			selected: false,
			index,
			first: index === 0,
			last: !!options?.last,
			expanded: false,
		};

		const link = this.getRowLink(item);
		const image = this.getRowImage(item);
		const label = this.getRowLabel(item);
		const columnsValue = this.getRowColumnsValue(item, index);
		const availableActions = this.getRowAvailableActions(context);
		const memoize: TableRowMemoize<T> = { link, image, label, columnsValue, availableActions };

		return { context, memoize } as TableRow<T>;
	}

	private onUpdateRow(row: TableRow<T>, changes: {item?: boolean; index?: boolean; context?: boolean}) {

		if (changes.item || changes.index) {
			row.memoize.columnsValue = this.getRowColumnsValue(row.context.$implicit, row.context.index);
		}

		if (changes.item) {
			row.memoize.link = this.getRowLink(row.context.$implicit);
			row.memoize.image = this.getRowImage(row.context.$implicit);
			row.memoize.label = this.getRowLabel(row.context.$implicit);
		}

		if (changes.context) {
			row.memoize.availableActions = this.getRowAvailableActions(row.context);
		}
	}

	private getRowLink(item: T) {
		return this.rowLink ? this.rowLink(item) : undefined;
	}

	private getRowImage(item: T) {
		return this.config.row?.image ? this.config.row.image(item) : undefined;
	}

	private getRowLabel(item: T) {
		return this.config.row?.label ? this.config.row.label(item) : undefined;
	}

	private getRowColumnsValue(item: T, index: number) {
		return this.config.columns.reduce<Record<string, unknown>>((values, column) => {

			// Skip value computation for custom cells
			if (this.cellsTemplate[column.name]) {
				return values;
			}

			let display: unknown;

			if (column.value) {
				display = column.value(item, index);
			} else {
				display = this.dataLookupService.lookupData(item as Record<string, unknown>, column.name, 'TableComponent column display via identifier');
			}

			values[column.name] = display;

			return values;
		}, {});
	}

	private getRowAvailableActions(context: TableRowContext<T>) {
		return this.config.actions?.filter((action) => {
			if (!action.predicate) {
				return true;
			}

			return action.predicate(context);
		}).map((action) => {
			const copyAction = Object.assign({}, action) as TableAction<T>;

			delete copyAction.predicate;

			return copyAction;
		});
	}

	private onSelectionChange(changes: SelectionChange<T>) {

		for (const added of changes.added) {
			const row = this.rows[this.indexOf(added)] as TableRow<T> | undefined;

			if (row) {
				row.context.selected = true;
				this.onUpdateRow(row, { context: true });
			}
		}

		for (const removed of changes.removed) {
			const row = this.rows[this.indexOf(removed)] as TableRow<T> | undefined;

			if (row) {
				row.context.selected = false;
				this.onUpdateRow(row, { context: true });
			}
		}

		// TODO Performance bottleneck
		this.status.selected = this.select?.selected ?? [];
		this.selectionChange.emit();
	}

	/*
	* Load [TablePreferences] to override @input configurations on component initialization
	* TablePreferences.sort managed by the container component to override the @Input sort.
	* This is to avoid the pattern of change an @Input parameter on component initialization
	*/
	private applyUserPreferences() {

		let userTablePreferences: TablePreferences | null = null;

		if (this.tablePreferencesProvider && this.config.id) {
			userTablePreferences = this.tablePreferencesProvider.loadTablePreferences(this.config.id);
		}

		if (!userTablePreferences) {
			return;
		}

		if (userTablePreferences.columns) {
			this.updateVisibleColumns(userTablePreferences.columns, true);
		}

		// userTablePreferences.sort to be manage by the container component
	}

	private notifyUserPreferences() {

		const userTablePreferences: TablePreferences = {
			sort: this.sortMaster?.sort,
			columns: this.config.columns.map((c) => ({ name: c.name, visible: !c.hidden })),
		};

		this.userPreferencesChange.next(userTablePreferences);

		if (this.tablePreferencesProvider && this.config.id) {
			this.tablePreferencesProvider.saveTablePreferences(this.config.id, userTablePreferences);
		}
	}

	private showSelectionLimitModal() {
		void this.modalService.openAlert({
			title: this.translateService.instant(CommonTranslationKey.FeedbackModalAlertTitle) as string,
			message: this.translateService.instant(CommonTranslationKey.TableFeedbackMaxiumSelections) as string,
		});
	}

	private onResize = ({ innerWidth }: WindowResizeInfo) => {
		this.screenWidthCache = innerWidth;

		this.setTemplate(this.classListObserver.classList, innerWidth);
	};

	private setTemplate(classList: string[], innerWidth: number) {

		const template = this.getTemplate(classList, innerWidth);

		if (template === this.currentTemplate) {
			return;
		}

		this.sortSubscription?.unsubscribe();
		this.selectSubscriptions?.unsubscribe();
		this.subscriptions.unsubscribe();
		this.outletRef.clear();
		this.outletRef.createEmbeddedView(template);
		this.changeDetectorRef.detectChanges();
		this.currentTemplate = template;
	}

	private getTemplate(classList: string[], screenSize: number): TemplateRef<TableComponent<T>> {
		const modifierClass = classList.find((name) => TableRenderModifierClassNames.includes(name));

		if (modifierClass) {
			const modifierSplitted = modifierClass.split('-');
			const renderType = modifierSplitted[0];
			const breakpoint = modifierSplitted[1];

			if (!renderType) {
				throw new Error('TableComponent.getTemplate renderType not found');
			}

			const templateRef = this.getTemplateRef(renderType);
			const breakpointInt = this.getBreakpointInt(breakpoint);

			if (breakpointInt == null || screenSize <= breakpointInt) {
				return templateRef;
			}
		}

		return this.tableTemplateRef;
	}

	/**
	 * @param name 'list' | 'cards'
	 */
	private getTemplateRef(name: string): TemplateRef<TableComponent<T>> {
		switch (name) {
			case 'list':
				return this.listTemplateRef;
			case 'cards':
				return this.cardsTemplateRef;
			default:
				return this.tableTemplateRef;
		}
	}

	/**
	 * @param name 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
	 */
	private getBreakpointInt(name: string | undefined): number | undefined {
		switch (name) {
			case 'sm':
				return 576;
			case 'md':
				return 768;
			case 'lg':
				return 992;
			case 'xl':
				return 1200;
			case 'xxl':
				return 1584;
			default:
				return;
		}
	}

}
