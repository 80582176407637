import { ChangeDetectorRef, Component, DestroyRef, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';

import { KeyCodes } from '../../constants';
import { NamePropertyInfo } from '../../models';
import { DOMEventHandler } from '../../services';
import { CommonTranslationKey, SharedTermsTranslationKey } from '../../translations';
import { ProgressComponent } from '../indicators';
import { Modal, ModalData, ModalRuntime } from '../modal';

export interface UfChipsBulkSelectModalData {
	search: (query: string | null) => void;
	optionsSubject: Subject<any[] | undefined>;
	nameProperty: NamePropertyInfo | null | undefined;
	minSearchLength: number | undefined;
	minSearchLengthMsg: string | undefined;
}

@Component({
	selector: 'uf-chips-bulk-select-modal',
	templateUrl: './uf-chips-bulk-select-modal.html',
})
export class UfChipsBulkSelectModalComponent implements Modal<UfChipsBulkSelectModalData, any[]>, OnInit, OnDestroy {

	@HostBinding('class.uf-form-card') formCardClass = true;
	@ViewChild(ProgressComponent, { static: true }) progress: ProgressComponent | undefined;

	runtime = inject<ModalRuntime<UfChipsBulkSelectModalData, any[]>>(ModalRuntime); 
	data = inject<UfChipsBulkSelectModalData>(ModalData);

	protected readonly commonTK = CommonTranslationKey;
	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected query: string | null | undefined;
	protected searchMessage: string | undefined;
	protected options: any[] = [];
	protected selectedOptions: boolean[] = [];
	protected activeIndex = 0;
	protected showSelectAll = false;
	protected showDeselectAll = false;

	private subscription = new Subscription();
	private domEventHandler = inject(DOMEventHandler);
	private destroyRef = inject(DestroyRef);
	private elementRef = inject(ElementRef);
	private translateService = inject(TranslateService);
	private cdr = inject(ChangeDetectorRef);

	ngOnInit() {
		this.subscription.add(this.data.optionsSubject.subscribe((options) => {
			this.applyOptions(options);
			this.setSelectedOptions(false);
			this.progress?.complete();
			this.cdr.detectChanges();
		}));

		this.domEventHandler.register({
			element: this.elementRef.nativeElement,
			event: 'keydown',
			listener: this.onKeyDown.bind(this),
			destroy: this.destroyRef,
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	close() {
		this.runtime.close();
	}

	protected onSearch(query: string) {
		this.searchMessage = undefined;
		this.options = [];

		// Guard the minSearchLength requirement
		if (!this.data.minSearchLength || (query && query.length >= this.data.minSearchLength)) {
			this.progress?.start(.4);
			this.data.search(query);

			return;
		}

		// MinSearchLength not met => set options to undefined
		this.data.optionsSubject.next(undefined);
	}

	protected toggleSelection(index: number) {
		if (this.selectedOptions[index] == null) {
			return;
		}
		
		this.selectedOptions[index] = !this.selectedOptions[index];
		this.updateToggleAllButtonsVisibility();
	}

	protected selectAll() {
		this.setSelectedOptions(true);
	}

	protected deselectAll() {
		this.setSelectedOptions(false);
	}

	protected add() {
		this.runtime.close(this.options.filter((_, index) => this.selectedOptions[index]));
	}

	private applyOptions(options: any[] | undefined) {
		this.options = options ?? [];
		this.searchMessage = undefined;
		this.activeIndex = 0;

		if (!options && this.data.minSearchLength && this.data.minSearchLength > (this.query?.length ?? 0)) {
			this.searchMessage = this.translateService.instant(SharedTermsTranslationKey.SearchLabelMinSearchLength, { length: this.data.minSearchLength });
		}

		if (options?.length === 0) {
			this.searchMessage = this.data.minSearchLengthMsg ?? this.translateService.instant(SharedTermsTranslationKey.SearchLabelNoResults);
		}
	}

	private setSelectedOptions(initialValue: boolean) {
		this.selectedOptions = new Array(this.options.length).fill(initialValue);
		this.updateToggleAllButtonsVisibility();
	}

	private updateToggleAllButtonsVisibility() {
		if (!this.options.length) {
			this.showSelectAll = false;
			this.showDeselectAll = false;
		
			return;
		}

		const allOptionsSelected = this.selectedOptions.every((selected) => selected);

		this.showSelectAll = !allOptionsSelected;
		this.showDeselectAll = allOptionsSelected;
	}

	private onKeyDown(event: KeyboardEvent) {
		event.stopImmediatePropagation();
		
		const keyCode = event.code as KeyCodes;

		if (!this.options.length ||	![KeyCodes.ArrowDown, KeyCodes.ArrowUp, KeyCodes.Enter].includes(keyCode)) {
			return;
		}

		switch (keyCode) {
			case KeyCodes.ArrowDown:
			case KeyCodes.ArrowUp:
				this.activeIndex = this.getNextActive(keyCode === KeyCodes.ArrowDown);
				break;
			case KeyCodes.Enter:
				this.toggleSelection(this.activeIndex);
		}
	}

	private getNextActive(down: boolean): number {
		if (this.activeIndex === 0 && !down) {
			return this.options.length - 1;
		}

		if (this.activeIndex === this.options.length -1 && down) {
			return 0;
		}

		return this.activeIndex + (down ? 1 : -1);
	}

}
