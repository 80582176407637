import { CommonModule as ngCommonModule } from '@angular/common';
import { NgModule, RendererFactory2 } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Client, CompaniesClient, ExternalDataSourcesClient, HierarchyClient, MeClient, ProjectContentOptions, ProjectContentOptionsInterface, TenantClient, UsersClient } from '@unifii/sdk';

import { BarcodeScannerModalProvider, DATA_DISPLAY_COMPONENTS, UF_CAROUSEL_COMPONENTS, UF_CASCADE_SELECTION_COMPONENTS, UF_CONTAINER_COMPONENTS, UF_DROPDOWN_TABS_COMPONENTS, UF_DROP_MENU_COMPONENTS, UF_FILTERS_COMPONENTS, UF_FORM_COMPONENTS, UF_ICON_COMPONENTS, UF_INDICATOR_COMPONENTS, UF_MAP_COMPONENTS, UF_MODAL_COMPONENTS, UF_NAVIGATION_COMPONENTS, UF_TABLE_COMPONENTS, UF_TAB_COMPONENTS, UF_TOAST_COMPONENTS, UfBarcodeScannerModalTextProvider } from './components';
import { COMMON_DIRECTIVES } from './directives';
import { FilterAstNodeAdapter, FilterComponentRegistry, FilterSerializer, HierarchyUnitProvider, LocationProvider } from './models';
import { WindowWrapper } from './native';
import { COMMON_PIPES } from './pipes';
import { BucketDataDescriptorAdapterLoader, CollectionDataDescriptorAdapterLoader, DataDisplayComponentRegistry, DataDisplayService, DataDisplayTableComponentRegistry, FeatureFlagProvider, FieldDescriptionService, FieldDescriptionServiceProvider, FilterEntryAdapter, FilterEntryDataDescriptorAdapter, MarkdownProvider, ThemeProvider, ThemeService, UfBucketDataDescriptorAdapterLoader, UfCollectionDataDescriptorAdapterLoader, UfDataDisplayComponentRegistry, UfDataDisplayService, UfDataDisplayTableComponentRegistry, UfFeatureFlagProvider, UfFilterAstNodeAdapter, UfFilterComponentRegistry, UfFilterEntryAdapter, UfFilterEntryDataDescriptorAdapter, UfFilterSerializer, UfHierarchyUnitProvider, UfLocationProvider, UfMarkdownService, UfUserDataDescriptorAdapterLoader, UserDataDescriptorAdapterLoader } from './services';

const createThemeProvider = (window: Window, rendererFactory: RendererFactory2): ThemeService =>
	new ThemeService(window.document.documentElement, rendererFactory);

@NgModule({
	imports: [
		ngCommonModule, FormsModule, ReactiveFormsModule, TranslateModule, RouterModule, HammerModule,
	],
	declarations: [
		COMMON_PIPES, COMMON_DIRECTIVES, UF_FORM_COMPONENTS, UF_ICON_COMPONENTS, UF_MAP_COMPONENTS, UF_MODAL_COMPONENTS, UF_TOAST_COMPONENTS,
		UF_INDICATOR_COMPONENTS, DATA_DISPLAY_COMPONENTS, UF_NAVIGATION_COMPONENTS, UF_TABLE_COMPONENTS, UF_TAB_COMPONENTS,
		UF_DROP_MENU_COMPONENTS, UF_CONTAINER_COMPONENTS, UF_CAROUSEL_COMPONENTS, UF_FILTERS_COMPONENTS, UF_DROPDOWN_TABS_COMPONENTS,
		UF_CASCADE_SELECTION_COMPONENTS,
	],
	exports: [
		ngCommonModule, FormsModule, ReactiveFormsModule, TranslateModule,
		COMMON_PIPES, COMMON_DIRECTIVES, UF_FORM_COMPONENTS, UF_ICON_COMPONENTS, UF_MAP_COMPONENTS, UF_MODAL_COMPONENTS, UF_TOAST_COMPONENTS,
		UF_INDICATOR_COMPONENTS, DATA_DISPLAY_COMPONENTS, UF_NAVIGATION_COMPONENTS, UF_TABLE_COMPONENTS, UF_TAB_COMPONENTS,
		UF_DROP_MENU_COMPONENTS, UF_CONTAINER_COMPONENTS, UF_CAROUSEL_COMPONENTS, UF_FILTERS_COMPONENTS, UF_DROPDOWN_TABS_COMPONENTS,
		UF_CASCADE_SELECTION_COMPONENTS,
	],
	providers: [
		COMMON_PIPES,
		{ provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
		{ provide: ThemeProvider, useFactory: createThemeProvider, deps: [WindowWrapper, RendererFactory2] },
		{ provide: MarkdownProvider, useClass: UfMarkdownService },
		{ provide: LocationProvider, useClass: UfLocationProvider },
		{ provide: FieldDescriptionServiceProvider, useClass: FieldDescriptionService },
		{ provide: FeatureFlagProvider, useClass: UfFeatureFlagProvider },
		{ provide: FilterEntryAdapter, useClass: UfFilterEntryAdapter },
		{ provide: FilterEntryDataDescriptorAdapter, useClass: UfFilterEntryDataDescriptorAdapter },
		{ provide: FilterComponentRegistry, useClass: UfFilterComponentRegistry },
		{ provide: FilterSerializer, useClass: UfFilterSerializer },
		{ provide: FilterAstNodeAdapter, useClass: UfFilterAstNodeAdapter },
		{ provide: BarcodeScannerModalProvider, useValue: UfBarcodeScannerModalTextProvider },
		{ provide: UserDataDescriptorAdapterLoader, useClass: UfUserDataDescriptorAdapterLoader },
		{ provide: CollectionDataDescriptorAdapterLoader, useClass: UfCollectionDataDescriptorAdapterLoader },
		{ provide: BucketDataDescriptorAdapterLoader, useClass: UfBucketDataDescriptorAdapterLoader },
		{ provide: HierarchyUnitProvider, useClass: UfHierarchyUnitProvider },
		{ provide: DataDisplayService, useClass: UfDataDisplayService },
		{ provide: DataDisplayComponentRegistry, useClass: UfDataDisplayComponentRegistry },
		{ provide: DataDisplayTableComponentRegistry, useClass: UfDataDisplayTableComponentRegistry },
		// SDK
		{ provide: TenantClient, useFactory: (client: Client) => new TenantClient(client), deps: [Client] },
		{ provide: UsersClient, useFactory: (client: Client) => new UsersClient(client), deps: [Client] },
		{ provide: CompaniesClient, useFactory: (client: Client) => new CompaniesClient(client), deps: [Client] },
		{ provide: MeClient, useFactory: (client: Client) => new MeClient(client), deps: [Client] },
		{ provide: HierarchyClient, useFactory: (client: Client) => new HierarchyClient(client), deps: [Client] },
		{ provide: ExternalDataSourcesClient, useFactory: (client: Client, options: ProjectContentOptionsInterface) => new ExternalDataSourcesClient(client, options), deps: [Client, ProjectContentOptions] },
	],
})
export class CommonModule { }
