/* eslint-disable @typescript-eslint/naming-convention */
import { InjectionToken } from '@angular/core';

export interface GoogleMapsLatLng {
	lat(): number;
	lng(): number;
	equals(other: GoogleMapsLatLng): boolean;
	toString(): string;
	toUrlValue(precision?: number): string;
	toJSON(): google.maps.LatLngLiteral;
}

export interface GoogleMaps {
	places: any; /** no type available use class google.maps.places on variable */
	MapTypeId: google.maps.MapTypeId;
	Map: any; /** no type available use class google.maps.Map on variable */
	Marker: any; /** no type available use class google.maps.Marker on variable */
	GeocoderStatus: google.maps.GeocoderStatus;
	Geocoder: any; /** no type available use class google.maps.Geocoder on variable */
	event: any; /** no type available use class google.maps.event on variable */
	visualRefresh: boolean;
	LatLng: any; /** no type available use class google.maps.LatLng on variable */
	LatLngBounds: any; /** no type available use class google.maps.LatLngBounds on variable */
}

export const GoogleMaps = new InjectionToken<GoogleMaps>('GoogleMaps');
