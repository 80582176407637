import { Component, OnInit, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ClipboardService, PasswordService } from '../../services';
import { CommonTranslationKey } from '../../translations';

import { UfPasswordComponent } from './uf-password.component';

/**
 * Super charged password input includes:
 *  - Copy action
 *  - Generate password option
 *  - have I been pwned check
 */
@Component({
	selector: 'uf-password-advanced',
	templateUrl: './uf-password-advanced.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfPasswordAdvancedComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-password-advanced.less'],
})
export class UfPasswordAdvancedComponent extends UfPasswordComponent implements OnInit {

	readonly commonTS = CommonTranslationKey;

	protected pwned: boolean;
	protected clipboard = inject(ClipboardService);

	private passwordService = inject(PasswordService);

	ngOnInit() {
		this.subscriptions.add(this.control.valueChanges.pipe(
			debounceTime(300),
			distinctUntilChanged())
			.subscribe((v: string) => void this.onPasswordChange(v)),
		);
	}

	protected async generatePassword() {
		const password = await this.passwordService.generate();

		this.control.markAsTouched();
		this.control.markAsDirty();
		this.control.setValue(password, { emitEvent: true, onlySelf: false });
	}

	private async onPasswordChange(v: string) {
		this.pwned = false;

		if (this.control.valid) {
			this.pwned = await this.passwordService.hasBeenPwned(v);
		}
	}

}
