<div class="uf-app-bar flat transparent">
	<button *ngIf="showCode" (click)="updateInnerHTML(); visible = !visible" type="button"
		class="uf-action tertiary right" title="Show code">
		<uf-icon [name]="visible ? 'viewHidden' : 'view'" />
	</button>
	<button *ngIf="showCopy" (click)="copy()" type="button" class="uf-action tertiary" title="Copy code">
		<uf-icon name="copy" />
	</button>
</div>
<div #content (mutations)="updateInnerHTML()" class="content" domObserver>
	<ng-content></ng-content>
</div>
<pre *ngIf="visible" class="gap-none">
  <code>{{code || innerHTML}}</code>
</pre>