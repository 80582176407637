import { AfterViewInit, ChangeDetectorRef, Component, ContentChild, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, TemplateRef, ViewChild, inject } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Dictionary, generateUUID } from '@unifii/sdk';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { UfControl } from '../../controls';
import { NamePropertyInfo } from '../../models';
import { NamePropertyPipe } from '../../pipes';
import { SharedTermsTranslationKey } from '../../translations';
import { ProgressComponent } from '../indicators';

import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-autocomplete',
	templateUrl: './uf-autocomplete.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfAutoCompleteComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-autocomplete.less', './uf-input-action-button.less'],
})
export class UfAutoCompleteComponent extends UfControlValueAccessor<any> implements OnInit, AfterViewInit {

	// TemplateRef 'listBox' is mapped into the ListBoxDirective @Input template
	@ContentChild('listBox', { static: true }) listBoxTemplate: TemplateRef<any>;
	@ViewChild('input', { static: true }) input: ElementRef | undefined;
	@ViewChild(ProgressComponent, { static: true }) progress: ProgressComponent | undefined;

	@Input() name: string | null | undefined;
	@Input() label: string | null | undefined;
	@Input() placeholder: string | null | undefined;
	@Input() debounce: number | null | undefined = 200;
	@Input() autofocus: boolean | null | undefined;
	@Input() nameProperty: NamePropertyInfo | null | undefined; /** Configure the component to work with objects and nameProperty is the attribute used to display its representation */
	@Input() allowCustom: boolean | null | undefined; /** Allow the user to provide custom value outside of the options range */
	@Input() noResultsMsg: string | null | undefined;
	@Input() minSearchLength: number | null | undefined = 0;
	@Input() minSearchLengthMsg: string | null | undefined;
	@Input() focusAfterDelete: boolean | null | undefined = true;
	@Output() searchChange = new EventEmitter<string>(); /** Notify search criteria has changed */
	@Output() override valueChange = new EventEmitter<any>(); /** Notify the selected value has changed */

	/** Chrome autofill no longer accepts autocomplete="off" for now a random value seems to work
	in the future we may have to create a unique value for each */
	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly autocomplete = generateUUID();
	protected inputControl = new FormControl('');
	protected focused = false;
	protected hasSearchConfigured = false;

	private _options: any[] | null | undefined;
	private initialized = false;
	private namePropertyPipe = inject(NamePropertyPipe);
	private cdr = inject(ChangeDetectorRef);

	ngOnInit() {
		this.onDisabledChanges(this.control.disabled);

		if (this.value != null) {
			this.updateInputControl();
		}

		this.subscriptions.add(this.inputControl.valueChanges.pipe(
			debounceTime(this.debounce ?? 0),
			distinctUntilChanged(),
		).subscribe((q) => { this.inputValueChange(q); }));

		this.hasSearchConfigured = this.searchChange.observed;
		this.initialized = true;
	}

	ngAfterViewInit() {
		if (!this.input) {
			return;
		}

		this.subscriptions.add(fromEvent(this.input.nativeElement, 'focus').subscribe(() => { this.onFocus(); }));
		// debounce to guarantee onSelect to execute before blur, avoid updateInputControl race condition
		this.subscriptions.add(fromEvent(this.input.nativeElement, 'blur').pipe(debounceTime(100)).subscribe(() => { this.onBlur(); }));

		if (this.autofocus) {
			this.input.nativeElement.focus();
		}
	}

	@Input() override set value(v: string | Dictionary<any> | undefined | null) {
		super.value = v;
		this.updateInputControl();
	}

	override get value(): string | Dictionary<any> | undefined | null {
		return super.value;
	}

	@Input() override set control(v: UfControl) {
		super.control = v;
		this.updateInputControl();
	}

	override get control(): UfControl {
		return super.control;
	}

	@Input() set options(v: any[] | null | undefined) {
		this.progress?.complete();
		this._options = v;
	}

	get options(): any[] | null | undefined {
		return this._options;
	}

	protected clear() {
		// Race condition for options input to be updated in the ListBoxDirective
		this.safeClearOptions();
		this.cdr.detectChanges();

		this.control.setValue(null);
		this.inputControl.setValue(null);

		if (this.focusAfterDelete) {
			this.input?.nativeElement.focus();
		}
	}

	protected onSelect(value: Dictionary<any> | string = '') {
		this.control.setValue(value);
		this.updateInputControl();
	}

	protected override onDisabledChanges(disabled: boolean) {
		if (disabled) {
			this.inputControl.disable();
		} else {
			this.inputControl.enable();
		}
	}

	/** Align the inputControl with the value stored in the control to display it in the input*/
	private updateInputControl() {
		let controlDisplayValue: string | null | undefined;

		// Complex configuration => get display value
		if (this.value != null && typeof this.value === 'object') {
			// Guard for incompatible configuration
			if (!this.nameProperty && this.initialized) {
				console.warn('UfAutocomplete: (with null nameProperty) incompatible value is being set', this.value);

				return;
			}
			controlDisplayValue = this.namePropertyPipe.transform(this.value, this.nameProperty);
		} else {
			controlDisplayValue = this.value;
		}

		const inputControlValue = this.inputControl.value;

		if (inputControlValue !== controlDisplayValue) {
			this.inputControl.setValue(controlDisplayValue ?? '', { onlySelf: true, emitEvent: false });
		}
	}

	private inputValueChange(q: string | null) {
		// Guard from user not touched the input
		if (this.inputControl.pristine) {
			return;
		}

		// Clear value if input completely empty
		if (this.control.value != null && !q) {
			this.control.setValue(null);
		}

		this.guardedEmitSearchChange(q);
	}

	private guardedEmitSearchChange(q: string | null | undefined) {
		if (!this.focused) {
			return;
		}

		this.control.markAsDirty();
		this.control.markAsTouched();

		if (+(this.minSearchLength ?? 0) > (q ?? '').length) {
			return;
		}

		if (this.hasSearchConfigured) {
			this.progress?.start(.4);
			this.searchChange.emit(q ?? '');
		}
	}

	private safeClearOptions() {
		if (this.hasSearchConfigured) {
			this.options = null;
		}
	}

	private onFocus() {
		this.focused = true;

		if (this.disabled || this.value) {
			return;
		}

		/** Clear previous options and immediately fire a new search request */
		this.safeClearOptions();
		this.guardedEmitSearchChange(this.inputControl.value);
	}

	private onBlur() {
		this.focused = false;
		this.progress?.complete();
		this.updateInputControl();
	}

	@HostBinding('class.focused') get focusedClass() {
		return this.focused && !this.disabled;
	}

	@HostBinding('class.error') get errorClass() {
		return this.control.showError && !this.disabled;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	@HostBinding('class.value') get valueClass() {
		return !!this.value;
	}

}
