<div class="uf-app-bar">
	<button [title]="sharedTermsTK.ActionClose | translate" (click)="close()" type="button" class="uf-action tertiary">
		<uf-icon name="close" />
	</button>
	<h3>{{data.label}}</h3>
</div>
<uf-panel class="grow">
	<div class="uf-grid pad">
		<ul class="col-12 uf-list uf-box flat">
			<a *ngFor="let dataSeed of data.dataSeeds" (click)="onSelect(dataSeed)" class="uf-list-button">
				<uf-data-seed-mappings-display *ngIf="data.field.sourceConfig" [sourceConfig]="data.field.sourceConfig"
					[dataSeed]="dataSeed" class="shrink" />
			</a>
		</ul>
	</div>
</uf-panel>