import { Component, EventEmitter, HostBinding, OnDestroy, OnInit, Optional, QueryList, ViewChildren } from '@angular/core';
import { Compound, FormStyle, Option } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { RuntimeField, UfControlGroup } from '@unifii/library/common';
import { FieldComponent, FormContainerField, FormService } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-survey',
	templateUrl: './survey.html',
	styleUrls: ['../form-group.less', './survey.less'],
})
export class SurveyComponent implements FormContainerField, OnInit, OnDestroy {

	field: RuntimeField;
	control: UfControlGroup;
	scope: Compound;
	contentChange: EventEmitter<any>;
	collapsed: boolean;
	options: Option[] = [];

	protected widthClass: string;

	private _fieldComponentQL: QueryList<FieldComponent> | undefined;
	private subscriptions = new Subscription();

	constructor(@Optional() private formService?: FormService) { }

	@HostBinding('class.collapsed') get hostCollapsed() {
		return this.collapsed;
	}

	@HostBinding('class.disabled') get hostDisabled() {
		return this.control.disabled && !this.isSummary;
	}

	ngOnInit() {
		this.options = this.field.options;
		this.widthClass = `${this.field.width?.toLowerCase()}`;

		this.updateChildControlStatus();
		this.subscriptions.add(this.control.statusChanges.subscribe(() => { this.updateChildControlStatus(); }));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	@ViewChildren(FieldComponent) set fieldComponentQL(v: QueryList<FieldComponent> | undefined) {
		if (v != null) {
			this._fieldComponentQL = v;
			this.updateChildControlStatus();
		}
	}

	get fieldComponentQL(): QueryList<FieldComponent> | undefined {
		return this._fieldComponentQL;
	}

	isVisible(child: RuntimeField): boolean {
		return !this.formService || this.formService.isGranted(child);
	}

	get isSummary(): boolean {
		return this.formService?.style === FormStyle.Summary;
	}

	private updateChildControlStatus() {
		if (this.fieldComponentQL == null) {
			return;
		}

		const disabled = this.control.disabled;

		this.fieldComponentQL.forEach((component) => {
			if (disabled && component.control?.enabled) {
				component.control.disable({ emitEvent: false });
			} else if (!disabled && component.control?.disabled) {
				component.control.enable({ emitEvent: false });
			}
		});
	}

}
