import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-tabs.html',
})
export class ShowTabsComponent {

	protected readonly sizes = Size;
	protected readonly columnGaps = ColumnGap;

	protected readonly tableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];

	protected readonly tableInfo = [
		{ name: 'large', type: 'modifier', description: 'Large tabs' },
		{ name: 'default', type: 'modifier', description: 'Default tabs size' },
		{ name: 'small', type: 'modifier', description: 'Small tabs' },
		{ name: 'x-small', type: 'modifier', description: 'Extra small tabs' },
		{ name: 'scrollable', type: 'modifier', description: 'Makes the content inside div.content scrollable' },
		{ name: 'vertical', type: 'modifier', description: 'Makes tab go to the left of the page, being vertical instead of horizontal' },
		{ name: 'responsive', type: 'modifier', description: 'Makes tabs turn to a dropdown for mobile applications' },
	];

	protected readonly sizeOptions = [
		{ name: 'Large', value: 'large' },
		{ name: 'Medium (default)', value: '' },
		{ name: 'Small', value: 'small' },
		{ name: 'Extra Small', value: 'x-small' },
	];

	protected readonly orientationOptions = [
		{ name: 'Horizontal', value: '' },
		{ name: 'Vertical', value: 'vertical' },
	];

	protected readonly outputTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];

	protected readonly outputTableInfo = [
		{ name: 'selectedChange', type: 'EventEmitter', description: 'Emits an event when the tab is changed' },
	];

	protected size = '';
	protected orientation = '';
	protected class = '';
	protected isResponsive = false;
	protected template = `
	<uf-tabs>
		<uf-tab label="Tab 1">
			<p>
				Content 1
			</p>
		</uf-tab>
		<uf-tab icon="edit" label="Tab 2">
			<p>
				Content 2
			</p>
		</uf-tab>
		<uf-tab label="Tab 3">
			<p>
				Content 3
			</p>
		</uf-tab>
	</uf-tabs>
	`;

	protected modifierChange() {
		this.class = [this.size, this.orientation].filter((s) => s.length).join(' ');
		this.template = `
		<uf-tabs${this.class ? ' class="' + this.class + '"' : ''}>
			<uf-tab label="Tab 1">
				<p>
					Content 1
				</p>
			</uf-tab>
			<uf-tab icon="edit" label="Tab 2">
				<p>
					Content 2
				</p>
			</uf-tab>
			<uf-tab label="Tab 3">
				<p>
					Content 3
				</p>
			</uf-tab>
		</uf-tabs>
		`;
	}

}
