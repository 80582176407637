import { Injectable, Type } from '@angular/core';

// TODO Find out why the bundle import '../../components' break the unit tests and fix the following import to the root bundle?
import { FilterAutoCompleteComponent, FilterChipsComponent, FilterChoiceComponent, FilterCostComponent, FilterHierarchiesComponent, FilterMultiChoiceComponent, FilterNumberComponent, FilterNumberRangeComponent, FilterTempoComponent, FilterTempoRangeComponent, FilterTextArrayComponent, FilterTextComponent } from '../../components/filters/field-filters';
import { FilterComponentRegistry, FilterEntry, FilterType, IFilterComponent } from '../../models';

@Injectable()
export class UfFilterComponentRegistry implements FilterComponentRegistry<FilterEntry> {

	// eslint-disable-next-line complexity
	get({ type }: FilterEntry): Type<IFilterComponent> | undefined {
		switch (type) {
			case FilterType.Text:
				return FilterTextComponent;
			case FilterType.TextArray:
				return FilterTextArrayComponent;
			case FilterType.Number:
				return FilterNumberComponent;
			case FilterType.NumberRange:
				return FilterNumberRangeComponent;
			case FilterType.Cost:
				return FilterCostComponent;
			case FilterType.Time:
			case FilterType.Date:
			case FilterType.Datetime:
			case FilterType.ZonedDatetime:
				return FilterTempoComponent;
			case FilterType.TimeRange:
			case FilterType.DateRange:
			case FilterType.DatetimeRange:
			case FilterType.ZonedDatetimeRange:
				return FilterTempoRangeComponent;
			case FilterType.UserStatus:
			case FilterType.Bool:
			case FilterType.Choice:
				return FilterChoiceComponent;
			case FilterType.OptionArray:
				return FilterMultiChoiceComponent;
			case FilterType.DataSeed:
				return FilterAutoCompleteComponent;
			case FilterType.User:
			case FilterType.Company:
			case FilterType.DataSeedArray:
				return FilterChipsComponent;
			case FilterType.HierarchyUnit:
				return FilterHierarchiesComponent;
		}
	}

}
