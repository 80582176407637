import { ElementRef } from '@angular/core';
import { GeoLocation } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { GoogleMaps } from '../models';

import { GoogleLocationProvider } from './google-location-provider';
import { PatternUtil } from './pattern-util';

export class GoogleMapData {

	markerChange = new Subject<GeoLocation>();

	private googleMap: GoogleMaps['Map'];
	private patternUtil = new PatternUtil<GeoLocation>();
	private mapsMarker: any;

	constructor(
		private locationProvider: GoogleLocationProvider,
		private element: ElementRef<HTMLDivElement>,
		private marker?: GeoLocation,
	) {
		this.locationProvider.maps.visualRefresh = true;

		const position = Object.assign({}, this.locationProvider.regionGeoLocation, { zoom: 3 });

		// TODO this check exclude the value 0 for lat/lng... is it necessary? Why not using isGeoLocation instead?
		if (!!marker?.lat && !!marker.lng) {
			Object.assign(position, marker);
		}

		const options = {
			zoom: position.zoom,
			center: new this.locationProvider.maps.LatLng(position),
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			disableDoubleClickZoom: true,
			draggableCursor: 'crosshair',
			streetViewControl: false,
			scaleControl: true,
		};

		this.googleMap = new this.locationProvider.maps.Map(this.element.nativeElement, options);
		this.mapsMarker = new this.locationProvider.maps.Marker({ map: this.googleMap, draggable: true });

		this.syncGoogleMap(true);
		this.addListeners();
	}

	setMarker(marker?: GeoLocation | null) {
		if (this.patternUtil.isEqual(marker, this.marker)) {
			return;
		}

		this.marker = marker ?? undefined;
		this.syncGoogleMap(true);
	}

	getMarkerLocation(): GeoLocation {
		return Object.assign({ lat: 0, lng: 0 }, this.marker);
	}

	private addListeners() {
		/**
		 * every subscription will be kept in the location provider
		*/
		// TODO unsubscribe from these subscriptions as reference of
		this.locationProvider.maps.event.addListener(this.googleMap, 'click', (e: any) => {
			const lat = +e.latLng.lat().toFixed(5);
			const lng = +e.latLng.lng().toFixed(5);
			const zoom = this.googleMap.getZoom();

			this.marker = { lat, lng, zoom };
			this.syncGoogleMap(false);

			this.markerChange.next({ ...this.marker });
		});

		this.locationProvider.maps.event.addListener(this.googleMap, 'zoom_changed', () => {
			const zoom = this.googleMap.getZoom();

			if (zoom === this.marker?.zoom || !this.marker?.lat || !this.marker.lng) {
				return;
			}

			this.marker = { ...this.marker, zoom };
			this.syncGoogleMap(false);

			this.markerChange.next({ ...this.marker });
		});
	}

	private syncGoogleMap(panToMarker = true) {
		if (!this.marker) {
			this.mapsMarker.setPosition();
			this.mapsMarker.setMap(this.googleMap);
		
			return;
		}

		const latLng = this.getLatLng(this.marker);

		this.mapsMarker.setPosition(latLng);
		this.mapsMarker.setMap(this.googleMap);

		if (this.marker.zoom != null) {
			this.googleMap.setZoom(this.marker.zoom);
		}

		if (panToMarker) {
			this.panToMarker(this.marker.lat, this.marker.lng);
		}
	}

	private panToMarker(lat: number, lng: number) {
		const latLng = new this.locationProvider.maps.LatLng(lat, lng);

		this.googleMap.panTo(latLng);
	}

	private getLatLng(geoLocation: GeoLocation): any {
		if (!!geoLocation.lat && !!geoLocation.lng) {
			return new this.locationProvider.maps.LatLng(geoLocation.lat, geoLocation.lng);
		}

		return null;
	}

}
