import { Component, Input } from '@angular/core';

import { DataDisplayComponentRegistry, DataDisplayTableComponentRegistry } from '../../services';

const dataDisplayComponentRegistryFactory = (registry: DataDisplayComponentRegistry): DataDisplayComponentRegistry =>
	registry;

@Component({
	selector: 'uf-data-display-table-cell',
	templateUrl: './data-display-table-cell.html',
	styleUrls: ['./data-display-table-cell.less'],
	providers: [{
		provide: DataDisplayComponentRegistry,
		useFactory: dataDisplayComponentRegistryFactory,
		deps: [DataDisplayTableComponentRegistry],
	}],
})
export class DataDisplayTableCellComponent {

	protected values: unknown[] = [];
	
	private _data: unknown;

	@Input({ required: true }) set data(v: unknown) {
		this.values = Array.isArray(v) ? v : [v];
	}

	get data(): unknown {
		return this._data;
	}

}
