<label *ngIf="label">{{label}}
	<ng-content></ng-content>
</label>
<div class="input-wrap">
	<input #input [formControl]="numberControl" [attr.name]="name" [attr.placeholder]="placeholder"
		[attr.tabindex]="disabled ? -1 : 0" [readonly]="disabled" [attr.autocomplete]="autocomplete" [attr.step]="step"
		(focus)="onFocus()" (blur)="onBlur()" (paste)="onPaste($event)" (keydown)="onKey($event)"
		(input)="onInput($event)" type="number" />
	<uf-error [control]="control" />
	<uf-underline *ngIf="!disabled" [class.active]="focused" />
</div>