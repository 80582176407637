<div [translate]="commonTK.TableToolbarSelectedRowsLabel" [translateParams]="{count: table.status.selected.length}"
	class="title selected">
</div>

<div [ngClass]="{'wide': toolbarActions.length > 3}" class="flat-actions row">
	<button *ngFor="let action of toolbarActions" [disabled]="inProgress" (click)="onAction(action)"
		class="uf-button tertiary small" type="button">
		{{action.label}}
	</button>
</div>

<div [ngClass]="{'wide': toolbarActions.length > 3}" class="drop-actions">
	<uf-drop-menu [options]="toolbarActions" (selected)="onAction($event)" />
</div>

<button (click)="table.select?.deselectAll()" type="button" class="uf-action small">
	<uf-icon name="close" />
</button>