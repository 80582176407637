export enum SmartFormsTranslationKey {
	FormRepeatFieldErrorRequired = 'form_repeat_field_error_required',
	AddressInputFieldLineOneLabel = 'address_input_field_line_one_label',
	AddressInputFieldLineTwoLabel = 'address_input_field_line_two_label',
	AddressInputFieldSuburbLabel = 'address_input_field_suburb_label',
	AddressInputFieldPostcodeLabel = 'address_input_field_postcode_label',
	AddressInputFieldCityLabel = 'address_input_field_city_label',
	AddressInputFieldStateLabel = 'address_input_field_state_label',
	AddressInputFieldCountryLabel = 'address_input_field_country_label',
	AddressInputFieldMapLabel = 'address_input_field_map_label',
	GeoLocationInputFieldPositionLabel = 'geo_location_input_field_position_label',
	GeoLocationInputFieldLatitudeLabel = 'geo_location_input_field_latitude_label',
	GeoLocationInputFieldLongitudeLabel = 'geo_location_input_field_longitude_label',
	GeoLocationInputFieldZoomLabel = 'geo_location_input_field_zoom_label',
	ContentTypeTitleLabel ='content_type_title_label',
	ContentTypeUseViewerLabel ='content_type_use_viewer_label',
}
