import { Component, Input } from '@angular/core';
import { Compound, Dictionary } from '@unifii/sdk';

import { RuntimeField } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

import { CellRow, FieldTableRow } from '../../models';

@Component({
	selector: 'uf-link-list-display',
	templateUrl: './link-list.html',
	styleUrls: ['./link-list.less'],
})
export class LinkListComponent implements ContentField {

	linkList: Compound[] = [];
	tableColumns: RuntimeField[] = [];
	tableRows: FieldTableRow[] = [];

	private _content: Compound[] = [];
	private _field: RuntimeField;

	get field(): RuntimeField {
		return this._field;
	}

	@Input() set field(v: RuntimeField) {
		this._field = v;
		this.setup();
	}

	get content(): Compound[] {
		return this._content;
	}

	@Input() set content(v: Compound[] | null) {
		this._content = v ?? [];
		this.setup();
	}

	private setup() {
		if (!this.content.length || !this.field.fields.length) {
			return;
		}

		const fields = this.field.fields;
		const compounds = this.content;

		if (this.field.template !== 'table') {
			/** Show list  */
			this.linkList = compounds;

			return;
		}

		this.setupTable(fields, compounds);
	}

	private setupTable(fields: RuntimeField[], values: Compound[]) {
		const configuredColumns = this.field.columns?.length ? this.field.columns : undefined;

		this.tableColumns = this.getColumns(fields, configuredColumns);
		this.tableRows = this.getRows(this.tableColumns, values, this.content.map((item) => item.id));
	}

	private getColumns(items: RuntimeField[], columns?: { identifier: string; label: string }[]): RuntimeField[] {
		/** Columns are predefined columns */
		/** if no predefined columns show all */
		if (!columns) {
			return items;
		}

		/** Return new filtered array in correct order */
		return columns.reduce<RuntimeField[]>((result, column) => {
			/** find entry and add */
			const parsedItem = items.find((item) => item.identifier === column.identifier);

			/** Guard needed incase column no longer exists */
			if (parsedItem) {
				result.push(parsedItem);
			}

			return result;

		}, []);
	}

	private getRows(columns: RuntimeField[], values: Compound[], order: (string | undefined)[]): FieldTableRow[] {
		return order
			.filter((id) => values.find((item) => item.id === id))
			.reduce<FieldTableRow[]>((result, id) => {
				const value = values.find((item) => item.id === id);
				
				if (!value) {
					return result;
				}
				
				const row = this.addCellsToRow(columns, value);

				if (Object.keys(row).length) {
					result.push(row);
				}

				return result;
			}, []);
	}

	private addCellsToRow(columns: RuntimeField[], value: Compound): Dictionary<CellRow> {
		const row = {} as Dictionary<CellRow>;

		/** Iterate through each column and find a match in values */
		for (const column of columns) {
			const columnIdentifier = column.identifier;

			if (!columnIdentifier) {
				continue;
			}

			row[columnIdentifier] = {
				type: column.type,
				value: value[columnIdentifier] ?? '',
			};
		}

		return row;
	}

}
