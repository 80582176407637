import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { CommonTranslationKey, SharedTermsTranslationKey } from '../../translations';

import { UfControlValueAccessor } from './uf-control-value-accessor';

/** Emit the typed search via searchChange
 *  When the search value is shorter than the configured minSearchLength it is considered invalid
 *  In case of an invalid search value a null will be emitted instead and the input cleared onblur
 *  A lastEmitted value is stored to skip re-emission of the same value
 *  Value sent from binding won't be emitted and will be updated as lastEmitted value
 *  Emitted valid empty string Vs null allow the consumer to:
 *	  distinguish between a valid empty search and a clear action leading to an invalid (too short search)
 */
@Component({
	selector: 'uf-search',
	templateUrl: './uf-search.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfSearchComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-search.less'],
})
export class UfSearchComponent extends UfControlValueAccessor<string> implements OnInit, AfterViewInit {

	@ViewChild('input', { static: true }) input: ElementRef | undefined;

	@Input() name: string | null | undefined;
	@Input() label: string | null | undefined;
	@Input() placeholder: string | null | undefined;
	@Input() autofocus: boolean | null | undefined;
	@Input() debounce: number | null | undefined = 200;
	@Input() minSearchLength: number | null | undefined;
	@Input() minSearchLengthMsg: string | null | undefined;
	@Input() minSearchClass: string | string[] | null | undefined;
	@Output() searchChange = new EventEmitter<string>();

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;
	protected hasSearchConfigured = false;
	protected focused = false;

	private lastEmitted: string | null = null;

	ngOnInit() {
		this.subscriptions.add(this.control.valueChanges.pipe(
			debounceTime(this.debounce ?? 0),
			distinctUntilChanged(),
		).subscribe((v: string) => this.guardedEmitSearchChange(v)));

		this.hasSearchConfigured = this.searchChange.observed;
	}

	ngAfterViewInit() {
		if (!this.input) {
			return;
		}

		this.subscriptions.add(fromEvent(this.input.nativeElement, 'focus').subscribe(() => this.onFocus()));
		this.subscriptions.add(fromEvent(this.input.nativeElement, 'blur').subscribe(() => this.onBlur()));

		if (this.autofocus) {
			this.input.nativeElement.focus();
		}
	}

	@Input() set search(v: string | null | undefined) {
		this.lastEmitted = v ?? null;
		super.value = v;
	}

	get search(): string | null | undefined {
		return super.value;
	}

	get reachedMinSearchLength() {
		return +(this.minSearchLength ?? 0) <= (this.search ?? '').length;
	}

	get showClear() {
		return (this.search ?? '').length > 0 && this.reachedMinSearchLength;
	}

	clear() {
		this.control.setValue('');
	}

	private guardedEmitSearchChange(q: string | null | undefined) {
		const query = q ?? '';
		let nextEmit = '';

		/** Skip if q is shorter than minSearchLength and not empty */
		if (!this.reachedMinSearchLength) {
			if (query !== '') {
				return;
			}
			nextEmit = '';
		} else {
			nextEmit = query;
		}

		if (this.lastEmitted === nextEmit) {
			return;
		}

		this.lastEmitted = nextEmit;
		this.searchChange.emit(nextEmit);
	}

	private onFocus() {
		this.focused = true;

		if (this.disabled) {
			return;
		}

		this.guardedEmitSearchChange(this.search);
	}

	private onBlur() {
		if (!this.reachedMinSearchLength) {
			this.clear();
		}

		this.focused = false;
	}

	@HostBinding('class.focused') get focusedClass() {
		return this.focused && !this.disabled;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled || this.control.disabled;
	}

}
