import { InjectionToken } from '@angular/core';
import { ErrorType, HierarchyChildrenSearchOptions, HierarchySearchOptions, HierarchyUnitExtended, HierarchyUnitFormData, HierarchyUnitWithChildCount, HierarchyUnitWithPath, UfError } from '@unifii/sdk';

export type HierarchyUnitIdentifier = string | { id: string };

export type HierarchyCascadeSelectionMode = 'single' | 'siblings';

export class UfHierarchyError extends UfError {

	override data: {
		showRetry?: boolean;
		showClear?: boolean;
	};

	constructor(message: string, showRetry: boolean, showClear: boolean) {
		super(message, ErrorType.Unknown, { showRetry, showClear });
	}

}

// TODO refactor/standardize the names of hierarchy behavioral flags
// TODO centralize all hierarchy behavioral flags in an interface and define the other once starting from the basic one
export type HierarchyValidatorOptions = {
	isRequired?: boolean;
	selectLeafsOnly?: boolean;
	selectActivesOnly?: boolean;
	ceilings?: HierarchyUnitIdentifier[] | null; // TODO change to descendants (multiple potential ceilings)
	unselectableUnits?: HierarchyUnitIdentifier[] | null;
};

export interface HierarchyUnitProvider {
	search(params?: HierarchySearchOptions): Promise<HierarchyUnitWithPath[]>;
	getUnit(id: string): Promise<HierarchyUnitExtended | undefined>;
	getUnits(ids: string[]): Promise<HierarchyUnitWithPath[]>;
	getChildren(parentId: string, params?: HierarchyChildrenSearchOptions): Promise<HierarchyUnitWithChildCount[]>;
}

export const HierarchyUnitProvider = new InjectionToken<HierarchyUnitProvider>('HierarchyUnitLoader');

export interface HierarchyUnitPickerData {
	/** picker input current value */
	value?: HierarchyUnitFormData | null;
	/** ceiling unit to limit the selection to descendant units only */
	ceiling?: HierarchyUnitIdentifier | null;
	/** unit preselected when the picker dialog opens */
	preselected?: HierarchyUnitIdentifier | null;
	/** picker input label */
	label?: string | null;
	/** picker input is readonly */
	readOnly?: boolean | null;
	/** inactive units won't display as selectable options */
	filterInactiveChildren?: boolean;
	/** add a validator to make the selection required */
	isRequired?: boolean | null;
	/** add a validator that allows only leaf units to be selected */
	selectLeafsOnly?: boolean | null;
	/** add a validator to forbid selection of a specific list of units */
	unselectableUnits?: HierarchyUnitIdentifier[] | null;
	/** add a validator to limit selection of unit to the sections of the tree identified by this list of units' identifier */
	matchOrDescendantsUnits?: HierarchyUnitIdentifier[] | null;
}
