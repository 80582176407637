import { Component } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

@Component({
	templateUrl: './show-links.html',

})
export class ShowLinksComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	simpleLink = `
		<p>
			<a href="/">Simple link</a>
		</p>`;

	buttonLink = `
		<a href="/" class="uf-button tertiary">Link</a>
		<a href="/" class="uf-button">Link</a>
		<a href="/" class="uf-button primary">Link</a>
	`;

	listLinks = `
		<ul class="uf-nav-list">
			<li><a href="/">About</a></li>
			<li><a href="/">Components</a></li>
			<li><a href="/">Foundation</a></li>
		</ul>
		`;

	linkClick(event: any) {
		event.preventDefault();
	}

}
