import { Injectable } from '@angular/core';
import { AbstractControl, AbstractControlOptions, AsyncValidatorFn, FormArray, FormGroup, ValidatorFn } from '@angular/forms';
import { Dictionary } from '@unifii/sdk';

import { UfControl, UfControlArray, UfControlGroup, UfControlOptions, UfFormControl } from '../controls';

export interface UfAbstractControlOptions extends AbstractControlOptions {
	options?: UfControlOptions;
}

const isAbstractControlOptions = (
	options: UfAbstractControlOptions | Record<string, any>): options is UfAbstractControlOptions =>
	(options as UfAbstractControlOptions).asyncValidators !== undefined ||
	(options as UfAbstractControlOptions).validators !== undefined ||
	(options as UfAbstractControlOptions).updateOn !== undefined;

@Injectable({ providedIn: 'root' })
export class UfFormBuilder {

	group(
		controlsConfig: Record<string, any>,
		options?: UfAbstractControlOptions | null,
	): UfControlGroup;
	group(
		controlsConfig: Record<string, any>,
		options: Record<string, any>,
	): UfControlGroup;
	group(
		controlsConfig: Record<string, any>,
		options: | Record<string, any> | null = null,
	): UfControlGroup {

		const controls = this._reduceControls(controlsConfig);
		let validators: ValidatorFn | ValidatorFn[] | undefined;
		let asyncValidators: AsyncValidatorFn | AsyncValidatorFn[] | undefined;

		if (options != null) {
			if (isAbstractControlOptions(options)) {
				// `options` are `AbstractControlOptions`
				validators = options.validators ?? undefined;
				asyncValidators = options.asyncValidators ?? undefined;
			} else {
				// `options` are legacy form group options
				validators = options.validator ?? undefined;
				asyncValidators = options.asyncValidator ?? undefined;
			}
		}

		const validatorCompatibleType = validators as ValidatorFn | undefined;
		const asyncValidatorCompatibleType = asyncValidators as AsyncValidatorFn | undefined;

		return new UfControlGroup(controls, options?.options, validatorCompatibleType, asyncValidatorCompatibleType);
	}

	/**
	 * @description
	 * Construct a new `FormControl` with the given state, validators and options.
	 *
	 * @param formState Initializes the control with an initial state value, or
	 * with an object that contains both a value and a disabled status.
	 *
	 * @param validatorOrOpts A synchronous validator function, or an array of
	 * such functions, or an `AbstractControlOptions` object that contains
	 * validation functions and a validation trigger.
	 *
	 * @param asyncValidator A single async validator or array of async validator
	 * functions.
	 *
	 * @usageNotes
	 *
	 * ### Initialize a control as disabled
	 *
	 * The following example returns a control with an initial value in a disabled state.
	 *
	 * <code-example path="forms/ts/formBuilder/form_builder_example.ts" region="disabled-control">
	 * </code-example>
	 */
	control(
		formState: any,
		validatorOrOpts?: ValidatorFn | ValidatorFn[] | null,
		asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
		controlOptions?: UfControlOptions | null): UfControl {

		const asyncValidatorCompatibleType = asyncValidator as AsyncValidatorFn | undefined;

		return new UfControl(validatorOrOpts ?? undefined, controlOptions ?? undefined, asyncValidatorCompatibleType, formState);
	}

	/**
	 * Constructs a new `FormArray` from the given array of configurations,
	 * validators and options.
	 *
	 * @param controlsConfig An array of child controls or control configs. Each
	 * child control is given an index when it is registered.
	 *
	 * @param validatorOrOpts A synchronous validator function, or an array of
	 * such functions, or an `AbstractControlOptions` object that contains
	 * validation functions and a validation trigger.
	 *
	 * @param asyncValidator A single async validator or array of async validator
	 * functions.
	 */
	array(
		controlsConfig: any[],
		validatorOrOpts?: ValidatorFn | ValidatorFn[] | null,
		asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
		controlOptions?: UfControlOptions | null): UfControlArray {

		const controls = controlsConfig.map((c) => this._createControl(c));
		const validatorCompatibleType = validatorOrOpts as ValidatorFn | undefined;
		const asyncValidatorCompatibleType = asyncValidator as AsyncValidatorFn | undefined;

		return new UfControlArray(controls, controlOptions ?? undefined, validatorCompatibleType, asyncValidatorCompatibleType);
	}

	/**
	 * Detach the input control from its parent control
	 *
	 * @param control The control to detach
	 * @returns Detach succeeded
	 */
	detach(control?: AbstractControl | null, options?: { emitEvent?: boolean | undefined }): boolean {

		if (!control?.parent?.controls) {
			return false;
		}

		if (control.parent instanceof FormArray) {
			const index = control.parent.controls.findIndex((c) => c === control);

			if (index === -1) {
				throw new Error('removeControl: control not found in parent FormArray');
			}

			control.parent.removeAt(index, options);

			return true;
		}

		if (control.parent instanceof FormGroup) {
			const parent = control.parent;
			const key = Object.keys(parent.controls).find((k) => parent.controls[k] === control);

			if (!key) {
				throw new Error('removeControl: control not found in parent FormGroup');
			}

			control.parent.removeControl(key, options);

			return true;
		}

		return false;
	}

	/**
	 * @description
	 * Creates an object of keys and controls using optional configuration
	 */
	private _reduceControls(controlsConfig: Record<string, any>): Dictionary<UfFormControl> {
		const controls: Record<string, UfFormControl> = {};

		Object.keys(controlsConfig).forEach((controlName) => {
			controls[controlName] = this._createControl(controlsConfig[controlName]);
		});

		return controls;
	}

	private _createControl(controlConfig: any): UfFormControl {

		if (controlConfig instanceof UfControl
			|| controlConfig instanceof UfControlGroup
			|| controlConfig instanceof UfControlArray
		) {
			return controlConfig;
		}

		if (Array.isArray(controlConfig)) {
			const value = controlConfig[0];
			const validator = controlConfig[1] as ValidatorFn | undefined;
			const asyncValidator = controlConfig[2] as AsyncValidatorFn | undefined;
			const controlOptions = controlConfig[3] as UfControlOptions | undefined;

			return this.control(value, validator, asyncValidator, controlOptions);
		} else {
			return this.control(controlConfig);
		}
	}

}
