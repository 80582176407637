<div class="grid">

	<div class="col-1of1 row space-children">
		<button (click)="clear()" class="uf-button" type="button">Clear</button>
		<button (click)="set()" class="uf-button" type="button">Set 'something'</button>
		<button (click)="setNull()" class="uf-button" type="button">Set null</button>
	</div>

	<uf-search #searchInput [search]="search" [minSearchLength]="4" (searchChange)="onSearch($event)" label="Search"
		placeholder="search for..." minSearchClass="small" class="col-1of1" />

</div>