import { Component, Input } from '@angular/core';

@Component({
	selector: 'uf-data-display-table-list',
	templateUrl: './data-display-table-list.html',
	styleUrls: ['./data-display-table-list.less'],
})
export class DataDisplayTableListComponent {

	protected values: unknown[] = [];

	@Input({ required: true }) set data(v: unknown) {
		this.values = Array.isArray(v) ? v : [v];
	}

	get data() {
		return this.values;
	}

}
