import { Component, OnInit, inject } from '@angular/core';
import { Compound } from '@unifii/sdk';

import { RuntimeField } from '@unifii/library/common';
import { FormContainerField, FormService } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-survey-description',
	templateUrl: './survey-description.html',
	styleUrls: ['../form-group.less'],
})
export class SurveyDescriptionComponent implements FormContainerField, OnInit {

	field: RuntimeField;
	scope: Compound;

	private formService = inject(FormService, { optional: true });

	ngOnInit() {
		// Inject options into questions
		if (this.field.fields.length) {
			for (const field of this.field.fields) {
				field.options = this.field.options;
			}
		}
	}

	isVisible(child: RuntimeField): boolean {
		return !this.formService || this.formService.isGranted(child);
	}

}
