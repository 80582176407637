import { Inject, Injectable, SkipSelf } from '@angular/core';

import { AppContext, ContextProvider, RuntimeDefinition, RuntimeTransition, UfFormControl, controlIterator } from '@unifii/library/common';

import { DebugValidation, ValidatorGroupType } from '../constants';
import { FormWrapper } from '../models';
import { FormFunctions, amendFormData } from '../utils';

import { ScopeManager } from './scope-manager';

@Injectable()
export class FormDebugger implements ContextProvider {

	formRoles: string[] = [];
	formTransitions: RuntimeTransition[] = [];
	formStates: string[] = [];

	private form?: FormWrapper;
	private scopeManager?: ScopeManager;
	private _roles: string[];
	private _validation: DebugValidation = DebugValidation.On;
	private _enableWorkflow = true;

	constructor(@SkipSelf() @Inject(ContextProvider) private contextProvider: ContextProvider) { }

	register(form: FormWrapper, scopeManager: ScopeManager) {

		this.form = form;
		this.scopeManager = scopeManager;

		this.updateValidators();

		this.formRoles = this.definition?.roles ?? [];
		this.formTransitions = FormFunctions.getTransitions(this.definition);
		this.formStates = FormFunctions.getStates(this.definition);
	}

	get(): AppContext {
		const context = this.contextProvider.get();

		if (context.user) {
			context.user.roles = this.roles;
		}

		return context;
	}

	set enableWorkflow(v: boolean) {
		if (this._enableWorkflow === v || !this.form) {
			return;
		}

		this._enableWorkflow = v;

		this.form.formData = amendFormData({}, this.form.definition);
	}

	get enableWorkflow(): boolean {
		return this._enableWorkflow;
	}

	set validation(v: DebugValidation) {
		if (this._validation === v) {
			return;
		}

		this._validation = v;

		if (!this.scopeManager) {
			return;
		}

		this.updateValidators();
	}

	get validation(): DebugValidation {
		return this._validation;
	}

	set state(v: string | null) {

		if (!this.form || this.form.formData._state === v) {
			return;
		}

		this.form.formData = Object.assign({}, this.form.formData, { _state: v ?? undefined });
	}

	get state() {
		return this.form?.formData._state ?? null;
	}

	set roles(v: string[]) {
		this._roles = v;
	}

	get roles(): string[] {
		return this._roles;
	}

	updateValidators(entryPoint?: UfFormControl | undefined) {

		const entryControl = entryPoint ?? this.rootControl;

		if (!entryControl) {
			return;
		}

		for (const control of controlIterator(entryControl)) {

			switch (this.validation) {
				case DebugValidation.Off:
					control.clearValidators();
					break;
				case DebugValidation.ExcludeRequired:
					if (this.scopeManager) {
						control.setValidators(this.scopeManager.getValidatorFn(control, ValidatorGroupType.RequiredExcluded));
					}
					break;
				case DebugValidation.On:
					if (this.scopeManager) {
						control.setValidators(this.scopeManager.getValidatorFn(control, ValidatorGroupType.Initial));
					}
			}

			control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
		}
	}

	private get definition(): RuntimeDefinition | null | undefined {
		return this.form?.definition;
	}

	private get rootControl() {
		return this.form?.rootControl;
	}

}
