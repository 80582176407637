import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'arrayFilter',
	pure: true,
})
export class ArrayFilterPipe implements PipeTransform {

	transform(value: any[] | null | undefined, args: any[] | null | undefined) {

		if (!value || !args) {
			return value || [];
		}

		return value.filter((item) => !args.includes(item));
	}

}
