<div [ufErrorMessage]="errorMessage" class="error-message pad-sides"></div>
<div class="actions-wrapper">
	<button *ngIf="cancelButtonLabel" (click)="onCancel()" class="uf-button tertiary" type="button">
		{{cancelButtonLabel}}
	</button>

	<div class="grow"></div>

	<button *ngIf="submitButtonLabel" [disabled]="disabled" (click)="onSubmit()" class="uf-button primary"
		type="button">
		{{submitButtonLabel}}
	</button>

	<ng-container *ngFor="let action of workflowService.actions">
		<!-- Cancel actions always considered a tertiary action -->
		<button [ngClass]="action.transition.actionLabel === 'Cancel' ? 'uf-button tertiary' : 'uf-button primary'"
			[disabled]="disabled" (click)="workflowService.trigger(action)" type="button">
			{{ action.transition.actionLabel }}
		</button>
	</ng-container>

</div>

<button [disabled]="disabled" (click)="openActionsModal()" type="button"
	class="uf-action primary large gap floating-button">
	<uf-icon name="hamburger" />
</button>