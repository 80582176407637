import { Component, OnInit } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-chips.html',
})
export class ShowChipsComponent implements OnInit {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	// Table
	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'uf-chip', type: 'base class', description: 'Contains chip base styles' },
		{ name: 'primary', type: 'modifier', description: 'Sets background to primary action colour' },
		{ name: 'secondary', type: 'modifier', description: 'Sets style to match secondary button style' },
		{ name: 'outlined', type: 'modifier', description: 'A style variation with outline' },
		{ name: 'disabled', type: 'modifier', description: 'Disables chip' },
		{ name: 'clickable', type: 'modifier', description: 'Adds pointer and hover colours' },
	];

	readonly templateSource = `
		<div class="uf-chip #cssClasses">
			<div class="uf-avatar">
				A
			</div>
			User Avatar
		</div>
		<div class="uf-chip #cssClasses">
			<div class="uf-avatar success">
				<uf-icon name="tick"></uf-icon>
			</div>
			Status Avatar
		</div>
		<div class="uf-chip #cssClasses">
			Basic
		</div>
		<div class="uf-chip #cssClasses">
			Custom Action
			<button class="uf-action">
				<uf-icon name="edit"></uf-icon>
			</button>
		</div>
		<div class="uf-chip #cssClasses">
			Deletable
			<button class="uf-action">
				<uf-icon name="delete"></uf-icon>
			</button>
		</div>
		<div class="uf-chip focused #cssClasses">
			Focused
			<button class="uf-action">
				<uf-icon name="delete"></uf-icon>
			</button>
		</div>
	`;

	// Interactive
	readonly sizeOptions = [
		{ name: 'Small (default)', value: '' },
		{ name: 'Extra Small', value: 'x-small' },
	];
	readonly colourOptions = [
		{ name: 'Default', value: '' },
		{ name: 'Outlined', value: 'outlined' },
		{ name: 'Primary', value: 'primary' },
		{ name: 'Secondary', value: 'secondary' },
		{ name: 'Disabled', value: 'disabled' },
	];
	readonly typeOptions = [
		{ name: 'Default', value: '' },
		{ name: 'Clickable', value: 'clickable' },
	];

	size = '';
	colour = '';
	type = '';
	template: string;

	value: any;
	allResults = ['Apple', 'Carrot', 'Banana', 'Pear'];
	results: any[] = [];

	find(e: any) {

		console.log('e', e);
		if (e == null || e === '') {
			this.results = [...this.allResults];

			return;
		}

		this.results = this.allResults.filter((r) => r.includes(e));
	}

	ngOnInit() {

		this.results = [...this.allResults];
		this.modifierChange();
	}

	modifierChange() {
		const classes = [this.size, this.colour, this.type].filter((c) => c !== '').join(' ');

		this.template = this.templateSource.replace('#cssClasses', classes);
	}

}
