import { format, parse } from 'date-fns';

import { DateFormat } from '../constants';
import { TemporalDataTypes } from '../models';

import { displayFormatByType, getFormat, inputFormatByType, isValidFormat, modelFormatByType } from './date-time-functions';

export class DateConverter {

	// The format used for the data model
	readonly modelFormat: string;
	// The format used to display the date to the user
	readonly displayFormat: string;
	// The format used by the user to edit/type the date
	readonly inputFormat: string;
	// The format used to show the input mask to the user
	readonly placeholderFormat: string;

	/**
	 * @param type The type of temporal manage by this DateConverter instance (for ZonedDateTime the type is DateTime)
	 * @param displayFormat Specify a custom format to be used to display the date value, if invalid an hardcoded one per type is used
	 * @param displayFallbackFormat A fallback format to be used instead of the hardcoded when the displayFormat is invalid
	 */
	constructor(type: `${TemporalDataTypes}`, displayFormat?: string | null, displayFallbackFormat?: string | null) {
		const temporalDataType = type as TemporalDataTypes;

		this.modelFormat = modelFormatByType(temporalDataType);
		this.displayFormat = displayFormatByType(temporalDataType, displayFormat, displayFallbackFormat);
		this.inputFormat = inputFormatByType(temporalDataType, displayFormat);
		this.placeholderFormat = this.inputFormat.replace(`${DateFormat}`, 'DD/MM/YYYY');
	}

	fromModelToDisplay(value: string): string | undefined {
		return this.convertValue(value, this.modelFormat, this.displayFormat);
	}

	fromModelToInput(value: string): string | undefined {
		return this.convertValue(value, this.modelFormat, this.inputFormat);
	}

	fromInputToModel(value: string): string | undefined {
		return this.convertValue(value, this.inputFormat, this.modelFormat);
	}

	fromDisplayToModel(value: string): string | undefined {
		return this.convertValue(value, this.displayFormat, this.modelFormat);
	}

	fromDisplayToInput(value: string): string | undefined {
		return this.convertValue(value, this.displayFormat, this.inputFormat);
	}

	normalizeModelValue(value: string): string | undefined {
		return this.convertValue(value, this.modelFormat, this.modelFormat);
	}

	convertValue(value: string, fromFormat: string, toFormat?: string): string | undefined {

		if (!isValidFormat(value, fromFormat)) {
			const verifiedValueFormat = getFormat(value);

			if (verifiedValueFormat) {
				const valueDate = parse(value, verifiedValueFormat, new Date());

				return format(valueDate, toFormat ?? '');
			}

			return;
		}
		const date = parse(value, fromFormat, new Date());

		return format(date, toFormat ?? '');
	}

}
