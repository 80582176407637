<sc-page-content width="lg" identifier="drawers">

	<ng-container editor>
		<sc-code-editor-expander>
			<button (click)="openDrawer = !openDrawer" class="uf-button primary" type="button">Open</button>
			<uf-drawer [(open)]="openDrawer">
				<div class="uf-app-bar flat">
					<button (click)="openDrawer = false" class="uf-action tertiary" type="button">
						<uf-icon name="close" />
					</button>
				</div>
				<div class="pad-sides">
					<h3 class="gap-none-top">Your Content</h3>
					<p>A place for addition inputs, actions or content.</p>
				</div>
			</uf-drawer>
		</sc-code-editor-expander>
	</ng-container>

</sc-page-content>