import { FieldType, OperatorComparison, Option, SchemaHierarchyConfiguration } from '@unifii/sdk';

import { SourceConfig } from './source-config-model';

export interface DataPropertyInfo {
	identifier: string;
	type: FieldType; // TODO DataType
	label: string;
}

export interface DataPropertyDescriptor extends DataPropertyInfo {
	/** Behavior flags */
	asDisplay?: boolean;
	asSort?: boolean;
	asSearch?: boolean;
	asStaticFilter?: boolean;
	asInputFilter?: boolean;

	/** label (identifier) */
	display?: string;
	icon?: string;
	options?: Option[];
	sourceConfig?: SourceConfig;
	hierarchyConfig?: SchemaHierarchyConfiguration;
	operators?: OperatorComparison[];
	isExpression?: boolean;
}

export const DataDescriptorUsersType = 'Users';
export const DataDescriptorBucketType = 'Bucket';
export const DataDescriptorCollectionType = 'Collection';
export const DataDescriptorCompanyType = 'Company';
export const DataDescriptorClaimsType = 'Claims';
export const DataDescriptorExternalType = 'External';

export type DataDescriptorType =
	typeof DataDescriptorUsersType |
	typeof DataDescriptorBucketType |
	typeof DataDescriptorCollectionType |
	typeof DataDescriptorCompanyType |
	typeof DataDescriptorClaimsType |
	typeof DataDescriptorExternalType;

export interface DataDescriptor {
	type: DataDescriptorType;
	propertyDescriptors: DataPropertyDescriptor[];
	propertyDescriptorsMap: Map<string, DataPropertyDescriptor>;
	isSearchable?: boolean;
	skippedProperties?: Option[];
	// pagination: DataPaginationDescriptor
}

export type DataDescriptorResolveFunction = (...args: any) => Promise<DataDescriptor | undefined>;

export interface DataDescriptorAdapter {
	getDataDescriptor: DataDescriptorResolveFunction;
}
