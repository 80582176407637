<table [ngClass]="cssClasses" class="uf-table">
	<thead>
		<tr>
			<th *ngFor="let column of columnConfig">{{ column.name }}</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let row of rows">
			<ng-container *ngFor="let column of columnConfig">
				<td *ngIf="column.fieldType == null; else inputCell">
					<div *ngIf="column.level != null; else cellText" [ngClass]="column.level" class="uf-lozenge">
						{{ row[column.identifier] }}
					</div>
					<ng-template #cellText>
						{{ row[column.identifier] }}
					</ng-template>
				</td>
				<ng-template #inputCell>
					<td class="input-cell small">
						<ng-container [ngSwitch]="column.fieldType">
							<uf-checkbox *ngSwitchCase="fieldType.Bool" [(value)]="row[column.identifier]" />
							<uf-text *ngSwitchDefault [placeholder]="column.placeholder || null"
								[(value)]="row[column.identifier]" class="table-cell" />
						</ng-container>
					</td>
				</ng-template>
			</ng-container>
		</tr>
	</tbody>
</table>