<ul class="uf-list uf-box" style="width: 300px;">
	<li class="uf-list-item">
		<uf-data-display-list [items]="{ server: repository.baseUrl }" class="stacked" />
	</li>
	<li class="uf-list-item">
		<uf-switch [(value)]="repository.preview" (valueChange)="reload()" label="Preview" />
	</li>
	<li *ngIf="projects.length" class="pad">
		<uf-select [options]="projects" [value]="repository.projectId" [nameProperty]="projectLabel"
			(valueChange)="selectProject($event)" label="Project" valueProperty="id" />
	</li>
	<li *ngIf="(translate?.langs?.length ?? 0) > 1" class="pad">
		<uf-select [options]="translate.langs" [value]="translate.currentLang" (valueChange)="selectLang($event)"
			label="Language" />
	</li>
	<li class="pad">
		<uf-select [options]="baseSizeOptions" [(value)]="baseSize" (valueChange)="updateBaseSize($event)"
			label="App size" valueProperty="identifier" />
	</li>
</ul>