import { Component, ElementRef, HostBinding, OnDestroy, OnInit, TemplateRef, inject } from '@angular/core';
import { Dictionary } from '@unifii/sdk';
import { Subject, Subscription } from 'rxjs';

import { KeyCodes } from '../../../constants';
import { Modal } from '../modal';
import { ModalRuntime } from '../modal-runtime';
import { ModalData } from '../modal-types';

export interface ListBoxConfig {
	options?: any[];
	optionsChange?: Subject<(string | ListBoxOption)[]>;
	nameProperty?: string | ((v: any) => string) | null;
	template?: TemplateRef<any>;
	width?: string;
	minWidth?: string;
	cssClass?: string | string[] | Set<string>; // css class that will be added to the list box
	disableKeyNavigation?: boolean;
}

export interface ListBoxOption extends Dictionary<any> {
	_notSelectable?: boolean;
	_customOption?: boolean;
	_cssClass?: string | string[];
	name?: string; // display 'label' for the notSelectable and customOption 'label' scenarios
	identifier?: string; // value for the customOption
}

@Component({
	selector: 'uf-list-box',
	templateUrl: './list-box.html',
	styleUrls: ['./list-box.less'],
})

export class ListBoxComponent implements Modal<ListBoxConfig, any>, OnDestroy, OnInit {

	data = inject(ModalData) as ListBoxConfig;
	runtime = inject(ModalRuntime) as ModalRuntime<ListBoxConfig, any>;

	protected activeIndex = 0;
	protected nameProperty: string | ((v: any) => string) | null;
	protected options: any[] = [];
	protected template: TemplateRef<any> | undefined;
	protected cssClass: string | string[] | Set<string>; // additional list styles

	private width: string | null;
	private minWidth: string | null;
	private subscriptions = new Subscription();

	private elementRef = inject(ElementRef);

	@HostBinding('style.width') protected get listWidth() {
		return this.width ?? 'initial';
	}

	@HostBinding('style.minWidth') protected get minListWidth() {
		return this.minWidth ?? 'initial';
	}

	@HostBinding('style.visibility') protected get visibility() {
		return this.options.length > 0 ? 'initial' : 'hidden';
	}

	ngOnInit() {
		this.mapDataToAttributes();
		this.setActiveItem();
		this.createSubscriptions();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
		document.removeEventListener('keydown', this.onKeyDown.bind(this));
	}

	protected select(option: any) {

		if (option?._notSelectable) {
			return;
		}

		if (option?._customOption) {
			this.runtime.close(option.identifier);

			return;
		}

		this.runtime.close(option);
	}

	private getNextActive(down: boolean): number {

		if (this.activeIndex === 0 && !down) {
			return this.options.length - 1;
		}

		if (this.activeIndex === this.options.length -1 && down) {
			return 0;
		}

		return this.activeIndex + (down ? 1 : -1);
	}

	private scrollToFocusedElement() {
		// Assign to scoped variable to avoid multiple DOM queries
		const container = this.elementRef.nativeElement.querySelector('ul');
		const option = this.elementRef.nativeElement.querySelector('li');

		if (container == null || option == null ||
			(container.scrollHeight <= container.getBoundingClientRect().height)
		) {
			return;
		}

		container.scrollTop = option.clientHeight * (this.activeIndex + 1) - container.clientHeight;
	}

	private mapDataToAttributes() {
		this.width = this.data.width ?? null;
		this.minWidth = this.data.minWidth ?? null;
		this.template = this.data.template;
		this.nameProperty = this.data.nameProperty ?? null;
		this.options = this.data.options ?? [];
		this.cssClass = this.data.cssClass ?? [];
	}

	private setActiveItem() {
		const activeIndex = this.options.findIndex((option: ListBoxOption) => !!option.active);

		if (activeIndex > 0) {
			this.activeIndex = activeIndex;
		}
	}

	private createSubscriptions() {
		if (this.data.optionsChange != null) {
			this.subscriptions.add(this.data.optionsChange.subscribe((v) => (this.options = v)));
		}

		if (!this.data.disableKeyNavigation) {
			document.addEventListener('keydown', this.onKeyDown.bind(this));
		}
	}

	private onKeyDown(event: KeyboardEvent) {
		event.stopImmediatePropagation();
		
		const keyCode = event.code as KeyCodes;

		if (!this.options.length || ![KeyCodes.ArrowDown, KeyCodes.ArrowUp, KeyCodes.Enter].includes(keyCode)) {
			return;
		}

		if ([KeyCodes.ArrowDown, KeyCodes.ArrowUp].includes(keyCode)) {
			this.activeIndex = this.getNextActive(keyCode === KeyCodes.ArrowDown);
			this.scrollToFocusedElement();
		}

		if (keyCode === KeyCodes.Enter && this.options.length > this.activeIndex && this.options[this.activeIndex]._notSelectable !== true) {
			this.select(this.options[this.activeIndex]);
		}
	}

}
