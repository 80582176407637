import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { Subscription } from 'rxjs';

import { DataDisplayListItemForDisplay, ExpressionParser, FieldDescriptionServiceProvider, RuntimeField, Scope } from '@unifii/library/common';
import { FieldBindToManager, ScopeManager } from '@unifii/library/smart-forms';

/**
 * RepeatCellComponent is responsible for run bindTo when dependencies change
 */
@Component({
	/**
	 * Directive selector required to generate a table with the correct HTML structure
	 * inserting unsupported tags within a table may cause some unexpected results
	 */
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: '[uf-repeat-cell]',
	templateUrl: './repeat-cell.html',
})
export class RepeatCellComponent implements OnInit, OnDestroy {

	@Input({ required: true }) field: RuntimeField;
	@Input({ required: true }) scope: Scope;
	/**
	 * Optional scopeManager input available for when there's need to supply your
	 * own scope rather then use the parent components scope.
	 * Needed for cell's rendered outside of their current scope
	 */
	@Input() scopeManager: ScopeManager | null;

	protected dataDisplayItem: DataDisplayListItemForDisplay | null;

	private descriptionService = inject(FieldDescriptionServiceProvider);
	private scopeManagerDI = inject(ScopeManager);
	private expressionParser = inject(ExpressionParser);
	private subscriptions = new Subscription();
	private configuredScopeManager: ScopeManager;

	ngOnInit() {
		// default to inputted scopeManager with DI as a fallback
		this.configuredScopeManager = this.scopeManager ?? this.scopeManagerDI;
		this.initBindTo();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	private getDataDisplayListItem(fieldIdentifier: string, field: RuntimeField, scope: Scope): DataDisplayListItemForDisplay | null {
		
		// Create description list using the first item data
		const firstItem = this.descriptionService.transform(scope[fieldIdentifier], field, scope)?.at(0);

		if (firstItem == null) {
			return null;
		}
		
		return { data: Array.isArray(firstItem.data) ? firstItem.data : [firstItem.data] };
	}

	private initBindTo() {

		const fieldIdentifier = this.field.identifier;

		if (!fieldIdentifier) {
			return;
		}

		const control = this.configuredScopeManager.getControl(this.field);

		if (!control) {
			console.warn(`RepeatCellComponent.initBindTo - scope control null for field ${fieldIdentifier}`);

			return;
		}

		this.dataDisplayItem = this.getDataDisplayListItem(fieldIdentifier, this.field, this.scope);

		if (!this.field.bindTo) {
			return;
		}

		const bindToManager = new FieldBindToManager(control, this.field, this.configuredScopeManager, this.expressionParser);

		this.subscriptions.add(control.valueChanges.subscribe(() => {
			this.scope[fieldIdentifier] = bindToManager.getValue();

			// Refresh description due to this.scope changed
			this.dataDisplayItem = this.getDataDisplayListItem(fieldIdentifier, this.field, this.scope);
		}));
	}

}
