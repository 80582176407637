import { Component, Input } from '@angular/core';

import { DataDisplayComponentType } from '../../../models';

import { DataDisplayMarkdownValue, isDataDisplayMarkdownValue } from './data-display-components-model';

@Component({
	selector: 'uf-data-display-markdown',
	templateUrl: './data-display-markdown.html',
	styleUrls: ['../data-display.less'],
})
export class DataDisplayMarkdownComponent implements DataDisplayComponentType {
	
	@Input({ required: true }) data: DataDisplayMarkdownValue;

	static isDataAccepted = isDataDisplayMarkdownValue;
	
}
