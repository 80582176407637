import { AfterViewInit, Component, DestroyRef, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';

import { DOMEventHandler } from '../../services';

@Component({
	selector: 'uf-panel',
	templateUrl: 'panel.html',
	styleUrls: ['./panel.less'],
})
export class PanelComponent implements OnInit, AfterViewInit, OnDestroy {

	@Input() scrollable = true;
	@Input() bottomThreshold?: number;
	@Input() forceReflow?: boolean; /** mobile iOS 12.2 scroll bug where scroll position fixes  */
	@Output() scrollBottom = new EventEmitter<number>(true);
	@Output() scrollStop = new EventEmitter<boolean>(true);
	@ViewChild('child', { static: true }) scrollableContainer: ElementRef<HTMLElement>;

	/** Deprecated 1.4.490 use scrollable input */
	locked: boolean; /** Set by presence of .locked css class on container */

	private htmlElement?: HTMLElement;
	private scrollStopTimeout?: NodeJS.Timer;
	private lastScrollHeight = 0;

	private element = inject(ElementRef) as ElementRef<HTMLElement>;
	private domEventHandler = inject(DOMEventHandler);
	private destroy = inject(DestroyRef);

	ngOnInit() {
		// Deprecated use scrollable input
		this.locked = this.element.nativeElement.classList.contains('locked');
	}

	ngAfterViewInit() {

		if (!this.enableScrollListener) {
			return;
		}

		this.htmlElement = this.scrollableContainer.nativeElement;

		this.domEventHandler.register({
			element: this.htmlElement,
			event: 'scroll',
			listener: this.onscroll.bind(this),
			destroy: this.destroy,
			debounceTime: 100,
		});

	}

	ngOnDestroy() {
		clearTimeout(this.scrollStopTimeout);
	}

	get hasScrollBar(): boolean {

		if (!this.htmlElement) {
			return false;
		}

		return this.htmlElement.scrollHeight > this.htmlElement.clientHeight;
	}

	reflow() {
		/**
		 * mobile iOS 12.2 fix where where scroll position fixes
		 * on scroll end forcing reflow helps the webview refresh the view
		 */
		this.element.nativeElement.style.display = 'none';
		const height = this.element.nativeElement.offsetHeight;

		this.element.nativeElement.style.display = 'block';
		console.warn('forced reflow on scrollable container', height);
	}

	private onscroll = () => {

		clearTimeout(this.scrollStopTimeout);
		this.scrollStopTimeout = setTimeout(() => { this.onScrollStop(); }, 100);

		if (!this.htmlElement || this.bottomThreshold == null) {
			return;
		}

		// Bottom scroll end detection
		const dBottom = this.htmlElement.scrollHeight - this.htmlElement.scrollTop - this.htmlElement.offsetHeight;

		// block horizontal scroll
		if (this.htmlElement.scrollTop === this.lastScrollHeight) {
			return;
		}

		this.lastScrollHeight = this.htmlElement.scrollTop;

		if (dBottom <= this.bottomThreshold) {
			this.scrollBottom.emit(dBottom);
		}
	};

	private onScrollStop() {

		if (this.forceReflow) {
			/**
			 * mobile iOS 12.2 fix where where scroll position fixes
			 * on scroll end forcing reflow helps the webview refresh the view
			 */
			this.reflow();
		}

		this.scrollStop.emit(true);
	}

	private get enableScrollListener() {
		return !((this.locked || !this.scrollable) || (!this.forceReflow && this.bottomThreshold == null));
	}

}
