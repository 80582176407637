import { Component, Input, OnInit, inject } from '@angular/core';
import { isNotNull } from '@unifii/sdk';

import { DataDisplayListItem, FieldDescriptionServiceProvider, RuntimeField, Scope } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-field-description',
	templateUrl: './field-description.html',
	styleUrls: ['./field-description.less'],
})
export class FieldDescriptionComponent implements ContentField, OnInit {

	@Input({ required: true }) field: RuntimeField;
	@Input({ required: true }) content: unknown;
	@Input() scope?: Scope;

	protected items: DataDisplayListItem[] = [];

	private fieldDescriptionService = inject(FieldDescriptionServiceProvider);

	ngOnInit() {
		this.items = this.fieldDescriptionService.transform(this.content, this.field, this.scope)?.filter(isNotNull) ?? [];
	}

}
