import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Option } from '@unifii/sdk';

import { FilterEntry, FilterViewMode, IFilterComponent } from '../../../models';
import { CommonTranslationKey, SharedTermsTranslationKey } from '../../../translations';

export interface FilterChoiceInfo extends FilterEntry {
	options: Option[];
}

@Component({
	selector: 'uf-filter-choice',
	templateUrl: './filter-choice.html',
	styleUrls: ['./filter-choice.less', './field-filter.less'],
})
export class FilterChoiceComponent implements IFilterComponent {

	@Input() mode = FilterViewMode.Input;
	@Input({ required: true }) entry: FilterChoiceInfo;
	@Input() value: string | null = null;
	@Output() valueChange = new EventEmitter<string>();

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;
	protected readonly modes = FilterViewMode;

	get isEmpty(): boolean {
		return this.value == null;
	}

	get label(): string {
		return this.entry.label;
	}

	protected get displayValue(): string | null {
		return this.entry.options.find((option) => option.identifier === this.value)?.name ?? this.value;
	}

	protected remove() {
		this.value = null;
		this.valueChanged();
	}

	protected valueChanged() {
		this.valueChange.emit(this.value ?? undefined);
	}

}
