import { TranslateService } from '@ngx-translate/core';
import { ContentLinkFormData, LinkContentType, isStringTrimmedNotEmpty } from '@unifii/sdk';

import { SEPARATOR_SLASH } from '../constants';
import { CommonTranslationKey } from '../translations';

export const getContentLinkLabel = (contentLinkFormData: ContentLinkFormData, translateService: TranslateService, hideType = false): string => {
	const tokens = [contentLinkFormData.label];
	
	if (!hideType) {
		tokens.unshift(getContentLinkTypeLabel(contentLinkFormData.type, translateService));
	}

	return tokens.filter(isStringTrimmedNotEmpty).join(SEPARATOR_SLASH);
	
};

const getContentLinkTypeLabel = (contentLinkType: LinkContentType, translateService: TranslateService): string => {
	switch (contentLinkType) {
		case LinkContentType.Asset:
			return translateService.instant(CommonTranslationKey.ContentTypeAssetLabel) as string;
		case LinkContentType.Attachment:
			return translateService.instant(CommonTranslationKey.ContentTypeAttachmentLabel) as string;
		case LinkContentType.Form:
			return translateService.instant(CommonTranslationKey.ContentTypeFormLabel) as string;
		case LinkContentType.Page:
			return translateService.instant(CommonTranslationKey.ContentTypePageLabel) as string;
		case LinkContentType.Url:
			return translateService.instant(CommonTranslationKey.ContentTypeUrlLabel) as string;
	}
};
