import { Component, Input, Output } from '@angular/core';

import { SharedTermsTranslationKey } from '../../translations';

@Component({
	selector: 'uf-expander-button',
	templateUrl: './expander-button.html',
	styleUrls: ['./expander-button.less'],
})
export class ExpanderButtonComponent {

	@Input() isExpanded: boolean;
	@Input() actionBtn: boolean;

	readonly sharedTermsTK = SharedTermsTranslationKey;

	@Output() toggle(_: MouseEvent): void { }
	@Output() expandAll(): void { }
	@Output() collapseAll(): void { }

}
