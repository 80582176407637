import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SkipSelf, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isDataSeed } from '@unifii/sdk';

import { DataDisplayComponentRegistry, DataDisplayService, ModalService, RuntimeField, Scope, SharedTermsTranslationKey, SourceConfigMapping, UfControlGroup, UfFormControl } from '@unifii/library/common';
import { FormService, ScopeManager } from '@unifii/library/smart-forms';

import { InputTranslationKey } from '../../../translations';

import { getDataSeedVisibleItems } from './repeat-functions';
import { dataDisplayRepeatTableComponentRegistryFactory } from './uf-data-display-repeat-table-component-registry';

@Component({
	/**
	 * Directive selector required to generate a table with the correct HTML structure
	 * inserting unsupported tags within a table may cause some unexpected results
	 */
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: '[uf-repeat-table-row]',
	templateUrl: './repeat-table-row.html',
	styleUrls: ['./repeat-table-row.less'],
	providers: [
		ScopeManager,
		{
			provide: DataDisplayComponentRegistry,
			useFactory: dataDisplayRepeatTableComponentRegistryFactory,
			deps: [
				[new SkipSelf(), DataDisplayComponentRegistry],
			],
		}],
})
export class RepeatTableRowComponent implements OnInit, OnChanges {

	@Input({ required: true }) field: RuntimeField;
	@Input({ required: true }) scope: Scope;
	@Input({ required: true }) dataSeedVisibleLabels: SourceConfigMapping[];
	@Output() remove = new EventEmitter<void>();

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected visibleFields: RuntimeField[] = [];
	protected control: UfControlGroup;
	protected dataSeedVisibleItems: Record<string, unknown[]> = {};

	private modalService = inject(ModalService);
	private translate = inject(TranslateService);
	private scopeManager = inject(ScopeManager);
	private parentScopeManager = inject(ScopeManager, { skipSelf: true });
	private formService = inject(FormService, { optional: true });
	private dataDisplayService = inject(DataDisplayService);
	private _hiddenColumns: string[] = [];

	@Input() set hiddenColumns(v: string[]) {
		this._hiddenColumns = v;
		this.visibleFields = this.getVisibleFields(v);
	}

	get hiddenColumns(): string[] {
		return this._hiddenColumns;
	}

	protected get showAddDeleteButton(): boolean {
		return !this.control.disabled && !this.field.autofill;
	}
	
	ngOnInit() {
		this.scopeManager.init(this.field, this.parentScopeManager);
		this.scopeManager.scope = this.scope;
		this.control = this.scopeManager.control;
		this.control.patchValue(this.scope);

		if ((this.control.parent as UfFormControl | undefined)?.submitted) {
			this.control.setSubmitted(true);
		}
	}

	ngOnChanges() {
		if (!isDataSeed(this.scope) || !this.field.sourceConfig) {
			this.dataSeedVisibleItems = {};
			
			return;
		}

		this.dataSeedVisibleItems = getDataSeedVisibleItems(
			this.scope,
			this.field.sourceConfig,
			this.dataSeedVisibleLabels,
			this.dataDisplayService,
		) ?? {};
	}

	protected async removeItem(event: Event) {
		event.stopPropagation();

		const confirm = await this.modalService.openConfirm({
			title: this.translate.instant(InputTranslationKey.RepeatInputDeleteConfirmTitle) as string,
			message: this.translate.instant(InputTranslationKey.RepeatInputDeleteConfirmMessage) as string,
		});

		if (!confirm) {
			return;
		}

		this.remove.emit();
	}

	private getVisibleFields(hiddenColumns: string[]): RuntimeField[] {
		return this.field.fields.filter((child) => (!this.formService || this.formService.isGranted(child)) && !(!child.identifier || hiddenColumns.includes(child.identifier)));
	}

}
