import { DataSeed, Dictionary, UserContext } from '@unifii/sdk';

import { DurationUnit } from './expression-models';

export type Scope = Dictionary<any>;

export interface Context extends Dictionary<any> {
	/** Dictionary to extend for when custom context is provided */
	self: any;
	root: Dictionary<any>;
	item?: Dictionary<any>;
	seed?: DataSeed;
	user?: UserContext;
	now?: string;
}

export type ExpressionFunction = (scope: Scope, context: Context, funcs: ExpressionFunctionsSet) => any;

export enum ExpressionTypes {
	Compound = 'Compound',
	Identifier = 'Identifier',
	MemberExpression = 'MemberExpression',
	Literal = 'Literal',
	ThisExpression = 'ThisExpression',
	CallExpression = 'CallExpression',
	UnaryExpression = 'UnaryExpression',
	BinaryExpression = 'BinaryExpression',
	ConditionalExpression = 'ConditionalExpression', // Ternary
	ArrayExpression = 'ArrayExpression'
}

/**
 * Set of functions available in Unifii for the execution of expressions
 */
export type ExpressionFunctionsSet = {
	/**
	 * Return a new date in format 'yyyy-MM-ddTHH:mm:ss' with the added quantity of unit(s) requested
	 *
	 * @param dateString - format 'yyyy-MM-ddTHH:mm:ss'
	 * @param quantity - positive/negative amount of units to add to the date
	 * @param unit - duration type
	 * @param context - Context
	 * @return - date in string format 'yyyy-MM-ddTHH:mm:ss'
	 */
	add: (dateString: string, quantity: number, unit: DurationUnit, context: any) => string;

	/**
	 * Return only the date part of the input
	 *
	 * @param dateString - format 'yyyy-MM-ddTHH:mm:ss'
	 * @param context - Context
	 * @returns - date part in string format 'yyyy-MM-dd'
	 */
	toDate: (dateString: string, context: any) => string;

	/**
	 * Return only the time part of the input
	 *
	 * @param dateString - format 'yyyy-MM-ddTHH:mm:ss'
	 * @param context - Context
	 * @returns - date part in string format 'HH:mm:ss'
	 */
	toTime: (dateString: string, context: any) => string;

	/**
	 * Transform an array string containing literal numbers to an array of numbers
	 *
	 * @param value - array like of number values in string format
	 * @param context - Context
	 * @returns - array of numbers in any input provided, undefined otherwise
	 */
	toNumbers: (value: string[] | string | null | undefined, context: any) => number[] | undefined;

	/**
	 * Re implementation of JS Array.some
	 *
	 * @param values - Array to run some against
	 * @param propertyName - Each item[propertyName]
	 * @param value - value to compare the item[propertyName]
	 * @returns bool result of the Array.some execution
	 */
	some: (values: any[], propertyName: string, value: any) => boolean;

	/**
	 * Re implementation of JS Array.join
	 *
	 * @param values - Array to run join against
	 * @param separator - used as separator to join the values
	 */
	concat: (values: string[], separator: string) => string;

	/** use instead mapUserToUserContext to map Claims for direct usage in expressions
	 *
	 * @deprecated
	 * @since 1.17
	 */
	getClaimValues: (claims: Dictionary<string | string[]>, type: string) => string[] | null;

	/** Extension point for injecting other functions at runtime */
	[key: string]: (...args: any) => any;
};
