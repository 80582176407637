import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HierarchyUnitFormData } from '@unifii/sdk';

import { HierarchyUnitIdentifier, HierarchyUnitPickerData } from '../../models';
import { ModalService } from '../../services';
import { SharedTermsTranslationKey } from '../../translations';

import { HierarchyUnitPickerComponent } from './hierarchy-utilities/hierarchy-unit-picker.component';
import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-hierarchy-unit',
	templateUrl: './uf-hierarchy-unit.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfHierarchyUnitComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-hierarchy-unit.less'],
})
export class UfHierarchyUnitComponent extends UfControlValueAccessor<HierarchyUnitFormData> {

	@HostBinding('class.border-none') get borderNone() {
		return this.template === 'input';
	}
	
	@ViewChild('showPickerButton') buttonElement: ElementRef<HTMLButtonElement> | null;

	@Input() label: string | null | undefined;
	@Input() placeholder: string | null | undefined;
	/** ceiling unit to limit the selection to descendant units only */
	@Input() ceiling: HierarchyUnitIdentifier | null | undefined = 'root';
	/** add a validator that allows only leaf units to be selected */
	@Input() selectLeafsOnly = true;
	/** add a validator to make the selection required */
	@Input() isRequired = true;
	/** add a validator to forbid selection of a specific list of units */
	@Input() unselectableUnits: HierarchyUnitIdentifier[] | null | undefined;
	/** unit preselected when the picker dialog opens */
	@Input() preselected: HierarchyUnitIdentifier | null | undefined;
	/** add a validator to limit selection of unit to the sections of the tree identified by this list of units' identifier */
	@Input() matchOrDescendantsUnits: HierarchyUnitIdentifier[] | null | undefined;
	/** 'input' render the component inline, 'box' render it as a bordered box, 'box' is the default */
	@Input() template: 'box' | 'input' = 'box';
	/** inactive units won't display as selectable options */
	@Input() filterInactiveChildren = false;
	@Output() override valueChange = new EventEmitter<HierarchyUnitFormData>();

	protected readonly sharedTK = SharedTermsTranslationKey;

	private isPickerOpen = false;
	private modalService = inject(ModalService);

	protected async showPicker() {

		if (this.disabled || this.isPickerOpen) {
			return;
		}

		this.isPickerOpen = true;

		const data: HierarchyUnitPickerData = {
			value: this.value,
			label: this.label,
			ceiling: this.ceiling,
			preselected: this.preselected,
			selectLeafsOnly: this.selectLeafsOnly,
			unselectableUnits: this.unselectableUnits,
			matchOrDescendantsUnits: this.matchOrDescendantsUnits,
			isRequired: this.isRequired,
			filterInactiveChildren: this.filterInactiveChildren,
		};

		await this.modalService.openLarge(HierarchyUnitPickerComponent,
			data,
			// Work with update to avoid registering a change of value when a null is passed for a dialog close
			{
				update: this.applyValue.bind(this),
			},
		);
		this.isPickerOpen = false;
		this.buttonElement?.nativeElement.focus();
	}

	protected applyValue(value: HierarchyUnitFormData | undefined) {
		this.control.setValue(value);
		this.control.markAsTouched();
	}

	@HostBinding('class.error') get errorClass() {
		return this.control.showError && !this.disabled;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	@HostBinding('class.value') get valueClass() {
		return !!this.value;
	}

}
