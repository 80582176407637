import { Component, OnInit } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-lozenges.html',
})
export class ShowLozengesComponent implements OnInit {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'uf-lozenge', type: 'base class', description: 'Sets base style of lozenge' },
		{ name: 'accent', type: 'modifier', description: 'Sets colour to accent colour' },
		{ name: 'info', type: 'modifier', description: 'Sets colour to information message colour' },
		{ name: 'warning', type: 'modifier', description: 'Sets colour to warning message colour' },
		{ name: 'success', type: 'modifier', description: 'Sets colour to success message colour' },
		{ name: 'error', type: 'modifier', description: 'Sets colour to error message colour' },
		{ name: 'x-small', type: 'modifier', description: 'Changes lozenge to be a circle with one letter' },
		// These options were added so we could have a smaller lozenge in the app bar in console (when using on smaller screens) - UNIFII-6302
		{ name: 'x-small-md', type: 'modifier', description: 'Changes lozenge to be a circle with one letter, only on medium screen devices' },
		{ name: 'x-small-sm', type: 'modifier', description: 'Changes lozenge to be a circle with one letter, only on small screen devices' },
	];

	// Interactive
	readonly colourOptions = [
		{ name: 'Default', value: '' },
		{ name: 'Info', value: 'info' },
		{ name: 'Warning', value: 'warning' },
		{ name: 'Error', value: 'error' },
		{ name: 'Success', value: 'success' },
		{ name: 'Accent', value: 'accent' },
	];

	// Interactive
	readonly sizeOptions = [
		{ name: 'Default', value: '' },
		{ name: 'Extra Small on Medium devices', value: 'x-small-md' },
		{ name: 'Extra Small on Smaller devices', value: 'x-small-sm' },
		{ name: 'Extra Small', value: 'x-small' },
	];

	colour = '';
	size = '';
	template: string;

	private templateSource = `
		<div class="uf-lozenge#cssClasses">
			Text
		</div>
	`;

	ngOnInit() {
		this.modifierChange();
	}

	modifierChange() {

		let classes = [this.colour].join(' ').trim();

		if (classes !== '') {
			classes = ' ' + classes;
		}

		this.template = this.templateSource.replace('#cssClasses', classes);
	}

}
