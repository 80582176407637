import { Injectable, inject } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HierarchyUnit, HierarchyUnitState, HierarchyUnitWithChildCount, HierarchyUnitWithPath, isNotNull } from '@unifii/sdk';

import { HierarchyUnitIdentifier, HierarchyValidatorOptions, UfHierarchyError } from '../models';
import { CommonTranslationKey, SharedTermsTranslationKey } from '../translations';
import { HierarchyFunctions, ValidatorFunctions, getValueAsArray } from '../utils';

type UnitChildCount = Partial<Pick<HierarchyUnitWithChildCount, 'childCount'>>;
type UnitState = Partial<Pick<HierarchyUnit, 'state'>>;
type UnitPath = Partial<Pick<HierarchyUnitWithPath, 'path'>>;

@Injectable({ providedIn: 'root' })
export class HierarchyService {

	private translateService = inject(TranslateService);

	/**
	 * Return validators based on the rules requested.
	 * The validators expect a nullable unit-like or units-like array as value.
	 * @param options configuration of rules to validate
	 * @returns set of validators matching the input options
	 */
	createValidator(options: HierarchyValidatorOptions): ValidatorFn[] {
		const ceilings = options.ceilings?.map(HierarchyFunctions.getId).filter(isNotNull);
		const unselectableUnitsIds = options.unselectableUnits?.map(HierarchyFunctions.getId).filter(isNotNull);
		const validators: ValidatorFn[] = [];

		if (options.isRequired) {
			validators.push(ValidatorFunctions.custom(
				(value: HierarchyUnitIdentifier | HierarchyUnitIdentifier[] | null) =>
					(Array.isArray(value) && !!value.length) || !!value,
				this.translateService.instant(SharedTermsTranslationKey.ValidatorValueRequired),
			));
		}

		if (options.selectLeafsOnly) {
			validators.push(ValidatorFunctions.custom(
				(value: UnitChildCount | UnitChildCount[] | null) =>
					getValueAsArray(value).every(({ childCount }) => childCount === 0),
				this.translateService.instant(CommonTranslationKey.SelectLeafUnitMessage),
			));
		}

		if (options.selectActivesOnly) {
			validators.push(ValidatorFunctions.custom(
				(value: UnitState | UnitState[] | null) =>
					getValueAsArray(value).every(({ state }) => state === HierarchyUnitState.Active),
				this.translateService.instant(CommonTranslationKey.SelectActiveUnitMessage),
			));
		}

		if (ceilings) {
			validators.push(ValidatorFunctions.custom(
				(value: UnitPath | UnitPath[] | null) =>
					getValueAsArray(value).every(({ path }) => !!path?.some((step) => ceilings.includes(step.id))),
				this.translateService.instant(CommonTranslationKey.SelectDescendantUnitMessage, { descendants: ceilings.join(', ') }),
			));			
		}

		if (unselectableUnitsIds?.length) {
			validators.push(ValidatorFunctions.custom(
				(value: HierarchyUnitIdentifier | HierarchyUnitIdentifier[] | null) =>
					getValueAsArray(value).every((unit) => !unselectableUnitsIds.includes(HierarchyFunctions.getId(unit))),
				this.translateService.instant(CommonTranslationKey.SelectSelectableUnitMessage)),
			);
		}

		return validators;
	}

	get misconfiguredError(): UfHierarchyError {
		return new UfHierarchyError(
			this.translateService.instant(CommonTranslationKey.HierarchySelectorInvalidConfigurationMessage),
			true,
			false,
		);
	}

	get selectorLoadError(): UfHierarchyError {
		return new UfHierarchyError(
			this.translateService.instant(CommonTranslationKey.HierarchySelectorLoadErrorMessage),
			true,
			false,
		);
	}

	get forbiddenError(): UfHierarchyError {
		return new UfHierarchyError(
			this.translateService.instant(SharedTermsTranslationKey.ErrorForbidden),
			true,
			false,
		);
	}

	get invalidError(): UfHierarchyError {
		return new UfHierarchyError(
			this.translateService.instant(CommonTranslationKey.SelectSelectableUnitMessage),
			false,
			true,
		);
	}

}
