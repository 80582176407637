<!-- Survey -->
<ng-template [ngIf]="field.parent?.type === fieldType.Survey" [ngIfElse]="notSurvey">
	<uf-multi-choice *ngIf="field.parent" [ngClass]="cssClass" [control]="control" [value]="content"
		[label]="field.label" [maxLength]="field.maxLength" [options]="field.parent.options"
		[columns]="field.parent.options.length" [direction]="field.layoutDirection" [rows]="rows"
		(valueChange)="changed($event)" class="survey center-all" valueProperty="identifier" nameProperty="name"
		trackBy="identifier">
		<uf-help *ngIf="field?.help" [content]="field.help" />
	</uf-multi-choice>
</ng-template>

<!-- Not Survey -->
<ng-template #notSurvey>

	<!-- Chips Template -->
	<ng-template [ngIf]="field.template === fieldTemplate.Chips" [ngIfElse]="notChips">
		<uf-chips [control]="control" [placeholder]="field.placeholder" [label]="field.label"
			[options]="filteredOptions" [value]="content" (valueChange)="changed($event)"
			(searchChange)="search($event)" nameProperty="name" valueProperty="identifier">
			<uf-help *ngIf="field.help" [content]="field.help" />
		</uf-chips>
	</ng-template>

	<!-- MultiChoice Template -->
	<ng-template #notChips>
		<uf-multi-choice [ngClass]="cssClass" [class.hide-icon]="field.template === fieldTemplate.OptionWithContent"
			[control]="control" [value]="content" [label]="field.label" [maxLength]="field.maxLength"
			[options]="field.options" [columns]="field.columnCount" [direction]="field.layoutDirection" [rows]="rows"
			(valueChange)="changed($any($event))" nameProperty="name" valueProperty="identifier">
			<span *ngIf="suffix" class="suffix">{{ suffix }}</span>
			<uf-help *ngIf="field?.help" [content]="field.help" />
		</uf-multi-choice>
	</ng-template>

</ng-template>