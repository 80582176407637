import { Component, OnInit } from '@angular/core';

import { ColumnGap, Size } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-expanders.html',

})
export class ShowExpandersComponent implements OnInit {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly templateSource = `
		<uf-expander #cssClasses #inputs>
			<div class="uf-expander-header" expanderHeader>
				<span>YourTitle</span>
			</div>
			<div expanderBody>YourContent</div>
		</uf-expander>
	`;

	// Table
	readonly propertiesColConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'default', name: 'Default' },
		{ identifier: 'description', name: 'Description' },
	];

	readonly propertiesRows = [
		{
			name: 'isExpanded',
			type: 'boolean',
			default: 'true',
			description: 'Responsible for showing and hiding content',
		},
		{
			name: 'actionBtn',
			type: 'boolean',
			default: 'false',
			description: 'Replaces expander toggle with an action button',
		},
		{
			name: 'isExpandedChange',
			type: 'EventEmitter<boolean>',
			default: '',
			description: 'Notifies listeners of changes to isExpanded boolean',
		},
	];

	readonly cssTableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];
	readonly cssTableInfo = [
		{ name: 'large', type: 'modifier', description: 'Sets size of header and children to large' },
		{ name: 'default', type: 'modifier', description: 'Sets size of header and children to default' },
		{ name: 'small', type: 'modifier', description: 'Sets size of header and children to small' },
	];

	// Interactive
	readonly sizeOptions = [
		{ name: 'Large', value: 'large' },
		{ name: 'Medium (default)', value: '' },
		{ name: 'Small', value: 'small' },
	];
	readonly modeOptions = [
		{ name: 'Toggle (default)', value: '' },
		{ name: 'Action Button', value: 'actionBtn' },
	];

	size = '';
	mode = '';
	loading = false;
	template = '';

	ngOnInit() {
		this.modifierChange();
	}

	modifierChange() {

		this.loading = true;

		const cssClasses = [];
		const inputs = [];

		if (this.size !== '') {
			cssClasses.push(`class="${this.size}"`);
		}

		if (this.mode === 'action') {
			inputs.push(`actionBtn=true`);
		}

		this.template = this.templateSource
			.replace('#cssClasses', cssClasses.join(' ').trim())
			.replace('#inputs', inputs.join(' ').trim());

		setTimeout(() => { this.loading = false; }, 1);
	}

}
