<div class="uf-app-bar">
	<button (click)="close()" title="Close" type="button" class="uf-action tertiary">
		<uf-icon name="close" />
	</button>
	<h3>{{entry.display}}</h3>
</div>

<uf-panel class="grow">
	<div class="uf-grid pad">
		<!-- Options -->
		<ul *ngIf="entry.options?.length && !dataSourceLoader" class="uf-list uf-box flat col-12">
			<ng-template [ngForOf]="entry.options" ngFor let-option>
				<li class="pad-sides">
					<uf-data-display-list [items]="{ identifier: option.identifier, name: option.name}" />
				</li>
			</ng-template>
		</ul>
		<!-- SourceConfig -->
		<uf-help *ngIf="entry.sourceConfig" [content]="entry.sourceConfig | json" />
		<uf-autocomplete *ngIf="dataSourceLoader" [options]="seeds" [(value)]="selectedSeed"
			(searchChange)="search($event)" label="Search via DataSource" nameProperty="_display" class="col-12" />
		<pre *ngIf="selectedSeed" class="col-12">{{selectedSeed | json}}</pre>
		<uf-message *ngIf="entry.sourceConfig && dataSourceLoader == null" icon="warningSolid" heading="Not available"
			content="External and Named DataSources are not available here" class="col-12 disabled" />
		<!-- Operators -->
		<ul *ngIf="entry.operators?.length" class="uf-list uf-box flat col-12">
			<ng-template [ngForOf]="entry.operators" ngFor let-operator>
				<li class="pad-sides">
					<uf-data-display-list [items]="{ operator: getOperatorName(operator) }" />
				</li>
			</ng-template>
		</ul>
	</div>
</uf-panel>