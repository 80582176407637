<label *ngIf="label">{{label}}
	<ng-content />
</label>
<div class="row">
	<div class="input-wrap">
		<input [formControl]="control" [attr.name]="name" [attr.placeholder]="placeholder" [attr.maxlength]="maxLength"
			[readonly]="disabled" [attr.autocomplete]="autocomplete" (focus)="focused = true" (blur)="focused = false;"
			type="text" />
		<uf-error [control]="control" />
		<uf-underline *ngIf="!disabled" [class.active]="focused" />
	</div>
	<ng-content select="[actions]" />
</div>