import { Component, OnInit } from '@angular/core';
import { CompoundType, FieldType, Option } from '@unifii/sdk';

import { ColumnGap, RuntimeDefinitionAdapter, RuntimeField, Size } from '@unifii/library/common';

@Component({
	selector: 'sc-show-survey',
	templateUrl: './show-survey.html',
	styleUrls: ['./show-survey.less'],
})
export class ShowSurveyComponent implements OnInit {

	protected readonly sizes = Size;
	protected readonly columnGaps = ColumnGap;
	protected fieldType = 'choice';
	protected surveySingle: RuntimeField | undefined;
	protected surveyMulti: RuntimeField | undefined;

	protected readonly fieldOptions: Option[] = [
		{ identifier: 'choice', name: 'Choice' },
		{ identifier: 'multiChoice', name: 'MultiChoice' },
	];

	private readonly singleOptions: Option[] = [
		{ identifier: '0', name: 'Very unsatisfied' },
		{ identifier: '1', name: 'Unsatistied' },
		{ identifier: '2', name: 'Normal' },
		{ identifier: '3', name: 'Satisfied' },
		{ identifier: '4', name: 'Very satisfied' },
	];

	private readonly multiOptions: Option[] = [
		{ identifier: '0', name: 'Beef' },
		{ identifier: '1', name: 'Chicken' },
		{ identifier: '2', name: 'Beacon' },
		{ identifier: '3', name: 'Salad' },
		{ identifier: '4', name: 'Mayo' },
	];

	private readonly singleQuestions = [
		{
			type: FieldType.Choice,
			label: 'Accuracy of your order',
			identifier: 's1',
			help: 'How precise was the boy at the counter?',
		},
		{
			type: FieldType.Choice,
			label: 'Friendliness of the crew',
			identifier: 's2',
		},
		{
			type: FieldType.Choice,
			label: 'Cleanliness of the restaurant',
			identifier: 's3',
		}
		,
		{
			type: FieldType.Choice,
			label: 'Temperature of your food',
			identifier: 's4',
		},
		{
			type: FieldType.Choice,
			label: 'Speed of service',
			identifier: 's5',
		},
		{
			type: FieldType.Choice,
			label: 'Taste of your food',
			identifier: 's3',
		},
	];

	private readonly multiQuestions = [
		{
			type: FieldType.MultiChoice,
			label: 'Burger',
			identifier: 'm1',
			help: 'The classic burger',
		},
		{
			type: FieldType.MultiChoice,
			label: 'BigMac',
			identifier: 'm2',
		},
		{
			type: FieldType.MultiChoice,
			label: 'Sambo',
			identifier: 'm3',
		},
	];

	private readonly _surveySingle = {
		type: FieldType.Survey,
		label: 'Please rate your mecca',
		help: 'Help text content',
		options: this.singleOptions,
		fields: this.singleQuestions,
	};

	private readonly _surveyMulti = {
		type: FieldType.Survey,
		label: 'Make your order',
		help: 'Help text content',
		options: this.multiOptions,
		fields: this.multiQuestions,
	};

	constructor(private runtimeAdapter: RuntimeDefinitionAdapter) { }

	async ngOnInit() {

		const definition = await this.runtimeAdapter.transform({
			compoundType: CompoundType.Form,
			identifier: 'survey-form',
			label: 'survey-form',
			fields: [this._surveySingle, this._surveyMulti],
		});

		this.surveySingle = definition.fields[0];
		this.surveyMulti = definition.fields[1];
	}

}
