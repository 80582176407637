import { AstNode, CompaniesClient, Company, Query, mergeAstNodes } from '@unifii/sdk';

import { TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';

export class ShowEntityCompaniesDataSource extends TableDataSource<Company> {
	
	filtered: false;
	sorted: false;

	constructor(
		private companiesClient: CompaniesClient,
		private staticFilter: AstNode | undefined,
	) {
		super();
	}

	async load(options?: TableDataSourceQueryOptions) {

		let query = new Query();

		query = query.fromAst(mergeAstNodes('and', this.staticFilter)).limit(options?.limit, options?.offset);

		try {
			this.stream.next({ data: await this.companiesClient.query(query) });
		} catch (error) {
			this.stream.next({ error });
		}
	}

}
