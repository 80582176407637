import { AbstractControl } from '@angular/forms';

import { ControlAccessor } from '../services/control-accessor';

type UfControlWithDependents = AbstractControl & { dependents?: UfControlWithDependents[] };

export class ControlDependencyManager<T extends UfControlWithDependents> {

	dependencies: T[] = [];

	private controlAccessor: ControlAccessor;

	constructor(
		private control: T,
		private deps?: string[] | T[],
	) {
		this.controlAccessor = new ControlAccessor(this.control);
		this.addDependencies(this.getDependencies(this.deps));
	}

	addDependencies(dependencies: T[]) {
		dependencies
			.filter((d) => !this.dependantRegistered(d, this.control))
			.forEach((d) => {
				d.dependents?.push(this.control);
				this.dependencies.push(d);
			});
	}

	removeDependencies() {
		this.dependencies.forEach((d, i) => {
			const index = d.dependents?.indexOf(this.control) ?? -1;

			if (index >= 0) {
				d.dependents?.splice(index, 1);
			}
			this.dependencies.splice(i, 1);
		});
	}

	updateDependencies() {
		const dependencies = this.getDependencies(this.deps); // store temporary reference to save additional computation

		if (!dependencies.length) {
			return;
		}

		this.removeDependencies();
		this.addDependencies(dependencies);
		this.control.updateValueAndValidity();
	}

	/**
	 * @description
	 * Checks if control already exists in dependencies controls dependant list
	 * */
	private dependantRegistered(dependency: T, control: T): boolean {
		return dependency.dependents?.find((c) => c === control) != null;
	}

	/**
	 * @description
	 * returns a list of controls, if a dependency path has been set
	 * it use the controlAccessor to retrieve the control
	 */
	private getDependencies(deps: string[] | T[] = []): T[] {
		const dependencies: T[] = [];

		for (const dependency of deps) {
			if (typeof dependency === 'string') {
				const controls = this.controlAccessor.get(dependency) as T[];

				dependencies.push(...controls);
			} else {
				dependencies.push(dependency);
			}
		}

		return dependencies;
	}

	// TODO would be good to implement an out of the box destroy feature
	// onDestroy() {
	//	 this.removeDependencies();
	// }

}
