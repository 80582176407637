import { Injectable, Type } from '@angular/core';
import { FieldType } from '@unifii/sdk';

import { ComponentRegistry, ComponentRegistryType, FormComponentRegistryOptions, FormField } from '@unifii/library/smart-forms';

// Break circular dependency
import * as containers from '../components/fields/containers';
import * as inputs from '../components/fields/inputs';
import * as repeats from '../components/fields/repeats';

@Injectable()
export class UfFormComponentRegistry implements ComponentRegistry {

	type = ComponentRegistryType.Input;

	// eslint-disable-next-line complexity
	get(name: string, options?: FormComponentRegistryOptions): Type<FormField> {
		switch (name as FieldType) {
			/** Structure Fields */
			case FieldType.Section: return containers.SectionComponent;
			case FieldType.Carousel: return containers.ContentCarouselComponent;
			case FieldType.Group: return containers.GroupComponent;
			case FieldType.Repeat: return repeats.RepeatComponent;
			case FieldType.ActionGroup: return containers.ActionGroupComponent;
			case FieldType.Survey: return containers.SurveyComponent;
			case FieldType.Content: return inputs.FieldContentComponent;
			case FieldType.Separator: return containers.SeparatorComponent;
			case FieldType.Stepper: return options?.isActive === true ? containers.FormStepperComponent : containers.GroupComponent;
			case FieldType.Step: return options?.isActive === true ? containers.FormStepComponent : containers.GroupComponent;
				/** Choice Fields */
			case FieldType.Lookup: return inputs.LookupComponent;
			case FieldType.Bool: return inputs.BoolComponent;
			case FieldType.Choice: return inputs.ChoiceComponent;
			case FieldType.MultiChoice: return inputs.MultiChoiceComponent;
			case FieldType.Hierarchy: return inputs.HierarchyUnitInputComponent;
				/** Import Fields */
			case FieldType.ImageList: return inputs.ImageListComponent;
			case FieldType.FileList: return inputs.FileListComponent;
				/** Advanced Fields */
			case FieldType.Address: return inputs.AddressComponent;
			case FieldType.GeoLocation: return inputs.GeoLocationComponent;
			case FieldType.Link: return inputs.ContentLinkComponent;
				/** Entry Fields */
			default: return inputs.UfInputComponent;
		}
	}

}
