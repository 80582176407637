import { AstNode, DataSeed, FormData, FormDataClient, isNotNull } from '@unifii/sdk';

import { Context, DataSourceLoader, DataSourceLoaderConfig, Scope, SourceConfigBucket } from '../../models';
import { ExpressionParser, sortByDisplay } from '../../services';

import { DataSourceConverter } from './data-source-converter';
import { toDataSeedSafeOption } from './retrocompatibility-loader-hack';
import { SourceConfigQueryBuilder } from './source-config-query-builder';

/** Initialized via DataLoaderFactory */
export class BucketLoader implements DataSourceLoader {

	private queryBuilder: SourceConfigQueryBuilder;

	constructor(
		public sourceConfig: SourceConfigBucket,
		public config: DataSourceLoaderConfig | undefined,
		private formDataClient: FormDataClient,
		private dataSourceConverter: DataSourceConverter,
		expressionParser: ExpressionParser,
	) {
		this.queryBuilder = new SourceConfigQueryBuilder(sourceConfig, expressionParser);
	}

	async getOptions(context?: Context, scope?: Scope): Promise<DataSeed[]> {

		const query = this.queryBuilder.create(undefined, context, scope);
		const formsData = await this.formDataClient.query(query, { analytics: this.config?.requestAnalytics });

		return formsData.map((fd) => this.mapToSeed(fd)).filter(isNotNull).map(toDataSeedSafeOption);
	}

	async search(q?: string, context?: Context, scope?: Scope, signal?: AbortSignal, contextFilters?: AstNode): Promise<DataSeed[]> {

		const query = this.queryBuilder.create(q, context, scope, undefined, contextFilters);

		const formsData = await this.formDataClient.query(query, { signal, analytics: this.config?.requestAnalytics });

		return formsData.map((u) => this.mapToSeed(u)).filter(isNotNull);
	}

	async findAllBy(match: string, context?: Context, scope?: Scope, signal?: AbortSignal): Promise<DataSeed[]> {

		if (!this.sourceConfig.findBy) {
			console.warn('Datasource has not set findBy, falling back on search');

			return this.search(match, context, scope, signal);
		}

		const query = this.queryBuilder.create(match, context, scope, this.sourceConfig.findBy);

		const formsData = await this.formDataClient.query(query, { signal, analytics: this.config?.requestAnalytics });

		return formsData.map((u) => this.mapToSeed(u)).filter(isNotNull).sort(sortByDisplay);
	}

	async get(id: string): Promise<DataSeed | null> {
		return this.mapToSeed(await this.formDataClient.get(id));
	}

	mapToSeed(formData?: FormData): DataSeed | null {
		return this.dataSourceConverter.toDataSeed(formData ?? null, this.sourceConfig);
	}

}
