import { Component, OnInit, inject } from '@angular/core';

import { ModalService } from '@unifii/library/common';

@Component({
	selector: 'sc-empty',
	templateUrl: './show-empty.html',
	styleUrls: ['./show-empty.less'],
})
export class ShowEmptyComponent implements OnInit {

	modalService = inject(ModalService);
	
	ngOnInit() {
		void this.modalService.openConfirm({ title: 'Logout', cancelLabel: 'No', confirmLabel: 'Yes' });
	}

}
