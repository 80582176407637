import { AstNode, DataSeed, FieldType, RequestAnalytics } from '@unifii/sdk';

import { Context, Scope } from './expression-interfaces';
import { SourceConfig } from './source-config-model';

export interface DataSourceLoaderConfig {
	requestAnalytics?: RequestAnalytics;
}

export interface DataSourceLoader {

	// The configured source
	sourceConfig: SourceConfig;
	// Configuration for loader runtime
	config?: DataSourceLoaderConfig;

	// Provide seeds from a specific source, used for options based fields
	// DataSeed '_id' and '_display' mapped also into 'identifier' and 'name' (retro-compatibility)
	getOptions: (context?: Context, scope?: Scope) => Promise<DataSeed[]>;

	// Provide seeds from a specific source, used for search fields
	search: (query?: string, context?: Context, scope?: Scope, signal?: AbortSignal, contextFilters?: AstNode) => Promise<DataSeed[]>;

	// Provide seed(s) from a specific source, used for data capture fields
	findAllBy: (match: string, context?: Context, scope?: Scope, signal?: AbortSignal) => Promise<DataSeed[]>;

	// Look up for seed by id
	get: (id: string) => Promise<DataSeed | null>;

	// Map the original value object to a seed based on the mapping outputs configuration
	mapToSeed: (value: any) => DataSeed | null;
}

/** The mapping entry to '_display' can be sourced from a restricted set of FieldType */
export const DataSourceMappingDisplayAllowedDataTypes = [
	FieldType.Text,
	FieldType.MultiText,
	FieldType.Number,
	FieldType.Phone,
	FieldType.Email,
	FieldType.Website,
];
