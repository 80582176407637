<uf-cascade-selection *ngIf="ready" [dataSource]="dataSource" [control]="inputControl" />

<uf-error *ngIf="inputControl.enabled" [control]="control" />

<uf-message *ngIf="error" [content]="error.message" icon="warningSolid" class="warning">
	<button *ngIf="!disabled && error.data.showRetry" (click)="retry()" type="button" class="uf-button small right">
		{{ sharedTK.ActionRetry | translate }}
	</button>
	<button *ngIf="!disabled && error.data.showClear" (click)="reset()" type="button" class="uf-button small right">
		{{ sharedTK.ActionClear | translate }}
	</button>
</uf-message>