import { Component, OnInit, inject } from '@angular/core';
import { hasLengthAtLeast } from '@unifii/sdk';

import { RuntimePage } from '@unifii/library/common';
import { DisplayService } from '@unifii/library/smart-forms/display';

import { CompoundInfo, UsAPIContentClient } from '../../services';

@Component({
	selector: 'sc-show-page',
	templateUrl: './show-page.html',
})
export class ShowPageComponent implements OnInit {

	protected pages: CompoundInfo[] = [];
	protected id?: string;
	protected page?: RuntimePage;

	private usContent = inject(UsAPIContentClient);
	private displayService = inject(DisplayService);

	async ngOnInit() {
		this.pages = await this.usContent.getPages();

		if (hasLengthAtLeast(this.pages, 1)) {
			this.id = this.pages[0].id;
			void this.render(this.id);
		}
	}

	async render(id: string | undefined) {

		this.id = id;

		if (!this.id) {
			delete this.page;

			return;
		}

		try {
			const page = await this.usContent.getPage(+this.id);
			const data = await this.displayService.renderPage(page);

			this.page = data.page;
		} catch (err) {
			console.error('ERROR', err);
		}
	}

}
