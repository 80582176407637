import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

export interface MutationEvent {
	addedElements: HTMLElement[];
	removedElements: HTMLElement[];
	mutationRecords: MutationRecord[];
}

@Directive({
	selector: '[domObserver]',
})
export class DomObserverDirective implements AfterViewInit, OnDestroy {

	@Output() mutations = new EventEmitter<MutationEvent>();
	@Input() mutationsConfig = { childList: true, attributes: true, subtree: true };

	private mutationObserver: MutationObserver;

	constructor(private el: ElementRef) { }

	ngAfterViewInit() {
		this.mutationObserver = new MutationObserver((records) => this.onMutation(records));
		this.mutationObserver.observe(this.el.nativeElement, this.mutationsConfig);
	}

	ngOnDestroy() {
		this.mutationObserver.disconnect();
	}

	private onMutation(mutationRecords: MutationRecord[]) {

		const event: MutationEvent = {
			addedElements: [],
			removedElements: [],
			mutationRecords,
		};

		for (const record of mutationRecords) {

			/** Typescript complaining about NodeList iterators so using old loop */
			for (let i = 0; i < record.addedNodes.length; i++) {
				event.addedElements.push(record.addedNodes.item(i) as HTMLElement);
			}

			for (let i = 0; i < record.removedNodes.length; i++) {
				event.removedElements.push(record.removedNodes.item(i) as HTMLElement);
			}
		}

		this.mutations.emit(event);

	}

}
