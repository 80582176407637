import { Component, Input } from '@angular/core';

import { DataDisplayComponentType } from '../../../models';

import { DataDisplayIconValue, isDataDisplayIconValue } from './data-display-components-model';

@Component({
	selector: 'uf-data-display-icon',
	templateUrl: './data-display-icon.html',
	styleUrls: ['../data-display.less'],
})
export class DataDisplayIconComponent implements DataDisplayComponentType {
	
	@Input({ required: true }) data: DataDisplayIconValue;

	static isDataAccepted = isDataDisplayIconValue;
	
}
