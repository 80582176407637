/* eslint-disable @typescript-eslint/naming-convention */
export enum TimezonesTranslationKey {
	'Africa/Abidjan' = 'africaAbidjan',
	'Africa/Accra' = 'africaAccra',
	'Africa/Nairobi' = 'africaNairobi',
	'Africa/Algiers' = 'africaAlgiers',
	'Africa/Lagos' = 'africaLagos',
	'Africa/Bissau' = 'africaBissau',
	'Africa/Maputo' = 'africaMaputo',
	'Africa/Cairo' = 'africaCairo',
	'Africa/Casablanca' = 'africaCasablanca',
	'Africa/Ceuta' = 'africaCeuta',
	'Africa/El_Aaiun' = 'africaEl_Aaiun',
	'Africa/Johannesburg' = 'africaJohannesburg',
	'Africa/Juba' = 'africaJuba',
	'Africa/Khartoum' = 'africaKhartoum',
	'Africa/Monrovia' = 'africaMonrovia',
	'Africa/Ndjamena' = 'africaNdjamena',
	'Africa/Sao_Tome' = 'africaSao_Tome',
	'Africa/Tripoli' = 'africaTripoli',
	'Africa/Tunis' = 'africaTunis',
	'Africa/Windhoek' = 'africaWindhoek',
	'America/Adak' = 'americaAdak',
	'America/Anchorage' = 'americaAnchorage',
	'America/Port_of_Spain' = 'americaPort_of_Spain',
	'America/Araguaina' = 'americaAraguaina',
	'America/Argentina/Buenos_Aires' = 'americaArgentinaBuenos_Aires',
	'America/Argentina/Catamarca' = 'americaArgentinaCatamarca',
	'America/Argentina/Cordoba' = 'americaArgentinaCordoba',
	'America/Argentina/Jujuy' = 'americaArgentinaJujuy',
	'America/Argentina/La_Rioja' = 'americaArgentinaLa_Rioja',
	'America/Argentina/Mendoza' = 'americaArgentinaMendoza',
	'America/Argentina/Rio_Gallegos' = 'americaArgentinaRio_Gallegos',
	'America/Argentina/Salta' = 'americaArgentinaSalta',
	'America/Argentina/San_Juan' = 'americaArgentinaSan_Juan',
	'America/Argentina/San_Luis' = 'americaArgentinaSan_Luis',
	'America/Argentina/Tucuman' = 'americaArgentinaTucuman',
	'America/Argentina/Ushuaia' = 'americaArgentinaUshuaia',
	'America/Curacao' = 'americaCuracao',
	'America/Asuncion' = 'americaAsuncion',
	'America/Atikokan' = 'americaAtikokan',
	'America/Bahia_Banderas' = 'americaBahia_Banderas',
	'America/Bahia' = 'americaBahia',
	'America/Barbados' = 'americaBarbados',
	'America/Belem' = 'americaBelem',
	'America/Belize' = 'americaBelize',
	'America/Blanc-Sablon' = 'americaBlancSablon',
	'America/Boa_Vista' = 'americaBoa_Vista',
	'America/Bogota' = 'americaBogota',
	'America/Boise' = 'americaBoise',
	'America/Cambridge_Bay' = 'americaCambridge_Bay',
	'America/Campo_Grande' = 'americaCampo_Grande',
	'America/Cancun' = 'americaCancun',
	'America/Caracas' = 'americaCaracas',
	'America/Cayenne' = 'americaCayenne',
	'America/Panama' = 'americaPanama',
	'America/Chicago' = 'americaChicago',
	'America/Chihuahua' = 'americaChihuahua',
	'America/Costa_Rica' = 'americaCosta_Rica',
	'America/Creston' = 'americaCreston',
	'America/Cuiaba' = 'americaCuiaba',
	'America/Danmarkshavn' = 'americaDanmarkshavn',
	'America/Dawson_Creek' = 'americaDawson_Creek',
	'America/Dawson' = 'americaDawson',
	'America/Denver' = 'americaDenver',
	'America/Detroit' = 'americaDetroit',
	'America/Edmonton' = 'americaEdmonton',
	'America/Eirunepe' = 'americaEirunepe',
	'America/El_Salvador' = 'americaEl_Salvador',
	'America/Tijuana' = 'americaTijuana',
	'America/Fort_Nelson' = 'americaFort_Nelson',
	'America/Fort_Wayne' = 'americaFort_Wayne',
	'America/Fortaleza' = 'americaFortaleza',
	'America/Glace_Bay' = 'americaGlace_Bay',
	'America/Godthab' = 'americaGodthab',
	'America/Goose_Bay' = 'americaGoose_Bay',
	'America/Grand_Turk' = 'americaGrand_Turk',
	'America/Guatemala' = 'americaGuatemala',
	'America/Guayaquil' = 'americaGuayaquil',
	'America/Guyana' = 'americaGuyana',
	'America/Halifax' = 'americaHalifax',
	'America/Havana' = 'americaHavana',
	'America/Hermosillo' = 'americaHermosillo',
	'America/Indiana/Knox' = 'americaIndianaKnox',
	'America/Indiana/Marengo' = 'americaIndianaMarengo',
	'America/Indiana/Petersburg' = 'americaIndianaPetersburg',
	'America/Indiana/Tell_City' = 'americaIndianaTell_City',
	'America/Indiana/Vevay' = 'americaIndianaVevay',
	'America/Indiana/Vincennes' = 'americaIndianaVincennes',
	'America/Indiana/Winamac' = 'americaIndianaWinamac',
	'America/Inuvik' = 'americaInuvik',
	'America/Iqaluit' = 'americaIqaluit',
	'America/Jamaica' = 'americaJamaica',
	'America/Juneau' = 'americaJuneau',
	'America/Kentucky/Louisville' = 'americaKentuckyLouisville',
	'America/Kentucky/Monticello' = 'americaKentuckyMonticello',
	'America/La_Paz' = 'americaLa_Paz',
	'America/Lima' = 'americaLima',
	'America/Los_Angeles' = 'americaLos_Angeles',
	'America/Maceio' = 'americaMaceio',
	'America/Managua' = 'americaManagua',
	'America/Manaus' = 'americaManaus',
	'America/Martinique' = 'americaMartinique',
	'America/Matamoros' = 'americaMatamoros',
	'America/Mazatlan' = 'americaMazatlan',
	'America/Menominee' = 'americaMenominee',
	'America/Merida' = 'americaMerida',
	'America/Metlakatla' = 'americaMetlakatla',
	'America/Mexico_City' = 'americaMexico_City',
	'America/Miquelon' = 'americaMiquelon',
	'America/Moncton' = 'americaMoncton',
	'America/Monterrey' = 'americaMonterrey',
	'America/Montevideo' = 'americaMontevideo',
	'America/Toronto' = 'americaToronto',
	'America/Nassau' = 'americaNassau',
	'America/New_York' = 'americaNew_York',
	'America/Nipigon' = 'americaNipigon',
	'America/Nome' = 'americaNome',
	'America/Noronha' = 'americaNoronha',
	'America/North_Dakota/Beulah' = 'americaNorth_DakotaBeulah',
	'America/North_Dakota/Center' = 'americaNorth_DakotaCenter',
	'America/North_Dakota/New_Salem' = 'americaNorth_DakotaNew_Salem',
	'America/Ojinaga' = 'americaOjinaga',
	'America/Pangnirtung' = 'americaPangnirtung',
	'America/Paramaribo' = 'americaParamaribo',
	'America/Phoenix' = 'americaPhoenix',
	'America/Port-au-Prince' = 'americaPortauPrince',
	'America/Rio_Branco' = 'americaRio_Branco',
	'America/Porto_Velho' = 'americaPorto_Velho',
	'America/Puerto_Rico' = 'americaPuerto_Rico',
	'America/Punta_Arenas' = 'americaPunta_Arenas',
	'America/Rainy_River' = 'americaRainy_River',
	'America/Rankin_Inlet' = 'americaRankin_Inlet',
	'America/Recife' = 'americaRecife',
	'America/Regina' = 'americaRegina',
	'America/Resolute' = 'americaResolute',
	'America/Santarem' = 'americaSantarem',
	'America/Santiago' = 'americaSantiago',
	'America/Santo_Domingo' = 'americaSanto_Domingo',
	'America/Sao_Paulo' = 'americaSao_Paulo',
	'America/Scoresbysund' = 'americaScoresbysund',
	'America/Sitka' = 'americaSitka',
	'America/St_Johns' = 'americaSt_Johns',
	'America/Swift_Current' = 'americaSwift_Current',
	'America/Tegucigalpa' = 'americaTegucigalpa',
	'America/Thule' = 'americaThule',
	'America/Thunder_Bay' = 'americaThunder_Bay',
	'America/Vancouver' = 'americaVancouver',
	'America/Whitehorse' = 'americaWhitehorse',
	'America/Winnipeg' = 'americaWinnipeg',
	'America/Yakutat' = 'americaYakutat',
	'America/Yellowknife' = 'americaYellowknife',
	'Antarctica/Casey' = 'antarcticaCasey',
	'Antarctica/Davis' = 'antarcticaDavis',
	'Antarctica/DumontDUrville' = 'antarcticaDumontDUrville',
	'Antarctica/Macquarie' = 'antarcticaMacquarie',
	'Antarctica/Mawson' = 'antarcticaMawson',
	'Pacific/Auckland' = 'pacificAuckland',
	'Antarctica/Palmer' = 'antarcticaPalmer',
	'Antarctica/Rothera' = 'antarcticaRothera',
	'Antarctica/Syowa' = 'antarcticaSyowa',
	'Antarctica/Troll' = 'antarcticaTroll',
	'Antarctica/Vostok' = 'antarcticaVostok',
	'Europe/Oslo' = 'europeOslo',
	'Asia/Riyadh' = 'asiaRiyadh',
	'Asia/Almaty' = 'asiaAlmaty',
	'Asia/Amman' = 'asiaAmman',
	'Asia/Anadyr' = 'asiaAnadyr',
	'Asia/Aqtau' = 'asiaAqtau',
	'Asia/Aqtobe' = 'asiaAqtobe',
	'Asia/Ashgabat' = 'asiaAshgabat',
	'Asia/Atyrau' = 'asiaAtyrau',
	'Asia/Baghdad' = 'asiaBaghdad',
	'Asia/Qatar' = 'asiaQatar',
	'Asia/Baku' = 'asiaBaku',
	'Asia/Bangkok' = 'asiaBangkok',
	'Asia/Barnaul' = 'asiaBarnaul',
	'Asia/Beirut' = 'asiaBeirut',
	'Asia/Bishkek' = 'asiaBishkek',
	'Asia/Brunei' = 'asiaBrunei',
	'Asia/Kolkata' = 'asiaKolkata',
	'Asia/Chita' = 'asiaChita',
	'Asia/Choibalsan' = 'asiaChoibalsan',
	'Asia/Shanghai' = 'asiaShanghai',
	'Asia/Colombo' = 'asiaColombo',
	'Asia/Dhaka' = 'asiaDhaka',
	'Asia/Damascus' = 'asiaDamascus',
	'Asia/Dili' = 'asiaDili',
	'Asia/Dubai' = 'asiaDubai',
	'Asia/Dushanbe' = 'asiaDushanbe',
	'Asia/Famagusta' = 'asiaFamagusta',
	'Asia/Gaza' = 'asiaGaza',
	'Asia/Hebron' = 'asiaHebron',
	'Asia/Ho_Chi_Minh' = 'asiaHo_Chi_Minh',
	'Asia/Hong_Kong' = 'asiaHong_Kong',
	'Asia/Hovd' = 'asiaHovd',
	'Asia/Irkutsk' = 'asiaIrkutsk',
	'Europe/Istanbul' = 'europeIstanbul',
	'Asia/Jakarta' = 'asiaJakarta',
	'Asia/Jayapura' = 'asiaJayapura',
	'Asia/Jerusalem' = 'asiaJerusalem',
	'Asia/Kabul' = 'asiaKabul',
	'Asia/Kamchatka' = 'asiaKamchatka',
	'Asia/Karachi' = 'asiaKarachi',
	'Asia/Urumqi' = 'asiaUrumqi',
	'Asia/Kathmandu' = 'asiaKathmandu',
	'Asia/Khandyga' = 'asiaKhandyga',
	'Asia/Krasnoyarsk' = 'asiaKrasnoyarsk',
	'Asia/Kuala_Lumpur' = 'asiaKuala_Lumpur',
	'Asia/Kuching' = 'asiaKuching',
	'Asia/Macau' = 'asiaMacau',
	'Asia/Magadan' = 'asiaMagadan',
	'Asia/Makassar' = 'asiaMakassar',
	'Asia/Manila' = 'asiaManila',
	'Asia/Nicosia' = 'asiaNicosia',
	'Asia/Novokuznetsk' = 'asiaNovokuznetsk',
	'Asia/Novosibirsk' = 'asiaNovosibirsk',
	'Asia/Omsk' = 'asiaOmsk',
	'Asia/Oral' = 'asiaOral',
	'Asia/Pontianak' = 'asiaPontianak',
	'Asia/Pyongyang' = 'asiaPyongyang',
	'Asia/Qostanay' = 'asiaQostanay',
	'Asia/Qyzylorda' = 'asiaQyzylorda',
	'Asia/Rangoon' = 'asiaRangoon',
	'Asia/Sakhalin' = 'asiaSakhalin',
	'Asia/Samarkand' = 'asiaSamarkand',
	'Asia/Seoul' = 'asiaSeoul',
	'Asia/Singapore' = 'asiaSingapore',
	'Asia/Srednekolymsk' = 'asiaSrednekolymsk',
	'Asia/Taipei' = 'asiaTaipei',
	'Asia/Tashkent' = 'asiaTashkent',
	'Asia/Tbilisi' = 'asiaTbilisi',
	'Asia/Tehran' = 'asiaTehran',
	'Asia/Thimphu' = 'asiaThimphu',
	'Asia/Tokyo' = 'asiaTokyo',
	'Asia/Tomsk' = 'asiaTomsk',
	'Asia/Ulaanbaatar' = 'asiaUlaanbaatar',
	'Asia/Ust-Nera' = 'asiaUstNera',
	'Asia/Vladivostok' = 'asiaVladivostok',
	'Asia/Yakutsk' = 'asiaYakutsk',
	'Asia/Yekaterinburg' = 'asiaYekaterinburg',
	'Asia/Yerevan' = 'asiaYerevan',
	'Atlantic/Azores' = 'atlanticAzores',
	'Atlantic/Bermuda' = 'atlanticBermuda',
	'Atlantic/Canary' = 'atlanticCanary',
	'Atlantic/Cape_Verde' = 'atlanticCape_Verde',
	'Atlantic/Faroe' = 'atlanticFaroe',
	'Atlantic/Madeira' = 'atlanticMadeira',
	'Atlantic/Reykjavik' = 'atlanticReykjavik',
	'Atlantic/South_Georgia' = 'atlanticSouth_Georgia',
	'Atlantic/Stanley' = 'atlanticStanley',
	'Australia/Sydney' = 'australiaSydney',
	'Australia/Adelaide' = 'australiaAdelaide',
	'Australia/Brisbane' = 'australiaBrisbane',
	'Australia/Broken_Hill' = 'australiaBroken_Hill',
	'Australia/Currie' = 'australiaCurrie',
	'Australia/Darwin' = 'australiaDarwin',
	'Australia/Eucla' = 'australiaEucla',
	'Australia/Hobart' = 'australiaHobart',
	'Australia/Lord_Howe' = 'australiaLord_Howe',
	'Australia/Lindeman' = 'australiaLindeman',
	'Australia/Melbourne' = 'australiaMelbourne',
	'Australia/Perth' = 'australiaPerth',
	'Pacific/Easter' = 'pacificEaster',
	'Europe/Dublin' = 'europeDublin',
	'Europe/Amsterdam' = 'europeAmsterdam',
	'Europe/Andorra' = 'europeAndorra',
	'Europe/Astrakhan' = 'europeAstrakhan',
	'Europe/Athens' = 'europeAthens',
	'Europe/London' = 'europeLondon',
	'Europe/Belgrade' = 'europeBelgrade',
	'Europe/Berlin' = 'europeBerlin',
	'Europe/Prague' = 'europePrague',
	'Europe/Brussels' = 'europeBrussels',
	'Europe/Bucharest' = 'europeBucharest',
	'Europe/Budapest' = 'europeBudapest',
	'Europe/Zurich' = 'europeZurich',
	'Europe/Chisinau' = 'europeChisinau',
	'Europe/Copenhagen' = 'europeCopenhagen',
	'Europe/Gibraltar' = 'europeGibraltar',
	'Europe/Helsinki' = 'europeHelsinki',
	'Europe/Kaliningrad' = 'europeKaliningrad',
	'Europe/Kiev' = 'europeKiev',
	'Europe/Kirov' = 'europeKirov',
	'Europe/Lisbon' = 'europeLisbon',
	'Europe/Luxembourg' = 'europeLuxembourg',
	'Europe/Madrid' = 'europeMadrid',
	'Europe/Malta' = 'europeMalta',
	'Europe/Minsk' = 'europeMinsk',
	'Europe/Monaco' = 'europeMonaco',
	'Europe/Moscow' = 'europeMoscow',
	'Europe/Paris' = 'europeParis',
	'Europe/Riga' = 'europeRiga',
	'Europe/Rome' = 'europeRome',
	'Europe/Samara' = 'europeSamara',
	'Europe/Saratov' = 'europeSaratov',
	'Europe/Simferopol' = 'europeSimferopol',
	'Europe/Sofia' = 'europeSofia',
	'Europe/Stockholm' = 'europeStockholm',
	'Europe/Tallinn' = 'europeTallinn',
	'Europe/Tirane' = 'europeTirane',
	'Europe/Ulyanovsk' = 'europeUlyanovsk',
	'Europe/Uzhgorod' = 'europeUzhgorod',
	'Europe/Vienna' = 'europeVienna',
	'Europe/Vilnius' = 'europeVilnius',
	'Europe/Volgograd' = 'europeVolgograd',
	'Europe/Warsaw' = 'europeWarsaw',
	'Europe/Zaporozhye' = 'europeZaporozhye',
	'Indian/Chagos' = 'indianChagos',
	'Indian/Christmas' = 'indianChristmas',
	'Indian/Cocos' = 'indianCocos',
	'Indian/Kerguelen' = 'indianKerguelen',
	'Indian/Mahe' = 'indianMahe',
	'Indian/Maldives' = 'indianMaldives',
	'Indian/Mauritius' = 'indianMauritius',
	'Indian/Reunion' = 'indianReunion',
	'Pacific/Kwajalein' = 'pacificKwajalein',
	'Pacific/Chatham' = 'pacificChatham',
	'Pacific/Apia' = 'pacificApia',
	'Pacific/Bougainville' = 'pacificBougainville',
	'Pacific/Chuuk' = 'pacificChuuk',
	'Pacific/Efate' = 'pacificEfate',
	'Pacific/Enderbury' = 'pacificEnderbury',
	'Pacific/Fakaofo' = 'pacificFakaofo',
	'Pacific/Fiji' = 'pacificFiji',
	'Pacific/Funafuti' = 'pacificFunafuti',
	'Pacific/Galapagos' = 'pacificGalapagos',
	'Pacific/Gambier' = 'pacificGambier',
	'Pacific/Guadalcanal' = 'pacificGuadalcanal',
	'Pacific/Guam' = 'pacificGuam',
	'Pacific/Honolulu' = 'pacificHonolulu',
	'Pacific/Kiritimati' = 'pacificKiritimati',
	'Pacific/Kosrae' = 'pacificKosrae',
	'Pacific/Majuro' = 'pacificMajuro',
	'Pacific/Marquesas' = 'pacificMarquesas',
	'Pacific/Pago_Pago' = 'pacificPago_Pago',
	'Pacific/Nauru' = 'pacificNauru',
	'Pacific/Niue' = 'pacificNiue',
	'Pacific/Norfolk' = 'pacificNorfolk',
	'Pacific/Noumea' = 'pacificNoumea',
	'Pacific/Palau' = 'pacificPalau',
	'Pacific/Pitcairn' = 'pacificPitcairn',
	'Pacific/Pohnpei' = 'pacificPohnpei',
	'Pacific/Port_Moresby' = 'pacificPort_Moresby',
	'Pacific/Rarotonga' = 'pacificRarotonga',
	'Pacific/Tahiti' = 'pacificTahiti',
	'Pacific/Tarawa' = 'pacificTarawa',
	'Pacific/Tongatapu' = 'pacificTongatapu',
	'Pacific/Wake' = 'pacificWake',
	'Pacific/Wallis' = 'pacificWallis',
	// TODO add initialized timezones when translations required
	// 'CET' = 'cet',
	// 'CST6CDT' = 'cst6ccdt',
	// 'EET' = 'EET',
	// 'EST' = 'EST',
	// 'EST5EDT' = 'EST5EDT',
	// 'Etc/UTC' = 'Etc/UTC',
	// 'HST' = 'HST',
	// 'MET' = 'MET',
	// 'MST' = 'MST',
	// 'MST7MDT' = 'MST7MDT',
	// 'PST8PDT' = 'PST8PDT',
	// 'WET' = 'WET'
	// 'Etc/GMT-0' = 'Etc/GMT-0',
	// 'Etc/GMT-1' = 'Etc/GMT-1',
	// 'Etc/GMT-10' = 'Etc/GMT-10',
	// 'Etc/GMT-11' = 'Etc/GMT-11',
	// 'Etc/GMT-12' = 'Etc/GMT-12',
	// 'Etc/GMT-13' = 'Etc/GMT-13',
	// 'Etc/GMT-14' = 'Etc/GMT-14',
	// 'Etc/GMT-2' = 'Etc/GMT-2',
	// 'Etc/GMT-3' = 'Etc/GMT-3',
	// 'Etc/GMT-4' = 'Etc/GMT-4',
	// 'Etc/GMT-5' = 'Etc/GMT-5',
	// 'Etc/GMT-6' = 'Etc/GMT-6',
	// 'Etc/GMT-7' = 'Etc/GMT-7',
	// 'Etc/GMT-8' = 'Etc/GMT-8',
	// 'Etc/GMT-9' = 'Etc/GMT-9',
	// 'Etc/GMT+1' = 'Etc/GMT+1',
	// 'Etc/GMT+10' = 'Etc/GMT+10',
	// 'Etc/GMT+11' = 'Etc/GMT+11',
	// 'Etc/GMT+12' = 'Etc/GMT+12',
	// 'Etc/GMT+2' = 'Etc/GMT+2',
	// 'Etc/GMT+3' = 'Etc/GMT+3',
	// 'Etc/GMT+4' = 'Etc/GMT+4',
	// 'Etc/GMT+5' = 'Etc/GMT+5',
	// 'Etc/GMT+6' = 'Etc/GMT+6',
	// 'Etc/GMT+7' = 'Etc/GMT+7',
	// 'Etc/GMT+8' = 'Etc/GMT+8',
	// 'Etc/GMT+9' = 'Etc/GMT+9',
}
