import { ContentChild, Directive, ElementRef, HostBinding, HostListener, inject } from '@angular/core';

// Break circular dependency
import { UfCheckboxComponent } from '../components/form/uf-checkbox.component';

/**
 * Toggles value on first child checkbox
 */
@Directive({
	selector: '[checkboxContainer]',
})
export class CheckboxContainerDirective {

	@HostBinding('style.cursor') cursor = 'pointer';

	@ContentChild(UfCheckboxComponent, { static: true }) private checkbox: UfCheckboxComponent | null;

	private elementRef = inject(ElementRef);

	@HostListener('click', ['$event.target'])
	onclick(target: Element) {
		/**
		 * Checkbox element not found
		 * or target element is checkbox so no action required
		 */
		if (this.checkbox == null || this.checkbox.disabled || this.targetIsCheckbox(target)) {
			return;
		}

		this.checkbox.control.setValue(!this.checkbox.value, { emitEvent: true });
	}

	private targetIsCheckbox(target: Element): boolean {

		while (target.parentElement) {

			// Is container
			if (target === this.elementRef.nativeElement) {
				return false;
			}

			// Contains checkbox input
			if (target.querySelector('input[type="checkbox"]') != null) {
				return true;
			}
			target = target.parentElement;
		}

		return false;
	}

}
