import { Component, OnInit, inject } from '@angular/core';
import { Client, Company, DataType, FormData, FormDataClient, ProjectContentOptions, ProjectContentOptionsInterface, UserInfo, detectDataType, isStringNotEmpty } from '@unifii/sdk';

import { DataDescriptor, DataDescriptorService, DataDisplayListItem, DataDisplayService, DataDisplaySpecificEntitiesService, DataLookupService, DataPropertyDescriptor, dataDescriptorToDataDisplayInfo } from '@unifii/library/common';
import { EntityPickerOutput } from 'showcase/src/app/components';

@Component({
	selector: 'sc-show-data-display-entity',
	templateUrl: './show-data-display-entity.html',
})
export class ShowDataDisplayEntityComponent implements OnInit {

	protected identifiersCSV = '';
	protected entityInfo: EntityPickerOutput | null;
	protected error: Error | undefined;
	protected results: {
		identifier: string;
		value: unknown;
		detectedDataType: DataType | undefined;
		dataDescriptor: DataPropertyDescriptor;
		dataDisplayItem?: DataDisplayListItem | undefined | null;
	}[] = [];

	private dataDescriptor: DataDescriptor | undefined;
	private record: UserInfo | Company | FormData | undefined;
	private dataLookupService = inject(DataLookupService);
	private dataDisplayService = inject(DataDisplayService);
	private dataDisplaySpecificEntitiesService = inject(DataDisplaySpecificEntitiesService);
	private projectContentOptions = inject(ProjectContentOptions) as ProjectContentOptionsInterface;
	private client = inject(Client);
	private dataDescriptorService = inject(DataDescriptorService);

	private get whitelistIdentifiers(): string[] {
		return this.identifiersCSV
			.split(',')
			.map((identifier) => identifier.trim())
			.filter(isStringNotEmpty);
	}

	async ngOnInit() {
		const bucket = 'fields';
		const formDataId = 'fcbcc7c4-46b9-49c8-bd26-0389c7e0faba';

		const formDataClient = new FormDataClient(this.client, {
			preview: this.projectContentOptions.preview,
			projectId: this.projectContentOptions.projectId,
			bucket,
		});

		const formData = await formDataClient.get(formDataId);
		const dataDescriptor = await this.dataDescriptorService.getBucketDataDescriptor(bucket);

		if (!dataDescriptor) {
			return;
		}

		const config: EntityPickerOutput = {
			type: 'bucket',
			formData,
			dataDescriptor,
		};

		this.onEntityEmit(config);
	}

	protected onEntityEmit(info: EntityPickerOutput | undefined) {
		this.results = [];

		switch (info?.type) {
			case 'user': {
				if (!info.user) {
					return;
				}

				this.dataDescriptor = info.dataDescriptor;
				this.record = structuredClone(info.user);
				this.buildResults();

				for (const result of this.results) {
					result.dataDisplayItem = this.dataDisplaySpecificEntitiesService.displayUserPropertyAsDescriptionItem(
						this.record as UserInfo,
						result.dataDescriptor,
					);
				}
			}
				break;
			case 'bucket': {
				if (!info.formData) {
					return;
				}

				this.dataDescriptor = info.dataDescriptor;
				this.record = info.formData;
				this.buildResults();

				for (const result of this.results) {
					const displayInfo = dataDescriptorToDataDisplayInfo(result.dataDescriptor);
					const value = this.dataLookupService.lookupData(this.record, result.identifier);
					
					if (!displayInfo || value == null) {
						continue;
					}

					result.dataDisplayItem = {
						data: this.dataDisplayService.displayAsDataDisplayValue(value, displayInfo),
					};
				}
			}
		}
	}

	private buildResults() {
		
		if (!this.dataDescriptor || !this.record) {
			this.results = [];
			
			return;
		}

		const displayEntriesDescriptors = this.dataDescriptor.propertyDescriptors
			.filter((descriptor) =>
				/* descriptor.asDisplay &&*/ (
					!this.whitelistIdentifiers.length ||
					this.whitelistIdentifiers.includes(descriptor.identifier)
				));

		for (const descriptor of displayEntriesDescriptors) {
			const value = this.dataLookupService.lookupData(this.record as unknown as Record<string, unknown>, descriptor.identifier);
			const detectedDataType = detectDataType(value).type as DataType;

			this.results.push({
				identifier: descriptor.identifier,
				value,
				detectedDataType,
				dataDescriptor: descriptor,
			});
		}
	}

}
