<div class="uf-container-md pad">
	<div class="uf-grid">
		<div class="col-12">
			<h2>Encoding Functions</h2>
			<p></p>
		</div>
		<uf-textarea [(value)]="jsonValue" label="JSON" class="col-6" />
		<uf-textarea [(value)]="encoded" label="String" class="col-6" />

		<div class="col-12 row">
			<div class="right"></div>
			<button (click)="decode()" class="uf-button gap-right" type="button">Decode</button>
			<button (click)="encode()" class="uf-button primary" type="button">Encode</button>
		</div>

	</div>
</div>