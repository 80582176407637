import { Component, HostBinding, Inject } from '@angular/core';
import { Client, DataSeed, DataSourceType, OperatorComparison, Option, ProjectContentOptions, ProjectContentOptionsInterface, PublishedContent } from '@unifii/sdk';

import { DataLoaderFactory, DataSourceLoader, Modal, ModalData, ModalRuntime, SourceConfig } from '@unifii/library/common';

export interface DataPropertyListerData {
	display?: string;
	sourceConfig?: SourceConfig;
	options?: Option[];
	operators?: OperatorComparison[];
}

@Component({
	selector: 'sc-show-data-item-descriptor-options',
	templateUrl: './show-data-item-descriptor-options.html',
})
export class ShowDataItemDescriptorOptionsComponent implements Modal<DataPropertyListerData, void> {

	@HostBinding('class.uf-form-card') cardClass = true;

	protected dataSourceLoader: DataSourceLoader | undefined;
	protected seeds: DataSeed[];
	protected selectedSeed: DataSeed | null;

	constructor(
		public runtime: ModalRuntime<DataPropertyListerData, void>,
		@Inject(ModalData) public entry: DataPropertyListerData,
		client: Client,
		@Inject(ProjectContentOptions) projectContentOptions: ProjectContentOptionsInterface,
		@Inject(PublishedContent) content: PublishedContent,
	) {
		if (this.entry.sourceConfig &&
			this.entry.sourceConfig.type !== DataSourceType.External &&
			this.entry.sourceConfig.type !== DataSourceType.Named
		) {
			const dataLoaderFactory = new DataLoaderFactory(client, content, projectContentOptions);

			this.dataSourceLoader = dataLoaderFactory.create(this.entry.sourceConfig);
		}
	}

	close() {
		this.runtime.close();
	}

	protected async search(q: string) {
		if (!this.dataSourceLoader) {
			return;
		}

		this.seeds = await this.dataSourceLoader.search(q);
	}

	protected getOperatorName(operator: OperatorComparison): string {
		switch (operator) {
			case 'eq': return 'Equal';
			case 'ne': return 'Not equal';
			case 'lt': return 'Lower than';
			case 'le': return 'Lower equal';
			case 'gt': return 'Greater than';
			case 'ge': return 'Greater equal';
			case 'in': return 'In';
			case 'contains': return 'Contains';
			case 'descs': return 'Descendants';
			default: return '';
		}
	}

}
