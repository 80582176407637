import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HierarchyUnitFormData } from '@unifii/sdk';

import { FilterEntry, FilterViewMode, IFilterComponent } from '../../../models';
import { CommonTranslationKey, SharedTermsTranslationKey } from '../../../translations';
import { HierarchyFunctions } from '../../../utils';

@Component({
	selector: 'uf-filter-hierarchy',
	templateUrl: './filter-hierarchy.html',
	styleUrls: ['./field-filter.less'],
})
export class FilterHierarchyComponent implements IFilterComponent {

	@Input() mode: FilterViewMode = FilterViewMode.Input;
	@Input() entry?: FilterEntry;
	@Input() value: HierarchyUnitFormData | null = null;

	@Output() valueChange = new EventEmitter<HierarchyUnitFormData>();

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;
	protected readonly modes = FilterViewMode;

	get label(): string {
		return this.entry?.label ?? '';
	}

	get isEmpty(): boolean {
		return this.value == null;
	}

	protected get displayValue(): string | null {
		return this.value ? HierarchyFunctions.pathToDisplay(this.value.path, this.entry?.format) : null;
	}

	protected remove() {
		this.value = null;
		this.valueChanged();
	}

	protected valueChanged() {
		this.valueChange.emit(this.value ?? undefined);
	}

}
