import { Component, ElementRef, Input, ViewChild, inject } from '@angular/core';

import { Size } from '@unifii/library/common';

import { ShowcaseContentService, ShowcasePage } from '../services';

@Component({
	selector: 'sc-page-content',
	templateUrl: './show-page-content.html',
	styleUrls: ['./show-page-content.less'],
})
export class ShowPageContentComponent {

	@ViewChild('api', { static: true }) apiRef: ElementRef<HTMLElement>;
	@ViewChild('editor', { static: true }) editorRef: ElementRef<HTMLElement>;

	@Input() showWidthToggle = false;

	containerClassName = '';
	data: ShowcasePage = {};

	private readonly widthMap = new Map<`${Size}`, string>([
		[Size.XL, 'uf-container-xl'],
		[Size.LG, 'uf-container-lg'],
		[Size.MD, 'uf-container-md'],
		[Size.SM, 'uf-container-sm'],
		[Size.XS, 'uf-container-xs'],
	]);
	private widthOrder = [Size.SM, Size.MD, Size.LG, Size.XL] as `${Size}`[];
	private widthIndex = 2;

	private content = inject(ShowcaseContentService);

	@Input() set width(v: `${Size}` | null) {
		const containerClassName = v && this.widthMap.get(v);

		if (containerClassName) {
			this.widthIndex = this.widthOrder.indexOf(v);
			this.containerClassName = containerClassName;
		}
	}

	@Input() set identifier(identifier: string) {
		this.data = this.content.getPage(identifier);
	}

	changePageSize(increment: number) {
		const widthIndex = this.widthIndex + increment;
		const width = this.widthOrder[widthIndex] as Size | undefined;

		if (width != null) {
			this.width = width;
		} else if (increment === 1) {
			this.containerClassName = '';
		}
	}

}
