export * from './show-app-details.component';
export * from './show-info-table.component';
export * from './show-login.component';
export * from './show-configuration.component';
export * from './show-data-display-bold.component';
export * from './show-block.component';
export * from './show-field-editor-form.component';
export * from './show-menu.component';
export * from './show-header.component';
export * from './show-menu-item.component';
export * from './show-playground.component';
export * from './show-dynamic.component';
export * from './show-entity-picker.component';
export * from './show-code-editor-expander.component';
export * from './show-column-demo.component';
export * from './show-user-details.component';
export * from './show-page-content.component';

import { ShowAppDetailsComponent } from './show-app-details.component';
import { BlockComponent } from './show-block.component';
import { ShowCodeEditorExpanderComponent } from './show-code-editor-expander.component';
import { ShowColumnDemoComponent } from './show-column-demo.component';
import { ConfigurationComponent } from './show-configuration.component';
import { DataDisplayBoldComponent } from './show-data-display-bold.component';
import { ShowDynamicComponent } from './show-dynamic.component';
import { ShowEntityPickerComponent } from './show-entity-picker.component';
import { FieldEditorFormComponent } from './show-field-editor-form.component';
import { HeaderComponent } from './show-header.component';
import { ShowInfoTableComponent } from './show-info-table.component';
import { ShowLoginComponent } from './show-login.component';
import { ShowMenuItemComponent } from './show-menu-item.component';
import { MenuComponent } from './show-menu.component';
import { ShowPageContentComponent } from './show-page-content.component';
import { ShowPlaygroundComponent } from './show-playground.component';
import { ShowUserDetailsComponent } from './show-user-details.component';

export const SC_COMPONENTS = [
	ShowAppDetailsComponent, ShowUserDetailsComponent, ShowLoginComponent, ShowInfoTableComponent,
	ConfigurationComponent, DataDisplayBoldComponent, BlockComponent, FieldEditorFormComponent,
	MenuComponent, HeaderComponent, ShowMenuItemComponent, ShowPlaygroundComponent,
	ShowDynamicComponent, ShowCodeEditorExpanderComponent,
	ShowColumnDemoComponent, ShowPageContentComponent, ShowEntityPickerComponent,
];
