import { Component, EventEmitter, HostBinding, Input, NgZone, Output, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FileData, FileType, Progress } from '@unifii/sdk';

import { UfControl } from '../../controls';
import { FileUploader, InputFile } from '../../models';
import { ModalService } from '../../services/modal.service';
import { CommonTranslationKey } from '../../translations';

import { UfControlValueAccessor } from './uf-control-value-accessor';

export const ImageFileType = 'image/*';
export const ImageFileLabel = 'image';

@Component({
	selector: 'uf-media-list',
	templateUrl: './uf-media-list.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfMediaListComponent, multi: true,
	}],
	styleUrls: ['./uf-media-list.less'],
})
export class UfMediaListComponent extends UfControlValueAccessor<FileData[]> {

	@Input() label: string | null | undefined;
	@Input() fileType: string | null | undefined;
	@Input() maxLength: number | null | undefined;
	@Input() fileTypeLabel: string | null | undefined;
	@Input() cameraRequired: boolean | null | undefined;
	@Input() allowedFileTypes: FileType[] | undefined;
	@Output() override valueChange = new EventEmitter<FileData[]>();
	@Output() progressChange = new EventEmitter<InputFile[]>();

	inputFiles: InputFile[] = [];
	imageFileType = ImageFileType;
	fileUploader = inject(FileUploader);

	private _cssClass: string | string[] | undefined;
	private zone = inject(NgZone);
	private modalService = inject(ModalService);
	private translate = inject(TranslateService);

	@Input() override set value(v: FileData[] | null | undefined) {
		void this.updateInputFileList(v ?? []);

		if (this.patternUtil.isEqual(v, this.value)) {
			return;
		}
		super.value = v;
	}

	override get value(): FileData[] | null | undefined {
		return super.value;
	}

	@Input() override set control(v: UfControl) {
		// Force cast to optional for guarding against potential null bound as any
		if ((v as UfControl | undefined) == null) {
			return;
		}

		super.control = v;

		// Call update file list after control value accessor has set control value
		void this.updateInputFileList(this.value ?? []);
	}

	override get control(): UfControl {
		return super.control;
	}

	@Input() set cssClass(v: string | string[] | undefined) {
		this._cssClass = v;
	}

	get cssClass(): string | string[] {
		return this._cssClass ?? [];
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	get canAdd(): boolean {
		return !(this.disabled ||
			(this.maxLength != null && this.inputFiles.length >= this.maxLength));
	}

	async add(files: File[]) {
		for (const file of files) {
			if (!await this.canUpload(file)) {
				continue;
			}

			const inputFile = this.startUpload(file);

			this.inputFiles.push(inputFile);
		}
	}

	async remove(inputFile: InputFile) {
		const index = this.inputFiles.findIndex((item) => item === inputFile);

		if (index === -1) {
			return;
		}

		inputFile.controller?.abort();

		this.inputFiles.splice(index, 1);

		if (this.fileUploader.remove && inputFile.file.id) {
			await this.fileUploader.remove(inputFile.file.id);
		}

		if (!this.value) {
			return;
		}

		const value = this.value;

		value.splice(index, 1);

		this.control.setValue(value.length > 0 ? value : undefined);
	}

	private uploadDone(inputFile: InputFile, progress: Progress) {
		if (!progress.id) {
			return;
		}

		inputFile.file.id = progress.id;
		inputFile.progress = 1;
		inputFile.completed = true;

		const value = this.value ?? [];

		value.push(inputFile.file);

		this.control.setValue(value);
	}

	private async updateInputFileList(value: FileData[]) {
		const uploads = this.inputFiles.filter((i) => i.uploadPromise != null).map((i) => i.uploadPromise);

		if (uploads.length) {
			await Promise.all(uploads);
		}

		const valueIds = value.map((v) => v.id) as string[];
		const inputIds = this.inputFiles.map((v) => v.file.id) as string[];

		if (!this.areArraysEquivalent(valueIds, inputIds)) {
			this.inputFiles.forEach((i) => i.controller?.abort());
			this.inputFiles = value.map(this.createUploadedInputFile.bind(this));
		}
	}

	private uploadError(inputFile: InputFile) {
		if (!inputFile.controller?.signal.aborted) {
			void this.showAlert(this.translate.instant(CommonTranslationKey.UploadErrorFailedToUploadMessage) as string);
		}

		void this.remove(inputFile);
	}

	private async canUpload({ size, type, name }: File): Promise<boolean> {
		// Check allowed file types
		const fileExtension = '.' + (name.split('.').pop() ?? '').toLowerCase();
		const allowed = !this.allowedFileTypes || !!this.allowedFileTypes.find(({ extensions, mimeTypes }) => extensions.includes(fileExtension) && mimeTypes.includes(type));

		if (!allowed) {
			await this.showAlert(this.translate.instant(CommonTranslationKey.UploadErrorFileTypeDeniedMessage) as string);

			return false;
		}

		// Check file size, limit to files under 100mb
		if (Math.round(size / 1024) > 102400) {
			await this.showAlert(this.translate.instant(CommonTranslationKey.UploadErrorFileToLargeMessage) as string);

			return false;
		}

		return true;
	}

	private startUpload(file: File): InputFile {
		const progressCallback = (progress: Progress) => {
			this.onProgressChange();
			this.zone.run(() => { inputFile.progress = progress.done / progress.total; });
		};

		const inputFile: InputFile = {
			file: { name: file.name },
			progress: 0,
			completed: false,
			controller: new AbortController(),
		};

		inputFile.uploadPromise = this.fileUploader.upload(file, progressCallback, inputFile.controller?.signal)
			.then((progress) => { this.uploadDone(inputFile, progress); })
			.catch(() => { this.uploadError(inputFile); })
			.finally(this.onProgressChange);

		return inputFile;
	}

	private onProgressChange = () => {
		this.progressChange.emit(this.inputFiles);
	};

	private createUploadedInputFile(file: FileData): InputFile {
		return {
			file,
			progress: 1,
			completed: true,
			uploadPromise: Promise.resolve(),
		};
	}

	private showAlert(message: string): Promise<void> {
		return this.modalService.openAlert({
			message,
			title: this.translate.instant(CommonTranslationKey.UploadErrorAlertTitle) as string,
		});
	}

	private areArraysEquivalent(array1: string[], array2: string[]) {
		if (array1.length !== array2.length) {
			return false;
		}

		return array1.every((element) => array2.includes(element));
	}

}
