<uf-expander *ngIf="field" [actionBtn]="true" [ngClass]="widthClass">
	<div [class.small]="isSummary && control.disabled" [class.error]="control.submitted && control.invalid"
		expanderHeader class="uf-app-bar medium">
		<uf-icon *ngIf="field.icon" [name]="field.icon" />
		<div class="title">
			{{field.label}}
			<uf-help *ngIf="field.help" [content]="field.help" icon="infoSolid" class="grow" />
		</div>
	</div>
	<div expanderBody class="form-group-content">

		<div class="labels row">
			<div class="spacer"></div>
			<div [columns]="options.length" [cellTotal]="options.length" ufGrid direction="Row" rows="1" columnGap="sm"
				class="grow">
				<div *ngFor="let option of options" class="label">
					{{ option.name }}
				</div>
			</div>
		</div>

		<ng-container *ngFor="let child of field.fields">
			<ng-template [ngIf]="isVisible(child)">
				<ng-template [ngIf]="child.identifier" [ngIfElse]="nonContent">
					<uf-field [field]="child" [scope]="scope" [(content)]="scope[child.identifier]" class="col- pad" />
				</ng-template>
				<ng-template #nonContent>
					<uf-field [field]="child" [scope]="scope" class="col- pad" />
				</ng-template>
			</ng-template>
		</ng-container>

	</div>

</uf-expander>