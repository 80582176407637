import { AfterViewInit, Component, ComponentRef, ElementRef, HostBinding, HostListener, Injector, Input, ViewChild, ViewContainerRef, inject } from '@angular/core';

import { Animations } from '../../constants';
// Break circular dependency
import { ModalService } from '../../services/modal.service';

import { Modal } from './modal';
import { ModalRuntime } from './modal-runtime';
import { ModalConfig, ModalData } from './modal-types';

@Component({
	selector: 'uf-modal-centered-render',
	templateUrl: './modal-centered-render.html',
	styleUrls: ['./modal-centered-render.less'],
	animations: [Animations.ModalAnimations],
})
export class ModalCenteredRenderComponent<Data, Result> implements AfterViewInit {

	@HostBinding('@modalAnimations') animation: any;

	// No risk of override DOM classes, used only in modal-container.component
	@HostBinding('class') get classes() {
		return `modal-${this.config.display}`;
	}

	@ViewChild('modal', { read: ViewContainerRef, static: true }) viewContainerRef: ViewContainerRef;

	@Input({ required: true }) config: ModalConfig<Data, Result>;

	private modalService = inject(ModalService);
	private element = inject<ElementRef<HTMLDivElement>>(ElementRef);
	private target: EventTarget | null;
	private modalComponentRef?: ComponentRef<Modal<Data, Result>>;

	ngAfterViewInit() {

		const providers = [
			{ provide: ModalRuntime, useValue: new ModalRuntime(this.modalService, this.config) },
			{ provide: ModalData, useValue: this.config.data ?? {} },
			...(this.config.providers ?? []),
		];

		const injector = Injector.create({ providers, parent: this.config.injector ?? this.viewContainerRef.injector });

		this.modalComponentRef = this.viewContainerRef.createComponent(this.config.type, { index: 0, injector });
		this.modalComponentRef.changeDetectorRef.detectChanges();
		this.focus();
	}

	focus() {
		// TODO doesn't work as only inputs and a tags can receive focus
		this.element.nativeElement.focus();
	}

	@HostListener('mousedown', ['$event'])
	mousedown(e: MouseEvent) {
		/**
		 * Set target element on mousedown to handle the case
		 * where uses click inside the modal and drag outside eg. when highlighting text
		 */
		this.target = e.target;
	}

	@HostListener('click', ['$event']) // only needed in IE10 that doesn't support CSS pointer-events
	userClick() {

		if (this.target !== this.element.nativeElement || !this.canClose()) {
			return;
		}

		this.close();
	}

	@HostListener('document:keydown', ['$event'])
	keydown(e: KeyboardEvent) {
		e.stopPropagation();

		if (e.keyCode !== 27 || !this.canClose()) {
			return;
		}

		this.close();
	}

	close = () => {
		this.modalService.close(this.config);
	};

	/** Order in priority to decide if the component can be closed
	 *  - ModalService guard option
	 *  - Component guard option
	 *  - true
	*/
	private canClose() {
		if (this.config.guard != null) {
			return !this.config.guard;
		}

		const componentGuard = this.modalComponentRef?.instance.guard;

		if (componentGuard == null) {
			return true;
		}

		return !(
			typeof componentGuard === 'boolean' ?
				componentGuard :
				componentGuard()
		);
	}

}
