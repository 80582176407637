import { AfterViewInit, Component, EventEmitter, HostBinding, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Address, DataSeed, FieldType, FormStyle, GeoLocation, ensureError, isAddress, isDataSeed, isGeoLocation, isNotNull, isStringTrimmedNotEmpty, objectKeys } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { ContextProvider, LocationProvider, LocationProviderType, PatternUtil, ProgressComponent, RuntimeField, SharedTermsTranslationKey, ToastService, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { FormField, FormService, SmartFormsTranslationKey, WorkflowService } from '@unifii/library/smart-forms';

import { InputTranslationKey } from '../../../translations';

import { NestedField } from './model';

type AddressInternalValue = Address & { map?: Geolocation };
type AddressNestedFieldsKeys = Exclude<keyof AddressInternalValue, 'formattedAddress' | 'room' | 'venue' | 'lat' | 'lng' | 'zoom'>;
type AddressNestedField = NestedField & { identifier: AddressNestedFieldsKeys };

const PreventDisabledBubble = 'preventDisabledBubble';

@Component({
	selector: 'uf-address-input',
	templateUrl: './address.html',
	styleUrls: ['../form-group.less', './address.less'],
})
export class AddressComponent implements FormField, OnInit, AfterViewInit, OnDestroy {

	@HostBinding('class.uf-box') protected groupClassName = true;
	@HostBinding('class.hidden') protected isHidden: boolean;
	@ViewChild('progress') private progressComponent: ProgressComponent;

	contentChange = new EventEmitter<Address>();
	control: UfControlGroup;
	field: RuntimeField;
	cssClass: string | string[];
	
	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly inputTK = InputTranslationKey;
	protected nestedFields: AddressNestedField[];
	protected autocompleteOptions: DataSeed[];
	protected mapAvailable: boolean;
	protected isSummary: boolean;

	private readonly cssClasses: Readonly<Record<string, string>> = Object.freeze({
		address1: 'col-12',
		address2: 'col-12',
		suburb: 'col-6',
		city: 'col-6',
		state: 'col-6',
		postcode: 'col-6',
		country: 'col-6',
	});
	private _content: Address = {};
	private lastEmittedContent: Address | null = null;
	private region: 'AU' | 'NZ';
	private patternUtil = new PatternUtil<Address>();
	private subscriptions = new Subscription();
	private toastService = inject(ToastService);
	private translateService = inject(TranslateService);
	private readonly inputLabels: Record<AddressNestedFieldsKeys, string> = {
		address1: this.translateService.instant(SmartFormsTranslationKey.AddressInputFieldLineOneLabel) as string,
		address2: this.translateService.instant(SmartFormsTranslationKey.AddressInputFieldLineTwoLabel) as string,
		suburb: this.translateService.instant(SmartFormsTranslationKey.AddressInputFieldSuburbLabel) as string,
		city: this.translateService.instant(SmartFormsTranslationKey.AddressInputFieldCityLabel) as string,
		state: this.translateService.instant(SmartFormsTranslationKey.AddressInputFieldStateLabel) as string,
		postcode: this.translateService.instant(SmartFormsTranslationKey.AddressInputFieldPostcodeLabel) as string,
		country: this.translateService.instant(SmartFormsTranslationKey.AddressInputFieldCountryLabel) as string,
		map: this.translateService.instant(SmartFormsTranslationKey.AddressInputFieldMapLabel) as string,
	};
	private locationProvider = inject(LocationProvider);
	private contextProvider = inject(ContextProvider);
	private workflowService = inject(WorkflowService, { optional: true });
	private formService = inject(FormService, { optional: true });

	@HostBinding('class.error') get hostError() {
		return this.control.submitted && !this.control.valid;
	}

	@HostBinding('class.disabled') get hostDisabled() {
		return this.control.disabled;
	}

	set content(v: Address | null | undefined) {
		const safeContent = v ?? {};

		if (this.patternUtil.isEqual(safeContent, this._content)) {
			return;
		}

		this.lastEmittedContent = safeContent;
		this._content = safeContent;

		this.updateControls(this._content);
	}

	get content(): Address {
		return this._content;
	}

	ngOnInit() {
		this.mapAvailable = this.locationProvider.type === LocationProviderType.Google;
		this.isSummary = this.formService?.style === FormStyle.Summary;
		this.region = (this.contextProvider.get().region as 'AU' | 'NZ' | null) ?? 'AU';

		/**
		* If there are no visible fields the input is considered as hidden and not displayed
		* this is used in with autodetect so it can silently autodetect
		* not great behavior this is a side effect that should be fixed
		*/
		this.isHidden = !this.field.visibleFields.filter((v) => Object.keys(this.inputLabels).includes(v)).length;

		if (this.isHidden) {
			void this.locateHiddenField();

			return;
		}

		const fields = this.createAddressNestedFields(this.field);
		const controls = this.createNestedFormControls(fields, this.control);

		/**
		 * set an enabled control if all the fields are read only to make sure the disabled state doesn't buddle up
		*/
		let enabledControl;

		if (!fields.some((f) => !f.isReadOnly) && !this.control.disabled) {
			enabledControl = new UfControl();
			this.control.setControl(PreventDisabledBubble, enabledControl);
		}

		for (const { identifier, control } of controls) {
			this.control.setControl(identifier, control);
		}

		// if there is at least one required field, add validator and dependency
		const requiredField = fields.find((f) => f.isRequired);

		if (enabledControl && requiredField) {
			const dependency = this.control.controls[requiredField.identifier] as UfControl;

			enabledControl.setValidators(ValidatorFunctions.custom(
				() => dependency.value != null,
				this.translateService.instant(SharedTermsTranslationKey.ValidatorValueRequired),
			));
			enabledControl.addDependencies([dependency]);
			enabledControl.updateValueAndValidity();
		}

		// align control values with content
		this.updateControls(this.content);

		this.subscriptions.add(this.control.valueChanges.subscribe((value) => { this.onValueChanges(value); }));
		this.nestedFields = fields;
	}

	ngAfterViewInit() {
		/**
		 * After view required to ensure we have access to all child components
		 * progress component is required for locate
		 */
		if (this.field.autoDetect &&
			!this.isHidden &&
			(this.workflowService != null && this.workflowService.isActive(this.field)) &&
			this.hasValorizedFields(this.content, this.field.visibleFields)
		) {
			void this.locate();
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected clear() {
		this.updateControls(null);
	}

	protected async locate() {
		try {
			this.progressComponent.start();
			this.updateControls(await this.getDeviceAddress());
		} catch (error) {
			this.toastService.error(ensureError(error).message);
		} finally {
			this.progressComponent.complete();
		}
	}

	protected async search(q: string) {
		if (!this.locationProvider.search) {
			return;
		}

		try {
			this.autocompleteOptions = await this.locationProvider.search(q);
		} catch (e) {
			/** Fail silently as field should work as normal input */
		}
	}

	protected async findLocation(data: DataSeed | Address) {
		try {
			if (this.locationProvider.findLocation) {
				const addressStringValue = isDataSeed(data) ? data._display : this.mapAddressToSearchText(data);
				
				this.content = await this.locationProvider.findLocation(addressStringValue,	this.region);
			}

			if (this.hasValorizedFields(this.content, this.field.visibleFields.filter((f) => f !== 'map'))) {
				this.toastService.error(this.translateService.instant(InputTranslationKey.AddressInputErrorInsufficientDataToLocate) as string);
			}
		} catch (error) {
			this.toastService.error(ensureError(error).message);

			return;
		}

		this.updateControls(this.content);
	}

	protected async findAddress() {

		if (!this.locationProvider.findAddress) {
			return;
		}

		if (!isGeoLocation(this.content)) {
			this.toastService.error(this.translateService.instant(InputTranslationKey.AddressInputErrorNoMarkerPosition) as string);

			return;
		}

		try {
			const updatedAddress = await this.locationProvider.findAddress(this.content);

			this.updateControls(Object.assign(this.content, updatedAddress));
		} catch (e) {
			/* Fails silently */
		}
	}

	private updateControls(address: Address | null | undefined) {
		const controlKeys = objectKeys(this.control.controls) as (keyof Address)[];

		if (!controlKeys.length) {
			// controls not setup
			return;
		}

		const safeAddress = address ?? {};

		/**
		 * Create object that matches controls otherwise angular will throw errors
		 */
		const addressControlValues = controlKeys.reduce<Record<string, unknown>>((obj, key) => {
			obj[key] = safeAddress[key] ?? null;

			return obj;
		}, {});

		if (isGeoLocation(address)) {
			addressControlValues.map = {
				lat: address.lat,
				lng: address.lng,
				zoom: address.zoom,
			} satisfies GeoLocation;
		} else {
			addressControlValues.map = null;
		}

		/**
		 * If prevent the preventDisabled bubble control exists it means setting
		 * the value on the group control will have no effect so onValueChanges has
		 * to be triggered manually
		 */
		if (this.control.controls[PreventDisabledBubble]) {
			this.onValueChanges(addressControlValues);
		} else {
			// Ensure control has controls for each value in addressControlValues
			for (const key of Object.keys(addressControlValues)) {
				if (!this.control.get(key)) {
					// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
					delete addressControlValues[key];
				}
			}

			this.control.setValue(addressControlValues);
		}
	}

	private createAddressNestedFields(field: RuntimeField): AddressNestedField[] {

		const order = objectKeys(this.inputLabels);

		const nestedFields: AddressNestedField[] = (field.visibleFields as AddressNestedFieldsKeys[])
			// ensure correct order
			.sort((a, b) => order.indexOf(a) - order.indexOf(b))
			.map((key) => ({
				label: this.inputLabels[key],
				type: FieldType.Text,
				identifier: key,
				isRequired: field.requiredFields.includes(key),
				isReadOnly: field.readOnlyFields.includes(key),
				cssClass: [this.cssClasses[key], ...this.customCssClass].join(' '),
				suffix: this.getFieldSuffix(field.requiredFields.includes(key)),
			}));

		return nestedFields;
	}

	private createNestedFormControls(fields: AddressNestedField[], parentControl: UfControlGroup): { identifier: string; control: UfControl }[] {

		return fields.map((field) => {

			const control = new UfControl();

			if (field.isRequired) {
				control.setValidators(ValidatorFunctions.required(this.translateService.instant(SharedTermsTranslationKey.ValidatorValueRequired) as string));
			}

			if (field.isReadOnly) {
				control.component = { disabled: true };
			}

			if (field.isReadOnly || parentControl.disabled) {
				control.disable();
			}

			return {
				identifier: field.identifier,
				control,
			};
		});
	}

	private async locateHiddenField() {

		if (isGeoLocation(this.content)) {
			return;
		}

		try {
			this.contentChange.emit(await this.getDeviceAddress());
		} catch (error) {
			console.error('AddressComponent - auto-locate failed', error);
		}
	}

	private async getDeviceAddress(): Promise<Address> {
		const location = await this.locationProvider.locate();
		
		if (!this.locationProvider.findAddress) {
			return location;
		}

		return Object.assign(
			location,
			await this.locationProvider.findAddress(location),
		);
	}

	private getFieldSuffix(required: boolean): string {

		if (required && this.formService?.definitionSettings.requiredSuffix) {
			return this.formService.definitionSettings.requiredSuffix;
		}

		return this.formService?.definitionSettings.optionalSuffix ?? '';
	}

	private hasValorizedFields(address: Address | null | undefined, includeKeys: string[]) {

		if (!includeKeys.length) {
			return true;
		}

		return !address || objectKeys(address)
			.filter((k) => includeKeys.includes(k) && !ValidatorFunctions.isEmpty(address[k]))
			.length === 0;
	}

	private onValueChanges(value: AddressInternalValue) {
		/**
		 * Map control needs to be part of the control tree
		 * for validation but values should not be emitted as part of the value
		 */
		if (isGeoLocation(value.map)) {
			Object.assign(value, { lat: value.map.lat, lng: value.map.lng, zoom: value.map.zoom });
			delete value.map;
		}

		/** Clean data */
		for (const key of objectKeys(value)) {
			if (ValidatorFunctions.isEmpty(value[key])) {
				// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
				delete value[key];
			}
		}

		if (!this.patternUtil.isEqual(value, this.lastEmittedContent)) {

			this.lastEmittedContent = value;

			if (!isAddress(value)) {
				this.contentChange.emit(undefined);
			} else {
				this.contentChange.emit(value);
			}
		}
	}

	private get customCssClass(): string[] {
		return (Array.isArray(this.cssClass) ? this.cssClass : [this.cssClass]).filter(isNotNull);
	}

	private mapAddressToSearchText(data: Address): string {
		return [
			data.address1,
			data.suburb,
			data.postcode,
			data.state,
			data.country,
		].filter(isStringTrimmedNotEmpty).join(' ');
	}

}
