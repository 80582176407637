<sc-page-content width="lg" identifier="directory">

	<ng-container editor>
		<sc-code-editor-expander class="pad-sides">
			<div [root]="directory" ufDirectoryRoot childrenProperty="children"
				style="min-width: 420px; background-color: white;">
				<ng-container *ngFor="let item of directory | flatten: 'children'">
					<div [item]="item" ufDirectory class="row center-all">
						<uf-directory-indicator />
						{{ item.label }}
					</div>
				</ng-container>
			</div>

		</sc-code-editor-expander>

		<h3 class="pad-sides">Examples</h3>
		<h3 class="pad-sides">List</h3>
		<ul [root]="directory" ufDirectoryRoot childrenProperty="children" class="uf-list small">
			<ng-container *ngFor="let item of directory | flatten: 'children'">
				<li [item]="item" ufDirectory class="row center-all uf-list-item pad-none">
					<uf-directory-indicator />
					{{ item.label }}
				</li>
			</ng-container>
		</ul>
		<h3 class="pad-sides">Table</h3>
		<table #example class="uf-table accent clickable">
			<thead>
				<tr>
					<th>Group</th>
					<th>Something else</th>
				</tr>
			</thead>
			<tbody [root]="directory" ufDirectoryRoot childrenProperty="children">
				<tr *ngFor="let item of directory | flatten: 'children'" [item]="item" (click)="testClick()"
					ufDirectory>
					<td class="no-padding">
						<uf-directory-indicator class="light"><code>{{item.label}}</code></uf-directory-indicator>
					</td>
					<td>
						Other thing
					</td>
				</tr>
			</tbody>
		</table>

	</ng-container>

	<ng-container api>
		<h3 class="pad-sides">CSS Classes</h3>
		<sc-info-table [columnConfig]="tableConfig" [rows]="tableInfo" />
	</ng-container>


</sc-page-content>