import { Component } from '@angular/core';
import { Option } from '@unifii/sdk';

import { ColumnGap, MessageLevel, Size, ToastService } from '@unifii/library/common';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-toaster.html',
})
export class ShowToasterComponent {

	readonly sizes = Size;
	readonly columnGaps = ColumnGap;

	readonly apiColConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'inputs', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];

	readonly propertiesRows = [
		{
			name: 'error',
			inputs: 'message: string, options: ToastOptions',
			description: 'Show error toast',
		},
		{
			name: 'warning',
			inputs: 'message: string, options: ToastOptions',
			description: 'Show warning toast',
		},
		{
			name: 'info',
			inputs: 'message: string, options: ToastOptions',
			description: 'Show information toast',
		},
		{
			name: 'success',
			inputs: 'message: string, options: ToastOptions',
			description: 'Show success toast',
		},
	];

	readonly timingOptions: Option[] = [
		{ identifier: '', name: 'Default (Dependant on message type)' },
		{ identifier: '-1', name: 'Permanent' },
		{ identifier: '3000', name: '3 seconds (Long)' },
		{ identifier: '2000', name: '2 seconds (Medium)' },
		{ identifier: '1000', name: '1 seconds (Short)' },
	];

	messageLevels = MessageLevel;
	time = '';
	inProgress: boolean;

	constructor(private toast: ToastService) { }

	show(level: MessageLevel) {

		this.inProgress = true;
		const message = this.getMessage(level);
		let options;

		if (this.time) {
			options = {
				duration: +this.time,
			};
		}

		switch (level) {
			case MessageLevel.Error:
				this.toast.error(message, options);
				break;
			case MessageLevel.Warning:
				this.toast.warning(message, options);
				break;
			case MessageLevel.Info:
				this.toast.info(message, options);
				break;
			case MessageLevel.Success:
				this.toast.success(message, options);
				break;
		}

		this.inProgress = false;
	}

	private getMessage(level: MessageLevel) {

		let pronoun = 'a';
		let lvl = level as string;

		if (level === MessageLevel.Info || level === MessageLevel.Error) {
			pronoun = 'an';
		}

		if (level === MessageLevel.Info) {
			lvl = 'information';
		}

		return `This is ${pronoun} ${lvl} message`;
	}

}
