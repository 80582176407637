import { Component } from '@angular/core';

import { contentIconMap } from '@unifii/library/smart-forms';

import { ColumnInfo } from '../../../components';

@Component({
	templateUrl: './show-carousel.html',
	selector: 'sc-show-carousel',
	styleUrls: ['./show-carousel.less'],
})
export class ShowCarouselComponent {

	protected readonly iconMap = contentIconMap;

	protected readonly intervalOptions = [
		{ name: 'None', value: 0 },
		{ name: '5s', value: 5000 },
		{ name: '10s', value: 10000 },
		{ name: '30s', value: 30000 },
	];

	protected readonly tableConfig: ColumnInfo[] = [
		{ identifier: 'name', name: 'Name' },
		{ identifier: 'type', name: 'Type', level: 'info' },
		{ identifier: 'description', name: 'Description' },
	];

	protected readonly tableInfo = [
		{ name: 'uf-carousel-page.flex', type: 'modifier', description: 'enables flex and stretches content' },
		{ name: 'uf-carousel-page.padded', type: 'modifier', description: 'pads page content to fit inside navigation buttons' },
	];

	protected interval = 5000;
	protected required = false;
	protected showError = false;
	protected content = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur cursus molestie felis nec auctor. Suspendisse ac tristique ex. Aliquam fringilla ipsum ultricies magna faucibus, nec eleifend nibh efficitur. Nullam iaculis libero imperdiet, euismod lectus at, condimentum massa. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Maecenas aliquam elit quis massa semper varius. Integer vehicula pharetra nisl. Suspendisse elementum, felis sed vulputate feugiat, eros eros mattis ipsum, ac vestibulum massa elit vitae nunc. Curabitur sed dui eget lectus fermentum pulvinar. Aenean porta odio et auctor iaculis. Cras non leo quis orci feugiat commodo. In lobortis lobortis lacinia. Integer suscipit varius massa vel lobortis.';
	protected class = '';
	protected template = ``;

	protected modifierChange() {

		if (!this.required) {
			this.showError = false;
		}

		this.template = ``;
	}

}
