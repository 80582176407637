import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CostModelFormat } from '@unifii/sdk';

import { FilterEntry, FilterViewMode, IFilterComponent } from '../../../models';

@Component({
	selector: 'uf-filter-cost',
	templateUrl: './filter-cost.html',
	styleUrls: ['./field-filter.less'],
})
export class FilterCostComponent implements IFilterComponent {

	@Input() mode: FilterViewMode = FilterViewMode.Input;
	@Input() entry?: FilterEntry;
	@Input() value: CostModelFormat | null = null;

	@Output() valueChange = new EventEmitter<CostModelFormat>();

	protected readonly modes = FilterViewMode;

	get label(): string {
		return this.entry?.label ?? '';
	}

	get isEmpty(): boolean {
		return !this.value;
	}

	protected remove() {
		this.value = null;
		this.valueChanged();
	}

	protected valueChanged() {
		this.valueChange.emit(this.value ?? undefined);
	}

}
