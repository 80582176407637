<div class="grid">
	<uf-select *ngIf="collections.length" [options]="collections" [(value)]="identifier" class="col-1of2"
		label="Select Collection" nameProperty="name" valueProperty="identifier" />

	<uf-select *ngIf="compounds.length" [options]="compounds" [(value)]="id" class="col-1of2" label="Select Compound"
		nameProperty="name" valueProperty="id" />

	<div *ngIf="!collections.length" class="col-1of1">
		<uf-message class="info" icon="info" content="No Collections" />
	</div>

	<div *ngIf="collections.length && !compounds.length" class="col-1of1">
		<uf-message class="info" icon="info" content="No Compound in the collection" />
	</div>
</div>

<ng-template [ngIf]="data">
	<uf-collection-item *ngIf="data.definition && data.compound" [definition]="data.definition"
		[compound]="data.compound" />
</ng-template>