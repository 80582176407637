import { Pipe, PipeTransform, inject } from '@angular/core';

import { DataDisplayInfo } from '../models';
import { DataDisplayService } from '../services';

@Pipe({ name: 'dataDisplay' })
export class DataDisplayPipe implements PipeTransform {

	private dataDisplayService = inject(DataDisplayService);

	transform(value: unknown, info?: DataDisplayInfo): string | null {
		return this.dataDisplayService.displayAsString(value, info);
	}

}
